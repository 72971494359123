import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Autocompletee from "@material-ui/lab/Autocomplete";
import {TextField} from "@material-ui/core";

const CssTextField = withStyles({
    root: {
      borderRadius: "0",
      fontFamily: "EYInterState-regular",
      boxShadow: "none",
      width: "100%",
      '& label.MuiFormLabel-root': {
        fontSize: 12,
        paddingLeft: 10,
        color: '#2e2e38'
      },
      '& label + .MuiInput-formControl': {
        marginTop: 0
      },
      '& label.Mui-focused': {
        color: '#2E2E38',
        // background: "#FFF",
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(11px, -5px) scale(0.75)',
        // fontSize: "12px",
      },
      '& .MuiInput-underline:after': {
        border: '0',
        borderRadius:"0",
      },
      '& .MuiInput-underline:before': {
        borderBottom: '1px solid #2e2e38 !important',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          border: '0.14rem solid rgba(0, 0, 0, 0.23)', 
          borderRadius:"0",
        },
        '&:hover fieldset': {
          borderColor: "#747480",
          boxShadow: "none",
        },
        '&.Mui-focused fieldset': {
          borderColor: '#747480',
        },
      },
      '& p.MuiFormHelperText-root.Mui-error': {
        position: 'relative',
        bottom: '12px',
        left: '2px',
        margin: '0px'
      },
      '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
        paddingLeft: '8px !important',
        marginTop: 12
      }
    },
  })(TextField);
  const useStyles = makeStyles((theme) => ({
    inputRoot: {
      '&&[class*="MuiOutlinedInput-root"] $input': {
        marginTop: 0
      },
    },
    input: {
      width: "100%",
      boxShadow: "none",
      outline: "none",
      border: "0",
      fontFamily: "EYInterState-regular",
      marginTop: 10,
      backgroundColor: '#fff',//added hm
      fontSize: 12,
    },
    // inputBase: {
    //   paddingLeft: 8
    // },
    listbox: {
      width: "97%",
      top:0,
      left:0,
      margin: 0,
      padding: 0,
      zIndex: 9,
      position: 'absolute',
      listStyle: 'none',
      fontFamily: "EYInterState-regular",
      border: '0.14rem solid #2E2E38',  
      borderTop: "0px",
      maxHeight: '20vh',
      overflow: 'auto',
      msOverflowStyle: "none",
      scrollbarWidth: "none",
      background:"#FFF",
      '& ::-webkit-scrollbar': {
        display: "none",
      },
      '& li': {
        padding: "8px 15px",
      },
      '& li[data-focus="true"]': {
        backgroundColor: '#F6F6FA',
        color: '#2E2E38',
        cursor: 'pointer',
        fontWeight: 600,
      },
      '& li:active': {
        backgroundColor: '#F6F6FA',
        color: '#2E2E38',
        fontWeight: 600,
      },
    },
    /* formControl: {
       minWidth: 120,
       width: "100%",
     },
     selectEmpty: {
       marginTop: theme.spacing(2),
       padding:'4px 0'
     }, */
     popupIndicator: {
      marginTop: 5,
      fontSize: 12,
      marginRight: 5,
    },
    popupIndicatorOpen: {
      marginTop: 5,
      fontSize: 12,
    }
  }));
  
  export const Autocomplete = (props)=>{
    const classes = useStyles();
    const {label,
        options,
        optionLabel=null,
        required=false,
        value,
        disableClearable=true,
        disableCloseOnSelect=false,
        dataChangeEvent} = props;  
  return (<Autocompletee style={{width:180}}//120
                options={options}
                getOptionLabel={(option) => optionLabel!==null?option[optionLabel]:option}
                size="small"
                classes={classes}
                disableClearable={disableClearable}
                disableCloseOnSelect={disableCloseOnSelect}
                popupIcon={<i className="icon-chevron-down"/>}
                defaultValue={
                  options.find(
                    (v) => optionLabel!==null?v[optionLabel]:v === value
                  ) || {}
                }
                renderInput={(params) => <CssTextField 
                  {...params} 
                  required={required}
                  label={label}
                  variant="standard" 
                  className={classes.input}
                  onBlur={dataChangeEvent}
                  value={value}
                />}
              />);  
    }