import React, { useContext, useState } from "react";
import { Modal } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import AppContext from "../../utils/appContext";
import "./DelegatesPopup.scss";
import moment from "moment";
import { authProvider, APIscope } from "./../../Authentication/authProvider";
import { useDispatch } from "react-redux";
import { decodeIdToken, removeSpecialCharsComments } from "../../utils/utils";
import AlertSubmitPopup from "../Common/AlertSubmitPopup";

const ApprovalPopup = (props) => {
  const dispatch = useDispatch();
  const [approveType, setApproveType] = useState(null);
  const [comment, setComment] = useState("");
  const myContext = useContext(AppContext);
  const maxLength = 10000;
  const closePopup = () => {
    props.cancelApprovalPopup();
  };

  const [showAlertSubmitPopup, setShowAlertSubmitPopup] = useState(false);
  const [alertHeader, setAlertHeader] = useState("Approval");
  const [alertMessage, setAlertMessage] = useState(
    "You have approved the submission, please confirm."
  );
  const alertSubmitTxt = useState("Confirm");

  const activePPMDuserId =
    myContext.activePPMDuserId || sessionStorage.activePPMDuserId;

  const submitPopupChanges = async () => {
    props.setLoader(true);
    const token = await authProvider.acquireTokenSilent({
      scopes: APIscope,
    });
    const idToken = token.accessToken;
    let payloadObj = {
      timePeriod: myContext.activePeriod,
      financialYear: myContext.financialYear,
      counseleeId: activePPMDuserId,
    };
    payloadObj["reviewerName"] = decodeIdToken(idToken).given_name || "";
    payloadObj["comment"] = comment;
    dispatch({
      type: "APPROVE_OR_REJECT_COUNSELEE",
      payload: {
        token: idToken,
        payloadObj,
        approveType: approveType,
      },
    });
    setShowAlertSubmitPopup(false);
    props.cancelApprovalPopup();
  };
  const saveApprovals = () => {
    if (approveType) {
      setAlertHeader("Approval");
      setAlertMessage("You are about to approve the submission.");
    } else {
      setAlertHeader("Request for Change");
      setAlertMessage("You are about to reject the submission.");
    }
    setShowAlertSubmitPopup(true);
  };

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.showApprovalPopup}
        onClose={(e) => closePopup()}
        hideBackdrop={false}
        disableEscapeKeyDown={true}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <div className={"delegatePopup approvalPopup msgPopup"}>
          <div className="delegateHeader">
            <div>Respond to PPMD</div>
            <i
              className="icon-close-filled closeIcon"
              onClick={(e) => closePopup()}
            />
          </div>
          <div className="delegateContent" style={{ marginTop: "10px" }}>
            <div className="partnerHeader">
              <div className="partnerImage">
                {props.partnerImg ? (
                  <img
                    src={
                      props.partnerImg
                        ? props.partnerImg
                        : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
                    }
                    alt="user-profile-pic"
                    className="profile-img"
                  />
                ) : (
                  <div className={"dummyDP"}>{props.partnerNameLetters}</div>
                )}
                <div className="partnerName">
                  <p>{props.partnerName}</p>
                  <p>Partner</p>
                </div>
              </div>
              <div className="partnerSubmitted">
                Submitted On :{" "}
                {moment(
                  props.userAssessmentData &&
                    props.userAssessmentData.assessmentEndDate
                ).format("h:mm A,DD-MM-YYYY")}
              </div>
            </div>

            <div className="statusContainer">
              <span className={"sub-head"}>Status</span>
              <br />

              <div style={{ marginLeft: "-5px", marginTop: "4px" }}>
                {approveType === null || !approveType ? (
                  <i
                    className="icon-radio-unchecked"
                    onClick={() => setApproveType(true)}
                  />
                ) : (
                  approveType && <i className="icon-radio-checked" />
                )}
                <span>Yes, Approve</span>
                {approveType === null || approveType ? (
                  <i
                    className="icon-radio-unchecked"
                    onClick={() => setApproveType(false)}
                  />
                ) : (
                  !approveType && <i className="icon-radio-checked" />
                )}
                <span>Changes Required</span>
              </div>
              <span className="note">
                Approval indicates LEAD Reviewer has reviewed counselee’s
                self-assessment survey and attests to the accuracy.
              </span>
            </div>
            <textarea
              id="txtArea"
              rows="10"
              cols="1000"
              placeholder="Comment"
              maxLength={maxLength}
              value={comment}
              onChange={(e) => {
                setComment(removeSpecialCharsComments(e.target.value));
              }}></textarea>
            {comment.length > maxLength / 2 ? (
              <span className="remainingChar">
                ({maxLength - comment.length}/{maxLength})
              </span>
            ) : null}
            <div className="okContainer">
              <div
                className="ok"
                style={approveType !== null ? { background: "#ffe600" } : {}}
                onClick={() => approveType !== null && saveApprovals()}>
                Send
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <AlertSubmitPopup
        onlyAlert={false}
        header={alertHeader}
        submitBtnTxt={alertSubmitTxt}
        message={alertMessage}
        showAlertSubmitPopup={showAlertSubmitPopup}
        cancelAlertSubmitPopup={() => setShowAlertSubmitPopup(false)}
        submitPopupChanges={submitPopupChanges}
        simpleClosePopup={() => setShowAlertSubmitPopup(false)}
      />
    </div>
  );
};
export default withRouter(ApprovalPopup);
