import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import SideMenu from "../SideMenu/SideMenu";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import * as myConsts from "../../constants/constants";
import "./Admin.scss";
import { toast } from "react-toastify";
import AppContext from "../../utils/appContext";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SimpleBar from "simplebar-react";

/* This is the main component for the admin page where we are having general parameters, manage users 
and reports. This page can be accessed by the users who is having admin access. If user tries to access
this page without admin acccess, then automatically redirected to the home page */

const Admin = (props) => {
  const myContext = useContext(AppContext);
  const countData = useSelector((state) => state.getApprovalCount.data);
  const getAssesmentMetaData = useSelector(
    (state) => state.getAssesmentMetaData
  );
  const [isBobDisabled, setIsBobDisabled] = useState(false);

  const useStyles = makeStyles((theme) => ({
    tooltip: {
      fontSize: 12,
      marginLeft: 10,
    },
  }));

  const classes = useStyles();

  let activePhase = getAssesmentMetaData
    ? getAssesmentMetaData.data
      ? getAssesmentMetaData.data.data
        ? getAssesmentMetaData.data.data.activePhase
        : []
      : []
    : [];

  const dispatch = useDispatch();
  const crumbs = [{ name: "Admin", path: "" }];

  const getPendingApprovalCount = () => {
    dispatch({
      type: "GET_PENDING_APPROVAL_COUNT",
      payload: {},
    });
  };

  const resetApprovalData = () => {
    dispatch({
      type: "RESET_ADMIN_APPROVAL",
      payload: {},
    });
  };

  useEffect(() => {
    sessionStorage.removeItem("currentPageName");
    getPendingApprovalCount();
    resetApprovalData();
    myContext.setActivePPMDuserId("");
    sessionStorage.setItem("activePPMDuserId", "");
    myContext.setIsRouteFromAdmin(false);
    sessionStorage.removeItem("profilePhase");
    sessionStorage.setItem("viewUserProfile", false);
    if (myContext.lockPopup) toast.dismiss();
    // eslint-disable-next-line
    dispatch({
      type: "SET_VIEW_USER_DATA",
      payload: {
        viewData: false,
      },
    });
  }, []);

  useEffect(() => {
    if (
      getAssesmentMetaData &&
      getAssesmentMetaData.data &&
      getAssesmentMetaData.data.data
    ) {
      if (activePhase !== null) {
        setIsBobDisabled(true);
      } else setIsBobDisabled(false);
    }
  }, [getAssesmentMetaData]);

  return (
    <div className="admin-container">
      <div className="admin-info-main-sidemenu">
        <SideMenu
          minimized={true}
          isminimizedMainMenu={true}
          isMainMenu={true}
          sideMenuItemSelect="Admin"
        />
      </div>
      <div className="admin-content">
        <div className="second-header">
          <Breadcrumb crumbs={crumbs} isAdmin={true} {...props} />
        </div>
        <SimpleBar
          style={{ maxHeight: "calc(100% - 40px)", height: "calc(100% - 40px)" }}>
          <Grid container spacing={2} className="card-container" style={{
            height: "100%",
            overflow: "hidden scroll"
          }}>
            <Grid item sm={3}>
              <div
                className="card"
                onClick={() =>
                  props.history.push(myConsts.routeAdminGeneralParameters)
                }>
                <div className="header">General Parameters</div>
                <div className="card-content">
                  Manage parameters like year, period, assessment, year-end discussion guide and approval
                  email reminders
                </div>
                {/* <div className="footer">
                Last updated by: Sudeep Jayaprakash | 05, Aug,2021
              </div> */}
              </div>
            </Grid>
            <Grid item sm={3}>
              <div
                className="card"
                onClick={() =>
                  props.history.push(myConsts.routeAdminUserManagement)
                }>
                <div className="header">User Management</div>
                <div className="card-content">
                  Manage user profiles, user roles and permissions, reminders,
                  emails and approvals
                </div>
                {/* <div className="footer">
                Last updated by: Sudeep Jayaprakash | 05, Aug,2021
              </div> */}
              </div>
            </Grid>
            <Grid item sm={3}>
              <div
                className="card"
                onClick={() => props.history.push(myConsts.routeAdminAssessment)}>
                <div className="header">Assessment</div>
                <div className="card-content">
                  Manage global metrics, goals,sections and impact statements.
                </div>
                {/* <div className="footer">
                Last updated by: Sudeep Jayaprakash | 05, Aug,2021
              </div> */}
              </div>
            </Grid>
            <Grid item sm={3}>
              <div
                className="card"
                onClick={() =>
                  props.history.push(myConsts.routeAdminReportMainPage)
                }>
                <div className="header">Reports</div>
                <div className="card-content">
                  View and download Book of Business and Assessment reports.
                </div>
                {/*  <div className="footer">
                Last updated by: Sudeep Jayaprakash | 05, Aug,2021
              </div> */}
              </div>
            </Grid>
            {/* ..DISABLE BOB :: COMMENTED BELOW CODE IF IT WILL REQUIRE TO ENABLE IN FUTURE */}
            {/* <Grid item sm={3}>
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={
                  isBobDisabled
                    ? "Will be enabled after the current phase lock date"
                    : ""
                }
                placement="bottom-start">
                <div
                  className={"card " + (isBobDisabled ? "opacityReduced" : "")}
                  onClick={() => {
                    if (!isBobDisabled)
                      props.history.push(myConsts.routeAdminBookOfBusiness);
                  }}>
                  <div className="header">Book Of Business</div>
                  <div className="card-content">
                    Upload Mercury and Regional data to generate Book of Business.
                  </div> */}
                  {/*  <div className="footer">
                  Last updated by: Sudeep Jayaprakash | 05, Aug,2021
                </div> */}
                {/* </div>
              </Tooltip>
            </Grid> */}
            <Grid item sm={3}>
              <div
                className="card"
                onClick={() =>
                  props.history.push(myConsts.routeAdminEmailConfiguration)
                }>
                <div className="header">Email Configurations</div>
                <div className="card-content">Manage email messages.</div>
                {/*  <div className="footer">
                Last updated by: Sudeep Jayaprakash | 05, Aug,2021
              </div> */}
              </div>
            </Grid>
            <Grid item sm={3}>
              <div
                className="card"
                onClick={() => props.history.push(myConsts.routeAdminApprovals)}>
                <div className="header">Approvals</div>
                <div className="card-content">
                  Manage global metrics, goals, sections and impact statements.
                </div>
                {countData && countData.total > 0 ? (
                  <div className="admin-pending-count">{countData.total}</div>
                ) : null}
              </div>
            </Grid>
            <Grid item sm={3}>
              <div
                className="card"
                onClick={() => props.history.push(myConsts.routeAdminContentManagement)}>
                <div className="header">Content Management</div>
                <div className="card-content">
                  Manage content on the home page.
                </div>
              </div>
            </Grid>
            {/* ..DISABLE BOB :: COMMENTED BELOW CODE IF IT WILL REQUIRE TO ENABLE IN FUTURE */}
            {/* <Grid item sm={3}>
              <div
                className="card"
                onClick={() => props.history.push(myConsts.routeAdminDataManagement)}>
                <div className="header">Data Management</div>
                <div className="card-content">
                  Manage sec indicator, mercury data during active phase.
                </div>
              </div>
            </Grid> */}
          </Grid>
        </SimpleBar>

      </div>
    </div>
  );
};
export default Admin;
