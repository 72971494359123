import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import "./ReviewConfirmation.scss";

const ReviewConfirmation = (props) => {

    const [initialConfirmation, setInitialConfirmation] = useState(true);
    const [value, setValue] = React.useState('');

    const cancelClick = () => {
        if(initialConfirmation) {
            closePopup();
        } else {
            setInitialConfirmation(true);
        }
    }

    const closePopup = () => {
        setValue("");
        setInitialConfirmation(true);
        props.dismissPopup();
    }

    const confirmClick = () => {
        if(value){
            if (initialConfirmation) {
                setInitialConfirmation(false);
            } else {
                setValue("");
                setInitialConfirmation(true);
                props.reviewResponse(value);
            }
        }
    }
    
    const handleRadioChange = (event) => {
        setValue(event.target.value);
    };

    return (
        <div>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={props.showReviewPopup}
                onClose={(e) => closePopup()}
                hideBackdrop={false}
                disableEscapeKeyDown={true}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                <div className={"review-popup approval-popup"}>
                    <div className={"reviewHeader"}>
                        <div>{initialConfirmation ? "Respond": "Confirmation"}</div>
                        <i
                            className="icon-close-filled closeIcon"
                            onClick={(e) => closePopup()}
                        />
                    </div>
                    {initialConfirmation ? <div className="review-confirmation-wrapper">
                        <FormControl>
                            <FormLabel component="legend">Status</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={value}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="Approved" control={<Radio color="default" />} label="Approve" />
                                <FormControlLabel value="Rejected" control={<Radio color="default" />} label="Reject" />
                            </RadioGroup>
                        </FormControl>
                    </div> : <div
                        className="reviewContent"
                        style={{ marginTop: "10px", marginBottom: "0px" }}>
                        <div className="reviewText">{(value == "Approved" ? "Would you like to approve the submitted changes? ": "Would you like to reject the submitted changes?")}</div>
                        <div className="reviewText">{"Please note that you will not be able to recall once confirmed"}</div>
                        <div
                            className="reviewText"
                            style={{ fontFamily: "EYInterState-Regular, sans-serif" }}>
                            {props.bodyText}
                        </div>
                    </div>
                    }
                    <div className="okContainer">
                        <div className="cancel" onClick={() => cancelClick()}>
                            {"Cancel"}
                        </div>
                        <div className={"save " + (value ? "": "disable") } onClick={() => confirmClick()}>
                            {initialConfirmation ? "Update" : "Confirm"}
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};
export default withRouter(ReviewConfirmation);
