import React, { useState, useEffect, useContext } from "react";
// import { Route, Switch, Redirect } from "react-router-dom";
import * as myConsts from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import { authProvider, APIscope } from "./../../Authentication/authProvider";
import Loader from "../Loader/Loader";
import AppContext from "../../utils/appContext";
import SideMenu from "../SideMenu/SideMenu";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import DashBoardFilters from "../DashBoard/DashBoardFilters";
import SimpleBar from "simplebar-react";
import "./PPMDList.scss";
import PPMDCard from "./PPMDCard/PPMDCard";

const PPMDList = (props) => {
  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  // const currentPeriod = myContext.currentPeriod;
  const getCounseleesList = useSelector((state) => state.getCounseleesStatus);
  const [counsellesList, setcounseleesList] = useState([]);
  const [loader, setLoader] = useState(true);
  const ppmdStatusFilters = useSelector(
    (state) => state.ppmdStatusFilters.data
  );

  const crumbs = [
    {
      name: "PPMD Profiles",
      path: myConsts.routePpmdProfiles,
    },
  ];
  const isFilteredStatus = (Status) => {
    //status values from API is different from status to be displayed.This function is to filter cards with particular status selected
    if (Status && isNaN(Status)) {
      let status = Status.toLowerCase();
      let array1 = [];
      let array2 = [];
      let array3 = [];
      if (
        status === myConsts.statusNotStarted ||
        status === myConsts.statusInProgress ||
        status === myConsts.pendingSubmission
      ) {
        array1 = ppmdStatusFilters.filter(
          (item) => item.option.toLowerCase() === myConsts.pendingSubmission
        );
        if (array1.length > 0) {
          return array1[0].isSelected;
        }
      } else if (status === myConsts.statusSubmitted) {
        array2 = ppmdStatusFilters.filter(
          (item) => item.option.toLowerCase() === myConsts.pendingApproval
        );
        if (array2.length > 0) {
          return array2[0].isSelected;
        }
      } else if (status === myConsts.statusCompleted) {
        array2 = ppmdStatusFilters.filter(
          (item) => item.option.toLowerCase() === myConsts.approved
        );
        if (array2.length > 0) {
          return array2[0].isSelected;
        }
      } else if (status === myConsts.statusRequestedChange) {
        array3 = ppmdStatusFilters.filter(
          (item) => item.option.toLowerCase() === myConsts.statusRequestedChange
        );
        if (array3.length > 0) {
          return array3[0].isSelected;
        }
      } else return false;
    }
  };
  const applystatusFilters = (filters) => {
    // To save apply filter values in store
    dispatch({
      type: "SET_PPMD_STATUS_FILTERS",
      payload: filters,
    });
  };

  useEffect(() => {
    //Handle data from getCounselee list API
    if (
      getCounseleesList &&
      !getCounseleesList.fetching &&
      getCounseleesList.data
    ) {
      setcounseleesList(getCounseleesList.data.data);
      setLoader(false);
    }
  }, [getCounseleesList]);
  useEffect(() => {
    //API call for getting list of counselees on page load
    (async function () {
      myContext.setActivePPMDuserId("");
      myContext.setPPMDData("");
      myContext.setIsRouteFromAdmin(false);
      sessionStorage.removeItem("profilePhase");
      sessionStorage.removeItem("activePPMDuserId");
      sessionStorage.removeItem("PPMDData");
      setLoader(true);
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;

        dispatch({
          type: "GET_COUNSELEES_STATUS",
          payload: {
            token: idToken,
            activePeriod: myContext.currentPeriod,
            financialYear: myContext.financialYear,
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loader && <Loader />}
      <div className={"ppmd-list"}>
        <div style={{ width: 58, float: "left" }}>
          <SideMenu
            minimized={true}
            sideMenuItemSelect="Home"
            isminimizedMainMenu={true}
            isMainMenu={true}
          />
        </div>
        <div style={{ width: "100%" }}>
          <div
            className="second-header"
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              marginLeft: "55px",
            }}>
            <Breadcrumb crumbs={crumbs} {...props} />
            <div className="pillar-filters">
              <DashBoardFilters
                filterOptions={ppmdStatusFilters}
                onApplyFilter={(filters) => applystatusFilters(filters)}
              />
            </div>
          </div>
        </div>
        <SimpleBar className="scroll-ppmd" style={{ maxHeight: "100vh" }}>
          <div className="ppmd-list-inner">
            {ppmdStatusFilters &&
              counsellesList &&
              counsellesList.length > 0 &&
              counsellesList
                .filter((x) => x.status && isFilteredStatus(x.status))
                .map((item, index) => {
                  return (
                    <PPMDCard
                      userData={item}
                      history={props.history}
                      id={index}
                      key={index}
                    />
                  );
                })}
            {ppmdStatusFilters &&
              counsellesList &&
              counsellesList.length === 0 && (
                <div className="no-data">No PPMD profiles found.</div>
              )}
          </div>
        </SimpleBar>
      </div>
    </>
  );
};

export default PPMDList;
