export const PrepareAddRequest = (
  formValues,
  userDetails,
  financialYear,
  phase,
  mode,
  currentUserDetails
) => {
  let request = {};
  const isExistingUser = mode === "Add user";
  Object.keys(formValues).forEach((key) => {
    if (
      (key === "primarySector" || key === "secondarySectorSpecialization") &&
      (formValues[key].value === "Select" || formValues[key].value === "")
    ) {
      request[key] = "All";
    } else {
      request[key] = formValues[key].value;
    }
  });
  request = {
    ...request,
    isAdmin: formValues.role.value.indexOf("Admin") !== -1,
    isOperationalAdmin:
      formValues.role.value.indexOf("Operational Admin") !== -1,
    role: generateRole(formValues.role.value),
    givenName: userDetails.data.givenName,
    preferredName: isExistingUser
      ? userDetails.data.preferredName
      : userDetails.data.displayName,
    userId: isExistingUser ? userDetails.data.userId : userDetails.data.mail,
    rankDesc: isExistingUser
      ? userDetails.data.rankDesc
      : userDetails.data.jobTitle,
  };
  return {
    period: {
      financialYear,
      phase,
    },
    userData: {
      ...request,
    },
    createdBy:{
      name: currentUserDetails.displayName,
      userId: currentUserDetails.mail
    },
  };
};

export const PrepareEditRequest = (formValues, userDetails, financialYear, phase, currentUserDetails, isSubmitted, madeLRAutoEmail) => {
  let request = {};
  Object.keys(formValues).forEach((key) => {
    if (
      (key === "primarySector" || key === "secondarySectorSpecialization") &&
      (formValues[key].value === "Select" || formValues[key].value === "")
    ) {
      request[key] = "All";
    } else {
      request[key] = formValues[key].value;
    }
  });
  request = {
    ...request,
    isAdmin: formValues.role.value.indexOf("Admin") !== -1,
    isOperationalAdmin:
      formValues.role.value.indexOf("Operational Admin") !== -1,
    userId: userDetails.data.userId,
    rankDesc: userDetails.data.rankDesc,
    role: generateRole(formValues.role.value),
    preferredName: userDetails.data.preferredName,
    madeLRAutoEmail: madeLRAutoEmail
  };
  return {
    period: {
      financialYear,
      phase,
    },
    userData: {
      ...request,
    },
    createdBy:{
      name: currentUserDetails.displayName,
      userId: currentUserDetails.mail
    },
    validationStatus: isSubmitted ? "Submitted" : "InProgress",
  };
};

const generateRole = (role) => {
  const filteredRoles = role.filter((role) => {
    return role !== "Operational Admin" && role !== "Admin";
  });

  if (filteredRoles.length === 1) {
    return filteredRoles[0].replace(/\s/g, "");
  } else if (
    filteredRoles.indexOf("PPMD") !== -1 &&
    filteredRoles.indexOf("Lead Reviewer") !== -1
  ) {
    return "LeadReviewer-PPMD";
  } else {
    return "Admin";
  }
};
