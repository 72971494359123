import Loader from "../Loader/Loader";

const centerStyle = {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#ededf2",
};

const Info = ({ text = 'Loading...' }) => (
    <>
        <div style={centerStyle}>
            {/* {`${text}`} */}
            <Loader/>
        </div>
    </>
)

export default Info;