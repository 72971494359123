import { InputLabel, TextField, makeStyles, FormHelperText, FormControl } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import QuillEditor from '../QuillEditor/QuillEditor';
import DropdownComponent from "../../AdminGeneralParam/DropdownComponent/DropdownComponent";
import { emailRecipientList } from '../constants';
import { placeholders } from '../QuillEditor/constant';
import DateTimeCalender from "../../AdminGeneralParam/DateTimeCalender/DateTimeCalender";
import TimezoneSelect from 'react-timezone-select';
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import * as myConsts from "../../../../constants/constants";
import { Flip, toast } from 'react-toastify';
import { OUTSIDE_CLICK_COMMON } from '../../../../actions/types';
import AlertSubmitPopup from '../../../Common/AlertSubmitPopup';
import moment from 'moment';
import AppContext from '../../../../utils/appContext';


const useStyles = makeStyles((theme) => ({
    root1: {
        "& .MuiFilledInput-root": {
            background: "rgb(246,246,250)",
            borderRadius: "0px",
            fontFamily: "EYInterState-Regular",
        },
        "& .MuiFilledInput-underline": {
            width: "250px",
            height: "27px",
            //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
            //   borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
            "&:after": {
                // The MUI source seems to use this but it doesn't work
                borderBottom: "none",
            },
            //   "&:hover:not($disabled):not($focused):not($error):before": {
            //     borderBottom: "none",
            //   },
            " &:hover:before": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
            },
            " &:hover:after": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
            },
        },

        "& .MuiFilledInput-input": {
            padding: "8px 10px 8px 6px",
            fontSize: "12px",
            lineHeight: "15px",
        },
        "& .MuiFilledInput-multiline": {
            padding: "10px  10px 6px  0px",
        },
    },
    // labelroot: {
    //   backgroundColor: "rgb(246 ,246, 250)!important",
    //   color: "rgba(0, 0, 0, 0.94)",
    //   paddingLeft: "5px",
    //   paddingTop: "5px",
    //   fontSize: "8px",
    // },
    // topSpacing: {
    //   marginTop: "16px",
    // },
    // topSyli: {
    //   marginBottom: " -30px",
    //   marginTop: "16px",
    //   zIndex: "8",
    //   width: "115px",
    // },
    // half: {
    //   display: "flex",
    //   flexDirection: "row",
    //   width: "350px",
    //   marginLeft: "15px",
    // },
    root900: {
        "& .MuiFilledInput-root": {
            background: "rgb(246,246,250)",
            borderRadius: "0px",
            fontFamily: "EYInterState-Regular",
        },
        "& .MuiFilledInput-underline": {
            // width: "870px",
            "&:after": {
                // The MUI source seems to use this but it doesn't work
                borderBottom: "none",
            },
            "&:hover:not($disabled):not($focused):not($error):before": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
            },
        },

        "& .MuiFilledInput-input": {
            padding: "8px 10px 8px 6px",
            fontSize: "12px",
            lineHeight: "15px",
            fontFamily: "EYInterState-Regular",
        },
        "& .MuiFilledInput-multiline": {
            padding: "10px  10px 6px  0px",
            fontFamily: "EYInterState-Regular",
        },
    },
    root: {
        "& .MuiFilledInput-root": {
            background: "rgb(246,246,250)",
            borderRadius: "0px",
        },
        "& .MuiFilledInput-underline": {
            width: "400px",
            //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
            "&:after": {
                // The MUI source seems to use this but it doesn't work
                borderBottom: "none",
            },
            "&:hover:not($disabled):not($focused):not($error):before": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
            },
        },

        "& .MuiFilledInput-input": {
            padding: "8px 10px 8px 6px",
            fontSize: "12px",
            lineHeight: "15px",
            fontFamily: "EYInterState-Regular",
        },
        "& .MuiFilledInput-multiline": {
            padding: "10px  10px 6px  0px",
            fontFamily: "EYInterState-Regular",
        },
    },
    rootMulti: {
        "& .MuiFilledInput-root": {
            background: "rgb(246,246,250)",
            borderRadius: "0px",
        },
        "& .MuiFilledInput-underline": {
            width: "677px",
            //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
            "&:after": {
                // The MUI source seems to use this but it doesn't work
                borderBottom: "none",
            },
            "&:hover:not($disabled):not($focused):not($error):before": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
            },
        },

        "& .MuiFilledInput-input": {
            padding: "8px 10px 8px 6px",
            fontSize: "12px",
            lineHeight: "15px",
            fontFamily: "EYInterState-Regular",
        },
        "& .MuiFilledInput-multiline": {
            padding: "10px  10px 6px  0px",
        },
    },
    labelroot: {
        backgroundColor: "rgb(246 ,246, 250)!important",
        color: "rgba(0, 0, 0, 0.94)",
        paddingLeft: "5px",
        paddingTop: "5px",
        fontSize: "8px",
        // fontWeight: "600",
    },
    labelroot1: {
        backgroundColor: "rgb(246 ,246, 250)!important",
        color: "rgba(0, 0, 0, 0.94)",
        paddingLeft: "5px",
        paddingTop: "5px",
        fontSize: "10px",
        fontWeight: "600",
    },
    topSpacing: {
        marginTop: "8px",
    },
    topSyli: {
        marginBottom: " -30px",
        marginTop: "16px",
        zIndex: "8",
        width: "100px",
    },
    half: {
        display: "flex",
        flexDirection: "row",
    },
    arrow: {
        position: "absolute",
        fontSize: 14,
        width: "6em",
        height: "4em",
        //   transform: "rotate(225deg)",
        "&::before": {
            content: '""',
            margin: "auto",
            display: "block",
            width: 0,
            height: 0,
            borderStyle: "solid",
        },
    },
    paper2: {
        backgroundColor: "white",
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 2, 2),
        width: 170,
        overflow: "hidden",
        overflowX: "hidden",
        fontSize: "11px",
    },
    options: {
        borderBottom: "1px solid grey",
        padding: "5px 0px 5px 5px",
        display: "flex",
        alignItems: "center",
        height: "24px",
        cursor: "pointer",
    },
    popper: {
        // zIndex: 1,
        left: "-55px!important",
        '&[x-placement*="top-end"]': {
            top: "-12px!important",
        },
        '&[x-placement*="bottom-end"]': {
            top: "9px!important",
        },
        '&[x-placement*="bottom"] $arrow': {
            top: 2,
            left: "105px!important",
            marginTop: "-0.9em",
            width: "6em",
            height: "2em",
            "&::before": {
                borderWidth: "0 1em 1em 1em",
                borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: "103px!important",
            marginBottom: "-3.6em",
            width: "6em",
            height: "4em",
            "&::before": {
                borderWidth: "1em 1em 0 1em",
                borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: "-0.9em",
            width: "6em",
            height: "4em",
            "&::before": {
                borderWidth: "1em 1em 1em 0",
                borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: "-0.9em",
            width: "6em",
            height: "4em",
            "&::before": {
                borderWidth: "1em 0 1em 1em",
                borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
            },
        },
    },
}));

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        fontSize: "12px",
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
    control: (provided) => ({
        ...provided,
        width: 350,
        border: "none",
        borderBottom: " 1.5px solid black",
        borderRadius: "0px",
        boxShadow: "none",
        backgroundColor: "rgb(246, 246, 250)",
        "&:hover": {
            order: "none",
            borderBottom: " 1.5px solid black",
            borderRadius: "0px",
            boxShadow: "none",
        },
    }),
    container: () => ({
        width: 350,
    }),
    placeholder: () => ({
        fontSize: "0.9rem",
        top: "12%",
        marginLeft: "0px",
    }),
    singleValue: (provided, state) => {
        const fontSize = "12px";
        const color = "rgba(0, 0, 0, 0.87)";

        return { ...provided, fontSize, color };
    },
};
const EmailTemplates = (props) => {
    const { setOpen, setPreviewContent, setView, onSend, setIsChangesInChild, onCancelCheck } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [sendList, setSendList] = useState([]);
    const [subject, setSubject] = useState('');
    const [isEmpty, setEmpty] = useState(true);
    const [saveClicked, setSaveClicked] = useState(0);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [timezone, setTimezone] = useState(myConsts.defaultSelectedTimeZone);
    const [dateTime, setDateTime] = useState(null);
    const [flagON, setIsFlagON] = useState(false);
    const [type, setType] = useState(-1);
    const { Specific, ...dynamicPlaceholders } = placeholders;
    const myContext = useContext(AppContext);

    const onSave = (content) => {
        const list = emailRecipientList.filter(recipient => (sendList.findIndex(ele => (ele === recipient.value)) !== -1));
        const senderList = list.map(({ key }) => (key));
        const data = {
            sendList: senderList,
            subject,
            content,
            sendBy: myContext.displayName || '',
            dateTime: convertDateToUTC(dateTime)
        }
        onSend(data, type);
        localChanges(false);
        setDateTime(null);
    };

    const onPopupConfirm = () => {
        setIsPopupVisible(false);
        setType(3)
        setSaveClicked(saveClicked + 1);
    };

    const convertOffsetToProperFormat = (value) => {
        //To convert offset from timezone-select component to std. format(eg:GMT+1.5 to GMT+01:30)
        //If decimal point is present in offset, conversion is done else return offset as such
        let tempVal = value;
        tempVal.offsetformatted = value.offset;
        let offset = value.offset.toString();
        if (offset.includes(".")) {
            let tempmin = offset.substring(offset.indexOf("."));
            let temphr = offset.substring(0, offset.indexOf("."));
            tempVal.offsetformatted = temphr + ":" + Number.parseFloat(tempmin) * 60;
            return tempVal;
        } else {
            return tempVal;
        }
    };

    const convertDateToUTC = (date) => {
        if (date) {
            return new Date( //tobe used while saving
                new Date(date).toLocaleString() +
                " UTC" +
                (timezone.offset < 0
                    ? timezone.offsetformatted
                    : (" +" + timezone.offsetformatted).toString())
            ).toISOString();
        } else return null;
    };

    const inValidDateTimeSelection = (date) => {
        //all the dates till next day scheduler time  12:00am UTC has already been picked,so not editable
        const diffInMins = moment(moment(convertDateToUTC(date))).diff(new Date(), 'minutes');
        const diffInHrs = moment(moment(convertDateToUTC(date))).diff(new Date(), 'hours');
        if (
            timezone &&
            timezone.value !== undefined &&
            timezone.offset !== undefined &&
            date != null &&
            diffInMins > 15 &&
            diffInHrs < 72
            // && convertTZ(new Date(new Date(new Date().setUTCHours(72, 0, 0, 0)))) > new Date(date)
            // && convertTZ(new Date(new Date(new Date().setUTCMinutes(15, 0, 0, 0)))) < new Date(date)
        ) {
            return false;
        } else {
            return true;
        }
    };

    const dateTimeOnChange = (fieldname, value) => {
        // common onchange function for date-time selection fields
        if (inValidDateTimeSelection(value)) {
            if (!flagON) {
                setIsFlagON(true);
                setTimeout(function () {
                    setIsFlagON(false);
                }, 3000);
                toast.warn(
                    <div>
                        <i className="icon-verified-outline icon" />
                        {"Sorry!You can't choose a time 'less than 15 mins' or 'more than 72 hours' from now."}
                    </div>,
                    {
                        position: "bottom-center",
                        transition: Flip,
                        autoClose: 1500,
                        hideProgressBar: true,
                        closeOnClick: false,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    }
                );
            }
        } else {
            setDateTime(value);
        }
    };

    const localChanges = (status) => {
        setIsChangesInChild(status);
        dispatch({
            type: OUTSIDE_CLICK_COMMON,
            payload: {
                feature: "EmailConfiguration",
                changesDone: status,
                OpenModal: "",
                ProceedWithNavigation: "",
            },
        });
    }

    const onCancel = () => {
        setPreviewContent('');
        setView('adhoc-email-list');
    }

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }} >
                <div>
                    <div className={classes.half}
                        style={{
                            width: '18%',
                            float: 'left'
                        }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "10px",
                                marginRight: "10px",
                                marginTop: "-5px"
                            }}>
                            <DropdownComponent
                                label={'Send to'}
                                defaultValue={sendList}
                                required={true}
                                multiple={true}
                                selectOnChange={(dummy, value) => {
                                    setSendList(value)
                                    localChanges(true);
                                }}
                                varName="emailRecipients"
                                list={emailRecipientList.map(({ key, value }) => (value))}
                                errorMessage={{ emailRecipients: '' }}
                                // disableUnderline={!editable}
                                // disabled={!editable}
                                customClass={"narrowtextField"}
                            />
                        </div>
                    </div>
                    <div className={classes.half}
                        style={{
                            width: '74%',
                            float: 'left'
                        }}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "10px",
                                marginRight: "10px",
                                width: '100%'
                            }}>
                            <InputLabel
                                htmlFor="uncontrolled-native"
                                className={classes.topSpacing}
                                classes={{
                                    root: classes.labelroot,
                                }}>
                                {"Email Subject*"}
                            </InputLabel>
                            <TextField
                                inputProps={{ maxLength: 200 }}
                                fullWidth
                                id="filled-basic"
                                style={{ textAlign: 'left' }}
                                variant="filled"
                                className={classes.root900}
                                // multiline
                                maxRows={1}
                                required={true}
                                value={subject}
                                onChange={(e) => {
                                    setSubject(e.target.value)
                                    localChanges(true);
                                }}
                            />
                        </div>
                    </div>
                    <div
                        style={
                            {
                                cursor: "pointer",
                                opacity: "1",
                                border: "1px solid #c4c4cd",
                                padding: "4px",
                                fontSize: "10px",
                                borderRadius: "3px",
                                marginTop: "15px",
                                width: "5%",
                                float: "left"
                            }
                        }
                        onClick={() => {
                            setOpen(true);
                        }}>
                        <i
                            className="eyaa-image-25"
                            style={{ marginRight: "5px" }}
                        />
                        Preview
                    </div>
                </div>
                <div style={{ padding: '10px 10px 10px 10px', height: '60vh' }} >
                    <QuillEditor
                        text={''}
                        maxHeight={"320px"}
                        onSave={onSave}
                        saveClicked={saveClicked}
                        placeholders={dynamicPlaceholders}
                        onChange={(value, isEmpty) => {
                            setPreviewContent(value)
                            setEmpty(isEmpty)
                            localChanges(true);
                        }}
                    />
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    borderTop: '1px #e2e2e7 solid',
                    padding: '10px',
                    position: 'absolute',
                    bottom: '0px',
                    right: '0px',
                    width: '100%'
                }}>
                    <div
                        onClick={() => onCancelCheck(onCancel)}
                        className={"btn"}
                        style={{ border: '1px #e2e2e7 solid' }}
                    >
                        Cancel
                    </div>
                    <div
                        onClick={() => {
                            if (sendList.length > 0 && subject !== '') { setIsPopupVisible(true) }
                        }}
                        className={classNames("btn yellow-color refresh-btn", {
                            "disable-element": sendList.length === 0 || subject === '' || isEmpty
                        })}
                    >
                        Send Later
                    </div>
                    <div
                        onClick={() => {
                            if (sendList.length > 0 && subject !== '') {
                                setType(4)
                                setSaveClicked(saveClicked + 1);
                            }
                        }}
                        className={classNames("btn yellow-color refresh-btn", {
                            "disable-element": sendList.length === 0 || subject === '' || isEmpty
                        })}
                    >
                        Send Now
                    </div>
                </div>
            </div>

            {isPopupVisible && (
                <div className="popup-dialog">
                    <div className="dialog" style={{ height: "240px", width: '380px' }}>
                        <div className="header">
                            <h5>Schedule Email</h5>
                            <span
                                className="icon-close-filled icon"
                                onClick={() => setIsPopupVisible(false)}
                            ></span>
                        </div>
                        <div className="content">
                            <div className="seperator">
                                <div className="headingStyle">Select Time Zone</div>
                                <FormControl
                                    error={false}
                                    disabled={false}>
                                    <TimezoneSelect
                                        value={timezone}
                                        styles={customStyles}
                                        isDisabled={false}
                                        onChange={(value) => {
                                            const formattedValue = convertOffsetToProperFormat(value);
                                            setTimezone(formattedValue)
                                            setDateTime(null)
                                        }}
                                        placeholder="Select Time Zone*"
                                    />
                                </FormControl>
                            </div>
                            <DateTimeCalender
                                label="Choose date and time"
                                disablePast={false}
                                defaultValue={dateTime}
                                required={true}
                                dateTimeOnChange={dateTimeOnChange}
                                varName="dataTime"
                                errorMessage={{ dataTime: '' }}
                                customTextField
                            />
                        </div>
                        <div className="footer">
                            <button
                                className="cancel-btn"
                                onClick={() => {
                                    setDateTime(null);
                                    setIsPopupVisible(false)
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className={classNames("confirm-btn", {
                                    "disable-element": timezone === '' || dateTime === null
                                })}
                                onClick={() => {
                                    if (timezone !== '' && dateTime !== null)
                                        onPopupConfirm()
                                }}>
                                Schedule
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default EmailTemplates
