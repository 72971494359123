import React, { useCallback, useState, useMemo, useEffect } from "react";
//import ReactExport from "react-data-export";
import _ from "lodash";

const DownloadRegionalDataTemplate = (props) => {
    // const ExcelFile = ReactExport.ExcelFile;
    // const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const [templateData, setTemplateData] = useState([]);

    const contentBorder = useMemo(() => {
        return {
          top: { style: "medium", color: { rgb: "FF000000" } },
          bottom: { style: "medium", color: { rgb: "FF000000" } },
          left: { style: "medium", color: { rgb: "FF000000" } },
          right: { style: "medium", color: { rgb: "FF000000" } },
        };
      }, []);
    const contentStyle = useMemo(() => {
      return {
        font: { name: "EYInterstate", sz: "8" },
        alignment: {
          wrapText: true,
          horizontal: "left",
          vertical: "center",
        },
        border: contentBorder,
      };
    }, [contentBorder]);
    
      const templateHeaders = useMemo(
        () => [
          {
            label: "Client ID",
            width: 100,
          },
          {
            label: "Client",
            width: 100,
          },
          {
            label: "Engagement Region",
            width: 150,
          },
          {
            label: "SEC Indicator",
            width: 150,
          },

          {
            label: "AccountSector",
            width: 100,
          },
          {
            label: "Coordinating Partner Name & GPN/GUI",
            width: 200,
          },
          {
            label: "Engagement Partner #1 Name & GPN/GUI",
            width: 200,
          },
          {
            label: "Engagement Partner #2 Name & GPN/GUI (if applicable)",
            width: 200,
          },
          {
            label: "Engagement Partner #3 Name & GPN/GUI (if applicable)",
            width: 200,
          },
          {
            label: "Engagement Partner #4 Name & GPN/GUI (if applicable)",
            width: 200,
          },
          {
            label: "Engagement Partner #5 Name & GPN/GUI (if applicable)",
            width: 200,
          },
          {
            label: "Revenue Goal ($)",
            width: 80,
          },
          {
            label: "Region Margin Target %",
            width: 80,
          },
        ],
        []
      );
      const proccessRegionalData = useCallback(() => {
        let template = [
            {
              columns: [],
              data: [],
            },
          ];
          templateHeaders.forEach((data, index) => {
            template[0].columns.push({
              title: data.label,
              width: { wpx: data.width },
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFBDD7EE" } },
                font: { name: "EYInterstate", sz: "11", bold: true },
                border: contentBorder,
                alignment: {
                  wrapText: true,
                  horizontal: "left",
                  vertical: "center",
                },
              },
            });
          });

          for (var i = 0; i <= 100; i++) {
            const excelData = [];
            excelData.push(
              {
                value: "",
                style: contentStyle,
              },
              {
                value: "",
                style: contentStyle,
              },
              {
                value: "",
                style: contentStyle,
              },
              {
                value: "",
                style: contentStyle,
              },
              {
                value: "",
                style: contentStyle,
              },
              {
                value: "",
                style: contentStyle,
              },
              {
                value: "",
                style: contentStyle,
              },
              {
                value: "",
                style: contentStyle,
              },
              {
                value: "",
                style: contentStyle,
              },
              {
                value: "",
                style: contentStyle,
              },
              {
                value: "",
                style: contentStyle,
              },
              {
                value: "",
                style: contentStyle,
              },
              {
                value: "",
                style: contentStyle,
              },
            );
            template[0].data.push(excelData);
          }
          setTemplateData(template);
    }, [templateHeaders]);

    useEffect(() => {
        
        proccessRegionalData();
      }, [proccessRegionalData, contentBorder]);
    return (
        // <ExcelFile
        //   element={
        //     <button
        //       disabled={props.disableDownloadBtn}
        //       className={props.disableDownloadBtn ? "disable-element" : ""}>
        //       <span className="eyaa-arrow-tab-down"></span>
        //       <span>Download Template</span>
        //     </button>
        //   }
        //   filename="Add Regional Data_Template"
        // >
        //     <ExcelSheet
        //     dataSet={templateData}
        //     name="Add Regional Data_Template"
        //     />
        // </ExcelFile>
        <></>
    );
};
export default DownloadRegionalDataTemplate;