import React, { useState, useEffect, useContext } from "react";
// import { Route, Switch, Redirect } from "react-router-dom";
import * as myConsts from "../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  Grid,
  Checkbox,
  ListItemText,
  FormHelperText,
  IconButton,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { authProvider, APIscope } from "./../../Authentication/authProvider";
import Loader from "../Loader/Loader";
import AppContext from "../../utils/appContext";
import SideMenu from "../SideMenu/SideMenu";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import DashBoardFilters from "../DashBoard/DashBoardFilters";
import SimpleBar from "simplebar-react";
import "./DiscussionGuideList.scss";
import DiscussionGuideCard from "./DiscussionGuideCard/DiscussionGuideCard";
import NoAccess from "../../components/NotValidUser/NoAccess";
import AdminGeneralParam from "./../Admin/AdminGeneralParam/AdminGeneralParam";

const DiscussionGuideList = (props) => {
  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  // const currentPeriod = myContext.currentPeriod;
  const getCounseleesList = useSelector((state) => state.getCounseleesStatus);
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  const [counsellesList, setcounseleesList] = useState([]);
  const [loader, setLoader] = useState(true);
  const ppmdStatusFilters = useSelector(
    (state) => state.ppmdStatusFilters.data
  );

  const crumbs = [
    {
      name: "Discussion Guide",
      path: myConsts.routeDiscussionGuide,
    },
  ];
  const isFilteredStatus = (Status) => {
    //status values from API is different from status to be displayed.This function is to filter cards with particular status selected
    if (Status && isNaN(Status)) {
      let status = Status.toLowerCase();
      let array1 = [];
      let array2 = [];
      let array3 = [];
      if (
        status === myConsts.statusNotStarted ||
        status === myConsts.statusInProgress ||
        status === myConsts.pendingSubmission
      ) {
        array1 = ppmdStatusFilters.filter(
          (item) => item.option.toLowerCase() === myConsts.pendingSubmission
        );
        if (array1.length > 0) {
          return array1[0].isSelected;
        }
      } else if (status === myConsts.statusSubmitted) {
        array2 = ppmdStatusFilters.filter(
          (item) => item.option.toLowerCase() === myConsts.pendingApproval
        );
        if (array2.length > 0) {
          return array2[0].isSelected;
        }
      } else if (status === myConsts.statusCompleted) {
        array2 = ppmdStatusFilters.filter(
          (item) => item.option.toLowerCase() === myConsts.approved
        );
        if (array2.length > 0) {
          return array2[0].isSelected;
        }
      } else if (status === myConsts.statusRequestedChange) {
        array3 = ppmdStatusFilters.filter(
          (item) => item.option.toLowerCase() === myConsts.statusRequestedChange
        );
        if (array3.length > 0) {
          return array3[0].isSelected;
        }
      } else return false;
    }
  };
  const applystatusFilters = (filters) => {
    // To save apply filter values in store
    dispatch({
      type: "SET_PPMD_STATUS_FILTERS",
      payload: filters,
    });
  };

  useEffect(() => {
    //Handle data from getCounselee list API    
    if (
      getCounseleesList &&
      !getCounseleesList.fetching &&
      getCounseleesList.data
    ) {
      setcounseleesList(getCounseleesList.data.data);
      setLoader(false);
    }
  }, [getCounseleesList]);
  useEffect(() => {
    //API call for getting list of counselees on page load
    (async function () {
      //debugger;
      myContext.setActivePPMD_Id("");
      sessionStorage.removeItem("activePPMD_Id");
      //myContext.setActivePPMDuserId("");
      //myContext.setPPMDData("");
      //myContext.setIsRouteFromAdmin(false);
      //sessionStorage.removeItem("profilePhase");
      //sessionStorage.removeItem("activePPMDuserId");
      //sessionStorage.removeItem("PPMDData");
      setLoader(true);
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;

        dispatch({
          type: "GET_COUNSELEES_STATUS",
          payload: {
            token: idToken,
            activePeriod: myContext.currentPeriod,
            financialYear: myContext.financialYear,
          },
        });
        dispatch({
          type: "GET_COUNSELEES_FINALIZATION_DETAILS_RESET",
        });
        dispatch({
          type: "GET_ALL_USER_ASSESSMENT_DATA",
          payload: {
            token: idToken,

            activePeriod: myContext.activePeriod,
            financialYear: myContext.financialYear,
          },
        });
        dispatch({
          type: "GET_ALL_ASSESSMENT_DATA",
          payload: {
            token: idToken,

            activePeriod: myContext.activePeriod,
            financialYear: myContext.financialYear,
          },
        });
        dispatch({
          type: "GET_ALL_OPTIONS", //get all option id's and description like {option_id: "t1000",options: "I do not have this opportunity in my BOB"}
          payload: {
            activePeriod: myContext.activePeriod,
            financialYear: myContext.financialYear,
            tokens: idToken,
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {
        myContext.currentPeriod === myConsts.TimePeriodYearEnd &&
          assessmentMetaData && assessmentMetaData.data &&
          assessmentMetaData.data.data && assessmentMetaData.data.data.assessmentMetaData &&
          assessmentMetaData.data.data.assessmentMetaData.leadReviewer_DiscussionGuide_CompletionDate &&
          new Date(assessmentMetaData.data.data.assessmentMetaData.leadReviewer_DiscussionGuide_CompletionDate)
          >= new Date() ? (
          <></>
        )
          :
          (
            <NoAccess {...props} />
          )
      }

      {loader && <Loader />}
      <div className={"discussion-guide-list"}>
        <div style={{ width: 58, float: "left" }}>
          <SideMenu
            minimized={true}
            sideMenuItemSelect="Home"
            isminimizedMainMenu={true}
            isMainMenu={true}
          />
        </div>
        <div style={{ width: "100%" }}>
          <div
            className="second-header"
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              marginLeft: "55px",
            }}>
            <Breadcrumb crumbs={crumbs} isRouteFromDiscussionGuide={true} {...props} />
            {/* <div className="pillar-filters">
              <DashBoardFilters
                filterOptions={ppmdStatusFilters}
                onApplyFilter={(filters) => applystatusFilters(filters)}
              />
            </div> */}
          </div>
        </div>
        {/* <div className="admin-content">
          <AdminGeneralParam history={props.history} />
        </div> */}
        <SimpleBar className="scroll-discussion-guide" style={{ maxHeight: "100vh" }}>
          <div className="discussion-guide-list-inner">
            {ppmdStatusFilters &&
              counsellesList &&
              counsellesList.length > 0 &&
              counsellesList
                .filter((x) => x.status && isFilteredStatus(x.status))
                .map((item, index) => {
                  return (
                    <DiscussionGuideCard
                      userData={item}
                      history={props.history}
                      id={index}
                      key={index}
                    //radioOptionChoice={props.location.state && props.location.state.radioOptionChoice}
                    />
                    // <SimpleBar className="discussion-guide-container">
                    //   <div className="header">
                    //     {/* {initialMode === "Edit" && <h4>Edit User Details</h4>} */}
                    //     <span>{`FY ${myContext.financialYear}, ${myContext.currentPeriod}`}</span>
                    //   </div>
                    //   {
                    //     <>
                    //       {
                    //         <div className="demographic-details">
                    //           <h6>What progress have you made on the focus statements above?</h6>
                    //           <Grid container spacing={2}>
                    //             <Grid item md={2}>
                    //               <TextField
                    //                 //label="GPN*"
                    //                 variant="filled"
                    //                 className="input-control"
                    //                 //value={formValues.gpn.value}
                    //                 inputProps={{ maxLength: 20 }}
                    //               />
                    //             </Grid>
                    //           </Grid>
                    //           <h6>What additional accomplishments/activities would you like to discuss not readily apparent above or in your feedback?</h6>
                    //           <Grid container spacing={2}>
                    //             <Grid item md={2}>
                    //               <TextField
                    //                 //label="GPN*"
                    //                 variant="filled"
                    //                 className="input-control"
                    //                 //value={formValues.gpn.value}
                    //                 inputProps={{ maxLength: 20 }}
                    //               />
                    //             </Grid>
                    //           </Grid>
                    //         </div>
                    //       }
                    //     </>
                    //   }
                    // </SimpleBar>
                  );
                })}
            {ppmdStatusFilters &&
              counsellesList &&
              counsellesList.length === 0 && (
                <div className="no-data">No PPMD profiles found.</div>
              )}
          </div>
        </SimpleBar>
      </div>
    </>
  );
};

export default DiscussionGuideList;
