import React, { useContext } from "react";
import { Box } from "@material-ui/core";
import AppContext from "../../utils/appContext";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as myConsts from "../../constants/constants";

const QualityGoal = React.memo((props) => {
  const myContext = useContext(AppContext);
  const [isExpandGoal, setExpansionGoal] = useState(false);
  const [order, setOrder] = useState([]);
  const allSectionDataFetch = useSelector(
    (state) => state.getAssessmentSectionList
  );
  const currentPeriod = myContext.currentPeriod;
  const assessmentSectionList = allSectionDataFetch
    ? allSectionDataFetch.data
      ? allSectionDataFetch.data.data
        ? allSectionDataFetch.data.data.assessmentDetails
        : []
      : []
    : [];
  const { tabObj, tabIndex, totalTabs, selectedSection } = props;
  const getBgColor = (name) => {
    // if (name === "Table stakes") return "stake";
    // else if (name === "Leading") return "leading";
    // else if (name === "Transformative") return "trans";
    // else return "";
    const sectionData = assessmentSectionList.find(
      (list) => list.name === name
    );
    return sectionData ? sectionData.color : "";
  };

  useEffect(() => {
    if (
      allSectionDataFetch &&
      !allSectionDataFetch.fetching &&
      allSectionDataFetch.data &&
      allSectionDataFetch.data.data &&
      allSectionDataFetch.data.data.assessmentDetails
    ) {
      console.log(allSectionDataFetch.data.data.assessmentDetails);
      let temp = [];
      allSectionDataFetch.data.data.assessmentDetails.forEach((item) => {
        temp.push(item.name);
      });
      setOrder(temp);
      console.log(temp);
    }
  }, [allSectionDataFetch]);

  return (
    <div
      className="start-section quality-goal"
      style={isExpandGoal ? { padding: "20px 0px" } : {}}>
      <div className="txt-content">
        <div className="goal-expand-collapse">
          <div className="txt-header">
            {selectedSection.pillarDisplayName}:{" "}
            {selectedSection.tabs[tabIndex - 1]} ({tabIndex} of {totalTabs})
          </div>
          {!isExpandGoal ? (
            <i
              className="icon-minus"
              onClick={() => setExpansionGoal(!isExpandGoal)}></i>
          ) : (
            <i
              className="icon-plus"
              onClick={() => setExpansionGoal(!isExpandGoal)}></i>
          )}
        </div>
        {!isExpandGoal && (
          <div>
            <span style={{ fontSize: "13px" }}>
              <p>
                <b> {selectedSection.tabs[tabIndex - 1]}</b>:{" "}
                {tabObj.goalDescription}
              </p>
              <p style={{ fontSize: "13px" }}> {tabObj.goalRemark}</p>
            </span>
            <Box
              display="flex"
              fontSize="12.5px"
              fontFamily="EYInterState-Regular"
              flexDirection="column">
              {order.length > 0 &&
                tabObj.sectionValues &&
                tabObj.sectionValues
                  .sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name))
                  .map((section, i) => (
                    <Box
                      key={i}
                      display="flex"
                      alignItems="center"
                      marginRight="50px"
                      marginBottom="5px">
                      <>
                        {section && section.color ? (
                          <div
                            className="dv-size"
                            style={{ backgroundColor: section.color }}></div>
                        ) : (
                          <div
                            className={`dv-size`}
                            style={{
                              backgroundColor: ` ${getBgColor(section.name)}`,
                            }}></div>
                        )}
                      </>
                      {/* <div 
                      className={`dv-size ${getBgColor(section.name)}`}></div>style={{ backgroundColor : section.color}}
                    <div> */}
                      <strong>{section.name} - </strong>
                      {section.description}
                    </Box>
                  ))}
            </Box>
            <div className="notes">
              {`Note: You have an option to select one “focus” leading/transformative activity within each goal.  These “focus” activities are populated in the ${currentPeriod === myConsts.TimePeriodYearEnd ? currentPeriod.toLowerCase() : currentPeriod?.replace("-", "").toLowerCase()} discussion guide to highlight and/or further discuss with your LEAD reviewer during your Feedback conversation.  Table stake activities are not eligible for selection as these are considered baseline activities that every PPMD should be performing if applicable.`}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default QualityGoal;
