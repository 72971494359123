const env = process.env.REACT_APP_ENV;

export const appConfig = {
  api: {
    networkInterface: {
      development: "https://ctpdev-lead4d.ey.com",
      qa: window.location.origin,
      staging: window.location.origin,
      production: window.location.origin,
    }[env],
  },
  msal: {
    clientId: {
      development: "3aa7ea64-2214-4f70-b7e4-6ffeb35941bc",
      qa: "119b25cb-878a-4ea3-84b0-7d199bf5b393",
      staging: "c860aa73-936a-4540-a125-7a2cc5259604",
      production: "fecb730f-91cd-49e2-8b1a-69bdad76dddb",
    }[env],
  },
  paans: {
    url: {
      development: "https://uat.paans.sbp.eyclienthub.com",
      qa: "https://uat.paans.sbp.eyclienthub.com",
      staging: "https://uat.paans.sbp.eyclienthub.com",
      production: "https://prod.paans.sbp.eyclienthub.com",
    }[env],
    authorization: {
      development:
        "Basic NDQ2NTYzNzItNzZCMC00RjIyLUFDQTMtMzJEQzI5NDU0RThDOjRDQkYyOUJCLTNDMDctNDcxOS1BRkIwLUVEQjJCNDkyQ0VBMw==",
      qa: "Basic NDQ2NTYzNzItNzZCMC00RjIyLUFDQTMtMzJEQzI5NDU0RThDOjRDQkYyOUJCLTNDMDctNDcxOS1BRkIwLUVEQjJCNDkyQ0VBMw==",
      staging:
        "Basic NDQ2NTYzNzItNzZCMC00RjIyLUFDQTMtMzJEQzI5NDU0RThDOjRDQkYyOUJCLTNDMDctNDcxOS1BRkIwLUVEQjJCNDkyQ0VBMw==",
      production:
        "Basic M0UxMDlCOTctQUQ0NC00QTk2LUFFMjQtMDUyODk3NTk2QUJEOjEzM0ExNEUyLUVGRTQtNDJDMy04QTEyLUJFMzIzMzVFRjBBNA==",
    }[env],
    appId: {
      development: "44656372-76B0-4F22-ACA3-32DC29454E8C",
      qa: "44656372-76B0-4F22-ACA3-32DC29454E8C",
      staging: "44656372-76B0-4F22-ACA3-32DC29454E8C",
      production: "3E109B97-AD44-4A96-AE24-052897596ABD",
    }[env],
    secret: {
      development: "4CBF29BB-3C07-4719-AFB0-EDB2B492CEA3",
      qa: "4CBF29BB-3C07-4719-AFB0-EDB2B492CEA3",
      staging: "4CBF29BB-3C07-4719-AFB0-EDB2B492CEA3",
      production: "133A14E2-EFE4-42C3-8A12-BE32335EF0A4",
    }[env],
    redirectUrl: {
      development: "https://ctpdev-lead4d.ey.com",
      qa: "https://ctpuat2-lead4d.ey.com",
      staging: "https://ctpuat-lead4d.ey.com",
      production: "https://lead4d.ey.com",
    }[env],
    policyType: {
      development: 2,
      qa: 3,
      staging: 1,
      production: 1,
    }[env],
  },
  appInsightKey: {
    development: "84feb981-cb7d-4ffc-b036-e627557c34d9",
    qa: "28b33d6b-ca07-4cdd-b857-e3ee120c1d95",
    staging: "fc3cccb6-f335-421e-b3f9-2e135f65e458",
    production: "8e7a09ef-7a29-4580-86af-58e3186426ee",
  }[env]
};

export default appConfig;
