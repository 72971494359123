import React, { useState, useEffect } from "react";
import { Modal } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import AddSectionForm from "./AddSectionForm";
import CopyForm from "./CopyForm";
import {
  authProvider,
  APIscope,
} from "./../../../../Authentication/authProvider";
import { useDispatch } from "react-redux";
import "./AdminPopup.scss";
import GeneralSectionForm from "./GeneralSectionForm";
import { EDIT_GENERAL_SECTION } from "../../../../actions/types";

const AdminEditPopup = (props) => {
  const [enableSave, setEnableSave] = useState(false);
  const [values, setValues] = useState({});
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const [isChangeDone, setIsChangeDone] = useState(false);
  const [showPopupOutside, setShowPopupOutside] = useState(false);

  


  const closePopup = () => {
    props.cancelPopup();
  };
  useEffect(() => {
    if (props.data) setData(props.data);
  }, [props.data]);

  const validateValue = (values, errorMessages) => {
    if (props.formType === 1) {
      if (
        values.sectionName === "" ||
        values.sectionDescription === "" ||
        values.color === "" ||
        values.sectionName === "white" ||
        values.multiplierScore === "" ||
        values.multiplierScore === "" ||
        errorMessages.color !== "" ||
        errorMessages.sectionName !== ""
      )
        setEnableSave(false);
      else {
        setEnableSave(true);
        setValues(values);
      }
    } else if (props.formType === 2) {
      if (values.length > 0) {
        setEnableSave(true);
        setValues(values);
      } else setEnableSave(false);
    } else if (props.formType === 3) {
      if (
        values.sectionName === "" ||
        values.radioButtonMultiplierScore === "" ||
        values.checkBoxMultiplierScore === "" ||
        values.dropDownMultiplierScore === "" ||
        errorMessages.sectionName !== "" ||
        errorMessages.radioButtonMultiplierScore !== "" ||
        errorMessages.checkBoxMultiplierScore !== "" ||
        errorMessages.dropDownMultiplierScore !== ""
      )
        setEnableSave(false);
      else {
        setEnableSave(true);
        setValues(values);
      }
    }
  };
  const onSave = async () => {
    // console.log(values)
    let temp = {};
    if (props.formType === 1) {
      temp.score = values.multiplierScore.replace("x", "0");
      temp.color = values.color;
      temp.name = values.sectionName;
      temp._id = values._id;
      temp.description = values.sectionDescription;
      temp.financialYear = props.fyYear.replace("FY ", "");
      temp.existingName = values.existingName
      temp.displayOrder = values.displayOrder
      //add section
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "EDIT_ASSESSMENT_SECTION", //add
          payload: {
            token: idToken,
            data: temp,
          },
        });
      } catch (e) {
        console.error(e);
      }
    } else if (props.formType === 3) {
      const score = {}
      score.drop_down = values.dropDownMultiplierScore.replace("x", "");
      score.radio_button = values.radioButtonMultiplierScore.replace("x", "");
      score.check_box = values.checkBoxMultiplierScore.replace("x", "");
      temp.financialYear = props.fyYear.replace("FY ", "");
      temp.data = score;
      dispatch({
        type: EDIT_GENERAL_SECTION,
        payload: {
          data: temp,
        },
      });
    }
    props.cancelPopup();
  };
  const checkUpdateStatus = ()=>{
    if(isChangeDone){
      setShowPopupOutside(true);

    }else{
      closePopup()
    }
  }
  return (
    <>
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.showPopup}
      onClose={(e) => {
        if(props.formType === 1 || props.formType === 3){
          checkUpdateStatus()
        }
        else{
          closePopup()
        }
      }}
      hideBackdrop={false}
      disableEscapeKeyDown={true}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
      <div
        className={
          "AdminPopup " +
          (props.formType === 0
            ? " addNewGoal"
            : (props.formType === 1 || props.formType === 3)
              ? " addNewSection"
              : " copyGoal")
        }>
        <div className="AdminHeader">
          <div>{props.title}</div>
          <i className="icon-close closeIcon" onClick={(e) =>{
              if(props.formType === 1 || props.formType === 3){
                checkUpdateStatus()
              }
              else{
                closePopup()
              }
            }} />
        </div>
        {props.formType === 1 && (
          <AddSectionForm
            passValues={(value, errormessages) =>
              validateValue(value, errormessages)
            }
            uniquelist={props.uniquelist}
            data={data}
            isChangeDone={(value)=>setIsChangeDone(value)}
          />
        )}
        {props.formType === 2 && (
          <CopyForm
            passValues={(value) => validateValue(value)}
            getPrevAssesmentData={props.getPrevAssesmentData}
            fyYear={props.fyYear}
            prevAssessmentData={props.prevAssessmentData}
          />
        )}
        {props.formType === 3 && (
          <GeneralSectionForm
            passValues={(value, errormessages) =>
              validateValue(value, errormessages)
            }
            uniquelist={props.uniquelist}
            data={data}
            isChangeDone={(value)=>setIsChangeDone(value)}
          />
        )}

        <div className="AdminActions">
          <>
            <div className="cancel" onClick={() => {
              if(props.formType === 1 || props.formType === 3){
                checkUpdateStatus()
              }
              else{
                closePopup()
              }
            }}>
              Cancel
            </div>
            {props.formType === 2 ? (
              <div
                className={"save" + (enableSave ? "" : " disable-btn ")}
                onClick={() => {
                  if (enableSave) onSave();
                }}>
                Copy
              </div>
            ) : (
              <div
                className={"save" + (enableSave && isChangeDone ? "" : " disable-btn ")}
                onClick={() => {
                  if (enableSave && isChangeDone) onSave();
                }}>
                Update
              </div>
            )}
          </>
        </div>
      </div>
    </Modal>
    {showPopupOutside &&
    <div className="popup-dialog1">
    <div className="dialog">
      <div className="header">
        <h5>Confirmation</h5>
        <span
          className="icon-close-filled icon"
          onClick={()=>{
            setShowPopupOutside(false)
          }}
        ></span>
      </div>
      <div className="content">
        Would you like to close without completing the operation?
        Please note that on confirming, changes will be lost
      </div>
      <div className="footer">
        <button className="cancel-btn" onClick={()=>{
            setShowPopupOutside(false);
          }}>
          Cancel
        </button>
        <button className="confirm-btn" onClick={()=>{
            setIsChangeDone(false);
            setShowPopupOutside(false);
            closePopup();
          }}>
          Confirm
        </button>
      </div>
    </div>
    </div>
    }
    </>
  );
};
export default withRouter(AdminEditPopup);
