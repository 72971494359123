import React, { useCallback, useState, useMemo, useEffect } from "react";
//import ReactExport from "react-data-export";
import _ from "lodash";

const DownloadTemplate = (props) => {
  const { rowCount, colCount, searchedList, radioChoice } = props;
  // const ExcelFile = ReactExport.ExcelFile;
  // const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const [templateData, setTemplateData] = useState([]);

  const contentBorder = useMemo(() => {
    return {
      top: { style: "medium", color: { rgb: "FF000000" } },
      bottom: { style: "medium", color: { rgb: "FF000000" } },
      left: { style: "medium", color: { rgb: "FF000000" } },
      right: { style: "medium", color: { rgb: "FF000000" } },
    };
  }, []);
  const contentStyle = useMemo(() => {
    return {
      font: { name: "EYInterstate", sz: "8" },
      alignment: {
        wrapText: true,
        horizontal: "left",
        vertical: "center",
      },
      border: contentBorder,
    };
  }, [contentBorder]);

  const headers = ["ClientId",
    "Client",
    "EngagementRegion",
    "AccountSector",
    "ANSR_FYTD",
    "Margin_FYTD",
    "TER_FYTD",
    "ANSR_PFYTD",
    "Margin_PFYTD",
    "TER_PFYTD",
    "ANSR_PFY",
    "Margin_PFY",
    "TER_PFY",
    "SECIndicator",
    "EngagementSubServiceLine",
    "EngagementCountry",
    "EngagementCompanyCd",
    "RevenueGoal($)",
    "RegionMarginTarget(%)"];

  const templateHeaders = useMemo(
    () => [
      {
        label: "ClientId",
        width: 100,
      },
      {
        label: "Client",
        width: 100,
      },
      {
        label: "EngagementRegion",
        width: 150,
      },
      {
        label: "AccountSector",
        width: 200,
      },
      {
        label: "ANSR_FYTD",
        width: 200,
      },
      {
        label: "Margin_FYTD",
        width: 200,
      },
      {
        label: "TER_FYTD",
        width: 200,
      },
      {
        label: "ANSR_PFYTD",
        width: 200,
      },
      {
        label: "Margin_PFYTD",
        width: 200,
      },
      {
        label: "TER_PFYTD",
        width: 200,
      },
      {
        label: "ANSR_PFY",
        width: 200,
      },
      {
        label: "Margin_PFY",
        width: 200,
      },
      {
        label: "TER_PFY",
        width: 200,
      },
      {
        label: "SECIndicator",
        width: 100,
      },
      {
        label: "EngagementSubServiceLine",
        width: 200,
      },
      {
        label: "EngagementCountry",
        width: 200,
      },
      {
        label: "EngagementCompanyCd",
        width: 200,
      },
      {
        label: "RevenueGoal($)",
        width: 150,
      },
      {
        label: "RegionMarginTarget(%)",
        width: 150,
      }
    ],
    []
  );
  const proccessRegionalData = useCallback(() => {
    let template = [
      {
        columns: [],
        data: [],
      },
    ];
    // if (radioChoice === "new") {
    //   templateHeaders.push({
    //     label: "Revenue Goal ($)",
    //     width: 150,
    //   });
    //   templateHeaders.push({
    //     label: "Region Margin Target %",
    //     width: 150,
    //   });
    // }
    templateHeaders.forEach((data, index) => {
      template[0].columns.push({
        title: data.label,
        width: { wpx: data.width },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFBDD7EE" } },
          font: { name: "EYInterstate", sz: "11", bold: true },
          border: contentBorder,
          alignment: {
            wrapText: true,
            horizontal: "left",
            vertical: "center",
          },
        },
      });
    });

    for (var i = 0; i < rowCount; i++) {
      const excelData = [];
      for (var j = 0; j < colCount; j++) {
        let rowObject = {
          value: "",
          style: contentStyle,
        };
        if (searchedList && !_.isEmpty(searchedList)) {
          if (headers[j] === "ClientId") {
            rowObject.value = `${searchedList[i][headers[j]] === null ? "" : searchedList[i][headers[j]]}`;
          } else {            
            rowObject.value = searchedList[i][headers[j]] === null ? "" : searchedList[i][headers[j]];
          }
        }
        if (radioChoice === "new") {
          if (headers[j] === "EngagementSubServiceLine") {
            rowObject.value = "Audit";
          }
          else if (headers[j] === "EngagementCountry") {
            rowObject.value = "USA";
          }
          else if (headers[j] === "EngagementCompanyCd") {
            rowObject.value = "US01";
          }
          // else if (headers[j] === "EngagementRegion") {
          //   rowObject.value = ['FSO Americas', 'US-East', 'US-Central', 'US-West'].toString();
          //   //rowObject.value = () => { return ['FSO Americas', 'US-East', 'US-Central', 'US-West'].toString(); };
          // }
          // else if (headers[j] === "AccountSector") {
          //   // rowObject.value = ["Advanced Manufacturing & Mobility", "Government & Public Sector", 
          //   // "Health Sciences & Wellness","Technology, Media & Entertainment, Telecommunications",
          //   // "Real Estate, Hospitality & Construction","To be Determined"];
          // }
        }
        excelData.push(rowObject);
      }
      template[0].data.push(excelData);
    }
    setTemplateData(template);
  }, [templateHeaders]);

  useEffect(() => {

    proccessRegionalData();
  }, [proccessRegionalData, contentBorder]);
  return (
    // <ExcelFile
    //   element={
    //     <button
    //       disabled={props.disableDownloadBtn}
    //       className={props.disableDownloadBtn ? "disable-element" : ""}>
    //       <span className="eyaa-arrow-tab-down"></span>
    //       <span>Download Template</span>
    //     </button>
    //   }
    //   filename="Add Client Data_Template"
    // >
    //   <ExcelSheet
    //     dataSet={templateData}
    //     name="Add Client Data_Template"
    //   />
    // </ExcelFile>
    <></>
  );
};
export default DownloadTemplate;