import { useEffect, useState, useContext, useCallback } from "react";
import "./TimePeriod.scss";
import { authProvider, APIscope } from "../../Authentication/authProvider";
import { useDispatch, useSelector } from "react-redux";
import AppContext from "../../utils/appContext";
import moment from "moment";
import classNames from "classnames";
import {
  Box,
  LinearProgress,
  makeStyles,
  Typography,
  Grid,
  Tooltip
} from "@material-ui/core";
import ProgressChart from "../progressChart/progressChart";
import * as myConsts from "../../constants/constants";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: 12,
    border: "1px solid #D8D8D8",
  },
  bgColor: {
    backgroundColor: "transparent",
  },
  filledColor: {
    backgroundColor: "#188CE5",
  },
  font: {
    fontSize: 13,
  },
  tooltip: {
    fontSize: 10,
  }
});

const TimePeriod = (props) => {
  const classes = useStyles();
  const {
    userAssessmentProgress,
    userAssessmentStatusData,
    assessmentMetaDetails,
    getCurrentUser,
    setLoader,
    activePPMDuserId,
    userDiscussionGuideData,
    isImpersonateUser
  } = props;
  const dispatch = useDispatch();
  const userBobSummary = useSelector((state) => state.userBobSummary);
  const myContext = useContext(AppContext);
  const [bobExpand, setBobExpand] = useState(false);
  const [dashboardExpand, setDashboardExpand] = useState(false);
  const [approvalExpand, setApprovalExpand] = useState(false);
  const [userBobSummaryData, setUserBobSummaryData] = useState(null);
  const reviewerData = useSelector((state) => state.getReviewerHomePageData);
  const [reviewerPPMDCount, setReviewerPPMDCount] = useState(null);
  const userProfile = useSelector((state) => state.userProfile);
  const [userPhaseFlag, setUserPhaseFlag] = useState({});

  const navigateToBookOfBusiness = () => {
    props.history.push(myConsts.routeBookOfBusiness);
  };

  const navigateToDashboard = useCallback(() => {
    props.history.push(myConsts.routeDashBoard);
  }, []);

  const pillarChartData = useSelector((state) => state.userAssesmentScore);
  const isValidDate = (date) => {
    return moment(date).isValid() ? date : "Not Available";
  };

  useEffect(() => {
    //debugger;
    if (userProfile && userProfile.data && userProfile.data.length) {
      const flag = userProfile.data.find((item) => item.financialYear === myContext.financialYear);
      if (flag) {
        setUserPhaseFlag(flag.phaseFlag);
      } else {
        setUserPhaseFlag({
          "Plan": false,
          "Mid-Year": false,
          "Year-End": false
        });
      }
    }
  }, [userProfile]);

  useEffect(() => {
    (async function () {
      try {
        setLoader(true);
        setBobExpand(false);
        setDashboardExpand(false);
        setApprovalExpand(false);
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        if (!getCurrentUser()) {
          return;
        }
        dispatch({
          type: "GET_USER_BOB_SUMMARY",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
            userId: getCurrentUser(),
          },
        });
        dispatch({
          type: "GET_USER_ASSESSMENT_STATUS",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
            userId: getCurrentUser(),
          },
        });
        dispatch({
          type: "GET_USER_ASSESSMENT_SCORE",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
            userId: getCurrentUser(),
          },
        });
        dispatch({
          type: "GET_REVIEWER_HOMEPAGE_DATA",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [props.location.pathname, myContext.currentPeriod]);

  const getRouteBobTextOnStatus = () => {
    if (!myContext.formsInActive) {
      if (
        (myContext.bobStatus === myConsts.statusNotStarted ||
          myContext.bobStatus === myConsts.statusInProgress ||
          myContext.bobStatus === myConsts.statusRequestedChange) &&
        !myContext.formsInActive
      ) {
        return "Validate Book of Business";
      } else if (
        myContext.bobStatus === myConsts.statusSubmitted ||
        myContext.bobStatus === myConsts.statusCompleted ||
        myContext.formsInActive
      ) {
        return "View Book of Business";
      } else return "View Book of Business";
    } else return "View Book of Business";
  };

  const getRouteTextOnStatus = () => {
    if (!myContext.formsInActive) {
      if (
        myContext.bobStatus === myConsts.statusNotStarted &&
        !myContext.formsInActive
      ) {
        return "Complete Assessment";
      } else if (
        (myContext.assessmentStatus === myConsts.statusNotStarted ||
          myContext.assessmentStatus === myConsts.statusInProgress) &&
        !myContext.formsInActive
      ) {
        return "Complete Assessment";
      } else if (
        myContext.assessmentStatus === myConsts.statusSubmitted ||
        myContext.formsInActive
      ) {
        return "View Assessment";
      } else if (
        myContext.assessmentStatus === myConsts.statusRequestedChange
      ) {
        return "Edit Assessment";
      } else if (myContext.assessmentStatus === myConsts.statusCompleted) {
        return "View Assessment"; //Approved";
      } else return "View Assessment";
    } else return "View Assessment";
  };
  useEffect(() => {
    if (userBobSummary && userBobSummary.data) {
      const data = userBobSummary.data;
      setTimeout(function () {
        setLoader(false);
      }, 800);
      if (data && data.status) {
        setUserBobSummaryData(data);
        myContext.setBobStatus(data.status.toLowerCase());
      } else {
        setUserBobSummaryData(data.message);
        myContext.setBobStatus("");
      }
    }
  }, [userBobSummary]);

  useEffect(() => {
    if (reviewerData && reviewerData.data) {
      const data = reviewerData.data;
      setReviewerPPMDCount(data);
    }
  }, [reviewerData]);

  const isChartsVisible = useCallback(() => {
    return (
      userAssessmentProgress === 100 &&
      userAssessmentStatusData &&
      (userAssessmentStatusData.status.toLowerCase() ===
        myConsts.statusSubmitted ||
        userAssessmentStatusData.status.toLowerCase() ===
        myConsts.statusCompleted)
    );
  }, [userAssessmentProgress, userAssessmentStatusData]);

  const twoDigitsNum = (myNumber) => {
    return myNumber
      ? myNumber.toString().length < 2
        ? "0" + myNumber
        : myNumber
      : "0";
  };

  return (
    userAssessmentStatusData && (
      <div
        className="mid-year"
        style={
          activePPMDuserId && activePPMDuserId !== null
            ? { marginBottom: "11%" }
            : {}
        }>
        {/* ..DISABLE SELF-ASSESSMENT FY24_YE :: COMMENTED BELOW CODE IF IT WILL REQUIRE TO ENABLE IN FUTURE */}
        {/* {(myContext.userRole !== myConsts.rolesLeadReviewer ||
          (activePPMDuserId && activePPMDuserId !== null)) &&
          userPhaseFlag[myContext.currentPeriod] ? (
          <>
            <div
              className="mid-yr-padding flx-box"
              onClick={() => setBobExpand(!bobExpand)}>
              <div>
                {activePPMDuserId && activePPMDuserId !== null ? "" : "My"}{" "} */}
        {/* ..DISABLE BOB :: COMMENTED BELOW CODE IF IT WILL REQUIRE TO ENABLE IN FUTURE */}
        {/* Book of Business Validation & Assessment */}
        {/* {`${myContext.currentPeriod === myConsts.TimePeriodYearEnd && !isImpersonateUser ? "Self Assessment & Discussion Guide" : "Self Assessment"}`}
              </div>
              {bobExpand ? (
                <i
                  className="icon-minus"
                  onClick={() => setBobExpand(!bobExpand)}></i>
              ) : (
                <i
                  className="icon-plus"
                  onClick={() => setBobExpand(!bobExpand)}></i>
              )}
            </div>
            {bobExpand && (
              <div className="mid-yr-padding mid-year-expanded "> */}
        {/* ..DISABLE BOB :: COMMENTED BELOW CODE IF IT WILL REQUIRE TO ENABLE IN FUTURE */}
        {/* <div className="mid-year-card bob-card">
                    <div className="title">
                      Book of Business Validation & PPMD Profile
                    </div>
                    {userBobSummaryData === "No data found" ? (
                      <div className="dash-no-data">No data to display</div>
                    ) : (
                      <div className="bob-mid-sec">
                        <i className="icon-email-37"></i>
                        <div className="bob-mid-right-limit-width">
                          <div>
                            <div className="bob-mid-no">
                              {userBobSummaryData &&
                                userBobSummaryData.noOfClients}
                            </div>
                            <div className="bob-mid-light">No Of Clients</div>
                          </div>
                          <div>
                            <div className="bob-mid-head">Status:</div>
                            <div className="bob-mid-light">
                              {(userBobSummaryData &&
                                (userBobSummaryData.status.toLowerCase() ===
                                myConsts.statusCompleted
                                  ? "Approved"
                                  : userBobSummaryData.status)) ||
                                ""}
                            </div>
                          </div>
                          <div>
                            <div>
                              {userBobSummaryData &&
                                userBobSummaryData.status &&
                                (userBobSummaryData.status.toLowerCase() ===
                                  myConsts.statusNotStarted ||
                                userBobSummaryData.status.toLowerCase() ===
                                  myConsts.statusInProgress
                                  ? "Complete By"
                                  : userBobSummaryData.status.toLowerCase() ===
                                    myConsts.statusSubmitted
                                  ? "Submitted On"
                                  : userBobSummaryData.status.toLowerCase() ===
                                    myConsts.statusCompleted
                                  ? "Approved On"
                                  : "Requested on")}
                              :
                            </div>
                            <div className="bob-mid-light">
                              {userBobSummaryData &&
                                userBobSummaryData.status &&
                                isValidDate(
                                  moment(
                                    userBobSummaryData.status.toLowerCase() ===
                                      myConsts.statusSubmitted
                                      ? userBobSummaryData.submittedByDate
                                      : userBobSummaryData.status.toLowerCase() ===
                                        myConsts.statusCompleted
                                      ? userBobSummaryData.approvedByDate
                                      : userBobSummaryData.status.toLowerCase() ===
                                        myConsts.statusRequestedChange
                                      ? userAssessmentStatusData.assessmentEndDate
                                      : userBobSummaryData.completeByDate
                                  ).format("DD-MMM-YYYY")
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="butn-container">
                      <div
                        onClick={(e) =>
                          userBobSummaryData !== "No data found" &&
                          navigateToBookOfBusiness()
                        }
                        className={
                          userBobSummaryData === "No data found"
                            ? "butn grey-color disable-color"
                            : "butn yellow-color"
                        }>
                        {getRouteBobTextOnStatus()}
                      </div>
                    </div>
                  </div> */}
        {/* <div className="mid-year-card bob-card">
                  <div className="title">Assessment</div>
                  {userAssessmentStatusData &&
                    userAssessmentStatusData.status.toLowerCase() ===
                    "no data" ? (
                    <div className="dash-no-data">No data to display</div>
                  ) : (
                    <>
                      <Box
                        display="flex"
                        alignItems="center"
                        padding="0 18px">
                        <Box minWidth={35}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            className={
                              classes.font
                            }>{`${userAssessmentProgress}%`}</Typography>
                        </Box>
                        <Box width="100%" mr={1}>
                          <LinearProgress
                            classes={{
                              root: classes.root,
                              colorPrimary: classes.bgColor,
                              barColorPrimary: classes.filledColor,
                            }}
                            variant="determinate"
                            value={userAssessmentProgress}
                          />
                        </Box>
                      </Box>
                      <div className="assess-mid">
                        <div className="assess-left-prt">
                          <div className="bob-mid-head">Status:</div>
                          <div className="bob-mid-light">
                            {(userAssessmentStatusData !== null &&
                              userAssessmentStatusData.status.toLowerCase() ===
                              myConsts.statusCompleted
                              ? "Approved"
                              : userAssessmentStatusData.status) || ""}
                          </div>
                        </div>
                        <div>
                          <div>
                            {userAssessmentStatusData !== null &&
                              (userAssessmentStatusData.status.toLowerCase() ===
                                myConsts.statusNotStarted ||
                                userAssessmentStatusData.status.toLowerCase() ===
                                myConsts.statusInProgress
                                ? "Complete By"
                                : userAssessmentStatusData.status.toLowerCase() ===
                                  myConsts.statusSubmitted
                                  ? "Submitted On"
                                  : userAssessmentStatusData.status.toLowerCase() ===
                                    myConsts.statusCompleted
                                    ? "Approved On"
                                    : "Requested on")}
                            :
                          </div>
                          <div className="bob-mid-light">
                            {assessmentMetaDetails &&
                              userAssessmentStatusData !== null &&
                              isValidDate(
                                moment(
                                  userAssessmentStatusData.status.toLowerCase() ===
                                    myConsts.statusNotStarted ||
                                    userAssessmentStatusData.status.toLowerCase() ===
                                    myConsts.statusInProgress
                                    ? userAssessmentStatusData.metaEndDate
                                    : userAssessmentStatusData.assessmentEndDate
                                ).format("DD-MMM-YYYY")
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="assess-butn-container">
                    {userAssessmentStatusData !== null &&
                      userAssessmentStatusData.status.toLowerCase() !==
                      "no data" && (
                        <div
                          className="text-link"
                          onClick={() => {
                            if (
                              userAssessmentProgress === 100 &&
                              (myContext.bobStatus ===
                                myConsts.statusSubmitted ||
                                myContext.bobStatus ===
                                myConsts.statusCompleted)
                            ) {
                              myContext.setNavToSummary(true);
                              props.navigateToAssessment();
                            }
                          }}
                          style={
                            userAssessmentProgress === 100 &&
                              (myContext.bobStatus ===
                                myConsts.statusSubmitted ||
                                myContext.bobStatus ===
                                myConsts.statusCompleted)
                              ? { cursor: "pointer" }
                              : { cursor: "not-allowed" }
                          }>
                          View Summary
                        </div>
                      )}

                    {userAssessmentStatusData !== null &&
                      userAssessmentStatusData.status.toLowerCase() !==
                      "no data" ? (
                      <div
                        onClick={(e) =>
                          myContext.formsInActive
                            ? props.navigateToAssessment()
                            : myContext.bobStatus ===
                              myConsts.statusSubmitted ||
                              myContext.bobStatus ===
                              myConsts.statusCompleted ||
                              userAssessmentStatusData.status.toLowerCase() ===
                              myConsts.statusCompleted
                              ? props.navigateToAssessment()
                              : ""
                        }
                        className={
                          myContext.formsInActive
                            ? "butn yellow-color"
                            : getRouteTextOnStatus().toLowerCase() ===
                              "Approved" ||
                              getRouteBobTextOnStatus().toLowerCase() ===
                              "Validate Book of Business" ||
                              myContext.bobStatus ===
                              myConsts.statusNotStarted ||
                              myContext.bobStatus ===
                              myConsts.statusInProgress ||
                              userBobSummaryData == "No data found" ||
                              myContext.bobStatus ===
                              myConsts.statusRequestedChange
                              ? "butn grey-color disable-color"
                              : "butn yellow-color"
                        }>
                        {getRouteTextOnStatus()}{" "}
                      </div>
                    ) : (
                      <div className="butn grey-color disable-color">
                        {getRouteTextOnStatus()}{" "}
                      </div>
                    )}
                  </div>
                </div>
                {
                  myContext.currentPeriod === myConsts.TimePeriodYearEnd && !isImpersonateUser && (
                    <div className="mid-year-card">
                      <div className="title">Year-End Discussion Guide</div>
                      <>
                        <Box
                          display="flex"
                          alignItems="center"
                          padding="0 18px">
                          <Box minWidth={35}>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              className={
                                classes.font
                              }>{`${userDiscussionGuideData && userDiscussionGuideData.progress ? userDiscussionGuideData.progress : 0}%`}</Typography>
                          </Box>
                          <Box width="100%" mr={1}>
                            <LinearProgress
                              classes={{
                                root: classes.root,
                                colorPrimary: classes.bgColor,
                                barColorPrimary: classes.filledColor,
                              }}
                              variant="determinate"
                              value={userDiscussionGuideData && userDiscussionGuideData.progress ? userDiscussionGuideData.progress : 0}
                            />
                          </Box>
                        </Box>
                        <div className="assess-mid">
                          <div className="assess-left-prt">
                            <div className="bob-mid-head">Status:</div>
                            <div className="bob-mid-light">
                              {userDiscussionGuideData !== null &&
                                userDiscussionGuideData.status ? userDiscussionGuideData.status : "Not Started"}
                            </div>
                          </div>
                          <div>
                            <div>
                              Submitted On:
                            </div>
                            <div className="bob-mid-light">
                              {
                                userDiscussionGuideData !== null &&
                                userDiscussionGuideData.status &&
                                userDiscussionGuideData.status.toLowerCase() === myConsts.statusSubmitted &&
                                isValidDate(moment(userDiscussionGuideData.submittedDate).format("DD-MMM-YYYY"))
                              }
                            </div>
                          </div>
                        </div>
                      </>

                      <div className="assess-butn-container">
                        <div
                          onClick={(e) => {
                            props.history.push(myConsts.routeSelfDiscussionGuide);
                          }}
                          className="butn yellow-color">
                          {"Discussion Guide"}{" "}
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            )} */}
        {/* ..DISABLE BOB :: COMMENTED BELOW CODE IF IT WILL REQUIRE TO ENABLE IN FUTURE */}

        {/* <div
                className="mid-yr-padding flx-box"
                // PPMD SCORING HIDDEN 
                // onClick={() => setDashboardExpand(!dashboardExpand)}
                onClick={() => {
                  isChartsVisible() && navigateToDashboard();
                }}
                >
                <div>
                  {activePPMDuserId && activePPMDuserId !== null ? "" : "My"}{" "}
                  Dashboard
                </div>
                {dashboardExpand ? (
                  <i
                    className="icon-minus"
                    onClick={() => setDashboardExpand(!dashboardExpand)}></i>
                ) : (
                  <i
                    className="icon-plus"
                    onClick={() => setDashboardExpand(!dashboardExpand)}></i>
                )}
              </div>
              {dashboardExpand && (
                <div className="mid-yr-padding mid-year-expanded">
                  <div className="mid-year-card dashboard-card">
                    <div className="title">Dashboard</div>
                    {!isChartsVisible() ? (
                      <div className="dash-no-data">No data to display</div>
                    ) : (
                      pillarChartData &&
                      pillarChartData.data.length > 0 && (
                        <Grid container>
                          <Grid item lg={4}>
                            <ProgressChart
                              pillarName={pillarChartData.data[0].pillar}
                              pillarScore={pillarChartData.data[0].pillarScore}
                              radius={30}
                              isTitleBottomVisible={true}
                              isScoreTextVisible={false}
                              fillSize={9}
                            />
                          </Grid>
                          <Grid item lg={4}>
                            <ProgressChart
                              pillarName={
                                pillarChartData.data[1].pillarDisplayName
                              }
                              pillarScore={pillarChartData.data[1].pillarScore}
                              radius={30}
                              isTitleBottomVisible={true}
                              isScoreTextVisible={false}
                              fillSize={9}
                            />
                          </Grid>
                          <Grid item lg={4}>
                            <div
                              className="moreItems"
                              onClick={() => {
                                isChartsVisible() && navigateToDashboard();
                              }}>
                              +
                              {myContext.currentPeriod ===
                              myConsts.TimePeriodPlan
                                ? 2
                                : 4}{" "}
                              more
                            </div>
                          </Grid>
                        </Grid>
                      )
                    )}
                    <div className="butn-container">
                      <div
                        onClick={() => {
                          isChartsVisible() && navigateToDashboard();
                        }}
                        className={classNames("butn", {
                          "yellow-color": isChartsVisible(),
                          "grey-color disable-btn": !isChartsVisible(),
                        })}>
                        View All
                      </div>
                    </div>
                  </div>
                </div>
              )} 
          </>*/}
        {/* ) : (
          <div className="mid-yr-padding warning-box">
            You do not have access to this phase. Please reach out to lead4d.support@ey.com.
          </div>
        )} */}

        {/* ..DISABLE SELF-ASSESSMENT FY24_YE :: BELOW MESSAGE SHOULD DISPLAY IF PPMD OR LR-PPMD TRY TO ACCESS SELF ASSESSMENT DASHBOARD */}
        {/* {
          myContext.userRole === myConsts.rolesPPMD ? (
            <div className="mid-yr-padding warning-box">
              You do not have access to this phase. Please reach out to lead4d.support@ey.com.
            </div>
          )
            :
            (
            
          )
        } */}


        {myContext.userRole !== myConsts.rolesPPMD &&
          !(activePPMDuserId && activePPMDuserId !== null) &&
          userPhaseFlag[myContext.currentPeriod] ? ( // hide for ppmd profile by lead reviewer
          <>
            <div
              className="mid-yr-padding flx-box"
              onClick={() => setApprovalExpand(!approvalExpand)}>
              <div>
                {activePPMDuserId && activePPMDuserId !== null ? "" : "My"}{" "}
                {`${myContext.currentPeriod === myConsts.TimePeriodYearEnd ? "Counselee’s Finalization Form Commentary" : "Counselee’s Approvals"}`}
              </div>
              {approvalExpand ? (
                <i
                  className="icon-minus"
                  onClick={() => setApprovalExpand(!approvalExpand)}></i>
              ) : (
                <i
                  className="icon-plus"
                  onClick={() => setApprovalExpand(!approvalExpand)}></i>
              )}
            </div>
            {approvalExpand && Object.keys(reviewerPPMDCount).length > 0 && (
              <div className="mid-yr-padding mid-year-expanded">
                <div className="mid-year-card" style={{ width: 510 }}>
                  <div className="title">List of PPMD</div>
                  <div className="aprvl-sec">
                    {/* <div className="aprvl-mid-sec">
                      <i className="icon-user-52"></i>
                      <div className="bob-mid-right">
                        <div>
                          <div className="bob-mid-no">
                            {twoDigitsNum(reviewerPPMDCount.totalCounselees)}
                          </div>
                          <div className="bob-mid-light">PPMD</div>
                        </div>
                      </div>
                    </div>
                    <div className="aprvl-mid-sec">
                      <i className="eyaa-file-24"></i>
                      <div className="bob-mid-right">
                        <div>
                          <div className="bob-mid-no in-draft">
                            {twoDigitsNum(reviewerPPMDCount["in draft"])}
                          </div>
                          <div className="bob-mid-light">In Draft</div>
                        </div>
                      </div>
                    </div>
                    <div className="aprvl-mid-sec">
                      <i className="eyaa-file-33"></i>
                      <div className="bob-mid-right">
                        <div>
                          <div className="bob-mid-no pending-approval">
                            {twoDigitsNum(reviewerPPMDCount.open)}
                          </div>
                          <div className="bob-mid-light">
                            Pending Approval
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="aprvl-mid-sec">
                      <i className="eyaa-file-23"></i>
                      <div className="bob-mid-right">
                        <div>
                          <div className="bob-mid-no approved">
                            {twoDigitsNum(reviewerPPMDCount.closed)}
                          </div>
                          <div className="bob-mid-light">Approved</div>
                        </div>
                      </div>
                    </div>
                    <div className="aprvl-mid-sec">
                      <i className="eyaa-folder-110"></i>
                      <div className="bob-mid-right">
                        <div>
                          <div className="bob-mid-no request-change">
                            {twoDigitsNum(reviewerPPMDCount["req change"])}
                          </div>
                          <div className="bob-mid-light">
                            Requested Change
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="aprvl-mid-sec">
                      <i className="icon-user-52"></i>
                      <div className="bob-mid-right">
                        <div>
                          <div className="bob-mid-no">
                            {twoDigitsNum(reviewerPPMDCount.totalCounselees)}
                          </div>
                          <div className="bob-mid-light">PPMD</div>
                        </div>
                      </div>
                    </div>
                    <div className="aprvl-mid-sec">
                      <i className="eyaa-file-24"></i>
                      <div className="bob-mid-right">
                        <div>
                          <div className="bob-mid-no in-draft">
                            {twoDigitsNum(reviewerPPMDCount.statusLRNotStarted)}
                          </div>
                          <div className="bob-mid-light">Not Started</div>
                        </div>
                      </div>
                    </div>
                    <div className="aprvl-mid-sec">
                      <i className="eyaa-file-33"></i>
                      <div className="bob-mid-right">
                        <div>
                          <div className="bob-mid-no pending-approval">
                            {twoDigitsNum(reviewerPPMDCount.statusLRInProgress)}
                          </div>
                          <div className="bob-mid-light">
                            In Progress
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="aprvl-mid-sec">
                      <i className="eyaa-file-23"></i>
                      <div className="bob-mid-right">
                        <div>
                          <div className="bob-mid-no approved">
                            {twoDigitsNum(reviewerPPMDCount.statusLRSubmitted)}
                          </div>
                          <div className="bob-mid-light">Submitted</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {!reviewerPPMDCount["lock date"] ? (
                    <div className="aprvl-cmp">
                      <div>Complete By:</div>
                      <div className="bob-mid-light">
                        {assessmentMetaDetails &&
                          moment(reviewerPPMDCount["lock date"]).format(
                            "DD-MMM-YYYY"
                          )}
                      </div>
                    </div>
                  ) : null} */}
                  <div className="aprvl-cmp">
                    <div>Complete By:</div>
                    <div className="bob-mid-light">
                      {assessmentMetaDetails &&
                        moment(reviewerPPMDCount.metaLRLockDate).format(
                          "DD-MMM-YYYY"
                        )}
                    </div>
                  </div>
                  <div className="butn-container">
                    {/* <div
                        onClick={(e) => {
                          props.history.push(myConsts.routePpmdProfiles);
                        }}
                        className="butn yellow-color"
                        style={{ position: "unset" }}>
                        View PPMD profiles
                      </div> */}
                    {/* {
                        myContext.currentPeriod === myConsts.TimePeriodYearEnd && (
                          assessmentMetaDetails.leadReviewer_DiscussionGuide_CompletionDate &&
                            new Date(assessmentMetaDetails.leadReviewer_DiscussionGuide_CompletionDate)
                            >= new Date() ? (
                            <div
                              onClick={(e) => {
                                props.history.push(myConsts.routeDiscussionGuide);
                              }}
                              className="butn yellow-color"
                              style={{ position: "unset" }}>
                              Discussion Guide
                            </div>
                          )
                            :
                            <>
                              <div
                                className="butn grey-color disable-color"
                                style={{ position: "unset" }}>
                                Discussion Guide
                              </div>
                              {                                
                                userProfile && userProfile.data && userProfile.data.length > 0 &&
                                ( ((userProfile.data[0].rankCode === 13 || userProfile.data[0].rankCode === "13") && //..few records holding integer type rank code
                                  assessmentMetaDetails.finalCommentary_MD_StartDate
                                  && assessmentMetaDetails.finalCommentary_MD_EndDate
                                  && new Date(assessmentMetaDetails.finalCommentary_MD_StartDate) <= new Date()
                                  && new Date(assessmentMetaDetails.finalCommentary_MD_EndDate) >= new Date()) || (
                                    (userProfile.data[0].rankCode === 11 || userProfile.data[0].rankCode === "11") &&
                                    assessmentMetaDetails.finalCommentary_Partner_StartDate
                                    && assessmentMetaDetails.finalCommentary_Partner_EndDate
                                    && new Date(assessmentMetaDetails.finalCommentary_Partner_StartDate) <= new Date()
                                    && new Date(assessmentMetaDetails.finalCommentary_Partner_EndDate) >= new Date()
                                  ) ) && (
                                  <>
                                    <Tooltip
                                      classes={{ tooltip: classes.tooltip }}
                                      title={`This information should be entered into SuccessFactors`}
                                      placement="top">
                                      <div
                                        onClick={(e) => {
                                          props.history.push(myConsts.routeFinalCommentary);
                                        }}
                                        className="butn yellow-color"
                                        style={{ position: "unset" }}>
                                        FINAL Finalization Form Commentary
                                      </div>
                                    </Tooltip>
                                  </>
                                )
                              }
                            </>
                        )
                      } */}
                    {
                      myContext.currentPeriod === myConsts.TimePeriodYearEnd && (

                        myContext.currentPeriod === myConsts.TimePeriodYearEnd &&
                          assessmentMetaDetails.leadReviewer_DiscussionGuide_CompletionDate &&
                          new Date(assessmentMetaDetails.leadReviewer_DiscussionGuide_CompletionDate)
                          >= new Date() ? (
                          <div
                            onClick={(e) => {
                              props.history.push(myConsts.routeDiscussionGuide);
                            }}
                            className="butn yellow-color"
                            style={{ position: "unset" }}>
                            Discussion Guide
                          </div>
                        )
                          :
                          (
                            <>
                              {/* <div
                                className="butn grey-color disable-color"
                                style={{ position: "unset" }}>
                                Discussion Guide
                              </div> */}
                              <Tooltip
                                classes={{ tooltip: classes.tooltip }}
                                title={`This information should be entered into SuccessFactors`}
                                placement="top">
                                <div
                                  onClick={(e) => {
                                    props.history.push(myConsts.routeFinalCommentary);
                                  }}
                                  className="butn yellow-color"
                                  style={{ position: "unset" }}>
                                  FINAL Finalization Form Commentary
                                </div>
                              </Tooltip>
                            </>
                          )

                      )
                    }
                  </div>
                </div>
              </div>
            )}
          </>
        )
          :
          (
            <div className="mid-yr-padding warning-box">
              You do not have access to this phase. Please reach out to lead4d.support@ey.com.
            </div>
          )
        }
      </div>
    )
  );
};

export default TimePeriod;
