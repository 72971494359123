import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import { customAxios as axios, REQ_TIMEOUT } from "./customAxios";
import * as apis from "../constants/constants";
import * as types from "../actions/types";
import {
  authProvider,
  GraphExplorerscopes,
} from "./../Authentication/authProvider";
//import { getEYFinancialYear } from "../utils/utils";

async function getData(url, options) {
  const idtoken = await authProvider.acquireTokenSilent({
    scopes: GraphExplorerscopes,
  });
  const token = idtoken.accessToken;
  options.headers.Authorization = "Bearer " + token;
  return fetch(url, options)
    .then((response) => response.json())
    .then((res) => res);
}

export function getCurrentUser() {
  return sessionStorage.activePPMDuserId || sessionStorage.userId;
}

function backendCall(method, headers, url, data, timeout) {
  return axios({ method, url, headers, data, timeout }).then((response) => {
    return response.data;
  });
}

function* callwrapperSaga(method, headers, url, data, timeout = REQ_TIMEOUT) {
  try {
    return yield call(backendCall, method, headers, url, data, timeout);
  } catch (error) {
    if (
      error.response.headers["content-type"] &&
      error.response.headers["content-type"].includes("text/html") &&
      error.response.data &&
      error.response.data.includes("Unavailable")
    ) {
      yield put({
        type: types.MAINTENANCE_ERROR,
      });
    }
    yield put({
      type: types.API_SERVER_ERROR_CODE,
      payload: error.response.status,
    });
    return error;
  }
}
async function backendCallBlob(options, url) {
  const idtoken = await authProvider.acquireTokenSilent({
    scopes: GraphExplorerscopes,
  });
  const token = idtoken.accessToken;
  options.headers.Authorization = "Bearer " + token;
  return fetch(url, options)
    .then(async (response) => {
      // if (response.status === 200) return response.blob();
      // else throw new Error("Network response was not ok.");

      if (response.status === 200 && !response.bodyUsed) return response;
    })
    .then(async (response) => {
      const l = await response.blob();
      // console.log(l);
      if (l.size > 0) {
        let href = window.URL.createObjectURL(l);
        return href;
      }
    });
}

function* watcherGetAllAssesmentData() {
  yield takeLatest(types.GET_ALL_ASSESSMENT_DATA, workerGetAllAssesmentData);
}
function* watcherGetAssesmentSectionList() {
  yield takeLatest(
    types.GET_ASSESSMENT_SECTION_LIST,
    workerGetAllAssesmentSection
  );
}
function* watcherGetDeleteSection() {
  yield takeLatest(types.DELETE_ASSESSMENT_SECTION, workerDeleteSection);
}
function* watcherGetEditSection() {
  yield takeLatest(types.EDIT_ASSESSMENT_SECTION, workerEditSection);
}

function* watcherGetAllPrevAssesmentData() {
  yield takeLatest(
    types.GET_ALL_PREV_ASSESSMENT_DATA,
    workerGetAllPrevAssesmentData
  );
}

function* watcherGetAllUserAssesmentData() {
  yield takeLatest(
    types.GET_ALL_USER_ASSESSMENT_DATA,
    workerGetAllUserAssesmentData
  );
}

function* watcherSetUserAssesmentData() {
  yield takeLatest(types.SAVE_USER_ASSESSMENT_DATA, workerSetUserAssesmentData);
}

function* watcherGetUserDetails() {
  yield takeLatest(types.GET_USER_DETAILS, workerGetUserDetails);
}

function* watcherGetAdminSearchUserDetails() {
  yield takeLatest(
    types.GET_ADMIN_SEARCH_USER_DETAILS,
    workerGetAdminSearchUserDetails
  );
}

function* watcherGetAdminSearchManagerDetails() {
  yield takeLatest(
    types.GET_ADMIN_SEARCH_MANAGER_DETAILS,
    workerGetAdminSearchManagerDetails
  );
}

function* watcherSearchUserData() {
  yield takeLatest(types.SEARCH_USER_DATA, workerSearchUserData);
}

function* watcherSearchUserMatchData() {
  yield takeLatest(types.SEARCH_USER_DATA_MATCH, workerSearchUserDataMatch);
}

function* watcherGetUserImg() {
  yield takeLatest(types.GET_USER_IMG, workerGetUserImg);
}

function* watcheGetOptionValues() {
  yield takeLatest(types.GET_OPTION_VALUES, workerGetOptionValues);
}

function* watcherGetAssessmentMetaData() {
  yield takeLatest(types.GET_ASSESSMENT_META_DATA, workerGetAssessmentMetaData);
}

function* watcherGetValidationAssessmentMetaData() {
  yield takeLatest(
    types.GET_VALIDATION_ASSESSMENT_META_DATA,
    workerGetValidationAssessmentMetaData
  );
}

function* watcherSetAssessmentStart() {
  yield takeLatest(types.MARK_ASSESSMENT_START, workerSetAssessmentStart);
}

function* watcherGetUserProfile() {
  yield takeLatest(types.GET_USER_PROFILE_BY_ID, workerGetUserProfile);
}
function* watcherGetAllOptions() {
  yield takeLatest(types.GET_ALL_OPTIONS, workerGetAllOptions);
}

function* watcherSubmitUserAssessment() {
  yield takeLatest(types.SUBMIT_USER_ASSESSMENT, workerSubmitUserAssessment);
}

function* watcherGetBobRollforwardStatus() {
  yield takeLatest(
    types.GET_BOB_ROLLFORWARD_STATUS,
    workerGetBobRollforwardStatus
  );
}

function* watcherGetBobRollforwardToNextPhase() {
  yield takeLatest(
    types.ROLLFORWARD_BOB_TO_NEXT_PHASE,
    workerGetBobRollforwardToNextPhase
  );
}

function* watcherProgressStatusFromBob() {
  yield takeLatest(types.PROGRESS_STATUS_FROM_BOB, workerProgressStatusFromBob);
}

function* watcherBobAdminActionStatus() {
  yield takeLatest(types.BOB_ADMIN_ACTION_STATUS, workerBobAdminActionStatus);
}

function* watcherGetUserAssessmentStatus() {
  yield takeLatest(
    types.GET_USER_ASSESSMENT_STATUS,
    workerGetUserAssessmentStatus
  );
}

function* watcherGetUserBookOfBusinessSummary() {
  yield takeLatest(
    types.GET_USER_BOB_SUMMARY,
    workerGetUserBookOfBusinessSummary
  );
}

function* watcherGetUserAssesmentScore() {
  yield takeLatest(
    types.GET_USER_ASSESSMENT_SCORE,
    workerGetUserAssesmentScore
  );
}

function* watcherGetUserGoalScore() {
  yield takeLatest(types.GET_USER_GOAL_SCORE, workerGetUserGoalScore);
}

function* watcherGetPillarGroupScore() {
  yield takeLatest(types.GET_PILLAR_GROUP_SCORE, workerGetPillarGroupScore);
}

function* watcherGetGoalGroupScore() {
  yield takeLatest(types.GET_GOAL_GROUP_SCORE, workerGetGoalGroupScore);
}

function* watcherGetQuantitativeScore() {
  yield takeLatest(types.GET_QUANTITATIVE_SCORE, workerGetQuantitativeScore);
}

function* watcherGetBobData() {
  yield takeLatest(types.GET_BOB_DATA, workerGetBobData);
}

function* watcherUpdateBobData() {
  yield takeLatest(types.UPDATE_BOB_DATA, workerUpdateBobData);
}

function* watcherGetResponsibilityAndSectors() {
  yield takeLatest(
    types.GET_RESPONSIBILITY_SECTORS,
    workerGetResponsibilityAndSectors
  );
}

function* watcherGetCounseleesStatus() {
  yield takeLatest(types.GET_COUNSELEES_STATUS, workerGetCounseleesStatus);
}

function* watcherGetReviewerHomePageData() {
  yield takeLatest(
    types.GET_REVIEWER_HOMEPAGE_DATA,
    workerGetReviewerHomePageData
  );
}

function* watcherAcceptOrRejectCounselee() {
  yield takeLatest(
    types.APPROVE_OR_REJECT_COUNSELEE,
    workerAcceptOrRejectCounselee
  );
}

function* watcherCommentRead() {
  yield takeLatest(types.COMMENT_READ, workerCommentRead);
}
function* watcherGetGeneralParms() {
  yield takeLatest(types.GET_GENERAL_PARAMETERS, workerGetGeneralParam);
}
function* watcherEditGeneralParms() {
  yield takeLatest(types.EDIT_GENERAL_PARAMETERS, workerEditGeneralParam);
}
function* watcherSearchUser() {
  yield takeLatest(types.SEARCH_USER, workerSearchUser);
}

function* watcherResetUser() {
  yield takeLatest(types.RESET_USER, workerResetUser);
}

function* watcherGetReportsData() {
  yield takeLatest(types.GET_REPORTS_DATA, workerGetReportsData);
}

function* watcherGetExcelReportsData() {
  yield takeLatest(types.GET_EXCEL_REPORTS_DATA, workerGetExcelReportsData);
}

function* watcherSearchClient() {
  yield takeLatest(types.SEARCH_CLIENT, workerSearchClient);
}

function* watcherSetUserUUID() {
  yield takeLatest(types.SET_UUID_FOR_USER, workerSetUserUUID);
}

function* watcherGetTimePeriodStatus() {
  yield takeLatest(types.GET_TIME_PERIOD_STATUS, workerGetTimePeriodStatus);
}
function* watcherGetUserCount() {
  yield takeLatest(types.GET_USER_COUNT, workerGetUserCount);
}

function* watcherRollForwardUser() {
  yield takeLatest(types.ROLL_FORWARD_USER, workerRollForwardUsers);
}

function* watcherValidAssessmentUsers() {
  yield takeLatest(types.VALID_ASSESSMENT_YEARS, workerValidAssessmentUsers);
}

function* watcherCheckLockStatus() {
  yield takeLatest(types.CHECK_LOCK_STATUS, workerAdminCheckLockStatus);
}
function* watcherAddMultipleUser() {
  yield takeLatest(types.ADD_MULTIPLE_USER, workerAddMultipleUsers);
}
function* watcherBulkUploadDiscussionGuide() {
  yield takeLatest(types.BULK_UPLOAD_DISCUSSION_GUIDE, workerBulkUploadDiscussionGuide);
}
function* watcherAddSingleUser() {
  yield takeLatest(types.ADD_SINGLE_USER, workerAddSingleUser);
}
function* watcherEditSingleUser() {
  yield takeLatest(types.EDIT_SINGLE_USER, workerEditSingleUser);
}
function* watcherDeleteSingleUser() {
  yield takeLatest(types.DELETE_SINGLE_USER, workerDeleteSingleUser);
}
function* watcherGetAdminConfig() {
  yield takeLatest(types.GET_ADMIN_MASTER_CONFIG, workerGetAdminConfig);
}
function* watcherAddGlobalMetric() {
  yield takeLatest(types.ADD_GLOBAL_METRIC, workerAddGlobalMetric);
}
function* watcherEditGlobalMetric() {
  yield takeLatest(types.EDIT_GLOBAL_METRIC, workerEditGlobalMetric);
}
function* watcherDeleteGlobalMetric() {
  yield takeLatest(types.DELETE_GLOBAL_METRIC, workerDeleteGlobalMetric);
}
function* watcherReOrderGlobalMetric() {
  yield takeLatest(types.REORDER_GLOBAL_METRIC, workerReOrderGlobalMetric);
}
function* watcherReOrderAssessmentSection() {
  yield takeLatest(
    types.REORDER_ASSESSMENT_SECTION,
    workerReOrderAssessmentSection
  );
}
function* watcherRollForwardAssessment() {
  yield takeLatest(types.ROLL_FORWARD_ASSESSMENT, workerRollForwardAssessment);
}
function* watcherMercuryFileUpload() {
  yield takeLatest(types.MERCURY_FILE_UPLOAD, workerMercuryFileUpload);
}
function* watcherGetAssesmentGoal() {
  yield takeLatest(types.GET_ASSESSMENT_GOAL, workerGetAssesmentGoal);
}
function* watcherAddAssesmentGoal() {
  yield takeLatest(types.ADD_ASSESSMENT_GOAL, workerAddAssesmentGoal);
}
function* watcherEditAssesmentGoal() {
  yield takeLatest(types.EDIT_ASSESSMENT_GOAL, workerEditAssesmentGoal);
}
function* watcherDeleteAssesmentGoal() {
  yield takeLatest(types.DELETE_ASSESSMENT_GOAL, workerDeleteAssesmentGoal);
}
function* watcherReorderAssesmentGoal() {
  yield takeLatest(types.REORDER_ASSESSMENT_GOAL, workerReorderAssesmentGoal);
}

function* watcherGetAssessmentStatus() {
  yield takeLatest(types.GET_ASSESSMENT_STATUS, workerGetAssessmentStatus);
}
function* watcherGetValidateUserChangesData() {
  yield takeLatest(
    types.VALIDATE_USER_CHANGES,
    workerGetValidateUserChangesData
  );
}
function* watcherGetUserDataChanges() {
  yield takeLatest(types.GET_USER_DATA_CHANGES, workerGetUserDataChanges);
}
function* watcherUpdateUserDetails() {
  yield takeLatest(types.UPDATE_USER_DETAILS, workerUpdateUserDetails);
}
function* watcherDeleteUsers() {
  yield takeLatest(types.DELETE_USERS, workerDeleteUsers);
}
function* watcherGetPhaseValidationStatus() {
  yield takeLatest(
    types.GET_PHASE_VALIDATION_STATUS,
    workerGetPhaseValidationStatus
  );
}

function* watcherUploadAssessmentContent() {
  yield takeLatest(
    types.UPLOAD_ASSESSMENT_CONTENT,
    workerUploadAssessmentContent
  );
}
function* watcherUploadRegionalData() {
  yield takeLatest(types.REGIONAL_DATA_UPLOAD, workerUploadRegionalData);
}
function* watcherGenerateBookOfBusiness() {
  yield takeLatest(
    types.GENERATE_BOOK_OF_BUSINESS,
    workerGenerateBookOfBusiness
  );
}

function* watcherAddImpactStatement() {
  yield takeLatest(types.ADD_IMPACT_STATEMENT, workerAddImpactStatement);
}

function* watcherMoveImpactStatement() {
  yield takeLatest(types.MOVE_IMPACT_STATEMENT, workerMoveImpactStatement);
}

function* watcherDeleteImpactStatement() {
  yield takeLatest(types.DELETE_IMPACT_STATEMENT, workerDeleteImpactStatement);
}

function* watcherSortImpactStatement() {
  yield takeLatest(types.SORT_IMPACT_STATEMENT, workerSortImpactStatement);
}

function* watcherGetEmailTemplate() {
  yield takeLatest(types.GET_EMAIL_TEMPLATE, workerGetEmailTemplate);
}

function* watcherEditEmailTemplate() {
  yield takeLatest(types.EDIT_EMAIL_TEMPLATE, workerEditEmailTemplate);
}

function* watcherGetListMails() {
  yield takeLatest(types.GET_LIST_MAILS, workerGetListMails);
}

function* watcherCancelMailRequest() {
  yield takeLatest(types.CANCEL_MAIL_REQUEST, workerCancelMailRequest);
}

function* watcherSendAdhocMail() {
  yield takeLatest(types.SEND_ADHOC_MAIL, workerSendAdhocMail);
}

function* watcherGetGeneralSection() {
  yield takeLatest(types.GET_GENERAL_SECTION, workerGetGeneralSection);
}

function* watcherGetCompletedPhase() {
  yield takeLatest(types.GET_COMPLETED_PHASE, workerGetCompletedPhase);
}

function* watcherEditGeneralSection() {
  yield takeLatest(types.EDIT_GENERAL_SECTION, workerEditGeneralSection);
}

function* watcherGetInvalidAssessmentData() {
  yield takeLatest(
    types.GET_INVALID_ASSESSMENT_DATA,
    workerGetInvalidAssessmentData
  );
}

function* watcherAdminAssessmentSubmission() {
  yield takeLatest(types.ADMIN_SUBMIT_ASSESSMENT, workerSubmitAdminAssessment);
}
function* watcherGetApprovalRequests() {
  yield takeLatest(types.GET_APPROVAL_REQUEST, workerGetApprovalRequests);
}

function* watcherGetAssessmentApprovalRequests() {
  yield takeLatest(
    types.GET_ASSESSMENT_APPROVAL_LIST,
    workerGetAssessmentApprovalRequests
  );
}

function* watcherSendAdminApproval() {
  yield takeLatest(types.SEND_ADMIN_REVIEW, workerValidateContentRequest);
}

function* watcherGetChangedData() {
  yield takeLatest(types.GET_HIGHLIGHT_DATA, workerGetChangedData);
}

function* watcherGetRequestCount() {
  yield takeLatest(types.GET_PENDING_APPROVAL_COUNT, workerGetRequestCount);
}

function* watcherGetReportFilter() {
  yield takeLatest(types.GET_REPORT_FILTER, workerGetReportFilter);
}

function* watcherGetAdditionalReportData() {
  yield takeLatest(
    types.GET_ADDITIONAL_REPORT_DATA,
    workerGetAdditionalReportData
  );
}

function* watcherExportAdditionalReport() {
  yield takeLatest(
    types.EXPORT_ADDITIONAL_REPORT,
    workerExportAdditionalReport
  );
}

function* watcherGetPowerBILink() {
  yield takeLatest(types.GET_POWERBI_LINK, workerGetPowerBILink);
}
function* watcherUpdatePowerBILink() {
  yield takeLatest(types.UPDATE_POWERBI_LINK, workerUpdatePowerBILink);
}

function* watcherGetUserStatusAssessment() {
  yield takeLatest(
    types.GET_USER_STATUS_ASSESSMENT,
    workerGetUserStatusAssessment
  );
}

function* watcherExportEntireAssessmentContentReport() {
  yield takeLatest(
    types.EXPORT_ENTIRE_ASSESSMENT_CONTENT,
    workerExportEntireAssessmentContentReport
  );
}
function* watcherGetApprovedCompletedYearsList() {
  yield takeLatest(types.GET_YEARS_LIST, workerGetApprovedCompletedYearsList);
}
function* watcherUploadGeneralContent() {
  yield takeLatest(types.UPLOAD_GENERAL_CONTENT, workerUploadGeneralContent);
}

function* watcherGetGeneralContent() {
  yield takeLatest(types.GET_GENERAL_CONTENT, workerGetGeneralContent);
}
//..Discussion Guide
function* watcherGetCounseleesFinalizationDetails() {
  yield takeLatest(types.GET_COUNSELEES_FINALIZATION_DETAILS, workerGetCounseleesFinalizationDetails);
}
function* watcherAddOrEditCounseleesFinalizationDetails() {
  yield takeLatest(types.ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS, workerAddOrEditCounseleesFinalizationDetails);
}

function* watcherUpdateIsRecordedInSuccessFactors() {
  yield takeLatest(types.UPDATE_IS_RECORDED_IN_SUCCESS_FACTORS, workerUpdateIsRecordedInSuccessFactors);
}

function* watcherGetCounseleeDiscussionGuide() {
  yield takeLatest(types.GET_COUNSELEE_DISCUSSION_GUIDE, workerGetCounseleeDiscussionGuide);
}

function* watcherAddOrUpdateCounseleeDiscussionGuide() {
  yield takeLatest(types.ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE, workerAddOrUpdateCounseleeDiscussionGuide);
}

function* watcherSearchDiscussionGuide() {
  yield takeLatest(types.SEARCH_DISCUSSION_GUIDE, workerSearchDiscussionGuide);
}

function* watcherResetDiscussionGuide() {
  yield takeLatest(types.RESET_DISCUSSION_GUIDE, workerResetDiscussionGuide);
}


function* workerGetResponsibilityAndSectors(params) {
  try {
    const url = `${apis.getResponsibiltyAndSectors}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_RESPONSIBILITY_SECTORS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_RESPONSIBILITY_SECTORS_ERROR, payload: e });
    yield put({
      type: types.API_SERVER_ERROR_CODE,
      payload: e.response.status,
    });
  }
}

function* workerGetBobData(params) {
  try {
    const url = `${apis.getBobData}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const data = {
      financialYear: params.payload.financialYear,
      timePeriod: params.payload.timePeriod,
      userId: getCurrentUser(),
    };
    const payload = yield call(callwrapperSaga, "POST", headers, url, data);
    yield put({ type: types.GET_BOB_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_BOB_DATA_ERROR, payload: e });
  }
}

function* workerUpdateBobData(params) {
  const { payloadObj } = params.payload;
  payloadObj.userId = sessionStorage.userId;
  try {
    const url = `${apis.updateBobData}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const data = {
      ...payloadObj,
    };

    const payload = yield call(callwrapperSaga, "PUT", headers, url, data);
    yield put({ type: types.UPDATE_BOB_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.UPDATE_BOB_DATA_ERROR, payload: e });
  }
}

function* workerGetUserAssesmentScore(params) {
  const { userId } = params.payload;
  try {
    const url = `${apis.getPillarScore}/${params.payload.financialYear}/${params.payload.timePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
      requestuserid: userId || getCurrentUser(),
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_USER_ASSESSMENT_SCORE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_USER_ASSESSMENT_SCORE_ERROR, payload: e });
  }
}

function* workerProgressStatusFromBob(params) {
  try {
    const url = `${apis.progressStatusFromBob}/${params.payload.actionTypes}/${params.payload.financialYear}/${params.payload.timePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.PROGRESS_STATUS_FROM_BOB_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.PROGRESS_STATUS_FROM_BOB_ERROR, payload: e });
  }
}

function* workerBobAdminActionStatus(params) {
  // console.log(params.payload);
  try {
    const url = `${apis.bobAdminActionStatus}/${params.payload.financialYear}/${params.payload.timePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.BOB_ADMIN_ACTION_STATUS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.BOB_ADMIN_ACTION_STATUS_ERROR, payload: e });
  }
}

function* workerGetUserGoalScore(params) {
  try {
    const url = `${apis.getGoalScore}/${params.payload.financialYear}/${params.payload.timePeriod}/${params.payload.pillarId}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
      requestuserid: getCurrentUser(),
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_USER_GOAL_SCORE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_USER_GOAL_SCORE_ERROR, payload: e });
  }
}

function* workerGetPillarGroupScore(params) {
  let filters = {};
  params.payload.filters.forEach((filter, index) => {
    if (
      filter.isSelected &&
      filter.paramName !== "SelectAll" &&
      filter.subText !== "NULL" &&
      filter.subText !== "null" &&
      filter.subText !== ""
    ) {
      filters[filter.paramName] = filter.subText;
    }
  });
  const requestBody = {
    timePeriod: params.payload.timePeriod,
    financialYear: params.payload.financialYear,
    filter: filters,
    rankDesc: params.payload.rankDesc,
  };
  try {
    const url = `${apis.getPillarGroupScore}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
      requestuserid: getCurrentUser(),
    };
    const data = requestBody;
    const payload = yield call(callwrapperSaga, "POST", headers, url, data);
    yield put({ type: types.GET_PILLAR_GROUP_SCORE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_PILLAR_GROUP_SCORE_ERROR, payload: e });
  }
}

function* workerGetGoalGroupScore(params) {
  let filters = {};
  params.payload.filters.forEach((filter, index) => {
    if (
      filter.isSelected &&
      filter.paramName !== "SelectAll" &&
      filter.subText !== "NULL" &&
      filter.subText !== "null" &&
      filter.subText !== ""
    ) {
      filters[filter.paramName] = filter.subText;
    }
  });
  const requestBody = {
    timePeriod: params.payload.timePeriod,
    financialYear: params.payload.financialYear,
    pillarId: params.payload.pillarId,
    filter: filters,
    rankDesc: params.payload.rankDesc,
  };
  try {
    const url = `${apis.getGoalGroupScore}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
      requestuserid: getCurrentUser(),
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      requestBody
    );
    yield put({ type: types.GET_GOAL_GROUP_SCORE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_GOAL_GROUP_SCORE_ERROR, payload: e });
  }
}

function* workerGetQuantitativeScore(params) {
  try {
    const url = `${apis.getQuantitativeScore}/${params.payload.financialYear}/${params.payload.timePeriod}/${params.payload.focus}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
      requestuserid: getCurrentUser(),
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url);

    yield put({ type: types.GET_QUANTITATIVE_SCORE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_QUANTITATIVE_SCORE_ERROR, payload: e });
  }
}

function* workerGetAllOptions(params) {
  try {
    const url = `${apis.getAllOptions}/${params.payload.financialYear}/${params.payload.activePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url);

    yield put({ type: types.GET_ALL_OPTIONS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_ALL_OPTIONS_ERROR, payload: e });
  }
}

function* workerGetBobRollforwardStatus() {
  try {
    const url = `${apis.getBobRollForwardStatus}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url);

    yield put({ type: types.GET_BOB_ROLLFORWARD_STATUS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_BOB_ROLLFORWARD_STATUS_ERROR, payload: e });
  }
}

function* workerGetAllAssesmentData(params) {
  const { financialYear, activePeriod } = params.payload;
  try {
    const url = `${apis.getAssesmentData}/${financialYear}/${activePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);

    yield put({ type: types.GET_ALL_ASSESSMENT_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_ALL_ASSESSMENT_DATA_ERROR, payload: e });
  }
}
function* workerGetAllPrevAssesmentData(params) {
  const { financialYear, activePeriod } = params.payload;
  try {
    const url = `${apis.getAssesmentData}/${financialYear}/${activePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);

    yield put({ type: types.GET_ALL_PREV_ASSESSMENT_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_ALL_PREV_ASSESSMENT_DATA_ERROR, payload: e });
  }
}

function* workerGetAllUserAssesmentData(params) {
  const { financialYear, activePeriod, isFromDiscussionGuide, ppmdUserId } = params.payload;
  try {
    const url = `${apis.getUserAssesmentData}/${financialYear}/${activePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
      requestuserid: isFromDiscussionGuide ? ppmdUserId : getCurrentUser(),
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);

    yield put({ type: types.GET_ALL_USER_ASSESSMENT_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_ALL_USER_ASSESSMENT_DATA_ERROR, payload: e });
  }
}

function* workerSetUserAssesmentData(params) {
  const { requestData } = params.payload;
  let requestBody = { ...requestData };
  try {
    let methodUrl =
      "_id" in requestBody
        ? apis.editUserAssesmentData
        : apis.addUserAssesmentData;
    const url = `${methodUrl}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      requestBody
    );

    yield put({ type: types.SAVE_USER_ASSESSMENT_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.SAVE_USER_ASSESSMENT_DATA_ERROR, payload: e });
  }
}

function* workerGetUserDetails(params) {
  try {
    const url = `${apis.msHostName}${apis.getUserDetails + "/" + getCurrentUser()
      }`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        userid: getCurrentUser(),
      },
    };

    const payload = yield call(getData, url, options);
    yield put({ type: types.GET_USER_DETAILS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_USER_DETAILS_ERROR, payload: e });
  }
}

function* workerGetAdminSearchUserDetails(params) {
  try {
    const url = `${apis.msHostName}${apis.getUserDetails + "/" + params.payload.emailId
      }`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        userid: getCurrentUser(),
      },
    };

    const payload = yield call(getData, url, options);
    yield put({ type: types.GET_ADMIN_SEARCH_USER_DETAILS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_ADMIN_SEARCH_USER_DETAILS_ERROR, payload: e });
  }
}

function* workerGetAdminSearchManagerDetails(params) {
  try {
    const url = `${apis.msHostName}${apis.getUserDetails + "/" + params.payload.emailId
      }/manager`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        userid: getCurrentUser(),
      },
    };

    const payload = yield call(getData, url, options);
    yield put({
      type: types.GET_ADMIN_SEARCH_MANAGER_DETAILS_SUCCESS,
      payload,
    });
  } catch (e) {
    yield put({
      type: types.GET_ADMIN_SEARCH_MANAGER_DETAILS_ERROR,
      payload: e,
    });
  }
}

function* workerSearchUserData(params) {
  try {
    const url = `${apis.searchUserData}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const data = {
      financialYear: params.payload.financialYear,
      timePeriod: params.payload.timePeriod,
      searchText: params.payload.searchText,
    };
    const payload = yield call(callwrapperSaga, "POST", headers, url, data);
    yield put({ type: types.SEARCH_USER_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.SEARCH_USER_DATA_ERROR, payload: e });
  }
}

function* workerSearchUserDataMatch(params) {
  try {
    const url = `${apis.searchUserDataMatch}?searchText=${params.payload.searchText}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    let data = {
      financialYear: params.payload.financialYear,
      timePeriod: params.payload.timePeriod
    };
    const payload = yield call(callwrapperSaga, "POST", headers, url, data);
    yield put({ type: types.SEARCH_USER_DATA_MATCH_SUCCESS, payload });
    //..call search user data service
    if (params.payload.isValUserId
      && payload
      && Array.isArray(payload.data)
      && payload.data.length <= 0) {
      data.searchText = params.payload.searchText;
      const payload = yield call(callwrapperSaga, "POST", headers, `${apis.searchUserData}`, data);
      yield put({ type: types.SEARCH_USER_DATA_SUCCESS, payload });
    }
  } catch (e) {
    yield put({ type: types.SEARCH_USER_DATA_MATCH_ERROR, payload: e });
  }
}

function* workerGetUserImg(params) {
  try {
    const url = `${apis.msHostName + "/users/" + sessionStorage.userId + apis.getUserImg
      }`;
    const options = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    const payload = yield call(backendCallBlob, options, url);

    yield put({
      type: types.GET_USER_IMG_SUCCESS,
      payload: {
        href: payload,
        userId: sessionStorage.userId,
      },
    });
  } catch (e) {
    yield put({ type: types.GET_USER_IMG_ERROR, payload: e });
  }
}

function* workerGetOptionValues(params) {
  try {
    const url = `${apis.getOptionValues}/${params.payload.goalId}/${params.payload.uiType}/${params.payload.financialYear}/${params.payload.activePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url);

    yield put({ type: types.GET_OPTION_VALUES_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_OPTION_VALUES_ERROR, payload: e });
  }
}

function* workerGetAssessmentMetaData(params) {
  const { financialYear } = params.payload;
  try {
    const url = `${apis.getAssessmentMetadata}/${financialYear}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url);
    if (payload.response) {
      yield put({
        type: types.API_SERVER_ERROR_CODE,
        payload: payload.response.status,
      });
    }
    yield put({ type: types.GET_ASSESSMENT_META_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_ASSESSMENT_META_DATA_ERROR, payload: e });
    yield put({
      type: types.API_SERVER_ERROR_CODE,
      payload: e.response.status,
    });
  }
}

function* workerGetValidationAssessmentMetaData(params) {
  const { financialYear } = params.payload;
  try {
    const metadataUrl = `${apis.getAssessmentMetadata}/${financialYear}`;
    const rollforwardUrl = `${apis.getBobRollForwardStatus}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const { metadata, rollforwardData } = yield all({
      metadata: call(callwrapperSaga, "GET", headers, metadataUrl),
      rollforwardData: call(callwrapperSaga, "GET", headers, rollforwardUrl),
    });
    yield put({
      type: types.GET_VALIDATION_ASSESSMENT_META_DATA_SUCCESS,
      payload: { metadata, rollforwardData },
    });
  } catch (e) {
    yield put({
      type: types.GET_VALIDATION_ASSESSMENT_META_DATA_ERROR,
      payload: e,
    });
    yield put({
      type: types.API_SERVER_ERROR_CODE,
      payload: e.response.status,
    });
  }
}

function* workerSetAssessmentStart(params) {
  const { financialYear, activePeriod } = params.payload;
  try {
    const url = `${apis.addUserAssessmentStatus}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const data = {
      financialYear: financialYear,
      timePeriod: activePeriod,
    };

    const payload = yield call(callwrapperSaga, "POST", headers, url, data);

    yield put({ type: types.MARK_ASSESSMENT_START_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.MARK_ASSESSMENT_START_ERROR, payload: e });
  }
}

function* workerGetUserProfile(params) {
  const { userId, financialYear } = params.payload;

  try {
    const url = `${apis.getUserProfileById}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const data = {
      userid: userId,
      financialYear: financialYear,
    };
    const payload = yield call(callwrapperSaga, "POST", headers, url, data);

    yield put({ type: types.GET_USER_PROFILE_BY_ID_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_USER_PROFILE_BY_ID_ERROR, payload: e });
  }
}

function* workerGetUserBookOfBusinessSummary(params) {
  const { financialYear, timePeriod, userId } = params.payload;

  try {
    const url = `${apis.getUserBookOfBusinessSummary}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const data = {
      financialYear: financialYear,
      timePeriod: timePeriod,
      userId: userId,
    };
    let payload = yield call(callwrapperSaga, "POST", headers, url, data);
    //..DISABLE BOB :: COMMENTED BELOW line of CODE IF IT WILL REQUIRE TO ENABLE IN FUTURE
    //yield put({ type: types.GET_USER_BOB_SUMMARY_SUCCESS, payload });
    //..BOB Disable :: Below API will invoke when home page load to validate BOB automatically    
    const disableInputs = (payload && payload.status
      && (payload.status.toLowerCase() === "Submitted".toLowerCase() ||
        payload.status.toLowerCase() === "Completed".toLowerCase())) || false;
    if (!disableInputs) {
      try {
        const urlUpdateBOB = `${apis.updateBobData}`;
        const dataUpdateBOB = {
          financialYear: financialYear,
          timePeriod: timePeriod,
          userId: userId,
          status: "Submitted"
        };
        const payloadUpdateBOB = yield call(callwrapperSaga, "PUT", headers, urlUpdateBOB, dataUpdateBOB);
        yield put({ type: types.UPDATE_BOB_DATA_SUCCESS, payload: payloadUpdateBOB });
      } catch (e) {
        yield put({ type: types.UPDATE_BOB_DATA_ERROR, payload: e });
      }
    }
    payload = yield call(callwrapperSaga, "POST", headers, url, data);
    yield put({ type: types.GET_USER_BOB_SUMMARY_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_USER_BOB_SUMMARY_ERROR, payload: e });
  }
}

function* workerSubmitUserAssessment(params) {
  const { payloadObj } = params.payload;
  try {
    const url = `${apis.submitAssessment}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      payloadObj
    );
    yield put({ type: types.SUBMIT_USER_ASSESSMENT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.SUBMIT_USER_ASSESSMENT_ERROR, payload: e });
  }
}

function* workerGetUserAssessmentStatus(params) {
  const { financialYear, timePeriod, userId } = params.payload;

  try {
    const url = `${apis.getUserAssessmentStatus}/${financialYear}/${timePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
      requestuserid: userId || getCurrentUser(),
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_USER_ASSESSMENT_STATUS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_USER_ASSESSMENT_STATUS_ERROR, payload: e });
  }
}

function* workerGetCounseleesStatus(params) {
  const { financialYear, activePeriod } = params.payload;

  try {
    const url = `${apis.getCounselleStatus}/${financialYear}/${activePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_COUNSELEES_STATUS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_COUNSELEES_STATUS_ERROR, payload: e });
  }
}

function* workerGetReviewerHomePageData(params) {
  const { financialYear, timePeriod } = params.payload;

  try {
    const url = `${apis.getReviewerHomePageData}/${financialYear}/${timePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_REVIEWER_HOMEPAGE_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_REVIEWER_HOMEPAGE_DATA_ERROR, payload: e });
  }
}

function* workerAcceptOrRejectCounselee(params) {
  const { payloadObj, approveType } = params.payload;

  try {
    const url = `${approveType ? apis.approveAssessment : apis.rejectAssessment
      }`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      payloadObj
    );
    yield put({
      type: types.APPROVE_OR_REJECT_COUNSELEE_SUCCESS,
      payload: { ...payload, approveType },
    });
  } catch (e) {
    yield put({ type: types.APPROVE_OR_REJECT_COUNSELEE_ERROR, payload: e });
  }
}

function* workerCommentRead(params) {
  const { timePeriod, financialYear } = params.payload;
  try {
    const url = `${apis.readComments}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const data = {
      timePeriod: timePeriod,
      financialYear: financialYear,
    };
    const payload = yield call(callwrapperSaga, "POST", headers, url, data);
    yield put({
      type: types.COMMENT_READ_SUCCESS,
      payload: { payload },
    });
  } catch (e) {
    yield put({ type: types.COMMENT_READ_ERROR, payload: e });
  }
}

function* workerGetBobRollforwardToNextPhase(params) {
  const { financialYear, timePeriod } = params.payload;

  try {
    const url = `${apis.copyToNextPhase}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const data = {
      financialYear: financialYear,
      timePeriod: timePeriod,
    };
    const payload = yield call(callwrapperSaga, "POST", headers, url, data);
    yield put({ type: types.ROLLFORWARD_BOB_TO_NEXT_PHASE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.ROLLFORWARD_BOB_TO_NEXT_PHASE_ERROR, payload: e });
  }
}

function* workerSearchClient(params) {
  const { client, clientId, timePeriod, financialYear, isFromDataManagement } = params.payload;

  try {
    const url = isFromDataManagement ? `${apis.hostName}${apis.getClientDetails}` : `${apis.hostName}${apis.searchClient}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const data = {
      client,
      clientId,
      financialYear,
      timePeriod,
    };

    const payload = yield call(callwrapperSaga, "POST", headers, url, data);
    yield put({
      type: types.SEARCH_CLIENT_SUCCESS,
      payload: { payload },
    });
  } catch (e) {
    yield put({ type: types.SEARCH_CLIENT_ERROR, payload: e });
  }
}

function* workerGetGeneralParam(params) {
  const { financialYear, timePeriod, pastData } = params.payload;

  try {
    const url = `${apis.hostName}${apis.getGeneralParameters}/${financialYear}/${timePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url);
    if (payload.status == "success") {
      try {
        const url2 = `${apis.hostName}${apis.getGeneralParameters}/${pastData.financialYear}/${pastData.timePeriod}`;
        const headers2 = {
          Accept: "application/json",
          "Content-Type": "application/json",
          userid: sessionStorage.userId,
        };

        const payload2 = yield call(callwrapperSaga, "GET", headers2, url2);
        if (payload2.status == "success") {
          let finalData = payload.data[0];
          finalData = {
            ...finalData,
            lastPhaseLockDate: payload2.data[0].leadReviewer_CompletionDate,
          };

          yield put({
            type: types.GET_GENERAL_PARAMETERS_SUCCESS,
            payload: { ...payload, data: [finalData] },
          });
        } else {
          let finalData = payload.data[0];
          finalData = {
            ...finalData,
            lastPhaseLockDate: null,
          };
          yield put({
            type: types.GET_GENERAL_PARAMETERS_SUCCESS,
            payload: { ...payload, data: [finalData] },
          });
        }
      } catch (e) {
        let finalData = payload.data[0];
        finalData = {
          ...finalData,
          lastPhaseLockDate: null,
        };
        yield put({
          type: types.GET_GENERAL_PARAMETERS_SUCCESS,
          payload: { ...payload, data: [finalData] },
        });
      }
    } else {
      yield put({ type: types.GET_GENERAL_PARAMETERS_ERROR, payload });
    }
  } catch (e) {
    yield put({ type: types.GET_GENERAL_PARAMETERS_ERROR, payload: e });
  }
}

function* workerEditGeneralParam(params) {
  const { token, dataJson } = params.payload;

  try {
    const url = `${apis.hostName}${apis.editGeneralParameters}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "PUT", headers, url, dataJson);
    yield put({ type: types.EDIT_GENERAL_PARAMETERS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.EDIT_GENERAL_PARAMETERS_ERROR, payload: e });
  }
}

function* workerSearchUser(params) {
  try {
    const url = `${apis.hostName}${apis.searchUser}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload.body
    );
    yield put({ type: types.SEARCH_USER_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.SEARCH_USER_ERROR, payload: e });
  }
}

function* workerResetUser(params) {
  try {
    const url = `${apis.hostName}${apis.resetUserData}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload.body
    );
    yield put({ type: types.RESET_USER_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.RESET_USER_ERROR, payload: e });
  }
}
function* workerGetReportsData(params) {
  const { financialYear, timePeriod, api } = params.payload;

  try {
    const url = `${apis.hostName}/${api}/${financialYear}/${timePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_REPORTS_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_REPORTS_DATA_ERROR, payload: e });
  }
}

function* workerSetUserUUID(params) {
  try {
    const url = `${apis.hostName}${apis.setUserUUID}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );
    yield put({ type: types.SET_UUID_FOR_USER_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.SET_UUID_FOR_USER_ERROR, payload: e });
  }
}

function* workerGetExcelReportsData(params) {
  const { financialYear, timePeriod, api } = params.payload;

  try {
    const url = `${apis.hostName}/${api}/${financialYear}/${timePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_EXCEL_REPORTS_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_EXCEL_REPORTS_DATA_ERROR, payload: e });
  }
}

function* workerGetTimePeriodStatus(params) {
  const { financialYear } = params.payload;
  try {
    const url = `${apis.hostName}${apis.getTimePeriodStatus}/${financialYear}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = {
      data: yield call(callwrapperSaga, "GET", headers, url),
      year: financialYear,
    };
    yield put({ type: types.GET_TIME_PERIOD_STATUS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_TIME_PERIOD_STATUS_ERROR, payload: e });
  }
}
function* workerGetAllAssesmentSection(params) {
  const { financialYear } = params.payload;
  try {
    const url = `${apis.getAssesmentSection}/${financialYear}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);

    yield put({ type: types.GET_ASSESSMENT_SECTION_LIST_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_ASSESSMENT_SECTION_LIST_ERROR, payload: e });
  }
}
function* workerDeleteSection(params) {
  const { id } = params.payload;
  try {
    const url = `${apis.deleteAssessmentSection}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
      requestuserid: getCurrentUser(),
    };

    const payload = yield call(
      callwrapperSaga,
      "DELETE",
      headers,
      url,
      params.payload
    );
    yield put({ type: types.DELETE_ASSESSMENT_SECTION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.DELETE_ASSESSMENT_SECTION_ERROR, payload: e });
  }
}
function* workerEditSection(params) {
  const { data } = params.payload;
  try {
    const url = `${apis.getAssesmentSection}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "PUT", headers, url, data);
    yield put({ type: types.EDIT_ASSESSMENT_SECTION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.EDIT_ASSESSMENT_SECTION_ERROR, payload: e });
  }
}

function* workerGetUserCount(params) {
  const { financialYear, timePeriod } = params.payload;
  try {
    const url = `${apis.hostName}${apis.getUserCount}/${financialYear}/${timePeriod}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_USER_COUNT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_USER_COUNT_ERROR, payload: e });
  }
}

function* workerRollForwardUsers(params) {
  const { financialYear, timePeriod } = params.payload;
  try {
    const url = `${apis.hostName}${apis.rollForwardUsers}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );
    yield put({ type: types.ROLL_FORWARD_USER_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.ROLL_FORWARD_USER_ERROR, payload: e });
  }
}

function* workerAddMultipleUsers(params) {
  try {
    const url = `${apis.hostName}${apis.saveMultiUsers}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      userid: sessionStorage.userId,
      // ad_token: params.payload.ad_token,
    };

    let formData = new FormData();
    let temp = params.payload;
    formData.append("multi_user_upload", params.payload.multi_user_upload);
    console.log(formData.getAll("file"));
    formData.append("phase", params.payload.phase);
    formData.append("financialYear", params.payload.financialYear);
    formData.append("ad_token", params.payload.ad_token);
    temp = formData;

    let payload = yield call(callwrapperSaga, "POST", headers, url, temp);

    yield put({ type: types.ADD_MULTIPLE_USER_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.ADD_MULTIPLE_USER_ERROR, payload: e });
  }
}

//..Bulk Upload Discussion Guide
function* workerBulkUploadDiscussionGuide(params) {
  try {
    //debugger;
    const url = `${apis.hostName}${apis.bulkUploadDiscussionGuide}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      userid: sessionStorage.userId,
      // ad_token: params.payload.ad_token,
    };

    let formData = new FormData();
    let temp = params.payload;
    formData.append("multi_user_upload", params.payload.bulk_discussion_guide_upload);
    console.log(formData.getAll("file"));
    formData.append("phase", params.payload.phase);
    formData.append("financialYear", params.payload.financialYear);
    formData.append("ad_token", params.payload.ad_token);
    temp = formData;

    let payload = yield call(callwrapperSaga, "POST", headers, url, temp);

    yield put({ type: types.BULK_UPLOAD_DISCUSSION_GUIDE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.BULK_UPLOAD_DISCUSSION_GUIDE_ERROR, payload: e });
  }
}

function* workerMercuryFileUpload(params) {
  try {
    const url = `${apis.mercuryFileUpload}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      userid: getCurrentUser(),
    };

    let formData = new FormData();
    formData.append("excel_upload", params.payload.excel_upload);
    formData.append("financialYear", params.payload.financialYear);
    formData.append("timePeriod", params.payload.timePeriod);
    formData.append("bobParameter", params.payload.bobParameter);
    if (params.payload.isFromDataManagement) {
      formData.append("isFromDataManagement", params.payload.isFromDataManagement);
    }
    const payload = yield call(
      callwrapperSaga,
      "PUT",
      headers,
      url,
      formData,
      120000
    ); // 120 sec timeout for mercury uploads

    yield put({ type: types.MERCURY_FILE_UPLOAD_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.MERCURY_FILE_UPLOAD_ERROR, payload: e });
  }
}

function* workerAddSingleUser(params) {
  try {
    const url = `${apis.addSingleUser}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );
    yield put({ type: types.ADD_SINGLE_USER_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.ADD_SINGLE_USER_ERROR, payload: e });
  }
}

function* workerEditSingleUser(params) {
  try {
    const url = `${apis.editSingleUser}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const payload = yield call(
      callwrapperSaga,
      "PUT",
      headers,
      url,
      params.payload
    );
    yield put({ type: types.EDIT_SINGLE_USER_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.EDIT_SINGLE_USER_ERROR, payload: e });
  }
}

function* workerDeleteSingleUser(params) {
  try {
    const url = `${apis.deleteSingleUser}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );
    yield put({ type: types.DELETE_SINGLE_USER_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.DELETE_SINGLE_USER_ERROR, payload: e });
  }
}

function* workerAdminCheckLockStatus(params) {
  try {
    const url = `${apis.hostName}${apis.getAdminActionStatus}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.CHECK_LOCK_STATUS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.CHECK_LOCK_STATUS_ERROR, payload: e });
  }
}

function* workerGetAdminConfig(params) {
  try {
    const url = `${apis.hostName}${apis.getAdminMasterConfig}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_ADMIN_MASTER_CONFIG_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_ADMIN_MASTER_CONFIG_ERROR, payload: e });
  }
}

function* workerAddGlobalMetric(params) {
  try {
    const url = `${apis.addGlobalMetric}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );
    yield put({ type: types.ADD_GLOBAL_METRIC_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.ADD_GLOBAL_METRIC_ERROR, payload: e });
  }
}

function* workerEditGlobalMetric(params) {
  try {
    const url = `${apis.editGlobalMetric}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const payload = yield call(
      callwrapperSaga,
      "PUT",
      headers,
      url,
      params.payload
    );
    yield put({ type: types.EDIT_GLOBAL_METRIC_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.EDIT_GLOBAL_METRIC_ERROR, payload: e });
  }
}

function* workerDeleteGlobalMetric(params) {
  try {
    const url = `${apis.deleteGlobalMetric}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const payload = yield call(
      callwrapperSaga,
      "DELETE",
      headers,
      url,
      params.payload
    );
    yield put({ type: types.DELETE_GLOBAL_METRIC_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.DELETE_GLOBAL_METRIC_ERROR, payload: e });
  }
}

function* workerRollForwardAssessment(params) {
  try {
    const url = `${apis.rollForwardAssessment}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );
    yield put({ type: types.ROLL_FORWARD_ASSESSMENT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.ROLL_FORWARD_ASSESSMENT_ERROR, payload: e });
  }
}

function* workerGetAssesmentGoal(params) {
  const { payload: requestPayload } = params;
  const { financialYear, pillarId, goalId } = requestPayload || {};
  try {
    const url = `${apis.getAssesmentGoal}/${financialYear}/${pillarId}/${goalId}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_ASSESSMENT_GOAL_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_ASSESSMENT_GOAL_ERROR, payload: e });
  }
}

function* workerAddAssesmentGoal(params) {
  const { payloadObj } = params.payload;
  try {
    const url = `${apis.addAssesmentGoal}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      payloadObj
    );
    yield put({ type: types.ADD_ASSESSMENT_GOAL_SUCCESS, payload });
    yield put({
      type: types.GET_ALL_ASSESSMENT_DATA,
      payload: {
        activePeriod: "Plan",
        financialYear: payloadObj.financialYear,
      },
    });
  } catch (e) {
    yield put({ type: types.ADD_ASSESSMENT_GOAL_ERROR, payload: e });
    yield put({
      type: types.GET_ALL_ASSESSMENT_DATA,
      payload: {
        activePeriod: "Plan",
        financialYear: payloadObj.financialYear,
      },
    });
  }
}

function* workerEditAssesmentGoal(params) {
  const { payloadObj } = params.payload;
  try {
    const url = `${apis.editAssesmentGoal}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "PUT",
      headers,
      url,
      payloadObj
    );
    yield put({ type: types.EDIT_ASSESSMENT_GOAL_SUCCESS, payload });
    yield put({
      type: types.GET_ALL_ASSESSMENT_DATA,
      payload: {
        activePeriod: "Plan",
        financialYear: payloadObj.financialYear,
      },
    });
  } catch (e) {
    yield put({ type: types.EDIT_ASSESSMENT_GOAL_ERROR, payload: e });
    yield put({
      type: types.GET_ALL_ASSESSMENT_DATA,
      payload: {
        activePeriod: "Plan",
        financialYear: payloadObj.financialYear,
      },
    });
  }
}

function* workerDeleteAssesmentGoal(params) {
  const { payloadObj } = params.payload;
  try {
    const url = `${apis.deleteAssesmentGoal}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "DELETE",
      headers,
      url,
      payloadObj
    );
    yield put({ type: types.DELETE_ASSESSMENT_GOAL_SUCCESS, payload });
    yield put({
      type: types.GET_ALL_ASSESSMENT_DATA,
      payload: {
        activePeriod: "Plan",
        financialYear: payloadObj.financialYear,
      },
    });
  } catch (e) {
    yield put({ type: types.DELETE_ASSESSMENT_GOAL_ERROR, payload: e });
    yield put({
      type: types.GET_ALL_ASSESSMENT_DATA,
      payload: {
        activePeriod: "Plan",
        financialYear: payloadObj.financialYear,
      },
    });
  }
}

function* workerReorderAssesmentGoal(params) {
  const { payloadObj } = params.payload;
  try {
    const url = `${apis.reorderAssesmentGoal}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      payloadObj
    );
    yield put({ type: types.REORDER_ASSESSMENT_GOAL_SUCCESS, payload });
    yield put({
      type: types.GET_ALL_ASSESSMENT_DATA,
      payload: {
        activePeriod: "Plan",
        financialYear: payloadObj.financialYear,
      },
    });
  } catch (e) {
    yield put({ type: types.REORDER_ASSESSMENT_GOAL_ERROR, payload: e });
    yield put({
      type: types.GET_ALL_ASSESSMENT_DATA,
      payload: {
        activePeriod: "Plan",
        financialYear: payloadObj.financialYear,
      },
    });
  }
}

function* workerGetAssessmentStatus(params) {
  try {
    const url = `${apis.hostName}${apis.getAssessmentStatus}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_ASSESSMENT_STATUS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_ASSESSMENT_STATUS_ERROR, payload: e });
  }
}

function* workerValidAssessmentUsers() {
  try {
    const url = `${apis.validAssessmentYears}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.VALID_ASSESSMENT_YEARS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.VALID_ASSESSMENT_YEARS_ERROR, payload: e });
  }
}

function* workerUploadAssessmentContent(params) {
  try {
    const url = `${apis.uploadAssessmentContent}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };

    let formData = new FormData();
    formData.append("assessment_upload", params.payload.assessment_upload);
    formData.append("financialYear", params.payload.financialYear);
    formData.append("ad_token", params.payload.ad_token);
    const payload = yield call(callwrapperSaga, "POST", headers, url, formData);

    yield put({ type: types.UPLOAD_ASSESSMENT_CONTENT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.UPLOAD_ASSESSMENT_CONTENT_ERROR, payload: e });
  }
}

function* workerUploadRegionalData(params) {
  // console.log(params)
  try {
    const url = `${apis.regionDataUpload}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };

    let formData = new FormData();
    formData.append("excel_upload", params.payload.regionalData_upload);
    formData.append("financialYear", params.payload.financialYear);
    formData.append("timePeriod", params.payload.timePeriod);
    const payload = yield call(callwrapperSaga, "PUT", headers, url, formData);

    yield put({ type: types.REGIONAL_DATA_UPLOAD_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.REGIONAL_DATA_UPLOAD_ERROR, payload: e });
  }
}

function* workerGenerateBookOfBusiness(params) {
  // console.log(params);
  try {
    const url = `${apis.generateBookOfBusiness}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );
    yield put({ type: types.GENERATE_BOOK_OF_BUSINESS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GENERATE_BOOK_OF_BUSINESS_ERROR, payload: e });
  }
}

function* workerReOrderGlobalMetric(params) {
  try {
    const url = `${apis.reOrderGlobalMetric}`;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );
    yield put({ type: types.REORDER_GLOBAL_METRIC_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.REORDER_GLOBAL_METRIC_ERROR, payload: e });
  }
}

function* workerReOrderAssessmentSection(params) {
  try {
    const url = `${apis.reorderAssessmentSections}`;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );
    yield put({ type: types.REORDER_ASSESSMENT_SECTION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.REORDER_ASSESSMENT_SECTION_ERROR, payload: e });
  }
}

function* workerGetValidateUserChangesData(params) {
  const { financialYear, timePeriod, ad_token } = params.payload;
  // console.log(params.payload);
  try {
    const url = `${apis.validateUserChanges}`;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );

    yield put({ type: types.VALIDATE_USER_CHANGES_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.VALIDATE_USER_CHANGES_ERROR, payload: e });
  }
}

function* workerGetUserDataChanges(params) {
  // const { financialYear, timePeriod ,ad_token } = params.payload;
  // console.log(params.payload);
  try {
    const url = `${apis.getUserDataChanges}`;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );

    yield put({ type: types.GET_USER_DATA_CHANGES_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_USER_DATA_CHANGES_ERROR, payload: e });
  }
}
function* workerUpdateUserDetails(params) {
  try {
    const url = `${apis.updateUserDetails}`;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );

    yield put({ type: types.UPDATE_USER_DETAILS_SUCCESS, payload });
    yield put({
      type: "GET_USER_DATA_CHANGES",
      payload: params.refreshData,
    });
  } catch (e) {
    yield put({ type: types.UPDATE_USER_DETAILS_ERROR, payload: e });
  }
}

function* workerDeleteUsers(params) {
  // console.log(params.payload)
  // console.log(params.refreshData)
  const { deleteMultiUsers = false } = params;
  try {
    const url = deleteMultiUsers
      ? `${apis.deleteMultiUsers}`
      : `${apis.deleteUsers}`;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );

    yield put({ type: types.DELETE_USERS_SUCCESS, payload });
    if (!deleteMultiUsers) {
      yield put({
        type: "GET_USER_DATA_CHANGES",
        payload: params.refreshData,
      });
    }
  } catch (e) {
    yield put({ type: types.DELETE_USERS_ERROR, payload: e });
  }
}

function* workerGetPhaseValidationStatus(params) {
  try {
    const url = `${apis.getPhaseValidationStatus}`;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);

    yield put({ type: types.GET_PHASE_VALIDATION_STATUS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_PHASE_VALIDATION_STATUS_ERROR, payload: e });
  }
}

function* workerAddImpactStatement(params) {
  try {
    const url = `${apis.addImpactStatement}`;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );

    yield put({ type: types.ADD_IMPACT_STATEMENT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.ADD_IMPACT_STATEMENT_ERROR, payload: e });
  }
}

function* workerMoveImpactStatement(params) {
  try {
    const url = `${apis.moveImpactStatement}`;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );

    yield put({ type: types.MOVE_IMPACT_STATEMENT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.MOVE_IMPACT_STATEMENT_ERROR, payload: e });
  }
}

function* workerDeleteImpactStatement(params) {
  try {
    const url = `${apis.deleteImpactStatement}`;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "DELETE",
      headers,
      url,
      params.payload
    );

    yield put({ type: types.DELETE_IMPACT_STATEMENT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.DELETE_IMPACT_STATEMENT_ERROR, payload: e });
  }
}

function* workerSortImpactStatement(params) {
  try {
    const url = `${apis.sortImpactStatement}`;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );

    yield put({ type: types.SORT_IMPACT_STATEMENT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.SORT_IMPACT_STATEMENT_ERROR, payload: e });
  }
}

function* workerGetEmailTemplate(params) {
  const { payload: requestPayload } = params;
  const { type } = requestPayload || {};
  try {
    const url = `${apis.getEmailTemplate}/${type}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_EMAIL_TEMPLATE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_EMAIL_TEMPLATE_ERROR, payload: e });
  }
}

function* workerEditEmailTemplate(params) {
  const { payloadObj } = params.payload || {};
  const { type, ...newPayloadObj } = payloadObj || {};
  try {
    const url = `${apis.editEmailTemplate}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "PUT",
      headers,
      url,
      newPayloadObj
    );
    yield put({ type: types.EDIT_EMAIL_TEMPLATE_SUCCESS, payload });
    yield put({
      type: types.GET_EMAIL_TEMPLATE,
      payload: {
        type,
      },
    });
  } catch (e) {
    yield put({ type: types.EDIT_EMAIL_TEMPLATE_ERROR, payload: e });
  }
}

function* workerGetListMails(params) {
  const { payload: requestPayload } = params;
  const { start = 0, limit = 1000 } = requestPayload || {};
  try {
    const url = `${apis.getListMails}/${start}/${limit}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_LIST_MAILS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_LIST_MAILS_ERROR, payload: e });
  }
}

function* workerCancelMailRequest(params) {
  const { payload: payloadObj } = params;
  try {
    const url = `${apis.cancelMailRequest}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      payloadObj
    );
    yield put({ type: types.CANCEL_MAIL_REQUEST_SUCCESS, payload });
    yield delay(1000);
    yield put({
      type: types.GET_LIST_MAILS,
      payload: {
        start: 0,
        limit: 1000,
      },
    });
  } catch (e) {
    yield put({ type: types.CANCEL_MAIL_REQUEST_ERROR, payload: e });
  }
}

function* workerSendAdhocMail(params) {
  const { payload: payloadObj } = params;
  try {
    const url = `${apis.sendAdhocMail}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      payloadObj
    );
    yield put({ type: types.SEND_ADHOC_MAIL_SUCCESS, payload });
    yield delay(1000);
    yield put({
      type: types.GET_LIST_MAILS,
      payload: {
        start: 0,
        limit: 1000,
      },
    });
  } catch (e) {
    yield put({ type: types.SEND_ADHOC_MAIL_ERROR, payload: e });
  }
}

function* workerGetGeneralSection(params) {
  const { financialYear } = params.payload;
  try {
    const url = `${apis.getGeneralSection}/${financialYear}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);

    yield put({ type: types.GET_GENERAL_SECTION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_GENERAL_SECTION_ERROR, payload: e });
  }
}

function* workerGetCompletedPhase() {
  try {
    const url = `${apis.getCompletedPhase}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);

    yield put({ type: types.GET_COMPLETED_PHASE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_COMPLETED_PHASE_ERROR, payload: e });
  }
}

function* workerEditGeneralSection(params) {
  const { data } = params.payload;
  try {
    const url = `${apis.editGeneralSection}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "PUT", headers, url, data);
    yield put({ type: types.EDIT_GENERAL_SECTION_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.EDIT_GENERAL_SECTION_ERROR, payload: e });
  }
}

function* workerGetInvalidAssessmentData(params) {
  const { financialYear } = params.payload;
  try {
    const url = `${apis.getInvalidAssessmentDetails}/${financialYear}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);

    yield put({ type: types.GET_INVALID_ASSESSMENT_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_INVALID_ASSESSMENT_DATA_ERROR, payload: e });
  }
}

function* workerSubmitAdminAssessment(params) {
  try {
    const url = `${apis.submitAdminAssessment}`;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );

    yield put({ type: types.ADMIN_SUBMIT_ASSESSMENT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.ADMIN_SUBMIT_ASSESSMENT_ERROR, payload: e });
  }
}

function* workerGetApprovalRequests(params) {
  const { payload: requestPayload } = params;
  const { type } = requestPayload || {};
  try {
    const url = `${apis.listUpdateRequest}/${type}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_APPROVAL_REQUEST_SUCCESS, payload });
    if (type === "Assessment") {
      yield put({ type: types.SET_ASSESSMENT_SUBMISSION_STATUS, payload });
    }
  } catch (e) {
    yield put({ type: types.GET_APPROVAL_REQUEST_ERROR, payload: e });
  }
}

function* workerGetAssessmentApprovalRequests(params) {
  const { payload: requestPayload } = params;
  const { type } = requestPayload || {};
  try {
    const url = `${apis.listUpdateRequest}/${type}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_ASSESSMENT_APPROVAL_LIST_SUCCESS, payload });
    if (type === "Assessment") {
      yield put({ type: types.SET_ASSESSMENT_SUBMISSION_STATUS, payload });
    }
  } catch (e) {
    yield put({ type: types.GET_ASSESSMENT_APPROVAL_LIST_ERROR, payload: e });
  }
}

function* workerValidateContentRequest(params) {
  const payloadObj = params.payload;
  try {
    const url = `${apis.validateContentRequest}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      payloadObj
    );
    yield put({ type: types.SEND_ADMIN_REVIEW_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.SEND_ADMIN_REVIEW_ERROR, payload: e });
  }
}

function* workerGetChangedData(params) {
  const { payload: requestPayload } = params;
  const { type, financialYear, timePeriod, userId } = requestPayload || {};
  try {
    let url = `${apis.highlightPendingUpdates}/${type}/${financialYear}/${timePeriod}`;
    if (userId) {
      url = `${url}/${userId}`;
    }
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_HIGHLIGHT_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_HIGHLIGHT_DATA_ERROR, payload: e });
  }
}

function* workerGetRequestCount(params) {
  const { payload: requestPayload } = params;
  const { type } = requestPayload || {};
  try {
    const url = `${apis.listUpdateRequest}/All`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_PENDING_APPROVAL_COUNT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_PENDING_APPROVAL_COUNT_ERROR, payload: e });
  }
}

function* workerGetReportFilter(params) {
  const { financialYear, period } = params.payload;
  try {
    const url = `${apis.getReportFilter}/${financialYear}/${period}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);

    yield put({ type: types.GET_REPORT_FILTER_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_REPORT_FILTER_ERROR, payload: e });
  }
}

function* workerGetAdditionalReportData(params) {
  const { fyYear, period, filter } = params.payload;

  try {
    const url = `${apis.getAdditionalReportData}/${fyYear}/${period}`;

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "POST", headers, url, filter);

    yield put({ type: types.GET_ADDITIONAL_REPORT_DATA_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_ADDITIONAL_REPORT_DATA_ERROR, payload: e });
  }
}

function* workerExportAdditionalReport(params) {
  const { payload: payloadObj } = params || {};
  const { filter, fyYear, period } = payloadObj || {};
  try {
    const url = `${apis.exportAdditionalReportData}/${fyYear}/${period}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "POST", headers, url, filter);
    yield put({ type: types.EXPORT_ADDITIONAL_REPORT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.EXPORT_ADDITIONAL_REPORT_ERROR, payload: e });
  }
}

function* workerGetPowerBILink() {
  try {
    const url = `${apis.getPowerBIlink}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_POWERBI_LINK_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_POWERBI_LINK_ERROR, payload: e });
  }
}
function* workerUpdatePowerBILink(params) {
  try {
    const url = `${apis.updatePowerBILink}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const data = {
      content: params.payload.content,
    };
    const payload = yield call(callwrapperSaga, "POST", headers, url, data);
    yield put({ type: types.UPDATE_POWERBI_LINK_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.UPDATE_POWERBI_LINK_ERROR, payload: e });
  }
}

function* workerGetUserStatusAssessment(params) {
  try {
    const url = `${apis.getUserStatusAssessment}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );
    yield put({ type: types.GET_USER_STATUS_ASSESSMENT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_USER_STATUS_ASSESSMENT_ERROR, payload: e });
  }
}

function* workerExportEntireAssessmentContentReport(params) {
  const { payload: { fyYear, period } = {} } = params;
  try {
    const url = `${apis.exportEntireAssessmentContentData}/${fyYear}/${period}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const res = yield call(callwrapperSaga, "GET", headers, url);
    // const res = { status: "success" };
    // yield delay(1000);
    yield put({
      type: types.EXPORT_ENTIRE_ASSESSMENT_CONTENT_SUCCESS,
      payload: res,
    });
  } catch (e) {
    yield put({
      type: types.EXPORT_ENTIRE_ASSESSMENT_CONTENT_ERROR,
      payload: e,
    });
  }
}

function* workerGetApprovedCompletedYearsList() {
  try {
    const url = `${apis.getApprovedCompletedYearsList}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_YEARS_LIST_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_YEARS_LIST_ERROR, payload: e });
  }
}

function* workerUploadGeneralContent(params) {
  try {
    const url = `${apis.uploadGeneralContent}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: getCurrentUser(),
    };
    const key = params.payload.key ? params.payload.key : params.payload.get('key');
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload
    );
    yield put({
      type: types.UPLOAD_GENERAL_CONTENT_SUCCESS,
      payload: { ...payload, key },
    });
  } catch (e) {
    yield put({ type: types.UPLOAD_GENERAL_CONTENT_ERROR, payload: e });
  }
}
function* workerGetGeneralContent() {
  try {
    const url = `${apis.getGeneralContent}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(callwrapperSaga, "GET", headers, url);
    yield put({ type: types.GET_GENERAL_CONTENT_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_GENERAL_CONTENT_ERROR, payload: e });
  }
}
//..Discussion Guide
function* workerGetCounseleesFinalizationDetails(params) {
  try {
    const { isFromOption1 } = params.payload;
    yield put({ type: types.GET_COUNSELEES_FINALIZATION_DETAILS_RESET });
    //const url = `${isFromOption1 ? apis.getCounseleesFinalizationDetailsOption1 : apis.getCounseleesFinalizationDetails}`;
    const url = `${apis.getCounseleesFinalizationDetails}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
      ad_token: params.payload.ad_token
    };

    const payload = yield call(callwrapperSaga, "POST", headers, url, params.payload);
    yield put({ type: types.GET_COUNSELEES_FINALIZATION_DETAILS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_COUNSELEES_FINALIZATION_DETAILS_ERROR, payload: e });
  }
}

function* workerAddOrEditCounseleesFinalizationDetails(params) {
  try {
    const { isFromOption1 } = params.payload;
    //yield put({ type: types.ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS_RESET });
    const url = `${apis.addOrEditCounseleesFinalizationDetails}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "POST", headers, url, params.payload);
    yield put({ type: types.ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS_ERROR, payload: e });
  }
}

function* workerUpdateIsRecordedInSuccessFactors(params) {
  try {
    const url = `${apis.updateIsRecordedInSuccessFactors}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(callwrapperSaga, "POST", headers, url, params.payload);
    yield put({ type: types.UPDATE_IS_RECORDED_IN_SUCCESS_FACTORS_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.UPDATE_IS_RECORDED_IN_SUCCESS_FACTORS_ERROR, payload: e });
  }
}

function* workerGetCounseleeDiscussionGuide(params) {
  try {
    //debugger;
    const { financialYear, userId } = params.payload;
    //const url = `${apis.getCounseleeDiscussionGuide}`;
    const url = `${apis.hostName}${apis.getCounseleeDiscussionGuide}/${financialYear}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
      ad_token: params.payload.ad_token
    };

    const payload = yield call(callwrapperSaga, "GET", headers, url, params.payload);
    yield put({ type: types.GET_COUNSELEE_DISCUSSION_GUIDE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.GET_COUNSELEE_DISCUSSION_GUIDE_ERROR, payload: e });
  }
}

function* workerAddOrUpdateCounseleeDiscussionGuide(params) {
  try {
    //debugger;
    const { financialYear } = params.payload;
    let url = `${apis.hostName}${apis.addOrUpdateCounseleeDiscussionGuide}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    let payload = yield call(callwrapperSaga, "POST", headers, url, params.payload);
    yield put({ type: types.ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE_SUCCESS, payload });
    //..After save refresh progress on home page
    url = `${apis.hostName}${apis.getCounseleeDiscussionGuide}/${financialYear}`;
    payload = yield call(callwrapperSaga, "GET", headers, url, params.payload);
    yield put({ type: types.GET_COUNSELEE_DISCUSSION_GUIDE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE_ERROR, payload: e });
  }
}

function* workerSearchDiscussionGuide(params) {
  try {
    const url = `${apis.hostName}${apis.searchDiscussionGuide}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };

    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload.body
    );
    yield put({ type: types.SEARCH_DISCUSSION_GUIDE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.SEARCH_DISCUSSION_GUIDE_ERROR, payload: e });
  }
}

function* workerResetDiscussionGuide(params) {
  try {
    const url = `${apis.hostName}${apis.resetDiscussionGuideData}`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      userid: sessionStorage.userId,
    };
    const payload = yield call(
      callwrapperSaga,
      "POST",
      headers,
      url,
      params.payload.body
    );
    yield put({ type: types.RESET_DISCUSSION_GUIDE_SUCCESS, payload });
  } catch (e) {
    yield put({ type: types.RESET_DISCUSSION_GUIDE_ERROR, payload: e });
  }
}



// saga
export default function* rootSaga() {
  yield all([
    watcherGetAllAssesmentData(),
    watcherGetAllPrevAssesmentData(),
    watcherGetAllUserAssesmentData(),
    watcherSetUserAssesmentData(),
    watcherGetUserDetails(),
    watcherGetUserImg(),
    watcheGetOptionValues(),
    watcherGetAssessmentMetaData(),
    watcherSetAssessmentStart(),
    watcherGetUserProfile(),
    watcherGetAllOptions(),
    watcherGetUserBookOfBusinessSummary(),
    watcherSubmitUserAssessment(),
    watcherGetUserAssessmentStatus(),
    watcherGetUserAssesmentScore(),
    watcherGetBobData(),
    watcherUpdateBobData(),
    watcherGetUserGoalScore(),
    watcherGetResponsibilityAndSectors(),
    watcherGetCounseleesStatus(),
    watcherGetPillarGroupScore(),
    watcherGetGoalGroupScore(),
    watcherGetQuantitativeScore(),
    watcherGetReviewerHomePageData(),
    watcherAcceptOrRejectCounselee(),
    watcherCommentRead(),
    watcherSearchUser(),
    watcherResetUser(),
    watcherSearchClient(),
    watcherGetGeneralParms(),
    watcherEditGeneralParms(),
    watcherGetReportsData(),
    watcherGetExcelReportsData(),
    watcherSetUserUUID(),
    watcherGetTimePeriodStatus(),
    watcherGetAssesmentSectionList(),
    watcherGetDeleteSection(),
    watcherGetEditSection(),
    watcherGetAdminSearchUserDetails(),
    watcherGetAdminSearchManagerDetails(),
    watcherGetUserCount(),
    watcherSearchUserData(),
    watcherSearchUserMatchData(),
    watcherRollForwardUser(),
    watcherCheckLockStatus(),
    watcherAddMultipleUser(),
    watcherBulkUploadDiscussionGuide(),
    watcherAddSingleUser(),
    watcherEditSingleUser(),
    watcherDeleteSingleUser(),
    watcherGetAdminConfig(),
    watcherGetAssesmentGoal(),
    watcherAddAssesmentGoal(),
    watcherEditAssesmentGoal(),
    watcherDeleteAssesmentGoal(),
    watcherReorderAssesmentGoal(),
    watcherAddGlobalMetric(),
    watcherEditGlobalMetric(),
    watcherDeleteGlobalMetric(),
    watcherReOrderGlobalMetric(),
    watcherReOrderAssessmentSection(),
    watcherRollForwardAssessment(),
    watcherGetAssessmentStatus(),
    watcherUploadAssessmentContent(),
    watcherUploadRegionalData(),
    watcherGenerateBookOfBusiness(),
    watcherGetValidateUserChangesData(),
    watcherGetUserDataChanges(),
    watcherUpdateUserDetails(),
    watcherDeleteUsers(),
    watcherGetPhaseValidationStatus(),
    watcherGetEmailTemplate(),
    watcherEditEmailTemplate(),
    watcherAddImpactStatement(),
    watcherMoveImpactStatement(),
    watcherDeleteImpactStatement(),
    watcherSortImpactStatement(),
    watcherGetListMails(),
    watcherCancelMailRequest(),
    watcherSendAdhocMail(),
    watcherGetBobRollforwardStatus(),
    watcherGetGeneralSection(),
    watcherEditGeneralSection(),
    watcherGetInvalidAssessmentData(),
    watcherAdminAssessmentSubmission(),
    watcherGetBobRollforwardToNextPhase(),
    watcherProgressStatusFromBob(),
    watcherBobAdminActionStatus(),
    watcherMercuryFileUpload(),
    watcherValidAssessmentUsers(),
    watcherGetApprovalRequests(),
    watcherGetAssessmentApprovalRequests(),
    watcherSendAdminApproval(),
    watcherGetChangedData(),
    watcherGetRequestCount(),
    watcherGetReportFilter(),
    watcherGetAdditionalReportData(),
    watcherExportAdditionalReport(),
    watcherGetPowerBILink(),
    watcherUpdatePowerBILink(),
    watcherGetUserStatusAssessment(),
    watcherExportEntireAssessmentContentReport(),
    watcherGetValidationAssessmentMetaData(),
    watcherGetCompletedPhase(),
    watcherGetApprovedCompletedYearsList(),
    watcherUploadGeneralContent(),
    watcherGetGeneralContent(),
    watcherGetCounseleesFinalizationDetails(),
    watcherAddOrEditCounseleesFinalizationDetails(),
    watcherUpdateIsRecordedInSuccessFactors(),
    watcherGetCounseleeDiscussionGuide(),
    watcherAddOrUpdateCounseleeDiscussionGuide(),
    watcherSearchDiscussionGuide(),
    watcherResetDiscussionGuide(),
  ]);
}
