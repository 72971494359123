import React from "react";
import "./Loader.scss";

const Loader = () => {
  return (
    <div className="loaderContainer">
      <div className="loaderWrap">
      <div className="loader">
      </div>
      <div className="loader-text">
        LOADING...
      </div>
      </div>
    </div>
  );
};

export default Loader;
