import "./DropdownComponent.scss";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import MenuItem from "@material-ui/core/MenuItem";
import _ from "lodash";
// import TimezoneSelect from "react-timezone-select";

import "moment/locale/nl-be";
import { Checkbox, ListItemText } from "@material-ui/core";
// import moment from "moment";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  liststyle: {
    "&.Mui-selected": {
      backgroundColor: "rgb(246, 246, 250)!important",
    },

    "&:hover": {
      backgroundColor: "rgba(246, 246, 250,0.7)!important",
    },
  },
  labelroot: {
    fontSize: "1.2rem",
    left: "-3px",
    fontFamily: "EYInterstate-Regular",
    color: "rgba(0, 0, 0, 0.80)",
  },
  formctrl: {
    // backgroundColor: "rgb(246, 246, 250)",
    marginRight: "20px",
    marginTop: "20px",
  },
  textField: {
    marginLeft: "0px",
    // marginRight: theme.spacing(1),
    width: 252,
    margin: "0px 5px 0px 5px",
    fontFamily: "EYInterstate-Regular",
    marginRight: "0px",
    // textTransform: "uppercase",
  },
  narrowtextField: {
    width: 200,
    fontFamily: "EYInterstate-Regular",
  },
  widertextField: {
    width: 420,
    fontFamily: "EYInterstate-Regular",
  },
  focused: {
    borderColor: "rgb(246, 246, 250)",
  },
  selectroot: {
    backgroundColor: "rgb(246, 246, 250)",
    fontSize: "1.2rem",
    fontFamily: "EYInterstate-Regular",
  },
  helper: {
    marginLeft: "10px",
  },

  bgGreenDropdown:{
    "& .MuiSelect-root":{
      // backgroundColor: "#e2f5c8 !important",
      // fontSize: "1.2rem",
      // fontFamily: "EYInterstate-Regular",
      backgroundColor:"#e2f5c8 !important",
      "&:focus":{
        backgroundColor:"#e2f5c8 !important",
      }
     
    },
    
   
  },
}));
// const usePlaceholderStyles = makeStyles((theme) => ({
//   placeholder: {
//     color: "#aaa",
//   },
// }));
// const Placeholder = ({ children }) => {
//   const classes = usePlaceholderStyles();
//   return <div className={classes.placeholder}>{children}</div>;
// };

const DropDown = (props) => {
  // const [value, setValue] = React.useState("");
  const classes = useStyles();
  const selectOnChange = (e) => {
    props.selectOnChange(props.varName, e.target.value);
  };
  const { disableFirstItem = false } = props;
  return (
    <FormControl
      error={props.errorMessage[props.varName] !== ""}
      disabled={props.disabled}
      variant="filled"
      classes={{
        // filled: classes.selectroot,
        root: classes.formctrl,
      }}
      className="dropdown-custom">
      <InputLabel
        htmlFor="uncontrolled-native"
        className={"leftSpacing"}
        classes={{
          root: classes.labelroot,
        }}
        shrink={true}>
        {props.label + (props.required ? "*" : "")}
      </InputLabel>
      <Select
        size="medium"
        multiple={props.multiple || false}
        id={props.label}
        label={props.label}
        placeholder={props.label}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        // defaultValue={props.defaultValue ? props.defaultValue : ""}
        onChange={(e) => selectOnChange(e)}
        value={_.isUndefined(props.defaultValue) ? "" : props.defaultValue}
        renderValue={(selected) => (props.multiple ? selected.join(", ") : selected)}
        className={props.customClass ? classes[props.customClass] : classes.textField}
        classes={{
          root: classes.selectroot,
          selectMenu: classes.selectroot,
          filled: classes.selectroot,
        }}
        // InputLabelProps={{
        //   shrink: true,
        // }}
        disabled={props.disabled}
        required={props.required ? true : false}
        disableUnderline={props.disableUnderline ? true : false}>
        {/* <option value=""></option> */}
        {props.list &&
          props.list.map((value, key) => (
            <MenuItem disabled={key === 0 && disableFirstItem} value={value} classes={{ root: classes.liststyle }} style={{ opacity: key === 0 && disableFirstItem ? "0.5" : "1", cursor: key === 0 && disableFirstItem ? "default" : "cursor" }}>
              {props.multiple ? <>
                <Checkbox
                  checked={
                    props.defaultValue &&
                    props.defaultValue.indexOf(value) > -1
                  }
                />
                <ListItemText primary={value} />
              </> : value}
            </MenuItem>

          ))}
      </Select>
      <FormHelperText
        classes={{
          root: classes.helper,
        }}>
        {props.errorMessage[props.varName]}
      </FormHelperText>
    </FormControl>
  );
};
export default DropDown;

// "/* datetime-local" */
