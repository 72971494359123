import "./ChangesHistoryField.scss";
import {
  makeStyles,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import React, { useState, useEffect, useContext } from "react";
import HistoryOutlined from "@material-ui/icons/HistoryOutlined";
import { Popover } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textroot: {
    fontSize: "12px",
    "& .MuiFormLabel-root": {
      fontSize: "1.2rem",
      fontFamily: "EYInterstate-Regular",
    },
  },
  inputRoot: {
    fontSize: "1.2rem",
    fontFamily: "EYInterstate-Regular",
    "& .MuiFormLabel-root": {
      color: "red", // or black
    },
  },
  // labelroot: {
  //   fontSize: "1.2rem",
  //   fontFamily: "EYInterstate-Regular",
  // },
  textField: {
    marginLeft: theme.spacing(1),
    width: 252,
    margin: "5px 5px 0px 5px",
    fontFamily: "EYInterstate-Regular",
    //   marginRight: "0px",
    fontSize: "1.2rem",
    // textTransform: "uppercase",
  },
  textFieldLg: {
    marginLeft: theme.spacing(1),
    width: 340,
    margin: "5px 5px 0px 5px",
    fontFamily: "EYInterstate-Regular",
    //   marginRight: "0px",
    fontSize: "1.2rem",
    // textTransform: "uppercase",
  },

  formctrl: {
    backgroundColor: "rgb(246, 246, 250)",
    marginRight: "20px",
    marginTop: "20px",
  },

  helper: {
    marginLeft: "10px",
  },
  rootIcon: {
    position: "absolute",
    right: "8px",
    top: "26px",
    fill: "rgba(0, 0, 0, 0.70)",
    fontSize: "1.7rem",
    cursor: "pointer",
  },
  popoverRoot: {},
  popoverPaper: {
    borderRadius: 0,
  },
  valueChanged: {
    backgroundColor: "rgba(255,154,145, 0.29)",
    marginRight: "20px",
    marginTop: "20px",
  },
}));

const ChangesHistoryField = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = () => {
    let el = document.getElementById("review-field" + props.identifier);
    setAnchorEl(el);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <FormControl
        id={"review-field" + props.identifier}
        className={
          "review-textfield " +
          (props.size && props.size === "lg" ? "review-textfield-lg" : "")
        }
        xs={12}
        sm={6}
        classes={{
          root: props.oldValue ? classes.valueChanged : classes.formctrl,
        }}>
        <TextField
          className={classes.textField}
          defaultValue={props.value}
          label={props.fieldLabel}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: true,
            endAdornment:
              props.oldValue && props.oldValue.toString().length ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClick}
                    classes={{
                      root: `${true ? "iconDisableRoot" : "iconroot"}`,
                    }}>
                    <HistoryOutlined />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }}
        />
        {props.oldValue && props.oldValue.toString().length ? (
          <Popover
            transitionDuration={1}
            classes={{
              paper: classes.popoverPaper,
            }}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}>
            <div
              className={
                "past-data-wrapper " +
                (props.size && props.size === "lg"
                  ? "past-data-wrapper-lg"
                  : props.size && props.size === "sm"
                  ? "past-data-wrapper-sm"
                  : "")
              }>
              <div className="past-data-content">{props.oldValue}</div>
            </div>
          </Popover>
        ) : null}
      </FormControl>
    </>
  );
};

export default ChangesHistoryField;

{
  /* <ChangesHistoryField
            identifier="123"
            fieldLabel={"Welcome Email Date & Time"}
            value={"asdasdasd"}
            oldValue={""}
          /> 
        
        import ChangesHistoryField from "../Approvals/ChangesHistoryField/ChangesHistoryField";
        */
}
