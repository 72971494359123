import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import classNames from "classnames";
import { toast, Flip } from "react-toastify";
import _ from "lodash";
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";
import NavigationPrompt from "react-router-navigation-prompt";
import "./RollForward.scss";
import AppContext from "../../../../utils/appContext";
import Loader from "../../../Loader/Loader";
import AssessmentPopup from "../AssessmentPopup/AssessmentPopup";
import { getFinancialYears } from "../../../../utils/utils";

const RollForward = (props) => {
  const menuProps = useMemo(() => {
    return {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    };
  }, []);
  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  const [fromYear, setFromYear] = useState(myContext.financialYear);
  const [toYear, setToYear] = useState(myContext.financialYear + 1);
  const [expanded, setExpanded] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [assessmentData, setAssessmentData] = useState({});
  const [checkedItems, setCheckedItems] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupContentType, setPopupContentType] = useState(0);
  const [currentPillarIndex, setCurrentPillarIndex] = useState(0);
  const [deletedActionArray, setDeletedActionArray] = useState([]);
  const [updatedSectionArray, setUpdatedSectionArray] = useState([]);
  const [financialYearFromArray, setFinancialYearFromArray] = useState([]);
  const [financialYearToArray, setFinancialYearToArray] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [isDisplayAssessmentContent, setIsDisplayAssessmentContent] =
    useState(false);
  const allAssesmentData = useSelector((state) => state.allAssesmentData);
  const approvalPendingYears = useSelector((state) => state.getAssessmentSubmissionStatus.pendingYears);
  const assessmentStatus = useSelector(
    (state) => state.getAssessmentStatus.data
  );
  const rollForwardAssessment = useSelector(
    (state) => state.rollForwardAssessment
  );
  const validAssessmentYears = useSelector(
    (state) => state.validAssessmentYears
  );
  /* const [assessmentStatus, setAssessmentStatus] = useState({
    proceedAction: false
}); */

  const isDisplayNavigationPromt = useMemo(() => {
    return (
      deletedActionArray.length > 0 ||
      updatedSectionArray.length > 0 ||
      checkedItems.length > 0
    );
  }, [deletedActionArray, updatedSectionArray, checkedItems]);

  const isProceedAction = useMemo(() => {
    return _.isEmpty(assessmentStatus) || assessmentStatus.proceedAction;
  }, [assessmentStatus]);

  const isAssessmentDataNotFound = useMemo(() => {
    return (
      assessmentData?.assessmentDetails &&
      assessmentData.assessmentDetails.length === 0
    );
  }, [assessmentData]);

  const financialYearOptions = useMemo(() => {
    return getFinancialYears(myContext.financialYear, true);;
  }, [myContext]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const updateSectionDropdown = useCallback(
    (value, pillarIndex, sectionIndex, impactIndex) => {
      const copyAssessmentData = _.cloneDeep(assessmentData);
      const copyUpdatedSectionArray = _.cloneDeep(updatedSectionArray);
      const actionId =
        copyAssessmentData.assessmentDetails[pillarIndex].section[sectionIndex]
          .data[impactIndex].action_id;
      const isActionIdExists = copyUpdatedSectionArray.some(
        (section) => section.action_id === actionId
      );
      if (isActionIdExists) {
        copyUpdatedSectionArray.forEach((section) => {
          if (section.action_id === actionId) {
            section.type = value;
          }
        });
      } else {
        copyUpdatedSectionArray.push({
          action_id: actionId,
          type: value,
        });
      }

      copyAssessmentData.assessmentDetails[pillarIndex].section[
        sectionIndex
      ].data[impactIndex].type = value;
      setUpdatedSectionArray(copyUpdatedSectionArray);
      setAssessmentData(copyAssessmentData);
    },
    [assessmentData, updatedSectionArray]
  );

  const trackSelectedItems = useCallback(
    (pillarIndex, sectionIndex, impactIndex, id) => {
      let copyCheckedItems = [...checkedItems];
      const isAlreadyExists = copyCheckedItems.some((item) => item.id === id);
      if (isAlreadyExists) {
        copyCheckedItems = copyCheckedItems.filter((item) => item.id !== id);
      } else {
        copyCheckedItems.push({
          pillarIndex,
          sectionIndex,
          impactIndex,
          id,
        });
      }
      setCheckedItems(copyCheckedItems);
    },
    [checkedItems]
  );

  const deleteImpactStatements = useCallback(
    (pillarIndex) => {
      const copyAssessmentData = _.cloneDeep(assessmentData);
      const currentPillarItems = checkedItems.filter(
        (item) => item.pillarIndex === pillarIndex
      );
      let currentCheckedItems = _.cloneDeep(checkedItems);
      const copyDeletedActionArray = _.cloneDeep(deletedActionArray);
      setIsPopupVisible(false);
      currentPillarItems.forEach((item, index) => {
        const checkedItemIndex = currentCheckedItems.findIndex(
          (i) => i.id === item.id
        );
        const currentIndex = copyAssessmentData.assessmentDetails[
          item.pillarIndex
        ].section[item.sectionIndex].data.findIndex(
          (i) => i.action_id === item.id
        );
        currentCheckedItems.splice(checkedItemIndex, 1);
        copyAssessmentData.assessmentDetails[item.pillarIndex].section[
          item.sectionIndex
        ].data.splice(currentIndex, 1);
      });
      checkedItems.forEach((item) => {
        copyDeletedActionArray.push(item.id);
      });
      setCheckedItems(currentCheckedItems);
      setAssessmentData(copyAssessmentData);
      setDeletedActionArray(copyDeletedActionArray);
    },
    [checkedItems, assessmentData, deletedActionArray]
  );

  const getDropdown = useCallback(
    (options, pillarIndex, sectionIndex, impactIndex) => {
      if (options.length === 0) {
        return (
          <p>
            {
              assessmentData.assessmentDetails[pillarIndex].section[
                sectionIndex
              ].data[impactIndex].type
            }
          </p>
        );
      }
      return (
        <Select
          MenuProps={menuProps}
          className="table-dropdown"
          value={
            assessmentData.assessmentDetails[pillarIndex].section[sectionIndex]
              .data[impactIndex].type
          }
          onChange={(e) =>
            updateSectionDropdown(
              e.target.value,
              pillarIndex,
              sectionIndex,
              impactIndex
            )
          }
        >
          {options.length > 0 &&
            options.map((option, index) => (
              <MenuItem
                value={option.name}
                key={index}
                style={{
                  opacity:
                    assessmentData.assessmentDetails[pillarIndex].section[
                      sectionIndex
                    ].data[impactIndex].type === option.name
                      ? "0.5"
                      : "1",
                }}
              >
                {option.name}
              </MenuItem>
            ))}
        </Select>
      );
    },
    [assessmentData, updateSectionDropdown, menuProps]
  );

  const showDeletePopup = useCallback((index) => {
    setPopupContentType(0);
    setIsPopupVisible(true);
    setCurrentPillarIndex(index);
  }, []);

  const getDeleteBtn = useCallback(
    (pillarIndex) => {
      const enableBtn = checkedItems.some(
        (item) => item.pillarIndex === pillarIndex
      );
      return (
        <Button
          variant="outlined"
          className="delete-btn"
          startIcon={<span className="eyaa-trash" />}
          disabled={!enableBtn || !isProceedAction || errorMsg}
          onClick={() => showDeletePopup(pillarIndex)}
        >
          Delete
        </Button>
      );
    },
    [checkedItems, showDeletePopup, errorMsg]
  );

  const getAllImpactStatements = useCallback(
    (section, pillarIndex, sectionIndex) => {
      const currentGoalName = section.goalName;
      return (
        <React.Fragment key={section.goalId}>
          {section.data &&
            section.data.length > 0 &&
            section.data.map((data, impactIndex) => (
              <TableRow key={impactIndex}>
                <TableCell>{currentGoalName}</TableCell>
                <TableCell>
                  {getDropdown(
                    section.sectionValues,
                    pillarIndex,
                    sectionIndex,
                    impactIndex
                  )}
                </TableCell>
                <TableCell>
                  <span>
                    <Checkbox
                      inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                      color="default"
                      className="impact-check-box"
                      checked={checkedItems.some(
                        (item) => item.id === data.action_id
                      )}
                      disabled={errorMsg}
                      onChange={() =>
                        trackSelectedItems(
                          pillarIndex,
                          sectionIndex,
                          impactIndex,
                          data.action_id
                        )
                      }
                    />
                    <span>{data.impactActions}</span>
                  </span>
                </TableCell>
              </TableRow>
            ))}
        </React.Fragment>
      );
    },
    [getDropdown, trackSelectedItems, checkedItems, errorMsg]
  );

  const onPopupConfirm = useCallback(() => {
    if (popupContentType === 0) {
      deleteImpactStatements(currentPillarIndex);
    } else {
      dispatch({
        type: "ROLL_FORWARD_ASSESSMENT",
        payload: {
          fromFinancialYear: fromYear,
          toFinancialYear: toYear,
          deletedActionArray,
          updatedSectionArray,
        },
      });
      setTimeout(() => {
        dispatch({
          type: "GET_ASSESSMENT_STATUS",
          payload: {},
        });
      }, 500);
    }
  }, [
    popupContentType,
    deleteImpactStatements,
    fromYear,
    toYear,
    deletedActionArray,
    updatedSectionArray,
    currentPillarIndex,
  ]);

  const notify = useCallback(() => {
    toast(
      <div className="success-text">
        <span className="icon-verified-outline icon"></span>
        <p>
          <span>Success: </span>
          <span>Rolled forward assessment.</span>
        </p>
      </div>,
      {
        position: "top-center",
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  const getAssessmentData = useCallback(
    (year) => {
      setIsDisplayAssessmentContent(false);
      setFromYear(year);
      setDeletedActionArray([]);
      setUpdatedSectionArray([]);
      dispatch({
        type: "GET_ALL_ASSESSMENT_DATA",
        payload: {
          activePeriod: "Mid-Year",
          financialYear: year,
        },
      });
    },
    [dispatch, toYear]
  );

  useEffect(()=>{
    dispatch({
      type: "VALID_ASSESSMENT_YEARS",
      payload: {},
    });
    // setIsLoading(false);
  }, [])

  useEffect(() => {
    if(approvalPendingYears.includes(toYear)) {
      setErrorMsg("Assessment content for the selected year has been submitted for review. Only after completing the review you will be able to perform this action for the selected year.");
    } else if (fromYear > toYear) {
      setErrorMsg("Assessment cannot be rolled forward to a prior year.");
    } else if (fromYear === toYear) {
      setErrorMsg(
        "You cannot have same Year in 'From' and 'To'. Kindly make a different selection."
      );
    } else {
      setErrorMsg("");
    }
  }, [fromYear, toYear]);

  useEffect(() => {
    if (allAssesmentData.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setAssessmentData(_.cloneDeep(allAssesmentData.data.data));
    }
  }, [allAssesmentData]);

  useEffect(() => {
    getAssessmentData(fromYear);
    return () => {
      dispatch({
        type: "GET_ALL_ASSESSMENT_DATA_RESET",
        payload: {},
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (rollForwardAssessment.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }

    if (
      !_.isEmpty(rollForwardAssessment.data) &&
      rollForwardAssessment.data.code === "INITIATED"
    ) {
      notify();
      dispatch({
        type: "ROLL_FORWARD_ASSESSMENT_RESET",
        payload: {},
      });
      props.onClose();
    }
  }, [rollForwardAssessment, notify, dispatch]);

  useEffect(() => {
    if (validAssessmentYears.fetching && validAssessmentYears.data &&  validAssessmentYears.data.fromYearsList) {
      setIsLoading(true);
    } else if (!validAssessmentYears.fetching && validAssessmentYears.data &&  validAssessmentYears.data.fromYearsList) {
      setFinancialYearFromArray(validAssessmentYears.data.fromYearsList);
      setFinancialYearToArray(validAssessmentYears.data.toYearsList)
      if(validAssessmentYears.data.fromYearsList.length){
        setFromYear(validAssessmentYears.data.fromYearsList[0]);
      }
      if(validAssessmentYears.data.toYearsList.length){
        setToYear(validAssessmentYears.data.toYearsList[0]);
      }
 
      setIsLoading(false);
      dispatch({
        type: "VALID_ASSESSMENT_YEARS_RESET",
        payload: {},
      });
    }
  }, [validAssessmentYears]);

  useEffect(() => {
    props.onChildChange(isDisplayNavigationPromt);
  }, [isDisplayNavigationPromt])

  return (
    <div className="assessment-roll-forward">
      {isLoading && <Loader />}
      <AssessmentPopup />
      <NavigationPrompt when={isDisplayNavigationPromt}>
        {({ onConfirm, onCancel }) => (
          <div className="popup-dialog">
            <div className="dialog">
              <div className="header">
                <h5>Confirmation</h5>
                <span
                  className="icon-close-filled icon"
                  onClick={onCancel}
                ></span>
              </div>
              <div className="content">
                Would you like to navigate without completing the operation?
                Please note that on confirming, incomplete actions will be lost
              </div>
              <div className="footer">
                <button className="cancel-btn" onClick={onCancel}>
                  Cancel
                </button>
                <button className="confirm-btn" onClick={onConfirm}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </NavigationPrompt>
      <SimpleBar className="roll-forward-scroll">
        <div className="input-container">
          <>
            <div className="input-group">
              <h5>From</h5>
              <FormControl variant="filled" className="input-control">
                <InputLabel id="fromYear">Year</InputLabel>
                <Select
                  MenuProps={menuProps}
                  label="year"
                  labelId="fromYear"
                  value={fromYear}
                  disabled={!isProceedAction}
                  onChange={(e) => {
                    getAssessmentData(e.target.value);
                  }}
                >
                  {/*<MenuItem value="Select" style={{ opacity: "0.5" }}>
                    Select
                </MenuItem>*/}
                  {financialYearFromArray.map((option) => (
                    <MenuItem value={option} key={option}>
                      {"FY " + option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Button
              variant="outlined"
              startIcon={<span className="eyaa-data-08" />}
              onClick={() => setIsDisplayAssessmentContent(true)}
              disabled={!isProceedAction || isAssessmentDataNotFound}
            >
              View
            </Button>
          </>
          <div className="input-group">
            <h5>To</h5>
            <FormControl variant="filled" className="input-control">
              <InputLabel id="toYear">Year</InputLabel>
              <Select
                MenuProps={menuProps}
                label="year"
                labelId="toYear"
                value={toYear}
                onChange={(e) => setToYear(e.target.value)}
                disabled={!isProceedAction}
              >
                {/*<MenuItem value="Select" style={{ opacity: "0.5" }}>
                  Select
                  </MenuItem>*/}
                {financialYearToArray.map((option) => (
                  <MenuItem value={option} key={option}>
                    {"FY " + option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <p className="error-msg">{errorMsg}</p>
        </div>
        <hr />
        <div className="tabs-container">
          {isDisplayAssessmentContent &&
            assessmentData?.assessmentDetails &&
            assessmentData.assessmentDetails.length > 0 &&
            assessmentData.assessmentDetails.map((data, pillarIndex) => (
              <Accordion
                className="accordian"
                expanded={expanded === "panel" + pillarIndex}
                onChange={handleChange("panel" + pillarIndex)}
                key={data._id}
                disabled={!isProceedAction}
              >
                <AccordionSummary>
                  <Typography>
                    {" "}
                    {expanded === "panel" + pillarIndex ? (
                      <span>–</span>
                    ) : (
                      <span>+</span>
                    )}
                    <span>{data.pillar}</span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {getDeleteBtn(pillarIndex)}
                  <TableContainer className="table-container">
                    <Table sx={{ minWidth: 650 }} aria-label="user list">
                      <TableHead>
                        <TableRow>
                          <TableCell>Goal</TableCell>
                          <TableCell>Section</TableCell>
                          <TableCell>Impact Statement</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.section &&
                          data.section.length > 0 &&
                          data.section.map((section, sectionIndex) =>
                            getAllImpactStatements(
                              section,
                              pillarIndex,
                              sectionIndex
                            )
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))}
        </div>
        {isAssessmentDataNotFound && (
          <p className="no-data-found">No data found for the selected year.</p>
        )}
        <div
          className={classNames("footer", {
            expanded: expanded && !isAssessmentDataNotFound && isDisplayAssessmentContent,
          })}
        >
          <Button
            variant="outlined"
            className="cancel-btn"
            onClick={props.onClose}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            className={classNames("roll-btn", {
              "disable-btn":
                checkedItems.length > 0 || errorMsg || isAssessmentDataNotFound,
            })}
            disabled={
              checkedItems.length > 0 ||
              errorMsg ||
              !isProceedAction ||
              isAssessmentDataNotFound
            }
            onClick={() => {
              setIsPopupVisible(true);
              setPopupContentType(1);
            }}
          >
            Roll Forward
          </Button>
        </div>
        {isPopupVisible && (
          <div className="popup-dialog">
            <div
              className="dialog"
              style={{ height: popupContentType === 0 ? "160px" : "200px" }}
            >
              <div className="header">
                <h5>Confirmation</h5>
                <span
                  className="icon-close-filled icon"
                  onClick={() => setIsPopupVisible(false)}
                ></span>
              </div>
              <div className="content">
                {popupContentType === 0 && (
                  <p>Would you like to delete the impact statement(s)?</p>
                )}
                {popupContentType === 1 && (
                  <>
                    <p>
                      {`Would you like to roll forward Assessment content from FY
                      ${fromYear} to FY ${toYear}`}
                    </p>
                    <p>
                      {" "}
                      Please note that this would replace any existing content
                      mapped within the target year.
                    </p>
                    <p>
                      {" "}
                      This operation will take some time to complete. Once it is
                      completed, we will inform you over an email.
                    </p>
                  </>
                )}
              </div>
              <div className="footer">
                <button
                  className="cancel-btn"
                  onClick={() => setIsPopupVisible(false)}
                >
                  Cancel
                </button>
                <button className="confirm-btn" onClick={onPopupConfirm}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </SimpleBar>
    </div>
  );
};
export default RollForward;
