import React, { useState, useContext, useEffect } from "react";
import "./BookOfBusiness.scss";
import moment from "moment";
import { makeStyles, Select, MenuItem, OutlinedInput } from "@material-ui/core";
import AppContext from "../../utils/appContext";
import SimpleBar from "simplebar-react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SideMenu from "../SideMenu/SideMenu";
import Alert from "../Alert/AlertComponent";
import { useAlert } from "../../helpers/useAlert";
import AlertSubmitPopup from "../Common/AlertSubmitPopup";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
// import { useLocation } from "react-router";
import NotificationDialog from "../Header/NotificationDialog";
import { authProvider, APIscope } from "./../../Authentication/authProvider";
import BobTable from "./BobTable";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import * as myConsts from "../../constants/constants";
import { ToastContainer, toast, Flip } from "react-toastify";
import Modal from "react-responsive-modal";
import SearchClientTable from "../SearchClientTable/SearchClientTable";
import _ from "lodash";
import Preview from "../Admin/ContentManagement/Preview";

const useStyles = makeStyles({
  input: {
    width: "100%",
    boxShadow: "none",
    outline: "none",
    border: "0",
    fontFamily: "EYInterState-regular",
  },
  itemStyle: {
    padding: "0px !important",
    height: "28px",
  },
  checklabel: {
    fontSize: "12px",
    fontWeight: 700,
    fontFamily: "EYInterstate-Regular",
    width: "15%",
  },
  noBorder: {
    border: "none",
  },
  rootSelect: {
    padding: "11px 7px",
  },
  inputBas: {
    width: 200,
    borderRadius: 0,
  },
  icon: {
    top: "calc(50% - 8px)",
  },
  inpClas: {
    width: 175,
    padding: 10,
    fontSize: 13,
  },
  outRoot: {
    borderRadius: 2,
    marginTop: 7,
  },
  searchButton: {
    backgroundColor: "#ffe600",
    color: "#2e2e38",
    cursor: "pointer",
    padding: "5px 15px",
    fontSize: 13,
    fontFamily: "EYInterState-Regular, sans-serif",
  },
  searchCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  inpCont: {
    display: "flex",
    flexDirection: "column",
  },
  mainPopCont: {
    display: "flex",
    marginTop: 20,
    marginBottom: 20,
    fontSize: 14,
  },
  borderBottom: {
    borderBottom: "1px solid #ccc",
    marginBottom: 15,
  },
  bobPop: {
    padding: "15px 20px",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    position: "relative",
    // minHeight: '50vh'
  },
  btnCont: {
    marginTop: 20,
    marginBottom: 7,
    display: "flex",
    justifyContent: "end",
  },
  cancelButton: {
    backgroundColor: "transparent",
    cursor: "pointer",
    fontFamily: "EYInterState-Light, sans-serif",
    border: "1px solid #2e2e38",
    color: "#747480",
    marginRight: 10,
    padding: "5px 15px",
    fontSize: 13,
  },
  error: {
    color: "red",
    fontSize: 12,
    fontFamily: "EYInterState-Regular",
  },
});

const BookOfBusiness = (props) => {
  const dispatch = useDispatch();
  // const location = useLocation();
  // console.log("router", location);
  const responsibityAndSectors = useSelector(
    (state) => state.responsibiltyAndSectors
  );
  const userBobData = useSelector((state) => state.getBobData);
  const submitBobData = useSelector((state) => state.updateBobData);
  const clientList = useSelector((state) => state.clientList);
  const searchClientList = useSelector((state) => state.searchClient);
  const isOutsideClick = useSelector((state) => state.outsideClickBob);
  const [bobDetail, setBobDetail] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  // const [crumbs, setBreadcrumb] = useState([
  //   {
  //     name: "Book of Business & PPMD Profile",
  //     path: myConsts.routeBookOfBusiness,
  //   },
  // ]);
  const crumbs = [
    {
      name: "Book of Business & PPMD Profile",
      path: myConsts.routeBookOfBusiness,
    },
  ];

  const classes = useStyles();
  const appContext = useContext(AppContext);
  const [isAccurate, setAccuracy] = useState();
  const [clients, setClients] = useState([]);
  // const [roles, setRoles] = useState({
  //   marketSegmentLeader: false,
  //   assuranceLeader: false,
  //   omp: false,
  //   others: false,
  // });
  // const [sideMenuItemSelected, setSideMenuItemSelected] =
  //   useState("Book of Business");
  const activePPMDuserId =
    appContext.activePPMDuserId || sessionStorage.activePPMDuserId;
  // console.log("activePPMDuserId", appContext);
  const [otherRole, setOtherRole] = useState("");
  const [comment, setComment] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [clientOption, setClientOption] = useState([]);
  const [sectors, setSectors] = useState({
    primary: "",
    secondary: "",
  });
  const [sectorOption, setSectorOption] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [roleListGeneral, setRoleListGeneral] = useState([]);
  const [roleListQuality, setRoleListQuality] = useState([]);
  const [roleListFSO, setRoleListFSO] = useState([]);
  const [userDetail, setUserDetail] = useState([]);
  const [bobStatus, setBobStatus] = useState("");
  const [bobUpdateOn, setBobUpdateOn] = useState("31-Oct-2021");
  const [searchedList, setSearchedList] = useState([]);
  const generalContent = useSelector(
    (state) => state.generalContent
  );
  const bobContent = _.get(generalContent, "data.bob", {});

  // useEffect has been used for getting the all bob data based on financial year & time period
  useEffect(() => {
    const getBobData = async () => {
      try {
        setLoader(true);
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;

        dispatch({
          type: "GET_BOB_DATA",
          payload: {
            token: idToken,
            financialYear: appContext.financialYear,
            timePeriod: appContext.currentPeriod,
          },
        });
      } catch (e) {
        console.error(e);
      }
    };
    getBobData();
  }, []);
  // useEffect has been used for getting the all roles and sectors list
  useEffect(() => {
    (async function () {
      try {
        // setLoader(true);
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;

        dispatch({
          type: "GET_RESPONSIBILITY_SECTORS",
          payload: {
            token: idToken,
          },
        });
        dispatch({
          type: "GET_GENERAL_CONTENT",
          payload: {},
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (clientList && clientList.data && clientList.data.status === "success") {
      if (
        clientList &&
        clientList.data &&
        clientList.data.data &&
        clientList.data.data.clientList
      )
        setClientOption(clientList.data.data.clientList);
    }
  }, [clientList]);
  // useEffect has been used for getting the all bob data based on financial year & time period
  useEffect(() => {
    if (
      submitBobData &&
      submitBobData.data &&
      submitBobData.data.status === "success"
    ) {
      (async function () {
        try {
          setLoader(true);
          const token = await authProvider.acquireTokenSilent({
            scopes: APIscope,
          });
          const idToken = token.accessToken;

          dispatch({
            type: "GET_BOB_DATA",
            payload: {
              token: idToken,
              financialYear: appContext.financialYear,
              timePeriod: appContext.currentPeriod,
            },
          });
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [submitBobData]);
  // useEffect has been used for getting the all roles and sectors based on responsibityAndSectors variable value check
  useEffect(() => {
    if (
      responsibityAndSectors &&
      responsibityAndSectors.data &&
      responsibityAndSectors.data.sectors
    ) {
      setSectorOption(responsibityAndSectors.data.sectors);
    }
  }, [responsibityAndSectors]);
  // useEffect has been used for getting the all roles and sectors based on responsibityAndSectors variable value check
  useEffect(() => {
    if (
      responsibityAndSectors &&
      responsibityAndSectors.data &&
      responsibityAndSectors.data.responsibilities
    ) {
      responsibityAndSectors.data.responsibilities.forEach(
        (item) => (item.isChecked = false)
      );
      const roleListGeneral =
        responsibityAndSectors.data.responsibilities.filter(
          (item) => item.category.toLowerCase() === "general"
        );
      const roleListQuality =
        responsibityAndSectors.data.responsibilities.filter(
          (item) => item.category.toLowerCase() === "quality"
        );
      const roleListFSO = responsibityAndSectors.data.responsibilities.filter(
        (item) => item.category.toLowerCase() === "fso"
      );
      setRoleListGeneral(roleListGeneral);
      setRoleListQuality(roleListQuality);
      setRoleListFSO(roleListFSO);
      // console.log('rols', roleListGeneral, roleListQuality, roleListFSO)
      setRoleList(responsibityAndSectors.data.responsibilities);
    }
  }, [responsibityAndSectors]);

  // an object, that has all the total values for different pillers
  const [bobTotalObj, setTotalObj] = useState({
    currentTerTotal: 0,
    priorTerTotal: 0,
    revenueTotal: 0,
    marginTotal: 0,
    currentMarginTotal: 0,
    priorMarginTotal: 0,
  });
  // return total based on passed data and particular pillaer value
  const getTotal = (data, val) => {
    const total = data.reduce((total, cur) => total + Number(cur[val]), 0);
    return total.toFixed(1);
  };
  const checkOutsideClickBob = (fn, params1) => {
    if (appContext.bobChangeTriggered) {
      dispatch({
        type: "OUTSIDE_CLICK_BOB",
        payload: {
          flag: false,
          callback: { fn: fn, params: params1 },
        },
      });
      // setShowAlertSubmitPopup(true);
      appContext.setShowAlertPopupBob(true);
    } else {
      fn && fn(params1);
    }
  };
  // used to cancel edit
  const cancelEdit = () => {
    setEditMode(false);
  };
  useEffect(() => {
    if (userBobData && userBobData.data) {
      setLoader(false);
      if (userBobData.data.bobDetails) {
        const dataQuantitave =
          userBobData.data && userBobData.data.bobQuantitative;
        const data = userBobData.data.bobDetails;
        const obj = {
          currentTerTotal: getTotal(data, "currentTER"),
          priorTerTotal: getTotal(data, "priorTER"),
          revenueTotal: getTotal(data, "revenueGoalIn"),
          marginTotal: getTotal(data, "marginIn"),
          currentMarginTotal: dataQuantitave && dataQuantitave.highImpactMargin,
          priorMarginTotal: dataQuantitave && dataQuantitave.priorMargin,
        };

        setTotalObj(obj);
        setBobDetail(data);
      }
      if (userBobData.data.bobStatus) {
        setBobStatus(userBobData.data.bobStatus);
      }
      if (userBobData.data.bobLastUpdatedDate) {
        const updatedOn = moment(userBobData.data.bobLastUpdatedDate).format("DD-MMM-YYYY");
        setBobUpdateOn(updatedOn);
      }
      if (userBobData.data.userDetails) {
        const {
          additionalResponsibility,
          primarySector,
          secondarySectorSpecialization,
        } = userBobData.data.userDetails[0];
        const tempRoleList = [...roleList];
        tempRoleList.forEach((role) => {
          additionalResponsibility.forEach((item) => {
            if (role.responsibility === item) {
              role.isChecked = true;
            }
          });
        });
        // setRoleList(tempRoleList);
        setUserDetail(userBobData.data.userDetails);
        setSectors({
          ...sectors,
          primary: primarySector,
          secondary: secondarySectorSpecialization,
        });
      }
      const outClickCallback = isOutsideClick.data.callback;
      if (outClickCallback && Object.keys(outClickCallback).length > 0) {
        dispatch({
          type: "OUTSIDE_CLICK_BOB",
          payload: { flag: false, callback: {} },
        });
        outClickCallback.fn && outClickCallback.fn(outClickCallback.params);
        dispatch({
          type: "OUTSIDE_CLICK_BOB_RESET",
          payload: {},
        });
        appContext.setBobChangeTriggered(false);
        // setShowAlertSubmitPopup(false);
        appContext.setShowAlertPopupBob(false);
      }
      dispatch({
        type: "OUTSIDE_CLICK_BOB_RESET",
        payload: {},
      });
      appContext.setBobChangeTriggered(false);
      // setShowAlertSubmitPopup(false);
      appContext.setShowAlertPopupBob(false);
    }
  }, [userBobData, roleListGeneral]);

  const [alertData, toogleShow] = useAlert();
  const [showAlertSubmitPopup, setShowAlertSubmitPopup] = useState(false);

  const alertSubmitPopupShow = () => {
    setShowAlertSubmitPopup(true);
  };
  const cancelAlertSubmitPopup = () => {
    setShowAlertSubmitPopup(false);
  };
  const cancelSaveAlertSubmitPopup = () => {
    dispatch({
      type: "OUTSIDE_CLICK_BOB_CANCEL",
      payload: {},
    });
    // setShowAlertSubmitPopup(false);
    appContext.setShowAlertPopupBob(false);
  };
  const changeAccuracy = (value) => {
    setAccuracy(value);
  };
  const addNewClient = () => {
    const item = {
      clientId: "",
      clientName: "",
      role: "",
      sector: "",
      region: "",
      secIndicator: "",
      ccRiskDesignation: "",
      ccTRRScore: "",
      complexityRating: "",
    };
    let tempObj = [...clients];
    tempObj.push(item);
    setClients(tempObj);
  };
  const handleSectorChange = (name, value) => {
    setSectors({ ...sectors, [name]: value });
    // console.log('sector---change-', name, value);
  };
  // api call function for triggering the async calls
  const asynApiCall = async (status) => {
    let otherRoles = [];
    roleList.forEach((role) => {
      if (role.isChecked) otherRoles.push(role.responsibility);
    });
    userDetail[0].primarySector = sectors.primary;
    userDetail[0].secondarySectorSpecialization = sectors.secondary;
    userDetail[0].additionalResponsibility = otherRoles;

    const payloadObj = {
      timePeriod: appContext.activePeriod,
      financialYear: appContext.financialYear,
    };
    const token = await authProvider.acquireTokenSilent({
      scopes: APIscope,
    });
    const idToken = token.accessToken;
    if (isEditMode) {
      payloadObj.bobDetails = bobDetail;
      payloadObj.userDetails = userDetail;
      payloadObj.status = status;
    } else {
      if (numSelected) {
        payloadObj.bobDetails = bobDetail;
        payloadObj.userDetails = userDetail;
      }
      payloadObj.status = status;
    }
    dispatch({
      type: "UPDATE_BOB_DATA",
      payload: { tokens: idToken, payloadObj },
    });
  };
  // calls on submit button's confirmation
  const submitPopupChanges = async () => {
    cancelAlertSubmitPopup();

    asynApiCall("Submitted");
    toast(
      <div>
        <i className="icon-verified-outline" />
        Thank you for submitting changes. Your changes have been saved.
      </div>,
      {
        position: "bottom-center",
        transition: Flip,
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    setEditMode(false);
  };

  useEffect(() => {
    if (
      appContext.bobChangeTriggered &&
      isOutsideClick.data &&
      !isOutsideClick.data.flag &&
      isOutsideClick.data.cancel
    ) {
      //save on cancel button click,currently dispatch for this reducer change is commented
      const outClickCallback = isOutsideClick.data.callback;
      if (outClickCallback && Object.keys(outClickCallback).length > 0) {
        dispatch({
          type: "OUTSIDE_CLICK_BOB",
          payload: { flag: false, callback: {} },
        });
        outClickCallback.fn && outClickCallback.fn(outClickCallback.params);
        dispatch({
          type: "OUTSIDE_CLICK_BOB_RESET",
          payload: {},
        });
        appContext.setBobChangeTriggered(false);
      }
    } else if (
      appContext.bobChangeTriggered &&
      isOutsideClick.data &&
      isOutsideClick.data.flag
    ) {
      submitChanges("In Progress");
    }
    // eslint-disable-next-line
  }, [appContext.bobChangeTriggered, isOutsideClick]);

  const removeClient = (index) => {
    let tempObj = [...clients];
    tempObj.splice(index, 1);
    setClients(tempObj);
  };
  const handleRoleUpdate = (name, value) => {
    let tempArr = [...roleList];
    const getCheckedObj = tempArr.find(
      (arrItem) => arrItem.responsibility === name
    );
    getCheckedObj.isChecked = value;
    setRoleList(tempArr);
  };
  const handleChangeOtherRole = (event) => {
    setOtherRole(event.target.value);
  };
  const handleChangeComment = (event) => {
    setComment(event.target.value);
  };
  // calls on submit button click and show confirm and cancel options
  const submitChanges = (status) => {
    appContext.setShowAlertPopupBob(false);
    if (status === "In Progress") {
      asynApiCall("In Progress");
      toast(
        <div>
          <i className="icon-verified-outline" />
          Your changes have been saved.
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setEditMode(false);
    } else {
      setAlertMessage("Would you like to confirm your submission?");
      alertSubmitPopupShow();
    }
  };
  // for client change function
  const handleClientChange = (index) => (event, newInputValue) => {
    let tempObj = [...clients];
    let tempClients = [...clientOption];
    tempObj[index].clientName = newInputValue.clientName;
    const clientIdSearch = tempClients.find(
      (client) => client.clientName === newInputValue.clientName
    );
    if (Object.values(clientIdSearch).length > 0) {
      tempObj[index].clientId = clientIdSearch.clientId;
      tempObj[index].ccRiskDesignation = clientIdSearch.ccRiskDesignation;
      tempObj[index].ccTRRScore = clientIdSearch.ccTRRScore;
      tempObj[index].complexityRating = clientIdSearch.complexityRating;
      tempObj[index].secIndicator = clientIdSearch.secIndicator;
      tempObj[index].sector = clientIdSearch.sector;
    }
    setClients(tempObj);
  };
  // role change function
  const setRoleSelected = (index, e) => {
    let tempArr = [...bobDetail];
    if (!tempArr[index].orgRole) {
      tempArr[index].orgRole = tempArr[index].role;
    }
    tempArr[index].role = e.target.value;
    setBobDetail(tempArr);
  };
  // focus client change function
  const setFocusClientSelected = (index, e) => {
    let tempArr = [...bobDetail];
    if (!tempArr[index].orgFocusClient) {
      tempArr[index].orgFocusClient = tempArr[index].focusClient;
    }
    tempArr[index].focusClient = e.target.value;
    setBobDetail(tempArr);
  };
  // parts of bob change function
  const setPartOfBobSelected = (index, e) => {
    let tempArr = [...bobDetail];
    if (!tempArr[index].orgIsPartOfBob) {
      tempArr[index].orgIsPartOfBob = tempArr[index].isPartOfBob;
    }
    tempArr[index].isPartOfBob = e.target.value;
    setBobDetail(tempArr);
  };

  const setPreviousBobSelected = (index, e) => {
    let tempArr = [...bobDetail];
    tempArr[index].focusClient = e.target.value;
    setBobDetail(tempArr);
  };

  const disableInputs =
    (bobStatus &&
      (bobStatus.toLowerCase() === myConsts.statusSubmitted ||
        bobStatus.toLowerCase() === myConsts.statusCompleted)) ||
    false;
  const [error, setError] = useState("");
  const [isOpenAddClient, setOpenAddClient] = useState(false);
  // setting all required parameters for passing as single object
  const params = {
    isEditMode: isEditMode,
    setEditMode: setEditMode,
    handleSectorChange: handleSectorChange,
    activePPMDuserId: activePPMDuserId,
    bobStatus: bobStatus,
    bobTotalObj: bobTotalObj,
    sectors: sectors,
    roleList: roleList,
    roleListGeneral: roleListGeneral,
    roleListQuality: roleListQuality,
    roleListFSO: roleListFSO,
    bobDetail: bobDetail,
    // roles: roles,
    clients: clients,
    classes: classes,
    comment: comment,
    otherRole: otherRole,
    isAccurate: isAccurate,
    appContext: appContext,
    setFocusClientSelected: setFocusClientSelected,
    setPreviousBobSelected: setPreviousBobSelected,
    setRoleSelected: setRoleSelected,
    sectorOption: sectorOption,
    addNewClient: addNewClient,
    removeClient: removeClient,
    changeAccuracy: changeAccuracy,
    handleRoleUpdate: handleRoleUpdate,
    handleClientChange: handleClientChange,
    handleChangeComment: handleChangeComment,
    handleChangeOtherRole: handleChangeOtherRole,
    disableInputs: disableInputs,
    submitChanges: submitChanges,
    setOpenAddClient: setOpenAddClient,
    setSearchedList: setSearchedList,
    setPartOfBobSelected: setPartOfBobSelected,
    setError: setError,
    bobUpdateOn,
    bobContent
  };
  const [open, setOpen] = React.useState(false);

  const handleDialogClose = () => {
    setOpen(false);
  };
  const [clientSearchObj, setClientSearchObj] = useState({
    client: "",
    clientId: "",
  });
  // console.log('searchClientList', searchClientList);
  // useEffect for setting the searched cliet list
  useEffect(() => {
    if (
      searchClientList &&
      searchClientList.data &&
      searchClientList.data.payload &&
      searchClientList.data.payload.data
    ) {
      setLoader(false);
      const data = searchClientList.data.payload.data;

      data.forEach((item) => {
        bobDetail &&
          bobDetail.forEach((bobItm) => {
            if (bobItm.clientId === item.clientId && bobItm.engagementRegion === item.engagementRegion) {
              item.isDuplicate = true;
            }
          });
      });
      setSearchedList(data);
      setShowNoRecordsText(true);
    }
  }, [searchClientList]);

  const isSearchButtonDisable =
    (clientSearchObj && clientSearchObj.client !== "") ||
    (clientSearchObj && clientSearchObj.clientId !== "");
  // handle change function for search client text input
  const handleChangeSearch = (value, name) => {
    // if (value.length > 2) {
    if (!value.match(/[%<>\\$#}{)(?!/,@^&*'"]/)) {
      setError("");
      if (value.length > 2) {
        setClientSearchObj({ ...clientSearchObj, [name]: value });
      } else setClientSearchObj({ ...clientSearchObj, [name]: "" });
    } else {
      setError(`Characters restricted [%<>\\$#}{)(?!/,@^&*'"]`);
    }

    // }
  };
  const [isShowNoRecordsText, setShowNoRecordsText] = useState(false);
  // function for searching the client using dispatch search_client
  const searchClient = async () => {
    // console.log('obj', clientSearchObj)

    const { client, clientId } = clientSearchObj;
    try {
      setLoader(true);
      const token = await authProvider.acquireTokenSilent({
        scopes: APIscope,
      });
      const idToken = token.accessToken;
      dispatch({
        type: "SEARCH_CLIENT",
        payload: {
          token: idToken,
          client,
          clientId,
          financialYear: appContext.financialYear,
          timePeriod: appContext.currentPeriod,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  // handle function for client's individual checked/unchecked
  const handleClientChecked = (index, value) => {
    let tempArr = [...searchedList];
    // console.log('temp', tempArr, index, value)
    const getCheckedObj = tempArr[index];
    getCheckedObj.isNewClient = value;
    setSearchedList(tempArr);
  };

  const numSelected = searchedList.filter(
    (itm) => itm.isNewClient === true
  ).length;

  const isDuplicateFound = searchedList.filter(
    (itm) => itm.isDuplicate === true
  ).length;
  // handle function for client's all checked/unchecked
  const handleClientCheckedAll = (value) => {
    let tempArr = [...searchedList];
    tempArr.forEach((item) => (item.isNewClient = value));
    setSearchedList(tempArr);
  };

  const addClient = () => {
    setShowNoRecordsText(false);
    const tempSelectedArr = [...searchedList];
    tempSelectedArr.forEach((itm) => {
      itm.role = "EP";
      itm.isPartOfBob = "Y";
      itm.focusClient = "Y";
    });
    const newSelectedArr = tempSelectedArr.filter(
      (item) => item.isNewClient === true
    );
    let tempArr = [...bobDetail];
    tempArr.push(...newSelectedArr);
    setBobDetail(tempArr);
    setOpenAddClient(false);
    !appContext.formsInActive && appContext.setBobChangeTriggered(true);
  };
  // const checkSpecialChar = (event) => /[0-9a-zA-Z]/i.test(event.key);
  // console.log('appContext', appContext)
  return props.previewTable ? (
    <div className="businessChanges" style={{ width: "100%" }}>
      <BookOfBusinessTable
        {...params}
        checkOutsideClickBob={checkOutsideClickBob}
        cancelEdit={cancelEdit}
      />
    </div>
  ) : (
    <>
      {loader && <Loader />}
      <Alert options={{ alertData, toogleShow }} />
      {
        <ToastContainer
          toastStyle={{
            backgroundColor: "#b1de76",
            color: "white",
            fontFamily: "EYInterState-Regular",
            fontSize: "13px",
            textAlign: "center",
          }}
          transition={Flip}
          position="bottom-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      }
      <AlertSubmitPopup
        header={"Confirm Submission"}
        onlyAlert={
          alertMessage === "Please select client and role!" ? true : false
        }
        message={alertMessage}
        description={
          "Do note that once submitted you cannot make anymore changes."
        }
        showAlertSubmitPopup={showAlertSubmitPopup}
        cancelAlertSubmitPopup={cancelAlertSubmitPopup}
        simpleClosePopup={cancelAlertSubmitPopup}
        submitPopupChanges={submitPopupChanges}
      ></AlertSubmitPopup>
      <AlertSubmitPopup
        header={"Save Changes"}
        onlyAlert={false}
        message={""}
        cancelBtnTxt={"No"}
        submitBtnTxt={"Yes"}
        simpleClosePopup={() => appContext.setShowAlertPopupBob(false)}
        description={"Do you wish to save the changes before you proceed."}
        showAlertSubmitPopup={appContext.showAlertPopupBob}
        cancelAlertSubmitPopup={cancelSaveAlertSubmitPopup}
        submitPopupChanges={() => {
          submitChanges("In Progress");
        }}
      ></AlertSubmitPopup>
      <NotificationDialog open={open} handleDialogClose={handleDialogClose} />
      <div className="businessContainer">
        <div style={{ width: 58, float: "left" }}>
          <SideMenu
            minimized={true}
            sideMenuItemSelect="Home"
            isminimizedMainMenu={true}
            isMainMenu={true}
          />
        </div>
        <div style={{ width: "100%", overflowX: "hidden" }}>
          <div
            className="second-header"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            <Breadcrumb crumbs={crumbs} {...props} />
            {/* <div className="respond-sec">
              {!activePPMDuserId && (
                <i
                  className="icon-web-071"
                  onClick={() => {
                    submitChanges("In Progress");
                  }}
                  style={
                    !isEditMode || disableInputs
                      ? { pointerEvents: "none" }
                      : {}
                  }
                ></i>
              )}
            </div> */}
          </div>

          <SimpleBar className="businessChanges">
            <BookOfBusinessTable
              {...params}
              checkOutsideClickBob={checkOutsideClickBob}
              cancelEdit={cancelEdit}
            />
          </SimpleBar>
          <div className="saveContainer">
            {!activePPMDuserId ? (
              <div
                className={
                  disableInputs || appContext.formsInActive
                    ? "button-save disableButton"
                    : "button-save save"
                }
                onClick={() => {
                  submitChanges("Submitted");
                }}
              >
                Submit
              </div>
            ) : (
              <div
                className={"button-save ppmd-user"}
                onClick={() => {
                  props.history.push(
                    myConsts.routeHome + "/" + activePPMDuserId
                  );
                }}
              >
                Back to PPMD Home
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={isOpenAddClient}
        onClose={() => {
          setShowNoRecordsText(false);
          setOpenAddClient(false);
          setClientSearchObj({ ...clientSearchObj, client: "", clientId: "" });
        }}
        styles={{
          modal: {
            maxWidth: "unset",
            padding: "unset",
            width: "94vw",
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.5)",
          },
          /*  closeButton: {
          background: "white",
        }, */
        }}
        center
      >
        <SimpleBar
          style={{ overflowX: "hidden", maxHeight: "82vh" }}
          className={classes.bobPop}
        >
          <div>Add Client</div>
          <div className={classes.mainPopCont}>
            <div
              style={{
                marginRight: 25,
              }}
              className={classes.inpCont}
            >
              <label>Client Name</label>
              <OutlinedInput
                onInput={(e) => {
                  e.target.value = e.target.value.replace(
                    /[^a-zA-Z0-9_/&-,. ]/g,
                    ""
                  );
                }}
                onChange={(e) => handleChangeSearch(e.target.value, "client")}
                placeholder="Client Name"
                inputProps={{
                  "aria-label": "client",
                  maxLength: 50,
                }}
                classes={{ input: classes.inpClas, root: classes.outRoot }}
              />
            </div>
            <div
              style={{
                marginRight: 45,
              }}
              className={classes.inpCont}
            >
              <label>Client ID</label>
              <OutlinedInput
                placeholder="Client ID"
                onChange={(e) => handleChangeSearch(e.target.value, "clientId")}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 15);
                }}
                inputProps={{
                  "aria-label": "clientId",
                }}
                type="number"
                classes={{ input: classes.inpClas, root: classes.outRoot }}
              />
            </div>
            <div className={classes.searchCont}>
              <div
                className={classes.searchButton}
                style={
                  isSearchButtonDisable
                    ? { pointerEvents: "auto" }
                    : { pointerEvents: "none", backgroundColor: "#eaeaea" }
                }
                onClick={() => {
                  searchClient();
                }}
              >
                Search
              </div>
            </div>
          </div>
          <div className={classes.error}>{error}</div>
          <div className={classes.borderBottom}></div>
          <SearchClientTable
            isShowNoRecordsText={isShowNoRecordsText}
            rowCount={searchedList.length}
            numSelected={numSelected}
            isDuplicateFound={isDuplicateFound}
            handleClientCheckedAll={handleClientCheckedAll}
            handleClientChecked={handleClientChecked}
            clientList={searchedList}
          />
          <div className={classes.btnCont}>
            <div
              className={classes.cancelButton}
              onClick={() => {
                setShowNoRecordsText(false);
                setOpenAddClient(false);
              }}
            >
              Cancel
            </div>
            <div
              className={classes.searchButton}
              onClick={() => {
                addClient();
              }}
              style={
                numSelected > 0
                  ? { pointerEvents: "auto" }
                  : { pointerEvents: "none", backgroundColor: "#eaeaea" }
              }
            >
              Add
            </div>
          </div>
        </SimpleBar>
      </Modal>
    </>
  );
};

export default BookOfBusiness;

const BookOfBusinessTable = (props) => {
  const {
    isEditMode,
    setEditMode,
    activePPMDuserId,
    bobStatus,
    bobTotalObj,
    sectors,
    handleSectorChange,
    roleListGeneral,
    roleListQuality,
    roleListFSO,
    bobDetail,
    sectorOption,
    setFocusClientSelected,
    setPreviousBobSelected,
    setRoleSelected,
    handleRoleUpdate,
    classes,
    disableInputs,
    submitChanges,
    setOpenAddClient,
    setSearchedList,
    setPartOfBobSelected,
    setError,
    appContext,
    bobUpdateOn,
    bobContent
  } = props;

  const navToNewTab = (type) => {
    let newWindow;
    switch (type) {
      case "learn":
        newWindow = window.open(
          "https://eyus.sharepoint.com/sites/AmericasAssuranceNewsHub/Site%20Assets/files/LEAD%204D/LEAD-4D-FAQ.pdf",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      default:
        break;
    }
    if (newWindow) newWindow.opener = null;
  };

  console.log('bobDetail', bobDetail)
  return (
    <>
      <div className="bob-title-heading">
        <div className="">
          <div className="businessHeading">Book of Business & PPMD Profile</div>
          <div className="businessLabel">
            {bobDetail && bobDetail.length > 0 ? (
              <span>
                The below list represents your comprehensive book of business.
                Please confirm the accuracy of this list. Click the 'Edit' icon
                to make changes and once done click 'Submit'.
              </span>
            ) : (
              <span>No Records Available</span>
            )}
          </div>
        </div>
        {!disableInputs && !isEditMode && (
          <div style={{ display: "flex" }}>
            <div
              className="addClientContainer"
              style={
                appContext.formsInActive
                  ? {
                    justifyContent: "flex-end",
                    marginRight: 15,
                  }
                  : { justifyContent: "flex-end", marginRight: 15 }
              }
            >
              {!activePPMDuserId && (
                <div
                  className="addClientButton"
                  onClick={() => {
                    setError("");
                    setSearchedList([]);
                    !appContext.formsInActive && setOpenAddClient(true);
                  }}
                  style={
                    appContext.formsInActive
                      ? { cursor: "not-allowed", fontSize: 10 }
                      : { fontSize: 10 }
                  }
                >
                  <span>
                    <i className="icon-plus" style={{ fontSize: 9 }}></i>
                  </span>
                  <span className="txt">Add Client</span>
                </div>
              )}
            </div>
            <div
              className="addClientContainer"
              style={
                appContext.formsInActive
                  ? { justifyContent: "flex-end" }
                  : { justifyContent: "flex-end" }
              }
            >
              {!activePPMDuserId && (
                <div
                  className="addClientButton"
                  onClick={() => {
                    !appContext.formsInActive && setEditMode(true);
                    !appContext.formsInActive &&
                      appContext.setBobChangeTriggered(true);
                  }}
                  style={
                    appContext.formsInActive
                      ? { cursor: "not-allowed", fontSize: 10 }
                      : { fontSize: 10 }
                  }
                >
                  <span>
                    <i className="icon-pencil" style={{ fontSize: 9 }}></i>
                  </span>
                  <span className="txt">Edit</span>
                </div>
              )}
            </div>
          </div>
        )}
        {!disableInputs && isEditMode && (
          <div style={{ display: "flex" }}>
            <div
              className="addClientContainer"
              style={{ justifyContent: "flex-end", marginRight: 15 }}
            >
              {!activePPMDuserId && (
                <div
                  className="addClientButton"
                  onClick={() => {
                    props.checkOutsideClickBob(props.cancelEdit);
                  }}
                  style={{ fontSize: 10 }}
                >
                  <span className="txt">Cancel</span>
                </div>
              )}
            </div>
            <div
              className="addClientContainer"
              style={{ justifyContent: "flex-end" }}
            >
              {!activePPMDuserId && (
                <div
                  className="addClientButton"
                  onClick={() => {
                    submitChanges("In Progress");
                  }}
                  style={
                    !isEditMode || disableInputs
                      ? { pointerEvents: "none", fontSize: 10 }
                      : { fontSize: 10 }
                  }
                >
                  <span className="txt">Save</span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="clientContainer">
        {bobDetail && bobDetail.length > 0 && (
          <BobTable
            bobTotalObj={bobTotalObj}
            bobDetail={bobDetail}
            bobStatus={bobStatus}
            setFocusClientSelected={setFocusClientSelected}
            setPreviousBobSelected={setPreviousBobSelected}
            setRoleSelected={setRoleSelected}
            isEditMode={isEditMode}
            setPartOfBobSelected={setPartOfBobSelected}
          />
        )}
      </div>
      <div
        className="businessLabel-content"
        style={{ padding: "0px 20px 15px", backgroundColor: "#fff" }}
      >
        {bobDetail && bobDetail.length > 0 && (
          // <span>
          //   Focus accounts are those where you are most instrumental as a PPMD.
          //   In most cases, this will be the entirety of your BOB. For additional
          //   guidance on selecting focus accounts, click{" "}
          //   <span
          //     style={{
          //       textDecoration: "underline",
          //       color: "blue",
          //       cursor: "pointer",
          //       fontFamily: "EYInterState-Bold",
          //     }}
          //     onClick={() => navToNewTab("learn")}
          //   >
          //     here
          //   </span>{" "}
          //   to access the LEAD 4D Mid-Year FAQ.
          //   <div className="bob-note">
          //     {" "}
          //     <span>{`Book of Business is as of ${bobUpdateOn}`}</span>
          //   </div>
          // </span>
          <Preview content={bobContent.content} />
        )}
      </div>
      <div className="sectorContainer">
        <div style={{ marginRight: 40 }}>
          <label>Primary Sector</label>
          <Select
            labelId="primary-sec-select-label"
            id="primary-sec-select"
            value={sectors.primary}
            onChange={(e) => handleSectorChange("primary", e.target.value)}
            variant="outlined"
            classes={{ root: classes.rootSelect, icon: classes.icon }}
            className={classes.inputBas}
            IconComponent={(props) => <ExpandMoreIcon {...props} />}
            style={!isEditMode ? { pointerEvents: "none" } : {}}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {sectorOption &&
              sectorOption.map((option, i) => (
                <MenuItem key={i} value={option.sectorName}>
                  {option.sectorName}
                </MenuItem>
              ))}
          </Select>
        </div>
        <div>
          <label>Secondary Sector</label>
          <Select
            labelId="secondary-sec-select-label"
            id="secondary-sec-select"
            value={sectors.secondary}
            onChange={(e) => handleSectorChange("secondary", e.target.value)}
            variant="outlined"
            classes={{ root: classes.rootSelect, icon: classes.icon }}
            className={classes.inputBas}
            IconComponent={(props) => <ExpandMoreIcon {...props} />}
            style={!isEditMode ? { pointerEvents: "none" } : {}}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {sectorOption &&
              sectorOption.map((option, i) => (
                <MenuItem key={i} value={option.sectorName}>
                  {option.sectorName}
                </MenuItem>
              ))}
          </Select>
        </div>
      </div>
      <div className="roleContainer">
        <div className="roleHead">
          As a PPMD, which other roles are you associated with, if any?
        </div>
        <div className="roleList_">
          <div className="roleItem">
            <div className="titl">General</div>
            <div className="role_">
              {roleListGeneral &&
                roleListGeneral.map((role) => (
                  <>
                    {
                      (role.order !== '9' && role.order !== '10') ?
                        (
                          <div className="roles" key={role._id}>
                            {role.isChecked ? (
                              <i
                                className="icon-check-checked"
                                onClick={() =>
                                  handleRoleUpdate(role.responsibility, false)
                                }
                                style={{
                                  pointerEvents: !isEditMode ? "none" : "",
                                }}
                              />
                            ) : (
                              <i
                                className="icon-check-unchecked"
                                onClick={() =>
                                  handleRoleUpdate(role.responsibility, true)
                                }
                                style={{
                                  pointerEvents: !isEditMode ? "none" : "",
                                }}
                              />
                            )}
                            <span>{role.responsibility}</span>
                          </div>
                        )
                        :
                        (
                          <></>
                        )
                    }
                  </>
                ))}
            </div>
            {roleListGeneral &&
                roleListGeneral.map((role) => (
                  <>
                    {
                      (role.order == '9' || role.order == '10') ?
                        (
                          <div className="roles" key={role._id}>
                            {role.isChecked ? (
                              <i
                                className="icon-check-checked"
                                onClick={() =>
                                  handleRoleUpdate(role.responsibility, false)
                                }
                                style={{
                                  pointerEvents: !isEditMode ? "none" : "",
                                }}
                              />
                            ) : (
                              <i
                                className="icon-check-unchecked"
                                onClick={() =>
                                  handleRoleUpdate(role.responsibility, true)
                                }
                                style={{
                                  pointerEvents: !isEditMode ? "none" : "",
                                }}
                              />
                            )}
                            <span>{role.responsibility}</span>
                          </div>
                        )
                        :
                        (
                          <></>
                        )
                    }
                  </>
                ))}
          </div>
          <div className="roleItem">
            <div className="titl">Quality</div>
            <div className="role_">
              {roleListQuality &&
                roleListQuality.map((role) => (
                  <div className="roles" key={role._id}>
                    {role.isChecked ? (
                      <i
                        className="icon-check-checked"
                        onClick={() =>
                          handleRoleUpdate(role.responsibility, false)
                        }
                        style={{
                          pointerEvents: !isEditMode ? "none" : "",
                        }}
                      />
                    ) : (
                      <i
                        className="icon-check-unchecked"
                        onClick={() =>
                          handleRoleUpdate(role.responsibility, true)
                        }
                        style={{
                          pointerEvents: !isEditMode ? "none" : "",
                        }}
                      />
                    )}
                    <span>{role.responsibility}</span>
                  </div>
                ))}
            </div>
          </div>
          <div className="roleItem">
            <div className="titl">FSO</div>
            <div className="role_">
              {roleListFSO &&
                roleListFSO.map((role) => (
                  <div className="roles" key={role._id}>
                    {role.isChecked ? (
                      <i
                        className="icon-check-checked"
                        onClick={() =>
                          handleRoleUpdate(role.responsibility, false)
                        }
                        style={{
                          pointerEvents: !isEditMode ? "none" : "",
                        }}
                      />
                    ) : (
                      <i
                        className="icon-check-unchecked"
                        onClick={() =>
                          handleRoleUpdate(role.responsibility, true)
                        }
                        style={{
                          pointerEvents: !isEditMode ? "none" : "",
                        }}
                      />
                    )}
                    <span>{role.responsibility}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
