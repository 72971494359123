import React, { useCallback, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation } from 'react-router-dom';
import AppContext from "../../../utils/appContext";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import SideMenu from "../../SideMenu/SideMenu";
import ManageApprovals from "./ManageApprovals/ManageApprovals";

import * as myConsts from "../../../constants/constants";
import _ from "lodash";

import "./Approval.scss";
import "../Admin.scss";

const Approvals = (props) => {
    const myContext = useContext(AppContext);  
    const dispatch = useDispatch();
    const location = useLocation();
    const countData = useSelector((state) => state.getApprovalCount.data);
    const crumbs = [{ name: "Admin", path: myConsts.routeAdmin }];
    
    const [isMainPageVisible, setIsMainPageVisible] = useState(
        sessionStorage.getItem("currentPageName")
          ? sessionStorage.getItem("currentPageName") !== "Approvals"
            ? false
            : true
          : true
      );

    const [currentPage, setCurrentPage] = useState(
    sessionStorage.getItem("currentPageName")
        ? sessionStorage.getItem("currentPageName") === "General Parameters"
        ? 1
        : sessionStorage.getItem("currentPageName") === "User Management"
        ? 2
        : sessionStorage.getItem("currentPageName") === "Assessment"
        ? 3
        : null
        : null
    );

    const [currentPageName, setCurrentPageName] = useState(
        sessionStorage.getItem("currentPageName") || "Approvals"
    );

    const getPendingApprovalCount = () => {
      dispatch({
        type: "GET_PENDING_APPROVAL_COUNT",
        payload: {},
      });
    }

    useEffect(() => {
      getPendingApprovalCount();
      // eslint-disable-next-line
    }, [location]);

    
    const setCurrentPageConfig = useCallback((pageNo) => {
        switch (pageNo) {
          case 1:
            setCurrentPageName("General Parameters");
            sessionStorage.setItem("currentPageName", "General Parameters");
            break;
          case 2:
            setCurrentPageName("User Management");
            sessionStorage.setItem("currentPageName", "User Management");
            break;
          case 3:
            setCurrentPageName("Assessment");
            sessionStorage.setItem("currentPageName", "Assessment");
            break;
          default: 
          setCurrentPageName("");
          sessionStorage.setItem("currentPageName", "");
        }
        setCurrentPage(pageNo);
        setIsMainPageVisible(false);
      }, []);

      const getCurrentPage = useCallback(() => {
        switch (currentPage) {
          case 1:
            return (
              <ManageApprovals
                type={"GeneralParameters"}
                onClose={() => {
                  setCurrentPageName("General Parameters");
                  setIsMainPageVisible(true);
                  sessionStorage.setItem("currentPageName", "General Parameters");
                }}
                {...props}
              />
            );
          case 2:
            return (
              <ManageApprovals
                type={"UserManagement"}
                onClose={() => {
                  setCurrentPageName("User Management");
                  sessionStorage.setItem("currentPageName", "User Management");
                  setIsMainPageVisible(true);
                }}
                {...props}
              />
            );
          case 3:
            return (
              <ManageApprovals
                type={"Assessment"}
                onClose={() => {
                  setCurrentPageName("Assessment");
                  sessionStorage.setItem("currentPageName", "Assessment");
                  setIsMainPageVisible(true);
                }}
                {...props}
              />
            );
          default: return '';
        }
      }, [currentPage]);
    

    return(
        <div className="admin-container manage-approvals">
            <div className="admin-info-main-sidemenu">
                <SideMenu
                minimized={true}
                isminimizedMainMenu={true}
                isMainMenu={true}
                sideMenuItemSelect="Admin"
                />
            </div>
            <div className="admin-content">
          <div className="second-header admin-crumbs">
            <i
              className="icon-arrow-left"
              onClick={() => {
                if (!isMainPageVisible) {
                  setCurrentPageName("Approvals");
                  setIsMainPageVisible(true);
                  sessionStorage.setItem("currentPageName", "Approvals");
                } else {
                  props.history.push(myConsts.routeAdmin);
                }
              }}
            />
            <div className="admin-crumb-txt">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <Breadcrumb
                  crumbs={crumbs}
                  isAdmin={true}
                  isInnerAdmin={true}
                  {...props}
                />
                {!isMainPageVisible && (
                  <div className="icon-chevron-right iconStyling">
                    <a
                      className=" userMngmnt MuiTypography-root MuiLink-root MuiLink-underlineHover admin-crumb MuiTypography-colorInherit"
                      onClick={() => {
                        setIsMainPageVisible(true);
                        setCurrentPageName("Approvals");
                        sessionStorage.setItem(
                          "currentPageName",
                          "Approvals"
                        );
                      }}>
                      Approvals
                    </a>
                  </div>
                )}
              </div>
              <h4>{currentPageName}</h4>
            </div>
          </div>
          <div className="manage-users-content">
            <div className="main-content">
              {isMainPageVisible ? (
                <div className="card-block">
                  <div className="card" onClick={() => setCurrentPageConfig(1)}>
                    {
                      (countData.pendingCountGP > 0) ?
                        <div className="admin-pending-count">{countData.pendingCountGP}</div> : null
                    }
                    <span className="eyaa-settings"></span>
                    <span>General Parameters</span>
                  </div>
                  <div className="card" onClick={() => setCurrentPageConfig(2)}>
                    {
                      (countData.pendingCountUser > 0) ?
                        <div className="admin-pending-count">{countData.pendingCountUser}</div> : null
                    }
                    <span className="eyaa-user-05"></span>
                    <span>User Management</span>
                  </div>
                  <div className="card" onClick={() => setCurrentPageConfig(3)}>
                    {
                      (countData.pendingCountAssessment > 0) ?
                        <div className="admin-pending-count">{countData.pendingCountAssessment}</div> : null
                    }
                    <span className="eyaa-web-134"></span>
                    <span>Assessment</span>
                  </div>
                </div>
              ) : (
                <>{getCurrentPage()}</>
              )}
            </div>
          </div>
        </div>
        </div>    
    );
};
export default Approvals;
