import React from "react";
import { Box } from "@material-ui/core";
import AnsCard from "./AnsCard";
import AnsCheckbox from "./AnsCheckbox";

const AnsComponent = React.memo((props) => {
  return (
    <Box padding="10px 0" paddingBottom="0" paddingLeft="40px" display="flex">
      <AnsCard {...props} />
      <AnsCheckbox {...props} />
    </Box>
  );
});

export default AnsComponent;
