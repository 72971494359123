import "./DateTimeCalender.scss";
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// import { fr, es, ru } from "date-fns/locale";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import AccessAlarm from "@material-ui/icons/AccessAlarm";
// import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { IconButton, InputAdornment } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import "moment/locale/nl-be";
import moment from "moment";
// import * as momentTZ from "moment-timezone";
// momentTZ.locale("nl-be");
// momentTZ.tz.setDefault("Europe/Brussels");
moment.locale("de");

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  inputRoot: {
    fontSize: "1.2rem",
    fontFamily: "EYInterstate-Regular",
  },
  labelroot: {
    fontSize: "1.2rem",
    fontFamily: "EYInterstate-Regular",
  },
  formctrl: {
    backgroundColor: "rgb(246, 246, 250)",
    marginRight: "20px",
    marginTop: "20px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 252,
    fontSize: "1.2rem",
    margin: "5px 5px 0px 5px",
    fontFamily: "EYInterstate-Regular",
    // textTransform: "uppercase",
  },
  customTextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 333,
    fontSize: "1.2rem",
    margin: "5px 5px 0px 5px",
    fontFamily: "EYInterstate-Regular",
    // textTransform: "uppercase",
  },
  focused: {
    borderColor: "yellow",
  },
  helper: {
    marginLeft: "10px",
  },
}));

const DateCalender = (props) => {
  const [openTo, setOpenTo] = React.useState("date");
  const classes = useStyles();
  useEffect(() => {
    // console.log(props.varName, props.defaultValue);
  }, [props]);
  return (
    // <MuiPickersUtilsProvider locale={es} utils={DateFnsUtils}>
    <FormControl
      className="dateTimeCalender"
      xs={12}
      sm={6}
      disabled={props.disabled}
      classes={{
        filled: classes.selectroot,
        root: classes.formctrl,
      }}
      // locale={es}
      error={props.errorMessage[props.varName] !== ""}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          variant="inline"
          // id={props.label}
          label={props.label}
          // type={"datetime-local"}
          disabled={props.disabled}
          // ampm={true}
          value={props.defaultValue}
          autoOk={true}
          className={props.customTextField ? classes.customTextField : classes.textField}
          // minDate={props.minDate}
          // maxDate={props.maxDate}
          // minDateMessage={props.minDateMessage}
          // maxDateMessage={props.maxDateMessage}
          format="dd-MMM-yyyy h:mm aa"
          placeholder="DD-MMM-YYYY HH:MM"
          disablePast={props.disablePast}
          openTo={openTo}
          onChange={(date) => {
            // setOpen(false);
            props.dateTimeOnChange(props.varName, date);
          }}
          views={["date", "month", "year", "hours", "minutes"]}
          classes={{
            // root: classes.root,
            focused: classes.focused,
            root: classes.labelroot,
            label: classes.labelroot,
            // The list of keys you pass here depend on your variant
            // If for example you used variant="outlined", then you need
            // to check the CSS API of the OutlinedInput.
          }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            classes: { root: classes.inputRoot },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  classes={{
                    root: `${props.disabled ? "iconDisableRoot" : "iconroot"}`,
                  }}>
                  <DateRangeIcon
                    onClick={() => {
                      setOpenTo("date");
                    }}
                  />
                </IconButton>
                <IconButton
                  classes={{
                    root: `${props.disabled ? "iconDisableRoot" : "iconroot"}`,
                  }}>
                  <AccessAlarm
                    onClick={() => {
                      setOpenTo("hours");
                    }}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
          required={props.required ? true : false}
        />
      </MuiPickersUtilsProvider>
      <FormHelperText
        classes={{
          root: classes.helper,
        }}>
        {props.errorMessage[props.varName]}
      </FormHelperText>
    </FormControl>
    //
  );
};
export default DateCalender;

// "/* datetime-local" */
