import React, { useState } from "react";
import { Box, Button, Modal, TextareaAutosize } from "@material-ui/core";
import { images } from "../../utils/groupImage";
import "./NotificationDialog.scss";

const useStyles = () => ({
  dialogPaper: {
    borderRadius: 0,
    width: "27vw",
  },
});

const tabs = [
  { id: 1, label: 1, type: "a" },
  { id: 2, label: 2, type: "b" },
  { id: 3, label: 3, type: "c" },
  { id: 4, label: 4, type: "d" },
];

const NotificationDialog = (props) => {
  const classes = useStyles();
  const { open, handleDialogClose } = props;
  const [tabList, setTabs] = useState(tabs);
  const [type, setType] = useState("a");
  // console.log("tabs", type);
  const [status, changeStatus] = useState("");
  const [approvalOps, setApprovalOps] = useState({
    dashboard: false,
    sf: false,
  });
  const handleApproveUpdate = (name, value) => {
    let tempObj = { ...approvalOps };
    // console.log("temp", tempObj, value, name);
    tempObj[name] = value;
    setApprovalOps(tempObj);
  };
  return (
    <div>
      <Modal
        aria-labelledby="approval-modal-title"
        aria-describedby="approval-modal-description"
        open={open}
        onClose={(e) => handleDialogClose()}
        hideBackdrop={false}
        disableEscapeKeyDown={true}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <div className={"notificationPopup"}>
          <div className="header">
            <Box color="rgba(46,46,56,.9)">LEAD 4D</Box>
            <i
              className="icon-close-filled closeIcon"
              onClick={(e) => handleDialogClose()}
            />
          </div>
          <div className="content">
            <div className="tabs">
              {tabList &&
                tabList.map((tab) => (
                  <div
                    className={tab.type === type ? "tab selected-tab" : "tab"}
                    key={tab.id}
                    onClick={() => setType(tab.type)}>
                    <div className={tab.type === type ? "msg" : "msg-non"}>
                      <p>{tab.label}</p>
                    </div>
                  </div>
                ))}
            </div>
            <div className="tab-content">
              {type === "b" && <TypeANotification />}
              {type === "a" && (
                <TypeBNotification
                  status={status}
                  changeStatus={changeStatus}
                  approvalOps={approvalOps}
                  handleApproveUpdate={handleApproveUpdate}
                />
              )}
              {type === "c" && <TypeCNotification />}
              {type === "d" && <TypeDNotification />}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NotificationDialog;

const TypeANotification = () => {
  return (
    <div>
      <div className="profile-sec">
        <div className="user">
          <div>
            <img
              src={
                "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
              }
              alt="user"
              className="image"
            />
          </div>
          <Box marginLeft="3px">
            <Box color="#B9B9C2" fontSize="10px">
              Reviewer
            </Box>
            <Box className="name">Edward Mitchell</Box>
          </Box>
        </div>
        <div className="dates">
          <div>
            Re-submitted On: <span>1:27 pm, 27/04/21</span>
          </div>
          <div>
            Review On: <span>--</span>
          </div>
        </div>
      </div>
      <div className="comment" style={{ minHeight: 100 }}>
        <Box mb={1}>Comments</Box>
        <div>--</div>
      </div>
      <div className="status">
        <Box mb={1}>Status</Box>
        <div>--</div>
      </div>
    </div>
  );
};

const TypeBNotification = (props) => {
  const { status, changeStatus, approvalOps, handleApproveUpdate } = props;
  return (
    <div>
      <div className="profile-sec">
        <div className="user">
          <div>
            <img
              src={
                "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
              }
              alt="user"
              className="image"
            />
          </div>
          <Box marginLeft="3px">
            <Box className="name">Victor Williams</Box>
            <Box color="#B9B9C2" fontSize="10px">
              PPMD
            </Box>
          </Box>
        </div>
        <div className="dates">
          <div>
            Submitted On: <span>1:27 pm, 27/04/21</span>
          </div>
          <div>
            Review On: <span>2:35 pm, 07/05/21</span>
          </div>
        </div>
      </div>
      <div className="comment">
        <Box mb={1}>Status</Box>
        <div className="notificationRadioGroup">
          {status === "Yes" ? (
            <i className="icon-radio-checked" />
          ) : (
            <i
              className="icon-radio-unchecked"
              onClick={() => changeStatus("Yes")}
            />
          )}
          <span>{"Yes, Approve"}</span>
          {status === "No" ? (
            <i className="icon-radio-checked" />
          ) : (
            <i
              className="icon-radio-unchecked"
              onClick={() => changeStatus("No")}
            />
          )}
          <span>{"Changes Required"}</span>
        </div>
      </div>
      {status === "Yes" && (
        <div className="status">
          <Box marginBottom="5px">Select below option for approve</Box>
          <div className="checkList">
            <div className="check">
              {approvalOps.dashboard ? (
                <i
                  className="icon-checked-filled"
                  onClick={() => handleApproveUpdate("dashboard", false)}
                />
              ) : (
                <i
                  className="icon-check-unchecked"
                  onClick={() => handleApproveUpdate("dashboard", true)}
                />
              )}
              <span>
                {
                  "In my opinion, this dashboard reasonably reflects the impact actions taken to date."
                }
              </span>
            </div>
            <div className="check">
              {approvalOps.sf ? (
                <i
                  className="icon-checked-filled"
                  onClick={() => handleApproveUpdate("sf", false)}
                />
              ) : (
                <i
                  className="icon-check-unchecked"
                  onClick={() => handleApproveUpdate("sf", true)}
                />
              )}
              <span>
                {
                  "The feedback received in Success Factors is consistent with the information contained in the self-assessment."
                }
              </span>
            </div>
          </div>
        </div>
      )}
      <Box
        marginLeft="0"
        marginTop={status !== "Yes" ? 0 : "12px"}
        marginBottom="12px"
        marginRight="9px">
        <TextareaAutosize
          placeholder="Comment"
          rowsMin="3"
          className="text-area-style"
        />
      </Box>
      <Box textAlign="right">
        <Button variant="contained" className="send">
          Send
        </Button>
      </Box>
    </div>
  );
};

const TypeCNotification = () => {
  return (
    <div>
      <div className="profile-sec">
        <div className="user">
          <div>
            <img
              src={
                "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
              }
              alt="user"
              className="image"
            />
          </div>
          <Box marginLeft="3px">
            <Box className="name">Victor Williams</Box>
            <Box color="#B9B9C2" fontSize="10px">
              PPMD
            </Box>
          </Box>
        </div>
        <div className="dates">
          <div>
            Submitted On: <span>1:27 pm, 27/04/21</span>
          </div>
          <div>
            Review On: <span>2:35 pm, 07/05/21</span>
          </div>
        </div>
      </div>
      <div className="comment">
        <Box mb={1}>Comments</Box>
        <div className="value-text">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </div>
      </div>
      <div className="status">
        <Box mb={1}>Status</Box>
        <div className="value-text">Requested Changes</div>
      </div>
    </div>
  );
};

const TypeDNotification = (props) => {
  return (
    <div>
      <div className="profile-sec">
        <div className="user">
          <div>
            <img
              src={
                "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
              }
              alt="user"
              className="image"
            />
          </div>
          <Box marginLeft="3px">
            <Box color="#B9B9C2" fontSize="10px">
              Reviewer
            </Box>
            <Box className="name">Edward Mitchell</Box>
          </Box>
        </div>
        <div className="dates">
          <div>
            Re-submitted On: <span>1:27 pm, 27/04/21</span>
          </div>
          <div>
            Review On: <span>2:35 pm, 07/05/21</span>
          </div>
        </div>
      </div>
      <div className="comment">
        <Box mb={1}>Comments</Box>
        <div className="value-text">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book.
        </div>
      </div>
      <div className="comment" style={{ paddingTop: 0 }}>
        <Box className="value-text">
          Quality: Lorem Ipsum is simply dummy text of the printing and
          typesetting industry. Lorem Ipsum has been the industry's standard
          dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to make a type specimen book.
        </Box>
      </div>
      <div className="status">
        <Box mb={1}>Status</Box>
        <Box color="#4A95D9" fontSize="10px">
          Approved
        </Box>
      </div>
    </div>
  );
};
