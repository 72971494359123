import { InputLabel, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { Flip, toast } from 'react-toastify';
import SimpleBar from "simplebar-react";
import _ from 'lodash';
import { EDIT_EMAIL_TEMPLATE, EDIT_EMAIL_TEMPLATE_RESET, GET_EMAIL_TEMPLATE } from '../../../../actions/types';
import Loader from '../../../Loader/Loader';
import QuillEditor from '../QuillEditor/QuillEditor';
import EmailPreview from './EmailPreview';
import { placeholders } from '../QuillEditor/constant';
import { OUTSIDE_CLICK_COMMON } from '../../../../actions/types';

const useStyles = makeStyles((theme) => ({
    root1: {
        "& .MuiFilledInput-root": {
            background: "rgb(246,246,250)",
            borderRadius: "0px",
            fontFamily: "EYInterState-Regular",
        },
        "& .MuiFilledInput-underline": {
            width: "250px",
            height: "27px",
            //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
            //   borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
            "&:after": {
                // The MUI source seems to use this but it doesn't work
                borderBottom: "none",
            },
            //   "&:hover:not($disabled):not($focused):not($error):before": {
            //     borderBottom: "none",
            //   },
            " &:hover:before": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
            },
            " &:hover:after": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
            },
        },

        "& .MuiFilledInput-input": {
            padding: "8px 10px 8px 6px",
            fontSize: "12px",
            lineHeight: "15px",
        },
        "& .MuiFilledInput-multiline": {
            padding: "10px  10px 6px  0px",
        },
    },
    // labelroot: {
    //   backgroundColor: "rgb(246 ,246, 250)!important",
    //   color: "rgba(0, 0, 0, 0.94)",
    //   paddingLeft: "5px",
    //   paddingTop: "5px",
    //   fontSize: "8px",
    // },
    // topSpacing: {
    //   marginTop: "16px",
    // },
    // topSyli: {
    //   marginBottom: " -30px",
    //   marginTop: "16px",
    //   zIndex: "8",
    //   width: "115px",
    // },
    // half: {
    //   display: "flex",
    //   flexDirection: "row",
    //   width: "350px",
    //   marginLeft: "15px",
    // },
    root900: {
        "& .MuiFilledInput-root": {
            background: "rgb(246,246,250)",
            borderRadius: "0px",
            fontFamily: "EYInterState-Regular",
        },
        "& .MuiFilledInput-underline": {
            width: "870px",
            "&:after": {
                // The MUI source seems to use this but it doesn't work
                borderBottom: "none",
            },
            "&:hover:not($disabled):not($focused):not($error):before": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
            },
        },

        "& .MuiFilledInput-input": {
            padding: "8px 10px 8px 6px",
            fontSize: "12px",
            lineHeight: "15px",
            fontFamily: "EYInterState-Regular",
        },
        "& .MuiFilledInput-multiline": {
            padding: "10px  10px 6px  0px",
            fontFamily: "EYInterState-Regular",
        },
    },
    root: {
        "& .MuiFilledInput-root": {
            background: "rgb(246,246,250)",
            borderRadius: "0px",
        },
        "& .MuiFilledInput-underline": {
            width: "400px",
            //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
            "&:after": {
                // The MUI source seems to use this but it doesn't work
                borderBottom: "none",
            },
            "&:hover:not($disabled):not($focused):not($error):before": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
            },
        },

        "& .MuiFilledInput-input": {
            padding: "8px 10px 8px 6px",
            fontSize: "12px",
            lineHeight: "15px",
            fontFamily: "EYInterState-Regular",
        },
        "& .MuiFilledInput-multiline": {
            padding: "10px  10px 6px  0px",
            fontFamily: "EYInterState-Regular",
        },
    },
    rootMulti: {
        "& .MuiFilledInput-root": {
            background: "rgb(246,246,250)",
            borderRadius: "0px",
        },
        "& .MuiFilledInput-underline": {
            width: "677px",
            //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
            "&:after": {
                // The MUI source seems to use this but it doesn't work
                borderBottom: "none",
            },
            "&:hover:not($disabled):not($focused):not($error):before": {
                borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
            },
        },

        "& .MuiFilledInput-input": {
            padding: "8px 10px 8px 6px",
            fontSize: "12px",
            lineHeight: "15px",
            fontFamily: "EYInterState-Regular",
        },
        "& .MuiFilledInput-multiline": {
            padding: "10px  10px 6px  0px",
        },
    },
    labelroot: {
        backgroundColor: "rgb(246 ,246, 250)!important",
        color: "rgba(0, 0, 0, 0.94)",
        paddingLeft: "5px",
        paddingTop: "5px",
        fontSize: "8px",
        // fontWeight: "600",
    },
    labelroot1: {
        backgroundColor: "rgb(246 ,246, 250)!important",
        color: "rgba(0, 0, 0, 0.94)",
        paddingLeft: "5px",
        paddingTop: "5px",
        fontSize: "10px",
        fontWeight: "600",
    },
    topSpacing: {
        marginTop: "8px",
    },
    topSyli: {
        marginBottom: " -30px",
        marginTop: "16px",
        zIndex: "8",
        width: "100px",
    },
    half: {
        display: "flex",
        flexDirection: "row",
    },
    arrow: {
        position: "absolute",
        fontSize: 14,
        width: "6em",
        height: "4em",
        //   transform: "rotate(225deg)",
        "&::before": {
            content: '""',
            margin: "auto",
            display: "block",
            width: 0,
            height: 0,
            borderStyle: "solid",
        },
    },
    paper2: {
        backgroundColor: "white",
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        // padding: theme.spacing(2, 2, 2),
        width: 170,
        overflow: "hidden",
        overflowX: "hidden",
        fontSize: "11px",
    },
    options: {
        borderBottom: "1px solid grey",
        padding: "5px 0px 5px 5px",
        display: "flex",
        alignItems: "center",
        height: "24px",
        cursor: "pointer",
    },
    popper: {
        // zIndex: 1,
        left: "-55px!important",
        '&[x-placement*="top-end"]': {
            top: "-12px!important",
        },
        '&[x-placement*="bottom-end"]': {
            top: "9px!important",
        },
        '&[x-placement*="bottom"] $arrow': {
            top: 2,
            left: "105px!important",
            marginTop: "-0.9em",
            width: "6em",
            height: "2em",
            "&::before": {
                borderWidth: "0 1em 1em 1em",
                borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: "103px!important",
            marginBottom: "-3.6em",
            width: "6em",
            height: "4em",
            "&::before": {
                borderWidth: "1em 1em 0 1em",
                borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: "-0.9em",
            width: "6em",
            height: "4em",
            "&::before": {
                borderWidth: "1em 1em 1em 0",
                borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: "-0.9em",
            width: "6em",
            height: "4em",
            "&::before": {
                borderWidth: "1em 0 1em 1em",
                borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
            },
        },
    },
}));


const EmailEvents = (props) => {
    const { setIsChangesInChild, setInsideNavigation } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const emailTemplateData = useSelector((state) => (state.getEmailTemplate)) || {};
    const editEmailTemplateData = useSelector((state) => (state.editEmailTemplate)) || {};
    const { data: emailTemplates = [] } = emailTemplateData.data || {};
    const [loader, setLoader] = useState(true);
    const [isOpen, setOpen] = useState(false);
    const [saveClicked, setSaveClicked] = useState(0);
    const [activeTab, setActiveTab] = useState('User');
    const [selectedEmailTemplateIndex, setSelectedEmailTemplateIndex] = useState(0);
    const [selectedEmailTemplate, setSelectedEmailTemplate] = useState({});
    const { subject = '', templateName = '', content = '', dynamicFields = {} } = selectedEmailTemplate || {};
    const [previewContent, setPreviewContent] = useState('');
    const outsideClickState = useSelector((state) => state.outsideClickCommon);

    useEffect(() => {
        dispatch({
            type: GET_EMAIL_TEMPLATE,
            payload: {
                type: activeTab
            },
        });
        setSelectedEmailTemplateIndex(0)
    }, [dispatch, activeTab]);

    useEffect(() => {        
        setSelectedEmailTemplate(emailTemplates[selectedEmailTemplateIndex] || {})       
        setPreviewContent(emailTemplates[selectedEmailTemplateIndex] && emailTemplates[selectedEmailTemplateIndex].content || '')
    }, [emailTemplates, selectedEmailTemplateIndex]);

    useEffect(() => {
        if (
            emailTemplateData &&
            emailTemplateData.data &&
            !emailTemplateData.fetching
        ) {
            setLoader(false);
        } else if (
            emailTemplateData &&
            emailTemplateData.data &&
            emailTemplateData.fetching
        ) {
            setLoader(true);
        }
    }, [emailTemplateData]);

    useEffect(() => {
        if (
            editEmailTemplateData &&
            editEmailTemplateData.data &&
            editEmailTemplateData.data.status === 'success' &&
            !editEmailTemplateData.fetching
        ) {
            dispatch({
                type: EDIT_EMAIL_TEMPLATE_RESET
            });
            toast.warn(
                <div>
                    <i className="icon-check-outline" />
                    {'Email template updated successfully'}
                </div>,
                {
                    position: "top-center",
                    transition: Flip,
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
        } else if (
            editEmailTemplateData &&
            editEmailTemplateData.data &&
            editEmailTemplateData.fetching
        ) {
            setLoader(true);
        }
    }, [editEmailTemplateData]);

    const updateEmailTemplate = (type, value) => {
        localChanges(true);
        setSelectedEmailTemplate(
            {
                ...selectedEmailTemplate,
                [type]: value
            }
        );
    }

    const onSave = (content) => {
        const payloadObj = {
            templateName,
            subject,
            content,
            type: activeTab
        }
        localChanges(false);
        dispatch({
            type: EDIT_EMAIL_TEMPLATE,
            payload: { payloadObj }
        });
    }

    const localChanges = (status) => {
        setIsChangesInChild(status);
        dispatch({
            type: OUTSIDE_CLICK_COMMON,
            payload: {
                feature: "EmailConfiguration",
                changesDone: status,
                OpenModal: "",
                ProceedWithNavigation: "",
            },
        });
    }

    const checkOutsideClick = (fn, params) => {
        setInsideNavigation(true);
        if (props.history.location.pathname === "/Admin/EmailConfiguration") {
            if (
                outsideClickState.data &&
                outsideClickState.data.feature !== "" &&
                outsideClickState.data.changesDone
            ) {
                dispatch({
                    type: "OUTSIDE_CLICK_COMMON_OPEN_MODEL",
                    payload: {
                        callback: { fn: fn, params: params },
                    },
                });
            } else {
                fn && fn(params);
            }
        }
    }

    const [maxHeight, setmaxHeight] = useState()
    const ref = useRef(null)

    useEffect(() => {
        setmaxHeight(ref.current.clientHeight - 46) // height of the header removed 
    }, [])

    return (
        <>
            {loader && <Loader />}
            <div className='email-configuration-container'>
                <div className='admin-content'>
                    <div className="third-header admin-crumbs ">
                        <div className="tabSection">
                            <div
                                className={
                                    "  tabStyling" + (activeTab === 'User' ? " selected-tab " : "")
                                }
                                onClick={() => {
                                    checkOutsideClick(setActiveTab, 'User')
                                }}>
                                <div className={
                                    (activeTab === 'User' ? " selected-text " : " unSelected-text ")
                                }>
                                    Email Events - User
                                </div>
                            </div>
                            <div
                                className={
                                    "  tabStyling" + (activeTab === 'Admin' ? " selected-tab " : "")
                                }
                                onClick={() => {
                                    checkOutsideClick(setActiveTab, 'Admin')
                                }}>
                                <div className={
                                    (activeTab === 'Admin' ? " selected-text " : " unSelected-text ")
                                }>
                                    Email Events - Admin
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="addNewGlobal">
                    <div className={"lowerDiv"}>
                        <div className="goal-details">
                            <div className="goal-list" ref={ref}>
                                <div>
                                    <div
                                        className="header">
                                        {" "}
                                        Email Events
                                    </div>
                                    <SimpleBar style={{
                                        maxHeight: maxHeight
                                    }}>
                                        <div>
                                            <>
                                                {emailTemplates.map((item, index) => (
                                                    <>
                                                        <div
                                                            // className={"dragged-card"}
                                                            className={
                                                                "goal-card " +
                                                                (index === selectedEmailTemplateIndex
                                                                    ? " selected-card"
                                                                    : " ")
                                                            }
                                                            style={{ display: "flex" }}
                                                            onClick={() => {
                                                                checkOutsideClick(setSelectedEmailTemplateIndex, index);
                                                            }}>
                                                            {item.description || ''}
                                                            <div style={{ marginLeft: "auto" }}>
                                                                <i className="icon-chevron-right" />
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                            </>
                                        </div>
                                    </SimpleBar>
                                </div>
                            </div>
                            <div className={"statements" + (!true ? " cent" : "")}>

                                <>
                                    <div className="statement-header">
                                        <div
                                            className="sub-header">
                                            {"Email Subject"}
                                        </div>
                                        <div className={"btn-sec"}>
                                            {/* <div class="tooltip" style={{marginTop : '5px'}}>
                                                <i class="icon-info-outline" />
                                                <span class="tooltiptext">Tooltip text</span>
                                            </div> */}
                                            <div
                                                className={
                                                    "btn" +
                                                    (!true
                                                        ? " opacityReduced "
                                                        : "")
                                                }
                                                style={{
                                                    cursor: `${true
                                                        ? " pointer"
                                                        : "auto"
                                                        }`,
                                                    opacity: `${true ? 1 : 0.5
                                                        }`,
                                                }}
                                                onClick={() => {
                                                    setOpen(true);
                                                }}>
                                                <i
                                                    className="eyaa-image-25"
                                                    style={{ marginRight: "5px" }}
                                                />
                                                Preview
                                            </div>
                                            {activeTab === 'User' ? <div
                                                className={
                                                    "btn" +
                                                    (!true
                                                        ? " opacityReduced "
                                                        : "")
                                                }
                                                style={{
                                                    cursor: `${true
                                                        ? " pointer"
                                                        : "auto"
                                                        }`,
                                                    opacity: `${true ? 1 : 0.5
                                                        }`,
                                                }}
                                                onClick={() => {
                                                    setSaveClicked(saveClicked + 1)
                                                }}>
                                                <i
                                                    className={"icon-web-071"}
                                                    style={{ marginRight: "5px" }}
                                                />
                                                Save
                                            </div> : null}
                                        </div>
                                    </div>
                                    <div className={classes.half}>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                            }}>
                                            <InputLabel
                                                htmlFor="uncontrolled-native"
                                                className={classes.topSpacing}
                                                classes={{
                                                    root: classes.labelroot,
                                                }}>
                                                {"Email Subject*"}
                                            </InputLabel>
                                            <TextField
                                                fullWidth
                                                inputProps={{ maxLength: 200 }}
                                                id="filled-basic"
                                                variant="filled"
                                                className={classes.root900}
                                                required={true}
                                                value={subject}
                                                disabled={activeTab !== 'User'}
                                                onChange={(e) => {
                                                    updateEmailTemplate('subject', e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ padding: '10px', height: '74%' }}>
                                        <QuillEditor
                                            text={content}
                                            onSave={onSave}
                                            saveClicked={saveClicked}
                                            placeholders={placeholders}
                                            onChange={(template) => {
                                                if (activeTab === 'User' && !_.isEmpty(selectedEmailTemplate.content) && !_.isEmpty(template) && selectedEmailTemplate.content !== template.replaceAll('&lt;', '{{').replaceAll('&gt;', '}}'))
                                                    localChanges(true);
                                                setPreviewContent(template)
                                            }}
                                            readOnly={activeTab !== 'User'}
                                        />
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='email-template-modal'>
                <Modal
                    open={isOpen}
                    onClose={() => {
                        setOpen(false);
                    }}
                    styles={{
                        modal: {
                            maxWidth: "unset",
                            padding: "unset",
                        },
                        overlay: {
                            background: "rgba(0, 0, 0, 0.5)",
                        }
                    }}
                    center
                >
                    <div className="email-template-wrap">
                        <div className="modal-heading" >
                            Email Preview
                        </div>
                        <SimpleBar
                            style={{
                                overflowX: "hidden",
                                maxHeight: "80vh",
                                width: '70vw',
                            }}
                        >
                            <div style={{ minHeight: "80vh", padding: '10px' }}>
                                <EmailPreview content={previewContent} />
                            </div>
                        </SimpleBar>
                    </div>
                </Modal>
            </div>
        </>
    );
}

export default EmailEvents
