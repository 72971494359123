import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import "./PillarWiseComponent.scss";

const PillarWiseComponent = (props) => {
  const { data } = props;
  const [selectedId, setSelectedId] = useState(0);
  const [selectedSectionId, setselectedSectionId] = useState(0);
  //Stmnts not vailable in asseemnet data
  const statments = {
    0: [
      "I am leading my team in demonstrating a data-first mindset,starting with performing risk assessments using automated techniques",
      "I am leading my team in challenging the audit plan using the substantive evaluation matrix (SEM), as applicable, ensuring that it is responsive to the identified risks and demonstrating confidence to remove procedures that are redundant.",
      "I am leading each of my EY Private engagement teams in the adoption of all relevant methodology simplification guidance such as that used for determining Planning Materiality, limited risk accounts, Noncomplex Entity (NCE) approach and sampling",
      "I am leading in contributing to the development and/or implementation of new ways to work smarter in the area of risk assessment, leveraging data and technology, resulting in more effective/efficient audit efforts.",
    ],
    1: [
      "I am leading my team in demonstrating a data-first mindset,starting with performing risk assessments using automated techniques",
      "I am leading in contributing to the development and/or implementation of new ways to work smarter in the area of risk assessment, leveraging data and technology, resulting in more effective/efficient audit efforts.",
    ],
    2: [],
  };
  return (
    <div className="pillar-wise">
      <div className="goal">
        {data.map((item, index) => (
          <div
            className="vertical-listing"
            onClick={() => {
              setSelectedId(index);
              setselectedSectionId(0);
            }}>
            <div
              className={
                "goal-card " + (selectedId !== index ? " " : " selected-card")
              }>
              {item.goalName} <i className="icon-chevron-right" />
            </div>
          </div>
        ))}
      </div>
      <div className="section">
        {data[selectedId].sectionValues.length > 0 ? (
          <div className="horizontal-listing">
            {data[selectedId].sectionValues.length > 0 ? (
              data[selectedId].sectionValues.map((item2, index2) => (
                <div
                  onClick={() => {
                    setselectedSectionId(index2);
                  }}
                  className={
                    "tabname " +
                    (selectedSectionId === index2 ? " selected-tabName " : " ")
                  }>
                  {item2.name}
                </div>
              ))
            ) : (
              <div className={"nodata"}>No Section Data Available</div>
            )}
          </div>
        ) : null}

        <div className="listing">
          {/* <SimpleBar style={{ maxHeight: "200px" }}> */}
          {statments[selectedSectionId].length > 0 ? (
            statments[selectedSectionId].map((item3) => (
              <div className="statment-card">{item3}</div>
            ))
          ) : (
            <div
              className={"nodata"}
              style={{ marginTop: "-20px", fontSize: "12px" }}>
              Statement list is empty.
            </div>
          )}
          {/* </SimpleBar> */}
        </div>
      </div>
    </div>
  );
};

export default withRouter(PillarWiseComponent);
