import React, { useCallback, useState, useEffect, useContext, useMemo } from "react";
import SideMenu from "../../SideMenu/SideMenu";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import "../Admin.scss";
import _ from "lodash";
import "./UserManagement.scss";
import * as myConsts from "../../../constants/constants";
import ResetBobAssessment from "./ResetBobAssessment/ResetBobAssessment";
import ResetDiscussionGuide from "./ResetDiscussionGuide/ResetDiscussionGuide";
import ManageUser from "./ManageUser/ManageUser";
import RollForwardUser from "./RollForwardUsers/RollForwardUsers";
import AddMultipleUsers from "./AddMultipleUsers/AddMultipleUsers";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppContext from "../../../utils/appContext";
import ChangeManagement from "./ChangeManagement/ChangeManagement";
import { Tooltip } from "@material-ui/core";
import ViewUserProfiles from "./ViewUserProfiles/ViewUserProfiles";
import { makeStyles } from "@material-ui/core/styles";
import DeleteMultipleUsers from "./DeleteMultipleUsers/DeleteMultipleUsers";

/* Admin can search the user by either GPN or mail id. Then admin can reset the BoB or Assessment if 
their status is submitted. otherwise the radio buttons are disabled. if both status are submitted, then 
both radio button will be enabled */

const UserManagement = (props) => {
  const myContext = useContext(AppContext);
  const dispatch = useDispatch();
  const crumbs = [{ name: "Admin", path: myConsts.routeAdmin }];
  const viewUserData = useSelector((state) => state.setViewUserData);
  const [isMainPageVisible, setIsMainPageVisible] = useState(
    sessionStorage.getItem("currentPageName")
      ? sessionStorage.getItem("currentPageName") !== "User Management"
        ? false
        : true
      : true
  );
  const [currentPage, setCurrentPage] = useState(
    sessionStorage.getItem("currentPageName")
      ? //..DISABLE BOB :: COMMENTED BELOW CODE IF IT WILL REQUIRE TO ENABLE IN FUTURE
      //sessionStorage.getItem("currentPageName") === "Reset Bob and Assessment"
      sessionStorage.getItem("currentPageName") === "Reset Assessment"
        ? 1
        : sessionStorage.getItem("currentPageName") === "Roll Forward User"
          ? 2
          : sessionStorage.getItem("currentPageName") === "Manage User"
            ? 3
            : sessionStorage.getItem("currentPageName") === "Add Multiple Users"
              ? 4
              : sessionStorage.getItem("currentPageName") ===
                "View User Detail Changes"
                ? 5
                : sessionStorage.getItem("currentPageName") ===
                  "View User Profiles"
                  ? 6
                  : sessionStorage.getItem("currentPageName") ===
                    "Delete Multiple Users"
                    ? 7
                    : null
      : null
  );
  const useStyles = makeStyles((theme) => ({
    paper: {
      border: "1px solid #42425ad6",
      fontSize: "11px",
      padding: "15px",
      maxWidth: "750px",
      backgroundColor: theme.palette.background.paper,
    },
    tooltip: {
      fontSize: 10,
    },
  }));
  const classes = useStyles();
  const [currentPageName, setCurrentPageName] = useState(
    sessionStorage.getItem("currentPageName") || "User Management"
  );
  const [isChangesInChild, setIsChangesInChild] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isBackArrowClicked, setIsBackArrowClicked] = useState(false);
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  const userProfile = useSelector((state) => state.userProfile);
  const isActivePhase =
    !_.isNull(assessmentMetaData?.data?.data?.activePhase) &&
    !_.isEmpty(assessmentMetaData?.data?.data?.activePhase);

  const currentUserId = useMemo(() => {
    return _.get(userProfile, "data[0].userId", "");
  }, [userProfile]);

  const setCurrentPageConfig = useCallback((pageNo) => {
    switch (pageNo) {
      case 1:
        //..DISABLE BOB :: COMMENTED BELOW CODE IF IT WILL REQUIRE TO ENABLE IN FUTURE
        // setCurrentPageName("Reset Bob and Assessment");
        // sessionStorage.setItem("currentPageName", "Reset Bob and Assessment");
        setCurrentPageName("Reset Assessment");
        sessionStorage.setItem("currentPageName", "Reset Assessment");
        break;
      case 2:
        setCurrentPageName("Roll Forward User");
        sessionStorage.setItem("currentPageName", "Roll Forward User");
        break;
      case 3:
        setCurrentPageName("Manage User");
        sessionStorage.setItem("currentPageName", "Manage User");
        break;
      case 4:
        setCurrentPageName("Add Multiple Users");
        sessionStorage.setItem("currentPageName", "Add Multiple Users");
        break;
      case 5:
        setCurrentPageName("View User Detail Changes");
        sessionStorage.setItem("currentPageName", "View User Detail Changes");
        break;
      case 6:
        setCurrentPageName("View User Profiles");
        sessionStorage.setItem("currentPageName", "View User Profiles");
        break;
      case 7:
        setCurrentPageName("Delete Multiple Users");
        sessionStorage.setItem("currentPageName", "Delete Multiple Users");
        break;
      case 8:
        setCurrentPageName("Reset Discussion Guide");
        sessionStorage.setItem("currentPageName", "Reset Discussion Guide");
        break;
      default:
        setCurrentPageName("");
        sessionStorage.setItem("currentPageName", "");
    }
    setCurrentPage(pageNo);
    setIsMainPageVisible(false);
    sessionStorage.setItem("viewUserProfile", false);
    if (
      myContext.activeFinancialYear &&
      myContext.activeFinancialYear !== null
    ) {
      myContext.setFinancialYear(myContext.activeFinancialYear);
      sessionStorage.setItem("financialYear", myContext.activeFinancialYear);
    } else {
      myContext.setFinancialYear(myContext.lastActiveFinancialYear);
      sessionStorage.setItem(
        "financialYear",
        myContext.lastActiveFinancialYear
      );
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: "GET_BOB_ROLLFORWARD_STATUS",
      payload: {},
    });
    myContext.setActivePPMDuserId("");
    sessionStorage.setItem("activePPMDuserId", "");
    if (currentUserId !== myContext.userId) {
      dispatch({
        type: "GET_USER_PROFILE_BY_ID",
        payload: {
          userId: myContext.userId || sessionStorage.userId,
          financialYear: myContext.financialYear,
        },
      });
      myContext.setIsRouteFromAdmin(false);
    }
  }, []);

  useEffect(() => {
    if (currentPageName === "User Management")
      dispatch({
        type: "CHECK_LOCK_STATUS_RESET",
        payload: {},
      });
    if (myContext.lockPopup) toast.dismiss();
  }, [currentPageName, dispatch]);

  useEffect(() => {
    if (sessionStorage.getItem("viewUserProfile") === "true") {
      setCurrentPageConfig(6);
    }
  }, [setCurrentPageConfig]);

  const getCurrentPage = useCallback(() => {
    switch (currentPage) {
      case 1:
        return (
          <ResetBobAssessment
            onClose={() => {
              setCurrentPageName("User Management");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "User Management");
            }}
          />
        );
      case 2:
        return (
          <RollForwardUser
            onClose={() => {
              setCurrentPageName("User Management");
              sessionStorage.setItem("currentPageName", "User Management");
              setIsMainPageVisible(true);
            }}
          />
        );
      case 3:
        return (
          <ManageUser changesInChild={(val) => setIsChangesInChild(val)} />
        );
      case 4:
        return (
          <AddMultipleUsers
            onClose={() => {
              setCurrentPageName("User Management");
              sessionStorage.setItem("currentPageName", "User Management");
              setIsMainPageVisible(true);
            }}
          />
        );
      case 5:
        return (
          <ChangeManagement
            onManageUser={() => {
              setCurrentPage(3);
            }}
          />
        );
      case 6:
        return <ViewUserProfiles history={props.history} />;
      case 7:
        return (
          <DeleteMultipleUsers
            history={props.history}
            changesInChild={(val) => setIsChangesInChild(val)}
            onClose={() => {
              setIsChangesInChild(false);
              setCurrentPageName("User Management");
              sessionStorage.setItem("currentPageName", "User Management");
              setIsMainPageVisible(true);
            }}
          />
        );
      case 8:
        return (
          <ResetDiscussionGuide
            onClose={() => {
              setCurrentPageName("User Management");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "User Management");
            }}
          />
        );
      default:
        return "";
    }
  }, [currentPage]);

  const onPopupConfirm = useCallback(() => {
    setIsChangesInChild(false);
    setIsPopupVisible(false);
    if (isBackArrowClicked) {
      if (!isMainPageVisible) {
        setCurrentPageName("User Management");
        setIsMainPageVisible(true);
        sessionStorage.setItem("currentPageName", "User Management");
      } else {
        props.history.push(myConsts.routeAdmin);
      }
    } else {
      setIsMainPageVisible(true);
      setCurrentPageName("User Management");
      sessionStorage.setItem("currentPageName", "User Management");
    }
  }, [isBackArrowClicked, isMainPageVisible]);

  const checkForChanges = useCallback(() => {
    if (isChangesInChild) {
      setIsPopupVisible(true);
    } else {
      onPopupConfirm();
    }
  }, [isChangesInChild, onPopupConfirm]);

  return (
    <>
      <div className="admin-container manage-users">
        <div className="admin-info-main-sidemenu">
          <SideMenu
            minimized={true}
            isminimizedMainMenu={true}
            isMainMenu={true}
            sideMenuItemSelect="Admin"
          />
        </div>
        <div className="admin-content">
          <div className="second-header admin-crumbs">
            <i
              className="icon-arrow-left"
              onClick={() => {
                setIsBackArrowClicked(true);
                checkForChanges();
              }}
            />
            <div className="admin-crumb-txt">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <Breadcrumb
                  crumbs={crumbs}
                  isAdmin={true}
                  isInnerAdmin={true}
                  {...props}
                />
                {!isMainPageVisible && (
                  <div className="icon-chevron-right iconStyling">
                    <a
                      className=" userMngmnt MuiTypography-root MuiLink-root MuiLink-underlineHover admin-crumb MuiTypography-colorInherit"
                      onClick={() => {
                        setIsBackArrowClicked(false);
                        checkForChanges();
                      }}>
                      User Management
                    </a>
                  </div>
                )}
              </div>
              <h4>{currentPageName}</h4>
            </div>
          </div>
          <div className="manage-users-content">
            <div className="main-content">
              {isMainPageVisible ? (
                <div className="card-block">
                  {
                    myContext.currentPeriod === myConsts.TimePeriodYearEnd && (
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={`${isActivePhase
                          ? ""
                          : "Will be enabled when a phase is made active"
                          }`}
                        placement="bottom-center">
                        <div
                          className={
                            "card " + (isActivePhase ? "" : "opacityReduced")
                          }
                          onClick={() => {
                            if (isActivePhase) setCurrentPageConfig(8);
                          }}>
                          <span className="icon-change-outline"></span>
                          <span>Reset Discussion Guide</span>
                        </div>
                      </Tooltip>
                    )
                  }
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={`${isActivePhase
                      ? ""
                      : "Will be enabled when a phase is made active"
                      }`}
                    placement="bottom-center">
                    <div
                      className={
                        "card " + (isActivePhase ? "" : "opacityReduced")
                      }
                      onClick={() => {
                        if (isActivePhase) setCurrentPageConfig(1);
                      }}>
                      <span className="icon-change-outline"></span>
                      {/* ..DISABLE BOB :: COMMENTED BELOW CODE IF IT WILL REQUIRE TO ENABLE IN FUTURE */}
                      {/* <span>Reset BOB and Assessment</span> */}
                      <span>Reset Assessment</span>
                    </div>
                  </Tooltip>
                  <div className="card" onClick={() => setCurrentPageConfig(2)}>
                    <span className="eyaa-web-063"></span>
                    <span>Roll Forward User</span>
                  </div>
                  <div className="card" onClick={() => setCurrentPageConfig(3)}>
                    <span className="eyaa-user-05"></span>
                    <span>Manage User</span>
                  </div>
                  <div className="card" onClick={() => setCurrentPageConfig(4)}>
                    <span className="eyaa-user-42"></span>
                    <span>Add Multiple Users</span>
                  </div>
                  <div className="card" onClick={() => setCurrentPageConfig(7)}>
                    <span className="eyaa-user-32"></span>
                    <span>Delete Multiple Users</span>
                  </div>
                  <div className="card" onClick={() => setCurrentPageConfig(5)}>
                    <span className="eyaa-web-307"></span>
                    <span>View User Detail Changes</span>
                  </div>
                  <div
                    className="card"
                    onClick={() => {
                      dispatch({
                        type: "SET_VIEW_USER_DATA",
                        payload: {
                          viewData: false,
                        },
                      });
                      setCurrentPageConfig(6);
                    }}>
                    <span className="eyaa-user-19"></span>
                    <span>View User Profiles</span>
                  </div>
                </div>
              ) : (
                /* <>
                  {currentPage === 1 ? (
                    <ResetBobAssessment onClose={() => setIsMainPageVisible(true)}/>
                  ) : (
                    <ManageUserList />
                  )}
                </>*/
                <>{getCurrentPage()}</>
              )}
            </div>
          </div>
        </div>
      </div>
      {isPopupVisible && (
        <div className="popup-dialog">
          <div className="dialog">
            <div className="header">
              <h5>Confirmation</h5>
              <span
                className="icon-close-filled icon"
                onClick={() => setIsPopupVisible(false)}></span>
            </div>
            <div className="content">
              Would you like to navigate without completing the operation?
              Please note that on confirming, incomplete actions will be lost.
            </div>
            <div className="footer">
              <button
                className="cancel-btn"
                onClick={() => setIsPopupVisible(false)}>
                Cancel
              </button>
              <button className="confirm-btn" onClick={onPopupConfirm}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default UserManagement;
