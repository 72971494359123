export const OUTSIDE_CLICK = "OUTSIDE_CLICK";
export const OUTSIDE_CLICK_RESET = "OUTSIDE_CLICK_RESET";
export const OUTSIDE_CLICK_TRUE = "OUTSIDE_CLICK_TRUE";
export const OUTSIDE_CLICK_CANCEL = "OUTSIDE_CLICK_CANCEL";

export const OUTSIDE_CLICK_ADMIN = "OUTSIDE_CLICK_ADMIN";
export const OUTSIDE_CLICK_ADMIN_RESET = "OUTSIDE_CLICK_ADMIN_RESET";
export const OUTSIDE_CLICK_ADMIN_TRUE = "OUTSIDE_CLICK_ADMIN_TRUE";
export const OUTSIDE_CLICK_ADMIN_CANCEL = "OUTSIDE_CLICK_ADMIN_CANCEL";

export const OUTSIDE_CLICK_BOB = "OUTSIDE_CLICK_BOB";
export const OUTSIDE_CLICK_BOB_RESET = "OUTSIDE_CLICK_BOB_RESET";
export const OUTSIDE_CLICK_BOB_TRUE = "OUTSIDE_CLICK_BOB_TRUE";
export const OUTSIDE_CLICK_BOB_CANCEL = "OUTSIDE_CLICK_BOB_CANCEL";

export const OUTSIDE_CLICK_COMMON = "OUTSIDE_CLICK_COMMON";
export const OUTSIDE_CLICK_COMMON_OPEN_MODEL =
  "OUTSIDE_CLICK_COMMON_OPEN_MODEL";
export const OUTSIDE_CLICK_COMMON_RESET = "OUTSIDE_CLICK_COMMON_RESET";
export const OUTSIDE_CLICK_COMMON_PROCEED = "OUTSIDE_CLICK_COMMON_PROCEED";

export const GET_ASSESSMENT_DATA = "GET_ASSESSMENT_DATA";
export const GET_ASSESSMENT_DATA_SUCCESS = "GET_ASSESSMENT_DATA_SUCCESS";
export const GET_ASSESSMENT_DATA_ERROR = "GET_ASSESSMENT_DATA_ERROR";
export const GET_ASSESSMENT_DATA_RESET = "GET_ASSESSMENT_DATA_RESET";

export const GET_USER_ASSESSMENT_DATA = "GET_USER_ASSESSMENT_DATA";
export const GET_USER_ASSESSMENT_DATA_SUCCESS =
  "GET_USER_ASSESSMENT_DATA_SUCCESS";
export const GET_USER_ASSESSMENT_DATA_ERROR = "GET_USER_ASSESSMENT_DATA_ERROR";
export const GET_USER_ASSESSMENT_DATA_RESET = "GET_USER_ASSESSMENT_DATA_RESET";

export const SAVE_USER_ASSESSMENT_DATA = "SAVE_USER_ASSESSMENT_DATA";
export const SAVE_USER_ASSESSMENT_DATA_SUCCESS =
  "SAVE_USER_ASSESSMENT_DATA_SUCCESS";
export const SAVE_USER_ASSESSMENT_DATA_ERROR =
  "SAVE_USER_ASSESSMENT_DATA_ERROR";
export const SAVE_USER_ASSESSMENT_DATA_RESET =
  "SAVE_USER_ASSESSMENT_DATA_RESET";

export const GET_ALL_USER_ASSESSMENT_DATA = "GET_ALL_USER_ASSESSMENT_DATA";
export const GET_ALL_USER_ASSESSMENT_DATA_SUCCESS =
  "GET_ALL_USER_ASSESSMENT_DATA_SUCCESS";
export const GET_ALL_USER_ASSESSMENT_DATA_ERROR =
  "GET_ALL_USER_ASSESSMENT_DATA_ERROR";
export const GET_ALL_USER_ASSESSMENT_DATA_RESET =
  "GET_ALL_USER_ASSESSMENT_DATA_RESET";

export const GET_ALL_ASSESSMENT_DATA = "GET_ALL_ASSESSMENT_DATA";
export const GET_ALL_ASSESSMENT_DATA_SUCCESS =
  "GET_ALL_ASSESSMENT_DATA_SUCCESS";
export const GET_ALL_ASSESSMENT_DATA_ERROR = "GET_ALL_ASSESSMENT_DATA_ERROR";
export const GET_ALL_ASSESSMENT_DATA_RESET = "GET_ALL_ASSESSMENT_DATA_RESET";

export const GET_ALL_PREV_ASSESSMENT_DATA = "GET_ALL_PREV_ASSESSMENT_DATA";
export const GET_ALL_PREV_ASSESSMENT_DATA_SUCCESS =
  "GET_ALL_PREV_ASSESSMENT_DATA_SUCCESS";
export const GET_ALL_PREV_ASSESSMENT_DATA_ERROR =
  "GET_ALL_PREV_ASSESSMENT_DATA_ERROR";
export const GET_ALL_PREV_ASSESSMENT_DATA_RESET =
  "GET_ALL_PREV_ASSESSMENT_DATA_RESET";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_ERROR = "GET_USER_DETAILS_ERROR";
export const GET_USER_DETAILS_RESET = "GET_USER_DETAILS_RESET";

export const GET_USER_IMG = "GET_USER_IMG";
export const GET_USER_IMG_SUCCESS = "GET_USER_IMG_SUCCESS";
export const GET_USER_IMG_ERROR = "GET_USER_IMG_ERROR";
export const GET_USER_IMG_RESET = "GET_USER_IMG_RESET";

export const GET_OPTION_VALUES = "GET_OPTION_VALUES";
export const GET_OPTION_VALUES_SUCCESS = "GET_OPTION_VALUES_SUCCESS";
export const GET_OPTION_VALUES_ERROR = "GET_OPTION_VALUES_ERROR";
export const GET_OPTION_VALUES_RESET = "GET_OPTION_VALUES_RESET";

export const GET_ASSESSMENT_META_DATA = "GET_ASSESSMENT_META_DATA";
export const GET_ASSESSMENT_META_DATA_SUCCESS =
  "GET_ASSESSMENT_META_DATA_SUCCESS";
export const GET_ASSESSMENT_META_DATA_ERROR = "GET_ASSESSMENT_META_DATA_ERROR";
export const GET_ASSESSMENT_META_DATA_RESET = "GET_ASSESSMENT_META_DATA_RESET";

export const MARK_ASSESSMENT_START = "MARK_ASSESSMENT_START";
export const MARK_ASSESSMENT_START_SUCCESS = "MARK_ASSESSMENT_START_SUCCESS";
export const MARK_ASSESSMENT_START_ERROR = "MARK_ASSESSMENT_START_ERROR";
export const MARK_ASSESSMENT_START_RESET = "MARK_ASSESSMENT_START_RESET";

export const GET_USER_PROFILE_BY_ID = "GET_USER_PROFILE_BY_ID";
export const GET_USER_PROFILE_BY_ID_SUCCESS = "GET_USER_PROFILE_BY_ID_SUCCESS";
export const GET_USER_PROFILE_BY_ID_ERROR = "GET_USER_PROFILE_BY_ID_ERROR";
export const GET_USER_PROFILE_BY_ID_RESET = "GET_USER_PROFILE_BY_ID_RESET";

export const GET_ALL_OPTIONS = "GET_ALL_OPTIONS";
export const GET_ALL_OPTIONS_SUCCESS = "GET_ALL_OPTIONS_SUCCESS";
export const GET_ALL_OPTIONS_ERROR = "GET_ALL_OPTIONS_ERROR";
export const GET_ALL_OPTIONS_RESET = "GET_ALL_OPTIONS_RESET";

export const GET_USER_BOB_SUMMARY = "GET_USER_BOB_SUMMARY";
export const GET_USER_BOB_SUMMARY_SUCCESS = "GET_USER_BOB_SUMMARY_SUCCESS";
export const GET_USER_BOB_SUMMARY_ERROR = "GET_USER_BOB_SUMMARY_ERROR";
export const GET_USER_BOB_SUMMARY_RESET = "GET_USER_BOB_SUMMARY_RESET";

export const SUBMIT_USER_ASSESSMENT = "SUBMIT_USER_ASSESSMENT";
export const SUBMIT_USER_ASSESSMENT_SUCCESS = "SUBMIT_USER_ASSESSMENT_SUCCESS";
export const SUBMIT_USER_ASSESSMENT_ERROR = "SUBMIT_USER_ASSESSMENT_ERROR";
export const SUBMIT_USER_ASSESSMENT_RESET = "SUBMIT_USER_ASSESSMENT_RESET";

export const GET_USER_ASSESSMENT_STATUS = "GET_USER_ASSESSMENT_STATUS";
export const GET_USER_ASSESSMENT_STATUS_SUCCESS =
  "GET_USER_ASSESSMENT_STATUS_SUCCESS";
export const GET_USER_ASSESSMENT_STATUS_ERROR =
  "GET_USER_ASSESSMENT_STATUS_ERROR";
export const GET_USER_ASSESSMENT_STATUS_RESET =
  "GET_USER_ASSESSMENT_STATUS_RESET";

export const GET_USER_ASSESSMENT_SCORE = "GET_USER_ASSESSMENT_SCORE";
export const GET_USER_ASSESSMENT_SCORE_SUCCESS =
  "GET_USER_ASSESSMENT_SCORE_SUCCESS";
export const GET_USER_ASSESSMENT_SCORE_ERROR =
  "GET_USER_ASSESSMENT_SCORE_ERROR";

export const GET_USER_GOAL_SCORE = "GET_USER_GOAL_SCORE";
export const GET_USER_GOAL_SCORE_SUCCESS = "GET_USER_GOAL_SCORE_SUCCESS";
export const GET_USER_GOAL_SCORE_ERROR = "GET_USER_GOAL_SCORE_ERROR";
export const GET_USER_GOAL_SCORE_RESET = "GET_USER_GOAL_SCORE_RESET";

export const GET_BOB_DATA = "GET_BOB_DATA";
export const GET_BOB_DATA_SUCCESS = "GET_BOB_DATA_SUCCESS";
export const GET_BOB_DATA_ERROR = "GET_BOB_DATA_ERROR";
export const GET_BOB_DATA_RESET = "GET_BOB_DATA_RESET";

export const UPDATE_BOB_DATA = "UPDATE_BOB_DATA";
export const UPDATE_BOB_DATA_SUCCESS = "UPDATE_BOB_DATA_SUCCESS";
export const UPDATE_BOB_DATA_ERROR = "UPDATE_BOB_DATA_ERROR";
export const UPDATE_BOB_DATA_RESET = "UPDATE_BOB_DATA_RESET";

export const GET_RESPONSIBILITY_SECTORS = "GET_RESPONSIBILITY_SECTORS";
export const GET_RESPONSIBILITY_SECTORS_SUCCESS =
  "GET_RESPONSIBILITY_SECTORS_SUCCESS";
export const GET_RESPONSIBILITY_SECTORS_ERROR =
  "GET_RESPONSIBILITY_SECTORS_ERROR";
export const GET_RESPONSIBILITY_SECTORS_RESET =
  "GET_RESPONSIBILITY_SECTORS_RESET";

export const SET_DASHBOARD_PILLAR_FILTERS = "SET_DASHBOARD_PILLAR_FILTERS";
export const RESET_DASHBOARD_PILLAR_FILTERS = "RESET_DASHBOARD_PILLAR_FILTERS";

export const SET_DASHBOARD_GOAL_FILTERS = "SET_DASHBOARD_GOAL_FILTERS";
export const RESET_DASHBOARD_GOAL_FILTERS = "RESET_DASHBOARD_GOAL_FILTERS";

export const GET_COUNSELEES_STATUS = "GET_COUNSELEES_STATUS";
export const GET_COUNSELEES_STATUS_SUCCESS = "GET_COUNSELEES_STATUS_SUCCESS";
export const GET_COUNSELEES_STATUS_ERROR = "GET_COUNSELEES_STATUS_ERROR";
export const GET_COUNSELEES_STATUS_RESET = "GET_COUNSELEES_STATUS_RESET";

export const GET_PILLAR_GROUP_SCORE = "GET_PILLAR_GROUP_SCORE";
export const GET_PILLAR_GROUP_SCORE_SUCCESS = "GET_PILLAR_GROUP_SCORE_SUCCESS";
export const GET_PILLAR_GROUP_SCORE_ERROR = "GET_PILLAR_GROUP_SCORE_ERROR";

export const GET_GOAL_GROUP_SCORE = "GET_GOAL_GROUP_SCORE";
export const GET_GOAL_GROUP_SCORE_SUCCESS = "GET_GOAL_GROUP_SCORE_SUCCESS";
export const GET_GOAL_GROUP_SCORE_ERROR = "GET_GOAL_GROUP_SCORE_ERROR";

export const GET_QUANTITATIVE_SCORE = "GET_QUANTITATIVE_SCORE";
export const GET_QUANTITATIVE_SCORE_SUCCESS = "GET_QUANTITATIVE_SCORE_SUCCESS";
export const GET_QUANTITATIVE_SCORE_ERROR = "GET_QUANTITATIVE_SCORE_ERROR";

export const SET_PPMD_STATUS_FILTERS = "SET_PPMD_STATUS_FILTERS";
export const RESET_PPMD_STATUS_FILTERS = "RESET_PPMD_STATUS_FILTERS";

export const GET_REVIEWER_HOMEPAGE_DATA = "GET_REVIEWER_HOMEPAGE_DATA";
export const GET_REVIEWER_HOMEPAGE_DATA_SUCCESS =
  "GET_REVIEWER_HOMEPAGE_DATA_SUCCESS";
export const GET_REVIEWER_HOMEPAGE_DATA_ERROR =
  "GET_REVIEWER_HOMEPAGE_DATA_ERROR";

export const APPROVE_OR_REJECT_COUNSELEE = "APPROVE_OR_REJECT_COUNSELEE";
export const APPROVE_OR_REJECT_COUNSELEE_SUCCESS =
  "APPROVE_OR_REJECT_COUNSELEE_SUCCESS";
export const APPROVE_OR_REJECT_COUNSELEE_ERROR =
  "APPROVE_OR_REJECT_COUNSELEE_ERROR";
export const APPROVE_OR_REJECT_COUNSELEE_RESET =
  "APPROVE_OR_REJECT_COUNSELEE_RESET";

export const COMMENT_READ = "COMMENT_READ";
export const COMMENT_READ_SUCCESS = "COMMENT_READ_SUCCESS";
export const COMMENT_READ_ERROR = "COMMENT_READ_ERROR";
export const COMMENT_READ_RESET = "COMMENT_READ_RESET";

export const SEARCH_USER = "SEARCH_USER";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_ERROR = "SEARCH_USER_ERROR";
export const SEARCH_USER_RESET = "SEARCH_USER_RESET";

export const RESET_USER = "RESET_USER";
export const RESET_USER_SUCCESS = "RESET_USER_SUCCESS";
export const RESET_USER_ERROR = "RESET_USER_ERROR";
export const RESET_USER_DATA = "RESET_USER_DATA";

export const SEARCH_CLIENT = "SEARCH_CLIENT";
export const SEARCH_CLIENT_SUCCESS = "SEARCH_CLIENT_SUCCESS";
export const SEARCH_CLIENT_ERROR = "SEARCH_CLIENT_ERROR";
export const SEARCH_CLIENT_RESET = "SEARCH_CLIENT_RESET";

export const GET_GENERAL_PARAMETERS = "GET_GENERAL_PARAMETERS";
export const GET_GENERAL_PARAMETERS_SUCCESS = "GET_GENERAL_PARAMETERS_SUCCESS";
export const GET_GENERAL_PARAMETERS_ERROR = "GET_GENERAL_PARAMETERS_ERROR";
export const GET_GENERAL_PARAMETERS_RESET = "GET_GENERAL_PARAMETERS_RESET";

export const EDIT_GENERAL_PARAMETERS = "EDIT_GENERAL_PARAMETERS";
export const EDIT_GENERAL_PARAMETERS_SUCCESS =
  "EDIT_GENERAL_PARAMETERS_SUCCESS";
export const EDIT_GENERAL_PARAMETERS_ERROR = "EDIT_GENERAL_PARAMETERS_ERROR";
export const EDIT_GENERAL_PARAMETERS_RESET = "EDIT_GENERAL_PARAMETERS_RESET";

export const API_SERVER_ERROR_CODE = "API_SERVER_ERROR_CODE";
export const RESET_API_SERVER_ERROR_CODE = "RESET_API_SERVER_ERROR_CODE";

export const GET_REPORTS_DATA = "GET_REPORTS_DATA";
export const GET_REPORTS_DATA_SUCCESS = "GET_REPORTS_DATA_SUCCESS";
export const GET_REPORTS_DATA_ERROR = "GET_REPORTS_DATA_ERROR";
export const GET_REPORTS_DATA_RESET = "GET_REPORTS_DATA_RESET";

export const SET_UUID_FOR_USER = "SET_UUID_FOR_USER";
export const SET_UUID_FOR_USER_SUCCESS = "SET_UUID_FOR_USER_SUCCESS";
export const SET_UUID_FOR_USER_ERROR = "SET_UUID_FOR_USER_ERROR";
export const SET_UUID_FOR_USER_RESET = "SET_UUID_FOR_USER_RESET";

export const GET_EXCEL_REPORTS_DATA = "GET_EXCEL_REPORTS_DATA";
export const GET_EXCEL_REPORTS_DATA_SUCCESS = "GET_EXCEL_REPORTS_DATA_SUCCESS";
export const GET_EXCEL_REPORTS_DATA_ERROR = "GET_EXCEL_REPORTS_DATA_ERROR";
export const GET_EXCEL_REPORTS_DATA_RESET = "GET_EXCEL_REPORTS_DATA_RESET";

export const GET_TIME_PERIOD_STATUS = "GET_TIME_PERIOD_STATUS";
export const GET_TIME_PERIOD_STATUS_SUCCESS = "GET_TIME_PERIOD_STATUS_SUCCESS";
export const GET_TIME_PERIOD_STATUS_ERROR = "GET_TIME_PERIOD_STATUS_ERROR";
export const GET_TIME_PERIOD_STATUS_RESET = "GET_TIME_PERIOD_STATUS_RESET";

export const GET_ASSESSMENT_SECTION_LIST = "GET_ASSESSMENT_SECTION_LIST";
export const GET_ASSESSMENT_SECTION_LIST_SUCCESS =
  "GET_ASSESSMENT_SECTION_LIST_SUCCESS";
export const GET_ASSESSMENT_SECTION_LIST_ERROR =
  "GET_ASSESSMENT_SECTION_LIST_ERROR";
export const GET_ASSESSMENT_SECTION_LIST_RESET =
  "GET_ASSESSMENT_SECTION_LIST_RESET";

export const EDIT_ASSESSMENT_SECTION = "EDIT_ASSESSMENT_SECTION";
export const EDIT_ASSESSMENT_SECTION_SUCCESS =
  "EDIT_ASSESSMENT_SECTION_SUCCESS";
export const EDIT_ASSESSMENT_SECTION_ERROR = "EDIT_ASSESSMENT_SECTION_ERROR";
export const EDIT_ASSESSMENT_SECTION_RESET = "EDIT_ASSESSMENT_SECTION_RESET";

export const DELETE_ASSESSMENT_SECTION = "DELETE_ASSESSMENT_SECTION";
export const DELETE_ASSESSMENT_SECTION_SUCCESS =
  "DELETE_ASSESSMENT_SECTION_SUCCESS";
export const DELETE_ASSESSMENT_SECTION_ERROR =
  "DELETE_ASSESSMENT_SECTION_ERROR";
export const DELETE_ASSESSMENT_SECTION_RESET =
  "DELETE_ASSESSMENT_SECTION_RESET";

export const GET_ADMIN_SEARCH_USER_DETAILS = "GET_ADMIN_SEARCH_USER_DETAILS";
export const GET_ADMIN_SEARCH_USER_DETAILS_SUCCESS =
  "GET_ADMIN_SEARCH_USER_DETAILS_SUCCESS";
export const GET_ADMIN_SEARCH_USER_DETAILS_ERROR =
  "GET_ADMIN_SEARCH_USER_DETAILS_ERROR";
export const GET_ADMIN_SEARCH_USER_DETAILS_RESET =
  "GET_ADMIN_SEARCH_USER_DETAILS_RESET";

export const GET_ADMIN_SEARCH_MANAGER_DETAILS =
  "GET_ADMIN_SEARCH_MANAGER_DETAILS";
export const GET_ADMIN_SEARCH_MANAGER_DETAILS_SUCCESS =
  "GET_ADMIN_SEARCH_MANAGER_DETAILS_SUCCESS";
export const GET_ADMIN_SEARCH_MANAGER_DETAILS_ERROR =
  "GET_ADMIN_SEARCH_MANAGER_DETAILS_ERROR";
export const GET_ADMIN_SEARCH_MANAGER_DETAILS_RESET =
  "GET_ADMIN_SEARCH_MANAGER_DETAILS_RESET";

export const GET_USER_COUNT = "GET_USER_COUNT";
export const GET_USER_COUNT_SUCCESS = "GET_USER_COUNT_SUCCESS";
export const GET_USER_COUNT_ERROR = "GET_USER_COUNT_ERROR";
export const GET_USER_COUNT_RESET = "GET_USER_COUNT_RESET";

export const SEARCH_USER_DATA = "SEARCH_USER_DATA";
export const SEARCH_USER_DATA_SUCCESS = "SEARCH_USER_DATA_SUCCESS";
export const SEARCH_USER_DATA_ERROR = "SEARCH_USER_DATA_ERROR";
export const SEARCH_USER_DATA_RESET = "SEARCH_USER_DATA_RESET";

export const ROLL_FORWARD_USER = "ROLL_FORWARD_USER";
export const ROLL_FORWARD_USER_SUCCESS = "ROLL_FORWARD_USER_SUCCESS";
export const ROLL_FORWARD_USER_ERROR = "ROLL_FORWARD_USER_ERROR";
export const ROLL_FORWARD_USER_RESET = "ROLL_FORWARD_USER_RESET";

export const CHECK_LOCK_STATUS = "CHECK_LOCK_STATUS";
export const CHECK_LOCK_STATUS_SUCCESS = "CHECK_LOCK_STATUS_SUCCESS";
export const CHECK_LOCK_STATUS_ERROR = "CHECK_LOCK_STATUS_ERROR";
export const CHECK_LOCK_STATUS_RESET = "CHECK_LOCK_STATUS_RESET";

export const ADD_MULTIPLE_USER = "ADD_MULTIPLE_USER";
export const ADD_MULTIPLE_USER_SUCCESS = "ADD_MULTIPLE_USER_SUCCESS";
export const ADD_MULTIPLE_USER_ERROR = "ADD_MULTIPLE_USER_ERROR";
export const ADD_MULTIPLE_USER_RESET = "ADD_MULTIPLE_USER_RESET";

export const BULK_UPLOAD_DISCUSSION_GUIDE = "BULK_UPLOAD_DISCUSSION_GUIDE";
export const BULK_UPLOAD_DISCUSSION_GUIDE_SUCCESS = "BULK_UPLOAD_DISCUSSION_GUIDE_SUCCESS";
export const BULK_UPLOAD_DISCUSSION_GUIDE_ERROR = "BULK_UPLOAD_DISCUSSION_GUIDE_ERROR";
export const BULK_UPLOAD_DISCUSSION_GUIDE_RESET = "BULK_UPLOAD_DISCUSSION_GUIDE_RESET";

export const ADD_SINGLE_USER = "ADD_SINGLE_USER";
export const ADD_SINGLE_USER_SUCCESS = "ADD_SINGLE_USER_SUCCESS";
export const ADD_SINGLE_USER_ERROR = "ADD_SINGLE_USER_ERROR";
export const ADD_SINGLE_USER_RESET = "ADD_SINGLE_USER_RESET";

export const EDIT_SINGLE_USER = "EDIT_SINGLE_USER";
export const EDIT_SINGLE_USER_SUCCESS = "EDIT_SINGLE_USER_SUCCESS";
export const EDIT_SINGLE_USER_ERROR = "EDIT_SINGLE_USER_ERROR";
export const EDIT_SINGLE_USER_RESET = "EDIT_SINGLE_USER_RESET";

export const DELETE_SINGLE_USER = "DELETE_SINGLE_USER";
export const DELETE_SINGLE_USER_SUCCESS = "DELETE_SINGLE_USER_SUCCESS";
export const DELETE_SINGLE_USER_ERROR = "DELETE_SINGLE_USER_ERROR";
export const DELETE_SINGLE_USER_RESET = "DELETE_SINGLE_USER_RESET";

export const GET_ADMIN_MASTER_CONFIG = "GET_ADMIN_MASTER_CONFIG";
export const GET_ADMIN_MASTER_CONFIG_SUCCESS =
  "GET_ADMIN_MASTER_CONFIG_SUCCESS";
export const GET_ADMIN_MASTER_CONFIG_ERROR = "GET_ADMIN_MASTER_CONFIG_ERROR";
export const GET_ADMIN_MASTER_CONFIG_RESET = "GET_ADMIN_MASTER_CONFIG_RESET";

export const GET_ASSESSMENT_GOAL = "GET_ASSESSMENT_GOAL";
export const GET_ASSESSMENT_GOAL_SUCCESS = "GET_ASSESSMENT_GOAL_SUCCESS";
export const GET_ASSESSMENT_GOAL_ERROR = "GET_ASSESSMENT_GOAL_ERROR";
export const RESET_ASSESSMENT_GOAL = "RESET_ASSESSMENT_GOAL";
export const ADD_ASSESSMENT_GOAL = "ADD_ASSESSMENT_GOAL";
export const ADD_ASSESSMENT_GOAL_SUCCESS = "ADD_ASSESSMENT_GOAL_SUCCESS";
export const ADD_ASSESSMENT_GOAL_ERROR = "ADD_ASSESSMENT_GOAL_ERROR";
export const RESET_ADD_ASSESSMENT_GOAL = "RESET_ADD_ASSESSMENT_GOAL";
export const EDIT_ASSESSMENT_GOAL = "EDIT_ASSESSMENT_GOAL";
export const EDIT_ASSESSMENT_GOAL_SUCCESS = "EDIT_ASSESSMENT_GOAL_SUCCESS";
export const EDIT_ASSESSMENT_GOAL_ERROR = "EDIT_ASSESSMENT_GOAL_ERROR";
export const RESET_EDIT_ASSESSMENT_GOAL = "RESET_EDIT_ASSESSMENT_GOAL";
export const DELETE_ASSESSMENT_GOAL = "DELETE_ASSESSMENT_GOAL";
export const DELETE_ASSESSMENT_GOAL_SUCCESS = "DELETE_ASSESSMENT_GOAL_SUCCESS";
export const DELETE_ASSESSMENT_GOAL_ERROR = "DELETE_ASSESSMENT_GOAL_ERROR";
export const RESET_DELETE_ASSESSMENT_GOAL = "RESET_DELETE_ASSESSMENT_GOAL";
export const REORDER_ASSESSMENT_GOAL = "REORDER_ASSESSMENT_GOAL";
export const REORDER_ASSESSMENT_GOAL_SUCCESS =
  "REORDER_ASSESSMENT_GOAL_SUCCESS";
export const REORDER_ASSESSMENT_GOAL_ERROR = "REORDER_ASSESSMENT_GOAL_ERROR";
export const RESET_REORDER_ASSESSMENT_GOAL = "RESET_REORDER_ASSESSMENT_GOAL";

export const ADD_GLOBAL_METRIC = "ADD_GLOBAL_METRIC";
export const ADD_GLOBAL_METRIC_SUCCESS = "ADD_GLOBAL_METRIC_SUCCESS";
export const ADD_GLOBAL_METRIC_ERROR = "ADD_GLOBAL_METRIC_ERROR";
export const ADD_GLOBAL_METRIC_RESET = "ADD_GLOBAL_METRIC_RESET";

export const EDIT_GLOBAL_METRIC = "EDIT_GLOBAL_METRIC";
export const EDIT_GLOBAL_METRIC_SUCCESS = "EDIT_GLOBAL_METRIC_SUCCESS";
export const EDIT_GLOBAL_METRIC_ERROR = "EDIT_GLOBAL_METRIC_ERROR";
export const EDIT_GLOBAL_METRIC_RESET = "EDIT_GLOBAL_METRIC_RESET";

export const DELETE_GLOBAL_METRIC = "DELETE_GLOBAL_METRIC";
export const DELETE_GLOBAL_METRIC_SUCCESS = "DELETE_GLOBAL_METRIC_SUCCESS";
export const DELETE_GLOBAL_METRIC_ERROR = "DELETE_GLOBAL_METRIC_ERROR";
export const DELETE_GLOBAL_METRIC_RESET = "DELETE_GLOBAL_METRIC_RESET";

export const REORDER_GLOBAL_METRIC = "REORDER_GLOBAL_METRIC";
export const REORDER_GLOBAL_METRIC_SUCCESS = "REORDER_GLOBAL_METRIC_SUCCESS";
export const REORDER_GLOBAL_METRIC_ERROR = "REORDER_GLOBAL_METRIC_ERROR";
export const REORDER_GLOBAL_METRIC_RESET = "REORDER_GLOBAL_METRIC_RESET";

export const REORDER_ASSESSMENT_SECTION = "REORDER_ASSESSMENT_SECTION";
export const REORDER_ASSESSMENT_SECTION_SUCCESS =
  "REORDER_ASSESSMENT_SECTION_SUCCESS";
export const REORDER_ASSESSMENT_SECTION_ERROR =
  "REORDER_ASSESSMENT_SECTIONC_ERROR";
export const REORDER_ASSESSMENT_SECTION_RESET =
  "REORDER_ASSESSMENT_SECTION_RESET";

export const ROLL_FORWARD_ASSESSMENT = "ROLL_FORWARD_ASSESSMENT";
export const ROLL_FORWARD_ASSESSMENT_SUCCESS =
  "ROLL_FORWARD_ASSESSMENT_SUCCESS";
export const ROLL_FORWARD_ASSESSMENT_ERROR = "ROLL_FORWARD_ASSESSMENT_ERROR";
export const ROLL_FORWARD_ASSESSMENT_RESET = "ROLL_FORWARD_ASSESSMENT_RESET";

export const GET_ASSESSMENT_STATUS = "GET_ASSESSMENT_STATUS";
export const GET_ASSESSMENT_STATUS_SUCCESS = "GET_ASSESSMENT_STATUS_SUCCESS";
export const GET_ASSESSMENT_STATUS_ERROR = "GET_ASSESSMENT_STATUS_ERROR";
export const GET_ASSESSMENT_STATUS_RESET = "GET_ASSESSMENT_STATUS_RESET";

export const UPLOAD_ASSESSMENT_CONTENT = "UPLOAD_ASSESSMENT_CONTENT";
export const UPLOAD_ASSESSMENT_CONTENT_SUCCESS =
  "UPLOAD_ASSESSMENT_CONTENT_SUCCESS";
export const UPLOAD_ASSESSMENT_CONTENT_ERROR =
  "UPLOAD_ASSESSMENT_CONTENT_ERROR";
export const UPLOAD_ASSESSMENT_CONTENT_RESET =
  "UPLOAD_ASSESSMENT_CONTENT_RESET";

export const GENERATE_BOOK_OF_BUSINESS = "GENERATE_BOOK_OF_BUSINESS";
export const GENERATE_BOOK_OF_BUSINESS_SUCCESS =
  "GENERATE_BOOK_OF_BUSINESS_SUCCESS";
export const GENERATE_BOOK_OF_BUSINESS_ERROR =
  "GENERATE_BOOK_OF_BUSINESS_ERROR";
export const GENERATE_BOOK_OF_BUSINESS_RESET =
  "GENERATE_BOOK_OF_BUSINESS_RESET";

export const REGIONAL_DATA_UPLOAD = "REGIONAL_DATA_UPLOAD";
export const REGIONAL_DATA_UPLOAD_SUCCESS = "REGIONAL_DATA_UPLOAD_SUCCESS";
export const REGIONAL_DATA_UPLOAD_ERROR = "REGIONAL_DATA_UPLOAD_ERROR";
export const REGIONAL_DATA_UPLOAD_RESET = "REGIONAL_DATA_UPLOAD_RESET";

export const VALIDATE_USER_CHANGES = "VALIDATE_USER_CHANGES";
export const VALIDATE_USER_CHANGES_SUCCESS = "VALIDATE_USER_CHANGES_SUCCESS";
export const VALIDATE_USER_CHANGES_ERROR = "VALIDATE_USER_CHANGES_ERROR";
export const VALIDATE_USER_CHANGES_RESET = "VALIDATE_USER_CHANGES_RESET";

export const GET_USER_DATA_CHANGES = "GET_USER_DATA_CHANGES";
export const GET_USER_DATA_CHANGES_SUCCESS = "GET_USER_DATA_CHANGES_SUCCESS";
export const GET_USER_DATA_CHANGES_ERROR = "GET_USER_DATA_CHANGES_ERROR";
export const GET_USER_DATA_CHANGES_RESET = "GET_USER_DATA_CHANGES_RESET";

export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_DETAILS_SUCCESS = "UPDATE_USER_DETAILS_SUCCESS";
export const UPDATE_USER_DETAILS_ERROR = "UPDATE_USER_DETAILS_ERROR";
export const UPDATE_USER_DETAILS_RESET = "UPDATE_USER_DETAILS_RESET";

export const DELETE_USERS = "DELETE_USERS";
export const DELETE_USERS_SUCCESS = "DELETE_USERS_SUCCESS";
export const DELETE_USERS_ERROR = "DELETE_USERS_ERROR";
export const DELETE_USERS_RESET = "DELETE_USERS_RESET";

export const GET_EMAIL_TEMPLATE = "GET_EMAIL_TEMPLATE";
export const GET_EMAIL_TEMPLATE_SUCCESS = "GET_EMAIL_TEMPLATE_SUCCESS";
export const GET_EMAIL_TEMPLATE_ERROR = "GET_EMAIL_TEMPLATE_ERROR";
export const EDIT_EMAIL_TEMPLATE = "EDIT_EMAIL_TEMPLATE";
export const EDIT_EMAIL_TEMPLATE_SUCCESS = "EDIT_EMAIL_TEMPLATE_SUCCESS";
export const EDIT_EMAIL_TEMPLATE_ERROR = "EDIT_EMAIL_TEMPLATE_ERROR";
export const EDIT_EMAIL_TEMPLATE_RESET = "EDIT_EMAIL_TEMPLATE_RESET";
export const ADD_IMPACT_STATEMENT = "ADD_IMPACT_STATEMENT";
export const ADD_IMPACT_STATEMENT_SUCCESS = "ADD_IMPACT_STATEMENT_SUCCESS";
export const ADD_IMPACT_STATEMENT_ERROR = "ADD_IMPACT_STATEMENT_ERROR";
export const ADD_IMPACT_STATEMENT_RESET = "ADD_IMPACT_STATEMENT_RESET";

export const MOVE_IMPACT_STATEMENT = "MOVE_IMPACT_STATEMENT";
export const MOVE_IMPACT_STATEMENT_SUCCESS = "MOVE_IMPACT_STATEMENT_SUCCESS";
export const MOVE_IMPACT_STATEMENT_ERROR = "MOVE_IMPACT_STATEMENT_ERROR";
export const MOVE_IMPACT_STATEMENT_RESET = "MOVE_IMPACT_STATEMENT_RESET";

export const DELETE_IMPACT_STATEMENT = "DELETE_IMPACT_STATEMENT";
export const DELETE_IMPACT_STATEMENT_SUCCESS =
  "DELETE_IMPACT_STATEMENT_SUCCESS";
export const DELETE_IMPACT_STATEMENT_ERROR = "DELETE_IMPACT_STATEMENT_ERROR";
export const DELETE_IMPACT_STATEMENT_RESET = "DELETE_IMPACT_STATEMENT_RESET";

export const SORT_IMPACT_STATEMENT = "SORT_IMPACT_STATEMENT";
export const SORT_IMPACT_STATEMENT_SUCCESS = "SORT_IMPACT_STATEMENT_SUCCESS";
export const SORT_IMPACT_STATEMENT_ERROR = "SORT_IMPACT_STATEMENT_ERROR";
export const SORT_IMPACT_STATEMENT_RESET = "SORT_IMPACT_STATEMENT_RESET";

export const GET_LIST_MAILS = "GET_LIST_MAILS";
export const GET_LIST_MAILS_SUCCESS = "GET_LIST_MAILS_SUCCESS";
export const GET_LIST_MAILS_ERROR = "GET_LIST_MAILS_ERROR";

export const CANCEL_MAIL_REQUEST = "CANCEL_MAIL_REQUEST";
export const CANCEL_MAIL_REQUEST_SUCCESS = "CANCEL_MAIL_REQUEST_SUCCESS";
export const CANCEL_MAIL_REQUEST_ERROR = "CANCEL_MAIL_REQUEST_ERROR";
export const CANCEL_MAIL_REQUEST_RESET = "CANCEL_MAIL_REQUEST_RESET";

export const SEND_ADHOC_MAIL = "SEND_ADHOC_MAIL";
export const SEND_ADHOC_MAIL_SUCCESS = "SEND_ADHOC_MAIL_SUCCESS";
export const SEND_ADHOC_MAIL_ERROR = "SEND_ADHOC_MAIL_ERROR";
export const SEND_ADHOC_MAIL_RESET = "SEND_ADHOC_MAIL_RESET";

export const GET_BOB_ROLLFORWARD_STATUS = "GET_BOB_ROLLFORWARD_STATUS";
export const GET_BOB_ROLLFORWARD_STATUS_SUCCESS =
  "GET_BOB_ROLLFORWARD_STATUS_SUCCESS";
export const GET_BOB_ROLLFORWARD_STATUS_ERROR =
  "GET_BOB_ROLLFORWARD_STATUS_ERROR";
export const GET_BOB_ROLLFORWARD_STATUS_RESET =
  "GET_BOB_ROLLFORWARD_STATUS_RESET";

export const GET_GENERAL_SECTION = "GET_GENERAL_SECTION";
export const GET_GENERAL_SECTION_SUCCESS = "GET_GENERAL_SECTION_SUCCESS";
export const GET_GENERAL_SECTION_ERROR = "GET_GENERAL_SECTION_ERROR";

export const EDIT_GENERAL_SECTION = "EDIT_GENERAL_SECTION";
export const EDIT_GENERAL_SECTION_SUCCESS = "EDIT_GENERAL_SECTION_SUCCESS";
export const EDIT_GENERAL_SECTION_ERROR = "EDIT_GENERAL_SECTION_ERROR";
export const EDIT_GENERAL_SECTION_RESET = "EDIT_GENERAL_SECTION_RESET";

export const GET_INVALID_ASSESSMENT_DATA = "GET_INVALID_ASSESSMENT_DATA";
export const GET_INVALID_ASSESSMENT_DATA_SUCCESS =
  "GET_INVALID_ASSESSMENT_DATA_SUCCESS";
export const GET_INVALID_ASSESSMENT_DATA_ERROR =
  "GET_INVALID_ASSESSMENT_DATA_ERROR";
export const GET_INVALID_ASSESSMENT_DATA_RESET =
  "GET_INVALID_ASSESSMENT_DATA_RESET";

export const ADMIN_SUBMIT_ASSESSMENT = "ADMIN_SUBMIT_ASSESSMENT";
export const ADMIN_SUBMIT_ASSESSMENT_SUCCESS =
  "ADMIN_SUBMIT_ASSESSMENT_SUCCESS";
export const ADMIN_SUBMIT_ASSESSMENT_ERROR = "ADMIN_SUBMIT_ASSESSMENT_ERROR";
export const ADMIN_SUBMIT_ASSESSMENT_RESET = "ADMIN_SUBMIT_ASSESSMENT_RESET";
export const ROLLFORWARD_BOB_TO_NEXT_PHASE = "ROLLFORWARD_BOB_TO_NEXT_PHASE";
export const ROLLFORWARD_BOB_TO_NEXT_PHASE_SUCCESS =
  "ROLLFORWARD_BOB_TO_NEXT_PHASE_SUCCESS";
export const ROLLFORWARD_BOB_TO_NEXT_PHASE_ERROR =
  "ROLLFORWARD_BOB_TO_NEXT_PHASE_ERROR";
export const ROLLFORWARD_BOB_TO_NEXT_PHASE_RESET =
  "ROLLFORWARD_BOB_TO_NEXT_PHASE_RESET";

export const PROGRESS_STATUS_FROM_BOB = "PROGRESS_STATUS_FROM_BOB";
export const PROGRESS_STATUS_FROM_BOB_SUCCESS =
  "PROGRESS_STATUS_FROM_BOB_SUCCESS";
export const PROGRESS_STATUS_FROM_BOB_ERROR = "PROGRESS_STATUS_FROM_BOB_ERROR";
export const PROGRESS_STATUS_FROM_BOB_RESET = "PROGRESS_STATUS_FROM_BOB_RESET";

export const BOB_ADMIN_ACTION_STATUS = "BOB_ADMIN_ACTION_STATUS";
export const BOB_ADMIN_ACTION_STATUS_SUCCESS =
  "BOB_ADMIN_ACTION_STATUS_SUCCESS";
export const BOB_ADMIN_ACTION_STATUS_ERROR = "BOB_ADMIN_ACTION_STATUS_ERROR";
export const BOB_ADMIN_ACTION_STATUS_RESET = "BOB_ADMIN_ACTION_STATUS_RESET";

// export const BULK_UPLOAD_DISCUSSION_GUIDE_ADMIN_ACTION_STATUS = "BULK_UPLOAD_DISCUSSION_GUIDE_ADMIN_ACTION_STATUS";
// export const BULK_UPLOAD_DISCUSSION_GUIDE_ADMIN_ACTION_STATUS_SUCCESS = "BULK_UPLOAD_DISCUSSION_GUIDE_ADMIN_ACTION_STATUS_SUCCESS";
// export const BULK_UPLOAD_DISCUSSION_GUIDE_ADMIN_ACTION_STATUS_ERROR = "BULK_UPLOAD_DISCUSSION_GUIDE_ADMIN_ACTION_STATUS_ERROR";
// export const BULK_UPLOAD_DISCUSSION_GUIDE_ADMIN_ACTION_STATUS_RESET = "BULK_UPLOAD_DISCUSSION_GUIDE_ADMIN_ACTION_STATUS_RESET";


export const MERCURY_FILE_UPLOAD = "MERCURY_FILE_UPLOAD";
export const MERCURY_FILE_UPLOAD_SUCCESS = "MERCURY_FILE_UPLOAD_SUCCESS";
export const MERCURY_FILE_UPLOAD_ERROR = "MERCURY_FILE_UPLOAD_ERROR";
export const MERCURY_FILE_UPLOAD_RESET = "MERCURY_FILE_UPLOAD_RESET";

export const VALID_ASSESSMENT_YEARS = "VALID_ASSESSMENT_YEARS";
export const VALID_ASSESSMENT_YEARS_SUCCESS = "VALID_ASSESSMENT_YEARS_SUCCESS";
export const VALID_ASSESSMENT_YEARS_ERROR = "VALID_ASSESSMENT_YEARS_ERROR";
export const VALID_ASSESSMENT_YEARS_RESET = "VALID_ASSESSMENT_YEARS_RESET";

export const GET_PHASE_VALIDATION_STATUS = "GET_PHASE_VALIDATION_STATUS";
export const GET_PHASE_VALIDATION_STATUS_SUCCESS =
  "GET_PHASE_VALIDATION_STATUS_SUCCESS";
export const GET_PHASE_VALIDATION_STATUS_ERROR =
  "GET_PHASE_VALIDATION_STATUS_ERROR";
export const GET_PHASE_VALIDATION_STATUS_RESET =
  "GET_PHASE_VALIDATION_STATUS_RESET";

export const GET_APPROVAL_REQUEST = "GET_APPROVAL_REQUEST";
export const GET_APPROVAL_REQUEST_SUCCESS = "GET_APPROVAL_REQUEST_SUCCESS";
export const GET_APPROVAL_REQUEST_ERROR = "GET_APPROVAL_REQUEST_ERROR";
export const GET_APPROVAL_REQUEST_RESET = "GET_APPROVAL_REQUEST_RESET";
export const SET_APPROVAL_PREVIEW_DATA = "SET_APPROVAL_PREVIEW_DATA";
export const RESET_APPROVAL_PREVIEW_DATA = "RESET_APPROVAL_PREVIEW_DATA";
export const RESET_ADMIN_APPROVAL = "RESET_ADMIN_APPROVAL";

export const SEND_ADMIN_REVIEW = "SEND_ADMIN_REVIEW";
export const SEND_ADMIN_REVIEW_SUCCESS = "SEND_ADMIN_REVIEW_SUCCESS";
export const SEND_ADMIN_REVIEW_ERROR = "SEND_ADMIN_REVIEW_ERROR";
export const SEND_ADMIN_REVIEW_RESET = "SEND_ADMIN_REVIEW_RESET";

export const GET_REVIEW_DATA = "GET_REVIEW_DATA";
export const GET_REVIEW_DATA_SUCCESS = "GET_REVIEW_DATA_SUCCESS";
export const GET_REVIEW_DATA_ERROR = "GET_REVIEW_DATA_ERROR";
export const GET_REVIEW_DATA_RESET = "GET_REVIEW_DATA_RESET";

export const GET_HIGHLIGHT_DATA = "GET_HIGHLIGHT_DATA";
export const GET_HIGHLIGHT_DATA_SUCCESS = "GET_HIGHLIGHT_DATA_SUCCESS";
export const GET_HIGHLIGHT_DATA_ERROR = "GET_HIGHLIGHT_DATA_ERROR";
export const GET_HIGHLIGHT_DATA_RESET = "GET_HIGHLIGHT_DATA_RESET";

export const SET_ASSESSMENT_SUBMISSION_STATUS =
  "SET_ASSESSMENT_SUBMISSION_STATUS";
export const GET_ASSESSMENT_APPROVAL_LIST = "GET_ASSESSMENT_APPROVAL_LIST";
export const GET_ASSESSMENT_APPROVAL_LIST_SUCCESS =
  "GET_ASSESSMENT_APPROVAL_LIST_SUCCESS";
export const GET_ASSESSMENT_APPROVAL_LIST_ERROR =
  "GET_ASSESSMENT_APPROVAL_LIST_ERROR";

export const GET_VALIDATION_ASSESSMENT_META_DATA =
  "GET_VALIDATION_ASSESSMENT_META_DATA";
export const GET_VALIDATION_ASSESSMENT_META_DATA_SUCCESS =
  "GET_VALIDATION_ASSESSMENT_META_DATA_SUCCESS";
export const GET_VALIDATION_ASSESSMENT_META_DATA_ERROR =
  "GET_VALIDATION_ASSESSMENT_META_DATA_ERROR";
export const GET_VALIDATION_ASSESSMENT_META_DATA_RESET =
  "GET_VALIDATION_ASSESSMENT_META_DATA_RESET";

export const GET_PENDING_APPROVAL_COUNT = "GET_PENDING_APPROVAL_COUNT";
export const GET_PENDING_APPROVAL_COUNT_SUCCESS =
  "GET_PENDING_APPROVAL_COUNT_SUCCESS";
export const GET_PENDING_APPROVAL_COUNT_ERROR =
  "GET_PENDING_APPROVAL_COUNT_ERROR";
export const GET_PENDING_APPROVAL_COUNT_RESET =
  "GET_PENDING_APPROVAL_COUNT_RESET";

export const ENABLE_SUBMIT_BUTTON = "ENABLE_SUBMIT_BUTTON";
export const ENABLE_SUBMIT_BUTTON_RESET = "ENABLE_SUBMIT_BUTTON_RESET";
export const SUBMIT_BUTTON_CLICKED = "SUBMIT_BUTTON_CLICKED";
export const SUBMIT_BUTTON_CLICKED_RESET = "SUBMIT_BUTTON_CLICKED_RESET";
export const SET_SUBMISSION_STATUS = "SET_SUBMISSION_STATUS";
export const RESPOND_BUTTON_CLICKED = "RESPOND_BUTTON_CLICKED";
export const RESPOND_BUTTON_CLICKED_RESET = "RESPOND_BUTTON_CLICKED_RESET";

export const GET_REPORT_FILTER = "GET_REPORT_FILTER";
export const GET_REPORT_FILTER_SUCCESS = "GET_REPORT_FILTER_SUCCESS";
export const GET_REPORT_FILTER_ERROR = "GET_REPORT_FILTER_ERROR";
export const GET_REPORT_FILTER_RESET = "GET_REPORT_FILTER_RESET";

export const GET_ADDITIONAL_REPORT_DATA = "GET_ADDITIONAL_REPORT_DATA";
export const GET_ADDITIONAL_REPORT_DATA_SUCCESS =
  "GET_ADDITIONAL_REPORT_DATA_SUCCESS";
export const GET_ADDITIONAL_REPORT_DATA_ERROR =
  "GET_ADDITIONAL_REPORT_DATA_ERROR";
export const GET_ADDITIONAL_REPORT_DATA_RESET =
  "GET_ADDITIONAL_REPORT_DATA_RESET";

export const EXPORT_ADDITIONAL_REPORT = "EXPORT_ADDITIONAL_REPORT";
export const EXPORT_ADDITIONAL_REPORT_SUCCESS =
  "EXPORT_ADDITIONAL_REPORT_SUCCESS";
export const EXPORT_ADDITIONAL_REPORT_ERROR = "EXPORT_ADDITIONAL_REPORT_ERROR";
export const EXPORT_ADDITIONAL_REPORT_RESET = "EXPORT_ADDITIONAL_REPORT_RESET";

export const GET_POWERBI_LINK = "GET_POWERBI_LINK";
export const GET_POWERBI_LINK_SUCCESS = "GET_POWERBI_LINK_SUCCESS";
export const GET_POWERBI_LINK_ERROR = "GET_POWERBI_LINK_ERROR";
export const GET_POWERBI_LINK_RESET = "GET_POWERBI_LINK_RESET";

export const UPDATE_POWERBI_LINK = "UPDATE_POWERBI_LINK";
export const UPDATE_POWERBI_LINK_SUCCESS = "UPDATE_POWERBI_LINK_SUCCESS";
export const UPDATE_POWERBI_LINK_ERROR = "UPDATE_POWERBI_LINK_ERROR";
export const UPDATE_POWERBI_LINK_RESET = "UPDATE_POWERBI_LINK_RESET";

export const GET_COMPLETED_PHASE = "GET_COMPLETED_PHASE";
export const GET_COMPLETED_PHASE_SUCCESS = "GET_COMPLETED_PHASE_SUCCESS";
export const GET_COMPLETED_PHASE_ERROR = "GET_COMPLETED_PHASE_ERROR";
export const GET_COMPLETED_PHASE_RESET = "GET_COMPLETED_PHASE_RESET";

export const EXPORT_ENTIRE_ASSESSMENT_CONTENT =
  "EXPORT_ENTIRE_ASSESSMENT_CONTENT";
export const EXPORT_ENTIRE_ASSESSMENT_CONTENT_SUCCESS =
  "EXPORT_ENTIRE_ASSESSMENT_CONTENT_SUCCESS";
export const EXPORT_ENTIRE_ASSESSMENT_CONTENT_ERROR =
  "EXPORT_ENTIRE_ASSESSMENT_CONTENT_ERROR";
export const EXPORT_ENTIRE_ASSESSMENT_CONTENT_RESET =
  "EXPORT_ENTIRE_ASSESSMENT_CONTENT_RESET";

export const GET_YEARS_LIST = "GET_YEARS_LIST";
export const GET_YEARS_LIST_SUCCESS = "GET_YEARS_LIST_SUCCESS";
export const GET_YEARS_LIST_ERROR = "GET_YEARS_LIST_ERROR";
export const GET_YEARS_LIST_RESET = "GET_YEARS_LIST_RESET";

export const GET_USER_STATUS_ASSESSMENT = "GET_USER_STATUS_ASSESSMENT";
export const GET_USER_STATUS_ASSESSMENT_SUCCESS =
  "GET_USER_STATUS_ASSESSMENT_SUCCESS";
export const GET_USER_STATUS_ASSESSMENT_ERROR =
  "GET_USER_STATUS_ASSESSMENT_ERROR";
export const GET_USER_STATUS_ASSESSMENT_RESET =
  "GET_USER_STATUS_ASSESSMENT_RESET";

export const SET_VIEW_USER_DATA = "SET_VIEW_USER_DATA";
export const SET_VIEW_USER_DATA_RESET = "SET_VIEW_USER_DATA_RESET";

export const MAINTENANCE_ERROR = "MAINTENANCE_ERROR";
export const MAINTENANCE_ERROR_RESET = "MAINTENANCE_ERROR_RESET";

export const DELETE_MULTIPLE_USERS = "DELETE_MULTIPLE_USERS";
export const DELETE_MULTIPLE_USERS_SUCCESS = "DELETE_MULTIPLE_USERS_SUCCESS";
export const DELETE_MULTIPLE_USERS_ERROR = "DELETE_MULTIPLE_USERS_ERROR";
export const DELETE_MULTIPLE_USERS_RESET = "DELETE_MULTIPLE_USERS_RESET";

export const UPLOAD_GENERAL_CONTENT = "UPLOAD_GENERAL_CONTENT";
export const UPLOAD_GENERAL_CONTENT_SUCCESS = "UPLOAD_GENERAL_CONTENT_SUCCESS";
export const UPLOAD_GENERAL_CONTENT_ERROR = "UPLOAD_GENERAL_CONTENT_ERROR";
export const UPLOAD_GENERAL_CONTENT_RESET = "UPLOAD_GENERAL_CONTENT_RESET";

export const GET_GENERAL_CONTENT = "GET_GENERAL_CONTENT";
export const GET_GENERAL_CONTENT_SUCCESS = "GET_GENERAL_CONTENT_SUCCESS";
export const GET_GENERAL_CONTENT_ERROR = "GET_GENERAL_CONTENT_ERROR";
export const GET_GENERAL_CONTENT_RESET = "GET_GENERAL_CONTENT_RESET";

export const SEARCH_USER_DATA_MATCH = "SEARCH_USER_DATA_MATCH";
export const SEARCH_USER_DATA_MATCH_SUCCESS = "SEARCH_USER_DATA_MATCH_SUCCESS";
export const SEARCH_USER_DATA_MATCH_ERROR = "SEARCH_USER_DATA_MATCH_ERROR";
export const SEARCH_USER_DATA_MATCH_RESET = "SEARCH_USER_DATA_MATCH_RESET";
//..Discussion Guide
export const GET_COUNSELEES_FINALIZATION_DETAILS = "GET_COUNSELEES_FINALIZATION_DETAILS";
export const GET_COUNSELEES_FINALIZATION_DETAILS_SUCCESS = "GET_COUNSELEES_FINALIZATION_DETAILS_SUCCESS";
export const GET_COUNSELEES_FINALIZATION_DETAILS_ERROR = "GET_COUNSELEES_FINALIZATION_DETAILS_ERROR";
export const GET_COUNSELEES_FINALIZATION_DETAILS_RESET = "GET_COUNSELEES_FINALIZATION_DETAILS_RESET";

export const ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS = "ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS";
export const ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS_SUCCESS = "ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS_SUCCESS";
export const ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS_ERROR = "ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS_ERROR";
export const ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS_RESET = "ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS_RESET";

export const UPDATE_IS_RECORDED_IN_SUCCESS_FACTORS = "UPDATE_IS_RECORDED_IN_SUCCESS_FACTORS";
export const UPDATE_IS_RECORDED_IN_SUCCESS_FACTORS_SUCCESS = "UPDATE_IS_RECORDED_IN_SUCCESS_FACTORS_SUCCESS";
export const UPDATE_IS_RECORDED_IN_SUCCESS_FACTORS_ERROR = "UPDATE_IS_RECORDED_IN_SUCCESS_FACTORS_ERROR";
export const UPDATE_IS_RECORDED_IN_SUCCESS_FACTORS_RESET = "UPDATE_IS_RECORDED_IN_SUCCESS_FACTORS_RESET";

export const GET_COUNSELEE_DISCUSSION_GUIDE = "GET_COUNSELEE_DISCUSSION_GUIDE";
export const GET_COUNSELEE_DISCUSSION_GUIDE_SUCCESS = "GET_COUNSELEE_DISCUSSION_GUIDE_SUCCESS";
export const GET_COUNSELEE_DISCUSSION_GUIDE_ERROR = "GET_COUNSELEE_DISCUSSION_GUIDE_ERROR";
export const GET_COUNSELEE_DISCUSSION_GUIDE_RESET = "GET_COUNSELEE_DISCUSSION_GUIDE_RESET";

// export const GET_SELECTED_COUNSELEE_DISCUSSION_GUIDE = "GET_SELECTED_COUNSELEE_DISCUSSION_GUIDE";
// export const GET_SELECTED_COUNSELEE_DISCUSSION_GUIDE_SUCCESS = "GET_SELECTED_COUNSELEE_DISCUSSION_GUIDE_SUCCESS";
// export const GET_SELECTED_COUNSELEE_DISCUSSION_GUIDE_ERROR = "GET_SELECTED_COUNSELEE_DISCUSSION_GUIDE_ERROR";
// export const GET_SELECTED_COUNSELEE_DISCUSSION_GUIDE_RESET = "GET_SELECTED_COUNSELEE_DISCUSSION_GUIDE_RESET";

export const ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE = "ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE";
export const ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE_SUCCESS = "ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE_SUCCESS";
export const ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE_ERROR = "ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE_ERROR";
export const ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE_RESET = "ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE_RESET";

export const SEARCH_DISCUSSION_GUIDE = "SEARCH_DISCUSSION_GUIDE";
export const SEARCH_DISCUSSION_GUIDE_SUCCESS = "SEARCH_DISCUSSION_GUIDE_SUCCESS";
export const SEARCH_DISCUSSION_GUIDE_ERROR = "SEARCH_DISCUSSION_GUIDE_ERROR";
export const SEARCH_DISCUSSION_GUIDE_RESET = "SEARCH_DISCUSSION_GUIDE_RESET";

export const RESET_DISCUSSION_GUIDE = "RESET_DISCUSSION_GUIDE";
export const RESET_DISCUSSION_GUIDE_SUCCESS = "RESET_DISCUSSION_GUIDE_SUCCESS";
export const RESET_DISCUSSION_GUIDE_ERROR = "RESET_DISCUSSION_GUIDE_ERROR";
export const RESET_DISCUSSION_GUIDE_DATA = "RESET_DISCUSSION_GUIDE_DATA";
