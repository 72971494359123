import * as types from "../actions/types";
import _ from "lodash";

const InitialOutsideClickState = {
  data: {},
};
const InitialOutsideClickCommonState = {
  data: {
    feature: "",
    changesDone: false,
    OpenModal: false,
    ProceedWithNavigation: false,
    callback: "",
  },
};

const InitialSaveUserAssessmentDataState = {
  fetching: false,
  data: {},
  error: {},
};

const InitialAllAssessmentDataState = {
  fetching: false,
  data: {},
  error: {},
};

const InitialAllUserAssessmentDataState = {
  fetching: false,
  data: {},
  error: {},
};

const InitialUserDetails = {
  fetching: false,
  data: {},
  error: {},
};
const InitialUserImg = {
  fetching: false,
  data: {},
  error: {},
};
const dataJson = {
  fetching: false,
  data: {},
  error: {},
};

const InitialAssessmentStart = {
  fetching: false,
  data: {},
  error: {},
};

const InitialAssessmentMetaData = {
  fetching: false,
  data: {},
  error: {},
};

const InitialUserProfileData = {
  fetching: false,
  data: {},
  error: {},
};

const InitialUserBobSummaryData = {
  fetching: false,
  data: {},
  error: {},
};

const InitialUserAssessmentStatusData = {
  fetching: false,
  data: {},
  error: {},
};

const InitialUserAssessmentScore = {
  fetching: false,
  data: {},
  error: {},
};

const InitialUserGoalScore = {
  fetching: false,
  data: {},
  error: {},
};

const InitialBobData = {
  fetching: false,
  data: {},
  error: {},
};

const InitialUpdateBobData = {
  fetching: false,
  data: {},
  error: {},
};

const InitialSubmitBobData = {
  fetching: false,
  data: {},
  error: {},
};

const InitialGetResponsibilitySectors = {
  fetching: false,
  data: {},
  error: {},
};

const InitialPillarGroupScore = {
  fetching: false,
  data: {},
  error: {},
};

const InitialGoalGroupScore = {
  fetching: false,
  data: {},
  error: {},
};

const InitialQuantitativeScore = {
  fetching: false,
  data: [],
  error: {},
};

const InitialApprovalData = {
  fetching: false,
  data: [],
  error: {},
  isPreview: false,
  previewParams: {},
};

const InitialDashboardPillarFilters = {
  data: [
    {
      option: "Select all",
      paramName: "SelectAll",
      subText: "",
      isSelected: false,
      isDisabled: false,
    },
    {
      option: "Region",
      paramName: "region",
      subText: "",
      isSelected: true,
      isDisabled: false,
    },
    {
      option: "Tenure",
      paramName: "tenor",
      subText: "",
      isSelected: true,
      isDisabled: false,
    },
    {
      option: "Primary Sector",
      paramName: "primarySector",
      subText: "",
      isSelected: false,
      isDisabled: false,
    },
    {
      option: "Secondary Sector",
      paramName: "secondarySector",
      subText: "",
      isSelected: false,
      isDisabled: false,
    },
    {
      option: "Office",
      paramName: "office",
      subText: "",
      isSelected: false,
      isDisabled: false,
    },
  ],
};

const InitialPPMDStatusFilters = {
  data: [
    {
      option: "Select all",
      paramName: "SelectAll",
      subText: "",
      isSelected: true,
      isDisabled: false,
    },
    {
      option: "In Draft",
      paramName: "inDraft",
      subText: "",
      isSelected: true,
      isDisabled: false,
    },
    {
      option: "Pending Approval",
      paramName: "pendingApproval",
      subText: "",
      isSelected: true,
      isDisabled: false,
    },
    {
      option: "Approved",
      paramName: "approved",
      subText: "",
      isSelected: true,
      isDisabled: false,
    },
    {
      option: "Requested Change",
      paramName: "requestedChange",
      subText: "",
      isSelected: true,
      isDisabled: false,
    },
  ],
};

const InitialDashboardGoalFilters = {
  data: [
    {
      option: "Select all",
      paramName: "SelectAll",
      subText: "",
      isSelected: false,
      isDisabled: false,
    },
    {
      option: "Region",
      paramName: "region",
      subText: "",
      isSelected: true,
      isDisabled: true,
    },
    {
      option: "Tenure",
      paramName: "tenor",
      subText: "",
      isSelected: false,
      isDisabled: false,
    },
    {
      option: "Primary Sector",
      paramName: "primarySector",
      subText: "",
      isSelected: false,
      isDisabled: false,
    },
    {
      option: "Secondary Sector",
      paramName: "secondarySector",
      subText: "",
      isSelected: false,
      isDisabled: false,
    },
    {
      option: "Office",
      paramName: "office",
      subText: "",
      isSelected: false,
      isDisabled: false,
    },
  ],
};

const InitialReviewerHomePageData = {
  fetching: false,
  data: {},
  error: {},
};

const InitialacceptOrRejectCounseleeData = {
  fetching: false,
  data: {},
  error: {},
};

const InitialMenuDetails = {
  data: {
    label: "Home",
    icon: "icon-home",
    id: "1005",
    route: "/home",
    activeIcon: "app_new.svg",
  },
};

const InitialSearchUser = {
  fetching: false,
  data: [],
  error: {},
};

const InitialResetUser = {
  fetching: false,
  data: {},
  error: {},
};

const InitialSearchClient = {
  fetching: false,
  data: {},
  error: {},
};

const InitialAPICode = {
  data: 0,
};

const InitialReportsData = {
  fetching: false,
  data: [],
  error: {},
};

const InitialUserUUID = {
  fetching: false,
  data: {},
  error: {},
};

const InitialExcelReportsData = {
  fetching: false,
  data: [],
  error: {},
};

const InitialTimePeriodStatus = {
  fetching: false,
  data: [],
  error: {},
  year: "",
};

const InitialAdminSearchUserDetails = {
  fetching: false,
  data: {},
  error: {},
};

const InitialAdminSearchManagerDetails = {
  fetching: false,
  data: {},
  error: {},
};

const IntitalSearchUserData = {
  fetching: false,
  data: {},
  error: {},
};

const IntitalSearchUserMatchData = {
  fetching: false,
  data: {},
  error: {},
};

const InitialAssessmentGoalData = {
  fetching: false,
  data: {},
  error: {},
};

const InitialAddAssessmentGoalData = {
  fetching: false,
  data: {},
  error: {},
};

const InitialEditAssessmentGoalData = {
  fetching: false,
  data: {},
  error: {},
};

const InitialReorderAssessmentGoalData = {
  fetching: false,
  data: {},
  error: {},
};

const InitialDeleteAssessmentGoalData = {
  fetching: false,
  data: {},
  error: {},
};
const InitialValidateUserChangeState = {
  fetching: false,
  data: {},
  error: {},
};
const InitialGetUserDataChangesState = {
  fetching: false,
  data: {},
  error: {},
};
const InitialUpdateUserDetailsState = {
  fetching: false,
  data: {},
  error: {},
};
const InitialEmailTemplateData = {
  fetching: false,
  data: [],
  error: {},
};
const InitialEditEmailTemplateData = {
  fetching: false,
  data: "",
  error: {},
};
const InitialAdhocMailListData = {
  fetching: false,
  data: [],
  error: {},
  reviewState: {},
};
const InitialCountTemplate = {
  fetching: false,
  data: {
    pendingCountGP: 0,
    pendingCountUser: 0,
    pendingCountAssessment: 0,
    total: 0,
  },
  error: {},
};

const IntialAdminState = {
  enable: false,
  type: "",
};

const InitialViewUserData = {
  financialYear: "",
  timePeriod: "",
  mailId: "",
  viewData: false,
};

const maintenanceErrorObj = {
  data: false,
};

const InitialCounseleeFinalizationDetails = {
  fetching: false,
  data: {},
  error: {},
};

export function outsideClikcFn(state = InitialOutsideClickState, action) {
  switch (action.type) {
    case types.OUTSIDE_CLICK:
      return { data: action.payload };
    case types.OUTSIDE_CLICK_TRUE:
      return { ...state, data: { ...state.data, flag: true } };
    case types.OUTSIDE_CLICK_CANCEL:
      return { ...state, data: { ...state.data, flag: false, cancel: true } };
    case types.OUTSIDE_CLICK_RESET:
      return InitialOutsideClickState;
    default:
      return state;
  }
}

export function outsideClickAdmin(state = InitialOutsideClickState, action) {
  switch (action.type) {
    case types.OUTSIDE_CLICK_ADMIN:
      return { data: action.payload };
    case types.OUTSIDE_CLICK_ADMIN_TRUE:
      return { ...state, data: { ...state.data, flag: true } };
    case types.OUTSIDE_CLICK_ADMIN_CANCEL:
      return { ...state, data: { ...state.data, flag: false, cancel: true } };
    case types.OUTSIDE_CLICK_ADMIN_RESET:
      return InitialOutsideClickState;
    default:
      return state;
  }
}

export function outsideClickBob(state = InitialOutsideClickState, action) {
  switch (action.type) {
    case types.OUTSIDE_CLICK_BOB:
      return { data: action.payload };
    case types.OUTSIDE_CLICK_BOB_TRUE:
      return { ...state, data: { ...state.data, flag: true } };
    case types.OUTSIDE_CLICK_BOB_CANCEL:
      return { ...state, data: { ...state.data, flag: false, cancel: true } };
    case types.OUTSIDE_CLICK_BOB_RESET:
      return InitialOutsideClickState;
    default:
      return state;
  }
}

export function outsideClickCommon(
  state = InitialOutsideClickCommonState,
  action
) {
  switch (action.type) {
    case types.OUTSIDE_CLICK_COMMON:
      return { data: action.payload };
    case types.OUTSIDE_CLICK_COMMON_OPEN_MODEL:
      return {
        ...state,
        data: {
          ...state.data,
          OpenModal: true,
          callback: action.payload.callback,
        },
      };
    case types.OUTSIDE_CLICK_COMMON_PROCEED:
      return {
        ...state,
        data: {
          ...state.data,
          OpenModal: false,
          ProceedWithNavigation: action.payload.ProceedWithNavigation,
        },
      };

    case types.OUTSIDE_CLICK_COMMON_RESET:
      return InitialOutsideClickState;
    default:
      return state;
  }
}

export function setUserAssesmentData(
  state = InitialSaveUserAssessmentDataState,
  action
) {
  switch (action.type) {
    case types.SAVE_USER_ASSESSMENT_DATA:
      return { fetching: true, data: {}, error: null };
    case types.SAVE_USER_ASSESSMENT_DATA_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.SAVE_USER_ASSESSMENT_DATA_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.SAVE_USER_ASSESSMENT_DATA_RESET:
      return InitialSaveUserAssessmentDataState;
    default:
      return state;
  }
}

export function getAllAssesmentData(
  state = InitialAllAssessmentDataState,
  action
) {
  switch (action.type) {
    case types.GET_ALL_ASSESSMENT_DATA:
      return { fetching: true, data: {}, error: null };
    case types.GET_ALL_ASSESSMENT_DATA_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_ALL_ASSESSMENT_DATA_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_ALL_ASSESSMENT_DATA_RESET:
      return InitialAllAssessmentDataState;
    default:
      return state;
  }
}
export function getAllPrevAssesmentData(
  state = InitialAllAssessmentDataState,
  action
) {
  switch (action.type) {
    case types.GET_ALL_PREV_ASSESSMENT_DATA:
      return { fetching: true, data: {}, error: null };
    case types.GET_ALL_PREV_ASSESSMENT_DATA_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_ALL_PREV_ASSESSMENT_DATA_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_ALL_PREV_ASSESSMENT_DATA_RESET:
      return InitialAllAssessmentDataState;
    default:
      return state;
  }
}

export function getAllUserAssesmentData(
  state = InitialAllUserAssessmentDataState,
  action
) {
  switch (action.type) {
    case types.GET_ALL_USER_ASSESSMENT_DATA:
      return { fetching: true, data: {}, error: null };
    case types.GET_ALL_USER_ASSESSMENT_DATA_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_ALL_USER_ASSESSMENT_DATA_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_ALL_USER_ASSESSMENT_DATA_RESET:
      return InitialAllUserAssessmentDataState;
    default:
      return state;
  }
}

export function getUserDetails(state = InitialUserDetails, action) {
  switch (action.type) {
    case types.GET_USER_DETAILS:
      return { fetching: true, data: {}, error: null };
    case types.GET_USER_DETAILS_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_USER_DETAILS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_USER_DETAILS_RESET:
      return InitialUserDetails;
    default:
      return state;
  }
}
export function getUserImg(state = InitialUserImg, action) {
  switch (action.type) {
    case types.GET_USER_IMG:
      return { fetching: true, data: {}, error: null };
    case types.GET_USER_IMG_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_USER_IMG_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_USER_IMG_RESET:
      return InitialUserImg;
    default:
      return state;
  }
}
export function getOptionValues(state = dataJson, action) {
  switch (action.type) {
    case types.GET_OPTION_VALUES:
      return { fetching: true, data: {}, error: null };
    case types.GET_OPTION_VALUES_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_OPTION_VALUES_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_OPTION_VALUES_RESET:
      return dataJson;
    default:
      return state;
  }
}
export function getAllOptions(state = dataJson, action) {
  switch (action.type) {
    case types.GET_ALL_OPTIONS:
      return { fetching: true, data: {}, error: null };
    case types.GET_ALL_OPTIONS_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_ALL_OPTIONS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_ALL_OPTIONS_RESET:
      return dataJson;
    default:
      return state;
  }
}
export function getAssesmentMetaData(
  state = InitialAssessmentMetaData,
  action
) {
  switch (action.type) {
    case types.GET_ASSESSMENT_META_DATA:
      return { fetching: true, data: {}, error: null };
    case types.GET_ASSESSMENT_META_DATA_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_ASSESSMENT_META_DATA_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_ASSESSMENT_META_DATA_RESET:
      return InitialAssessmentMetaData;
    default:
      return state;
  }
}

export function getValidationAssesmentMetaData(
  state = { fetching: false, activeYear: 0, error: null },
  action
) {
  switch (action.type) {
    case types.GET_VALIDATION_ASSESSMENT_META_DATA:
      return { ...state, fetching: true, error: null };
    case types.GET_VALIDATION_ASSESSMENT_META_DATA_SUCCESS:
      let parsedYear = 0;
      const metadataYear = _.get(
        action,
        "payload.metadata.data.activePhaseYear",
        0
      );
      if (!metadataYear) {
        parsedYear = _.get(action, "payload.rollforwardData.fromYear", 0);
      } else {
        parsedYear = metadataYear;
      }
      return { ...state, fetching: false, activeYear: parsedYear, error: null };
    case types.GET_VALIDATION_ASSESSMENT_META_DATA_ERROR:
      return { fetching: false, activeYear: 0, error: action.payload };
    case types.GET_VALIDATION_ASSESSMENT_META_DATA_RESET:
      return { fetching: false, activeYear: 0, error: null };
    default:
      return state;
  }
}

export function setAssesmentStart(state = InitialAssessmentStart, action) {
  switch (action.type) {
    case types.MARK_ASSESSMENT_START:
      return { fetching: true, data: {}, error: null };
    case types.MARK_ASSESSMENT_START_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.MARK_ASSESSMENT_START_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.MARK_ASSESSMENT_START_RESET:
      return InitialAssessmentStart;
    default:
      return state;
  }
}

export function getUserProfileById(state = InitialUserProfileData, action) {
  switch (action.type) {
    case types.GET_USER_PROFILE_BY_ID:
      return { fetching: true, data: {}, error: null };
    case types.GET_USER_PROFILE_BY_ID_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_USER_PROFILE_BY_ID_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_USER_PROFILE_BY_ID_RESET:
      return InitialUserProfileData;
    default:
      return state;
  }
}

export function getUserBobSummaryData(
  state = InitialUserBobSummaryData,
  action
) {
  switch (action.type) {
    case types.GET_USER_BOB_SUMMARY:
      return { fetching: true, data: {}, error: null };
    case types.GET_USER_BOB_SUMMARY_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_USER_BOB_SUMMARY_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_USER_BOB_SUMMARY_RESET:
      return InitialUserBobSummaryData;
    default:
      return state;
  }
}

export function submitUserAssessment(state = dataJson, action) {
  switch (action.type) {
    case types.SUBMIT_USER_ASSESSMENT:
      return { fetching: true, data: {}, error: null };
    case types.SUBMIT_USER_ASSESSMENT_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.SUBMIT_USER_ASSESSMENT_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.SUBMIT_USER_ASSESSMENT_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getUserAssessmentStatusData(
  state = InitialUserAssessmentStatusData,
  action
) {
  switch (action.type) {
    case types.GET_USER_ASSESSMENT_STATUS:
      return { fetching: true, data: {}, error: null };
    case types.GET_USER_ASSESSMENT_STATUS_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_USER_ASSESSMENT_STATUS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_USER_ASSESSMENT_STATUS_RESET:
      return InitialUserAssessmentStatusData;
    default:
      return state;
  }
}

export function getUserAssesmentScore(
  state = InitialUserAssessmentScore,
  action
) {
  switch (action.type) {
    case types.GET_USER_ASSESSMENT_SCORE:
      return { fetching: true, data: {}, error: null };
    case types.GET_USER_ASSESSMENT_SCORE_SUCCESS:
      return { fetching: false, data: action.payload.data, error: null };
    case types.GET_USER_ASSESSMENT_SCORE_ERROR:
      return { fetching: false, data: null, error: action.payload };
    default:
      return state;
  }
}

export function getBobData(state = InitialBobData, action) {
  switch (action.type) {
    case types.GET_BOB_DATA:
      return { fetching: true, data: {}, error: null };
    case types.GET_BOB_DATA_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_BOB_DATA_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_BOB_DATA_RESET:
      return InitialBobData;
    default:
      return state;
  }
}

export function updateBobData(state = InitialUpdateBobData, action) {
  switch (action.type) {
    case types.UPDATE_BOB_DATA:
      return { fetching: true, data: {}, error: null };
    case types.UPDATE_BOB_DATA_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.UPDATE_BOB_DATA_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.UPDATE_BOB_DATA_RESET:
      return InitialUpdateBobData;
    default:
      return state;
  }
}

export function getUserGoalScore(state = InitialUserGoalScore, action) {
  switch (action.type) {
    case types.GET_USER_GOAL_SCORE:
      return { fetching: true, data: {}, error: null };
    case types.GET_USER_GOAL_SCORE_SUCCESS:
      return { fetching: false, data: action.payload.data, error: null };
    case types.GET_USER_GOAL_SCORE_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_USER_GOAL_SCORE_RESET:
      return InitialUserGoalScore;
    default:
      return state;
  }
}
export function getResponsibiltyAndSectors(
  state = InitialGetResponsibilitySectors,
  action
) {
  switch (action.type) {
    case types.GET_RESPONSIBILITY_SECTORS:
      return { fetching: true, data: {}, error: null };
    case types.GET_RESPONSIBILITY_SECTORS_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_RESPONSIBILITY_SECTORS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_RESPONSIBILITY_SECTORS_RESET:
      return InitialGetResponsibilitySectors;
    default:
      return state;
  }
}

export function setDashboardPillarFilters(
  state = InitialDashboardPillarFilters,
  action
) {
  switch (action.type) {
    case types.SET_DASHBOARD_PILLAR_FILTERS:
      return { data: action.payload };
    case types.RESET_DASHBOARD_PILLAR_FILTERS:
      return InitialDashboardPillarFilters;
    default:
      return state;
  }
}

export function setDashboardGoalFilters(
  state = InitialDashboardGoalFilters,
  action
) {
  switch (action.type) {
    case types.SET_DASHBOARD_GOAL_FILTERS:
      return { data: action.payload };
    case types.RESET_DASHBOARD_GOAL_FILTERS:
      return InitialDashboardGoalFilters;
    default:
      return state;
  }
}

export function getCounseleesStatus(state = dataJson, action) {
  switch (action.type) {
    case types.GET_COUNSELEES_STATUS:
      return { fetching: true, data: {}, error: null };
    case types.GET_COUNSELEES_STATUS_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_COUNSELEES_STATUS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_COUNSELEES_STATUS_RESET:
      return dataJson;
    default:
      return state;
  }
}
//..Discussion Guide
export function getCounseleesFinalizationDetails(state = InitialCounseleeFinalizationDetails, action) {
  switch (action.type) {
    case types.GET_COUNSELEES_FINALIZATION_DETAILS:
      return { fetching: true, data: {}, error: null };
    case types.GET_COUNSELEES_FINALIZATION_DETAILS_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_COUNSELEES_FINALIZATION_DETAILS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_COUNSELEES_FINALIZATION_DETAILS_RESET:
      return InitialCounseleeFinalizationDetails;
    default:
      return state;
  }
}

export function addOrEditCounseleesFinalizationDetails(state = InitialCounseleeFinalizationDetails, action) {
  switch (action.type) {
    case types.ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS:
      return { fetching: true, data: {}, error: null };
    case types.ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS_RESET:
      return InitialCounseleeFinalizationDetails;
    default:
      return state;
  }
}

export function getCounseleeDiscussionGuide(state = InitialCounseleeFinalizationDetails, action) {
  switch (action.type) {
    case types.GET_COUNSELEE_DISCUSSION_GUIDE:
      return { fetching: true, data: {}, error: null };
    case types.GET_COUNSELEE_DISCUSSION_GUIDE_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_COUNSELEE_DISCUSSION_GUIDE_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_COUNSELEE_DISCUSSION_GUIDE_RESET:
      return InitialCounseleeFinalizationDetails;
    default:
      return state;
  }
}

export function addOrUpdateCounseleeDiscussionGuide(state = InitialCounseleeFinalizationDetails, action) {
  switch (action.type) {
    case types.ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE:
      return { fetching: true, data: {}, error: null };
    case types.ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE_RESET:
      return InitialCounseleeFinalizationDetails;
    default:
      return state;
  }
}

export function searchDiscussionGuide(state = InitialSearchUser, action) {
  switch (action.type) {
    case types.SEARCH_DISCUSSION_GUIDE:
      return { fetching: true, data: [], error: null };
    case types.SEARCH_DISCUSSION_GUIDE_SUCCESS:
      return { fetching: false, data: action.payload.data, error: null };
    case types.SEARCH_DISCUSSION_GUIDE_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.SEARCH_DISCUSSION_GUIDE_RESET:
      return InitialSearchUser;
    default:
      return state;
  }
}

export function resetDiscussionGuide(state = InitialResetUser, action) {
  switch (action.type) {
    case types.RESET_DISCUSSION_GUIDE:
      return { fetching: true, data: {}, error: null };
    case types.RESET_DISCUSSION_GUIDE_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.RESET_DISCUSSION_GUIDE_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.RESET_DISCUSSION_GUIDE_DATA:
      return { fetching: false, data: {}, error: action.payload };
    default:
      return state;
  }
}

export function getBobRollforwardStatus(state = dataJson, action) {
  switch (action.type) {
    case types.GET_BOB_ROLLFORWARD_STATUS:
      return { fetching: true, data: {}, error: null };
    case types.GET_BOB_ROLLFORWARD_STATUS_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_BOB_ROLLFORWARD_STATUS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_BOB_ROLLFORWARD_STATUS_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getBobRollforwardToNextPhase(state = dataJson, action) {
  switch (action.type) {
    case types.ROLLFORWARD_BOB_TO_NEXT_PHASE:
      return { fetching: true, data: {}, error: null };
    case types.ROLLFORWARD_BOB_TO_NEXT_PHASE_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.ROLLFORWARD_BOB_TO_NEXT_PHASE_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.ROLLFORWARD_BOB_TO_NEXT_PHASE_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function progressStatusFromBob(state = dataJson, action) {
  switch (action.type) {
    case types.PROGRESS_STATUS_FROM_BOB:
      return { fetching: true, data: {}, error: null };
    case types.PROGRESS_STATUS_FROM_BOB_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.PROGRESS_STATUS_FROM_BOB_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.PROGRESS_STATUS_FROM_BOB_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function validAssessmentYears(state = dataJson, action) {
  switch (action.type) {
    case types.VALID_ASSESSMENT_YEARS:
      return { fetching: true, data: {}, error: null };
    case types.VALID_ASSESSMENT_YEARS_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.VALID_ASSESSMENT_YEARS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.VALID_ASSESSMENT_YEARS_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function bobAdminActionStatus(state = dataJson, action) {
  switch (action.type) {
    case types.BOB_ADMIN_ACTION_STATUS:
      return { fetching: true, data: {}, error: null };
    case types.BOB_ADMIN_ACTION_STATUS_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.BOB_ADMIN_ACTION_STATUS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.BOB_ADMIN_ACTION_STATUS_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getPillarGroupScore(state = InitialPillarGroupScore, action) {
  switch (action.type) {
    case types.GET_PILLAR_GROUP_SCORE:
      return { fetching: true, data: {}, error: null };
    case types.GET_PILLAR_GROUP_SCORE_SUCCESS:
      return { fetching: false, data: action.payload.data, error: null };
    case types.GET_PILLAR_GROUP_SCORE_ERROR:
      return { fetching: false, data: null, error: action.payload };
    default:
      return state;
  }
}

export function getGoalGroupScore(state = InitialGoalGroupScore, action) {
  switch (action.type) {
    case types.GET_GOAL_GROUP_SCORE:
      return { fetching: true, data: {}, error: null };
    case types.GET_GOAL_GROUP_SCORE_SUCCESS:
      return { fetching: false, data: action.payload.data, error: null };
    case types.GET_GOAL_GROUP_SCORE_ERROR:
      return { fetching: false, data: null, error: action.payload };
    default:
      return state;
  }
}

export function getQuantitativeScore(state = InitialQuantitativeScore, action) {
  switch (action.type) {
    case types.GET_QUANTITATIVE_SCORE:
      return { fetching: true, data: [], error: null };
    case types.GET_QUANTITATIVE_SCORE_SUCCESS:
      return { fetching: false, data: action.payload.data, error: null };
    case types.GET_QUANTITATIVE_SCORE_ERROR:
      return { fetching: false, data: [], error: action.payload };
    default:
      return state;
  }
}

export function ppmdStatusFilters(state = InitialPPMDStatusFilters, action) {
  switch (action.type) {
    case types.SET_PPMD_STATUS_FILTERS:
      return { data: action.payload };
    case types.RESET_PPMD_STATUS_FILTERS:
      return InitialPPMDStatusFilters;
    default:
      return state;
  }
}

export function getReviewerHomePageData(
  state = InitialReviewerHomePageData,
  action
) {
  switch (action.type) {
    case types.GET_REVIEWER_HOMEPAGE_DATA:
      return { fetching: true, data: {}, error: null };
    case types.GET_REVIEWER_HOMEPAGE_DATA_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_REVIEWER_HOMEPAGE_DATA_ERROR:
      return { fetching: false, data: null, error: action.payload };
    default:
      return state;
  }
}

export function acceptOrRejectCounselee(
  state = InitialacceptOrRejectCounseleeData,
  action
) {
  switch (action.type) {
    case types.APPROVE_OR_REJECT_COUNSELEE:
      return { fetching: true, data: {}, error: null };
    case types.APPROVE_OR_REJECT_COUNSELEE_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.APPROVE_OR_REJECT_COUNSELEE_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.APPROVE_OR_REJECT_COUNSELEE_RESET:
      return InitialacceptOrRejectCounseleeData;
    default:
      return state;
  }
}

export function commentRead(state = dataJson, action) {
  switch (action.type) {
    case types.COMMENT_READ:
      return { fetching: true, data: {}, error: null };
    case types.COMMENT_READ_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.COMMENT_READ_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.COMMENT_READ_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function searchUser(state = InitialSearchUser, action) {
  switch (action.type) {
    case types.SEARCH_USER:
      return { fetching: true, data: [], error: null };
    case types.SEARCH_USER_SUCCESS:
      return { fetching: false, data: action.payload.data, error: null };
    case types.SEARCH_USER_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.SEARCH_USER_RESET:
      return InitialSearchUser;
    default:
      return state;
  }
}

export function setApiErrorCode(state = InitialAPICode, action) {
  switch (action.type) {
    case types.API_SERVER_ERROR_CODE:
      return { data: action.payload };
    case types.RESET_API_SERVER_ERROR_CODE:
      return { data: 0 };
    default:
      return state;
  }
}

export function resetUser(state = InitialResetUser, action) {
  switch (action.type) {
    case types.RESET_USER:
      return { fetching: true, data: {}, error: null };
    case types.RESET_USER_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.RESET_USER_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.RESET_USER_DATA:
      return { fetching: false, data: {}, error: action.payload };
    default:
      return state;
  }
}

export function searchClient(state = InitialSearchClient, action) {
  switch (action.type) {
    case types.SEARCH_CLIENT:
      return { fetching: true, data: {}, error: null };
    case types.SEARCH_CLIENT_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.SEARCH_CLIENT_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.SEARCH_CLIENT_RESET:
      return { fetching: false, data: {}, error: action.payload };
    default:
      return state;
  }
}

export function getGeneralParam(state = dataJson, action) {
  switch (action.type) {
    case types.GET_GENERAL_PARAMETERS:
      return { fetching: true, data: {}, error: null };
    case types.GET_GENERAL_PARAMETERS_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_GENERAL_PARAMETERS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_GENERAL_PARAMETERS_RESET:
    case types.RESET_APPROVAL_PREVIEW_DATA:
      return { fetching: false, data: {}, error: null };
    default:
      return state;
  }
}

export function editGeneralParam(state = dataJson, action) {
  switch (action.type) {
    case types.EDIT_GENERAL_PARAMETERS:
      return { fetching: true, data: {}, error: null };
    case types.EDIT_GENERAL_PARAMETERS_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.EDIT_GENERAL_PARAMETERS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.EDIT_GENERAL_PARAMETERS_RESET:
      return { fetching: false, data: {}, error: action.payload };
    default:
      return state;
  }
}

export function getReportsData(state = InitialReportsData, action) {
  switch (action.type) {
    case types.GET_REPORTS_DATA:
      return { fetching: true, data: [], error: null };
    case types.GET_REPORTS_DATA_SUCCESS:
      return { fetching: false, data: action.payload.data, error: null };
    case types.GET_REPORTS_DATA_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_REPORTS_DATA_RESET:
      return { fetching: false, data: [], error: action.payload };
    default:
      return state;
  }
}
export function setUserUUID(state = InitialUserUUID, action) {
  switch (action.type) {
    case types.SET_UUID_FOR_USER:
      return { fetching: true, data: {}, error: null };
    case types.SET_UUID_FOR_USER_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.SET_UUID_FOR_USER_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.SET_UUID_FOR_USER_RESET:
      return InitialUserUUID;
    default:
      return state;
  }
}

export function getExcelReportData(state = InitialExcelReportsData, action) {
  switch (action.type) {
    case types.GET_EXCEL_REPORTS_DATA:
      return { fetching: true, data: {}, error: null };
    case types.GET_EXCEL_REPORTS_DATA_SUCCESS:
      return { fetching: false, data: action.payload.data, error: null };
    case types.GET_EXCEL_REPORTS_DATA_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_EXCEL_REPORTS_DATA_RESET:
      return state;
    default:
      return state;
  }
}

export function getTimePeriodStatus(state = InitialTimePeriodStatus, action) {
  switch (action.type) {
    case types.GET_TIME_PERIOD_STATUS:
      return { fetching: true, data: {}, error: null, year: "" };
    case types.GET_TIME_PERIOD_STATUS_SUCCESS:
      return {
        fetching: false,
        data: action.payload.data,
        error: null,
        year: action.payload.year,
      };
    case types.GET_TIME_PERIOD_STATUS_ERROR:
      return { fetching: false, data: null, error: action.payload, year: "" };
    case types.GET_TIME_PERIOD_STATUS_RESET:
      return InitialTimePeriodStatus;
    default:
      return state;
  }
}

export function getAssessmentSectionList(state = dataJson, action) {
  switch (action.type) {
    case types.GET_ASSESSMENT_SECTION_LIST:
      return { fetching: true, data: {}, error: null };
    case types.GET_ASSESSMENT_SECTION_LIST_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_ASSESSMENT_SECTION_LIST_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_ASSESSMENT_SECTION_LIST_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function editAssessmentSectionstate(state = dataJson, action) {
  switch (action.type) {
    case types.EDIT_ASSESSMENT_SECTION:
      return { fetching: true, data: {}, error: null };
    case types.EDIT_ASSESSMENT_SECTION_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.EDIT_ASSESSMENT_SECTION_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.EDIT_ASSESSMENT_SECTION_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function deleteAssessmentSectionstate(state = dataJson, action) {
  switch (action.type) {
    case types.DELETE_ASSESSMENT_SECTION:
      return { fetching: true, data: {}, error: null };
    case types.DELETE_ASSESSMENT_SECTION_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.DELETE_ASSESSMENT_SECTION_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.DELETE_ASSESSMENT_SECTION_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getAdminSearchUserDetails(
  state = InitialAdminSearchUserDetails,
  action
) {
  switch (action.type) {
    case types.GET_ADMIN_SEARCH_USER_DETAILS:
      return { fetching: true, data: {}, error: null };
    case types.GET_ADMIN_SEARCH_USER_DETAILS_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_ADMIN_SEARCH_USER_DETAILS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_ADMIN_SEARCH_USER_DETAILS_RESET:
      return InitialAdminSearchUserDetails;
    default:
      return state;
  }
}

export function getAdminSearchManagerDetails(
  state = InitialAdminSearchManagerDetails,
  action
) {
  switch (action.type) {
    case types.GET_ADMIN_SEARCH_MANAGER_DETAILS:
      return { fetching: true, data: {}, error: null };
    case types.GET_ADMIN_SEARCH_MANAGER_DETAILS_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_ADMIN_SEARCH_MANAGER_DETAILS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_ADMIN_SEARCH_MANAGER_DETAILS_RESET:
      return InitialAdminSearchManagerDetails;
    default:
      return state;
  }
}

export function getUserCountstate(state = dataJson, action) {
  switch (action.type) {
    case types.GET_USER_COUNT:
      return { fetching: true, data: {}, error: null };
    case types.GET_USER_COUNT_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_USER_COUNT_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_USER_COUNT_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function searchUserData(state = IntitalSearchUserData, action) {
  switch (action.type) {
    case types.SEARCH_USER_DATA:
      return { fetching: true, data: {}, error: null };
    case types.SEARCH_USER_DATA_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.SEARCH_USER_DATA_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.SEARCH_USER_DATA_RESET:
      return InitialAdminSearchManagerDetails;
    default:
      return state;
  }
}

export function searchUserDataMatch(state = IntitalSearchUserMatchData, action) {
  switch (action.type) {
    case types.SEARCH_USER_DATA_MATCH:
      return { fetching: true, data: {}, error: null };
    case types.SEARCH_USER_DATA_MATCH_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.SEARCH_USER_DATA_MATCH_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.SEARCH_USER_DATA_MATCH_RESET:
      return InitialAdminSearchManagerDetails;
    default:
      return state;
  }
}

export function rollForwardUsers(state = dataJson, action) {
  switch (action.type) {
    case types.ROLL_FORWARD_USER:
      return { fetching: true, data: {}, error: null };
    case types.ROLL_FORWARD_USER_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.ROLL_FORWARD_USER_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.ROLL_FORWARD_USER_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function checkLockStatus(state = dataJson, action) {
  switch (action.type) {
    case types.CHECK_LOCK_STATUS:
      return { fetching: true, data: {}, error: null };
    case types.CHECK_LOCK_STATUS_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.CHECK_LOCK_STATUS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.CHECK_LOCK_STATUS_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function addMultipleUsers(state = dataJson, action) {
  switch (action.type) {
    case types.ADD_MULTIPLE_USER:
      return { fetching: true, data: {}, error: null };
    case types.ADD_MULTIPLE_USER_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.ADD_MULTIPLE_USER_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.ADD_MULTIPLE_USER_RESET:
      return dataJson;
    default:
      return state;
  }
}
//..Bulk Upload Discussion Guide
export function bulkUploadDiscussionGuide(state = dataJson, action) {
  switch (action.type) {
    case types.BULK_UPLOAD_DISCUSSION_GUIDE:
      return { fetching: true, data: {}, error: null };
    case types.BULK_UPLOAD_DISCUSSION_GUIDE_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.BULK_UPLOAD_DISCUSSION_GUIDE_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.BULK_UPLOAD_DISCUSSION_GUIDE_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function addSingleUser(state = dataJson, action) {
  switch (action.type) {
    case types.ADD_SINGLE_USER:
      return { fetching: true, data: {}, error: null };
    case types.ADD_SINGLE_USER_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.ADD_SINGLE_USER_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.ADD_SINGLE_USER_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function editSingleUser(state = dataJson, action) {
  switch (action.type) {
    case types.EDIT_SINGLE_USER:
      return { fetching: true, data: {}, error: null };
    case types.EDIT_SINGLE_USER_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.EDIT_SINGLE_USER_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.EDIT_SINGLE_USER_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function deleteSingleUser(state = dataJson, action) {
  switch (action.type) {
    case types.DELETE_SINGLE_USER:
      return { fetching: true, data: {}, error: null };
    case types.DELETE_SINGLE_USER_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.DELETE_SINGLE_USER_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.DELETE_SINGLE_USER_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getAdminConfig(state = dataJson, action) {
  switch (action.type) {
    case types.GET_ADMIN_MASTER_CONFIG:
      return { fetching: true, data: {}, error: null };
    case types.GET_ADMIN_MASTER_CONFIG_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_ADMIN_MASTER_CONFIG_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_ADMIN_MASTER_CONFIG_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function addGlobalMetric(state = dataJson, action) {
  switch (action.type) {
    case types.ADD_GLOBAL_METRIC:
      return { fetching: true, data: {}, error: null };
    case types.ADD_GLOBAL_METRIC_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.ADD_GLOBAL_METRIC_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.ADD_GLOBAL_METRIC_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function editGlobalMetric(state = dataJson, action) {
  switch (action.type) {
    case types.EDIT_GLOBAL_METRIC:
      return { fetching: true, data: {}, error: null };
    case types.EDIT_GLOBAL_METRIC_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.EDIT_GLOBAL_METRIC_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.EDIT_GLOBAL_METRIC_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function mercuryFileUpload(state = dataJson, action) {
  switch (action.type) {
    case types.MERCURY_FILE_UPLOAD:
      return { fetching: true, data: {}, error: null };
    case types.MERCURY_FILE_UPLOAD_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.MERCURY_FILE_UPLOAD_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.MERCURY_FILE_UPLOAD_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function deleteGlobalMetric(state = dataJson, action) {
  switch (action.type) {
    case types.DELETE_GLOBAL_METRIC:
      return { fetching: true, data: {}, error: null };
    case types.DELETE_GLOBAL_METRIC_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.DELETE_GLOBAL_METRIC_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.DELETE_GLOBAL_METRIC_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function reOrderGlobalMetric(state = dataJson, action) {
  switch (action.type) {
    case types.REORDER_GLOBAL_METRIC:
      return { fetching: true, data: {}, error: null };
    case types.REORDER_GLOBAL_METRIC_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.REORDER_GLOBAL_METRIC_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.REORDER_GLOBAL_METRIC_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function reOrderAssessmentSection(state = dataJson, action) {
  switch (action.type) {
    case types.REORDER_ASSESSMENT_SECTION:
      return { fetching: true, data: {}, error: null };
    case types.REORDER_ASSESSMENT_SECTION_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.REORDER_ASSESSMENT_SECTION_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.REORDER_ASSESSMENT_SECTION_RESET:
      return dataJson;
    default:
      return state;
  }
}
export function rollForwardAssessment(state = dataJson, action) {
  switch (action.type) {
    case types.ROLL_FORWARD_ASSESSMENT:
      return { fetching: true, data: {}, error: null };
    case types.ROLL_FORWARD_ASSESSMENT_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.ROLL_FORWARD_ASSESSMENT_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.ROLL_FORWARD_ASSESSMENT_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getAssesmentGoalData(
  state = InitialAssessmentGoalData,
  action
) {
  switch (action.type) {
    case types.GET_ASSESSMENT_GOAL:
      return { fetching: true, data: {}, error: null };
    case types.GET_ASSESSMENT_GOAL_SUCCESS:
      const data = action.payload
        ? action.payload.data
          ? action.payload.data[0]
          : {}
        : {};
      return { fetching: false, data, error: null };
    case types.GET_ASSESSMENT_GOAL_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    case types.RESET_ASSESSMENT_GOAL:
      return InitialAssessmentGoalData;
    default:
      return state;
  }
}

export function addAssessmentGoal(state = dataJson, action) {
  switch (action.type) {
    case types.ADD_ASSESSMENT_GOAL:
      return { fetching: true, data: {}, error: null };
    case types.ADD_ASSESSMENT_GOAL_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.ADD_ASSESSMENT_GOAL_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    case types.RESET_ADD_ASSESSMENT_GOAL:
      return InitialAddAssessmentGoalData;
    default:
      return state;
  }
}

export function editAssessmentGoal(state = dataJson, action) {
  switch (action.type) {
    case types.EDIT_ASSESSMENT_GOAL:
      return { fetching: true, data: {}, error: null };
    case types.EDIT_ASSESSMENT_GOAL_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.EDIT_ASSESSMENT_GOAL_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    case types.RESET_EDIT_ASSESSMENT_GOAL:
      return InitialEditAssessmentGoalData;
    default:
      return state;
  }
}

export function reorderAssessmentGoal(state = dataJson, action) {
  switch (action.type) {
    case types.REORDER_ASSESSMENT_GOAL:
      return { fetching: true, data: {}, error: null };
    case types.REORDER_ASSESSMENT_GOAL_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.REORDER_ASSESSMENT_GOAL_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    case types.RESET_REORDER_ASSESSMENT_GOAL:
      return InitialReorderAssessmentGoalData;
    default:
      return state;
  }
}

export function deleteAssessmentGoal(state = dataJson, action) {
  switch (action.type) {
    case types.DELETE_ASSESSMENT_GOAL:
      return { fetching: true, data: {}, error: null };
    case types.DELETE_ASSESSMENT_GOAL_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.DELETE_ASSESSMENT_GOAL_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    case types.RESET_DELETE_ASSESSMENT_GOAL:
      return InitialDeleteAssessmentGoalData;
    default:
      return state;
  }
}
export function getAssessmentStatus(state = dataJson, action) {
  switch (action.type) {
    case types.GET_ASSESSMENT_STATUS:
      return { ...state, fetching: true, error: null };
    case types.GET_ASSESSMENT_STATUS_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_ASSESSMENT_STATUS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_ASSESSMENT_STATUS_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function uploadAssessmentContent(state = dataJson, action) {
  switch (action.type) {
    case types.UPLOAD_ASSESSMENT_CONTENT:
      return { fetching: true, data: {}, error: null };
    case types.UPLOAD_ASSESSMENT_CONTENT_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.UPLOAD_ASSESSMENT_CONTENT_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.UPLOAD_ASSESSMENT_CONTENT_RESET:
      return dataJson;
    default:
      return state;
  }
}
export function regionDataUpload(state = dataJson, action) {
  switch (action.type) {
    case types.REGIONAL_DATA_UPLOAD:
      return { fetching: true, data: {}, error: null };
    case types.REGIONAL_DATA_UPLOAD_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.REGIONAL_DATA_UPLOAD_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.REGIONAL_DATA_UPLOAD_RESET:
      return dataJson;
    default:
      return state;
  }
}
export function generateBookOfBusiness(state = dataJson, action) {
  switch (action.type) {
    case types.GENERATE_BOOK_OF_BUSINESS:
      return { fetching: true, data: {}, error: null };
    case types.GENERATE_BOOK_OF_BUSINESS_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GENERATE_BOOK_OF_BUSINESS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GENERATE_BOOK_OF_BUSINESS_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getValidateUserChangeData(
  state = InitialValidateUserChangeState,
  action
) {
  switch (action.type) {
    case types.VALIDATE_USER_CHANGES:
      return { fetching: true, data: {}, error: null };
    case types.VALIDATE_USER_CHANGES_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.VALIDATE_USER_CHANGES_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.VALIDATE_USER_CHANGES_RESET:
      return InitialValidateUserChangeState;
    default:
      return state;
  }
}

export function getUserDataChangesData(
  state = InitialGetUserDataChangesState,
  action
) {
  switch (action.type) {
    case types.GET_USER_DATA_CHANGES:
      return { fetching: true, data: {}, error: null };
    case types.GET_USER_DATA_CHANGES_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_USER_DATA_CHANGES_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_USER_DATA_CHANGES_RESET:
      return InitialValidateUserChangeState;
    default:
      return state;
  }
}

export function updateUserDetails(
  state = InitialUpdateUserDetailsState,
  action
) {
  switch (action.type) {
    case types.UPDATE_USER_DETAILS:
      return { fetching: true, data: {}, error: null };
    case types.UPDATE_USER_DETAILS_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.UPDATE_USER_DETAILS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.UPDATE_USER_DETAILS_RESET:
      return InitialUpdateUserDetailsState;
    default:
      return state;
  }
}
export function deleteUsers(state = dataJson, action) {
  switch (action.type) {
    case types.DELETE_USERS:
      return { fetching: true, data: {}, error: null };
    case types.DELETE_USERS_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.DELETE_USERS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.DELETE_USERS_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getPhaseValidationStatus(state = dataJson, action) {
  switch (action.type) {
    case types.GET_PHASE_VALIDATION_STATUS:
      return { fetching: true, data: {}, error: null };
    case types.GET_PHASE_VALIDATION_STATUS_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_PHASE_VALIDATION_STATUS_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_PHASE_VALIDATION_STATUS_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function addImpactStatement(state = dataJson, action) {
  switch (action.type) {
    case types.ADD_IMPACT_STATEMENT:
      return { fetching: true, data: {}, error: null };
    case types.ADD_IMPACT_STATEMENT_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.ADD_IMPACT_STATEMENT_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    case types.ADD_IMPACT_STATEMENT_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function moveImpactStatement(state = dataJson, action) {
  switch (action.type) {
    case types.MOVE_IMPACT_STATEMENT:
      return { fetching: true, data: {}, error: null };
    case types.MOVE_IMPACT_STATEMENT_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.MOVE_IMPACT_STATEMENT_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    case types.MOVE_IMPACT_STATEMENT_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function deleteImpactStatement(state = dataJson, action) {
  switch (action.type) {
    case types.DELETE_IMPACT_STATEMENT:
      return { fetching: true, data: {}, error: null };
    case types.DELETE_IMPACT_STATEMENT_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.DELETE_IMPACT_STATEMENT_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    case types.DELETE_IMPACT_STATEMENT_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function sortImpactStatement(state = dataJson, action) {
  switch (action.type) {
    case types.SORT_IMPACT_STATEMENT:
      return { fetching: true, data: {}, error: null };
    case types.SORT_IMPACT_STATEMENT_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.SORT_IMPACT_STATEMENT_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    case types.SORT_IMPACT_STATEMENT_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getEmailTemplate(state = InitialEmailTemplateData, action) {
  switch (action.type) {
    case types.GET_EMAIL_TEMPLATE:
      return { fetching: true, data: {}, error: null };
    case types.GET_EMAIL_TEMPLATE_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_EMAIL_TEMPLATE_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    default:
      return state;
  }
}

export function editEmailTemplate(
  state = InitialEditEmailTemplateData,
  action
) {
  switch (action.type) {
    case types.EDIT_EMAIL_TEMPLATE:
      return { fetching: true, data: {}, error: null };
    case types.EDIT_EMAIL_TEMPLATE_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.EDIT_EMAIL_TEMPLATE_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    case types.EDIT_EMAIL_TEMPLATE_RESET:
      return InitialEditEmailTemplateData;
    default:
      return state;
  }
}

export function getAdhocMailList(state = InitialAdhocMailListData, action) {
  switch (action.type) {
    case types.GET_LIST_MAILS:
      return { ...state, fetching: true, error: null };
    case types.GET_LIST_MAILS_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_LIST_MAILS_ERROR:
      return { fetching: false, data: [], error: action.payload };
    default:
      return state;
  }
}

export function approvalDataTemplate(state = InitialApprovalData, action) {
  switch (action.type) {
    case types.GET_APPROVAL_REQUEST:
      return {
        fetching: true,
        data: {},
        error: null,
        isPreview: false,
        previewParams: {},
      };
    case types.GET_APPROVAL_REQUEST_SUCCESS:
      return {
        fetching: false,
        data: action.payload,
        error: null,
        isPreview: false,
        previewParams: {},
      };
    case types.GET_APPROVAL_REQUEST_ERROR:
      return {
        fetching: false,
        data: {},
        error: action.payload,
        isPreview: false,
        previewParams: {},
      };
    case types.SET_APPROVAL_PREVIEW_DATA:
      return { ...state, isPreview: true, previewParams: action.payload };
    case types.RESET_APPROVAL_PREVIEW_DATA:
      return { ...state, isPreview: false, previewParams: {} };
    case types.RESET_ADMIN_APPROVAL:
      return InitialApprovalData;
    default:
      return state;
  }
}

export function cancelAdhocMailRequest(state = dataJson, action) {
  switch (action.type) {
    case types.CANCEL_MAIL_REQUEST:
      return { fetching: true, data: {}, error: null };
    case types.CANCEL_MAIL_REQUEST_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.CANCEL_MAIL_REQUEST_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    case types.CANCEL_MAIL_REQUEST_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function submitAdminReview(
  state = InitialEditEmailTemplateData,
  action
) {
  switch (action.type) {
    case types.SEND_ADMIN_REVIEW:
      return {
        fetching: true,
        data: {},
        reviewState: action.payload,
        error: null,
      };
    case types.SEND_ADMIN_REVIEW_SUCCESS:
      return { ...state, fetching: false, data: action.payload, error: null };
    case types.SEND_ADMIN_REVIEW_ERROR:
      return { ...state, fetching: false, data: {}, error: action.payload };
    case types.RESET_ADMIN_APPROVAL:
    case types.SEND_ADMIN_REVIEW_RESET:
      return InitialEditEmailTemplateData;
    default:
      return state;
  }
}

export function getApprovalCount(state = InitialCountTemplate, action) {
  switch (action.type) {
    case types.GET_PENDING_APPROVAL_COUNT:
      return {
        fetching: true,
        data: {
          pendingCountGP: 0,
          pendingCountUser: 0,
          pendingCountAssessment: 0,
          total: 0,
        },
        error: null,
      };
    case types.GET_PENDING_APPROVAL_COUNT_SUCCESS:
      const data =
        action.payload && action.payload.data
          ? action.payload.data
          : {
            pendingCountGP: 0,
            pendingCountUser: 0,
            pendingCountAssessment: 0,
            total: 0,
          };
      data.total =
        data.pendingCountGP +
        data.pendingCountUser +
        data.pendingCountAssessment;
      return { fetching: false, data, error: null };
    case types.GET_PENDING_APPROVAL_COUNT_ERROR:
      return {
        fetching: false,
        data: {
          pendingCountGP: 0,
          pendingCountUser: 0,
          pendingCountAssessment: 0,
          total: 0,
        },
        error: action.payload,
      };
    case types.GET_PENDING_APPROVAL_COUNT_RESET:
      return InitialCountTemplate;
    default:
      return state;
  }
}

export function getAssessmentSubmissionStatus(
  state = {
    pendingYears: [],
    approvedYears: [],
    draftYears: [],
    approvalList: [],
  },
  action
) {
  switch (action.type) {
    case types.SET_ASSESSMENT_SUBMISSION_STATUS:
      const rawlist = _.get(action, "payload.data.list", []);
      const filtered = new Set();
      const approved = new Set();
      const inDraft = new Set();
      rawlist.forEach((el) => {
        if (el.validationStatus == "Submitted") {
          filtered.add(el.financialYear);
        } else if (el.validationStatus == "Approved") {
          approved.add(el.financialYear);
        } else if (el.validationStatus == "In Draft") {
          inDraft.add(el.financialYear);
        }
      });
      return {
        pendingYears: [...filtered],
        approvedYears: [...approved],
        draftYears: [...inDraft],
        approvalList: rawlist,
      };
    default:
      return state;
  }
}

export function getUpdateHighlightData(
  state = InitialEditEmailTemplateData,
  action
) {
  switch (action.type) {
    case types.GET_HIGHLIGHT_DATA:
      return { fetching: true, data: {}, error: null };
    case types.GET_HIGHLIGHT_DATA_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_HIGHLIGHT_DATA_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    case types.RESET_ADMIN_APPROVAL:
    case types.GET_HIGHLIGHT_DATA_RESET:
      return InitialEditEmailTemplateData;
    default:
      return state;
  }
}

export function createAdhocMailRequest(state = dataJson, action) {
  switch (action.type) {
    case types.SEND_ADHOC_MAIL:
      return { fetching: true, data: {}, error: null };
    case types.SEND_ADHOC_MAIL_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.SEND_ADHOC_MAIL_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    case types.SEND_ADHOC_MAIL_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function adminAppState(state = IntialAdminState, action) {
  switch (action.type) {
    case types.ENABLE_SUBMIT_BUTTON:
      return {
        ...state,
        enableSubmit: true,
        type: action.payload,
        submitClicked: false,
      };
    case types.ENABLE_SUBMIT_BUTTON_RESET:
      return { ...state, enableSubmit: false, type: "", submitClicked: false };
    case types.SUBMIT_BUTTON_CLICKED:
      return { ...state, submitClicked: true };
    case types.SUBMIT_BUTTON_CLICKED_RESET:
      return { ...state, submitClicked: false };
    case types.RESPOND_BUTTON_CLICKED:
      return { ...state, respondClicked: true };
    case types.RESPOND_BUTTON_CLICKED_RESET:
      return { ...state, respondClicked: false };
    case types.SET_SUBMISSION_STATUS:
      return {
        ...state,
        status: action.payload.status,
        type: action.payload.type,
      };
    case types.RESET_ADMIN_APPROVAL:
      return IntialAdminState;
    default:
      return state;
  }
}

export function getGeneralSection(state = dataJson, action) {
  switch (action.type) {
    case types.GET_GENERAL_SECTION:
      return { ...state, fetching: true, error: null };
    case types.GET_GENERAL_SECTION_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_GENERAL_SECTION_ERROR:
      return { fetching: false, data: {}, error: action.payload };
    default:
      return state;
  }
}

export function editGeneralSection(state = dataJson, action) {
  switch (action.type) {
    case types.EDIT_GENERAL_SECTION:
      return { fetching: true, data: {}, error: null };
    case types.EDIT_GENERAL_SECTION_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.EDIT_GENERAL_SECTION_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.EDIT_GENERAL_SECTION_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getInvalidAssessmentData(state = dataJson, action) {
  switch (action.type) {
    case types.GET_INVALID_ASSESSMENT_DATA:
      return { fetching: true, data: {}, error: null };
    case types.GET_INVALID_ASSESSMENT_DATA_SUCCESS:
      return { fetching: false, data: action.payload, error: null };
    case types.GET_INVALID_ASSESSMENT_DATA_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_INVALID_ASSESSMENT_DATA_RESET:
      return dataJson;
    default:
      return state;
  }
}
export function submitAdminAssessment(state = dataJson, action) {
  switch (action.type) {
    case types.ADMIN_SUBMIT_ASSESSMENT:
      return { fetching: true, data: {}, error: null };
    case types.ADMIN_SUBMIT_ASSESSMENT_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.ADMIN_SUBMIT_ASSESSMENT_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.ADMIN_SUBMIT_ASSESSMENT_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getReportFilter(state = dataJson, action) {
  switch (action.type) {
    case types.GET_REPORT_FILTER:
      return { fetching: true, data: {}, error: null };
    case types.GET_REPORT_FILTER_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_REPORT_FILTER_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_REPORT_FILTER_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getAdditionalReportData(state = dataJson, action) {
  switch (action.type) {
    case types.GET_ADDITIONAL_REPORT_DATA:
      return { fetching: true, data: {}, error: null };
    case types.GET_ADDITIONAL_REPORT_DATA_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_ADDITIONAL_REPORT_DATA_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_ADDITIONAL_REPORT_DATA_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getPowerBIlink(state = dataJson, action) {
  switch (action.type) {
    case types.GET_POWERBI_LINK:
      return { fetching: true, data: {}, error: null };
    case types.GET_POWERBI_LINK_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_POWERBI_LINK_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_POWERBI_LINK_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function exportAdditionalReportData(state = dataJson, action) {
  switch (action.type) {
    case types.EXPORT_ADDITIONAL_REPORT:
      return { fetching: true, data: {}, error: null };
    case types.EXPORT_ADDITIONAL_REPORT_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.EXPORT_ADDITIONAL_REPORT_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.EXPORT_ADDITIONAL_REPORT_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function updatePowerBILink(state = dataJson, action) {
  switch (action.type) {
    case types.UPDATE_POWERBI_LINK:
      return { fetching: true, data: {}, error: null };
    case types.UPDATE_POWERBI_LINK_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.UPDATE_POWERBI_LINK_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.UPDATE_POWERBI_LINK_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getUserStatusAssessment(state = dataJson, action) {
  switch (action.type) {
    case types.GET_USER_STATUS_ASSESSMENT:
      return { fetching: true, data: {}, error: null };
    case types.GET_USER_STATUS_ASSESSMENT_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_USER_STATUS_ASSESSMENT_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_USER_STATUS_ASSESSMENT_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getCompletedPhase(state = dataJson, action) {
  switch (action.type) {
    case types.GET_COMPLETED_PHASE:
      return { fetching: true, data: {}, error: null };
    case types.GET_COMPLETED_PHASE_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_COMPLETED_PHASE_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_COMPLETED_PHASE_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function exportEntireAssessmentData(state = dataJson, action) {
  switch (action.type) {
    case types.EXPORT_ENTIRE_ASSESSMENT_CONTENT:
      return { fetching: true, data: {}, error: null };
    case types.EXPORT_ENTIRE_ASSESSMENT_CONTENT_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.EXPORT_ENTIRE_ASSESSMENT_CONTENT_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.EXPORT_ENTIRE_ASSESSMENT_CONTENT_RESET:
      return dataJson;
    default:
      return state;
  }
}

export function getApprovedCompletedYearsList(state = dataJson, action) {
  switch (action.type) {
    case types.GET_YEARS_LIST:
      return { fetching: true, data: {}, error: null };
    case types.GET_YEARS_LIST_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_YEARS_LIST_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_YEARS_LIST_RESET:
      return dataJson;
    default:
      return state;
  }
}
export function setViewUserData(state = InitialViewUserData, action) {
  switch (action.type) {
    case types.SET_VIEW_USER_DATA:
      return { ...state, ...action.payload };
    case types.SET_VIEW_USER_DATA_RESET:
      return InitialViewUserData;
    default:
      return state;
  }
}

export function maintenanceError(state = maintenanceErrorObj, action) {
  switch (action.type) {
    case types.MAINTENANCE_ERROR:
      return { data: true };
    case types.MAINTENANCE_ERROR_RESET:
      return { data: false };
    default:
      return state;
  }
}

export function uploadGeneralContent(state = dataJson, action) {
  switch (action.type) {
    case types.UPLOAD_GENERAL_CONTENT:
      return { fetching: true, data: {}, error: null };
    case types.UPLOAD_GENERAL_CONTENT_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.UPLOAD_GENERAL_CONTENT_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.UPLOAD_GENERAL_CONTENT_RESET:
      return dataJson;
    default:
      return state;
  }
}
export function getGeneralContent(state = dataJson, action) {
  switch (action.type) {
    case types.GET_GENERAL_CONTENT:
      return { fetching: true, data: {}, error: null };
    case types.GET_GENERAL_CONTENT_SUCCESS:
      return { ...state, fetching: false, data: action.payload };
    case types.GET_GENERAL_CONTENT_ERROR:
      return { fetching: false, data: null, error: action.payload };
    case types.GET_GENERAL_CONTENT_RESET:
      return dataJson;
    default:
      return state;
  }
}
