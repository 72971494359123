import React, { useState, useContext, useEffect } from "react";
import SideMenu from "../SideMenu/SideMenu";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import FinalizationForm from "./FinalizationForm/FinalizationForm";
import "./Admin.scss";
import * as myConsts from "../../constants/constants";
import AppContext from "../../utils/appContext";
import {
  authProvider, APIscope,
  GraphExplorerscopes,
} from "./../../Authentication/authProvider";
import { useDispatch, useSelector } from "react-redux";
//import _ from "lodash";
import Modal from "react-responsive-modal";
import SimpleBar from "simplebar-react";
import Loader from "../Loader/Loader";
import FinalizationFormPreview from "./FinalizationForm/FinalizationFormPreview";
//import DownloadTemplate from './FinalizationForm/DownloadTemplate';
import DownloadTemplateLRDG from './FinalizationForm/DownloadTemplateLRDG';
import FinalizationFormSelf from './FinalizationForm/FinalizationFormSelf';
//import DownloadTemplateCounseleeSelf from './FinalizationForm/DownloadTemplateCounseleeSelf';
import DownloadTemplateSelfDG from './FinalizationForm/DownloadTemplateSelfDG';

const FinalizationGuidance = (props) => {
  const dispatch = useDispatch();
  const [crumbs, setBreadcrumbs] = useState([
    { name: "Discussion Guide", path: myConsts.routeDiscussionGuide },
    { name: "LEAD Reviewer Year-End Discussion Guide", path: myConsts.routeDiscussionGuide },
  ]);
  //..Local States
  const [isOpen, setOpen] = useState(false);
  const [isCounseleeFormOpen, setCounseleeFormOpen] = useState(false);
  const [finalizationFormDetails, setFinalizationFormDetails] = useState({});
  const [loader, setLoader] = useState(true);
  const myContext = useContext(AppContext);
  const counseleesFinalizationDetails = useSelector((state) => state.getCounseleesFinalizationDetails);
  //..Finalization form states
  const [fieldValues, setFieldValues] = useState({
    financialYear: myContext.financialYear,
    userId: null,
    //counseleeId: null,
    name: null,
    firstName: null,
    lastName: null,
    gui: null,
    rank: null,
    region: null,
    primarySector: null,
    office: null,
    leadReviewer: null,
    leadReviewerGUI: null,
    question1AnsContent: null,
    question1AnsPlainText: null,
    question2AnsContent: null,
    question2AnsPlainText: null,
    question3AnsContent: null,
    question3AnsPlainText: null,
    question4AnsContent: null,
    question4AnsPlainText: null,
    question5AnsContent: null,
    question5AnsPlainText: null,
    question6AnsContent: null,
    question6AnsPlainText: null,
    distinguishPerformanceContent: null,
    distinguishPerformancePlainText: null,
    developmentPointsContent: null,
    developmentPointsPlainText: null,
    potentialAndAspirationsContent: null,
    potentialAndAspirationsPlainText: null,
    overallCategory: null,
    reviewerAuditContent: null,
    reviewerAudit: null,
    reviewerSubmittedDate: null,
    status: null,
    counseleeSelfGuide: {}
  });

  const mandatoryFields = [
    { key: "question1AnsContent", name: "To what extent did your PPMD achieve your goals against the six metrics, including the required goals?" },
    { key: "question2AnsContent", name: "How has the PPMD teamed this year across SLs, SSLs and functions? And how will they accelerate it next year?" },
    { key: "question3AnsContent", name: "What actions has the PPMD taken to contribute to priorities including diversity, equity and inclusiveness and wellbeing?" },
    { key: "question4AnsContent", name: "What is the PPMD's capability and aspiration to take on the next complex challenge? What is needed to help them get there?" },
    { key: "question5AnsContent", name: "Summary of Roles/Areas of Responsibility:" },
    { key: "question6AnsContent", name: "Are there any significant performance deficiences that need to be highlighted?" },
    { key: "distinguishPerformanceContent", name: "Describe the top 2-4 distinguished performance behaviors exhibited during the fiscal year" },
    { key: "developmentPointsContent", name: "Describe 2-4 development points for PPMD:" },
    { key: "potentialAndAspirationsContent", name: "Describe potential and aspirations:" },
    { key: "overallCategory", name: "Proposed Overall Category" }
  ];

  const [errorMessages, setErrorMessages] = useState({
    question1AnsContent: "",
    question2AnsContent: "",
    question3AnsContent: "",
    question4AnsContent: "",
    question5AnsContent: "",
    question6AnsContent: "",
    distinguishPerformanceContent: "",
    developmentPointsContent: "",
    potentialAndAspirationsContent: "",
    overallCategory: "",
  });

  const allUserAssesmentData = useSelector(
    (state) => state.allUserAssesmentData //get assessment data of user for all yera and period
  );
  const allAssesmentData = useSelector((state) => state.allAssesmentData);
  const getAllOptions = useSelector((state) => state.getAllOptions);
  const [summaryData, setSummaryData] = useState([]);
  const [anyFocusedStmtSelected, setAnyFocusedStmtSelected] = useState(false);
  const [assessmentObj, setAssessmentObj] = useState({});
  const [allOptionList, setAllOptionList] = useState({});
  const timePeriods = myConsts.TimePeriodsArray;
  const currentPeriod = myContext.currentPeriod;

  //..Events
  //..Focus Summary View
  useEffect(() => {
    //debugger;
    if (
      getAllOptions &&
      !getAllOptions.fetching &&
      getAllOptions.data &&
      getAllOptions.data.length
    ) {
      let temp = {};
      getAllOptions.data.map((item) => {
        temp[item.option_id] = item.options;
      });
      setAllOptionList(temp);
    }
  }, [getAllOptions]);

  useEffect(() => {
    //debugger;
    if (
      allAssesmentData &&
      allAssesmentData.data &&
      allAssesmentData.data.status === "success"
    ) {
      const {
        data: { data },
      } = { ...allAssesmentData };
      const assessmentDetails = data.assessmentDetails;
      setAssessmentObj(assessmentDetails);
    }
    if (
      allUserAssesmentData &&
      allUserAssesmentData.data &&
      allAssesmentData &&
      allAssesmentData.data &&
      allAssesmentData.data.status === "success"
    ) {
      let data = [];
      const assessmentDetails = allAssesmentData.data.data.assessmentDetails;
      if (
        allUserAssesmentData.data.data &&
        allUserAssesmentData.data.data.length > 0
      ) {
        data = allUserAssesmentData.data.data;
      }

      let tempMenuObj = [];
      assessmentDetails.forEach((pillarData) => {
        let menuTabs = [];
        let tabIds = [];
        let uiTypes = [];
        let useroptions = [];
        pillarData.section.forEach((tab) => {
          menuTabs.push(tab.goalName); //goal names array
          tabIds.push(tab.goalId);
          uiTypes.push(tab.uiType ? tab.uiType : "");
          useroptions.push(tab.data); //impact stament with action_id,impactActions  and type
        });
        tempMenuObj.push({
          pillarId: pillarData.pillarId,
          iconUrl: pillarData.iconUrl,
          pillar: pillarData.pillar,
          pillarDisplayName: pillarData.pillarDisplayName,
          tabs: menuTabs,
          tabIds: tabIds,
          uiTypes: uiTypes,
          userOptions: useroptions,
          displayOrder: pillarData.displayOrder,
        });
      });

      const UsrObj = data !== "no data" ? data : [];
      //let focusedChecked = {};
      //let array = [];
      generateSurveyData(UsrObj, tempMenuObj);
    }
    // eslint-disable-next-line
  }, [allAssesmentData, allUserAssesmentData]);

  const generateSurveyData = (
    userAssessmetData,
    menuList,
  ) => {
    /*To generate committed statments and changed statements for all goals.This data is displayed in Summary page 
        comitted Statements are identified based on userinput  to statments :-
        dropdown-if value selected is yes
        checkbox-if checkbox is selected
        readio-if option selected is C or D
        Changed Statements are those for which user selected values are different from last phase values.*/
    let summaryData = [];
    let ansMapping = {};
    //debugger;
    menuList &&
      menuList.forEach((pillar) => {
        if (pillar.pillar !== "Summary") {
          pillar.userOptions &&
            pillar.userOptions.forEach((eachGoal) => {
              eachGoal &&
                eachGoal.forEach((action) => {
                  ansMapping[action.action_id] = action;
                });
            });
        }
      });

    menuList &&
      menuList.forEach((pillar) => {
        let tempPillar = {};
        if (pillar.pillar !== "Summary") {
          tempPillar["pillar"] = pillar.pillar;
          tempPillar["pillarDisplayName"] = pillar.pillarDisplayName;
          tempPillar["tabList"] = [];
          pillar.tabIds.forEach((tab, index) => {
            let goalData = {};
            goalData["goal"] = pillar.tabs[index];
            goalData["uiType"] = pillar.uiTypes[index];
            let tempCurrent = {};
            let tempPrev = {};
            //let diffData = [];
            //let comittedData = [], othersData = [];
            let focusedData = [];
            let prevPeriods = timePeriods.filter(timePeriod => (timePeriod !== currentPeriod));
            userAssessmetData &&
              userAssessmetData.length > 0 &&
              userAssessmetData.forEach((userData) => {
                if (
                  userData.goalId === tab &&
                  userData.timePeriod === currentPeriod &&
                  userData.pillarId === pillar.pillarId
                ) {
                  tempCurrent = userData;
                }
                if (
                  userData.goalId === tab &&
                  prevPeriods.findIndex(prevPeriod => (prevPeriod === userData.timePeriod)) !== -1 &&
                  userData.pillarId === pillar.pillarId
                ) {
                  tempPrev = userData;
                }
              });
            tempCurrent &&
              tempCurrent.userInput &&
              tempPrev.userInput &&
              tempCurrent.userInput.forEach((stmnts, qstnIndex) => {
                let focused = {};
                //..Focused statements
                if (stmnts && stmnts.focused) {
                  focused["qstn"] =
                    ansMapping[tempCurrent.userInput[qstnIndex].action_id];
                  if (getAllOptions && getAllOptions.data
                    && Array.isArray(getAllOptions.data)) {
                    let selOption = getAllOptions.data.find(x => x.option_id === tempCurrent.userInput[qstnIndex].option_id);
                    if (selOption && focused["qstn"]) {
                      focused["qstn"].selectedOption = selOption.options;
                    }
                  }
                }
                if (focused.qstn) focusedData.push(focused);
              });

            goalData["focused"] = focusedData;
            tempPillar["tabList"].push(goalData);
          });

          summaryData.push(tempPillar);
        }
      });
    //..check if any focus statement is selected
    let anyFocusedStmtSelected = false;
    if (summaryData && Array.isArray(summaryData)) {
      summaryData.map((d, i) => {
        if (d.tabList && Array.isArray(d.tabList)) {
          d.tabList.map((g, i) => {
            if (g.focused && Array.isArray(g.focused) && g.focused.length > 0) {
              anyFocusedStmtSelected = true;
            }
          });
        }
      });
    }
    setAnyFocusedStmtSelected(anyFocusedStmtSelected);
    setSummaryData(summaryData);
  };

  useEffect(() => {
    //API call for getting list of counselees on page load
    (async function () {
      //debugger;
      setLoader(true);
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        const idADtoken = await authProvider.acquireTokenSilent({
          scopes: GraphExplorerscopes,
        });
        const ADtoken = idADtoken.accessToken;
        const ppmdId =
          myContext.activePPMD_Id || sessionStorage.activePPMD_Id;
        const ppmdUserId =
          myContext.activePPMD_UserId || sessionStorage.activePPMD_UserId;
        if (!ppmdId) {
          setLoader(false);
          return;
        }
        dispatch({
          type: "GET_COUNSELEES_FINALIZATION_DETAILS",
          payload: {
            ad_token: ADtoken,
            financialYear: myContext.financialYear,
            //id: props.location.state.id
            id: ppmdId
          },
        });
        dispatch({
          type: "GET_ALL_USER_ASSESSMENT_DATA",
          payload: {
            token: idToken,
            activePeriod: myContext.activePeriod,
            financialYear: myContext.financialYear,
            isFromDiscussionGuide: true,
            ppmdUserId: ppmdUserId
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //debugger;
    setLoader(true);
    if (counseleesFinalizationDetails &&
      !counseleesFinalizationDetails.fetching &&
      counseleesFinalizationDetails.data) {
      const data = counseleesFinalizationDetails.data;
      if (data.name) {
        //debugger;
        setFinalizationFormDetails(data);
        setFieldValues((prevState) => ({
          ...prevState,
          userId: data.userId,
          //counseleeId: data.counseleeId,
          name: data.name,
          firstName: data.firstName,
          lastName: data.lastName,
          gui: data.gui,
          rank: data.rank,
          region: data.region,
          primarySector: data.primarySector,
          office: data.office,
          leadReviewer: data.leadReviewer,
          leadReviewerGUI: data.leadReviewerGUI,
          question1AnsContent: data.question1AnsContent,
          question1AnsPlainText: data.question1AnsPlainText,
          question2AnsContent: data.question2AnsContent,
          question2AnsPlainText: data.question2AnsPlainText,
          question3AnsContent: data.question3AnsContent,
          question3AnsPlainText: data.question3AnsPlainText,
          question4AnsContent: data.question4AnsContent,
          question4AnsPlainText: data.question4AnsPlainText,
          question5AnsContent: data.question5AnsContent,
          question5AnsPlainText: data.question5AnsPlainText,
          question6AnsContent: data.question6AnsContent,
          question6AnsPlainText: data.question6AnsPlainText,
          distinguishPerformanceContent: data.distinguishPerformanceContent,
          distinguishPerformancePlainText: data.distinguishPerformancePlainText,
          developmentPointsContent: data.developmentPointsContent,
          developmentPointsPlainText: data.developmentPointsPlainText,
          potentialAndAspirationsContent: data.potentialAndAspirationsContent,
          potentialAndAspirationsPlainText: data.potentialAndAspirationsPlainText,
          overallCategory: data.overallCategory,
          reviewerAuditContent: data.reviewerAuditContent,
          reviewerAudit: data.reviewerAudit,
          reviewerSubmittedDate: data.reviewerSubmittedDate,
          status: data.status,
          counseleeSelfGuide: data.counseleeSelfGuide
        }));
        setLoader(false);
      }
    }
  }, [counseleesFinalizationDetails]);

  //..Finalization form handlers
  const selectOnChange = (fieldname, value) => {
    setFieldValues({ ...fieldValues, [fieldname]: value });
    setErrorMessages({ ...errorMessages, [fieldname]: "" });
  };

  const handleTextChange = (e) => {
    //debugger;
    const { name, value, namePlainText, plainText } = e.target;
    setFieldValues({ ...fieldValues, [name]: value, [namePlainText]: plainText });
    setErrorMessages({ ...errorMessages, [name]: "" });
  };

  return (
    <>
      {loader && <Loader />}
      <div className="admin-container general-parameters">
        <div className="admin-info-main-sidemenu">
          <SideMenu
            minimized={true}
            isminimizedMainMenu={true}
            isMainMenu={true}
            sideMenuItemSelect="Home"
          />
        </div>
        <div className="admin-content">
          <div className="second-header admin-crumbs">
            <div className="admin-crumb-txt">
              <Breadcrumb
                crumbs={crumbs}
                isRouteFromDiscussionGuide={true}
                {...props}
              />
            </div>
            <div className="btn-wrapper-top" style={{ right: "17px" }}>
              {/* <div
                className={
                  "btn"
                  // +
                  // (fieldValues.status !== "Submitted"
                  //   ? " opacityReduced "
                  //   : "")
                }
                style={{
                  cursor: `${true
                    ? " pointer"
                    : "auto"
                    }`,
                  opacity: `${true ? 1 : 0.5
                    }`,
                }}
                onClick={() => {
                  setCounseleeFormOpen(true);
                }}>
                <i
                  className="eyaa-image-25"
                  style={{ marginRight: "5px" }}
                />
                Counselee Discussion Guide
              </div> */}
              <div className={"btn" + (!true
                ? " opacityReduced "
                : "")
              }
                style={{
                  cursor: `${true
                    ? " pointer"
                    : "auto"
                    }`,
                  opacity: `${true ? 1 : 0.5
                    }`,
                }}
                onClick={() => {
                  setOpen(true);
                }}>
                <i
                  className="eyaa-image-25"
                  style={{ marginRight: "5px" }}
                />
                {/* {`Preview\n${fieldValues.reviewerAuditContent ? "(Reviewed by STC's)" : ""}`} */}
                {`DRAFT Finalization Form Commentary`}
              </div>
              <>
                {/* {
                  finalizationFormDetails &&
                  finalizationFormDetails.name && (
                    <DownloadTemplate
                      data={finalizationFormDetails} />
                  )
                } */}
                {
                  finalizationFormDetails &&
                  finalizationFormDetails.name && (
                    <DownloadTemplateLRDG
                      data={finalizationFormDetails} />
                  )
                }
              </>
            </div>
          </div>
          <>
            {
              finalizationFormDetails &&
              finalizationFormDetails.name &&
              (
                <FinalizationForm
                  counseleesFinalizationDetails={finalizationFormDetails}
                  fieldValues={fieldValues}
                  handleTextChange={handleTextChange}
                  selectOnChange={selectOnChange}
                  errorMessages={errorMessages}
                  setErrorMessages={setErrorMessages}
                  mandatoryFields={mandatoryFields}
                  loader={loader}
                  //id={props.location.state.id}
                  id={myContext.activePPMD_Id || sessionStorage.activePPMD_Id}
                  summaryData={summaryData}
                  assessmentObj={assessmentObj}
                  allOptionList={allOptionList}
                  anyFocusedStmtSelected={anyFocusedStmtSelected}
                  isFormPreview={false}
                  history={props.history} />
              )
            }
          </>
        </div>
      </div>
      <div className='email-template-modal'>
        <Modal
          open={isOpen}
          onClose={() => {
            setOpen(false);
          }}
          styles={{
            modal: {
              maxWidth: "unset",
              padding: "unset",
            },
            overlay: {
              background: "rgba(0, 0, 0, 0.5)",
            }
          }}
          center
        >
          <div className="email-template-wrap">
            <div className="modal-heading" style={{ backgroundColor: "#ffe600" }}>
              {`FY${myContext.financialYear} LEAD Reviewer Year-End Discussion Guide`}
            </div>
            <SimpleBar
              style={{
                overflowX: "hidden",
                maxHeight: "80vh",
                width: '70vw',
              }}
            >
              <div style={{ minHeight: "80vh", padding: '10px', paddingTop: "0px" }}>
                <FinalizationFormPreview
                  data={finalizationFormDetails}
                  formValues={fieldValues} />
              </div>
            </SimpleBar>
          </div>
        </Modal>
        <Modal
          open={isCounseleeFormOpen}
          onClose={() => {
            setCounseleeFormOpen(false);
          }}
          styles={{
            modal: {
              maxWidth: "unset",
              padding: "unset",
            },
            overlay: {
              background: "rgba(0, 0, 0, 0.5)",
            }
          }}
          center
        >
          <div className="email-template-wrap">
            <div className="modal-heading" style={{ backgroundColor: "#ffe600" }}>
              {`FY${myContext.financialYear} Counselee Year-End Discussion Guide`}
            </div>
            <div style={{
              display: "flex",
              flexDirection: "row-reverse",
              padding: "5px"
            }}>
              {/* {
                finalizationFormDetails &&
                finalizationFormDetails.name && (
                  <DownloadTemplateCounseleeSelf
                    data={finalizationFormDetails.counseleeSelfGuide} />
                )
              } */}
              {
                finalizationFormDetails &&
                finalizationFormDetails.name && (
                  <DownloadTemplateSelfDG
                    data={finalizationFormDetails.counseleeSelfGuide} />
                )
              }
            </div>
            <SimpleBar
              style={{
                overflowX: "hidden",
                maxHeight: "80vh",
                width: '70vw',
              }}
            >
              <div style={{ minHeight: "80vh", padding: '10px', paddingTop: "0px" }}>
                <FinalizationFormSelf
                  fieldValues={fieldValues.counseleeSelfGuide}
                  summaryData={summaryData}
                  //errorMessages={errorMessages}
                  assessmentObj={assessmentObj}
                  allOptionList={allOptionList}
                  anyFocusedStmtSelected={anyFocusedStmtSelected}
                  isFormPreview={true}
                  history={props.history}
                />
              </div>
            </SimpleBar>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default FinalizationGuidance;
