import "./FinalizationForm.scss";
// import React from "react";
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  makeStyles,
  TextField,
  Snackbar
} from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import AppContext from "../../../utils/appContext";
import DropdownComponent from "./DropdownComponent/DropdownComponent";
import AlertSubmitPopup from "../../Common/AlertSubmitPopup";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { toast, Flip } from "react-toastify";
import { authProvider, APIscope } from "./../../../Authentication/authProvider";
import "react-toastify/dist/ReactToastify.css";
import SimpleBar from "simplebar-react";
import _ from "lodash";
import * as myConsts from "../../../constants/constants";
import FocusSummaryView from "./FocusSummaryView";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
//import { placeholders } from './QuillEditor/constant';
import "./QuillEditor/QuillOption3.scss";
import ReactHtmlParser from "react-html-parser";
import quillBulletIcon from '../../../assets/images/QuillBulletIcon.png';

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiPaper-root .MuiAlert-root": {
      fontSize: "1.25rem"
    }
  }
}));

const FinalizationForm = (props) => {
  const { isFormPreview,
    counseleesFinalizationDetails,
    //finalizationFormDetails,
    //setFinalizationFormDetails,
    fieldValues,
    handleTextChange,
    selectOnChange,
    errorMessages,
    setErrorMessages,
    mandatoryFields,
    //loader,
    //setLoader
    id,
    summaryData,
    assessmentObj,
    allOptionList,
    anyFocusedStmtSelected,
  } = props;
  const classes = useStyles();
  const characterLimit = 350;
  const question1AnsContentRef = React.useRef();
  const question2AnsContentRef = React.useRef();
  const question3AnsContentRef = React.useRef();
  const question4AnsContentRef = React.useRef();
  const question5AnsContentRef = React.useRef();
  const question6AnsContentRef = React.useRef();
  const distinguishPerformanceContentRef = React.useRef();
  const developmentPointsContentRef = React.useRef();
  const potentialAndAspirationsContentRef = React.useRef();
  const myContext = useContext(AppContext);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [showAlertSavePopup, setShowAlertSavePopup] = useState(false);
  const [showSubmissionConfirmation, setShowSubmissionConfirmation] =
    useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [fv, setFv] = useState({});
  const excludedList = ["fyYear", "period", "active"];
  const discussionGuideStatuses = {
    NotStarted: "Not Started",
    InProgress: "In Progress",
    Submitted: "Submitted"
  };
  const modules = {
    toolbar: [
      //[{ 'header': [1, 2, false] }],
      //['bold', 'italic', 'underline','strike', 'blockquote'],
      //[{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'list': 'bullet' }],
      //['link', 'image'],
      //['clean']
    ]
  };
  // useEffect(() => {
  //   debugger;
  //   setFv(fieldValues);
  // }, [fieldValues]);
  const [reviewerAuditContent, setReviewerAuditContent] = useState("");
  const [reviewerAudit, setReviewerAudit] = useState("");
  const [isEmpty, setIsEmpty] = useState(null);
  //const { Specific, ...dynamicPlaceholders } = placeholders;

  // const isQuillEmpty = (quill) => {
  //   if ((quill.getContents()['ops'] || []).length !== 1) { return false }
  //   return quill.getText().trim().length === 0
  // }  

  const checkCharacterCount = (event, ref) => {
    //debugger;
    const unprivilegedEditor = ref.current.unprivilegedEditor;
    let key = event.which || event.keyCode; // Detecting keyCode
    let ctrl = event.ctrlKey ? event.ctrlKey : ((key === 17)
      ? true : false);
    //const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    //if (fieldValues[fieldName] && fieldValues[fieldName].length > characterLimit && event.key !== 'Backspace') {
    if (unprivilegedEditor.getLength() > characterLimit && (event.key !== 'Backspace' || (key == 86 && ctrl))) {
      event.preventDefault();
    }
  };
  const handleEditorChange = (value, delta, source, editor, fieldName) => {
    //debugger;
    //let plainText = value.replaceAll('&lt;', '{{').replaceAll('&gt;', '}}').replace(/({{([^>]+)}})/ig, 'R').replace(/(<([^>]+)>)/ig, '');
    let plainText = editor.getText();
    const characterCount = editor.getLength() - 1;

    //if (characterCount < characterLimit) {
    if (plainText === "\n") {
      value = ""
    }

    // if (characterCount > characterLimit) {
    //   plainText = plainText.substring(0, characterLimit);
    //   value = value.substring(0, characterLimit);
    // }

    let obj = {
      target: {
        name: `${fieldName}Content`,
        value: value,
        namePlainText: `${fieldName}PlainText`,
        plainText: plainText === "\n" ? "" : plainText
      }
    }
    //setFv({...fv, [`${fieldName}Content`]: value});
    handleTextChange(obj);
    // obj.target.name = `${source}PlainText`;
    // obj.target.value = plainText;
    // handleTextChange(obj);
    //}
  }

  // const cancelConfirmation = () => {
  //   dispatch({
  //     type: "SUBMIT_BUTTON_CLICKED_RESET",
  //     payload: {},
  //   });
  // };

  const submitClicked = () => {
    if (checkifAllMandatoryFieldsFilled() &&
      fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted) {
      setShowSubmissionConfirmation(true);
    } else if (!checkifAllMandatoryFieldsFilled() &&
      fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted) {
      handleErrors();
    }
  };

  const handleErrors = () => {
    //debugger;
    let errorMsgs = JSON.parse(JSON.stringify(errorMessages));
    mandatoryFields.forEach((value) => {
      if (fieldValues[value.key] === "" || fieldValues[value.key] === null) {
        errorMsgs[value.key] = value.name;
      }
    });

    //setErrors(errorMsgs);
    setErrorMessages(errorMsgs);
    setShowErrorAlert(true);
  };

  const handleErrorAlertClose = () => {
    setShowErrorAlert(false);
  }

  const saveBtnClick = () => {
    //check for error messages.if no error call save function else show popup to fix errors related to input fields
    // if (checkifAllMandatoryFieldsFilled() &&
    //   fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted) {
    if (fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted) {
      //debugger;
      let fv = JSON.parse(JSON.stringify(fieldValues));
      // let reviewerAuditContent = `${fv.name} exhibited the following distinguished performance behaviors this fiscal year: ${fv.distinguishPerformanceContent}. 
      // ${fv.name} has the following development points: ${fv.developmentPointsContent}. 
      // ${fv.name}'s potential and aspirations include: ${fv.potentialAndAspirationsContent}.

      // Overall, ${fv.name} is in the ${fv.overallCategoryOption3} category. 
      // ${fv.name}'s quality rating has been considered in their LEAD category determination and the related commentary is contained in MARQ.`;

      // document.getElementById('dvContent').innerHTML = reviewerAuditContent;
      // const reviewerAudit = document.getElementById('dvContent').innerText;
      fv.status = discussionGuideStatuses.InProgress;
      // fv.reviewerAuditContent = reviewerAuditContent;
      // fv.reviewerAudit = reviewerAudit;
      dispatch({
        type: "ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS",
        payload: fv,
      });
      setTimeout(() => {
        props.history.push(myConsts.routeDiscussionGuide);
      }, 1000);
    }
  };

  const cancelAlertSubmitPopup = () => {
    props.history.push(myConsts.routeDiscussionGuide);
  };
  const cancelConfirmation = () => {
    // dispatch({
    //   type: "SUBMIT_BUTTON_CLICKED_RESET",
    //   payload: {},
    // });
    props.history.push(myConsts.routeDiscussionGuide);
  };
  const cancelClick = () => {
    if (checkifAnyFieldFilled() &&
      fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted) {
      setShowAlertSavePopup(true);
    } else {
      props.history.push(myConsts.routeDiscussionGuide);
    }
  };

  //...TODO ::
  const saveData = async (isFinalSubmission) => {
  };

  const checkifAllMandatoryFieldsFilled = () => {
    // To check if all mandatory fields are filled.tenureReferenceDate is mandatory field only in Plan phase.
    //debugger;
    let flag = true;
    mandatoryFields.forEach((value) => {
      if (fieldValues[value.key] === "" || fieldValues[value.key] === null) {
        flag = false;
      }
    });

    return flag;
  };

  const checkifAnyFieldFilled = () => {
    let isFilled = false;
    const keys = Object.keys(fieldValues);
    keys.forEach((key, index) => {
      if (!excludedList.includes(key) &&
        (fieldValues[key] !== "" && fieldValues[key] !== null)) {
        isFilled = true;
      }
    });
    return isFilled;
  };

  // useEffect(() => {
  //   //call validation method on each field value change,to update error messages
  //   validationDate();
  // }, [fieldValues]);   

  const submitData = () => {
    if (checkifAllMandatoryFieldsFilled() &&
      fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted) {
      let fv = JSON.parse(JSON.stringify(fieldValues));
      setLoader(true);
      fv.status = discussionGuideStatuses.Submitted;
      dispatch({
        type: "ADD_EDIT_COUNSELEES_FINALIZATION_DETAILS",
        payload: fv,
      });
      setTimeout(() => {
        props.history.push(myConsts.routeDiscussionGuide);
      }, 1000);
    }
  }

  return (
    <div className="finalizationForm">
      <div id="dvContent" style={{ display: "none" }}></div>
      {loader && <Loader />}
      <SimpleBar
        style={{ maxHeight: "calc(100% - 40px)", height: "calc(100% - 40px)" }}>
        {
          //!isFormPreview && (
          <>
            <div className="seperator" style={{ paddingBottom: "0px" }}>
              {
                !isFormPreview && (
                  <>
                    <div className="mainHeading" style={{ color: "red" }}>Please complete and submit <u>prior</u> to PFC meeting. These deadlines are determined by region, please reach out to your STC for your applicable deadline(s).</div>
                    <div class="mainHeading" style={{ color: "red" }}>*Note: All questions must be answered to submit the form.</div>
                    <div className="headingStyleH1">{`FY${myContext.financialYear} LEAD Reviewer Year-End Discussion Guide`}</div>
                  </>
                )
              }
              <div className="finalization-form-table">
                <table>
                  <tr>
                    <th>Name:</th>
                    <td>{fieldValues && counseleesFinalizationDetails.name}</td>
                  </tr>
                  <tr>
                    <th>Rank: </th>
                    <td>{fieldValues && counseleesFinalizationDetails.rank}</td>
                  </tr>
                  <tr>
                    <th>Region: </th>
                    <td>{fieldValues && fieldValues.region}</td>
                  </tr>
                  <tr>
                    <th>Office: </th>
                    <td>{fieldValues && fieldValues.office}</td>
                  </tr>
                  <tr>
                    <th>LEAD Reviewer: </th>
                    <td>{fieldValues && fieldValues.leadReviewer}</td>
                  </tr>
                </table>
              </div>
            </div>
            {/* {
              !isFormPreview && (
                <>
                  <div className="seperator" style={{ paddingBottom: "0px" }}>
                    <div className="headingStyle" style={{
                      marginBottom: "10px",
                      paddingBottom: "0px"
                    }}>Year-end Self-Assessment focus action items:</div>
                    <div className="subHeading">As a reminder, this is a listing of top leading/transformative behaviors the PPMD wants to highlight from their year.</div>
                    <div className="subHeading" style={{
                      fontStyle: "italic",
                      marginLeft: "15px"
                    }}>List of selected focus action items from counselee's self-assessment.</div>
                    {
                      anyFocusedStmtSelected ? (
                        <FocusSummaryView
                          summaryData={summaryData}
                          pillers={assessmentObj}
                          allOptionList={allOptionList}
                        />
                      )
                        :
                        (
                          <div className="subHeading" style={{ fontStyle: "italic" }}> Focused statements have not selected by {fieldValues.name}</div>
                        )
                    }
                  </div>
                </>
              )
            } */}
          </>
          //)
        }
        <div className="seperator" style={{ paddingBottom: "0px" }}>
          <div className="mainHeading" style={{ color: "red" }}>To utilize bulleted text, click on this <img src={quillBulletIcon} alt="" style={{ height: "15px" }} /> icon.</div>
          <div className="mainHeading">PFC Discussion Questions:</div>
          <div className={`mainHeading ${errorMessages && errorMessages['question1AnsContent'] !== "" ? 'redColor' : ''}`}>1.  To what extent did your PPMD achieve your goals against the six metrics, including the required goals?</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues && fieldValues.question1AnsContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div style={{ padding: '10px', height: '74%' }}>
                    <div className={`quill-container ${errorMessages && errorMessages['question1AnsContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        id="question1AnsContent"
                        modules={modules}
                        ref={question1AnsContentRef}
                        onKeyDown={(e) => checkCharacterCount(e, question1AnsContentRef)}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        placeholder="Comments on goals*"
                        defaultValue={fieldValues && fieldValues.question1AnsContent ? fieldValues.question1AnsContent : null}
                        //defaultValue={fieldValues.question1AnsContent}
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "question1Ans")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "question1Ans")}
                      />
                    </div>
                    <div id="question1AnsContentCounter"
                      className={`${errorMessages && errorMessages['question1AnsContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.question1AnsPlainText ? fieldValues.question1AnsPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }

          <div className={`mainHeading ${errorMessages && errorMessages['question2AnsContent'] !== "" ? 'redColor' : ''}`}>2.  How has the PPMD teamed this year across SLs, SSLs and functions? And how will they accelerate it next year?</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.question2AnsContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div style={{ padding: '10px', height: '74%' }}>
                    <div className={`quill-container ${errorMessages && errorMessages['question2AnsContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        id="question2AnsContent"
                        modules={modules}
                        ref={question2AnsContentRef}
                        onKeyDown={(e) => checkCharacterCount(e, question2AnsContentRef)}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        placeholder="Comments here..*"
                        defaultValue={fieldValues && fieldValues.question2AnsContent ? fieldValues.question2AnsContent : null}
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "question2Ans")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "question2Ans")}
                      />
                    </div>
                    <div id="question2AnsContentCounter"
                      className={`${errorMessages && errorMessages['question2AnsContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.question2AnsPlainText ? fieldValues.question2AnsPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }
          <div className={`mainHeading ${errorMessages && errorMessages['question3AnsContent'] !== "" ? 'redColor' : ''}`}>3.  What actions has the PPMD taken to contribute to priorities including diversity, equity and inclusiveness and wellbeing?</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.question3AnsContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div style={{ padding: '10px', height: '74%' }}>
                    <div className={`quill-container ${errorMessages && errorMessages['question3AnsContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        id="question3AnsContent"
                        modules={modules}
                        ref={question3AnsContentRef}
                        onKeyDown={(e) => checkCharacterCount(e, question3AnsContentRef)}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        placeholder="Comments here..*"
                        defaultValue={fieldValues && fieldValues.question3AnsContent ? fieldValues.question3AnsContent : null}
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "question3Ans")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "question3Ans")}
                      />
                    </div>
                    <div id="question3AnsContentCounter"
                      className={`${errorMessages && errorMessages['question3AnsContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.question3AnsPlainText ? fieldValues.question3AnsPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }
          <div className={`mainHeading ${errorMessages && errorMessages['question4AnsContent'] !== "" ? 'redColor' : ''}`}>4.  What is the PPMD's capability and aspiration to take on the next complex challenge? What is needed to help them get there?</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.question4AnsContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div style={{ padding: '10px', height: '74%' }}>
                    <div className={`quill-container ${errorMessages && errorMessages['question4AnsContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        id="question4AnsContent"
                        modules={modules}
                        ref={question4AnsContentRef}
                        onKeyDown={(e) => checkCharacterCount(e, question4AnsContentRef)}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        placeholder="Comments here..*"
                        defaultValue={fieldValues && fieldValues.question4AnsContent ? fieldValues.question4AnsContent : null}
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "question4Ans")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "question4Ans")}
                      />
                    </div>
                    <div id="question4AnsContentCounter"
                      className={`${errorMessages && errorMessages['question4AnsContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.question4AnsPlainText ? fieldValues.question4AnsPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }
          <div className={`mainHeading ${errorMessages && errorMessages['question6AnsContent'] !== "" ? 'redColor' : ''}`}>5.  Are there any significant performance deficiences that need to be highlighted?</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.question6AnsContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div style={{ padding: '10px', height: '74%' }}>
                    <div className={`quill-container ${errorMessages && errorMessages['question6AnsContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        id="question6AnsContent"
                        modules={modules}
                        ref={question6AnsContentRef}
                        onKeyDown={(e) => checkCharacterCount(e, question6AnsContentRef)}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        placeholder="Comments here..*"
                        defaultValue={fieldValues && fieldValues.question6AnsContent ? fieldValues.question6AnsContent : null}
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "question6Ans")}
                      />
                    </div>
                    <div id="question6AnsContentCounter"
                      className={`${errorMessages && errorMessages['question6AnsContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.question6AnsPlainText ? fieldValues.question6AnsPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }
          <div className={`mainHeading ${errorMessages && errorMessages['question5AnsContent'] !== "" ? 'redColor' : ''}`}>Summary of Roles/Areas of Responsibility:</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.question5AnsContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div style={{ padding: '10px', height: '74%' }}>
                    <div className={`quill-container ${errorMessages && errorMessages['question5AnsContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        id="question5AnsContent"
                        modules={modules}
                        ref={question5AnsContentRef}
                        onKeyDown={(e) => checkCharacterCount(e, question5AnsContentRef)}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        placeholder="Comments on Roles/Areas of Responsibility*"
                        defaultValue={fieldValues && fieldValues.question5AnsContent ? fieldValues.question5AnsContent : null}
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "question5Ans")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "question5Ans")}
                      />
                    </div>
                    <div id="question5AnsContentCounter"
                      className={`${errorMessages && errorMessages['question5AnsContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.question5AnsPlainText ? fieldValues.question5AnsPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }
        </div>
        <div className="seperator" style={{ paddingBottom: "0px" }}>
          <div>
            <div class="mainHeading" style={{ color: "red" }}>Please complete and submit <u>prior</u> to PFC meeting. These deadlines are determined by region, please reach out to your STC for your applicable deadline(s).</div>
            <div class="mainHeading">Guidance for preparing LEAD Reviewer YE Comments:</div>
            <ul class="subHeading">
              <li>Please draft your counsleee's year-end commentary for the finalization form in the format below utilizing descriptive examples of distinguished performance behavior per Audit service line priorities  detailed in LEAD 4D and if applicable, from regional leadership.</li>
              <li>Do not reference non-audit pursuits/sales/fees on Ch 1 clients or extending relationships to build non-audit revenues on Ch 1 clients.</li>
              <li>To be <u>considered</u> as <span style={{ color: "red" }}>Strategic Impact</span>, there <u>must</u> be demonstrated sponsorship of 1-2 underrepresented individuals evidenced by specific details.</li>
              {/* <li>The last two sentences are required for every finalization form.</li> */}
            </ul>
            {/* <div class="mainHeading">Draft Finalization Form Comments:</div>
            <div class="subHeading">Describe the top 2-4 distinguished performance behaviors exhibited during the fiscal year (include at least one DEI and one continuous improvement behavior):</div>
            <ul class="subHeading">
              <li> - XXX</li>
              <li> - XXX</li>
            </ul>
            <div class="subHeading">Describe 2-4 development points:</div>
            <ul class="subHeading">
              <li> - XXX</li>
              <li> - XXX</li>
            </ul>
            <div class="subHeading">Describe potential and aspirations:</div>
            <ul class="subHeading">
              <li> - XXX</li>
            </ul> */}
            {/* <div class="mainHeading">Potential and Aspirations:</div> */}
            {/* <div class="subHeading">Overall, it has been a [strong/inconsistent] year for [PPMD name] and is in the [XXX] category.  [PPMD name]'s quality rating has been considered in their LEAD category determination and the related commentary is contained in MARQ.</div> */}
          </div>
        </div>
        <div className="seperator">
          <div class="mainHeading">Draft Finalization Form Comments:</div>
          <div className="mainHeading" style={{ color: "red" }}>Important Note: The comments entered below will be aggregated by the system into one finalization write-up, so either utilize <u>bullets</u> (click on <img src={quillBulletIcon} alt="" style={{ height: "15px" }} />) or <u>brief phrases</u> to document the behaviors/points. The preview button above and export will display the resulting finalization write-up.</div>
        </div>
        <div className="seperator">
          <div className={`mainHeading ${errorMessages && errorMessages['distinguishPerformanceContent'] !== "" ? 'redColor' : ''}`}>Describe the top 2-4 distinguished performance behaviors exhibited during the fiscal year</div>
          <div className={`mainHeading ${errorMessages && errorMessages['distinguishPerformanceContent'] !== "" ? 'redColor' : ''}`}> - Include at least one DEI [people and teaming] and one continuous improvement [margin] behavior. These behaviors should align with the "focus action items" identified above from the self-assessment and/or any significant leading/transformative behaviors noted in the PPMD discussion guide.</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.distinguishPerformanceContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div className="seperatorControls">
                    <div className={`quill-container ${errorMessages && errorMessages['distinguishPerformanceContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        id="distinguishPerformanceContent"
                        modules={modules}
                        ref={distinguishPerformanceContentRef}
                        onKeyDown={(e) => checkCharacterCount(e, distinguishPerformanceContentRef)}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        defaultValue={fieldValues && fieldValues.distinguishPerformanceContent ? fieldValues.distinguishPerformanceContent : null}
                        placeholder="Comments on distinguished performance behaviors*"
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "distinguishPerformance")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "distinguishPerformance")}
                      />
                    </div>
                    <div id="distinguishPerformanceCounter"
                      className={`${errorMessages && errorMessages['distinguishPerformanceContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.distinguishPerformancePlainText ? fieldValues.distinguishPerformancePlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }
          <div className={`mainHeading ${errorMessages && errorMessages['developmentPointsContent'] !== "" ? 'redColor' : ''}`}>Describe 2-4 development points for PPMD:</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.developmentPointsContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div className="seperatorControls">
                    <div className={`quill-container ${errorMessages && errorMessages['developmentPointsContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        id="developmentPointsContent"
                        modules={modules}
                        ref={developmentPointsContentRef}
                        onKeyDown={(e) => checkCharacterCount(e, developmentPointsContentRef)}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        defaultValue={fieldValues && fieldValues.developmentPointsContent ? fieldValues.developmentPointsContent : null}
                        placeholder="Comments on development points*"
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "developmentPoints")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "developmentPoints")}
                      />
                    </div>
                    <div id="developmentPointsPlainText"
                      className={`${errorMessages && errorMessages['developmentPointsContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.developmentPointsPlainText ? fieldValues.developmentPointsPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }
          <div className={`mainHeading ${errorMessages && errorMessages['potentialAndAspirationsContent'] !== "" ? 'redColor' : ''}`}>Describe potential and aspirations:</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.potentialAndAspirationsContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div className="seperatorControls">
                    <div className={`quill-container ${errorMessages && errorMessages['potentialAndAspirationsContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        id="potentialAndAspirationsContent"
                        modules={modules}
                        ref={potentialAndAspirationsContentRef}
                        onKeyDown={(e) => checkCharacterCount(e, potentialAndAspirationsContentRef)}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        defaultValue={fieldValues && fieldValues.potentialAndAspirationsContent ? fieldValues.potentialAndAspirationsContent : null}
                        placeholder="Comments on potential and aspirations"
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "potentialAndAspirations")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "potentialAndAspirations")}
                      />
                    </div>
                    <div id="potentialAndAspirationsPlainText"
                      className={`${errorMessages && errorMessages['potentialAndAspirationsContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.potentialAndAspirationsPlainText ? fieldValues.potentialAndAspirationsPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }
          <div className="mainHeading">Proposed Overall Category</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="mainHeadingPreview"><span style={{ color: "#6a6d70" }}>
                    Response</span></div>
                  <div className="subHeadingPreview">{fieldValues.overallCategory}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <DropdownComponent
                    label="Proposed Overall Category"
                    defaultValue={fieldValues && fieldValues.overallCategory}
                    required={true}
                    selectOnChange={(e, value) => selectOnChange(e, value)}
                    varName="overallCategory"
                    list={[
                      "Does Not Meet",
                      "Progressing",
                      "Differentiating",
                      "Strategic Impact",
                    ]}
                    incrFontSize={true}
                    errorMessage={errorMessages}
                    disabled={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                  />
                </>
              )
          }
        </div>
      </SimpleBar>
      <div className={"btnSection"}>
        <div className="update-info">
        </div>
        <div className="buttons-wrapper">
          {
            !isFormPreview && (
              <>
                {/* <div
                  style={{
                    fontSize: "0.90rem",
                    color: "rgba(0,0,0,.80)",
                    fontFamily: "EYInterState-Regular",
                    marginRight: "10px",
                  }}>
                  * Please fill all mandatory fields
                </div> */}
                {/* <div
                    className={
                      checkifAllMandatoryFieldsFilled()
                        ? " cancel-button summary-button "
                        : "disable-sub save-button "
                    }
                    onClick={() => {
                      if (checkifAllMandatoryFieldsFilled()) {
                        checkOutsideClickAdmin(cancelClick);
                      }
                    }}>
                    Export
                  </div> */}
                <div
                  // className={
                  //   checkifAllMandatoryFieldsFilled()
                  //     ? " cancel-button summary-button "
                  //     : "disable-sub save-button "
                  // }
                  className="cancel-button summary-button"
                  // onClick={() => {
                  //   if (checkifAllMandatoryFieldsFilled()) {
                  //     checkOutsideClickAdmin(cancelClick);
                  //   }
                  // }}
                  onClick={cancelClick}
                >
                  Cancel
                </div>
              </>
            )
          }
          {
            !isFormPreview && (
              <>
                <div
                  // className={
                  //   checkifAllMandatoryFieldsFilled() &&
                  //     fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted
                  //     //&&
                  //     //!disableForm() &&
                  //     //myContext.changeTriggered
                  //     ? "conf-save save-button"
                  //     : "disable-sub save-button opacityReduced"
                  // }
                  className={
                    fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted
                      ? "conf-save save-button"
                      : "disable-sub save-button opacityReduced"
                  }
                  onClick={() => {
                    saveBtnClick();
                  }}>
                  Save
                </div>
                <div
                  // className={"conf-sub submit-button" + (!checkifAllMandatoryFieldsFilled()
                  //   || (fieldValues && fieldValues.status === discussionGuideStatuses.Submitted) ? " opacityReduced " : "")}
                  className={"conf-sub submit-button" + (
                    (fieldValues && fieldValues.status === discussionGuideStatuses.Submitted) ? " opacityReduced " : "")}
                  onClick={() => {
                    submitClicked();
                  }}>
                  <i
                    className="icon-verified-outline "
                    style={{ marginRight: "5px" }}
                  />
                  Submit
                </div>
              </>
            )
          }
        </div>
      </div>

      <Snackbar open={showErrorAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={'topcenter'}
        onClose={handleErrorAlertClose}>
        <Alert className={classes.root}
          elevation={6} onClose={handleErrorAlertClose} variant="filled" severity="error">
          <AlertTitle>Below questions must be answered</AlertTitle>
          <ul>
            {
              errorMessages && Object.keys(errorMessages).map(key =>
                errorMessages[key] !== "" &&
                <>
                  <li>{`${errorMessages[key]}`}</li><br />
                </>
              )}
            {/* {
              Object.keys(errors).forEach((key, index) => (
                <>
                  <li>{errors[key]}</li><br />
                </>
              ))
            } */}
            {/* {
              mandatoryFields.forEach((value) => (
                fieldValues[value.key] !== "" && (
                  <>
                    <li>{value.name}</li><br />
                  </>
                )
              ))
            } */}
          </ul>
        </Alert>
      </Snackbar>

      <AlertSubmitPopup
        header={"Save Changes"}
        onlyAlert={false}
        message={""}
        cancelBtnTxt={"No"}
        submitBtnTxt={"Yes"}
        simpleClosePopup={() => setShowAlertSavePopup(false)}
        description={"Do you wish to save the changes before you proceed?"}
        showAlertSubmitPopup={showAlertSavePopup}
        cancelAlertSubmitPopup={cancelAlertSubmitPopup}
        submitPopupChanges={() => {
          // setShowAlertSubmitPopup(false);
          saveBtnClick();
        }}></AlertSubmitPopup>
      <AlertSubmitPopup
        header={"Confirmation"}
        onlyAlert={false}
        message={"Would you like to submit the changes for review ?"}
        cancelBtnTxt={"Cancel"}
        submitBtnTxt={"Confirm"}
        simpleClosePopup={() => setShowSubmissionConfirmation(false)}
        description={
          "Please note that you will not be able to make futher edits once submitted."
        }
        showAlertSubmitPopup={showSubmissionConfirmation}
        cancelAlertSubmitPopup={cancelConfirmation}
        submitPopupChanges={() => {
          submitData();
        }}></AlertSubmitPopup>

      {/* <ReviewConfirmation
        showReviewPopup={showReviewConfirmation}
        dismissPopup={dismissReviewWindow}
        reviewResponse={submitReviewResponse}></ReviewConfirmation> */}
    </div>
  );
};
export default FinalizationForm;