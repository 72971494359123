import { useContext, useEffect, useState } from "react";
//import ReactExport from "react-data-export";
import BobTable from "./BobTable";
import AppContext from "../../utils/appContext";
import moment from "moment";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// excel data format columns and rows
const multiDataSet = [
  {
    columns: [
      {
        title: "Client ID",
        width: { wpx: 80 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          border: { right: { style: "medium", color: { rgb: "FF000000" } } },
          alignment: { wrapText: true, horizontal: "center", vertical: "top" },
        },
      },
      {
        title: "Client Name",
        width: { wpx: 80 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          border: { right: { style: "medium", color: { rgb: "FF000000" } } },
          alignment: { wrapText: true, horizontal: "left", vertical: "top" },
        },
      },
      {
        title: "Account Sector",
        width: { wpx: 120 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          border: { right: { style: "medium", color: { rgb: "FF000000" } } },
          alignment: { wrapText: true, horizontal: "left", vertical: "top" },
        },
      },
      {
        title: "Region",
        width: { wpx: 60 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          border: { right: { style: "medium", color: { rgb: "FF000000" } } },
          alignment: { wrapText: true, horizontal: "center", vertical: "top" },
        },
      },
      {
        title: "SEC Indicator",
        width: { wpx: 100 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          border: { right: { style: "medium", color: { rgb: "FF000000" } } },
          alignment: { wrapText: true, horizontal: "center", vertical: "top" },
        },
      },
      {
        title: "FY22 TER (YTD)",
        width: { wpx: 120 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          border: { right: { style: "medium", color: { rgb: "FF000000" } } },
          alignment: { wrapText: true, horizontal: "center", vertical: "top" },
        },
      },
      {
        title: "FY21 TER (YTD)",
        width: { wpx: 120 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          border: { right: { style: "medium", color: { rgb: "FF000000" } } },
          alignment: { wrapText: true, horizontal: "center", vertical: "top" },
        },
      },
      {
        title: "FY22 Revenue Plan (TER)",
        width: { wpx: 120 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          border: { right: { style: "medium", color: { rgb: "FF000000" } } },
          alignment: { wrapText: true, horizontal: "center", vertical: "top" },
        },
      },
      {
        title: "FY22 Margin $ YTD",
        width: { wpx: 120 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          border: { right: { style: "medium", color: { rgb: "FF000000" } } },
          alignment: { wrapText: true, horizontal: "center", vertical: "top" },
        },
      },
      {
        title: "FY22 Margin % (YTD)",
        width: { wpx: 120 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          border: { right: { style: "medium", color: { rgb: "FF000000" } } },
          alignment: { wrapText: true, horizontal: "center", vertical: "top" },
        },
      },
      {
        title: "FY21 Margin % (YTD)",
        width: { wpx: 120 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          border: { right: { style: "medium", color: { rgb: "FF000000" } } },
          alignment: { wrapText: true, horizontal: "center", vertical: "top" },
        },
      },
      {
        title: "FY22 Client Target Margin %",
        width: { wpx: 130 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          border: { right: { style: "medium", color: { rgb: "FF000000" } } },
          alignment: { wrapText: true, horizontal: "center", vertical: "top" },
        },
      },
      {
        title: "Role",
        width: { wpx: 40 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          border: { right: { style: "medium", color: { rgb: "FF000000" } } },
          alignment: { wrapText: true, horizontal: "center", vertical: "top" },
        },
      },
      {
        title: "Focus Client",
        width: { wpx: 75 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          border: { right: { style: "medium", color: { rgb: "FF000000" } } },
          alignment: { wrapText: true, horizontal: "center", vertical: "top" },
        },
      },
      {
        title: "In BOB at any time during FY22",
        width: { wpx: 130 },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
          alignment: { wrapText: true, horizontal: "center", vertical: "top" },
        },
      },
    ],
    data: [
      [
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
        { value: "" },
      ],
    ],
  },
];

const dataSetHeader = (fy = '22') => {
  return ([
    {
      title: "Client ID",
      width: { wpx: 80 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        border: { right: { style: "medium", color: { rgb: "FF000000" } } },
        alignment: { wrapText: true, horizontal: "center", vertical: "top" },
      },
    },
    {
      title: "Client Name",
      width: { wpx: 80 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        border: { right: { style: "medium", color: { rgb: "FF000000" } } },
        alignment: { wrapText: true, horizontal: "left", vertical: "top" },
      },
    },
    {
      title: "Account Sector",
      width: { wpx: 120 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        border: { right: { style: "medium", color: { rgb: "FF000000" } } },
        alignment: { wrapText: true, horizontal: "left", vertical: "top" },
      },
    },
    {
      title: "Region",
      width: { wpx: 60 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        border: { right: { style: "medium", color: { rgb: "FF000000" } } },
        alignment: { wrapText: true, horizontal: "center", vertical: "top" },
      },
    },
    {
      title: "SEC Indicator",
      width: { wpx: 100 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        border: { right: { style: "medium", color: { rgb: "FF000000" } } },
        alignment: { wrapText: true, horizontal: "center", vertical: "top" },
      },
    },
    {
      title: `FY${fy} TER (YTD)`,
      width: { wpx: 120 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        border: { right: { style: "medium", color: { rgb: "FF000000" } } },
        alignment: { wrapText: true, horizontal: "center", vertical: "top" },
      },
    },
    {
      title: `FY${fy - 1} TER (YTD)`,
      width: { wpx: 120 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        border: { right: { style: "medium", color: { rgb: "FF000000" } } },
        alignment: { wrapText: true, horizontal: "center", vertical: "top" },
      },
    },
    {
      title: `FY${fy} Revenue Plan (TER)`,
      width: { wpx: 120 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        border: { right: { style: "medium", color: { rgb: "FF000000" } } },
        alignment: { wrapText: true, horizontal: "center", vertical: "top" },
      },
    },
    {
      title: `FY${fy} Margin $ YTD`,
      width: { wpx: 120 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        border: { right: { style: "medium", color: { rgb: "FF000000" } } },
        alignment: { wrapText: true, horizontal: "center", vertical: "top" },
      },
    },
    {
      title: `FY${fy} Margin % (YTD)`,
      width: { wpx: 120 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        border: { right: { style: "medium", color: { rgb: "FF000000" } } },
        alignment: { wrapText: true, horizontal: "center", vertical: "top" },
      },
    },
    {
      title: `FY${fy - 1} Margin % (YTD)`,
      width: { wpx: 120 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        border: { right: { style: "medium", color: { rgb: "FF000000" } } },
        alignment: { wrapText: true, horizontal: "center", vertical: "top" },
      },
    },
    {
      title: `FY${fy} Client Target Margin %`,
      width: { wpx: 130 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        border: { right: { style: "medium", color: { rgb: "FF000000" } } },
        alignment: { wrapText: true, horizontal: "center", vertical: "top" },
      },
    },
    {
      title: "Role",
      width: { wpx: 40 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        border: { right: { style: "medium", color: { rgb: "FF000000" } } },
        alignment: { wrapText: true, horizontal: "center", vertical: "top" },
      },
    },
    {
      title: "Focus Client",
      width: { wpx: 75 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        border: { right: { style: "medium", color: { rgb: "FF000000" } } },
        alignment: { wrapText: true, horizontal: "center", vertical: "top" },
      },
    },
    {
      title: `In BOB at any time during FY${fy}`,
      width: { wpx: 130 },
      style: {
        fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
        alignment: { wrapText: true, horizontal: "center", vertical: "top" },
      },
    },
  ]);
};
// headers with specific order to show in excel file
const headersOrderArr = [
  "clientId",
  "client",
  "accountSector",
  "engagementRegion",
  "secInd",
  "currentTER",
  "priorTER",
  "revenueGoalIn",
  "marginIn",
  "curentMarginYTD",
  "priorMarginYTD",
  "regionMarginYTD",
  "role",
  "focusClient",
  "isPartOfBob",
];

const BobTablePopup = ({
  bobDetail,
  bobStatus,
  bobTotalObj,
  setRoleSelected,
  setFocusClientSelected,
}) => {
  const appContext = useContext(AppContext);
  const [exportableArr, setExportableArr] = useState(multiDataSet);
  const myContext = useContext(AppContext);
  const fy = myContext.financialYear ? `${myContext.financialYear}`.substring(2, 4) : '22';
  //   console.log("bobDetail", multiDataSet, exportableArr);
  // getting data as props from parent and doing modifications
  useEffect(() => {
    let arr = [];
    if (bobDetail && bobDetail.length > 0) {
      bobDetail.forEach((item) => {
        let arrItem = [];
        delete item._id;
        delete item.bobObjId;
        item.role = item.role.toUpperCase();
        item.curentMarginYTD = +item.curentMarginYTD;
        item.currentTER = +item.currentTER;
        item.marginIn = +item.marginIn;
        item.priorMarginYTD = +item.priorMarginYTD;
        item.priorTER = +item.priorTER;
        item.regionMarginYTD = +item.regionMarginYTD;
        item.revenueGoalIn = +item.revenueGoalIn; // converts in number

        headersOrderArr.forEach((key) => {
          arrItem.push({
            value: item[key] ? item[key].toString() : '',
            style: {
              alignment: {
                wrapText: true,
                horizontal: (key === 'client') || (key === 'accountSector') ? "left" : 'center',
                vertical: "top",
              },
              border: {
                bottom: { style: "thin", color: { rgb: "000000" } },
                left: { style: "thin", color: { rgb: "000000" } },
                right: { style: "thin", color: { rgb: "000000" } },
              },
            },
          });
        });
        arr.push(arrItem);
      });
      // console.log("arr", arr);
      const tempArr = exportableArr[0];
      tempArr.data = arr;
      setExportableArr([tempArr]);
    }
  }, [bobDetail, exportableArr]);

  useEffect(() => {
    const newHeader = dataSetHeader(fy);
    const tempArr = exportableArr[0];
    tempArr.columns = newHeader;
    setExportableArr([tempArr]);
  }, [exportableArr, fy])

  // creating the name of excel file as per user's name and with date time
  const fileName =
    "LEAD 4D_BOB_" +
    appContext.displayName +
    "_" +
    moment(new Date()).format("YYDDMM_HHmmss");

  return (
    <>
      <div className="bob-title-heading">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "25px 0px",
            fontSize: 13,
            backgroundColor: "#f9f9fb",
            padding: "10px 35px 10px 10px",
          }}
        >
          <div className="businessHeading">Book of Business</div>
          <div
            className="businessLabel"
            style={{ color: "#188CE5", display: "flex", alignItems: "center" }}
          >
            <i
              className="icon-cloud-download"
              style={{ fontSize: "medium" }}
            ></i>{" "}
            {/* <ExcelFile
              element={
                <button
                  style={{
                    color: "#188CE5",
                    border: "none",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    marginTop: 0
                  }}
                >
                  Download
                </button>
              }
              filename={fileName}
            >
              <ExcelSheet dataSet={exportableArr} name="Book of Business" />
            </ExcelFile> */}
          </div>
        </div>
      </div>
      <div className="clientContainer">
        {bobDetail && bobDetail.length > 0 && (
          <BobTable
            bobTotalObj={bobTotalObj}
            bobDetail={bobDetail}
            bobStatus={bobStatus}
            setFocusClientSelected={setFocusClientSelected}
            setRoleSelected={setRoleSelected}
            isEditMode={false}
          />
        )}
      </div>
    </>
  );
};

export default BobTablePopup;
