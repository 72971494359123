import React, { useState, useEffect, useContext } from "react";
import * as myConsts from "../../constants/constants";
import "./NotValidUser.scss";
const Page404 = (props) => {
  return (
    // <div className="not-found-wrap">
    //   <p>404</p> <p>Page Not found !</p>
    //   <button onClick={() => props.history.push(myConsts.routeHome)}>
    //     Home
    //   </button>
    // </div>
    <div className="error-fallback">
      <div className="msg" style={{ paddingBottom: '0px' }}>404</div>
      <div className="msg">Page not found !</div>
      <div className="btn-wrapper">
        <div
          className='btn'
          onClick={() => props.history.push(myConsts.routeHome)}>
          Go to Home
        </div>
      </div>
    </div>
  );
};

export default Page404;
