let documentTitle = 'Unknown-file';

const importAll = (r) => {
	let images = {};
	r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
	return images;
}
export const images = importAll(require.context('../assets/images', false, /\.(png|PNG|jpe?g|svg|pdf|gif)$/));

export const getDocmentTitleFromUrl = ( url ) => {
	if( url ){
		 let firstIndex = url.indexOf("blobcontainer") + 14 ;
		 let lastIndex = url.lastIndexOf("?");
		 documentTitle = url.substring( firstIndex, lastIndex );
	}
	return documentTitle;
}