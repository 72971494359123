import React from "react";
import "./ProgressChartCard.scss";
import ProgressChart from "../../progressChart/progressChart";

/* This component is for displaying the progress chart in the both dashboard and dashboard goal page. 
Here blue marker represents the overall score and green marker represets the peer group avg. Marker 
will be hidden if value is zero.
 */

const ProgressChartCard = (props) => {
  const {
    pillarName,
    pillarScore,
    peerGroupAvg,
    pillarId,
    onClick,
    peerGroupVisible,
  } = props;

  return (
    <div
      className="dashboardChartsWrapper progressCard"
      onClick={() => onClick && onClick(pillarId)}
    >
      <p className="dashboardChartHeader">{pillarName}</p>
      <div className="dashboardChartsContainer">
        <ProgressChart
          pillarName={pillarName}
          pillarScore={pillarScore}
          radius={50}
          isTitleBottomVisible={false}
          isScoreTextVisible={false}
          fillSize={11}
          isScoreVisible={false}
          peerGroupAvg={peerGroupAvg}
        />
        <div className="scoreWrapper">
          <div className="overallScore">
            <p>{pillarScore}</p>
            <p>Overall Score</p>
          </div>
          {peerGroupVisible && (
            <div className="peerGroupAvg">
              <p>{peerGroupAvg}</p>
              <p>Peer Group Avg</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgressChartCard;
