import "./AdminGeneralParam.scss";
// import React from "react";
import React, { useState, useEffect, useContext, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import DateCalender from "./DateCalender/DateCalender";
import AppContext from "../../../utils/appContext";
import DateTimeCalender from "./DateTimeCalender/DateTimeCalender";
import DropdownComponent from "./DropdownComponent/DropdownComponent";
import ChangesHistoryField from "../Approvals/ChangesHistoryField/ChangesHistoryField";
import ReviewConfirmation from "../Approvals/ReviewConfimation/ReviewConfirmation";
import FormControl from "@material-ui/core/FormControl";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import AlertSubmitPopup from "../../Common/AlertSubmitPopup";
import TimezoneSelect from "react-timezone-select";
import { toast, Flip } from "react-toastify";
import { authProvider, APIscope } from "./../../../Authentication/authProvider";
import "react-toastify/dist/ReactToastify.css";
import FormHelperText from "@material-ui/core/FormHelperText";
import SimpleBar from "simplebar-react";
import moment from "moment";
import "moment-timezone";
import uuid from "react-uuid";
import _ from "lodash";
import * as myConsts from "../../../constants/constants";
// import { validate } from "@material-ui/pickers";
// import { fr } from "date-fns/locale";

const useStyles = makeStyles((theme) => ({
  helper: {
    marginLeft: "10px",
  },
}));
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    fontSize: "12px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  control: (styles, { isDisabled }) => {
    return {
      ...styles,
      width: 350,
      border: "none",
      borderBottom: " 1.5px solid black",
      borderRadius: "0px",
      boxShadow: "none",
      backgroundColor: "rgb(246, 246, 250)",
      opacity: isDisabled ? "0.5" : "1",
      "&:hover": {
        order: "none",
        borderBottom: " 1.5px solid black",
        borderRadius: "0px",
        boxShadow: "none",
        opacity: isDisabled ? "0.5" : "1",
      },
    };
  },
  container: () => ({
    width: 350,
  }),
  placeholder: () => ({
    fontSize: "0.9rem",
    top: "12%",
    marginLeft: "0px",
  }),
  singleValue: (provided, state) => {
    const fontSize = "12px";
    const color = "rgba(0, 0, 0, 0.87)";

    return { ...provided, fontSize, color };
  },
};
const AdminGeneralParam = (props) => {
  const classes = useStyles();
  const myContext = useContext(AppContext);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [isPreview, setIsPreview] = useState(false);
  const [madeActive, setMadeActive] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [showSubmissionConfirmation, setShowSubmissionConfirmation] =
    useState(false);
  const [showReviewConfirmation, setShowReviewConfirmation] = useState(false);
  const [lastUpdateInfo, setLastUpdateInfo] = useState({ name: "", date: "" });
  const [highlightsData, setHighlightsData] = useState({});
  const [keyindex, setKeyIndex] = useState(0);
  const [disableActiveForAPIError, setDisableActiveForAPIError] =
    useState(false);
  // const [selectedTimezone, setSelectedTimezone] = useState("");
  const adminApprovalData = useSelector((state) => state.approvalData);
  const getAllGeneralParamData = useSelector((state) => state.getGeneralParam);
  const editGeneralParamData = useSelector((state) => state.editGeneralParam);
  const isOutsideClick = useSelector((state) => state.outsideClickAdmin);
  const [isApprovalPreview, setIsApprovalPreview] = useState(false);
  const [isReviewer, setIsReviewer] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const getUpdateHighlightData = useSelector(
    (state) => state.getUpdateHighlightData
  );
  const adminAppState = useSelector((state) => state.adminAppState);
  const submitAdminReview = useSelector((state) => state.submitAdminReview);

  const approvalPendingYears = useSelector((state) => state.getAssessmentSubmissionStatus.pendingYears);
  const approvedAssessmentYears = useSelector((state) => state.getAssessmentSubmissionStatus.approvedYears);

  const [flagON, setIsFlagON] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    fyYear: "FY" + myContext.financialYear,
    period: myContext.activePeriod
      ? myContext.activePeriod
      : myConsts.TimePeriodsArray[0], //incase null
    active: true,
    assessmentStartDate: new Date(),
    assessmentCompletionDate: null,
    assessmentLockDate: null,
    selectedTimezone: "",
    tenureReferenceDate: null,
    welcomeEmailDateTime: null,
    firstReminderSelf: null,
    secondReminderSelf: null,
    thirdReminderSelf: null,
    fourthReminderSelf: null,
    fifthReminderSelf: null,
    firstReminderApproval: null,
    secondReminderApproval: null,
    thirdReminderApproval: null,
    //..Year End Discussion Guide
    discussionGuideCompletionDate: null,
    discussionGuideLockDate: null,
    reviewerFinalCommentaryMDsLaunchDate: null,
    reviewerFinalCommentaryPartnersLaunchDate: null,
    firstDiscussionGuideReminderSelf: null,
    secondDiscussionGuideReminderSelf: null,
    thirdDiscussionGuideReminderSelf: null,
    // fourthDiscussionGuideReminderSelf: null,
    // fifthDiscussionGuideReminderSelf: null,
    firstDiscussionGuideReminderApproval: null,
    secondDiscussionGuideReminderApproval: null,
    thirdDiscussionGuideReminderApproval: null,
    fourthDiscussionGuideReminderApproval: null,
    fifthDiscussionGuideReminderApproval: null,
    sixthDiscussionGuideReminderApproval: null,

    finalCommentaryMDStartDate: null,
    finalCommentaryMDEndDate: null,

    finalCommentaryPartnerStartDate: null,
    finalCommentaryPartnerEndDate: null,

    firstFinalCommentaryMDsReminder: null,
    secondFinalCommentaryMDsReminder: null,
    thirdFinalCommentaryMDsReminder: null,

    firstFinalCommentaryPartnersReminder: null,
    secondFinalCommentaryPartnersReminder: null,
    thirdFinalCommentaryPartnersReminder: null,
    fourthFinalCommentaryPartnersReminder: null,
    fifthFinalCommentaryPartnersReminder: null,

    _id: "",
    activePeriodLockDate: null,
    pastPhaseLockDate: null,
  });

  const mandatoryFields = [
    { key: "fyYear", name: "FY Year" },
    { key: "period", name: "period" },
    //{ key: "assessmentStartDate", name: "Assessment Start Date" },
    //{ key: "assessmentLockDate", name: "Assessment Lock Date" },
    //{ key: "assessmentCompletionDate", name: "Assessment Completion Date" },
    { key: "selectedTimezone", name: "Time Zone" },
  ];
  // const [period, setPeriod] = useState("");
  const [errorMessages, setErrorMessages] = useState({
    active: "",
    fyYear: "",
    period: "",
    assessmentStartDate: "",
    assessmentCompletionDate: "",
    assessmentLockDate: "",
    selectedTimezone: "",
    tenureReferenceDate: "",
    welcomeEmailDateTime: "",
    firstReminderSelf: "",
    secondReminderSelf: "",
    thirdReminderSelf: "",
    fourthReminderSelf: "",
    fifthReminderSelf: "",
    firstReminderApproval: "",
    secondReminderApproval: "",
    thirdReminderApproval: "",
  });

  const isAssessmentApprovedyear = useCallback(() => {
    const year = Number.parseInt(fieldValues.fyYear.replace("FY", ""))
    return !approvalPendingYears.includes(year) && (approvedAssessmentYears.includes(year) || year <= myContext.financialYear);
  }, [approvalPendingYears, approvedAssessmentYears, fieldValues.fyYear])


  const getAssessmentApprovalList = () => {
    // approval list is used to verify the valid years on which roll forward and upload content can be done.
    dispatch({
      type: "GET_ASSESSMENT_APPROVAL_LIST",
      payload: {
        type: "Assessment"
      },
    });
  }

  useEffect(() => {
    const isPreview = _.get(adminApprovalData, "isPreview", false);
    setIsReviewer(_.get(adminApprovalData, "previewParams.isReviewer", false))
    setIsApprovalPreview(isPreview);
  }, [adminApprovalData])

  useEffect(() => {
    // initial fetch call ,on page load, with current year and period
    if (!adminApprovalData.isPreview) {
      callGeneralParam(myContext.financialYear, myContext.activePeriod);
      getAssessmentApprovalList();
    } else {
      callChangedUpdates(
        adminApprovalData.previewParams.financialYear,
        adminApprovalData.previewParams.timePeriod
      );
      callGeneralParam(
        adminApprovalData.previewParams.financialYear,
        adminApprovalData.previewParams.timePeriod
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setHighlightsData(_.get(getUpdateHighlightData, "data.updatedList", {}));
    setTimeout(() => {
      setKeyIndex(uuid());
    }, 5000);
    // eslint-disable-next-line
  }, [getUpdateHighlightData]);

  useEffect(() => {
    if (
      submitAdminReview &&
      !submitAdminReview.fetching &&
      submitAdminReview.data.message === "success"
    ) {
      const flag = _.get(submitAdminReview, "reviewState.validationStatus", "");
      const type = _.get(submitAdminReview, "reviewState.type", "");
      if (type === "GeneralParameters") {
        successNotify(flag === "Approved");
        dispatch({
          type: "SEND_ADMIN_REVIEW_RESET",
          payload: { type: "GeneralParameters" },
        });
        setTimeout(() => {
          props.history.push(myConsts.routeAdminApprovals);
        }, 1000);
      }
    }
  }, [submitAdminReview]);

  const validateAndEnableSubmitBtn = () => {
    if (
      checkifAllMandatoryFieldsFilled() &&
      !disableForm() &&
      !checkifErrorMessages() &&
      (myContext.changeTriggered || submissionStatus === "InProgress")
    ) {
      dispatch({
        type: "ENABLE_SUBMIT_BUTTON",
        payload: { type: "GeneralParameters" },
      });
    } else {
      dispatch({
        type: "ENABLE_SUBMIT_BUTTON_RESET",
        payload: {},
      });
    }
  };

  useEffect(() => {
    validateAndEnableSubmitBtn();
    // eslint-disable-next-line
  }, [
    fieldValues,
    ...Object.keys(errorMessages).map((key) => errorMessages[key]),
  ]);

  // useEffect(() => {
  //   validateAndEnableSubmitBtn();
  // }, [...Object.keys(errorMessages).map(key => errorMessages[key])])


  useEffect(() => {
    setShowSubmissionConfirmation(_.get(adminAppState, "submitClicked", false));
    setShowReviewConfirmation(_.get(adminAppState, "respondClicked", false));
    setEnableButton(_.get(adminAppState, "enableSubmit", false));
    // eslint-disable-next-line
  }, [adminAppState]);

  const cancelConfirmation = () => {
    dispatch({
      type: "SUBMIT_BUTTON_CLICKED_RESET",
      payload: {},
    });
  };

  const submitClicked = () => {
    saveData(true);
  };

  const dismissReviewWindow = () => {
    dispatch({
      type: "RESPOND_BUTTON_CLICKED_RESET",
      payload: {},
    });
  };
  const submitReviewResponse = (reviewResponse) => {
    dismissReviewWindow();
    setLoader(true);
    const payload = {
      type: "GeneralParameters",
      timePeriod: adminApprovalData.previewParams.timePeriod,
      financialYear: adminApprovalData.previewParams.financialYear,
      validatedBy: {
        userId: myContext.userId,
        name: myContext.displayName,
      },
      validationStatus: reviewResponse,
    };
    dispatch({
      type: "SEND_ADMIN_REVIEW",
      payload,
    });
  };

  const setLastEditData = (data) => {
    setLastUpdateInfo({
      date: getFormattedDate(_.get(data, "updatedOn", "")),
      name: _.get(data, "updatedBy.name", ""),
    });
  };

  const processValidationStatus = (status) => {
    setSubmissionStatus(status);
    dispatch({
      type: "SET_SUBMISSION_STATUS",
      payload: {
        type: "GeneralParameters",
        status: status === "InProgress" ? "Draft" : status,
      },
    });
  };

  const checkIfActiveDisable = () => {
    // Disable active checkbox if lock date has passed or if API error
    if (
      disableActiveForAPIError ||
      (fieldValues &&
        fieldValues.activePeriodLockDate &&
        new Date(new Date().toISOString()).setUTCHours(0, 0, 0, 0) <=
        new Date(fieldValues.activePeriodLockDate).setUTCHours(0, 0, 0, 0))
    )
      return true;
    else return false;
  };

  const disableforPastDays = (date) => {
    //Disable editing of fields if field values are past dates
    if (
      fieldValues &&
      fieldValues.selectedTimezone &&
      fieldValues.selectedTimezone.offsetformatted !== undefined &&
      fieldValues.selectedTimezone.offset !== undefined &&
      date != null &&
      new Date(new Date().toISOString()).setUTCHours(0, 0, 0, 0) >
      new Date(convertDateToUTC(date)).setUTCHours(0, 0, 0, 0)
    )
      return true;
    else return false;
  };

  const disableforPastDayTimes = (date) => {
    //all the dates till next day scheduler time  12:00am UTC has already been picked,so not editable
    const currentPhase = _.get(getAllGeneralParamData, "data.data[0].activePhase", null);
    if (!currentPhase) {
      return false;
    }
    if (
      fieldValues &&
      fieldValues.selectedTimezone &&
      fieldValues.selectedTimezone.value !== undefined &&
      fieldValues.selectedTimezone.offset !== undefined &&
      date != null &&
      convertTZ(new Date(new Date(new Date().setUTCHours(24, 0, 0, 0)))) >
      new Date(date)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const saveBtnClick = () => {
    //check for error messages.if no error call save function else show popup to fix errors related to input fields
    if (
      checkifAllMandatoryFieldsFilled() &&
      !disableForm() &&
      myContext.changeTriggered
    ) {
      let t = checkifErrorMessages();
      if (!t) {
        // console.log("call APi");
        saveData(false);
      } else {
        myContext.setIsWarningPopup(true);
        toast.warn(
          <div>
            <i className="icon-warning-outline" /> Please re-check error
            messages.
          </div>,
          {
            position: "bottom-center",
            transition: Flip,
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        myContext.setShowPopupAdmin(false);
      }
    }
  };
  const convertOffsetToProperFormat = (value) => {
    //To convert offset from timezone-select component to std. format(eg:GMT+1.5 to GMT+01:30)
    //If decimal point is present in offset, conversion is done else return offset as such
    if (value.offset === '' || value.offset === undefined || value.offset === null)
      return myConsts.defaultSelectedTimeZone;
    else {
      let tempVal = value;
      tempVal.offsetformatted = value.offset;
      let offset = value.offset.toString();
      if (offset.includes(".")) {
        let tempmin = offset.substring(offset.indexOf("."));
        let temphr = offset.substring(0, offset.indexOf("."));
        tempVal.offsetformatted = temphr + ":" + Number.parseFloat(tempmin) * 60;
        return tempVal;
      } else {
        return tempVal;
      }
    }
  };
  const checkifErrorMessages = () => {
    //check errorMessages Obj for non empty strings,for identifying standing errors
    let flag = false;
    let json = errorMessages;
    Object.values(json) &&
      Object.values(json).map((val) => {
        if (val !== "") flag = true;
        return null;
      });
    return flag;
  };
  const cancelAlertSubmitPopup = () => {
    // Function called on clicking cancel button.If no form changes were done,then user taken to admin page
    // else save confirmation popup opens
    dispatch({
      type: "OUTSIDE_CLICK_ADMIN_CANCEL",
      payload: {},
    });
    myContext.setShowPopupAdmin(false);
  };
  const cancelClick = () => {
    props.history.push(myConsts.routeAdmin);
  };
  // const generateCurrentTimeForGivenTimeZone = (date) => {
  //   return moment(date).tz(fieldValues.selectedTimezone.value);
  // };

  function convertTZ(date, tzString) {
    //convert time to selected time zone
    return new Date(
      (typeof date === "string" ? new Date(date) : date).toLocaleString(
        "en-US",
        { timeZone: fieldValues.selectedTimezone.value }
      )
    );
  }
  const convertDateToUTC = (date) => {
    if (date) {
      //debugger;
      return new Date( //tobe used while saving
        new Date(date).toLocaleString() +
        " UTC" +
        (fieldValues.selectedTimezone.offset < 0
          ? fieldValues.selectedTimezone.offsetformatted
          : (" +" + fieldValues.selectedTimezone.offsetformatted).toString())
      ).toISOString();
    } else return null;
  };  
  const saveData = async (isFinalSubmission) => {
    debugger;
    let dataJson = {
      timePeriod: fieldValues.period,
      financialYear: Number.parseInt(fieldValues.fyYear.replace("FY", "")),
      updatedBy: {
        userId: myContext.userId,
        name: myContext.displayName,
      },
      validationStatus: isFinalSubmission ? "Submitted" : "InProgress",
      data: {
        // assessment_StartDate: convertDateToUTC(fieldValues.assessmentStartDate),
        // assessment_EndDate: convertDateToUTC(
        //   fieldValues.assessmentCompletionDate
        // ),
        // bob_EndDate: convertDateToUTC(fieldValues.assessmentCompletionDate),
        tenureCalculationReferenceDate: convertDateToUTC(
          fieldValues.tenureReferenceDate
        ),
        financialYear: Number.parseInt(fieldValues.fyYear.replace("FY", "")),
        activePhase: myContext.activePeriod,
        timePeriod: fieldValues.period,
        timeZone: fieldValues.selectedTimezone.value,
        timeZone_offset: fieldValues.selectedTimezone.offset,
        timeZone_label: fieldValues.selectedTimezone.label,
        welcomeEmailDate: convertDateToUTC(fieldValues.welcomeEmailDateTime),
        leadReviewer_CompletionDate: convertDateToUTC(
          fieldValues.assessmentLockDate
        ),
        // reminder_StartDate1: convertDateToUTC(fieldValues.firstReminderSelf),
        // reminder_StartDate2: convertDateToUTC(fieldValues.secondReminderSelf),
        // reminder_StartDate3: convertDateToUTC(fieldValues.thirdReminderSelf),
        // reminder_StartDate4: convertDateToUTC(fieldValues.fourthReminderSelf),
        // reminder_StartDate5: convertDateToUTC(fieldValues.fifthReminderSelf),
        // reviewer_ReminderDate1: convertDateToUTC(
        //   fieldValues.firstReminderApproval
        // ),
        // reviewer_ReminderDate2: convertDateToUTC(
        //   fieldValues.secondReminderApproval
        // ),
        // reviewer_ReminderDate3: convertDateToUTC(
        //   fieldValues.thirdReminderApproval
        // ),
        dashboard_UpdateDate: null,
        _id: fieldValues._id !== "" ? fieldValues._id : null,
        isActivePhase: fieldValues.active,
      },
    };
    if (fieldValues.period &&
      fieldValues.period.toLowerCase() === "year-end") {
      //..If year end phase is active
      //dataJson.data.discussionGuide_EndDate = convertDateToUTC(fieldValues.discussionGuideCompletionDate);
      dataJson.data.leadReviewer_DiscussionGuide_CompletionDate = convertDateToUTC(fieldValues.discussionGuideLockDate);
      dataJson.data.reviewer_FinalCommentary_MDs_LaunchDate = convertDateToUTC(fieldValues.reviewerFinalCommentaryMDsLaunchDate);
      dataJson.data.reviewer_FinalCommentary_Partners_LaunchDate = convertDateToUTC(fieldValues.reviewerFinalCommentaryPartnersLaunchDate);
      // dataJson.data.reminder_DiscussionGuide_StartDate1 = convertDateToUTC(fieldValues.firstDiscussionGuideReminderSelf);
      // dataJson.data.reminder_DiscussionGuide_StartDate2 = convertDateToUTC(fieldValues.secondDiscussionGuideReminderSelf);
      // dataJson.data.reminder_DiscussionGuide_StartDate3 = convertDateToUTC(fieldValues.thirdDiscussionGuideReminderSelf);
      dataJson.data.reviewer_DiscussionGuide_ReminderDate1 = convertDateToUTC(fieldValues.firstDiscussionGuideReminderApproval);
      dataJson.data.reviewer_DiscussionGuide_ReminderDate2 = convertDateToUTC(fieldValues.secondDiscussionGuideReminderApproval);
      dataJson.data.reviewer_DiscussionGuide_ReminderDate3 = convertDateToUTC(fieldValues.thirdDiscussionGuideReminderApproval);
      dataJson.data.reviewer_DiscussionGuide_ReminderDate4 = convertDateToUTC(fieldValues.fourthDiscussionGuideReminderApproval);
      dataJson.data.reviewer_DiscussionGuide_ReminderDate5 = convertDateToUTC(fieldValues.fifthDiscussionGuideReminderApproval);
      dataJson.data.reviewer_DiscussionGuide_ReminderDate6 = convertDateToUTC(fieldValues.sixthDiscussionGuideReminderApproval);

      dataJson.data.finalCommentary_MD_StartDate = convertDateToUTC(fieldValues.finalCommentaryMDStartDate);
      dataJson.data.finalCommentary_MD_EndDate = convertDateToUTC(fieldValues.finalCommentaryMDEndDate);

      dataJson.data.finalCommentary_Partner_StartDate = convertDateToUTC(fieldValues.finalCommentaryPartnerStartDate);
      dataJson.data.finalCommentary_Partner_EndDate = convertDateToUTC(fieldValues.finalCommentaryPartnerEndDate);

      dataJson.data.reviewer_FinalCommentary_MDs_ReminderDate1 = convertDateToUTC(fieldValues.firstFinalCommentaryMDsReminder);
      dataJson.data.reviewer_FinalCommentary_MDs_ReminderDate2 = convertDateToUTC(fieldValues.secondFinalCommentaryMDsReminder);
      dataJson.data.reviewer_FinalCommentary_MDs_ReminderDate3 = convertDateToUTC(fieldValues.thirdFinalCommentaryMDsReminder);

      dataJson.data.reviewer_FinalCommentary_Partners_ReminderDate1 = convertDateToUTC(fieldValues.firstFinalCommentaryPartnersReminder);
      dataJson.data.reviewer_FinalCommentary_Partners_ReminderDate2 = convertDateToUTC(fieldValues.secondFinalCommentaryPartnersReminder);
      dataJson.data.reviewer_FinalCommentary_Partners_ReminderDate3 = convertDateToUTC(fieldValues.thirdFinalCommentaryPartnersReminder);
      dataJson.data.reviewer_FinalCommentary_Partners_ReminderDate4 = convertDateToUTC(fieldValues.fourthFinalCommentaryPartnersReminder);
      dataJson.data.reviewer_FinalCommentary_Partners_ReminderDate5 = convertDateToUTC(fieldValues.fifthFinalCommentaryPartnersReminder);

    }
    try {
      setLoader(true);
      const token = await authProvider.acquireTokenSilent({
        scopes: APIscope,
      });
      const idToken = token.accessToken;
      dispatch({
        type: "EDIT_GENERAL_PARAMETERS",
        payload: {
          token: idToken,
          dataJson: dataJson,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const disableForm = () => {
    //debugger;
    //disable form if assement lock date has passed or selected financial year is past year
    const isActivePhase = _.get(getAllGeneralParamData, "data.data[0].isActivePhase", true);
    const status = _.get(getAllGeneralParamData, "data.data[0].status", "");

    if (status !== "Completed" && !isActivePhase && submissionStatus !== "Submitted") {
      return false;
    }
    else if (
      submissionStatus === "Submitted" ||
      (fieldValues &&
        fieldValues.assessmentLockDate != null &&
        new Date(new Date().toISOString()).setHours(24) >
        new Date(convertDateToUTC(fieldValues.assessmentLockDate)).setHours(
          24
        )) ||
      (fieldValues &&
        fieldValues.fyYear &&
        fieldValues.fyYear.replace("FY", "") &&
        fieldValues.fyYear.replace("FY", "") < myContext.financialYear)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkifAllMandatoryFieldsFilled = () => {
    // To check if all mandatory fields are filled.tenureReferenceDate is mandatory field only in Plan phase.
    let flag = true;
    if (
      fieldValues &&
      fieldValues.period &&
      fieldValues.period.toLowerCase() === "plan"
    ) {
      let temp = mandatoryFields;
      temp.push({ key: "tenureReferenceDate", name: "Tenure Reference Date" });
      temp.forEach((value) => {
        if (fieldValues[value.key] === "" || fieldValues[value.key] === null) {
          flag = false;
        }
      });
    } else {
      mandatoryFields.forEach((value) => {
        if (fieldValues[value.key] === "" || fieldValues[value.key] === null) {
          flag = false;
        }
      });
    }
    return flag;
  };

  const checkOutsideClickAdmin = (fn, params1) => {
    // myContext.changeTriggered will be true if there is any unsaved change in form value.
    // On any outside click, this function will be called with actual function,connected to click as param
    // If flag is true,save confirmation popup will be opened,else excution of actual function will happen
    if (myContext.changeTriggered) {
      dispatch({
        type: "OUTSIDE_CLICK_ADMIN",
        payload: {
          flag: false,
          callback: { fn: fn, params: params1 },
        },
      });
      // setShowAlertSubmitPopup(true);
      myContext.setShowPopupAdmin(true);
    } else {
      fn && fn(params1);
    }
  };

  useEffect(() => {
    if (
      myContext.changeTriggered &&
      isOutsideClick.data &&
      !isOutsideClick.data.flag &&
      isOutsideClick.data.cancel
    ) {
      //save on cancel button click,
      const outClickCallback = isOutsideClick.data.callback;
      if (outClickCallback && Object.keys(outClickCallback).length > 0) {
        dispatch({
          type: "OUTSIDE_CLICK_ADMIN",
          payload: { flag: false, callback: {} },
        });
        outClickCallback.fn && outClickCallback.fn(outClickCallback.params);
        dispatch({
          type: "OUTSIDE_CLICK_ADMIN_RESET",
          payload: {},
        });
        myContext.setChangeTriggered(false);
      }
    } else if (
      myContext.changeTriggered &&
      isOutsideClick.data &&
      isOutsideClick.data.flag
    ) {
      saveBtnClick();
      myContext.setIsAutoSave(false);
    }
    // eslint-disable-next-line
  }, [myContext.changeTriggered, isOutsideClick]);

  const validationDate = () => {
    //function to do all date vaidations,in checkdate array each obj has keyname of the field,
    //keyname of min and max limit fields and error messages if it crosses min or max values and a flag whether border value can be included or not
    var checkDate = [
      {
        key: "assessmentStartDate",
        minKey: "pastPhaseLockDate",
        maxKey: "assessmentCompletionDate",
        minMessage:
          "This date should not overlap with dates of " +
          getLastPhaseAndYear(fieldValues.period, fieldValues.fyYear)
            .timePeriod +
          " period",
        maxMessage: "This date should be before assessment completion date",
        including: false,
      },
      {
        key: "assessmentCompletionDate",
        minKey: "assessmentStartDate",
        maxKey: "assessmentLockDate",
        minMessage: "This date should be after assessment start date",
        maxMessage: "This date should be before assessment lock date",
        including: false,
      },
      {
        key: "assessmentLockDate",
        minKey: "assessmentCompletionDate",
        maxKey: "",
        minMessage: "This date should be after assessment completion date",
        maxMessage: "max",
        including: false,
      },
      // {
      //   key: "welcomeEmailDateTime",
      //   minKey: "assessmentStartDate",
      //   maxKey: "assessmentLockDate",
      //   minMessage: "This date should be after assessment start date",
      //   maxMessage: "This date should be before assessment lock date",
      //   including: false,
      // },
      {
        key: "firstReminderSelf",
        minKey: "assessmentStartDate",
        maxKey: "secondReminderSelf",
        secondMaxKey: "assessmentCompletionDate",
        minMessage: "This date should be after assessment start date",
        maxMessage: "This date should be before second Reminder date",
        secondMaxMessage:
          "This date should be before assessment completion date",
        including: false,
      },
      {
        key: "secondReminderSelf",
        minKey: "firstReminderSelf",
        maxKey: "thirdReminderSelf",
        secondMaxKey: "assessmentCompletionDate",
        minMessage: "This date should be after first Reminder date",
        maxMessage: "This date should be before third Reminder date",
        secondMaxMessage:
          "This date should be before assessment completion date",
        including: false,
      },
      {
        key: "thirdReminderSelf",
        minKey: "secondReminderSelf",
        maxKey: "fourthReminderSelf",
        secondMaxKey: "assessmentCompletionDate",
        minMessage: "This date should be after second Reminder date",
        maxMessage: "This date should be before fourth Reminder date",
        secondMaxMessage:
          "This date should be before assessment completion date",
        including: false,
      },
      {
        key: "fourthReminderSelf",
        minKey: "thirdReminderSelf",
        maxKey: "fifthReminderSelf",
        secondMaxKey: "assessmentCompletionDate",
        minMessage: "This date should be after third Reminder date",
        maxMessage: "This date should be before fifth Reminder date",
        secondMaxMessage:
          "This date should be before assessment completion date",
        including: false,
      },
      {
        key: "fifthReminderSelf",
        minKey: "fourthReminderSelf",
        maxKey: "assessmentCompletionDate",
        secondMaxKey: "assessmentLockDate",
        minMessage: "This date should be after fourth Reminder date",
        maxMessage: "This date should be before assessment completion date",
        secondMaxMessage: "This date should be before assessment lock date",
        including: false,
      },

      {
        key: "firstReminderApproval",
        minKey: "assessmentCompletionDate",
        maxKey: "secondReminderApproval",
        secondMaxKey: "assessmentLockDate",
        minMessage: "This date should be after assessment completion date",
        maxMessage: "This date should be before second Reminder date",
        secondMaxMessage: "This date should be before assessment lock date",
        including: false,
      },
      {
        key: "secondReminderApproval",
        minKey: "firstReminderApproval",
        maxKey: "thirdReminderApproval",
        secondMaxKey: "assessmentLockDate",
        minMessage: "This date should be after first Reminder date",
        maxMessage: "This date should be before third Reminder date",
        secondMaxMessage: "This date should be before assessment lock date",
        including: false,
      },
      {
        key: "thirdReminderApproval",
        minKey: "secondReminderApproval",
        maxKey: "assessmentLockDate",
        minMessage: "This date should be after second Reminder date",
        maxMessage: "This date should be before assessment lock date",
        including: false,
      },
    ];
    checkDate.forEach((keyset) => {
      if (fieldValues) {
        if (
          fieldValues[keyset.key] &&
          keyset.minKey !== "" &&
          fieldValues[keyset.minKey] &&
          ((keyset.including &&
            fieldValues[keyset.minKey] >= fieldValues[keyset.key]) ||
            (!keyset.including &&
              fieldValues[keyset.minKey] >= fieldValues[keyset.key]))
        ) {
          setErrorMessages((prevState) => ({
            ...prevState,
            [keyset.key]: keyset.minMessage,
          }));
        } else if (
          fieldValues[keyset.key] &&
          keyset.maxKey !== "" &&
          fieldValues[keyset.maxKey] &&
          ((!keyset.including &&
            fieldValues[keyset.maxKey] < fieldValues[keyset.key]) ||
            (keyset.including &&
              fieldValues[keyset.maxKey] <= fieldValues[keyset.key]))
        ) {
          setErrorMessages((prevState) => ({
            ...prevState,
            [keyset.key]: keyset.maxMessage,
          }));
        } else if (
          fieldValues[keyset.key] &&
          keyset.secondMaxKey &&
          keyset.secondMaxKey !== "" &&
          fieldValues[keyset.secondMaxKey] &&
          ((!keyset.including &&
            fieldValues[keyset.secondMaxKey] < fieldValues[keyset.key]) ||
            (keyset.including &&
              fieldValues[keyset.secondMaxKey] <= fieldValues[keyset.key]))
        ) {
          setErrorMessages((prevState) => ({
            ...prevState,
            [keyset.key]: keyset.secondMaxMessage,
          }));
        } else {
          setErrorMessages((prevState) => ({
            ...prevState,
            [keyset.key]: "",
          }));
        }
      }
    });
  };

  const selectOnChange = (fieldname, value) => {
    // common on change function for select input year and phase
    setMadeActive(false);
    setDisableActiveForAPIError(false);
    myContext.setChangeTriggered(false);
    setFieldValues({ ...fieldValues, [fieldname]: value });
    setErrorMessages({ ...errorMessages, [fieldname]: "" });
  };
  const dateOnChange = (fieldname, value) => {
    // common onchange function for date selection fields
    if (disableforPastDays(value)) {
      myContext.setIsWarningPopup(true);
      toast.warn(
        <div>
          <i className="icon-verified-outline icon" />
          Sorry!You can't choose a past date.
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      myContext.setChangeTriggered(true);
      setErrorMessages((prevState) => ({
        ...prevState,
        [fieldname]: "",
      }));
      // }
      setFieldValues((prevState) => ({
        ...prevState,
        [fieldname]: new Date(value).setHours(0, 0, 0, 0),
      }));
    }
  };
  const dateTimeOnChange = (fieldname, value) => {
    debugger;
    // common onchange function for date-time selection fields
    if (disableforPastDayTimes(value)) {
      myContext.setIsWarningPopup(true);
      if (!flagON) {
        setIsFlagON(true);
        setTimeout(function () {
          setIsFlagON(false);
        }, 2300);
        toast.warn(
          <div>
            <i className="icon-verified-outline icon" />
            {"Sorry!You can't choose a date or time before " +
              new Date(
                new Date(
                  new Date().toLocaleString("en-US", {
                    timeZone: "Etc/GMT",
                  })
                ).setHours(24, 0, 0, 0)
              ).toLocaleString("en-US", {
                month: "short",
                year: "numeric",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
              }) +
              " UTC."}
          </div>,
          {
            position: "bottom-center",
            transition: Flip,
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } else {
      myContext.setChangeTriggered(true);
      setFieldValues((prevState) => ({
        ...prevState,
        [fieldname]: value,
      }));

      setErrorMessages((prevState) => ({
        ...prevState,
        [fieldname]: "",
      }));
    }
  };
  useEffect(() => {
    //call validation method on each field value change,to update error messages
    validationDate();
  }, [fieldValues]);

  // const generateCurrentTimeZoneISO = (date) => {
  //   return moment(date).tz(fieldValues.selectedTimezone.value).toISOString();
  // };

  useEffect(() => {
    // call API to fetch data on value change in phase or year field
    if (
      !adminApprovalData.isPreview &&
      fieldValues.period !== "" &&
      fieldValues.fyYear !== ""
    )
      callGeneralParam(
        fieldValues.fyYear.replace("FY", ""),
        fieldValues.period
      );
    // eslint-disable-next-line
  }, [fieldValues.fyYear, fieldValues.period, adminApprovalData]);

  const callGeneralParam = async (FY, period) => {
    //function to call feth API for given year and period.
    try {
      setLoader(true);
      const token = await authProvider.acquireTokenSilent({
        scopes: APIscope,
      });
      const idToken = token.accessToken;
      dispatch({
        type: "GET_GENERAL_PARAMETERS",
        payload: {
          token: idToken,
          financialYear: FY,
          timePeriod: period,
          pastData: getLastPhaseAndYear(fieldValues.period, fieldValues.fyYear),
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const callChangedUpdates = async (year, timePeriod) => {
    // function to call the highlight updates changes for review mode
    setLoader(true);
    dispatch({
      type: "GET_HIGHLIGHT_DATA",
      payload: {
        type: "GeneralParameters",
        financialYear: year,
        timePeriod: timePeriod,
      },
    });
  };

  useEffect(() => {
    //get generalprams data from API and assign it to states
    if (
      !getAllGeneralParamData.fetching &&
      getAllGeneralParamData.data &&
      getAllGeneralParamData.data.data !== undefined &&
      getAllGeneralParamData.data.data.length > 0
    ) {
      let completeData = getAllGeneralParamData.data.data[0];
      setFieldValues((prevState) => ({
        ...prevState,
        fyYear: "FY" + completeData.financialYear,
        period: completeData.timePeriod,
        active:
          adminApprovalData.isPreview ||
            completeData.validationStatus === "Submitted"
            ? completeData.isActivePhase
            : completeData.activePhase === completeData.timePeriod &&
            completeData.activePhaseYear === completeData.financialYear,

        assessmentStartDate: completeData.assessment_StartDate ? new Date(
          new Date(completeData.assessment_StartDate).toLocaleString("en-US", {
            //to get the UTC date and time without conversion.however, new Date() will attach browser GMT
            timeZone: "Etc/GMT",
          })
        ) : null,
        assessmentCompletionDate: completeData.assessment_EndDate ? new Date(
          new Date(completeData.assessment_EndDate).toLocaleString("en-US", {
            timeZone: "Etc/GMT",
          })
        ) : null,
        assessmentLockDate: completeData.leadReviewer_CompletionDate ? new Date(
          new Date(completeData.leadReviewer_CompletionDate).toLocaleString(
            "en-US",
            {
              timeZone: "Etc/GMT",
            }
          )
        ) : null,
        selectedTimezone: convertOffsetToProperFormat({
          value: completeData.timeZone,
          offset: completeData.timeZone_offset,
          label: completeData.timeZone_label,
        }),
        tenureReferenceDate: completeData.tenureCalculationReferenceDate
          ? new Date(
            new Date(
              completeData.tenureCalculationReferenceDate
            ).toLocaleString("en-US", { timeZone: "Etc/GMT" })
          )
          : null,
        welcomeEmailDateTime: completeData.welcomeEmailDate
          ? new Date(
            new Date(completeData.welcomeEmailDate).toLocaleString("en-US", {
              timeZone: "Etc/GMT",
            })
          )
          : null,
        firstReminderSelf: completeData.reminder_StartDate1
          ? new Date(
            new Date(completeData.reminder_StartDate1).toLocaleString(
              "en-US",
              {
                timeZone: "Etc/GMT",
              }
            )
          )
          : null,
        secondReminderSelf: completeData.reminder_StartDate2
          ? new Date(
            new Date(completeData.reminder_StartDate2).toLocaleString(
              "en-US",
              {
                timeZone: "Etc/GMT",
              }
            )
          )
          : null,
        thirdReminderSelf: completeData.reminder_StartDate3
          ? new Date(
            new Date(completeData.reminder_StartDate3).toLocaleString(
              "en-US",
              {
                timeZone: "Etc/GMT",
              }
            )
          )
          : null,
        fourthReminderSelf: completeData.reminder_StartDate4
          ? new Date(
            new Date(completeData.reminder_StartDate4).toLocaleString(
              "en-US",
              {
                timeZone: "Etc/GMT",
              }
            )
          )
          : null,
        fifthReminderSelf: completeData.reminder_StartDate5
          ? new Date(
            new Date(completeData.reminder_StartDate5).toLocaleString(
              "en-US",
              {
                timeZone: "Etc/GMT",
              }
            )
          )
          : null,
        firstReminderApproval: completeData.reviewer_ReminderDate1
          ? new Date(
            new Date(completeData.reviewer_ReminderDate1).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        secondReminderApproval: completeData.reviewer_ReminderDate2
          ? new Date(
            new Date(completeData.reviewer_ReminderDate2).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        thirdReminderApproval: completeData.reviewer_ReminderDate3
          ? new Date(
            new Date(completeData.reviewer_ReminderDate3).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        _id: completeData._id,
        activePeriodLockDate: completeData.activePhaseLockDate
          ? new Date(completeData.activePhaseLockDate)
          : null,
        pastPhaseLockDate: completeData.lastPhaseLockDate
          ? new Date(completeData.lastPhaseLockDate)
          : null,
        //..Year End Discussion Guide
        discussionGuideCompletionDate: completeData.discussionGuide_EndDate ? new Date(
          new Date(completeData.discussionGuide_EndDate).toLocaleString("en-US", {
            //to get the UTC date and time without conversion.however, new Date() will attach browser GMT
            timeZone: "Etc/GMT",
          })
        ) : null,
        discussionGuideLockDate: completeData.leadReviewer_DiscussionGuide_CompletionDate ? new Date(
          new Date(completeData.leadReviewer_DiscussionGuide_CompletionDate).toLocaleString("en-US", {
            //to get the UTC date and time without conversion.however, new Date() will attach browser GMT
            timeZone: "Etc/GMT",
          })
        ) : null,

        reviewerFinalCommentaryMDsLaunchDate: completeData.reviewer_FinalCommentary_MDs_LaunchDate ? new Date(
          new Date(completeData.reviewer_FinalCommentary_MDs_LaunchDate).toLocaleString("en-US", {
            //to get the UTC date and time without conversion.however, new Date() will attach browser GMT
            timeZone: "Etc/GMT",
          })
        ) : null,
        reviewerFinalCommentaryPartnersLaunchDate: completeData.reviewer_FinalCommentary_Partners_LaunchDate ? new Date(
          new Date(completeData.reviewer_FinalCommentary_Partners_LaunchDate).toLocaleString("en-US", {
            //to get the UTC date and time without conversion.however, new Date() will attach browser GMT
            timeZone: "Etc/GMT",
          })
        ) : null,

        firstDiscussionGuideReminderSelf: completeData.reminder_DiscussionGuide_StartDate1
          ? new Date(
            new Date(completeData.reminder_DiscussionGuide_StartDate1).toLocaleString(
              "en-US",
              {
                timeZone: "Etc/GMT",
              }
            )
          )
          : null,
        secondDiscussionGuideReminderSelf: completeData.reminder_DiscussionGuide_StartDate2
          ? new Date(
            new Date(completeData.reminder_DiscussionGuide_StartDate2).toLocaleString(
              "en-US",
              {
                timeZone: "Etc/GMT",
              }
            )
          )
          : null,
        thirdDiscussionGuideReminderSelf: completeData.reminder_DiscussionGuide_StartDate3
          ? new Date(
            new Date(completeData.reminder_DiscussionGuide_StartDate3).toLocaleString(
              "en-US",
              {
                timeZone: "Etc/GMT",
              }
            )
          )
          : null,

        // fourthDiscussionGuideReminderSelf: null,
        // fifthDiscussionGuideReminderSelf: null,
        firstDiscussionGuideReminderApproval: completeData.reviewer_DiscussionGuide_ReminderDate1
          ? new Date(
            new Date(completeData.reviewer_DiscussionGuide_ReminderDate1).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        secondDiscussionGuideReminderApproval: completeData.reviewer_DiscussionGuide_ReminderDate2
          ? new Date(
            new Date(completeData.reviewer_DiscussionGuide_ReminderDate2).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        thirdDiscussionGuideReminderApproval: completeData.reviewer_DiscussionGuide_ReminderDate3
          ? new Date(
            new Date(completeData.reviewer_DiscussionGuide_ReminderDate3).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        fourthDiscussionGuideReminderApproval: completeData.reviewer_DiscussionGuide_ReminderDate4
          ? new Date(
            new Date(completeData.reviewer_DiscussionGuide_ReminderDate4).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        fifthDiscussionGuideReminderApproval: completeData.reviewer_DiscussionGuide_ReminderDate5
          ? new Date(
            new Date(completeData.reviewer_DiscussionGuide_ReminderDate5).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        sixthDiscussionGuideReminderApproval: completeData.reviewer_DiscussionGuide_ReminderDate6
          ? new Date(
            new Date(completeData.reviewer_DiscussionGuide_ReminderDate6).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,

        finalCommentaryMDStartDate: completeData.finalCommentary_MD_StartDate
          ? new Date(
            new Date(completeData.finalCommentary_MD_StartDate).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        finalCommentaryMDEndDate: completeData.finalCommentary_MD_EndDate
          ? new Date(
            new Date(completeData.finalCommentary_MD_EndDate).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,

        finalCommentaryPartnerStartDate: completeData.finalCommentary_Partner_StartDate
          ? new Date(
            new Date(completeData.finalCommentary_Partner_StartDate).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        finalCommentaryPartnerEndDate: completeData.finalCommentary_Partner_EndDate
          ? new Date(
            new Date(completeData.finalCommentary_Partner_EndDate).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,

        firstFinalCommentaryMDsReminder: completeData.reviewer_FinalCommentary_MDs_ReminderDate1
          ? new Date(
            new Date(completeData.reviewer_FinalCommentary_MDs_ReminderDate1).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        secondFinalCommentaryMDsReminder: completeData.reviewer_FinalCommentary_MDs_ReminderDate2
          ? new Date(
            new Date(completeData.reviewer_FinalCommentary_MDs_ReminderDate2).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        thirdFinalCommentaryMDsReminder: completeData.reviewer_FinalCommentary_MDs_ReminderDate3
          ? new Date(
            new Date(completeData.reviewer_FinalCommentary_MDs_ReminderDate3).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,

        firstFinalCommentaryPartnersReminder: completeData.reviewer_FinalCommentary_Partners_ReminderDate1
          ? new Date(
            new Date(completeData.reviewer_FinalCommentary_Partners_ReminderDate1).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        secondFinalCommentaryPartnersReminder: completeData.reviewer_FinalCommentary_Partners_ReminderDate2
          ? new Date(
            new Date(completeData.reviewer_FinalCommentary_Partners_ReminderDate2).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        thirdFinalCommentaryPartnersReminder: completeData.reviewer_FinalCommentary_Partners_ReminderDate3
          ? new Date(
            new Date(completeData.reviewer_FinalCommentary_Partners_ReminderDate3).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        fourthFinalCommentaryPartnersReminder: completeData.reviewer_FinalCommentary_Partners_ReminderDate4
          ? new Date(
            new Date(completeData.reviewer_FinalCommentary_Partners_ReminderDate4).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,
        fifthFinalCommentaryPartnersReminder: completeData.reviewer_FinalCommentary_Partners_ReminderDate5
          ? new Date(
            new Date(completeData.reviewer_FinalCommentary_Partners_ReminderDate5).toLocaleString(
              "en-US",
              { timeZone: "Etc/GMT" }
            )
          )
          : null,

      }));
      setIsPreview(adminApprovalData.isPreview);
      setLastEditData(completeData);
      processValidationStatus(completeData.validationStatus);
      setKeyIndex(uuid());
      setLoader(false);
    }

    if (
      !getAllGeneralParamData.fetching &&
      getAllGeneralParamData.error &&
      getAllGeneralParamData.error.messageCode
    ) {
      setLoader(false);
      setDisableActiveForAPIError(true);
      setFieldValues((prevState) => ({
        ...prevState,
        // fyYear: "FY" + completeData.financialYear,
        // period: completeData.timePeriod,
        active: false,
        assessmentStartDate: null,
        assessmentCompletionDate: null,
        assessmentLockDate: null,
        selectedTimezone: myConsts.defaultSelectedTimeZone,
        tenureReferenceDate: null,
        welcomeEmailDateTime: null,
        firstReminderSelf: null,
        secondReminderSelf: null,
        thirdReminderSelf: null,
        fourthReminderSelf: null,
        fifthReminderSelf: null,
        firstReminderApproval: null,
        secondReminderApproval: null,
        thirdReminderApproval: null,
        activePeriodLockDate: null,
        pastPhaseLockDate: null,
        _id: "",
      }));
    }
  }, [getAllGeneralParamData]);

  const getLastPhaseAndYear = (currentPhase, currentYear) => {
    //to get just passed period/year
    const index = myConsts.TimePeriodsArray.indexOf(currentPhase);
    if (index > 0) {
      return {
        timePeriod: myConsts.TimePeriodsArray[index - 1],
        financialYear: currentYear.replace("FY", ""),
      };
    } else {
      return {
        timePeriod: myConsts.TimePeriodsArray[2],
        financialYear: Number.parseInt(currentYear.replace("FY", "")) - 1,
      };
    }
  };

  const getFormattedDate = (dateString) => {
    return dateString ? moment(dateString).format("DD-MMM-YYYY hh:mm A") : "";
  };

  const getFormattedHighLightDateData = (key) => {
    let data = highlightsData[key];
    if (data) {
      const dateString = new Date(
        new Date(data).toLocaleString("en-US", {
          timeZone: "Etc/GMT",
        })
      );
      data = moment(dateString).format("DD-MMM-YYYY hh:mm A");
    }
    return data ? data : "";
  };

  const successNotify = useCallback((isApprove) => {
    toast(
      <div className="success-text">
        <span className="icon-verified-outline icon"></span>
        <p>
          <span></span>
          <span>
            {isApprove
              ? "Successfully approved the request"
              : "Successfully rejected the request"}
          </span>
        </p>
      </div>,
      {
        position: "bottom-center",
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  useEffect(() => {
    //handel response from editgeneralparams API
    if (
      editGeneralParamData &&
      !editGeneralParamData.fetching &&
      editGeneralParamData.data &&
      editGeneralParamData.data.status === "success"
    ) {
      setLoader(false);
      callGeneralParam(
        fieldValues.fyYear.replace("FY", ""),
        fieldValues.period
      );
      myContext.setChangeTriggered(false);
      dispatch({
        type: "EDIT_GENERAL_PARAMETERS_RESET",
        payload: {},
      });
      myContext.setIsWarningPopup(false);
      toast.warn(
        <div className="toast-success-text">
          <i className="icon-verified-outline icon" />
          {adminAppState.submitClicked
            ? "Success: Submitted general parameters for review."
            : "Success: Your information is saved."}
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          closeButton: false,
        }
      );
      dispatch({
        type: "ENABLE_SUBMIT_BUTTON_RESET",
        payload: {},
      });
      const outClickCallback = isOutsideClick.data.callback;
      if (outClickCallback && Object.keys(outClickCallback).length > 0) {
        dispatch({
          type: "OUTSIDE_CLICK_ADMIN",
          payload: { flag: false, callback: {} },
        });
        outClickCallback.fn && outClickCallback.fn(outClickCallback.params);
        dispatch({
          type: "OUTSIDE_CLICK_ADMIN_RESET",
          payload: {},
        });
        myContext.setChangeTriggered(false);
        // setShowAlertSubmitPopup(false);
        myContext.setShowPopupAdmin(false);
      }
    }

    // eslint-disable-next-line
  }, [editGeneralParamData]);

  const submitData = () => {
    if (enableButton) {
      dispatch({
        type: "SUBMIT_BUTTON_CLICKED",
        payload: {},
      });
    }
  }

  const reviewRespond = () => {
    dispatch({
      type: "RESPOND_BUTTON_CLICKED",
      payload: {},
    });
  }

  return (
    <div className="adminGeneral">
      {loader && <Loader />}
      <SimpleBar
        style={{ maxHeight: "calc(100% - 40px)", height: "calc(100% - 40px)" }}>
        <div className="seperator">
          <div className="headingStyle">Select Year & Period</div>
          {!isPreview ? (
            <>
              <DropdownComponent
                label="FY Year"
                defaultValue={fieldValues.fyYear}
                required={true}
                selectOnChange={selectOnChange}
                varName="fyYear"
                list={[
                  "FY" + myContext.financialYear,
                  "FY" + (myContext.financialYear + 1),
                  "FY" + (myContext.financialYear + 2),
                ]}
                errorMessage={errorMessages}
                disabled={false}
              />
              <DropdownComponent
                label="Period"
                defaultValue={fieldValues.period}
                required={true}
                varName="period"
                selectOnChange={selectOnChange}
                list={myConsts.TimePeriodsArray}
                errorMessage={errorMessages}
                disabled={false}
              />
            </>
          ) : (
            <>
              <ChangesHistoryField
                key={"financialYear" + keyindex}
                identifier="financialYear"
                fieldLabel={"FY Year"}
                value={"FY " + adminApprovalData.previewParams.financialYear}
                oldValue={""}
              />
              <ChangesHistoryField
                key={"timePeriod" + keyindex}
                identifier="timePeriod"
                fieldLabel={"Period"}
                value={adminApprovalData.previewParams.timePeriod}
                oldValue={""}
              />{" "}
            </>
          )}

          <FormControl
            error={errorMessages.selectedTimezone !== ""}
            disabled={disableForm() || !isAssessmentApprovedyear()}
            onClick={(e) => {
              if (isPreview) {
                return;
              }
              if (!disableForm() && isAssessmentApprovedyear()) {
                if (
                  !checkIfActiveDisable() &&
                  (!fieldValues.active || madeActive)
                ) {
                  setMadeActive(true);
                  myContext.setChangeTriggered(true);
                  setFieldValues({
                    ...fieldValues,
                    active: !fieldValues.active,
                  });
                }
              } else if (!isAssessmentApprovedyear()) {
                myContext.setIsWarningPopup(true);
                toast.warn(
                  <div>
                    <i className="icon-warning-outline" /> Assessment content for the selected year is pending review. Please note that only after getting the approval you will be able to make the phase active.
                  </div>,
                  {
                    toastId: "assessment-warning",
                    position: "bottom-center",
                    transition: Flip,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
                myContext.setShowPopupAdmin(false);
              }
            }
            }>
            <div className={
              isPreview && ("isActivePhase" in highlightsData)
                ? "active-phase-wrapper highlight"
                : "active-phase-wrapper"
            }
            >
              <i
                style={
                  !disableForm() &&
                    !checkIfActiveDisable() &&
                    isAssessmentApprovedyear() &&
                    (!fieldValues.active || madeActive)
                    ? { cursor: "auto" }
                    : {
                      cursor: "no-drop",
                    }
                }
                className={
                  fieldValues && fieldValues.active
                    ? "icon-check-squere-filled"
                    : "icon-check-unchecked"
                }
              />
              <span
                style={{
                  cursor: "auto",
                  paddingLeft: "8px",
                  fontSize: "10px",
                }}>
                Active
              </span>
            </div>
          </FormControl>
        </div>
        <div className="seperator">
          <div className="headingStyle">Select Time Zone</div>
          {isPreview ? (
            <ChangesHistoryField
              size={"lg"}
              key={"selectedTimezone" + keyindex}
              identifier="selectedTimezone"
              fieldLabel={"Time zone"}
              value={
                fieldValues.selectedTimezone.label ||
                fieldValues.selectedTimezone.value
              }
              oldValue={
                highlightsData.timeZone_label || highlightsData.timeZone
              }
            />
          ) : (
            <FormControl
              error={errorMessages.selectedTimezone !== ""}
              disabled={disableForm()}>
              <TimezoneSelect
                value={
                  fieldValues && fieldValues.selectedTimezone
                    ? fieldValues.selectedTimezone
                    : ""
                }
                styles={customStyles}
                isDisabled={disableForm()}
                onChange={(value) => {
                  if (!disableForm()) {
                    myContext.setChangeTriggered(true);
                    value = convertOffsetToProperFormat(value);
                    setFieldValues({
                      ...fieldValues,
                      selectedTimezone: value,
                    });
                    setErrorMessages({
                      ...errorMessages,
                      selectedTimezone: "",
                    });
                  }
                }}
                placeholder="Select Time Zone*"
              />
              <FormHelperText
                classes={{
                  root: classes.helper,
                }}>
                {errorMessages.selectedTimezone}
              </FormHelperText>
            </FormControl>
          )}
        </div>
        {/* DISABLE ASSESSMENT:: FY24 YE */}
        {/* <div className="seperator">
          <div className="headingStyle">Select Assessment Date</div>
          {isPreview ? (
            <ChangesHistoryField
              key={"assessmentStartDate" + keyindex}
              identifier="assessmentStartDate"
              fieldLabel={"Assessment Start Datxes"}
              value={getFormattedDate(fieldValues.assessmentStartDate)}
              oldValue={getFormattedHighLightDateData("assessment_StartDate")}
            />
          ) : (
            <DateTimeCalender
              label="Assessment Start Date"
              disablePast={false}
              defaultValue={
                fieldValues && fieldValues.assessmentStartDate
                  ? fieldValues.assessmentStartDate
                  : null
              }
              required={true}
              dateTimeOnChange={dateTimeOnChange}
              disabled={
                disableForm() ||
                disableforPastDayTimes(fieldValues.assessmentStartDate)
              }
              varName="assessmentStartDate"
              errorMessage={errorMessages}
            />
          )}          
          {isPreview ? (
            <ChangesHistoryField
              key={"assessmentCompletionDate" + keyindex}
              identifier="assessmentCompletionDate"
              fieldLabel={"PPMD Assessment Completion Date"}
              value={
                fieldValues.assessmentCompletionDate &&
                getFormattedDate(fieldValues.assessmentCompletionDate)
              }
              oldValue={getFormattedHighLightDateData("assessment_EndDate")}
            />
          ) : (
            <DateTimeCalender
              label="PPMD Assessment Completion Date"
              disablePast={false}
              defaultValue={
                fieldValues && fieldValues.assessmentCompletionDate
                  ? fieldValues.assessmentCompletionDate
                  : null
              }
              required={true}
              dateTimeOnChange={dateTimeOnChange}
              disabled={
                disableForm() ||
                disableforPastDayTimes(fieldValues.assessmentCompletionDate)
              }
              varName="assessmentCompletionDate"
              errorMessage={errorMessages}
            />
          )}         
          {isPreview ? (
            <ChangesHistoryField
              key={"assessmentLockDate" + keyindex}
              identifier="assessmentLockDate"
              fieldLabel={"Total Assessment Lock Date"}
              value={getFormattedDate(fieldValues.assessmentLockDate)}
              oldValue={getFormattedHighLightDateData(
                "leadReviewer_CompletionDate"
              )}
            />
          ) : (
            <DateTimeCalender
              label="Total Assessment Lock Date"
              disablePast={false}
              defaultValue={
                fieldValues && fieldValues.assessmentLockDate
                  ? fieldValues.assessmentLockDate
                  : null
              }
              required={true}
              dateTimeOnChange={dateTimeOnChange}
              disabled={
                disableForm() ||
                disableforPastDayTimes(fieldValues.assessmentLockDate)
              }
              varName="assessmentLockDate"
              errorMessage={errorMessages}
            />
          )}
        </div> */}

        <div className="seperator">
          <div className="headingStyle">Select Email Reminder Date & Time</div>
          {isPreview ? (
            <ChangesHistoryField
              key={"welcomeEmailDateTime" + keyindex}
              identifier="welcomeEmailDateTime"
              fieldLabel={"Welcome Email Date & Time"}
              value={getFormattedDate(fieldValues.welcomeEmailDateTime)}
              oldValue={getFormattedHighLightDateData("welcomeEmailDate")}
            />
          ) : (
            <DateTimeCalender
              label="Welcome Email Date & Time"
              disablePast={false}
              defaultValue={
                fieldValues && fieldValues.welcomeEmailDateTime
                  ? fieldValues.welcomeEmailDateTime
                  : null
              }
              required={false}
              dateTimeOnChange={dateTimeOnChange}
              disabled={
                false ||
                disableForm() ||
                disableforPastDayTimes(fieldValues.welcomeEmailDateTime)
              }
              varName="welcomeEmailDateTime"
              minDate={
                fieldValues && fieldValues.assessmentStartDate
                  ? new Date().setDate(
                    new Date(fieldValues.assessmentStartDate).getDate() - 1
                  )
                  : new Date()
              }
              minDateMessage={
                "This date should be on or after Assesment start date"
              }
              errorMessage={errorMessages}
            />
          )}
        </div>
        {/* DISABLE ASSESSMENT:: FY24 YE */}
        {/* <div className="seperator">
          <div className="headingStyle">PPMD Self Assessment Reminders</div>
          {isPreview ? (
            <>
              <ChangesHistoryField
                key={"firstReminderSelf" + keyindex}
                identifier="firstReminderSelf"
                fieldLabel={"First Reminder Date & Time"}
                value={getFormattedDate(fieldValues.firstReminderSelf)}
                oldValue={getFormattedHighLightDateData("reminder_StartDate1")}
              />
              <ChangesHistoryField
                key={"secondReminderSelf" + keyindex}
                identifier="secondReminderSelf"
                fieldLabel={"Second Reminder Date & Time"}
                value={getFormattedDate(fieldValues.secondReminderSelf)}
                oldValue={getFormattedHighLightDateData("reminder_StartDate2")}
              />
              <ChangesHistoryField
                key={"thirdReminderSelf" + keyindex}
                identifier="thirdReminderSelf"
                fieldLabel={"Third Reminder Date & Time"}
                value={getFormattedDate(fieldValues.thirdReminderSelf)}
                oldValue={getFormattedHighLightDateData("reminder_StartDate3")}
              />
              <ChangesHistoryField
                key={"fourthReminderSelf" + keyindex}
                identifier="fourthReminderSelf"
                fieldLabel={"Fourth Reminder Date & Time"}
                value={getFormattedDate(fieldValues.fourthReminderSelf)}
                oldValue={getFormattedHighLightDateData("reminder_StartDate4")}
              />
              <div>
                <ChangesHistoryField
                  key={"fifthReminderSelf" + keyindex}
                  identifier="fifthReminderSelf"
                  fieldLabel={"Fifth Reminder Date & Time"}
                  value={getFormattedDate(fieldValues.fifthReminderSelf)}
                  oldValue={getFormattedHighLightDateData(
                    "reminder_StartDate5"
                  )}
                />
              </div>
            </>
          ) : (
            <>
              <DateTimeCalender
                label="First Reminder Date & Time"
                disablePast={false}
                defaultValue={
                  fieldValues && fieldValues.firstReminderSelf
                    ? fieldValues.firstReminderSelf
                    : null
                }
                disabled={
                  false ||
                  disableForm() ||
                  disableforPastDayTimes(fieldValues.firstReminderSelf)
                }
                required={false}
                dateTimeOnChange={dateTimeOnChange}
                varName="firstReminderSelf"
                errorMessage={errorMessages}
                minDate={
                  fieldValues && fieldValues.assessmentStartDate
                    ? new Date().setDate(
                      new Date(fieldValues.assessmentStartDate).getDate() + 1
                    )
                    : new Date()
                }
                maxDate={
                  fieldValues && fieldValues.assessmentLockDate
                    ? fieldValues.assessmentLockDate
                    : new Date()
                }
                minDateMessage={
                  "This date should be after assessment start date"
                }
                maxDateMessage={
                  "This date should be before assesment lock date "
                }
              />

              <DateTimeCalender
                label="Second Reminder Date & Time"
                disablePast={false}
                defaultValue={
                  fieldValues && fieldValues.secondReminderSelf
                    ? fieldValues.secondReminderSelf
                    : null
                }
                required={false}
                dateTimeOnChange={dateTimeOnChange}
                varName="secondReminderSelf"
                errorMessage={errorMessages}
                disabled={
                  !(fieldValues && fieldValues.firstReminderSelf) ||
                  disableForm() ||
                  disableforPastDayTimes(fieldValues.secondReminderSelf)
                }
                minDate={
                  fieldValues && fieldValues.firstReminderSelf
                    ? fieldValues.firstReminderSelf
                    : new Date()
                }
                maxDate={
                  fieldValues && fieldValues.assessmentLockDate
                    ? fieldValues.assessmentLockDate
                    : new Date()
                }
                minDateMessage={"This date should be after first reminder date"}
                maxDateMessage={
                  "This date should be before assesment lock date "
                }
              />

              <DateTimeCalender
                disablePast={false}
                label="Third Reminder Date & Time"
                defaultValue={
                  fieldValues && fieldValues.thirdReminderSelf
                    ? fieldValues.thirdReminderSelf
                    : null
                }
                dateTimeOnChange={dateTimeOnChange}
                required={false}
                varName="thirdReminderSelf"
                errorMessage={errorMessages}
                disabled={
                  !(fieldValues && fieldValues.secondReminderSelf) ||
                  disableForm() ||
                  disableforPastDayTimes(fieldValues.thirdReminderSelf)
                }
                minDate={
                  fieldValues && fieldValues.secondReminderSelf
                    ? new Date().setDate(
                      new Date(fieldValues.secondReminderSelf).getDate() + 1
                    )
                    : new Date()
                }
                maxDate={
                  fieldValues && fieldValues.assessmentLockDate
                    ? fieldValues.assessmentLockDate
                    : new Date()
                }
                minDateMessage={
                  "This date should be after second reminder date"
                }
                maxDateMessage={
                  "This date should be before assesment lock date "
                }
              />
              <DateTimeCalender
                disablePast={false}
                label="Fourth Reminder Date & Time"
                defaultValue={
                  fieldValues && fieldValues.fourthReminderSelf
                    ? fieldValues.fourthReminderSelf
                    : null
                }
                dateTimeOnChange={dateTimeOnChange}
                required={false}
                varName="fourthReminderSelf"
                errorMessage={errorMessages}
                disabled={
                  !(fieldValues && fieldValues.thirdReminderSelf) ||
                  disableForm() ||
                  disableforPastDayTimes(fieldValues.fourthReminderSelf)
                }
                minDate={
                  fieldValues && fieldValues.thirdReminderSelf
                    ? new Date().setDate(
                      new Date(fieldValues.thirdReminderSelf).getDate() + 1
                    )
                    : new Date()
                }
                maxDate={
                  fieldValues && fieldValues.assessmentLockDate
                    ? fieldValues.assessmentLockDate
                    : new Date()
                }
                minDateMessage={"This date should be after third reminder date"}
                maxDateMessage={
                  "This date should be before assesment lock date "
                }
              />
              <div>
                <DateTimeCalender
                  disablePast={false}
                  label="Fifth Reminder Date & Time"
                  defaultValue={
                    fieldValues && fieldValues.fifthReminderSelf
                      ? fieldValues.fifthReminderSelf
                      : null
                  }
                  dateTimeOnChange={dateTimeOnChange}
                  required={false}
                  varName="fifthReminderSelf"
                  errorMessage={errorMessages}
                  disabled={
                    !(fieldValues && fieldValues.fourthReminderSelf) ||
                    disableForm() ||
                    disableforPastDayTimes(fieldValues.fifthReminderSelf)
                  }
                  minDate={
                    fieldValues && fieldValues.fourthReminderSelf
                      ? new Date().setDate(
                        new Date(fieldValues.fourthReminderSelf).getDate() + 1
                      )
                      : new Date()
                  }
                  maxDate={
                    fieldValues && fieldValues.assessmentLockDate
                      ? fieldValues.assessmentLockDate
                      : new Date()
                  }
                  minDateMessage={
                    "This date should be after fourth reminder date"
                  }
                  maxDateMessage={
                    "This date should be before assesment lock date "
                  }
                />
              </div>
            </>
          )}
        </div> */}

        {/* <div className="seperator">
          <div className="headingStyle">Lead Reviewer - Approval Reminder</div>
          {isPreview ? (
            <>
              <ChangesHistoryField
                key={"firstReminderApproval" + keyindex}
                identifier="firstReminderApproval"
                fieldLabel={"First Reminder Date & Time"}
                value={getFormattedDate(fieldValues.firstReminderApproval)}
                oldValue={getFormattedHighLightDateData(
                  "reviewer_ReminderDate1"
                )}
              />
              <ChangesHistoryField
                key={"secondReminderApproval" + keyindex}
                identifier="secondReminderApproval"
                fieldLabel={"Second Reminder Date & Time"}
                value={getFormattedDate(fieldValues.secondReminderApproval)}
                oldValue={getFormattedHighLightDateData(
                  "reviewer_ReminderDate2"
                )}
              />
              <ChangesHistoryField
                key={"thirdReminderApproval" + keyindex}
                identifier="thirdReminderApproval"
                fieldLabel={"Third Reminder Date & Time"}
                value={getFormattedDate(fieldValues.thirdReminderApproval)}
                oldValue={getFormattedHighLightDateData(
                  "reviewer_ReminderDate3"
                )}
              />
            </>
          ) : (
            <>
              <DateTimeCalender
                disablePast={false}
                label="First Reminder Date & Time"
                defaultValue={
                  fieldValues && fieldValues.firstReminderApproval
                    ? fieldValues.firstReminderApproval
                    : null
                }
                dateTimeOnChange={dateTimeOnChange}
                required={false}
                varName="firstReminderApproval"
                disabled={
                  disableForm() ||
                  disableforPastDayTimes(fieldValues.firstReminderApproval)
                }
                errorMessage={errorMessages}
                minDate={
                  fieldValues && fieldValues.assessmentCompletionDate
                    ? new Date().setDate(
                      new Date(
                        fieldValues.assessmentCompletionDate
                      ).getDate() + 1
                    )
                    : new Date()
                }
                maxDate={
                  fieldValues && fieldValues.assessmentLockDate
                    ? fieldValues.assessmentLockDate
                    : new Date()
                }
                minDateMessage={
                  "This date should be after assesment completion date"
                }
                maxDateMessage={
                  "This date should be before assesment lock date "
                }
              />
              <DateTimeCalender
                disablePast={false}
                label="Second Reminder Date & Time"
                defaultValue={
                  fieldValues && fieldValues.secondReminderApproval
                    ? fieldValues.secondReminderApproval
                    : null
                }
                dateTimeOnChange={dateTimeOnChange}
                required={false}
                varName="secondReminderApproval"
                errorMessage={errorMessages}
                minDate={
                  fieldValues && fieldValues.firstReminderApproval
                    ? new Date().setDate(
                      new Date(fieldValues.firstReminderApproval).getDate() +
                      1
                    )
                    : new Date()
                }
                maxDate={
                  fieldValues && fieldValues.assessmentLockDate
                    ? fieldValues.assessmentLockDate
                    : new Date()
                }
                minDateMessage={"This date should be after first reminder date"}
                maxDateMessage={
                  "This date should be before assesment lock date "
                }
                disabled={
                  !(fieldValues && fieldValues.firstReminderApproval) ||
                  disableForm() ||
                  disableforPastDayTimes(fieldValues.secondReminderApproval)
                }
              />

              <DateTimeCalender
                disablePast={false}
                label="Third Reminder Date & Time"
                defaultValue={
                  fieldValues && fieldValues.thirdReminderApproval
                    ? fieldValues.thirdReminderApproval
                    : null
                }
                dateTimeOnChange={dateTimeOnChange}
                required={false}
                varName="thirdReminderApproval"
                errorMessage={errorMessages}
                minDate={
                  fieldValues && fieldValues.secondReminderApproval
                    ? new Date(
                      new Date().setDate(
                        new Date(
                          fieldValues.secondReminderApproval
                        ).getDate() + 1
                      )
                    )
                    : new Date()
                }
                maxDate={
                  fieldValues && fieldValues.assessmentLockDate
                    ? fieldValues.assessmentLockDate
                    : new Date()
                }
                minDateMessage={
                  "This date should be after second reminder date"
                }
                maxDateMessage={
                  "This date should be before assesment lock date "
                }
                disabled={
                  !(fieldValues && fieldValues.secondReminderApproval) ||
                  disableForm() ||
                  disableforPastDayTimes(fieldValues.thirdReminderApproval)
                }
              />
            </>
          )}
        </div> */}

        {
          fieldValues.period &&
          fieldValues.period.toLowerCase() === "year-end" && (
            <div className="seperator">
              <div className="headingStyle" style={{ color: "red", fontWeight: "bold", textDecoration: "underline" }}>Discussion Guide:</div>
              <div className="headingStyle">Select Discussion Guide Dates</div>
              {/* {isPreview ? (
                <ChangesHistoryField
                  key={"discussionGuideCompletionDate" + keyindex}
                  identifier="discussionGuideCompletionDate"
                  fieldLabel={"PPMD Discussion Guide Completion Date"}
                  value={
                    fieldValues.discussionGuideCompletionDate &&
                    getFormattedDate(fieldValues.discussionGuideCompletionDate)
                  }
                  oldValue={getFormattedHighLightDateData("discussionGuide_EndDate")}
                />
              ) : (
                <DateTimeCalender
                  label="PPMD Discussion Guide Completion Date"
                  disablePast={false}
                  defaultValue={
                    fieldValues && fieldValues.discussionGuideCompletionDate
                      ? fieldValues.discussionGuideCompletionDate
                      : null
                  }
                  required={true}
                  dateTimeOnChange={dateTimeOnChange}
                  // disabled={
                  //   disableForm() ||
                  //   disableforPastDayTimes(fieldValues.discussionGuideCompletionDate)
                  // }
                  varName="discussionGuideCompletionDate"
                  errorMessage={errorMessages}
                />
              )} */}
              {isPreview ? (
                <ChangesHistoryField
                  key={"discussionGuideLockDate" + keyindex}
                  identifier="discussionGuideLockDate"
                  fieldLabel={"Total Discussion Guide Lock Date"}
                  value={getFormattedDate(fieldValues.discussionGuideLockDate)}
                  oldValue={getFormattedHighLightDateData(
                    "leadReviewer_DiscussionGuide_CompletionDate"
                  )}
                />
              ) : (
                <DateTimeCalender
                  label="Total Discussion Guide Lock Date"
                  disablePast={false}
                  defaultValue={
                    fieldValues && fieldValues.discussionGuideLockDate
                      ? fieldValues.discussionGuideLockDate
                      : null
                  }
                  required={true}
                  dateTimeOnChange={dateTimeOnChange}
                  // disabled={
                  //   disableForm() ||
                  //   disableforPastDayTimes(fieldValues.discussionGuideLockDate)
                  // }
                  varName="discussionGuideLockDate"
                  errorMessage={errorMessages}
                />
              )}
            </div>
          )
        }

        {/* {
          fieldValues.period &&
          fieldValues.period.toLowerCase() === "year-end" && (
            <div className="seperator">
              <div className="headingStyle">PPMD Self Discussion Guide Reminders</div>
              {isPreview ? (
                <>
                  <ChangesHistoryField
                    key={"firstDiscussionGuideReminderSelf" + keyindex}
                    identifier="firstDiscussionGuideReminderSelf"
                    fieldLabel={"First Discussion Guide Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.firstDiscussionGuideReminderSelf)}
                    oldValue={getFormattedHighLightDateData("reminder_DiscussionGuide_StartDate1")}
                  />
                  <ChangesHistoryField
                    key={"secondDiscussionGuideReminderSelf" + keyindex}
                    identifier="secondDiscussionGuideReminderSelf"
                    fieldLabel={"Second Discussion Guide Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.secondDiscussionGuideReminderSelf)}
                    oldValue={getFormattedHighLightDateData("reminder_DiscussionGuide_StartDate2")}
                  />
                  <ChangesHistoryField
                    key={"thirdDiscussionGuideReminderSelf" + keyindex}
                    identifier="thirdDiscussionGuideReminderSelf"
                    fieldLabel={"Third Discussion Guide Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.thirdDiscussionGuideReminderSelf)}
                    oldValue={getFormattedHighLightDateData("reminder_DiscussionGuide_StartDate3")}
                  />
                </>
              ) : (
                <>
                  <DateTimeCalender
                    label="First Discussion Guide Reminder Date & Time"
                    disablePast={false}
                    defaultValue={
                      fieldValues && fieldValues.firstDiscussionGuideReminderSelf
                        ? fieldValues.firstDiscussionGuideReminderSelf
                        : null
                    }
                    disabled={
                      false ||
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.firstDiscussionGuideReminderSelf)
                    }
                    required={false}
                    dateTimeOnChange={dateTimeOnChange}
                    varName="firstDiscussionGuideReminderSelf"
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.assessmentStartDate
                        ? new Date().setDate(
                          new Date(fieldValues.assessmentStartDate).getDate() + 1
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={
                      "This date should be after assessment start date"
                    }
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                  />

                  <DateTimeCalender
                    label="Second Discussion Guide Reminder Date & Time"
                    disablePast={false}
                    defaultValue={
                      fieldValues && fieldValues.secondDiscussionGuideReminderSelf
                        ? fieldValues.secondDiscussionGuideReminderSelf
                        : null
                    }
                    required={false}
                    dateTimeOnChange={dateTimeOnChange}
                    varName="secondDiscussionGuideReminderSelf"
                    errorMessage={errorMessages}
                    disabled={
                      !(fieldValues && fieldValues.firstDiscussionGuideReminderSelf) ||
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.secondDiscussionGuideReminderSelf)
                    }
                    minDate={
                      fieldValues && fieldValues.firstDiscussionGuideReminderSelf
                        ? fieldValues.firstDiscussionGuideReminderSelf
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={"This date should be after first reminder date"}
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                  />

                  <DateTimeCalender
                    disablePast={false}
                    label="Third Discussion Guide Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.thirdDiscussionGuideReminderSelf
                        ? fieldValues.thirdDiscussionGuideReminderSelf
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="thirdDiscussionGuideReminderSelf"
                    errorMessage={errorMessages}
                    disabled={
                      !(fieldValues && fieldValues.secondDiscussionGuideReminderSelf) ||
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.thirdDiscussionGuideReminderSelf)
                    }
                    minDate={
                      fieldValues && fieldValues.secondDiscussionGuideReminderSelf
                        ? new Date().setDate(
                          new Date(fieldValues.secondDiscussionGuideReminderSelf).getDate() + 1
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={
                      "This date should be after second reminder date"
                    }
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                  />
                </>
              )}
            </div>
          )
        } */}

        {
          fieldValues.period &&
          fieldValues.period.toLowerCase() === "year-end" && (
            <div className="seperator">
              <div className="headingStyle">Lead Reviewer - Discussion Guide Reminder</div>
              {isPreview ? (
                <>
                  <ChangesHistoryField
                    key={"firstDiscussionGuideReminderApproval" + keyindex}
                    identifier="firstDiscussionGuideReminderApproval"
                    fieldLabel={"First Discussion Guide Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.firstDiscussionGuideReminderApproval)}
                    oldValue={getFormattedHighLightDateData(
                      "reviewer_DiscussionGuide_ReminderDate1"
                    )}
                  />
                  <ChangesHistoryField
                    key={"secondDiscussionGuideReminderApproval" + keyindex}
                    identifier="secondDiscussionGuideReminderApproval"
                    fieldLabel={"Second Discussion Guide Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.secondDiscussionGuideReminderApproval)}
                    oldValue={getFormattedHighLightDateData(
                      "reviewer_DiscussionGuide_ReminderDate2"
                    )}
                  />
                  <ChangesHistoryField
                    key={"thirdDiscussionGuideReminderApproval" + keyindex}
                    identifier="thirdDiscussionGuideReminderApproval"
                    fieldLabel={"Third Discussion Guide Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.thirdDiscussionGuideReminderApproval)}
                    oldValue={getFormattedHighLightDateData(
                      "reviewer_DiscussionGuide_ReminderDate3"
                    )}
                  />
                  <ChangesHistoryField
                    key={"fourthDiscussionGuideReminderApproval" + keyindex}
                    identifier="fourthDiscussionGuideReminderApproval"
                    fieldLabel={"Fourth Discussion Guide Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.fourthDiscussionGuideReminderApproval)}
                    oldValue={getFormattedHighLightDateData(
                      "reviewer_DiscussionGuide_ReminderDate4"
                    )}
                  />
                  <ChangesHistoryField
                    key={"fifthDiscussionGuideReminderApproval" + keyindex}
                    identifier="fifthDiscussionGuideReminderApproval"
                    fieldLabel={"Fifth Discussion Guide Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.fifthDiscussionGuideReminderApproval)}
                    oldValue={getFormattedHighLightDateData(
                      "reviewer_DiscussionGuide_ReminderDate5"
                    )}
                  />
                  <ChangesHistoryField
                    key={"sixthDiscussionGuideReminderApproval" + keyindex}
                    identifier="sixthDiscussionGuideReminderApproval"
                    fieldLabel={"Sixth Discussion Guide Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.sixthDiscussionGuideReminderApproval)}
                    oldValue={getFormattedHighLightDateData(
                      "reviewer_DiscussionGuide_ReminderDate6"
                    )}
                  />
                </>
              ) : (
                <>
                  <DateTimeCalender
                    disablePast={false}
                    label="First Discussion Guide Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.firstDiscussionGuideReminderApproval
                        ? fieldValues.firstDiscussionGuideReminderApproval
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="firstDiscussionGuideReminderApproval"
                    disabled={
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.firstDiscussionGuideReminderApproval)
                    }
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.assessmentCompletionDate
                        ? new Date().setDate(
                          new Date(
                            fieldValues.assessmentCompletionDate
                          ).getDate() + 1
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={
                      "This date should be after assesment completion date"
                    }
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                  />
                  <DateTimeCalender
                    disablePast={false}
                    label="Second Discussion Guide Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.secondDiscussionGuideReminderApproval
                        ? fieldValues.secondDiscussionGuideReminderApproval
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="secondDiscussionGuideReminderApproval"
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.firstDiscussionGuideReminderApproval
                        ? new Date().setDate(
                          new Date(fieldValues.firstDiscussionGuideReminderApproval).getDate() +
                          1
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={"This date should be after first reminder date"}
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                    disabled={
                      !(fieldValues && fieldValues.firstDiscussionGuideReminderApproval) ||
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.secondDiscussionGuideReminderApproval)
                    }
                  />

                  <DateTimeCalender
                    disablePast={false}
                    label="Third Discussion Guide Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.thirdDiscussionGuideReminderApproval
                        ? fieldValues.thirdDiscussionGuideReminderApproval
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="thirdDiscussionGuideReminderApproval"
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.secondDiscussionGuideReminderApproval
                        ? new Date(
                          new Date().setDate(
                            new Date(
                              fieldValues.secondDiscussionGuideReminderApproval
                            ).getDate() + 1
                          )
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={
                      "This date should be after second reminder date"
                    }
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                    disabled={
                      !(fieldValues && fieldValues.secondDiscussionGuideReminderApproval) ||
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.thirdDiscussionGuideReminderApproval)
                    }
                  />

                  <DateTimeCalender
                    disablePast={false}
                    label="Fourth Discussion Guide Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.fourthDiscussionGuideReminderApproval
                        ? fieldValues.fourthDiscussionGuideReminderApproval
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="fourthDiscussionGuideReminderApproval"
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.thirdDiscussionGuideReminderApproval
                        ? new Date(
                          new Date().setDate(
                            new Date(
                              fieldValues.thirdDiscussionGuideReminderApproval
                            ).getDate() + 1
                          )
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={
                      "This date should be after second reminder date"
                    }
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                    disabled={
                      !(fieldValues && fieldValues.thirdDiscussionGuideReminderApproval) ||
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.fourthDiscussionGuideReminderApproval)
                    }
                  />

                  <DateTimeCalender
                    disablePast={false}
                    label="Fifth Discussion Guide Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.fifthDiscussionGuideReminderApproval
                        ? fieldValues.fifthDiscussionGuideReminderApproval
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="fifthDiscussionGuideReminderApproval"
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.fourthDiscussionGuideReminderApproval
                        ? new Date(
                          new Date().setDate(
                            new Date(
                              fieldValues.fourthDiscussionGuideReminderApproval
                            ).getDate() + 1
                          )
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={
                      "This date should be after second reminder date"
                    }
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                    disabled={
                      !(fieldValues && fieldValues.fourthDiscussionGuideReminderApproval) ||
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.fifthDiscussionGuideReminderApproval)
                    }
                  />

                  <DateTimeCalender
                    disablePast={false}
                    label="Sixth Discussion Guide Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.sixthDiscussionGuideReminderApproval
                        ? fieldValues.sixthDiscussionGuideReminderApproval
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="sixthDiscussionGuideReminderApproval"
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.fifthDiscussionGuideReminderApproval
                        ? new Date(
                          new Date().setDate(
                            new Date(
                              fieldValues.fifthDiscussionGuideReminderApproval
                            ).getDate() + 1
                          )
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={
                      "This date should be after second reminder date"
                    }
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                    disabled={
                      !(fieldValues && fieldValues.fifthDiscussionGuideReminderApproval) ||
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.sixthDiscussionGuideReminderApproval)
                    }
                  />
                </>
              )}
            </div>
          )
        }
        {
          fieldValues.period &&
          fieldValues.period.toLowerCase() === "year-end" && (
            <div className="seperator">
              <div className="headingStyle" style={{ color: "red", fontWeight: "bold", textDecoration: "underline" }}>EDs/MDs Lead Reviewer:</div>
              <div className="headingStyle">Select FINAL Finalization Form Commentary Dates</div>
              {isPreview ? (
                <ChangesHistoryField
                  key={"finalCommentaryMDStartDate" + keyindex}
                  identifier="finalCommentaryMDStartDate"
                  fieldLabel={"FINAL Commentary Start Date"}
                  value={
                    fieldValues.finalCommentaryMDStartDate &&
                    getFormattedDate(fieldValues.finalCommentaryMDStartDate)
                  }
                  oldValue={getFormattedHighLightDateData("finalCommentary_MD_StartDate")}
                />
              ) : (
                <DateTimeCalender
                  label="FINAL Commentary Start Date"
                  disablePast={false}
                  defaultValue={
                    fieldValues && fieldValues.finalCommentaryMDStartDate
                      ? fieldValues.finalCommentaryMDStartDate
                      : null
                  }
                  required={true}
                  dateTimeOnChange={dateTimeOnChange}
                  // disabled={
                  //   disableForm() ||
                  //   disableforPastDayTimes(fieldValues.discussionGuideCompletionDate)
                  // }
                  varName="finalCommentaryMDStartDate"
                  errorMessage={errorMessages}
                />
              )}
              {isPreview ? (
                <ChangesHistoryField
                  key={"finalCommentaryMDEndDate" + keyindex}
                  identifier="finalCommentaryMDEndDate"
                  fieldLabel={"FINAL Commentary End Date"}
                  value={
                    fieldValues.finalCommentaryMDEndDate &&
                    getFormattedDate(fieldValues.finalCommentaryMDEndDate)
                  }
                  oldValue={getFormattedHighLightDateData("finalCommentary_MD_EndDate")}
                />
              ) : (
                <DateTimeCalender
                  label="FINAL Commentary End Date"
                  disablePast={false}
                  defaultValue={
                    fieldValues && fieldValues.finalCommentaryMDEndDate
                      ? fieldValues.finalCommentaryMDEndDate
                      : null
                  }
                  required={true}
                  dateTimeOnChange={dateTimeOnChange}
                  // disabled={
                  //   disableForm() ||
                  //   disableforPastDayTimes(fieldValues.discussionGuideCompletionDate)
                  // }
                  varName="finalCommentaryMDEndDate"
                  errorMessage={errorMessages}
                />
              )}

              <div className="headingStyle" style={{ marginTop: "15px" }}>Launch Email Date</div>
              {isPreview ? (
                <ChangesHistoryField
                  key={"reviewerFinalCommentaryMDsLaunchDate" + keyindex}
                  identifier="reviewerFinalCommentaryMDsLaunchDate"
                  fieldLabel={"Launch Email Date to MD LEAD Reviewers to complete finalization commentary"}
                  value={getFormattedDate(fieldValues.reviewerFinalCommentaryMDsLaunchDate)}
                  oldValue={getFormattedHighLightDateData(
                    "reviewer_FinalCommentary_MDs_LaunchDate"
                  )}
                />
              ) : (
                <DateTimeCalender
                  label="Launch Email Date to MD LEAD Reviewers to complete finalization commentary"
                  disablePast={false}
                  defaultValue={
                    fieldValues && fieldValues.reviewerFinalCommentaryMDsLaunchDate
                      ? fieldValues.reviewerFinalCommentaryMDsLaunchDate
                      : null
                  }
                  required={true}
                  dateTimeOnChange={dateTimeOnChange}
                  // disabled={
                  //   disableForm() ||
                  //   disableforPastDayTimes(fieldValues.discussionGuideLockDate)
                  // }
                  varName="reviewerFinalCommentaryMDsLaunchDate"
                  errorMessage={errorMessages}
                />
              )}

              <div className="headingStyle" style={{ marginTop: "15px" }}>Reminder - FINAL Finalization Form Commentary</div>
              {isPreview ? (
                <>
                  <ChangesHistoryField
                    key={"firstFinalCommentaryMDsReminder" + keyindex}
                    identifier="firstFinalCommentaryMDsReminder"
                    fieldLabel={"First Final Commentary Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.firstFinalCommentaryMDsReminder)}
                    oldValue={getFormattedHighLightDateData(
                      "reviewer_FinalCommentary_MDs_ReminderDate1"
                    )}
                  />
                  <ChangesHistoryField
                    key={"secondFinalCommentaryMDsReminder" + keyindex}
                    identifier="secondFinalCommentaryMDsReminder"
                    fieldLabel={"Second Final Commentary Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.secondFinalCommentaryMDsReminder)}
                    oldValue={getFormattedHighLightDateData(
                      "reviewer_FinalCommentary_MDs_ReminderDate2"
                    )}
                  />
                  <ChangesHistoryField
                    key={"thirdFinalCommentaryMDsReminder" + keyindex}
                    identifier="thirdFinalCommentaryMDsReminder"
                    fieldLabel={"Third Final Commentary Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.thirdFinalCommentaryMDsReminder)}
                    oldValue={getFormattedHighLightDateData(
                      "reviewer_FinalCommentary_MDs_ReminderDate3"
                    )}
                  />
                </>
              ) : (
                <>
                  <DateTimeCalender
                    disablePast={false}
                    label="First Final Commentary Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.firstFinalCommentaryMDsReminder
                        ? fieldValues.firstFinalCommentaryMDsReminder
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="firstFinalCommentaryMDsReminder"
                    disabled={
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.firstFinalCommentaryMDsReminder)
                    }
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.assessmentCompletionDate
                        ? new Date().setDate(
                          new Date(
                            fieldValues.assessmentCompletionDate
                          ).getDate() + 1
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={
                      "This date should be after assesment completion date"
                    }
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                  />
                  <DateTimeCalender
                    disablePast={false}
                    label="Second Final Commentary Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.secondFinalCommentaryMDsReminder
                        ? fieldValues.secondFinalCommentaryMDsReminder
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="secondFinalCommentaryMDsReminder"
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.firstFinalCommentaryMDsReminder
                        ? new Date().setDate(
                          new Date(fieldValues.firstFinalCommentaryMDsReminder).getDate() +
                          1
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={"This date should be after first reminder date"}
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                    disabled={
                      !(fieldValues && fieldValues.firstFinalCommentaryMDsReminder) ||
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.secondFinalCommentaryMDsReminder)
                    }
                  />

                  <DateTimeCalender
                    disablePast={false}
                    label="Third Final Commentary Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.thirdFinalCommentaryMDsReminder
                        ? fieldValues.thirdFinalCommentaryMDsReminder
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="thirdFinalCommentaryMDsReminder"
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.secondFinalCommentaryMDsReminder
                        ? new Date(
                          new Date().setDate(
                            new Date(
                              fieldValues.secondFinalCommentaryMDsReminder
                            ).getDate() + 1
                          )
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={
                      "This date should be after second reminder date"
                    }
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                    disabled={
                      !(fieldValues && fieldValues.secondFinalCommentaryMDsReminder) ||
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.thirdFinalCommentaryMDsReminder)
                    }
                  />
                </>
              )}
            </div>
          )
        }
        {
          fieldValues.period &&
          fieldValues.period.toLowerCase() === "year-end" && (
            <div className="seperator">
              <div className="headingStyle" style={{ color: "red", fontWeight: "bold", textDecoration: "underline" }}>Partners Lead Reviewer:</div>
              <div className="headingStyle">Select FINAL Finalization Form Commentary Dates</div>
              {isPreview ? (
                <ChangesHistoryField
                  key={"finalCommentaryPartnerStartDate" + keyindex}
                  identifier="finalCommentaryPartnerStartDate"
                  fieldLabel={"FINAL Commentary Start Date"}
                  value={
                    fieldValues.finalCommentaryPartnerStartDate &&
                    getFormattedDate(fieldValues.finalCommentaryPartnerStartDate)
                  }
                  oldValue={getFormattedHighLightDateData("finalCommentary_Partner_StartDate")}
                />
              ) : (
                <DateTimeCalender
                  label="FINAL Commentary Start Date"
                  disablePast={false}
                  defaultValue={
                    fieldValues && fieldValues.finalCommentaryPartnerStartDate
                      ? fieldValues.finalCommentaryPartnerStartDate
                      : null
                  }
                  required={true}
                  dateTimeOnChange={dateTimeOnChange}
                  // disabled={
                  //   disableForm() ||
                  //   disableforPastDayTimes(fieldValues.discussionGuideCompletionDate)
                  // }
                  varName="finalCommentaryPartnerStartDate"
                  errorMessage={errorMessages}
                />
              )}
              {isPreview ? (
                <ChangesHistoryField
                  key={"finalCommentaryPartnerEndDate" + keyindex}
                  identifier="finalCommentaryPartnerEndDate"
                  fieldLabel={"FINAL Commentary End Date"}
                  value={
                    fieldValues.finalCommentaryPartnerEndDate &&
                    getFormattedDate(fieldValues.finalCommentaryPartnerEndDate)
                  }
                  oldValue={getFormattedHighLightDateData("finalCommentary_Partner_EndDate")}
                />
              ) : (
                <DateTimeCalender
                  label="FINAL Commentary End Date"
                  disablePast={false}
                  defaultValue={
                    fieldValues && fieldValues.finalCommentaryPartnerEndDate
                      ? fieldValues.finalCommentaryPartnerEndDate
                      : null
                  }
                  required={true}
                  dateTimeOnChange={dateTimeOnChange}
                  // disabled={
                  //   disableForm() ||
                  //   disableforPastDayTimes(fieldValues.discussionGuideCompletionDate)
                  // }
                  varName="finalCommentaryPartnerEndDate"
                  errorMessage={errorMessages}
                />
              )}

              <div className="headingStyle" style={{ marginTop: "15px" }}>Launch Email Date</div>
              {isPreview ? (
                <ChangesHistoryField
                  key={"reviewerFinalCommentaryPartnersLaunchDate" + keyindex}
                  identifier="reviewerFinalCommentaryPartnersLaunchDate"
                  fieldLabel={"Launch Email to Partner LEAD Reviewers to complete finalization commentary"}
                  value={getFormattedDate(fieldValues.reviewerFinalCommentaryPartnersLaunchDate)}
                  oldValue={getFormattedHighLightDateData(
                    "reviewer_FinalCommentary_Partners_LaunchDate"
                  )}
                />
              ) : (
                <DateTimeCalender
                  label="Launch Email to Partner LEAD Reviewers to complete finalization commentary"
                  disablePast={false}
                  defaultValue={
                    fieldValues && fieldValues.reviewerFinalCommentaryPartnersLaunchDate
                      ? fieldValues.reviewerFinalCommentaryPartnersLaunchDate
                      : null
                  }
                  required={true}
                  dateTimeOnChange={dateTimeOnChange}
                  // disabled={
                  //   disableForm() ||
                  //   disableforPastDayTimes(fieldValues.discussionGuideLockDate)
                  // }
                  varName="reviewerFinalCommentaryPartnersLaunchDate"
                  errorMessage={errorMessages}
                />
              )}

              <div className="headingStyle" style={{ marginTop: "15px" }}>Reminder - FINAL Finalization Form Commentary</div>
              {isPreview ? (
                <>
                  <ChangesHistoryField
                    key={"firstFinalCommentaryPartnersReminder" + keyindex}
                    identifier="firstFinalCommentaryPartnersReminder"
                    fieldLabel={"First Final Commentary Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.firstFinalCommentaryPartnersReminder)}
                    oldValue={getFormattedHighLightDateData(
                      "reviewer_FinalCommentary_Partners_ReminderDate1"
                    )}
                  />
                  <ChangesHistoryField
                    key={"secondFinalCommentaryPartnersReminder" + keyindex}
                    identifier="secondFinalCommentaryPartnersReminder"
                    fieldLabel={"Second Final Commentary Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.secondFinalCommentaryPartnersReminder)}
                    oldValue={getFormattedHighLightDateData(
                      "reviewer_FinalCommentary_Partners_ReminderDate2"
                    )}
                  />
                  <ChangesHistoryField
                    key={"thirdFinalCommentaryPartnersReminder" + keyindex}
                    identifier="thirdFinalCommentaryPartnersReminder"
                    fieldLabel={"Third Final Commentary Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.thirdFinalCommentaryPartnersReminder)}
                    oldValue={getFormattedHighLightDateData(
                      "reviewer_FinalCommentary_Partners_ReminderDate3"
                    )}
                  />
                  <ChangesHistoryField
                    key={"fourthFinalCommentaryPartnersReminder" + keyindex}
                    identifier="fourthFinalCommentaryPartnersReminder"
                    fieldLabel={"Fourth Final Commentary Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.fourthFinalCommentaryPartnersReminder)}
                    oldValue={getFormattedHighLightDateData(
                      "reviewer_FinalCommentary_Partners_ReminderDate4"
                    )}
                  />
                  <ChangesHistoryField
                    key={"fifthFinalCommentaryPartnersReminder" + keyindex}
                    identifier="fifthFinalCommentaryPartnersReminder"
                    fieldLabel={"Fifth Final Commentary Reminder Date & Time"}
                    value={getFormattedDate(fieldValues.fifthFinalCommentaryPartnersReminder)}
                    oldValue={getFormattedHighLightDateData(
                      "reviewer_FinalCommentary_Partners_ReminderDate5"
                    )}
                  />
                </>
              ) : (
                <>
                  <DateTimeCalender
                    disablePast={false}
                    label="First Final Commentary Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.firstFinalCommentaryPartnersReminder
                        ? fieldValues.firstFinalCommentaryPartnersReminder
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="firstFinalCommentaryPartnersReminder"
                    disabled={
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.firstFinalCommentaryPartnersReminder)
                    }
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.assessmentCompletionDate
                        ? new Date().setDate(
                          new Date(
                            fieldValues.assessmentCompletionDate
                          ).getDate() + 1
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={
                      "This date should be after assesment completion date"
                    }
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                  />
                  <DateTimeCalender
                    disablePast={false}
                    label="Second Final Commentary Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.secondFinalCommentaryPartnersReminder
                        ? fieldValues.secondFinalCommentaryPartnersReminder
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="secondFinalCommentaryPartnersReminder"
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.firstFinalCommentaryPartnersReminder
                        ? new Date().setDate(
                          new Date(fieldValues.firstFinalCommentaryPartnersReminder).getDate() +
                          1
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={"This date should be after first reminder date"}
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                    disabled={
                      !(fieldValues && fieldValues.firstFinalCommentaryPartnersReminder) ||
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.secondFinalCommentaryPartnersReminder)
                    }
                  />

                  <DateTimeCalender
                    disablePast={false}
                    label="Third Final Commentary Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.thirdFinalCommentaryPartnersReminder
                        ? fieldValues.thirdFinalCommentaryPartnersReminder
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="thirdFinalCommentaryPartnersReminder"
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.secondFinalCommentaryPartnersReminder
                        ? new Date(
                          new Date().setDate(
                            new Date(
                              fieldValues.secondFinalCommentaryPartnersReminder
                            ).getDate() + 1
                          )
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={
                      "This date should be after second reminder date"
                    }
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                    disabled={
                      !(fieldValues && fieldValues.secondFinalCommentaryPartnersReminder) ||
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.thirdFinalCommentaryPartnersReminder)
                    }
                  />

                  <DateTimeCalender
                    disablePast={false}
                    label="Fourth Final Commentary Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.fourthFinalCommentaryPartnersReminder
                        ? fieldValues.fourthFinalCommentaryPartnersReminder
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="fourthFinalCommentaryPartnersReminder"
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.thirdFinalCommentaryPartnersReminder
                        ? new Date(
                          new Date().setDate(
                            new Date(
                              fieldValues.thirdFinalCommentaryPartnersReminder
                            ).getDate() + 1
                          )
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={
                      "This date should be after second reminder date"
                    }
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                    disabled={
                      !(fieldValues && fieldValues.thirdFinalCommentaryPartnersReminder) ||
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.fourthFinalCommentaryPartnersReminder)
                    }
                  />

                  <DateTimeCalender
                    disablePast={false}
                    label="Fifth Final Commentary Reminder Date & Time"
                    defaultValue={
                      fieldValues && fieldValues.fifthFinalCommentaryPartnersReminder
                        ? fieldValues.fifthFinalCommentaryPartnersReminder
                        : null
                    }
                    dateTimeOnChange={dateTimeOnChange}
                    required={false}
                    varName="fifthFinalCommentaryPartnersReminder"
                    errorMessage={errorMessages}
                    minDate={
                      fieldValues && fieldValues.fourthFinalCommentaryPartnersReminder
                        ? new Date(
                          new Date().setDate(
                            new Date(
                              fieldValues.fourthFinalCommentaryPartnersReminder
                            ).getDate() + 1
                          )
                        )
                        : new Date()
                    }
                    maxDate={
                      fieldValues && fieldValues.discussionGuideLockDate
                        ? fieldValues.discussionGuideLockDate
                        : new Date()
                    }
                    minDateMessage={
                      "This date should be after second reminder date"
                    }
                    maxDateMessage={
                      "This date should be before discussion guide lock date "
                    }
                    disabled={
                      !(fieldValues && fieldValues.fourthFinalCommentaryPartnersReminder) ||
                      disableForm() ||
                      disableforPastDayTimes(fieldValues.fifthFinalCommentaryPartnersReminder)
                    }
                  />
                </>
              )}
            </div>
          )
        }
        <div className="seperator">
          <div className="headingStyle">Select Tenure Reference</div>
          {isPreview ? (
            <ChangesHistoryField
              identifier="tenureReferenceDate"
              fieldLabel={"Tenure Reference Date"}
              value={getFormattedDate(fieldValues.tenureReferenceDate)}
              oldValue={getFormattedHighLightDateData(
                "tenureCalculationReferenceDate"
              )}
            />
          ) : (
            <DateCalender
              label="Tenure Reference Date"
              defaultValue={
                fieldValues && fieldValues.tenureReferenceDate
                  ? fieldValues.tenureReferenceDate
                  : null
              }
              required={
                fieldValues.period &&
                fieldValues.period.toLowerCase() === "plan"
              }
              varName="tenureReferenceDate"
              errorMessage={errorMessages}
              dateOnChange={dateOnChange}
              // disablePast={true}
              maxTenureDate={
                fieldValues && fieldValues.fyYear
                  ? new Date("7-31-" + fieldValues.fyYear.replace("FY", ""))
                  : new Date()
              }
              disabled={
                !(
                  fieldValues &&
                  fieldValues.period &&
                  fieldValues.period.toLowerCase() === "plan"
                ) || disableForm()
              }
            />
          )}
        </div>
      </SimpleBar>
      {isPreview ? null : (
        <div className={"btnSection"}>
          <div className="update-info">
            {`Last updated by: ${lastUpdateInfo.name}  |  ${lastUpdateInfo.date}`}
          </div>
          <div className="buttons-wrapper">
            <div
              style={{
                fontSize: "0.90rem",
                color: "rgba(0,0,0,.80)",
                fontFamily: "EYInterState-Regular",
                marginRight: "10px",
              }}>
              * Please fill all mandatory fields
            </div>
            <div
              className={
                checkifAllMandatoryFieldsFilled()
                  ? " cancel-button summary-button "
                  : "disable-sub save-button "
              }
              onClick={() => {
                if (checkifAllMandatoryFieldsFilled()) {
                  checkOutsideClickAdmin(cancelClick);
                }
              }}>
              Cancel
            </div>
            <div
              className={
                checkifAllMandatoryFieldsFilled() &&
                  !disableForm() &&
                  myContext.changeTriggered
                  ? "conf-save save-button"
                  : "disable-sub save-button"
              }
              onClick={() => {
                dispatch({
                  type: "OUTSIDE_CLICK_ADMIN_RESET",
                  payload: {},
                });
                myContext.setChangeTriggered(false);
                saveBtnClick();
              }}>
              Save
            </div>
            {!isApprovalPreview && (
              <div
                className={"conf-sub submit-button" + (!enableButton ? " opacityReduced " : "")}
                onClick={() => {
                  submitData();
                }}>
                <i
                  className="icon-verified-outline "
                  style={{ marginRight: "5px" }}
                />
                Submit
              </div>
            )}
          </div>
        </div>
      )}
      <AlertSubmitPopup
        header={"Save Changes"}
        onlyAlert={false}
        message={""}
        cancelBtnTxt={"No"}
        submitBtnTxt={"Yes"}
        simpleClosePopup={() => myContext.setShowPopupAdmin(false)}
        description={"Do you wish to save the changes before you proceed?"}
        showAlertSubmitPopup={myContext.showPopupAdmin}
        cancelAlertSubmitPopup={cancelAlertSubmitPopup}
        submitPopupChanges={() => {
          // setShowAlertSubmitPopup(false);
          saveBtnClick();
        }}></AlertSubmitPopup>
      <AlertSubmitPopup
        header={"Confirmation"}
        onlyAlert={false}
        message={"Would you like to submit the changes for review ?"}
        cancelBtnTxt={"Cancel"}
        submitBtnTxt={"Confirm"}
        simpleClosePopup={cancelConfirmation}
        description={
          "Please note that you will be able to make futher edits once the reviewer responds."
        }
        showAlertSubmitPopup={showSubmissionConfirmation}
        cancelAlertSubmitPopup={cancelConfirmation}
        submitPopupChanges={() => {
          submitClicked();
        }}></AlertSubmitPopup>

      <ReviewConfirmation
        showReviewPopup={showReviewConfirmation}
        dismissPopup={dismissReviewWindow}
        reviewResponse={submitReviewResponse}></ReviewConfirmation>
    </div>
  );
};
export default AdminGeneralParam;