export const fontColors = [
    "#e60000",
    "#ff9900",
    "#ffff00",
    "#008a00",
    "#0066cc",
    "#9933ff",
    "#ffffff",
    "#facccc",
    "#ffebcc",
    "#ffffcc",
    "#cce8cc",
    "#cce0f5",
    "#ebd6ff",
    "#bbbbbb",
    "#f06666",
    "#ffc266",
    "#ffff66",
    "#66b966",
    "#66a3e0",
    "#c285ff",
    "#888888",
    "#a10000",
    "#b26b00",
    "#b2b200",
    "#006100",
    "#0047b2",
    "#6b24b2",
    "#444444",
    "#5c0000",
    "#663d00",
    "#666600",
    "#003700",
    "#002966",
    "#3d1466"];

export const backgroundColors = [
    "#000000",
    "#e60000",
    "#ff9900",
    "#ffff00",
    "#008a00",
    "#0066cc",
    "#9933ff",
    "#facccc",
    "#ffebcc",
    "#ffffcc",
    "#cce8cc",
    "#cce0f5",
    "#ebd6ff",
    "#bbbbbb",
    "#f06666",
    "#ffc266",
    "#ffff66",
    "#66b966",
    "#66a3e0",
    "#c285ff",
    "#888888",
    "#a10000",
    "#b26b00",
    "#b2b200",
    "#006100",
    "#0047b2",
    "#6b24b2",
    "#444444",
    "#5c0000",
    "#663d00",
    "#666600",
    "#003700",
    "#002966",
    "#3d1466"
]

export const modules = {
    toolbar: '#toolbar',
    clipboard: {
        matchVisual: false
    },
    placeholder: {
        delimiters: ['<', '>'],
        placeholders: [
            "Phase",
            "FY",
            "FirstName",
            "FullName",
            "LeadReviewerFirstName",
            "LeadReviewerFullName",
            "AssessmentEndDate",
            "AssessmentStartDate",
            "AssessmentLockDate",
            "AssessmentEndDate+2",
            "DiscussionGuideEndDate",
            "CounseleeFullName",
            "ReviewAction",
            "ReviewComment",
            "ResetModule"
        ].map(placeholder => ({
            id: placeholder,
            label: placeholder,
            displayName: placeholder
        }))
    },
}

export const placeholders = {
    User: [
        "FirstName",
        "FullName",
        "LeadReviewerFirstName",
        "LeadReviewerFullName"
    ],
    Date: [
        "AssessmentEndDate",
        "AssessmentStartDate",
        "AssessmentLockDate",
        "AssessmentEndDate+2",
        "DiscussionGuideEndDate",
    ],
    General: [
        "Phase",
        "FY"
    ],
    Specific: [
        "CounseleeFullName",
        "ReviewAction",
        "ReviewComment",
        "ResetModule"
    ]
}