import React, {
    useCallback,
    useState,
    useEffect,
    useMemo,
    useContext,
} from 'react';
import {
    Button,
  } from "@material-ui/core";
import "./AddRegionalData.scss"
import AppContext from "../../../../utils/appContext";
import Loader from "../../../Loader/Loader";
import NavigationPrompt from "react-router-navigation-prompt";
import DropdownComponent from "../../AdminGeneralParam/DropdownComponent/DropdownComponent";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import readXlsxFile from "read-excel-file";
import _ from "lodash";
import { toast, Flip } from "react-toastify";


import { useDispatch, useSelector } from "react-redux";

//import FileSaver, { saveAs } from "file-saver";
import DownloadRegionalDataTemplate from './DownloadRegionalDataTemplate';


const AddRegionalData = (props) => {
    const dispatch = useDispatch();

    const myContext = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const [dropdownValue, setDropdownValue] = useState("")
    const [firstTime, setFirstTime] = useState(true);
    const [fileInfo, setFileInfo] = useState({});
    const [popupType, setPopUpType] = useState(0);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [progress, setProgress] = useState(0);
    const [enableProgress, setEnableProgress] = useState(false);
    const [isFileUploadCompleted, setIsFileUploadCompleted] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [headerErrors, setHeaderError] = useState(false);
    const [validationDone, setValidationDone] = useState(false);
    const regionDataUpload = useSelector((state) => state.regionDataUpload);
    const bobAdminActionStatus = useSelector(
      (state) => state.bobAdminActionStatus
    );



    const [isFileUploadStarted, setIsFileUploadStarted] = useState(false);
    const [errorMessages, setErrorMessages] = useState({
        dropdownValue: ""
    });

    const selectDropdown = (value) => {
        setDropdownValue(value)
    }
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop: (files) => {
            setHeaderError(false);
            validateFile(files[0]);
        },
        maxFiles: 1,
        maxSize: 5119031,
        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet ,application/vnd.ms-excel.sheet.binary.macroEnabled.12",
        disabled: myContext.disableUserUpdation,
    });
    const validateFile = useCallback((file) => {
        if (!file) {
            return;
        } else {
            setFileInfo(file);
            setIsPopupVisible(true);
            setPopUpType(0);
         
        }
    }, []);
    const getPopupContent = useCallback(() => {
        if (popupType === 1) {
            return (
                <>
                    <span>Selected file </span>
                    <b>{fileInfo.name}</b>
                    <span> should be validated before uploading.</span>
                </>
            );
        } else {
            return (
                <>
                    <p>Would you like to add the Assessment content?</p>
                    <p>
                        Please note that this would replace the existing content structure
                        if any in 'Manage Assessment'.
                    </p>
                    <p>
                        {" "}
                        This operation will take some time to complete. Once it is
                        completed, we will inform you over an email.
                    </p>
                </>
            );
        }
    }, [popupType, fileInfo]);
    // const saveFile=()=>{
    //     FileSaver.saveAs("./Add_Regional_Data_ Template.xlsx","Add_Regional_Data_ Template.xlsx")
    // }

    const onPopupConfirm = useCallback(() => {
        setIsPopupVisible(false);
        if (popupType === 1) {
          setProgress(0);
          setEnableProgress(true);
          fileHandler();
        } else {
        }
      }, [popupType, fileHandler, dispatch]);

      const fileHandler = useCallback(() => {
        setIsFileUploadStarted(true);
        setIsFileUploadCompleted(false);
       
        readXlsxFile(fileInfo).then((rows) => {
            validateExcelData(rows);
            setIsFileUploadCompleted(true);
          });
       
        
      }, [fileInfo]);

      const validateExcelData = (rows)=>{
        let secIndValues=['Y','y','N','n']
        setErrorMsg("");
        const updatedRows = _.cloneDeep(rows);
        const excelHeaders = [
        "Client ID",
        "Client",
        "Engagement Region",
        "SEC Indicator",
        "AccountSector",
        "Coordinating Partner Name & GPN/GUI",
        "Engagement Partner #1 Name & GPN/GUI",
        "Engagement Partner #2 Name & GPN/GUI (if applicable)",
        "Engagement Partner #3 Name & GPN/GUI (if applicable)",
        "Engagement Partner #4 Name & GPN/GUI (if applicable)",
        "Engagement Partner #5 Name & GPN/GUI (if applicable)",
        "Revenue Goal ($)",
        "Region Margin Target %"
        ];
        updatedRows.shift();
        const regionalData = _.groupBy(updatedRows, 0);
        let isEmpty = false;
        let issecIndValues=false;
        updatedRows.forEach((updatedRow) => {
          updatedRow.forEach((row,index) => {
            if (row === "" || row === null && (index<4 || index>10)) {
              isEmpty = true;
            }
          });
        });
        updatedRows.forEach((updatedRow , index) => {
            if (!secIndValues.includes(updatedRow[3]) ) {
              issecIndValues= true;
            }
        });
        setValidationDone(true);
        if (!_.isEqual(excelHeaders, rows[0])) {
          setErrorMsg(
            "The file which you have uploaded is not in the correct format."
          );
          return;
        }else if (isEmpty || updatedRows.length === 0) {
          setErrorMsg("File which you uploaded has blank cells.");
          return;
        }else if (issecIndValues) {
          setErrorMsg("File which you uploaded has SEC Indicator values other than Y or N .");
          return;
        }
        
        
      };

      useEffect(() => {
        if (progress < 100) {
          setTimeout(function () {
            if (progress == 98 && !isFileUploadStarted) {
              setProgress(100);
            } else setProgress(progress + 2);
          }, 1);
        }
      }, [progress]);

    const uploadRegionalDataAPI = useCallback(()=>{
      try {
          dispatch({
            type: "REGIONAL_DATA_UPLOAD",
            payload: {
              financialYear: props.rollForwardData.toYear,
              timePeriod:props.rollForwardData.toPhase,
              regionalData_upload: fileInfo
            },
          });
          setIsLoading(true);
        } catch (e) {
          console.error(e);
        }
    })

    useEffect(()=>{
      if (
        !regionDataUpload.fetching &&
        !_.isEmpty(regionDataUpload.data) &&
        regionDataUpload.data._id&&
        regionDataUpload.data.status === "In Progress"
      ) {
        setIsLoading(false);
        dispatch({
          type: "REGIONAL_DATA_UPLOAD_RESET",
          payload: {},
        });
        successNotify();
        props.onClose();
      }
    }, [regionDataUpload]);

    const successNotify = useCallback(() => {
      toast(
        <div className="success-text">
          <span className="icon-verified-outline icon"></span>
          <p>
            <span></span>
            <span>Regional Data upload initiated!</span>
          </p>
        </div>,
        {
          position: "top-center",
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }, []);

    const getLockStatusAPI =  () => {
      //function to call bobAdminActionStatus(Lock) Api.
      try {
       if(props.rollForwardData.toYear&&props.rollForwardData.toPhase){
        dispatch({
          type: "BOB_ADMIN_ACTION_STATUS",
          payload: {
              financialYear: props.rollForwardData.toYear,
              timePeriod:props.rollForwardData.toPhase,
          },
      });
       }   
          
      } catch (e) {
          console.error(e);
      }
  };
      useEffect(() => {
        //lock status API response
        if (
            !bobAdminActionStatus.fetching &&
            bobAdminActionStatus.data &&
            bobAdminActionStatus.data.status &&
            bobAdminActionStatus.data.proceedAction
        ) {
            //Succefully Completed
            setIsLoading(false);
            myContext.setDisableUserUpdation(false);
        } else if (
            !bobAdminActionStatus.fetching &&
            bobAdminActionStatus.data &&
            bobAdminActionStatus.data.status &&
            !bobAdminActionStatus.data.proceedAction
        ) {
            //not completed
            if (firstTime) {
                myContext.setIsWarningPopup(false);
                triggerLock();
                setFirstTime(false);
                setIsLoading(false);
            }
            myContext.setDisableUserUpdation(true);
            dispatch({
                type: "BOB_ADMIN_ACTION_STATUS_RESET",
                payload: {},
            });
            setTimeout(function () {
                getLockStatusAPI();
            }, 5000);
        }
        // else if (!bobAdminActionStatus.fetching && bobAdminActionStatus.error) {
        //   getLockStatusAPI();
        // }
    }, [bobAdminActionStatus]);


    useEffect(() => {
        // setIsLoading(true);
        getLockStatusAPI();
    }, []);

    const triggerLock = () => {
      myContext.setIsLockPopup(true);
      toast.warn(
          <div
              style={{
                  // textTransform: "capitalize",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontFamily: "EYInterState-Regular",
                  fontSize: "12px",
              }}>
              <i className="icon-warning-outline" />
              {/* "Section Updated Successfully" : */}
              {`Previous operation initiated by ${bobAdminActionStatus.data.userName} is still in progress. It will take some time. You can
                perform this action after the operation is completed. We appreciate your
                patience!`}
          </div>,
          {
              position: "top-center",
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
          }
      );
    };


    return (
        <div className="add-assessment-container">
            {isLoading && <Loader />}
            <NavigationPrompt when={isFileUploadStarted}>
                {({ onConfirm, onCancel }) => (
                    <div className="popup-dialog">
                        <div className="dialog">
                            <div className="header">
                                <h5>Confirmation</h5>
                                <span
                                    className="icon-close-filled icon"
                                    onClick={onCancel}
                                ></span>
                            </div>
                            <div className="content">
                                Would you like to navigate without completing the operation?
                                Please note that on confirming, incomplete actions will be lost
                            </div>
                            <div className="footer">
                                <button className="cancel-btn" onClick={onCancel}>
                                    Cancel
                                </button>
                                <button className="confirm-btn" onClick={onConfirm}>
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </NavigationPrompt>
            <div className="add-assessment-header">
                <div className="input-group">
                    <div style={{ display: "flex" }}>
                        <div>
                            FY {props.rollForwardData.toYear} {props.rollForwardData.toPhase}
                        </div>
                        {/* <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "20px",
                                marginTop: "-23px",
                                width: "250px",
                                height: "50px"
                            }}>
                            <DropdownComponent
                                label={"GPN/GUI"}
                                defaultValue={dropdownValue}
                                selectOnChange={
                                    (dummy, value) => {
                                        selectDropdown(value);
                                    }
                                }
                                varName="dropdownValue"
                                list={["GPN", "GUI"]}
                                errorMessage={errorMessages}
                                disabled={false}
                            />
                        </div> */}

                    </div>
                </div>
                <span>Upload File to Add Regional Data</span>
            </div>
            <div className="download-template">
                <DownloadRegionalDataTemplate
                disableDownloadBtn={myContext.disableUserUpdation}/>
            </div>
            <p className="info-text">
                Download the template to make edits and upload the updated file here.
            </p>
            <div
                className={classNames("file-upload-container", {
                    "disable-element": myContext.disableUserUpdation,
                })}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <div className="helper-text">
                    <p>Drag your file here to Upload</p>
                    <p>Maximum file size: 5MB</p>
                </div>
                <label
                    htmlFor="uploadFile"
                    className={classNames("", {
                        "disable-element": myContext.disableUserUpdation,
                    })}
                >
                    {" "}
                    <span className="eyaa-files-104 file-icon"></span>{" "}
                    <span>Choose file</span>
                </label>
            </div>
            {enableProgress && (
        <div
          className="shadowBox"
          style={{
            borderLeftColor: `${progress === 100 ? "#b1de76" : "#2e2e38"}`,
          }}
        >
          <div className="title">
            {progress === 100 ? "Validation Completed" : "Validating file"}
            {errorMsg && <div className="errorMsg">{"File Error"}</div>}

          </div>
          {progress !== 100 ? (
            <div className="blackLine">
              <div
                className="yelloLine"
                style={{ left: `${(progress / 100) * 330}px` }}
              >
                {"&nbsp;"}
              </div>
            </div>
          ) : (
            <div className="greenLine">{"&nbsp;"}</div>
          )}
          <div className="row">
            <div className="fileN">{fileInfo.name}</div>
            <div className="progress">
              {" "}
              {progress +
                "% " +
                (progress === 100 ? "Complete" : "Processing...")}
            </div>
          </div>
        </div>
      )}
      {errorMsg && progress === 100 && (
        <div className="error-section">
          <span className="redMsg">
            {" "}
            <i className="icon-warning-filled" />
            File error
          </span>
          <div className="desc">
            <p>{errorMsg}</p>
            <p>Please check the file and upload again.</p>
          </div>
        </div>
      )}
      <div className="btn-container">
        <Button
          variant="outlined"
          className="cancel-btn"
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          className={classNames("add-regional-btn", {
            "disable-btn":
              (errorMsg && isFileUploadCompleted) || !isFileUploadStarted,
          })}
          disabled={
            (errorMsg && isFileUploadCompleted) ||
            !isFileUploadStarted ||
            myContext.disableUserUpdation
            // !isProceedAction
          }
          onClick={() => {
            setIsPopupVisible(true);
            setPopUpType(1);
          }}
        >
          Add Regional Data
        </Button>
      </div>
      {isPopupVisible && (
        <div className="popup-dialog">
          <div className="dialog">
            <div className="header">
              <h5>{popupType == 0 ? "File Validation" : "Confirmation"}</h5>
              <span
                style={{ position: "absolute", right: "12px", top: "10px" }}
                className="icon-close-filled icon"
                onClick={() => setIsPopupVisible(false)}></span>
            </div>
            {popupType === 0 ? (
              <div className="content">
                <span>Selected file</span>
                <b> {fileInfo.name}</b>
                <span> should be validated before uploading.</span>
              </div>
            ) : (
              <div className="content">Would you like to add Regional Data? </div>
            )}
            {popupType === 1 && (
              <div className="content" style={{ marginTop: "10px" }}>
                Please note that this will take some time. Once the process is
                completed, we will share the summary report over email.
              </div>
            )}
            {popupType == 0 ? (
              <div className="footer">
                <button
                  className="cancel-btn"
                  onClick={() => {
                    setIsPopupVisible(false);
                    setEnableProgress(false);
                    setFileInfo(null);
                    setHeaderError(false);
                  }}>
                  Cancel
                </button>
                <button
                  className="validate-btn"
                  onClick={() => {
                    setEnableProgress(true);
                    setIsPopupVisible(false);
                    setProgress(0);
                    fileHandler();
                    setHeaderError(false);
                  }}>
                  Validate
                </button>
              </div>
            ) : (
              <div className="footer">
                <button
                  className="cancel-btn"
                  onClick={() => {
                    setIsPopupVisible(false);
                  }}>
                  Cancel
                </button>
                <button
                  className="validate-btn"
                  onClick={() => {
                    setIsPopupVisible(false);
                    setEnableProgress(false);
                    setIsLoading(true);
                    myContext.setDisableUserUpdation(true);
                    uploadRegionalDataAPI();
                    dispatch({
                      type: "BOB_ADMIN_ACTION_STATUS_RESET",
                      payload: {},
                  });
                  }}>
                  Confirm
                </button>
              </div>
            )}
          </div>
        </div>
      )}
        </div>
    );
}

export default AddRegionalData