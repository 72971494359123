import React, { useEffect, useContext, useState } from "react";
import AppContext from "../../../utils/appContext";
import { makeStyles, Checkbox } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { styled } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import ReactHtmlParser from "react-html-parser";
import * as myConsts from "../../../constants/constants";
import moment from "moment";
import "./FinalCommentaryTable.scss";
import truncate from 'truncate-html';
import AlertSubmitPopup from "../../Common/AlertSubmitPopup";
import Loader from "../../Loader/Loader";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiSvgIcon-root': {
            fontSize: '2.5rem',
        },
    },
}));

const FinalCommentaryTable = (props) => {
    const { filterListStatus, filterListRegion,
        userProfile,
        assessmentMetaData } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const [isShowMore, setShowMore] = React.useState({});
    const [checkForSuccessFactors, setCheckForSuccessFactors] = React.useState({});
    const [showSubmissionConfirmation, setShowSubmissionConfirmation] = useState(false);
    const [selectedPartnerEmail, setSelectedPartnerEmail] = useState("");
    const [selectedPartnerChecked, setSelectedPartnerChecked] = useState("");
    const [loader, setLoader] = useState(false);
    const [finalData, setFinalData] = useState([]);

    const myContext = useContext(AppContext);
    const showLess = (fieldName) => {
        //debugger;
        let value = isShowMore[fieldName];
        setShowMore((prevState) => (
            {
                ...prevState,
                [fieldName]: !value
            }
        ));
    };

    const handleCheckForSuccessFactors = (e, row) => {
        //debugger;
        const { name, checked } = e.target;
        //let value = checkForSuccessFactors[name];
        setCheckForSuccessFactors((prevState) => (
            {
                ...prevState,
                [name]: checked
            }
        ));
        setShowSubmissionConfirmation(true);
        setSelectedPartnerEmail(row["Partner Email"]);
        setSelectedPartnerChecked(checked);
    }

    const submitData = () => {
        if (selectedPartnerEmail && selectedPartnerChecked) {
            setLoader(true);
            dispatch({
                type: "UPDATE_IS_RECORDED_IN_SUCCESS_FACTORS",
                payload: {
                    financialYear: myContext.financialYear,
                    userId: selectedPartnerEmail,
                    isRecordedInSuccessFactors: selectedPartnerChecked,
                },
            });
            setShowSubmissionConfirmation(false);
            setTimeout(() => {
                setLoader(false);
            }, 1000);
        }
    }

    const cancelConfirmation = () => {
        setShowSubmissionConfirmation(false);
        if (selectedPartnerEmail && selectedPartnerChecked) {
            setCheckForSuccessFactors((prevState) => (
                {
                    ...prevState,
                    [`${selectedPartnerEmail}_checkbox`]: !selectedPartnerChecked
                }
            ));
            setSelectedPartnerEmail("");
            setSelectedPartnerChecked("");
        }
    };

    const isValidDate = (date) => {
        return moment(date).isValid() ? date : "Not Available";
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        // console.log(newPage);
    };
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: "#FAFAFC",
        },
        // hide last border
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));
    useEffect(() => {
        debugger;
        setPage(0);
        let showMoreObj = {}, checkForSuccessFactors = {};
        let data = [];
        props && props.dataSource && props.dataSource.length > 0 && props.dataSource.map((d) => {
            showMoreObj[`${d["Partner Email"]}_isShowMore`] = false;
            checkForSuccessFactors[`${d["Partner Email"]}_checkbox`] = d.isRecordedInSuccessFactors;
            d.isPhaseOver = isPhaseOver(d);
            data.push(d);
        });
        setShowMore(showMoreObj);
        setCheckForSuccessFactors(checkForSuccessFactors);
        const sData = data.sort((a, b) => a.isPhaseOver - b.isPhaseOver);
        setFinalData(sData);
    }, [props.dataSource]);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const TablePaginationActions = (props) => {
        const { count, page, rowsPerPage, onPageChange } = props;

        const handleFirstPageButtonClick = (event) => {
            onPageChange(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onPageChange(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onPageChange(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };
        // console.log(props);
        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="first page">
                    {<FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="previous page">
                    {<KeyboardArrowLeft />}
                </IconButton>
                {page + 1 + "/" + Math.ceil(count / rowsPerPage)}
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page">
                    {<KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="last page">
                    {<LastPageIcon />}
                </IconButton>
            </Box>
        );
    };

    const filterEDsPartnersData = (row) => {
        //debugger;
        try {
            if (row && assessmentMetaData && assessmentMetaData.data &&
                assessmentMetaData.data.data && (assessmentMetaData.data.data.assessmentMetaData
                    || assessmentMetaData.data.data.lastAssessmentMetaData)) {
                let isWithinDateRange = false;

                let assessmentDetails = assessmentMetaData.data.data.lastAssessmentMetaData &&
                    Object.keys(assessmentMetaData.data.data.lastAssessmentMetaData).length > 0 &&
                    assessmentMetaData.data.data.lastAssessmentMetaData.timePeriod === myConsts.TimePeriodYearEnd ? assessmentMetaData.data.data.lastAssessmentMetaData
                    : assessmentMetaData.data.data.assessmentMetaData;

                let selUserRankCode = row["rankCode"];
                let todaysDate = new Date();

                let mdStartDate = new Date(assessmentDetails.finalCommentary_MD_StartDate);
                let mdEndDate = new Date(assessmentDetails.finalCommentary_MD_EndDate);
                let partnerStartDate = new Date(assessmentDetails.finalCommentary_Partner_StartDate);
                let partnerEndDate = new Date(assessmentDetails.finalCommentary_Partner_EndDate);

                switch (selUserRankCode) {
                    case "13": //...EDs/MDs
                    case 13: //...EDs/MDs
                        if (mdStartDate <= todaysDate && mdEndDate >= todaysDate) {
                            isWithinDateRange = true;
                        }
                        break;
                    case "11": //..Partners
                    case 11: //..Partners
                        if (partnerStartDate <= todaysDate && partnerEndDate >= todaysDate) {
                            isWithinDateRange = true;
                        }
                        break;
                    default:
                        break;
                }

                return isWithinDateRange;
            }
        } catch (error) {
            console.log(`ERROR:: ${error}`);
        }
    };

    const isPhaseOver = (row) => {
        //debugger;
        try {
            if (row && assessmentMetaData && assessmentMetaData.data &&
                assessmentMetaData.data.data && (assessmentMetaData.data.data.assessmentMetaData
                    || assessmentMetaData.data.data.lastAssessmentMetaData)) {
                let isPhaseOver = false;

                let assessmentDetails = assessmentMetaData.data.data.lastAssessmentMetaData &&
                    Object.keys(assessmentMetaData.data.data.lastAssessmentMetaData).length > 0 &&
                    assessmentMetaData.data.data.lastAssessmentMetaData.timePeriod === myConsts.TimePeriodYearEnd ? assessmentMetaData.data.data.lastAssessmentMetaData
                    : assessmentMetaData.data.data.assessmentMetaData;

                let selUserRankCode = row["rankCode"];
                let todaysDate = new Date();

                let mdStartDate = new Date(assessmentDetails.finalCommentary_MD_StartDate);
                let mdEndDate = new Date(assessmentDetails.finalCommentary_MD_EndDate);
                let partnerStartDate = new Date(assessmentDetails.finalCommentary_Partner_StartDate);
                let partnerEndDate = new Date(assessmentDetails.finalCommentary_Partner_EndDate);

                switch (selUserRankCode) {
                    case "13": //...EDs/MDs
                    case 13: //...EDs/MDs
                        if (mdStartDate < todaysDate && mdEndDate < todaysDate) {
                            isPhaseOver = true;
                        }
                        break;
                    case "11": //..Partners
                    case 11: //..Partners
                        if (partnerStartDate < todaysDate && partnerEndDate < todaysDate) {
                            isPhaseOver = true;
                        }
                        break;
                    default:
                        break;
                }

                return isPhaseOver;
            }
        } catch (error) {
            console.log(`ERROR:: ${error}`);
        }
    };

    const prepareFinalCommentary = (row) => {
        //debugger;
        /* let finalCommentary = `<div>${row["Partner Name"]} exhibited the following distinguished performance behaviors this fiscal year: <br>${row["Distinguish Performance"]}<br> 
                                          ${row["Partner Name"]} has the following development points: <br>${row["Development Points"]}<br>
                                          ${row["Partner Name"]}'s potential and aspirations include: <br>${row["Potential And Aspirations"]}<br>
                                          Overall, ${row["Partner Name"]} is in the ${row["Overall Category"]} category.<br> 
                                          ${row["Partner Name"]}'s quality rating has been considered in their LEAD category determination and the related commentary is contained in MARQ.</div>`; */

        let finalCommentary = row["Overall Category"] ? `<div>Overall, ${row["Partner Name"]} is in the ${row["Overall Category"]} category. Year-end distinguished performance<br>
        behaviors, development points and potential/aspirations has been discussed with ${row["Partner Name"]} during<br>
        the Expectations conversation. ${row["Partner Name"]}'s quality rating has been considered in their LEAD category<br>
        determination and the related commentary is contained in MARQ.<div>` : "";

        truncate(finalCommentary, 200);
        return finalCommentary;
    }
    // console.log(props);
    //return props && props.dataSource && props.dataSource.length > 0 ? (
    return finalData && finalData.length > 0 ? (
        <div className="final-commentary-table" style={{ border: "1px solid black" }}>
            {loader && <Loader />}
            <Typography
                sx={{ flex: "1 1 100%" }}
                variant="h6"
                id="tableTitle"
                style={{
                    padding: "5px 5px 5px 5px",
                    backgroundColor: "black",
                    color: "white",
                }}
                component="div">
                {"Lead Reviewer - FINAL Finalization Form Commentary"}
            </Typography>
            <TableContainer style={{ maxHeight: 430 }}>
                <Table
                    stickyHeader
                    /*  style={{ minWidth: 650, maxHeight: 340 }} */
                    aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                {" "}
                                <b>Last Name</b>
                            </TableCell>
                            <TableCell align="left">
                                {" "}
                                <b>First Name</b>
                            </TableCell>
                            <TableCell align="left">
                                {" "}
                                <b>GUI</b>
                            </TableCell>
                            <TableCell align="left">
                                {" "}
                                <b>Office</b>
                            </TableCell>
                            <TableCell align="left">
                                {" "}
                                <b>Rank</b>
                            </TableCell>
                            <TableCell align="left">
                                {" "}
                                <b>Region</b>
                            </TableCell>
                            {/* <TableCell align="left">
                                {" "}
                                <b>Submitted Status</b>
                            </TableCell> */}
                            <TableCell align="left">
                                {" "}
                                <b>FINAL Finalization Form Commentary <br></br><span style={{ color: "red" }}>(Copy and paste the final finalization form commentary below into SuccessFactors. DO NOT make changes.)</span></b>
                            </TableCell>
                            <TableCell align="left">
                                {" "}
                                <b>Confirmation of entry into SuccessFactors <br></br><span style={{ color: "red" }}>(After commentary entered into SuccessFactors, please confirm by selecting the checkbox below next to the respective PPMD)</span></b>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {(rowsPerPage > 0
                            ? props.dataSource.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            : props.dataSource
                        ).map((row) => ( */}
                        {(rowsPerPage > 0
                            ? finalData.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                            )
                            : finalData
                        ).map((row) => (
                            <StyledTableRow
                                key={row.name}
                                // className={`${filterEDsPartnersData(row)
                                //     ?
                                //     ""
                                //     :
                                //     "hideRow"
                                //     }`}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell component="th" scope="row" className={`${filterEDsPartnersData(row) ? "" : "hideRow"}`}>
                                    {row["Last Name"]}
                                </TableCell>
                                <TableCell component="th" scope="row" className={`${filterEDsPartnersData(row) ? "" : "hideRow"}`}>
                                    {row["First Name"]}
                                </TableCell>
                                <TableCell component="th" scope="row" className={`${filterEDsPartnersData(row) ? "" : "hideRow"}`}>
                                    {row["GUI"]}
                                </TableCell>
                                <TableCell component="th" scope="row" className={`${filterEDsPartnersData(row) ? "" : "hideRow"}`}>
                                    {row["Office"]}
                                </TableCell>
                                <TableCell component="th" scope="row" className={`${filterEDsPartnersData(row) ? "" : "hideRow"}`}>
                                    {row["Rank"]}
                                </TableCell>
                                <TableCell component="th" scope="row" className={`${filterEDsPartnersData(row) ? "" : "hideRow"}`}>
                                    {row["Region"]}
                                </TableCell>
                                {/* <TableCell component="th" scope="row">
                                    {row["Reviewer Submitted Status"]}
                                </TableCell> */}
                                <TableCell component="th" scope="row">
                                    {
                                        !filterEDsPartnersData(row)
                                            ?
                                            (
                                                isPhaseOver(row) ? (
                                                    <>
                                                        <div className={`${filterEDsPartnersData(row) ? "" : "hideRow"}`}>
                                                            {
                                                                row["FINAL Finalization Wording"] === "FINAL" ?
                                                                    ReactHtmlParser(isShowMore[`${row["Partner Email"]}_isShowMore`] ? prepareFinalCommentary(row)
                                                                        :
                                                                        truncate(prepareFinalCommentary(row), 100))
                                                                    :
                                                                    //ReactHtmlParser(row["FINAL Finalization Wording"] ? row["FINAL Finalization Wording"].replaceAll("\n", "<br/>") : "")
                                                                    ReactHtmlParser(row["FINAL Finalization Wording"] ?
                                                                        isShowMore[`${row["Partner Email"]}_isShowMore`] ? row["FINAL Finalization Wording"].replaceAll("\n", "<br/>") : truncate(row["FINAL Finalization Wording"].replaceAll("\n", "<br/>"), 100) : "")
                                                            }
                                                        </div>
                                                        {
                                                            row["Overall Category"] ?
                                                                <span onClick={() => showLess(`${row["Partner Email"]}_isShowMore`)}
                                                                    style={{
                                                                        cursor: "pointer",
                                                                        color: "#188ce5",
                                                                        fontSize: "12px",
                                                                        opacity: ".8"
                                                                    }}>
                                                                    {isShowMore[`${row["Partner Email"]}_isShowMore`] ? " View Less" : " View More"}
                                                                </span>
                                                                :
                                                                null
                                                        }
                                                    </>
                                                )
                                                    :
                                                    (
                                                        <></>
                                                    )
                                            )
                                            :
                                            (
                                                <>
                                                    {
                                                        row["FINAL Finalization Wording"] === "FINAL" ?
                                                            ReactHtmlParser(isShowMore[`${row["Partner Email"]}_isShowMore`] ? prepareFinalCommentary(row)
                                                                :
                                                                truncate(prepareFinalCommentary(row), 100))
                                                            :
                                                            //ReactHtmlParser(row["FINAL Finalization Wording"] ? row["FINAL Finalization Wording"].replaceAll("\n", "<br/>") : "")
                                                            ReactHtmlParser(row["FINAL Finalization Wording"] ?
                                                                isShowMore[`${row["Partner Email"]}_isShowMore`] ? row["FINAL Finalization Wording"].replaceAll("\n", "<br/>") : truncate(row["FINAL Finalization Wording"].replaceAll("\n", "<br/>"), 100) : "")
                                                    }
                                                    {
                                                        row["Overall Category"] ?
                                                            <span onClick={() => showLess(`${row["Partner Email"]}_isShowMore`)}
                                                                style={{
                                                                    cursor: "pointer",
                                                                    color: "#188ce5",
                                                                    fontSize: "12px",
                                                                    opacity: ".8"
                                                                }}>
                                                                {isShowMore[`${row["Partner Email"]}_isShowMore`] ? " View Less" : " View More"}
                                                            </span>
                                                            :
                                                            null
                                                    }
                                                </>
                                            )
                                    }
                                </TableCell>
                                <TableCell component="th" scope="row" className={`${filterEDsPartnersData(row) ? "" : "hideRow"}`}>
                                    {
                                        row["Overall Category"] ?
                                            (
                                                !filterEDsPartnersData(row)
                                                    ?
                                                    (
                                                        isPhaseOver(row) ? (
                                                            <Checkbox
                                                                color="default"
                                                                defaultValue={checkForSuccessFactors[`${row["Partner Email"]}_checkbox`]}
                                                                className={classes.root}
                                                                name={`${row["Partner Email"]}_checkbox`}
                                                                checked={checkForSuccessFactors[`${row["Partner Email"]}_checkbox`]}
                                                                disabled={true}
                                                            >
                                                            </Checkbox>
                                                        )
                                                            :
                                                            (
                                                                <></>
                                                            )
                                                    )
                                                    :
                                                    (
                                                        <Checkbox
                                                            color="default"
                                                            defaultValue={checkForSuccessFactors[`${row["Partner Email"]}_checkbox`]}
                                                            className={classes.root}
                                                            name={`${row["Partner Email"]}_checkbox`}
                                                            onChange={(e) => handleCheckForSuccessFactors(e, row)}
                                                            checked={checkForSuccessFactors[`${row["Partner Email"]}_checkbox`]}
                                                            disabled={checkForSuccessFactors[`${row["Partner Email"]}_checkbox`]}
                                                        //name={answer.action_id}
                                                        //onChange={handleImpactStmtFocusChecked}
                                                        //   checked={impactStmtFocusChecked
                                                        //     && impactStmtFocusChecked.hasOwnProperty(`${answer.action_id}`)
                                                        //     && impactStmtFocusChecked[`${answer.action_id}`].hasOwnProperty(`focused`)
                                                        //     && impactStmtFocusChecked[`${answer.action_id}`].focused === true ? true : false}
                                                        //   disabled={
                                                        //     !myContext.disableUpdateSummary && !myContext.formsInActive
                                                        //       ?
                                                        //       impactStmtFocusChecked
                                                        //         && impactStmtFocusChecked.hasOwnProperty(`${answer.action_id}`)
                                                        //         && impactStmtFocusChecked[`${answer.action_id}`].hasOwnProperty(`disabled`)
                                                        //         && impactStmtFocusChecked[`${answer.action_id}`].disabled === true ? true : false
                                                        //       : true}
                                                        >
                                                        </Checkbox>
                                                    )
                                            )
                                            :
                                            null
                                    }
                                </TableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                colSpan={7}
                                //count={props.dataSource.length}
                                count={finalData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                labelRowsPerPage={<div style={{ fontSize: "12px" }}>Show</div>}
                                SelectProps={{
                                    inputProps: {
                                        "aria-label": "Show",
                                    },
                                    native: true,
                                }}
                                labelDisplayedRows={() => null}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <AlertSubmitPopup
                header={"Confirmation"}
                onlyAlert={false}
                message={"Would you like to submit?"}
                cancelBtnTxt={"Cancel"}
                submitBtnTxt={"Confirm"}
                simpleClosePopup={cancelConfirmation}
                description={
                    "Before submit make sure you have copied FINAL finalization commentary into SuccessFactors. Please note that you will not be able to make futher edits once submitted."
                }
                showAlertSubmitPopup={showSubmissionConfirmation}
                cancelAlertSubmitPopup={cancelConfirmation}
                submitPopupChanges={() => {
                    submitData();
                }}></AlertSubmitPopup>
        </div>
    ) : !props.isLoading ? (
        <div className="no-data-found">
            {" "}
            No data found. Please apply different filters
        </div>
    ) : null;
};
export default FinalCommentaryTable;
