import React from 'react'

const GenerateBob = () => {
    return (
        <div>
            GenerateBob
        </div>
    );
}

export default GenerateBob