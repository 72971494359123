import { ErrorBoundary } from 'react-error-boundary'
import { withRouter } from 'react-router-dom';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import _ from "lodash";
import ErrorFallBack from './ErrorFallBack'

const InternalErrorBoundary = (props) => {
    const { children, history, type = '' } = props;
    const appInsights = useAppInsightsContext();

    const getFormattedType = type => {
        let route = '';
        switch (type) {
            case 'Main':
            case 'Router':
            case '':
                route = type;
                break;
            default:
                const tempRoute = type.split('/') || [];
                const formattedTempRoute = tempRoute[tempRoute.length - 1].indexOf(':') !== -1 ?
                    tempRoute[tempRoute.length - 2] :
                    tempRoute[tempRoute.length - 1]
                route = _.startCase(formattedTempRoute);
        }
        return route;
    }

    const page = getFormattedType(type);

    const myErrorHandler = (error, info) => {
        appInsights.trackException({ error: new Error(error), severityLevel: SeverityLevel.Error });
    }

    return (
        <ErrorBoundary
            FallbackComponent={({ ...args }) => (ErrorFallBack({ ...args, history, page }))}
            onError={myErrorHandler}
        >
            {children}
        </ErrorBoundary>
    )
}

export default withRouter(InternalErrorBoundary);