import React, { useState, useEffect, useContext } from "react";
import SimpleBar from "simplebar-react";
import { withRouter } from "react-router-dom";
import DropdownComponent from "../../AdminGeneralParam/DropdownComponent/DropdownComponent";
import AppContext from "../../../../utils/appContext";
import "./AdminPopup.scss";
import PillarWiseComponent from "./PillarWiseComponent/PillarWiseComponent";

const CopyForm = (props) => {
  const myContext = useContext(AppContext);
  const { prevAssessmentData } = props;
  const [fyYear, setFyYear] = useState();
  const [errorMessages, setErrorMessages] = useState({
    fyYear: "",
  });
  const [isCheckedPillar, setIsCheckedPillar] = useState({});
  const [expandPillar, setExpandPillar] = useState({});
  const selectOnChange = (dummy, value) => {
    setFyYear(value);
    props.getPrevAssesmentData(
      myContext.currentPeriod,
      value.replace("FY ", "")
    );
  };
  useEffect(() => {
    setFyYear("FY " + (Number.parseInt(props.fyYear.replace("FY", "")) - 1));
    // console.log("FY " + (Number.parseInt(props.fyYear.replace("FY", "")) - 1));
    props.getPrevAssesmentData(
      myContext.currentPeriod,
      Number.parseInt(props.fyYear.replace("FY", ""))
    );

    // eslint-disable-next-line
  }, [props.fyYear]);

  useEffect(() => {
    //On expanding each pillar,other pillars will be collapsed
    let temp = [];
    prevAssessmentData &&
      prevAssessmentData.length > 0 &&
      prevAssessmentData.forEach((item) => {
        temp[item.pillar] = false;
      });
    setIsCheckedPillar({ ...temp });
    setExpandPillar({ ...temp });
    console.log(temp);
  }, [prevAssessmentData]);

  useEffect(() => {
    let temp = [];
    prevAssessmentData &&
      prevAssessmentData.length > 0 &&
      prevAssessmentData.forEach((item) => {
        if (isCheckedPillar[item.pillar]) {
          temp.push(item);
        }
      });
    props.passValues(temp);

    // eslint-disable-next-line
  }, [isCheckedPillar]);

  return (
    <div className="copy-form">
      <div class="date-div">
        <DropdownComponent
          label="Choose the FY to copy"
          defaultValue={fyYear}
          required={true}
          selectOnChange={selectOnChange}
          varName="fyYear"
          list={
            props.fyYear
              ? [
                  "FY " + Number.parseInt(props.fyYear.replace("FY", "")),
                  "FY " + (Number.parseInt(props.fyYear.replace("FY", "")) - 1),
                  "FY " + (Number.parseInt(props.fyYear.replace("FY", "")) - 2),
                  "FY " + (Number.parseInt(props.fyYear.replace("FY", "")) - 3),
                ]
              : []
          }
          disabled={false}
          errorMessage={errorMessages}
        />
      </div>
      {props.prevAssessmentData && props.prevAssessmentData.length > 0 ? (
        <div className="listing">
          <SimpleBar
            style={{
              maxHeight: "320px",
              height: "320px",
            }}>
            {props.prevAssessmentData &&
              props.prevAssessmentData.map((item) => (
                <>
                  <div
                    className={"list-card"}
                    style={{
                      marginBottom: `${
                        expandPillar[item.pillar] ? "0px" : "5px"
                      }`,
                    }}>
                    <div className="icons-box">
                      <i
                        style={{ cursor: "auto" }}
                        className={
                          "check-sym " +
                          (isCheckedPillar[item.pillar]
                            ? "icon-check-squere-filled"
                            : "icon-check-unchecked")
                        }
                        onClick={(e) => {
                          setIsCheckedPillar({
                            ...isCheckedPillar,
                            [item.pillar]: !isCheckedPillar[item.pillar],
                          });
                        }}
                      />
                      <i
                        className={
                          "accord-sym " +
                          (expandPillar[item.pillar]
                            ? "icon-minus"
                            : "icon-plus")
                        }
                        onClick={() => {
                          let temp = expandPillar;
                          if (!expandPillar[item.pillar]) {
                            prevAssessmentData.forEach((i) => {
                              if (i.pillar !== item.pillar)
                                temp[i.pillar] = false;
                              else temp[i.pillar] = true;
                            });
                            setExpandPillar({ ...temp });
                          } else
                            setExpandPillar({
                              ...expandPillar,
                              [item.pillar]: !expandPillar[item.pillar],
                            });
                        }}
                      />
                    </div>
                    {item.pillar}
                  </div>
                  {expandPillar[item.pillar] ? (
                    <div className={"list-detail"}>
                      <PillarWiseComponent data={item.section} />
                    </div>
                  ) : null}
                </>
              ))}
          </SimpleBar>
        </div>
      ) : (
        <div className={"nodata"}>
          {" "}
          Global metric data is not available for selected year. Please choose a
          different year.
        </div>
      )}
    </div>
  );
};
export default withRouter(CopyForm);
