import React, { useState, useEffect, useContext } from "react";
import "./AdditionalReporting.scss";
import { useDispatch, useSelector } from "react-redux";
import SimpleBar from "simplebar-react";
import Table from "rc-table";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Loader from "../../../Loader/Loader";
import _ from "lodash";
import AppContext from "../../../../utils/appContext";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import DropdownComponent from "../../AdminGeneralParam/DropdownComponent/DropdownComponent";
import {
  EXPORT_ADDITIONAL_REPORT,
  EXPORT_ADDITIONAL_REPORT_RESET,
} from "../../../../actions/types";
import { Flip, toast } from "react-toastify";

const AdditionalReporting = (props) => {
  const myContext = useContext(AppContext);

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.getReportFilter);
  const getReportData = useSelector((state) => state.getAdditionalReportData);
  const exportAdditionalReporting = useSelector(
    (state) => state.exportAdditionalReportData
  );

  const [lastFilterData, setLastFilterData] = useState({
    region: [],
    office: [],
    tenure: [],
    primarySector: [],
    pillar: [],
  });
  const [fieldValues, setFieldValues] = useState({
    fyYear: "FY " + myContext.financialYear,
    selectedperiod: myContext.currentPeriod,
    region: [],
    office: [],
    tenure: [],
    primarySector: [],
    pillar: [],
    goal: [],
  });
  const [errorMesaages, setErrorMessages] = useState({
    fyYear: "",
    selectedperiod: "",
    region: "",
    office: "",
    tenure: "",
    primarySector: "",
    pillar: "",
    goal: "",
  });
  const [completedPhaseYear, setCompletedPhaseYear] = useState({});
  const [pillarList, setPillarList] = useState([]);
  const getCompletedPhase = useSelector((state) => state.getCompletedPhase);
  const notEmptyString = (element) => element !== "";
  const basiccolumns = [
    {
      title: "PPMD Name",
      dataIndex: "PPMD Name",
      key: "PPMD Name",
      fixed: "left",
      width: 150,
      className: "table-row-odd",
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      fixed: "left",
      width: 100,
      className: "table-row-odd",
    },
    {
      title: "Tenure(Year)",
      dataIndex: "tenure",
      key: "tenure",
      fixed: "left",
      width: 100,
      className: "table-row-odd centerAign",
    },
    {
      title: "Primary Sector",
      dataIndex: "primarySector",
      key: "primarySector",
      fixed: "left",
      width: 100,
      className: "table-row-odd",
    },
    {
      title: "Office",
      dataIndex: "office",
      key: "office",
      fixed: "left",
      width: 100,
      className: "table-row-odd",
    },
  ];
  const [columns, setColumns] = useState([]);
  const [currentpageNo, setCurrentpageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDataCount, setTotalDataCount] = useState(100);
  const pageSizeOptions = [10, 25, 30];
  const [dataSource, setDataSource] = useState([]);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const chipView = (selected) => {
    let x = 0;
    let flag = false;

    // !_.isEmpty(filterData.data) &&
    //   filterData.data.locations.length > 0 &&
    //   console.log(
    //     getOffices(filterData.data.locations, fieldValues.region),
    //     getOffices(filterData.data.locations, fieldValues.region).some(
    //       notEmptyString
    //     )
    //   );

    if (selected.length === 0) {
      return (
        <span
          style={{
            marginLeft: "3px",
            position: "absolute",
            top: "9px",
            color: "grey",
          }}>
          Select
        </span>
      );
    } else
      return (
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
          {selected.sort().map((value, index) => {
            if (x + value.length < 17 && !flag) {
              x = x + value.length;
              return <Chip label={value} key={value + index} />;
            } else if (!flag) {
              flag = true;
              return (
                <Chip
                  // key={"+" + (selected.length - index)}
                  label={"+" + (selected.length - index)}
                  key={"+" + (selected.length - index + index)}
                />
              );
            } else return null;
          })}
        </Box>
      );
  };
  // const camelize = (str) => {
  //   return str
  //     .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
  //       return index === 0 ? word.toLowerCase() : word.toUpperCase();
  //     })
  //     .replace(/\s+/g, "");
  // };
  useEffect(() => {
    // console.log(fieldValues);
  }, [fieldValues]);

  useEffect(() => {
    if (getCompletedPhase.fetching) {
      // setLoader(true);
    } else if (
      !getCompletedPhase.fetching &&
      getCompletedPhase.data &&
      getCompletedPhase.data.length
    ) {
      setFieldValues({
        ...fieldValues,
        fyYear: "FY " + getCompletedPhase.data[0].year,
        selectedperiod: getCompletedPhase.data[0].phase[0],
      });
      let data = getCompletedPhase.data;
      let temp = {};
      data.map((year) => {
        temp["FY " + year.year] = year.phase;
      });
      setCompletedPhaseYear(temp);
    }
  }, [getCompletedPhase]);
  const getFilterData = (period, year) => {
    setLoader(true);

    dispatch({
      type: "GET_REPORT_FILTER",
      payload: {
        period: period,
        financialYear: year ? year.toString().replace("FY ", "") : "",
      },
    });
  };

  useEffect(() => {
    setLoader(true);
    dispatch({
      type: "GET_COMPLETED_PHASE",
      payload: {},
    });
  }, []);

  useEffect(() => {
    getFilterData(fieldValues.selectedperiod, fieldValues.fyYear);
    setFieldValues({
      ...fieldValues,
      region: [],
      office: [],
      tenure: [],
      primarySector: [],
      pillar: [],
      goal: [],
    });
  }, [fieldValues.fyYear, fieldValues.selectedperiod]);
  useEffect(() => {
    if (
      filterData &&
      filterData.data &&
      !filterData.fetching &&
      filterData.data.locations
    ) {
      setLoader(false);
      const Data = filterData.data;
      let pillarData = [];

      Data.pillars &&
        Data.pillars.map((item) => {
          let temp = {};

          temp.pillar = item.pillarName;
          temp.key = item.pillarId;
          let sections = [];
          item.headers.map((inner) => {
            sections.push({
              key: Object.keys(inner)[0],
              header: Object.values(inner)[0],
            });
          });
          temp.sections = sections;
          pillarData.push(temp);
        });
      // console.log(pillarData, "$$$$$");
      setPillarList([...pillarData]);
      // dispatch({
      //   type: "GET_REPORT_FILTER_RESET",
      //   payload: {},
      // });
      setLoader(false);
    } else if (
      filterData &&
      filterData.data &&
      filterData.data.messageCode &&
      filterData.data.messageCode === "MSG_280"
    ) {
      //   setAssessmentData([]);
      setLoader(false);
    }
  }, [filterData]);

  useEffect(() => {
    if (
      getReportData &&
      getReportData.data &&
      !getReportData.fetching &&
      getReportData.data.data
    ) {
      setLoader(false);
      setDataSource(getReportData.data.data);
      setTotalDataCount(getReportData.data.count);
      // console.log(getReportData.data.data);
    } else if (
      getReportData &&
      getReportData.data &&
      getReportData.data.messageCode &&
      getReportData.data.messageCode === "MSG_280"
    ) {
      //   setAssessmentData([]);
      setLoader(false);
    }
  }, [getReportData]);
  const checkifDifference = () => {
    if (
      _.difference(fieldValues.region, lastFilterData.region, _.isEqual)
        .length !== 0 ||
      _.difference(lastFilterData.region, fieldValues.region, _.isEqual)
        .length !== 0 ||
      _.difference(lastFilterData.office, fieldValues.office, _.isEqual)
        .length !== 0 ||
      _.difference(fieldValues.office, lastFilterData.office, _.isEqual)
        .length !== 0 ||
      _.difference(fieldValues.tenure, lastFilterData.tenure, _.isEqual)
        .length !== 0 ||
      _.difference(lastFilterData.tenure, fieldValues.tenure, _.isEqual)
        .length !== 0 ||
      _.difference(
        lastFilterData.primarySector,
        fieldValues.primarySector,
        _.isEqual
      ).length !== 0 ||
      _.difference(
        fieldValues.primarySector,
        lastFilterData.primarySector,
        _.isEqual
      ).length !== 0 ||
      _.difference(lastFilterData.pillar, fieldValues.pillar).length !== 0 ||
      _.difference(fieldValues.pillar, lastFilterData.pillar).length !== 0
    )
      return true;
    else return false;
  };
  const nonEmptyFilterData = () => {
    if (
      fieldValues.region.length !== 0 ||
      fieldValues.office.length !== 0 ||
      fieldValues.tenure.length !== 0 ||
      fieldValues.primarySector.length !== 0 ||
      fieldValues.pillar.length !== 0
    )
      return true;
    else return false;
  };

  const showToastMessage = (type) => {
    let message = "";
    switch (type) {
      case "export":
        message = "Additional report export initiated";
        break;
      default:
        break;
    }
    toast(
      <div className="success-text">
        <span className="icon-verified-outline icon"></span>
        <p>
          <span>{message}</span>
        </p>
      </div>,
      {
        position: "top-center",
        transition: Flip,
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  useEffect(() => {
    if (
      exportAdditionalReporting &&
      exportAdditionalReporting.data &&
      !exportAdditionalReporting.fetching &&
      exportAdditionalReporting.data.Message &&
      exportAdditionalReporting.data.Message ===
        "Successfully send the report to your mail"
    ) {
      showToastMessage("export");
      setLoader(false);
      dispatch({
        type: EXPORT_ADDITIONAL_REPORT_RESET,
        payload: {},
      });
    } else if (
      exportAdditionalReporting &&
      exportAdditionalReporting.data &&
      exportAdditionalReporting.fetching
    ) {
      setLoader(true);
    }
  }, [exportAdditionalReporting, dispatch]);

  useEffect(() => {
    // setLoader(true);
    getDataAPI();
  }, [currentpageNo, pageSize]);
  const applyFilter = (clear) => {
    let temp = basiccolumns;

    for (let i = 0; i < pillarList.length; i++) {
      if (
        fieldValues.pillar.length == 0 ||
        clear ||
        (fieldValues.pillar.length > 0 &&
          fieldValues.pillar.filter((it) => it == pillarList[i]["pillar"])
            .length == 1)
      ) {
        let c = [];

        pillarList[i].sections.forEach((i2) => {
          //when there is goal based filter curremntly removed
          if (
            fieldValues.goal.length === 0 ||
            (fieldValues.goal.length > 0 &&
              fieldValues.goal.filter((it) => it === i2).length === 1)
          ) {
            // console.log(pillarList[i], i2);
            c.push({
              title: i2.header,
              dataIndex: i2.key /* + "-" + pillarList[i].key */,
              key: i2.key,
              width: 100,
              className:
                (i2.header.includes("Self")
                  ? "oddcolor "
                  : i2.header.includes("Peer")
                  ? "evenColor "
                  : " centerAign ") +
                (i2.header === "Overall - Peer" ||
                i2.header === "Overall - Self"
                  ? " boldTxt"
                  : ""),
            });
          }
        });
        temp.push({
          title: pillarList[i].pillar,
          dataIndex: pillarList[i].key,
          key: pillarList[i].key,
          children: c,
          className: "lightGrey",
        });
      }
    }
    setColumns([...temp]);
    if (clear) clearData();
    else getDataAPI();
    // console.log(temp, "column");
  };
  useEffect(() => {
    applyFilter(false);
    // console.log(pillarList);
  }, [
    pillarList,
    /*fieldValues.pillar , fieldValues.goal */
    ,
  ]);

  // useEffect(() => {
  //   console.log(completedPhaseYear);
  // }, [completedPhaseYear]);

  const getDataAPI = () => {
    setLoader(true);
    // console.log(pillarList);
    let pillarIds = [];
    pillarList.forEach((item) => {
      if (fieldValues.pillar.includes(item.pillar)) {
        pillarIds.push(item.key);
      }
    });
    dispatch({
      type: "GET_ADDITIONAL_REPORT_DATA",
      payload: {
        fyYear: fieldValues.fyYear.replace("FY ", ""),
        period: fieldValues.selectedperiod,
        filter: {
          startIndex: pageSize * (currentpageNo - 1),
          limit: pageSize,
          region: fieldValues.region,
          office: fieldValues.office,
          tenure: fieldValues.tenure,
          sector: fieldValues.primarySector,
          pillar: pillarIds,
        },
      },
    });
  };
  const clearData = () => {
    setLoader(true);
    dispatch({
      type: "GET_ADDITIONAL_REPORT_DATA",
      payload: {
        fyYear: fieldValues.fyYear.replace("FY ", ""),
        period: fieldValues.selectedperiod,
        filter: {
          startIndex: 0,
          limit: 10,
          region: [],
          office: [],
          tenure: [],
          sector: [],
          pillar: [],
        },
      },
    });
  };

  const exportAdditionalReport = () => {
    let pillarIds = [];
    pillarList.forEach((item) => {
      if (fieldValues.pillar.includes(item.pillar)) {
        pillarIds.push(item.key);
      }
    });
    dispatch({
      type: EXPORT_ADDITIONAL_REPORT,
      payload: {
        fyYear: fieldValues.fyYear.replace("FY ", ""),
        period: fieldValues.selectedperiod,
        filter: {
          region: fieldValues.region,
          office: fieldValues.office,
          tenure: fieldValues.tenure,
          sector: fieldValues.primarySector,
          pillar: pillarIds,
        },
      },
    });
    setIsPopupVisible(false);
  };

  const selectOnChange = (name, value) => {
    setFieldValues({ ...fieldValues, [name]: value });
    setCurrentpageNo(1);
    setPageSize(10);
    setTotalDataCount(0);
    if (name === "fyYear") {
      setFieldValues({
        ...fieldValues,
        [name]: value,
        selectedperiod:
          completedPhaseYear &&
          completedPhaseYear[value] &&
          completedPhaseYear[value] &&
          completedPhaseYear[value].length > 0
            ? completedPhaseYear[value][0]
            : "Plan",
      });
    }
  };
  const getRegions = (data) => {
    const groupRegions = _.groupBy(data, "region");
    let regions = [];
    Object.keys(groupRegions).forEach((key) => {
      regions.push(key);
    });
    return regions.sort();
  };
  const getOffices = (data, region) => {
    let flag = [];
    data.map((item) => {
      if (
        region.includes("FSO Americas") ||
        region.includes("National Assurance BUs") ||
        region.includes("CBS BUs")
      ) {
        flag = flag.concat(item.offices.filter((str) => str !== ""));
      } else if (region.includes(item.region)) {
        flag = flag.concat(item.offices);
      }
    });
    return _.uniq(flag.sort());
  };

  const handleChange = (label, value, e) => {
    // common on change function for select input year and phase
    // console.log(label, value, e);
    setFieldValues({ ...fieldValues, [label]: value });
    if (label == "region") {
      fieldValues.region.map((v) => {
        if (value.includes(v) <= 0)
          setFieldValues({ ...fieldValues, office: [], [label]: value });
      });
    }
    // else if (label == "primarySector") {//All value
    //   if (value.includes("All")) {
    //     if (!fieldValues.primarySector.includes("All")) {
    //       setFieldValues({
    //         ...fieldValues,
    //         [label]: filterData.data.sectors,
    //       });
    //     }
    //   } else {
    //     if (fieldValues.primarySector.includes("All"))
    //       setFieldValues({
    //         ...fieldValues,
    //         [label]: [],
    //       });
    //   }
    // }
    // setErrorMessages({ ...errorMessages, [fieldname]: "" });
  };
  return (
    <div className="additionalReporting">
      {loader && <Loader />}
      <SimpleBar style={{ maxHeight: "80vh" }}>
        <div
          className="top-div "
          style={{
            alignItems: "center",
          }}>
          <div
            style={{
              marginTop: "-8px",
              display: "flex",
              flexDirection: "row",
            }}>
            {
              <div class="date-div">
                <DropdownComponent
                  label="Select Year"
                  defaultValue={fieldValues.fyYear}
                  required={true}
                  selectOnChange={selectOnChange}
                  varName="fyYear"
                  list={
                    completedPhaseYear ? Object.keys(completedPhaseYear) : []
                  }
                  disabled={false}
                  errorMessage={errorMesaages}
                />
              </div>
            }
            {
              <div class="date-div">
                <DropdownComponent
                  label="Select Period"
                  defaultValue={fieldValues.selectedperiod}
                  required={true}
                  selectOnChange={selectOnChange}
                  varName="selectedperiod"
                  list={
                    completedPhaseYear && completedPhaseYear[fieldValues.fyYear]
                      ? completedPhaseYear[fieldValues.fyYear]
                      : []
                  }
                  disabled={false}
                  errorMessage={errorMesaages}
                />
              </div>
            }
          </div>
          <div
            className={"btn" + (totalDataCount === 0 ? " opacityReduced " : "")}
            style={{ height: "26px" }}
            onClick={() => {
              if (totalDataCount !== 0) {
                setIsPopupVisible(true);
              }
            }}>
            <i className="eyaa-share" style={{ marginRight: "5px" }} />
            Export
          </div>
        </div>
        <div className="userInputSection">
          <div className="multi-select">
            <div style={{ fontSize: "9px", marginLeft: "3px" }}>Region</div>
            <Select
              multiple={true}
              renderValue={(selected) => chipView(selected)}
              allowClear
              displayEmpty
              title={"region"}
              // bordered={false}
              style={{ width: "100%" }}
              value={fieldValues.region}
              onChange={(e) => handleChange("region", e.target.value, e)}>
              {!_.isEmpty(filterData.data) &&
                filterData.data.locations.length > 0 &&
                getRegions(filterData.data.locations).map((region, index) => (
                  <MenuItem
                    key={region}
                    value={region}
                    style={{ fontSize: "11px" }}>
                    {region}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="multi-select">
            <div style={{ fontSize: "9px", marginLeft: "3px" }}>Office</div>
            <Select
              displayEmpty
              multiple={true}
              renderValue={(selected) => chipView(selected)}
              allowClear
              disabled={
                fieldValues.region.length === 0 ||
                (!_.isEmpty(filterData.data) &&
                  filterData.data.locations.length > 0 &&
                  !getOffices(
                    filterData.data.locations,
                    fieldValues.region
                  ).some(notEmptyString))
              }
              title={"office"}
              value={fieldValues.office}
              bordered={false}
              style={{ width: "100%" }}
              defaultValue={[]}
              onChange={(e) => handleChange("office", e.target.value, e)}>
              {fieldValues.region.length != 0 &&
                !_.isEmpty(filterData.data) &&
                filterData.data.locations.length > 0 &&
                getOffices(filterData.data.locations, fieldValues.region).map(
                  (data, index) => (
                    <MenuItem
                      key={data}
                      value={data}
                      style={{ fontSize: "11px" }}>
                      {data}{" "}
                    </MenuItem>
                  )
                )}
            </Select>
          </div>
          <div className="multi-select">
            <div style={{ fontSize: "9px", marginLeft: "3px" }}>
              Tenure (Year)
            </div>
            <Select
              multiple={true}
              renderValue={(selected) => chipView(selected)}
              allowClear
              displayEmpty
              title={"tenure"}
              value={fieldValues.tenure}
              bordered={false}
              style={{ width: "100%" }}
              defaultValue={[]}
              onChange={(e) => handleChange("tenure", e.target.value, e)}>
              {!_.isEmpty(filterData.data) &&
                filterData.data.tenure.length > 0 &&
                filterData.data.tenure.map((item, i) => (
                  <MenuItem
                    key={item}
                    value={item}
                    style={{ fontSize: "11px" }}>
                    {item}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="multi-select">
            <div style={{ fontSize: "9px", marginLeft: "3px" }}>
              Prim Sector
            </div>
            <Select
              multiple={true}
              renderValue={(selected) => chipView(selected)}
              allowClear
              displayEmpty
              title={"primarySector"}
              value={fieldValues.primarySector}
              bordered={false}
              style={{ width: "100%" }}
              defaultValue={[]}
              onChange={(e) =>
                handleChange("primarySector", e.target.value, e)
              }>
              {!_.isEmpty(filterData.data) &&
                filterData.data.sectors.length > 0 &&
                filterData.data.sectors.sort().map((sector, index) => (
                  <MenuItem
                    key={sector}
                    value={sector}
                    style={{ fontSize: "11px" }}>
                    {sector}
                  </MenuItem>
                ))}
            </Select>
          </div>

          <div className="multi-select">
            <div style={{ fontSize: "9px", marginLeft: "3px" }}>Pillar</div>
            <Select
              multiple={true}
              renderValue={(selected) => chipView(selected)}
              allowClear
              displayEmpty
              title={"pillar"}
              value={fieldValues.pillar}
              bordered={false}
              style={{ width: "100%" }}
              defaultValue={[]}
              onChange={(e) => handleChange("pillar", e.target.value, e)}>
              {!_.isEmpty(filterData.data) &&
                filterData.data.pillars.length > 0 &&
                _.sortBy(filterData.data.pillars, [
                  function (o) {
                    return o.pillarName;
                  },
                ]).map((item, i) => (
                  <MenuItem
                    key={item.pillarName}
                    value={item.pillarName}
                    style={{ fontSize: "11px" }}>
                    {item.pillarName}
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div
            className={
              "btn" +
              (nonEmptyFilterData() && checkifDifference()
                ? " yellow-button "
                : " opacityReduced ")
            }
            style={{ height: "26px", marginLeft: "26px" }}
            onClick={() => {
              if (nonEmptyFilterData() && checkifDifference()) {
                setLastFilterData({
                  region: fieldValues.region,
                  office: fieldValues.office,
                  tenure: fieldValues.tenure,
                  primarySector: fieldValues.primarySector,
                  pillar: fieldValues.pillar,
                });
                setPageSize(10);
                setCurrentpageNo(1);
                applyFilter(false);
              }
            }}>
            Apply
          </div>
          <div
            className={
              "btn" + (!nonEmptyFilterData() ? " opacityReduced " : " ")
            }
            style={{ height: "26px" }}
            onClick={() => {
              if (nonEmptyFilterData()) {
                setFieldValues({
                  ...fieldValues,
                  region: [],
                  office: [],
                  tenure: [],
                  primarySector: [],
                  pillar: [],
                });
                setLastFilterData({
                  ...lastFilterData,
                  region: [],
                  office: [],
                  tenure: [],
                  primarySector: [],
                  pillar: [],
                });
              }
              applyFilter(true);
            }}>
            Clear
          </div>
          {/* <div className="multi-select">
            <Typography level={5}>Goal</Typography>
            <Select
             multiple={true}
              renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
              value={fieldValues.goal}
              allowClear
              bordered={false}
              showArrow
              style={{ width: "100%" }}
              placeholder="Select"
              defaultValue={[]}
              maxTagCount="responsive"
              onChange={(value) => handleChange("goal", value)}>
              {pillarList
                .filter(
                  (p) =>
                    fieldValues.pillar.length == 0 ||
                    fieldValues.pillar.indexOf(p.pillar) > -1
                )
                .map((item, i) =>
                  item.sections.map((i) => (
                    <MenuItem key={i} style={{ fontSize: "11px" }}>
                      {i}
                    </MenuItem>
                  ))
                )}
            </Select>
          </div> */}
        </div>
        {totalDataCount !== 0 ? (
          <div style={{ margin: "10px" }}>
            {
              <Table
                rowClassName={(record, index) =>
                  index % 2 === 0 ? "table-row-odd" : "table-row-even"
                }
                data={dataSource}
                useFixedHeader={true}
                columns={columns}
                pagination={false}
                bordered
                scroll={{ x: "calc(650px )", y: "300px" }}
                className="table-striped-rows"
              />
            }
            <div className="paginationSection">
              <i
                className=" eyaa-chevron-right"
                onClick={() => {
                  if (currentpageNo !== Math.ceil(totalDataCount / pageSize)) {
                    setCurrentpageNo(Math.ceil(totalDataCount / pageSize));
                  }
                }}
                style={{
                  borderRight: "1px solid rgba(0, 0, 0, 0.45)",
                  margin: "5px 10px",
                  cursor: `${
                    currentpageNo === Math.ceil(totalDataCount / pageSize)
                      ? "not-allowed"
                      : "pointer"
                  }`,
                }}
              />
              <i
                className=" eyaa-chevron-right"
                onClick={() => {
                  if (currentpageNo !== Math.ceil(totalDataCount / pageSize)) {
                    setCurrentpageNo(currentpageNo + 1);
                  }
                }}
                style={{
                  margin: "5px 6px",
                  cursor: `${
                    currentpageNo === Math.ceil(totalDataCount / pageSize)
                      ? "not-allowed"
                      : "pointer"
                  }`,
                }}
              />
              <div style={{ margin: "4px" }}>
                {" "}
                {currentpageNo + "/" + Math.ceil(totalDataCount / pageSize)}
              </div>
              <i
                className=" eyaa-chevron-left"
                style={{
                  margin: "5px 6px",
                  cursor: `${currentpageNo === 1 ? "not-allowed" : "pointer"}`,
                }}
                onClick={() => {
                  if (currentpageNo !== 1) {
                    setCurrentpageNo(currentpageNo - 1);
                  }
                }}
              />
              <i
                className=" eyaa-chevron-left"
                style={{
                  borderLeft: "1px solid rgba(0, 0, 0, 0.45)",
                  margin: "5px 10px",

                  cursor: `${currentpageNo === 1 ? "not-allowed" : "pointer"}`,
                }}
                onClick={() => {
                  if (currentpageNo !== 1) {
                    setCurrentpageNo(1);
                  }
                }}
              />
              <Select
                defaultValue={10}
                value={pageSize}
                style={{ fontSize: "11px" }}
                onChange={(e) => {
                  setPageSize(e.target.value);
                  setCurrentpageNo(1);
                }}>
                {pageSizeOptions.map((item) => (
                  <MenuItem
                    value={item}
                    key={item}
                    style={{ fontSize: "11px" }}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <span
                style={{
                  marginRight: "5px",
                  color: "#383535",
                  paddingBottom: "2px",
                }}>
                {" "}
                Show
              </span>
              {/* <Pagination
              current={currentpageNo}
              onChange={(value) => {
                console.log(value);
                setCurrentpageNo(value);
              }}
              pageSizeOptions={pageSizeOptions}
              total={totalDataCount}
              onShowSizeChange={() => {}}
            /> */}
            </div>
          </div>
        ) : (
          <div className="no-data-found">
            {" "}
            No data found. Please apply different filters
          </div>
        )}
      </SimpleBar>
      {isPopupVisible && (
        <div className="popup-dialog">
          <div className="dialog">
            <div className="header">
              <h5 className="popup-header">Confirmation</h5>
              <span
                className="icon-close-filled icon"
                onClick={() => setIsPopupVisible(false)}></span>
            </div>
            <div className="content">
              <p>Would you like to export the additional report ?</p>
              <p>
                Please note that this operation would take some time. Once
                completed, we will share the report over an email.
              </p>
            </div>
            <div className="footer">
              <button
                className="cancel-btn"
                onClick={() => setIsPopupVisible(false)}>
                Cancel
              </button>
              <button className="confirm-btn" onClick={exportAdditionalReport}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AdditionalReporting;
