import React, { useState, useEffect, useContext, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppContext from "../../../../utils/appContext";
import { makeStyles } from "@material-ui/core/styles";
import DropdownComponent from "../../AdminGeneralParam/DropdownComponent/DropdownComponent";
import PlaylistAddSharpIcon from "@material-ui/icons/PlaylistAddSharp";
import CardComponent from "./CardComponent";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import AlertSubmitPopup from "../../../Common/AlertSubmitPopup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import SimpleBar from "simplebar-react";
import SortableList, { SortableItem } from "react-easy-sort";
import ReactQuill from "react-quill";
import "../AssessmentAdmin.scss";
import "react-quill/dist/quill.snow.css";
import ReactHtmlParser from "react-html-parser";
import GoalDetails from "./GoalDetails";
import _ from "lodash";
import Loader from "../../../Loader/Loader";
import { toast, Flip } from "react-toastify";
import classNames from "classnames";
import { ifNonEmptyInput } from "../../../../helpers/textValidator";
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  Tooltip,
} from "@material-ui/core";

import EditorToolbar, {
  modules0,
  modules1,
  modules2,
  formats,
} from "./EditToolBar";
import {
  DELETE_ASSESSMENT_GOAL,
  REORDER_ASSESSMENT_GOAL,
  RESET_REORDER_ASSESSMENT_GOAL,
} from "../../../../actions/types";
import { errorCheck, getErrorMessages } from "../../../../utils/utils";
import NavigationPrompt from "react-router-navigation-prompt";

const useStyles = makeStyles((theme) => ({
  root1: {
    "& .MuiFilledInput-underline.Mui-error:after": {
      borderBottom: "1px solid #f44336",
    },
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-underline": {
      width: "250px",
      height: "27px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      //   borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      //   "&:hover:not($disabled):not($focused):not($error):before": {
      //     borderBottom: "none",
      //   },
      " &:hover:before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      },
      " &:hover:after": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  // labelroot: {
  //   backgroundColor: "rgb(246 ,246, 250)!important",
  //   color: "rgba(0, 0, 0, 0.94)",
  //   paddingLeft: "5px",
  //   paddingTop: "5px",
  //   fontSize: "8px",
  // },
  // topSpacing: {
  //   marginTop: "16px",
  // },
  // topSyli: {
  //   marginBottom: " -30px",
  //   marginTop: "16px",
  //   zIndex: "8",
  //   width: "115px",
  // },
  // half: {
  //   display: "flex",
  //   flexDirection: "row",
  //   width: "350px",
  //   marginLeft: "15px",
  // },
  root900: {
    "& .MuiFilledInput-underline.Mui-error:after": {
      borderBottom: "1px solid #f44336",
    },
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-underline": {
      width: "900px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
      fontFamily: "EYInterState-Regular",
    },
  },
  root: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "400px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
      fontFamily: "EYInterState-Regular",
    },
  },
  rootMulti: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "677px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  labelroot: {
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "8px",
    // fontWeight: "600",
  },
  labelroot1: {
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "10px",
    fontWeight: "600",
  },
  topSpacing: {
    marginTop: "8px",
  },
  topSyli: {
    marginBottom: " -30px",
    marginTop: "16px",
    zIndex: "8",
    width: "100px",
  },
  half: {
    display: "flex",
    flexDirection: "row",
  },
  arrow: {
    position: "absolute",
    fontSize: 14,
    width: "6em",
    height: "4em",
    //   transform: "rotate(225deg)",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  paper2: {
    backgroundColor: "white",
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 2, 2),
    width: 170,
    overflow: "hidden",
    overflowX: "hidden",
    fontSize: "11px",
  },
  options1: {
    borderBottom: "1px solid grey",
    padding: "5px 0px 5px 5px",
    display: "flex",
    alignItems: "center",
    height: "24px",
    cursor: "pointer",
  },
  options2: {
    padding: "5px 0px 5px 5px",
    display: "flex",
    alignItems: "center",
    height: "24px",
    cursor: "pointer",
  },
  popper: {
    // zIndex: 1,
    left: "-55px!important",
    '&[x-placement*="top-end"]': {
      top: "-12px!important",
    },
    '&[x-placement*="bottom-end"]': {
      top: "9px!important",
    },
    '&[x-placement*="bottom"] $arrow': {
      top: 2,
      left: "105px!important",
      marginTop: "-0.9em",
      width: "6em",
      height: "2em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: "103px!important",
      marginBottom: "-3.6em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },

  customTooltip: {
    // I used the rgba color for the standard "secondary" color
    maxWidth: "120px",
    fontSize: "10px",
  },
}));

const GlobalMetric = (props) => {
  const classes = useStyles();
  const myContext = useContext(AppContext);
  const dispatch = useDispatch();
  const inputValuesforType = {
    radio_button: "Radio Button",
    check_box: "CheckBox",
    drop_down: "Drop Down",
  };
  const globalMatricType = ["Qualitative", "Quantitative"];
  const [backUpvalues, setBackUpvalues] = useState({});
  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevAssessmentData = usePrevious({
    assessmentData: props.assessmentData,
  });
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupType, setPopupType] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isCheckboxClicked, setIsCheckboxClicked] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const [values, setValues] = useState({
    globalMetricName: "",
    // order: "",
    pillarId: "",
    description: "",
    metricType: "",
    displayName: "",
    iconName: "icon-object-032",
    notesText: [""],
    goalData: [
      {
        goalName: "",
        goalDescription: "",
        noOfOptions: "",
        goalType: "",
        goalOptions: [],
        goalOptionNo: "",
        sectionValue: [],
        sectionValues: [],
        data: [],
      },
    ],
    impactStatements: [],
  });
  const prevValues = usePrevious(values);
  const [errorMessages, setErrorMessages] = useState({
    // order: "",
    globalMetricName: "",
    displayName: "",
    description: "",
    metricType: "",
    goalName: "",
    goalDescription: "",
    noOfOptions: "",
    goalType: "",
    goalOptions: [],
    goalOptionNo: "",
    sectionValues: "",
    data: "",
  });
  const outsideClickState = useSelector((state) => state.outsideClickCommon);
  const allSectionDataFetch = useSelector(
    (state) => state.getAssessmentSectionList
  );
  const assessmentSectionList = allSectionDataFetch
    ? allSectionDataFetch.data
      ? allSectionDataFetch.data.data
        ? allSectionDataFetch.data.data.assessmentDetails
        : []
      : []
    : [];

  const impactStatement = useSelector((state) => state.addImpactStatement);
  const moveImpactStatement = useSelector((state) => state.moveImpactStatement);
  const deleteImpactStatement = useSelector(
    (state) => state.deleteImpactStatement
  );
  const sortImpactStatement = useSelector((state) => state.sortImpactStatement);
  const reorderAssessmentGoalData = useSelector(
    (state) => state.reorderAssessmentGoal
  );
  const assessmentGoalData = useSelector((state) => state.getAssesmentGoalData);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [moveToOrFilter, setMoveToOrFilter] = useState(0); //0 forfilter and 1 for
  const [selectedSectionIndex, setselectedSectionIndex] = useState(0);
  const [score, setScore] = useState(1);
  const [onHover, setOnHover] = useState(false);
  const [deleteType, setDeleteType] = useState(0);
  const [editable, setEditable] = useState(true);
  const [toggleflag, toggleView] = useState(false);
  const [addView, setAddView] = useState(false);
  const [changeInFilter, setChangeInFilter] = useState(false);
  const [anchor, setanchor] = React.useState(null);
  const [arrowRef, setArrowRef] = React.useState(null);
  const openpopUp = Boolean(anchor);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [disableGoalSection, setDisableGoalSection] = useState(false);
  const [globalMetriceditable, setGlobalMetriceditable] = useState(false);
  const [addNewGoalScreenEnabled, setAddNewGoalScreenEnabled] = useState(false);
  const [cardEditIndex, setCardEditIndex] = useState(-1);
  const [deleteNoteIndex, setDeleteNoteIndex] = useState(-1);
  const [activeNoteIndex, setactiveNoteIndex] = useState(0);
  const [saveGoal, setSaveGoal] = useState(0);
  const [disableSaveGoal, setDisableSaveGoal] = useState(true);
  const [disableSaveGlobalMetric, setDisableSaveGlobalMetric] = useState(true);
  const [goalReorderFlag, setGoalReorderFlag] = useState(false);
  const [sectionName, setSectionName] = useState("");
  const [currentImpactStatements, setCurrentImpactStatements] = useState([]);
  const [sortedStatements, setSortedStatements] = useState([]);
  const [selectedStatements, setSelectedStatements] = useState([]);
  const [bulkMoveImpactStatements, setBulkMoveImpactStatements] = useState([]);
  const [tempSectionTabInfo, setTempSectionTabInfo] = useState({
    id: "",
    name: "",
  });
  const [tempGoalId, setTempGoalId] = useState(-1);
  const [currentEditStatementId, setCurrentEditStatementId] = useState(-1);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedMoveToSection, setSelectedMoveToSection] = useState("");
  const [isStatementsSorted, setIsStatementsSorted] = useState(false);
  const callAddGlobalMetricAPI = () => {
    const payload = {
      pillar: "",
      type: "",
      iconUrl: "",
      pillarDisplayName: "",
      displayOrder:
        props.assessmentData && props.assessmentData.length > 0
          ? props.assessmentData[props.assessmentData.length - 1].displayOrder +
          1
          : 1,
      financialYear: "",
      pillarOverview: "",
      pillarNotes: [],
    };
    payload.pillar = values.globalMetricName;
    payload.type = values.metricType;
    payload.pillarDisplayName = values.displayName;
    payload.iconUrl = values.iconName;
    payload.financialYear = Number.parseInt(props.fyYear.replace("FY ", ""));
    payload.pillarOverview = values.description;
    values.notesText &&
      values.notesText.forEach((item) => {
        if (item !== "") {
          if (item.replace(/<[^>]*>/g, " ").trim() !== "")
            payload.pillarNotes.push(item);
        }
      });
    // payload.pillarNotes = values.notesText;
    if (props.activeGoal === -1) {
      dispatch({
        type: "ADD_GLOBAL_METRIC",
        payload: payload,
      });
    } else {
      payload.pillarId = values.pillarId;
      payload.displayOrder = values.order;
      dispatch({
        type: "EDIT_GLOBAL_METRIC",
        payload: payload,
      });
    }
  };
  useEffect(() => {
    if (
      outsideClickState.data &&
      outsideClickState.data.feature === "AssessmentAdmin" &&
      !outsideClickState.data.OpenModal &&
      outsideClickState.data.ProceedWithNavigation
    ) {
      //save on cancel button click,
      const outClickCallback = outsideClickState.data.callback;
      if (outClickCallback && Object.keys(outClickCallback).length > 0) {
        outClickCallback.fn && outClickCallback.fn(outClickCallback.params);
        dispatch({
          type: "OUTSIDE_CLICK_COMMON_RESET",
          payload: {},
        });
      }
    }
    // eslint-disable-next-line
  }, [outsideClickState]);

  useEffect(() => {
    const isError = errorCheck(errorMessages);
    if (isError) setDisableSaveGlobalMetric(isError);
  }, [errorMessages]);

  const validateInput = (label) => {
    let text = values[label];
    if (!ifNonEmptyInput(text)) {
      setValues({ ...values, [label]: "" });
    } else {
      setValues({ ...values, [label]: text.trim() });
    }
  };

  const textOnChange = (value, label) => {
    setDisableSaveGlobalMetric(false);
    dispatch({
      type: "OUTSIDE_CLICK_COMMON",
      payload: {
        feature: "AssessmentAdmin",
        changesDone: true,
        OpenModal: "",
        ProceedWithNavigation: "",
      },
    });
    if (label == "description") {
      if (value.length < 150) setValues({ ...values, [label]: value });
    } else if (label === "metricType") {
      if (value.length < 30) {
        setValues({ ...values, [label]: value });
      }
    } else {
      if (value.length < 50) setValues({ ...values, [label]: value });
    }
  };
  const clickAwayHandler = () => {
    setanchor(null);
  };
  // const CustomToolbar = () => (
  // <div id="toolbar">
  //   <select
  //     className="ql-header"
  //     defaultValue={""}
  //     onChange={(e) => e.persist()}>
  //     <option value="1" />
  //     <option value="2" />
  //     <option selected />
  //   </select>
  //   <button className="ql-bold" />
  //   <button className="ql-italic" />
  //   <select className="ql-color">
  //     <option value="red" />
  //     <option value="green" />
  //     <option value="blue" />
  //     <option value="orange" />
  //     <option value="violet" />
  //     <option value="#d0d1d2" />
  //     <option selected />
  //   </select>
  //   <button className="ql-insertStar">
  //     <CustomButton />
  //   </button>
  // </div>
  // );

  const moveTo = (e) => {
    setMoveToOrFilter(1);
    setanchor(e.currentTarget);
  };

  const setImpactStatements = () => {
    let copyGoalData = _.cloneDeep(values.goalData);
    let impactStatements = [];
    if (
      copyGoalData &&
      copyGoalData[selectedId] &&
      copyGoalData[selectedId].data
    ) {
      const areAllStatementsGeneral = copyGoalData[selectedId].data.every(
        (goal) => goal.type === "General"
      );
      if (toggleflag) {
        if (areAllStatementsGeneral) {
          copyGoalData[selectedId].data.forEach((val) => {
            val.type =
              assessmentSectionList.length > 0
                ? assessmentSectionList[0].name
                : "";
          });
          impactStatements = copyGoalData[selectedId].data;
          if (isCheckboxClicked && !isFirstLoad) {
            setIsCheckboxClicked(false);
            // setBulkMoveImpactStatements(impactStatements);
          }
        } else {
          //setBulkMoveImpactStatements([]);
          copyGoalData[selectedId].data.forEach((val) => {
            if (val.type === sectionName) {
              impactStatements.push({
                impactActions: val.impactActions,
                type: val.type,
                action_id: val.action_id,
                checked: false,
              });
            }
          });
        }
      } else {
        copyGoalData[selectedId].data.forEach((val) => {
          val.type = "General";
        });
        impactStatements = copyGoalData[selectedId].data;
        if (isCheckboxClicked && !isFirstLoad) {
          setIsCheckboxClicked(false);
          // setBulkMoveImpactStatements(impactStatements);
        }
      }
    }
    setValues({
      ...values,
      goalData: [...copyGoalData],
      impactStatements,
    });
  };

  const setInitialSectionInfo = () => {
    if (
      props.assessmentData[props.activeGoal] &&
      props.assessmentData[props.activeGoal].section[selectedId] &&
      props.assessmentData[props.activeGoal].section[selectedId].sectionValues
        .length > 0
    ) {
      toggleView(true);
    } else {
      toggleView(false);
    }
  };

  useEffect(() => {
    setImpactStatements();
  }, [sectionName]);

  useEffect(() => {
    if (!_.isEqual(values, prevValues)) {
      setImpactStatements();
    }
  }, [values.goalData]);

  useEffect(() => {
    const { pillarId, globalMetricName, displayName, metricType, description } =
      values;
    if (pillarId !== "") {
      const obj = { globalMetricName, displayName, metricType, description };
      let localErrorMessage = getErrorMessages(obj, [
        "globalMetricName",
        "displayName",
        "metricType",
        "description",
      ]);
      setErrorMessages((prevState) => ({
        ...prevState,
        ...localErrorMessage,
      }));
    }
  }, [values]);

  useEffect(() => {
    if (selectedTab === 1) {
      setselectedSectionIndex(0);
      setInitialSectionInfo();
      // setImpactStatements();
      setIsFirstLoad(true);
      props.getAssessmentData();
    }
  }, [selectedTab]);

  useEffect(() => {
    if (!addNewGoalScreenEnabled && selectedTab === 1) {
      setselectedSectionIndex(0);
      setInitialSectionInfo();
      // setImpactStatements();
      setIsFirstLoad(true);
      props.getAssessmentData();
    }
  }, [selectedId]);

  /* useEffect(() => {
   setInitialSectionInfo();
  }, []);*/

  useEffect(() => {
    if (toggleflag) {
      let flag = false;

      const isAltleastOneSelected = values.goalData[
        selectedId
      ].sectionValues.some((val) => val.checked);
      if (
        !isAltleastOneSelected &&
        values.goalData[selectedId].sectionValues.length > 0
      ) {
        const copyValues = _.cloneDeep(values);
        setSectionName(values.goalData[selectedId].sectionValues[0].name);
        setselectedSectionIndex(0);
        copyValues.goalData[selectedId].sectionValues[0].checked = true;
        setValues(copyValues);
      } else {
        if (
          currentImpactStatements.length === 0 &&
          bulkMoveImpactStatements.length === 0 &&
          selectedStatements.length === 0 &&
          !isStatementsSorted
        ) {
          values.goalData[selectedId].sectionValues.forEach((ele, id) => {
            if (ele.checked && !flag) {
              setSectionName(ele.name);
              setselectedSectionIndex(id);
              flag = true;
            }
          });

          if (!flag) {
            //  toggleView(false);
            setanchor(null);
          }
        }
      }
    } else {
      setSectionName("General");
      /*  const copyValues = _.cloneDeep(values);
     copyValues.goalData[selectedId].sectionValue = [];
     setValues(copyValues);*/
    }

    // eslint-disable-next-line
  }, [changeInFilter, toggleflag, selectedId]);

  /* useEffect(() => {
    if (
      !toggleflag &&
      values.goalData[selectedId] &&
      values.goalData[selectedId].sectionValues
    ) {
      let Completegoal = values.goalData;
      let sectionValue =
        values.goalData[selectedId] && values.goalData[selectedId].sectionValues
          ? values.goalData[selectedId].sectionValues
          : [];
      sectionValue.forEach((item) => (item.checked = true));
      Completegoal[selectedId].sectionValues = [...sectionValue];
      setValues({ ...values, goalData: Completegoal });
    }
    // eslint-disable-next-line
  }, [toggleflag]); */

  useEffect(() => {
    setValues({
      ...values,
      iconName: props.iconName || "icon-object-032",
    });
    // eslint-disable-next-line
  }, [props.iconName]);

  useEffect(() => {
    if (
      backUpvalues.notesText &&
      values.notesText.length == backUpvalues.notesText.length &&
      !_.isEqual(values, prevValues)
    )
      values.notesText.forEach((text, index) => {
        if (text !== backUpvalues.notesText[index]) {
          dispatch({
            type: "OUTSIDE_CLICK_COMMON",
            payload: {
              feature: "AssessmentAdmin",
              changesDone: true,
              OpenModal: "",
              ProceedWithNavigation: "",
            },
          });
        }
      });
    //   // eslint-disable-next-line
  }, [values.notesText]);

  useEffect(() => {
    dispatch({
      type: "OUTSIDE_CLICK_COMMON_RESET",
      payload: {},
    });
    if (props.activeGoal !== -1) {
      let globalData = props.assessmentData[props.activeGoal];

      let array = [];
      globalData.section &&
        globalData.section.length > 0 &&
        globalData.section.forEach((element) => {
          const {
            goalId,
            goalName,
            goalDescription,
            goalRemark,
            uiType,
            goalOptions,
            considerForScoring,
            checkboxLabel,
            sectionValues = [],
            data = [],
          } = element;
          const currentSections = [];
          /* const temps = [...sectionValues];
          console.log(sectionValues);
          for (let i = 0; i < temps.length; i++) {
            temps[i].checked = true;
          }
          assessmentSectionList.data.forEach((section) => {
            const isSectionAvailble = temps.some(
              (temp) => temp.name === section.name
            );
            if (!isSectionAvailble) {
              temps.push({
                checked: false,
                description: section.description,
                name: section.name,
              });
            }
          }); */
          assessmentSectionList.forEach((section) => {
            const isSectionAvailable = sectionValues.some(
              (val) => val.name === section.name
            );
            currentSections.push({
              checked: isSectionAvailable,
              description: section.description,
              name: section.name,
            });
          });
          let temp = {
            goalId,
            goalName,
            goalDescription,
            goalRemark,
            uiType,
            goalOptions,
            considerForScoring,
            checkboxLabel,
            sectionValues: currentSections,
            data: data,
          };
          array.push(temp);
        });
      setValues({
        ...values,
        globalMetricName: globalData.pillar,
        pillarId: globalData.pillarId,
        displayName: globalData.pillarDisplayName,
        metricType: globalData.type ? globalData.type : "",
        description: globalData.pillarOverview,
        notesText: globalData.pillarNotes
          ? globalData.pillarNotes.length > 0
            ? globalData.pillarNotes
            : [""]
          : [""],
        order: globalData.displayOrder,
        iconName: globalData.iconUrl || "icon-object-032",
        goalData: [...array],
      });
      setBackUpvalues({
        ...values,
        globalMetricName: globalData.pillar,
        pillarId: globalData.pillarId,
        displayName: globalData.pillarDisplayName,
        metricType: globalData.type ? globalData.type : "",
        description: globalData.pillarOverview,
        notesText: globalData.pillarNotes,
        order: globalData.displayOrder,
        iconName: globalData.iconUrl || "icon-object-032",
        goalData: [...array],
      });
      if ((addNewGoalScreenEnabled && selectedTab === 0) || (deleteType === 0 && array.length <= selectedId))
        setSelectedId(array.length > -1 ? array.length - 1 : 0);
      if (props.editGlobalMetric) {
        setGlobalMetriceditable(true);
        setactiveNoteIndex(0);
        setDisableSaveGlobalMetric(true);
      } else {
        setDisableGoalSection(false);
        setGlobalMetriceditable(false);
      }
    } else {
      setDisableGoalSection(true);
      setGlobalMetriceditable(true);
    }
    try {
      if (
        props.assessmentData[props.activeGoal].section.length <
        prevAssessmentData.assessmentData[props.activeGoal].section.length
      ) {
        if (
          props.assessmentData[props.activeGoal].section.length < 7 &&
          addNewGoalScreenEnabled
        ) {
          setAddNewGoalScreenEnabled(false);
          if (selectedTab === 0) setSelectedId(0);
        }
      }
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, [props.activeGoal, props.assessmentData, allSectionDataFetch]);
  const handleNotesTextChange = (value, index, delta, source, editor) => {
    // console.log(source, "soooo");
    // if (editor.getSelection() !== null) {
    //   dispatch({
    //     type: "OUTSIDE_CLICK_COMMON",
    //     payload: {
    //       feature: "AssessmentAdmin",
    //       changesDone: true,
    //       OpenModal: "",
    //       ProceedWithNavigation: "",
    //     },
    //   });
    // }
    if (source === "user") {
      setDisableSaveGlobalMetric(false);
    }
    if (editor.getLength() < 502) {
      let temp = values.notesText;
      temp[index] = value;
      setValues({ ...values, notesText: [...temp] });
    }
    else {
      let temp = values.notesText;
      temp[index] = values.notesText[index];
      setValues({ ...values, notesText: [...temp] });
    }
  };

  useEffect(() => {
    if (impactStatement.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (
        !_.isEmpty(impactStatement.data) &&
        impactStatement.data.status === "success"
      ) {
        const copyValues = _.cloneDeep(values);
        /*currentImpactStatements.forEach((data) => {
          const isAlreadyExist = copyValues.goalData[
            selectedId
          ].data.some(
            (statement) => statement.action_id === data.action_id
          );
          if (isAlreadyExist) {
            const index = copyValues.goalData[
              selectedId
            ].data.findIndex(
              (statement) => statement.action_id === data.action_id
            );
            copyValues.goalData[selectedId].data[index].impactActions =
              data.impactActions;
          } else {
            copyValues.goalData[selectedId].data.push(data);
          }
        });*/
        copyValues.goalData[selectedId].data = impactStatement.data.data;
        setValues(copyValues);
        setCurrentImpactStatements([]);
        props.getAssessmentData();
        dispatch({
          type: "ADD_IMPACT_STATEMENT_RESET",
          payload: {},
        });
        notify("Your changes have been saved.");
      }
    }
  }, [impactStatement]);

  useEffect(() => {
    if (moveImpactStatement.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (
        !_.isEmpty(moveImpactStatement.data) &&
        moveImpactStatement.data.status === "success"
      ) {
        const copyValues = _.cloneDeep(values);
        copyValues.goalData[selectedId].data = moveImpactStatement.data.data;
        setValues(copyValues);
        //props.getAssessmentData();
        notify("Impact statement(s) have been moved.");
        dispatch({
          type: "MOVE_IMPACT_STATEMENT_RESET",
          payload: {},
        });
        setBulkMoveImpactStatements([]);
        setCurrentImpactStatements([]);
        setSelectedStatements([]);
      }
    }
  }, [moveImpactStatement]);

  useEffect(() => {
    if (deleteImpactStatement.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (
        !_.isEmpty(deleteImpactStatement.data) &&
        deleteImpactStatement.data.status === "success"
      ) {
        const copyValues = _.cloneDeep(values);
        copyValues.goalData[selectedId].data = deleteImpactStatement.data.data;
        // props.getAssessmentData();
        notify("Impact statement(s) have been deleted.");
        dispatch({
          type: "DELETE_IMPACT_STATEMENT_RESET",
          payload: {},
        });
        const copyCurrentImpactStatements = _.cloneDeep(
          currentImpactStatements
        );
        selectedStatements.forEach((statement) => {
          const currentImpactIndex = copyCurrentImpactStatements.findIndex(
            (s) => statement.impactActions === s.impactActions
          );
          copyCurrentImpactStatements.splice(currentImpactIndex, 1);
          const index = copyValues.impactStatements.findIndex(
            (s) => statement.impactActions === s.impactActions
          );
          copyValues.impactStatements.splice(index, 1);
        });
        setCurrentImpactStatements(copyCurrentImpactStatements);
        setValues(copyValues);
        setSelectedStatements([]);
        // setImpactStatements();
      }
    }
  }, [deleteImpactStatement]);

  useEffect(() => {
    if (sortImpactStatement.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (
        !_.isEmpty(sortImpactStatement.data) &&
        sortImpactStatement.data.status === "success"
      ) {
        const copyValues = _.cloneDeep(values);
        copyValues.goalData[selectedId].data = sortImpactStatement.data.data;
        setValues(copyValues);
        //props.getAssessmentData();
        notify("Your changes have been saved.");
        dispatch({
          type: "SORT_IMPACT_STATEMENT_RESET",
          payload: {},
        });
        setCurrentImpactStatements([]);
        setSortedStatements([]);
        setIsStatementsSorted(false);
      }
    }
  }, [sortImpactStatement]);

  useEffect(() => {
    if (
      reorderAssessmentGoalData &&
      reorderAssessmentGoalData.data &&
      !reorderAssessmentGoalData.fetching &&
      reorderAssessmentGoalData.data.message === "success"
    ) {
      notify("Goal reordered successfully");
      dispatch({
        type: RESET_REORDER_ASSESSMENT_GOAL,
        payload: {},
      });
    } else if (
      reorderAssessmentGoalData &&
      reorderAssessmentGoalData.data &&
      reorderAssessmentGoalData.fetching
    ) {
      setLoader(true);
    }
    // eslint-disable-next-line
  }, [reorderAssessmentGoalData]);

  useEffect(() => {
    if (
      currentImpactStatements.length !== 0 ||
      bulkMoveImpactStatements.length !== 0 ||
      isStatementsSorted
    ) {
      props.changesInChild(true);
    } else {
      props.changesInChild(false);
    }
  }, [currentImpactStatements, bulkMoveImpactStatements, isStatementsSorted]);

  const { activeGoal, updateGoalName, setLoader, disableFields } = props;
  const goalDetails = values && values.goalData && values.goalData[selectedId];
  const goalMetadata = {
    selectedPillarIndex: activeGoal,
    selectedGoalIndex: selectedId,
    pillarId: values && values.pillarId,
    goalId:
      values &&
      values.goalData &&
      values.goalData[selectedId] &&
      values.goalData[selectedId].goalId,
    financialYear: Number.parseInt(props.fyYear.replace("FY ", "")),
  };
  const goalAdded =
    activeGoal !== -1 &&
    activeGoal !== undefined &&
    values &&
    values.goalData &&
    values.goalData.length > 0;
  const disableGoalReorder =
    disableFields ||
    _.isEmpty(goalMetadata.goalId) ||
    globalMetriceditable ||
    (values.goalData && values.goalData.length <= 1) ||
    values.goalData.findIndex((goal) => goal.goalId === "") !== -1;

  const goalDelete = () => {
    const { financialYear, pillarId, goalId } = goalMetadata;
    const payloadObj = { financialYear, pillarId, goalId };
    const type = DELETE_ASSESSMENT_GOAL;
    try {
      dispatch({
        type,
        payload: { payloadObj },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onSortEnd = (oldIndex, newIndex) => {
    setGoalReorderFlag(true);
    const goalArray = values && values.goalData ? [...values.goalData] : [];
    var element = goalArray[oldIndex];
    goalArray.splice(oldIndex, 1);
    goalArray.splice(newIndex, 0, element);
    setValues({ ...values, goalData: goalArray });
    setSelectedId(newIndex);
    dispatch({
      type: "OUTSIDE_CLICK_COMMON",
      payload: {
        feature: "AssessmentAdmin",
        changesDone: true,
        OpenModal: "",
        ProceedWithNavigation: "",
      },
    });
  };

  const onStatementSortEnd = (oldIndex, newIndex) => {
    setIsStatementsSorted(true);
    setSelectedStatements([]);
    const statements =
      values && values.impactStatements ? [...values.impactStatements] : [];
    var element = statements[oldIndex];
    statements.splice(oldIndex, 1);
    statements.splice(newIndex, 0, element);
    setValues({ ...values, impactStatements: statements });
    setSortedStatements(statements);
  };

  const reOrderGoal = () => {
    setGoalReorderFlag(false);
    const { financialYear, pillarId } = goalMetadata;
    const section = Array.isArray(values.goalData)
      ? values.goalData
        .filter((goal) => goal.goalId !== "")
        .map((goal) => {
          const { sectionValues = [] } = goal;
          return {
            ...goal,
            sectionValues: sectionValues
              .filter((section) => section.checked)
              .map(({ checked, ...rest }) => ({ ...rest })),
          };
        })
      : [];
    const payloadObj = { financialYear, pillarId, section };
    const type = REORDER_ASSESSMENT_GOAL;
    try {
      dispatch({
        type,
        payload: { payloadObj },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const addImpactStatement = (val) => {
    if (isEditMode) {
      let copyImpactStatements = _.cloneDeep(values.impactStatements);
      copyImpactStatements.forEach((statement, index) => {
        if (
          currentEditStatementId === index &&
          statement.impactActions !== val
        ) {
          statement.impactActions = val;
          setCurrentImpactStatements([
            ...currentImpactStatements,
            {
              impactActions: val,
              type: statement.type,
              action_id: statement.action_id ? statement.action_id : "",
            },
          ]);
        }
      });
      setIsEditMode(false);
      setValues({
        ...values,
        impactStatements: copyImpactStatements,
      });
    } else {
      setValues({
        ...values,
        impactStatements: [
          ...values.impactStatements,
          {
            impactActions: val,
            type: sectionName,
            action_id: "",
          },
        ],
      });
      setCurrentImpactStatements([
        ...currentImpactStatements,
        {
          impactActions: val,
          type: sectionName,
          action_id: "",
        },
      ]);
    }
    setAddView(false);
  };

  const saveImpactStatements = () => {
    const sectionData = assessmentSectionList.find(
      (list) => list.name === sectionName
    );
    if (!addView && isStatementsSorted) {
      const payload = {
        pillarId: values.pillarId,
        goalId: values.goalData[selectedId].goalId,
        financialYear: Number.parseInt(props.fyYear.replace("FY ", "")),
        statements: sortedStatements,
        sectionValue: sectionName,
      };
      dispatch({
        type: "SORT_IMPACT_STATEMENT",
        payload,
      });
    } else if (currentImpactStatements.length > 0 && !addView) {
      const payload = {
        pillarId: values.pillarId,
        goalId: values.goalData[selectedId].goalId,
        financialYear: Number.parseInt(props.fyYear.replace("FY ", "")),
        statements: currentImpactStatements,
        sectionData: {
          name: sectionData?.name ? sectionData.name : "General",
          description: sectionData?.description ? sectionData.description : "",
          color: sectionData?.color ? sectionData.color : "",
          score: sectionData?.score ? sectionData.score : score,
          displayOrder: sectionData?.displayOrder
            ? sectionData.displayOrder
            : "",
        },
      };
      dispatch({
        type: "ADD_IMPACT_STATEMENT",
        payload,
      });
    } else if (bulkMoveImpactStatements.length > 0 && !addView) {
      const isAllAreGeneralSection = bulkMoveImpactStatements.every(
        (statement) => statement.type === "General"
      );
      moveImpactStatements(
        bulkMoveImpactStatements,
        !isAllAreGeneralSection ? "General" : "All",
        bulkMoveImpactStatements[0].type
      );
    }
  };

  const moveImpactStatements = (statements, from, to) => {
    const payload = {
      pillarId: values.pillarId,
      goalId: values.goalData[selectedId].goalId,
      financialYear: Number.parseInt(props.fyYear.replace("FY ", "")),
      fromSectionValue: from,
      toSectionValue: to,
      statements,
    };
    dispatch({
      type: "MOVE_IMPACT_STATEMENT",
      payload,
    });
  };

  const deleteImpactStatements = () => {
    const payload = {
      pillarId: values.pillarId,
      goalId: values.goalData[selectedId].goalId,
      financialYear: Number.parseInt(props.fyYear.replace("FY ", "")),
      statements: selectedStatements,
    };
    dispatch({
      type: "DELETE_IMPACT_STATEMENT",
      payload,
    });
  };

  const onSectionTabChange = (ele, id) => {
    if (!addView) {
      setSelectedStatements([]);
      setPopupType(1);
      setIsFirstLoad(false);
      if (currentImpactStatements.length === 0 && !isStatementsSorted) {
        setselectedSectionIndex(id);
        setSectionName(ele.name);
        setIsStatementsSorted(false);
      } else {
        setTempSectionTabInfo({
          id,
          name: ele.name,
        });
        setIsPopupVisible(true);
      }
    }
  };

  const onSectionChange = (name, id) => {
    const isSectionAvailable = values.goalData[selectedId].data.some(
      (statement) => statement.type === name
    );
    if (isSectionAvailable) {
      setanchor(null);
      setPopupType(6);
      setIsPopupVisible(true);
    } else {
      let Completegoal = values.goalData;
      let sectionValue = values.goalData[selectedId].sectionValues;
      sectionValue[id].checked = !sectionValue[id].checked;
      Completegoal[selectedId].sectionValues = [...sectionValue];
      setValues({ ...values, goalData: Completegoal });
      setChangeInFilter(!changeInFilter);
    }
  };

  const moveAllStatements = () => {
    let statements = _.cloneDeep(values.goalData[selectedId].data);
    let copyGoalData = _.cloneDeep(values.goalData);
    if (toggleflag) {
      statements.forEach((statement) => {
        statement.type = "General";
      });
      copyGoalData[selectedId].sectionValues.forEach((section) => {
        section.checked = false;
      });
      setValues({
        ...values,
        goalData: copyGoalData,
      });
      moveImpactStatements(statements, "All", "General");
    } else {
      statements.forEach((statement) => {
        statement.type = assessmentSectionList[0].name;
      });
      moveImpactStatements(
        statements,
        "General",
        assessmentSectionList[0].name
      );
    }
  };

  const onPopupConfirm = () => {
    setIsPopupVisible(false);
    if (popupType === 1) {
      setCurrentImpactStatements([]);
      setIsStatementsSorted(false);
      setselectedSectionIndex(tempSectionTabInfo.id);
      setSectionName(tempSectionTabInfo.name);
    } else if (popupType === 2 || popupType === 3) {
      moveAllStatements();
      setSelectedStatements([]);
      toggleView(!toggleflag);
    } else if (popupType === 4) {
      deleteImpactStatements();
    } else if (popupType === 5) {
      moveImpactStatements(
        selectedStatements,
        sectionName,
        selectedMoveToSection
      );
    } else if (popupType === 7) {
      setCurrentImpactStatements([]);
      setGoalInfo();
    } else if (popupType === 8) {
      setSelectedTab(0);
      setCardEditIndex(-1);
      setAddView(false);
      resetImpactStatementData();
    } else if (popupType === 9) {
      resetImpactStatementData();
      setAddNewGoal(values);
    } else if (popupType === 10) {
      resetImpactStatementData();
      setGlobalMetriceditable(true);
      setDisableSaveGlobalMetric(true);
      setactiveNoteIndex(0);
    }
  };

  const resetImpactStatementData = () => {
    setCurrentImpactStatements([]);
    setBulkMoveImpactStatements([]);
    setIsStatementsSorted(false);
  };

  const onGoalTabChange = (index) => {
    setTempGoalId(index);
    if (
      currentImpactStatements.length !== 0 ||
      bulkMoveImpactStatements.length !== 0 ||
      isStatementsSorted
    ) {
      setPopupType(7);
      setIsPopupVisible(true);
    } else {
      setGoalInfo(index);
    }
  };

  const setGoalInfo = (index = tempGoalId) => {
    setCurrentImpactStatements([]);
    setBulkMoveImpactStatements([]);
    setIsStatementsSorted(false);
    setSelectedId(index);
    setAddView(false);
    setCardEditIndex(-1);
    setSelectedTab(0);
  };

  const toggleSections = () => {
    if (!disableFields && !globalMetriceditable) {
      setIsFirstLoad(false);
      setIsCheckboxClicked(true);
      if (!addView) {
        if (toggleflag) {
          setPopupType(2);
          setCurrentImpactStatements([]);
        } else {
          setPopupType(3);
        }
        setIsPopupVisible(true);
        // toggleView(!toggleflag);
      }
    }
  };

  const setStatementEditInfo = (id) => {
    setAddView(true);
    setCurrentEditStatementId(id);
    setIsEditMode(true);
    /*console.log(id);
    console.log(values.impactStatements)
    console.log(values.impactStatements[id])*/
  };

  const onStatementSelect = (id) => {
    let copyImpactStatements = _.cloneDeep(values.impactStatements);
    const copySelectedStatements = _.cloneDeep(selectedStatements);
    const index = copySelectedStatements.findIndex(
      (statement) =>
        statement.action_id === values.impactStatements[id].action_id
    );

    if (index === -1) {
      copySelectedStatements.push(values.impactStatements[id]);
    } else {
      copySelectedStatements.splice(index, 1);
    }
    copyImpactStatements[id].checked = !copyImpactStatements[id].checked;
    setValues({
      ...values,
      impactStatements: copyImpactStatements,
    });
    setSelectedStatements(copySelectedStatements);
    /*setSelectedStatements([
      ...selectedStatements,
      values.impactStatements[id]
    ]);*/
  };

  const isSectionSelected = () => {
    return values.goalData[selectedId].data.every(
      (statement) => statement.type === "General"
    );
  };

  const notify = (msg) => {
    toast(
      <div className="success-text">
        <span className="icon-verified-outline icon"></span>
        <p>
          <span>{msg}</span>
        </p>
      </div>,
      {
        position: "top-center",
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      }
    );
  };

  if (
    values &&
    values.goalData &&
    values.goalData.length >= 7 &&
    !addNewGoalScreenEnabled
  )
    setAddNewGoalScreenEnabled(true);

  const checkOutsideClick = (fn, params) => {
    if (props.history.location.pathname === "/Admin/Assessment") {
      if (
        outsideClickState.data &&
        outsideClickState.data.feature !== "" &&
        outsideClickState.data.changesDone
      ) {
        dispatch({
          type: "OUTSIDE_CLICK_COMMON_OPEN_MODEL",
          payload: {
            callback: { fn: fn, params: params },
          },
        });
      } else {
        fn && fn(params);
      }
    } else if (
      props.history.location.pathname === "/Admin/Approvals/Assessment"
    ) {
      fn && fn(params);
    }
  };

  const editGlobalMetricClick = (flag) => {
    if (
      currentImpactStatements.length === 0 &&
      bulkMoveImpactStatements.length === 0 &&
      !isStatementsSorted
    ) {
      setGlobalMetriceditable(flag);
      setDisableSaveGlobalMetric(flag);
      setactiveNoteIndex(0);
    } else {
      setPopupType(10);
      setIsPopupVisible(true);
    }
    setGoalReorderFlag(false);
  };

  const addNewGoalClick = (values) => {
    if (
      currentImpactStatements.length === 0 &&
      bulkMoveImpactStatements.length === 0 &&
      !isStatementsSorted
    ) {
      setAddNewGoal(values);
    } else {
      setPopupType(9);
      setIsPopupVisible(true);
    }
  };

  const setAddNewGoal = (values) => {
    if (!addNewGoalScreenEnabled) {
      props.addNewGoal(values.globalMetricName, values.order, values.iconName);
      setSelectedTab(0);
      setAddNewGoalScreenEnabled(true);
    }
  };

  const goalTabClick = (tab) => {
    if (
      currentImpactStatements.length === 0 &&
      bulkMoveImpactStatements.length === 0 &&
      !isStatementsSorted
    ) {
      setSelectedTab(tab);
      setCardEditIndex(-1);
      setAddView(false);
    } else {
      setPopupType(8);
      setIsPopupVisible(true);
    }
  };

  const impactStatmentTabClick = (tab) => {
    if (!_.isEmpty(assessmentGoalData.data) && assessmentGoalData.data.uiType) {
      setSelectedTab(tab);
      setAddView(false);
      setGoalReorderFlag(false);
    }
  };

  const cancelGoalClick = (msg) => {
    props.getAssessmentData();
  };
  
  return (
    <SimpleBar style={{ maxHeight: "82vh" }}>
      {isLoading && <Loader />}
      <NavigationPrompt
        when={
          currentImpactStatements.length !== 0 ||
          bulkMoveImpactStatements.length !== 0 ||
          isStatementsSorted
        }>
        {({ onConfirm, onCancel }) => (
          <div className="popup-dialog">
            <div className="dialog">
              <div className="header">
                <h5>Confirmation</h5>
                <span
                  className="icon-close-filled icon"
                  onClick={onCancel}></span>
              </div>
              <div className="content">
                Would you like to navigate without completing the operation?
                Please note that on confirming, incomplete actions will be lost
              </div>
              <div className="footer">
                <button className="cancel-btn" onClick={onCancel}>
                  Cancel
                </button>
                <button className="confirm-btn" onClick={onConfirm}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </NavigationPrompt>
      <div className="addNewGlobal">
        <div className="UpperDiv">
          <div className="header">
            {/*  */}
            {globalMetriceditable ? (
              <div className="edit-section">
                <div className={classes.half}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "10px",
                    }}>
                    <InputLabel
                      htmlFor="uncontrolled-native"
                      className={classes.topSpacing}
                      classes={{
                        root: classes.labelroot,
                      }}
                      error={errorMessages.globalMetricName !== ""}>
                      {"Global Metric Name*"}
                    </InputLabel>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      className={classes.root1}
                      required={true}
                      value={values.globalMetricName}
                      maxLength={12}
                      onChange={(e) =>
                        textOnChange(e.target.value, "globalMetricName")
                      }
                      onBlur={() => validateInput("globalMetricName")}
                      error={errorMessages.globalMetricName !== ""}
                      helperText={errorMessages.globalMetricName}
                    />
                  </div>
                </div>
                <div className={classes.half}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "20px",
                    }}>
                    <InputLabel
                      htmlFor="uncontrolled-native"
                      className={classes.topSpacing}
                      error={errorMessages.displayName !== ""}
                      classes={{
                        root: classes.labelroot,
                      }}>
                      {"Display Name*"}
                    </InputLabel>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      className={classes.root1}
                      required={true}
                      value={values.displayName}
                      onChange={(e) =>
                        textOnChange(e.target.value, "displayName")
                      }
                      onBlur={() => validateInput("displayName")}
                      error={errorMessages.displayName !== ""}
                      helperText={errorMessages.displayName}
                    />
                  </div>
                </div>
                <div className={classes.half}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "20px",
                      marginTop: "-9px",
                      width: "250px",
                      height: "50px",
                    }}>
                    <DropdownComponent
                      label={"Global Metric Type"}
                      defaultValue={values.metricType}
                      required={true}
                      selectOnChange={(dummy, value) => {
                        textOnChange(value, "metricType");
                      }}
                      varName="metricType"
                      list={globalMatricType}
                      errorMessage={errorMessages}
                      disabled={false}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>{values.displayName}</div>
            )}
            {globalMetriceditable ? (
              <div className={"btn-sec"}>
                <div style={{ opacity: "0.6", fontSize: "11px" }}>
                  {props.fyYear}
                </div>
                {
                  <div
                    className={"btn"}
                    onClick={() => {
                      // if (!disableSaveGlobalMetric) {
                      props.setIcon("");
                      setGlobalMetriceditable(false);
                      setDisableSaveGlobalMetric(true);
                      props.onEditCancel();
                      dispatch({
                        type: "OUTSIDE_CLICK_COMMON_RESET",
                        payload: {},
                      });
                      if (
                        backUpvalues &&
                        backUpvalues.hasOwnProperty("globalMetricName")
                      ) {
                        let temp = backUpvalues.notesText;
                        setValues({
                          ...backUpvalues,
                          notesText: temp.length > 0 ? [...temp] : [""],
                        });
                      } else {
                        props.viewAddNewGlobalMetric(false);
                      }
                      // }
                    }}>
                    <i
                      className="icon-close-outline"
                      style={{ marginRight: "5px" }}
                    />
                    Cancel
                  </div>
                }
                <div
                  className={
                    "btn" +
                    (activeGoal === -1
                      ? " opacityReduced "
                      : "") /*  + (disableGoalSection ? " opacityReduced " : "") */
                  }
                  onClick={() => {
                    /*  if (!disableGoalSection) {/*  */
                    if (activeGoal !== -1) {
                      setShowDeleteModal(true);
                      setDeleteType(2);
                      setDisableSaveGlobalMetric(true);
                    }

                    // } */
                  }}>
                  <i className="icon-trash" style={{ marginRight: "5px" }} />
                  Delete
                </div>
                <div
                  className={
                    "btn" +
                    (disableSaveGlobalMetric ||
                      values.globalMetricName === "" ||
                      values.iconName === "" ||
                      values.description === "" ||
                      values.metricType === ""
                      ? " opacityReduced "
                      : "")
                  }
                  style={{
                    cursor: `${!disableSaveGlobalMetric &&
                        values.globalMetricName !== "" &&
                        values.iconName !== "" &&
                        values.description !== "" &&
                        values.metricType !== ""
                        ? " pointer"
                        : "auto"
                      }`,
                  }}
                  onClick={() => {
                    // setEditable(!editable);
                    if (
                      !disableSaveGlobalMetric &&
                      values.globalMetricName !== "" &&
                      values.iconName !== "" &&
                      values.metricType !== "" &&
                      values.description !== ""
                    ) {
                      setDisableSaveGlobalMetric(true);
                      setGlobalMetriceditable(false);
                      setDisableGoalSection(false);
                      dispatch({
                        type: "OUTSIDE_CLICK_COMMON_RESET",
                        payload: {},
                      });

                      callAddGlobalMetricAPI();
                    }
                  }}>
                  <i
                    className={"icon-web-071"}
                    style={{
                      marginRight: "5px",
                      cursor: `${!disableSaveGlobalMetric &&
                          values.globalMetricName !== "" &&
                          values.iconName !== ""
                          ? " pointer"
                          : "auto"
                        }`,
                    }}
                  />
                  {"Save"}
                </div>
              </div>
            ) : (
              !disableFields && (
                <div className={"btn-sec"}>
                  <div
                    style={{
                      marginRight: "10px",
                      marginBottom: "3px",
                      font: "normal normal normal 11px EYInterstate",
                      opacity: "0.7",
                    }}>
                    {values.metricType}, {props.fyYear}
                  </div>
                  <div
                    style={{ cursor: "pointer", fontSize: "14px" }}
                    onClick={() => {
                      checkOutsideClick(editGlobalMetricClick, true);
                    }}>
                    <i
                      className="eyaa-file-001"
                      style={{ marginRight: "5px", fontSize: "16px" }}
                    />
                  </div>
                </div>
              )
            )}
          </div>
          <div className="horizontal">
            <div
              className="upload-section"
              style={{ width: `${globalMetriceditable ? "30%" : "15%"}` }}>
              <div className="circle">
                <div className="inner-circle">
                  <i className={values.iconName} />
                </div>
              </div>
              {globalMetriceditable && (
                <div className="btn-section">
                  <div
                    className="btn"
                    onClick={() => {
                      setDisableSaveGlobalMetric(false);
                      props.addIcon();
                    }}>
                    {
                      /* props.activeGoal !== -1
                      ? "Add or Upload Icon": */
                      "Select Icon"
                    }
                  </div>
                  <div className="text">
                    Assign the image to use as the gloabl metric image icon
                  </div>
                </div>
              )}
            </div>
            {globalMetriceditable ? (
              <div className="edit-section">
                {" "}
                <div className={classes.half}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "10px",
                      marginRight: "14px",
                    }}>
                    <InputLabel
                      htmlFor="uncontrolled-native"
                      className={classes.topSpacing}
                      classes={{
                        root: classes.labelroot,
                      }}
                      error={errorMessages.description !== ""}>
                      {"Description*"}
                    </InputLabel>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      className={classes.root900}
                      required={true}
                      value={values.description}
                      onChange={(e) =>
                        textOnChange(e.target.value, "description")
                      }
                      onBlur={() => validateInput("description")}
                      error={errorMessages.description !== ""}
                      helperText={errorMessages.description}
                    />
                  </div>
                </div>
                <div className="quil-section">
                  {values.notesText &&
                    values.notesText.map((value, index) => {
                      return (
                        <EditorToolbar
                          display={index === activeNoteIndex}
                          index={index}
                          addNote={() => {
                            let temp = values.notesText;
                            temp.push("");
                            setValues({ ...values, notesText: [...temp] });
                            // setDisableSaveGlobalMetric(false);
                          }}
                          length={
                            values.notesText ? values.notesText.length : 0
                          }
                        />
                      );
                    })}
                  {/* <SimpleBar
                    style={{
                      maxHeight: "125px"
                    }}> */}
                  <div
                    style={{
                      marginBottom: "30px",
                    }}>
                    {values.notesText &&
                      values.notesText.map((value, index) => (
                        <div className="customReactQ">
                          <div className="deletebox">
                            <i
                              onClick={() => {
                                dispatch({
                                  type: "OUTSIDE_CLICK_COMMON",
                                  payload: {
                                    feature: "AssessmentAdmin",
                                    changesDone: true,
                                    OpenModal: "",
                                    ProceedWithNavigation: "",
                                  },
                                });
                                setShowDeleteModal(true);
                                setDeleteType(3);
                                setDeleteNoteIndex(index);
                              }}
                              className="eyaa-trash"
                              style={{ marginRight: "5px" }}
                            />
                          </div>
                          <ReactQuill
                            index={index}
                            theme="snow"
                            onFocus={() => {
                              console.log("focus", index);
                              setactiveNoteIndex(index);
                            }}
                            value={values.notesText[index]}
                            onChange={(content, delta, source, editor) =>
                              handleNotesTextChange(
                                content,
                                index,
                                delta,
                                source,
                                editor
                              )
                            }
                            modules={
                              index == 0
                                ? modules0
                                : index == 1
                                  ? modules1
                                  : modules2
                            }
                            formats={formats}
                          />
                        </div>
                      ))}
                  </div>
                  {/* </SimpleBar> */}
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "15px",
                  // marginTop: "-12px",
                  height: "100%",
                }}>
                <InputLabel
                  htmlFor="uncontrolled-native"
                  className={classes.topSpacing}
                  classes={{
                    root: classes.labelroot1,
                  }}>
                  {"Description"}
                </InputLabel>
                <div
                  style={{
                    fontSize: "11px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                    opacity: "0.7",
                  }}>
                  {values.description}
                </div>
                <InputLabel
                  htmlFor="uncontrolled-native"
                  className={classes.topSpacing}
                  classes={{
                    root: classes.labelroot1,
                  }}>
                  {"Notes"}
                </InputLabel>
                <div
                  style={{
                    fontSize: "11px",
                    paddingLeft: "5px",
                    paddingTop: "4px",
                    opacity: "0.7",
                    wordBreak: "break-word",
                    maxWidth: "970px",
                  }}>
                  {values.notesText &&
                    values.notesText.map((value, index) =>
                      ReactHtmlParser(value)
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={disableGoalSection ? " disableform" : ""}></div>
        <div className={"lowerDiv"}>
          <div>
            <div className="header" style={{ paddingLeft: "10px" }}>
              {" "}
              Add Global Metric Details
            </div>
            <div className="goal-details">
              <div className="goal-list">
                {!goalAdded ? (
                  <div className="emptyList">
                    {!disableFields && !globalMetriceditable ? (
                      <>
                        <PlaylistAddSharpIcon
                          classes={{ root: "icon-color" }}
                        />
                        <div className="text">
                          Click here to start adding Goals
                        </div>
                        <div
                          className="btn"
                          onClick={() => {
                            checkOutsideClick(addNewGoalClick, values);
                          }}>
                          <i
                            className="icon-plus"
                            style={{ marginRight: "5px" }}
                          />
                          Add New Goal
                        </div>
                      </>
                    ) : (
                      <div className="text">
                        Save or cancel Global Metric edit to add goals
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div
                      className="header"
                      style={{
                        fontSize: "12px",
                        paddingLeft: "10px",
                        justifyContent: "space-between",
                      }}>
                      {" "}
                      Goal List
                      {!disableFields && !globalMetriceditable ? (
                        <>
                          {!goalReorderFlag ? (
                            <div
                              className="add-btn"
                              onClick={() => {
                                checkOutsideClick(addNewGoalClick, values);
                              }}>
                              <i
                                className="icon-plus"
                                style={{ marginRight: "5px" }}
                              />
                              Add New Goal
                            </div>
                          ) : (
                            <div
                              style={{
                                border: "1px solid #c4c4cd",
                                fontSize: "10px",
                                borderRadius: "3px",
                                marginLeft: "auto",
                                cursor: "pointer",
                              }}
                              onClick={() => reOrderGoal()}>
                              <i
                                className={"icon-web-071"}
                                style={{
                                  border: "1px solid #c4c4cd",
                                  fontSize: "10px",
                                  borderRadius: "3px",
                                  marginLeft: "auto",
                                  cursor: "pointer",
                                }}
                              // onClick={() => reOrderGoal()}>
                              // <i
                              //   className={"icon-web-071"}
                              //   style={{
                              //     margin: "3px",
                              //   }}
                              />
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                    <div className="grid-display">
                      <div className="centerText">
                        {" "}
                        Maximum of 7 Goals can be added{" "}
                      </div>
                    </div>
                    <div>
                      <SortableList
                        onSortEnd={onSortEnd}
                        className="list"
                        draggedItemClassName="dragged-card"
                        disabled={disableFields}
                        allowDrag={
                          !(
                            disableFields ||
                            disableGoalReorder ||
                            selectedTab === 1
                          )
                        }>
                        {values &&
                          values.goalData &&
                          values.goalData.map((item, index) => (
                            <SortableItem key={item}>
                              <div
                                // className={"dragged-card"}
                                className={
                                  "goal-card " +
                                  (selectedId !== index
                                    ? " "
                                    : " selected-card")
                                }
                                style={{ display: "flex" }}
                                onClick={() => {
                                  checkOutsideClick(onGoalTabChange, index);
                                }}>
                                <div className={"card"} onClick={() => { }}>
                                  <i
                                    className=" icon-drag"
                                    style={{
                                      paddingRight: "5px",
                                      fontSize: "15px",
                                    }}
                                  />
                                </div>
                                {item.goalName !== ""
                                  ? item.goalName
                                  : "New Goal"}
                                <div style={{ marginLeft: "auto" }}>
                                  <i className="icon-chevron-right" />
                                </div>
                              </div>
                            </SortableItem>
                          ))}
                      </SortableList>
                    </div>
                  </div>
                )}
              </div>
              <div className={"statements" + (!goalAdded ? " cent" : "")}>
                {!goalAdded ? (
                  <div className="text">
                    Add goals first to add sections and impact statmenets.
                  </div>
                ) : (
                  <>
                    <div className="statement-header">
                      <div
                        onClick={() => {
                          checkOutsideClick(goalTabClick, 0);
                        }}
                        className={
                          "tab" + (selectedTab === 0 ? " selectedTab" : "")
                        }>
                        Goal Details
                      </div>
                      <div
                        onClick={() => {
                          if (goalMetadata && goalMetadata.goalId) {
                            checkOutsideClick(impactStatmentTabClick, 1);
                          }
                        }}
                        className={
                          "tab" + (selectedTab === 1 ? " selectedTab" : "")
                        }
                        style={{
                          cursor: assessmentGoalData.data.uiType
                            ? "pointer"
                            : "default",
                        }}>
                        Impact Statements
                      </div>
                      {!disableFields && !globalMetriceditable ? (
                        <div className={"btn-sec"}>
                          {goalMetadata && goalMetadata.goalId === "" ? (
                            <div
                              className={"btn"}
                              style={{
                                cursor: " pointer",
                                opacity: 1,
                              }}
                              onClick={() => {
                                checkOutsideClick(cancelGoalClick, "dummyArg");
                              }}>
                              <i
                                className="icon-close-outline"
                                style={{ marginRight: "5px" }}
                              />
                              Cancel
                            </div>
                          ) : null}
                          {!addView && selectedTab === 0 && (
                            <div
                              className={
                                "btn" +
                                (!(goalMetadata && goalMetadata.goalId)
                                  ? " opacityReduced "
                                  : "")
                              }
                              style={{
                                cursor: `${goalMetadata && goalMetadata.goalId
                                    ? " pointer"
                                    : "auto"
                                  }`,
                                opacity: `${goalMetadata && goalMetadata.goalId ? 1 : 0.5
                                  }`,
                              }}
                              onClick={() => {
                                if (goalMetadata && goalMetadata.goalId) {
                                  setCardEditIndex(-1);
                                  setShowDeleteModal(true);
                                  setDeleteType(0);
                                }
                              }}>
                              <i
                                className="icon-trash"
                                style={{ marginRight: "5px" }}
                              />
                              Delete
                            </div>
                          )}
                          {selectedTab === 0 && (
                            <div
                              className={
                                "btn" +
                                (disableSaveGoal ? " opacityReduced " : "")
                              }
                              style={{
                                cursor: `${!disableSaveGoal ? " pointer" : "auto"
                                  }`,
                                opacity: `${!disableSaveGoal ? 1 : 0.5}`,
                              }}
                              onClick={() => {
                                setCardEditIndex(-1);
                                setAddView(false);
                                if (!disableSaveGoal)
                                  setSaveGoal((prev) => prev + 1);
                              }}>
                              <i
                                className={
                                  !editable && selectedTab === 0
                                    ? "eyaa-file-001"
                                    : "icon-web-071"
                                }
                                style={{
                                  marginRight: "5px",
                                  cursor: `${!disableSaveGoal ? " pointer" : "auto"
                                    }`,
                                }}
                              />
                              {!editable && selectedTab === 0 ? "Edit" : "Save"}
                            </div>
                          )}

                          {selectedTab === 1 && (
                            <>
                              <div
                                className={classNames("btn", {
                                  "disable-btn":
                                    selectedStatements.length === 0 ||
                                    addView ||
                                    !toggleflag,
                                })}
                                onClick={(e) => {
                                  setMoveToOrFilter(1);
                                  setanchor(e.currentTarget);
                                }}>
                                <i
                                  className="eyaa-web-074"
                                  style={{
                                    marginRight: "5px",
                                  }}
                                />
                                Move To
                              </div>
                              <div
                                className={classNames("btn", {
                                  "disable-btn":
                                    selectedStatements.length === 0 || addView,
                                })}
                                onClick={() => {
                                  setPopupType(4);
                                  setIsPopupVisible(true);
                                }}>
                                <i
                                  className="icon-trash"
                                  style={{
                                    marginRight: "5px",
                                  }}
                                />
                                Delete
                              </div>
                              <div
                                className={classNames("btn", {
                                  "disable-btn":
                                    (currentImpactStatements.length === 0 &&
                                      bulkMoveImpactStatements.length === 0 &&
                                      !isStatementsSorted) ||
                                    addView,
                                })}
                                onClick={saveImpactStatements}>
                                <i
                                  className="icon-web-071"
                                  style={{
                                    marginRight: "5px",
                                  }}
                                />
                                Save
                              </div>
                              <div
                                className={classNames("yellow-btn", {
                                  "disable-btn":
                                    bulkMoveImpactStatements.length > 0 ||
                                    addView ||
                                    isStatementsSorted ||
                                    selectedStatements.length !== 0,
                                })}
                                onClick={() => {
                                  // toggleView(false);
                                  setAddView(true);
                                }}>
                                <i
                                  className="icon-plus"
                                  style={{ marginRight: "5px" }}
                                />
                                Add Impact Statement
                              </div>
                            </>
                          )}
                        </div>
                      ) : null}
                    </div>
                    {selectedTab === 0 ? (
                      // <GoalDetails {...props} />
                      <GoalDetails
                        goalDetails={goalDetails || {}}
                        goalMetadata={goalMetadata}
                        updateGoalName={updateGoalName}
                        saveGoal={saveGoal}
                        resetSaveGoal={() => {
                          setSaveGoal(0);
                          if (
                            values &&
                            values.goalData &&
                            values.goalData.length < 7
                          )
                            setAddNewGoalScreenEnabled(false);
                        }}
                        setDisableSaveGoal={setDisableSaveGoal}
                        setLoader={setLoader}
                        disableFields={disableFields || globalMetriceditable}
                      />
                    ) : (
                      <div className="impact-statement-container">
                        <div className="statement-tabs">
                          <div
                            className={"stmnt-tab section"}
                            style={{
                              opacity: addView ? "0.5" : "1",
                              cursor:
                                disableFields || addView
                                  ? "default"
                                  : "pointer",
                            }}>
                            <i
                              style={{
                                cursor: "auto",
                                marginRight: "5px",
                                opacity:
                                  addView ||
                                    disableFields ||
                                    globalMetriceditable
                                    ? "0.5"
                                    : "1",
                              }}
                              className={
                                toggleflag
                                  ? "icon-check-squere-filled"
                                  : "icon-check-unchecked"
                              }
                              onClick={toggleSections}
                            />
                            Section
                            <Tooltip
                              title={
                                disableFields || globalMetriceditable
                                  ? ""
                                  : isSectionSelected()
                                    ? "Select to enable sections within the goal. After selecting, click three dots to choose from the list of sections."
                                    : "Unselect to disable sections. On unselecting, all the impact statements will be mapped under 'General'."
                              }
                              aria-label="Profile"
                              arrow={true}
                              placement="bottom"
                              classes={{
                                tooltip: classes.customTooltip,
                              }}>
                              <i
                                className={
                                  onHover
                                    ? "icon-info-filled"
                                    : "icon-info-outline"
                                }
                                style={{
                                  margin: "2px 7px 0px 7px",
                                  opacity:
                                    disableFields || globalMetriceditable
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    disableFields || globalMetriceditable
                                      ? "default"
                                      : "pointer",
                                }}
                                onMouseEnter={() => {
                                  if (!globalMetriceditable && !disableFields)
                                    setOnHover(true);
                                }}
                                onMouseLeave={() => {
                                  if (!globalMetriceditable && !disableFields)
                                    setOnHover(false);
                                }}
                              />
                            </Tooltip>
                            <i
                              onClick={(e) => {
                                if (
                                  toggleflag &&
                                  !addView &&
                                  !disableFields &&
                                  !globalMetriceditable
                                ) {
                                  e.stopPropagation();
                                  setMoveToOrFilter(0);
                                  setanchor(e.currentTarget);
                                }
                              }}
                              className="icon-more-v"
                              style={{
                                margin: "0px",
                                fontSize: "17px",
                                color:
                                  toggleflag &&
                                    !addView &&
                                    !disableFields &&
                                    !globalMetriceditable
                                    ? "black"
                                    : "rgba(0,0,0,.26)",
                                cursor:
                                  toggleflag &&
                                    !addView &&
                                    !disableFields &&
                                    !globalMetriceditable
                                    ? "pointer"
                                    : "auto",
                              }}
                            />
                          </div>
                          {toggleflag &&
                            values.goalData[selectedId].sectionValues &&
                            values.goalData[selectedId].sectionValues.map(
                              (ele, id) => {
                                if (ele.checked)
                                  return (
                                    <div
                                      onClick={() =>
                                        onSectionTabChange(ele, id)
                                      }
                                      style={{
                                        opacity: addView ? "0.5" : "1",
                                        cursor: addView ? "default" : "pointer",
                                      }}
                                      className={
                                        "stmnt-tab" +
                                        (selectedSectionIndex === id
                                          ? " selectedTabBtm"
                                          : "")
                                      }>
                                      {ele.name}
                                    </div>
                                  );
                                else return null;
                              }
                            )}
                        </div>
                        <div className="impact-statements">
                          {!addView && values.impactStatements.length > 0 ? (
                            <div>
                              <SortableList
                                onSortEnd={onStatementSortEnd}
                                className="list"
                                draggedItemClassName="dragged-statement-card"
                                allowDrag={
                                  !disableFields &&
                                  !globalMetriceditable &&
                                  currentImpactStatements.length === 0 &&
                                  bulkMoveImpactStatements.length === 0 &&
                                  selectedStatements.length === 0
                                }>
                                {values.impactStatements.map((val, id) => (
                                  <SortableItem key={id} index={id}>
                                    <div>
                                      <CardComponent
                                        data={val.impactActions}
                                        seTaddView={(flag) => setAddView(flag)}
                                        onEditBtnClicked={setStatementEditInfo}
                                        onStatementSelect={onStatementSelect}
                                        cardEditIndex={cardEditIndex}
                                        selected={val.checked}
                                        id={id}
                                        disableDragIcon={
                                          currentImpactStatements.length !==
                                          0 ||
                                          selectedStatements.length !== 0 ||
                                          bulkMoveImpactStatements.length !== 0
                                        }
                                        disableCheckbox={
                                          currentImpactStatements.length !==
                                          0 ||
                                          isStatementsSorted ||
                                          bulkMoveImpactStatements.length !== 0
                                        }
                                        disabledEditBtn={
                                          selectedStatements.length !== 0 ||
                                          isStatementsSorted ||
                                          bulkMoveImpactStatements.length !==
                                          0 ||
                                          val.action_id === ""
                                        }
                                        disableFields={
                                          disableFields || globalMetriceditable
                                        }
                                        //actionId = {val.action_id}
                                        moveTo={moveTo}
                                        type={val.type}
                                        setCardEditable={(flag) => {
                                          if (flag) setCardEditIndex(id);
                                          else {
                                            setCardEditIndex(-1);
                                            setAddView(false);
                                          }
                                        }}
                                        setShowDeleteModal={() => {
                                          setShowDeleteModal(true);
                                          setDeleteType(1);
                                        }}
                                        StmtTextOnChange={(value) => {
                                          props.StmtTextOnChange(
                                            selectedId,
                                            id,
                                            value
                                          );
                                        }}
                                      />
                                    </div>
                                  </SortableItem>
                                ))}
                              </SortableList>
                            </div>
                          ) : (
                            !addView && (
                              <p className="no-data">
                                No impact statement to display, click to add
                                impact statements
                              </p>
                            )
                          )}
                          {/*!toggleflag &&
                            !addView &&
                            values.goalData[selectedId] &&
                            values.goalData[selectedId].data &&
                            values.goalData[selectedId].data.map(
                              (ele, id) => {
                                return (
                                  <CardComponent
                                    data={ele.impactActions}
                                    seTaddView={(flag) => setAddView(flag)}
                                    cardEditIndex={cardEditIndex}
                                    id={id}
                                    moveTo={moveTo}
                                    setCardEditable={(flag) => {
                                      if (flag) setCardEditIndex(id);
                                      else {
                                        setCardEditIndex(-1);
                                        setAddView(false);
                                      }
                                    }}
                                    setShowDeleteModal={() => {
                                      setShowDeleteModal(true);
                                      setDeleteType(1);
                                    }}
                                    StmtTextOnChange={(value) => {
                                      props.StmtTextOnChange(
                                        selectedId,
                                        id,
                                        value
                                      );
                                    }}
                                  />
                                );
                              }
                            )*/}
                          {/*toggleflag &&
                            values.goalData[selectedId].data &&
                            values.goalData[selectedId].data.map(
                              (ele, id) => {
                                if (
                                  ele.type ===
                                  values.goalData[selectedId].sectionValues[
                                    selectedSectionIndex
                                  ].name
                                )
                                  return (
                                    <CardComponent
                                      data={ele.impactActions}
                                      type={ele.type}
                                      id={id}
                                      moveTo={moveTo}
                                      cardEditIndex={cardEditIndex}
                                      setCardEditable={(flag) => {
                                        if (flag) setCardEditIndex(id);
                                        else {
                                          setCardEditIndex(-1);
                                          setAddView(false);
                                        }
                                      }}
                                      StmtTextOnChange={(value) => {
                                        props.StmtTextOnChange(
                                          selectedId,
                                          id,
                                          value
                                        );
                                      }}
                                      seTaddView={(flag) => setAddView(flag)}
                                      setShowDeleteModal={() => {
                                        setShowDeleteModal(true);
                                        setDeleteType(1);
                                      }}
                                    />
                                  );
                                else return null;
                              }
                            )*/}
                          {addView && (
                            <CardComponent
                              data={
                                isEditMode
                                  ? values.impactStatements[
                                  currentEditStatementId
                                  ]
                                  : ""
                              }
                              type={""}
                              moveTo={moveTo}
                              addView={addView}
                              cardEditIndex={cardEditIndex}
                              editMode={isEditMode}
                              setCardEditable={(flag) => {
                                if (!flag) {
                                  setCardEditIndex(-1);
                                  setAddView(false);
                                }
                              }}
                              seTaddView={(flag) => setAddView(flag)}
                              setShowDeleteModal={() => {
                                setShowDeleteModal(true);
                                setDeleteType(1);
                              }}
                              addImpactStatement={addImpactStatement}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            {/* {props.activeGoal === -1 && (
              <div className="button-section ">
                <div
                  className={"save" + (true ? "" : " disable-btn ")}
                  onClick={() => {
                    //   if (true)
                    //   onSave();
                    props.closeAddScreen();
                  }}>
                  Save
                </div>
                <div
                  className="cancel"
                  onClick={() => {
                    props.closeAddScreen();
                  }}>
                  Cancel
                </div>
              </div>
            )} */}
          </div>
        </div>
        <Popper
          placement="bottom"
          id="customPopupRoot"
          className={classes.popper}
          open={openpopUp}
          anchorEl={anchor}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: "scrollParent",
            },
            arrow: {
              enabled: true,
              element: arrowRef,
            },
          }}>
          {moveToOrFilter !== 1 && (
            <span className={classes.arrow} ref={setArrowRef} />
          )}
          <ClickAwayListener onClickAway={clickAwayHandler}>
            <Paper
              className={classes.paper2}
              style={{
                border: "1px solid #000",
                borderRadius: 0,
                textIndent: "10px",
              }}>
              {moveToOrFilter ? (
                <>
                  {values.goalData[selectedId].sectionValues.map((ele, id) => (
                    <>
                      {" "}
                      {ele.checked && (
                        <div
                          className={classNames(classes.options2, {
                            "disable-btn": ele.name === sectionName || addView,
                          })}
                          onClick={() => {
                            setanchor(null);
                            setPopupType(5);
                            setIsPopupVisible(true);
                            setSelectedMoveToSection(ele.name);
                          }}>
                          {ele.name}
                        </div>
                      )}{" "}
                    </>
                  ))}
                </>
              ) : (
                <>
                  {values.goalData[selectedId] &&
                    values.goalData[selectedId].sectionValues.map((ele, id) => (
                      <>
                        <div className={classes.options1}>
                          <i
                            style={{ cursor: "auto", marginRight: "5px" }}
                            className={
                              ele.checked
                                ? "icon-check-squere-filled"
                                : "icon-check-unchecked"
                            }
                            onClick={() => onSectionChange(ele.name, id)}
                          />
                          {ele.name}
                        </div>
                      </>
                    ))}
                </>
              )}
            </Paper>
          </ClickAwayListener>
        </Popper>
        <AlertSubmitPopup
          header={"Confirmation"}
          // {
          //   "Delete " +
          //   (deleteType === 0
          //     ? " Goal"
          //     : deleteType === 1
          //     ? " Impact Statement"
          //     : " Global Metric")
          // }
          // "Would you like to delete the global metric?"
          description={
            deleteType === 2
              ? "Please note that this will also delete all the goals and impact statements within the selected metric."
              : deleteType === 0
                ? "Please note that this will also delete all the impact statements within the selected goal."
                : ""
          }
          onlyAlert={false}
          delete={true}
          cancelBtnTxt={"Cancel"}
          submitBtnTxt={"Confirm"}
          simpleClosePopup={() => setShowDeleteModal(false)}
          message={
            "Would you like to delete the " +
            (deleteType === 0
              ? " goal?"
              : deleteType === 1
                ? " impact statement?"
                : deleteType === 3
                  ? " Note?"
                  : " global metric?")
          }
          showAlertSubmitPopup={showDeleteModal}
          cancelAlertSubmitPopup={() => setShowDeleteModal(false)}
          submitPopupChanges={() => {
            setShowDeleteModal(false);
            if (deleteType === 2) {
              dispatch({
                type: "DELETE_GLOBAL_METRIC",
                payload: {
                  pillarId: values.pillarId,
                  financialYear: Number.parseInt(
                    props.fyYear.replace("FY ", "")
                  ),
                },
              });
              props.viewAddNewGlobalMetric(false);
            } else if (deleteType === 0) {
              goalDelete();
            } else if (deleteType === 3) {
              let temp = values.notesText;
              if (values.notesText.length > 1) {
                temp.splice(deleteNoteIndex, 1);
              } else {
                temp = [""];
              }
              setactiveNoteIndex(0);
              setValues({ ...values, notesText: [...temp] });
              setDisableSaveGlobalMetric(false);
            }
          }}></AlertSubmitPopup>

        <AlertSubmitPopup
          header={"Discard Changes"}
          onlyAlert={false}
          message={"Would you like to discard changes?"}
          cancelBtnTxt={"Cancel"}
          submitBtnTxt={"Confirm"}
          simpleClosePopup={() => {
            dispatch({
              type: "OUTSIDE_CLICK_COMMON_PROCEED",
              payload: {
                ProceedWithNavigation: false,
              },
            });
          }}
          description={
            "Please note that on confirming, unsaved changes will be lost."
          }
          showAlertSubmitPopup={
            outsideClickState &&
            outsideClickState.data &&
            outsideClickState.data.feature === "AssessmentAdmin" &&
            outsideClickState.data.OpenModal
          }
          cancelAlertSubmitPopup={() => {
            dispatch({
              type: "OUTSIDE_CLICK_COMMON_PROCEED",
              payload: {
                ProceedWithNavigation: false,
              },
            });
          }}
          submitPopupChanges={() => {
            dispatch({
              type: "OUTSIDE_CLICK_COMMON_PROCEED",
              payload: {
                ProceedWithNavigation: true,
              },
            });
          }}></AlertSubmitPopup>
        {isPopupVisible && (
          <div className="popup-dialog">
            <div
              className="dialog"
              style={{ height: popupType === 3 ? "200px" : "180px" }}>
              <div className="header">
                {(popupType === 1 ||
                  popupType === 7 ||
                  popupType === 8 ||
                  popupType === 9 ||
                  popupType === 10) && <h5>Discard Changes</h5>}
                {(popupType === 2 ||
                  popupType === 3 ||
                  popupType === 4 ||
                  popupType === 5 ||
                  popupType === 6) && <h5>Confirmation</h5>}
                <span
                  className="icon-close-filled icon"
                  onClick={() => setIsPopupVisible(false)}></span>
              </div>
              {(popupType === 1 ||
                popupType === 7 ||
                popupType === 8 ||
                popupType === 9 ||
                popupType === 10) && (
                  <div className="content">
                    <p>Would you like to discard changes?</p>
                    <p>
                      Please note that on confirming, unsaved changes will be
                      lost.
                    </p>
                  </div>
                )}
              {popupType === 2 && (
                <div className="content">
                  <p>Would you like to disable sections?</p>
                  <p>
                    Please note that on confirming, unsaved changes will be lost
                    and impact statements will be mapped under general section.
                  </p>
                </div>
              )}
              {popupType === 3 && (
                <div className="content">
                  <p>Would you like to enable sections?</p>
                  <p>
                    {" "}
                    Please note that on confirming, unsaved changes will be lost
                    and all the impact statements will be mapped under the first
                    section and you will have to select and move statements to
                    different sections as per your requirement.
                  </p>
                </div>
              )}
              {popupType === 4 && (
                <div className="content">
                  Would you like to delete the impact statement(s)?
                </div>
              )}
              {popupType === 5 && (
                <div className="content">
                  <p>Would you like to move the impact statements?</p>
                  <p>
                    Please note that this will move the impact statements to the
                    selected section.
                  </p>
                </div>
              )}
              {popupType === 6 && (
                <div className="content">
                  <p>
                    Please delete impact statement(s) within the section or move
                    the impact statement(s) to other sections before
                    unselecting.
                  </p>
                </div>
              )}
              <div className="footer">
                <button
                  className="cancel-btn"
                  onClick={() => setIsPopupVisible(false)}>
                  {popupType !== 6 ? "Cancel" : "OK"}
                </button>
                {popupType !== 6 && (
                  <button className="confirm-btn" onClick={onPopupConfirm}>
                    Confirm
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </SimpleBar>
  );
};
export default withRouter(GlobalMetric);
