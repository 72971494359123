import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { iconList } from "./iconlist";
import "./AdminPopup.scss";

import SimpleBar from "simplebar-react";

const ChangeIcon = (props) => {
  const [searchText, setSearchText] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");

  useEffect(() => {
    props.passValues(selectedIcon);
    // eslint-disable-next-line
  }, [selectedIcon]);
  useEffect(() => {
    if (props.iconName) {
      setSelectedIcon(props.iconName);
    }
    // eslint-disable-next-line
  }, [props.iconName]);

  return (
    <div className="add-new-icon">
      <div className="search-box">
        <i className="icon-search search-icon" />
        <input
          type="text"
          placeholder="Search"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            //   onChangeText(e);
          }}
        />
      </div>
      <div className="grid-display">
        <SimpleBar style={{ maxHeight: "270px" }}>
          {iconList &&
            iconList.map((item) => {
              if (item.includes(searchText))
                return (
                  <div
                    className={
                      "selct-div" +
                      (selectedIcon === item ? " selectedIcon" : "")
                    }
                    onClick={() => setSelectedIcon(item)}>
                    <i className={item} />
                  </div>
                );
              else return null;
            })}
        </SimpleBar>
      </div>
    </div>
  );
};
export default withRouter(ChangeIcon);
