import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { withStyles, Checkbox } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppContext from "../../utils/appContext";
import * as myConsts from "../../constants/constants";
import { getBaseLinePeroid } from "../../utils/utils";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiSvgIcon-root': {
      fontSize: '2.5rem',
    },
  },
}));

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
    width: "115px",
  },
  input: {
    borderRadius: 0,
    position: "relative",
    height: "11px",
    backgroundColor: "white",
    border: "1.5px solid #707070",
    fontSize: 13,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: "EYInterState-Regular",
    "&:focus": {
      // borderColor: "#707070",
      backgroundColor: "white",
      height: "10px",
      // boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const AnsCheckbox = React.memo((props) => {
  const {
    answer,
    selectedTab,
    currentObj,
    index,
    activePeriod,
    currentPeriod,
    changeSelectedData,
    assesmentOptions,
    usrQuestOpt,
    isPlanDataNull,
    readOnly,
    preview,
    handleImpactStmtFocusChecked,
    impactStmtFocusChecked
  } = props;

  const prevTimePeriod = getBaseLinePeroid(currentPeriod, usrQuestOpt);
  const myContext = useContext(AppContext);
  const [value, setValue] = useState(usrQuestOpt[currentPeriod]);
  const [prevValue, setPrevValue] = useState(usrQuestOpt[prevTimePeriod]);
  const classes = useStyles();

  useEffect(() => {
    if (
      currentObj.uiType === "check_box" ||
      currentObj.uiType === "drop_down"
    ) {
      setValue(
        usrQuestOpt[currentPeriod]
          ? usrQuestOpt[currentPeriod]
          : usrQuestOpt[prevTimePeriod]
      );
      setPrevValue(usrQuestOpt[prevTimePeriod]);
    } else {
      setValue(usrQuestOpt[currentPeriod]);
      setPrevValue(usrQuestOpt[prevTimePeriod]);
    }
    // eslint-disable-next-line
  }, [assesmentOptions]);

  const getClassNameSelect = (activePhase) => {
    if (!preview) {
      if (
        activePhase === myConsts.TimePeriodMidYear &&
        usrQuestOpt[prevTimePeriod]
      ) {
        return prevValue === value ? "prev-value-select" : "new-value-select";
      } else if (activePhase === myConsts.TimePeriodPlan) {
        return (
          (value ? "prev-value-select" : "no-value-select") +
          (isPlanDataNull ? " plan-select" : "")
        );
      } else if (activePhase === myConsts.TimePeriodYearEnd) {
        return prevValue ?
          prevValue === value ? "prev-value-select" : "new-value-select"
          : "";
      }
    }
  };

  const getClassNameCheckBox = (activePhase) => {
    if (!preview) {
      if (
        activePhase === myConsts.TimePeriodMidYear &&
        usrQuestOpt[prevTimePeriod]
      ) {
        return prevValue === value
          ? "prev-value"
          : value === -1
            ? "prev-value"
            : "new-value";
      } else if (activePhase === myConsts.TimePeriodPlan) {
        return value
          ? isPlanDataNull
            ? " prev-value-plan"
            : " prev-value"
          : "no-value";
      } else if (activePhase === myConsts.TimePeriodYearEnd) {
        return prevValue ?
          prevValue === value ? "prev-value" : "new-value"
          : "no-value";
      }
    } else {
      return "no-value";
    }
  };

  const getOptionsVal = (val, elem) => {
    if (assesmentOptions.length === 2) {
      const node = elem === "option_id" ? "option" : "option_id";
      const opt = assesmentOptions.find((v) => v[node] === val);
      if (opt && Object.keys(opt).length > 0) {
        return opt[elem];
      } else return;
    }
  };

  return (
    <div className="ans-wrap">
      {props.preview ? (
        <>
          {props.preview &&
            props.currentObj &&
            props.currentObj.uiType === "radio_button" ? (
            assesmentOptions &&
            assesmentOptions.map((opt, key) =>
              prevValue === opt.option_id ? (
                <div
                  style={{
                    width: "135px",
                    display: "flex",
                    justifyContent: "center",
                    cursor: "not-allowed",
                  }}
                  key={"div" + key}>
                  <div
                    className={
                      (opt.option_id !== "chkFocused" ? "radio-item " : "") + (value !== prevValue && value ? " " : " ")
                    }>
                    {
                      opt.option_id === "chkFocused" ?
                        (
                          <>
                            <Checkbox
                              color="default"
                              defaultValue={false}
                              className={classes.root}
                              name={answer.action_id}
                              style={{ color: "rgba(0, 0, 0, 0.54)" }}
                              disabled
                            >
                            </Checkbox>
                          </>
                        )
                        :
                        <>
                          <input
                            type="radio"
                            name={"oldVal" + index}
                            value={opt.option_id}
                            key={"3" + index + key}
                            checked
                            id={index + "" + key}
                          />
                          <label htmlFor={index + "" + key} />
                        </>
                    }
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: "135px",
                    display: "flex",
                    justifyContent: "center",
                    cursor: "not-allowed",
                  }}
                  key={"div" + key}>
                  <div
                    className={
                      (opt.option_id !== "chkFocused" ? "radio-item " : "") +
                      (value === opt.option_id
                        ? prevValue
                          ? " "
                          : isPlanDataNull
                            ? " "
                            : " "
                        : "")
                    }>
                    {
                      opt.option_id === "chkFocused" ?
                        (
                          <>
                            <Checkbox
                              color="default"
                              defaultValue={false}
                              className={classes.root}
                              name={answer.action_id}
                              style={{ color: "rgba(0, 0, 0, 0.54)" }}
                              disabled
                            >
                            </Checkbox>
                          </>
                        )
                        :
                        <>
                          <input
                            type="radio"
                            name={"newVal" + index}
                            key={key}
                            id={index + "" + key}
                            value={opt.option_id}
                            checked={value === opt.option_id}
                          />
                          <label htmlFor={index + "" + key} />
                        </>
                    }
                  </div>
                </div>
              )
            )
          ) : props.currentObj && props.currentObj.uiType === "check_box" ? (
            <div className={getClassNameCheckBox(currentPeriod)}>
              <i
                style={{ cursor: "not-allowed" }}
                className={"icon-check-unchecked"}
              />
            </div>
          ) : (
            //Selectbox
            <div
              className={getClassNameSelect(currentPeriod)}
              style={
                !myContext.disableUpdateSummary && !myContext.formsInActive
                  ? { cursor: "auto" }
                  : { cursor: "no-drop" }
              }>
              <FormControl variant="outlined">
                <NativeSelect
                  value={""}
                  IconComponent={ExpandMoreIcon}
                  input={<BootstrapInput />}>
                  {(currentPeriod === myConsts.TimePeriodPlan ||
                    isPlanDataNull ||
                    props.preview) && <option value="">{" Select "}</option>}
                  {assesmentOptions &&
                    assesmentOptions.map((opt, key) => (
                      <option disabled={true}>{opt.option}</option>
                    ))}
                </NativeSelect>
              </FormControl>
            </div>
          )}
        </>
      ) : (
        <>
          {props.currentObj && props.currentObj.uiType === "radio_button" ? (
            assesmentOptions &&
            assesmentOptions.map((opt, key) =>
              prevValue === opt.option_id ? (
                <div
                  style={{
                    width: "135px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className={
                    !myContext.disableUpdateSummary && !myContext.formsInActive
                      ? "enable-ans-options"
                      : "disable-ans-options"
                  }
                  key={"div" + key}
                  onClick={() => {
                    if (
                      !myContext.disableUpdateSummary &&
                      !myContext.formsInActive
                    ) {
                      changeSelectedData(
                        "radio",
                        selectedTab,
                        activePeriod,
                        answer.action_id,
                        opt.option_id
                      );
                      setValue(opt.option_id);
                      // myContext.setIsAutoSave(true);
                    }
                  }}>
                  <div
                    className={
                      (opt.option_id !== "chkFocused" ? "radio-item " : "") +
                      (value !== prevValue && value
                        ? " bg-baseChange"
                        : "bg-base ")
                    }>
                    {
                      opt.option_id === "chkFocused" ?
                        (
                          <>
                            <Checkbox
                              color="default"
                              defaultValue={false}
                              className={classes.root}
                              name={answer.action_id}
                              onChange={handleImpactStmtFocusChecked}
                              checked={impactStmtFocusChecked
                                && impactStmtFocusChecked.hasOwnProperty(`${answer.action_id}`)
                                && impactStmtFocusChecked[`${answer.action_id}`].hasOwnProperty(`focused`)
                                && impactStmtFocusChecked[`${answer.action_id}`].focused === true ? true : false}
                              disabled={
                                !myContext.disableUpdateSummary && !myContext.formsInActive
                                  ?
                                  impactStmtFocusChecked
                                    && impactStmtFocusChecked.hasOwnProperty(`${answer.action_id}`)
                                    && impactStmtFocusChecked[`${answer.action_id}`].hasOwnProperty(`disabled`)
                                    && impactStmtFocusChecked[`${answer.action_id}`].disabled === true ? true : false
                                  : true}
                            >
                            </Checkbox>
                          </>
                        )
                        :
                        <>
                          <input
                            type="radio"
                            name={"oldVal" + index}
                            value={opt.option_id}
                            key={"3" + index + key}
                            checked
                            id={index + "" + key}
                            onChange={() => {
                              setValue(opt.option_id);
                              // myContext.setIsAutoSave(true);
                            }}
                          />
                          <label htmlFor={index + "" + key} />
                        </>
                    }
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: "135px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className={
                    !myContext.disableUpdateSummary && !myContext.formsInActive
                      ? "enable-ans-options"
                      : "disable-ans-options"
                  }
                  key={"div" + key}>
                  <div
                    className={
                      (opt.option_id !== "chkFocused" ? "radio-item " : "") +
                      (value === opt.option_id
                        ? prevValue
                          ? "bg-change"
                          : isPlanDataNull
                            ? "bg-plan"
                            : "bg-base"
                        : "")
                    }>
                    {
                      opt.option_id === "chkFocused" ?
                        (
                          <>
                            <Checkbox
                              color="default"
                              defaultValue={false}
                              className={classes.root}
                              name={answer.action_id}
                              onChange={handleImpactStmtFocusChecked}
                              checked={impactStmtFocusChecked
                                && impactStmtFocusChecked.hasOwnProperty(`${answer.action_id}`)
                                && impactStmtFocusChecked[`${answer.action_id}`].hasOwnProperty(`focused`)
                                && impactStmtFocusChecked[`${answer.action_id}`].focused === true ? true : false}
                              disabled={
                                !myContext.disableUpdateSummary && !myContext.formsInActive
                                  ?
                                  impactStmtFocusChecked
                                    && impactStmtFocusChecked.hasOwnProperty(`${answer.action_id}`)
                                    && impactStmtFocusChecked[`${answer.action_id}`].hasOwnProperty(`disabled`)
                                    && impactStmtFocusChecked[`${answer.action_id}`].disabled === true ? true : false
                                  : true}
                            // disabled={                               
                            //     impactStmtFocusChecked
                            //       && impactStmtFocusChecked.hasOwnProperty(`${answer.action_id}`)
                            //       && impactStmtFocusChecked[`${answer.action_id}`].hasOwnProperty(`disabled`)
                            //       && impactStmtFocusChecked[`${answer.action_id}`].disabled === true ? true : false
                            //    }
                            >
                            </Checkbox>
                          </>
                        )
                        :
                        <>
                          <input
                            type="radio"
                            name={"newVal" + index}
                            key={key}
                            id={index + "" + key}
                            value={opt.option_id}
                            checked={value === opt.option_id}
                            onChange={() => {
                              if (
                                !myContext.disableUpdateSummary &&
                                !myContext.formsInActive
                              ) {
                                changeSelectedData(
                                  "radio",
                                  selectedTab,
                                  activePeriod,
                                  answer.action_id,
                                  opt.option_id
                                );
                                setValue(opt.option_id);
                                // myContext.setIsAutoSave(true);
                              }
                            }}
                          />
                          <label htmlFor={index + "" + key} />
                        </>
                    }
                  </div>
                </div>
              )
            )
          ) : props.currentObj && props.currentObj.uiType === "check_box" ? (
            <div className={getClassNameCheckBox(currentPeriod)}>
              <i
                style={
                  !myContext.disableUpdateSummary && !myContext.formsInActive
                    ? { cursor: "auto" }
                    : { cursor: "no-drop" }
                }
                className={
                  getOptionsVal(value, "option") === "True"
                    ? "icon-check-squere-filled"
                    : "icon-check-unchecked"
                }
                onClick={(e) => {
                  if (
                    !myContext.disableUpdateSummary &&
                    !myContext.formsInActive
                  ) {
                    isPlanDataNull
                      ? changeSelectedData(
                        "checkbox",
                        selectedTab,
                        activePeriod,
                        answer.action_id,
                        e.target.className === "icon-check-unchecked"
                          ? getOptionsVal("True", "option_id")
                          : getOptionsVal("False", "option_id"),
                        props.currentObj.data,
                        getOptionsVal("False", "option_id")
                      )
                      : changeSelectedData(
                        "checkbox",
                        selectedTab,
                        activePeriod,
                        answer.action_id,
                        e.target.className === "icon-check-unchecked"
                          ? getOptionsVal("True", "option_id")
                          : getOptionsVal("False", "option_id")
                      );
                    setValue(
                      e.target.className === "icon-check-unchecked"
                        ? getOptionsVal("True", "option_id")
                        : getOptionsVal("False", "option_id")
                    );
                    // myContext.setIsAutoSave(true);
                  }
                }}
              />
            </div>
          ) : (
            //Selectbox
            <div
              className={getClassNameSelect(currentPeriod)}
              style={
                !myContext.disableUpdateSummary && !myContext.formsInActive
                  ? { cursor: "auto" }
                  : { cursor: "no-drop" }
              }>
              <FormControl
                variant="outlined"
                disabled={
                  myContext.disableUpdateSummary || myContext.formsInActive
                }>
                <NativeSelect
                  value={value}
                  disabled={
                    myContext.disableUpdateSummary || myContext.formsInActive
                  }
                  onChange={(e) => {
                    if (
                      !myContext.disableUpdateSummary &&
                      !myContext.formsInActive
                    ) {
                      changeSelectedData(
                        "select",
                        selectedTab,
                        activePeriod,
                        answer.action_id,
                        e.target.value
                      );
                      setValue(e.target.value);
                    }
                    // myContext.setIsAutoSave(true);
                  }}
                  IconComponent={ExpandMoreIcon}
                  input={
                    <BootstrapInput
                      disabled={
                        myContext.disableUpdateSummary ||
                        myContext.formsInActive
                      }
                    />
                  }>
                  {(currentPeriod === myConsts.TimePeriodPlan ||
                    isPlanDataNull) && <option value="">{" Select "}</option>}
                  {assesmentOptions &&
                    assesmentOptions.map((opt, key) => (
                      <option
                        key={index + key + "select"}
                        value={opt.option_id}
                        disabled={
                          myContext.disableUpdateSummary ||
                          myContext.formsInActive
                        }>
                        {opt.option}
                      </option>
                    ))}
                </NativeSelect>
              </FormControl>
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default AnsCheckbox;
