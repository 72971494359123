import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../SideMenu/SideMenu";
import "simplebar/dist/simplebar.min.css";
import "./DashBoard.scss";
import SimpleBar from "simplebar-react";
import Survey from "./../survey/Survey";
import ProgressChartCard from "./DashBoardCards/ProgressChartCard";
import DashBoardFilters from "./DashBoardFilters";
import { authProvider, APIscope } from "./../../Authentication/authProvider";
import AppContext from "../../utils/appContext";
import * as myConsts from "../../constants/constants";
import Loader from "../Loader/Loader";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import BarChartCard from "./DashBoardCards/BarChartCard";
import DoubleBarChartCard from "./DashBoardCards/DoubleBarChartCard";
import Modal from "react-responsive-modal";
import BobTablePopup from "../BookOfBusiness/BobTablePopup";
import moment from "moment";

/* This component is similar to the dashboard component. Here we will display the goals 
for each pillars. User can visit this page either clicking the pillar in the dashboard page or
enter the url(<Domain_name>/Dashboard/<Pillar_id>) directly in the browser. User can't access 
this page until assesment has submitted */

const screenSize = "(min-width: 1300px)";
const DashBoardGoal = (props) => {
  const [loader, setLoader] = useState(true);
  const {
    match: { params },
  } = props;
  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  const fy = myContext.financialYear ? `${myContext.financialYear}`.substring(2, 4) : '22';
  const pillarChartData = useSelector((state) => state.userAssesmentScore);
  const goalChartData = useSelector((state) => state.userGoalScore);
  const goalFilters = useSelector((state) => state.dashboardGoalFilters.data);
  const pillarFilters = useSelector(
    (state) => state.dashboardPillarFilters.data
  );
  const userProfile = useSelector((state) => state.userProfile);
  const goalGroupScore = useSelector((state) => state.goalGroupScore);
  const pillarGroupScore = useSelector((state) => state.pillarGroupScore);
  const quantitativeScore = useSelector((state) => state.quantitativeScore);
  const assessmentMetaData = useSelector(
    (state) => state.getAssesmentMetaData.data
  );
  const [crumbs, setBreadcrumbs] = useState([
    { name: "Dashboard", path: myConsts.routeDashBoard },
  ]);
  const userBobData = useSelector((state) => state.getBobData);
  const userAssessmentStatus = useSelector(
    (state) => state.userAssessmentStatus.data
  );
  const [bobDetail, setBobDetail] = useState(null);
  const [bobStatus, setBobStatus] = useState("");
  const [focusAccount, setFocusAccount] = useState(false);
  const userProfileDataProps = useMemo(
    () => [
      "region",
      "tenor",
      "primarySector",
      "secondarySectorSpecialization",
      "locationCity",
    ],
    []
  );

  useEffect(() => {
    dispatch({
      type: "OUTSIDE_CLICK_RESET",
      payload: {},
    });
    if (params.pillarId !== "Revenue") {
      getGoalScore();
    }
    if (pillarChartData.data && pillarChartData.data.length === undefined) {
      getUserProfileData();
      getPillarScore();
    }
    // eslint-disable-next-line
  }, [currentPillarId]);

  useEffect(() => {
    (async function () {
      try {
        setLoader(true);
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;

        dispatch({
          type: "GET_BOB_DATA",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
          },
        });
        dispatch({
          type: "GET_USER_ASSESSMENT_STATUS",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
            userId: getCurrentUser(),
          },
        });
        dispatch({
          type: "GET_ALL_ASSESSMENT_DATA",
          payload: {
            token: idToken,

            activePeriod: myContext.currentPeriod,
            financialYear: myContext.financialYear,
          },
        });
        dispatch({
          type: "GET_ALL_USER_ASSESSMENT_DATA",
          payload: {
            token: idToken,

            activePeriod: myContext.currentPeriod,
            financialYear: myContext.financialYear,
          },
        });

        dispatch({
          type: "GET_ALL_OPTIONS",
          payload: {
            activePeriod: myContext.currentPeriod,
            financialYear: myContext.financialYear,
            tokens: idToken,
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();
    // eslint-disable-next-line
  }, []);

  const [bobTotalObj, setTotalObj] = useState({
    currentTerTotal: 0,
    priorTerTotal: 0,
    revenueTotal: 0,
    marginTotal: 0,
    currentMarginTotal: 0,
    priorMarginTotal: 0,
  });

  const getTotal = (data, val) => {
    const total = data.reduce((total, cur) => total + Number(cur[val]), 0);
    return total.toFixed(1);
  };

  const getCurrentUser = useCallback(() => {
    const ppmdID =
      myContext.activePPMDuserId || sessionStorage.activePPMDuserId;
    const userId = myContext.userId || sessionStorage.userId;
    return ppmdID || userId;
  }, [myContext]);

  const getPillarGroupScore = useCallback(
    (filters) => {
      (async function () {
        try {
          const token = await authProvider.acquireTokenSilent({
            scopes: APIscope,
          });
          const idToken = token.accessToken;
          dispatch({
            type: "GET_PILLAR_GROUP_SCORE",
            payload: {
              token: idToken,
              financialYear: myContext.financialYear,
              timePeriod: myContext.currentPeriod,
              filters: filters,
              rankDesc: userProfile.data[0].rankDesc,
            },
          });
        } catch (e) {
          console.error(e);
        }
      })();
    },
    [userProfile, dispatch, myContext]
  );

  const getGoalGroupScore = useCallback(
    (filters) => {
      dispatch({
        type: "SET_DASHBOARD_GOAL_FILTERS",
        payload: filters,
      });
      (async function () {
        try {
          const token = await authProvider.acquireTokenSilent({
            scopes: APIscope,
          });
          const idToken = token.accessToken;
          dispatch({
            type: "GET_GOAL_GROUP_SCORE",
            payload: {
              token: idToken,
              financialYear: myContext.financialYear,
              timePeriod: myContext.currentPeriod,
              pillarId: params.pillarId === "Margin" ? "P06": params.pillarId,
              filters: filters,
              rankDesc: userProfile.data[0].rankDesc,
            },
          });
        } catch (e) {
          console.error(e);
        }
      })();
    },
    [userProfile, params, dispatch, myContext]
  );

  const getQuantitativeScore = useCallback(
    (focus) => {
      (async function () {
        try {
          const token = await authProvider.acquireTokenSilent({
            scopes: APIscope,
          });
          const idToken = token.accessToken;
          dispatch({
            type: "GET_QUANTITATIVE_SCORE",
            payload: {
              token: idToken,
              financialYear: myContext.financialYear,
              timePeriod: myContext.currentPeriod,
              focus,
            },
          });
        } catch (e) {
          console.error(e);
        }
      })();
    },
    [dispatch, myContext]
  );

  const setFilterData = useCallback(() => {
    const copyGoalFilters = JSON.parse(JSON.stringify(pillarFilters));
    userProfileDataProps.forEach((data, index) => {
      if (
        copyGoalFilters[index + 1].paramName === "tenor" &&
        userProfile.data[0].role === "Executive Director"
      ) {
        copyGoalFilters[index + 1].subText = "";
      } else {
        copyGoalFilters[index + 1].subText = userProfile.data[0][data];
      }
    });
    if (params.pillarId !== "Revenue") {
      getGoalGroupScore(copyGoalFilters);
    } else {
      copyGoalFilters.forEach((filter, index) => {
        if (index === 1 || index === 2) {
          filter.isSelected = true;
        } else {
          filter.isSelected = false;
        }
      });
      getPillarGroupScore(copyGoalFilters);
      dispatch({
        type: "SET_DASHBOARD_GOAL_FILTERS",
        payload: copyGoalFilters,
      });
    }
  }, [
    pillarFilters,
    userProfileDataProps,
    params,
    dispatch,
    getPillarGroupScore,
    getGoalGroupScore,
    userProfile,
  ]);

  useEffect(() => {
    if (userBobData && userBobData.data && userBobData.data.bobDetails) {
      setLoader(false);
      const dataQuantitave =
        userBobData.data && userBobData.data.bobQuantitative;
      const data = userBobData.data.bobDetails;
      const obj = {
        currentTerTotal: getTotal(data, "currentTER"),
        priorTerTotal: getTotal(data, "priorTER"),
        revenueTotal: getTotal(data, "revenueGoalIn"),
        marginTotal: getTotal(data, "marginIn"),
        currentMarginTotal: dataQuantitave && dataQuantitave.highImpactMargin,
        priorMarginTotal: dataQuantitave && dataQuantitave.priorMargin,
      };
      setTotalObj(obj);
      setBobDetail(data);
    }
    if (userBobData && userBobData.data && userBobData.data.bobStatus) {
      setBobStatus(userBobData.data.bobStatus);
    }
  }, [userBobData]);
  const setRoleSelected = (index, e) => {
    let tempArr = [...bobDetail];
    tempArr[index].role = e.target.value;
    setBobDetail(tempArr);
  };

  const setFocusClientSelected = (index, e) => {
    let tempArr = [...bobDetail];
    tempArr[index].focusClient = e.target.value;
    setBobDetail(tempArr);
  };

  useEffect(() => {
    if (
      pillarChartData.fetching ||
      goalChartData.fetching ||
      goalGroupScore.fetching ||
      quantitativeScore.fetching
    ) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [pillarChartData, goalChartData, goalGroupScore, quantitativeScore]);

  useEffect(() => {
    const copyCrumbs = [...crumbs];
    const isExitAlready = copyCrumbs.some(
      (crumb) => crumb.name === "Margin" || crumb.name === "Revenue"
    );
    if (isExitAlready || crumbs.length === 2) return;
    if (params.pillarId === "Revenue") {
      copyCrumbs.push({ name: "Revenue", path: "" });
    } else if (params.pillarId === "Margin") {
      copyCrumbs.push({ name: "Margin", path: "" });
    } else if (pillarChartData.data && pillarChartData.data.length > 0) {
      const pillar = pillarChartData.data.find(
        (pillar) => pillar.pillarId === params.pillarId
      );

      copyCrumbs.push({ name: pillar.pillar, path: "" });
    }
    setBreadcrumbs(copyCrumbs);
  }, [pillarChartData, params, crumbs]);

  useEffect(() => {
    if (userProfile.data.length > 0) {
      setFilterData();
    }
    // eslint-disable-next-line
  }, [userProfile]);

  const getUserProfileData = useCallback(() => {
    (async function () {
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "GET_USER_PROFILE_BY_ID",
          payload: {
            tokens: idToken,
            userId: getCurrentUser(),
            financialYear: myContext.financialYear,
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getCurrentUser]);

  const getPillarScore = useCallback(() => {
    (async function () {
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "GET_USER_ASSESSMENT_SCORE",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
          },
        });
        getQuantitativeScore(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, myContext, getQuantitativeScore]);

  const getGoalScore = useCallback(() => {
    (async function () {
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "GET_USER_GOAL_SCORE",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
            pillarId: currentPillarId,
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, myContext, params, currentPillarId]);

  const removeFilter = useCallback(
    (filters, index) => {
      let copyFilterOptions = [...filters];
      copyFilterOptions[index].isSelected = false;
      getGoalGroupScore(copyFilterOptions);
    },
    [getGoalGroupScore]
  );

  const getTenure = useCallback((tenure) => {
    if (tenure <= 5) {
      return "0-5 Years";
    } else if (tenure > 5 && tenure <= 10) {
      return "6-10 Years";
    } else if (tenure > 10) {
      return "11+";
    } else {
      return "1 Year";
    }
  }, []);

  const isHideCloseBtn = useCallback(() => {
    const selectedFilters = goalFilters.filter(
      (filter) => filter.isSelected && filter.option !== "Select all"
    );
    return selectedFilters && selectedFilters.length === 1;
  }, [goalFilters]);

  const isPeerGroupVisible = useCallback(() => {
    if (
      userAssessmentStatus?.data &&
      userAssessmentStatus.data.metaEndDate !== null
    ) {
      const endDate = userAssessmentStatus.data.metaEndDate;
      const currentDate = moment.utc(new Date()).format();

      return moment(currentDate).isSameOrAfter(endDate);
    } else return false;
  }, [assessmentMetaData, myContext]);

  const getPeerGroupAvg = useCallback(
    (goalId, goalScore) => {
      if (isPeerGroupVisible()) {
        const goal =
          goalGroupScore.data.length > 0 &&
          goalGroupScore.data.find((goal) => goal.goalId === goalId);
        return goal.groupScore ? goal.groupScore : goalScore;
      } else {
        return 0;
      }
    },
    [goalGroupScore, isPeerGroupVisible]
  );

  const isHideFilters = useCallback(() => {
    return params.pillarId === "Margin" || params.pillarId === "Revenue";
  }, [params]);

  const [isOpen, setOpen] = useState(false);
  const [goalObj, setGoalObj] = useState({});

  const openModal = (pillarId, goalId, goalName, isMarginGoals=false) => {
    if (goalName !== "p5p6") {
      let pillar;
      if (isMarginGoals) {
        pillar = pillarChartData.data.find(
          (pillar) => pillar.pillar === pillarId
        );
      } else {
        pillar = pillarChartData.data.find(
          (pillar) => pillar.pillarId === pillarId
        );
      }
      setGoalObj({
        pillarId: pillar.pillarId,
        goalId: goalId,
        goalName: goalName,
        pillarName: pillar.pillar,
        isMarginGoals
      });
      myContext.setDisableUpdateSummary(true);
      sessionStorage.setItem("disableUpdateSummary", true);
    }
    setOpen(true);
  };

  const findRank = useCallback(
    (val) => {
      if (!val || !isPeerGroupVisible()) {
        return {
          level: "",
          position: "",
        };
      }
      const pos = [
        "bottom 10",
        "bottom 20",
        "bottom 30",
        "bottom 40",
        "bottom 50",
        "top 50",
        "top 40",
        "top 30",
        "top 20",
        "top 10",
      ];
      const rank = pos[val * 10 - 1].split(" ");
      return {
        level: parseInt(rank[1]) === 50 ? "mid" : rank[0],
        position: rank[1],
      };
    },
    [isPeerGroupVisible]
  );

  const currentPillarId = useMemo(() => {
    if (params.pillarId === "Margin") {
      const pillar =
        pillarChartData.data &&
        pillarChartData.data.length > 0 &&
        pillarChartData.data.find(
          (pillar) => pillar.pillar === params.pillarId
        );
      return pillar.pillarId;
    } else {
      return params.pillarId;
    }
  }, [pillarChartData]);

  /*const marginGoalId = useMemo(() => {
    if (
      params.pillarId === "Margin" &&
      goalChartData.data &&
      goalChartData.data.length > 0
    ) {
      return goalChartData.data[0].goalId;
    } else {
      return "";
    }
  }, [goalChartData]);

  const marginPillarScore = useMemo(() => {
    const marginScore =
      pillarChartData.data &&
      pillarChartData.data.length > 0 &&
      pillarChartData.data.find((pillar) => pillar.pillar === "Margin");
    return marginScore && marginScore.pillarScore ? marginScore.pillarScore : 0;
  }, [pillarChartData]);

  const marginGroupScore = useMemo(() => {
    if (isPeerGroupVisible()) {
      const marginPillar =
        pillarChartData.data &&
        pillarChartData.data.length > 0 &&
        pillarChartData.data.find((pillar) => pillar.pillar === "Margin");
      const marginScore =
        pillarGroupScore.data &&
        pillarGroupScore.data.length > 0 &&
        pillarGroupScore.data.find(
          (pillar) => pillar.pillarId === marginPillar.pillarId
        );
      return marginScore && marginScore.groupScore
        ? marginScore.groupScore
        : marginPillarScore;
    } else {
      return 0;
    }
  }, [pillarGroupScore, marginPillarScore, isPeerGroupVisible]); */

  const toggleFocusAccount = useCallback(
    (focus) => {
      setFocusAccount(focus);
      getQuantitativeScore(focus);
    },
    [getQuantitativeScore]
  );

  useEffect(() => {
    if (
      userAssessmentStatus?.data &&
      !(
        userAssessmentStatus.data.status.toLowerCase() ===
          myConsts.statusSubmitted ||
        userAssessmentStatus.data.status.toLowerCase() ===
          myConsts.statusCompleted
      )
    ) {
      props.history.push(myConsts.routeHome);
    }
  }, [userAssessmentStatus, props.history]);

  return (
    <div className="dashBoard-info-main-container">
      {loader && <Loader />}
      <div className="dashBoard-info-main-sidemenu">
        <SideMenu
          minimized={true}
          isminimizedMainMenu={true}
          isMainMenu={true}
          sideMenuItemSelect="Home"
        />
      </div>
      <div className="dashBoard-info-container">
        <div
          className="second-header"
          style={{ paddingLeft: "20px", paddingRight: "20px" }}>
          <Breadcrumb crumbs={crumbs} {...props} />
          <div className="goal-filters">
            <DashBoardFilters
              filterOptions={goalFilters}
              onApplyFilter={(filters) => getGoalGroupScore(filters)}
              isHideFilter={isHideFilters()}
            />
          </div>
        </div>

        <div>
          <SimpleBar
            style={{
              width: "100%",
              height:
                "calc(" +
                (window.matchMedia(screenSize).matches ? "88vh" : "86vh") +
                " - 60px)",
            }}
            className="dashBoardContent">
            <div className="chicletsCotnainer">
              {goalFilters.map((option, index) => (
                <React.Fragment key={index}>
                  {option.isSelected && index !== 0 && (
                    <div className="chiclet">
                      <span className="chiclet-option">
                        <span className="chiclet-name">{option.option}</span>
                        {option.paramName === "tenor" ? (
                          <span className="sub-text">
                            {getTenure(parseInt(option.subText))}
                          </span>
                        ) : (
                          <>
                            {option.subText && (
                              <span className="sub-text">
                                {option.subText === "NULL" ||
                                option.subText === "null"
                                  ? "All"
                                  : option.subText}
                              </span>
                            )}
                          </>
                        )}
                      </span>
                      {!option.isDisabled &&
                        !isHideCloseBtn() &&
                        !isHideFilters() && (
                          <span
                            className="close-icon"
                            onClick={() => removeFilter(goalFilters, index)}>
                            &times;
                          </span>
                        )}
                    </div>
                  )}
                </React.Fragment>
              ))}
              {params.pillarId === "Margin" || params.pillarId === "Revenue" ? (
                <div className="input-group">
                  <label htmlFor="toggleFocus">Focus Account</label>
                  <input
                    type="checkbox"
                    name="toggleFocus"
                    onChange={() => {
                      toggleFocusAccount(!focusAccount);
                    }}
                  />
                </div>
              ) : null}
            </div>
            {!loader && (
              <Grid
                container
                spacing={2}
                className="dashboardCharts dashboardGoalCharts">
                {params.pillarId !== "Margin" &&
                params.pillarId !== "Revenue" &&
                goalChartData.data &&
                goalChartData.data.length > 0 ? (
                  goalChartData.data.map((data, index) => (
                    <Grid item lg={3} key={index}>
                      <ProgressChartCard
                        pillarName={data.goalName}
                        pillarId={data.goalId}
                        pillarScore={data.goalScore}
                        peerGroupAvg={getPeerGroupAvg(
                          data.goalId,
                          data.goalScore
                        )}
                        onClick={(goalId) =>
                          openModal(params.pillarId, goalId, data.goalName)
                        }
                        peerGroupVisible={isPeerGroupVisible()}
                      />
                    </Grid>
                  ))
                ) : params.pillarId === "Revenue" ? (
                  <>
                    <Grid item lg={3}>
                      <DoubleBarChartCard
                        rank={
                          quantitativeScore.data && quantitativeScore.data[0]
                            ? findRank(
                                quantitativeScore.data[0].revenueGoalPercentile
                              )
                            : findRank("")
                        }
                        revenue={
                          quantitativeScore.data &&
                          quantitativeScore.data[0] &&
                          quantitativeScore.data[0].revenue
                        }
                        revenueGoal={
                          quantitativeScore.data &&
                          quantitativeScore.data[0] &&
                          quantitativeScore.data[0].revenueGoal
                        }
                        header="Deliver High-Impact Revenue vs Goal"
                        onClick={(goalId) =>
                          openModal(params.pillarId, goalId, "p5p6")
                        }
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <BarChartCard
                        rank={
                          quantitativeScore.data && quantitativeScore.data[0]
                            ? findRank(
                                quantitativeScore.data[0]
                                  .highImpactRevenueGrowthPercentile
                              )
                            : findRank("")
                        }
                        percentage={
                          quantitativeScore.data &&
                          quantitativeScore.data[0] &&
                          quantitativeScore.data[0].highImpactRevenueGrowth
                        }
                        header="Deliver High-Impact Revenue Growth"
                        percentageInfo={`FY${fy} TER YTD`}
                        onClick={(goalId) =>
                          openModal(params.pillarId, goalId, "p5p6")
                        }
                      />
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item lg={3}>
                      <BarChartCard
                        rank={
                          quantitativeScore.data && quantitativeScore.data[0]
                            ? findRank(
                                quantitativeScore.data[0]
                                  .highImpactMarginPercentile
                              )
                            : findRank("")
                        }
                        percentage={
                          quantitativeScore.data &&
                          quantitativeScore.data[0] &&
                          quantitativeScore.data[0].highImpactMargin
                        }
                        percentageInfo={`FY${fy} Margin YTD`}
                        header="Deliver High-Impact Margin"
                        onClick={(goalId) =>
                          openModal(params.pillarId, goalId, "p5p6")
                        }
                      />
                    </Grid>
                    <Grid item lg={3}>
                      <BarChartCard
                        rank={
                          quantitativeScore.data && quantitativeScore.data[0]
                            ? findRank(
                                quantitativeScore.data[0]
                                  .highImpactMarginGrowthPercentile
                              )
                            : findRank("")
                        }
                        percentage={
                          quantitativeScore.data &&
                          quantitativeScore.data[0] &&
                          quantitativeScore.data[0].highImpactMarginGrowth
                        }
                        header="Deliver High-Impact Margin Growth"
                        percentageInfo={`FY${fy} Margin Growth YTD`}
                        isBasisPoints={true}
                        onClick={(goalId) =>
                          openModal(params.pillarId, goalId, "p5p6")
                        }
                      />
                    </Grid>
                   {/* <Grid item lg={3}>
                      <ProgressChartCard
                        pillarName="Activate Margin Levers (Qualitative)"
                        pillarId="P09"
                        pillarScore={marginPillarScore}
                        peerGroupAvg={marginGroupScore}
                        chartMargin={12}
                        onClick={(goalId) =>
                          openModal(
                            params.pillarId,
                            marginGoalId,
                            "Activate levers"
                          )
                        }
                        peerGroupVisible={isPeerGroupVisible()}
                      />
                      </Grid>*/}
                      {goalChartData.data.length > 0 &&
                        goalChartData.data.map((goal, index) => (
                          <Grid item lg={3}>
                      <ProgressChartCard
                        pillarName={goal.goalName}
                        pillarId={goal.goalId}
                        pillarScore={goal.goalScore}
                        peerGroupAvg={getPeerGroupAvg(
                          goal.goalId,
                          goal.goalScore
                        )}
                        chartMargin={12}
                        onClick={(goalId) =>
                          openModal(
                            params.pillarId,
                            goal.goalId,
                            goal.goalName,
                            true
                          )
                        }
                        peerGroupVisible={isPeerGroupVisible()}
                      />
                      </Grid>
                        ))
                      }
                  </>
                )}
              </Grid>
            )}
          </SimpleBar>
        </div>
      </div>
      <Modal
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setGoalObj({});
        }}
        styles={{
          modal: {
            maxWidth: "unset",
            padding: "unset",
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.5)",
          },
          /*  closeButton: {
          background: "white",
        }, */
        }}
        center>
        <SimpleBar
          style={{
            overflowX: "hidden",
            maxHeight: "82vh",
            width:
              ((params.pillarId === "Margin" ||
                params.pillarId === "Revenue") &&
                !goalObj.isMarginGoals) &&
              (window.matchMedia("(max-width: 1280px)").matches
                ? "92.7vw"
                : "90vw"),
          }}
          className="survey-wrap">
          {(params.pillarId === "Margin" || params.pillarId === "Revenue") &&
          !goalObj.isMarginGoals ? (
            <BobTablePopup
              bobTotalObj={bobTotalObj}
              bobDetail={bobDetail}
              bobStatus={bobStatus}
              setFocusClientSelected={setFocusClientSelected}
              setRoleSelected={setRoleSelected}
            />
          ) : (
            <>
              <div className="modal-heading">
                {goalObj.pillarName + " - " + goalObj.goalName}
              </div>
              <div style={{ height: "80vh" }}>
                <Survey
                  readOnly={true}
                  goalObj={goalObj}
                  match={{ params: "" }}
                />
              </div>
            </>
          )}
        </SimpleBar>
      </Modal>
    </div>
  );
};

export default DashBoardGoal;
