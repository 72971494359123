import React, { useCallback, useState, useEffect, useContext } from "react";
import moment from "moment";
import ChangesHistoryField from "../../Approvals/ChangesHistoryField/ChangesHistoryField";

/* Admin can search the user by either GPN or mail id. Then admin can reset the BoB or Assessment if 
their status is submitted. otherwise the radio buttons are disabled. if both status are submitted, then 
both radio button will be enabled */

const PreviewMode = (props) => {
  const { highlightsData, userDetails } = props;

  const proccessRoles = useCallback(
    (role) => {
      if (role === "LeadReviewer-PPMD" && userDetails.isAdmin) {
        return "Lead Reviewer, PPMD, Admin"
      } else if (role === "LeadReviewer-PPMD") {
        return "Lead Reviewer, PPMD"
      } else if (role === "LeadReviewer" && userDetails.isAdmin) {
        return "Lead Reviewer, Admin";
      } else if (role === "LeadReviewer") {
        return "Lead Reviewer";
      } else if (role === "PPMD" && userDetails.isAdmin) {
        return "PPMD, Admin";
      } else if (role === "PPMD") {
        return "PPMD";
      } else if (role === "Admin") {
        return "Admin";
      }
    },
    [userDetails]
  );

  return (
    <div className="demographic-details preview">
      <h6>Demographic Details</h6>
      <ChangesHistoryField
        size={"sm"}
        key="gpn"
        identifier="gpn"
        fieldLabel="GPN*"
        value={userDetails.gpn}
        oldValue={highlightsData.gpn}
      />
      <ChangesHistoryField
        size={"sm"}
        key="lpn"
        identifier="lpn"
        fieldLabel="LPN*"
        value={userDetails.lpn}
        oldValue={highlightsData.lpn}
      />
      <ChangesHistoryField
        size={"sm"}
        key="gui"
        identifier="gui"
        fieldLabel="GUI*"
        value={userDetails.gui}
        oldValue={highlightsData.gui}
      />
      <ChangesHistoryField
        size={"sm"}
        key="rankCode"
        identifier="rankCode"
        fieldLabel="Rank Code*"
        value={userDetails.rankCode}
        oldValue={highlightsData.rankCode}
      />
      <ChangesHistoryField
        size={"sm"}
        key="serviceLine"
        identifier="serviceLine"
        fieldLabel="Service line*"
        value={userDetails.serviceLine}
        oldValue={highlightsData.serviceLine}
      />
      <ChangesHistoryField
        size={"sm"}
        key="subServiceLine"
        identifier="subServiceLine"
        fieldLabel="Sub service line*"
        value={userDetails.subServiceLine}
        oldValue={highlightsData.subServiceLine}
      />
      <ChangesHistoryField
        size={"sm"}
        key="country"
        identifier="gpn"
        fieldLabel="Country*"
        value={userDetails.country}
        oldValue={highlightsData.country}
      />
      <ChangesHistoryField
        size={"sm"}
        key="region"
        identifier="region"
        fieldLabel="Region*"
        value={userDetails.region}
        oldValue={highlightsData.region}
      />
      <ChangesHistoryField
        size={"sm"}
        key="locationCity"
        identifier="locationCity"
        fieldLabel="City*"
        value={userDetails.locationCity}
        oldValue={highlightsData.locationCity}
      />
      <ChangesHistoryField
        size={"sm"}
        key="tenorStartDate"
        identifier="tenorStartDate"
        fieldLabel="Tenure Start date*"
        value={moment(userDetails.tenorStartDate).format("YYYY/MM/DD")}
        oldValue={
          highlightsData.tenorStartDate
            ? moment(highlightsData.tenorStartDate).format("YYYY/MM/DD")
            : ""
        }
      />
      <ChangesHistoryField
        size={"sm"}
        key="counselorName"
        identifier="counselorName"
        fieldLabel="Counsellor Name*"
        value={userDetails.counselorName}
        oldValue={highlightsData.counselorName}
      />
      <ChangesHistoryField
        size={"sm"}
        key="counselorEmail"
        identifier="counselorEmail"
        fieldLabel="Counsellor Email*"
        value={userDetails.counselorEmail}
        oldValue={highlightsData.counselorEmail}
      />
      <ChangesHistoryField
        size={"sm"}
        key="counselorGUI"
        identifier="counselorGUI"
        fieldLabel="Counsellor GUI*"
        value={userDetails.counselorGUI}
        oldValue={highlightsData.counselorGUI}
      />
      <ChangesHistoryField
        size={"sm"}
        key="buNumber"
        identifier="buNumber"
        fieldLabel="BU Number*"
        value={userDetails.buNumber}
        oldValue={highlightsData.buNumber}
      />
      <ChangesHistoryField
        size={"sm"}
        key="smuCode"
        identifier="smuCode"
        fieldLabel="SMU Code*"
        value={userDetails.smuCode}
        oldValue={highlightsData.smuCode}
      />
      <h6>Select User Role</h6>
      <ChangesHistoryField
        size={"sm"}
        key="role"
        identifier="role"
        fieldLabel="Role*"
        value={proccessRoles(userDetails.role)}
        oldValue={highlightsData.role}
      />
      <h6>Select Additional Details</h6>
      <ChangesHistoryField
        size={"sm"}
        key="primarySector"
        identifier="primarySector"
        fieldLabel="Primary Sector"
        value={userDetails.primarySector}
        oldValue={highlightsData.primarySector}
      />
      <ChangesHistoryField
        size={"sm"}
        key="secondarySectorSpecialization"
        identifier="secondarySectorSpecialization"
        fieldLabel="Secondary Sector"
        value={userDetails.secondarySectorSpecialization}
        oldValue={highlightsData.secondarySectorSpecialization}
      />
      <ChangesHistoryField
        size={"sm"}
        key="additionalResponsibility"
        identifier="additionalResponsibility"
        fieldLabel="Additional Responsibility"
        value={userDetails.additionalResponsibility}
        oldValue={highlightsData.additionalResponsibility?.toString()}
      />
    </div>
  );
};
export default PreviewMode;
