import React, { useCallback, useState, useEffect, useContext } from "react";
import SideMenu from "../../SideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import AppContext from "../../../utils/appContext";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { toast } from "react-toastify";
import { Flip } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import "../Admin.scss";
import "./Assessment.scss";
import * as myConsts from "../../../constants/constants";
import AssessmentAdmin from "../AssessmentAdmin/AssessmentAdmin";
import RollForward from "./RollForward/RollForward";
import AddAssessmentContent from "./AddAssessmentContent/AddAssessmentContent";
import Modal from "react-responsive-modal";
import SimpleBar from "simplebar-react";
import Survey from "./../../survey/SurveyPreview";
import moment from "moment";
import _ from 'lodash'

const Assessment = (props) => {
  const myContext = useContext(AppContext);
  const outsideClickState = useSelector(
    (state) => state.outsideClickCommon.data
  );
  const submitAdminAssessment = useSelector(
    (state) => state.submitAdminAssessment
  );
  const approvalPendingYears = useSelector((state) => state.getAssessmentSubmissionStatus.pendingYears);
  const approvedAssessmentYears = useSelector((state) => state.getAssessmentSubmissionStatus.approvedYears);
  const approvalList = useSelector((state) => state.getAssessmentSubmissionStatus.approvalList);
  const activePhaseYear = useSelector((state) => state.getValidationAssesmentMetaData.activeYear);
  const allAssesmentData = useSelector((state) => state.allAssesmentData.data);
  const assessmentData = _.get(allAssesmentData, "data.assessmentDetails", []);

  const dispatch = useDispatch();
  const [submittedStatus, setSubmittedStatus] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [goalObj, setGoalObj] = useState({});
  const [addNewGlobalMetricScreen, setaddNewGlobalMetricScreen] =
    useState(false); //if true single pillar preview else whole preview
  const [lastUpdateInfo, setLastUpdateInfo] = useState({ name: "", date: "" });
  const [backToListing, setBackToListing] = useState(false);
  const [anchor, setanchor] = React.useState(null);
  const openpopUp = Boolean(anchor);
  const [addNewGlobalMetricData, setaddNewGlobalMetricData] = useState({});
  const [currentPageName, setCurrentPageName] = useState(
    sessionStorage.getItem("currentPageName") || "Assessment"
  );
  const [currentPage, setCurrentPage] = useState(
    sessionStorage.getItem("currentPageName")
      ? sessionStorage.getItem("currentPageName") === "Roll Forward Assessment"
        ? 1
        : sessionStorage.getItem("currentPageName") === "Manage Assessment"
        ? 2
        : sessionStorage.getItem("currentPageName") === "Add Assessment Content"
        ? 3
        : null
      : null
  );
  const [isMainPageVisible, setIsMainPageVisible] = useState(
    sessionStorage.getItem("currentPageName")
      ? sessionStorage.getItem("currentPageName") !== "Assessment"
        ? false
        : true
      : true
  );
  const [isChangesInChild, setIsChangesInChild] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isConfirmationPopupVisible, setIsConfirmationPopupVisible] =
    useState(false);
  const [invalidData, setInvalidData] = useState({});
  const [selectedYear, setSelectedYear] = useState(0);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [invalidDataPresent, setInvalidDataPresent] = useState(false);
  const [arrowRef, setArrowRef] = React.useState(null);
  const [crumbs, setBreadcrumbs] = useState([
    { name: "Admin", path: myConsts.routeAdmin },
  ]);

  const [isApprovalPreview, setIsApprovalPreview] = useState(false);
  const [isReviewer, setIsReviewer] = useState(false);
  const adminApprovalData = useSelector((state) => state.approvalData);

  const useStyles = makeStyles((theme) => ({
    arrow: {
      position: "absolute",
      fontSize: 14,
      width: "6em",
      height: "4em",
      //   transform: "rotate(225deg)",
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
      },
    },
    header: {
      height: "27px",
      paddingBottom: "4px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      borderBottom: "1px solid #00000029",
      paddingLeft: "5px",
    },
    paper2: {
      backgroundColor: "white",
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      // padding: theme.spacing(2, 2, 2),
      width: 375,
      maxHeight: 479,
      overflow: "hidden",
      overflowX: "hidden",
      fontSize: "12px",
    },
    popper: {
      zIndex: 100,
      left: "-10px!important",
      top: "8px!important",
      '&[x-placement*="top-end"]': {
        top: "-12px!important",
      },
      '&[x-placement*="bottom-end"]': {
        top: "9px!important",
      },
      '&[x-placement*="bottom"] $arrow': {
        top: 2,
        left: "294px!important",
        marginTop: "-0.9em",
        width: "6em",
        height: "2em",
        "&::before": {
          borderWidth: "0 1em 1em 1em",
          borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
        },
      },
      '&[x-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        marginBottom: "-0.9em",
        width: "6em",
        height: "4em",
        "&::before": {
          borderWidth: "1em 1em 0 1em",
          borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: "-0.9em",
        width: "6em",
        height: "4em",
        "&::before": {
          borderWidth: "1em 1em 1em 0",
          borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: "-0.9em",
        width: "6em",
        height: "4em",
        "&::before": {
          borderWidth: "1em 0 1em 1em",
          borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
        },
      },
    },
  }));
  const classes = useStyles();

  const setCurrentPageConfig = useCallback((pageNo) => {
    switch (pageNo) {
      case 1:
        setCurrentPageName("Roll Forward Assessment");
        sessionStorage.setItem("currentPageName", "Roll Forward Assessment");
        break;
      case 2:
        setCurrentPageName("Manage Assessment");
        sessionStorage.setItem("currentPageName", "Manage Assessment");
        break;
      case 3:
        setCurrentPageName("Add Assessment Content");
        sessionStorage.setItem("currentPageName", "Add Assessment Content");
        break;
      default:
        setCurrentPageName("");
    }
    setCurrentPage(pageNo);
    setIsMainPageVisible(false);
  }, []);

  useEffect(() => {
    // redirect direclty to manage assessment incase routed from admin approval page
    if(props.location && props.location.pathname === myConsts.routeAdminAssessmentApprovals) {  
      setCurrentPageConfig(2)
    }
    
    dispatch({
      type: "OUTSIDE_CLICK_COMMON_RESET",
      payload: {},
    });
   }, []);

   const getValidationAssesmentMetaData = (year) => {
    dispatch({
      type: "GET_VALIDATION_ASSESSMENT_META_DATA",
      payload: {
          financialYear: year,
          userId: myContext.userId,
      },
  });
  }

  useEffect(() => {
    const type = _.get(adminApprovalData, "previewParams.type", "");
    if (type === "Assessment") {
      const isPreview = _.get(adminApprovalData, "isPreview", false);
      setIsApprovalPreview(isPreview);
      setIsReviewer( _.get(adminApprovalData, "previewParams.isReviewer", false))
      if(isPreview) {
        setBreadcrumbs([
          { name: "Admin", path: myConsts.routeAdmin },
          { name: "Approvals", path: myConsts.routeAdminApprovals },
        ]);
       } else {
        setBreadcrumbs([
          { name: "Admin", path: myConsts.routeAdmin },
        ]);
       }
    } 
  }, [adminApprovalData])

  useEffect(() => {
    if (!isApprovalPreview) {
        getAssessmentApprovalList();
        getValidationAssesmentMetaData(myContext.financialYear);
    }
  }, [isApprovalPreview])


  const getAssessmentApprovalList = () => {
    // approval list is used to verify the valid years on which roll forward and upload content can be done.
      dispatch({
        type: "GET_ASSESSMENT_APPROVAL_LIST",
        payload: {
          type: "Assessment"
        },
      });
  }

  const getFormattedDate = (dateString) => {
    return dateString ? moment(dateString).format("DD-MMM-YYYY hh:mm A") : "";
  };

  const setLastEditData = (data) => {
    setLastUpdateInfo({
      date: getFormattedDate(_.get(data, "updatedOn", "")),
      name: _.get(data, "updatedBy.name", ""),
    });
  };

  const isValidYear = useCallback(() => {
    return  (selectedYear > activePhaseYear) && ( selectedYear > myContext.financialYear) 
   },[selectedYear])
  
  
   const isSubmittedYear = useCallback(() => {
    // selected year should not be included in the assessment pending approval status
    return approvalPendingYears.includes(selectedYear); 
   },[selectedYear, approvalPendingYears])
  
   const isApprovedYear = useCallback(() => {
     // asessment approved by admins or current or previous financial years
     return approvedAssessmentYears.includes(selectedYear) || (selectedYear <= myContext.financialYear) ; 
   },[selectedYear, approvedAssessmentYears])
  
  
   const assessmentStatus = useCallback(() => {
    const latestItem = approvalList.find(el => el.financialYear == selectedYear);
    let status = "Draft";
    if(selectedYear <= myContext.financialYear){
      status = "Approved";
    } else if(latestItem) {
      let temp = latestItem.validationStatus;
      status = (temp === "In Draft") ? "Draft" : temp;
    }
    return status;
  },[selectedYear, approvalList])

  useEffect(() => {
    const latestItem = approvalList.find(el => el.financialYear == selectedYear);
      setLastEditData(latestItem ||  {});
  }, [selectedYear, approvalList])

  useEffect(() => {
    if (
      submitAdminAssessment &&
      submitAdminAssessment.data &&
      !submitAdminAssessment.fetching &&
      submitAdminAssessment.data &&
      submitAdminAssessment.data.success
    ) {
      myContext.setIsLockPopup(false);
      myContext.setIsWarningPopup(false);
      getAssessmentApprovalList();
      toast.warn(
        <div>
          <i className="icon-check-outline" />
          {"Assessment Submitted successfully."}
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      dispatch({
        type: "ADMIN_SUBMIT_ASSESSMENT_RESET",
        payload: {},
      });
    } else if (
      submitAdminAssessment &&
      submitAdminAssessment.data &&
      !submitAdminAssessment.fetching &&
      submitAdminAssessment.data &&
      submitAdminAssessment.data.message &&
      !submitAdminAssessment.data.success
    ) {
      myContext.setIsLockPopup(false);
      myContext.setIsWarningPopup(true);
      setSubmittedStatus(false);
      toast.warn(
        <div>
          <i className="icon-warning-outline" />
          {" Assessment Submisson failed: " +
            (submitAdminAssessment.data.message.includes("Scoring ")
              ? " Scoring data is not correct."
              : " Options are not added properly. ")}
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      dispatch({
        type: "ADMIN_SUBMIT_ASSESSMENT_RESET",
        payload: {},
      });
    }
  }, [submitAdminAssessment]);

  useEffect(() => {
      if (
        (invalidData &&
          invalidData.data &&
          invalidData.data.inCompleteGlobalMetrics &&
          invalidData.data.inCompleteGlobalMetrics.length > 0) ||
        (invalidData &&
          invalidData.data &&
          invalidData.data.inCompleteGoals &&
          invalidData.data.inCompleteGoals.length > 0) ||
        (invalidData &&
          invalidData.data &&
          invalidData.data.inValidGlobalMetrics &&
          invalidData.data.inValidGlobalMetrics.length > 0) ||
        (invalidData &&
          invalidData.data &&
          invalidData.data.inValidGoals &&
          invalidData.data.inValidGoals.length > 0) ||
        (invalidData &&
          invalidData.data &&
          invalidData.data.inValidSections &&
          invalidData.data.inValidSections.length > 0)
      ) {
        setInvalidDataPresent(true);
        setEnableSubmit(false);
      } else {
        setInvalidDataPresent(false);
        if (!isSubmittedYear() && (assessmentStatus() === "Draft") && isValidYear()) {
          setEnableSubmit(true);
        } else{
          setEnableSubmit(false);
        }
      }
    setSubmittedStatus(false);
  }, [invalidData]);

  const clickAwayHandler = () => {
    setanchor(null);
  };

  const getCurrentPage = useCallback(() => {
    switch (currentPage) {
      case 1:
        return (
          <RollForward
            onClose={() => {
              setCurrentPageName("Assessment");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "Assessment");
            }}
            onChildChange={(val) => setIsChangesInChild(val)}
          />
        );
      case 2:
        return (
          <AssessmentAdmin
            backToListing={backToListing}
            passInavlidDataList={(data) => {
              setInvalidData(data);
            }}
            passSelectedYear={(year) => {
              setSelectedYear(year);
            }}
            getAssessmentApprovalList={() => getAssessmentApprovalList()}
            lastUpdateInfo={lastUpdateInfo}
            revertFlag={() => setBackToListing(false)}
            setActiveTab={(index) => setActiveTab(index)}
            setViewAddNewGlobalMetric={(flag, data) => {
              setaddNewGlobalMetricScreen(flag);
              setaddNewGlobalMetricData(data);
            }}
            onClose={() => {
              setCurrentPageName("Assessment");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "Assessment");
            }}
            onChildChange={(val) => setIsChangesInChild(val)}
            history={props.history}
          />
        );
      case 3:
        return (
          <AddAssessmentContent
            onClose={() => {
              setCurrentPageName("Assessment");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "Assessment");
            }}
            onChildChange={(val) => setIsChangesInChild(val)}
          />
        );
      default:
        return "";
    }
  }, [currentPage, backToListing, lastUpdateInfo]);

  const backArrowClick = () => {
    /*if (!isMainPageVisible) {
      if (addNewGlobalMetricScreen) {
        setBackToListing(true);
      } else {
        if (isChangesInChild) {
          setIsPopupVisible(true);
        } else {
          showAssessmentPage();
        }
      }
    } else {
      props.history.push(myConsts.routeAdmin);
    }*/
    getAssessmentApprovalList();
    if(isApprovalPreview) {
      if (addNewGlobalMetricScreen) {
        setBackToListing(true);
      } else {
        props.history.push(myConsts.routeAdminApprovals);
      }
    } else if (!isMainPageVisible) {
      if (isChangesInChild) {
        setIsPopupVisible(true);
      } else {
        onPopupConfirm();
      }
    } else {
      props.history.push(myConsts.routeAdmin);
    }
  };
  const invalidDataCard = (data, type, incomplete) => {
    if (type === "Goals") {
      return (
        <div>
          {data.goalsList &&
            data.goalsList.map((item) => (
              <div className="greyCard">
                <div className="header">
                  {"Incomplete Goal Details" +
                    (incomplete ? " - Statement not added" : "")}
                </div>
                <div className="content">{data.metricName + " > " + item}</div>
              </div>
            ))}
        </div>
      );
    } else if (type === "GM")
      return (
        <div className="greyCard">
          <div className="header">
            {"Incomplete Global Metrics Details" +
              (incomplete ? " - Goal not added" : "")}
          </div>
          <div className="content">{data}</div>
        </div>
      );
    else
      return (
        <div className="greyCard">
          <div className="header">Incomplete Section Details</div>
          <div className="content">{data}</div>
        </div>
      );
  };

  const checkForChange = (fn, params) => {
    if (
      outsideClickState &&
      outsideClickState.feature !== "" &&
      outsideClickState.changesDone
    ) {
      // alert();
      dispatch({
        type: "OUTSIDE_CLICK_COMMON_OPEN_MODEL",
        payload: {
          callback: { fn: fn, params: params },
        },
      });
    } else fn();
  };
  
  const AssessmentClick = () => {
    if (isChangesInChild) {
      setIsPopupVisible(true);
    } else {
      showAssessmentPage();
    }
  };

  const showAssessmentPage = () => {
    setIsChangesInChild(false);
    setIsPopupVisible(false);
    setIsMainPageVisible(true);
    setCurrentPageName("Assessment");
    sessionStorage.setItem("currentPageName", "Assessment");
  };

  const onPopupConfirm = () => {
    setIsChangesInChild(false);
    if (addNewGlobalMetricScreen) {
      setBackToListing(true);
      setIsPopupVisible(false);
    } else {
      showAssessmentPage();
    }
  };

  useEffect(() => {
    let temp = {};
    if (addNewGlobalMetricScreen) {
      if (addNewGlobalMetricData) {
        if (
          addNewGlobalMetricData.section &&
          addNewGlobalMetricData.section.length !== 0 &&
          addNewGlobalMetricData.section[0]
        ) {
          temp = {
            pillar: addNewGlobalMetricData.pillar,
            pillarId: addNewGlobalMetricData.pillarId,
            goalId: addNewGlobalMetricData.section[0].goalId,
            goalName: addNewGlobalMetricData.section[0].goalName,
          };
        } else {
          let goalId = "";
          let goalName = "";
          temp = {
            pillar: addNewGlobalMetricData.pillar,
            pillarId: addNewGlobalMetricData.pillarId,
            goalId: goalId,
            goalName: goalName,
          };
        }
      }
    }
    setGoalObj(temp);
  }, [addNewGlobalMetricData]);

  const openModal = () => {
    setOpen(true);
  };



 const reviewRespond = () => {
  dispatch({
    type: "RESPOND_BUTTON_CLICKED",
    payload: {},
  });
}

  return (
    <div className="admin-container">
      <div className="admin-info-main-sidemenu">
        <SideMenu
          minimized={true}
          isminimizedMainMenu={true}
          isMainMenu={true}
          sideMenuItemSelect="Admin"
        />
      </div>
      <div className="admin-content admin-assessment-content">
        <div className="second-header admin-crumbs">
          <i
            className="icon-arrow-left"
            onClick={() => {
              checkForChange(backArrowClick);
            }}
          />
          <div className="admin-crumb-txt">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
              <Breadcrumb
                crumbs={crumbs}
                isAdmin={true}
                isInnerAdmin={true}
                {...props}
              />
              {!isApprovalPreview && !isMainPageVisible && (
                <div className="icon-chevron-right iconStyling">
                  <a
                    className=" userMngmnt MuiTypography-root MuiLink-root MuiLink-underlineHover admin-crumb MuiTypography-colorInherit"
                    onClick={() => {
                      checkForChange(AssessmentClick);
                    }}>
                    Assessment
                  </a>
                </div>
              )}
            </div>
            { !isApprovalPreview ? <h4>{currentPageName}</h4> : <h4>{_.get(adminApprovalData,"previewParams.displayType", "")}</h4>}
          </div>
          {sessionStorage.getItem("currentPageName") &&
            sessionStorage.getItem("currentPageName") ===
              "Manage Assessment" && (
              <div className="btnSectionTop">
                { !isApprovalPreview && !addNewGlobalMetricScreen && assessmentData.length !== 0  && (
                  <div className="status">
                    {" "}
                    {isSubmittedYear() ? "Submitted" : assessmentStatus()}{" "}
                  </div>
                )}
                {/* <div
                  className={"btn" + (true ? " opacityReduced " : "")}
                  onClick={() => {}}>
                  <i className="eyaa-share" style={{ marginRight: "5px" }} />
                  Export
                </div> */}
                <div
                  className={
                    "btn" +
                    (!addNewGlobalMetricData.enable ||
                    addNewGlobalMetricData.isPreviewEnable
                      ? " opacityReduced "
                      : "")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    if (
                      addNewGlobalMetricData.enable &&
                      !addNewGlobalMetricData.isPreviewEnable
                    )
                      openModal();
                  }}>
                  <i className="eyaa-image-25" style={{ marginRight: "5px" }} />
                  Preview
                </div>
                {
                  isApprovalPreview && isReviewer ? <div
                    className={"btn yellow-button"}
                    onClick={reviewRespond}
                  >
                    <i
                      className="icon-verified-outline "
                      style={{ marginRight: "5px" }}
                    />
                    Respond
                  </div> : null
                }
                { !isApprovalPreview &&!addNewGlobalMetricScreen &&  (
                  <>
                    {
                      <>
                        {isValidYear() && assessmentData.length !== 0 ? (
                          <>
                            <div
                              className={
                                "btn " +
                                (!enableSubmit || submittedStatus
                                  ? " opacityReduced "
                                  : " yellow-button")
                              }
                              onClick={() => {
                                if (enableSubmit && !submittedStatus)
                                  setIsConfirmationPopupVisible(true);
                              }}>
                              <i
                                className="icon-verified-outline "
                                style={{ marginRight: "5px" }}
                              />
                              Submit
                            </div>
                            {!enableSubmit &&  invalidDataPresent && (
                              <div
                                style={{ minWidth: "14px" }}
                                className={
                                  "btn " + (anchor ? " yellow-button " : " ")
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (anchor) setanchor(null);
                                  else setanchor(e.currentTarget);
                                }}>
                                <i className="icon-info-outline " />
                              </div>
                            )}
                          </>
                        ) : null}
                      </>
                      /*  (
                      <div
                        className={" btn yellow-button"}
                        onClick={() => {
                          setPublishStatus(!publishStatus);
                        }}>
                        <i
                          className="eyaa-web-074"
                          style={{ marginRight: "5px" }}
                        />
                        Publish
                      </div>
                    ) */
                    }
                  </>
                )}
              </div>
            )}
        </div>
        <div className="assessment-main-content">
          {isMainPageVisible ? (
            <div className="card-block">
              <div className="card" onClick={() => setCurrentPageConfig(1)}>
                <span className="eyaa-web-063"></span>
                <span>Roll Forward Assessment</span>
              </div>
              <div className="card" onClick={() => setCurrentPageConfig(2)}>
                <span className="eyaa-web-134"></span>
                <span>Manage Assessment</span>
              </div>
              <div className="card" onClick={() => setCurrentPageConfig(3)}>
                <span className="eyaa-upload"></span>
                <span>Add Assessment Content</span>
              </div>
            </div>
          ) : (
            <>{getCurrentPage()}</>
          )}
        </div>
      </div>
      <Modal
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        styles={{
          modal: {
            maxWidth: "unset",
            padding: "unset",
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.5)",
          },
          /*  closeButton: {
          background: "white",
        }, */
        }}
        center>
        <SimpleBar
          style={{
            overflowX: "hidden",
            maxHeight: "92vh",
            width: "90vw",
          }}
          className="survey-wrap">
          <div className="modal-heading">
            {(addNewGlobalMetricData.pillar
              ? addNewGlobalMetricData.pillar
              : "Assessment Preview") +
              " - " +
              "FY" +
              (addNewGlobalMetricData && addNewGlobalMetricData.financialYear
                ? addNewGlobalMetricData.financialYear
                : "")}
          </div>
          <div style={{ height: "80vh" }}>
            <Survey
              readOnly={true}
              preview={true}
              goalObj={goalObj}
              match={{ params: "" }}
              singleView={addNewGlobalMetricScreen}
              fyYear={
                addNewGlobalMetricData && addNewGlobalMetricData.financialYear
                  ? addNewGlobalMetricData.financialYear
                  : ""
              }
            />
          </div>
        </SimpleBar>
      </Modal>
      <Popper
        placement="bottom"
        id="customPopupRoot"
        className={classes.popper}
        open={openpopUp}
        anchorEl={anchor}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          // arrow: {
          //   enabled: true,
          //   element: arrowRef,
          // },
        }}>
        {/* {<span className={classes.arrow} ref={setArrowRef} />} */}
        <ClickAwayListener onClickAway={clickAwayHandler}>
          <Paper className={classes.paper2}>
            <SimpleBar style={{ maxHeight: "465px" }}>
              <div
                style={{ margin: "10px", cursor: "pointer" }}
                className="invalid-Data-list"
                onClick={() => {}}>
                <div className={classes.header}> Incomplete Details</div>
                <div>
                  {invalidData &&
                    invalidData.data &&
                    invalidData.data.inCompleteGlobalMetrics &&
                    invalidData.data.inCompleteGlobalMetrics.length > 0 &&
                    invalidData.data.inCompleteGlobalMetrics.map((item) =>
                      invalidDataCard(item, "GM", true)
                    )}
                  {invalidData &&
                    invalidData.data &&
                    invalidData.data.inCompleteGoals &&
                    invalidData.data.inCompleteGoals.length > 0 &&
                    invalidData.data.inCompleteGoals.map((item) =>
                      invalidDataCard(item, "Goals", true)
                    )}
                  {invalidData &&
                    invalidData.data &&
                    invalidData.data.inValidGlobalMetrics &&
                    invalidData.data.inValidGlobalMetrics.length > 0 &&
                    invalidData.data.inValidGlobalMetrics.map((item) =>
                      invalidDataCard(item, "GM", false)
                    )}
                  {invalidData &&
                    invalidData.data &&
                    invalidData.data.inValidGoals &&
                    invalidData.data.inValidGoals.length > 0 &&
                    invalidData.data.inValidGoals.map((item) =>
                      invalidDataCard(item, "Goals", false)
                    )}
                  {invalidData &&
                    invalidData.data &&
                    invalidData.data.inValidSections &&
                    invalidData.data.inValidSections.length > 0 &&
                    invalidData.data.inValidSections.map((item) =>
                      invalidDataCard(item, "Section", false)
                    )}
                </div>
              </div>
            </SimpleBar>
          </Paper>
        </ClickAwayListener>
      </Popper>
      {isConfirmationPopupVisible && (
        <div className="popup-dialog">
          <div className="dialog">
            <div className="header">
              <h5 className="popup-header">Confirmation</h5>
              <span
                className="icon-close-filled icon"
                onClick={() => setIsConfirmationPopupVisible(false)}></span>
            </div>
            <div className="content">
              Would you like to submit the changes for review? <br /> <br />
              Please note that you will be able to make further edits once the
              reviewer responds.
            </div>
            <div className="footer">
              <button
                className="cancel-btn"
                onClick={() => setIsConfirmationPopupVisible(false)}>
                Cancel
              </button>
              <button
                className="confirm-btn"
                onClick={() => {
                  setIsConfirmationPopupVisible(false);
                  setSubmittedStatus(true);
                  dispatch({
                    type: "ADMIN_SUBMIT_ASSESSMENT",
                    payload: {
                      financialYear: invalidData.year,
                    },
                  });
                }}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      {isPopupVisible && (
        <div className="popup-dialog">
          <div className="dialog">
            <div className="header">
              <h5 className="popup-header">Confirmation</h5>
              <span
                className="icon-close-filled icon"
                onClick={() => setIsPopupVisible(false)}></span>
            </div>
            <div className="content">
              Would you like to navigate without completing the operation?
              Please note that on confirming, incomplete actions will be lost
            </div>
            <div className="footer">
              <button
                className="cancel-btn"
                onClick={() => setIsPopupVisible(false)}>
                Cancel
              </button>
              <button className="confirm-btn" onClick={onPopupConfirm}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Assessment;
