import React, { useState } from "react";
import { Box } from "@material-ui/core";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";
import "./Survey.scss";

const AnsCard = React.memo((props) => {
  let color;
  const { answer, currentObj } = props;
  const [showMoreText, setMoreText] = useState(false);
  const allSectionDataFetch = useSelector(
    (state) => state.getAssessmentSectionList
  );
  const assessmentSectionList = allSectionDataFetch
    ? allSectionDataFetch.data
      ? allSectionDataFetch.data.data
        ? allSectionDataFetch.data.data.assessmentDetails
        : []
      : []
    : [];
  const readMore = () => {
    setMoreText(!showMoreText);
  };

  // const getColor = (type) => {
  //   console.log(type)
  //   if (type === "Table stakes") {
  //     return "#F08E8E";
  //   } else if (type === "Leading") {
  //     return "#B1DE76";
  //   } else if (type === "Transformative") {
  //     return "#94DFE9";
  //   }
  //   return "#2E2E38";
  // };

  const getColor = (type) => {
    const sectionData = assessmentSectionList.find(
      (list) => list.name === type
    );
    return sectionData ? sectionData.color : "#f9f9fb";
    // console.log(type, "---", sectionData ? sectionData.color : "#f9f9fb");

    // if (type === "Table stakes") {
    //   return "#F08E8E";
    // } else if (type === "Leading") {
    //   return "#B1DE76";
    // } else if (type === "Transformative") {
    //   return "#94DFE9";
    // } else if (
    //   currentObj &&
    //   currentObj.sectionValues &&
    //   currentObj.sectionValues.length > 0 &&
    //   currentObj.sectionValues[0]
    // ) {
    //   currentObj.sectionValues.forEach((item) => {
    //     if (type === item.name) {
    //       color = item.color;
    //     }
    //   });
    //   return color;
    // } else {
    //   return "#f9f9fb";
    // }
  };

  return (
    <>
      <Box
        width={props.showFullWidth ? "95%" : "56%"}
        className="ans-card"
        style={{ borderLeftColor: getColor(answer.type) }}>
        <SimpleBar
          style={{
            maxHeight: window.matchMedia("(max-width: 1280px)").matches
              ? "10vh"
              : "7vh",
            overflowY: "auto",
            fontSize: "11px",
          }}>
          {showMoreText || props.showFullWidth ? (
            <span className={!props.wordBreak ? "txt-break" : "txt-overflow"}>
              {answer.impactActions}
              <div style={{fontSize:"10px",fontStyle:"italic",padding:"3px"}}>
                {props.isFromDiscussionGuide && ` - ${answer.selectedOption}`}
              </div>
            </span>
          ) : answer && answer.impactActions.length > 250 ? (
            <span
              className={!props.wordBreak ? "txt-break" : "txt-overflow"}
              style={{ cursor: "pointer", fontSize: "11px" }}
              onClick={readMore}>
              {answer.impactActions.slice(0, 250)}... Read More{" "}
              <i style={{ fontSize: 11 }} className="icon-arrow-down"></i>
            </span>
          ) : (
            <span className={!props.wordBreak ? "txt-break" : "txt-overflow"}>
              {answer.impactActions}
              <div style={{fontSize:"10px",fontStyle:"italic",padding:"3px"}}>
                {props.isFromDiscussionGuide && ` - ${answer.selectedOption}`}
              </div>
            </span>
          )}
        </SimpleBar>
      </Box>
    </>
  );
});

export default AnsCard;
