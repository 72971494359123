import React, { useState, useContext, useEffect } from "react";
// import {
//     Table,
//     TableBody,
//     TableCell,
//     TableContainer,
//     TableHead,
//     Typography,
//     TableRow,
// } from "@material-ui/core";
// import { styled } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../SideMenu/SideMenu";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import Loader from "../Loader/Loader";
import * as myConsts from "../../constants/constants";
import AppContext from "../../utils/appContext";
import FinalCommentaryTable from "./FinalizationForm/FinalCommentaryTable";
import NoAccess from "../../components/NotValidUser/NoAccess";
import "./FinalCommentary.scss"
import moment from "moment";

const FinalCommentary = (props) => {
    const dispatch = useDispatch();
    const myContext = useContext(AppContext);
    const reportsData = useSelector((state) => state.reportsData);
    const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
    const currentUserDetails = useSelector((state) => state.getUserDetails.data);
    const userProfile = useSelector((state) => state.userProfile);
    const [crumbs, setBreadcrumbs] = useState([
        { name: "FINAL Finalization Form Commentary", path: myConsts.routeFinalCommentary },
    ]);
    // const StyledTableRow = styled(TableRow)(({ theme }) => ({
    //     "&:nth-of-type(odd)": {
    //         backgroundColor: "#FAFAFC",
    //     },
    //     // hide last border
    //     "&:last-child td, &:last-child th": {
    //         border: 0,
    //     },
    // }));
    //..Local States
    const [isLoading, setIsLoading] = useState(false);

    //..Events
    useEffect(() => {
        (async function () {
            try {
                //debugger;
                setIsLoading(true);
                dispatch({
                    type: "GET_REPORTS_DATA",
                    payload: {
                        financialYear: myContext.financialYear,
                        timePeriod: myContext.currentPeriod,
                        api: "getDiscussionGuideReport",
                    },
                });

            } catch (e) {
                console.error(e);
            }
        })();
    }, []);
    // useEffect(() => {
    //     debugger;
    //     setIsLoading(true);
    //     dispatch({
    //         type: "GET_REPORTS_DATA",
    //         payload: {
    //             financialYear: myContext.financialYear,
    //             timePeriod: myContext.currentPeriod,
    //             api: "getDiscussionGuideReport",
    //         },
    //     });
    // }, [dispatch]);

    useEffect(() => {
        //debugger;
        if (reportsData &&
            !reportsData.fetching &&
            reportsData.data &&
            reportsData.data.DiscussionGuideReport) {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [reportsData]);

    //..Handlers
    const getData = () => {
        //debugger;
        return reportsData &&
            reportsData.data &&
            reportsData.data.DiscussionGuideReport &&
            currentUserDetails.mail
            ? reportsData.data.DiscussionGuideReport.filter(
                (item) => item["Lead Reviewer Email"] === currentUserDetails.mail.toLowerCase()
            )
            : [];

    };

    const isValidDate = (date) => {
        return moment(date).isValid() ? date : "Not Available";
    };

    const getFinalCommentaryDateRange = (type) => {
        //debugger;
        try {
            if (type && assessmentMetaData && assessmentMetaData.data &&
                assessmentMetaData.data.data && (assessmentMetaData.data.data.assessmentMetaData
                    || assessmentMetaData.data.data.lastAssessmentMetaData)) {
                let dateRange;

                let assessmentDetails = assessmentMetaData.data.data.lastAssessmentMetaData &&
                    Object.keys(assessmentMetaData.data.data.lastAssessmentMetaData).length > 0 &&
                    assessmentMetaData.data.data.lastAssessmentMetaData.timePeriod === myConsts.TimePeriodYearEnd ? assessmentMetaData.data.data.lastAssessmentMetaData
                    : assessmentMetaData.data.data.assessmentMetaData;

                let mdStartDate = new Date(assessmentDetails.finalCommentary_MD_StartDate);
                let mdEndDate = new Date(assessmentDetails.finalCommentary_MD_EndDate);
                let partnerStartDate = new Date(assessmentDetails.finalCommentary_Partner_StartDate);
                let partnerEndDate = new Date(assessmentDetails.finalCommentary_Partner_EndDate);

                switch (type) {
                    case 13: //...EDs/MDs
                        let mdSDate = isValidDate(moment(mdStartDate).format("MM/DD"));
                        let mdEDate = isValidDate(moment(mdEndDate).format("MM/DD"));
                        dateRange = `${mdSDate} - ${mdEDate}`;
                        break;
                    case 11: //..Partners
                        let partnerSDate = isValidDate(moment(partnerStartDate).format("MM/DD"));
                        let partnerEDate = isValidDate(moment(partnerEndDate).format("MM/DD"));
                        dateRange = `${partnerSDate} - ${partnerEDate}`;
                        break;
                    default:
                        break;
                }

                return dateRange;
            }
        } catch (error) {
            console.log(`getFinalCommentaryDateRange ERROR:: ${error}`);
        }
    };

    return (
        <>
            {
                myContext.currentPeriod === myConsts.TimePeriodYearEnd &&
                    assessmentMetaData && assessmentMetaData.data &&
                    assessmentMetaData.data.data && assessmentMetaData.data.data.assessmentMetaData &&
                    assessmentMetaData.data.data.assessmentMetaData.leadReviewer_DiscussionGuide_CompletionDate &&
                    new Date(assessmentMetaData.data.data.assessmentMetaData.leadReviewer_DiscussionGuide_CompletionDate)
                    >= new Date() ? (
                    <NoAccess {...props} />
                )
                    :
                    (
                        <></>
                    )
            }
            {isLoading && <Loader />}
            <div className="admin-container general-parameters" style={{ background: "#ffffff" }}>
                <div className="admin-info-main-sidemenu">
                    <SideMenu
                        minimized={true}
                        isminimizedMainMenu={true}
                        isMainMenu={true}
                        sideMenuItemSelect="Home"
                    />
                </div>
                <div className="admin-content">
                    <div className="second-header admin-crumbs">
                        <div className="admin-crumb-txt">
                            <Breadcrumb
                                crumbs={crumbs}
                                isRouteFromDiscussionGuide={true}
                                {...props}
                            />
                        </div>
                        <div style={{ marginLeft: "20px" }}>
                            {/* <marquee width="90%" direction="right"> */}
                            <div className="mainHeading" style={{ color: "red" }}>{`MD Final Commentary available: ${getFinalCommentaryDateRange(13)} and`} {`Partner Final Commentary available: ${getFinalCommentaryDateRange(11)}`}</div>

                            {/* </marquee> */}
                        </div>
                        <div className="btn-wrapper-top" style={{ right: "17px" }}>
                            {/* <div className={"btn"}
                                style={{ cursor: " pointer", opacity: 1 }}
                            // onClick={() => {
                            //     setCounseleeFormOpen(true);
                            // }}
                            >
                                <i
                                    className="eyaa-image-25"
                                    style={{ marginRight: "5px" }}
                                />
                                Navigate to Success Factor
                            </div> */}
                            {/* <div className="butn yellow-color"> */}
                            <a className="butn yellow-color"
                                style={{ textDecoration: "none" }}
                                href="https://sf.ey.net/" target="_blank" rel="noreferrer">
                                Navigate to SuccessFactors
                            </a>
                            {/* </div> */}
                            {/* <div className="hyper-link"
                            // onClick={() => {
                            //     if (
                            //         userAssessmentProgress === 100 &&
                            //         (myContext.bobStatus ===
                            //             myConsts.statusSubmitted ||
                            //             myContext.bobStatus ===
                            //             myConsts.statusCompleted)
                            //     ) {
                            //         myContext.setNavToSummary(true);
                            //         props.navigateToAssessment();
                            //     }
                            // }}
                            >
                                <a href="https://sf.ey.net/" target="_blank" rel="noreferrer">
                                    Navigate to Success Factor
                                </a>
                            </div> */}
                        </div>
                    </div>
                    <div className="reports-container">
                        {/* <div className="mainHeading" style={{ color: "red" }}>{`MD Final Commentary available: ${getFinalCommentaryDateRange(13)}`}</div>
                        <div class="mainHeading" style={{ color: "red" }}>{`Partner Final Commentary available: ${getFinalCommentaryDateRange(11)}`}</div> */}
                        <FinalCommentaryTable
                            dataSource={getData()}
                            isLoading={isLoading}
                            isFromFinalCommentary={true}
                            userProfile={userProfile}
                            assessmentMetaData={assessmentMetaData}
                        />
                        {/* {
                            excelReportData &&
                            excelReportData.data &&
                            excelReportData.data.DiscussionGuideReport && (
                                <BasicReportingTable
                                    label={"Lead Reviewer Discussion Guide"}
                                    dataSource={getData()}
                                    isLoading={isLoading}
                                />
                            )
                            // :
                            // !isLoading ? (
                            //     <div className="no-data-found">
                            //         {" "}
                            //         No data found. Please apply different filters
                            //     </div>
                            // ) : null
                        } */}

                    </div>
                </div>
            </div >
        </>
    )
}

export default FinalCommentary;