import React from "react";
import "./Alert.scss";
import { Dialog, makeStyles, Slide } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
const useStyles = makeStyles({
  root: {
    backgroundColor: "#B1DE76",
    display: "flex",
    alignItems: "center",
    color: "#2e2e38",
    fontSize: 11,
    fontFamily: "EYInterState-Regular',sans-serif",
    fontWeight: 600,
    height: "10vh",
    width: "70vh",
  },
  paper: {
    borderRadius: 0,
    backgroundColor: "#B1DE76",
    height: "auto !important",
  },
  action: {
    "& .MuiIconButton-label": {
      background: "#666",
      borderRadius: "50%",
      width: 12,
      height: 12,
    },
    "& .MuiIconButton-label .MuiSvgIcon-root": {
      fontSize: "9px !important",
      color: "#fff",
    },
  },
});
const AlertComponent = ({ options }) => {
  const classes = useStyles();
  const toogleShowAlert = (e, flag) => {
    e.stopPropagation();
    e.preventDefault();
    options.toogleShow(false);
  };
  return (
    <Dialog
      open={options.alertData.isShowing}
      TransitionComponent={Transition}
      onBackdropClick={(e) => toogleShowAlert(e, false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className="customAlert"
      classes={{ paper: classes.paper }}
    >
      <Alert
        icon={<i className="icon-verified-outline" />}
        classes={{ root: classes.root, action: classes.action }}
        onClose={(e) => toogleShowAlert(e, false)}
      >
        {options.alertData.title}
      </Alert>
    </Dialog>
  );
};
export default AlertComponent;
