import { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import _ from "lodash";
import InternalErrorBoundary from "./components/ErrorBoundary";
import * as myConsts from "./constants/constants";
import Admin from "./components/Admin/Admin";
import UserManagement from "./components/Admin/UserManagement/UserManagement";
import GeneralParameters from "./components/Admin/GeneralParameters";
import Reports from "./components/Admin/Reports/Reports";
import ReportMainPage from "./components/Admin/Reports/ReportMainPage";
import Assessment from "./components/Admin/Assessment/Assessment";
import BookofBusiness from "./components/Admin/BookOfBusiness/BookOfBusiness";
import ContentManagement from "./components/Admin/ContentManagement/ContentManagement";
import DataManagement from "./components/Admin/DataManagement/DataManagement";
import EmailConfiguration from "./components/Admin/EmailConfiguration";
import Approvals from "./components/Admin/Approvals/Approvals";
import AppContext from "./utils/appContext";
import Page404 from "./components/NotValidUser/Page404";
import NoAccess from "./components/NotValidUser/NoAccess";
import Info from "./components/Common/Info";
import ManageUserPreview from "./components/Admin/UserManagement/ManageUser/ManageUserPreview";

const routes = [
  {
    exact: true,
    path: myConsts.routeAdmin,
    component: Admin,
  },
  {
    path: myConsts.routeAdminGeneralParametersApprovals,
    component: GeneralParameters,
  },
  {
    path: myConsts.routeAdminUserManagementApprovals,
    component: ManageUserPreview,
  },
  {
    path: myConsts.routeAdminGeneralParameters,
    component: GeneralParameters,
  },
  {
    path: myConsts.routeAdminUserManagement,
    component: UserManagement,
  },
  {
    path: myConsts.routeAdminReports,
    component: Reports,
  },
  {
    path: myConsts.routeAdminReportMainPage,
    component: ReportMainPage,
  },
  {
    exact: false,
    path: myConsts.routeAdminAssessmentApprovals,
    component: Assessment,
  },
  {
    exact: false,
    path: myConsts.routeAdminAssessment,
    component: Assessment,
  },
  {
    path: myConsts.routeAdminBookOfBusiness,
    component: BookofBusiness,
  },
  {
    path: myConsts.routeAdminEmailConfiguration,
    component: EmailConfiguration,
  },
  {
    path: myConsts.routeAdminContentManagement,
    component: ContentManagement,
  },
  {
    path: myConsts.routeAdminDataManagement,
    component: DataManagement,
  },
  {
    path: myConsts.routeAdminApprovals,
    component: Approvals,
  },
  {
    path: myConsts.routeAdminUserManagement,
    component: UserManagement,
  },
];

const AdminRoutes = (props) => {
  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  const [isAdmin, setAdmin] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const userProfile = useSelector((state) => state.userProfile);
  const [firstTime, setFirstTime] = useState(true);
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  const getCurrentUser = useCallback(() => {
    return myContext.userId || sessionStorage.userId;
  }, [myContext]);
  useEffect(() => {
    if (
      myContext.financialYear !== myContext.activeFinancialYear
        ? myContext.activeFinancialYear
        : myContext.lastActiveFinancialYear
    ) {
      myContext.setFinancialYear(
        myContext.activeFinancialYear
          ? myContext.activeFinancialYear
          : myContext.lastActiveFinancialYear
      );
      sessionStorage.setItem(
        "financialYear",
        myContext.activeFinancialYear
          ? myContext.activeFinancialYear
          : myContext.lastActiveFinancialYear
      );
      sessionStorage.removeItem("activePeriod");
      myContext.setActivePeriod(null);
      sessionStorage.removeItem("currentPeriod");
      myContext.setCurrentPeriod(null);
      dispatch({
        type: "GET_ASSESSMENT_META_DATA_RESET",
        payload: {},
      });
      dispatch({
        type: "GET_TIME_PERIOD_STATUS_RESET",
        payload: {},
      });
      dispatch({
        type: "GET_USER_PROFILE_BY_ID_RESET",
        payload: {},
      });
    }
  }, []);

  useEffect(() => {
    if (_.isEmpty(assessmentMetaData.data))
      dispatch({
        type: "GET_ASSESSMENT_META_DATA",
        payload: {
          financialYear: myContext.financialYear,
          userId: getCurrentUser(),
        },
      });
    if (!Array.isArray(userProfile.data) || userProfile.data.length <= 0) {
      dispatch({
        type: "GET_USER_PROFILE_BY_ID",
        payload: {
          financialYear: myContext.financialYear,
          userId: getCurrentUser(),
        },
      });
    }
  }, [myContext.financialYear]);

  useEffect(() => {
    setLoading(userProfile.fetching);
    if (
      userProfile.data &&
      userProfile.data.length > 0 &&
      sessionStorage.userId === userProfile.data[0].userId
    ) {
      setAdmin(userProfile.data[0].isAdmin);
    } else if (!_.isEmpty(sessionStorage.getItem('userPermission'))) {
      const userPermission = sessionStorage.getItem('userPermission') ? JSON.parse(sessionStorage.getItem('userPermission')) : null;
      setAdmin(userPermission?.isAdmin);
    }
    if ((sessionStorage && userProfile.data[0] && sessionStorage.userId && sessionStorage.userId === userProfile.data[0].userId)
      || (_.isEmpty(sessionStorage.userId) && userProfile.data[0])) {
      sessionStorage.setItem('userPermission', JSON.stringify({ isAdmin: userProfile.data[0].isAdmin, role: userProfile.data[0].role }))
    }
  }, [userProfile]);

  useEffect(() => {
    if (firstTime) {
      setLoading(
        assessmentMetaData.fetching || _.isEmpty(assessmentMetaData.data)
      );
      if (!assessmentMetaData.fetching && !_.isEmpty(assessmentMetaData.data))
        setFirstTime(false);
    }
    if (
      assessmentMetaData &&
      assessmentMetaData.data &&
      assessmentMetaData.data.data
    ) {
      const data = assessmentMetaData.data.data;
      const activePhase = data.activePhase;
      if (sessionStorage.getItem("isValidUser") === null) {
        sessionStorage.setItem("isValidUser", data.validUser);
        myContext.setIsValidUser(data.validUser);
        if (!data.validUser) {
          props.history.push(myConsts.routeNotValidUser);
        }
      }
      if (
        activePhase === null ||
        (data.assessmentMetaData &&
          data.assessmentMetaData.leadReviewer_CompletionDate &&
          new Date() >
          new Date(data.assessmentMetaData.leadReviewer_CompletionDate)) ||
        (myContext.currentPeriod !== null &&
          activePhase !== null &&
          activePhase !== myContext.currentPeriod)
      ) {
        sessionStorage.setItem("activePhase", activePhase);
        myContext.setActivePhase(activePhase);
        sessionStorage.setItem("formsInActive", true);
        myContext.setFormsInActive(true);
        if (
          activePhase === null ||
          (data.assessmentMetaData &&
            data.assessmentMetaData.leadReviewer_CompletionDate &&
            new Date() >
            new Date(data.assessmentMetaData.leadReviewer_CompletionDate))
        ) {
          dispatch({
            type: "GET_TIME_PERIOD_STATUS",
            payload: { financialYear: myContext.financialYear },
          });
        }
      } else {
        if (sessionStorage.getItem("activePeriod") === null) {
          sessionStorage.setItem("activePeriod", activePhase);
        }

        sessionStorage.setItem("activePhase", activePhase);
        myContext.setActivePhase(activePhase);
        if (
          sessionStorage.getItem("currentPeriod") === null ||
          !myContext.currentPeriod
        ) {
          sessionStorage.setItem("currentPeriod", activePhase);
          myContext.setCurrentPeriod(activePhase);
        }

        sessionStorage.setItem("formsInActive", false);
        myContext.setFormsInActive(false);
        //setLoader(false);
        myContext.setActivePeriod(activePhase);
      }
      myContext.setUserRole(data.userRole); //data.userRole,LeadReviewer-PPMD
    }
  }, [assessmentMetaData]);

  return (
    <>
      {isLoading ? (
        <>
          <Route exact path="/Admin" render={() => <Info />} />
          <Route path="/Admin/:route" render={() => <Info />} />
        </>
      ) : (
        <>
          {isAdmin || myContext.isRouteFromAdmin ? (
            <>
              {" "}
              {routes.map((route) => {
                const { path, exact = false, component } = route;
                return (
                  <InternalErrorBoundary type={path}>
                    <Route exact={exact} path={path} component={component} />
                  </InternalErrorBoundary>
                );
              })}
              <Route component={Page404} />
            </>
          ) : (
            <Route component={NoAccess} />
          )}
        </>
      )}
    </>
  );
};

export default AdminRoutes;
