export const emailRecipientList= [
    {key: 'PPMD',value: 'PPMDs'},
    {key: 'LeadReviewer',value: 'LeadReviewers'},
    {key: 'LeadReviewer-PPMD',value: 'LeadReviewer + PPMDs'}
];

export const tableHeaders = [
    { key: 'subject', value: 'Email Subject' },
    { key: 'sendBy', value: 'Sent by' },
    { key: 'sendTo', value: 'Sent to' },
    { key: 'sendOn', value: 'Sent on' },
    { key: 'type', value: 'Type' }
];