import Parser from 'html-react-parser';

const Preview = (props) => {
    const { cardDetails } = props;
    const src =   cardDetails.fileDetails ? cardDetails.fileDetails.fileUrl : (cardDetails.fileUrl instanceof File ? URL.createObjectURL(cardDetails.fileUrl) : cardDetails.fileUrl);
    
    return (
        <div className="welcome-card">
            <div className="content">
                <span className="header">{cardDetails.title}</span>
                <div className="ql-editor" style={{ fontFamily: '"EYInterState-Light",sans-serif', fontSize: '15px', fontWeight: 'lighter' }}>
                    {Parser(cardDetails.content || '')}
                </div>
                {/* <img src={src} className="img-preview" alt="" /> */}
            </div>
        </div>
    );
}

export default Preview;