import "./RollForwardUsers.scss";
import React, { useCallback, useState, useContext, useEffect } from "react";
import AppContext from "../../../../utils/appContext";
import * as myConsts from "../../../../constants/constants";
import DropdownComponent from "../../../Admin/AdminGeneralParam/DropdownComponent/DropdownComponent";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";
import { Flip } from "react-toastify";
import {
  authProvider,
  APIscope,
} from "./../../../../Authentication/authProvider";
import AlertSubmitPopup from "../../../Common/AlertSubmitPopup";
import { useDispatch, useSelector } from "react-redux";
import value from "require-context.macro";
import { getFinancialYears } from "../../../../utils/utils";

const RollForwardUser = (props) => {
  const lockId = "custom-id-lock";
  const myContext = useContext(AppContext);
  const dispatch = useDispatch();
  const [enableSave, setEnableSave] = useState(true);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [firstTime, setFirstTime] = useState(true);
  const [errorMessages, setErrorMessages] = useState({
    fromYear: "",
    toYear: "",
    fromPeriod: "",
    toPeriod: "",
    common: "",
  });
  const [values, setValues] = useState({
    fromYear: "FY" + myContext.financialYear,
    toYear: "",
    fromPeriod: myContext.activePeriod
      ? myContext.activePeriod
      : myConsts.TimePeriodsArray[0],
    toPeriod: "",
  });

  const getUserCounts = useSelector((state) => state.getUserCountstate);
  const rollforwardUsers = useSelector((state) => state.rollForwardUsers);
  const getLockStatus = useSelector((state) => state.checkLockStatus);
  const getPhaseValidationStatus = useSelector(
    (state) => state.getPhaseValidationStatus
  );
  const [periodDropdown, setPeriodDropdown] = useState([]);

  const dropOnChange = (value, label) => {
    setValues({ ...values, [label]: value });
  };
  const getcountApi = async (FY, period) => {
    //function to call feth API for given year and period.
    try {
      setLoader(true);
      const token = await authProvider.acquireTokenSilent({
        scopes: APIscope,
      });
      const idToken = token.accessToken;
      dispatch({
        type: "GET_USER_COUNT",
        payload: {
          token: idToken,
          financialYear: values.fromYear.replace("FY", ""),
          timePeriod: values.fromPeriod,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const rollForwardAPI = async () => {
    //function to call feth API for given year and period.
    try {
      setLoader(true);
      const token = await authProvider.acquireTokenSilent({
        scopes: APIscope,
      });
      const idToken = token.accessToken;
      dispatch({
        type: "ROLL_FORWARD_USER",
        payload: {
          // token: idToken,
          fromFinancialYear: values.fromYear.replace("FY", ""),
          fromTimePeriod: values.fromPeriod,
          toFinancialYear: values.toYear.replace("FY", ""),
          toTimePeriod: values.toPeriod,
        },
      });

      // dispatch({
      //   type: "ROLL_FORWARD_USER_SUCCESS",
      //   payload: {
      //     message: "Admin action is already in progress",
      //     success: true,
      //   },
      // });
    } catch (e) {
      console.error(e);
    }
  };
  const getLockStatusAPI = async () => {
    //function to call feth API for given year and period.
    try {
      const token = await authProvider.acquireTokenSilent({
        scopes: APIscope,
      });
      const idToken = token.accessToken;
      dispatch({
        type: "CHECK_LOCK_STATUS",
        payload: {
          token: idToken,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    let pastto = false;
    let same = false;
    if (
      Number.parseInt(values.fromYear.replace("FY", "")) >
      Number.parseInt(values.toYear.replace("FY", ""))
    ) {
      pastto = true;
    } else if (
      Number.parseInt(values.fromYear.replace("FY", "")) ===
      Number.parseInt(values.toYear.replace("FY", ""))
    ) {
      if (
        myConsts.TimePeriodsArray.indexOf(values.fromPeriod) >
        myConsts.TimePeriodsArray.indexOf(values.toPeriod)
      )
        pastto = true;
      else if (
        myConsts.TimePeriodsArray.indexOf(values.fromPeriod) ===
        myConsts.TimePeriodsArray.indexOf(values.toPeriod)
      ) {
        same = true;
        pastto = true;
      } else pastto = false;
    }
    if (pastto) {
      setErrorMessages({
        ...errorMessages,
        common: !same
          ? "Users cannot be rolled forward to prior year or period"
          : "You cannot have same Year & Period in 'From' and 'To'. Kindly make a different selection.", //"This operation was already completed"
      });
      setEnableSave(false);
    } else {
      setErrorMessages({
        ...errorMessages,
        common: "",
      });
      setEnableSave(true);
    }
    getcountApi();
  }, [values]);

  useEffect(() => {
    getLockStatusAPI();
    setLoader(true);
    // myContext.setDisableUserUpdation(true);
  }, []);

  const triggerLock = () => {
    myContext.setIsLockPopup(true);
    if (!document.getElementById(lockId))
      toast.warn(
        <div
          style={{
            // textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "EYInterState-Regular",
            fontSize: "12px",
          }}>
          <i className="icon-warning-outline" />
          {/* "Section Updated Successfully" : */}
          {`Previous operation initiated by ${
            getLockStatus.data && getLockStatus.data.userName
          } is still in progress. It will take some time. You
          can perform this action after the operation is completed. We
          appreciate your patience!`}
        </div>,
        {
          position: "top-center",
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: lockId,
        }
      );
  };

  useEffect(() => {
    //get userCount data
    if (
      !getUserCounts.fetching &&
      getUserCounts.data &&
      getUserCounts.data.financialYear
    ) {
      setLoader(false);
      setCount(getUserCounts.data.noOfUsers);
    }
  }, [getUserCounts]);

  useEffect(() => {
    //roll forward API response
    if (
      !rollforwardUsers.fetching &&
      rollforwardUsers.data &&
      rollforwardUsers.data.message &&
      rollforwardUsers.data.code &&
      rollforwardUsers.data.code === "INITIATED"
    ) {
      //Succefully initiateed
      setLoader(false);
      setOpenPopup(false);
      myContext.setIsWarningPopup(false);
      myContext.setIsLockPopup(false);
      toast.warn(
        <div>
          <i className="icon-verified-outline" />
          <b> {"Success: "}</b>
          {" Users have been rolled forward"}
        </div>,
        {
          position: "top-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      props.onClose();
      dispatch({
        type: "ROLL_FORWARD_USER_RESET",
        payload: {},
      });

      getLockStatusAPI();

      // dispatch({
      //   //remove this line and uncomment abv line once actual API is ready
      //   type: "CHECK_LOCK_STATUS_SUCCESS",
      //   payload: {
      //     message: "Admin action is already in progress",
      //     proceedAction: false,
      //   },
      // });
    } else if (
      !rollforwardUsers.fetching &&
      rollforwardUsers.data &&
      rollforwardUsers.data.message &&
      rollforwardUsers.data.code &&
      rollforwardUsers.data.code === "ALREADY_COMPLETED"
    ) {
      //duplicate initiation
      setLoader(false);
      setOpenPopup(false);
      setIsPopupVisible(true);
      dispatch({
        type: "ROLL_FORWARD_USER_RESET",
        payload: {},
      });
    }
  }, [rollforwardUsers]);
  useEffect(() => {
    //lock status API response
    if (
      !getLockStatus.fetching &&
      getLockStatus.data &&
      getLockStatus.data.message &&
      getLockStatus.data.proceedAction
    ) {
      //Succefully Completed
      setLoader(false);
      myContext.setDisableUserUpdation(false);
    } else if (
      !getLockStatus.fetching &&
      getLockStatus.data &&
      getLockStatus.data.message &&
      !getLockStatus.data.proceedAction
    ) {
      //not completed
      if (firstTime) {
        myContext.setIsWarningPopup(false);
        triggerLock();
        setFirstTime(false);
        setLoader(false);
      }
      myContext.setDisableUserUpdation(true);
      dispatch({
        type: "CHECK_LOCK_STATUS_RESET",
        payload: {},
      });
      setTimeout(function () {
        getLockStatusAPI();
      }, 15000);
    }
  }, [getLockStatus]);

  useEffect(() => {
    dispatch({
      type: "GET_PHASE_VALIDATION_STATUS",
      payload: {},
    });
  }, [dispatch]);

  useEffect(() => {
    if (
      !getPhaseValidationStatus.fetching &&
      getPhaseValidationStatus.data.length
    ) {
      //not completed
      console.log(getPhaseValidationStatus);
      setValues({
        ...values,
        toYear: "FY" + getPhaseValidationStatus.data[0].year,
        toPeriod: getPhaseValidationStatus.data[0].phase[0],
      });
      setPeriodDropdown(getPhaseValidationStatus.data[0].phase);
      // processDropdown();
    }
  }, [getPhaseValidationStatus]);

  useEffect(() => {
    const yearDetails = Array.isArray(getPhaseValidationStatus.data)
      ? getPhaseValidationStatus.data.find(
          (elem) => `FY${elem.year}` === values.toYear
        )
      : {};
    if (yearDetails && yearDetails.phase) {
      setPeriodDropdown(yearDetails.phase);
    }
  }, [values.toYear]);

  const yearArray = Array.isArray(getPhaseValidationStatus.data)
    ? getPhaseValidationStatus.data.map((elem) => {
        return `FY${elem.year}`;
      })
    : [];

  return (
    <>
      {loader && <Loader />}
      <div className="rollforwardUsers">
        <div className="rowdata">
          <div className="header">From</div>
        </div>
        <div className="rowdata">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // marginLeft: "10px",
              margiTop: "2px",
            }}>
            <DropdownComponent
              label={"Year*"}
              defaultValue={values.fromYear}
              required={false}
              selectOnChange={(dummy, value) => {
                dropOnChange(value, "fromYear");
              }}
              varName="fromYear"
              list={getFinancialYears(myContext.financialYear)}
              errorMessage={errorMessages}
              disabled={myContext.disableUserUpdation}
            />
          </div>{" "}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // marginLeft: "10px",
              margiTop: "2px",
            }}>
            <DropdownComponent
              label={"Period*"}
              defaultValue={values.fromPeriod}
              required={false}
              selectOnChange={(dummy, value) => {
                dropOnChange(value, "fromPeriod");
              }}
              varName="fromPeriod"
              list={myConsts.TimePeriodsArray}
              errorMessage={errorMessages}
              disabled={myContext.disableUserUpdation}
            />
          </div>
          <div className="count-sec">
            <div className="per-box">
              <span className="eyaa-user-01"></span>
            </div>
            <div className={"string"}>
              <span className="">
                <b>{count}</b>
                {" users will be rolled forward"}
              </span>
            </div>
          </div>
        </div>
        <div className="rowdata">
          <div className="header" style={{ marginTop: "13px" }}>
            To
          </div>
        </div>
        <div className="rowdata">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // marginLeft: "10px",
              margiTop: "2px",
            }}>
            <DropdownComponent
              label={"Year*"}
              defaultValue={values.toYear}
              required={false}
              selectOnChange={(dummy, value) => {
                dropOnChange(value, "toYear");
              }}
              varName="toYear"
              list={yearArray}
              errorMessage={errorMessages}
              disabled={myContext.disableUserUpdation}
            />
          </div>{" "}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // marginLeft: "10px",
              margiTop: "2px",
            }}>
            <DropdownComponent
              label={"Period*"}
              defaultValue={values.toPeriod}
              required={false}
              selectOnChange={(dummy, value) => {
                dropOnChange(value, "toPeriod");
              }}
              varName="toPeriod"
              list={periodDropdown}
              errorMessage={errorMessages}
              disabled={myContext.disableUserUpdation}
            />
          </div>
          {/* </div> */}
          <div className="count-sec">
            <div className={"string"}>
              <span className="">{errorMessages.common}</span>
            </div>
          </div>
        </div>
        {/* {myContext.disableUserUpdation && (
          <div className="error-section">
            <span className="redMsg">
              {" "}
              <i className="icon-warning-filled" />
            </span>
            <div className="desc">
              {
                "Users are being added to the database. You can upload new file once this operation is completed. We appreciate your patience!"
              }
            </div>
          </div>
        )} */}
        <div className="AdminActions">
          <>
            <div
              className="cancel"
              onClick={() => {
                props.onClose();
              }}>
              Cancel
            </div>

            <div
              className={
                "save" +
                (enableSave && !myContext.disableUserUpdation && count > 0
                  ? ""
                  : " disable-btn ")
              }
              onClick={() => {
                if (enableSave && !myContext.disableUserUpdation && count > 0) {
                  setOpenPopup(true);
                }
              }}>
              Roll Forward
            </div>
          </>
        </div>
        <AlertSubmitPopup
          header={"Confirmation"}
          onlyAlert={false}
          delete={true}
          cancelBtnTxt={"Cancel"}
          submitBtnTxt={"Confirm"}
          simpleClosePopup={() => setOpenPopup(false)}
          admin={true}
          message={`${
            "Would you like to roll forward " +
            count +
            " users from " +
            values.fromYear.replace("FY", "FY ") +
            " " +
            values.fromPeriod +
            " to " +
            values.toYear.replace("FY", "FY ") +
            " " +
            values.toPeriod +
            " ? "
          } `}
          bodyText={
            "Please note that this will take some time. Once the process is completed, we will share the summary report over email"
          }
          showAlertSubmitPopup={openPopup}
          cancelAlertSubmitPopup={() => setOpenPopup(false)}
          submitPopupChanges={() => {
            setOpenPopup(false);
            rollForwardAPI();
          }}></AlertSubmitPopup>

        {isPopupVisible && (
          <div className="popup-dialog">
            <div className="dialog">
              <div className="header">
                <h5>{"Alert"}</h5>
                <span
                  style={{ position: "absolute", right: "12px", top: "10px" }}
                  className="icon-close-filled icon"
                  onClick={() => setIsPopupVisible(false)}></span>
              </div>

              <div
                className="content"
                style={{ marginTop: "31px", fontSize: "12px" }}>
                <span>
                  This operation was already performed. Kindly make a new
                  selection of Year/Period
                </span>
              </div>

              {
                <div className="footer">
                  <button
                    className="cancel-btn"
                    onClick={() => {
                      setIsPopupVisible(false);
                    }}>
                    Close
                  </button>
                </div>
              }
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default RollForwardUser;
