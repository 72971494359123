// AppInsights.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import config from "../constants/config";

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: config.appInsightKey,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        },
        maxBatchInterval: 1000,
        disableExceptionTracking: false,
        disableTelemetry: false,
        disableAjaxTracking: true,
        disableFetchTracking: true,
        disableDataLossAnalysis: true,
        disableCorrelationHeaders: true,
        disableFlushOnBeforeUnload: true,
        disableFlushOnUnload: true,
        disableCookiesUsage: true,
        disableXhr: true,
        disableFetch: true,
        disableBeacon: true,
        disablePageUnloadEvents: true,
        disablePageShowEvents: true,
        autoTrackPageVisitTime:false,
        enableAutoRouteTracking:false,
        enableRequestHeaderTracking: false,
        enableResponseHeaderTracking:false,
        isBrowserLinkTrackingEnabled:false,
    }
});
appInsights.loadAppInsights();
export { reactPlugin, appInsights };