import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import _ from "lodash";
import DropdownComponent from "../../AdminGeneralParam/DropdownComponent/DropdownComponent";
import TextField from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import FormHelperText from "@material-ui/core/FormHelperText";
import {ifNonEmptyInput} from '../../../../helpers/textValidator';

// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import "./AdminPopup.scss";
import { getErrorMessages, isNumeric } from "../../../../utils/utils";
const useStyles = makeStyles((theme) => ({
  helper: {
    color: "red",
  },
  root: {
    "& .MuiFilledInput-underline.Mui-error:after": {
      borderBottom: "1px solid #f44336",
    },
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "556px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  labelroot: {
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "8px",
  },
  topSpacing: {
    marginTop: "16px",
  },
  topSyli: {
    marginBottom: " -30px",
    marginTop: "16px",
    zIndex: "8",
    width: "100px",
  },
  half: {
    display: "flex",
    flexDirection: "row",
  },
  popper: {
    zIndex: "3000",
    '&[x-placement*="bottom-start"]': {
      top: "9px!important",
      left: "-248px!important",
    },
  },
}));
const AdminPopup = (props) => {
  const classes = useStyles();
  const colorPalette = [
    { id: 1, colorName: "green", selected: false, value: "#C981B2" },
    { id: 2, colorName: "blue", selected: false, value: "#B14891" },
    { id: 3, colorName: "violet", selected: false, value: "#922B73" },
    { id: 4, colorName: "peach", selected: false, value: "#750E5C" },
    { id: 5, colorName: "green", selected: false, value: "#5A0A42" },
    { id: 6, colorName: "blue", selected: false, value: "#42152D" },
    { id: 7, colorName: "violet", selected: false, value: "#351C21" },
    { id: 8, colorName: "peach", selected: false, value: "#FF9A91" },
    { id: 10, colorName: "green", selected: false, value: "#FF736A" },
    { id: 12, colorName: "violet", selected: false, value: "#F95D54" },
    { id: 13, colorName: "peach", selected: false, value: "#FF4136" },
    { id: 14, colorName: "green", selected: false, value: "#E0362C" },
    { id: 9, colorName: "blue", selected: false, value: "#B9251C" },
    { id: 11, colorName: "violet", selected: false, value: "#7A130D" },

    { id: 17, colorName: "peach", selected: false, value: "#FFB46A" },
    { id: 18, colorName: "green", selected: false, value: "#FF9831" },
    { id: 19, colorName: "blue", selected: false, value: "#FF810A" },
    { id: 20, colorName: "violet", selected: false, value: "#FF6E00" },
    { id: 21, colorName: "peach", selected: false, value: "#F76900" },
    { id: 22, colorName: "green", selected: false, value: "#EB4F00" },
    { id: 23, colorName: "blue", selected: false, value: "#BC2F00" },
    { id: 24, colorName: "violet", selected: false, value: "#8CE8AD" },
    { id: 25, colorName: "peach", selected: false, value: "#57E188" },
    { id: 26, colorName: "peach", selected: false, value: "#34C768" },
    { id: 27, colorName: "green", selected: false, value: "#2DB757" },
    { id: 28, colorName: "blue", selected: false, value: "#189D3E" },
    { id: 14, colorName: "green", selected: false, value: " #168736" },
    { id: 15, colorName: "blue", selected: false, value: " #13652A" },

    { id: 29, colorName: "violet", selected: false, value: "#93F0E6" },
    { id: 30, colorName: "peach", selected: false, value: "#60E6E1" },
    { id: 31, colorName: "green", selected: false, value: "#42C9C2" },
    { id: 32, colorName: "blue", selected: false, value: "#27ACAA" },
    { id: 33, colorName: "violet", selected: false, value: "#109090" },
    { id: 34, colorName: "peach", selected: false, value: "#0D7575" },
    { id: 29, colorName: "violet", selected: false, value: "#004F4F" },
    { id: 30, colorName: "peach", selected: false, value: "#87D3F2" },
    { id: 31, colorName: "green", selected: false, value: "#4EBEEB" },
    { id: 32, colorName: "blue", selected: false, value: "#35A4E8" },
    { id: 33, colorName: "violet", selected: false, value: "#188CE5" },
    { id: 34, colorName: "peach", selected: false, value: "#1777CF" },
    { id: 29, colorName: "violet", selected: false, value: "#155CB4" },
    { id: 30, colorName: "peach", selected: false, value: "#082C65" },

    { id: 31, colorName: "green", selected: false, value: "#9C82D4" },
    { id: 32, colorName: "blue", selected: false, value: "#724BC3" },
    { id: 33, colorName: "violet", selected: false, value: "#542EA5" },
    { id: 34, colorName: "peach", selected: false, value: "#3D1089" },

    { id: 31, colorName: "green", selected: false, value: "#240D75" },
    { id: 32, colorName: "blue", selected: false, value: "#0A095A" },
    { id: 33, colorName: "violet", selected: false, value: "#15173E" },
  ];
  //

  const [selectedIndex, setSelectedIndex] = useState("white");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [values, setValues] = useState({
    sectionName: "",
    sectionDescription: "",
    multiplierScore: "",
    color: "",
    displayOrder: "",
    _id: "",
  });
  const open = Boolean(anchorEl);

  const [errorMessages, setErrorMessages] = useState({
    sectionName: "",
    sectionDescription: '',
    multiplierScore: "",
    color: "",
  });
  // const clickAwayHandler = () => {
  //   setAnchorEl(null);
  // };

  const textOnChange = (value, label) => {
    props.isChangeDone(true)
    // console.log(props.uniquelist)
    if (label === "sectionName") {
      let flag = false;
      if ('general' === value.toLowerCase().trim()) {
        flag = true;
        setErrorMessages({
          ...errorMessages,
          sectionName: "Can't have section name as General",
        });
      }
      props.uniquelist &&
        props.uniquelist.sectionName.forEach((item) => {
          if (item.toLowerCase().trim() === value.toLowerCase().trim()) {
            flag = true;
            setErrorMessages({
              ...errorMessages,
              sectionName: "Section with same name already exists",
            });
          }
        });
      if (!flag)
        setErrorMessages({
          ...errorMessages,
          sectionName: "",
        });
      if (value.length < 50) setValues({ ...values, [label]: value });
    } else if (label === "sectionDescription") {
      if (value.length < 190) setValues({ ...values, [label]: value });
    } else setValues({ ...values, [label]: value });
  };
  useEffect(() => {
    props.passValues(values, errorMessages);
    // eslint-disable-next-line
  }, [values, errorMessages]);
  useEffect(() => {
    const { sectionName, sectionDescription, multiplierScore, color, _id } = values;
    if (_id !== '') {
      const obj = { sectionName, sectionDescription, multiplierScore, color }
      let localErrorMessage = getErrorMessages(obj, ['sectionName', 'sectionDescription', 'multiplierScore', 'color']);
      if ('general' === sectionName.toLowerCase().trim()) {
        localErrorMessage = { ...localErrorMessage, sectionName: `Can't have section name as General` }
      }
      setErrorMessages((prevState) => ({
        ...prevState,
        ...localErrorMessage
      }));
    }
  }, [values]);
  useEffect(() => {
    props.isChangeDone(false);
    if (props.data) {
      let temp = props.data;
      colorPalette.forEach((item, index) => {
        if (props.data.color && item.value === props.data.color.toLowerCase()) {
          temp.color = item.value;
          setSelectedIndex(item.colorName);
        }
      });
      setValues({
        sectionName: props.data.name,
        sectionDescription: props.data.description,
        multiplierScore: props.data && isNumeric(props.data.score) ? ("x" + Number.parseInt(props.data.score)) : '',
        color: temp.color,
        _id: temp._id,
        displayOrder: props.data.displayOrder,
        existingName: props.data.name
      });
    }
    // eslint-disable-next-line
  }, [props.data]);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };


 const validateInput = (label) => {
   const text = values[label];
    if(!ifNonEmptyInput(text)) {
      setValues({ ...values, [label]: "" });
    } else {
      setValues({ ...values, [label]: text.trim() });
    }
 }

  return (
    <div className="add-new-section">
      <InputLabel
        htmlFor="uncontrolled-native"
        className={"leftSpacing"}
        error={errorMessages.sectionName !== ''}
        classes={{
          root: classes.labelroot,
        }}>
        {"Section Name*"}
      </InputLabel>
      <TextField
        id="filled-basic"
        variant="filled"
        className={classes.root}
        required={true}
        value={values.sectionName}
        onChange={(e) => textOnChange(e.target.value, "sectionName")}
        onBlur={() => validateInput("sectionName")}
        error={errorMessages.sectionName !== ''}
        helperText={errorMessages.sectionName}
      />
      <InputLabel
        htmlFor="uncontrolled-native"
        error={errorMessages.sectionDescription !== ''}
        className={classes.topSpacing}
        classes={{
          root: classes.labelroot,
        }}>
        {"Section Description*"}
      </InputLabel>
      <TextField
        id="filled-basic"
        variant="filled"
        multiline
        maxRows={2}
        className={classes.root}
        required={true}
        value={values.sectionDescription}
        error={errorMessages.sectionDescription !== ''}
        helperText={errorMessages.sectionDescription}
        onChange={(e) => textOnChange(e.target.value, "sectionDescription")}
        onBlur={() => validateInput("sectionDescription")}
      />
      <div>
        <div className={classes.half}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputLabel
              htmlFor="uncontrolled-native"
              className={classes.topSyli}
              classes={{
                root: classes.labelroot,
              }}
              error={errorMessages.multiplierScore !== ""}
            >
              {"Multipler Score*"}
            </InputLabel>
            <DropdownComponent
              label=""
              defaultValue={values.multiplierScore}
              required={false}
              selectOnChange={
                (dummy, value) => {
                  textOnChange(value, "multiplierScore");
                } /* selectOnChange */
              }
              varName="multiplierScore"
              list={["", "x1", "x2", "x3", "x4", "x5", "x6", "x7", "x8", "x9", "x10"]}
              errorMessage={errorMessages}
              disabled={false}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="color-selection-div">
              <div
                style={{
                  height: "36px",
                  width: "36px",
                  border: "#80808061 solid 1px",
                  backgroundColor: `${values.color}`,
                  color: `${values.color}`,
                }}
                className={selectedIndex}
                value={values.color}>
                {/* <option
              selected={true}
              className={"color-div white"}
              style={{ display: "none" }}>
              &nbsp;
            </option>
            */}
              </div>
              <div className="color-text" style={{ borderBottomColor: errorMessages.color !== '' ? '#f44336' : '', color: errorMessages.color !== '' ? '#f44336' : '' }}>
                Section Color*<div className="value">{values.color}</div>
              </div>
              <div className="arrow-divv" onClick={(e) => handleClick(e)}>
                <i className="icon-chevron-down" />
              </div>
            </div>
            <FormHelperText
              classes={{
                root: classes.helper,
              }}>
              {errorMessages.color}
            </FormHelperText>
          </div>
        </div>
      </div>
      {/* <ClickAwayListener onClickAway={clickAwayHandler}> */}
      <Popper
        open={open}
        anchorEl={anchorEl}
        className="colorpopper"
        className={classes.popper}
        placement="bottom-start">
        <Box
          className="colorpopper"
          sx={{ border: 1, p: 1, bgcolor: "background.paper" }}
          style={{
            display: "flex",
            border: " 1px solid black",
            width: "280px",
            flexDirection: "row",
            flexWrap: "wrap",
            padding: "0px!important",
          }}>
          {colorPalette.map((item) => (
            <div
              onClick={(e) => {
                props.isChangeDone(true)
                let flag = false;
                props.uniquelist &&
                  props.uniquelist.color.forEach((i) => {
                    if (i && i.toLowerCase() === item.value.toLowerCase()) {
                      flag = true;
                      setErrorMessages({
                        ...errorMessages,
                        color: "Section with same color already exists",
                      });
                    }
                  });
                if (!flag)
                  setErrorMessages({
                    ...errorMessages,
                    color: "",
                  });
                setSelectedIndex(item.colorName);
                setValues({ ...values, color: item.value });
                setAnchorEl(null);
              }}
              className={"color-div "}
              style={{
                backgroundColor: `${item.value}`,
                color: `${item.value}`,
              }}
              value={item.value}>
              &nbsp;
            </div>
          ))}
        </Box>
      </Popper>
      {/* </ClickAwayListener> */}
    </div>
  );
};
export default withRouter(AdminPopup);
