import React, { useState, useEffect, useContext } from "react";
import * as myConsts from "../../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  authProvider,
  GraphExplorerscopes,
} from "./../../../Authentication/authProvider";
import AppContext from "../../../utils/appContext";
import moment from "moment";
import "./DiscussionGuideCard.scss";
const extractFirstLetters = (name) => {
  const myArr = name && name.split(" ");

  let firstName = myArr.length > 0 ? myArr[0] : "";
  let lastName = myArr.length > 1 ? myArr[1] : "";

  let firstLetters =
    (firstName.length > 0 ? firstName[0] : "") +
    (lastName.length > 0 ? lastName[0] : "");

  return firstLetters;
};
const getStatusColorClass = (status) => {
  // To set color of triangle in top-right corner based on counselee's assesment status
  if (status) {
    switch (status.toLowerCase()) {
      case myConsts.statusSubmitted:
        return "orangish";
      case myConsts.statusNotStarted:
      case myConsts.statusInProgress:
      case myConsts.pendingSubmission:
        return "redish";
      case myConsts.statusRequestedChange:
        return "purpleShade";
      case myConsts.statusCompleted:
        return "greenish";
      default:
        return "redish";
    }
  }
};
const getStatus = (status) => {
  // Status displayed on screen is differenr from status received through API,this function is to map value from API to UI
  if (status) {
    switch (status.toLowerCase()) {
      case myConsts.statusSubmitted:
        return myConsts.pendingApproval;
      case myConsts.statusNotStarted:
      case myConsts.statusInProgress:
      case myConsts.pendingSubmission:
        return myConsts.pendingSubmission;
      case myConsts.statusRequestedChange:
        return myConsts.statusRequestedChange;
      case myConsts.statusCompleted:
        return myConsts.approved;
      default:
        return myConsts.pendingSubmission;
    }
  }
};
const disableCard = (status) => {
  // To Disable Card click if status is anything other than approved or Completed or submitted
  if (status) {
    switch (status.toLowerCase()) {
      case myConsts.statusSubmitted:
      case myConsts.approved:
      case myConsts.statusCompleted:
        return false;
      case myConsts.statusNotStarted:
      case myConsts.statusInProgress:
      case myConsts.statusRequestedChange:
      case myConsts.pendingSubmission:
        return true;
      default:
        return true;
    }
  }
};

const DiscussionGuideCard = (props) => {
  // const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  const { isAdmin = false, year, phase } = props;
  const enableProfile = isAdmin && !(myContext.financialYear === year && myContext.activePeriod === phase);
  const [userDp, setUserDp] = useState("");
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  useEffect(() => {
    //to call graph API for fetching user display picture,individually
    (async function () {
      try {
        const idtoken = await authProvider.acquireTokenSilent({
          scopes: GraphExplorerscopes,
        });
        const token = idtoken.accessToken;
        const url = `${myConsts.msHostName +
          "/users/" +
          props.userData.userId +
          myConsts.getUserImg
          }`;
        const options = {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
            userid: props.userData.userId,
          },
        };
        await fetch(url, options)
          .then(async (r) => {
            if (r.status === 200 && !r.bodyUsed) return r;
          })
          .then(async (response) => {
            const l = await response.blob();
            // console.log(l);
            if (l.size > 0) {
              let href = window.URL.createObjectURL(l);
              setUserDp(href);
            }
          });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [props.userData]);

  // const [isPhaseOver, setIsPhaseOver] = useState(false);
  // useEffect(() => {
  //   if (
  //     assessmentMetaData &&
  //     assessmentMetaData.data &&
  //     assessmentMetaData.data.data
  //   ) {
  //     const data = assessmentMetaData.data.data;
  //     setIsPhaseOver(
  //       data.activePhase &&
  //         data.activePhase === myContext.currentPeriod &&
  //         new Date().setHours(24) >=
  //           new Date(
  //             data.assessmentMetaData.leadReviewer_CompletionDate
  //           ).setHours(24)
  //     );
  //   }
  // }, [assessmentMetaData]);
  // console.log(isPhaseOver);

  return (
    <div
      // className={
      //   "discussion-guide-card " +
      //   (!myContext.formsInActive && disableCard(props.userData.status) && !enableProfile
      //     ? " disable-discussion-guide"
      //     : "")
      // }
      className={"discussion-guide-card"}
    >
      <div
        className={`legend-triangle ${getStatusColorClass(
          props.userData.status
        )}`}></div>
      <div className={"pic-section"}>
        {userDp ? (
          <img
            alt="dp"
            src={
              userDp
                ? userDp
                : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
            }
          />
        ) : (
          <div className={"dummyDP"}>
            {extractFirstLetters(
              props.userData.preferredName ? props.userData.preferredName : ""
            )}{" "}
          </div>
        )}
      </div>
      <div className="profile-name">
        {props.userData && props.userData.preferredName}
      </div>
      <div className="profile-role">Partner</div>
      <div className="details-sec">
        {/* <div className="title">Assessment Submitted On:</div>
        <div className="value">
          {" "}
          {props.userData &&
            props.userData.submittedOn &&
            getStatus(props.userData.status) !== myConsts.pendingSubmission &&
            props.userData.submittedOn !== ""
            ? moment(props.userData.submittedOn).format("DD-MMM-YYYY")
            : ""}
        </div>
        <div className="title">Assessment Status:</div>
        <div className="value">
          {" "}
          {props.userData && props.userData.status
            ? getStatus(props.userData.status)
            : ""}
        </div>
        <div className="title">Assessment Approved On:</div>
        <div className="value">
          {" "}
          {props.userData &&
            props.userData.approvedOn &&
            props.userData.approvedOn !== "" &&
            getStatus(props.userData.status) === myConsts.approved
            ? moment(props.userData.approvedOn).format("DD-MMM-YYYY")
            : ""}
        </div>
        <div className="title">Self Discussion Guide Status:</div>
        <div className="value">
          {" "}
          {props.userData && props.userData.selfDiscussionGuideStatus
            ? props.userData.selfDiscussionGuideStatus
            : ""}
        </div> */}
        <div className="title" style={{ textAlign: "left" }}>Lead Reviewer Discussion Guide Status:</div>
        <div className="value">
          {" "}
          {props.userData && props.userData.finalizationStatus
            ? props.userData.finalizationStatus
            : ""}
        </div>
      </div>
      <div className="arrow">
        {" "}
        <i
          // className={
          //   "icon-arrow-right" +
          //   (!myContext.formsInActive && disableCard(props.userData.status) && !enableProfile
          //     ? " disable-ppmd"
          //     : "")
          // }
          className={"icon-arrow-right"}
          onClick={() => {
            // if (
            //   !disableCard(props.userData.status) ||
            //   myContext.formsInActive || enableProfile
            // ) {
            //   myContext.setActivePPMDuserId(props.userData.userId);
            //   myContext.setPPMDData(props.userData);
            //   sessionStorage.setItem("activePPMDuserId", props.userData.userId);
            //   sessionStorage.setItem(
            //     "PPMDData",
            //     JSON.stringify(props.userData)
            //   );
            //   if (enableProfile) {
            //     myContext.setIsRouteFromAdmin(true);
            //     // console.log(props.year, props.phase, "dddd");
            //     myContext.setFinancialYear(props.year);
            //     sessionStorage.setItem("financialYear", props.year);
            //     sessionStorage.setItem("profilePhase", props.phase);
            //     sessionStorage.setItem("viewUserProfile", true);
            //   }
            //const params = new URLSearchParams({id: props.userData["_id"] });
            myContext.setActivePPMD_Id(props.userData["_id"]);
            sessionStorage.setItem("activePPMD_Id", props.userData["_id"]);
            myContext.setActivePPMD_UserId(props.userData.userId);
            sessionStorage.setItem("activePPMD_UserId", props.userData.userId);
            props.history.push({
              pathname: myConsts.routeFinalizationForm,
              state: { id: props.userData["_id"] }
            });
            // props.history.push(
            //   //myConsts.routeHome + "/" + props.userData["_id"]
            //    `${myConsts.routeFinalizationForm}?id=${props.userData["_id"]}`
            // );
            //}
          }}></i>
      </div>
    </div>
  );
};

export default DiscussionGuideCard;
