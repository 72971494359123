import React, { useCallback, useState, useEffect, useContext } from "react";
import SideMenu from "../../SideMenu/SideMenu";
import Reports from "./Reports";
import AdditionalReporting from "./AdditionalReporting/AdditionalReporting";
import EntireAssessmentData from "./EntireAssessmentData/EntireAssessment";
import PowerBiDashboard from "./PowerBiDashboard/PowerBiDashboard";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import * as myConsts from "../../../constants/constants";
import { makeStyles } from "@material-ui/core/styles";
import { validateUrl } from "../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { Flip, toast } from "react-toastify";
import "./ReportMainPage.scss";
import "../Admin.scss";
import { InputLabel, TextField, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root1: {
    "& .MuiFilledInput-underline.Mui-error:after": {
      borderBottom: "none!important",
    },

    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
      fontFamily: "EYInterState-Regular",
    },

    "& .MuiFilledInput-underline": {
      width: "450px",
      height: "27px",
      "&:before": {
        borderBottom: "none!important",
      },
      "&:after": {
        borderBottom: "none!important",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
      fontFamily: "EYInterState-Regular",
    },
  },
  labelroot: {
    width: "445px",
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "8px",
    // fontWeight: "600",
  },
  tooltip: {
    fontSize: 12,
    marginLeft: 10,
  },
}));

const ReportMainPage = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const crumbs = [{ name: "Admin", path: myConsts.routeAdmin }];
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupType, setPopUpType] = useState(0);
  const [urlText, setUrlText] = useState("");
  const [isChangeDone, setIsChangeDone] = useState(false);
  const getPowerBIlink = useSelector((state) => state.getPowerBIlink);
  const updatePowerBILink = useSelector((state) => state.updatePowerBILink);
  const [isLoading, setIsLoading] = useState(false);

  let powerBILink = getPowerBIlink
    ? getPowerBIlink.data
      ? getPowerBIlink.data.data
      : ""
    : "";

  const [isMainPageVisible, setIsMainPageVisible] = useState(
    sessionStorage.getItem("currentPageName")
      ? sessionStorage.getItem("currentPageName") !== "Reports"
        ? false
        : true
      : true
  );

  const setCurrentPageConfig = useCallback((pageNo) => {
    switch (pageNo) {
      case 1:
        setCurrentPageName("Basic Reporting");
        sessionStorage.setItem("currentPageName", "Basic Reporting");
        break;
      case 2:
        setCurrentPageName("Additional Reporting");
        sessionStorage.setItem("currentPageName", "Additional Reporting");
        break;
      case 3:
        setCurrentPageName("Entire Assessment Data");
        sessionStorage.setItem("currentPageName", "Entire Assessment Data");
        break;
      case 4:
        setCurrentPageName("Power BI Dashboard");
        sessionStorage.setItem("currentPageName", "Power BI Dashboard");
        break;
      default:
        setCurrentPageName("");
    }
    setCurrentPage(pageNo);
    setIsMainPageVisible(false);
  }, []);

  const [currentPage, setCurrentPage] = useState(
    sessionStorage.getItem("currentPageName")
      ? sessionStorage.getItem("currentPageName") === "Basic Reporting"
        ? 1
        : sessionStorage.getItem("currentPageName") === "Additional Reporting"
        ? 2
        : sessionStorage.getItem("currentPageName") === "Entire Assessment Data"
        ? 3
        : sessionStorage.getItem("currentPageName") === "Power BI Dashboard"
        ? 4
        : null
      : null
  );

  const [currentPageName, setCurrentPageName] = useState(
    sessionStorage.getItem("currentPageName") || "Reports"
  );

  const getCurrentPage = useCallback(() => {
    switch (currentPage) {
      case 1:
        return (
          <Reports
            onClose={() => {
              setCurrentPageName("Reports");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "Reports");
            }}
            history={props.history}
          />
        );
      case 2:
        return (
          <AdditionalReporting
            onClose={() => {
              setCurrentPageName("Reports");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "Reports");
            }}
          />
        );
      case 3:
        return (
          <EntireAssessmentData
            onClose={() => {
              setCurrentPageName("Reports");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "Reports");
            }}
          />
        );
      case 4:
        return (
          <PowerBiDashboard
            onClose={() => {
              setCurrentPageName("Reports");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "Reports");
            }}
          />
        );
      default:
        return "";
    }
  }, [currentPage]);

  const getPopupContent = useCallback(() => {
    if (popupType === 1) {
      return (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "10px",
            }}>
            <InputLabel
              htmlFor="uncontrolled-native"
              classes={{
                root: classes.labelroot,
              }}>
              {"URL"}
            </InputLabel>
            <TextField
              id="filled-basic"
              variant="filled"
              className={classes.root1}
              required={true}
              placeholder={"www.site.com"}
              value={urlText}
              onChange={(e) => {
                setUrlText(e.target.value)
                if(powerBILink !== e.target.value){
                  setIsChangeDone(true);
                }
              }}
            />
          </div>
        </>
      );
    } else if (popupType === 2) {
      return (
        <>
          <p>Would you like to update the Power BI Dashboard URL?</p>
          <p>
            Please ensure that the URL is accurate and note that on confirming,
            users would be able to navigate to the Dashboard using this URL.
          </p>
        </>
      );
    } else if (popupType === 3) {
      return (
        <>
          <p>
            Would you like to close without completing the operation? Please
            note that on confirming, changes will be lost.
          </p>
        </>
      );
    }
  }, [popupType, urlText]);

  const showToastMessage = type => {
    let message = ''
    switch (type) {
      case 'urlUpdated':
        message = 'URL updated successfully'
        break;
      default:
        break;
    }
    toast(
      <div className="success-text">
        <span className="icon-verified-outline icon"></span>
        <p>
          <span>{message}</span>
        </p>
      </div>,
      {
        position: "top-center",
        transition: Flip,
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }

  useEffect(() => {
    dispatch({
      type: "GET_POWERBI_LINK",
      payload: {},
    });
  }, [updatePowerBILink]);

  useEffect(() => {
    if (getPowerBIlink && getPowerBIlink.data && getPowerBIlink.data.data) {
      setUrlText(powerBILink);
    }
  }, [getPowerBIlink]);

  const updatePowerBIlink = useCallback(() => {
    dispatch({
      type: "UPDATE_POWERBI_LINK",
      payload: {
        content: urlText,
      }
    });
    setIsLoading(true);
  }, [urlText]);

  useEffect(() =>{
    if(
      !updatePowerBILink.fetching &&
      updatePowerBILink.data &&
      updatePowerBILink.data.status === "success"
    ){
      setIsLoading(false);
      showToastMessage('urlUpdated');
      dispatch({
        type: "UPDATE_POWERBI_LINK_RESET",
        payload: {},
      });
    }
  })

  const navToNewTab = () => {
    const newWindow = window.open(urlText, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  return (
    <>
      <div className="admin-container">
      {isLoading && <Loader />}
        <div className="admin-info-main-sidemenu">
          <SideMenu
            minimized={true}
            isminimizedMainMenu={true}
            isMainMenu={true}
            sideMenuItemSelect="Admin"
          />
        </div>
        <div className="admin-content admin-report-content">
          <div className="second-header admin-crumbs">
            <i
              className="icon-arrow-left"
              onClick={() => {
                if (!isMainPageVisible) {
                  setCurrentPageName("Reports");
                  setIsMainPageVisible(true);
                  sessionStorage.setItem("currentPageName", "Reports");
                } else {
                  props.history.push(myConsts.routeAdmin);
                }
              }}
            />
            <div className="admin-crumb-txt">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <Breadcrumb
                  crumbs={crumbs}
                  isAdmin={true}
                  isInnerAdmin={true}
                  {...props}
                />
                {!isMainPageVisible && (
                  <div className="icon-chevron-right iconStyling">
                    <a
                      className=" userMngmnt MuiTypography-root MuiLink-root MuiLink-underlineHover admin-crumb MuiTypography-colorInherit"
                      onClick={() => {
                        setIsMainPageVisible(true);
                        setCurrentPageName("Reports");
                        sessionStorage.setItem("currentPageName", "Reports");
                      }}>
                      Reports
                    </a>
                  </div>
                )}
              </div>
              <h4>{currentPageName}</h4>
            </div>
          </div>
          <div className="manage-report-content">
            {isMainPageVisible ? (
              <div className="card-block">
                <div className="card" onClick={() => setCurrentPageConfig(1)}>
                  <span className="eyaa-data-21"></span>
                  <span>Basic Reporting</span>
                </div>
                <div className="card" onClick={() => setCurrentPageConfig(2)}>
                  <span className="eyaa-data-22"></span>
                  <span>Additional Reporting</span>
                </div>
                <div className="card" onClick={() => setCurrentPageConfig(3)}>
                  <span className="eyaa-web-221"></span>
                  <span>Entire Assessment Data</span>
                </div>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={urlText !== "" ? "" : "No URL added"}
                  placement="bottom-start">
                  <div
                    className="card"
                    onClick={() => {
                      if (urlText !== "") {
                        navToNewTab();
                      }
                    }}>
                    <div
                      className="url-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsPopupVisible(true);
                        setPopUpType(1);
                      }}>
                      <i
                        className="eyaa-editor-41"
                        style={{ fontSize: "12px", padding: "2px" }}
                      />
                    </div>
                    <span className="eyaa-web-137"></span>
                    <span>Power BI Dashboard</span>
                  </div>
                </Tooltip>
              </div>
            ) : (
              <>{getCurrentPage()}</>
            )}
          </div>
        </div>
      </div>
      {isPopupVisible && (
        <div className="popup-dialog">
          <div
            className="dialog"
            style={{
              height: popupType === 1 ? "220px" : "180px",
              width: popupType === 1 ? "500px" : "380px",
            }}>
            <div
              className="header"
              style={{
                borderBottom: popupType === 1 ? "1px solid #C4C4CD" : "",
                padding: popupType === 1 ? "5px" : "",
              }}>
              <h5>{popupType === 1 ? "Update URL" : "Confirmation"}</h5>
              <span
                className="icon-close-filled icon"
                onClick={() => {
                  if (!isChangeDone) {
                    setIsPopupVisible(false);
                    // setUrlText("");
                  } else if (isChangeDone && popupType === 1) {
                    setPopUpType(3);
                  } else {
                    setPopUpType(1);
                  }
                }}></span>
            </div>
            <div
              className="content"
              style={{
                borderBottom: popupType === 1 ? "1px solid #C4C4CD" : "",
                paddingBottom: popupType === 1 ? "60px" : "",
              }}>
              {getPopupContent()}
            </div>
            <div className="footer">
              {popupType === 1 ? (
                <button
                  className="cancel-btn"
                  onClick={() => {
                    if (!isChangeDone) {
                      setIsPopupVisible(false);
                      setUrlText(powerBILink);
                    } else {
                      setPopUpType(3);
                    }
                  }}>
                  Cancel
                </button>
              ) : (
                <button
                  className="cancel-btn"
                  onClick={() => {
                    setPopUpType(1);
                  }}>
                  Cancel
                </button>
              )}

              {popupType === 1 ? (
                <button
                  className={
                    "confirm-btn " + (validateUrl(urlText) && isChangeDone ? "" : "disable-btn")
                  }
                  onClick={() => {
                    if (validateUrl(urlText) && isChangeDone) {
                      setIsPopupVisible(true);
                      setPopUpType(2);
                    }
                  }}>
                  Save
                </button>
              ) : (
                <button
                  className="confirm-btn"
                  onClick={() => {
                    if(popupType === 2){
                      updatePowerBIlink();
                    }
                    setIsPopupVisible(false);
                    setUrlText(powerBILink);
                    setIsChangeDone(false);
                  }}
                >
                  Confirm
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ReportMainPage;
