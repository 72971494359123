import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
  forwardRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import * as myConsts from "../../../../constants/constants";
import _, { debounce } from "lodash";
import "./ManageUser.scss";
import {
  Paper,
  InputBase,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  MenuList,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TextField,
  Input,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { toast, Flip } from "react-toastify";
import classNames from "classnames";
import AddUser from "./AddUser";
import Loader from "../../../Loader/Loader";
import AppContext from "../../../../utils/appContext";
import { getFinancialYears, validateEmail } from "../../../../utils/utils";
// import { Autocomplete } from "@material-ui/lab";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
//import { pointer, style } from "d3";
import { SEARCH_USER_DATA_MATCH } from "../../../../actions/types";

const useStyles = makeStyles({
  customAutoComplete: {
    "& input": {
      fontSize: "12px",
    }
  },
  customTextField: {
    display: 'flex',
    "& input::placeholder": {
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "EYInterState-Regular",
      fontSize: "12px",
    },
    "& input": {
      fontSize: "12px",
    }
  }
})

const ManageUser = (props) => {
  let clearInterval;
  const classes = useStyles();
  const dispatch = useDispatch();
  const lockId = "custom-id-lock";
  const myContext = useContext(AppContext);
  const [financialYear, setFinancialYear] = useState(myContext.financialYear);
  const [timePeriod, setTimePeriod] = useState(
    myContext.activePeriod
      ? myContext.activePeriod
      : myConsts.TimePeriodsArray[0]
  );
  const [searchText, setSearchText] = useState("");
  const [userSearch, setUserSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState("");
  const [isAddUserPageVisible, setIsAddUserPageVisible] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const userDetails = useSelector((state) => state.searchUserData);
  const userMatch = useSelector((state) => state.searchUserDataMatch);
  const userMatchDetails = userMatch && userMatch.data && userMatch.data.data ? userMatch.data.data : [];
  const deletedUser = useSelector((state) => state.deleteSingleUser);
  const lockStatus = useSelector((state) => state.checkLockStatus);
  const [isValidUserId, setIsValidUserId] = useState(false);
  const getPhaseValidationStatus = useSelector(
    (state) => state.getPhaseValidationStatus
  );
  const currentUserDetails = useSelector((state) => state.getUserDetails.data);
  const [periodDropdown, setPeriodDropdown] = useState([]);
  const userListHeaders = useMemo(
    () => [
      { value: "User name", key: "preferredName" },
      { value: "GPN", key: "gpn" },
      { value: "LPN", key: "lpn" },
      { value: "GUI", key: "gui" },
      { value: "Email Id", key: "userId" },
      { value: "Rank code", key: "rankCode" },
      { value: "Rank description", key: "rankDesc" },
      { value: "Service line", key: "serviceLine" },
      { value: "Sub-service line", key: "subServiceLine" },
    ],
    []
  );

  const periodOptions = useMemo(() => ["Plan", "Mid-Year", "Year-End"], []);
  const triggerLock = () => {
    myContext.setIsLockPopup(true);
    if (!document.getElementById(lockId) && lockStatus?.data?.userName)
      toast.warn(
        <div
          style={{
            // textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "EYInterState-Regular",
            fontSize: "12px",
          }}
        >
          <i className="icon-warning-outline" />
          {/* "Section Updated Successfully" : */}
          {`Previous operation initiated by ${lockStatus.data && lockStatus.data.userName
            } is still in progress. It will take some time. You
          can perform this action after the operation is completed. We
          appreciate your patience!`}
        </div>,
        {
          position: "top-center",
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: lockId,
        }
      );
  };
  const financialYearOptions = useMemo(() => {
    return getFinancialYears(myContext.financialYear, true);
  }, [myContext]);

  const searchUser = useCallback((userObj) => {
    if (!userObj) return;
    const user = userObj ? userObj : selectedUser;
    const userId = user && user.userId ? user.userId : (searchText ? searchText : '');
    if (userId)
      dispatch({
        type: "SEARCH_USER_DATA",
        payload: {
          financialYear,
          timePeriod,
          searchText: userId,
        },
      });
    if (validateEmail(userId)) {
      setUserSearch(userId);
    }
  }, [selectedUser, dispatch, financialYear, timePeriod, searchText]);

  const searchUserMatch = useCallback((value) => {
    const searchName = value ? value : searchText;
    //const regexMatchUserId = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$");
    let isValUserId = false;
    //if (searchName.match(regexMatchUserId)) {
    if (validateEmail(searchName)) {
      isValUserId = true;
    }
    dispatch({
      type: SEARCH_USER_DATA_MATCH,
      payload: {
        financialYear,
        timePeriod,
        searchText: searchName,
        isValUserId: isValUserId
      },
    });
    //debugger;
    setIsValidUserId(isValUserId);
  }, [financialYear, timePeriod, searchText, dispatch]);

  const debouncedUserMatch = (value) => {
    searchUserMatch(value);
  }

  // const debouncedUserMatch = debounce((value) => {
  //   searchUserMatch(value);
  // }, 200);

  const onUserAdd = useCallback(() => {
    setIsAddUserPageVisible(false);
    dispatch({
      type: "SEARCH_USER_DATA_RESET",
      payload: {},
    });
    dispatch({
      type: "ADD_SINGLE_USER_RESET",
      payload: {},
    });
    setSearchText("");
  }, [dispatch]);

  const onUserUpdate = useCallback(() => {
    setIsAddUserPageVisible(false);
    dispatch({
      type: "EDIT_SINGLE_USER_RESET",
      payload: {},
    });
    searchUser();
  }, [dispatch, searchUser]);

  const onUserDelete = useCallback(
    (source = "parent") => {
      if (source === "child") {
        setIsPopupVisible(true);
      } else {
        setIsPopupVisible(false);
        dispatch({
          type: "DELETE_SINGLE_USER",
          payload: {
            financialYear,
            timePeriod,
            emailId: userDetails.data.data.userId,
            createdBy: {
              name: currentUserDetails.displayName,
              userId: currentUserDetails.mail
            },
          },
        });
      }
    },
    [financialYear, timePeriod, userDetails, currentUserDetails, dispatch]
  );

  const notify = useCallback(() => {
    toast(
      <div className="success-text">
        <span className="icon-verified-outline icon"></span>
        <p>
          <span></span>
          <span>User details submitted for review.</span>
        </p>
      </div>,
      {
        position: "top-center",
        transition: Flip,
        autoClose: 8000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  const isDisablePage = useCallback(() => {
    /* return (
      (!_.isEmpty(lockStatus.data) && !lockStatus.data.proceedAction && !lockStatus.fetching)
    );*/
    //return !lockStatus?.data?.proceedAction && !lockStatus.fetching;
    return myContext.disableUserUpdation;
  }, [myContext]);

  const checkIsPreviousPhase = useMemo(() => {
    const currentPeriod = myContext.activePeriod
      ? myContext.activePeriod
      : myConsts.TimePeriodsArray[0];
    if (myContext.financialYear > financialYear ||
      (myContext.financialYear === financialYear &&
        periodOptions.indexOf(currentPeriod) >
        periodOptions.indexOf(timePeriod))
    ) {
      return true;
    } else {
      return false;
    }
  }, [myContext, timePeriod, financialYear, periodOptions]);

  const setActions = useCallback(
    (mode) => {
      if (
        (isValidTimePeriod && !checkIsPreviousPhase &&
          userDetails.data.validationStatus !== "Submitted") ||
        (mode === "View") // should allow viewing of previous year data as well
      ) {
        setIsAddUserPageVisible(true);
        setMode(mode);
      }
    },
    [checkIsPreviousPhase, userDetails]
  );

  useEffect(() => {
    if (
      !lockStatus.fetching &&
      lockStatus.data &&
      lockStatus.data.message &&
      lockStatus.data.proceedAction
    ) {
      //Succefully Completed
      myContext.setDisableUserUpdation(false);
    }
    else if (
      !lockStatus.fetching &&
      lockStatus.data &&
      lockStatus.data.message &&
      !lockStatus.data.proceedAction
    ) {
      //not completed
      if (firstTime) {
        myContext.setIsWarningPopup(false);
        triggerLock();
        setFirstTime(false);
      }
      myContext.setDisableUserUpdation(true);
    }
  }, [lockStatus]);

  useEffect(() => {
    if (userDetails.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [userDetails]);

  useEffect(() => {
    if (deletedUser.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (!_.isEmpty(deletedUser.data)) {
        setIsAddUserPageVisible(false);
        notify();
        setSearchText("");
        dispatch({
          type: "SEARCH_USER_DATA_RESET",
          payload: {},
        });
        dispatch({
          type: "DELETE_SINGLE_USER_RESET",
          payload: {},
        });
      }
    }
  }, [deletedUser, dispatch, notify]);

  useEffect(() => {
    dispatch({
      type: "CHECK_LOCK_STATUS",
      payload: {},
    });
    clearInterval = setInterval(() => {
      dispatch({
        type: "CHECK_LOCK_STATUS",
        payload: {},
      });
    }, 15000);
    return () => {
      dispatch({
        type: "SEARCH_USER_DATA_RESET",
        payload: {},
      });
      window.clearInterval(clearInterval);
    };
  }, [dispatch]);

  const resetSearch = useCallback(() => {
    setSearchText("");
    dispatch({
      type: "SEARCH_USER_DATA_RESET",
      payload: {},
    });
  });

  useEffect(() => {
    if (searchText === "") {
      resetSearch();
    }
  }, [searchText]);

  useEffect(() => {
    dispatch({
      type: "GET_PHASE_VALIDATION_STATUS",
      payload: {},
    });
  }, []);

  // useEffect(() => {
  //   if (
  //     !getPhaseValidationStatus.fetching &&
  //     getPhaseValidationStatus.data.length
  //   ) {
  //     //not completed
  //     setFinancialYear("FY" + getPhaseValidationStatus.data[0].year);
  //     setPeriodDropdown(getPhaseValidationStatus.data[0].phase);
  //   }
  // }, [getPhaseValidationStatus]);

  // useEffect(() => {
  //   const yearDetails = Array.isArray(getPhaseValidationStatus.data)
  //     ? getPhaseValidationStatus.data.find(
  //         (elem) => `FY${elem.year}` === financialYear
  //       )
  //     : {};
  //   if (yearDetails && yearDetails.phase) {
  //     setPeriodDropdown(yearDetails.phase);
  //   }
  // }, [financialYear]);

  // const yearArray = Array.isArray(getPhaseValidationStatus.data)
  //   ? getPhaseValidationStatus.data.map((elem) => {
  //       return `FY${elem.year}`;
  //     })
  //   : [];


  const isValidTimePeriod = useMemo(() => {
    let isValid = false;
    const found = Array.isArray(getPhaseValidationStatus.data) ? getPhaseValidationStatus.data.find((elem) => elem.year === financialYear) : {};
    if (found && found.phase) {
      isValid = found.phase.includes(timePeriod);
    }
    return isValid;
  }, [financialYear, timePeriod, getPhaseValidationStatus]);

  const ListboxComponent = forwardRef(function ListboxComponentInner(
    { ...rest },
    ref
  ) {
    return (
      <ul
        ref={ref}
        {...rest}
      />
    );
  });

  const [open, setOpen] = React.useState(false);

  const filterOptions = createFilterOptions({
    // matchFrom: 'start',
    stringify: (option) => option.preferredName + option.userId + option.gpn,
  });

  const callUserMatchSearch = (value) => {
    const regexMatch = new RegExp("^.*[a-zA-Z]{2}[0-9]{1}.*$")
    const regexFullMatch = new RegExp("^.*[a-zA-Z]{2}[0-9]{9}.*$")
    const matchResult = value.match(regexMatch)
    const fullMatchResult = value.match(regexFullMatch)
    //const status = value.length >= 3 && (userMatchDetails.length > 0 || value.length <= 11) && (!matchResult || fullMatchResult)    
    const status = value.length >= 3
    return status;
  }

  return (
    <div className="manage-user">
      {isLoading && <Loader />}
      {!isAddUserPageVisible ? (
        <>
          <div className="input-container">
            <div className="select-container">
              <FormControl>
                <InputLabel id="yearLabel">Select Year</InputLabel>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  labelId="yearLabel"
                  value={financialYear}
                  onChange={(e) => {
                    dispatch({
                      type: "SEARCH_USER_DATA_RESET",
                      payload: {},
                    });
                    setFinancialYear(e.target.value);
                  }}
                  disabled={isDisablePage()}
                >
                  {financialYearOptions.map((option) => (
                    <MenuItem value={option.key} key={option.key}>
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id="periodLabel">Period</InputLabel>
                <Select
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  labelId="periodLabel"
                  value={timePeriod}
                  onChange={(e) => {
                    dispatch({
                      type: "SEARCH_USER_DATA_RESET",
                      payload: {},
                    });
                    setTimePeriod(e.target.value);
                  }}
                  disabled={isDisablePage()}
                >
                  {periodOptions.map((val, index) => (
                    <MenuItem
                      value={val}
                      key={index}
                    // disabled={
                    //   index < periodOptions.indexOf(myContext.activePeriod)
                    //     ? true
                    //     : null
                    // }
                    >
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="select-container">
              <Autocomplete
                filterOptions={filterOptions}
                open={open && !_.isEmpty(userMatch.data.data)}
                onInputChange={(_, value) => {
                  setSearchText(value)
                  if (value.length <= 0) {
                    if (open) setOpen(false);
                  } else {
                    if (!open) setOpen(true);
                  }
                  if (callUserMatchSearch(value))
                    debouncedUserMatch(value)
                }}
                onClose={() => setOpen(false)}
                disabled={isDisablePage()}
                classes={{ root: classes.customAutoComplete }}
                style={{ width: 260 }}
                sx={{ ml: 1, flex: 1 }}
                className="input-search"
                id="search"
                options={userMatchDetails}
                ListboxComponent={listboxProps => (
                  <ListboxComponent
                    {...listboxProps}
                  />
                )}
                renderInput={params => {
                  return (
                    <div id='autocompleteInput' style={{ display: "flex" }} classes={{ root: classes.customTextField }}>
                      <TextField {...params} placeholder='Search User' />
                    </div>
                  )
                }}
                renderOption={(props, option) => (
                  <li {...props}>{props.preferredName}<br />{props.userId}</li>
                )}
                getOptionLabel={option => option.preferredName}
                value={selectedUser}
                onChange={(_event, user) => {
                  setSelectedUser(user);
                  searchUser(user);
                }}
                noOptionsText={
                  searchText ?
                    <div
                      className={classes.noOptions}
                      style={{ cursor: "pointer" }}
                      role="presentation"
                      onMouseDown={(event) => {
                        if (!isDisablePage())
                          searchUser();
                      }}
                    >
                      {'Search User'}
                      <IconButton
                        // type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                      >
                        <SearchIcon />
                      </IconButton>
                    </div> : ''
                }
              />
            </div>
          </div>
          <div className="user-details">
            {(!_.isEmpty(userDetails.data) &&
              userDetails.data.searchFlag === "samePhase") ||
              userDetails.data.exactMatch ? (
              <div className="table-container">
                <h6>User List</h6>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="user list">
                    <TableHead>
                      <TableRow>
                        {userListHeaders.map((header, index) => (
                          <TableCell key={index}>{header.value}</TableCell>
                        ))}
                        <TableCell key="actions">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        {userListHeaders.map((header, index) => (
                          <TableCell key={index}>
                            {userDetails.data.data[header.key]}
                          </TableCell>
                        ))}
                        <TableCell>
                          <span
                            className={classNames("eyaa-image-25", {
                              // "disable-icon": checkIsPreviousPhase,
                            })}
                            onClick={() => {
                              setActions("View");
                            }}
                          ></span>
                          <span
                            className={classNames("eyaa-file-001", {
                              "disable-icon":
                                !isValidTimePeriod ||
                                checkIsPreviousPhase ||
                                userDetails.data.validationStatus ===
                                "Submitted",
                            })}
                            onClick={() => {
                              setActions("Edit");
                            }}
                          ></span>
                          <span
                            className={classNames("eyaa-trash", {
                              "disable-icon":
                                !isValidTimePeriod ||
                                checkIsPreviousPhase ||
                                userDetails.data.validationStatus ===
                                "Submitted",
                            })}
                            onClick={() => {
                              if (
                                isValidTimePeriod &&
                                !checkIsPreviousPhase &&
                                userDetails.data.validationStatus !==
                                "Submitted"
                              )
                                setIsPopupVisible(true);
                            }}
                          ></span>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : (
              !isDisablePage() && (
                <div className="helper-text">Search to view user data</div>
              )
            )}
          </div>
          {!_.isEmpty(userDetails.data) &&
            (userDetails.data.searchFlag === "inDb" ||
              userDetails.data.searchFlag === "sameYear") &&
            !userDetails.data.exactMatch &&
            userDetails.data.validationStatus !== "Submitted" && (
              <div className="user-popup">
                <h3>User not found.</h3>
                {isValidTimePeriod && !checkIsPreviousPhase && (
                  <>
                    <p>
                      We could find this user in the previous assessment. Do you
                      want to add the user to the current period?
                    </p>
                    <input
                      type="button"
                      value="Add"
                      onMouseDown={() => {
                        setIsAddUserPageVisible(true);
                        setMode("Add user");
                      }}
                    />
                  </>
                )}
                {checkIsPreviousPhase ? <h3>Please note that users cannot be added to a completed phase.</h3> : null}
              </div>
            )}
          {
            !_.isEmpty(userDetails.data) &&
            userDetails.data.searchFlag === "notInDb" &&
            _.isEmpty(userMatch.data.data) &&
            //!_.isEmpty(searchText) &&            
            //isValidUserId &&
            (
              <div className="user-popup">
                <h3>User not found.</h3>
                {checkIsPreviousPhase ? <h3>Please note that users cannot be added to a completed phase.</h3> : null}
                {isValidTimePeriod && !checkIsPreviousPhase && (
                  <>
                    <p>Would you like to add the user?</p>
                    <input
                      type="button"
                      value="Add"
                      onMouseDown={() => {
                        dispatch({
                          type: "SEARCH_USER_DATA_RESET",
                          payload: {},
                        });
                        setIsAddUserPageVisible(true);
                        setMode("Add new user");
                      }}
                    />
                  </>
                )}
              </div>
            )}
          {!_.isEmpty(userDetails.data) &&
            userDetails.data.searchFlag !== "samePhase" &&
            !userDetails.data.exactMatch &&
            userDetails.data.validationStatus === "Submitted" && (
              <div className="user-popup">
                {/* <h3>Try after sometime</h3> */}
                {(isValidTimePeriod && !checkIsPreviousPhase) ? (
                  <>
                    <p>
                      User details are yet to be approved in the previous
                      assessment. Only after completing the review you will be
                      able to perform this action for the searched user.
                    </p>
                  </>
                ) : <>
                  <h3>User not found</h3>
                  {checkIsPreviousPhase ? <h3>Please note that users cannot be added to a completed phase</h3> : null}
                </>}
              </div>
            )}
          {/* {!isEnablePage() && (
            <div className="error-section">
              <span className="redMsg">
                {" "}
                <i className="icon-warning-filled" />
              </span>
              <div className="desc">
                {
                  "Users are being added. It will take some time. You can perform this action after the operation is completed. We appreciate your patience!"
                }
              </div>
            </div>
          )} */}
        </>
      ) : (
        <AddUser
          mode={mode}
          searchText={searchText}
          financialYear={financialYear}
          phase={timePeriod}
          onCancel={() => {
            props.changesInChild(false);
            setIsAddUserPageVisible(false);
          }}
          onUserAdd={onUserAdd}
          onUserUpdate={onUserUpdate}
          onUserDelete={onUserDelete}
          changesInChild={(val) => props.changesInChild(val)}
          hideBtn={userDetails.data.validationStatus === "Submitted" || !isValidTimePeriod || checkIsPreviousPhase}
        />
      )}
      {isPopupVisible && (
        <div className="popup-dialog">
          <div className="dialog">
            <div className="header">
              <h5>Confirmation</h5>
              <span
                className="icon-close-filled icon"
                onClick={() => setIsPopupVisible(false)}
              ></span>
            </div>
            <div className="content">
              <p>Would you like to Delete the user for review? Please note that you will not be able to make any edits once the reviewer responds.</p>
            </div>
            <div className="footer">
              <button
                className="cancel-btn"
                onClick={() => setIsPopupVisible(false)}
              >
                Cancel
              </button>
              <button className="confirm-btn" onClick={onUserDelete}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ManageUser;
