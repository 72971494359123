import React, { useCallback, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppContext from "../../utils/appContext";
import { authProvider, APIscope } from "./../../Authentication/authProvider";
import moment from "moment";
import "./DashBoardFilters.scss";

/* This component is for filtering the peer group avg score for Qualitative Pillars. 
At first load, Region and tenure will selected by default. later, user can remove them but 
atleast any one of the filter in the list should be selected */

const DashBoardFilters = (props) => {
  const { filterOptions, onApplyFilter, isHideFilter=false } = props;
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState([]);
  const myContext = useContext(AppContext);
  const dispatch = useDispatch();
  const userAssesmentStatus = useSelector(
    (state) => state.userAssessmentStatus.data
  );

  const toggleSelectAll = useCallback(() => {
    const isAllOptionsSelected = filterOptions.some(
      (filter) => !filter.isSelected
    );
    if (isAllOptionsSelected) {
      let copyFilterOptions = JSON.parse(JSON.stringify(filterOptions));
      copyFilterOptions[0].isSelected = false;
      setFilters(copyFilterOptions);
    }
  }, [filterOptions]);

  const getCurrentUser = useCallback(() => {
    const ppmdID =
      myContext.activePPMDuserId || sessionStorage.activePPMDuserId;
    const userId = myContext.userId || sessionStorage.userId;
    return ppmdID || userId;
  }, [myContext]);

  const toggleClearAll = useCallback(
    (isSelectAllChecked) => {
      let copyFilterOptions = [...filters];
      copyFilterOptions.forEach((option) => {
        option.isSelected = isSelectAllChecked;
      });
      setFilters(copyFilterOptions);
    },
    [filters]
  );

  const onFilterSelect = useCallback(
    (index) => {
      let copyFilterOptions = [...filters];
      if (index === 0) {
        const isOptionAllSelected = !copyFilterOptions[0].isSelected;
        toggleClearAll(isOptionAllSelected);
      } else {
        copyFilterOptions[index].isSelected =
          !copyFilterOptions[index].isSelected;
      }
      const selectedFilters = copyFilterOptions.filter(
        (filter) => filter.isSelected && filter.option !== "Select all"
      );
      if (selectedFilters.length === copyFilterOptions.length - 1) {
        copyFilterOptions[0].isSelected = true;
      } else {
        copyFilterOptions[0].isSelected = false;
      }
      setFilters(copyFilterOptions);
    },
    [filters, toggleClearAll]
  );

  const isBtnEnabled = useCallback(() => {
    const selectedFilters = filters.filter(
      (filter) => filter.isSelected && filter.option !== "Select all"
    );
    return selectedFilters.length !== 0;
  }, [filters]);

  const applyFilters = useCallback(() => {
    setShowFilter(false);
    onApplyFilter(filters);
  }, [filters, onApplyFilter]);

  const toggleFilters = useCallback(() => {
    setShowFilter(!showFilter);
    toggleSelectAll();
  }, [showFilter, toggleSelectAll]);

  useEffect(() => {
    setFilters(JSON.parse(JSON.stringify(filterOptions)));
    toggleSelectAll();
  }, [filterOptions, toggleSelectAll]);

  useEffect(() => {
    (async function () {
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "GET_USER_ASSESSMENT_STATUS",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
            userId: getCurrentUser(),
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, getCurrentUser, myContext]);

  return (
    <div className="dashboard-filter">
      {userAssesmentStatus.data &&
        userAssesmentStatus.data.assessmentEndDate && (
          <div className="last-updated">
            Last updated on{" "}
            {moment(userAssesmentStatus.data.assessmentEndDate).format(
              "DD-MMM-YYYY"
            )}
          </div>
        )}
      {!isHideFilter &&<div className="filter-icon" onClick={() => toggleFilters()}>
        <span className="icon-filterclear"></span>
      </div>}
      {showFilter && (
        <div className="filter-container">
          {filters &&
            filters.length > 1 &&
            filters.map((filter, index) => (
              <div className="input-group" key={index}>
                <label htmlFor={filter.option}>{filter.option}</label>
                <input
                  type="checkbox"
                  name={filter.option}
                  value={filter.option}
                  checked={filter.isSelected}
                  disabled={filter.isDisabled}
                  onChange={() => onFilterSelect(index)}
                />
              </div>
            ))}
          <div className="btn-container">
            <div
              className={"btn" + (!isBtnEnabled() ? " disable " : "")}
              onClick={() => isBtnEnabled() && toggleClearAll(false)}
            >
              Clear All
            </div>
            <div
              className={"btn" + (!isBtnEnabled() ? " disable " : "")}
              onClick={() => isBtnEnabled() && applyFilters()}
            >
              Apply
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashBoardFilters;
