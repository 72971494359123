import { useCallback, useContext, useEffect, useState } from "react";
import { Route } from "react-router-dom";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import InternalErrorBoundary from "./components/ErrorBoundary";
import * as myConsts from "./constants/constants";
import Home from "./components/Home/Home";
import Survey from "./components/survey/Survey";
import DashBoard from "./components/DashBoard/DashBoard";
import DashBoardGoal from "./components/DashBoard/DashBoardGoal";
import NotValidUser from "./components/NotValidUser/NotValidUser";
import PPMDList from "./components/PPMDList/PPMDList";
import FinalizationGuidance from "./components/DiscussionGuide/FinalizationGuidance";
import DiscussionGuide from "./components/DiscussionGuide/DiscussionGuideList";
import FinalCommentary from "./components/DiscussionGuide/FinalCommentary";
import FinalizationGuidanceSelf from "./components/DiscussionGuide/FinalizationGuidanceSelf";
//import DiscussionGuide2 from "./components/DiscussionGuide/GeneralParameters";
import BookOfBusiness from "./components/BookOfBusiness/BookOfBusiness";
import LandingPage from "./LandingPage";
import Page404 from "./components/NotValidUser/Page404";
import AppContext from "./utils/appContext";
import Info from "./components/Common/Info";
import NoAccess from "./components/NotValidUser/NoAccess";
import { routeCompare } from "./utils/utils";

export const routes = [
  {
    exact: true,
    path: myConsts.routeLandingPage,
    component: LandingPage,
  },
  {
    exact: true,
    path: myConsts.routeHome,
    component: Home,
  },
  {
    path: myConsts.routePPMDHome,
    component: Home,
    permission: ["LeadReviewer", "LeadReviewer-PPMD"],
    isAdmin: true,
  },
  {
    path: myConsts.routeBookOfBusiness,
    component: BookOfBusiness,
    permission: ["PPMD", "LeadReviewer-PPMD", "LeadReviewer"],
    isAdmin: true,
  },
  {
    exact: true,
    path: myConsts.routeAssessment,
    component: Survey,
    permission: ["PPMD", "LeadReviewer-PPMD", "LeadReviewer"],
    isAdmin: true,
  },
  {
    path: myConsts.routeAssessmentSummary,
    component: Survey,
    permission: ["PPMD", "LeadReviewer-PPMD", "LeadReviewer"],
    isAdmin: true,
  },
  {
    exact: true,
    path: myConsts.routeDashBoard,
    component: DashBoard,
    permission: ["PPMD", "LeadReviewer-PPMD", "LeadReviewer"],
    isAdmin: true,
  },
  {
    path: myConsts.routeDashBoardGoal,
    component: DashBoardGoal,
    permission: ["PPMD", "LeadReviewer-PPMD", "LeadReviewer"],
    isAdmin: true,
  },
  {
    path: myConsts.routePpmdProfiles,
    component: PPMDList,
    permission: ["LeadReviewer", "LeadReviewer-PPMD"],
  },
  {
    path: myConsts.routeDiscussionGuide,
    component: DiscussionGuide,
    permission: ["LeadReviewer", "LeadReviewer-PPMD"],
  },
  {
    path: myConsts.routeFinalCommentary,
    component: FinalCommentary,
    permission: ["LeadReviewer", "LeadReviewer-PPMD"],
  },
  {
    path: myConsts.routeSelfDiscussionGuide,
    component: FinalizationGuidanceSelf,
    permission: ["PPMD", "LeadReviewer-PPMD"],
  },
  {
    path: myConsts.routeFinalizationForm,
    component: FinalizationGuidance,
    permission: ["LeadReviewer", "LeadReviewer-PPMD"],
  },
  {
    path: myConsts.routeNotValidUser,
    component: NotValidUser,
  },
];

const AdminRoutes = (props) => {
  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  const [isLoading, setLoading] = useState(true);
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [firstTime, setFirstTime] = useState(true);
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  const userProfile = useSelector((state) => state.userProfile);
  const getCurrentUser = useCallback(() => {
    return myContext.userId || sessionStorage.userId;
  }, [myContext]);
  useEffect(() => {
    if (_.isEmpty(assessmentMetaData.data))
      dispatch({
        type: "GET_ASSESSMENT_META_DATA",
        payload: {
          financialYear: myContext.financialYear,
          userId: getCurrentUser(),
        },
      });
    if (_.isEmpty(userProfile.data))
      dispatch({
        type: "GET_USER_PROFILE_BY_ID",
        payload: {
          userId: myContext.userId || sessionStorage.userId,
          financialYear: myContext.financialYear,
        },
      });
  }, []);

  useEffect(() => {
    if (firstTime) {
      setLoading(
        assessmentMetaData.fetching ||
          _.isEmpty(assessmentMetaData.data) ||
          userProfile.fetching ||
          _.isEmpty(userProfile.data)
      );
      if (
        !assessmentMetaData.fetching &&
        !_.isEmpty(assessmentMetaData.data) &&
        !userProfile.fetching &&
        !_.isEmpty(userProfile.data)
      )
        setFirstTime(false);
    }
    if (
      assessmentMetaData &&
      assessmentMetaData.data &&
      assessmentMetaData.data.data
    ) {
      const data = assessmentMetaData.data.data;
      const activePhase =
        data.activePhaseYear == myContext.financialYear
          ? data.activePhase
          : null;
      if (sessionStorage.getItem("isValidUser") === null) {
        sessionStorage.setItem("isValidUser", data.validUser);
        myContext.setIsValidUser(data.validUser);
        if (!data.validUser) {
          props.history.push(myConsts.routeNotValidUser);
        }
      }
      if (
        activePhase === null ||
        (data.assessmentMetaData &&
          data.assessmentMetaData.leadReviewer_CompletionDate &&
          new Date() >
            new Date(data.assessmentMetaData.leadReviewer_CompletionDate)) ||
        (myContext.currentPeriod !== null &&
          activePhase !== null &&
          activePhase !== myContext.currentPeriod)
      ) {
        sessionStorage.setItem("activePhase", activePhase);
        myContext.setActivePhase(activePhase);
        sessionStorage.setItem("formsInActive", true);
        myContext.setFormsInActive(true);
        if (
          activePhase === null ||
          (data.assessmentMetaData &&
            data.assessmentMetaData.leadReviewer_CompletionDate &&
            new Date() >
              new Date(data.assessmentMetaData.leadReviewer_CompletionDate))
        ) {
          dispatch({
            type: "GET_TIME_PERIOD_STATUS",
            payload: { financialYear: myContext.financialYear },
          });
        }
      } else {
        if (sessionStorage.getItem("activePeriod") === null) {
          sessionStorage.setItem("activePeriod", activePhase);
        }

        sessionStorage.setItem("activePhase", activePhase);
        myContext.setActivePhase(activePhase);
        if (
          sessionStorage.getItem("currentPeriod") === null ||
          !myContext.currentPeriod
        ) {
          sessionStorage.setItem("currentPeriod", activePhase);
          myContext.setCurrentPeriod(activePhase);
        }

        sessionStorage.setItem("formsInActive", false);
        myContext.setFormsInActive(false);
        //setLoader(false);
        myContext.setActivePeriod(activePhase);
      }
      myContext.setUserRole(data.userRole); //data.userRole,LeadReviewer-PPMD
    }
    if (userProfile && userProfile.data && !userProfile.fetching) {
      setFilteredRoutes(filteredRoutesFn());
    }
  }, [assessmentMetaData, userProfile]);

  useEffect(() => {
    if (
      (sessionStorage &&
        userProfile.data[0] &&
        sessionStorage.userId &&
        sessionStorage.userId === userProfile.data[0].userId) ||
      (_.isEmpty(sessionStorage.userId) && userProfile.data[0])
    ) {
      sessionStorage.setItem(
        "userPermission",
        JSON.stringify({
          isAdmin: userProfile.data[0].isAdmin,
          role: userProfile.data[0].role,
        })
      );
    }
  }, [userProfile]);

  const filteredRoutesFn = () =>
    routes.filter((route) => {
      if (myContext.isRouteFromAdmin) return true;
      if (!route.permission) return true;
      if (!Array.isArray(route.permission)) return true;
      if (!Array.isArray(userProfile.data) || !userProfile.data[0]) return true;
      if (sessionStorage.userId !== userProfile.data[0].userId) return true;
      if (route.isAdmin && userProfile.data[0].isAdmin) return true;
      const access = route.permission.findIndex(
        (role) => role === userProfile.data[0].role
      );
      if (access > -1) return true;
      return false;
    });

  return (
    <>
      {isLoading ? (
        <>
          <Route exact path="/" render={() => <Info />} />
          <Route exact path="/Home" render={() => <Info />} />
          <Route exact path="/LandingPage" render={() => <Info />} />
        </>
      ) : (
        <>
          {filteredRoutes.map((route) => {
            const { path, exact = false, component } = route;
            return (
              <InternalErrorBoundary type={path}>
                <Route exact={exact} path={path} component={component} />
              </InternalErrorBoundary>
            );
          })}
          <Route
            render={(props) => {
              const path = props?.location?.pathname;
              const isRouteConfigured = routes.findIndex((route) => {
                return routeCompare(route.path, path);
              });
              return (
                <>
                  {isRouteConfigured > -1 ? (
                    <NoAccess {...props} />
                  ) : (
                    <Page404 {...props} />
                  )}
                </>
              );
            }}
          />
        </>
      )}
    </>
  );
};

export default AdminRoutes;
