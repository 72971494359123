import React from "react";
import "./FilterComponent.scss";
const FilterComponent = (props) => {
  return (
    <div className="filter-container">
      {props.filterList &&
        props.filterList.length > 1 &&
        props.filterList.map((filter, index) => (
          <div className="input-group" key={index}>
            <input
              type="checkbox"
              name={filter.name}
              value={filter.name}
              checked={filter.isSelected}
              onChange={() => {
                let temp = props.filterList;
                if (filter.name === "Select All") {
                  let allflag = filter.isSelected;
                  temp.map((i) => {
                    i.isSelected = !allflag;
                  });

                  props.setFilterList(props.type, temp);
                } else {
                  temp[index].isSelected = !temp[index].isSelected;
                  if (!temp[index].isSelected) {
                    if (temp.filter((i) => i.isSelected).length !== temp.length)
                      temp[0].isSelected = false;
                  } else {
                    if (
                      temp.filter((i) => i.isSelected).length ==
                      temp.length - 1
                    )
                      temp[0].isSelected = true;
                  }
                  props.setFilterList(props.type, temp);
                }
              }}
            />
            <label htmlFor={filter.name}>
              {filter === "All Regions"
                ? "Select All"
                : filter.name === "Completed"
                ? "Approved"
                : filter.name === "Submitted" &&
                  props.label === "Lead Reviewer Approvals"
                ? "Pending Approval"
                : filter.name}
            </label>
          </div>
        ))}
    </div>
  );
};
export default FilterComponent;
