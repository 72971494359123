import React, { useState, useEffect, useContext, useMemo, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppContext from "../../../utils/appContext";
import DropdownComponent from "../AdminGeneralParam/DropdownComponent/DropdownComponent";
import ReviewConfirmation  from "../Approvals/ReviewConfimation/ReviewConfirmation";
import { toast } from "react-toastify";
import { Flip } from "react-toastify";
import { authProvider, APIscope } from "./../../../Authentication/authProvider";
import AdminAddPopup from "./AdminPopup/AdminAddPopup";
import LongCardListing from "./LongCardListingComponent/LongCardListing";
import Globalmetric from "./GlobalMetric/Globalmetric";
import * as myConsts from "../../../constants/constants";
import _ from 'lodash'
import Loader from "../../Loader/Loader";
// import * as myConsts from "../../constants/constants";
import "./AssessmentAdmin.scss";
import {
  EDIT_GENERAL_SECTION_RESET,
  GET_GENERAL_SECTION,
} from "../../../actions/types";
import AssessmentPopup from "../Assessment/AssessmentPopup/AssessmentPopup";
import { getFinancialYears } from "../../../utils/utils";

/* This is the  component for adding or editing assessment pillars and goals */

const AssessmentAdmin = (props) => {
  const myContext = useContext(AppContext);
  const crumbs = [{ name: "Admin", path: "/Admin" }];
  const [activeTab, setActiveTab] = useState(0);
  const [formType, setFormType] = useState(0);
  const [fyYear, setFyYear] = useState("FY " + myContext.financialYear);
  const [assessmentData, setAssessmentData] = useState([]);
  const [sectionData, setSectionData] = useState([]);
  const [calllType, setCalllType] = useState("normal");
  const [uniquelist, setuniquelist] = useState({
    sectionName: [],
    sectionColor: [],
  });
  const [prevAssessmentData, setPrevAssessmentData] = useState([]);
  const [viewAddNewGlobalMetric, setViewAddNewGlobalMetric] = useState(false);
  const [editGlobalMetric, setEditGlobalMetric] = useState(false);
  const [isPreviewEnable, setIsPreviewEnable] = useState(false);
  const [activeGoal, setActiveGoal] = useState(-1);
  const [showPopup, setShowPopup] = useState(false);
  const [disableFields, setDisableFields] = useState(false);
  const dispatch = useDispatch();
  // const [searchText, setSearchText] = useState("");
  const [iconName, setIcon] = useState("");

  const [loader, setLoader] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    fyYear: "",
  });
  const allAssesmentData = useSelector((state) => state.allAssesmentData);
  const approvalPendingYears = useSelector((state) => state.getAssessmentSubmissionStatus.pendingYears);
  const activePhaseYear = useSelector((state) => state.getValidationAssesmentMetaData.activeYear);
  const getGeneralSection =
    useSelector((state) => state.getGeneralSection) || {};
  const editGeneralSection =
    useSelector((state) => state.editGeneralSection) || {};
  const { data: getGeneralSectionDat } = getGeneralSection || {};
  const { data: generalSectionList = [] } = getGeneralSectionDat;
  const allSectionDataFetch = useSelector(
    (state) => state.getAssessmentSectionList
  );
  const allSectionData = allSectionDataFetch
    ? allSectionDataFetch.data
      ? allSectionDataFetch.data.data
        ? allSectionDataFetch.data.data.assessmentDetails
        : []
      : []
    : [];
  const deleteGlobalMetricdata = useSelector(
    (state) => state.deleteGlobalMetric
  );
  const addGlobalMetricdata = useSelector((state) => state.addGlobalMetric);
  const editGlobalMetricdata = useSelector((state) => state.editGlobalMetric);
  const submitAdminAssessment = useSelector(
    (state) => state.submitAdminAssessment
  );
  const reOrderGlobalMetricdata = useSelector(
    (state) => state.reOrderGlobalMetric
  );
  const reOrderAssessmentSectionData = useSelector(
    (state) => state.reOrderAssessmentSection
  );
  const deleteSection = useSelector(
    (state) => state.deleteAssessmentSectionstate
  );
  const addOrEditSection = useSelector(
    (state) => state.editAssessmentSectionstate
  );
  const allPrevAssesmentData = useSelector(
    (state) => state.getAllPrevAssesmentData
  );
  const getInvalidAssessmentData = useSelector(
    (state) => state.getInvalidAssessmentData
  );
  const [anchor, setanchor] = React.useState(null);
  const openpopUp = Boolean(anchor);

  const assessmentStatus = useSelector(
    (state) => state.getAssessmentStatus.data
  );

  const submitAdminReview = useSelector((state) => state.submitAdminReview);


  const isProceedAction = useMemo(() => {
    return _.isEmpty(assessmentStatus) || assessmentStatus.proceedAction;
  }, [assessmentStatus]);

  const usePreviousEffect = (fn, inputs = []) => {
    const previousInputsRef = useRef([...inputs])

    useEffect(() => {
      fn(previousInputsRef.current)
      previousInputsRef.current = [...inputs]
    }, [fn, inputs])
  }

  let Data = [
    "Add New Goals",
    "Add New Section",
    "What you want to copy?",
    "Select Icon",
    "Display Order",
    "Display Order",
  ];

    useEffect(() => {
    if (viewAddNewGlobalMetric)
      props.setViewAddNewGlobalMetric(viewAddNewGlobalMetric, {
        ...assessmentData[activeGoal],
        enable: assessmentData.length > 0,
        isPreviewEnable,
      });
    else
      props.setViewAddNewGlobalMetric(viewAddNewGlobalMetric, {
        financialYear: fyYear.replace("FY ", ""),
        enable: assessmentData.length > 0,
        isPreviewEnable,
      });
  }, [viewAddNewGlobalMetric, activeGoal, fyYear, assessmentData]);

  useEffect(() => {
    if (!viewAddNewGlobalMetric){
      getAllAssesmentData(
        myContext.currentPeriod,
        fyYear.replace("FY ", ""),
        "normal"
      );
      setIsPreviewEnable(false);
    }
  }, [viewAddNewGlobalMetric]);

  useEffect(() => {
    if (props.backToListing) {
      setViewAddNewGlobalMetric(false);
      setCalllType("normal");
      props.revertFlag();
    }
  }, [props]);

  useEffect(() => {
    const parsedYear = Number.parseInt(fyYear.replace("FY ", ""));
    if(parsedYear) {
      props.passSelectedYear(parsedYear);
    }
    if (approvalPendingYears.includes(parsedYear) || isApprovalPreview || parsedYear <= activePhaseYear|| parsedYear <= myContext.financialYear ) {
      setDisableFields(true);
    } else setDisableFields(false);
  }, [fyYear, approvalPendingYears, activePhaseYear]);

  const triggerLock = () => {
    myContext.setIsLockPopup(true);
    toast.warn(
      <div>
        <i className="icon-warning-outline" />
        {/* "Section Updated Successfully" : */}Warning: Assessment content is
        being added by Sudeep Jayaprakash. It will take some time. You can
        perform actions in this section once that operation is completed. We
        appreciate your patience!
      </div>,
      {
        position: "top-center",
        transition: Flip,
        // autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  useEffect(() => {
    if (
      submitAdminAssessment &&
      submitAdminAssessment.data &&
      submitAdminAssessment.fetching
    ) {
      setLoader(true);
    } else if (
      submitAdminAssessment &&
      submitAdminAssessment.data &&
      !submitAdminAssessment.fetching &&
      submitAdminAssessment.data &&
      submitAdminAssessment.data.message
    ) {
      setLoader(false);
    }
  }, [submitAdminAssessment]);

  useEffect(() => {
    if (
      addGlobalMetricdata &&
      addGlobalMetricdata.data &&
      addGlobalMetricdata.fetching
    ) {
      setLoader(true);
    } else if (
      addGlobalMetricdata &&
      addGlobalMetricdata.data &&
      !addGlobalMetricdata.fetching &&
      addGlobalMetricdata.data &&
      addGlobalMetricdata.data.status &&
      addGlobalMetricdata.data.status === "success"
    ) {
      dispatch({
        type: "ADD_GLOBAL_METRIC_RESET",
        payload: {},
      });
      props.getAssessmentApprovalList();
      myContext.setIsLockPopup(false);
      myContext.setIsWarningPopup(false);
      toast.warn(
        <div>
          <i className="icon-check-outline" />
          {/* "Section Updated Successfully" : */}Global metric added
          successfully
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      getAllAssesmentData(
        myContext.currentPeriod,
        fyYear.replace("FY ", ""),
        "add"
      );
    }
    // eslint-disable-next-line
  }, [addGlobalMetricdata]);

  useEffect(() => {
    if (
      editGlobalMetricdata &&
      editGlobalMetricdata.data &&
      !editGlobalMetricdata.fetching &&
      editGlobalMetricdata.data &&
      editGlobalMetricdata.data.message &&
      editGlobalMetricdata.data.message === "success"
    ) {
      dispatch({
        type: "EDIT_GLOBAL_METRIC_RESET",
        payload: {},
      });
      props.getAssessmentApprovalList();
      myContext.setIsLockPopup(false);
      myContext.setIsWarningPopup(false);
      toast.warn(
        <div>
          <i className="icon-check-outline" />
          {/* "Section Updated Successfully" : */}Global metric updated
          successfully
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      getAllAssesmentData(
        myContext.currentPeriod,
        fyYear.replace("FY ", ""),
        "edit"
      );
    }
    // eslint-disable-next-line
  }, [editGlobalMetricdata]);
  useEffect(() => {
    if (
      editGeneralSection &&
      editGeneralSection.data &&
      editGeneralSection.fetching
    ) {
      setLoader(true);
    } else if (
      editGeneralSection &&
      editGeneralSection.data &&
      !editGeneralSection.fetching &&
      editGeneralSection.data &&
      editGeneralSection.data.message &&
      editGeneralSection.data.message === "successfully Updated"
    ) {
      setLoader(false);
      dispatch({
        type: EDIT_GENERAL_SECTION_RESET,
        payload: {},
      });
      props.getAssessmentApprovalList();
      myContext.setIsLockPopup(false);
      myContext.setIsWarningPopup(false);
      toast.warn(
        <div>
          <i className="icon-check-outline" />
          {/* "Section Updated Successfully" : */}Section updated successfully
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      dispatch({
        type: GET_GENERAL_SECTION,
        payload: {
          financialYear: fyYear.replace("FY ", ""),
        },
      });
    }
    // eslint-disable-next-line
  }, [editGeneralSection]);

  useEffect(() => {
    if (
      addOrEditSection &&
      addOrEditSection.data &&
      !addOrEditSection.fetching &&
      (addOrEditSection.data === "data updated successfully" ||
        addOrEditSection.data === "data is created successfuly")
    ) {
      dispatch({
        type: "EDIT_ASSESSMENT_SECTION_RESET",
        payload: {},
      });
      props.getAssessmentApprovalList();
      myContext.setIsLockPopup(false);
      myContext.setIsWarningPopup(false);
      toast.warn(
        <div>
          <i className="icon-check-outline" />
          {addOrEditSection.data === "data updated successfully"
            ? "Section Updated Successfully"
            : "Section Added Successfully"}
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      getAllAssesmentData(
        myContext.currentPeriod,
        fyYear.replace("FY ", ""),
        "normal"
      );
    }
    // eslint-disable-next-line
  }, [addOrEditSection]);

  useEffect(() => {
    if (
      reOrderGlobalMetricdata &&
      reOrderGlobalMetricdata.data &&
      !reOrderGlobalMetricdata.fetching &&
      reOrderGlobalMetricdata.data.message &&
      reOrderGlobalMetricdata.data.message === "success"
    ) {
      dispatch({
        type: "REORDER_GLOBAL_METRIC_RESET",
        payload: {},
      });
      props.getAssessmentApprovalList();
      myContext.setIsLockPopup(false);
      myContext.setIsWarningPopup(false);
      toast.warn(
        <div>
          <i className="icon-check-outline" />
          {"Order changed successfully"}
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      getAllAssesmentData(
        myContext.currentPeriod,
        fyYear.replace("FY ", ""),
        "normal"
      );
    } else if (
      reOrderGlobalMetricdata &&
      reOrderGlobalMetricdata.data &&
      reOrderGlobalMetricdata.fetching
    ) {
      setLoader(true);
    }
    // eslint-disable-next-line
  }, [reOrderGlobalMetricdata]);
  useEffect(() => {
    if (
      reOrderAssessmentSectionData &&
      reOrderAssessmentSectionData.data &&
      !reOrderAssessmentSectionData.fetching &&
      reOrderAssessmentSectionData.data.message &&
      reOrderAssessmentSectionData.data.message === "success"
    ) {
      dispatch({
        type: "REORDER_ASSESSMENT_SECTION_RESET",
        payload: {},
      });
      props.getAssessmentApprovalList();
      myContext.setIsLockPopup(false);
      myContext.setIsWarningPopup(false);
      toast.warn(
        <div>
          <i className="icon-check-outline" />
          {"Order changed successfully"}
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      getAllAssesmentData(
        myContext.currentPeriod,
        fyYear.replace("FY ", ""),
        "normal"
      );
    } else if (
      reOrderAssessmentSectionData &&
      reOrderAssessmentSectionData.data &&
      reOrderAssessmentSectionData.fetching
    ) {
      setLoader(true);
    }
    // eslint-disable-next-line
  }, [reOrderAssessmentSectionData]);

  useEffect(() => {
    // console.log(deleteSection &&
    //   deleteSection.data &&
    //   !deleteSection.fetching &&
    //   deleteSection.data.message === "Section deleted successfully")
    if (
      deleteSection &&
      deleteSection.data &&
      !deleteSection.fetching &&
      deleteSection.data.message === "Section deleted successfully"
    ) {
      dispatch({
        type: "DELETE_ASSESSMENT_SECTION_RESET",
        payload: {},
      });
      props.getAssessmentApprovalList();
      myContext.setIsLockPopup(false);
      myContext.setIsWarningPopup(false);
      toast.warn(
        <div>
          {" "}
          <i className="icon-check-outline" />
          Section Deleted Successfully
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      getAllAssesmentData(
        myContext.currentPeriod,
        fyYear.replace("FY ", ""),
        "normal"
      );
    } else if (
      deleteSection &&
      deleteSection.data &&
      deleteSection.data.status &&
      deleteSection.data.status === "BLOCKING_OPERATION"
    ) {
      setLoader(false);
      dispatch({
        type: "DELETE_ASSESSMENT_SECTION_RESET",
        payload: {},
      });
      props.getAssessmentApprovalList();
      myContext.setIsLockPopup(false);
      myContext.setIsWarningPopup(false);
      toast.warn(
        <div>
          {" "}
          <i className="icon-warning-outline" />
          You will able to delete this section only after moving the impact
          statement mapped within it to any other section. Please move the
          statement and try again
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
    // eslint-disable-next-line
  }, [deleteSection]);

  useEffect(() => {
    if (
      deleteGlobalMetricdata &&
      deleteGlobalMetricdata.data &&
      !deleteGlobalMetricdata.fetching &&
      deleteGlobalMetricdata.data &&
      deleteGlobalMetricdata.data.message &&
      deleteGlobalMetricdata.data.message === "success"
    ) {
      dispatch({
        type: "DELETE_GLOBAL_METRIC_RESET",
        payload: {},
      });
      props.getAssessmentApprovalList();
      myContext.setIsLockPopup(false);
      myContext.setIsWarningPopup(false);
      toast.warn(
        <div>
          {" "}
          <i className="icon-check-outline" />
          Global metric deleted successfully
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      getAllAssesmentData(
        myContext.currentPeriod,
        fyYear.replace("FY ", ""),
        "normal"
      );
    } else if (
      deleteGlobalMetricdata &&
      deleteGlobalMetricdata.data &&
      deleteGlobalMetricdata.data.messageCode &&
      deleteGlobalMetricdata.data.messageCode === "MSG_280"
    ) {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [deleteGlobalMetricdata]);

  useEffect(() => {
    if (
      allAssesmentData &&
      allAssesmentData.data &&
      allAssesmentData.data.status === "success"
    ) {
      const {
        data: { data },
      } = { ...allAssesmentData };
      const assessmentDetails = data.assessmentDetails;
      setAssessmentData(assessmentDetails);
      setLoader(false);
      if (calllType == "add") {
        // setViewAddNewGlobalMetric(false);
        setActiveGoal(assessmentDetails.length - 1);
        setEditGlobalMetric(false);
        setIsPreviewEnable(false);
        // setViewAddNewGlobalMetric(true);
      } else if (calllType == "edit") {
        setEditGlobalMetric(false);
        setIsPreviewEnable(false);
        // setViewAddNewGlobalMetric(true);
      }
    } else if (
      allAssesmentData &&
      allAssesmentData.data &&
      allAssesmentData.data.messageCode &&
      allAssesmentData.data.messageCode === "MSG_280"
    ) {
      setAssessmentData([]);
      setLoader(false);
    }
  }, [allAssesmentData]);

  useEffect(() => {
    if (
      getInvalidAssessmentData &&
      getInvalidAssessmentData.data &&
      !getInvalidAssessmentData.fetching &&
      getInvalidAssessmentData.data.inValidGlobalMetrics
    ) {
      props.passInavlidDataList({
        data: getInvalidAssessmentData.data,
        year: fyYear.replace("FY ", ""),
      });
    }
  }, [getInvalidAssessmentData]);

  useEffect(() => {
    if (
      allSectionDataFetch &&
      allSectionDataFetch.data &&
      !allSectionDataFetch.fetching
    ) {
      if (allSectionData && allSectionData.length > 0) {
        const sectionData = [...allSectionData];
        setSectionData([...sectionData]);
        let tempname = [];
        let tempColor = [];
        sectionData.forEach((item) => {
          tempname.push(item.name);
          tempColor.push(item.color);
        });
        setuniquelist({
          sectionName: [...tempname],
          color: [...tempColor],
        });
      } else {
        setSectionData([]);
        setuniquelist({
          sectionName: [],
          color: [],
        });
      }
      setLoader(false);
    } else if (
      allSectionData &&
      allSectionData.data &&
      allSectionData.data.messageCode &&
      allSectionData.data.messageCode === "MSG_280"
    ) {
      setSectionData([]);
      setLoader(false);
    }
  }, [allSectionDataFetch]);

  useEffect(() => {
    if (
      allPrevAssesmentData &&
      allPrevAssesmentData.data &&
      allPrevAssesmentData.data.status === "success"
    ) {
      const {
        data: { data },
      } = { ...allPrevAssesmentData };
      const assessmentDetails = data.assessmentDetails;
      setPrevAssessmentData(assessmentDetails);
      setLoader(false);
    } else if (
      allPrevAssesmentData &&
      allPrevAssesmentData.data &&
      allPrevAssesmentData.data.messageCode &&
      allPrevAssesmentData.data.messageCode === "MSG_280"
    ) {
      setPrevAssessmentData([]);
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [allPrevAssesmentData]);

  useEffect(() => {
    props.onChildChange(false);
    dispatch({
      type: "GET_ASSESSMENT_APPROVAL_LIST",
      payload: {
        type: "Assessment"
      },
    });
    dispatch({
      type: "GET_ALL_ASSESSMENT_DATA_RESET",
    });
    getAllAssesmentData(
      myContext.currentPeriod,
      myContext.financialYear,
      "normal"
    );
    // eslint-disable-next-line
  }, []);

  usePreviousEffect(
    ([prevAssessmentStatus]) => {
      if (prevAssessmentStatus !== assessmentStatus && prevAssessmentStatus && !prevAssessmentStatus.proceedAction&& assessmentStatus && assessmentStatus.proceedAction) {
        getAllAssesmentData(
          myContext.currentPeriod,
          myContext.financialYear,
          "normal"
        );
      }
    },
    [assessmentStatus]
  )

  useEffect(() => {
    if (!viewAddNewGlobalMetric)
      getAllAssesmentData(
        myContext.currentPeriod,

        fyYear.replace("FY ", ""),

        "normal"
      );
  }, [viewAddNewGlobalMetric]);

  const selectOnChange = (dummy, value) => {
    setFyYear(value);
    getAllAssesmentData(
      myContext.currentPeriod,
      value.replace("FY ", ""),
      "normal"
    );
  };
  const clickAwayHandler = () => {
    setanchor(null);
  };
  const deleteCard = async (type, id, fyYearSection) => {
    if (type === "section") {
      setLoader(true);
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "DELETE_ASSESSMENT_SECTION",
          payload: {
            sectionName: id,
            financialYear: fyYearSection,
          },
        });
      } catch (e) {
        console.error(e);
      }
    } else if (type === "global metric") {
      dispatch({
        type: "DELETE_GLOBAL_METRIC",
        payload: {
          pillarId: id,
          financialYear: fyYear.replace("FY ", ""),
        },
      });
    }
  };

  const getAllAssesmentData = async (currentPeriod, financialYear, type) => {
    if (isProceedAction && !allAssesmentData.fetching) {
      setLoader(true);
      setCalllType(type);
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "GET_ALL_ASSESSMENT_DATA",
          payload: {
            token: idToken,

            activePeriod: "Plan",
            financialYear: financialYear,
          },
        });
        dispatch({
          type: "GET_ASSESSMENT_SECTION_LIST",
          payload: {
            token: idToken,

            financialYear: financialYear,
          },
        });
        dispatch({
          type: GET_GENERAL_SECTION,
          payload: {
            financialYear: financialYear,
          },
        });

        dispatch({
          type: "GET_INVALID_ASSESSMENT_DATA",
          payload: {
            financialYear: financialYear,
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
  };
  const routeToAdmin = () => {
    props.history.push(myConsts.routeAdmin);
  };
  const getPrevAssesmentData = async (currentPeriod, financialYear) => {
    setLoader(true);
    try {
      const token = await authProvider.acquireTokenSilent({
        scopes: APIscope,
      });
      const idToken = token.accessToken;
      dispatch({
        type: "GET_ALL_PREV_ASSESSMENT_DATA",
        payload: {
          token: idToken,

          activePeriod: currentPeriod,
          financialYear: financialYear,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  const updateGoalName = (goalName, selectedPillarIndex, selectedGoalIndex) => {
    let tempAssessmentData = [...assessmentData];
    let tempSection = tempAssessmentData[selectedPillarIndex]
      ? tempAssessmentData[selectedPillarIndex].section
      : [];
    tempSection[selectedGoalIndex] = {
      ...tempSection[selectedGoalIndex],
      goalName,
    };
    tempAssessmentData[selectedPillarIndex] = {
      ...tempAssessmentData[selectedPillarIndex],
      section: tempSection,
    };
    setAssessmentData(tempAssessmentData);
  };


  const [isApprovalPreview, setIsApprovalPreview] = useState(false);
  const [showReviewConfirmation, setShowReviewConfirmation] = useState(false);
  const adminApprovalData = useSelector((state) => state.approvalData);
  const adminAppState = useSelector((state) => state.adminAppState);

  useEffect(() => {
    const type = _.get(adminApprovalData, "previewParams.type", "");
    if (type === "Assessment") {
      const isPreview = _.get(adminApprovalData, "isPreview", false);
      setIsApprovalPreview(isPreview);
      if(isPreview) {
        const year = 'FY ' +  _.get(adminApprovalData,"previewParams.financialYear", myContext.financialYear);
        selectOnChange({}, year);
        }
    } 
 }, [adminApprovalData])

  useEffect(() => {
    // setShowSubmissionConfirmation(_.get(adminAppState, "submitClicked",false)); 
    setShowReviewConfirmation(_.get(adminAppState, "respondClicked",false))
    // eslint-disable-next-line
  }, [adminAppState])

  const dismissReviewWindow = () => {
    dispatch({
      type: "RESPOND_BUTTON_CLICKED_RESET",
      payload: {},
    });
  }

  const submitReviewResponse = (reviewResponse) => {
    dismissReviewWindow();
    setLoader(true);
    const payload = {
        type: "Assessment",
        financialYear: adminApprovalData.previewParams.financialYear,
        validatedBy: {
            userId: myContext.userId,
            name: myContext.displayName,
        },
        validationStatus: reviewResponse
      }
      dispatch({
        type: "SEND_ADMIN_REVIEW",
        payload
      });
  }

  useEffect(() => {
    if(submitAdminReview && !submitAdminReview.fetching && submitAdminReview.data.message === "success") {
      const flag = _.get(submitAdminReview, "reviewState.validationStatus", "" );
      const type = _.get(submitAdminReview, "reviewState.type" , "");
      if(type === "Assessment") {
        successNotify(flag === "Approved");
        dispatch({
          type: "SEND_ADMIN_REVIEW_RESET",
          payload: {type: "GeneralParameters"},
        });
        setTimeout(() => {props.history.push(myConsts.routeAdminApprovals);} , 1000)
      }
    }
  }, [submitAdminReview])

  const successNotify = useCallback((isApprove) => {
    toast(
      <div className="success-text">
        <span className="icon-verified-outline icon"></span>
        <p>
          <span></span>
          <span>{isApprove ? "Successfully approved the request" : "Successfully rejected the request"}</span>
        </p>
      </div>,
      {
        position: "bottom-center",
        transition: Flip,
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  return (
    <div className="assesment-admin-container ">
      <AssessmentPopup />
      {loader && <Loader />}
      {/*<div className="admin-info-main-sidemenu">
        <SideMenu
          minimized={true}
          isminimizedMainMenu={true}
          isMainMenu={true}
          sideMenuItemSelect="Admin"
        />
  </div>*/}
      <div className="admin-content">
        {/*<div className="second-header admin-crumbs">
          <i
            className="icon-arrow-left"
            onClick={(e) => {
              e.preventDefault();
              if (viewAddNewGlobalMetric) {
                setViewAddNewGlobalMetric(false);
              } else routeToAdmin();
            }}
          />
          <div className="admin-crumb-txt">
            <Breadcrumb
              crumbs={crumbs}
              isAdmin={true}
              isInnerAdmin={true}
              {...props}
            />
            <h4>Assessment</h4>
          </div>
          </div>*/}
        {!viewAddNewGlobalMetric ? (
          <>
            <div className="third-header admin-crumbs ">
              <div className="tabSection">
                <div
                  className={
                    "  tabStyling" + (activeTab === 0 ? " selected-tab " : "")
                  }
                  onClick={() => {
                    setActiveTab(0);
                    props.setActiveTab(0);
                  }}>
                  Global Metrics
                </div>
                <div
                  className={
                    "tabStyling" + (activeTab === 1 ? " selected-tab " : "")
                  }
                  onClick={() => {
                    setActiveTab(1);
                    props.setActiveTab(1);
                  }}>
                  Sections
                </div>
                { !isApprovalPreview ? <div class="date-div">
                  <DropdownComponent
                    label="Select Year"
                    defaultValue={fyYear}
                    required={true}
                    selectOnChange={selectOnChange}
                    varName="fyYear"
                    list={getFinancialYears(myContext.financialYear)}
                    disabled={!isProceedAction}
                    errorMessage={errorMessages}
                  />
                </div> : null}
              </div>
            </div>
            <div spacing={2} className="card-container">
              {!isApprovalPreview && !disableFields && (
                <div className="searchDiv">
                  {activeTab === 0 ? (
                    <>
                      {/*   {true ? (
                      <i
                        className="icon-more-v"
                        onClick={(e) => {
                          e.stopPropagation();
                          setanchor(e.currentTarget);
                        }}
                      />
                    ) : null}{" "} */}
                      <div
                        className={
                          assessmentData.length < 7
                            ? "yellow-button"
                            : "disable-sub "
                        }
                        onClick={() => {
                          if (assessmentData.length < 7) {
                            setViewAddNewGlobalMetric(true);
                            setActiveGoal(-1);
                            setEditGlobalMetric(true);
                            setIsPreviewEnable(true);
                            setIcon("");
                          }
                        }}>
                        <i className="icon-plus" /> Add New Global Metric
                      </div> 
                      {/* <div className="search-box">
                      <i className="icon-search search-icon" />
                      <input
                        type="text"
                        placeholder="Search"
                        value={searchText}
                        onChange={(e) => {
                          setSearchText(e.target.value);
                          //   onChangeText(e);
                        }}
                      />
                    </div> */}
                    </>
                  ) : (
                    <div
                      className={
                        sectionData.length < 5
                          ? "yellow-button"
                          : "disable-sub "
                      }
                      onClick={() => {
                        if (sectionData.length < 5) {
                          setShowPopup(true);
                          setFormType(1);
                        }
                      }}>
                      <i className="icon-plus" /> Add New Section
                    </div>
                  )}
                </div>
              )}
              {!isProceedAction ? null : (assessmentData.length === 0 && activeTab === 0) ||
                (sectionData.length === 0 && activeTab === 1) ? (
                <div className="noDataText">
                  {activeTab === 0
                    ? ' Click on "Add New Global Metric" button to start with.'
                    : ' Click on "Add New Section" button to start with.'}
                </div>
              ) : (
                <LongCardListing
                  disableFields={disableFields}
                  assessmentData={
                    activeTab === 0 ? assessmentData : sectionData
                  }
                  generalSectionList={generalSectionList}
                  ChangeOrder={() => {
                    console.log(activeTab);
                    if (activeTab === 0) {
                      setFormType(4);
                      setShowPopup(true);
                    } else if (activeTab === 1) {
                      setFormType(5);
                      setShowPopup(true);
                    }
                  }}
                  setActiveGoal={(index) => {
                    setActiveGoal(index);
                    setEditGlobalMetric(false);
                    setViewAddNewGlobalMetric(true);
                  }}
                  setActiveEditIndex={(index) => {
                    setActiveGoal(index);
                    setEditGlobalMetric(true);
                    setViewAddNewGlobalMetric(true);
                  }}
                  setPreview={(index) => {
                    setIsPreviewEnable(index)
                  }}
                  // isPreviewEnable={(value) => setIsPreviewEnable(value)}
                  tabName={activeTab === 0 ? "globalMetrics" : "sections"}
                  delete={deleteCard}
                  fyYear={fyYear}
                  uniquelist={uniquelist}
                />
              )}
              {!isApprovalPreview && props.lastUpdateInfo && props.lastUpdateInfo.name ? <div className="update-info">
                {`Last updated by: ${props.lastUpdateInfo.name}  |  ${props.lastUpdateInfo.date}`}
              </div> : null }
            </div>
          </>
        ) : (
          <Globalmetric
            calllType={calllType}
            activeGoal={activeGoal}
            disableFields={disableFields}
            editGlobalMetric={editGlobalMetric}
            fyYear={fyYear}
            getAssessmentData={() =>
              getAllAssesmentData(
                myContext.currentPeriod,
                fyYear.replace("FY ", ""),
                "normal"
              )
            }
            getPreviewDsable = {()=>
              setIsPreviewEnable(true)
            }
            closeAddScreen={() => {
              setViewAddNewGlobalMetric(false);
            }}
            StmtTextOnChange={(selectedId, id, value) => {
              let AssessmentArray = [...assessmentData];
              // console.log(
              //   AssessmentArray[activeGoal].section[selectedId].data[id]
              // );
              AssessmentArray[activeGoal].section[selectedId].data[
                id
              ].impactActions = value;
              // console.log(
              //   "after",
              //   AssessmentArray[activeGoal].section[selectedId].data[id]
              // );
              setAssessmentData([...AssessmentArray]);
            }}
            addNewGoal={(name, order, icon) => {
              // setFormType(0);
              // setShowPopup(true);
              if (activeGoal !== -1 && assessmentData[activeGoal]._id !== "") {
                let tempArray = [];
                let temp = assessmentData[activeGoal];
                temp.section.push({
                  data: [], ///impact stmnts
                  goalDescription: "",
                  goalId: "",
                  goalName: "",
                  goalRemark: "",
                  sectionValues: [],
                  uiType: "",
                });
                assessmentData.forEach((item, i) => {
                  if (i === activeGoal) {
                    tempArray.push(temp);
                  } else {
                    tempArray.push(item);
                  }
                });
                setAssessmentData([...tempArray]);
              } else {
                // console.log(assessmentData);
                let tempArray = [...assessmentData];
                tempArray.push({
                  displayOrder: order,
                  financialYear: fyYear,
                  iconUrl: icon,
                  partitionID: "",
                  pillar: name,
                  pillarDisplayName: name,
                  pillarId: "",
                  pillarNotes: "",
                  pillarOverview: "",
                  section: [
                    {
                      data: [], ///impact stmnts
                      goalDescription: "",
                      goalId: "",
                      goalName: "",
                      goalRemark: "",
                      sectionValues: [],
                      uiType: "",
                    },
                  ],
                  timePeriod: "Mid-Year",
                  _id: "",
                });
                setAssessmentData([...tempArray]);
                setActiveGoal(tempArray.length - 1);
              }
            }}
            addIcon={() => {
              setFormType(3);
              setShowPopup(true);
            }}
            assessmentData={assessmentData}
            iconName={iconName}
            setIcon={(value) => setIcon(value)}
            viewAddNewGlobalMetric={(value) => setViewAddNewGlobalMetric(value)}
            updateGoalName={updateGoalName}
            setLoader={setLoader}
            changesInChild={(val) => props.onChildChange(val)}
            onEditCancel = {() => setEditGlobalMetric(false)}
          />
        )}
      </div>

      <AdminAddPopup
        title={Data[formType]}
        showPopup={showPopup}
        formType={formType}
        cancelPopup={() => setShowPopup(false)}
        fyYear={fyYear}
        iconName={iconName}
        getPrevAssesmentData={getPrevAssesmentData}
        prevAssessmentData={prevAssessmentData}
        seticonType={(value) => setIcon(value)}
        uniquelist={uniquelist}
        assessmentData={assessmentData}
        sectionData={sectionData}
        tabName={activeTab === 0 ? "globalMetrics" : "sections"}
      />
      <ReviewConfirmation
          showReviewPopup={showReviewConfirmation}
          dismissPopup={dismissReviewWindow}
          reviewResponse={submitReviewResponse}></ReviewConfirmation> 
    </div>
  );
};
export default AssessmentAdmin;
