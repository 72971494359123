import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
//import ReactExport from "react-data-export";
import moment from "moment";
import AppContext from "../../../utils/appContext";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const ReportDownload = (props) => {
  const { reportApi, currentPeriod, currentYear, isFromBulkUpload } = props;
  // const ExcelFile = ReactExport.ExcelFile;
  // const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const dispatch = useDispatch();
  const workbook = new Excel.Workbook();
  const workSheetName = 'Finalization Form Commentary';
  const excelReportData = useSelector((state) => state.excelReportData);
  const [exportReport, setExportReport] = useState([]);
  const [currentReportHeaders, setCurrentReportHeaders] = useState([]);
  const [currentKey, setCurrentKey] = useState("");
  const [fileName, setFileName] = useState("");
  const myContext = useContext(AppContext);

  const assessmentReport = useMemo(() => {
    return [
      {
        header: "GPN",
        key: "GPN",
        width: 20,
      },
      {
        header: "Partner Name",
        key: "Partner Name",
        width: 30,
      },
      {
        header: "Partner Email",
        key: "Partner Email",
        width: 35,
      },
      {
        header: "Region",
        key: "Region",
        width: 30,
      },
      {
        header: "BU Number",
        key: "BU Number",
        width: 30,
      },
      {
        header: "SMU Code",
        key: "SMU Code",
        width: 30,
      },
      {
        header: "Tenure",
        key: "Tenor",
        width: 10,
      },
      {
        header: "Role",
        key: "Role",
        width: 30,
      },
      {
        header: "Lead Reviewer Name",
        key: "Lead Reviewer Name",
        width: 30,
      },
      {
        header: "Lead Reviewer Email",
        key: "Lead Reviewer Email",
        width: 35,
      },
      {
        header: "Assessment Status",
        key: "Assessment Status",
        width: 30,
      },
      {
        header: "Submitted Date",
        key: "Submitted Date",
        width: 30,
      },
    ];
  }, []);
  //..Discussion Guide
  const discusssionGuideReport = useMemo(() => {
    return [
      // {
      //   header: "Partner Name",
      //   key: "Partner Name",
      //   width: 180,
      // },
      {
        header: "Last Name",
        key: "Last Name",
        width: 30,
      },
      {
        header: "First Name",
        key: "First Name",
        width: 30,
      },
      {
        header: "Partner Email",
        key: "Partner Email",
        width: 35,
      },
      {
        header: "GUI",
        key: "GUI",
        width: 20,
      },
      {
        header: "Office",
        key: "Office",
        width: 30,
      },
      {
        header: "Rank",
        key: "Rank",
        width: 30,
      },
      {
        header: "Region",
        key: "Region",
        width: 30,
      },
      {
        header: "BU Number",
        key: "BU Number",
        width: 30,
      },
      {
        header: "SMU Code",
        key: "SMU Code",
        width: 30,
      },
      {
        header: "Primary Sector",
        key: "Primary Sector",
        width: 30,
      },
      {
        header: "Lead Reviewer Name",
        key: "Lead Reviewer Name",
        width: 30,
      },
      {
        header: "Lead Reviewer GUI",
        key: "Lead Reviewer GUI",
        width: 20,
      },
      {
        header: "Reviewer Submitted Date",
        key: "Reviewer Submitted Date",
        width: 20,
      },
      {
        header: "Reviewer Submitted Status",
        key: "Reviewer Submitted Status",
        width: 20,
      },
      // {
      //   header: "PFC Discussion Question 1\n(Did your PPMD achieve their goals?)",
      //   key: "PFC Discussion Question 1",
      //   width: 70,
      // },
      // {
      //   header: "PFC Discussion Question 2\n(How has the PPMD teamed this year across SLs, SSLs and functions? And how will they accelerate it next year?)",
      //   key: "PFC Discussion Question 2",
      //   width: 70,
      // },
      // {
      //   header: "PFC Discussion Question 3\n(What actions has the PPMD taken to contribute to priorities including diversity, equity and inclusiveness?)",
      //   key: "PFC Discussion Question 3",
      //   width: 70,
      // },
      // {
      //   header: "PFC Discussion Question 4\n(What is the PPMD's capability and aspiration to take on the next complex challenge? What is needed to help them get there?)",
      //   key: "PFC Discussion Question 4",
      //   width: 70,
      // },
      // {
      //   header: "PFC Discussion Question 5\n(Are there any significant performance deficiences that need to be highlighted?)",
      //   key: "PFC Discussion Question 5",
      //   width: 70,
      // },
      // {
      //   header: "Summary of Roles/Areas of Responsibility",
      //   key: "Summary of Roles/Areas of Responsibility",
      //   width: 70,
      // },
      // {
      //   header: "Distinguish Performance",
      //   key: "Distinguish Performance",
      //   width: 250,
      // },
      // {
      //   header: "Development Points",
      //   key: "Development Points",
      //   width: 250,
      // },
      // {
      //   header: "Potential And Aspirations",
      //   key: "Potential And Aspirations",
      //   width: 250,
      // },
      // {
      //   header: "Overall Category",
      //   key: "Overall Category",
      //   width: 180,
      // },
      // {
      //   //header: "Reviewer Audit\n(Please note below information will generate automatically based on comments entered against below columns\nDistinguish Performance, Development Points, Potential And Aspirations & Overall Category)",
      //   header: "DRAFT Finalization Form Commentary",
      //   key: "DRAFT Finalization Wording",
      //   width: 100,
      // },
      {
        header: "FINAL Finalization Form Commentary",
        key: "FINAL Finalization Wording",
        width: 100,
      },
      {
        header: "Is Recorded In SuccessFactors",
        key: "isRecordedInSuccessFactors",
        width: 20,
      },
      {
        header: "Updated By",
        key: "Updated By",
        width: 30,
      },
      {
        header: "Updated Date",
        key: "Updated Date",
        width: 20,
      }
    ];
  }, []);
  //..PPMD Discussion Guide
  const ppmdDiscusssionGuideReport = useMemo(() => {
    return [
      // {
      //   header: "Partner Name",
      //   key: "Partner Name",
      //   width: 180,
      // },
      {
        header: "Last Name",
        key: "Last Name",
        width: 30,
      },
      {
        header: "First Name",
        key: "First Name",
        width: 30,
      },
      {
        header: "Partner Email",
        key: "Partner Email",
        width: 30,
      },
      {
        header: "GUI",
        key: "GUI",
        width: 20,
      },
      {
        header: "Office",
        key: "Office",
        width: 30,
      },
      {
        header: "Rank",
        key: "Rank",
        width: 30,
      },
      {
        header: "Region",
        key: "Region",
        width: 30,
      },
      {
        header: "BU Number",
        key: "BU Number",
        width: 30,
      },
      {
        header: "SMU Code",
        key: "SMU Code",
        width: 30,
      },
      {
        header: "Primary Sector",
        key: "Primary Sector",
        width: 30,
      },
      {
        header: "Lead Reviewer Name",
        key: "Lead Reviewer Name",
        width: 30,
      },
      {
        header: "Lead Reviewer GUI",
        key: "Lead Reviewer GUI",
        width: 20,
      },
      {
        header: "Discussion Question 1",
        key: "Discussion Question 1",
        width: 70,
      },
      {
        header: "Summary of Roles/Areas of Responsibility",
        key: "Summary of Roles/Areas of Responsibility",
        width: 70,
      },
      {
        header: "Revenue/Margin",
        key: "Revenue/Margin",
        width: 70,
      },
      {
        header: "Sales and Pipeline",
        key: "Sales and Pipeline",
        width: 70,
      },
      {
        header: "Quality",
        key: "Quality",
        width: 70,
      },
      {
        header: "Exceptional Client Service",
        key: "Exceptional Client Service",
        width: 70,
      },
      {
        header: "People & Teaming",
        key: "People & Teaming",
        width: 70,
      },
      {
        header: "Summary Comments",
        key: "Draft Finalization Form Comments",
        width: 70,
      },
      // {
      //   header: "Draft Finalization Form Comment 2",
      //   key: "developmentPointsContent",
      //   width: 250,
      // },
      // {
      //   header: "Draft Finalization Form Comment 3",
      //   key: "potentialAndAspirationsContent",
      //   width: 250,
      // },
      {
        header: "Submitted Date",
        key: "Submitted Date",
        width: 20,
      },
      {
        header: "Submitted Status",
        key: "Submitted Status",
        width: 20,
      }
    ];
  }, []);
  //..Bulk Upload Discussion Guide
  const bulkUploaddiscusssionGuideReport = useMemo(() => {
    return [
      // {
      //   header: "Partner Name",
      //   key: "Partner Name",
      //   width: 180,
      // },
      {
        header: "Last Name",
        key: "Last Name",
        width: 30,
      },
      {
        header: "First Name",
        key: "First Name",
        width: 30,
      },
      {
        header: "Partner Email",
        key: "Partner Email",
        width: 35,
      },
      {
        header: "GUI",
        key: "GUI",
        width: 20,
      },
      {
        header: "Office",
        key: "Office",
        width: 30,
      },
      {
        header: "Rank",
        key: "Rank",
        width: 30,
      },
      {
        header: "Region",
        key: "Region",
        width: 30,
      },
      {
        header: "Primary Sector",
        key: "Primary Sector",
        width: 30,
      },
      {
        header: "Lead Reviewer Name",
        key: "Lead Reviewer Name",
        width: 30,
      },
      {
        header: "Lead Reviewer GUI",
        key: "Lead Reviewer GUI",
        width: 20,
      },
      // {
      //   header: "Reviewer Submitted Date",
      //   key: "Reviewer Submitted Date",
      //   width: 20,
      // },
      // {
      //   header: "Reviewer Submitted Status",
      //   key: "Reviewer Submitted Status",
      //   width: 20,
      // },
      // {
      //   //header: "Reviewer Audit\n(Please note below information will generate automatically based on comments entered against below columns\nDistinguish Performance, Development Points, Potential And Aspirations & Overall Category)",
      //   header: "DRAFT Finalization Wording",
      //   key: "DRAFT Finalization Wording",
      //   width: 450,
      // },
      {
        header: "Overall Category",
        key: "Overall Category",
        width: 20,
      },      
      {
        header: "FINAL Finalization Form Commentary",
        key: "FINAL Finalization Wording",
        width: 90,
      }
    ];
  }, []);
  const bulkUploadExcludedList = ["PFC Discussion Question 1",
    "PFC Discussion Question 2",
    "PFC Discussion Question 3",
    "PFC Discussion Question 4",
    "Summary of Roles/Areas of Responsibility",
    "Distinguish Performance",
    "Development Points",
    "Potential And Aspirations",
    //"Overall Category",
    "DRAFT Finalization Form Commentary"]

  const leadReviewerExcludedList = [
    "Distinguish Performance",
    "Development Points",
    "Potential And Aspirations",
    "Overall Category"
  ]

  const bobReport = useCallback(() => {
    return [
      {
        header: "GPN",
        key: "GPN",
        width: 30,
      },
      {
        header: "Partner Name",
        key: "Partner Name",
        width: 30,
      },
      {
        header: "Partner Email",
        key: "Partner Email",
        width: 35,
      },
      {
        header: "Region",
        key: "Region",
        width: 30,
      },
      {
        header: "Tenur",
        key: "Tenor",
        width: 10,
      },
      {
        header: "Role",
        key: "Role",
        width: 30,
      },
      {
        header: "Lead Reviewer Name",
        key: "Lead Reviewer Name",
        width: 30,
      },
      {
        header: "Lead Reviewer Email",
        key: "Lead Reviewer Email",
        width: 35,
      },
      {
        header: "BoB Status",
        key: "BoB Status",
        width: 30,
      },
      {
        header: "updatedOn",
        key: "updatedOn",
        width: 30,
      },
    ];
  }, []);

  const leadReport = useMemo(() => {
    return [
      {
        header: "Lead Reviewer Name",
        key: "Lead Reviewer Name",
        width: 30,
      },
      {
        header: "Lead Reviewer Email",
        key: "Lead Reviewer Email",
        width: 35,
      },
      {
        header: "Partner GPN",
        key: "Partner GPN",
        width: 30,
      },
      {
        header: "Partner Name",
        key: "Partner Name",
        width: 30,
      },
      {
        header: "Partner Email",
        key: "Partner Email",
        width: 35,
      },
      {
        header: "Region",
        key: "Region",
        width: 30,
      },
      {
        header: "BU Number",
        key: "BU Number",
        width: 30,
      },
      {
        header: "SMU Code",
        key: "SMU Code",
        width: 30,
      },
      {
        header: "Tenure",
        key: "Tenor",
        width: 10,
      },
      {
        header: "Role",
        key: "Role",
        width: 30,
      },
      {
        header: "Assessment Status",
        key: "Assessment Status",
        width: 30,
      },
      {
        header: "Submitted Date",
        key: "Submitted Date",
        width: 30,
      },
      // {
      //   header: "BoB Status",
      //   key: "BoB Status",
      //   width: 30,
      // },
      // {
      //   header: "BoB Update Date",
      //   key: "BoB Update Date",
      //   width: 30,
      // },
      {
        header: "Final Status",
        key: "Final Status",
        width: 30,
      },
    ];
  }, []);

  const setCurrentDetails = useCallback(() => {
    //debugger;
    if (reportApi === "getBoBReport") {
      setCurrentReportHeaders(bobReport);
      setCurrentKey("BoBreport");
      setFileName(`LEAD 4D_PPMD_BOB_${moment(new Date()).format("DDMMYYYY")}`);
    } else if (reportApi === "getAssessmentReport") {
      setCurrentReportHeaders(assessmentReport);
      setCurrentKey("AssessmentReport");
      setFileName(
        `LEAD 4D_PPMD_Assessment_${moment(new Date()).format("DDMMYYYY")}`
      );
    } else if (isFromBulkUpload && reportApi === "getDiscussionGuideReport") {
      setCurrentReportHeaders(bulkUploaddiscusssionGuideReport);
      setCurrentKey("DiscussionGuideReport");
      setFileName(
        `${currentYear} Year End Finalization Form Commentary_${moment(new Date()).format("DDMMYYYY")}`
      );
    } else if (reportApi === "getDiscussionGuideReport") {
      setCurrentReportHeaders(discusssionGuideReport);
      setCurrentKey("DiscussionGuideReport");
      setFileName(
        `${currentYear} LEAD Reviewer Year End Finalization Form Commentary_${moment(new Date()).format("DDMMYYYY")}`
      );
    } else if (reportApi === "getPPMDDiscussionGuideReport") {
      setCurrentReportHeaders(ppmdDiscusssionGuideReport);
      setCurrentKey("PPMDDiscussionGuideReport");
      setFileName(
        `${currentYear} Counselee Year End Discussion Guide_${moment(new Date()).format("DDMMYYYY")}`
      );
    } else {
      setCurrentReportHeaders(leadReport);
      setCurrentKey("LeadReport");
      setFileName(
        `LEAD 4D_Reviewer Approval_${moment(new Date()).format("DDMMYYYY")}`
      );
    }
  }, [reportApi, bobReport, assessmentReport, discusssionGuideReport, leadReport]);

  const processDateFormat = useCallback((value, label) => {
    if (label === "Submitted Date" || label === "updatedOn") {
      return moment(value).format("DD-MM-YYYY");
    } else if (
      (label === "BoB Status" ||
        label === "Assessment Status" ||
        label === "Final Status") &&
      value === "Completed"
    ) {
      return "Approved";
    } else if (label === "Final Status" && value === "Submitted") {
      return "Pending Approval";
    } else {
      return value;
    }
  }, []);

  const getPlainText = useCallback((value) => {
    try {
      //debugger;
      let arrFormatted = [], plainText = "";
      let isStringOrArray = false;
      if (typeof value === 'string' || Array.isArray(value)) {
        isStringOrArray = true;
      }
      if (!isStringOrArray) {
        return value;
      }
      if (value.includes("<li>")) {
        const av = value.split("<li>");
        av.map((v, i) => {
          if (!v.includes("<ul>")) {
            document.getElementById('dvContent').innerHTML = v;
            let vText = document.getElementById('dvContent').innerText;
            arrFormatted.push(`• ${vText}\r\n`);
          }
        });
        plainText = arrFormatted.join("");
      } else {
        document.getElementById('dvContent').innerHTML = value;
        plainText = document.getElementById('dvContent').innerText;
      }
      // document.getElementById('dvContent').innerHTML = content;
      // let plainText = document.getElementById('dvContent').innerText;
      return plainText;
    } catch (e) {
      console.log(`DownloadTemplate_Report::ERR:: ${e}`);
      return value;
    }
  }, [])

  //..Discussion Guide
  const processDiscussionGuideData = useCallback((value, label, data) => {
    //debugger;
    // let arrFormatted = [], reviewerAudit = "";
    // if (value.includes("<li>")) {
    //   const av = value.split("<li>");
    //   av.map((v, i) => {
    //     if (!v.includes("<ul>")) {
    //       document.getElementById('dvContent').innerHTML = v;
    //       let vText = document.getElementById('dvContent').innerText;
    //       arrFormatted.push(`• ${vText}\r\n`);
    //     }
    //   });
    //   reviewerAudit = arrFormatted.join("");
    // } else {
    //   document.getElementById('dvContent').innerHTML = value;
    //   reviewerAudit = document.getElementById('dvContent').innerText;
    // }
    let reviewerAudit = label === "isRecordedInSuccessFactors" ? value : getPlainText(value);
    let formattedValue = [];
    //debugger;
    switch (label) {
      case "Partner Name":
      case "First Name":
      case "Last Name":
      case "Partner Email":
      case "Lead Reviewer Name":
      case "Lead Reviewer GUI":
      case "GUI":
      case "Office":
      case "Rank":
      case "Region":
      case "BU Number":
      case "SMU Code":
      case "Primary Sector":
      case "Reviewer Submitted Status":
      case "Updated By":
        // case "Development Points":
        // case "Distinguish Performance":
        // case "Overall Category":
        // case "Potential And Aspirations":
        //formattedValue.push("\n");
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join(`\n`);
        break;
      case "isRecordedInSuccessFactors":
        //formattedValue.push("\n");
        formattedValue.push(`${data["isRecordedInSuccessFactors"] ? "Yes" : "No"}`);
        reviewerAudit = formattedValue.join(`\n`);
        break;
      case "Reviewer Submitted Date":
      case "Updated Date":
        reviewerAudit = moment(value).format("MM/DD/YYYY");
        //formattedValue.push("\n");
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join(`\n`);
        break;
      case "DRAFT Finalization Wording":
        formattedValue.push("\n");
        formattedValue.push(`${data["Partner Name"]} exhibited the following distinguished performance behaviors this fiscal year:`);
        //formattedValue.push(`\n`);
        formattedValue.push(`${getPlainText(data["Distinguish Performance"])}`);
        formattedValue.push("\n");
        formattedValue.push(`${data["Partner Name"]} has the following development points:`);
        //formattedValue.push("\n");
        formattedValue.push(`${getPlainText(data["Development Points"])}`);
        formattedValue.push("\n");
        formattedValue.push(`${data["Partner Name"]}'s potential and aspirations include:`);
        //formattedValue.push("\n");
        formattedValue.push(`${getPlainText(data["Potential And Aspirations"])}`);
        formattedValue.push("\n");
        formattedValue.push(`Overall, ${data["Partner Name"]} is in the ${data["Overall Category"]} category.`);
        formattedValue.push(`${data["Partner Name"]}'s quality rating has been considered in their LEAD category determination and the related commentary is contained in MARQ.`);
        reviewerAudit = formattedValue.join("\n");
        break;
      case "FINAL Finalization Wording":
        if (value === "FINAL") {
          formattedValue.push("\n");
          formattedValue.push(`${data["Partner Name"]} exhibited the following distinguished performance behaviors this fiscal year:`);
          //formattedValue.push(`\n`);
          formattedValue.push(`${getPlainText(data["Distinguish Performance"])}`);
          formattedValue.push("\n");
          formattedValue.push(`${data["Partner Name"]} has the following development points:`);
          //formattedValue.push("\n");
          formattedValue.push(`${getPlainText(data["Development Points"])}`);
          formattedValue.push("\n");
          formattedValue.push(`${data["Partner Name"]}'s potential and aspirations include:`);
          //formattedValue.push("\n");
          formattedValue.push(`${getPlainText(data["Potential And Aspirations"])}`);
          formattedValue.push("\n");
          formattedValue.push(`Overall, ${data["Partner Name"]} is in the ${data["Overall Category"]} category.`);
          formattedValue.push(`${data["Partner Name"]}'s quality rating has been considered in their LEAD category determination and the related commentary is contained in MARQ.`);
          reviewerAudit = formattedValue.join("\n");
        }
        break;
      case "PFC Discussion Question 1":
        //formattedValue.push("\n");
        //formattedValue.push("1. Did your PPMD achieve their goals?");
        formattedValue.push(`\n`);
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join(`\n`);
        break;
      case "PFC Discussion Question 2":
        //formattedValue.push("\n");
        //formattedValue.push("2.  How has the PPMD teamed this year across SLs, SSLs and functions? And how will they accelerate it next year?");
        formattedValue.push(`\n`);
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join("\n");
        break;
      case "PFC Discussion Question 3":
        //formattedValue.push("\n");
        //formattedValue.push("3.  What actions has the PPMD taken to contribute to priorities including diversity, equity and inclusiveness?");
        formattedValue.push(`\n`);
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join("\n");
        break;
      case "PFC Discussion Question 4":
        //formattedValue.push("\n");
        //formattedValue.push("4.  What is the PPMD's capability and aspiration to take on the next complex challenge? What is needed to help them get there?");
        formattedValue.push(`\n`);
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join("\n");
        break;
      case "PFC Discussion Question 5":
        //formattedValue.push("\n");
        //formattedValue.push("4.  What is the PPMD's capability and aspiration to take on the next complex challenge? What is needed to help them get there?");
        formattedValue.push(`\n`);
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join("\n");
        break;
      case "Summary of Roles/Areas of Responsibility":
        //formattedValue.push("\n");
        //formattedValue.push("Summary of Roles/Areas of Responsibility:");
        formattedValue.push(`\n`);
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join("\n");
        break;
      default:
        break;
    }
    return reviewerAudit;
  }, []);
  //.. PPMD Discussion Guide
  const processPPMDDiscussionGuideData = useCallback((value, label, data) => {
    //debugger;
    // document.getElementById('dvContent').innerHTML = value;
    // let reviewerAudit = document.getElementById('dvContent').innerText;
    let reviewerAudit = getPlainText(value);
    let formattedValue = [];
    //debugger;
    switch (label) {
      case "Partner Name":
      case "First Name":
      case "Last Name":
      case "Partner Email":
      case "GUI":
      case "Office":
      case "Rank":
      case "Region":
      case "BU Number":
      case "SMU Code":
      case "Primary Sector":
      case "Lead Reviewer Name":
      case "Lead Reviewer GUI":
      case "Submitted Status":
      case "Summary of Roles/Areas of Responsibility":
      case "Quality":
        formattedValue.push("\n");
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join(`\n`);
        break;
      case "Discussion Question 1":
        formattedValue.push("\n");
        formattedValue.push("What additional accomplishments/activities would you like to discuss not readily apparent above or in your feedback?");
        formattedValue.push(`\n`);
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join(`\n`);
        break;
      case "Revenue/Margin":
        //document.getElementById('dvContent').innerHTML = data["Revenue/Margin Comment"];
        //reviewerAudit = document.getElementById('dvContent').innerText;
        formattedValue.push("\n");
        formattedValue.push("Response:");
        //formattedValue.push(`\n`);
        formattedValue.push(`${data["Revenue/Margin"]}`);
        formattedValue.push(`\n`);
        formattedValue.push("Comments on Revenue/Margin:");
        //formattedValue.push(`\n`);
        formattedValue.push(`${getPlainText(data["Revenue/Margin Comment"])}`);
        reviewerAudit = formattedValue.join(`\n`);
        break;
      case "Sales and Pipeline":
        //document.getElementById('dvContent').innerHTML = data["Sales and Pipeline Comment"];
        //reviewerAudit = document.getElementById('dvContent').innerText;
        formattedValue.push("\n");
        formattedValue.push("Response:");
        //formattedValue.push(`\n`);
        formattedValue.push(`${data["Sales and Pipeline"]}`);
        formattedValue.push(`\n`);
        formattedValue.push("Comments on Sales and Pipeline:");
        //formattedValue.push(`\n`);
        formattedValue.push(`${getPlainText(data["Sales and Pipeline Comment"])}`);
        reviewerAudit = formattedValue.join(`\n`);
        break;
      case "Exceptional Client Service":
        //document.getElementById('dvContent').innerHTML = data["Exceptional Client Service Comment"];
        //reviewerAudit = document.getElementById('dvContent').innerText;
        formattedValue.push("\n");
        formattedValue.push("Response:");
        //formattedValue.push(`\n`);
        formattedValue.push(`${data["Exceptional Client Service"]}`);
        formattedValue.push(`\n`);
        formattedValue.push("Comments on ECS:");
        //formattedValue.push(`\n`);
        formattedValue.push(`${getPlainText(data["Exceptional Client Service Comment"])}`);
        reviewerAudit = formattedValue.join(`\n`);
        break;
      case "People & Teaming":
        //document.getElementById('dvContent').innerHTML = data["People & Teaming Comment"];
        //reviewerAudit = document.getElementById('dvContent').innerText;
        formattedValue.push("\n");
        formattedValue.push("Response:");
        //formattedValue.push(`\n`);
        formattedValue.push(`${data["People & Teaming"]}`);
        formattedValue.push(`\n`);
        formattedValue.push("Comments on People and Teaming:");
        //formattedValue.push(`\n`);
        formattedValue.push(`${getPlainText(data["People & Teaming Comment"])}`);
        reviewerAudit = formattedValue.join(`\n`);
        break;
      case "Draft Finalization Form Comments":
        formattedValue.push("\n");
        formattedValue.push("Describe the top 2-4 distinguished performance behaviors exhibited during the fiscal year:");
        //formattedValue.push(`\n`);
        formattedValue.push(`${reviewerAudit}`);
        //..developmentPointsContent
        //document.getElementById('dvContent').innerHTML = data["Draft Finalization Form Comment 2"];
        //let dp = document.getElementById('dvContent').innerText;
        formattedValue.push("\n");
        formattedValue.push("Describe 2-4 development points:");
        //formattedValue.push(`\n`);
        formattedValue.push(`${getPlainText(data["Draft Finalization Form Comment 2"])}`);
        //..potentialAndAspirationsContent
        //document.getElementById('dvContent').innerHTML = data["Draft Finalization Form Comment 3"];
        //let pa = document.getElementById('dvContent').innerText;
        formattedValue.push("\n");
        formattedValue.push("Describe potential and aspirations:");
        //formattedValue.push(`\n`);
        formattedValue.push(`${getPlainText(data["Draft Finalization Form Comment 3"])}`);

        reviewerAudit = formattedValue.join(`\n`);
        break;
      case "Submitted Date":
        reviewerAudit = moment(value).format("DD-MM-YYYY");
        formattedValue.push("\n");
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join(`\n`);
        break;
      default:
        break;
    }
    return reviewerAudit;
  }, []);
  //..Bulk Upload Template - Discussion Guide
  const processBulkUploadDiscussionGuideData = useCallback((value, label, data, rowIndex) => {
    //debugger;
    let reviewerAudit = getPlainText(value);
    let formattedValue = [];
    //debugger;
    switch (label) {
      case "Partner Name":
      case "First Name":
      case "Last Name":
      case "Partner Email":
      case "GUI":
      case "Office":
      case "Rank":
      case "Region":
      case "Primary Sector":
      case "Lead Reviewer Name":
      case "Lead Reviewer GUI":
      case "Reviewer Submitted Status":
      case "Development Points":
      case "Distinguish Performance":
      case "Overall Category":
      case "Potential And Aspirations":
        //formattedValue.push("\n");
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join(`\n`);
        break;
      case "Reviewer Submitted Date":
        reviewerAudit = moment(value).format("MM/DD/YYYY");
        //formattedValue.push("\n");
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join(`\n`);
        break;
      case "DRAFT Finalization Wording":
        formattedValue.push("\n");
        formattedValue.push(`${data["Partner Name"]} exhibited the following distinguished performance behaviors this fiscal year:`);
        //formattedValue.push(`\n`);
        formattedValue.push(`${getPlainText(data["Distinguish Performance"])}`);
        formattedValue.push("\n");
        formattedValue.push(`${data["Partner Name"]} has the following development points:`);
        //formattedValue.push("\n");
        formattedValue.push(`${getPlainText(data["Development Points"])}`);
        formattedValue.push("\n");
        formattedValue.push(`${data["Partner Name"]}'s potential and aspirations include:`);
        //formattedValue.push("\n");
        formattedValue.push(`${getPlainText(data["Potential And Aspirations"])}`);
        formattedValue.push("\n");
        formattedValue.push(`Overall, ${data["Partner Name"]} is in the ${data["Overall Category"]} category.`);
        formattedValue.push(`${data["Partner Name"]}'s quality rating has been considered in their LEAD category determination and the related commentary is contained in MARQ.`);
        reviewerAudit = formattedValue.join("\n");
        break;
      case "FINAL Finalization Wording":
        if (value === "FINAL") {
          // formattedValue.push("\n");
          // formattedValue.push(`${data["Partner Name"]} exhibited the following distinguished performance behaviors this fiscal year:`);
          // //formattedValue.push(`\n`);
          // formattedValue.push(`${getPlainText(data["Distinguish Performance"])}`);
          // formattedValue.push("\n");
          // formattedValue.push(`${data["Partner Name"]} has the following development points:`);
          // //formattedValue.push("\n");
          // formattedValue.push(`${getPlainText(data["Development Points"])}`);
          // formattedValue.push("\n");
          // formattedValue.push(`${data["Partner Name"]}'s potential and aspirations include:`);
          // //formattedValue.push("\n");
          // formattedValue.push(`${getPlainText(data["Potential And Aspirations"])}`);
          // formattedValue.push("\n");
          // formattedValue.push(`Overall, ${data["Partner Name"]} is in the ${data["Overall Category"]} category.`);
          // formattedValue.push(`${data["Partner Name"]}'s quality rating has been considered in their LEAD category determination and the related commentary is contained in MARQ.`);
          reviewerAudit = { formula: `"Overall, ${data["Partner Name"]} is in the " & K${rowIndex} & " category. Year-end distinguished performance behaviors, development points and potential/aspirations have been discussed with ${data["Partner Name"]} during the Expectations conversation. ${data["Partner Name"]}" & "'s quality rating has been considered in their LEAD category determination and the related commentary is contained in MARQ."`, result: '' };
        }
        break;
      case "PFC Discussion Question 1":
        //formattedValue.push("\n");
        //formattedValue.push("1. Did your PPMD achieve their goals?");
        formattedValue.push(`\n`);
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join(`\n`);
        break;
      case "PFC Discussion Question 2":
        //formattedValue.push("\n");
        //formattedValue.push("2.  How has the PPMD teamed this year across SLs, SSLs and functions? And how will they accelerate it next year?");
        formattedValue.push(`\n`);
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join("\n");
        break;
      case "PFC Discussion Question 3":
        //formattedValue.push("\n");
        //formattedValue.push("3.  What actions has the PPMD taken to contribute to priorities including diversity, equity and inclusiveness?");
        formattedValue.push(`\n`);
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join("\n");
        break;
      case "PFC Discussion Question 4":
        //formattedValue.push("\n");
        //formattedValue.push("4.  What is the PPMD's capability and aspiration to take on the next complex challenge? What is needed to help them get there?");
        formattedValue.push(`\n`);
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join("\n");
        break;
      case "Summary of Roles/Areas of Responsibility":
        //formattedValue.push("\n");
        //formattedValue.push("Summary of Roles/Areas of Responsibility:");
        formattedValue.push(`\n`);
        formattedValue.push(`${reviewerAudit}`);
        reviewerAudit = formattedValue.join("\n");
        break;
      default:
        break;
    }
    return reviewerAudit;
  }, []);

  const getReportData = () => {
    const financialYear = currentYear.split(" ")[1];
    const timePeriod = currentPeriod.split(" ");
    dispatch({
      type: "GET_EXCEL_REPORTS_DATA",
      payload: {
        financialYear,
        timePeriod:
          timePeriod.length > 1
            ? timePeriod[0] + "-" + timePeriod[1]
            : timePeriod[0],
        api: reportApi,
      },
    });
    setCurrentDetails();
  };

  useEffect(() => {
    getReportData();
  }, [reportApi, currentYear, currentPeriod]);

  // useEffect(() => {
  //   //debugger;
  //   let reportData = [
  //     {
  //       columns: [],
  //       data: [],
  //     },
  //   ];
  //   if (excelReportData.data && excelReportData.data[currentKey]?.length > 0) {
  //     currentReportHeaders.forEach((data, index) => {
  //       const currentField = data.label.split(" ");
  //       reportData[0].columns.push({
  //         title: data.label == "Tenor" ? "Tenure" : data.label,
  //         width: { wpx: data.width },
  //         style: {
  //           fill: { patternType: "solid", fgColor: { rgb: "FFB9B9C2" } },
  //           border: { right: { style: "medium", color: { rgb: "FF000000" } } },
  //           alignment: {
  //             wrapText: true,
  //             horizontal:
  //               currentField.indexOf("Email") !== -1 ||
  //                 currentField.indexOf("Name") !== -1
  //                 ? "left"
  //                 : "center",
  //             vertical: "top",
  //           },
  //         },
  //       });
  //     });
  //     let filterListStatus = [];
  //     props.appliedFilterList.status.forEach((i) => {
  //       if (i.isSelected) filterListStatus.push(i.name);
  //     });
  //     let filterListRegion = [];
  //     props.appliedFilterList.region.map((i) => {
  //       if (i.isSelected) filterListRegion.push(i.name);
  //     });
  //     excelReportData.data[currentKey]?.forEach((data, index) => {
  //       const tempData = [];
  //       /*  if (props && props.dropdownValue === "All Regions") {
  //         currentReportHeaders.forEach((value) => {
  //           const currentField = value.label.split(" ");
  //           tempData.push({
  //             value: data[value.label]
  //               ? processDateFormat(data[value.label], value.label)
  //               : "--",
  //             style: {
  //               alignment: {
  //                 wrapText: true,
  //                 horizontal:
  //                   currentField.indexOf("Email") !== -1 ||
  //                   currentField.indexOf("Name") !== -1
  //                     ? "left"
  //                     : "center",
  //                 vertical: "top",
  //               },
  //               border: {
  //                 bottom: { style: "thin", color: { rgb: "000000" } },
  //                 left: { style: "thin", color: { rgb: "000000" } },
  //                 right: { style: "thin", color: { rgb: "000000" } },
  //               },
  //             },
  //           });
  //         });
  //         reportData[0].data.push(tempData);
  //       }  else { */
  //       if (
  //         (filterListRegion.length === 0 ||
  //           filterListRegion.includes(data.Region)) &&
  //         (filterListStatus.length === 0 ||
  //           (filterListStatus.includes(data["Assessment Status"]) &&
  //             props.reportApi === "getAssessmentReport") ||
  //           (filterListStatus.includes(data["Reviewer Submitted Status"]) &&
  //             props.reportApi === "getDiscussionGuideReport" && isFromBulkUpload) ||
  //           (filterListStatus.includes(data["Reviewer Submitted Status"]) &&
  //             props.reportApi === "getDiscussionGuideReport") ||
  //           (filterListStatus.includes(data["Submitted Status"]) &&
  //             props.reportApi === "getPPMDDiscussionGuideReport") ||
  //           (filterListStatus.includes(data["BoB Status"]) &&
  //             props.reportApi === "getBoBReport") ||
  //           (filterListStatus.includes(data["Final Status"]) &&
  //             props.reportApi === "getLeadReviewerReport"))
  //       ) {
  //         currentReportHeaders.forEach((value) => {
  //           const currentField = value.key.split(" ");
  //           //debugger;
  //           let customStyle = {
  //             alignment: {
  //               wrapText: true,
  //               horizontal:
  //                 currentField.indexOf("Email") !== -1 ||
  //                   currentField.indexOf("Name") !== -1
  //                   ? "left"
  //                   : "center",
  //               vertical: "top",
  //             },
  //             border: {
  //               bottom: { style: "thin", color: { rgb: "000000" } },
  //               left: { style: "thin", color: { rgb: "000000" } },
  //               right: { style: "thin", color: { rgb: "000000" } },
  //             },
  //           };
  //           if (props.reportApi === "getDiscussionGuideReport"
  //             || props.reportApi === "getPPMDDiscussionGuideReport") {
  //             customStyle = {
  //               fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
  //               font: { name: "EYInterstate", sz: "9" },
  //               alignment: {
  //                 wrapText: true,
  //                 // horizontal:
  //                 //   currentField.indexOf("Email") !== -1 ||
  //                 //     currentField.indexOf("Name") !== -1
  //                 //     ? "left"
  //                 //     : "center",
  //                 vertical: "top",
  //               },
  //               border: {
  //                 // bottom: { style: "thin", color: { rgb: "000000" } },
  //                 // left: { style: "thin", color: { rgb: "000000" } },
  //                 // right: { style: "thin", color: { rgb: "000000" } },
  //                 left: { style: "medium", color: { rgb: "FF000000" } },
  //                 top: { style: "medium", color: { rgb: "FF000000" } },
  //                 right: { style: "medium", color: { rgb: "FF000000" } },
  //                 bottom: { style: "medium", color: { rgb: "FF000000" } },
  //               },
  //             };
  //           }
  //           //debugger;
  //           //..Bulk Upload
  //           if (isFromBulkUpload && props.reportApi === "getDiscussionGuideReport") {
  //             //..Exclude columns
  //             if (!bulkUploadExcludedList.includes(value.key)) {
  //               tempData.push({
  //                 // value: data[value.label]
  //                 //   ? processDateFormat(data[value.label], value.label)
  //                 //   : "--",
  //                 value: data[value.key]
  //                   ? processBulkUploadDiscussionGuideData(data[value.key], value.key, data)
  //                   : "--",
  //                 style: customStyle,
  //               });
  //             }
  //           } else {
  //             tempData.push({
  //               // value: data[value.label]
  //               //   ? processDateFormat(data[value.label], value.label)
  //               //   : "--",
  //               value: data[value.key]
  //                 ? props.reportApi === "getDiscussionGuideReport" ?
  //                   !leadReviewerExcludedList.includes(value.key) && processDiscussionGuideData(data[value.key], value.key, data)
  //                   :
  //                   props.reportApi === "getPPMDDiscussionGuideReport" ?
  //                     processPPMDDiscussionGuideData(data[value.key], value.key, data)
  //                     :
  //                     processDateFormat(data[value.key], value.key)
  //                 : "--",
  //               style: customStyle,
  //             });
  //           }
  //         });
  //         reportData[0].data.push(tempData);
  //       }
  //       // }
  //     });
  //     setExportReport(reportData);
  //   }
  // }, [
  //   excelReportData,
  //   currentKey,
  //   currentReportHeaders,
  //   processDateFormat,
  //   props.dropdownValue,
  //   props.appliedFilterList,
  //   props.reportApi,
  // ]);

  const handleExport = async () => {
    try {
      //debugger;
      if (excelReportData.data && excelReportData.data[currentKey]?.length > 0) {
        const worksheet = workbook.addWorksheet(workSheetName);
        worksheet.columns = currentReportHeaders;
        let columnHeaderRow = worksheet.getRow(1);
        columnHeaderRow.eachCell(function (cell) {
          const currentField = cell.text;
          cell.fill = { type: "pattern", pattern: 'solid', fgColor: { argb: "FFB9B9C2" } };
          //cell.font = { name: "Calibri", size: "12" };
          cell.border = { right: { style: "medium", color: { argb: "FF000000" } } };
          cell.alignment = {
            wrapText: true,
            horizontal: currentField.indexOf("Email") !== -1 ||
              currentField.indexOf("Name") !== -1
              ? "left"
              : "center",
            //horizontal: "center",
            vertical: "top",
          };
        });
        let filterListStatus = [];
        props.appliedFilterList && props.appliedFilterList.status
          && props.appliedFilterList.status.forEach((i) => {
            if (i.isSelected) filterListStatus.push(i.name);
          });
        let filterListRegion = [];
        props.appliedFilterList && props.appliedFilterList.status
          && props.appliedFilterList.region.map((i) => {
            if (i.isSelected) filterListRegion.push(i.name);
          });

        excelReportData.data[currentKey]?.forEach((data, index) => {
          if (
            (filterListRegion.length === 0 ||
              filterListRegion.includes(data.Region)) &&
            (filterListStatus.length === 0 ||
              (filterListStatus.includes(data["Assessment Status"]) &&
                props.reportApi === "getAssessmentReport") ||
              (filterListStatus.includes(data["Reviewer Submitted Status"]) &&
                props.reportApi === "getDiscussionGuideReport" && isFromBulkUpload) ||
              (filterListStatus.includes(data["Reviewer Submitted Status"]) &&
                props.reportApi === "getDiscussionGuideReport") ||
              (filterListStatus.includes(data["Submitted Status"]) &&
                props.reportApi === "getPPMDDiscussionGuideReport") ||
              (filterListStatus.includes(data["BoB Status"]) &&
                props.reportApi === "getBoBReport") ||
              (filterListStatus.includes(data["Final Status"]) &&
                props.reportApi === "getLeadReviewerReport"))
          ) {
            let rowObj = {}, rowIndex = worksheet.rowCount;
            if (isFromBulkUpload && props.reportApi === "getDiscussionGuideReport") {              
              rowIndex = rowIndex + 1; //..Incrementing row index for each row reference in the excel
              currentReportHeaders.forEach((c) => {
                if (!bulkUploadExcludedList.includes(c.key)) {
                  rowObj[c.key] = data[c.key] ? processBulkUploadDiscussionGuideData(data[c.key], c.key, data, rowIndex) : "--";
                }
              });
            } else {
              if (props.reportApi === "getDiscussionGuideReport") {
                currentReportHeaders.forEach((c) => {
                  if (!leadReviewerExcludedList.includes(c.key)) {
                    rowObj[c.key] = data[c.key] ? processDiscussionGuideData(data[c.key], c.key, data) : "--";
                  }
                });
              } else if (props.reportApi === "getPPMDDiscussionGuideReport") {
                currentReportHeaders.forEach((c) => {
                  rowObj[c.key] = data[c.key] ? processPPMDDiscussionGuideData(data[c.key], c.key, data) : "--";
                });
              } else {
                currentReportHeaders.forEach((c) => {
                  rowObj[c.key] = data[c.key] ? processDateFormat(data[c.key], c.key) : "--";
                });
              }
            }
            let row = worksheet.addRow(rowObj);
            row.eachCell(function (cell) {
              const currentField = cell._column._key;
              //cell.fill = { type: "pattern", pattern: 'solid', fgColor: { argb: "FFF2F2F2" } };
              //if (isFromBulkUpload && props.reportApi === "getDiscussionGuideReport") { } else {
              //cell.font = { name: "Calibri", size: "12" };
              //}
              cell.alignment = {
                wrapText: true,
                horizontal:
                  currentField.indexOf("Email") !== -1 ||
                    currentField.indexOf("Name") !== -1
                    ? "left"
                    : "center",
                //horizontal: "center",
                vertical: "top",
              };
              cell.border = {
                bottom: { style: "thin", color: { argb: "000000" } },
                left: { style: "thin", color: { argb: "000000" } },
                right: { style: "thin", color: { argb: "000000" } },
              };
              if (props.reportApi === "getDiscussionGuideReport"
                || props.reportApi === "getPPMDDiscussionGuideReport") {
                cell.fill = { type: "pattern", pattern: 'solid', fgColor: { argb: "FFF2F2F2" } };
                cell.font = { name: "EYInterstate", size: "9" };
                cell.alignment = {
                  wrapText: true,
                  vertical: "top",
                };
                cell.border = {
                  left: { style: "medium", color: { argb: "FF000000" } },
                  top: { style: "medium", color: { argb: "FF000000" } },
                  right: { style: "medium", color: { argb: "FF000000" } },
                  bottom: { style: "medium", color: { argb: "FF000000" } },
                };
              }
              // if (isFromBulkUpload && props.reportApi === "getDiscussionGuideReport") { } else {
              //   cell.border.top = { style: "medium", color: { argb: "FF000000" } };
              // }
            });
          }
        });

        // write the content using writeBuffer
        const buf = await workbook.xlsx.writeBuffer();

        // download the processed file
        saveAs(new Blob([buf]), `${fileName}.xlsx`);

      }
    } catch (error) {
      console.error('<<<ERRROR>>>', error);
      console.error('Something Went Wrong', error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  }

  return (
    <>
      {/* <ExcelFile
        element={
          <button>
            <span className="eyaa-data-51"></span>
            <span>{isFromBulkUpload ? "Download Template" : "Download Report"}</span>
          </button>
        }
        filename={fileName}>
        <ExcelSheet dataSet={exportReport} name="Organization" />
      </ExcelFile> */}
      <button onClick={handleExport}>
        <span className="eyaa-data-51"></span>
        <span>{isFromBulkUpload ? "Download Template" : "Download Report"}</span>
      </button>
    </>
  );
};
export default ReportDownload;
