import React, { useCallback, useState, useMemo, useEffect } from "react";
//import ReactExport from "react-data-export";
import _ from "lodash";

const DownloadTemplate = (props) => {
  const { assessmentData } = props;
  // const ExcelFile = ReactExport.ExcelFile;
  // const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const [templateData, setTemplateData] = useState([]);

  const contentBorder = useMemo(() => {
    return {
      top: { style: "medium", color: { rgb: "FF000000" } },
      bottom: { style: "medium", color: { rgb: "FF000000" } },
      left: { style: "medium", color: { rgb: "FF000000" } },
      right: { style: "medium", color: { rgb: "FF000000" } },
    };
  }, []);

  const contentStyle = useMemo(() => {
    return {
      font: { name: "EYInterstate", sz: "8" },
      alignment: {
        wrapText: true,
        horizontal: "left",
        vertical: "center",
      },
      border: contentBorder,
    };
  }, [contentBorder]);

  const instructions = useMemo(
    () => [
      {
        columns: [
          { title: "", width: { wpx: 10 } },
          {
            title: "LEAD 4D",
            width: { wpx: 1200 },
            style: {
              fill: { patternType: "solid", fgColor: { rgb: "FFD9D9D9" } },
              font: {
                name: "EYInterstate",
                sz: "16",
                color: { rgb: "FF000000" },
                bold: true,
              },
              border: contentBorder,
            },
          },
        ],
        data: [
          [
            {
              value: "",
            },
            {
              value: "Instructions/Notes",
              style: {
                font: {
                  name: "EYint",
                  sz: "10",
                  color: { rgb: "FFF29131" },
                  bold: true,
                },
              },
            },
          ],
          [
            {
              value: "",
            },
            {
              value: "Add content in the 'Assessment Content Template' sheet",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
                font: { name: "EYInterstate", sz: "9" },
                border: {
                  left: { style: "medium", color: { rgb: "FF000000" } },
                  top: { style: "medium", color: { rgb: "FF000000" } },
                  right: { style: "medium", color: { rgb: "FF000000" } },
                },
              },
            },
          ],
          [
            {
              value: "",
            },
            {
              value: "Maximum of 7 Global Metrics (overall) can be added",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
                font: { name: "EYInterstate", sz: "9" },
                border: {
                  left: { style: "medium", color: { rgb: "FF000000" } },
                  right: { style: "medium", color: { rgb: "FF000000" } },
                },
              },
            },
          ],
          [
            {
              value: "",
            },
            {
              value:
                "Maximum of 7 Goal within a Global Metric/Pillar can be added",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
                font: { name: "EYInterstate", sz: "9" },
                border: {
                  left: { style: "medium", color: { rgb: "FF000000" } },
                  right: { style: "medium", color: { rgb: "FF000000" } },
                },
              },
            },
          ],
          [
            {
              value: "",
            },
            {
              value: "Maximum of 5 sections (excluding general) can be added",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
                font: { name: "EYInterstate", sz: "9" },
                border: {
                  left: { style: "medium", color: { rgb: "FF000000" } },
                  right: { style: "medium", color: { rgb: "FF000000" } },
                },
              },
            },
          ],
          [
            {
              value: "",
            },
            {
              value: "Avoid empty cells and rows in between",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
                font: { name: "EYInterstate", sz: "9" },
                border: {
                  left: { style: "medium", color: { rgb: "FF000000" } },
                  right: { style: "medium", color: { rgb: "FF000000" } },
                },
              },
            },
          ],
          [
            {
              value: "",
            },
            {
              value:
                "If you do not wish to add Sections for Goals, Mention 'General' as Section under the column 'Section' for all Impact Statements within those Goals.",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
                font: { name: "EYInterstate", sz: "9" },
                border: {
                  left: { style: "medium", color: { rgb: "FF000000" } },
                  right: { style: "medium", color: { rgb: "FF000000" } },
                },
              },
            },
          ],
          [
            {
              value: "",
            },
            {
              value:
                "Adding content using the template would replace any existing content mapped within the Target year",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
                font: { name: "EYInterstate", sz: "9" },
                border: {
                  left: { style: "medium", color: { rgb: "FF000000" } },
                  right: { style: "medium", color: { rgb: "FF000000" } },
                },
              },
            },
          ],
          [
            {
              value: "",
            },
            {
              value:
                "Roll forwarded or content adding using manage assessment if any will be part of the template. You can update the content and upload to replace the existing content.",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
                font: { name: "EYInterstate", sz: "9" },
                border: {
                  left: { style: "medium", color: { rgb: "FF000000" } },
                  right: { style: "medium", color: { rgb: "FF000000" } },
                },
              },
            },
          ],
          [
            {
              value: "",
            },
            {
              value:
                "In case if no content has been added using 'Roll Forward' or 'Manage Assessment' or 'Add Assessment Content' in the Target year, the template will have no content withiin it. You can add content and upload to create a new content structure.",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
                font: { name: "EYInterstate", sz: "9" },
                border: {
                  left: { style: "medium", color: { rgb: "FF000000" } },
                  right: { style: "medium", color: { rgb: "FF000000" } },
                },
              },
            },
          ],
          [
            {
              value: "",
            },
            {
              value:
                "You will be able to add more details like 'Descriptions', 'Scores', options etc. and configure the complete assessment using 'Manage Assessment'",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
                font: { name: "EYInterstate", sz: "9" },
                border: {
                  left: { style: "medium", color: { rgb: "FF000000" } },
                  right: { style: "medium", color: { rgb: "FF000000" } },
                },
              },
            },
          ],
          [
            {
              value: "",
            },
            {
              value: "Follow the below mentioned format:",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
                font: { name: "EYInterstate", sz: "9" },
                border: {
                  left: { style: "medium", color: { rgb: "FF000000" } },
                  right: { style: "medium", color: { rgb: "FF000000" } },
                },
              },
            },
          ],
          [
            {
              value: "",
            },
            {
              value: "Pillar1 > Goal 1> Section 1 > Statement 1",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
                font: { name: "EYInterstate", sz: "9" },
                border: {
                  left: { style: "medium", color: { rgb: "FF000000" } },
                  right: { style: "medium", color: { rgb: "FF000000" } },
                },
              },
            },
          ],
          [
            {
              value: "",
            },
            {
              value: "Pillar1 > Goal 1> Section 1 > Statement 2",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
                font: { name: "EYInterstate", sz: "9" },
                border: {
                  left: { style: "medium", color: { rgb: "FF000000" } },
                  right: { style: "medium", color: { rgb: "FF000000" } },
                },
              },
            },
          ],
          [
            {
              value: "",
            },
            {
              value: "Pillar1 > Goal 1> Section 1 > Statement 3",
              style: {
                fill: { patternType: "solid", fgColor: { rgb: "FFF2F2F2" } },
                font: { name: "EYInterstate", sz: "9" },
                border: {
                  left: { style: "medium", color: { rgb: "FF000000" } },
                  right: { style: "medium", color: { rgb: "FF000000" } },
                  bottom: { style: "medium", color: { rgb: "FF000000" } },
                },
              },
            },
          ],
        ],
      },
    ],
    []
  );
  const templateHeaders = useMemo(
    () => [
      {
        label: "Global Metric/Pillar",
        width: 200,
      },
      {
        label: "Goal",
        width: 200,
      },
      {
        label: "Section",
        width: 150,
      },
      {
        label: "Impact Statement",
        width: 480,
      },
    ],
    []
  );

  const proccessAssessmentData = useCallback(() => {
    let template = [
      {
        columns: [],
        data: [],
      },
    ];

    templateHeaders.forEach((data, index) => {
      template[0].columns.push({
        title: data.label,
        width: { wpx: data.width },
        style: {
          fill: { patternType: "solid", fgColor: { rgb: "FFBDD7EE" } },
          font: { name: "EYInterstate", sz: "8", bold: true },
          border: contentBorder,
          alignment: {
            wrapText: true,
            horizontal: "left",
            vertical: "center",
          },
        },
      });
    });
    if (
      _.isObject(assessmentData.data.data) &&
      assessmentData.data.data.assessmentDetails.length > 0
    ) {
      assessmentData.data.data.assessmentDetails.forEach((pillar, index) => {
        const pillarName = pillar.pillarDisplayName;
        pillar.section.forEach((section) => {
          const goalName = section.goalName;
          if (section.data && Array.isArray(section.data))
            section.data.forEach((data) => {
              const excelData = [];
              excelData.push(
                {
                  value: pillarName,
                  style: contentStyle,
                },
                {
                  value: goalName,
                  style: contentStyle,
                },
                {
                  value: data.type,
                  style: contentStyle,
                },
                {
                  value: data.impactActions,
                  style: contentStyle,
                }
              );
              template[0].data.push(excelData);
            });
        });
      });
    } else {
      for (var i = 0; i <= 100; i++) {
        const excelData = [];
        excelData.push(
          {
            value: "",
            style: contentStyle,
          },
          {
            value: "",
            style: contentStyle,
          },
          {
            value: "",
            style: contentStyle,
          },
          {
            value: "",
            style: contentStyle,
          }
        );
        template[0].data.push(excelData);
      }
    }
    setTemplateData(template);
  }, [templateHeaders, assessmentData]);

  useEffect(() => {
    proccessAssessmentData();
  }, [assessmentData, proccessAssessmentData, contentBorder]);

  return (
    // <ExcelFile
    //   element={
    //     <button
    //       id="excel-export"
    //       // disabled={props.disableDownloadBtn}
    //       className="excel-file"
    //     >
    //       <span className="eyaa-arrow-tab-down"></span>
    //       <span>Download Template</span>
    //     </button>
    //   }
    //   filename="Assessment_Content_Template"
    // >
    //   <ExcelSheet dataSet={instructions} name="Instructions" />
    //   <ExcelSheet
    //     dataSet={templateData}
    //     name="Add Assessment Content_Template"
    //   />
    // </ExcelFile>
    <></>
  );
};
export default DownloadTemplate;
