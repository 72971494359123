import React, { useCallback, useState, useEffect, useContext, useMemo } from "react";
import SideMenu from "../../SideMenu/SideMenu";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import "../Admin.scss";
import _ from "lodash";
import "./DataManagement.scss";
import * as myConsts from "../../../constants/constants";
// import ManageClient from "./ManageClient/ManageClient";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppContext from "../../../utils/appContext";
import { makeStyles } from "@material-ui/core/styles";
import AddMercuryData from "./AddMercuryData/AddMercuryData";
import SimpleBar from "simplebar-react";

/* Admin can search the user by either GPN or mail id. Then admin can reset the BoB or Assessment if 
their status is submitted. otherwise the radio buttons are disabled. if both status are submitted, then 
both radio button will be enabled */

const DataManagement = (props) => {
  const myContext = useContext(AppContext);
  const dispatch = useDispatch();
  const crumbs = [{ name: "Admin", path: myConsts.routeAdmin }];
  const [isMainPageVisible, setIsMainPageVisible] = useState(
    sessionStorage.getItem("currentPageName")
      ? sessionStorage.getItem("currentPageName") !== "Data Management"
        ? false
        : true
      : true
  );
  const [currentPage, setCurrentPage] = useState(
    sessionStorage.getItem("currentPageName")
      ? sessionStorage.getItem("currentPageName") === "Manage Client"
        ? 1
        : sessionStorage.getItem("currentPageName") === "Add Mercury Data"
          ? 2
          : null
      : null
  );
  const useStyles = makeStyles((theme) => ({
    paper: {
      border: "1px solid #42425ad6",
      fontSize: "11px",
      padding: "15px",
      maxWidth: "750px",
      backgroundColor: theme.palette.background.paper,
    },
    tooltip: {
      fontSize: 10,
    },
  }));
  const classes = useStyles();
  const [currentPageName, setCurrentPageName] = useState(
    sessionStorage.getItem("currentPageName") || "Data Management"
  );
  const [isChangesInChild, setIsChangesInChild] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isBackArrowClicked, setIsBackArrowClicked] = useState(false);
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  const getBobRollforwardStatus = useSelector(
    (state) => state.getBobRollforwardStatus
  );
  const userProfile = useSelector((state) => state.userProfile);
  const [rollForwardData, setRollForwardData] = useState({ fromYear: "", fromPhase: "", toYear: "", toPhase: "", isRollForwardBobDone: "" })

  const currentUserId = useMemo(() => {
    return _.get(userProfile, "data[0].userId", "");
  }, [userProfile]);

  const setCurrentPageConfig = useCallback((pageNo) => {
    switch (pageNo) {
      case 1:
        setCurrentPageName("Manage Client");
        sessionStorage.setItem("currentPageName", "Manage Client");
        break;
      case 2:
        setCurrentPageName("Add Mercury Data");
        sessionStorage.setItem("currentPageName", "Add Mercury Data");
        break;
      default:
        setCurrentPageName("");
        sessionStorage.setItem("currentPageName", "");
    }
    setCurrentPage(pageNo);
    setIsMainPageVisible(false);
    sessionStorage.setItem("viewUserProfile", false);
    if (
      myContext.activeFinancialYear &&
      myContext.activeFinancialYear !== null
    ) {
      myContext.setFinancialYear(myContext.activeFinancialYear);
      sessionStorage.setItem("financialYear", myContext.activeFinancialYear);
    } else {
      myContext.setFinancialYear(myContext.lastActiveFinancialYear);
      sessionStorage.setItem(
        "financialYear",
        myContext.lastActiveFinancialYear
      );
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: "GET_BOB_ROLLFORWARD_STATUS",
      payload: {},
    });
    myContext.setActivePPMDuserId("");
    sessionStorage.setItem("activePPMDuserId", "");
    if (currentUserId !== myContext.userId) {
      dispatch({
        type: "GET_USER_PROFILE_BY_ID",
        payload: {
          userId: myContext.userId || sessionStorage.userId,
          financialYear: myContext.financialYear,
        },
      });
      myContext.setIsRouteFromAdmin(false);
    }
  }, []);

  useEffect(() => {
    if (currentPageName === "Data Management")
      dispatch({
        type: "CHECK_LOCK_STATUS_RESET",
        payload: {},
      });
    if (myContext.lockPopup) toast.dismiss();
  }, [currentPageName, dispatch]);

  useEffect(() => {
    if (sessionStorage.getItem("viewUserProfile") === "true") {
      setCurrentPageConfig(6);
    }
  }, [setCurrentPageConfig]);

  const getCurrentPage = useCallback(() => {
    switch (currentPage) {
      case 1:
        return (
          // <ManageClient changesInChild={(val) => setIsChangesInChild(val)} />
          <></>
        );
      case 2:
        return (
          <AddMercuryData
            rollForwardData={getBobRollforwardStatus.data}
            onClose={() => {
              setCurrentPageName("Data Management");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "Data Management");
            }}
          />
        );
      default:
        return "";
    }
  }, [currentPage]);

  const onPopupConfirm = useCallback(() => {
    setIsChangesInChild(false);
    setIsPopupVisible(false);
    if (isBackArrowClicked) {
      if (!isMainPageVisible) {
        setCurrentPageName("Data Management");
        setIsMainPageVisible(true);
        sessionStorage.setItem("currentPageName", "Data Management");
      } else {
        props.history.push(myConsts.routeAdmin);
      }
    } else {
      setIsMainPageVisible(true);
      setCurrentPageName("Data Management");
      sessionStorage.setItem("currentPageName", "Data Management");
    }
  }, [isBackArrowClicked, isMainPageVisible]);

  const checkForChanges = useCallback(() => {
    if (isChangesInChild) {
      setIsPopupVisible(true);
    } else {
      onPopupConfirm();
    }
  }, [isChangesInChild, onPopupConfirm]);

  return (
    <>
      <div className="admin-container manage-users">
        <div className="admin-info-main-sidemenu">
          <SideMenu
            minimized={true}
            isminimizedMainMenu={true}
            isMainMenu={true}
            sideMenuItemSelect="Admin"
          />
        </div>
        <div className="admin-content">
          <div className="second-header admin-crumbs">
            <i
              className="icon-arrow-left"
              onClick={() => {
                setIsBackArrowClicked(true);
                checkForChanges();
              }}
            />
            <div className="admin-crumb-txt">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <Breadcrumb
                  crumbs={crumbs}
                  isAdmin={true}
                  isInnerAdmin={true}
                  {...props}
                />
                {!isMainPageVisible && (
                  <div className="icon-chevron-right iconStyling">
                    <a
                      className=" userMngmnt MuiTypography-root MuiLink-root MuiLink-underlineHover admin-crumb MuiTypography-colorInherit"
                      onClick={() => {
                        setIsBackArrowClicked(false);
                        checkForChanges();
                      }}>
                      Data Management
                    </a>
                  </div>
                )}
              </div>
              <h4>{currentPageName}</h4>
            </div>
          </div>
          <div className="manage-users-content">
            <SimpleBar
              style={{ maxHeight: "calc(100% - 40px)", height: "calc(100% - 40px)" }}>
              <div className="main-content">
                {isMainPageVisible ? (
                  <div className="card-block">
                    {/* <div className="card" onClick={() => setCurrentPageConfig(1)}>
                    <span className="eyaa-user-05"></span>
                    <span>Manage SEC Indicator</span>
                  </div> */}
                    <div
                      className="card" onClick={() => setCurrentPageConfig(2)}>
                      <span className="eyaa-data-36"></span>
                      <span>Add / Update Mercury Data</span>
                    </div>
                  </div>
                ) : (
                  /* <>
                    {currentPage === 1 ? (
                      <ResetBobAssessment onClose={() => setIsMainPageVisible(true)}/>
                    ) : (
                      <ManageUserList />
                    )}
                  </>*/
                  <>{getCurrentPage()}</>
                )}
              </div>
            </SimpleBar>
          </div>
        </div>
      </div>
      {isPopupVisible && (
        <div className="popup-dialog">
          <div className="dialog">
            <div className="header">
              <h5>Confirmation</h5>
              <span
                className="icon-close-filled icon"
                onClick={() => setIsPopupVisible(false)}></span>
            </div>
            <div className="content">
              Would you like to navigate without completing the operation?
              Please note that on confirming, incomplete actions will be lost.
            </div>
            <div className="footer">
              <button
                className="cancel-btn"
                onClick={() => setIsPopupVisible(false)}>
                Cancel
              </button>
              <button className="confirm-btn" onClick={onPopupConfirm}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default DataManagement;
