import React from "react";
import "./ProgressChartCard.scss";
import "./BarChartCard.scss";
import classNames from "classnames";
import BarChart from "../../BarChart/BarChart";

/* This component is for displaying the Bar chart in the both dashboard and dashboard goal page. 
If margin percentile is in top, then bar chart will be filled in green color else bar chart will be
filled in red color */

const BarChartCard = (props) => {
  const {
    percentage,
    header,
    onClick,
    isBasisPoints = false,
    percentageInfo,
  } = props;
  const { position, level } = props.rank;
  return (
    <div
      className={classNames("dashboardChartsWrapper", {
        disableCursor: !position && !percentage,
      })}
      onClick={() => onClick && onClick()}
    >
      <p className="dashboardChartHeader">{header}</p>
      {position || percentage ? (
        <div className="dashboardChartsContainer barChartWrapper">
          {position && <div className="barChartCover">
            <BarChart
              rank={level === "top" ? 100 - position : position}
              fillStart={level}
              width="50"
              height="120"
            />
          </div>}
          <div className="scoreContainer">
            <div className="barChartData">
              {position ? <>
              <div className="barInfo">
                {level !== "mid" && <span>{level}</span>}
                <span
                  className={classNames("position", {
                    topColor: level === "top",
                    bottomColor: level === "bottom",
                  })}
                >
                  {position}
                </span>
                <span>Percentile</span>
              </div>
              <div
                className={classNames("indicator", {
                  upDownArrow: level === "mid",
                  topArrow: level === "top",
                  bottomArrow: level === "bottom",
                })}
              ></div></>: <p className="notApplicable">NA</p>}
            </div>
            <div className="percentage">
              {!isBasisPoints ? (
                <>
                  <span
                    className={classNames("percentageVal", {
                      topColor: parseInt(percentage) > 0,
                      bottomColor: parseInt(percentage) < 0,
                    })}
                  >{`${percentage}%`}</span>
                  <span className="percentageInfo">{percentageInfo}</span>
                </>
              ) : (
                <>
                  <span
                    className="percentageInfo"
                    style={{ lineHeight: "10px", marginBottom: "4px" }}
                  >
                    {percentageInfo}
                  </span>
                  <span
                    className={classNames("percentageVal", {
                      topColor: level === "top",
                      bottomColor: level === "bottom",
                    })}
                  >
                    {percentage}
                  </span>
                  <span className="percentageInfo">Basis Points</span>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="barChartNoData">
          <span>No data to display</span>
        </div>
      )}
    </div>
  );
};

export default BarChartCard;
