import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  makeStyles,
  withStyles,
  Select,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import AppContext from "../../utils/appContext";
import { useContext } from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#B9B9C2",
    color: "#0F0F10",
    fontFamily: "EYInterState-Regular",
    fontSize: window.matchMedia('(max-width: 1280px)').matches ? 9: 10,
    borderRight: "1px solid #F9F9FB",
    padding: "11px 7px",
    textAlign: "center",
  },
  body: {
    fontFamily: "EYInterState-Regular",
    fontSize: window.matchMedia('(max-width: 1280px)').matches ? 9: 10,
    backgroundColor: "#F9F9FB",
    borderRight: "1px solid #B9B9C2",
    color: "#707070",
    padding: "11px 7px",
    textAlign: "center",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
  rootCont: {
    overflowY: "auto",
    overflowX: "hidden",
    boxShadow: "none",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  rootSelect: {
    padding: "11px 18px",
    fontSize: 10,
  },
  inputBase: {
    borderRadius: 0,
    width: "100%",
  },
  tooltip: {
    fontSize: 10,
  },
  popper: {
    backgroundColor: "#747474",
  },
  rowBg: {
    backgroundColor: "#ABD5F6 !important",
  },
});

const BobTable = ({
  bobTotalObj,
  bobDetail,
  isEditMode,
  setFocusClientSelected,
  setRoleSelected,
  bobStatus,
  setPartOfBobSelected,
}) => {
  const classes = useStyles();
  const myContext = useContext(AppContext);
  const fy = myContext.financialYear ? `${myContext.financialYear}`.substring(2, 4) : '22';
  return (
    <div className="clientTable">
      <TableContainer classes={{ root: classes.rootCont }}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: "6%" }}>
                Client ID
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "left", width: "10%" }}>
                Client Name
              </StyledTableCell>
              <StyledTableCell style={{ textAlign: "left" }}>
                Account Sector
              </StyledTableCell>
              <StyledTableCell style={{ width: "4%" }}>Region</StyledTableCell>
              <StyledTableCell style={{ width: "4%" }}>
                SEC Indicator
              </StyledTableCell>
              <StyledTableCell style={{ width: "8.2%" }}>
                {`FY${fy} TER (YTD)`}
              </StyledTableCell>
              <StyledTableCell style={{ width: "8.2%" }}>
                {`FY${fy-1} TER (YTD)`}
              </StyledTableCell>
              <StyledTableCell style={{ width: "8.2%" }}>
               {` FY${fy} Revenue Plan (TER)`}
              </StyledTableCell>
              <StyledTableCell style={{ width: "8.2%" }}>
                {`FY${fy} Margin $ YTD`}
              </StyledTableCell>
              <StyledTableCell style={{ width: "5%" }}>
                {`FY${fy} Margin % (YTD)`}
              </StyledTableCell>
              <StyledTableCell style={{ width: "5%" }}>
                {`FY${fy-1} Margin % (YTD)`}
              </StyledTableCell>
              <StyledTableCell style={{ width: "5%" }}>
                {`FY${fy} Client Target Margin %`}
              </StyledTableCell>
              <StyledTableCell style={{ width: "3%" }}>Role</StyledTableCell>
              <StyledTableCell style={{ width: "3%" }}>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title="Your primary accounts – those that you directly influence"
                  placement="bottom-end"
                >
                  <span>Focus Client</span>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell style={{ width: "5%" }}>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={`Include all accounts in your BOB in FY${fy}`}
                  placement="bottom-end"
                >
                  <span>{`In BOB at any time during FY${fy} ?`}</span>
                </Tooltip>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bobDetail &&
              bobDetail.map((row, i) => (
                <StyledTableRow key={row.clientId}>
                  <StyledTableCell
                    className={row.isNewClient && classes.rowBg}
                    component="th"
                    scope="row"
                  >
                    {row.clientId}
                  </StyledTableCell>
                  <StyledTableCell
                    className={row.isNewClient && classes.rowBg}
                    style={{ textAlign: "left" }}
                  >
                    {row.client}
                  </StyledTableCell>
                  <StyledTableCell
                    className={row.isNewClient && classes.rowBg}
                    style={{ textAlign: "left" }}
                  >
                    {row.accountSector}
                  </StyledTableCell>
                  <StyledTableCell className={row.isNewClient && classes.rowBg}>
                    {row.engagementRegion}
                  </StyledTableCell>
                  <StyledTableCell className={row.isNewClient && classes.rowBg}>
                    {row.secInd}
                  </StyledTableCell>
                  <StyledTableCell
                    className={row.isNewClient && classes.rowBg}
                    style={{ textAlign: "right" }}
                  >
                    {row.currentTER && row.currentTER < 0
                      ? "$ (" + Math.abs(row.currentTER).toLocaleString() + ")"
                      : row.currentTER === null
                      ? "N/A"
                      : "$ " + row.currentTER.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell
                    className={row.isNewClient && classes.rowBg}
                    style={{ textAlign: "right" }}
                  >
                    {row.priorTER && row.priorTER < 0
                      ? "$ (" + Math.abs(row.priorTER).toLocaleString() + ")"
                      : row.priorTER === null
                      ? "N/A"
                      : "$ " + row.priorTER.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell
                    className={row.isNewClient && classes.rowBg}
                    style={{ textAlign: "right" }}
                  >
                    {row.revenueGoalIn && row.revenueGoalIn < 0
                      ? "$ (" +
                        Math.abs(row.revenueGoalIn).toLocaleString() +
                        ")"
                      : row.revenueGoalIn === null
                      ? "N/A"
                      : "$ " + row.revenueGoalIn.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell
                    className={row.isNewClient && classes.rowBg}
                    style={{ textAlign: "right" }}
                  >
                    {row.marginIn && row.marginIn < 0
                      ? "$ (" + Math.abs(row.marginIn).toLocaleString() + ")"
                      : row.marginIn === null
                      ? "N/A"
                      : "$ " + row.marginIn.toLocaleString()}
                  </StyledTableCell>
                  <StyledTableCell
                    className={row.isNewClient && classes.rowBg}
                    style={{
                      textAlign: "right",
                      color:
                        row.regionMarginYTD > 0
                          ? row.curentMarginYTD > row.regionMarginYTD
                            ? "#2DB757"
                            : row.curentMarginYTD !== 0 && "#E21E1E"
                          : "#707070",
                    }}
                  >
                    {row.curentMarginYTD !== null
                      ? row.curentMarginYTD + "%"
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell
                    className={row.isNewClient && classes.rowBg}
                    style={{ textAlign: "right" }}
                  >
                    {row.priorMarginYTD !== null
                      ? row.priorMarginYTD + "%"
                      : "N/A"}
                  </StyledTableCell>
                  <StyledTableCell
                    className={row.isNewClient && classes.rowBg}
                    style={{ textAlign: "right" }}
                  >
                    {row.regionMarginYTD !== null
                      ? row.regionMarginYTD + "%"
                      : "N/A"}
                  </StyledTableCell>
                  {!isEditMode && (
                    <StyledTableCell
                      className={row.isNewClient && classes.rowBg}
                      style={
                        row.orgRole &&
                        row.role !== row.orgRole &&
                        bobStatus &&
                        bobStatus.toLowerCase() !== "completed"
                          ? {
                              backgroundColor: "#ABD5F6",
                              textTransform: "uppercase",
                            }
                          : {
                              backgroundColor: "#EFEBF9",
                              textTransform: "uppercase",
                            }
                      }
                    >
                      {row.role}
                    </StyledTableCell>
                  )}
                  {isEditMode && (
                    <StyledTableCell
                      className={row.isNewClient && classes.rowBg}
                      style={
                        row.orgRole &&
                        row.role !== row.orgRole &&
                        bobStatus &&
                        bobStatus.toLowerCase() !== "completed"
                          ? { backgroundColor: "#ABD5F6", padding: 0 }
                          : { backgroundColor: "#EFEBF9", padding: 0 }
                      }
                    >
                      <Select
                        labelId="role-client-select-label"
                        id="role-client"
                        value={row.role && row.role.toLowerCase()}
                        onChange={(e) => setRoleSelected(i, e)}
                        variant="outlined"
                        classes={{ root: classes.rootSelect }}
                        className={`${classes.inputBase} ${
                          row.isNewClient && classes.rowBg
                        }`}
                        style={
                          row.orgRole && row.role !== row.orgRole
                            ? { backgroundColor: "#ABD5F6" }
                            : { backgroundColor: "#EFEBF9" }
                        }
                        IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      >
                        <MenuItem value={"ep"}>EP</MenuItem>
                        <MenuItem value={"cp"}>CP</MenuItem>
                      </Select>
                    </StyledTableCell>
                  )}
                  {!isEditMode && (
                    <StyledTableCell
                      className={row.isNewClient && classes.rowBg}
                      style={
                        row.orgFocusClient &&
                        row.focusClient !== row.orgFocusClient &&
                        bobStatus &&
                        bobStatus.toLowerCase() !== "completed"
                          ? { backgroundColor: "#ABD5F6" }
                          : { backgroundColor: "#EFEBF9" }
                      }
                    >
                      {row.focusClient}
                    </StyledTableCell>
                  )}
                  {isEditMode && (
                    <StyledTableCell
                      className={row.isNewClient && classes.rowBg}
                      style={
                        row.orgFocusClient &&
                        row.focusClient !== row.orgFocusClient &&
                        bobStatus &&
                        bobStatus.toLowerCase() !== "completed"
                          ? { backgroundColor: "#ABD5F6", padding: 0 }
                          : { backgroundColor: "#EFEBF9", padding: 0 }
                      }
                    >
                      <Select
                        labelId="focus-client-select-label"
                        id="focus-client"
                        value={row.focusClient}
                        onChange={(e) => setFocusClientSelected(i, e)}
                        variant="outlined"
                        classes={{ root: classes.rootSelect }}
                        className={`${classes.inputBase} ${
                          row.isNewClient && classes.rowBg
                        }`}
                        style={
                          row.orgFocusClient &&
                          row.focusClient !== row.orgFocusClient
                            ? { backgroundColor: "#ABD5F6" }
                            : { backgroundColor: "#EFEBF9" }
                        }
                        IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      >
                        <MenuItem value={"Y"}>Y</MenuItem>
                        <MenuItem value={"N"}>N</MenuItem>
                      </Select>
                    </StyledTableCell>
                  )}
                  {!isEditMode && (
                    <StyledTableCell
                      className={row.isNewClient && classes.rowBg}
                      style={
                        row.orgIsPartOfBob &&
                        row.isPartOfBob !== row.orgIsPartOfBob &&
                        bobStatus &&
                        bobStatus.toLowerCase() !== "completed"
                          ? { backgroundColor: "#ABD5F6" }
                          : { backgroundColor: "#EFEBF9" }
                      }
                    >
                      {row.isPartOfBob}
                    </StyledTableCell>
                  )}
                  {isEditMode && (
                    <StyledTableCell
                      className={row.isNewClient && classes.rowBg}
                      style={
                        row.orgIsPartOfBob &&
                        row.isPartOfBob !== row.orgIsPartOfBob &&
                        bobStatus &&
                        bobStatus.toLowerCase() !== "completed"
                          ? { backgroundColor: "#ABD5F6", padding: 0 }
                          : { backgroundColor: "#EFEBF9", padding: 0 }
                      }
                    >
                      <Select
                        labelId="focus-client-select-label"
                        id="focus-client"
                        value={row.isPartOfBob}
                        onChange={(e) => setPartOfBobSelected(i, e)}
                        variant="outlined"
                        classes={{ root: classes.rootSelect }}
                        className={`${classes.inputBase} ${
                          row.isNewClient && classes.rowBg
                        }`}
                        style={
                          row.orgIsPartOfBob &&
                          row.isPartOfBob !== row.orgIsPartOfBob
                            ? { backgroundColor: "#ABD5F6" }
                            : { backgroundColor: "#EFEBF9" }
                        }
                        IconComponent={(props) => <ExpandMoreIcon {...props} />}
                      >
                        <MenuItem value={"Y"}>Y</MenuItem>
                        <MenuItem value={"N"}>N</MenuItem>
                      </Select>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default BobTable;
