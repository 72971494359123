import React, { useCallback, useState, useEffect, useContext } from "react";
import "./BookOfBusiness.scss";
import SideMenu from "../../SideMenu/SideMenu";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import * as myConsts from "../../../constants/constants";
import { useDispatch, useSelector } from "react-redux";
import AppContext from "../../../utils/appContext";
import RollForwardBoB from "./RollForwardBoB/RollForwardBoB";
import AddMercuryData from "./AddMercuryData/AddMercuryData";
import AddRegionalData from "./AddRegionalData/AddRegionalData";
import GenerateBob from "./GenerateBob/GenerateBob";
import _ from "lodash";
import Loader from "../../Loader/Loader";
import { toast, Flip } from "react-toastify";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const BookofBusiness = (props) => {
  const lockId = "custom-id-lock";
  const dispatch = useDispatch();
  const generateBookOfBusiness = useSelector((state) => state.generateBookOfBusiness);
  // const getBobRollforwardStatus = useSelector((state) => state.getBobRollforwardStatus);
  const myContext = useContext(AppContext);  
  const crumbs = [{ name: "Admin", path: myConsts.routeAdmin }];
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [rollForwardData, setRollForwardData] = useState({ fromYear: "", fromPhase: "", toYear: "", toPhase: "", isRollForwardBobDone: "" })
  const [popupContentType, setPopupContentType] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [firstTime, setFirstTime] = useState(true);

  const useStyles = makeStyles((theme) => ({
    tooltip: {
      fontSize: 12,
      marginLeft: 10,
    },
  }));

  const classes = useStyles();
  const getBobRollforwardStatus = useSelector(
    (state) => state.getBobRollforwardStatus
  );
  const getBobRollforwardToNextPhase = useSelector(
    (state) => state.getBobRollforwardToNextPhase
  );
  const bobAdminActionStatus = useSelector(
    (state) => state.bobAdminActionStatus
  );

  const [isMainPageVisible, setIsMainPageVisible] = useState(
    sessionStorage.getItem("currentPageName")
      ? sessionStorage.getItem("currentPageName") !== "Book of Business"
        ? false
        : true
      : true
  );
  const [currentPage, setCurrentPage] = useState(
    sessionStorage.getItem("currentPageName")
      ? sessionStorage.getItem("currentPageName") ===
        "Roll Forward Book of Business"
        ? 1
        : sessionStorage.getItem("currentPageName") === "Add Mercury Data"
          ? 2
          : sessionStorage.getItem("currentPageName") === "Add Regional Data"
            ? 3
            : sessionStorage.getItem("currentPageName") ===
              "Generate Book of Business"
              ? 4
              : null
      : null
  );
  const [currentPageName, setCurrentPageName] = useState(
    sessionStorage.getItem("currentPageName") || "Book of Business"
  );

  const setCurrentPageConfig = useCallback((pageNo) => {
    switch (pageNo) {
      case 1:
        setCurrentPageName("Roll Forward Book of Business");
        sessionStorage.setItem("currentPageName", "Roll Forward Book of Business");
        break;
      case 2:
        setCurrentPageName("Add Mercury Data");
        sessionStorage.setItem("currentPageName", "Add Mercury Data");
        break;
      case 3:
        setCurrentPageName("Add Regional Data");
        sessionStorage.setItem("currentPageName", "Add Regional Data");
        break;
      case 4:
        setCurrentPageName("Generate Book of Business");
        sessionStorage.setItem("currentPageName", "Generate Book of Business");
        break;
      default:
        setCurrentPageName("");
    }
    setCurrentPage(pageNo);
    setIsMainPageVisible(false);
  }, []);

  const getCurrentPage = useCallback(() => {
    switch (currentPage) {
      case 1:
        return (
          <RollForwardBoB
            onClose={() => {
              setCurrentPageName("Book of Business");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "Book of Business");
            }}
          />
        );
      case 2:
        return (
          <AddMercuryData
            rollForwardData={rollForwardData}
            onClose={() => {
              setCurrentPageName("Book of Business");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "Book of Business");
            }}
          />
        );
      case 3:
        return (
          <AddRegionalData
            rollForwardData={rollForwardData}
            onClose={() => {
              setCurrentPageName("Book of Business");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "Book of Business");
            }}
          />
        );
      case 4:
        return (
          <GenerateBob
            onClose={() => {
              setCurrentPageName("Book of Business");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "Book of Business");
            }}
          />
        );
      default:
        return "";
    }
  }, [currentPage, getBobRollforwardStatus]);

useEffect(()=>{
  // console.log(generateBookOfBusiness)
  if(generateBookOfBusiness&&
    !generateBookOfBusiness.fetching&&
    generateBookOfBusiness.data&&
    generateBookOfBusiness.data._id&&
    generateBookOfBusiness.data.status ==="In Progress"){
      // console.log("true")
      
      toast(
        <div  className="success-text">
            <i className="icon-verified-outline" />
            {"Success"}
            <b> {": "}</b>
            {"Successfully generated book of business"}
        </div>,
        {
            position: "top-center",
            transition: Flip,
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        }
      );
      getLockStatusAPI();
    }else if(generateBookOfBusiness&&
      !generateBookOfBusiness.fetching&&
      generateBookOfBusiness.data&&
      generateBookOfBusiness.data.code ==="BLOCKING_OPERATION"){
        triggerLock();
        setTimeout(function () {
          getLockStatusAPI();
      }, 5000);
      dispatch({
        type: "GENERATE_BOOK_OF_BUSINESS_RESET",
        payload: {},
      });
      }
      
},[generateBookOfBusiness])

  const  generateBobAPI = ()=>{
    try {
      dispatch({
        type: "GENERATE_BOOK_OF_BUSINESS",
        payload: {
          financialYear: rollForwardData.toYear,
          timePeriod:rollForwardData.toPhase
        },
      });
      // setIsLoading(true);
    } catch (e) {
      console.error(e);
    }
    setIsPopupVisible(false)
  }

  const showBoBPopup = useCallback((index) => {
    setPopupContentType(index);
    setIsPopupVisible(true);
  }, []);

  const triggerLock = () => {
    myContext.setIsLockPopup(true);
    if (!document.getElementById(lockId))
    toast.warn(
      <div
        style={{
          // textTransform: "capitalize",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "EYInterState-Regular",
          fontSize: "12px",
        }}
      >
        <i className="icon-warning-outline" />
        {`Previous operation initiated by ${bobAdminActionStatus.data.userName} is still in progress. It will take some time. You can
        perform this action after the operation is completed. We appreciate your
        patience!`}
      </div>,
      {
        position: "top-center",
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        toastId: lockId,
      }
    );
  };

  const notify = useCallback(() => {
    toast(
      <div className="success-text">
        <span className="icon-verified-outline icon"></span>
        <p>
          <span>Success: </span>
          <span>Roll Forwarding BOB To Next Phase.</span>
        </p>
      </div>,
      {
        position: "top-center",
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  useEffect(() => {
    //Api to get the financial year and time period
    dispatch({
      type: "GET_BOB_ROLLFORWARD_STATUS",
      payload: {},
    });
    setIsLoading(false);
  }, [getBobRollforwardToNextPhase]);

  const getLockStatusAPI = async () => {
    // Api to get BoB Admin action status or Lock status
    try {
      if (!_.isEmpty(getBobRollforwardStatus.data)) {
        dispatch({
          type: "BOB_ADMIN_ACTION_STATUS",
          payload: {
            financialYear: getBobRollforwardStatus.data.toYear,
            timePeriod: getBobRollforwardStatus.data.toPhase,
          },
        });
        setRollForwardData({
          fromYear: getBobRollforwardStatus.data.fromYear,
          fromPhase: getBobRollforwardStatus.data.fromPhase,
          toYear: getBobRollforwardStatus.data.toYear,
          toPhase: getBobRollforwardStatus.data.toPhase,
          isRollForwardBobDone: getBobRollforwardStatus.data.isRollForwardBobDone
        });

        dispatch({
          type: "GET_BOB_ROLLFORWARD_STATUS_RESET",
          payload: {},
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const callAPIToRollforwardBoBToNextPhase = () => {
    // Api to Rollforward the BoB
    try {
      dispatch({
        type: "ROLLFORWARD_BOB_TO_NEXT_PHASE",
        payload: {
          financialYear: rollForwardData.toYear,
          timePeriod: rollForwardData.toPhase,
        },
      });
      setIsLoading(true);
    } catch (e) {
      console.error(e);
    }
    getLockStatusAPI();
  };

  useEffect(() => {
    if (getBobRollforwardStatus.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      getLockStatusAPI();
    }
  }, [getBobRollforwardStatus]);

  useEffect(() => {
    if (getBobRollforwardToNextPhase.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }

    if (
      !_.isEmpty(getBobRollforwardToNextPhase.data) &&
      getBobRollforwardToNextPhase.data.status === "In Progress"
    ) {
      notify();
      setTimeout(function () {
        getLockStatusAPI();
      }, 15000);
      dispatch({
        type: "ROLLFORWARD_BOB_TO_NEXT_PHASE_RESET",
        payload: {},
      });
    }
  }, [getBobRollforwardToNextPhase, notify, dispatch]);


  useEffect(() => {
    //lock status API response
    if (
      !bobAdminActionStatus.fetching &&
      bobAdminActionStatus.data &&
      bobAdminActionStatus.data.proceedAction
    ) {
      //Succefully Completed
      setIsLoading(false);
      myContext.setDisableUserUpdation(false);
    } else if (
      !bobAdminActionStatus.fetching &&
      !_.isEmpty(bobAdminActionStatus.data) &&
      !bobAdminActionStatus.data.proceedAction
    ) {
      //not completed
      if (firstTime) {
        myContext.setIsWarningPopup(false);
        setFirstTime(false);
        setIsLoading(false);
        triggerLock();
      }
      myContext.setDisableUserUpdation(true);
      // dispatch({ 
      //   type: "BOB_ADMIN_ACTION_STATUS_RESET",
      //   payload: {},
      // });
      setTimeout(function () {
        getLockStatusAPI();
      }, 15000);
    }
  }, [bobAdminActionStatus]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="admin-container">
        <div className="admin-info-main-sidemenu">
          <SideMenu
            minimized={true}
            isminimizedMainMenu={true}
            isMainMenu={true}
            sideMenuItemSelect="Admin"
          />
        </div>
        <div className="admin-content admin-assessment-content">
          <div className="second-header admin-crumbs">
            <i
              className="icon-arrow-left"
              onClick={() => {
                if (!isMainPageVisible) {
                  setCurrentPageName("Book of Business");
                  setIsMainPageVisible(true);
                  sessionStorage.setItem("currentPageName", "Book of Business");
                } else {
                  props.history.push(myConsts.routeAdmin);
                }
              }}
            />
            <div className="admin-crumb-txt">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Breadcrumb
                  crumbs={crumbs}
                  isAdmin={true}
                  isInnerAdmin={true}
                  {...props}
                />
                {!isMainPageVisible && (
                  <div className="icon-chevron-right iconStyling">
                    <a
                      className=" userMngmnt MuiTypography-root MuiLink-root MuiLink-underlineHover admin-crumb MuiTypography-colorInherit"
                      onClick={() => {
                        setIsMainPageVisible(true);
                        setCurrentPageName("Book of Business");
                        sessionStorage.setItem(
                          "currentPageName",
                          "Book of Business"
                        );
                      }}
                    >
                      Book of Business
                    </a>
                  </div>
                )}
              </div>
              <h4>{currentPageName}</h4>
            </div>
          </div>
          <div className="manage-bob-content">
            <div
              className="main-content"
              style={{ padding: isMainPageVisible ? "12px" : "0px" }}
            >
              {isMainPageVisible ? (
                <div className="card-block">
                  <Tooltip classes={{ tooltip: classes.tooltip }} title={rollForwardData.isRollForwardBobDone ? "This operation was already completed": ""} placement="bottom-start">
                      <div
                        className={
                          "card " +
                          (!rollForwardData.isRollForwardBobDone && bobAdminActionStatus.data.proceedAction ? "" : "opacityReduced")
                        }
                        onClick={(e) => {
                          if (
                            // (rollForwardData.isRollForwardBobDone && !bobAdminActionStatus.data.proceedAction) ||
                            (!rollForwardData.isRollForwardBobDone && !bobAdminActionStatus.data.proceedAction)
                          ) {
                            triggerLock();
                            myContext.setIsWarningPopup(false);
                          } else if (!rollForwardData.isRollForwardBobDone && bobAdminActionStatus.data.proceedAction) {
                            showBoBPopup(0);
                          }
                        }}
                      >
                        <span className="eyaa-web-063"></span>
                        <span>Roll Forward Book of Business</span>
                      </div>
                    </Tooltip>

                  <div
                    className={
                      "card " +
                      (rollForwardData.isRollForwardBobDone
                        ? ""
                        : "opacityReduced")
                    }
                    onClick={() => {
                      if (rollForwardData.isRollForwardBobDone) {
                        setCurrentPageConfig(2);
                      }
                    }}
                  >
                    <span className="eyaa-data-36"></span>
                    <span>Add Mercury Data</span>
                  </div>
                  <div
                    className={
                      "card " +
                      (rollForwardData.isRollForwardBobDone
                        ? ""
                        : "opacityReduced")
                    }
                    onClick={() => {
                      if (rollForwardData.isRollForwardBobDone) {
                        setCurrentPageConfig(3);
                      }
                    }}
                  >
                    <span className="eyaa-global-add"></span>
                    <span>Add Regional Data</span>
                  </div>
                  <div
                    className={
                      "card " +
                      (rollForwardData.isRollForwardBobDone
                        ? ""
                        : "opacityReduced")
                    }
                    onClick={() => {
                      if (rollForwardData.isRollForwardBobDone &&  !myContext.disableUserUpdation) {
                        showBoBPopup(1);
                      }else if(rollForwardData.isRollForwardBobDone &&  myContext.disableUserUpdation){
                        triggerLock()
                      }
                    }}
                  >
                    <span className="eyaa-web-210"></span>
                    <span>Generate Book of Business</span>
                  </div>
                </div>
              ) : (
                <>{getCurrentPage()}</>
              )}
            </div>
          </div>
        </div>
        {isPopupVisible && (
          <div className="popup-dialoge">
            <div className="dialog">
              <div className="header">
                <h5>Confirmation</h5>
                <span
                  className="icon-close-filled icon"
                  onClick={() => setIsPopupVisible(false)}
                ></span>
              </div>
              <div className="content">
                {popupContentType === 0 && (
                  <>
                    <p>
                      {" "}
                      Would you like to roll forward from FY{" "}
                      {rollForwardData.fromYear}{" "}
                      {rollForwardData.fromPhase} to FY{" "}
                      {rollForwardData.toYear}{" "}
                      {rollForwardData.toPhase} ?
                    </p>
                    <p>
                      {" "}
                      Please note that this operation will take some time to
                      complete. Once it is completed, we will inform you over an
                      email.
                    </p>
                  </>
                )}
                {popupContentType === 1 && (
                  <>
                    <p> Would you like to generate the book of business?</p>
                    <p>
                      {" "}
                      Please note that this operation will take some time to
                      complete. Once it is completed, we will inform you over an
                      email.
                    </p>
                  </>
                )}
              </div>
              <div className="footer">
                <button
                  className="cancel-btn"
                  onClick={() => setIsPopupVisible(false)}
                >
                  Cancel
                </button>
                {popupContentType === 0 && (
                  <>
                  <button className={
                    "confirm-btn" +
                    ((rollForwardData.isRollForwardBobDone) 
                      ? " disable-btn "
                      : "")
                  }
                  onClick={() => {
                    callAPIToRollforwardBoBToNextPhase();
                    setIsPopupVisible(false);
                }}>
                  Confirm
                  </button>
                  </>
                )}
                {popupContentType === 1 && (
                  <>
                  <button className={
                    "confirm-btn" +
                    ((!rollForwardData.isRollForwardBobDone) 
                      ? " disable-btn "
                      : "")
                  }
                  onClick={() => {
                    generateBobAPI();
                    setIsPopupVisible(false);
                  }}>
                    Confirm
                  </button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default BookofBusiness;
