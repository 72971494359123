import React, { useEffect, useState } from "react";
import "./AssessmentIntro.scss";
import { InputLabel, makeStyles, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import SimpleBar from "simplebar-react";
import ReactQuill from "react-quill";
import ReactHtmlParser from "react-html-parser";
import EditorToolbar, { modules0, formats } from "./EditToolBar";
import "react-quill/dist/quill.snow.css";
import Preview from "../Preview";
const useStyles = makeStyles((theme) => ({
  root1: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-underline": {
      width: "250px",
      height: "27px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      //   borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      //   "&:hover:not($disabled):not($focused):not($error):before": {
      //     borderBottom: "none",
      //   },
      " &:hover:before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      },
      " &:hover:after": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  labelroothalf: {
    width: "395px",
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "8px",
  },
  topSpacing: {
    marginTop: "16px",
  },
  topSyli: {
    marginBottom: " -30px",
    marginTop: "16px",
    zIndex: "8",
    width: "115px",
  },
  half: {
    display: "flex",
    flexDirection: "row",
    width: "350px",
    marginLeft: "15px",
    marginTop: "-10px",
  },
  root900: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-underline": {
      width: "870px",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
      fontFamily: "EYInterState-Regular",
    },
  },
  root: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "400px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
      fontFamily: "EYInterState-Regular",
    },
  },
  rootMulti: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "677px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  labelroot: {
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "8px",
    // fontWeight: "600",
  },
  labelroot1: {
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "10px",
    fontWeight: "600",
  },
  topSpacing: {
    marginTop: "8px",
  },
  topSyli: {
    marginBottom: " -30px",
    marginTop: "16px",
    zIndex: "8",
    width: "100px",
  },
  half: {
    display: "flex",
    flexDirection: "row",
  },
  arrow: {
    position: "absolute",
    fontSize: 14,
    width: "6em",
    height: "4em",
    //   transform: "rotate(225deg)",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  paper2: {
    backgroundColor: "white",
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 2, 2),
    width: 170,
    overflow: "hidden",
    overflowX: "hidden",
    fontSize: "11px",
  },
  options: {
    borderBottom: "1px solid grey",
    padding: "5px 0px 5px 5px",
    display: "flex",
    alignItems: "center",
    height: "24px",
    cursor: "pointer",
  },
  popper: {
    // zIndex: 1,
    left: "-55px!important",
    '&[x-placement*="top-end"]': {
      top: "-12px!important",
    },
    '&[x-placement*="bottom-end"]': {
      top: "9px!important",
    },
    '&[x-placement*="bottom"] $arrow': {
      top: 2,
      left: "105px!important",
      marginTop: "-0.9em",
      width: "6em",
      height: "2em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: "103px!important",
      marginBottom: "-3.6em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
}));

const AssessmentIntro = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  // const [description, setDescription] = useState("");
  const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "code-block",
  ];
  const handleDescriptionChange = (value, index, delta, source, editor) => {
    if (source === "user") {
      // setDisableSaveGlobalMetric(false);
    }
    if (editor.getLength() < 802) {
      props.setAssessmentData(value);
    } else {
      // let temp = description;
      props.setAssessmentData("");
      props.setAssessmentData(props.assessmentData);
    }
  };

  const descLength = props.assessmentData ? props.assessmentData.replace(/(<([^>]+)>)/ig, '').length : 0;

  return (
    <>
      <div className="assessmentIntro">
        <div
          className="goal-details"
          style={{
            marginTop: "10px",
            marginRight: "5px",
            border: "#c4c4cd solid 1px",
            height: "96%",
            padding: "0px",
          }}>
          <div className="statements1">
            <div className="statement-header">
              <div className="sub-header">{props.selectedSection}</div>
              <div className="btn-sec">
                <div className="btn" onClick={() => setOpen(true)}>
                  <i className="eyaa-image-25" style={{ marginRight: "5px" }} />
                  Preview
                </div>

                <div
                  className={
                    "btn" +
                    (props.assessmentData.replace(/(<([^>]+)>)/gi, "").length ==
                    0
                      ? " disable"
                      : "")
                  }
                  onClick={() => {
                    let plainText = props.assessmentData.replace(
                      /(<([^>]+)>)/gi,
                      ""
                    );
                    if (plainText.length !== 0) {
                      dispatch({
                        type: "UPLOAD_GENERAL_CONTENT",
                        payload: {
                          content: props.assessmentData,
                          key: "assessment",
                        },
                      });
                    }
                  }}>
                  <i className="icon-web-071" style={{ marginRight: "5px" }} />
                  Save
                </div>
              </div>
            </div>
          </div>
          <div className={classes.half}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "20px",
                marginRight: "20px",
              }}
              className="quil-section">
              <div className="quil-section">
                <EditorToolbar display={true} index={0} length={1} />

                {/* <SimpleBar
                    style={{
                      maxHeight: "125px"
                    }}> */}
                <div
                  style={{
                    marginBottom: "30px",
                  }}>
                  <InputLabel
                    htmlFor="uncontrolled-native"
                    className={classes.topSpacing}
                    classes={{
                      root: classes.labelroot,
                    }}>
                    {`Description* (${descLength}/800 Characters)`}
                  </InputLabel>

                  <div className="customReactQ">
                    <ReactQuill
                      index={0}
                      theme="snow"
                      // onFocus={() => {
                      //   console.log("focus", index);
                      //   setactiveNoteIndex(index);
                      // }}
                      value={props.assessmentData}
                      onChange={(content, delta, source, editor) =>
                        handleDescriptionChange(
                          content,
                          0,
                          delta,
                          source,
                          editor
                        )
                      }
                      modules={modules0}
                      formats={formats}
                    />
                  </div>
                </div>
                {/* </SimpleBar> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          open={isOpen}
          className="assessment-template-modal"
          onClose={() => {
            setOpen(false);
          }}
          styles={{
            modal: {
              maxWidth: "unset",
              padding: "unset",
            },
            overlay: {
              background: "rgba(0, 0, 0, 0.5)",
            },
          }}
          center>
          <div className="assessment-template-wrap">
            <div className="modal-heading">Assessment Introduction Preview</div>
            {/* <SimpleBar
              style={{
                overflowX: "hidden",
                maxHeight: "50vh",
                width: "70vw",
              }}> */}
            <div style={{ minHeight: "50vh", padding: "10px" }}>
              <div className="description-text">
                <SimpleBar
                  style={{
                    overflowX: "hidden",
                    maxHeight: "50vh",
                    width: "70vw",
                  }}>
                  <Preview content={props.assessmentData}/>
                </SimpleBar>
              </div>
            </div>
            {/* </SimpleBar> */}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AssessmentIntro;
