import "./DateCalender.scss";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { IconButton, InputAdornment } from "@material-ui/core";
// import Icon from "@material-ui/core/Icon";
import "moment/locale/nl-be";
// import moment from "moment";
import { Search } from "@material-ui/icons";
import DateRangeIcon from "@material-ui/icons/DateRange";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textroot: {
    fontSize: "12px",
    "& .MuiFormLabel-root": {
      fontSize: "1.2rem",
      fontFamily: "EYInterstate-Regular",
    },
  },
  inputRoot: {
    fontSize: "1.2rem",
    fontFamily: "EYInterstate-Regular",
    "& .MuiFormLabel-root": {
      color: "red", // or black
    },
  },
  labelroot: {
    fontSize: "1.2rem",
    fontFamily: "EYInterstate-Regular",
  },
  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: 252,
    margin: "5px 5px 0px 5px",
    fontFamily: "EYInterstate-Regular",
    marginRight: "0px",
    fontSize: "1.2rem",
    // textTransform: "uppercase",
  },
  formctrl: {
    backgroundColor: "rgb(246, 246, 250)",
    marginRight: "20px",
    marginTop: "20px",
  },
  focused: {
    borderColor: "yellow",
  },
  helper: {
    marginLeft: "10px",
  },
  rootIcon: {
    position: "absolute",
    right: "8px",
    top: "26px",
    fill: "rgba(0, 0, 0, 0.70)",
    fontSize: "1.7rem",
    cursor: "pointer",
  },
}));

const DateCalender = (props) => {
  const classes = useStyles();
  // const [open, setOpen] = React.useState(false);

  return (
    <FormControl
      className="dateCalender"
      xs={12}
      sm={6}
      classes={{
        filled: classes.selectroot,
        root: classes.formctrl,
      }}
      error={props.errorMessage[props.varName] !== ""}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          keyboard
          // open={open}
          variant="inline"
          disabled={props.disabled}
          id={props.label}
          label={props.label}
          value={props.defaultValue}
          onChange={(date) => {
            // setOpen(false);
            props.dateOnChange(props.varName, date);
          }}
          className={classes.textField}
          format="dd-MMM-yyyy"
          placeholder="DD-MMM-YYYY"
          disablePast={props.disablePast}
          autoOk={true}
          views={["date", "month", "year"]}
          InputProps={{
            classes: { root: classes.inputRoot },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  classes={{
                    root: `${props.disabled ? "iconDisableRoot" : "iconroot"}`,
                  }}>
                  <DateRangeIcon />
                  {/* <AlarmIcon /> */}
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
          required={props.required ? true : false}
          // minDate={props.minDate}
          maxDate={props.maxTenureDate}
          // minDateMessage={props.minDateMessage}
          keyboardIcon={<Search className={classes.root} />}
        />
      </MuiPickersUtilsProvider>
      <FormHelperText
        classes={{
          root: classes.helper,
        }}>
        {props.errorMessage[props.varName]}
      </FormHelperText>
      {/* <DateRangeIcon
        className={classes.rootIcon}
        onClick={() => {
          setOpen(true);
        }}
      /> */}
    </FormControl>
  );
};
export default DateCalender;
