/* UI and data handling of assesment section.
A logged in user can view his own assessment or view assessment of his counselee */
import React, { useState, useContext, useEffect } from "react";
import SideMenu from "../SideMenu/SideMenu";
import "./Survey.scss";
// import { Button } from "@material-ui/core";
import QualitySection from "./QualitySection";
import AppContext from "../../utils/appContext";
import { capitalizeFirstLetter } from "../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/Loader";
import Alert from "../Alert/AlertComponent";
import { useAlert } from "../../helpers/useAlert";
import NotificationDialog from "../Header/NotificationDialog";
import { authProvider, APIscope } from "./../../Authentication/authProvider";
import SummaryView from "../Summary/SummaryView";
import SimpleBar from "simplebar-react";
import AlertSubmitPopup from "../Common/AlertSubmitPopup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Flip } from "react-toastify";
import * as myConsts from "../../constants/constants";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import _ from "lodash";
import Preview from "../Admin/ContentManagement/Preview"
import truncate from 'truncate-html';

const Survey = (props) => {
  const {
    match: { params },
    goalObj,
  } = props;
  const dispatch = useDispatch();
  const isOutsideClick = useSelector((state) => state.isOutsideClick);
  const getAllOptions = useSelector((state) => state.getAllOptions);
  const submitUserAssessment = useSelector(
    (state) => state.submitUserAssessment
  );
  // const state1 = useSelector((state) => state);
  const myContext = useContext(AppContext);
  const [alertData, toogleShow, ChangeTitle] = useAlert();
  const [assessmentObj, setAssessmentObj] = useState({});
  const [menuList, setMenuList] = useState([]); //list of pillarobj
  const [loader, setLoader] = useState(false);
  const userBobSummary = useSelector((state) => state.userBobSummary);
  // const [refreshCurrent, setRefreshCurrent] = useState(false);
  const [assesmentUserObj, setAssesmentUserObj] = useState([]); //user submitted data corresponsing to all year and period
  const [allOptionList, setAllOptionList] = useState({});
  const [comittedOptionList, setAllcomittedFlagOptionList] = useState({});
  const [initialAssesmentUserObj, setInitialAssesmentUserObj] = useState([]); //backup data ffor checking if ther is a change in data to trigger save alert
  const [isQusFullScreen, setQusFullScreen] = useState(false);
  const [selectedSection, setSelectedSection] = useState({}); //set current active pillar data
  const [activeTab, setActiveTab] = useState(""); //store goal Id of active goal
  const [impactStmtFocusChecked, setImpactStmtFocusChecked] = useState({});
  const activePeriod = myContext.activePeriod;
  const currentPeriod = myContext.currentPeriod;
  const alertHeader = "Confirm Changes";
  const [progressPercentage, setProgressPercentage] = useState([]); //submission percentage of assessment,summary can b viewed only if 100%
  const [summaryData, setSummaryData] = useState([]); //store data for summary-changed and committed data pillar & goal wise
  const alertMessage = "Do you want to save the changes before you proceed?";

  const alertSubmitTxt = "Save";
  const [showAlertSubmitPopup, setShowAlertSubmitPopup] = useState(false);
  // const [initialValueObj, setInitialValueObj] = useState({});
  const timePeriods = myConsts.TimePeriodsArray;
  let activePPMDuserId =
    myContext.activePPMDuserId || sessionStorage.activePPMDuserId;

  // let long_hyphen = " " + "\u2014" + " ";

  const [isPlanDataNull, setIsPlanDataNull] = useState(true); //if prev phase data is empty
  const allSectionDataFetch = useSelector(
    (state) => state.getAssessmentSectionList
  );
  let assessmentSectionList = allSectionDataFetch
    ? allSectionDataFetch.data
      ? allSectionDataFetch.data.data
        ? allSectionDataFetch.data.data.assessmentDetails
        : []
      : []
    : [];

  const generalContent = useSelector(
    (state) => state.generalContent
  );
  const assessmentContent = _.get(generalContent, "data.assessment", {});

  // const alertSubmitPopupShow = () => {
  //   myContext.setOpenSavePopup(true);
  // };
  const cancelAlertSubmitPopup = () => {
    // dispatch({
    //   type: "OUTSIDE_CLICK_CANCEL",
    //   payload: {},
    // });
    myContext.setOpenSavePopup(false);
  };
  const submitPopupChanges = async () => {
    // handleSave();
    dispatch({
      type: "OUTSIDE_CLICK_TRUE",
      payload: {},
    });
    myContext.setOpenSavePopup(false);
  };
  const crumbs = [
    {
      name: "Self-Assessment - " + capitalizeFirstLetter(currentPeriod === myConsts.TimePeriodYearEnd ? currentPeriod : currentPeriod?.replace("-", "")),
      path: myConsts.routeAssessment,
    },
  ];
  useEffect(() => {
    //To disable assessment form edit ,if bob is not submitted
    if (userBobSummary && userBobSummary.data) {
      const data = userBobSummary.data;
      if (data && data.status) {
        if (data.status.toLowerCase() !== myConsts.statusSubmitted) {
          myContext.setDisableUpdateSummary(true);
          sessionStorage.setItem("disableUpdateSummary", true);
        }
      }
    }
  }, [userBobSummary]);

  useEffect(() => {
    /*  API calls to fetch bob summary data,all qstns and answer options,current user assessment status 
    and current user assessment data ,on initial load*/
    (async function () {
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "GET_ASSESSMENT_SECTION_LIST",
          payload: {
            financialYear: myContext.financialYear,
          },
        });
        dispatch({
          //to get bob submission status and disable assessment update if bob is not submitted
          type: "GET_USER_BOB_SUMMARY",
          payload: {
            token: token,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
            userId: getCurrentUser(),
          },
        });
        dispatch({
          type: "GET_USER_ASSESSMENT_STATUS",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
            userId: getCurrentUser(),
          },
        });
        dispatch({
          type: "GET_ALL_ASSESSMENT_DATA",
          payload: {
            token: idToken,

            activePeriod: currentPeriod,
            financialYear: myContext.financialYear,
          },
        });
        dispatch({
          type: "GET_ALL_USER_ASSESSMENT_DATA",
          payload: {
            token: idToken,

            activePeriod: currentPeriod,
            financialYear: myContext.financialYear,
          },
        });

        dispatch({
          type: "GET_ALL_OPTIONS", //get all option id's and description like {option_id: "t1000",options: "I do not have this opportunity in my BOB"}
          payload: {
            activePeriod: currentPeriod,
            financialYear: myContext.financialYear,
            tokens: idToken,
          },
        });
        dispatch({
          type: "GET_GENERAL_CONTENT",
          payload: {},
        });
      } catch (e) {
        console.error(e);
      }
    })();
    activePPMDuserId =
      myContext.activePPMDuserId || sessionStorage.activePPMDuserId;
    //useEffect(() => {
    myContext.setCurrentGoal({
      goalId: -1,
      pillarId: -1,
    });
    dispatch({
      type: "OUTSIDE_CLICK_RESET",
      payload: {},
    });
    // if (myContext.bobStatus !== myConsts.statusSubmitted) {
    //   myContext.setDisableUpdateSummary(true);
    // }
    if (Array.isArray(document.getElementsByClassName("not-found-wrap")))
      document.getElementsByClassName("not-found-wrap")[0].style.display =
        "none";
  }, []);
  //}, [props.location]);

  const allAssesmentData = useSelector((state) => state.allAssesmentData);
  const saveUserAssesmentData = useSelector(
    (state) => state.saveUserAssesmentData
  );
  const userAssessmentStatus = useSelector(
    //store assessment status of specific year and period
    (state) => state.userAssessmentStatus
  );
  const allUserAssesmentData = useSelector(
    (state) => state.allUserAssesmentData //get assessment data of user for all yera and period
  );

  const bookOfBusinessData = useSelector((state) => state.bookOfBusinessData);

  useEffect(() => {
    if (
      bookOfBusinessData &&
      bookOfBusinessData.data &&
      bookOfBusinessData.data.status === "success"
    ) {
      const {
        data: { data },
      } = { ...bookOfBusinessData };
      myContext.setIsBobApproved(data.isBOBApproved);
    }
    // eslint-disable-next-line
  }, [bookOfBusinessData]);

  useEffect(() => {
    if (
      getAllOptions &&
      !getAllOptions.fetching &&
      getAllOptions.data &&
      getAllOptions.data.length
    ) {
      let temp = {};
      let comitted = {};

      getAllOptions.data.map((item) => {
        temp[item.option_id] = item.options;
        comitted[item.option_id] = item.committed;
      });
      setAllOptionList(temp);
      setAllcomittedFlagOptionList(comitted);
      console.log(comitted);
      // console.log(temp);
    }
  }, [getAllOptions]);

  useEffect(() => {
    let timeout;
    /* handle response from asseement submission API.Shows success toast , updates submission status and disable form edit*/
    (async function () {
      if (
        submitUserAssessment &&
        !submitUserAssessment.fetching &&
        submitUserAssessment.data &&
        submitUserAssessment.data.statuscode &&
        submitUserAssessment.data.statuscode === "200"
      ) {
        // ChangeTitle(
        //   "Thank you for participating in LEAD 4D.Click on Download Report button to get PDF of the Summary "
        // );
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          props.history.push(myConsts.routeHome);
          window.location.reload();
        }, 5000);
        myContext.setIsWarningPopup(false);
        toast.warn(
          <div>
            <i className="icon-verified-outline" />
            {`Thank you for participating in LEAD 4D ${myContext.currentPeriod}. Click on 'Download
            Report' to view and save a PDF summary of your input.`}
          </div>,
          {
            position: "top-center",
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        dispatch({
          type: "SUBMIT_USER_ASSESSMENT_RESET",
          payload: {},
        });
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "GET_USER_ASSESSMENT_STATUS",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
            userId: getCurrentUser(),
          },
        });
        if (
          submitUserAssessment.data.assessmentStatus.toLowerCase() ===
          myConsts.statusSubmitted ||
          submitUserAssessment.data.assessmentStatus.toLowerCase() ===
          myConsts.statusCompleted
        ) {
          //if submitted or completed status disable edit and submission status set as true
          myContext.setAssessmentSubmissionStatus(true);
          sessionStorage.setItem("assessmentSubmissionStatus", true);
          myContext.setDisableUpdateSummary(true);
          sessionStorage.setItem("disableUpdateSummary", true);
          myContext.updateReviewerMessage({
            msg: "",
            from: "",
            read: true,
          });
        } else if (
          submitUserAssessment.data.assessmentStatus.toLowerCase() ===
          myConsts.statusRequestedChange &&
          activePPMDuserId &&
          activePPMDuserId !== null
        ) {
          //if reviewer role and statsus is requested change disable edit and set submissin status as false
          myContext.setDisableUpdateSummary(true);
          sessionStorage.setItem("disableUpdateSummary", true);
          myContext.setAssessmentSubmissionStatus(false);
          sessionStorage.setItem("assessmentSubmissionStatus", false);
        }
      } else if (
        submitUserAssessment &&
        !submitUserAssessment.fetching &&
        submitUserAssessment.data &&
        submitUserAssessment.data.statuscode &&
        submitUserAssessment.data.statuscode === "409"
      ) {
        if (
          submitUserAssessment.data.assessmentStatus.toLowerCase() ===
          "notsubmitted"
        ) {
          myContext.setIsWarningPopup(true);
          toast.warn(
            <div>
              <i className="icon-warning-outline" />
              {submitUserAssessment.data.status +
                ".Data for following goals were not saved:"}

              {submitUserAssessment.data.missedGoalsDetails.map((item) => (
                <>
                  {" "}
                  <br />
                  <br />
                  <span>
                    {'Goal "' +
                      item.goalName +
                      '" within Pillar "' +
                      item.pillarName +
                      '"'}
                  </span>
                </>
              ))}
            </div>,
            {
              position: "bottom-center",
              transition: Flip,
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          dispatch({
            type: "SUBMIT_USER_ASSESSMENT_RESET",
            payload: {},
          });
          // console.log(menuList, "menuList");
          menuList.forEach((i) => {
            if (
              submitUserAssessment.data.missedGoalsDetails.length > 0 &&
              i.pillar ===
              submitUserAssessment.data.missedGoalsDetails[0].pillarName
            ) {
              console.log(i.pillarId, "menuList");
              i.tabs.map((it, ind) => {
                if (
                  it ===
                  submitUserAssessment.data.missedGoalsDetails[0].goalName
                ) {
                  console.log(i.tabIds[ind], i.pillarId, "menuList");
                  myContext.setCurrentGoal({
                    goalId: i.tabIds[ind],
                    pillarId: i.pillarId,
                  });
                }
              });
            } else {
              if (menuList.length > 0 && menuList[0].tabIds.length > 0)
                myContext.setCurrentGoal({
                  goalId: menuList[0].tabIds[0],
                  pillarId: menuList[0].pillarId,
                });
            }
          });
          dispatch({
            type: "GET_ALL_USER_ASSESSMENT_DATA",
            payload: {
              activePeriod: currentPeriod,
              financialYear: myContext.financialYear,
            },
          });
        }
      }
    })();
    // eslint-disable-next-line
  }, [submitUserAssessment]);

  useEffect(() => {
    if (userAssessmentStatus && userAssessmentStatus.data) {
      const assessmentData = userAssessmentStatus.data;
      if (
        assessmentData.data &&
        Object.keys(assessmentData.data).length > 0 &&
        assessmentData.data !== "no data"
      ) {
        const completionFlg =
          assessmentData.data.status.toLowerCase() ===
          myConsts.statusCompleted ||
          assessmentData.data.status.toLowerCase() === myConsts.statusSubmitted;
        myContext.setAssessmentSubmissionStatus(completionFlg);
        sessionStorage.setItem("assessmentSubmissionStatus", completionFlg);
      }
    }
    // eslint-disable-next-line
  }, [userAssessmentStatus]);

  // useEffect(() => {
  //   if (params.isSummary && params.isSummary === "summary") {
  //     myContext.setNavToSummary(true);
  //   }
  //   // eslint-disable-next-line
  // }, [params.isSummary]);
  useEffect(() => {
    if (
      allUserAssesmentData &&
      allUserAssesmentData.data &&
      allUserAssesmentData.data.status === "success"
    ) {
      // setLoader(false);
      const {
        data: { data },
      } = { ...allUserAssesmentData };
    }
    if (
      allAssesmentData &&
      allAssesmentData.data &&
      allAssesmentData.data.status === "success"
    ) {
      const {
        data: { data },
      } = { ...allAssesmentData };
      const assessmentDetails = data.assessmentDetails;
      setAssessmentObj(assessmentDetails);
    }
    if (
      allUserAssesmentData &&
      allUserAssesmentData.data &&
      allAssesmentData &&
      allAssesmentData.data &&
      allAssesmentData.data.status === "success"
    ) {
      let data = [];
      const assessmentDetails = allAssesmentData.data.data.assessmentDetails;
      if (
        allUserAssesmentData.data.data &&
        allUserAssesmentData.data.data.length > 0
      ) {
        data = allUserAssesmentData.data.data;
      }

      let tempMenuObj = [];
      assessmentDetails.forEach((pillarData) => {
        let menuTabs = [];
        let tabIds = [];
        let uiTypes = [];
        let useroptions = [];
        pillarData.section.forEach((tab) => {
          menuTabs.push(tab.goalName); //goal names array
          tabIds.push(tab.goalId);
          uiTypes.push(tab.uiType ? tab.uiType : "");
          useroptions.push(tab.data); //impact stament with action_id,impactActions  and type
        });
        tempMenuObj.push({
          pillarId: pillarData.pillarId,
          iconUrl: pillarData.iconUrl,
          pillar: pillarData.pillar,
          pillarDisplayName: pillarData.pillarDisplayName,
          tabs: menuTabs,
          tabIds: tabIds,
          uiTypes: uiTypes,
          userOptions: useroptions,
          displayOrder: pillarData.displayOrder,
        });
      });
      if (activePPMDuserId && activePPMDuserId !== null) {
        //if reviewer views assesment of counselee,summary page will be shown first
        let count = 0;
        tempMenuObj.forEach((item, index) => {
          item.displayOrder += 1;
          count = item.displayOrder + 1;
        });
        tempMenuObj.unshift({
          pillarId: "P0" + count,
          iconUrl: "icon-page",
          pillar: "Summary",
          pillarDisplayName: "Summary",
          tabs: [],
          displayOrder: 1,
        });
      } else {
        // if a logged-in user views his own assement,summary would be the last step
        tempMenuObj.push({
          pillarId: "P0" + (tempMenuObj.length + 1),
          iconUrl: "icon-page",
          pillar: "Summary",
          pillarDisplayName: "Summary",
          tabs: [],
          displayOrder: tempMenuObj.length + 1,
        });
      }
      const activeTabData = tempMenuObj[0];
      if (goalObj && Object.keys(goalObj).length > 1) {
        //if preview for specific goal and pillar

        //console.log(goalObj);
        const activeTabData = menuList.find(
          (x) => x.pillarId === goalObj.pillarId
        );
        setSelectedSection(activeTabData);
        setActiveTab(goalObj.goalId);
      } else if (myContext.navToSummary) {
        const activeTabData = tempMenuObj.find(
          (x, index) =>
            index ===
            (activePPMDuserId && activePPMDuserId !== null
              ? 0
              : tempMenuObj.length - 1)
        );
        setSelectedSection(activeTabData);
        myContext.setCurrentGoal({
          goalId: "",
          pillarId: activeTabData.pillarId,
        });
      } else if (myContext.currentGoal.pillarId === -1) {
        //on initial load 1st pillar and goal
        setSelectedSection(activeTabData);
        const activeTab =
          activeTabData.pillar !== "Summary" ? activeTabData.tabIds[0] : 0;
        setActiveTab(activeTab);
        myContext.setCurrentGoal({
          goalId:
            activeTabData.pillar !== "Summary" ? activeTabData.tabIds[0] : 0,
          pillarId: activeTabData.pillarId,
        });
      } else {
        //retain current tab after save
        const activeTabData2 = tempMenuObj.find(
          (x) => x.pillarId === myContext.currentGoal.pillarId
        );
        setSelectedSection(activeTabData2);
        const activeTab2 = myContext.currentGoal.goalId;
        setActiveTab(activeTab2);
      }

      const UsrObj = data !== "no data" ? data : [];
      let focusedChecked = {};
      setMenuList(tempMenuObj);
      let array = [];
      myContext.setEnableSummary(true);
      const prevPeriods = timePeriods.filter(timePeriod => (timePeriod !== currentPeriod));;
      let isPlanDataNullFlg = true;
      UsrObj &&
        UsrObj.length > 0 &&
        UsrObj.find((userData) => {
          if (isPlanDataNullFlg && prevPeriods.findIndex(prevPeriod => (prevPeriod === userData.timePeriod)) !== -1) {
            isPlanDataNullFlg = false;
          }
          return null;
        });
      setIsPlanDataNull(isPlanDataNullFlg);
      tempMenuObj.forEach((pillar) => {
        if (pillar.pillar !== "Summary") {
          let gCount = pillar.tabs.length;
          let currentCount = 0;
          pillar.tabIds.forEach((tab, key) => {
            UsrObj &&
              UsrObj.length > 0 &&
              UsrObj.forEach((userData) => {
                if (
                  userData.goalId === tab &&
                  userData.timePeriod === currentPeriod &&
                  userData.pillarId === pillar.pillarId
                ) {
                  //   console.log(pillar.userOptions[key], userData, tempMenuObj); //.userOptions
                  if (
                    pillar.userOptions[key].length === userData.userInput.length
                  ) {
                    currentCount += 1;
                  }
                }
                const pd = tempMenuObj.find(x => x.pillarId === userData.pillarId);
                userData.userInput.map((v, i) => {
                  //focusedChecked[`${v.action_id}`] = false;
                  let stmtType = '';
                  if (pd && pd.hasOwnProperty('userOptions')) {
                    const uos = pd.userOptions;
                    if (uos && Array.isArray(uos)) {
                      const uo = uos.find(x => x.some(y => y.action_id === v.action_id));
                      if (uo && Array.isArray(uo)) {
                        const uot = uo.find(x => x.action_id === v.action_id);
                        if (uot && uot.hasOwnProperty('type')) {
                          stmtType = uot.type;
                        }
                      }
                    }
                  }
                  focusedChecked[`${v.action_id}`] = { focused: false, disabled: true, type: stmtType };
                  if (v.hasOwnProperty("focused")) {
                    //focusedChecked[`${v.action_id}`] = v.focused;
                    focusedChecked[`${v.action_id}`] = { focused: v.focused, disabled: stmtType === "Table stakes" ? true : !v.focused, type: stmtType };
                  }
                });
              });
          });
          let temp =
            currentCount !== 0 ? Math.round((currentCount / gCount) * 100) : 0;
          array.push(temp);

          if (temp < 100) {
            myContext.setEnableSummary(false);
          }
        }
      });
      let complete = true;
      array.forEach((item) => {
        if (item != 100) complete = false;
      });
      if (complete) {
        if (myContext.goToNextCalled) {
          myContext.setGoToNextCalled(false);

          setActiveTab(0);
          let current = {
            goalId: 0,
            pillarId: tempMenuObj[tempMenuObj.length - 1].pillarId,
          };
          const newSec = tempMenuObj[tempMenuObj.length - 1];
          setSelectedSection(newSec);
          myContext.setCurrentGoal({ ...current });
        }
      }
      setProgressPercentage(array);
      setAssesmentUserObj(UsrObj);
      setInitialAssesmentUserObj(UsrObj);
      setLoader(false);
      generateSurveyData(UsrObj, tempMenuObj, isPlanDataNullFlg);
      //..Check and apply check boxes disable state
      const fc = checkAndApplyChkDisableState(focusedChecked, UsrObj, tempMenuObj);
      setImpactStmtFocusChecked(fc);
    }
    // eslint-disable-next-line
  }, [allAssesmentData, allUserAssesmentData, comittedOptionList]);

  function checkAndApplyChkDisableState(stmtFocus, usrObj, tempMenuObj) {
    if (usrObj && Array.isArray(usrObj)) {
      for (let i = 0; i < usrObj.length; i++) {
        let disabledCounter = 0, tblStakesCount = 0;
        const usrData = usrObj[i];
        if (usrData
          && usrData.hasOwnProperty("userInput")
          && Array.isArray(usrData.userInput)) {
          for (let j = 0; j < usrData.userInput.length; j++) {
            const element = usrData.userInput[j];
            if (stmtFocus[`${element.action_id}`].type !== "Table stakes") {
              if (stmtFocus[`${element.action_id}`].focused) {
                stmtFocus[`${element.action_id}`].disabled = false;
              } else {
                stmtFocus[`${element.action_id}`].disabled = true;
                disabledCounter = disabledCounter + 1;
              }
            } else {
              tblStakesCount = tblStakesCount + 1;
            }
          }
          if (disabledCounter === (usrData.userInput.length - tblStakesCount)) {
            usrData.userInput.map((v, i) => {
              if (stmtFocus[`${v.action_id}`].type !== "Table stakes") {
                stmtFocus[`${v.action_id}`].disabled = false;
              }
            });
          }
        }
      }
    }
    return stmtFocus;
  }

  const getCurrentUser = () => {
    const ppmdID =
      myContext.activePPMDuserId || sessionStorage.activePPMDuserId;
    const userId = myContext.userId || sessionStorage.userId;
    return ppmdID || userId;
  };
  const generateSurveyData = (
    userAssessmetData,
    menuList,
    isPlanDataNullFlg
  ) => {
    /*To generate committed statments and changed statements for all goals.This data is displayed in Summary page 
        comitted Statements are identified based on userinput  to statments :-
        dropdown-if value selected is yes
        checkbox-if checkbox is selected
        readio-if option selected is C or D
        Changed Statements are those for which user selected values are different from last phase values.*/
    //debugger;
    let summaryData = [];
    let ansMapping = {};
    menuList &&
      menuList.forEach((pillar) => {
        if (pillar.pillar !== "Summary") {
          pillar.userOptions &&
            pillar.userOptions.forEach((eachGoal) => {
              eachGoal &&
                eachGoal.forEach((action) => {
                  ansMapping[action.action_id] = action;
                });
            });
        }
      });

    menuList &&
      menuList.forEach((pillar) => {
        let tempPillar = {};
        if (pillar.pillar !== "Summary") {
          tempPillar["pillar"] = pillar.pillar;
          tempPillar["pillarDisplayName"] = pillar.pillarDisplayName;
          tempPillar["tabList"] = [];
          pillar.tabIds.forEach((tab, index) => {
            let goalData = {};
            goalData["goal"] = pillar.tabs[index];
            goalData["uiType"] = pillar.uiTypes[index];
            let tempCurrent = {};
            let tempPrev = {};
            let diffData = [];
            let comittedData = [], othersData = [], focusedData = [];
            let prevPeriods = timePeriods.filter(timePeriod => (timePeriod !== currentPeriod));
            userAssessmetData &&
              userAssessmetData.length > 0 &&
              userAssessmetData.forEach((userData) => {
                if (
                  userData.goalId === tab &&
                  userData.timePeriod === currentPeriod &&
                  userData.pillarId === pillar.pillarId
                ) {
                  tempCurrent = userData;
                }
                if (
                  userData.goalId === tab &&
                  prevPeriods.findIndex(prevPeriod => (prevPeriod === userData.timePeriod)) !== -1 &&
                  userData.pillarId === pillar.pillarId
                ) {
                  tempPrev = userData;
                }
              });
            tempCurrent &&
              tempCurrent.userInput &&
              (tempPrev.userInput || isPlanDataNullFlg) &&
              tempCurrent.userInput.forEach((stmnts, qstnIndex) => {
                let compare = {};
                let comitted = {}, others = {}, focused = {};
                if (
                  !isPlanDataNullFlg &&
                  tempPrev.userInput[qstnIndex] &&
                  stmnts.option_id !== tempPrev.userInput[qstnIndex].option_id
                ) {
                  compare["qstn"] =
                    ansMapping[tempPrev.userInput[qstnIndex].action_id];
                  compare["prev"] = tempPrev.userInput[qstnIndex].option_id;
                  compare["current"] = stmnts.option_id;
                }
                if (compare.prev) diffData.push(compare);
                // if (pillar.uiTypes[index] === "radio_button") {
                // stmnts.option_id.endsWith("002") ||
                // stmnts.option_id.endsWith("003")
                //..Focused statements
                if (stmnts && stmnts.focused) {
                  focused["qstn"] =
                    ansMapping[tempCurrent.userInput[qstnIndex].action_id];
                  if (getAllOptions && getAllOptions.data
                    && Array.isArray(getAllOptions.data)) {
                    let selOption = getAllOptions.data.find(x => x.option_id === tempCurrent.userInput[qstnIndex].option_id);
                    if (selOption) {
                      focused["qstn"].selectedOption = selOption.options;
                    }
                  }
                }
                if (comittedOptionList[stmnts.option_id]) {
                  //to identify committed options
                  comitted["qstn"] =
                    ansMapping[tempCurrent.userInput[qstnIndex].action_id];
                  if (getAllOptions && getAllOptions.data
                    && Array.isArray(getAllOptions.data)) {
                    let selOption = getAllOptions.data.find(x => x.option_id === tempCurrent.userInput[qstnIndex].option_id);
                    if (selOption) {
                      comitted["qstn"].selectedOption = selOption.options;
                    }
                  }
                } else {
                  others["qstn"] =
                    ansMapping[tempCurrent.userInput[qstnIndex].action_id];
                  if (getAllOptions && getAllOptions.data
                    && Array.isArray(getAllOptions.data)) {
                    let selOption = getAllOptions.data.find(x => x.option_id === tempCurrent.userInput[qstnIndex].option_id);
                    if (selOption) {
                      others["qstn"].selectedOption = selOption.options;
                    }
                  }
                }
                // }
                //  else if (pillar.uiTypes[index] === "drop_down") {
                //   if (
                //     /* allOptionList[stmnts.option_id] === "Yes" */
                //     comittedOptionList[stmnts.option_id]
                //   ) {
                //     comitted["qstn"] =
                //       ansMapping[tempCurrent.userInput[qstnIndex].action_id];
                //   }
                // } else {
                //   if (
                //     /* allOptionList[stmnts.option_id] === "True" */ comittedOptionList[
                //       stmnts.option_id
                //     ]
                //   ) {
                //     comitted["qstn"] =
                //       ansMapping[tempCurrent.userInput[qstnIndex].action_id];
                //   }
                // }
                if (comitted.qstn) comittedData.push(comitted);
                if (others.qstn) othersData.push(others);
                if (focused.qstn) focusedData.push(focused);
              });

            //..Handle committed and others data in assessment report 
            //..in case of previous year assessment is incomplete
            if (tempCurrent &&
              tempCurrent.userInput
              && tempPrev.userInput === undefined && !isPlanDataNullFlg
              && comittedData.length === 0 && othersData.length === 0) {
              tempCurrent.userInput.forEach((stmnts, qstnIndex) => {
                let comitted = {}, others = {};
                if (comittedOptionList[stmnts.option_id]) {
                  //to identify committed options
                  comitted["qstn"] =
                    ansMapping[tempCurrent.userInput[qstnIndex].action_id];
                  if (getAllOptions && getAllOptions.data
                    && Array.isArray(getAllOptions.data)) {
                    let selOption = getAllOptions.data.find(x => x.option_id === tempCurrent.userInput[qstnIndex].option_id);
                    if (selOption) {
                      comitted["qstn"].selectedOption = selOption.options;
                    }
                  }
                } else {
                  others["qstn"] =
                    ansMapping[tempCurrent.userInput[qstnIndex].action_id];
                  if (getAllOptions && getAllOptions.data
                    && Array.isArray(getAllOptions.data)) {
                    let selOption = getAllOptions.data.find(x => x.option_id === tempCurrent.userInput[qstnIndex].option_id);
                    if (selOption) {
                      others["qstn"].selectedOption = selOption.options;
                    }
                  }
                }

                if (comitted.qstn) comittedData.push(comitted);
                if (others.qstn) othersData.push(others);
              });
            }
            goalData["difference"] = diffData;
            goalData["comitted"] = comittedData;
            goalData["remaining"] = othersData;
            goalData["focused"] = focusedData;
            tempPillar["tabList"].push(goalData);
          });

          summaryData.push(tempPillar);
        }
      });
    setSummaryData(summaryData);
    // console.log(summaryData, ansMapping);
    // }
  };
  useEffect(() => {
    if (
      saveUserAssesmentData &&
      saveUserAssesmentData.data &&
      (saveUserAssesmentData.data.statuscode === "200" ||
        saveUserAssesmentData.data.message === "success")
    ) {
      (async function () {
        try {
          const token = await authProvider.acquireTokenSilent({
            scopes: APIscope,
          });
          const idToken = token.accessToken;
          const {
            data: { data },
          } = { ...saveUserAssesmentData };
          myContext.setIsLockPopup(false);
          ChangeTitle("Your changes have been saved.");
          myContext.setIsWarningPopup(false);
          toast.warn(
            <div>
              <i className="icon-verified-outline" />
              Your changes have been saved.
            </div>,
            {
              position: "bottom-center",
              transition: Flip,
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          dispatch({
            type: "GET_ALL_USER_ASSESSMENT_DATA",
            payload: {
              token: idToken,

              activePeriod: currentPeriod,
              financialYear: myContext.financialYear,
            },
          });
          dispatch({ type: "SAVE_USER_ASSESSMENT_DATA_RESET" });
          // setRefreshCurrent(false);

          const outClickCallback = isOutsideClick.data.callback;
          if (outClickCallback && Object.keys(outClickCallback).length > 0) {
            dispatch({
              type: "OUTSIDE_CLICK",
              payload: { flag: false, callback: {} },
            });
            outClickCallback.fn && outClickCallback.fn(outClickCallback.params);
            dispatch({
              type: "OUTSIDE_CLICK_RESET",
              payload: {},
            });
            myContext.setIsAutoSave(false);
          }
        } catch (e) {
          console.error(e);
        }
      })();
    }
    // eslint-disable-next-line
  }, [saveUserAssesmentData]);

  const checkOutsideClick = (fn, params = "") => {
    /*myContext.isAutoSave will be true if there is any unsaved change in form .
    On any outside click, this function will be called with actual function,connected to click as param
    If flag is true,save confirmation popup will be opened,else excution of actual function will happen  */
    if (myContext.isAutoSave) {
      myContext.setOpenSavePopup(true);
      dispatch({
        type: "OUTSIDE_CLICK",
        payload: { flag: false, callback: { fn: fn, params: params } },
      });
    } else {
      fn && fn(params);
    }
  };

  useEffect(() => {
    if (
      myContext.isAutoSave &&
      isOutsideClick.data &&
      !isOutsideClick.data.flag &&
      isOutsideClick.data.cancel
    ) {
      //save  and proceed on cancel button click,currently dispatch for this reducer change is commented
      const outClickCallback = isOutsideClick.data.callback;
      if (outClickCallback && Object.keys(outClickCallback).length > 0) {
        dispatch({
          type: "OUTSIDE_CLICK",
          payload: { flag: false, callback: {} },
        });
        outClickCallback.fn && outClickCallback.fn(outClickCallback.params);
        dispatch({
          type: "OUTSIDE_CLICK_RESET",
          payload: {},
        });
        myContext.setIsAutoSave(false);
        (async function () {
          try {
            const token = await authProvider.acquireTokenSilent({
              scopes: APIscope,
            });
            const idToken = token.accessToken;
            dispatch({
              type: "GET_ALL_USER_ASSESSMENT_DATA",
              payload: {
                token: idToken,

                activePeriod: activePeriod,
                financialYear: myContext.financialYear,
              },
            });
          } catch (e) {
            console.error(e);
          }
        })();
      }
    } else if (
      myContext.isAutoSave &&
      isOutsideClick.data &&
      isOutsideClick.data.flag
    ) {
      //On ok click of save confirmation popup
      handleSave();
      myContext.setIsAutoSave(false);
    }
    // eslint-disable-next-line
  }, [myContext.isAutoSave, isOutsideClick]);

  useEffect(() => {
    setShowAlertSubmitPopup(myContext.openSavePopup);
  }, [myContext.openSavePopup]);

  // useEffect(() => {
  //   if (menuList && selectedSection) {
  //     const activeSec = selectedSection.title;
  //     if (activeSec === "Sales & Pipline") {
  //       const actIndex = menuList.findIndex((x) => x.title === activeSec);
  //       const actTabIndex = menuList[actIndex].section.findIndex(
  //         (x) => x === activeTab
  //       );
  //       const tabLen = menuList[actIndex].section.length;
  //       if (actTabIndex === tabLen - 1) {
  //         myContext.setIsLastTab(true);
  //       }
  //     } else if (myContext.isLastTab) {
  //       myContext.setIsLastTab(false);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [selectedSection, activeTab]);

  const viewQuestionFullScreen = (value) => {
    setQusFullScreen(value);
  };
  const changeSelectedSection = (id) => {
    // called on click of pillar box,on top of asseesment
    let current = {
      goalId: -1,
      pillarId: -1,
    };
    const temp = JSON.parse(JSON.stringify(menuList));
    const selected = temp.find((item) => item.pillarId === id);
    current.pillarId = id;
    setSelectedSection(selected);
    if (selected.pillar !== "Summary") {
      /* const obj = assessmentObj.find(
        (x) => x.section === selectedSection.title
      ); */
      const newTab = selected.tabIds[0];
      setActiveTab(newTab);
      current.goalId = selected.tabIds[0];
    }
    myContext.setCurrentGoal({ ...current });
  };

  const changeSelectedData = (
    //onchange for inputs
    inputType,
    tab,
    period,
    action_id,
    option,
    actionIdArr = [],
    false_opt_id = ""
  ) => {
    let tempObj = JSON.parse(JSON.stringify(assesmentUserObj));
    const section = selectedSection.pillarId;
    let Foundflag = false; //pillar data previously saved or not
    let FoundObjflag = -1;
    //Check if the pillar data was previously saved
    tempObj =
      (tempObj.length > 0 &&
        tempObj.map((obj, key) => {
          if (
            obj.goalId === tab &&
            obj.timePeriod === period &&
            obj.pillarId === section
          ) {
            obj.userInput.forEach((x) => {
              FoundObjflag = key;
              if (x.action_id === action_id) {
                //action-qstn & option-value id
                x.option_id = option;
                Foundflag = true;
              }
            });
          }
          return obj;
        })) ||
      [];
    if (Foundflag) {
      //pillar data was already saved
      setAssesmentUserObj(tempObj);

      //to check if there is an actual change
      myContext.setIsAutoSave(false);
      let initObj = JSON.parse(JSON.stringify(initialAssesmentUserObj));
      initObj.map((obj, index) => {
        if (
          obj.goalId === tab &&
          obj.timePeriod === period &&
          obj.pillarId === section
        ) {
          obj.userInput.map((x, indexy) => {
            if (tempObj[index] && tempObj[index].userInput) {
              if (x.action_id === tempObj[index].userInput[indexy].action_id) {
                if (
                  x.option_id !== tempObj[index].userInput[indexy].option_id
                ) {
                  myContext.setIsAutoSave(true);
                  // console.log("diff", indexy + 1);
                }
              }
            }
            // });
            return null;
          });
        }
        return null;
      });
    } else {
      //in case pillar data is being saved for the 1st time, take prev phase data, for same pillar as base data
      myContext.setIsAutoSave(true);
      let tempPrevObj = {};
      let prevPeriods = timePeriods.filter(timePeriod => (timePeriod !== currentPeriod));
      tempPrevObj =
        (tempObj.length > 0 &&
          tempObj.find(
            (obj) =>
              obj.goalId === activeTab &&
              prevPeriods.findIndex(prevPeriod => (prevPeriod === obj.timePeriod)) !== -1 &&
              obj.pillarId === section
          )) ||
        [];

      let temp = {};
      if (tempPrevObj && Object.keys(tempPrevObj).length > 0) {
        //previous phase data is present for given pillar
        temp.userInput = tempPrevObj.userInput;
        temp.goalId = tempPrevObj.goalId;
        temp.pillarId = tempPrevObj.pillarId;
        temp.timePeriod = timePeriods[timePeriods.indexOf(activePeriod)];
        temp.userInput &&
          temp.userInput.forEach((x) => {
            if (x.action_id === action_id) {
              x.option_id = option;
            }
          });
        tempObj.push(temp);
        // setInitialValueObj(temp);
      } else if (isPlanDataNull) {
        //previous phase user data is null
        //tested with PLAN save
        if (FoundObjflag === -1) {
          temp.userInput = [];
          if (inputType === "checkbox") {
            //for checkbox not checked assigned false
            actionIdArr.length > 0 &&
              actionIdArr.map((item) => {
                if (item.action_id !== action_id) {
                  temp.userInput.push({
                    action_id: item.action_id,
                    option_id: false_opt_id,
                  });
                  return null;
                } else {
                  temp.userInput.push({
                    action_id: action_id,
                    option_id: option,
                  });
                  return null;
                }
              });
          } else {
            temp.userInput.push({ action_id: action_id, option_id: option });
          }
          temp.goalId = activeTab;
          temp.pillarId = section;
          temp.timePeriod = period;

          tempObj.push(temp); //if first userinput add that year pperiod obj to array
          // setInitialValueObj(temp);
        } else {
          tempObj[FoundObjflag].userInput.push({
            action_id: action_id,
            option_id: option,
          });
        }
      }
      //console.log(temp, "temp2");
      //console.log(tempObj);      
      //..Handle save assessment data in case of previous year assessment is incomplete
      const selGoal = tempObj.find(x => x.pillarId === section && x.goalId === tab && x.timePeriod === period);
      if (selGoal) {
        for (let index = 0; index < tempObj.length; index++) {
          const element = tempObj[index];
          if (element && element.pillarId === section
            && element.goalId === tab && element.timePeriod === period
            && element.userInput && Array.isArray(element.userInput)) {
            const selUsrInput = element.userInput.find(x => x.action_id === action_id);
            if (!selUsrInput) {
              element.userInput.push({
                action_id: action_id,
                option_id: option,
              });
            }
          }
        }
      } else {
        if (allAssesmentData && allAssesmentData.data
          && allAssesmentData.data.data && allAssesmentData.data.data.assessmentDetails
          && Array.isArray(allAssesmentData.data.data.assessmentDetails)) {
          //..Find out selected assessment pillar info for active period
          let assessmentData = allAssesmentData.data.data.assessmentDetails.find(x =>
            x.pillarId === section && x.timePeriod === period);
          //..Check if pillar section data is available
          if (assessmentData && assessmentData.section
            && Array.isArray(assessmentData.section)) {
            //..Find out selected assessment goal info under selected pillar
            let assessmentGoal = assessmentData.section.find(x => x.goalId === tab);
            //..Check if selected goal info is available
            if (assessmentGoal && assessmentGoal.data
              && Array.isArray(assessmentGoal.data)) {
              //..Prepare insert object
              let insertObj = {
                userInput: [],
                pillarId: section,
                goalId: tab,
                financialYear: myContext.financialYear,
                timePeriod: period
              };
              //..traverse assessment goal info
              assessmentGoal.data.map(d => {
                //..check if selected impact statement is found (based on action_id)
                if (d.action_id === action_id) {
                  //..prepare user input object
                  insertObj.userInput.push({
                    action_id: action_id,
                    option_id: option
                  });
                }
              }
              );
              //..Insert into final user assessment info object
              tempObj.push(insertObj);
            }
          }
        }
      }
      setAssesmentUserObj(tempObj);
    }
  };

  //..Handle impact statements focus selection
  const handleImpactStmtFocusChecked = (e) => {
    const data = allUserAssesmentData.data.data;
    let disabledCounter = 0, tblStakesCount = 0;
    let stmtFocus = JSON.parse(JSON.stringify(impactStmtFocusChecked));
    if (stmtFocus[`${e.target.name}`]) {
      stmtFocus[`${e.target.name}`].focused = e.target.checked;
    }
    if (data && Array.isArray(data)) {
      //..find out user assessment details across current and previous years in current Financial year
      let usrData = data.find(x => x.userInput.some(y => y.action_id === e.target.name));
      //..find out user assessment details of current phase
      let activeUsrData = data.find(x => x.timePeriod === activePeriod && x.userInput.some(y => y.action_id === e.target.name));
      //..Below condition is to distinguish between previous year assessment is complete or incomplete
      if (usrData
        && usrData.hasOwnProperty("userInput")
        && Array.isArray(usrData.userInput)
        && activeUsrData
        && activeUsrData.hasOwnProperty("userInput")
        && Array.isArray(activeUsrData.userInput)
        && usrData.userInput.length < activeUsrData.userInput.length) {
        usrData = activeUsrData;
      }
      if (usrData
        && usrData.hasOwnProperty("userInput")
        && Array.isArray(usrData.userInput)) {
        for (let index = 0; index < usrData.userInput.length; index++) {
          const element = usrData.userInput[index];
          if (stmtFocus[`${element.action_id}`].type !== "Table stakes") {
            if (stmtFocus[`${element.action_id}`].focused) {
              stmtFocus[`${element.action_id}`].disabled = false;
            } else {
              stmtFocus[`${element.action_id}`].disabled = true;
              disabledCounter = disabledCounter + 1;
            }
          } else {
            tblStakesCount = tblStakesCount + 1;
          }
        }
        if (disabledCounter === (usrData.userInput.length - tblStakesCount)) {
          usrData.userInput.map((v, i) => {
            if (stmtFocus[`${v.action_id}`].type !== "Table stakes") {
              stmtFocus[`${v.action_id}`].disabled = false;
            }
          });
        }
      }
    }
    setImpactStmtFocusChecked(stmtFocus);
  };

  const handleSave = async () => {
    setLoader(true);
    let requestData = {};
    const activesectionId = selectedSection.pillarId;
    let tempObj = JSON.parse(JSON.stringify(assesmentUserObj));
    const actTabIndex = selectedSection.tabIds.findIndex(
      (x) => x === activeTab
    );

    let tempPrevObj = {};
    let tempCurrentObj = {};
    tempCurrentObj = tempObj.find(
      (obj) =>
        obj.goalId === activeTab &&
        obj.timePeriod === activePeriod &&
        obj.pillarId === activesectionId
    );
    //can be removed after testing**ifeles
    if (tempCurrentObj) {
    } else {
      let prevPeriods = timePeriods.filter(timePeriod => (timePeriod !== currentPeriod));
      tempPrevObj = tempObj.find(
        (obj) =>
          obj.goalId === activeTab &&
          prevPeriods.findIndex(prevPeriod => (prevPeriod === obj.timePeriod)) !== -1 &&
          obj.pillarId === activesectionId
      );
    }

    if (tempCurrentObj || tempPrevObj) {
      requestData["userInput"] = (
        tempCurrentObj && tempCurrentObj.userInput
          ? tempCurrentObj.userInput
          : tempPrevObj.userInput
      ).filter((x) => x.option_id !== "");
      requestData["pillarId"] = selectedSection.pillarId;
      requestData["goalId"] =
        actTabIndex >= 0 ? selectedSection.tabIds[actTabIndex] : "";
      requestData["financialYear"] = myContext.financialYear;
      requestData["timePeriod"] = myContext.activePeriod;
      requestData["partitionID"] = "";
      const token = await authProvider.acquireTokenSilent({
        scopes: APIscope,
      });
      const idToken = token.accessToken;
      if (tempCurrentObj && "_id" in tempCurrentObj) {
        //Update
        requestData = {};
        requestData["_id"] = tempCurrentObj._id;
        requestData["financialYear"] = tempCurrentObj.financialYear;
        requestData["pillarId"] = selectedSection.pillarId;
        requestData["goalId"] =
          actTabIndex >= 0 ? selectedSection.tabIds[actTabIndex] : "";
        requestData["timePeriod"] = tempCurrentObj.timePeriod;
        requestData["userInput"] = tempCurrentObj.userInput.filter(
          (x) => x.option_id !== ""
        );
      }
      //..Update user focused statement details
      if (requestData && requestData.userInput
        && Array.isArray(requestData.userInput)) {
        for (let index = 0; index < requestData.userInput.length; index++) {
          const element = requestData.userInput[index];
          if (impactStmtFocusChecked.hasOwnProperty(`${element.action_id}`)) {
            requestData.userInput[index].focused = impactStmtFocusChecked[`${element.action_id}`].focused;
          }
        }
      }
      dispatch({
        type: "SAVE_USER_ASSESSMENT_DATA",
        payload: { requestData, tokens: idToken },
      });
    } else {
      setLoader(false);
    }
  };

  const getCompletionPer = (activeSec) => {
    let tempObj = JSON.parse(JSON.stringify(assesmentUserObj));
    tempObj = tempObj.filter(
      (obj) => obj.pillar === activeSec && obj.period === currentPeriod
    );
    let totalCount = 0;
    let ansCount = 0;
    tempObj.forEach((obj) => {
      totalCount += obj.userInput.length;
      let cc = 0;
      obj.userInput.forEach((ui) => {
        if (ui.userOption.option_id) cc += 1;
      });
      ansCount += cc;
    });
    let percentage = ansCount === 0 ? ansCount : (ansCount / totalCount) * 100;
    return Math.round(percentage);
  };
  const goToPrev = () => {
    const activeSec = selectedSection.pillarId;
    const actIndex = menuList.findIndex((x) => x.pillarId === activeSec);
    const actTabIndex = menuList[actIndex].tabIds.findIndex(
      (x) => x === activeTab
    );

    //check if it's first save for given tab and cycle
    let checkifInitialSave = true;
    let recordPresent = false;
    if (!myContext.isAutoSave) {
      let initObj = JSON.parse(JSON.stringify(initialAssesmentUserObj));
      initObj.map((obj, index) => {
        if (
          obj.goalId === activeTab &&
          obj.timePeriod === activePeriod &&
          obj.pillarId === activeSec
        ) {
          recordPresent = true;
          if ("_id" in obj) checkifInitialSave = false;
        }
        return null;
      });
    }
    //
    if (actTabIndex === 0) {
      //goal index 0
      //nav to next Section
      if (actIndex === 0) {
        //pillar index 0
        //return false;
      } else {
        const newSec = menuList[actIndex - 1];
        setSelectedSection(newSec);
        const newTab = menuList[actIndex - 1].tabIds
          ? menuList[actIndex - 1].tabIds[
          menuList[actIndex - 1].tabIds.length - 1
          ]
          : 0;
        setActiveTab(newTab);
        let current = {
          goalId: menuList[actIndex - 1].tabIds
            ? menuList[actIndex - 1].tabIds[
            menuList[actIndex - 1].tabIds.length - 1
            ]
            : 0,
          pillarId: menuList[actIndex - 1].pillarId,
        };
        myContext.setCurrentGoal({ ...current });
      }
    } else {
      //goal index !=0
      //nav to next Tab
      const newTab = menuList[actIndex].tabIds[actTabIndex - 1];
      setActiveTab(newTab);
      let current = {
        goalId: menuList[actIndex].tabIds[actTabIndex - 1],
        pillarId: menuList[actIndex].pillarId,
      };
      myContext.setCurrentGoal({ ...current });
    }

    if (
      myContext.currentPeriod === myContext.activePeriod &&
      (myContext.isAutoSave || checkifInitialSave || !recordPresent)
    ) {
      handleSave();
      myContext.setIsAutoSave(false);
    }
  };
  const checkIfLastTab = () => {
    const activeSec = selectedSection.pillarId;
    const actIndex = menuList.findIndex((x) => x.pillarId === activeSec);
    const actTabIndex = menuList[actIndex].tabIds.findIndex(
      (x) => x === activeTab
    );
    const tabLen = menuList[actIndex].tabs.length;
    if (activePPMDuserId && activePPMDuserId !== null) {
      //for reviewers viewing ppmd
      if (actIndex === menuList.length - 1 && actTabIndex === tabLen - 1)
        return true;
      else return false;
    } else {
      // self  assemnt by ppmd

      if (actIndex === menuList.length - 2 && actTabIndex === tabLen - 1) {
        return true;
      } else return false;
    }
  };

  const goToNext = () => {
    const activeSec = selectedSection.pillarId;
    const actIndex = menuList.findIndex((x) => x.pillarId === activeSec);
    const actTabIndex =
      menuList[actIndex].pillar !== "Summary"
        ? menuList[actIndex].tabIds.findIndex((x) => x === activeTab)
        : 0;

    //check if it's first save for given tab and cycle
    let checkifInitialSave = true;
    let recordPresent = false;
    if (!myContext.isAutoSave) {
      let initObj = JSON.parse(JSON.stringify(initialAssesmentUserObj));
      initObj.map((obj, index) => {
        if (
          obj.goalId === activeTab &&
          obj.timePeriod === activePeriod &&
          obj.pillarId === activeSec
        ) {
          recordPresent = true;
          if ("_id" in obj) checkifInitialSave = false;
        }
        return null;
      });
    }
    if (
      myContext.currentPeriod === myContext.activePeriod &&
      (myContext.isAutoSave || checkifInitialSave || !recordPresent)
    ) {
      if (menuList[actIndex].pillar !== "Summary") {
        handleSave();
        myContext.setIsAutoSave(false);
      }
    }
    const tabLen = menuList[actIndex].tabs.length;
    const totalProgress = progressPercentage.reduce(
      (partial_sum, a) => partial_sum + a,
      0
    );
    if (
      actTabIndex === tabLen - 1 ||
      (menuList[actIndex].pillar === "Summary" &&
        activePPMDuserId &&
        activePPMDuserId !== null)
    ) {
      //max goal in current pillar
      if (
        totalProgress < (menuList.length - 1) * 100 &&
        activeTab === menuList[actIndex].tabIds[tabLen - 1] &&
        !myContext.enableSummary &&
        menuList[actIndex + 1].pillar === "Summary"
      ) {
        myContext.setGoToNextCalled(true);
        return false;
        //no navigation
      } else {
        //nav to next Section
        const newSec = menuList[actIndex + 1];
        setSelectedSection(newSec);
        const newTab =
          menuList[actIndex + 1].pillar !== "Summary"
            ? menuList[actIndex + 1].tabIds[0]
            : 0;
        setActiveTab(newTab);
        let current = {
          goalId:
            menuList[actIndex + 1].pillar !== "Summary"
              ? menuList[actIndex + 1].tabIds[0]
              : 0,
          pillarId: menuList[actIndex + 1].pillarId,
        };
        myContext.setCurrentGoal({ ...current });
      }
    } else {
      //nav to next Tab

      const newTab = menuList[actIndex].tabIds[actTabIndex + 1];
      setActiveTab(newTab);
      let current = {
        goalId: menuList[actIndex].tabIds[actTabIndex + 1],
        pillarId: menuList[actIndex].pillarId,
      };
      myContext.setCurrentGoal({ ...current });
    }
  };

  const ContentParams = {
    goToNext: goToNext,
    goToPrev: goToPrev,
    menuList: menuList,
    activeTab: activeTab,
    myContext: myContext,
    totalProgress: progressPercentage.reduce(
      (partial_sum, a) => partial_sum + a,
      0
    ),
    isPlanDataNull: isPlanDataNull,
    activePeriod: activePeriod,
    currentPeriod: currentPeriod,
    setActiveTab: setActiveTab,
    isQusFullScreen: isQusFullScreen,
    selectedSection: selectedSection,
    getCompletionPer: getCompletionPer,
    checkOutsideClick: checkOutsideClick,
    changeSelectedData: changeSelectedData,
    changeSelectedSection: changeSelectedSection,
    viewQuestionFullScreen: viewQuestionFullScreen,
  };
  const [openDialog, setOpenDialog] = React.useState(false);
  // const handleDialogOpen = () => {
  //   setOpenDialog(true);
  // };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const [isShowMore, setShowMore] = useState(false);
  const showLess = () => {
    setShowMore(!isShowMore);
  };
  const scrollableNodeRef = React.createRef();

  const welcomeTextHtml = isShowMore ? assessmentContent.content : truncate(assessmentContent.content, 350);

  return props.readOnly ? (
    <ContentComponent
      {...props}
      {...ContentParams}
      assessmentSectionList={
        allSectionDataFetch
          ? allSectionDataFetch.data
            ? allSectionDataFetch.data.data
              ? allSectionDataFetch.data.data.assessmentDetails
              : []
            : []
          : []
      }
      selectedSection={selectedSection}
      assesmentUserObj={assesmentUserObj}
      checkIfLastTab={checkIfLastTab}
      assessmentObj={assessmentObj}
      goToNext={goToNext}
      menuList={menuList}
      summaryData={summaryData}
      allOptionList={allOptionList}
      scrollableNodeRef={scrollableNodeRef}
      readOnly={props.readOnly}
      allUserAssesmentData={allUserAssesmentData}
      handleImpactStmtFocusChecked={handleImpactStmtFocusChecked}
      impactStmtFocusChecked={impactStmtFocusChecked}
    />
  ) : (
    <>
      {/* {
        isFocusReportPreview &&
        (
          <FocusStmtReportPreview />
        )
      } */}
      <NotificationDialog
        open={openDialog}
        handleDialogClose={handleDialogClose}
      />
      {loader && <Loader />}
      <div
        className="survey-section-main-container"
        id="survey-section-main-container">
        <div
          className="survey-section-main-sidemenu"
          style={myContext.isFullScreen === true ? { display: "none" } : {}}>
          <SideMenu
            minimized={true}
            sideMenuItemSelect="Home"
            isminimizedMainMenu={true}
            isMainMenu={true}
          />
        </div>
        {assessmentObj && ( //&& assessmentObj.length > 0
          <div className="survey-section-container">
            <div className="second-header survey-hdr-top">
              <Breadcrumb crumbs={crumbs} {...props} />
              {selectedSection &&
                selectedSection.pillar &&
                selectedSection.pillar !== "Summary" && (
                  <i
                    className="icon-web-071"
                    style={
                      !myContext.disableUpdateSummary &&
                        !myContext.formsInActive
                        ? { color: "grey", cursor: "pointer" }
                        : { color: "grey", cursor: "no-drop" }
                    }
                    onClick={() => {
                      if (
                        !myContext.disableUpdateSummary &&
                        !myContext.formsInActive
                      ) {
                        dispatch({
                          type: "OUTSIDE_CLICK_RESET",
                          payload: {},
                        });
                        myContext.setIsAutoSave(false);
                        // setRefreshCurrent(true);
                        handleSave();
                      }
                      // checkOutsideClick(goToNext);
                    }}
                  />
                )}
            </div>
            <SimpleBar
              scrollableNodeProps={{ ref: scrollableNodeRef }}
              className="start-survey-wrap"
              style={{ overflowX: "hidden", height: "79vh", width: "97%" }}>
              <div className="welcm-text">
                <p>
                  {"Welcome to the LEAD 4D " +
                    capitalizeFirstLetter(currentPeriod === myConsts.TimePeriodYearEnd ? currentPeriod : currentPeriod?.replace("-", "")) +
                    " Self-Assessment"}
                </p>
                {/* <p>
                  {isShowMore
                    ? welcomeText
                    : welcomeText.slice(0, 350) + "... "}
                  
                </p> */}
                <div style={{ paddingBottom: '10px', cursor: "auto !important" }}>
                  <Preview content={welcomeTextHtml} />
                  <span onClick={showLess}>
                    {isShowMore ? " View Less" : " View More"}
                  </span>
                </div>
              </div>

              <SimpleBar className="sectionContainer" style={{ overflowY: "hidden", height: "7.2vh", width: "100%" }}>
                <div className="section-list-scroll overview">
                  {menuList &&
                    menuList
                      // .sort((a, b) => a.displayOrder - b.displayOrder)
                      .map((section, key) => {
                        return (
                          <div
                            className={
                              "eachSection " +
                              (section.pillarDisplayName === "Summary" &&
                                !myContext.enableSummary
                                ? " disable"
                                : "")
                            }
                            key={section.pillarId}
                            style={
                              (selectedSection && selectedSection.pillarId) ===
                                (section && section.pillarId)
                                ? { background: "#FDD600" }
                                : {}
                            }
                            onClick={(e) => {
                              e.stopPropagation();
                              if (
                                section.pillarDisplayName !== "Summary" ||
                                (myContext.enableSummary &&
                                  section.pillarDisplayName === "Summary")
                              ) {
                                let current = {};
                                current.pillarId = section.pillarId;
                                const temp = JSON.parse(
                                  JSON.stringify(menuList)
                                );
                                const selected = temp.find(
                                  (item) => item.pillarId === section.pillarId
                                );
                                if (selected.pillar !== "Summary")
                                  current.goalId = selected.tabIds[0];
                                myContext.setCurrentGoal({ ...current });

                                checkOutsideClick(
                                  changeSelectedSection,
                                  section.pillarId
                                );
                              }
                            }}>
                            <div className="sectionDetails">
                              <i
                                style={
                                  (selectedSection &&
                                    selectedSection.pillarId) ===
                                    (section && section.pillarId)
                                    ? { color: "#2E2E38" }
                                    : section.pillarDisplayName === "Summary" &&
                                      !myContext.enableSummary
                                      ? { color: "#707070", opacity: "0.79" }
                                      : { color: "#707070" }
                                }
                                className={section.iconUrl}
                              />
                              <div
                                className="sectionName"
                                style={
                                  section.pillarDisplayName === "Summary" &&
                                    !myContext.enableSummary
                                    ? { color: "#707070", opacity: "0.79" }
                                    : {}
                                }>
                                {section ? section.pillarDisplayName : ""}
                              </div>
                              {section.pillarDisplayName !== "Summary" &&
                                progressPercentage[
                                activePPMDuserId && activePPMDuserId !== null
                                  ? key - 1
                                  : key
                                ] === 100 && (
                                  <i className="icon-verified-filled" />
                                )}
                            </div>
                            <div
                              className="completionBorder"
                              style={
                                section.pillarDisplayName !== "Summary"
                                  ? {
                                    width:
                                      progressPercentage[
                                      activePPMDuserId &&
                                        activePPMDuserId !== null
                                        ? key - 1
                                        : key
                                      ] + "%",
                                  }
                                  : {}
                              }></div>
                          </div>
                        );
                      })}
                </div>
              </SimpleBar>
              <ContentComponent
                {...props}
                {...ContentParams}
                assessmentSectionList={
                  allSectionDataFetch
                    ? allSectionDataFetch.data
                      ? allSectionDataFetch.data.data
                        ? allSectionDataFetch.data.data.assessmentDetails
                        : []
                      : []
                    : []
                }
                selectedSection={selectedSection}
                assesmentUserObj={assesmentUserObj}
                checkIfLastTab={checkIfLastTab}
                assessmentObj={assessmentObj}
                goToNext={goToNext}
                menuList={menuList}
                summaryData={summaryData}
                allOptionList={allOptionList}
                scrollableNodeRef={scrollableNodeRef}
                readOnly={false}
                allUserAssesmentData={allUserAssesmentData}
                handleImpactStmtFocusChecked={handleImpactStmtFocusChecked}
                impactStmtFocusChecked={impactStmtFocusChecked}
              />
            </SimpleBar>
          </div>
        )}
      </div>
      <Alert options={{ alertData, toogleShow }} />
      {/* {
        <ToastContainer
          toastStyle={{
            backgroundColor: "#b1de76",
            color: "white",
            fontFamily: "EYInterState-Regular",
            fontSize: "13px",
            textAlign: "center",
          }}
          transition={Flip}
          position="bottom-center"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      } */}
      <AlertSubmitPopup
        onlyAlert={false}
        header={alertHeader}
        submitBtnTxt={alertSubmitTxt}
        message={alertMessage}
        showAlertSubmitPopup={showAlertSubmitPopup}
        cancelAlertSubmitPopup={cancelAlertSubmitPopup}
        submitPopupChanges={submitPopupChanges}
        simpleClosePopup={() => myContext.setOpenSavePopup(false)}
      />
    </>
  );
};
const ContentComponent = (props) => {
  return props.selectedSection &&
    props.selectedSection.pillarDisplayName !== "Summary" ? (
    props.allUserAssesmentData &&
      props.allUserAssesmentData.data &&
      props.allUserAssesmentData.data.data &&
      props.assesmentUserObj &&
      props.assessmentObj.length > 0 ? (
      <QualitySection
        location={props.location}
        history={props.history}
        gaugeId="0"
        checkIfLastTab={props.checkIfLastTab}
        {...props}
        currentObj={props.assessmentObj.find(
          (x) => x.pillarId === props.selectedSection.pillarId
        )}
        currentUserObj={
          props.assesmentUserObj.length > 0 &&
          props.assesmentUserObj.filter(
            (x) => x.pillarId === props.selectedSection.pillarId
          )
        }
        scrollableNodeRef={props.scrollableNodeRef}
        handleImpactStmtFocusChecked={props.handleImpactStmtFocusChecked}
        impactStmtFocusChecked={props.impactStmtFocusChecked}
      />
    ) : (
      <div
        style={{
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        Data is loading ...{" "}
      </div>
    )
  ) : props.readOnly ? (
    <div
      style={{
        height: "80vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
      Data is loading ...{" "}
    </div>
  ) : (
    <SummaryView
      assessmentSectionList={props.assessmentSectionList}
      pillers={props.assessmentObj}
      goToNext={props.goToNext}
      checkIfLastTab={props.checkIfLastTab}
      history={props.history}
      menuList={props.menuList}
      currentUserArr={props.assesmentUserObj}
      scrollableNodeRef={props.scrollableNodeRef}
      summaryData={props.summaryData}
      allOptionList={props.allOptionList}
    />
  );
};
export default Survey;
