import React, { useState, useEffect, useContext } from "react";
import { Box, Grid } from "@material-ui/core";
import SimpleBar from "simplebar-react";
import AnsCard from "../../survey/AnsCard";

const FocusSummaryView = (props) => {
    //..Local States
    const { pillers,
        scrollableNodeRef,
        allOptionList,
        assessmentSectionList
    } = props;
    const [isExpandPiller, setIsExpandPiller] = useState({});
    const CHANGE_COLOR = "#87d3f2";
    const BASELINE_COLOR = "#9c82d4";
    const long_hyphen = " \u2014 ";
    //..Events
    useEffect(() => {
        //debugger;
        console.log(pillers);
        console.log(props.summaryData);
        console.log(allOptionList);
    },[]);
    //..Methods

    //..Render
    return (
        <>
            <div className="preview-download" id="pdf-div">
                <div className="survey-info-content">
                    <div className="summary-section" style={{paddingBottom:"0px"}}>
                        {props.summaryData &&
                            props.summaryData.map((pillerObj, index) => (
                                <>
                                    <div
                                        className="summary-item quality-section"
                                        style={{ width: "auto" }}>
                                        <div
                                            key={pillerObj._id}
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                height: "25px",
                                                alignItems: "center",
                                                marginBottom: `${!isExpandPiller[pillerObj.pillar] ? "0px" : "8px"
                                                    }`,
                                            }}>
                                            {!isExpandPiller[pillerObj.pillar] ? (
                                                <i
                                                    className="icon-plus pillarSymbol"
                                                    onClick={() => {
                                                        let temp = isExpandPiller;
                                                        pillers &&
                                                            pillers.length > 0 &&
                                                            pillers.forEach((item) => {
                                                                temp[item.pillar] = false;
                                                            });
                                                        temp[pillerObj.pillar] = !temp[pillerObj.pillar];
                                                        setIsExpandPiller({ ...temp });
                                                    }}></i>
                                            ) : (
                                                <i
                                                    className="icon-minus pillarSymbol"
                                                    onClick={() => {
                                                        let temp = isExpandPiller;
                                                        temp[pillerObj.pillar] = !temp[pillerObj.pillar];
                                                        setIsExpandPiller({ ...temp });
                                                    }}></i>
                                            )}
                                            <div
                                                className={"pillarHeading"}
                                                id={pillerObj.pillar + "scroll"}>
                                                {pillerObj.pillarDisplayName === "Exceptional client service"
                                                    ? "ECS"
                                                    : pillerObj.pillarDisplayName}
                                            </div>
                                        </div>
                                        <SimpleBar
                                            style={{
                                                maxHeight:
                                                    "100%" /* "225px"   `${!flag ? "100%" : "100%"}`*/,
                                                width: "100%",
                                                maxWidth: "100%",
                                                overflowX: "hidden",
                                            }}>
                                            {isExpandPiller[pillerObj.pillar] && (
                                                <div
                                                    className="txt-content"
                                                    style={{ width: "100%", marginLeft: "10px" }}>
                                                    {pillerObj.tabList &&
                                                        pillerObj.tabList.map((goal, index) => {
                                                            // if (index % 2 == 0)
                                                            return (
                                                                <>
                                                                {goal.focused &&
                                                                        goal.focused.length > 0 && (
                                                                            <p className="goalTitle">
                                                                                {(pillerObj.pillarDisplayName ===
                                                                                    "Exceptional client service"
                                                                                    ? "ECS "
                                                                                    : pillerObj.pillarDisplayName) +
                                                                                    long_hyphen +
                                                                                    goal.goal +
                                                                                    long_hyphen +
                                                                                    " Focused"}
                                                                            </p>
                                                                        )}
                                                                    {goal.focused &&
                                                                        goal.focused.map((impact, idx) => {
                                                                            // if (idx % 3 == 0)
                                                                            return (
                                                                                <Box
                                                                                    paddingBottom="0"
                                                                                    display="flex"
                                                                                    marginTop="10px"
                                                                                    fontSize="11px"
                                                                                    width="98%">
                                                                                    <AnsCard
                                                                                        isFromDiscussionGuide={true}
                                                                                        answer={impact.qstn}
                                                                                        showFullWidth={true}
                                                                                    />
                                                                                </Box>
                                                                            );
                                                                            // else return null;
                                                                        })}
                                                                    {goal.comitted &&
                                                                        goal.comitted.length > 0 && (
                                                                            <p className="goalTitle">
                                                                                {(pillerObj.pillarDisplayName ===
                                                                                    "Exceptional client service"
                                                                                    ? "ECS "
                                                                                    : pillerObj.pillarDisplayName) +
                                                                                    long_hyphen +
                                                                                    goal.goal +
                                                                                    long_hyphen +
                                                                                    " Committed"}
                                                                            </p>
                                                                        )}
                                                                    {goal.comitted &&
                                                                        goal.comitted.map((impact, idx) => {
                                                                            // if (idx % 3 == 0)
                                                                            return (
                                                                                <Box
                                                                                    paddingBottom="0"
                                                                                    display="flex"
                                                                                    marginTop="10px"
                                                                                    fontSize="11px"
                                                                                    width="98%">
                                                                                    <AnsCard
                                                                                        answer={impact.qstn}
                                                                                        showFullWidth={true}
                                                                                    />
                                                                                </Box>
                                                                            );
                                                                            // else return null;
                                                                        })}
                                                                    {goal.remaining &&
                                                                        goal.remaining.length > 0 && (
                                                                            <p className="goalTitle">
                                                                                {(pillerObj.pillarDisplayName ===
                                                                                    "Exceptional client service"
                                                                                    ? "ECS "
                                                                                    : pillerObj.pillarDisplayName) +
                                                                                    long_hyphen +
                                                                                    goal.goal +
                                                                                    long_hyphen +
                                                                                    " Uncommitted"}
                                                                            </p>
                                                                        )}
                                                                    {goal.remaining &&
                                                                        goal.remaining.map((impact, idx) => {
                                                                            // if (idx % 3 == 0)
                                                                            return (
                                                                                <Box
                                                                                    paddingBottom="0"
                                                                                    display="flex"
                                                                                    marginTop="10px"
                                                                                    fontSize="11px"
                                                                                    width="98%">
                                                                                    <AnsCard
                                                                                        answer={impact.qstn}
                                                                                        showFullWidth={true}
                                                                                    />
                                                                                </Box>
                                                                            );
                                                                            // else return null;
                                                                        })}
                                                                    {goal.difference &&
                                                                        goal.difference.length > 0 && (
                                                                            <Box
                                                                                paddingBottom="0"
                                                                                display="flex"
                                                                                width="95%"
                                                                                flexDirection="row"
                                                                                // alignItems="end"
                                                                                justifyContent="end"
                                                                                marginTop="-5px"
                                                                                marginBottom=" -5px"
                                                                                alignItems="center">
                                                                                <p className="goalTitle">
                                                                                    {(pillerObj.pillarDisplayName ===
                                                                                        "Exceptional client service"
                                                                                        ? "ECS"
                                                                                        : pillerObj.pillarDisplayName) +
                                                                                        long_hyphen +
                                                                                        goal.goal +
                                                                                        long_hyphen +
                                                                                        "Changed"}
                                                                                </p>
                                                                                {isExpandPiller[pillerObj.pillar] && (
                                                                                    <Box
                                                                                        paddingBottom="0"
                                                                                        display="flex"
                                                                                        width="50%"
                                                                                        flexDirection="row-reverse"
                                                                                        justifyContent="end">
                                                                                        <div
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                alignItems: "center",
                                                                                                justifyContent: "center",
                                                                                            }}>
                                                                                            <div className="baseLineLegend">
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div className="legendText">
                                                                                                Baseline
                                                                                            </div>
                                                                                            <div className="currentLegend">
                                                                                                &nbsp;
                                                                                            </div>
                                                                                            <div className="legendText">
                                                                                                Changed Value
                                                                                            </div>
                                                                                        </div>
                                                                                    </Box>
                                                                                )}
                                                                            </Box>
                                                                        )}
                                                                    {goal.difference &&
                                                                        goal.difference.map((impact, idx) => {
                                                                            // if (idx % 3 !== 0)
                                                                            return (
                                                                                <>
                                                                                    <Box
                                                                                        paddingBottom="0"
                                                                                        display="flex"
                                                                                        alignItems="center"
                                                                                        marginTop="10px"
                                                                                        fontSize="11px"
                                                                                        width="98%">
                                                                                        <AnsCard
                                                                                            answer={impact.qstn}
                                                                                            showFullWidth={true}
                                                                                            wordBreak={false}
                                                                                        />
                                                                                    </Box>
                                                                                    <div
                                                                                        style={{
                                                                                            display: "flex",
                                                                                        }}>
                                                                                        <Box
                                                                                            width="47.70%"
                                                                                            paddingBottom="0"
                                                                                            display="flex"
                                                                                            fontSize="11px"
                                                                                            alignItems="center"
                                                                                            justifyContent="center"
                                                                                            height="28px"
                                                                                            style={{
                                                                                                background: `${BASELINE_COLOR}`,
                                                                                                color: "white",
                                                                                            }}>
                                                                                            {goal.uiType === "check_box"
                                                                                                ? allOptionList[impact.prev] ===
                                                                                                    "True"
                                                                                                    ? "Selected"
                                                                                                    : "Un-Selected"
                                                                                                : allOptionList[impact.prev]}
                                                                                        </Box>
                                                                                        <Box
                                                                                            width="47.70%"
                                                                                            paddingBottom="0"
                                                                                            height="28px"
                                                                                            fontSize="11px"
                                                                                            justifyContent="center"
                                                                                            display="flex"
                                                                                            alignItems="center"
                                                                                            background={CHANGE_COLOR}
                                                                                            style={{
                                                                                                background: `${CHANGE_COLOR}`,
                                                                                                color: "rgba(46, 46, 56, 0.8)",
                                                                                            }}>
                                                                                            {goal.uiType === "check_box"
                                                                                                ? allOptionList[
                                                                                                    impact.current
                                                                                                ] === "True"
                                                                                                    ? "Selected"
                                                                                                    : "Un-Selected"
                                                                                                : allOptionList[impact.current]}
                                                                                        </Box>
                                                                                    </div>
                                                                                </>
                                                                            );
                                                                            // else return null;
                                                                        })}
                                                                </>
                                                            );
                                                        })}
                                                </div>
                                            )}
                                        </SimpleBar>
                                    </div>
                                </>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FocusSummaryView;