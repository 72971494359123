import React, { useState, useMemo, useEffect, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../../SideMenu/SideMenu";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import { makeStyles } from "@material-ui/core/styles";
import {
  Select,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  MenuItem,
  InputLabel,
  TextField
} from "@material-ui/core";

import "../Admin.scss";
import "./Reports.scss";
import * as myConsts from "../../../constants/constants";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import SimpleBar from "simplebar-react";
import classNames from "classnames";
import Loader from "../../Loader/Loader";
import ReportDownload from "./ReportDownload";
import BasicReportingTable from "./BasicReporting/BasicReportingTable";
import FilterComponent from "./BasicReporting/FilterComponent/FilterComponent";
import DropdownComponent from "../AdminGeneralParam/DropdownComponent/DropdownComponent";
import { getFinancialYears } from "../../../utils/utils";
import AppContext from "../../../utils/appContext";
//import DownloadTemplateOption3 from "../../DiscussionGuide/FinalizationForm/DownloadTemplateOption3";
import DiscussionGuideData from './BasicReporting/DiscussionGuideData/DiscussionGuideData';
/* Admin can view the reports for BoB, Assessment and Lead reviewer approvals in graphical 
representation. He has the option to view the reports in Year wise and Period wise. Also, admin can 
download the reports in the excel format */

const useStyles = makeStyles((theme) => ({
  header: {
    height: "27px",
    paddingBottom: "4px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontWeight: "600",
    borderBottom: "1px solid #00000029",

    paddingLeft: "5px",
  },
  subHeader: {
    height: "27px",
    paddingBottom: "4px",
    display: "flex",
    fontWeight: "600",
    justifyContent: "flex-start",
    alignItems: "center",

    paddingLeft: "5px",
  },
  paper2: {
    backgroundColor: "white",
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 2, 2),
    width: 375,
    maxHeight: 479,
    overflow: "hidden",
    overflowX: "hidden",
    fontSize: "12px",
  },
  popper: {
    zIndex: 100,
    left: "-10px!important",
    top: "8px!important",
    '&[x-placement*="top-end"]': {
      top: "-12px!important",
    },
    '&[x-placement*="bottom-end"]': {
      top: "9px!important",
    },
    '&[x-placement*="bottom"] $arrow': {
      top: 2,
      left: "294px!important",
      marginTop: "-0.9em",
      width: "6em",
      height: "2em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
}));

const Reports = (props) => {
  const crumbs = [{ name: "Admin", path: myConsts.routeAdmin }];
  const dispatch = useDispatch();
  const [allRegionData, setAllRegionData] = useState({
    total: 0,
    statusData: [],
  });
  const [anchor, setanchor] = React.useState(null);
  const openpopUp = Boolean(anchor);
  const [viewReport, setViewReport] = useState(false);
  //..DISABLE BOB :: COMMENTED BELOW CODE IF IT WILL REQUIRE TO ENABLE IN FUTURE
  const [navItems, setNavItems] = useState([
    // {
    //   label: "Book of Business",
    //   api: "getBoBGraphData",
    //   isSelected: true,
    //   reportApi: "getBoBReport",
    // },
    // {
    //   label: "Assessment",
    //   api: "getAssessmentGraphData",
    //   isSelected: true,
    //   reportApi: "getAssessmentReport",
    // },
    // {
    //   label: "Lead Reviewer Approvals",
    //   api: "getLeadReviewerGraphData",
    //   isSelected: false,
    //   reportApi: "getLeadReviewerReport",
    // },
    {
      label: "Download and Bulk Upload Finalization Form Commentary",
      api: "getDiscussionGuideGraphData",
      isSelected: true,
      reportApi: "getDiscussionGuideReport",
    },
    {
      label: "Lead Reviewer Finalization Form Commentary",
      api: "getDiscussionGuideGraphData",
      isSelected: false,
      reportApi: "getDiscussionGuideReport",
    },
    // {
    //   label: "PPMD Discussion Guide",
    //   api: "getPPMDDiscussionGuideGraphData",
    //   isSelected: false,
    //   reportApi: "getPPMDDiscussionGuideReport",
    // },
  ]);
  const myContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [currentYear, setCurrentYear] = useState(`FY ${myContext.financialYear}`);
  const [currentPeriod, setCurrentPeriod] = useState("Mid Year");
  const [dropdownValue, setDropdownValue] = useState("All Regions");
  const [filterList, setFilterList] = useState([]);
  const [filterListBulkUpload, setFilterListBulkUpload] = useState([]);
  const [CompleteFilterList, setCompleteFilterList] = useState({});
  const [appliedFilterList, setappliedFilterList] = useState({});

  const reportsData = useSelector((state) => state.reportsData);
  const excelReportData = useSelector((state) => state.excelReportData);

  const [errorMessages, setErrorMessages] = useState({
    dropdownValue: "",
  });
  const classes = useStyles();
  const yearOptions = getFinancialYears(myContext.financialYear);

  const periodOptions = useMemo(() => {
    return ["Plan", "Mid Year", "Year End"];
  }, []);

  const colorCodes = useMemo(() => {
    return [
      {
        status: "Not Started",
        color: "#FFE600",
      },
      {
        status: "In Progress",
        color: "#2E2E38",
      },
      {
        status: "In Draft",
        color: "#e21e1e",
      },
      {
        status: "Submitted",
        color: "#FF9831",
      },
      {
        status: "Requested Change",
        color: "#B14891",
      },
      {
        status: "Approved",
        color: "#B1DE76",
      },
      {
        status: "Completed",
        color: "#B1DE76",
      },
    ];
  }, []);

  const leadReviewerColorCodes = useMemo(() => {
    return [
      {
        status: "In Draft",
        color: "#E21E1E",
      },
      {
        status: "Submitted",
        color: "#FFC107",
      },
      {
        status: "Completed",
        color: "#2DB757",
      },
      {
        status: "Requested Change",
        color: "#724BC3",
      },
    ];
  }, []);

  const getStatusColor = useCallback(
    (status) => {
      const selectedTab = navItems.find((item) => item.isSelected).label;
      if (selectedTab === "Lead Reviewer Approvals") {
        return leadReviewerColorCodes.find((color) => color.status === status)
          ?.color;
      } else {
        return colorCodes.find((color) => color.status === status)?.color;
      }
    },
    [navItems, colorCodes, leadReviewerColorCodes]
  );

  const getPercentage = useCallback((total, count) => {
    return count !== 0 ? ((count / total) * 100).toFixed(1) : 0;
  }, []);
  const clickAwayHandler = () => {
    setanchor(null);
  };

  const getBarChartStyles = useCallback(
    (status, total, count) => {
      return {
        backgroundColor: getStatusColor(status),
        height: getPercentage(total, count) * 1.5 + 1 + "px",
      };
    },
    [getPercentage, getStatusColor]
  );

  const getTotalCountPos = useCallback((total) => {
    return {
      bottom: Math.round(total * 0.6 - 8) + "px",
    };
  }, []);

  const getStatusBorderColor = useCallback(
    (status) => {
      return {
        borderTop: `5px solid ${getStatusColor(status)}`,
      };
    },
    [getStatusColor]
  );

  const getReportsData = (api) => {
    const financialYear = currentYear.split(" ")[1];
    const timePeriod = currentPeriod.split(" ");
    setIsLoading(true);
    dispatch({
      type: "GET_REPORTS_DATA",
      payload: {
        financialYear,
        timePeriod:
          timePeriod.length > 1
            ? timePeriod[0] + "-" + timePeriod[1]
            : timePeriod[0],
        api,
      },
    });
  };

  const setNavItem = useCallback(
    (item) => {
      const copyNavItems = [...navItems];
      copyNavItems.forEach((nav) => {
        if (nav.label === item) {
          nav.isSelected = true;
        } else {
          nav.isSelected = false;
        }
      });
      setNavItems(copyNavItems);
    },
    [navItems]
  );

  useEffect(() => {
    let allRegionData = [];
    let totalCount = 0;

    if (reportsData.data && reportsData.data.length > 0) {
      reportsData.data &&
        reportsData.data.length > 0 &&
        reportsData.data[0].statusData &&
        reportsData.data[0].statusData.forEach((data, index) => {
          allRegionData.push({
            label: data.Status,
            count: 0,
          });
        });
      if (dropdownValue === "All Regions") {
        reportsData.data &&
          reportsData.data.length > 0 &&
          reportsData.data.forEach((data) => {
            totalCount = totalCount + data.Total;
            data.statusData.forEach((status, index) => {
              allRegionData[index].count =
                allRegionData[index].count + status.count;
            });
          });
      } else if (dropdownValue === "US-Central") {
        let ind;
        reportsData.data &&
          reportsData.data.length > 0 &&
          reportsData.data.forEach((elem) => {
            if (elem.Region === "US-Central") {
              ind = reportsData.data.indexOf(elem);
            }
          });
        totalCount = reportsData.data[ind].Total;
        reportsData.data[ind].statusData.forEach((status, index) => {
          allRegionData[index].count =
            allRegionData[index].count + status.count;
        });
      } else if (dropdownValue === "US-East") {
        let ind;
        reportsData.data &&
          reportsData.data.length > 0 &&
          reportsData.data.forEach((elem) => {
            if (elem.Region === "US-East") {
              ind = reportsData.data.indexOf(elem);
            }
          });
        totalCount = reportsData.data[ind].Total;
        reportsData.data[ind].statusData.forEach((status, index) => {
          allRegionData[index].count =
            allRegionData[index].count + status.count;
        });
      } else if (dropdownValue === "US-West") {
        let ind;
        reportsData.data.forEach((elem) => {
          if (elem.Region === "US-West") {
            ind = reportsData.data.indexOf(elem);
          }
        });
        totalCount = reportsData.data[ind].Total;
        reportsData.data[ind].statusData.forEach((status, index) => {
          allRegionData[index].count =
            allRegionData[index].count + status.count;
        });
      } else if (dropdownValue === "National Assurance BUs") {
        let ind;
        reportsData.data.forEach((elem) => {
          if (elem.Region === "National Assurance BUs") {
            ind = reportsData.data.indexOf(elem);
          }
        });
        totalCount = reportsData.data[ind].Total;
        reportsData.data[ind].statusData.forEach((status, index) => {
          allRegionData[index].count =
            allRegionData[index].count + status.count;
        });
      } else if (dropdownValue === "FSO Americas") {
        let ind;
        reportsData.data.forEach((elem) => {
          if (elem.Region === "FSO Americas") {
            ind = reportsData.data.indexOf(elem);
          }
        });
        totalCount = reportsData.data[ind].Total;
        reportsData.data[ind].statusData.forEach((status, index) => {
          allRegionData[index].count =
            allRegionData[index].count + status.count;
        });
      } else if (dropdownValue === "CBS BUs") {
        let ind;
        reportsData.data.forEach((elem) => {
          if (elem.Region === "CBS BUs") {
            ind = reportsData.data.indexOf(elem);
          }
        });
        totalCount = reportsData.data[ind].Total;
        reportsData.data[ind].statusData.forEach((status, index) => {
          allRegionData[index].count =
            allRegionData[index].count + status.count;
        });
      }

      setAllRegionData({
        total: totalCount,
        statusData: allRegionData,
      });
    }
  }, [reportsData, dropdownValue]);
  useEffect(() => {
    let temp = ["All Regions"];
    let temp2 = [{ name: "Select All", isSelected: true }];
    let temp3 = [{ name: "Select All", isSelected: true }];
    let temp4 = [{ name: "Select All", isSelected: true }];
    let temp5 = [{ name: "Select All", isSelected: true }];
    // console.log(reportsData);
    if (reportsData.data && reportsData.data.length) {
      reportsData.data.forEach((element, index) => {
        if (!temp.includes(element)) {
          temp.push(element.Region);
          temp2.push({ isSelected: true, name: element.Region });
          temp4.push({ isSelected: true, name: element.Region });
          if (index == 0) {
            element.statusData &&
              element.statusData.map((i) => {
                temp3.push({ isSelected: true, name: i.Status });
                temp5.push({ isSelected: true, name: i.Status });
              });
          }
        }
      });
    }

    // console.log(temp3);
    setappliedFilterList({ region: temp2.slice(), status: temp3.slice() });
    setCompleteFilterList({ region: temp4.slice(), status: temp5.slice() });
    setDropdownValue("All Regions");
  }, [reportsData]);
  const getData = () => {
    //debugger;
    let filterListStatus = [];
    appliedFilterList.status.forEach((i) => {
      if (i.isSelected) filterListStatus.push(i.name);
    });
    let filterListRegion = [];
    appliedFilterList.region.map((i) => {
      if (i.isSelected) filterListRegion.push(i.name);
    });
    // console.log(filterListStatus, filterListRegion);
    if (navItems.find((item) => item.isSelected).label === "Assessment") {
      return excelReportData &&
        excelReportData.data &&
        excelReportData.data.AssessmentReport
        ? excelReportData.data.AssessmentReport.filter(
          (item) =>
            (filterListRegion.length == 0 ||
              filterListRegion.includes(item.Region)) &&
            (filterListStatus.length == 0 ||
              filterListStatus.includes(item["Assessment Status"]))
        )
        : [];
    } else if (
      navItems.find((item) => item.isSelected).label ===
      "Lead Reviewer Finalization Form Commentary"
    ) {
      return excelReportData &&
        excelReportData.data &&
        excelReportData.data.DiscussionGuideReport
        ? excelReportData.data.DiscussionGuideReport.filter(
          (item) =>
            (filterListRegion.length == 0 ||
              filterListRegion.includes(item.Region)) &&
            (filterListStatus.length == 0 ||
              filterListStatus.includes(item["Reviewer Submitted Status"]))
        )
        : [];
    } else if (
      navItems.find((item) => item.isSelected).label ===
      "PPMD Discussion Guide"
    ) {
      return excelReportData &&
        excelReportData.data &&
        excelReportData.data.PPMDDiscussionGuideReport
        ? excelReportData.data.PPMDDiscussionGuideReport.filter(
          (item) =>
            (filterListRegion.length == 0 ||
              filterListRegion.includes(item.Region)) &&
            (filterListStatus.length == 0 ||
              filterListStatus.includes(item["Submitted Status"]))
        )
        : [];
    } else if (
      navItems.find((item) => item.isSelected).label ===
      "Lead Reviewer Approvals"
    ) {
      return excelReportData &&
        excelReportData.data &&
        excelReportData.data.LeadReport
        ? excelReportData.data.LeadReport.filter(
          (item) =>
            (filterListRegion.length == 0 ||
              filterListRegion.includes(item.Region)) &&
            (filterListStatus.length == 0 ||
              filterListStatus.includes(item["Final Status"]))
        )
        : [];
    } else if (
      navItems.find((item) => item.isSelected).label === "Book of Business"
    ) {
      return excelReportData &&
        excelReportData.data &&
        excelReportData.data.BoBreport
        ? excelReportData.data.BoBreport.filter(
          (item) =>
            (filterListRegion.length == 0 ||
              filterListRegion.includes(item.Region)) &&
            (filterListStatus.length == 0 ||
              filterListStatus.includes(item["BoB Status"]))
        )
        : [];
    }
  };

  const drawChart = useCallback(() => {
    const c = document.getElementById("reportsChart");
    if (c) {
      const ctx = c.getContext("2d");

      ctx.clearRect(0, 0, 800, 350);
      let linePos = 290;
      let leftValue = 0;
      let rightValue = 0;
      for (let i = 0; i < 9; i++) {
        ctx.beginPath();
        ctx.fillStyle = "#2E2E38";
        ctx.fillText(leftValue, 25, linePos + 2);
        ctx.fillText(rightValue + "%", 760, linePos + 2);
        ctx.moveTo(50, linePos);
        ctx.lineTo(750, linePos);

        ctx.lineWidth = 2;
        ctx.strokeStyle = "#EDEDF2";
        ctx.stroke();
        linePos = linePos - 30;
        leftValue = leftValue + 50;
        rightValue = rightValue + 20;
      }
      ctx.closePath();
      for (let i = 0; i < 5; i++) {
        const currentElem = document
          .getElementById("totalCount" + i)
          ?.getBoundingClientRect();
        const nextElem = document
          .getElementById("totalCount" + (i + 1))
          ?.getBoundingClientRect();
        const barChartContainer = document
          .getElementById("barChartContainer")
          ?.getBoundingClientRect();

        ctx.beginPath();
        ctx.strokeStyle = "#F08E8E";

        if (dropdownValue === "All Regions") {
          ctx.moveTo(
            currentElem.left - barChartContainer.left + 10,
            (400 - reportsData.data[i].Total) * 0.6 + 50
          );

          ctx.lineTo(
            nextElem.left - barChartContainer.left + 10,
            (400 - reportsData.data[i + 1].Total) * 0.6 + 50
          );
          ctx.stroke();
        }
      }
    }
  }, [reportsData, dropdownValue]);

  const checkStatus = useCallback(
    (status) => {
      const selectedTab = navItems.find((item) => item.isSelected).label;
      if (status === "Completed") {
        return "Approved";
      } else if (
        status === "Submitted" &&
        selectedTab === "Lead Reviewer Approvals"
      ) {
        return "Pending Approval";
      } else {
        return status;
      }
    },
    [navItems]
  );

  useEffect(() => {
    const api = navItems.find((item) => item.isSelected).api;
    getReportsData(api);
  }, [navItems, currentYear, currentPeriod]);

  useEffect(() => {
    if (reportsData.data.length !== 0) {
      drawChart();
      // getTotalCount();
      getFilterOptions();
    }
    if (!reportsData.fetching) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [reportsData]);

  useEffect(() => {
    if (!excelReportData.fetching) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [excelReportData, setIsLoading]);

  // useEffect(() => {
  //   // console.log("use", appliedFilterList);
  // }, [appliedFilterList && appliedFilterList.region]);

  const selectDropdown = (value) => {
    //debugger;
    setDropdownValue(value);
    let temp = CompleteFilterList.region;
    // console.log(value);
    if (value === "All Regions") {
      temp.forEach((i, ind) => (temp[ind].isSelected = true));
    } else {
      temp.forEach((i, ind) => {
        if (i.name === value) temp[ind].isSelected = true;
        else temp[ind].isSelected = false;
      });
    }
    // console.log(temp);
    setCompleteFilterList({
      status: JSON.parse(JSON.stringify(CompleteFilterList.status)),
      region: JSON.parse(JSON.stringify(temp)),
    });
    setappliedFilterList({
      status: JSON.parse(JSON.stringify(appliedFilterList.status)),
      region: JSON.parse(JSON.stringify(temp)),
    });
  };
  const enableFilterApply = () => {
    let flag = false;
    // console.log(CompleteFilterList, appliedFilterList);
    CompleteFilterList &&
      CompleteFilterList.region &&
      CompleteFilterList.region.forEach((item, index) => {
        if (appliedFilterList.region[index].isSelected !== item.isSelected)
          flag = true;
      });
    CompleteFilterList &&
      CompleteFilterList.status &&
      CompleteFilterList.status.forEach((item, index) => {
        if (appliedFilterList.status[index].isSelected !== item.isSelected)
          flag = true;
      });
    return flag;
  };

  const getFilterOptions = useCallback(() => {
    let temp = ["All Regions"];
    let temp2 = [{ name: "Select All", isSelected: true }];
    let temp3 = [{ name: "Select All", isSelected: true }];
    // console.log(reportsData);
    if (reportsData.data && reportsData.data.length) {
      reportsData.data.forEach((element, index) => {
        if (!temp.includes(element)) {
          temp.push(element.Region);
          temp2.push({ isSelected: true, name: element.Region });
          if (index == 0) {
            element.statusData &&
              element.statusData.map((i) => {
                temp3.push({ isSelected: true, name: i.Status });
              });
          }
        }
      });
    }
    setFilterList(temp);
    setFilterListBulkUpload(temp);
    // console.log(temp3);
    // setCompleteFilterList({ region: temp2, status: temp3 });
    // setappliedFilterList({ region: temp2, status: temp3 });
  });

  return (
    <>
      {isLoading && <Loader />}
      <div id="dvContent" style={{ display: "none" }}></div>
      {/* <div className="admin-container reports"> */}
      {/* <div className="admin-info-main-sidemenu">
          <SideMenu
            minimized={true}
            isminimizedMainMenu={true}
            isMainMenu={true}
            sideMenuItemSelect="Admin"
          />
        </div> */}
      {/* <div className="admin-content"> */}
      {/* <div className="second-header admin-crumbs">
            <i
              className="icon-arrow-left"
              onClick={() => props.history.push(myConsts.routeAdmin)}
            />
            <div className="admin-crumb-txt">
              <Breadcrumb
                crumbs={crumbs}
                isAdmin={true}
                isInnerAdmin={true}
                {...props}
              />
              <h4>Reports</h4>
            </div>
          </div> */}
      <div
        className="reports-nav-bar"
        style={{ height: `${viewReport ? "130px" : "70px"}` }}>
        <div className="first-row">
          <div className="nav-item">PPMD</div>
          {
            navItems.find((item) => item.isSelected).label === "Lead Reviewer Finalization Form Commentary"
              || navItems.find((item) => item.isSelected).label === "PPMD Discussion Guide"
              || navItems.find((item) => item.isSelected).label === "Download and Bulk Upload Finalization Form Commentary" ? (
              <></>
            )
              : (
                <div className="select-container">
                  <div className="input-container">
                    <label htmlFor="year">Select Year</label>
                    <select
                      name="year"
                      id="year"
                      disabled={navItems.find((item) => item.isSelected).label === "Lead Reviewer Finalization Form Commentary"
                        || navItems.find((item) => item.isSelected).label === "PPMD Discussion Guide"
                        || navItems.find((item) => item.isSelected).label === "Download and Bulk Upload Finalization Form Commentary" ? true : false}
                      value={currentYear}
                      onChange={(e) => setCurrentYear(e.target.value)}>
                      {yearOptions.map((option, index) => (
                        <option value={option} key={index}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="input-container">
                    <label htmlFor="period">Select Period</label>
                    <select
                      name="period"
                      id="period"
                      disabled={navItems.find((item) => item.isSelected).label === "Lead Reviewer Finalization Form Commentary"
                        || navItems.find((item) => item.isSelected).label === "PPMD Discussion Guide"
                        || navItems.find((item) => item.isSelected).label === "Download and Bulk Upload Finalization Form Commentary" ? true : false}
                      value={currentPeriod}
                      onChange={(e) => setCurrentPeriod(e.target.value)}>
                      {periodOptions.map((option, index) => (
                        <option value={option} key={index}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )
          }
        </div>
        {
          navItems.find((item) => item.isSelected).label === "Download and Bulk Upload Finalization Form Commentary" ?
            (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#ededf2",
                  }}>
                  <div className="second-row">
                    {navItems.map((nav, index) => (
                      <div
                        key={index}
                        className={classNames("nav-item", {
                          "nav-item-selected": nav.isSelected,
                        })}
                        onClick={() => {
                          setNavItem(nav.label);
                        }}>
                        {nav.label}
                      </div>
                    ))}
                  </div>
                  <div className="second-row-view-all">
                    <DiscussionGuideData
                      filterList={filterListBulkUpload}
                      reportApi={
                        navItems.find((item) => item.isSelected).reportApi
                      }
                      currentPeriod={currentPeriod}
                      currentYear={currentYear}
                      dropdownValue={dropdownValue}
                      appliedFilterList={appliedFilterList}
                      history={props.history}
                    />
                  </div>
                  <div>
                  </div>
                </div>
              </>
            )
            :
            (
              <>
                {!viewReport ? (
                  <div className="second-row">
                    {navItems.map((nav, index) => (
                      // (nav.label === "Lead Reviewer Discussion Guide"
                      //   || nav.label === "PPMD Discussion Guide"
                      //   || nav.label === "Download and Bulk Upload Discussion Guide")
                      //   && myContext.currentPeriod !== myConsts.TimePeriodYearEnd ? (
                      //   <></>
                      // )
                      //   :
                      //   (
                          <div
                            key={index}
                            className={classNames("nav-item", {
                              "nav-item-selected": nav.isSelected,
                            })}
                            onClick={() => {
                              setNavItem(nav.label);
                            }}>
                            {nav.label}
                          </div>
                        // )
                    ))}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      backgroundColor: "#ededf2",
                    }}>
                    <div className="second-row">
                      {navItems.map((nav, index) => (
                        <div
                          key={index}
                          className={classNames("nav-item", {
                            "nav-item-selected": nav.isSelected,
                          })}
                          onClick={() => {
                            setNavItem(nav.label);
                          }}>
                          {nav.label}
                        </div>
                      ))}
                    </div>
                    <div className="second-row-view-all">
                      <div className="left-sec">
                        <span
                          className="  eyaa-arrow-left"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setViewReport(false);
                            let temp1 = CompleteFilterList.status;
                            let temp2 = CompleteFilterList.region;
                            temp1.forEach((i) => (i.isSelected = true));
                            temp2.forEach((i) => (i.isSelected = true));
                            setCompleteFilterList({
                              status: JSON.parse(JSON.stringify(temp1)),
                              region: JSON.parse(JSON.stringify(temp2)),
                            });
                            setappliedFilterList({
                              status: JSON.parse(JSON.stringify(temp1)),
                              region: JSON.parse(JSON.stringify(temp2)),
                            });
                            setDropdownValue("All Regions");
                          }}></span>
                        <span>Reports</span>
                        <span
                          className="eyaa-chevron-right"
                          style={{ fontSize: "10px" }}></span>

                        <span>All List</span>
                      </div>
                      <div className="right-sec">
                        <div className="btnSection">
                          <ReportDownload
                            reportApi={
                              navItems.find((item) => item.isSelected).reportApi
                            }
                            currentPeriod={currentPeriod}
                            currentYear={currentYear}
                            dropdownValue={dropdownValue}
                            appliedFilterList={appliedFilterList}
                          />
                          {/* </>
                      )
                  } */}
                        </div>

                        <span
                          className="icon-filterclear"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (anchor) setanchor(null);
                            else setanchor(e.currentTarget);
                          }}></span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
        }
      </div>
      {
        navItems.find((item) => item.isSelected).label === "Download and Bulk Upload Finalization Form Commentary" ?
          (
            <>
            </>
          )
          :
          (
            <>
              {reportsData.data && reportsData.data.length > 0 ? (
                <div className="reports-container">
                  {!viewReport ? (
                    <div className="chart-container">
                      <div className="bar-chart-container" id="barChartContainer">
                        <canvas id="reportsChart" width="800" height="300"></canvas>
                        <div className="bar-chart-wrapper">
                          {reportsData.data.length > 0 &&
                            reportsData.data.map((data, index) =>
                              dropdownValue === "All Regions" ? (
                                <div className="bar-chart" key={index}>
                                  <p>{data.Region}</p>
                                  <div
                                    className="total-count"
                                    id={`totalCount${index}`}
                                    style={getTotalCountPos(data.Total)}>
                                    {data.Total}
                                  </div>
                                  <div className="bar-content">
                                    {data.statusData.map((status, index) => (
                                      <React.Fragment key={index}>
                                        <div
                                          className="bar"
                                          style={getBarChartStyles(
                                            status.Status,
                                            data.Total,
                                            status.count
                                          )}>
                                          <p>
                                            {getPercentage(data.Total, status.count)}%
                                          </p>
                                        </div>
                                      </React.Fragment>
                                    ))}
                                  </div>
                                </div>
                              ) : (
                                data.Region === dropdownValue && (
                                  <div
                                    className="bar-chart single-chart-width"
                                    key={index}>
                                    <p style={{ left: "70px" }}>{data.Region}</p>
                                    <div
                                      className={"total-count add-width"}
                                      id={`totalCount${index}`}
                                      style={getTotalCountPos(data.Total)}>
                                      {data.Total}
                                    </div>
                                    <div className="bar-content">
                                      {data.statusData.map((status, index) => (
                                        <React.Fragment key={index}>
                                          <div
                                            className="bar single-region"
                                            style={getBarChartStyles(
                                              status.Status,
                                              data.Total,
                                              status.count
                                            )}>
                                            <p>
                                              {getPercentage(data.Total, status.count)}%
                                            </p>
                                          </div>
                                        </React.Fragment>
                                      ))}
                                    </div>
                                  </div>
                                )
                              )
                            )}
                        </div>
                        <div className="legend-container">
                          {reportsData.data.length > 0 &&
                            reportsData.data[0].statusData.map((data, index) => (
                              <div className="legend" key={index}>
                                <div
                                  className="legend-icon"
                                  style={{
                                    backgroundColor: getStatusColor(data.Status),
                                  }}></div>
                                <div className="legend-text">
                                  {`${checkStatus(data.Status)} (%)`}
                                </div>
                              </div>
                            ))}
                          <div className="legend">
                            <div className="legend-icon"></div>
                            <div className="legend-text">Grand Total</div>
                          </div>
                        </div>
                      </div>
                      <div className="all-regions">
                        <div className="header">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "-9px",
                              marginTop: "-23px",
                              width: "340px",
                              height: "50px",
                            }}>
                            <DropdownComponent
                              label={""}
                              defaultValue={
                                dropdownValue ? dropdownValue : "All Regions"
                              }
                              selectOnChange={(dummy, value) => {
                                selectDropdown(value);
                              }}
                              varName="dropdownValue"
                              list={filterList}
                              errorMessage={errorMessages}
                              disabled={false}
                              customClass={"bgGreenDropdown"}
                            />
                          </div>
                        </div>
                        <div className="grand-total">
                          <div className="total">
                            <span>{allRegionData.total}</span>
                            <span>Grand Total</span>
                          </div>
                        </div>
                        <div className="status-cover">
                          {allRegionData.statusData.length > 0 &&
                            allRegionData.statusData.map((data, index) => (
                              <div
                                className="status"
                                style={getStatusBorderColor(data.label)}
                                key={index}>
                                <span>{data.count}</span>
                                <span>{checkStatus(data.label)}</span>
                              </div>
                            ))}
                        </div>
                        <div className="btnSection">
                          <ReportDownload
                            reportApi={
                              navItems.find((item) => item.isSelected).reportApi
                            }
                            currentPeriod={currentPeriod}
                            currentYear={currentYear}
                            dropdownValue={dropdownValue}
                            appliedFilterList={appliedFilterList}
                          />
                          <button
                            className="width2"
                            onClick={() => setViewReport(true)}>
                            <span className="eyaa-image-25"></span>
                            <span>View Report</span>
                          </button>
                        </div>
                        {
                          navItems.find((item) => item.isSelected).reportApi === "getDiscussionGuideReport"
                            || navItems.find((item) => item.isSelected).reportApi === "getPPMDDiscussionGuideReport" ? (
                            <div
                              style={{
                                fontSize: "0.90rem",
                                color: "#0000ee",
                                fontFamily: "EYInterState-Regular",
                                fontStyle: "italic"
                              }}>
                              {`* Important Note:- This will download ${navItems.find((item) => item.isSelected).reportApi === "getDiscussionGuideReport" ? "lead reviewer" : "PPMD"} submitted finalization form commentary`}
                            </div>
                          )
                            :
                            null
                        }
                      </div>
                    </div>
                  ) : (
                    <BasicReportingTable
                      label={navItems.find((item) => item.isSelected).label}
                      dataSource={getData()}
                      isLoading={isLoading}
                    />
                  )}

                  <Popper
                    placement="bottom"
                    id="customPopupRoot"
                    className={classes.popper}
                    open={openpopUp}
                    anchorEl={anchor}
                    modifiers={{
                      flip: {
                        enabled: true,
                      },
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: "scrollParent",
                      },
                    }}>
                    <ClickAwayListener onClickAway={clickAwayHandler}>
                      <Paper className={classes.paper2}>
                        <SimpleBar style={{ maxHeight: "465px" }}>
                          <div
                            style={{ margin: "10px", cursor: "pointer" }}
                            className="filter-popup"
                            onClick={() => { }}>
                            <div className={classes.header}>
                              {" "}
                              Filter Options{" "}
                              <i
                                className="icon-close"
                                style={{ marginRight: "5px" }}
                                onClick={() => {
                                  setanchor(null);
                                }}
                              />
                            </div>
                            <div>
                              <div className={classes.subHeader}> Select Region</div>
                              {
                                <FilterComponent
                                  filterList={CompleteFilterList.region}
                                  setFilterList={(type, newList) => {
                                    setCompleteFilterList({
                                      status: [...CompleteFilterList.status.slice()],
                                      [type]: [...newList.slice()],
                                    });
                                  }}
                                  type="region"
                                  label={navItems.find((item) => item.isSelected).label}
                                />
                              }
                              <div className={classes.subHeader}> Select Status</div>

                              {
                                <FilterComponent
                                  filterList={CompleteFilterList.status}
                                  type="status"
                                  label={navItems.find((item) => item.isSelected).label}
                                  setFilterList={(type, newList) => {
                                    setCompleteFilterList({
                                      region: [...CompleteFilterList.region.slice()],
                                      [type]: [...newList.slice()],
                                    });
                                    // console.log(
                                    //   "comple",
                                    //   newList,
                                    //   "app",
                                    //   appliedFilterList
                                    // );
                                  }}
                                />
                              }
                            </div>
                            <div className="btn-container">
                              <div
                                className={"btn" + (false ? " disable " : " ")}
                                onClick={() => {
                                  let temp1 = CompleteFilterList.status;
                                  let temp2 = CompleteFilterList.region;
                                  temp1.forEach((i) => (i.isSelected = false));
                                  temp2.forEach((i) => (i.isSelected = false));
                                  setCompleteFilterList({
                                    status: JSON.parse(JSON.stringify(temp1)),
                                    region: JSON.parse(JSON.stringify(temp2)),
                                  });
                                  setappliedFilterList({
                                    status: JSON.parse(JSON.stringify(temp1)),
                                    region: JSON.parse(JSON.stringify(temp2)),
                                  });
                                }}>
                                Clear All
                              </div>
                              <div
                                className={
                                  "btn" +
                                  (!enableFilterApply() ? " disable " : " yellow")
                                }
                                onClick={(e) => {
                                  let temp = CompleteFilterList;
                                  setappliedFilterList({
                                    status: JSON.parse(JSON.stringify(temp.status)),
                                    region: JSON.parse(JSON.stringify(temp.region)),
                                  });
                                }}>
                                Apply
                              </div>
                            </div>
                          </div>
                        </SimpleBar>
                      </Paper>
                    </ClickAwayListener>
                  </Popper>
                </div>
              ) : (
                !isLoading && <div className="no-data">-- No data to display -- </div>
              )}
            </>
          )
      }

      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default Reports;
