import React, { useContext, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import AnsComponent from "./AnsComponent";
import AppContext from "../../utils/appContext";
import * as myConsts from "../../constants/constants";
const QuestionComponent = React.memo((props) => {
  const {
    tabObj,
    hidePrevNavBtn,
    selectedTab,
    usrObj,
    goToPrev,
    goToNext,
    activePeriod,
    currentPeriod,
    changeSelectedData,
    assesmentOptions,
    assesmentOptionsLabel,
    uiType,

    totalProgress,
    checkIfLastTab,
    readOnly,
    isPlanDataNull,
    preview,
    handleImpactStmtFocusChecked,
    impactStmtFocusChecked,
  } = props;

  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  const allSectionDataFetch = useSelector(
    (state) => state.getAssessmentSectionList
  );
  const [order, setOrder] = useState([]);
  let activePPMDuserId =
    myContext.activePPMDuserId || sessionStorage.activePPMDuserId;

  const toLetters = (num) => {
    var mod = num % 26,
      pow = (num / 26) | 0,
      out = mod ? String.fromCharCode(64 + mod) : (--pow, "Z");
    return pow ? toLetters(pow) + out : out;
  };

  useEffect(() => {
    if (
      allSectionDataFetch &&
      !allSectionDataFetch.fetching &&
      allSectionDataFetch.data &&
      allSectionDataFetch.data.data &&
      allSectionDataFetch.data.data.assessmentDetails
    ) {
      console.log(allSectionDataFetch.data.data.assessmentDetails);
      let temp = [];
      allSectionDataFetch.data.data.assessmentDetails.forEach((item) => {
        temp.push(item.name);
      });
      setOrder(temp);
      console.log(temp);
    }
  }, [allSectionDataFetch]);

  return (
    <>
      {uiType !== "" && (
        <div style={{ backgroundColor: "#fff", marginTop: 15, paddingTop: "40px" }}>
          {!isPlanDataNull && (
            <Box display="flex" padding="0px">
              <Box
                padding="10px 0"
                paddingBottom="0"
                paddingLeft="40px"
                display="flex"
                width="56%"
                flexDirection="row-reverse"></Box>
              <Box
                paddingBottom="0"
                display="flex"
                width="41%"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
                marginRight="1%">
                {preview ? (
                  ""
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <div className="baseLineLegend">&nbsp;</div>
                    <div className="legendText">Baseline</div>
                    {/* {myContext.currentPeriod === myContext.activePeriod && ( */}
                    <>
                      <div className="currentLegend">&nbsp;</div>
                      <div className="legendText">Changed Value</div>
                    </>
                    {/* )} */}
                  </div>
                )}
              </Box>
            </Box>
          )}

          {uiType === "radio_button" ? (
            <Box
              display="flex"
              padding="10px 0"
              paddingBottom="0"
              paddingLeft="40px">
              <Box
                padding="10px 0"
                paddingBottom="0"
                display="flex"
                width="55%"
                fontSize="16px"
                color="#2e2e38">
                Impact statements
              </Box>
              <div className="ans-wrap" style={{ width: "39%", marginLeft: "44px", alignItems: "center" }}>
                {assesmentOptions &&
                  assesmentOptions.map((value, index) => {
                    return (
                      <div className="col-disp" key={index + "col-disp"}>
                        <div
                          className={assesmentOptions && assesmentOptions.length < 5 ? "radio-item-name " : "radio-item-name-five "}
                          style={{
                            fontSize: "11px",
                            fontFamily: "EYInterState-Bold",
                          }}
                          key={index}>
                          {toLetters(index + 1)}
                        </div>
                        <div className={(assesmentOptions && assesmentOptions.length < 5 ? "radio-item-name " : "radio-item-name-five ") + " option-desc marginBtmRemoved"}>
                          {value.option}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Box>
          ) : uiType === "check_box" ? (
            <Box display="flex" padding="20px 0">
              <Box
                padding="10px 0"
                paddingBottom="0"
                paddingLeft="40px"
                display="flex"
                width="56%"
                fontSize="16px"
                marginTop="-32px"
                color="#2e2e38">
                Impact statements
              </Box>
              <div className="ans-wrap">
                <div className="radio-item-name " style={{ fontSize: "13px" }}>
                  {assesmentOptionsLabel}
                </div>
                {/* <div className="radio-item-name " style={{ fontSize: "13px" }}>
                  I Acknowledge{" "}
                </div> */}
              </div>
            </Box>
          ) : (
            <Box
              padding="10px 0"
              paddingBottom="0"
              paddingLeft="40px"
              display="flex"
              width="56%"
              fontSize="16px"
              marginBottom="20px"
              color="#2e2e38">
              Impact statements
            </Box>
          )}
          {order.length > 0 &&
            tabObj &&
            tabObj.data &&
            tabObj.data
              .sort((a, b) => order.indexOf(a.type) - order.indexOf(b.type))
              .map((answer, key) => {
                let usrQuestOpt = [];
                usrObj.map((obj) =>
                  obj.userInput.filter((x) =>
                    x.action_id === answer.action_id
                      ? (usrQuestOpt[obj.timePeriod] = x.option_id)
                      : ""
                  )
                );
                return (
                  <AnsComponent
                    assesmentOptions={assesmentOptions}
                    key={key}
                    index={key}
                    answer={answer}
                    currentObj={tabObj}
                    usrQuestOpt={usrQuestOpt}
                    selectedTab={selectedTab}
                    tabAssessmentObj={usrObj}
                    activePeriod={activePeriod}
                    currentPeriod={currentPeriod}
                    isPlanDataNull={isPlanDataNull}
                    changeSelectedData={changeSelectedData}
                    readOnly={props.readOnly}
                    preview={props.preview}
                    handleImpactStmtFocusChecked={handleImpactStmtFocusChecked}
                    impactStmtFocusChecked={impactStmtFocusChecked}
                  />
                );
              })}
          {!isPlanDataNull ? (
            <Box display="flex" padding="20px 0">
              <Box
                padding="10px 0"
                paddingBottom="0"
                paddingLeft="40px"
                display="flex"
                width="56%"
                flexDirection="row-reverse"></Box>
              <Box
                paddingBottom="0"
                display="flex"
                width="41%"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-end"
                marginRight="1%">
                {preview ? (
                  ""
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <div className="baseLineLegend">&nbsp;</div>
                    <div className="legendText">Baseline</div>
                    {/* {myContext.currentPeriod === myContext.activePeriod && ( */}
                    <>
                      <div className="currentLegend">&nbsp;</div>
                      <div className="legendText">Changed Value</div>
                    </>
                    {/* )} */}
                  </div>
                )}
              </Box>
            </Box>
          ) : (
            <Box display="flex" padding="5px 0"></Box>
          )}
        </div>
      )}
      {(!readOnly || preview) && (
        <Box
          display="flex"
          // justifyContent="space-between"
          minHeight="37px"
          margin="15px 0 2% 10px">
          {!hidePrevNavBtn && (
            <div
              style={hidePrevNavBtn ? { visibility: "hidden" } : {}}
              className="previous survey-button"
              onClick={() => {
                // checkOutsideClick(goToPrev);
                dispatch({
                  type: "OUTSIDE_CLICK_RESET",
                  payload: {},
                });
                // myContext.setIsAutoSave(false);
                goToPrev();
              }}>
              Previous
            </div>
          )}
          {activePPMDuserId && activePPMDuserId !== null && (
            <div
              style={{ width: "120px" }}
              className="previous survey-button"
              onClick={() => {
                props.history.push(myConsts.routeHome + "/" + activePPMDuserId);
              }}>
              Back to PPMD Home
            </div>
          )}
          {(activePPMDuserId &&
            activePPMDuserId !== null &&
            checkIfLastTab()) ||
            (props.preview && checkIfLastTab()) ? null : (
            <div
              style={
                !myContext.enableSummary &&
                  totalProgress < 550 &&
                  checkIfLastTab()
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
              className="next survey-button"
              onClick={() => {
                // checkOutsideClick(goToNext);
                dispatch({
                  type: "OUTSIDE_CLICK_RESET",
                  payload: {},
                });
                // myContext.setIsAutoSave(false);
                goToNext();
              }}>
              Next
            </div>
          )}
        </Box>
      )}
    </>
  );
});

export default QuestionComponent;
