import React, { useCallback, useState, useEffect, useContext } from "react";
import _ from 'lodash'
import SideMenu from "../../SideMenu/SideMenu";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import "../Admin.scss";
import "./EmailConfiguration.scss";
import * as myConsts from "../../../constants/constants";
import EmailEvents from "./EmailEvents";
import AdhocEmails from "./AdhocEmails";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AppContext from "../../../utils/appContext";
import AlertSubmitPopup from "../../Common/AlertSubmitPopup";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #42425ad6",
    fontSize: "11px",
    padding: "15px",
    maxWidth: "750px",
    backgroundColor: theme.palette.background.paper,
  },
  tooltip: {
    fontSize: 10,
  },
}));

const EmailConfiguration = (props) => {
  const myContext = useContext(AppContext);
  const classes = useStyles();
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  const dispatch = useDispatch();
  const outsideClickState = useSelector((state) => state.outsideClickCommon);
  const [isChangesInChild, setIsChangesInChild] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [insideNavigation, setInsideNavigation] = useState(false);
  const crumbs = [{ name: "Admin", path: myConsts.routeAdmin }];
  const [isMainPageVisible, setIsMainPageVisible] = useState(
    sessionStorage.getItem("currentPageName")
      ? sessionStorage.getItem("currentPageName") !== "EmailConfiguration"
        ? false
        : true
      : true
  );
  const [currentPage, setCurrentPage] = useState(
    sessionStorage.getItem("currentPageName")
      ? sessionStorage.getItem("currentPageName") === "Email Events"
        ? 1
        : sessionStorage.getItem("currentPageName") === "Ad-hoc Emails"
          ? 2
          : null
      : null
  );
  const [currentPageName, setCurrentPageName] = useState(
    sessionStorage.getItem("currentPageName") || "EmailConfiguration"
  );

  const setCurrentPageConfig = useCallback((pageNo) => {
    switch (pageNo) {
      case 1:
        setCurrentPageName("Email Events");
        sessionStorage.setItem("currentPageName", "Email Events");
        break;
      case 2:
        setCurrentPageName("Ad-hoc Emails");
        sessionStorage.setItem("currentPageName", "Ad-hoc Emails");
        break;
      default:
        setCurrentPageName("");
        sessionStorage.setItem("currentPageName", "");
    }
    setCurrentPage(pageNo);
    setIsMainPageVisible(false);
  }, []);

  useEffect(() => {
    if (
      outsideClickState.data &&
      outsideClickState.data.feature === "EmailConfiguration" &&
      !outsideClickState.data.OpenModal &&
      outsideClickState.data.ProceedWithNavigation
    ) {
      //save on cancel button click,
      const outClickCallback = outsideClickState.data.callback;
      if (outClickCallback && Object.keys(outClickCallback).length > 0) {
        outClickCallback.fn && outClickCallback.fn(outClickCallback.params);
        dispatch({
          type: "OUTSIDE_CLICK_COMMON_RESET",
          payload: {},
        });
      }
    } else if (
      outsideClickState &&
      outsideClickState.data &&
      outsideClickState.data.feature === "EmailConfiguration" &&
      outsideClickState.data.OpenModal) {
      setIsPopupVisible(true);
    }
    // eslint-disable-next-line
  }, [outsideClickState]);

  useEffect(() => {
    if (currentPageName === "EmailConfiguration")
      dispatch({
        type: "CHECK_LOCK_STATUS_RESET",
        payload: {},
      });
    if (myContext.lockPopup) toast.dismiss();
  }, [currentPageName, dispatch]);

  const getCurrentPage = useCallback(() => {
    switch (currentPage) {
      case 1:
        return (
          <EmailEvents
            setIsChangesInChild={setIsChangesInChild}
            onCancelCheck={onCancelCheck}
            onClose={() => {
              setCurrentPageName("EmailConfiguration");
              setIsMainPageVisible(true);
              sessionStorage.setItem("currentPageName", "EmailConfiguration");
            }}
            setInsideNavigation={setInsideNavigation}
            {...props}
          />
        );
      case 2:
        return (
          <AdhocEmails
            setIsChangesInChild={setIsChangesInChild}
            onCancelCheck={onCancelCheck}
            onClose={() => {
              setCurrentPageName("EmailConfiguration");
              sessionStorage.setItem("currentPageName", "EmailConfiguration");
              setIsMainPageVisible(true);
            }}
          />
        );
      default: return '';
    }
  }, [currentPage]);

  const checkForChange = (fn, params) => {
    if (
      outsideClickState &&
      outsideClickState.feature !== "" &&
      outsideClickState.changesDone
    ) {
      dispatch({
        type: "OUTSIDE_CLICK_COMMON_OPEN_MODEL",
        payload: {
          callback: { fn: fn, params: params },
        },
      });
    } else fn();
  };

  const showEmailConfigurationPage = () => {
    setIsChangesInChild(false);
    setIsPopupVisible(false);
    if (!insideNavigation) {
      setCurrentPageName("EmailConfiguration");
      setIsMainPageVisible(true);
      sessionStorage.setItem("currentPageName", "EmailConfiguration");
    }
  }

  const backArrowClick = () => {
    if (!isMainPageVisible) {
      if (isChangesInChild) {
        setIsPopupVisible(true);
      } else {
        showEmailConfigurationPage();
      }
    } else {
      props.history.push(myConsts.routeAdmin);
    }
  };

  const onCancelCheck = (fn) => {
    setInsideNavigation(true);
    if (isChangesInChild) {
      setIsPopupVisible(true);
    } else {
      fn();
    }
  };

  const EmailConfigurationClick = () => {
    if (isChangesInChild) {
      setIsPopupVisible(true);
    } else {
      showEmailConfigurationPage();
    }
  };

  const onCancelNavigation = () => {
    setIsPopupVisible(false);
    dispatch({
      type: "OUTSIDE_CLICK_COMMON_PROCEED",
      payload: {
        ProceedWithNavigation: false,
      },
    });
  }

  useEffect(() => {
    console.log('isChangesInChild', isChangesInChild);;
  }, [isChangesInChild]);
  const isActivePhase = !_.isNull(assessmentMetaData?.data?.data?.activePhase) && !_.isEmpty(assessmentMetaData?.data?.data?.activePhase);

  return (
    <div className="admin-container">
      <div className="admin-info-main-sidemenu">
        <SideMenu
          minimized={true}
          isminimizedMainMenu={true}
          isMainMenu={true}
          sideMenuItemSelect="Admin"
        />
      </div>
      <div className="admin-content">
        <div className="second-header admin-crumbs">
          <i
            className="icon-arrow-left"
            onClick={() => {
              setInsideNavigation(false);
              checkForChange(backArrowClick);
            }}
          />
          <div className="admin-crumb-txt">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
              <Breadcrumb
                crumbs={crumbs}
                isAdmin={true}
                isInnerAdmin={true}
                {...props}
              />
              {!isMainPageVisible && (
                <div className="icon-chevron-right iconStyling">
                  <a
                    className=" emailConfiguration MuiTypography-root MuiLink-root MuiLink-underlineHover admin-crumb MuiTypography-colorInherit"
                    onClick={() => {
                      setInsideNavigation(false);
                      checkForChange(EmailConfigurationClick);
                    }}>
                    Email Configuration
                  </a>
                </div>
              )}
            </div>
            <h4>{currentPageName}</h4>
          </div>
        </div>
        <div className="manage-email-content">
          <div className="main-content" style={{ padding: isMainPageVisible ? '12px' : '0px' }}>
            {isMainPageVisible ? (
              <div className="card-block">
                <div className="card" onClick={() => setCurrentPageConfig(1)}>
                  <span className="eyaa-web-329"></span>
                  <span>Email Events</span>
                </div>
                <Tooltip
                  classes={{ tooltip: classes.tooltip }}
                  title={`${isActivePhase ? '' : 'Will be enabled when a phase is made active'}`}
                  placement="bottom-center">
                  <div className={"card " + (isActivePhase ? "" : "opacityReduced")} onClick={() => {if(isActivePhase)setCurrentPageConfig(2)}}>
                    <span className="eyaa-web-323"></span>
                    <span>Ad-hoc Emails</span>
                  </div>
                </Tooltip>
              </div>
            ) : (
              <>{getCurrentPage()}</>
            )}
          </div>
        </div>
      </div>
      {isPopupVisible && (
        <div className="popup-dialog">
          <div className="dialog">
            <div className="header">
              <h5 className="popup-header">Confirmation</h5>
              <span
                className="icon-close-filled icon"
                onClick={onCancelNavigation}
              ></span>
            </div>
            <div className="content">
              Would you like to discard changes? Please note that on confirming, unsaved changes will be lost
            </div>
            <div className="footer">
              <button className="cancel-btn" onClick={onCancelNavigation}>
                Cancel
              </button>
              <button className="confirm-btn" onClick={() => {
                showEmailConfigurationPage();
                dispatch({
                  type: "OUTSIDE_CLICK_COMMON_PROCEED",
                  payload: {
                    ProceedWithNavigation: true,
                  },
                });
              }}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default EmailConfiguration;
