import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import * as myConsts from "../../../../constants/constants";
import _ from "lodash";
import "./ManageApprovals.scss";

import {
  Paper,
  InputBase,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
} from "@material-ui/core";
import SimpleBar from "simplebar-react";
import SearchIcon from "@material-ui/icons/Search";
import classNames from "classnames";
import Loader from "../../../Loader/Loader";
import AppContext from "../../../../utils/appContext";
import moment from "moment";
import Fuse from "fuse.js";
import { getFinancialYears } from "../../../../utils/utils";

const ManageApprovals = (props) => {
  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  const location = useLocation();

  const [financialYear, setFinancialYear] = useState(0);
  const [approvalStatus, setApprovalStatus] = useState("All");
  const [timePeriod, setTimePeriod] = useState("All");
  const [searchText, setSearchText] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [processedList, setProcessedList] = useState([]);
  const adminApprovalData = useSelector((state) => state.approvalData);

  const approvalsListHeaders = useMemo(
    () => [
      { value: "Type", key: "displayType" },
      { value: "Submitted By", key: "updatedBy" },
      { value: "Submitted date", key: "updatedOn" },
      { value: "Status", key: "validationStatus" },
    ],
    []
  );

  useEffect(() => {
    fetchApprovalList();
    callResetPreview();
  }, []);

  useEffect(() => {
    // to force refresh approval list on reroute
    if (location.pathname === "/Admin/Approvals") {
      fetchApprovalList();
      callResetPreview();
    }
  }, [location]);

  useEffect(() => {
    // Modify the status "Submitted" to "Pending" to be displayed in table
    if (adminApprovalData && !adminApprovalData.fetching) {
      const list = [];
      _.get(adminApprovalData, "data.data.list", []).forEach(
        (item) => {
          if (item.validationStatus === "Submitted") {
            item.validationStatus = "Pending";
          } 
          if (item.validationStatus !== "In Draft"){
            list.push(item);
          }
        }
      );
      setProcessedList(list);
    }
  }, [adminApprovalData]);

  // filter dropdown values
  const periodOptions = useMemo(
    () => ["All", "Plan", "Mid-Year", "Year-End"],
    []
  );
  const statusOptions = useMemo(
    () => ["All", "Approved", "Rejected", "Pending"],
    []
  );
  const financialYearOptions = useMemo(() => {
    return getFinancialYears(myContext.financialYear, true);;
  }, [myContext]);

  // filter and search logic for the table
  const filterData = useCallback(() => {
    let rawList = _.get(adminApprovalData, "data.data.list", []).filter(el => el.validationStatus !== "In Draft");
    if (financialYear !== 0) {
      rawList = rawList.filter((item) => item.financialYear == financialYear);
    }

    if (timePeriod !== "All") {
      rawList = rawList.filter((item) => item.timePeriod == timePeriod);
    }

    if (approvalStatus !== "All") {
      rawList = rawList.filter(
        (item) => item.validationStatus == approvalStatus
      );
    }

    if (searchKey.length) {
      // fuzzy search logic refer fusejs.io
      const options = {
        threshold: 0.3,
        location: 0,
        distance: 1000,
        maxPatternLength: 32,
        minMatchCharLength: 4,
        keys: [
          "displayType",
          "updatedBy.name",
          "financialYear",
          "timePeriod",
          "validationStatus",
        ],
      };
      const fuse = new Fuse(rawList, options);
      const result = fuse.search(searchKey);
      console.log(result);
      rawList = result.map((el) => el.item);
    }
    setProcessedList(rawList);
  }, [financialYear, timePeriod, approvalStatus, searchKey]);

  const callResetPreview = () => {
    dispatch({
      type: "RESET_APPROVAL_PREVIEW_DATA",
      payload: {},
    });
  };

  const fetchApprovalList = () => {
    dispatch({
      type: "GET_APPROVAL_REQUEST",
      payload: {
        type: props.type,
      },
    });
  };

  const isActionAllowed = (owner) => {
    // owner admin should not be allowed to review
    return myContext.userId === _.get(owner, "userId", "") ? false : true;
  };

  // process data for the list view
  const extractData = (rowData, key) => {
    let data = "";
    switch (key) {
      case "updatedBy":
        data = _.get(rowData, "updatedBy.name", "");
        break;
      case "updatedOn":
        data = moment(rowData.updatedOn).format("DD-MM-YYYY hh:mm A");
        break;
      default:
        data = rowData[key];
        break;
    }
    return data;
  };

  const redirectToPreview = () => {
    if (props.type === "GeneralParameters") {
      props.history.push(myConsts.routeAdminGeneralParametersApprovals);
    } else if (props.type === "UserManagement") {
      props.history.push(myConsts.routeAdminUserManagementApprovals);
    } else if(props.type=== "Assessment") {
      props.history.push(myConsts.routeAdminAssessmentApprovals);
    }
  };

  // set the preview paramters to review changes
  const previewItem = (data) => {
    dispatch({
      type: "SET_APPROVAL_PREVIEW_DATA",
      payload: {
        type: props.type,
        ...data,
      },
    });

    redirectToPreview();
  };

  useEffect(() => {
    if (adminApprovalData.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [adminApprovalData]);

  const searchData = () => {
    setSearchKey(searchText);
  };

  useEffect(() => {
    filterData();
  }, [financialYear, timePeriod, approvalStatus, searchKey]);

  useEffect(() => {
    if (!searchText.length) {
      setSearchKey("");
    }
  }, [searchText]);

  return (
    <div className="manage-approval">
      {isLoading && <Loader />}
      <>
        <div className="input-container">
          <div className="select-container">
            <FormControl>
              <InputLabel id="yearLabel">Select Year</InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                labelId="yearLabel"
                value={financialYear}
                onChange={(e) => {
                  setFinancialYear(e.target.value);
                }}
              >
                {financialYearOptions.map((option) => (
                  <MenuItem value={option.key} key={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="periodLabel">Period</InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                labelId="periodLabel"
                value={timePeriod}
                onChange={(e) => {
                  setTimePeriod(e.target.value);
                }}
              >
                {periodOptions.map((val, index) => (
                  <MenuItem value={val} key={index}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="statusLabel">Status</InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                labelId="statusLabel"
                value={approvalStatus}
                onChange={(e) => {
                  setApprovalStatus(e.target.value);
                }}
              >
                {statusOptions.map((val, index) => (
                  <MenuItem value={val} key={index}>
                    {val}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="input-search-group">
            <Paper className="form">
              <InputBase
                className="input-search"
                style={{ width: 260 }}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search", maxLength: 255 }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && searchText.length >= 4) {
                    searchData();
                  }
                }}
              />
              <IconButton
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                disabled={searchText.length < 4}
                onClick={searchData}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
        </div>
        <div className="user-details">
          {processedList.length ? (
            <div className="table-container">
              <h6>Approvals</h6>

              <SimpleBar
                className="approve-list-scroll"
                style={{ maxHeight: "56vh" }}
              >
                {/* <TableContainer> */}
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="user list"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      {approvalsListHeaders.map((header, index) => (
                        <TableCell key={index}>{header.value}</TableCell>
                      ))}
                      <TableCell key="actions">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {processedList.map((approvalLineItem, lineItemIndex) => (
                      <TableRow>
                        {approvalsListHeaders.map((header, index) => (
                          <TableCell key={"lineItem" + index + lineItemIndex}>
                            {extractData(approvalLineItem, header.key)}
                          </TableCell>
                        ))}
                        <TableCell>
                          <span
                            className={classNames("eyaa-image-25", {
                              "disable-icon":
                                approvalLineItem.validationStatus != "Pending",
                            })}
                            onClick={() => {
                              if (
                                approvalLineItem.validationStatus == "Pending"
                              ) {
                                previewItem(approvalLineItem);
                              }
                            }}
                          ></span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {/* </TableContainer> */}
              </SimpleBar>
            </div>
          ) : (
            <div className="helper-text">No requests yet</div>
          )}
        </div>
      </>
    </div>
  );
};
export default ManageApprovals;
