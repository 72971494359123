import React, { useEffect, useContext, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash'
import AppContext from "./utils/appContext";
import * as myConsts from "./constants/constants";

const LandingPage = (props) => {
  const divStyle = {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#ededf2",
  };
  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  const userProfile = useSelector((state) => state.userProfile);
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  const getCurrentUser = useCallback(() => {
    const ppmdID =
      myContext.activePPMDuserId || sessionStorage.activePPMDuserId;
    const userId = myContext.userId || sessionStorage.userId;
    return ppmdID || userId;
  }, [myContext]);
  console.log(getCurrentUser());
  useEffect(() => {
    dispatch({
      type: "GET_USER_PROFILE_BY_ID",
      payload: {
        userId: getCurrentUser(),
        financialYear: myContext.financialYear,
      },
    });
  }, [getCurrentUser]);

  useEffect(() => {
    if ((sessionStorage && userProfile.data[0] && sessionStorage.userId && sessionStorage.userId === userProfile.data[0].userId)
      || (_.isEmpty(sessionStorage.userId) && userProfile.data[0])) {
      sessionStorage.setItem('userPermission', JSON.stringify({ isAdmin: userProfile.data[0].isAdmin, role: userProfile.data[0].role }))
    }
    if (userProfile.data && userProfile.data.length > 0) {
      if (userProfile.data[0].role === myConsts.rolesAdmin) {
        props.history.push(myConsts.routeAdmin);
      } else {
        props.history.push(myConsts.routeHome);
      }
    }
  }, [userProfile]);
  return (
    <div style={divStyle}>
      {assessmentMetaData.data && assessmentMetaData.data.data
        ? assessmentMetaData.data.data.validUser
          ? "Loading..."
          : "You dont have access to LEAD 4D portal.Please reach out to LEAD4D Support <lead4d.support@ey.com> for your access"
        : "Loading..."}
    </div>
  );
};

export default LandingPage;
