import config from "./config";

export const hostUrl = config.api.networkInterface;
export const clientId = config.msal.clientId;
export const hostName = hostUrl + "/api/v1";

/* AD URL for Graph API */
export const msHostName = "https://graph.microsoft.com/v1.0";

/* API URLs */
export const getAssesmentData = "/getAssesmentData";
export const getUserAssesmentData = "/getUserAssesmentData";
export const addUserAssesmentData = "/addUserAssesmentData";
export const editUserAssesmentData = "/editUserAssesmentData";
export const getUserDetails = "/users";
export const getUserImg = "/photo/$value";
export const getOptionValues = "/assessmentOptions"; //
export const getAssessmentMetadata = "/getAssessmentMetadata"; //
export const addUserAssessmentStatus = "/addUserAssessmentStatus";
export const getUserProfileById = "/userProfileById";
export const getAllOptions = "/allAssessmentOptions"; //
export const getUserBookOfBusinessSummary = "/userBookOfBusinessSummary";
export const submitAssessment = "/submitUserAssessment";
export const getUserAssessmentStatus = "/getUserAssessmentStatus";
export const getPillarScore = "/getPillarScore";
export const getBobData = "/userBookOfBusiness";
export const updateBobData = "/saveAndSubmitBob";
export const submitBobData = "/submitBobWithoutChange";
export const getGoalScore = "/getGoalScore";
export const getResponsibiltyAndSectors = "/getResponsibiltyAndSectors"; //
export const getCounselleStatus = "/getCounseleesStatus";
//..Year End Discussion Guide APIs
export const getCounseleesFinalizationDetails = "/getYearEndFinalizationForm";
//export const addOrEditCounseleesFinalizationDetails = "/addOrEditYearEndFinalizationForm";
//export const getCounseleesFinalizationDetailsOption1 = "/getYearEndFinalizationFormOption1";
export const addOrEditCounseleesFinalizationDetails = "/addOrEditYearEndFinalizationForm";
export const updateIsRecordedInSuccessFactors = "/updateIsRecordedInSuccessFactors";
export const getCounseleeDiscussionGuide = "/getCounseleeDiscussionGuide";
export const addOrUpdateCounseleeDiscussionGuide = "/addOrUpdateCounseleeDiscussionGuide";
export const searchDiscussionGuide = "/searchDiscussionGuide";
export const resetDiscussionGuideData = "/resetDiscussionGuideData";

export const getPillarGroupScore = "/getPillarGroupScore";
export const getGoalGroupScore = "/getGoalGroupScore";
export const getQuantitativeScore = "/getQuantitativeScore";
export const getReviewerHomePageData = "/getReviewerHomePageData";
export const approveAssessment = "/approveAssessment";
export const rejectAssessment = "/rejectAssessment";
export const readComments = "/readComment ";
export const searchUser = "/searchUser";
export const resetUserData = "/resetUserData";
export const searchClient = "/searchClient";
export const getClientDetails = "/getClientDetails";
export const getGeneralParameters = "/getGeneralParameters";
export const editGeneralParameters = "/editGeneralParameters";
export const setUserUUID = "/proceedSession";
export const validateUserUUID = "/validateSession";
export const getTimePeriodStatus = "/getTimePeriodStatus";
export const updateToken = "/updateToken";
export const getAssesmentSection = "/assessmentSection";
export const getUserCount = "/getUserCount";
export const searchUserData = "/searchUserData";
export const searchUserDataMatch = "/searchUserDataMatch";
export const getAdminActionStatus = "/getAdminActionStatus";
export const addSingleUser = "/saveUser";
export const rollForwardUsers = "/rollForwardUsers";
export const saveMultiUsers = "/saveMultiUsers";
export const bulkUploadDiscussionGuide = "/bulkUploadDiscussionGuide";
export const editSingleUser = "/editSingleUserDetails";
export const deleteSingleUser = "/deleteUserData";
export const getAdminMasterConfig = "/getAdminMasterConfig";
export const getAssesmentGoal = "/getGoal";
export const addAssesmentGoal = "/addGoals";
export const editAssesmentGoal = "/editGoals";
export const deleteAssesmentGoal = "/deleteGoals";
export const reorderAssesmentGoal = "/reOrderGoals";
export const addGlobalMetric = "/addGlobalMetric";
export const editGlobalMetric = "/editGlobalMetric";
export const deleteGlobalMetric = "/deleteGlobalMetric";
export const reOrderGlobalMetric = "/reOrderGlobalMetric";
export const reorderAssessmentSections = "/reorderAssessmentSections";
export const rollForwardAssessment = "/rollForwardAssessment";
export const getAssessmentStatus = "/getAdminAssessmentActionStatus";
export const uploadAssessmentContent = "/saveAssessmentUpload";
export const regionDataUpload = "/regionDataUpload";
export const generateBookOfBusiness = "/generateBookOfBusiness";
export const validateUserChanges = "/validateUserChanges";
export const getUserDataChanges = "/getUserDataChanges";
export const updateUserDetails = "/updateUserDetails";
export const deleteUsers = "/deleteUsers";
export const getEmailTemplate = "/getEmailTemplates";
export const editEmailTemplate = "/editEmailTemplate";
export const addImpactStatement = "/addImpactStatement";
export const moveImpactStatement = "/moveImpactStatement";
export const deleteImpactStatement = "/deleteImpactStatements";
export const deleteAssessmentSection = "/deleteAssessmentSection";
export const sortImpactStatement = "/reOrderImpactStatement";
export const getListMails = "/listMails";
export const cancelMailRequest = "/cancelMailRequest";
export const sendAdhocMail = "/sendAdminAdhocMail";
export const getGeneralSection = "/getGeneralSectionScore";
export const editGeneralSection = "/editGeneralSectionScore";
export const getBobRollForwardStatus = "/getBobRollForwardStatus";
export const copyToNextPhase = "/copyToNextPhase";
export const progressStatusFromBob = "/progressStatusFromBob";
export const bobAdminActionStatus = "/bobAdminActionStatus";
export const mercuryFileUpload = "/mercuryFileUpload";
export const getInvalidAssessmentDetails = "/getInvalidAssessmentDetails";
export const submitAdminAssessment = "/submitAssessment";
export const validAssessmentYears = "/validAssessmentYears";
export const getPhaseValidationStatus = "/getPhaseValidationStatus";
export const validateContentRequest = "/validateContentRequest";
export const listUpdateRequest = "/listUpdateRequests";
export const highlightPendingUpdates = "/highlightPendingUpdates";
export const getReportFilter = "/reportingFilter";
export const getAdditionalReportData = "/additionalReporting";
export const exportAdditionalReportData = "/exportReport";
export const getPowerBIlink = "/getPowerBILink";
export const updatePowerBILink = "/updatePowerBILink";
export const getUserStatusAssessment = "/getUserStatusAssessment";
export const exportEntireAssessmentContentData = "/exportAssessmentData";
export const getApprovedCompletedYearsList = "/getApprovedAndCompletedYears";
export const getCompletedPhase = "/getCompletedPhase";
export const deleteMultiUsers = "/deleteMultiUsers";
export const uploadGeneralContent = "/uploadGeneralContent";
export const getGeneralContent = "/getGeneralContent";
/* Variables */
/* Route Paths */
export const routeHome = "/Home";
export const routePPMDHome = "/Home/:ppmdID";
export const routeDashBoard = "/Dashboard";
export const routeDashBoardGoal = "/Dashboard/:pillarId";
export const routeAssessment = "/Assessment";
export const routeAssessmentSummary = "/Assessment/:isSummary";
export const routeNotValidUser = "/NotValidUser";
export const routeBookOfBusiness = "/BookOfBusiness";
export const routeAcceptPrivacy = "/AcceptPrivacy";
export const routePpmdProfiles = "/PpmdProfiles";
export const routeDiscussionGuide = "/DiscussionGuide";
export const routeFinalCommentary = "/DiscussionGuideFinalCommentary";
export const routeSelfDiscussionGuide = "/SelfDiscussionGuide";
export const routeFinalizationForm = "/FinalizationGuidance";
export const routeAdmin = "/Admin";
export const routeAdminUserManagement = "/Admin/UserManagement";
export const routeAdminGeneralParameters = "/Admin/GeneralParameters";
export const routeAdminBookOfBusiness = "/Admin/BookOfBusiness";
export const routeAdminEmailConfiguration = "/Admin/EmailConfiguration";
export const routeAdminApprovals = "/Admin/Approvals";
export const routeAdminReportMainPage = "/Admin/ReportMainPage";
export const routeAdminContentManagement = "/Admin/ContentManagement";
export const routeAdminDataManagement = "/Admin/DataManagement";
export const routeAdminGeneralParametersApprovals =
  "/Admin/Approvals/GeneralParameters";
export const routeAdminAssessmentApprovals = "/Admin/Approvals/Assessment";
export const routeAdminReports = "/Admin/Reports";
export const routeAdminAssessment = "/Admin/Assessment";
export const routeLandingPage = "/LandingPage";
export const routeAdminUserManagementApprovals =
  "/Admin/Approvals/UserManagement";

/* Bob & Assessment Status */
export const statusNotStarted = "Not Started".toLowerCase();
export const statusInProgress = "In Progress".toLowerCase();
export const statusSubmitted = "Submitted".toLowerCase();
export const statusCompleted = "Completed".toLowerCase();
export const statusRequestedChange = "Requested Change".toLowerCase();

// status values from API PPMD LIst
export const pendingSubmission = "In Draft".toLowerCase();
export const pendingApproval = "Pending Approval".toLowerCase();
export const approved = "Approved".toLowerCase();
export const requestEdit = "Request Edit".toLowerCase();

/* User Roles */
export const rolesPPMD = "PPMD";
export const rolesAdmin = "Admin";
export const rolesLeadReviewer = "LeadReviewer";
export const rolesLeadReviewerPPMD = "LeadReviewer-PPMD";

/* GENERAL */
export const TimePeriodsArray = ["Year-End"];
export const TimePeriodPlan = TimePeriodsArray[0];
export const TimePeriodMidYear = TimePeriodsArray[1];
export const TimePeriodYearEnd = TimePeriodsArray[0];

/* PAANS Variables */

export const PAANSRegion = "US";
export const PAANSLanguage = "EN";

export const PAANSUrl = config.paans.url;
export const PAANSAppId = config.paans.appId;
export const PAANSecret = config.paans.secret;
export const PAANSPolicyType = config.paans.policyType;
export const PAANSRedirectUrl = config.paans.redirectUrl;
export const PAANSAuthorization = config.paans.authorization;

export const PAANSRejectUrl = config.paans.redirectUrl + routeAcceptPrivacy;
export const PAANSAcceptUrl = config.paans.redirectUrl + routeHome;

/* Session Timeout */
export const idletimeout = 60 * 60 * 1000; // logout in 60min
export const idlepopuptime = 2 * 60 * 1000; //show popup in last 2 min
export const defaultSelectedTimeZone = {
  value: "America/Detroit",
  offset: "-4",
  offsetformatted: -4,
};
