import React, { useCallback, useState, useContext, useEffect } from "react";
import AppContext from "../../../../utils/appContext";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import * as myConsts from "../../../../constants/constants";
import Loader from "../../../Loader/Loader";
import { validateEmail } from "../../../../utils/utils";

import {
  authProvider,
  APIscope,
  GraphExplorerscopes,
} from "./../../../../Authentication/authProvider";
import "./ChangeManagement.scss";

import {
  Paper,
  InputBase,
  IconButton,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  TextField,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import SimpleBar from "simplebar-react";
import Modal from "react-responsive-modal";
import moment from "moment";
import { Flip, toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root1: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "350px",
      height: "27px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      //   borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      //   "&:hover:not($disabled):not($focused):not($error):before": {
      //     borderBottom: "none",
      //   },
      " &:hover:before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      },
      " &:hover:after": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  // labelroot: {
  //   backgroundColor: "rgb(246 ,246, 250)!important",
  //   color: "rgba(0, 0, 0, 0.94)",
  //   paddingLeft: "5px",
  //   paddingTop: "5px",
  //   fontSize: "8px",
  // },
  // topSpacing: {
  //   marginTop: "16px",
  // },
  // topSyli: {
  //   marginBottom: " -30px",
  //   marginTop: "16px",
  //   zIndex: "8",
  //   width: "115px",
  // },
  // half: {
  //   display: "flex",
  //   flexDirection: "row",
  //   width: "350px",
  //   marginLeft: "15px",
  // },
  root: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "360px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
      fontFamily: "EYInterState-Regular",
    },
  },
  rootMulti: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "677px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  labelroot: {
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "8px",
  },
  topSpacing: {
    marginTop: "16px",
  },
  topSyli: {
    marginBottom: " -30px",
    marginTop: "16px",
    zIndex: "8",
    width: "100px",
  },
  half: {
    display: "flex",
    flexDirection: "row",
  },
  arrow: {
    position: "absolute",
    fontSize: 14,
    width: "6em",
    height: "4em",
    //   transform: "rotate(225deg)",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  paper2: {
    backgroundColor: "white",
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 2, 2),
    width: 170,
    overflow: "hidden",
    overflowX: "hidden",
    fontSize: "11px",
  },
  options: {
    borderBottom: "1px solid grey",
    padding: "5px 0px 5px 5px",
    display: "flex",
    alignItems: "center",
    height: "24px",
    cursor: "pointer",
  },
  popper: {
    // zIndex: 1,
    left: "-55px!important",
    '&[x-placement*="top-end"]': {
      top: "-12px!important",
    },
    '&[x-placement*="bottom-end"]': {
      top: "9px!important",
    },
    '&[x-placement*="bottom"] $arrow': {
      top: 2,
      left: "105px!important",
      marginTop: "-0.9em",
      width: "6em",
      height: "2em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: "103px!important",
      marginBottom: "-3.6em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
}));

const ChangeManagement = (props) => {
  const dispatch = useDispatch();

  const myContext = useContext(AppContext);
  const classes = useStyles();

  const closeAll = {
    panel1: false,
    panel2: false,
    panel3: false,
    panel4: false,
    panel5: false,
    panel6: false,
    panel7: false,
  };
  const [expandPanel, setExpandPanel] = useState({
    panel1: false,
    panel2: false,
    panel3: false,
    panel4: false,
    panel5: false,
    panel6: false,
    panel7: false,
  });
  const [expanded, setExpanded] = useState("");
  const [searchText, setSearchText] = useState("");
  const [editable, setEditable] = useState(true);
  const [selectedEmail, setselectedEmail] = useState([]);
  const [selectedGivenName, setselectedGivenName] = useState([]);
  const [selectedPreferredName, setselectedPreferredName] = useState([]);
  const [selectedRankDesc, setselectedRankDesc] = useState([]);
  const [selectedCounselorEmail, setselectedCounselorEmail] = useState([]);
  const [selectedCounselorName, setselectedCounselorName] = useState([]);
  const [selectedLeadReviewer, setselectedLeadReviewer] = useState([]);
  const getLockStatus = useSelector((state) => state.checkLockStatus);
  const [isLoading, setIsLoading] = useState(false);
  const deleteUsers = useSelector((state) => state.deleteUsers);

  const [userValidateData, setUserValidateData] = useState({});
  const validateUserChange = useSelector((state) => state.validateUserChange);
  const getUserDataChanges = useSelector((state) => state.getUserDataChanges);
  const [userConflictData, setuserConflictData] = useState({});

  const [selectedAllEmail, setselectedAllEmail] = useState(false);
  const [selectedAllGivenName, setselectedAllGivenName] = useState(false);
  const [selectedAllPreferredName, setselectedAllPreferredName] =
    useState(false);
  const [selectedAllRankDesc, setselectedAllRankDesc] = useState(false);
  const [selectedAllCounselorEmail, setselectedAllCounselorEmail] =
    useState(false);
  const [selectedAllCounselorName, setselectedAllCounselorName] =
    useState(false);
  const [selectedAllLeadReviewer, setselectedAllLeadReviewer] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  const { data = {} } =
    useSelector((state) => state.getBobRollforwardStatus) || {};
  const { toPhase, toYear } = data || {};

  // const financialYear = myContext.activePeriod ? myContext.financialYear : toYear;
  // const timePeriod = myContext.activePeriod ? myContext.activePeriod : toPhase;

  const [financialYear, setFinancialYear] = useState("");
  const [timePeriod, setTimePeriod] = useState("Mid-Year");

  const handleChange = (panel) => (event, newExpanded) => {
    // console.log(panel)
    // console.log("handle change")
    setExpandPanel({ ...closeAll });
    // console.log(expandPanel)
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (searchText === "") {
      dispatch({
        type: "GET_USER_DATA_CHANGES",
        payload: {
          financialYear,
          timePeriod,
        },
      });
    }
  }, [searchText]);

  const searchValue = () => {
    let temp = {
      email: [],
      rankDesc: [],
      givenName: [],
      preferredName: [],
      counselorEmail: [],
      counselorName: [],
      leadReviewer: [],
    };

    let openPanels = {};
    if (getUserDataChanges.data.email.length) {
      getUserDataChanges.data.email.map((element, type) => {
        if (element.userId.includes(searchText)) {
          openPanels = { ...openPanels, panel1: true };
          if (!temp.email.includes(element)) {
            temp.email.push(element);
          }
        }
      });
    }
    if (getUserDataChanges.data.givenName.length) {
      getUserDataChanges.data.givenName.map((elementd) => {
        if (elementd.db_value.includes(searchText)) {
          openPanels = { ...openPanels, panel2: true };
          if (!temp.givenName.includes(elementd)) {
            temp.givenName.push(elementd);
          }
        }
      });
    }
    if (getUserDataChanges.data.preferredName.length) {
      getUserDataChanges.data.preferredName.map((elementd) => {
        if (elementd.db_value.includes(searchText)) {
          openPanels = { ...openPanels, panel3: true };
          if (!temp.preferredName.includes(elementd)) {
            temp.preferredName.push(elementd);
          }
        }
      });
    }
    if (getUserDataChanges.data.rankDesc.length) {
      getUserDataChanges.data.rankDesc.map((elementd) => {
        if (elementd.userId.includes(searchText)) {
          openPanels = { ...openPanels, panel4: true };
          if (!temp.rankDesc.includes(elementd)) {
            temp.rankDesc.push(elementd);
          }
        }
      });
    }
    if (getUserDataChanges.data.counselorEmail.length) {
      getUserDataChanges.data.counselorEmail.map((elementd) => {
        if (elementd.userId.includes(searchText)) {
          openPanels = { ...openPanels, panel5: true };
          if (!temp.counselorEmail.includes(elementd)) {
            temp.counselorEmail.push(elementd);
          }
        }
      });
    }
    if (getUserDataChanges.data.counselorName.length) {
      getUserDataChanges.data.counselorName.map((elementd) => {
        if (elementd.userId.includes(searchText)) {
          openPanels = { ...openPanels, panel6: true };
          if (!temp.counselorName.includes(elementd)) {
            temp.counselorName.push(elementd);
          }
        }
      });
    }
    if (getUserDataChanges.data.leadReviewer.length) {
      getUserDataChanges.data.leadReviewer.map((elementd) => {
        if (elementd.userId.includes(searchText)) {
          openPanels = { ...openPanels, panel7: true };
          if (!temp.leadReviewer.includes(elementd)) {
            temp.leadReviewer.push(elementd);
          }
        }
      });
    }

    setExpandPanel({ ...openPanels });
    getUserDataChanges.data.email = temp.email;
    getUserDataChanges.data.givenName = temp.givenName;
    getUserDataChanges.data.preferredName = temp.preferredName;
    getUserDataChanges.data.rankDesc = temp.rankDesc;
    getUserDataChanges.data.counselorEmail = temp.counselorEmail;
    getUserDataChanges.data.counselorName = temp.counselorName;
    getUserDataChanges.data.leadReviewer = temp.leadReviewer;
  };

  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (
      !deleteUsers.fetching &&
      deleteUsers.data &&
      deleteUsers.data.status === "200"
    ) {
      toast.success(
        <div
          style={{
            // textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "EYInterState-Regular",
            fontSize: "12px",
          }}>
          <i className="icon-verified-outline" />
          Delete Users Initiated
        </div>,
        {
          position: "top-center",
          transition: Flip,
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // setIsLoading(true);
    } else {
      // setIsLoading(false);
      // console.log(validateUserChange);
    }
  }, [deleteUsers]);

  const deleteEmail = () => {
    // let payload = {};
    let userList = [];
    selectedEmail.forEach((emailData) => {
      userList.push(emailData.userId);
    });

    // payload={
    //   userList:userList,
    //   financialYear,
    //   timePeriod
    // }
    // console.log(payload);
    dispatch({
      type: "DELETE_USERS",
      payload: {
        userList: userList,
        financialYear,
        timePeriod,
      },
      refreshData: {
        financialYear,
        timePeriod,
      },
    });
  };
  const openModal = () => {
    setOpen(true);
  };
  useEffect(() => {
    if (validateUserChange.fetching) {
      // setIsLoading(true);
    } else {
      // setIsLoading(false);
      // console.log(validateUserChange);
    }
  }, [validateUserChange]);

  useEffect(() => {
    if (getUserDataChanges.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      // console.log(getUserDataChanges.data);
    }
  }, [getUserDataChanges]);

  const validateUserChangeIntitiate = async () => {
    setFirstTime(false);
    const idtoken = await authProvider.acquireTokenSilent({
      scopes: GraphExplorerscopes,
    });
    const token = idtoken.accessToken;

    dispatch({
      type: "VALIDATE_USER_CHANGES",
      payload: {
        financialYear,
        timePeriod,
        ad_token: token,
      },
    });
    setIsLoading(true);
    setTimeout(function () {
      getLockStatusAPI();
    }, 1000);

    // dispatch({
    //   type: "GET_USER_DATA_CHANGES",
    //   payload:{
    //     financialYear,
    //     timePeriod,
    // },
    // });

    // try {
    //   setLoader(true);
    //   const token = await authProvider.acquireTokenSilent({
    //     scopes: APIscope,
    //   });
    //   const idToken = token.accessToken;
    //   dispatch({
    //     type: "GET_USER_COUNT",
    //     payload: {
    //       token: idToken,
    //       financialYear: values.fromYear.replace("FY", ""),
    //       timePeriod: values.fromPeriod,
    //     },
    //   });
    // } catch (e) {
    //   console.error(e);
    // }
  };

  useEffect(() => {
    if (
      assessmentMetaData &&
      assessmentMetaData.data &&
      assessmentMetaData.data.data
    ) {
      const data = assessmentMetaData.data.data;
      if (data.activePhase == null) {
        if (data.lastActivePhase == "Year-End") {
          setFinancialYear(Number(data.lastActivePhaseYear) + 1);
          setTimePeriod("Plan");
        } else {
          setFinancialYear(Number(data.lastActivePhaseYear));
          setTimePeriod(
            myConsts.TimePeriodsArray[
              myConsts.TimePeriodsArray.indexOf(data.lastActivePhase) + 1
            ]
          );
        }
      } else {
        setFinancialYear(data.activePhaseYear);

        setTimePeriod(data.activePhase);
      }
    }
  }, []);

  useEffect(() => {
    if (financialYear !== "") {
      dispatch({
        type: "GET_USER_DATA_CHANGES",
        payload: {
          financialYear,
          timePeriod,
        },
      });
    }
  }, [financialYear, timePeriod]);

  const trackSelectedItems = useCallback((mail, field) => {
    if (field === "email") {
      // console.log(selectedEmail)
      let copyselectedEmail = [...selectedEmail];
      // copyselectedEmail.some((item) => item === mail)
      const isAlreadyExists = copyselectedEmail.some(
        (item) => item.userId === mail.userId
      );
      if (isAlreadyExists) {
        copyselectedEmail = copyselectedEmail.filter(
          (item) => item.userId !== mail.userId
        );
      } else {
        mail.ad_value = "";
        copyselectedEmail.push(mail);
      }
      setselectedEmail(copyselectedEmail);
    } else if (field === "givenName") {
      let copySelectedGivenName = [...selectedGivenName];
      // copyselectedEmail.some((item) => item === mail)
      const isAlreadyExists = copySelectedGivenName.some(
        (item) => item === mail
      );
      if (isAlreadyExists) {
        copySelectedGivenName = copySelectedGivenName.filter(
          (item) => item !== mail
        );
      } else {
        copySelectedGivenName.push(mail);
      }
      setselectedGivenName(copySelectedGivenName);
    } else if (field === "preferredName") {
      let copySelectedPreferredName = [...selectedPreferredName];
      // copyselectedEmail.some((item) => item === mail)
      const isAlreadyExists = copySelectedPreferredName.some(
        (item) => item === mail
      );
      if (isAlreadyExists) {
        copySelectedPreferredName = copySelectedPreferredName.filter(
          (item) => item !== mail
        );
      } else {
        copySelectedPreferredName.push(mail);
      }
      setselectedPreferredName(copySelectedPreferredName);
    } else if (field === "rankDesc") {
      let copySelectedRankDesc = [...selectedRankDesc];
      // copyselectedEmail.some((item) => item === mail)
      const isAlreadyExists = copySelectedRankDesc.some(
        (item) => item === mail
      );
      if (isAlreadyExists) {
        copySelectedRankDesc = copySelectedRankDesc.filter(
          (item) => item !== mail
        );
      } else {
        copySelectedRankDesc.push(mail);
      }
      setselectedRankDesc(copySelectedRankDesc);
    } else if (field === "counselorEmail") {
      let copySelectedCounselorEmail = [...selectedCounselorEmail];
      // copyselectedEmail.some((item) => item === mail)
      const isAlreadyExists = copySelectedCounselorEmail.some(
        (item) => item === mail
      );
      if (isAlreadyExists) {
        copySelectedCounselorEmail = copySelectedCounselorEmail.filter(
          (item) => item !== mail
        );
      } else {
        copySelectedCounselorEmail.push(mail);
      }
      setselectedCounselorEmail(copySelectedCounselorEmail);
    } else if (field === "counselorName") {
      let copySelectedCounselorName = [...selectedCounselorName];
      // copyselectedEmail.some((item) => item === mail)
      const isAlreadyExists = copySelectedCounselorName.some(
        (item) => item === mail
      );
      if (isAlreadyExists) {
        copySelectedCounselorName = copySelectedCounselorName.filter(
          (item) => item !== mail
        );
      } else {
        copySelectedCounselorName.push(mail);
      }
      setselectedCounselorName(copySelectedCounselorName);
    } else if (field === "leadReviewer") {
      let copySelectedLeadReviewer = [...selectedLeadReviewer];
      // copyselectedEmail.some((item) => item === mail)
      const isAlreadyExists = copySelectedLeadReviewer.some(
        (item) => item === mail
      );
      if (isAlreadyExists) {
        copySelectedLeadReviewer = copySelectedLeadReviewer.filter(
          (item) => item !== mail
        );
      } else {
        copySelectedLeadReviewer.push(mail);
      }
      setselectedLeadReviewer(copySelectedLeadReviewer);
    }
  });

  const updateEmail = (e, index) => {
    const temp = [...selectedEmail];
    temp[index] = { ...temp[index], ad_value: e };
    setselectedEmail(temp);
  };

  const userDataChangeInitiate = (fieldType) => {
    if (fieldType === "email") {
      // let payload={
      //   field: "userId",
      //   updateDetails:selectedEmail
      //   }
      //   console.log(payload)
      dispatch({
        type: "UPDATE_USER_DETAILS",
        payload: {
          field: "userId",
          updateDetails: selectedEmail,
        },
        refreshData: {
          financialYear,
          timePeriod,
        },
      });
      setselectedEmail([]);
    } else if (fieldType === "givenName") {
      // let payload={
      //   field: "givenName",
      //   updateDetails:selectedGivenName
      //   }
      //   console.log(payload)
      dispatch({
        type: "UPDATE_USER_DETAILS",
        payload: {
          field: "givenName",
          updateDetails: selectedGivenName,
        },
        refreshData: {
          financialYear,
          timePeriod,
        },
      });
    } else if (fieldType === "preferredName") {
      // let payload={
      //   field: "preferredName",
      //   updateDetails:selectedPreferredName
      //   }
      //   console.log(payload)
      dispatch({
        type: "UPDATE_USER_DETAILS",
        payload: {
          field: "preferredName",
          updateDetails: selectedPreferredName,
        },
        refreshData: {
          financialYear,
          timePeriod,
        },
      });
    } else if (fieldType === "rankDesc") {
      // let payload={
      //   field: "rankDesc",
      //   updateDetails:selectedRankDesc
      //   }
      //   console.log(payload)
      dispatch({
        type: "UPDATE_USER_DETAILS",
        payload: {
          field: "rankDesc",
          updateDetails: selectedRankDesc,
        },
        refreshData: {
          financialYear,
          timePeriod,
        },
      });
    } else if (fieldType === "counselorName") {
      // let payload={
      //   field: "counselorName",
      //   updateDetails:selectedCounselorName
      //   }
      //   console.log(payload)
      dispatch({
        type: "UPDATE_USER_DETAILS",
        payload: {
          field: "counselorName",
          updateDetails: selectedCounselorName,
        },
        refreshData: {
          financialYear,
          timePeriod,
        },
      });
    } else if (fieldType === "counselorEmail") {
      // let payload={
      //   field: "counselorEmail",
      //   updateDetails:selectedCounselorEmail
      //   }
      //   console.log(payload)
      dispatch({
        type: "UPDATE_USER_DETAILS",
        payload: {
          field: "counselorEmail",
          updateDetails: selectedCounselorEmail,
        },
        refreshData: {
          financialYear,
          timePeriod,
        },
      });
    }
    setOpen(false);
  };

  useEffect(() => {
    dispatch({
      type: "CHECK_LOCK_STATUS",
      payload: {},
    });
  }, [dispatch]);

  // useEffect(()=>{
  //     dispatch({
  //     type: "GET_USER_DATA_CHANGES",
  //     payload:{
  //       financialYear,
  //       timePeriod,
  //   },
  //   });
  // },[myContext.disableUserUpdation])

  const getLockStatusAPI = async () => {
    //function to call feth API for given year and period.
    try {
      const token = await authProvider.acquireTokenSilent({
        scopes: APIscope,
      });
      const idToken = token.accessToken;
      dispatch({
        type: "CHECK_LOCK_STATUS",
        payload: {
          token: idToken,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };
  // useEffect(() => {
  //   //lock status API response
  //   if (
  //     !getLockStatus.fetching &&
  //     getLockStatus.data &&
  //     getLockStatus.data.message &&
  //     getLockStatus.data.proceedAction
  //   ) {
  //     //Succefully Completed
  //     setIsLoading(false);

  //     myContext.setDisableUserUpdation(false);
  //     console.log("mycontext-disable-false")
  //   //   dispatch({
  //   //   type: "GET_USER_DATA_CHANGES",
  //   //   payload:{
  //   //     financialYear,
  //   //     timePeriod,
  //   // },
  //   // });

  //   } else if (
  //     !getLockStatus.fetching &&
  //     getLockStatus.data &&
  //     getLockStatus.data.message &&
  //     !getLockStatus.data.proceedAction
  //   ) {
  //     //not completed
  //     setIsLoading(false);

  //     myContext.setDisableUserUpdation(true);
  //     dispatch({
  //       type: "CHECK_LOCK_STATUS_RESET",
  //       payload: {},
  //     });
  //     setTimeout(function () {
  //       getLockStatusAPI();
  //     }, 15000);

  //   }

  // }, [getLockStatus]);

  useEffect(() => {
    //lock status API response
    if (
      !getLockStatus.fetching &&
      getLockStatus.data &&
      getLockStatus.data.message &&
      getLockStatus.data.proceedAction
    ) {
      //Succefully Completed
      setIsLoading(false);
      myContext.setDisableUserUpdation(false);
    } else if (
      !getLockStatus.fetching &&
      getLockStatus.data &&
      getLockStatus.data.message &&
      !getLockStatus.data.proceedAction
    ) {
      //not completed
      if (firstTime) {
        myContext.setIsWarningPopup(false);
        triggerLock();
        setFirstTime(false);
      }
      setIsLoading(false);
      myContext.setDisableUserUpdation(true);
      dispatch({
        type: "CHECK_LOCK_STATUS_RESET",
        payload: {},
      });
      setTimeout(function () {
        getLockStatusAPI();
      }, 15000);
    }
    // else if (!getLockStatus.fetching && getLockStatus.error) {
    //   getLockStatusAPI();
    // }
  }, [getLockStatus]);

  const selectAllData = (field) => {
    if (field === "email") {
      if (selectedAllEmail === true) {
        setselectedEmail([]);
        setselectedAllEmail(false);
      } else {
        setselectedEmail(getUserDataChanges.data.email);
        setselectedAllEmail(true);
      }
    } else if (field === "givenName") {
      if (selectedAllGivenName === true) {
        setselectedGivenName([]);
        setselectedAllGivenName(false);
      } else {
        setselectedGivenName(getUserDataChanges.data.givenName);
        setselectedAllGivenName(true);
      }
    } else if (field === "preferredName") {
      if (selectedAllPreferredName === true) {
        setselectedPreferredName([]);
        setselectedAllPreferredName(false);
      } else {
        setselectedPreferredName(getUserDataChanges.data.PreferredName);
        setselectedAllPreferredName(true);
      }
    } else if (field === "rankDesc") {
      if (selectedAllRankDesc === true) {
        setselectedRankDesc([]);
        setselectedAllRankDesc(false);
      } else {
        setselectedRankDesc(getUserDataChanges.data.rankDesc);
        setselectedAllRankDesc(true);
      }
    } else if (field === "counselorEmail") {
      if (selectedAllCounselorEmail === true) {
        setselectedCounselorEmail([]);
        setselectedAllCounselorEmail(false);
      } else {
        setselectedCounselorEmail(getUserDataChanges.data.counselorEmail);
        setselectedAllCounselorEmail(true);
      }
    } else if (field === "counselorEmail") {
      if (selectedAllCounselorEmail === true) {
        setselectedCounselorEmail([]);
        setselectedAllCounselorEmail(false);
      } else {
        setselectedCounselorEmail(getUserDataChanges.data.counselorEmail);
        setselectedAllCounselorEmail(true);
      }
    } else if (field === "counselorName") {
      if (selectedAllCounselorName === true) {
        setselectedCounselorName([]);
        setselectedAllCounselorName(false);
      } else {
        setselectedCounselorName(getUserDataChanges.data.counselorName);
        setselectedAllCounselorName(true);
      }
    } else if (field === "leadReviewer") {
      if (selectedAllLeadReviewer === true) {
        setselectedLeadReviewer([]);
        setselectedAllLeadReviewer(false);
      } else {
        setselectedLeadReviewer(getUserDataChanges.data.leadReviewer);
        setselectedAllLeadReviewer(true);
      }
    }
  };

  const RedirectToManage = () => {
    props.onManageUser();
  };

  const resetSearch = () => {
    setExpandPanel({ ...closeAll });
    setSearchText("");
    dispatch({
      type: "GET_USER_DATA_CHANGES",
      payload: {
        financialYear,
        timePeriod,
      },
    });
  };

  const triggerLock = () => {
    myContext.setIsLockPopup(true);
    toast.warn(
      <div
        style={{
          // textTransform: "capitalize",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "EYInterState-Regular",
          fontSize: "12px",
        }}>
        <i className="icon-warning-outline" />
        {/* "Section Updated Successfully" : */}
        {`Previous operation initiated by ${getLockStatus.data.userName} is still in progress. It will take some time. You can perform this 
        action after the operation is completed. We appreciate your patience!`}
      </div>,
      {
        position: "top-center",
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };
  return (
    <>
      {isLoading && <Loader />}
      <div className="input-container-change">
        <div>
          FY {financialYear} {timePeriod}
        </div>
        <div className="input-search-group">
          <span className="text-refresh">
            Last Refresh:
            {getUserDataChanges.data.lastRunOn &&
            moment(getUserDataChanges.data.lastRunOn).isValid()
              ? moment(getUserDataChanges.data.lastRunOn).format(
                  "DD-MM-YYYY HH:mm"
                )
              : "Not Available"}
          </span>
          <Paper component="form">
            <InputBase
              className="input-search"
              style={{ width: 260 }}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search", maxLength: 255 }}
              value={searchText}
              disabled={myContext.disableUserUpdation}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && searchText.length >= 1) {
                  searchValue();
                  e.preventDefault();
                }
              }}
            />
            {searchText.length > 0 && (
              <i className="icon-close closeIcon" onClick={resetSearch} />
            )}

            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={searchValue}
              disabled={searchText.length < 1}>
              <SearchIcon />
            </IconButton>
          </Paper>
          <div
            onClick={validateUserChangeIntitiate}
            className={
              "butn yellow-color refresh-btn" +
              (!myContext.disableUserUpdation ? "" : " disable-btn ")
            }>
            Refresh Changes
          </div>
          {/* <Button
                  variant="outlined"
                  className="refresh-btn"
                  onClick={validateUserChangeIntitiate}
                >
                  Refresh Changes
                  </Button> */}
        </div>
      </div>
      <div></div>

      <div className="tabs-container-change">
        <SimpleBar className="accord-scroll" style={{ maxHeight: "68vh" }}>
          {/* Email */}
          <Accordion
            className="accordian"
            expanded={expanded === "panel1" || expandPanel["panel1"] === true}
            onChange={handleChange("panel1")}>
            <AccordionSummary>
              <Typography>
                {" "}
                {expanded === "panel1" || expandPanel["panel1"] === true ? (
                  <span>–</span>
                ) : (
                  <span>+</span>
                )}
                <span>Email</span>
                <span className="text-conflict">
                  {getUserDataChanges.data.email
                    ? getUserDataChanges.data.email.length
                    : 0}{" "}
                  Conflict
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer className="table-container">
                <Table sx={{ minWidth: 650 }} aria-label="user list">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          // inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                          color="default"
                          className="impact-check-box"
                          onChange={(e) => selectAllData("email")}
                        />
                        Details in LEAD 4D Database
                      </TableCell>
                      <TableCell>Details in Active Directory</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getUserDataChanges.data.email &&
                      getUserDataChanges.data.email.map((element, index) => (
                        <TableRow key={element.id}>
                          <TableCell>
                            <span>
                              <Checkbox
                                // inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                                color="default"
                                className="impact-check-box"
                                checked={selectedEmail.some(
                                  (item) => item.userId === element.userId
                                )}
                                onChange={() =>
                                  trackSelectedItems(element, "email")
                                }
                              />
                              <span className="email-cells">
                                {element.userId}
                              </span>
                            </span>
                          </TableCell>
                          {getUserDataChanges.data.email && index === 0 && (
                            <TableCell
                              rowSpan={getUserDataChanges.data.email.length}>
                              <span className="change-message-wrapper">
                                <span>Details not found.</span>
                                <span>If you would like to update the details,please
                                  contact support@lead4d.ey.com.</span>
                                <span>If the user does not exist, you can
                                  click on 'Delete' to remove the details.</span>
                              </span>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}

                    <TableRow>
                      <TableCell className="border-none"></TableCell>
                      <TableCell className="btn-contain">
                        {/* <Button
                                variant="outlined"
                                className="cancel-btn"
                                onClick={deleteEmail}
                            >
                            Delete 
                            </Button> */}
                        {/* <div
                          onClick={()=>{
                           
                              RedirectToManage()
                           }}
                            className={
                                "butn yellow-color "
                              }>
                            Manage Users
                          </div> */}
                        <div
                          onClick={() => {
                            if (
                              selectedEmail.length &&
                              !myContext.disableUserUpdation
                            ) {
                              deleteEmail();
                            }
                          }}
                          className={
                            "butn yellow-color " +
                            (!myContext.disableUserUpdation &&
                            selectedEmail.length
                              ? ""
                              : " disable-btn ")
                          }>
                          Delete
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          {/* DisplayName */}
          <Accordion
            className="accordian"
            expanded={expanded === "panel2" || expandPanel["panel2"] === true}
            onChange={handleChange("panel2")}>
            <AccordionSummary>
              <Typography>
                {" "}
                {expanded === "panel2" || expandPanel["panel2"] === true ? (
                  <span>–</span>
                ) : (
                  <span>+</span>
                )}
                <span>Display Name</span>
                <span className="text-conflict">
                  {" "}
                  {getUserDataChanges.data.givenName
                    ? getUserDataChanges.data.givenName.length
                    : 0}{" "}
                  Conflict
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer className="table-container">
                <Table sx={{ minWidth: 650 }} aria-label="user list">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          // inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                          color="default"
                          className="impact-check-box"
                          onChange={(e) => selectAllData("givenName")}
                        />
                        Details in LEAD 4D Database
                      </TableCell>
                      <TableCell>Details in Active Directory</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getUserDataChanges.data.givenName &&
                      getUserDataChanges.data.givenName.map(
                        (displayName, type) => (
                          <TableRow key={displayName.id}>
                            <TableCell>
                              <span>
                                <Checkbox
                                  // inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                                  color="default"
                                  className="impact-check-box"
                                  checked={selectedGivenName.some(
                                    (item) => item === displayName
                                  )}
                                  onChange={() =>
                                    trackSelectedItems(displayName, "givenName")
                                  }
                                />
                                <span>{displayName.db_value}</span>
                              </span>
                            </TableCell>
                            <TableCell>
                              <span>{displayName.ad_value}</span>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    <TableRow>
                      <TableCell className="border-none"></TableCell>
                      <TableCell className="btn-contain">
                        <div
                          onClick={() => {
                            if (
                              !myContext.disableUserUpdation &&
                              selectedGivenName &&
                              selectedGivenName.length > 0
                            ) {
                              userDataChangeInitiate("givenName");
                            }
                          }}
                          className={
                            "butn yellow-color" +
                            (!myContext.disableUserUpdation &&
                            selectedGivenName &&
                            selectedGivenName.length > 0
                              ? ""
                              : " disable-btn ")
                          }>
                          Update
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          {/* GivenName */}
          <Accordion
            className="accordian"
            expanded={expanded === "panel3" || expandPanel["panel3"] === true}
            onChange={handleChange("panel3")}>
            <AccordionSummary>
              <Typography>
                {" "}
                {expanded === "panel3" || expandPanel["panel3"] === true ? (
                  <span>–</span>
                ) : (
                  <span>+</span>
                )}
                <span>Given Name</span>
                <span className="text-conflict">
                  {" "}
                  {getUserDataChanges.data.preferredName
                    ? getUserDataChanges.data.preferredName.length
                    : 0}{" "}
                  Conflict
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer className="table-container">
                <Table sx={{ minWidth: 650 }} aria-label="user list">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          // inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                          color="default"
                          className="impact-check-box"
                          onChange={(e) => selectAllData("preferredName")}
                        />
                        Details in LEAD 4D Database
                      </TableCell>
                      <TableCell>Details in Active Directory</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getUserDataChanges.data.preferredName &&
                      getUserDataChanges.data.preferredName.map(
                        (preferredName, type) => (
                          <TableRow key={preferredName.id}>
                            <TableCell>
                              <span>
                                <Checkbox
                                  // inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                                  color="default"
                                  className="impact-check-box"
                                  checked={selectedPreferredName.some(
                                    (item) => item === preferredName
                                  )}
                                  onChange={() =>
                                    trackSelectedItems(
                                      preferredName,
                                      "preferredName"
                                    )
                                  }
                                />
                                <span>{preferredName.db_value}</span>
                              </span>
                            </TableCell>
                            <TableCell>
                              <span>{preferredName.ad_value}</span>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    <TableRow>
                      <TableCell className="border-none"></TableCell>
                      <TableCell className="btn-contain">
                        <div
                          onClick={() => {
                            if (
                              !myContext.disableUserUpdation &&
                              selectedPreferredName &&
                              selectedPreferredName.length > 0
                            ) {
                              userDataChangeInitiate("preferredName");
                            }
                          }}
                          className={
                            "butn yellow-color" +
                            (!myContext.disableUserUpdation &&
                            selectedPreferredName &&
                            selectedPreferredName.length > 0
                              ? ""
                              : " disable-btn ")
                          }>
                          Update
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          {/* Rank */}
          <Accordion
            className="accordian"
            expanded={expanded === "panel4" || expandPanel["panel4"] === true}
            onChange={handleChange("panel4")}>
            <AccordionSummary>
              <Typography>
                {" "}
                {expanded === "panel4" || expandPanel["panel4"] === true ? (
                  <span>–</span>
                ) : (
                  <span>+</span>
                )}
                <span>Rank</span>
                <span className="text-conflict">
                  {" "}
                  {getUserDataChanges.data.rankDesc
                    ? getUserDataChanges.data.rankDesc.length
                    : 0}{" "}
                  Conflict
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer className="table-container">
                <Table sx={{ minWidth: 650 }} aria-label="user list">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          // inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                          color="default"
                          className="impact-check-box"
                          onChange={(e) => selectAllData("rankDesc")}
                        />
                        User
                      </TableCell>
                      <TableCell>Details in LEAD 4D Directory</TableCell>
                      <TableCell>Details in Active Directory</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getUserDataChanges.data.rankDesc &&
                      getUserDataChanges.data.rankDesc.map(
                        (rankDescrip, type) => (
                          <TableRow key={rankDescrip.id}>
                            <TableCell>
                              <span>
                                <Checkbox
                                  // inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                                  color="default"
                                  className="impact-check-box"
                                  checked={selectedRankDesc.some(
                                    (item) => item === rankDescrip
                                  )}
                                  onChange={() =>
                                    trackSelectedItems(rankDescrip, "rankDesc")
                                  }
                                />
                                <span>{rankDescrip.userId}</span>
                              </span>
                            </TableCell>
                            <TableCell>
                              <span>{rankDescrip.db_value}</span>
                            </TableCell>
                            <TableCell>
                              <span>{rankDescrip.ad_value}</span>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    <TableRow>
                      <TableCell className="border-none"></TableCell>
                      <TableCell className="border-none"></TableCell>
                      <TableCell className="btn-contain">
                        <div
                          onClick={() => {
                            if (
                              !myContext.disableUserUpdation &&
                              selectedRankDesc.length > 0
                            ) {
                              userDataChangeInitiate("rankDesc");
                            }
                          }}
                          className={
                            "butn yellow-color" +
                            (!myContext.disableUserUpdation &&
                            selectedRankDesc.length > 0
                              ? ""
                              : " disable-btn ")
                          }>
                          Update
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>

          {/* Counsellor Email */}
          <Accordion
            className="accordian"
            expanded={expanded === "panel5" || expandPanel["panel5"] === true}
            onChange={handleChange("panel5")}>
            <AccordionSummary>
              <Typography>
                {" "}
                {expanded === "panel5" || expandPanel["panel5"] === true ? (
                  <span>–</span>
                ) : (
                  <span>+</span>
                )}
                <span>Counsellor Email</span>
                <span className="text-conflict">
                  {" "}
                  {getUserDataChanges.data.counselorEmail
                    ? getUserDataChanges.data.counselorEmail.length
                    : 0}{" "}
                  Conflict
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer className="table-container">
                <Table sx={{ minWidth: 650 }} aria-label="user list">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          // inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                          color="default"
                          className="impact-check-box"
                          onChange={(e) => selectAllData("counselorEmail")}
                        />
                        User
                      </TableCell>
                      <TableCell>Details in LEAD 4D Directory</TableCell>
                      <TableCell>Details in Active Directory</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getUserDataChanges.data.counselorEmail &&
                      getUserDataChanges.data.counselorEmail.map(
                        (counselEmail, type) => (
                          <TableRow key={counselEmail.id}>
                            <TableCell>
                              <span>
                                <Checkbox
                                  // inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                                  color="default"
                                  className="impact-check-box"
                                  checked={selectedCounselorEmail.some(
                                    (item) => item === counselEmail
                                  )}
                                  onChange={() =>
                                    trackSelectedItems(
                                      counselEmail,
                                      "counselorEmail"
                                    )
                                  }
                                />
                                <span>{counselEmail.userId}</span>
                              </span>
                            </TableCell>
                            <TableCell>
                              <span>{counselEmail.db_value}</span>
                            </TableCell>
                            <TableCell>
                              <span>{counselEmail.ad_value}</span>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    <TableRow>
                      <TableCell className="border-none"></TableCell>
                      <TableCell className="border-none"></TableCell>
                      <TableCell className="btn-contain">
                        <div
                          onClick={() => {
                            if (
                              !myContext.disableUserUpdation &&
                              selectedCounselorEmail.length > 0
                            ) {
                              userDataChangeInitiate("counselorEmail");
                            }
                          }}
                          className={
                            "butn yellow-color" +
                            (!myContext.disableUserUpdation &&
                            selectedCounselorEmail.length > 0
                              ? ""
                              : " disable-btn ")
                          }>
                          Update
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          {/* Counselor Name  */}
          <Accordion
            className="accordian"
            expanded={expanded === "panel6" || expandPanel["panel6"] === true}
            onChange={handleChange("panel6")}>
            <AccordionSummary>
              <Typography>
                {" "}
                {expanded === "panel6" || expandPanel["panel6"] === true ? (
                  <span>–</span>
                ) : (
                  <span>+</span>
                )}
                <span>Counselor Name</span>
                <span className="text-conflict">
                  {" "}
                  {getUserDataChanges.data.counselorName
                    ? getUserDataChanges.data.counselorName.length
                    : 0}{" "}
                  Conflict
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer className="table-container">
                <Table sx={{ minWidth: 650 }} aria-label="user list">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Checkbox
                          // inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                          color="default"
                          className="impact-check-box"
                          onChange={(e) => selectAllData("counselorName")}
                        />
                        User
                      </TableCell>
                      <TableCell>Details in LEAD 4D Directory</TableCell>
                      <TableCell>Details in Active Directory</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getUserDataChanges.data.counselorName &&
                      getUserDataChanges.data.counselorName.map(
                        (counselName, type) => (
                          <TableRow key={counselName.id}>
                            <TableCell>
                              <span>
                                <Checkbox
                                  // inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                                  color="default"
                                  className="impact-check-box"
                                  checked={selectedCounselorName.some(
                                    (item) => item === counselName
                                  )}
                                  onChange={() =>
                                    trackSelectedItems(
                                      counselName,
                                      "counselorName"
                                    )
                                  }
                                />
                                <span>{counselName.userId}</span>
                              </span>
                            </TableCell>
                            <TableCell>
                              <span>{counselName.db_value}</span>
                            </TableCell>
                            <TableCell>
                              <span>{counselName.ad_value}</span>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    <TableRow>
                      <TableCell className="border-none"></TableCell>
                      <TableCell className="border-none"></TableCell>
                      <TableCell className="btn-contain">
                        <div
                          onClick={() => {
                            if (
                              !myContext.disableUserUpdation &&
                              selectedCounselorName.length > 0
                            ) {
                              userDataChangeInitiate("counselorName");
                            }
                          }}
                          className={
                            "butn yellow-color" +
                            (!myContext.disableUserUpdation &&
                            selectedCounselorName.length > 0
                              ? ""
                              : " disable-btn ")
                          }>
                          Update
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          {/* Lead Reviewer Only   */}
          <Accordion
            className="accordian"
            expanded={expanded === "panel7" || expandPanel["panel7"] === true}
            onChange={handleChange("panel7")}>
            <AccordionSummary>
              <Typography>
                {" "}
                {expanded === "panel7" || expandPanel["panel7"] === true ? (
                  <span>–</span>
                ) : (
                  <span>+</span>
                )}
                <span>Lead Reviewer Only</span>
                <span className="text-conflict">
                  {" "}
                  {getUserDataChanges.data.leadReviewer
                    ? getUserDataChanges.data.leadReviewer.length
                    : 0}{" "}
                  Conflict
                </span>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer className="table-container">
                <Table sx={{ minWidth: 650 }} aria-label="user list">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ padding: "10px" }}>
                        {/* <Checkbox
                                // inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                                color="default"
                                className="impact-check-box"
                                onChange={(e) =>
                                  selectAllData("leadReviewer")
                                } 
                                    
                                /> */}
                        <span style={{ marginLeft: "16px" }}>User</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {getUserDataChanges.data.leadReviewer &&
                      getUserDataChanges.data.leadReviewer.map(
                        (leadReviewer, type) => (
                          <TableRow key={leadReviewer.id}>
                            <TableCell>
                              <span>
                                {/* <Checkbox
                                // inputProps={{ "aria-label": "Impact Statement Checkbox" }}
                                color="default"
                                className="impact-check-box"
                                checked={selectedLeadReviewer.some(
                                  (item) => item === leadReviewer
                                )}
                                onChange={() =>
                                  trackSelectedItems(leadReviewer ,"leadReviewer" )
                                } 
                                    
                            /> */}
                                <span
                                  style={{
                                    padding: "9px",
                                    marginLeft: "15px",
                                  }}>
                                  {leadReviewer.userId}
                                </span>
                              </span>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    <TableRow>
                      <TableCell className="btn-contain">
                        <div style={{ marginRight: "10px", marginTop: "5px" }}>
                          Please add the relevant user from the list in 'Manage
                          User' as Lead Reviewer to provide access.
                        </div>
                        <div
                          onClick={RedirectToManage}
                          className={"butn yellow-color"}>
                          Manage User
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </SimpleBar>
      </div>
      {/* {myContext.disableUserUpdation && (
          <div className="error-section">
            <span className="redMsg">
              {" "}
              <i className="icon-warning-filled" />
            </span>
            <div className="desc">
              {
                "Users are being added. It will take some time. You can perform this action after the operation is completed. We appreciate your patience!"
              }
            </div>
          </div>
        )} */}
      <div>
        <Modal
          open={isOpen}
          onClose={() => {
            setOpen(false);
          }}
          styles={{
            modal: {
              maxWidth: "180vh",
              width: "135vh",
              height: "75vh",
              padding: "unset",
            },
            overlay: {
              background: "rgba(0, 0, 0, 0.5)",
            },
            /*  closeButton: {
          background: "white",
        }, */
          }}
          center>
          <div className="modalheading">Update Email</div>
          <div className="hrborder"></div>
          {selectedEmail &&
            selectedEmail.map((email, index) => (
              <>
                <div className="input-wrap">
                  <div className="input-field-style" style={{ width: "360px" }}>
                    <InputLabel
                      htmlFor="uncontrolled-native"
                      className={"leftSpacing"}
                      disabled={true}>
                      {"Details in LEAD 4D database"}
                    </InputLabel>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      disableUnderline={true}
                      disabled={true}
                      value={email.userId}
                      className={classes.root}
                    />
                  </div>
                  <div className="input-field-style" style={{ width: "350px" }}>
                    <InputLabel
                      htmlFor="uncontrolled-native"
                      className={"leftSpacing"}
                      disabled={true}>
                      {"Updated Email"}
                    </InputLabel>
                    <TextField
                      id="filled-basic"
                      variant="filled"
                      disableUnderline={false}
                      disabled={false}
                      className={classes.root}
                      value={email.ad_value || ""}
                      onChange={(e) => {
                        updateEmail(e.target.value, index);
                      }}
                      required={true}
                    />
                  </div>
                </div>
              </>
            ))}

          {/* <div className="hrborder"></div> */}
          <div className="button-wrap">
            <div
              onClick={() => {
                setOpen(false);
              }}
              className={"butn grey-color"}>
              Cancel
            </div>
            <div
              onClick={() => {
                if (!selectedEmail.some((email) => email.ad_value === "")) {
                  userDataChangeInitiate("email");
                }
              }}
              className={
                "butn yellow-color" +
                (!myContext.disableUserUpdation ? "" : " disable-btn ")
              }>
              Update
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default ChangeManagement;
