import React from 'react';
import { Quill } from 'react-quill'
import getPlaceholderModule from 'quill-placeholder-module'
import _ from 'lodash';
import "./Quill.scss";
import { backgroundColors, fontColors } from './constant';

Quill.register('modules/placeholder', getPlaceholderModule(Quill));


const getPlaceholderDropdown = (dynamicPlaceholders = {}) => {
    const dropdownList = Object.keys(dynamicPlaceholders).map(key => {
        const dropdown = (<select class="ql-placeholder" id={`quill-${key}`}>
            {_.isArray(dynamicPlaceholders[key]) ? dynamicPlaceholders[key].map(ele => (
                <option value={ele}>{ele}</option>
            )) : null}
        </select>);
        return dropdown;
    }
    );
    return dropdownList;
}

const QuillToolbar = (props) => {
    const { dynamicPlaceholders, readOnly } = props;
    return (
        <div id="toolbar" className='quill-toolbar' style={{ display: readOnly ? 'none' : '' }}>
            <span class="ql-formats">
                {getPlaceholderDropdown(dynamicPlaceholders)}
                <button class="ql-bold" data-toggle="tooltip" data-placement="bottom" title="Bold" />
                <button class="ql-italic" data-toggle="tooltip" data-placement="bottom" title="Italic" />
                <button class="ql-underline" data-toggle="tooltip" data-placement="bottom" title="underline" />
                <button class="ql-strike" data-toggle="tooltip" data-placement="bottom" title="strike" />
                <button class="ql-script" value="sub" data-toggle="tooltip" data-placement="bottom" title="sub script" />
                <button class="ql-script" value="super" data-toggle="tooltip" data-placement="bottom" title="super script" />
                <select class="ql-color" data-toggle="tooltip" data-placement="bottom" title="font color">
                    <option selected />
                    {fontColors.map(color => (<option value={color} />))}
                </select>
                <select class="ql-background" data-toggle="tooltip" data-placement="bottom" title=" background color">
                    <option selected />
                    {backgroundColors.map(color => (<option value={color} />))}
                </select>
                <button class="ql-list" value="ordered" data-toggle="tooltip" data-placement="bottom" title="ordered list" />
                <button class="ql-list" value="bullet" data-toggle="tooltip" data-placement="bottom" title="bullet list" />
                <button class="ql-indent" value="-1" data-toggle="tooltip" data-placement="bottom" title="left indent" />
                <button class="ql-indent" value="+1" data-toggle="tooltip" data-placement="bottom" title="right indent" />
                <select class="ql-align" data-toggle="tooltip" data-placement="bottom" title="align">
                    <option selected />
                    <option value="center" />
                    <option value="right" />
                    <option value="justify" />
                </select>
                <button class="ql-link" data-toggle="tooltip" data-placement="bottom" title="link" />
                <button class="ql-clean" data-toggle="tooltip" data-placement="bottom" title="clean" />
            </span>
        </div>
    )
}

export default QuillToolbar;