export const iconList = [
  "icon-hamburger",
  "icon-menu",
  "icon-app-menu",
  "icon-drag",
  "icon-more-h",
  "icon-more-v",
  "icon-home",
  "icon-home-add",
  "icon-home-check",
  "icon-speedometer",
  "icon-search",
  "icon-zoom-in",
  "icon-zoom-out",
  "icon-search-help",
  "icon-check-unchecked",
  "icon-check-checked",
  "icon-checked-filled",
  "icon-radio-unchecked",
  "icon-check-outline",
  "icon-radio-checked",
  "icon-switch-off",
  "icon-switch-on",
  "icon-star-outline",
  "icon-star-filled",
  "icon-star-block",
  "icon-star-list",
  "icon-star-add",
  "icon-fav",
  "icon-fav-filled",
  "icon-like",
  "icon-dislike",
  "icon-bell",
  "icon-bell-filled",
  "icon-trash",
  "icon-trash-putback",
  "icon-recycle-bin",
  "icon-recycle-putback",
  "icon-pencil",
  "icon-pencil-undo",
  "icon-no-edit",
  "icon-wrench",
  "icon-settings",
  "icon-settings-2",
  "icon-settings-3",
  "icon-clock",
  "icon-alarm",
  "icon-alarm-snooze",
  "icon-hourglass",
  "icon-history",
  "icon-reload",
  "icon-refresh",
  "icon-refresh-clear",
  "icon-refresh-block",
  "icon-refresh-monitor",
  "icon-refresh-stop",
  "icon-global",
  "icon-global-add",
  "icon-global-user",
  "icon-global-fav",
  "icon-global-arrow",
  "icon-lock",
  "icon-unlock",
  "icon-lock-block",
  "icon-key",
  "icon-power-off",
  "icon-new",
  "icon-share",
  "icon-share-2",
  "icon-share-multipal",
  "icon-exit",
  "icon-logout",
  "icon-login",
  "icon-upload",
  "icon-door",
  "icon-more-squere",
  "icon-pin",
  "icon-un-pin",
  "icon-pin-block",
  "icon-cloud",
  "icon-cloud-add",
  "icon-cloud-upload",
  "icon-cloud-download",
  "icon-moon",
  "icon-sun",
  "icon-campus",
  "icon-case",
  "icon-case-add",
  "icon-layers",
  "icon-image",
  "icon-bookmark",
  "icon-bookmark-add",
  "icon-bookmark-search",
  "icon-bookmarks",
  "icon-puzzle",
  "icon-box1",
  "icon-bag",
  "icon-cart",
  "icon-atm-card",
  "icon-plus-filled",
  "icon-plus-o",
  "icon-minus-filled",
  "icon-minus-o",
  "icon-minus-undo",
  "icon-minus-redo",
  "icon-close-filled",
  "icon-close-outline",
  "icon-warning-filled",
  "icon-warning-outline",
  "icon-alert-filled",
  "icon-alert-outline",
  "icon-info-filled",
  "icon-info-outline",
  "icon-help-filled",
  "icon-help-outline",
  "icon-change-filled",
  "icon-change-outline",
  "icon-verified-filled",
  "icon-verified-outline",
  "icon-block",
  "icon-shield-outline",
  "icon-shield-close",
  "icon-shield-warning",
  "icon-shield-lock",
  "icon-help",
  "icon-warning3",
  "icon-plus-squere-filled",
  "icon-pluses",
  "icon-load",
  "icon-minus-squere-2",
  "icon-close-squere-filled",
  "icon-check-squere-filled",
  "icon-play-squre-filled",
  "icon-plus-plus",
  "icon-plus",
  "icon-minus",
  "icon-close",
  "icon-percentage",
  "icon-not-equal",
  "icon-chat",
  "icon-apps-2",
  "icon-shapes",
  "icon-qr-code",
  "icon-barcode-f",
  "icon-barcode",
  "icon-touchid",
  "icon-wand",
  "icon-wand-check",
  "icon-power",
  "icon-power-list",
  "icon-power-settings",
  "icon-power-user",
  "icon-power-check",
  "icon-eye",
  "icon-chevron-left",
  "icon-chevron-right",
  "icon-chevron-up",
  "icon-chevron-down",
  "icon-chevron-left-2",
  "icon-chevron-right-2",
  "icon-chevron-up-2",
  "icon-chevron-down-2",
  "icon-arrow-left-o",
  "icon-arrow-right-o",
  "icon-arrow-up-o",
  "icon-arrow-down-o",
  "icon-chevron-up-o",
  "icon-chevron-down-o",
  "icon-chevron-left-o",
  "icon-chevron-right-o",
  "icon-caret-left",
  "icon-caret-right",
  "icon-caret-up",
  "icon-caret-down",
  "icon-unfold-more",
  "icon-unfold-less",
  "icon-arrow-left-top-l",
  "icon-arrow-right-top-l",
  "icon-arrow-left-bottom-l",
  "icon-arrow-right-bottom-l",
  "icon-arrow-left",
  "icon-arrow-right",
  "icon-arrow-up",
  "icon-arrow-down",
  "icon-arrow-left-top",
  "icon-arrow-right-top",
  "icon-arrow-left-bottom",
  "icon-arrow-right-bottom",
  "icon-arrow-down-2l",
  "icon-arrow-split-down",
  "icon-arrow-up-2",
  "icon-arrow-down-2",
  "icon-arrow-left-2",
  "icon-arrow-right-2",
  "icon-long-arrow-left",
  "icon-long-arrow-right",
  "icon-long-arrow-up",
  "icon-long-arrow-down",
  "icon-long-arrow-left-right",
  "icon-long-arrow-up-down",
  "icon-arrow-swap-d1",
  "icon-arrow-swap-d2",
  "icon-arrow-swap-rl",
  "icon-arrow-swap-lr",
  "icon-arrow-tab-up",
  "icon-arrow-tab-down",
  "icon-arrow-tab-left",
  "icon-arrow-tab-right",
  "icon-carrow-right-2",
  "icon-carrow-left-2",
  "icon-carrow-right",
  "icon-carrow-left",
  "icon-carrow-top-left",
  "icon-carrow-top-right",
  "icon-carrow-circle",
  "icon-arrow-right1",
  "icon-hcarrow-left",
  "icon-swap-3",
  "icon-swap-4",
  "icon-expand-all",
  "icon-mouse-move-all",
  "icon-mouse-move-o",
  "icon-mouse-move",
  "icon-arrow-return-alt",
  "icon-arrow-return",
  "icon-arrow-merge-b",
  "icon-arrow-merge",
  "icon-dir-060",
  "icon-dir-061",
  "icon-dir-072",
  "icon-dir-071",
  "icon-dir-074",
  "icon-dir-077",
  "icon-dir-078",
  "icon-dir-100",
  "icon-config-squere",
  "icon-box2",
  "icon-boxes",
  "icon-bag-list",
  "icon-lit",
  "icon-plug",
  "icon-pluged",
  "icon-plug-in",
  "icon-phone",
  "icon-phone2",
  "icon-phone-incoming",
  "icon-phone3",
  "icon-phone-add",
  "icon-bug",
  "icon-eye-hide",
  "icon-eye-block",
  "icon-network",
  "icon-network2",
  "icon-wifi-error",
  "icon-wifi",
  "icon-wifi-block",
  "icon-hotspot",
  "icon-hotspot-add",
  "icon-hand",
  "icon-tap",
  "icon-touch-cursor",
  "icon-track-pad",
  "icon-cursor-block",
  "icon-new-window",
  "icon-hand-shake",
  "icon-responsive",
  "icon-book-self",
  "icon-cube",
  "icon-cube-edit",
  "icon-lab",
  "icon-tag",
  "icon-artic",
  "icon-temp",
  "icon-page",
  "icon-notepad",
  "icon-ticket",
  "icon-page2",
  "icon-page3",
  "icon-notepad2",
  "icon-comment2",
  "icon-recent2",
  "icon-ethernet2",
  "icon-ethernet-no",
  "icon-server",
  "icon-server2",
  "icon-server3",
  "icon-calender-fav",
  "icon-nav-change",
  "icon-v-layout",
  "icon-database",
  "icon-database-check",
  "icon-database-search",
  "icon-database-link",
  "icon-database-settings",
  "icon-database-sync",
  "icon-databasees",
  "icon-filter",
  "icon-filter-config",
  "icon-filterclear",
  "icon-filter-list",
  "icon-table-add",
  "icon-grid4",
  "icon-grid9",
  "icon-grid-list",
  "icon-grid-clear",
  "icon-grids",
  "icon-chart",
  "icon-chart2",
  "icon-chart3",
  "icon-chart4",
  "icon-chart5",
  "icon-chart6",
  "icon-chart7",
  "icon-chart8",
  "icon-chart9",
  "icon-chart10",
  "icon-chart11",
  "icon-chart12",
  "icon-chart13",
  "icon-chart14",
  "icon-chart15",
  "icon-chart16",
  "icon-chart17",
  "icon-chart18",
  "icon-chart19",
  "icon-data-flow",
  "icon-data-flow-alt",
  "icon-data-flow2",
  "icon-data-flow33",
  "icon-sort-az",
  "icon-sort-za",
  "icon-chart-window",
  "icon-editor-12",
  "icon-editor-13",
  "icon-editor-14",
  "icon-editor-15",
  "icon-editor-16",
  "icon-editor-19",
  "icon-editor-18",
  "icon-editor-22",
  "icon-editor-25",
  "icon-editor-24",
  "icon-editor-26",
  "icon-editor-27",
  "icon-editor-20",
  "icon-editor-21",
  "icon-editor-01",
  "icon-editor-02",
  "icon-editor-03",
  "icon-editor-04",
  "icon-editor-05",
  "icon-editor-07",
  "icon-editor-08",
  "icon-editor-09",
  "icon-editor-28",
  "icon-editor-29",
  "icon-editor-30",
  "icon-editor-32",
  "icon-editor-33",
  "icon-editor-34",
  "icon-editor-35",
  "icon-editor-36",
  "icon-editor-37",
  "icon-editor-38",
  "icon-editor-39",
  "icon-editor-54",
  "icon-editor-55",
  "icon-editor-56",
  "icon-editor-57",
  "icon-editor-58",
  "icon-editor-59",
  "icon-editor-60",
  "icon-editor-61",
  "icon-editor-53",
  "icon-editor-52",
  "icon-editor-80",
  "icon-web-145",
  "icon-web-088",
  "icon-web-091",
  "icon-web-083",
  "icon-web-082",
  "icon-web-070",
  "icon-editor-43",
  "icon-editor-49",
  "icon-editor-50",
  "icon-editor-51",
  "icon-web-071",
  "icon-web-227",
  "icon-web-079",
  "icon-editor-86",
  "icon-editor-87",
  "icon-editor-81",
  "icon-editor-41",
  "icon-link-break",
  "icon-web-153",
  "icon-editor-44",
  "icon-editor-45",
  "icon-editor-46",
  "icon-editor-47",
  "icon-editor-48",
  "icon-editor-83",
  "icon-file-86",
  "icon-file-85",
  "icon-file-84",
  "icon-file-87",
  "icon-file-80",
  "icon-file-62",
  "icon-file-61",
  "icon-file-35",
  "icon-file-83",
  "icon-file-59",
  "icon-file-67",
  "icon-web-098",
  "icon-file-06",
  "icon-file-12",
  "icon-file-15",
  "icon-file-16",
  "icon-file-51",
  "icon-file-58",
  "icon-file-69",
  "icon-file-70",
  "icon-file-89",
  "icon-file-99",
  "icon-file-100",
  "icon-file-101",
  "icon-files-104",
  "icon-web-067",
  "icon-web-137",
  "icon-web-245",
  "icon-web-246",
  "icon-web-176",
  "icon-file-94",
  "icon-file-95",
  "icon-file-96",
  "icon-editor-84",
  "icon-editor-85",
  "icon-web-200",
  "icon-views-013",
  "icon-views-104",
  "icon-views-015",
  "icon-views-014",
  "icon-views-017",
  "icon-views-001",
  "icon-views-003",
  "icon-views-004",
  "icon-views-058",
  "icon-views-059",
  "icon-views-007",
  "icon-views-019",
  "icon-views-021",
  "icon-views-023",
  "icon-views-026",
  "icon-views-115",
  "icon-views-031",
  "icon-views-032",
  "icon-views-041",
  "icon-views-060",
  "icon-views-061",
  "icon-views-044",
  "icon-views-045",
  "icon-views-051",
  "icon-views-052",
  "icon-views-056",
  "icon-views-062",
  "icon-views-063",
  "icon-views-064",
  "icon-views-076",
  "icon-views-081",
  "icon-views-108",
  "icon-views-117",
  "icon-email-01",
  "icon-email-02",
  "icon-web-323",
  "icon-email-20",
  "icon-email-03",
  "icon-email-05",
  "icon-email-04",
  "icon-email-06",
  "icon-web-329",
  "icon-web-312",
  "icon-email-39",
  "icon-email-38",
  "icon-web-160",
  "icon-web-161",
  "icon-email-37",
  "icon-web-069",
  "icon-email-09",
  "icon-email-10",
  "icon-email-11",
  "icon-email-17",
  "icon-email-18",
  "icon-email-21",
  "icon-email-25",
  "icon-email-26",
  "icon-email-34",
  "icon-email-32",
  "icon-email-33",
  "icon-email-35",
  "icon-web-117",
  "icon-web-143",
  "icon-map-05",
  "icon-map-22",
  "icon-map-21",
  "icon-web-046",
  "icon-web-047",
  "icon-web-108",
  "icon-web-285",
  "icon-map-01",
  "icon-map-02",
  "icon-map-03",
  "icon-map-04",
  "icon-map-06",
  "icon-map-07",
  "icon-map-08",
  "icon-map-09",
  "icon-map-10",
  "icon-map-11",
  "icon-map-12",
  "icon-map-13",
  "icon-map-14",
  "icon-map-15",
  "icon-map-16",
  "icon-map-17",
  "icon-map-18",
  "icon-map-19",
  "icon-map-20",
  "icon-user-01",
  "icon-user-02",
  "icon-user-03",
  "icon-user-04",
  "icon-user-07",
  "icon-user-19",
  "icon-user-06",
  "icon-user-09",
  "icon-user-08",
  "icon-user-13",
  "icon-web-275",
  "icon-web-276",
  "icon-user-12",
  "icon-user-18",
  "icon-user-22",
  "icon-user-25",
  "icon-user-26",
  "icon-user-27",
  "icon-user-28",
  "icon-web-116",
  "icon-user-29",
  "icon-user-30",
  "icon-user-31",
  "icon-user-32",
  "icon-user-33",
  "icon-user-34",
  "icon-user-36",
  "icon-user-37",
  "icon-user-38",
  "icon-user-42",
  "icon-user-43",
  "icon-user-45",
  "icon-user-46",
  "icon-user-50",
  "icon-user-52",
  "icon-user-53",
  "icon-user-54",
  "icon-web-051",
  "icon-web-052",
  "icon-web-060",
  "icon-web-061",
  "icon-web-075",
  "icon-web-010",
  "icon-web-129",
  "icon-web-094",
  "icon-web-103",
  "icon-web-111",
  "icon-web-123",
  "icon-web-125",
  "icon-web-126",
  "icon-web-136",
  "icon-web-146",
  "icon-web-170",
  "icon-web-198",
  "icon-web-212",
  "icon-web-213",
  "icon-web-273",
  "icon-web-286",
  "icon-object-001",
  "icon-object-002",
  "icon-object-003",
  "icon-object-004",
  "icon-object-005",
  "icon-object-006",
  "icon-object-007",
  "icon-object-008",
  "icon-object-009",
  "icon-object-010",
  "icon-object-011",
  "icon-object-012",
  "icon-object-013",
  "icon-object-014",
  "icon-object-015",
  "icon-object-016",
  "icon-object-017",
  "icon-object-018",
  "icon-object-019",
  "icon-object-020",
  "icon-object-021",
  "icon-object-022",
  "icon-object-023",
  "icon-object-024",
  "icon-object-025",
  "icon-object-026",
  "icon-object-027",
  "icon-object-028",
  "icon-object-029",
  "icon-object-030",
  "icon-object-031",
  "icon-object-032",
  "icon-object-033",
  "icon-object-034",
  "icon-object-035",
  "icon-object-036",
  "icon-object-037",
  "icon-object-038",
  "icon-object-039",
  "icon-object-040",
  "icon-object-041",
  "icon-object-042",
  "icon-object-043",
  "icon-object-044",
  "icon-object-045",
  "icon-object-046",
  "icon-object-047",
  "icon-object-048",
  "icon-object-049",
  "icon-object-050",
  "icon-object-051",
  "icon-object-052",
  "icon-object-053",
  "icon-object-054",
  "icon-object-055",
  "icon-object-056",
  "icon-object-057",
  "icon-object-058",
  "icon-object-059",
  "icon-object-060",
  "icon-object-061",
  "icon-object-062",
  "icon-object-063",
  "icon-object-064",
  "icon-object-065",
  "icon-object-066",
  "icon-object-067",
  "icon-object-068",
  "icon-object-069",
  "icon-object-070",
  "icon-object-071",
  "icon-object-072",
  "icon-object-073",
  "icon-object-074",
  "icon-object-075",
  "icon-object-076",
  "icon-object-077",
  "icon-object-078",
  "icon-object-079",
  "icon-object-080",
  "icon-object-081",
  "icon-object-082",
  "icon-object-083",
  "icon-object-084",
  "icon-object-085",
  "icon-object-086",
  "icon-object-087",
  "icon-object-088",
  "icon-object-089",
  "icon-object-090",
  "icon-object-091",
  "icon-object-092",
  "icon-object-093",
  "icon-object-094",
  "icon-object-095",
  "icon-object-096",
  "icon-object-097",
  "icon-object-098",
  "icon-object-099",
  "icon-object-100",
  "icon-object-101",
  "icon-object-102",
];
