import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import EditorToolbar, { modules0, modules1, modules2, modules3, formats } from "./EditToolBar";
import ReactQuill from "react-quill";
import _ from "lodash";
import SimpleBar from "simplebar-react";
import { toast, Flip } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import "./IntroductionCards.scss";
import { InputLabel, makeStyles, TextField } from "@material-ui/core";
import Loader from "../../../Loader/Loader";
import IntroductionCard from "../../../IntroductionCard/IntroductionCard";
import Modal from "react-responsive-modal";

export const imagePos = ["top_left", "top_right", "bottom_left", "bottom_right"];

const useStyles = makeStyles((theme) => ({
  root1: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-underline": {
      width: "250px",
      height: "27px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      //   borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      //   "&:hover:not($disabled):not($focused):not($error):before": {
      //     borderBottom: "none",
      //   },
      " &:hover:before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      },
      " &:hover:after": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  labelroothalf: {
    width: "395px",
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "8px",
  },
  topSpacing: {
    marginTop: "16px",
  },
  topSyli: {
    marginBottom: " -30px",
    marginTop: "16px",
    zIndex: "8",
    width: "115px",
  },
  half: {
    display: "flex",
    flexDirection: "row",
    width: "350px",
    marginLeft: "15px",
  },
  root900: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-underline": {
      //width: "870px",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
      fontFamily: "EYInterState-Regular",
    },
  },
  root: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "400px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
      fontFamily: "EYInterState-Regular",
    },
  },
  rootMulti: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "677px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  labelroot: {
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "8px",
    // fontWeight: "600",
  },
  labelroot1: {
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "10px",
    fontWeight: "600",
  },
  topSpacing: {
    //marginTop: "8px",
  },
  topSyli: {
    marginBottom: " -30px",
    marginTop: "16px",
    zIndex: "8",
    width: "100px",
  },
  half: {
    display: "flex",
    flexDirection: "row",
  },
  arrow: {
    position: "absolute",
    fontSize: 14,
    width: "6em",
    height: "4em",
    //   transform: "rotate(225deg)",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  paper2: {
    backgroundColor: "white",
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 2, 2),
    width: 170,
    overflow: "hidden",
    overflowX: "hidden",
    fontSize: "11px",
  },
  options: {
    borderBottom: "1px solid grey",
    padding: "5px 0px 5px 5px",
    display: "flex",
    alignItems: "center",
    height: "24px",
    cursor: "pointer",
  },
  popper: {
    // zIndex: 1,
    left: "-55px!important",
    '&[x-placement*="top-end"]': {
      top: "-12px!important",
    },
    '&[x-placement*="bottom-end"]': {
      top: "9px!important",
    },
    '&[x-placement*="bottom"] $arrow': {
      top: 2,
      left: "105px!important",
      marginTop: "-0.9em",
      width: "6em",
      height: "2em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: "103px!important",
      marginBottom: "-3.6em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
}));

const IntroductionCards = (props) => {
  const limit = 400;
  const classes = useStyles();
  const dispatch = useDispatch();
  const reactQuillRef = useRef([]);
  const [cardDetails, setCardDetails] = useState([
    {
      title: "",
      content: "",
      fileUrl: "",
      fileName: "",
    },
  ]);
  const [contentLength, setContentLength] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const generalContent = useSelector((state) => state.generalContent);
  const [activeCardIndex, setactiveCardIndex] = useState(0);

  const addCard = useCallback(() => {
    const copyCardDetails = _.cloneDeep(cardDetails);
    copyCardDetails.push({
      title: "",
      content: "",
      fileUrl: "",
      fileName: "",
    });
    setCardDetails(copyCardDetails);
  }, [cardDetails]);

  const updateCardDetails = useCallback(
    (index, field, val) => {
      const copyCardDetails = _.cloneDeep(cardDetails);
      copyCardDetails[index][field] = val;
      setCardDetails(copyCardDetails);
    },
    [cardDetails]
  );

  const validateFile = useCallback(
    (file, index) => {
      const fileName = file.name;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
        const copyCardDetails = _.cloneDeep(cardDetails);
        copyCardDetails[index].fileName = fileName;
        copyCardDetails[index].fileUrl = file;
        setCardDetails(copyCardDetails);
      } else {
        notfiy("Invalid file format", "error");
      }
    },
    [cardDetails]
  );

  const saveCardDetails = useCallback(
    (index) => {
      const cardData = cardDetails[index];
      let formData = new FormData();
      if (cardData.fileUrl instanceof File)
        formData.append("attachment", cardData.fileUrl);
      formData.append("content", cardData.content);
      formData.append("title", cardData.title);
      formData.append("key", imagePos[index]);

      dispatch({
        type: "UPLOAD_GENERAL_CONTENT",
        payload: formData,
      });
    },
    [cardDetails, imagePos, dispatch]
  );

  const notfiy = useCallback((msg, type) => {
    toast(
      <div className="error-text">
        {type === "error" ? (
          <span className="icon-close-filled icon"></span>
        ) : (
          <span className="eyaa-state-36 icon"></span>
        )}
        <p>
          {type === "error" ? (
            <>
              <span>Error:</span> <span>{msg}</span>
            </>
          ) : (
            <span>{msg}</span>
          )}
        </p>
      </div>,
      {
        className: type,
        position: "top-center",
        transition: Flip,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  useEffect(() => {
    dispatch({
      type: "GET_GENERAL_CONTENT",
      payload: {},
    });
  }, [dispatch]);

  useEffect(() => {
    if (generalContent.fetching) {
      setIsLoading(true);
    } else if (
      generalContent.data &&
      generalContent.data.introCards &&
      generalContent.data.introCards.length > 0
    ) {
      const cards = [];
      const contentLen = [];
      setIsLoading(false);
      generalContent.data.introCards.forEach((card) => {
        cards.push({
          title: card.title,
          content: card.content,
          fileUrl: card.fileDetails.fileUrl,
          fileName: card.fileDetails.actualFileName,
          key: card.key
        });
        contentLen.push(0);
      });
      const sortedCards = cards.sort((a, b) => imagePos.indexOf(a.key) - imagePos.indexOf(b.key));
      setCardDetails(sortedCards);
      // setContentLength(contentLen);
    }
  }, [generalContent]);

  const handleChange = (content, index) => {
    const quill = reactQuillRef.current[index];
    if (quill !== undefined && quill?.getEditor()) {
      const editorLength = quill.getEditor().getLength() - 1;
      if (editorLength > limit) {
        quill.getEditor().deleteText(limit, quill.getEditor().getLength());
      } else {
        // const copyContentLength = _.cloneDeep(contentLength);
        // copyContentLength[index] = editorLength;
        // setContentLength(copyContentLength);
        updateCardDetails(index, "content", content);
      }
    }
  };

  return (
    <div className="introduction-cards">
      {isLoading && <Loader />}
      <div className="introduction-header">
        <div className="sub-header">Introduction Cards</div>
        <div className="btn-sec">
          <div className="btn" onClick={() => setOpen(true)}>
            <i className="eyaa-image-25" />
            Preview
          </div>
          {/* <div className="btn">
            <i className="icon-web-071" />
            Save
          </div> */}
        </div>
      </div>
      <div className="quil-section">
        {cardDetails.length > 0 &&
          cardDetails.map((card, index) => (
            <EditorToolbar
              display={index === activeCardIndex}
              index={index}
              length={1} />
          ))}
      </div>
      <SimpleBar className="cards-container">
        <div className="cards">
          {cardDetails.length > 0 &&
            cardDetails.map((card, index) => {
              const descLength = cardDetails[index].content ? cardDetails[index].content.replace(/(<([^>]+)>)/ig, '').length : 0;
              return (
                <div className="card" key={index}>
                  <div className="input-wrapper">
                    <InputLabel
                      htmlFor="uncontrolled-native"
                      className={classes.topSpacing}
                      classes={{
                        root: classes.labelroot,
                      }}
                    >
                      {`Card ${index + 1} Title* (${cardDetails[index].title.length
                        }/60 Characters)`}
                    </InputLabel>
                    <TextField
                      fullWidth
                      id="filled-basic"
                      variant="filled"
                      className={classes.root900}
                      required={true}
                      value={cardDetails[index].title}
                      disabled={false}
                      onChange={(e) => {
                        if (e.target.value.length <= 60) {
                          updateCardDetails(index, "title", e.target.value);
                        }
                      }}
                    />
                    <SimpleBar className={`editor${index}`}>
                      <span className="editer-heading">{`Description* (${descLength}/${limit} Characters)`}</span>
                      <ReactQuill
                        index={index}
                        style={{}}
                        ref={(el) => (reactQuillRef.current[index] = el)}
                        theme="snow"
                        onFocus={() => {
                          setactiveCardIndex(index);
                        }}
                        value={cardDetails[index].content}
                        onChange={(content) => {
                          handleChange(content, index);
                        }}
                        // bounds=".editor"
                        bounds={`.editor${index}`}
                        modules={
                          index === 0
                            ? modules0
                            : index === 1
                              ? modules1
                              : index === 2
                                ? modules2
                                : modules3
                        }
                        formats={formats}
                      />
                    </SimpleBar>
                    <div className="upload-container">
                      <label htmlFor={`uploadImage${index}`}>
                        Infographics/Image(Upload only .jpeg/.png)
                      </label>
                      <input
                        type="file"
                        name="photo"
                        id={`uploadImage${index}`}
                        className="upload-photo"
                        accept="image/png, image/jpeg"
                        onChange={(e) => validateFile(e.target.files[0], index)}
                      />
                      <span className="file-name">
                        {cardDetails[index].fileName}
                      </span>
                      <span className="eyaa-files-104 upload-icon"></span>
                    </div>
                  </div>
                  <div className="btn-container">
                    <div className="icon-wrapper">
                      <i
                        className="icon-web-071"
                        onClick={() => saveCardDetails(index)}
                      ></i>
                    </div>
                    <div className="icon-wrapper">
                      <i className="icon-trash disable-icon"></i>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </SimpleBar>
      <Modal
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        styles={{
          modal: {
            maxWidth: "unset",
            padding: "unset",
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
        center
      >
        <div className="email-template-wrap">
          <div className="modal-heading">Introduction Cards</div>
          <SimpleBar
            style={{
              overflowX: "hidden",
              maxHeight: "80vh",
              width: "70vw",
            }}
            className="preview-modal"
          >
            <IntroductionCard cardDetails={cardDetails} />
          </SimpleBar>
        </div>
      </Modal>
    </div>
  );
};
export default IntroductionCards;
