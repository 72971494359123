import { useState } from 'react';

export const useAlert = () => {
	const defaultTitle = "Alert";

	const [ alertData, setAlertData ] = useState( {
		isShowing: false,
		title: defaultTitle
	} );

	const toogleShow = ( val ) => {
		setAlertData( prev => ({
				...prev,
				isShowing: val
		}));
	}

	const ChangeTitle = ( message ) => {
		setAlertData( prev => ({
				...prev,
				title: message
		}));
	}

	return [ alertData, toogleShow, ChangeTitle ];
}