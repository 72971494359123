import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import moment from "moment";
import AppContext from "../../../utils/appContext";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const DownloadTemplateLRDG = (props) => {
    const { data } = props;

    const myContext = useContext(AppContext);
    const workbook = new Excel.Workbook();
    const workSheetName = 'Discussion Guide';

    const exclusionList = [
        "Partner Name"
    ];

    const discusssionGuideReport = useMemo(() => {
        return [
            // {
            //   header: "Partner Name",
            //   key: "name",
            //   width: 30,
            // },
            {
                header: "Last Name",
                key: "lastName",
                width: 30,
            },
            {
                header: "First Name",
                key: "firstName",
                width: 30,
            },
            {
                header: "Partner Email",
                key: "userId",
                width: 30,
            },
            {
                header: "GUI",
                key: "gui",
                width: 20,
            },
            {
                header: "Office",
                key: "office",
                width: 30,
            },
            {
                header: "Rank",
                key: "rank",
                width: 30,
            },
            {
                header: "Region",
                key: "region",
                width: 30,
            },
            {
                header: "Primary Sector",
                key: "primarySector",
                width: 30,
            },
            {
                header: "Lead Reviewer Name",
                key: "leadReviewer",
                width: 30,
            },
            {
                header: "Lead Reviewer GUI",
                key: "leadReviewerGUI",
                width: 20,
            },
            {
                header: "Reviewer Submitted Date",
                key: "reviewerSubmittedDate",
                width: 20,
            },
            {
                header: "Reviewer Submitted Status",
                key: "status",
                width: 20,
            },
            {
                header: "PFC Discussion Question 1",
                key: "question1AnsContent",
                width: 70,
            },
            {
                header: "PFC Discussion Question 2",
                key: "question2AnsContent",
                width: 70,
            },
            {
                header: "PFC Discussion Question 3",
                key: "question3AnsContent",
                width: 70,
            },
            {
                header: "PFC Discussion Question 4",
                key: "question4AnsContent",
                width: 70,
            },
            {
                header: "PFC Discussion Question 5",
                key: "question6AnsContent",
                width: 70,
            },
            {
                header: "Summary of Roles/Areas of Responsibility",
                key: "question5AnsContent",
                width: 70,
            },
            {
                header: "DRAFT Finalization Form Commentary",
                key: "distinguishPerformanceContent",
                width: 90,
            },
            {
                header: "FINAL Finalization Form Commentary",
                key: "reviewerAuditContent",
                width: 90,
            }
        ];
    }, []);

    //..HANDLERS
    const getPlainText = useCallback((value) => {
        try {
            let arrFormatted = [], plainText = "";
            let isStringOrArray = false;
            if (typeof value === 'string' || Array.isArray(value)) {
                isStringOrArray = true;
            }
            if (!isStringOrArray) {
                return value;
            }
            if (value.includes("<li>")) {
                const av = value.split("<li>");
                av.map((v, i) => {
                    if (!v.includes("<ul>")) {
                        document.getElementById('dvContent').innerHTML = v;
                        let vText = document.getElementById('dvContent').innerText;
                        arrFormatted.push(`• ${vText}\r\n`);
                    }
                });
                plainText = arrFormatted.join("");
            } else {
                document.getElementById('dvContent').innerHTML = value;
                plainText = document.getElementById('dvContent').innerText;
            }
            // document.getElementById('dvContent').innerHTML = content;
            // let plainText = document.getElementById('dvContent').innerText;
            return plainText;
        } catch (e) {
            console.log(`DownloadTemplate_LR::ERR:: ${e}`);
            return value;
        }
    }, [])

    const processData = useCallback((value, label) => {
        // let arrFormatted = [], reviewerAudit = "";
        // if (value.includes("<li>")) {
        //   const av = value.split("<li>");
        //   av.map((v, i) => {
        //     if (!v.includes("<ul>")) {
        //       document.getElementById('dvContent').innerHTML = v;
        //       let vText = document.getElementById('dvContent').innerText;
        //       arrFormatted.push(`• ${vText}\r\n`);
        //     }
        //   });
        //   reviewerAudit = arrFormatted.join("");
        // } else {
        //   document.getElementById('dvContent').innerHTML = value;
        //   reviewerAudit = document.getElementById('dvContent').innerText;
        // }
        let reviewerAudit = getPlainText(value);
        // document.getElementById('dvContent').innerHTML = value;
        // let reviewerAudit = document.getElementById('dvContent').innerText;
        let formattedValue = [];
        //debugger;
        switch (label) {
            case "name":
            case "firstName":
            case "lastName":
            case "userId":
            case "leadReviewer":
            case "leadReviewerGUI":
            case "gui":
            case "office":
            case "rank":
            case "region":
            case "primarySector":
            case "status":
            case "reviewerAuditContent":
                formattedValue.push("\n");
                formattedValue.push(`${reviewerAudit}`);
                reviewerAudit = formattedValue.join(`\n`);
                break;
            case "reviewerSubmittedDate":
                reviewerAudit = moment(value).format("DD-MM-YYYY");
                formattedValue.push("\n");
                formattedValue.push(`${reviewerAudit}`);
                reviewerAudit = formattedValue.join(`\n`);
                break;
            case "distinguishPerformanceContent":
                formattedValue.push("\n");
                formattedValue.push(`${data.name} exhibited the following distinguished performance behaviors this fiscal year:`);
                //formattedValue.push(`\n`);
                formattedValue.push(`${getPlainText(data.distinguishPerformanceContent)}`);
                formattedValue.push("\n");
                formattedValue.push(`${data.name} has the following development points:`);
                //formattedValue.push("\n");
                formattedValue.push(`${getPlainText(data.developmentPointsContent)}`);
                formattedValue.push("\n");
                formattedValue.push(`${data.name}'s potential and aspirations include:`);
                //formattedValue.push("\n");
                formattedValue.push(`${getPlainText(data.potentialAndAspirationsContent)}`);
                formattedValue.push("\n");
                formattedValue.push(`Overall, ${data.name} is in the ${data.overallCategory} category.`);
                formattedValue.push(`${data.name}'s quality rating has been considered in their LEAD category determination and the related commentary is contained in MARQ.`);
                reviewerAudit = formattedValue.join("\n");
                break;
            case "question1AnsContent":
                formattedValue.push("\n");
                formattedValue.push("1. Did your PPMD achieve their goals?");
                formattedValue.push(`\n`);
                formattedValue.push(`${reviewerAudit}`);
                reviewerAudit = formattedValue.join(`\n`);
                break;
            case "question2AnsContent":
                formattedValue.push("\n");
                formattedValue.push("2.  How has the PPMD teamed this year across SLs, SSLs and functions? And how will they accelerate it next year?");
                formattedValue.push(`\n`);
                formattedValue.push(`${reviewerAudit}`);
                reviewerAudit = formattedValue.join("\n");
                break;
            case "question3AnsContent":
                formattedValue.push("\n");
                formattedValue.push("3.  What actions has the PPMD taken to contribute to priorities including diversity, equity and inclusiveness?");
                formattedValue.push(`\n`);
                formattedValue.push(`${reviewerAudit}`);
                reviewerAudit = formattedValue.join("\n");
                break;
            case "question4AnsContent":
                formattedValue.push("\n");
                formattedValue.push("4.  What is the PPMD's capability and aspiration to take on the next complex challenge? What is needed to help them get there?");
                formattedValue.push(`\n`);
                formattedValue.push(`${reviewerAudit}`);
                reviewerAudit = formattedValue.join("\n");
                break;
            case "question6AnsContent":
                formattedValue.push("\n");
                formattedValue.push("5.  Are there any significant performance deficiences that need to be highlighted?");
                formattedValue.push(`\n`);
                formattedValue.push(`${reviewerAudit}`);
                reviewerAudit = formattedValue.join("\n");
                break;
            case "question5AnsContent":
                formattedValue.push("\n");
                formattedValue.push("Summary of Roles/Areas of Responsibility:");
                formattedValue.push(`\n`);
                formattedValue.push(`${reviewerAudit}`);
                reviewerAudit = formattedValue.join("\n");
                break;
            default:
                break;
        }
        return reviewerAudit;
        // if (label === "Submitted Date" || label === "updatedOn") {
        //   return moment(value).format("DD-MM-YYYY");
        // } else if (label === "reviewerAuditContent"
        //   || label === "question1AnsContent"
        //   || label === "question2AnsContent"
        //   || label === "question3AnsContent"
        //   || label === "question4AnsContent"
        //   || label === "question5AnsContent") {
        //   document.getElementById('dvContent').innerHTML = value;
        //   const reviewerAudit = document.getElementById('dvContent').innerText;
        //   return reviewerAudit;
        //   //return value.replaceAll("div","p");
        // } else {
        //   return value;
        // }
    }, []);

    const handleExport = async () => {
        try {
            const worksheet = workbook.addWorksheet(workSheetName);
            worksheet.columns = discusssionGuideReport;
            let columnHeaderRow = worksheet.getRow(1);
            columnHeaderRow.eachCell(function (cell) {
                cell.fill = { type: "pattern", pattern: 'solid', fgColor: { argb: "FFB9B9C2" } };
                cell.font = { name: "EYInterstate", size: "11" };
                cell.border = { right: { style: "medium", color: { argb: "FF000000" } } };
                cell.alignment = {
                    wrapText: true,
                    horizontal: "center",
                    vertical: "top",
                };
            });
            let rowObj = {};
            discusssionGuideReport.forEach((c) => {
                if (!exclusionList.includes(c.key)) {
                    if (c.key === "reviewerAuditContent" && !data.reviewerAuditContent) { }
                    else {
                        rowObj[c.key] = data[c.key] ? processData(data[c.key], c.key) : "--";
                    }
                }
            });
            let row = worksheet.addRow(rowObj);
            row.eachCell(function (cell) {
                cell.fill = { type: "pattern", pattern: 'solid', fgColor: { argb: "FFF2F2F2" } };
                cell.font = { name: "EYInterstate", size: "9" };
                cell.alignment = {
                    wrapText: true,
                    vertical: "top",
                };
                cell.border = {
                    left: { style: "medium", color: { argb: "FF000000" } },
                    top: { style: "medium", color: { argb: "FF000000" } },
                    right: { style: "medium", color: { argb: "FF000000" } },
                    bottom: { style: "medium", color: { argb: "FF000000" } },
                };
            });
            // write the content using writeBuffer
            const buf = await workbook.xlsx.writeBuffer();

            // download the processed file
            saveAs(new Blob([buf]), `FY${myContext.financialYear} LEAD Reviewer Year End Discussion Guide.xlsx`);
        } catch (error) {
            console.error('<<<ERRROR>>>', error);
            console.error('Something Went Wrong', error.message);
        } finally {
            // removing worksheet's instance to create new one
            workbook.removeWorksheet(workSheetName);
        }
        // const idtoken = await authProvider.acquireTokenSilent({
        //   scopes: GraphExplorerscopes,
        // });
        // const token = idtoken.accessToken;
        // dispatch({
        //   type: "GET_SELF_DG_EXCEL_DOWNLOAD",
        //   payload: {
        //     ad_token: token,
        //     financialYear: myContext.financialYear
        //   },
        // });
    }

    return (
        <>
            <div onClick={handleExport} className={"btn" + (!true ? " opacityReduced " : "")}
                style={{
                    cursor: `${true ? " pointer" : "auto"}`,
                    opacity: `${true ? 1 : 0.5}`,
                }}>
                <i className="eyaa-share" style={{ marginRight: "5px" }} />
                Export
            </div>
        </>
    )
};

export default DownloadTemplateLRDG;