import * as myConsts from "../../constants/constants";
const NoAccess = (props) => {
  return (
    <div className="error-fallback">
      <div className="msg">You dont have access to this page. Please reach out to lead4d.support@ey.com</div>
      <div className="btn-wrapper">
        <div
          className='btn'
          onClick={() => props.history.push(myConsts.routeHome)}>
          Go to Home
        </div>
      </div>
    </div>
  );
};

export default NoAccess;
