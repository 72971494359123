import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import {ifNonEmptyInput} from '../../../../helpers/textValidator';

const useStyles = makeStyles((theme) => ({
  root1: {
    "& .MuiOutlinedInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
      width: "850px",
    },
    "& .MuiOutlinedInput": {
      width: "850px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiOutlinedInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiOutlinedInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
}));

const CardComponent = (props) => {
  const { editMode = false } = props;
  const classes = useStyles();
  const [editable, setEditable] = useState(false);
  const [stmnt, setstmnt] = useState("");
  const allSectionDataFetch = useSelector(
    (state) => state.getAssessmentSectionList
  );
  const assessmentSectionList = allSectionDataFetch?allSectionDataFetch.data?allSectionDataFetch.data.data?allSectionDataFetch.data.data.assessmentDetails:[]:[]:[]

  const getColor = (type) => {
    /*if (type && type === "Table stakes") {
      return "#F08E8E";
    } else if (type === "Leading") {
      return "#B1DE76";
    } else if (type === "Transformative") {
      return "#94DFE9";
    }
    return "#f9f9fb";*/
    const sectionData = assessmentSectionList.find(
      (list) => list.name === type
    );
    return sectionData ? sectionData.color : "#f9f9fb";
  };

  useEffect(() => {
    if (props.cardEditIndex === props.id) setEditable(true);
    else setEditable(false);

    // eslint-disable-next-line
  }, [props.cardEditIndex]);

  useEffect(() => {
    if (editMode) setstmnt(props.data.impactActions);
  }, [props.data, editMode]);


 const validateInput = (value) => {
   let text = ifNonEmptyInput(value) ? value.trim() : "";
   setstmnt(text);
 }

  if (!editable)
    return (
      <>
        {!props.addView ? (
          <div
            className="card"
            style={{
              borderLeftColor: props.type ? getColor(props.type) : "black",
            }}
          >
            {/*<i
              className=" icon-drag"
              style={{ margin: "0px 4px", fontSize: "15px" }}
            />*/}
            <input
              type="checkbox"
              style={{ margin: "0px 8px", cursor: !props.disableFields || !props.disableCheckbox ? "pointer" : "default" }}
              checked={props.selected}
              onChange={() => props.onStatementSelect(props.id)}
              disabled={props.disableFields || props.disableCheckbox}
            />
            <SimpleBar style={{ width: "85%", overflow:"auto", maxHeight:"6vh"}}>{props.data}</SimpleBar>
            <div className="rightSection">
              <i
                className={classNames("eyaa-file-001", {
                  "disable-btn": props.disableFields || props.disabledEditBtn,
                })}
                onClick={(e) => props.onEditBtnClicked(props.id)}
                style={{
                  margin: "0px 4px",
                  fontSize: "14px",
                  cursor: "pointer",
                  marginTop: "2px",
                }}
              />
              {/*<i
                className=" icon-trash"
                onClick={(e) => props.setShowDeleteModal(e)}
                style={{
                  margin: "0px 4px",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
              />*
              <i
                onClick={(e) => props.moveTo(e)}
                className=" icon-more-v"
                style={{
                  margin: "0px 4px",
                  fontSize: "12px",
                  cursor: "pointer",
                  marginLeft: "0px",
                }}
              />*/}
              <i
                className={classNames("icon-drag", {
                  "disable-btn": props.disableFields || props.disableDragIcon,
                })}
                style={{ margin: "0px 4px", fontSize: "15px" }}
              />
            </div>
          </div>
        ) : (
          //add
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // border: "1px solid #BDBDC3",
              // backgroundColor: " rgb(246, 246, 250)",
            }}
          >
            <div
              className="card add-card"
              style={{
                // borderColor: props.type ? "rgb(246, 246, 250)" : "black",
                border: " 1px solid #bdbdc3",
                backgroundColor: "#fff",
              }}
            >
              {/*<i
                className=" icon-drag"
                style={{ margin: "0px 4px", fontSize: "15px" }}
              />*/}
              <div style={{ width: "85%" }}>
                <TextField
                  style={{
                    backgroundColor: "#fff",
                    border: "none",
                    outline: "none",
                  }}
                  id="filled-basic"
                  variant="outlined"
                  className={classes.root1}
                  required={true}
                  value={stmnt}
                  placeholder={"Enter Text here"}
                  disableUnderline={true}
                  multiline
                  maxRows={2}
                  onChange={
                    (e) => {
                      if (e.target.value.length <= 1000)
                        setstmnt(e.target.value);
                    }
                    // props.textOnChange(e.target.value, "globalMetricName")
                  }
                  onBlur={() => validateInput(stmnt)}
                />
              </div>
            </div>
            <div className="addstmt-trash">
              <p>{stmnt.length}/1000 Characters</p>
              <i
                className={classNames("eyaa-web-071", {
                  "disable-btn": (!editMode && stmnt.length === 0) || (editMode && stmnt === props.data.impactActions),
                })}
                style={{ margin: "0px 10px", fontSize: "13px" }}
                onClick={() => {
                  if (stmnt.length !== 0 && stmnt !== props.data.impactActions) props.addImpactStatement(stmnt);
                }}
              />
              <i
                className={classNames("icon-close-filled icon", {
                  "disable-btn": false,
                })}
                onClick={(e) => {
                   props.seTaddView(false);
                }}
                style={{
                  fontSize: "12px",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        )}
      </>
    );
  //edit
  else
    return (
      <div
        className="card"
        style={{
          borderLeftColor: props.type ? getColor(props.type) : "black",
        }}
      >
        <div style={{ width: "85%" }}>
          <TextField
            id="filled-basic"
            variant="outlined"
            className={classes.root1}
            required={true}
            value={props.data}
            disableUnderline={true}
            multiline
            maxRows={2}
            onChange={(e) => {
              props.StmtTextOnChange(e.target.value);
            }}
          />
        </div>
      </div>
    );
};
export default withRouter(CardComponent);
