import React, { useState, useContext , useEffect} from "react";
import SideMenu from "../SideMenu/SideMenu";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import AdminGeneralParam from "./AdminGeneralParam/AdminGeneralParam";
import "./Admin.scss";
import * as myConsts from "../../constants/constants";
import AppContext from "../../utils/appContext";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";


const GeneralParameters = (props) => {
  const [crumbs, setBreadcrumbs] = useState([
    { name: "Admin", path: myConsts.routeAdmin },
  ]);
  const [isApprovalPreview, setIsApprovalPreview] = useState(false);
  const [isReviewer, setIsReviewer] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  const [dataStatus, setDataStatus] = useState("");
  const myContext = useContext(AppContext);
  const dispatch = useDispatch();

  const adminApprovalData = useSelector((state) => state.approvalData);
  const adminAppState = useSelector((state) => state.adminAppState);

  useEffect(() => {
     const isPreview = _.get(adminApprovalData,"isPreview", false);
     setIsReviewer( _.get(adminApprovalData, "previewParams.isReviewer", false))
     setIsApprovalPreview(isPreview);
     if(isPreview) {
      setBreadcrumbs([
        { name: "Admin", path: myConsts.routeAdmin },
        { name: "Approvals", path: myConsts.routeAdminApprovals },
      ]);
     } else {
      setBreadcrumbs([
        { name: "Admin", path: myConsts.routeAdmin },
      ]);
     }
  }, [adminApprovalData])

  useEffect(() => {
    sessionStorage.setItem("currentPageName", "General Parameters");
    return () => {
      sessionStorage.removeItem("currentPageName");
    }
  }, [])

  useEffect(() => {
    setEnableButton(_.get(adminAppState,"enableSubmit", false));
    setDataStatus(_.get(adminAppState,"status", ""));
  }, [adminAppState])
   
  const submitData = () => {
    if(enableButton) {
      dispatch({
        type: "SUBMIT_BUTTON_CLICKED",
        payload: {},
      });
    }
  }

  const reviewRespond = () => {
    dispatch({
      type: "RESPOND_BUTTON_CLICKED",
      payload: {},
    });
  }


  const checkOutsideClick = (fn, params1) => {
    if (myContext.changeTriggered) {
      dispatch({
        type: "OUTSIDE_CLICK_ADMIN",
        payload: {
          flag: false,
          callback: { fn: fn, params: params1 },
        },
      });
      myContext.setShowPopupAdmin(true);
    } else {
      fn && fn(params1);
    }
  };

  const routeToAdmin = () => {
    if(isApprovalPreview) {
      props.history.push(myConsts.routeAdminApprovals);
    } else {
      props.history.push(myConsts.routeAdmin);
    }
  };

  return (
    <>
      <div className="admin-container general-parameters">
        <div className="admin-info-main-sidemenu">
          <SideMenu
            minimized={true}
            isminimizedMainMenu={true}
            isMainMenu={true}
            sideMenuItemSelect="Admin"
          />
        </div>
        <div className="admin-content">
          <div className="second-header admin-crumbs">
            <i
              className="icon-arrow-left"
              onClick={(e) => {
                e.preventDefault();
                checkOutsideClick(routeToAdmin, myConsts.routeAdminGeneralParameters);
              }}
            />
            <div className="admin-crumb-txt">
              <Breadcrumb
                crumbs={crumbs}
                isAdmin={true}
                isInnerAdmin={true}
                {...props}
              />
              { !isApprovalPreview ? <h4>General Parameters</h4> : <h4>{_.get(adminApprovalData,"previewParams.displayType", "")}</h4>}
            </div>
            { !isApprovalPreview ? (
                <div className="btn-wrapper-top">
                  {!isApprovalPreview && (
                    <div className="status">
                      {" "}
                      {dataStatus}
                      {" "}
                    </div>
                  )}
                  {/* <div
                    className={"btn yellow-button" + (!enableButton ? " opacityReduced " : "")}
                    onClick={() => {
                        submitData();
                    }}>
                    <i
                      className="icon-verified-outline "
                      style={{ marginRight: "5px" }}
                    />
                    Submit
                  </div> */}
                </div>
              ): !isReviewer ? <div className="btn-wrapper-top">
                  <div
                    className={"btn yellow-button" + (false ? " opacityReduced " : "")}
                    onClick={() => {
                        reviewRespond();
                    }}>
                    <i
                      className="icon-verified-outline "
                      style={{ marginRight: "5px" }}
                    />
                    Respond
                  </div>
                </div>: null}
          </div>
          <AdminGeneralParam history={props.history}/>
        </div>
      </div>
    </>
  );
};
export default GeneralParameters;
