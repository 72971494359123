import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import SideMenu from "../SideMenu/SideMenu";
import "simplebar/dist/simplebar.min.css";
import "./DashBoard.scss";
import SimpleBar from "simplebar-react";
import ProgressChartCard from "./DashBoardCards/ProgressChartCard";
import DashBoardFilters from "./DashBoardFilters";
import { authProvider, APIscope } from "./../../Authentication/authProvider";
import AppContext from "../../utils/appContext";
import * as myConsts from "../../constants/constants";
import Loader from "../Loader/Loader";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import BarChartCard from "./DashBoardCards/BarChartCard";
import DoubleBarChartCard from "./DashBoardCards/DoubleBarChartCard";
import moment from "moment";

const screenSize = "(min-width: 1300px)";

/* This is the main component for dashboard page where we are having filters, chiclets,
Qualitative and Quantitative Pillar charts. User can visit this page through home page or directly 
enter the url (<Domain_name>/Dashboard) in the browser. User can't access this page until 
assesment has submitted */

const DashBoard = (props) => {
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  const fy = myContext.financialYear ? `${myContext.financialYear}`.substring(2, 4) : '22';
  const pillarChartData = useSelector((state) => state.userAssesmentScore);
  const pillarFilters = useSelector(
    (state) => state.dashboardPillarFilters.data
  );
  const pillarGroupScore = useSelector((state) => state.pillarGroupScore);
  const userProfile = useSelector((state) => state.userProfile);
  const quantitativeScore = useSelector((state) => state.quantitativeScore);
  const assessmentMetaData = useSelector(
    (state) => state.getAssesmentMetaData.data
  );
  const userAssessmentStatus = useSelector(
    (state) => state.userAssessmentStatus.data
  );
  const crumbs = [{ name: "Dashboard", path: myConsts.routeDashBoard }];

  const userProfileDataProps = useMemo(
    () => [
      "region",
      "tenor",
      "primarySector",
      "secondarySectorSpecialization",
      "locationCity",
    ],
    []
  );

  const getCurrentUser = useCallback(() => {
    const ppmdID =
      myContext.activePPMDuserId || sessionStorage.activePPMDuserId;
    const userId = myContext.userId || sessionStorage.userId;
    return ppmdID || userId;
  }, [myContext]);

  const getPillarGroupScore = useCallback(
    (filters) => {
      dispatch({
        type: "SET_DASHBOARD_PILLAR_FILTERS",
        payload: filters,
      });
      (async function () {
        try {
          const token = await authProvider.acquireTokenSilent({
            scopes: APIscope,
          });
          const idToken = token.accessToken;
          dispatch({
            type: "GET_PILLAR_GROUP_SCORE",
            payload: {
              token: idToken,
              financialYear: myContext.financialYear,
              timePeriod: myContext.currentPeriod,
              filters: filters,
              rankDesc: userProfile.data[0].rankDesc,
            },
          });
        } catch (e) {
          console.error(e);
        }
      })();
    },
    [userProfile, myContext, dispatch]
  );

  const setFilterData = useCallback(() => {
    const copyPillarFilters = JSON.parse(JSON.stringify(pillarFilters));

    userProfileDataProps.forEach((data, index) => {
      if (
        copyPillarFilters[index + 1].paramName === "tenor" &&
        userProfile.data[0].role === "Executive Director"
      ) {
        copyPillarFilters[index + 1].subText = "";
      } else {
        copyPillarFilters[index + 1].subText = userProfile.data[0][data];
      }
    });
    dispatch({
      type: "SET_DASHBOARD_PILLAR_FILTERS",
      payload: copyPillarFilters,
    });
    getPillarGroupScore(copyPillarFilters);
  }, [
    pillarFilters,
    userProfileDataProps,
    dispatch,
    userProfile,
    getPillarGroupScore,
  ]);

  const getPillarScore = () => {
    (async function () {
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "GET_USER_ASSESSMENT_SCORE",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
          },
        });
        if (userProfile.data.length === undefined) {
          dispatch({
            type: "GET_USER_PROFILE_BY_ID",
            payload: {
              tokens: idToken,
              userId: getCurrentUser(),
              financialYear: myContext.financialYear,
            },
          });
        }
        dispatch({
          type: "GET_QUANTITATIVE_SCORE",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
            focus: false,
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();
  };

  const removeFilter = useCallback(
    (filters, index) => {
      let copyFilterOptions = [...filters];
      copyFilterOptions[index].isSelected = false;
      dispatch({
        type: "SET_DASHBOARD_PILLAR_FILTERS",
        payload: copyFilterOptions,
      });
      getPillarGroupScore(filters);
    },
    [dispatch, getPillarGroupScore]
  );

  const navigateToGoal = useCallback(
    (pillarId) => {
      props.history.push(myConsts.routeDashBoard + "/" + pillarId);
    },
    [props.history]
  );

  const getTenure = useCallback((tenure) => {
    if (tenure <= 5) {
      return "0-5 Years";
    } else if (tenure > 5 && tenure <= 10) {
      return "6-10 Years";
    } else if (tenure > 10) {
      return "11+";
    } else {
      return "1 Year";
    }
  }, []);

  const isHideCloseBtn = useCallback(() => {
    const selectedFilters = pillarFilters.filter(
      (filter) => filter.isSelected && filter.option !== "Select all"
    );
    return selectedFilters && selectedFilters.length === 1;
  }, [pillarFilters]);

  const isPeerGroupVisible = useCallback(() => {
    if (
      userAssessmentStatus?.data &&
      userAssessmentStatus.data.metaEndDate !== null
    ) {
      const endDate = userAssessmentStatus.data.metaEndDate;
      const currentDate = moment.utc(new Date()).format();

      return moment(currentDate).isSameOrAfter(endDate);
    } else return false;
  }, [assessmentMetaData, myContext]);

  const getPeerGroupAvg = useCallback(
    (pillarId, pillarScore) => {
      if (isPeerGroupVisible()) {
        const pillar =
          pillarGroupScore.data.length > 0 &&
          pillarGroupScore.data.find((pillar) => pillar.pillarId === pillarId);
        return pillar.groupScore ? pillar.groupScore : pillarScore;
      } else {
        return 0;
      }
    },
    [pillarGroupScore, isPeerGroupVisible]
  );

  const findRank = useCallback(
    (val) => {
      if (!val || !isPeerGroupVisible()) {
        return {
          level: "",
          position: "",
        };
      }
      const pos = [
        "bottom 10",
        "bottom 20",
        "bottom 30",
        "bottom 40",
        "bottom 50",
        "top 50",
        "top 40",
        "top 30",
        "top 20",
        "top 10",
      ];
      const rank = pos[val * 10 - 1].split(" ");
      return {
        level: parseInt(rank[1]) === 50 ? "mid" : rank[0],
        position: rank[1],
      };
    },
    [isPeerGroupVisible]
  );

  useEffect(() => {
    dispatch({
      type: "OUTSIDE_CLICK_RESET",
      payload: {},
    });
    dispatch({
      type: "RESET_MENU_DETAILS",
      payload: {},
    });
    getPillarScore();
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (
      pillarChartData.fetching ||
      pillarGroupScore.fetching ||
      quantitativeScore.fetching
    ) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [pillarChartData, pillarGroupScore, quantitativeScore]);

  useEffect(() => {
    if (userProfile.data.length > 0) {
      setFilterData();
    }
    // eslint-disable-next-line
  }, [userProfile]);

  useEffect(() => {
    if (
      userAssessmentStatus?.data &&
      !(
        userAssessmentStatus.data.status.toLowerCase() ===
          myConsts.statusSubmitted ||
        userAssessmentStatus.data.status.toLowerCase() ===
          myConsts.statusCompleted
      )
    ) {
      props.history.push(myConsts.routeHome);
    }
  }, [userAssessmentStatus, props.history]);

  return (
    <div className="dashBoard-info-main-container">
      {loader && <Loader />}
      <div className="dashBoard-info-main-sidemenu">
        <SideMenu
          minimized={true}
          isminimizedMainMenu={true}
          isMainMenu={true}
          sideMenuItemSelect="Home"
        />
      </div>
      <div className="dashBoard-info-container">
        <div
          className="second-header"
          style={{ paddingLeft: "20px", paddingRight: "20px" }}>
          <Breadcrumb crumbs={crumbs} {...props} />
          <div className="pillar-filters">
            <DashBoardFilters
              filterOptions={pillarFilters}
              onApplyFilter={(filters) => getPillarGroupScore(filters)}
            />
          </div>
        </div>

        <div>
          <SimpleBar
            style={{
              width: "100%",
              maxHeight: window.matchMedia(screenSize).matches
                ? "88vh"
                : "84vh",
              height: window.matchMedia(screenSize).matches ? "88vh" : "84vh",
            }}
            className="dashBoardContent">
            <div className="chicletsCotnainer">
              {pillarFilters.map((option, index) => (
                <React.Fragment key={index}>
                  {option.isSelected && index !== 0 && (
                    <div className="chiclet">
                      <span className="chiclet-option">
                        <span className="chiclet-name">{option.option}</span>
                        {option.paramName === "tenor" ? (
                          <span className="sub-text">
                            {getTenure(parseInt(option.subText))}
                          </span>
                        ) : (
                          <>
                            {option.subText && (
                              <span className="sub-text">
                                {option.subText === "NULL" ||
                                option.subText === "null"
                                  ? "All"
                                  : option.subText}
                              </span>
                            )}
                          </>
                        )}
                      </span>
                      {!option.isDisabled && !isHideCloseBtn() && (
                        <span
                          className="close-icon"
                          onClick={() => removeFilter(pillarFilters, index)}>
                          &times;
                        </span>
                      )}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
            {!loader && (
              <>
              {/* PPMD SCORING HIDDEN */}
                {/* <Grid container spacing={2} className="dashboardCharts">
                  <div className="dashboardHeader">Qualitative Pillars</div>
                  {pillarChartData.data &&
                    pillarChartData.data.length > 0 &&
                    pillarChartData.data.map((data, index) => (
                      <React.Fragment key={index}>
                        {data.type === "Qualitative" && (
                          <Grid item lg={3}>
                            <ProgressChartCard
                              pillarName={data.pillar}
                              pillarId={data.pillarId}
                              pillarScore={data.pillarScore}
                              peerGroupAvg={getPeerGroupAvg(
                                data.pillarId,
                                data.pillarScore
                              )}
                              onClick={(pillarId) => navigateToGoal(pillarId)}
                              peerGroupVisible={isPeerGroupVisible()}
                            />
                          </Grid>
                        )}
                      </React.Fragment>
                    ))}
                </Grid> */}
                <Grid container spacing={2} className="dashboardCharts" style={{marginBottom: '32px'}}>
                  <div className="dashboardHeader">Quantitative Pillars</div>
                  <Grid item lg={3}>
                    <DoubleBarChartCard
                      rank={
                        quantitativeScore.data && quantitativeScore.data[0]
                          ? findRank(
                              quantitativeScore.data[0].revenueGoalPercentile
                            )
                          : findRank("")
                      }
                      revenue={
                        quantitativeScore.data &&
                        quantitativeScore.data[0] &&
                        quantitativeScore.data[0].revenue
                      }
                      revenueGoal={
                        quantitativeScore.data &&
                        quantitativeScore.data[0] &&
                        quantitativeScore.data[0].revenueGoal
                      }
                      header="Revenue"
                      onClick={() =>
                        quantitativeScore.data &&
                        quantitativeScore.data[0] &&
                        quantitativeScore.data[0].revenue &&
                        quantitativeScore.data[0].revenueGoal &&
                        navigateToGoal("Revenue")
                      }
                    />
                  </Grid>
                  <Grid item lg={3}>
                    <BarChartCard
                      rank={
                        quantitativeScore.data && quantitativeScore.data[0]
                          ? findRank(
                              quantitativeScore.data[0]
                                .highImpactMarginPercentile
                            )
                          : findRank("")
                      }
                      percentage={
                        quantitativeScore.data &&
                        quantitativeScore.data[0] &&
                        quantitativeScore.data[0].highImpactMargin
                      }
                      percentageInfo={`FY${fy} Margin YTD`}
                      header="Margin"
                      onClick={() =>
                        ((quantitativeScore.data &&
                          quantitativeScore.data[0] &&
                          quantitativeScore.data[0]
                            .highImpactMarginPercentile) ||
                          quantitativeScore.data[0]?.highImpactMargin) &&
                        navigateToGoal("Margin")
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </SimpleBar>
        </div>
      </div>
    </div>
  );
};

export default DashBoard;
