import React, { useState, useEffect, useContext, useCallback } from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import cn from "classnames";
import { withRouter } from "react-router-dom";
import "./SideMenu.scss";
import new_ey_logo from "../Header/images/EYlogo.svg";
import { nonAdminMenu, adminMenu } from "../../constants/LandingPageConstants";
import AppContext from "../../utils/appContext";
import { useDispatch, useSelector } from "react-redux";
import * as myConsts from "../../constants/constants";
import { authProvider, APIscope } from "./../../Authentication/authProvider";
import { routes } from "../../NonAdminRoutes";
import { routeCompare } from "../../utils/utils";

const useStyles = makeStyles({
  drillDownMenu: {
    background: "#151517 !important",
  },
  gridContainerLeft: {
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    paddingLeft: 12,
    cursor: "pointer",
    opacity: 0.85,
    color: "#000",
    width: "100%",
    paddingTop: "3px",
    "&:hover": {
      background: "#151517",
      transition: "all 200ms cubic-bezier(0.05, 0.74, 0.27, 0.99)",
    },
  },
  minimizedGridContainerLeft: {
    alignItems: "center",
    justifyContent: "center",
    height: 40,
    cursor: "pointer",
    textAlign: "center",
    // marginTop: "10px",
    //opacity: 0.4,
    background: "#2E2E38",
    width: "100%",
    color: "#000",
    "&:hover": {
      background: "#151517",
      transition: "all 200ms cubic-bezier(0.05, 0.74, 0.27, 0.99)",
    },
  },
  selectedGridContainerLeft: {
    // borderTop: "1.1px solid rgba(196, 196, 205, 0.3)",
    // borderBottom: "1.1px solid rgba(196, 196, 205, 0.3)",
    // borderLeft: "3px solid #FFE600",
    // background: "#fafafc 0 0 no-repeat padding-box",
    background: "#ffe600",
    width: "100%",
    opacity: 1,
    "&:hover": {
      background: "#ffe600",
    },
  },
  selectedHoverGridContainerLeft: {
    // borderTop: "1.1px solid rgba(196, 196, 205, 0.3)",
    // borderBottom: "1.1px solid rgba(196, 196, 205, 0.3)",
    // borderLeft: "3px solid #FFE600",
    // background: "#fafafc 0 0 no-repeat padding-box",
    background: "#ffe600",
    opacity: 1,
    "&:hover": {
      background: "#ffe600",
    },
  },
  minimizedSelectedGridContainerLeft: {
    borderTop: "1.1px solid rgba(196, 196, 205, 0.3)",
    borderBottom: "1.1px solid rgba(196, 196, 205, 0.3)",
    borderLeft: "3px solid #FFE600",
    background: "#fafafc 0 0 no-repeat padding-box",
    opacity: 1,
  },
  minimizedSelectedGridContainerLeftMainMenu: {
    // borderTop: "1.1px solid rgba(196, 196, 205, 0.3)",
    // borderBottom: "1.1px solid rgba(196, 196, 205, 0.3)",
    // borderLeft: "3px solid #FFE600",
    // background: "#fafafc 0 0 no-repeat padding-box",
    background: "#ffe600",
    opacity: 1,
  },
  labelStyle: {
    marginLeft: 15,
    fontFamily: "EYInterstate-Regular",
    fontSize: 10,
    color: "#fff",
    fontWeight: 400,
    opacity: 0.8,
  },
  addNewClientStyle: {
    marginLeft: 23,
    marginTop: 7,
    fontFamily: "EYInterstate-Regular",
    fontWeight: 600,
    opacity: 0.8,
    fontSize: 12,
    cursor: "pointer",
  },
  addNewClientStyleDisabled: {
    marginLeft: 23,
    marginTop: 7,
    fontFamily: "EYInterstate-Regular",
    fontWeight: 600,
    opacity: 0.8,
    fontSize: 12,
    cursor: "disabled",
    color: "rgba(51, 51, 51, 0.3)",
  },
  dividerStyle: {
    backgroundColor: "rgba(196, 196, 205, 0.3)",
    width: "80%",
    marginRight: "auto",
    marginLeft: "auto",
  },
  selectedLabel: {
    color: "#000",
    opacity: "1",
  },
});

const sideBarHoverOnSessionClickStyle = {
  zIndex: 0,
};

const SideMenu = (props) => {
  const classes = useStyles();
  const {
    minimized,
    sideMenuItemSelect,
    sideZoom,
    isopenSession,
    isminimizedMainMenu,
    isMainMenu,
    isDrillDown,
  } = props;

  const [showSideBarHover, setShowSideBarHover] = useState(false);
  const [sideMenuItemsMin, setSideMenuItemsMin] = useState([]);
  const [sideMenuItemSelected, setSideMenuItemSelected] = useState("");

  const outsideClickCommonState = useSelector(
    (state) => state.outsideClickCommon.data
  );
  const assessmentMetaData = useSelector(
    (state) => state.getAssesmentMetaData.data
  );
  const userProfile = useSelector((state) => state.userProfile);
  const toggleSidebar = () => {
    setShowSideBarHover(!showSideBarHover);
  };
  const myContext = useContext(AppContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isminimizedMainMenu) {
      if (nonAdminMenu.length > 4) {
        // let menuList = isApprover?nonAdminMenu.splice(1,1):nonAdminMenu.splice(0,1);
      }
      // setSideMenuItemsMin(nonAdminMenu);
    }
    if (
      (!assessmentMetaData || !assessmentMetaData.data) &&
      !assessmentMetaData.fetching
    ) {
      getAssesmentMetaData();
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (
      (!assessmentMetaData || !assessmentMetaData.data) &&
      !assessmentMetaData.fetching
    ) {
      getAssesmentMetaData();
    }
    // eslint-disable-next-line
  }, [myContext.financialYear]);

  const activePPMDuserId =
    myContext.activePPMDuserId || sessionStorage.activePPMDuserId;
  useEffect(() => {
    const userPermission = sessionStorage.getItem('userPermission') ? JSON.parse(sessionStorage.getItem('userPermission')) : null;
    if (userPermission && userPermission?.isAdmin) {
      if (
        userPermission &&
        userPermission.role === myConsts.rolesAdmin
      ) {
        setSideMenuItemsMin([adminMenu]);
      } else {
        setSideMenuItemsMin([...nonAdminMenu, adminMenu]);
      }
    } else {
      const path = props.history.location.pathname.toLowerCase().split("/");
      setSideMenuItemsMin(nonAdminMenu);
      if (
        path.indexOf("admin") !== -1 &&
        userPermission &&
        !userPermission.isAdmin
      ) {
        props.history.push(myConsts.routeHome);
      }
    }
  }, [props.history]);

  useEffect(() => {
    if (assessmentMetaData && assessmentMetaData.data) {
      const data = assessmentMetaData.data;
      const activePhase =
        data.activePhaseYear == myContext.financialYear
          ? data.activePhase
          : null;
      myContext.setActiveFinancialYear(data.activePhaseYear);
      sessionStorage.setItem("activefinancialYear", data.activePhaseYear);
      if (data.activePhaseYear == null) {
        myContext.setlastActiveFinancialYear(data.lastActivePhaseYear);
        sessionStorage.setItem(
          "lastactivefinancialYear",
          data.lastActivePhaseYear
        );
      }
      if (sessionStorage.getItem("isValidUser") === null) {
        sessionStorage.setItem("isValidUser", data.validUser);
        myContext.setIsValidUser(data.validUser);
        if (!data.validUser) {
          props.history.push(myConsts.routeNotValidUser);
        }
      }
      if (
        activePhase === null ||
        (data.assessmentMetaData &&
          data.assessmentMetaData.leadReviewer_CompletionDate &&
          new Date() >
          new Date(data.assessmentMetaData.leadReviewer_CompletionDate)) ||
        (myContext.currentPeriod !== null &&
          activePhase !== null &&
          activePhase !== myContext.currentPeriod)
      ) {
        sessionStorage.setItem("activePhase", activePhase);
        myContext.setActivePhase(activePhase);
        sessionStorage.setItem("formsInActive", true);
        myContext.setFormsInActive(true);
        if (
          activePhase === null ||
          (data.assessmentMetaData &&
            data.assessmentMetaData.leadReviewer_CompletionDate &&
            new Date() >
            new Date(data.assessmentMetaData.leadReviewer_CompletionDate))
        ) {
          dispatch({
            type: "GET_TIME_PERIOD_STATUS",
            payload: { financialYear: myContext.financialYear },
          });
        }
      } else {
        if (sessionStorage.getItem("activePeriod") === null) {
          sessionStorage.setItem("activePeriod", activePhase);
        }

        sessionStorage.setItem("activePhase", activePhase);
        myContext.setActivePhase(activePhase);
        if (
          sessionStorage.getItem("currentPeriod") === null ||
          !myContext.currentPeriod
        ) {
          sessionStorage.setItem("currentPeriod", activePhase);
          myContext.setCurrentPeriod(activePhase);
        }

        sessionStorage.setItem("formsInActive", false);
        myContext.setFormsInActive(false);
        //setLoader(false);
        myContext.setActivePeriod(activePhase);
      }
      myContext.setUserRole(data.userRole); //data.userRole,LeadReviewer-PPMD
    }
  }, [assessmentMetaData]);
  useEffect(() => {
    if (userProfile.data.length === undefined) {
      dispatch({
        type: "GET_USER_PROFILE_BY_ID",
        payload: {
          userId: myContext.userId || sessionStorage.userId,
          financialYear: myContext.financialYear,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const currentPath = props.history.location.pathname.split("/");
    const path = props.history?.location?.pathname;
    const allowAccess = routes.filter(route => (route.isAdmin || false)).findIndex((route) => {
      return routeCompare(route.path, path);
    });
    if (
      allowAccess === -1 &&
      currentPath.indexOf("Admin") === -1 &&
      userProfile.data &&
      userProfile.data.length > 0 &&
      userProfile.data[0].role === myConsts.rolesAdmin
    ) {
      props.history.push(myConsts.routeAdmin);
    }
  }, [props.history, userProfile]);

  const getAssesmentMetaData = useCallback(() => {
    (async function () {
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "GET_ASSESSMENT_META_DATA",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            userId: myContext.userId || sessionStorage.userId,
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, myContext]);

  const sideMenuItemClickFn = (val) => {
    const selectedMenu = sideMenuItemsMin.find((x) => x.id === val);
    props.history.push(selectedMenu.route);
  };

  useEffect(() => {
    if (sideMenuItemSelect)
      setSideMenuItemSelected(
        sideMenuItemsMin.find((x) => x.label === sideMenuItemSelect)?.id
      );
  }, [sideMenuItemSelect, sideMenuItemsMin]);

  const checkOutsideClick = (fn, params) => {
    if (props.history.location.pathname === "/BookOfBusiness") {
      if (myContext.bobChangeTriggered) {
        dispatch({
          type: "OUTSIDE_CLICK_BOB",
          payload: {
            flag: false,
            callback: { fn: fn, params },
          },
        });
        myContext.setShowAlertPopupBob(true);
      } else {
        fn && fn(params);
      }
    } else if (props.history.location.pathname === "/Admin/GeneralParameters") {
      if (myContext.changeTriggered) {
        dispatch({
          type: "OUTSIDE_CLICK_ADMIN",
          payload: {
            flag: false,
            callback: { fn: fn, params },
          },
        });
        myContext.setShowPopupAdmin(true);
      } else {
        fn && fn(params);
      }
    } else if (props.history.location.pathname === "/Admin/Assessment") {
      if (
        outsideClickCommonState &&
        outsideClickCommonState.feature !== "" &&
        outsideClickCommonState.changesDone
      ) {
        dispatch({
          type: "OUTSIDE_CLICK_COMMON_OPEN_MODEL",
          payload: {
            callback: { fn: fn, params: params },
          },
        });
      } else {
        fn && fn(params);
      }
    } else if (
      props.history.location.pathname === "/Admin/EmailConfiguration"
    ) {
      if (
        outsideClickCommonState &&
        outsideClickCommonState.feature !== "" &&
        outsideClickCommonState.changesDone
      ) {
        dispatch({
          type: "OUTSIDE_CLICK_COMMON_OPEN_MODEL",
          payload: {
            callback: { fn: fn, params: params },
          },
        });
      } else {
        fn && fn(params);
      }
    } else {
      if (myContext.isAutoSave) {
        dispatch({
          type: "OUTSIDE_CLICK",
          payload: { flag: false, callback: { fn: fn, params: params } },
        });
        myContext.setOpenSavePopup(true);
      } else {
        fn && fn(params);
      }
    }
  };

  return (
    <>
      {minimized ? (
        showSideBarHover ? (
          //hover menu design
          <div
            className={
              isMainMenu
                ? "main-menu hover-container-sidebar-style"
                : "hover-container-sidebar-style"
            }
            onMouseLeave={toggleSidebar}
            style={isopenSession ? sideBarHoverOnSessionClickStyle : null}>
            <div className="header-left">
              <div className="icon-hamburger"></div>
              <img src={new_ey_logo} alt="logo" className="new-logo" />
              <div className="sub-title">
                {/* <span className="headerText">LEAD 4D</span> */}
                {/* <img
                src={polaris}
                alt=""
                style={{ width: "68px", height: "auto" }}
              /> */}
              </div>
            </div>
            {isMainMenu &&
              !isDrillDown &&
              sideMenuItemsMin.map((sideMenuItem, key) => {
                return (
                  <Grid
                    container
                    key={sideMenuItem.id}
                    onClick={
                      /*!isApprover|| (isApprover && key === 0) false*/ true
                        ? () =>
                          checkOutsideClick(
                            sideMenuItemClickFn,
                            sideMenuItem.id
                          )
                        : ""
                    }
                    className={
                      sideMenuItemSelected === sideMenuItem.id
                        ? cn(
                          classes.gridContainerLeft,
                          classes.selectedHoverGridContainerLeft
                        )
                        : classes.gridContainerLeft
                    }
                    style={
                      /* isApprover && key > 0 */ false
                        ? {
                          opacity: 0.5,
                          cursor: "default",
                        }
                        : {}
                    }>
                    <Grid item xs={1}>
                      <span
                        className={cn(
                          sideMenuItem.icon,
                          "leftMenuIconStyle",
                          sideMenuItemSelected === sideMenuItem.id
                            ? "selectedIcon"
                            : ""
                        )}></span>
                    </Grid>
                    <Grid item xs={11}>
                      <Typography
                        className={
                          sideMenuItemSelected === sideMenuItem.id
                            ? cn(classes.labelStyle, classes.selectedLabel)
                            : classes.labelStyle
                        }>
                        {sideMenuItem.label}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
          </div>
        ) : (
          //minimized menu design
          <div
            className={
              isMainMenu
                ? "minimized-side-menu-style mainMenu"
                : "minimized-side-menu-style"
            }
            onMouseEnter={toggleSidebar}
            style={sideZoom ? { backgroundColor: "#f5f5fa" } : null}>
            <div className="new-logo">
              {" "}
              <img src={new_ey_logo} alt="logo" />
            </div>
            {sideMenuItemsMin &&
              sideMenuItemsMin.length > 0 &&
              sideMenuItemsMin.map((sideMenuMinItem, key) => (
                <Grid
                  container
                  key={sideMenuMinItem.id}
                  onClick={() =>
                    checkOutsideClick(sideMenuItemClickFn, sideMenuMinItem.id)
                  }
                  className={
                    sideMenuItemSelected === sideMenuMinItem.id
                      ? !isMainMenu
                        ? cn(
                          classes.minimizedGridContainerLeft,
                          classes.minimizedSelectedGridContainerLeft
                        )
                        : cn(
                          classes.minimizedGridContainerLeft,
                          classes.minimizedSelectedGridContainerLeftMainMenu,
                          sideMenuMinItem.label === "Reports"
                            ? "disabledIcon"
                            : ""
                        )
                      : cn(
                        classes.minimizedGridContainerLeft,
                        "fifth",
                        sideMenuMinItem.label === "Reports"
                          ? "disabledIcon"
                          : "",
                        sideMenuMinItem.mainMenu
                          ? cn(classes.mainMenu, "")
                          : classes.subMenu,
                        sideMenuMinItem.subMenu ? classes.drillDownMenu : ""
                      )
                  }
                  style={
                    isminimizedMainMenu
                      ? /* isApprover && key > 0 */ false
                        ? {
                          opacity: 0.5,
                          cursor: "default",
                        }
                        : { opacity: 1 }
                      : {
                        display: "flex",
                        flexDirection: "column",
                      }
                  }>
                  <span
                    className={cn(
                      sideMenuMinItem.icon,
                      "leftMenuIconStyle",
                      sideMenuItemSelected === sideMenuMinItem.id
                        ? "selectedIcon"
                        : ""
                    )}></span>
                  {isMainMenu ? (
                    <div
                      className={
                        sideMenuItemSelected === sideMenuMinItem.id &&
                          sideMenuMinItem.label !== "Reports"
                          ? "activeTitle sideMenutitle"
                          : "sideMenutitle"
                      }>
                      {/* {sideMenuMinItem.label} */}
                    </div>
                  ) : (
                    ""
                  )}
                  {isminimizedMainMenu || isDrillDown ? null : (
                    <label className="lbl-menu">
                      {/* {sideMenuMinItem.label} */}
                    </label>
                  )}
                </Grid>
              ))}
          </div>
        )
      ) : null}
    </>
  );
};

export default withRouter(SideMenu);
