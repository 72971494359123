import React from "react";
import { Modal } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import "./DelegatesPopup.scss";

const AlertSubmitPopup = (props) => {
  const closePopup = () => {
    props.cancelAlertSubmitPopup();
  };
  const savePopup = () => {
    props.submitPopupChanges();
  };
  const simpleClosePopup = () => {
    props.simpleClosePopup();
  };

  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.showAlertSubmitPopup}
        onClose={(e) => closePopup()}
        hideBackdrop={false}
        disableEscapeKeyDown={true}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
        <div
          className={
            "delegatePopup approvalPopup " + (props.admin ? " admin " : "  ")
          }>
          <div
            className={
              "delegateHeader " + (props.delete ? " deleteHeader " : "  ")
            }>
            <div>{props.header || "Alert"}</div>
            <i
              className="icon-close-filled closeIcon"
              onClick={(e) => simpleClosePopup()}
            />
          </div>
          <div
            className="delegateContent"
            style={{ marginTop: "10px", marginBottom: "0px" }}>
            {props.message && (<div className="delegateText">{props.message}</div>)}
            {props.description && (
              <div className="delegateText">{props.description}</div>
            )}
            {props && props.bodyText && (
              <div
                className="delegateText"
                style={{ fontFamily: "EYInterState-Regular, sans-serif" }}>
                {props.bodyText}
              </div>
            )}
          </div>
          {!props.onlyAlert && (
            <div className="okContainer">
              {props.header !== "Session Expire Warning" && (
                <div className="cancel" onClick={() => closePopup()}>
                  {props.cancelBtnTxt || "Cancel"}
                </div>
              )}
              <div className="save" onClick={() => savePopup()}>
                {props.submitBtnTxt || "Submit"}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};
export default withRouter(AlertSubmitPopup);
