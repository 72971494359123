import "./FinalizationForm.scss";
// import React from "react";
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  makeStyles,
  TextField,
  Snackbar  
} from "@material-ui/core";
import { Alert, AlertTitle } from '@material-ui/lab';
import AppContext from "../../../utils/appContext";
import DropdownComponent from "./DropdownComponent/DropdownComponent";
import AlertSubmitPopup from "../../Common/AlertSubmitPopup";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { toast, Flip } from "react-toastify";
import { authProvider, APIscope } from "./../../../Authentication/authProvider";
import "react-toastify/dist/ReactToastify.css";
import SimpleBar from "simplebar-react";
import _ from "lodash";
import * as myConsts from "../../../constants/constants";
import FocusSummaryView from "./FocusSummaryView";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
//import { placeholders } from './QuillEditor/constant';
import "./QuillEditor/QuillOption3.scss";
import ReactHtmlParser from "react-html-parser";
import quillBulletIcon from '../../../assets/images/QuillBulletIcon.png';

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiPaper-root .MuiAlert-root": {
      fontSize: "1.25rem"
    }
  }
}));

const FinalizationFormSelf = (props) => {
  const { isFormPreview,
    fieldValues,
    handleTextChange,
    selectOnChange,
    errorMessages,
    setErrorMessages,
    mandatoryFields,
    summaryData,
    assessmentObj,
    allOptionList,
    anyFocusedStmtSelected,
  } = props;
  const myContext = useContext(AppContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  //const [loader, setLoader] = useState(false);  
  const [showAlertSavePopup, setShowAlertSavePopup] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSubmissionConfirmation, setShowSubmissionConfirmation] = useState(false);
  const excludedList = ["fyYear", "period", "active"];
  const discussionGuideStatuses = {
    NotStarted: "Not Started",
    InProgress: "In Progress",
    Submitted: "Submitted"
  };
  const modules = {
    toolbar: [
      //[{ 'header': [1, 2, false] }],
      //['bold', 'italic', 'underline','strike', 'blockquote'],
      //[{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'list': 'bullet' }],
      //['link', 'image'],
      //['clean']
    ]
  };  

  const characterLimit = 350;
  const question1AnsContentRef = React.useRef();
  const question2AnsContentRef = React.useRef();
  const revenueMarginContentRef = React.useRef();
  const salesAndPipelineContentRef = React.useRef();
  const qualityContentRef = React.useRef();
  const ecsContentRef = React.useRef();
  const peopleAndTeamingContentRef = React.useRef();
  const distinguishPerformanceContentRef = React.useRef();
  const developmentPointsContentRef = React.useRef();
  const potentialAndAspirationsContentRef = React.useRef();

  // const checkCharacterCount = (event, fieldName) => {
  //   debugger;
  //   //const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
  //   // if (fieldValues[fieldName] && fieldValues[fieldName].length > characterLimit && event.key !== 'Backspace') {
  //   //   event.preventDefault();
  //   // }
  //   const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
  //   if (unprivilegedEditor.getLength() > 280 && event.key !== 'Backspace')
  //     event.preventDefault();
  // };
  const checkCharacterCount = (event, ref) => {
    //debugger;
    const unprivilegedEditor = ref.current.unprivilegedEditor;
    // console.log(event.key);
    // // Detecting Ctrl
    let key = event.which || event.keyCode; // Detecting keyCode
    let ctrl = event.ctrlKey ? event.ctrlKey : ((key === 17)
      ? true : false);

    // // If key pressed is V and if ctrl is true.
    // if (key == 86 && ctrl) {
    //   // print in console.
    //   console.log("Ctrl+V is pressed. "+ unprivilegedEditor.getText().length);
    // }
    if (unprivilegedEditor.getLength() > characterLimit && (event.key !== 'Backspace' || (key == 86 && ctrl))) {
      //console.log("inside - " + event.key);
      event.preventDefault();
    }
  };
  const handleEditorChange = (value, delta, source, editor, fieldName) => {
    //debugger;
    let plainText = editor.getText();
    const characterCount = editor.getLength() - 1;
    if (plainText === "\n") {
      value = ""
    }
    // if (characterCount > characterLimit) {
    //   plainText = plainText.substring(0, characterLimit);
    //   //value = value.substring(0, characterLimit);
    // }
    // alert(editor.getText())
    // alert(editor.getHTML())
    //let plainText = value.replaceAll('&lt;', '{{').replaceAll('&gt;', '}}').replace(/({{([^>]+)}})/ig, 'R').replace(/(<([^>]+)>)/ig, '');
    let obj = {
      target: {
        name: `${fieldName}Content`,
        value: value,
        namePlainText: `${fieldName}PlainText`,
        plainText: plainText === "\n" ? "" : plainText
      }
    }

    handleTextChange(obj);
  }

  const cancelConfirmation = () => {
    props.history.push(myConsts.routeHome);
  };

  const submitClicked = () => {
    if (checkifAllMandatoryFieldsFilled() &&
      fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted) {
      setShowSubmissionConfirmation(true);
    } else if (!checkifAllMandatoryFieldsFilled() &&
      fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted) {
      handleErrors();
    }
  };

  const handleErrors = () => {
    //debugger;
    let errorMsgs = JSON.parse(JSON.stringify(errorMessages));
    mandatoryFields.forEach((value) => {
      if (fieldValues[value.key] === "" || fieldValues[value.key] === null) {
        errorMsgs[value.key] = value.name;
      }
    });

    //setErrors(errorMsgs);
    setErrorMessages(errorMsgs);
    setShowErrorAlert(true);
  };

  const handleErrorAlertClose = () => {
    setShowErrorAlert(false);
  }

  const saveBtnClick = () => {
    //debugger;
    // if (checkifAllMandatoryFieldsFilled() &&
    //   fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted) {
    if (fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted) {
      //debugger;
      let fv = JSON.parse(JSON.stringify(fieldValues));
      //document.getElementById('dvContent').innerHTML = reviewerAuditContent;
      //const reviewerAudit = document.getElementById('dvContent').innerText;
      fv.status = discussionGuideStatuses.InProgress;      
      dispatch({
        type: "ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE",
        payload: fv,
      });
      props.history.push({ pathname: myConsts.routeHome });
    }
  };

  const cancelAlertSubmitPopup = () => {
    props.history.push(myConsts.routeHome);
  };
  const cancelClick = () => {
    if (checkifAnyFieldFilled() &&
      fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted) {
      setShowAlertSavePopup(true);
    } else {
      props.history.push(myConsts.routeHome);
    }
  };

  const checkifAllMandatoryFieldsFilled = () => {
    //debugger;
    let flag = true;
    mandatoryFields.forEach((value) => {
      if (fieldValues[value.key] === "" || fieldValues[value.key] === null) {
        flag = false;
      }
    });

    return flag;
  };

  const checkifAnyFieldFilled = () => {
    let isFilled = false;
    const keys = Object.keys(fieldValues);
    keys.forEach((key, index) => {
      if (!excludedList.includes(key) &&
        (fieldValues[key] !== "" && fieldValues[key] !== null)) {
        isFilled = true;
      }
    });
    return isFilled;
  };

  const submitData = () => {
    if (checkifAllMandatoryFieldsFilled() &&
      fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted) {
      let fv = JSON.parse(JSON.stringify(fieldValues));
      fv.status = discussionGuideStatuses.Submitted;
      fv.documentLink= myConsts.hostUrl;
      dispatch({
        type: "ADD_UPDATE_COUNSELEE_DISCUSSION_GUIDE",
        payload: fv,
      });
      props.history.push({ pathname: myConsts.routeHome });
    }
  }

  return (
    <div className="finalizationForm">
      <div id="dvContent" style={{ display: "none" }}></div>
      {/* {loader && <Loader />} */}
      <SimpleBar
        style={{ maxHeight: "calc(100% - 40px)", height: "calc(100% - 40px)" }}>
        {
          //!isFormPreview && (
          <>
            <div className="seperator" style={{ paddingBottom: "0px" }}>
              {
                !isFormPreview && (
                  <>
                    <div className="mainHeading" style={{ color: "red" }}>The completion of the year-end self-assessment is not required to fill out the discussion guide, however it is <u>highly recommended</u> as the behaviors outlined in the self-assessment provide the basis for most of the commentary needed in the discussion guide.  Identifying "focus action items" within the self-assessment also assists in highlighting the most significant 2-4 distinguished performance behaviors.</div>
                    <div class="mainHeading" style={{ color: "red" }}>*Note: All questions must be answered to submit the form.</div>
                    <div className="headingStyleH1">{`FY${myContext.financialYear} Counselee Year-End Discussion Guide`}</div>
                  </>
                )
              }
              <div className="finalization-form-table">
                <table>
                  <tr>
                    <th>Name:</th>
                    <td>{fieldValues.name}</td>
                  </tr>
                  <tr>
                    <th>Rank: </th>
                    <td>{fieldValues.rank}</td>
                  </tr>
                  <tr>
                    <th>Region: </th>
                    <td>{fieldValues.region}</td>
                  </tr>
                  <tr>
                    <th>Office: </th>
                    <td>{fieldValues.office}</td>
                  </tr>
                  <tr>
                    <th>LEAD Reviewer: </th>
                    <td>{fieldValues.leadReviewer}</td>
                  </tr>
                </table>
              </div>
            </div>
            {
              !isFormPreview && (
                <>
                  <div className="seperator" style={{ paddingBottom: "0px" }}>
                    <div className="headingStyle" style={{
                      marginBottom: "10px",
                      paddingBottom: "0px"
                    }}>Year-end Self-Assessment focus action items:</div>
                    <div className="subHeading" style={{
                      fontStyle: "italic",
                      marginLeft: "15px"
                    }}>List of selected focus action items from self-assessment.</div>
                    {
                      anyFocusedStmtSelected ? (
                        <FocusSummaryView
                          summaryData={summaryData}
                          pillers={assessmentObj}
                          allOptionList={allOptionList}
                        />
                      )
                        :
                        (
                          <div className="subHeading" style={{ fontStyle: "italic" }}> Focused statements have not selected by {fieldValues.name}</div>
                        )
                    }

                    {/* <div className="headingStyle">List of selected focus action items from counselee's self-assessment.</div>
                <div className="mainHeading">Quality</div>
                <div className="subHeading">Quality— Risk Assessment — Focused</div>
                <div className="headingStyleStatements">I am performing robust risk assessment process identifying all relevant risk of material misstatements, leveraging data with a challenging mindset and focus on what is
                  most important.</div>
                <div className="headingStyleOptions">- I am maximizing this action in my BOB</div> */}
                  </div>
                </>
              )
            }

          </>
          //)
        }
        <div className="seperator" style={{ paddingBottom: "0px" }}>
          {/* <div class="mainHeading">PFC Discussion Questions:</div> */}
          <div className="mainHeading" style={{ color: "red" }}>To utilize bulleted text, click on this <img src={quillBulletIcon} alt="" style={{ height: "15px" }} /> icon.</div>
          <div className={`mainHeading ${errorMessages && errorMessages['question1AnsContent'] !== "" ? 'redColor' : ''}`}>What additional accomplishments/activities would you like to discuss not readily apparent above or in your feedback?</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.question1AnsContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div style={{ padding: '10px', height: '74%' }}>
                    <div className={`quill-container ${errorMessages && errorMessages['question1AnsContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        onKeyDown={(e) => checkCharacterCount(e, question1AnsContentRef)}
                        ref={question1AnsContentRef}
                        id="question1AnsContent"
                        modules={modules}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        placeholder="Comments on additional accomplishments/activities*"
                        defaultValue={fieldValues.question1AnsContent}
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "question1Ans")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "question1Ans")}
                      />
                    </div>
                    <div id="question1AnsPlainText"
                      className={`${errorMessages && errorMessages['question1AnsContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.question1AnsPlainText ? fieldValues.question1AnsPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }

          <div className={`mainHeading ${errorMessages && errorMessages['question2AnsContent'] !== "" ? 'redColor' : ''}`}>Summary of Roles/Areas of Responsibility:</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.question2AnsContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div style={{ padding: '10px', height: '74%' }}>
                    <div className={`quill-container ${errorMessages && errorMessages['question2AnsContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        onKeyDown={(e) => checkCharacterCount(e, question2AnsContentRef)}
                        ref={question2AnsContentRef}
                        id="question2AnsContent"
                        modules={modules}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        placeholder="Comments on Roles/Areas of Responsibility*"
                        defaultValue={fieldValues.question2AnsContent}
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "question2Ans")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "question2Ans")}
                      />
                    </div>
                    <div id="question2AnsPlainText"
                      className={`${errorMessages && errorMessages['question2AnsContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.question2AnsPlainText ? fieldValues.question2AnsPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }
          <div className="headingStyle">Revenue/Margin</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="mainHeadingPreview"><span style={{ color: "#6a6d70" }}>
                    Response</span></div>
                  <div className="subHeadingPreview">{fieldValues.revenueMargin}</div>
                  <div className="mainHeadingPreview"><span style={{ color: "#6a6d70" }}>
                    Comments on Revenue/Margin</span></div>
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.revenueMarginContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <DropdownComponent
                    label="Revenue/Margin"
                    defaultValue={fieldValues.revenueMargin}
                    required={true}
                    selectOnChange={(e, value) => selectOnChange(e, value)}
                    varName="revenueMargin"
                    list={[
                      "Not Met",
                      "Met",
                      "Exceeded",
                      "N/A"
                    ]}
                    incrFontSize={true}
                    errorMessage={errorMessages}
                    disabled={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                  />
                  <div className="seperatorControls">
                    <div className={`headingStyle commentHeading ${errorMessages && errorMessages['revenueMarginContent'] !== "" ? 'redColor' : ''}`}>Please comment on any 1-2 leading/transformative behaviors from self-assessment in areas of: Prioritization, Centralizing Service Delivery, Continuous Improvement.</div>
                    <div className={`quill-container ${errorMessages && errorMessages['revenueMarginContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        onKeyDown={(e) => checkCharacterCount(e, revenueMarginContentRef)}
                        ref={revenueMarginContentRef}
                        id="revenueMarginContent"
                        modules={modules}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        defaultValue={fieldValues.revenueMarginContent}
                        placeholder="Comments on Revenue/Margin*"
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "revenueMargin")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "revenueMargin")}
                      />
                    </div>
                    <div id="revenueMarginPlainText"
                      className={`${errorMessages && errorMessages['revenueMarginContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.revenueMarginPlainText ? fieldValues.revenueMarginPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }
          <div className="headingStyle">Sales and pipeline</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="mainHeadingPreview"><span style={{ color: "#6a6d70" }}>
                    Response</span></div>
                  <div className="subHeadingPreview">{fieldValues.salesAndPipeline}</div>
                  <div className="mainHeadingPreview"><span style={{ color: "#6a6d70" }}>
                    Comments on Sales and pipeline</span></div>
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.salesAndPipelineContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <DropdownComponent
                    label="Sales and pipeline"
                    defaultValue={fieldValues.salesAndPipeline}
                    required={true}
                    selectOnChange={(e, value) => selectOnChange(e, value)}
                    varName="salesAndPipeline"
                    list={[
                      "Not Met",
                      "Met",
                      "Exceeded",
                      "N/A"
                    ]}
                    incrFontSize={true}
                    errorMessage={errorMessages}
                    disabled={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                  />
                  <div className="seperatorControls">
                    <div className={`headingStyle commentHeading ${errorMessages && errorMessages['salesAndPipelineContent'] !== "" ? 'redColor' : ''}`}>Please comment on any 1-2 leading/transformative behaviors from self-assessment in areas of: Driving pipeline and Sales growth</div>
                    <div className={`quill-container ${errorMessages && errorMessages['salesAndPipelineContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        onKeyDown={(e) => checkCharacterCount(e, salesAndPipelineContentRef)}
                        ref={salesAndPipelineContentRef}
                        id="salesAndPipelineContent"
                        modules={modules}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        defaultValue={fieldValues.salesAndPipelineContent}
                        placeholder="Comments on sales and pipeline*"
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "salesAndPipeline")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "salesAndPipeline")}
                      />
                    </div>
                    <div id="salesAndPipelinePlainText"
                      className={`${errorMessages && errorMessages['salesAndPipelineContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.salesAndPipelinePlainText ? fieldValues.salesAndPipelinePlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }
          <div className={`headingStyle ${errorMessages && errorMessages['qualityContent'] !== "" ? 'redColor' : ''}`}>Quality</div>
          <div className={`headingStyle commentHeading ${errorMessages && errorMessages['qualityContent'] !== "" ? 'redColor' : ''}`}>Please comment on any 1-2 leading/transformative behaviors from self-assessment in areas of: Simplifying our audit and Pacing and milestones.</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.qualityContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div className={`quill-container ${errorMessages && errorMessages['qualityContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                    style={{ height: false ? '95%' : '86%' }}>
                    <ReactQuill
                      onKeyDown={(e) => checkCharacterCount(e, qualityContentRef)}
                      ref={qualityContentRef}
                      id="qualityContent"
                      modules={modules}
                      theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                      defaultValue={fieldValues.qualityContent}
                      placeholder="Comments on Quality*"
                      readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                      //onChange={(e) => handleEditorChange(e, "quality")} 
                      onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "quality")}
                    />
                  </div>
                  <div id="qualityPlainText"
                    className={`${errorMessages && errorMessages['qualityContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                    {`${fieldValues.qualityPlainText ? fieldValues.qualityPlainText.length - 1 : 0} / ${characterLimit}`}
                  </div>
                </>
              )
          }

          <div className="headingStyle">Exceptional Client Service</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="mainHeadingPreview"><span style={{ color: "#6a6d70" }}>
                    Response</span></div>
                  <div className="subHeadingPreview">{fieldValues.ecs}</div>
                  <div className="mainHeadingPreview"><span style={{ color: "#6a6d70" }}>
                    Comments on Exceptional Client Service</span></div>
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.ecsContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <DropdownComponent
                    label="ECS"
                    defaultValue={fieldValues.ecs}
                    required={true}
                    selectOnChange={(e, value) => selectOnChange(e, value)}
                    varName="ecs"
                    list={[
                      "Not Met",
                      "Met",
                      "Exceeded",
                      "N/A"
                    ]}
                    incrFontSize={true}
                    errorMessage={errorMessages}
                    disabled={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                  />
                  <div className="seperatorControls">
                    <div className={`headingStyle commentHeading ${errorMessages && errorMessages['ecsContent'] !== "" ? 'redColor' : ''}`}>Please comment on any 1-2 leading/transformative behaviors from self-assessment in areas of: Distinctive Client Experience (DCE), Expanded Assurance, and Digital & Innovation</div>
                    <div className={`quill-container ${errorMessages && errorMessages['ecsContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        onKeyDown={(e) => checkCharacterCount(e, ecsContentRef)}
                        ref={ecsContentRef}
                        id="ecsContent"
                        modules={modules}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        defaultValue={fieldValues.ecsContent}
                        placeholder="Comments on ECS*"
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "ecs")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "ecs")}
                      />
                    </div>
                    <div id="ecsPlainText"
                      className={`${errorMessages && errorMessages['ecsContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.ecsPlainText ? fieldValues.ecsPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }

          <div className="headingStyle">People & Teaming</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="mainHeadingPreview"><span style={{ color: "#6a6d70" }}>
                    Response</span></div>
                  <div className="subHeadingPreview">{fieldValues.peopleAndTeaming}</div>
                  <div className="mainHeadingPreview"><span style={{ color: "#6a6d70" }}>
                    Comments on People & Teaming</span></div>
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.peopleAndTeamingContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <DropdownComponent
                    label="People & Teaming"
                    defaultValue={fieldValues.peopleAndTeaming}
                    required={true}
                    selectOnChange={(e, value) => selectOnChange(e, value)}
                    varName="peopleAndTeaming"
                    list={[
                      "Not Met",
                      "Met",
                      "Exceeded",
                      "N/A"
                    ]}
                    incrFontSize={true}
                    errorMessage={errorMessages}
                    disabled={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                  />
                  <div className="seperatorControls">
                    <div className={`headingStyle commentHeading ${errorMessages && errorMessages['peopleAndTeamingContent'] !== "" ? 'redColor' : ''}`}>Please comment on any 1-2 leading/transformative behaviors from self-assessment in areas of: High performance teaming (including EY WOW), Diversity, equity and inclusion, Wellbeing (individual and team). Please note specific name(s) for the sponsorship behavior.</div>
                    <div className={`quill-container ${errorMessages && errorMessages['peopleAndTeamingContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        onKeyDown={(e) => checkCharacterCount(e, peopleAndTeamingContentRef)}
                        ref={peopleAndTeamingContentRef}
                        id="peopleAndTeamingContent"
                        modules={modules}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        defaultValue={fieldValues.peopleAndTeamingContent}
                        placeholder="Comments on People & Teaming*"
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "peopleAndTeaming")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "peopleAndTeaming")}
                      />
                    </div>
                    <div id="peopleAndTeamingPlainText"
                      className={`${errorMessages && errorMessages['peopleAndTeamingContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.peopleAndTeamingPlainText ? fieldValues.peopleAndTeamingPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }

        </div>
        {/* {
          !isFormPreview && (
            <div className="seperator" style={{ paddingBottom: "0px" }}>
              <div>
                <div class="mainHeading" style={{ color: "red" }}>Draft Finalization Form - Utilize as requested by region</div>
                <div class="mainHeading">Guidance for preparing draft YE Comments:</div>
                <ul class="subHeading">
                  <li> Please draft your year end commentary for the finalization form in the format below utilizing descriptive examples of distinguished performance behavior per Audit service line priorities detailed in LEAD 4D and if applicable, from regional leadership.</li>
                  <li> Use third person and not "I"</li>
                  <li> Do not reference non-audit pursuits/sales/fees on Ch 1 clients or extending relationships to build non-audit revenues on Ch 1 clients.</li>
                  <li> To be considered as Strategic Impact, there must be demonstrated sponsorship of 1-2 underrepresented individuals evidenced by specific details.</li>
                </ul>
              </div>
            </div>
          )
        } */}
        <div className="seperator">
          {/* <div class="mainHeading">Draft Finalization Form Comments:</div> */}
          <div className={`mainHeading ${errorMessages && errorMessages['distinguishPerformanceContent'] !== "" ? 'redColor' : ''}`}>Describe the top <u>2-4</u> distinguished performance behaviors exhibited during the fiscal year</div>
          <div className={`mainHeading ${errorMessages && errorMessages['distinguishPerformanceContent'] !== "" ? 'redColor' : ''}`}> - Include at least one DEI (people and teaming) and one continuous improvement (margin) behavior. These behaviors should align with the "focus action items" identified above from the self-assessment and/or any significant leading/transformative behaviors noted.</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.distinguishPerformanceContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div className="seperatorControls">
                    <div className={`quill-container ${errorMessages && errorMessages['distinguishPerformanceContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        onKeyDown={(e) => checkCharacterCount(e, distinguishPerformanceContentRef)}
                        ref={distinguishPerformanceContentRef}
                        id="distinguishPerformanceContent"
                        modules={modules}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        defaultValue={fieldValues.distinguishPerformanceContent}
                        placeholder="Comments on distinguished performance behaviors*"
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "distinguishPerformance")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "distinguishPerformance")}
                      />
                    </div>
                    <div id="distinguishPerformancePlainText"
                      className={`${errorMessages && errorMessages['distinguishPerformanceContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.distinguishPerformancePlainText ? fieldValues.distinguishPerformancePlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }

          <div className={`mainHeading ${errorMessages && errorMessages['developmentPointsContent'] !== "" ? 'redColor' : ''}`}>Describe 2-4 development points:</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.developmentPointsContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div className="seperatorControls">
                    <div className={`quill-container ${errorMessages && errorMessages['developmentPointsContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        onKeyDown={(e) => checkCharacterCount(e, developmentPointsContentRef)}
                        ref={developmentPointsContentRef}
                        id="developmentPointsContent"
                        modules={modules}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        defaultValue={fieldValues.developmentPointsContent}
                        placeholder="Comments on development points*"
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "developmentPoints")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "developmentPoints")}
                      />
                    </div>
                    <div id="developmentPointsPlainText"
                      className={`${errorMessages && errorMessages['developmentPointsContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.developmentPointsPlainText ? fieldValues.developmentPointsPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }

          <div className={`mainHeading ${errorMessages && errorMessages['potentialAndAspirationsContent'] !== "" ? 'redColor' : ''}`}>Describe potential and aspirations:</div>
          {
            isFormPreview ? (
              <>
                <div className="seperator">
                  <div className="subHeadingPreview">{ReactHtmlParser(fieldValues.potentialAndAspirationsContent)}</div>
                </div>
              </>
            )
              :
              (
                <>
                  <div className="seperatorControls">
                    <div className={`quill-container ${errorMessages && errorMessages['potentialAndAspirationsContent'] !== "" ? 'redBorder' : 'grayBorder'}`}
                      style={{ height: false ? '95%' : '86%' }}>
                      <ReactQuill
                        onKeyDown={(e) => checkCharacterCount(e, potentialAndAspirationsContentRef)}
                        ref={potentialAndAspirationsContentRef}
                        id="potentialAndAspirationsContent"
                        modules={modules}
                        theme={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? "bubble" : "snow"}
                        defaultValue={fieldValues.potentialAndAspirationsContent}
                        placeholder="Comments on potential and aspirations*"
                        readOnly={fieldValues && fieldValues.status === discussionGuideStatuses.Submitted ? true : false}
                        //onChange={(e) => handleEditorChange(e, "potentialAndAspirations")} 
                        onChange={(value, delta, source, editor) => handleEditorChange(value, delta, source, editor, "potentialAndAspirations")}
                      />
                    </div>
                    <div id="potentialAndAspirationsPlainText"
                      className={`${errorMessages && errorMessages['potentialAndAspirationsContent'] !== "" ? 'counterPlaceholderError' : 'counterPlaceholder'}`}>
                      {`${fieldValues.potentialAndAspirationsPlainText ? fieldValues.potentialAndAspirationsPlainText.length - 1 : 0} / ${characterLimit}`}
                    </div>
                  </div>
                </>
              )
          }

        </div>
      </SimpleBar>
      <div className={"btnSection"}>
        <div className="update-info">
        </div>
        <div className="buttons-wrapper">
          {
            !isFormPreview && (
              <>
                {/* <div
                  style={{
                    fontSize: "0.90rem",
                    color: "rgba(0,0,0,.80)",
                    fontFamily: "EYInterState-Regular",
                    marginRight: "10px",
                  }}>
                  * Please fill all mandatory fields
                </div> */}
                {/* <div
                    className={
                      checkifAllMandatoryFieldsFilled()
                        ? " cancel-button summary-button "
                        : "disable-sub save-button "
                    }
                    onClick={() => {
                      if (checkifAllMandatoryFieldsFilled()) {
                        checkOutsideClickAdmin(cancelClick);
                      }
                    }}>
                    Export
                  </div> */}
                <div
                  // className={
                  //   checkifAllMandatoryFieldsFilled()
                  //     ? " cancel-button summary-button "
                  //     : "disable-sub save-button "
                  // }
                  className="cancel-button summary-button"
                  // onClick={() => {
                  //   if (checkifAllMandatoryFieldsFilled()) {
                  //     checkOutsideClickAdmin(cancelClick);
                  //   }
                  // }}
                  onClick={cancelClick}
                >
                  Cancel
                </div>
              </>
            )
          }
          {
            !isFormPreview && (
              <>
                <div
                  // className={
                  //   checkifAllMandatoryFieldsFilled() &&
                  //     fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted
                  //     //&&
                  //     //!disableForm() &&
                  //     //myContext.changeTriggered
                  //     ? "conf-save save-button"
                  //     : "disable-sub save-button opacityReduced"
                  // }
                  className={
                    fieldValues && fieldValues.status !== discussionGuideStatuses.Submitted
                      ? "conf-save save-button"
                      : "disable-sub save-button opacityReduced"
                  }
                  onClick={() => {
                    saveBtnClick();
                  }}>
                  Save
                </div>
                <div
                  // className={"conf-sub submit-button" + (!checkifAllMandatoryFieldsFilled()
                  //   || (fieldValues && fieldValues.status === discussionGuideStatuses.Submitted) ? " opacityReduced " : "")}
                  className={"conf-sub submit-button" + (
                    (fieldValues && fieldValues.status === discussionGuideStatuses.Submitted) ? " opacityReduced " : "")}
                  onClick={() => {
                    submitClicked();
                  }}>
                  <i
                    className="icon-verified-outline "
                    style={{ marginRight: "5px" }}
                  />
                  Submit
                </div>
              </>
            )
          }
        </div>
      </div>

      <Snackbar open={showErrorAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        key={'topcenter'}
        onClose={handleErrorAlertClose}>
        <Alert className={classes.root}
          elevation={6} onClose={handleErrorAlertClose} variant="filled" severity="error">
          <AlertTitle>Below questions must be answered</AlertTitle>
          <ul>
            {
              errorMessages && Object.keys(errorMessages).map(key =>
                errorMessages[key] !== "" &&
                <>
                  <li>{`${errorMessages[key]}`}</li><br />
                </>
              )}
            {/* {
              Object.keys(errors).forEach((key, index) => (
                <>
                  <li>{errors[key]}</li><br />
                </>
              ))
            } */}
            {/* {
              mandatoryFields.forEach((value) => (
                fieldValues[value.key] !== "" && (
                  <>
                    <li>{value.name}</li><br />
                  </>
                )
              ))
            } */}
          </ul>
        </Alert>
      </Snackbar>

      <AlertSubmitPopup
        header={"Save Changes"}
        onlyAlert={false}
        message={""}
        cancelBtnTxt={"No"}
        submitBtnTxt={"Yes"}
        simpleClosePopup={() => setShowAlertSavePopup(false)}
        description={"Do you wish to save the changes before you proceed?"}
        showAlertSubmitPopup={showAlertSavePopup}
        cancelAlertSubmitPopup={cancelAlertSubmitPopup}
        submitPopupChanges={() => {
          // setShowAlertSubmitPopup(false);
          saveBtnClick();
        }}></AlertSubmitPopup>
      <AlertSubmitPopup
        header={"Confirmation"}
        onlyAlert={false}
        message={"Would you like to submit the changes for review ?"}
        cancelBtnTxt={"Cancel"}
        submitBtnTxt={"Confirm"}
        simpleClosePopup={() => setShowSubmissionConfirmation(false)}
        description={
          "Please note that you will not be able to make futher edits once submitted."
        }
        showAlertSubmitPopup={showSubmissionConfirmation}
        cancelAlertSubmitPopup={cancelConfirmation}
        submitPopupChanges={() => {
          submitData();
        }}></AlertSubmitPopup>

      {/* <ReviewConfirmation
        showReviewPopup={showReviewConfirmation}
        dismissPopup={dismissReviewWindow}
        reviewResponse={submitReviewResponse}></ReviewConfirmation> */}
    </div>
  );
};
export default FinalizationFormSelf;