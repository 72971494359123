import axios from "axios";
import * as apis from "../constants/constants";
import { authProvider, APIscope } from "./../Authentication/authProvider";
// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.

const REQ_TIMEOUT = 50000;

const customAxios = axios.create({
  baseURL: apis.hostName,
  timeout: REQ_TIMEOUT,
});

// Step-2: Create request, response & error handlers
const requestHandler = async (request) => {
  const idtoken = await authProvider.acquireTokenSilent({
    scopes: APIscope,
  });
  const token = idtoken.accessToken;
  // Token will be dynamic so we can use any app-specific way to always
  // fetch the new token before making the call
  request.headers.Authorization = "Bearer " + token;
  const sessionToken = sessionStorage.getItem("userToken");
  if (
    (sessionToken !== null ||
      sessionToken !== "" ||
      sessionToken ||
      sessionToken !== "logout") &&
    token === sessionToken
  )
    return request;
  else {
    return axios
      .post(
        `${apis.hostName}${apis.updateToken}`,
        { reqType: "normal" },
        {
          headers: {
            Authorization: "Bearer " + token,
            userid: request.headers.userid,
          },
        }
      )
      .then((response) => {
        if (response.data && response.data.message === "success") {
          sessionStorage.setItem("userToken", token);
          return request;
        }
      });
  }
};

const responseHandler = (response) => {
  /*  if (response.status === 401) {
    window.location = "/login";
  } */
  return response;
};

const errorHandler = (error) => {
  return Promise.reject(error);
};

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export {
  customAxios,
  REQ_TIMEOUT
};
