export const columnNames = [
    "ClientId",
    "Client",
    "EngagementRegion",
    "AccountSector",
    "ANSR_FYTD",
    "Margin_FYTD",
    "TER_FYTD",
    "ANSR_PFYTD",
    "Margin_PFYTD",
    "TER_PFYTD",
    "ANSR_PFY",
    "Margin_PFY",
    "TER_PFY",
    "SECIndicator",
    "EngagementSubServiceLine",
    "EngagementCountry",
    "EngagementCompanyCd",
    "RevenueGoal($)",
    "RegionMarginTarget(%)"
];