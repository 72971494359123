import React from 'react';
import ReactHtmlParser from "react-html-parser";
import headerLogo from './header_logo.gif';
import footerLogo from './footer_logo.gif';

const EmailPreview = (props) => {
    const { content } = props;
    return (
        <div className="WordSection1">
            <div align="center">
                <span style={{ msoBookmark: '_MailOriginal' }} /><span style={{ msoBookmark: '_MailOriginal' }} /><span style={{ msoBookmark: '_MailOriginal' }} /><span style={{ msoBookmark: '_MailOriginal' }} /><table className="MsoNormalTable" border={0} cellSpacing={3} cellPadding={0} width="\&quot;50%\&quot;" style={{ width: '50.0%', msoCellspacing: '1.5pt', background: '#F7F6FC', msoYftiTbllook: 1184, msoPaddingAlt: '0cm 0cm 0cm 0cm' }}>
                    <tbody><tr style={{ msoYftiIrow: 0, msoYftiFirstrow: 'yes' }}>
                        <td style={{ padding: '.75pt .75pt .75pt .75pt' }}>
                            <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><a><span style={{ color: 'black' }}> <img src={headerLogo} style={{ height: '1.091in', width: '6in' }} alt='' /> </span> </a></p>
                        </td>
                    </tr>
                        <tr style={{ msoYftiIrow: 1 }}>
                            <td style={{ padding: '.75pt .75pt .75pt .75pt' }}>
                                <div align="center">
                                    <span style={{ msoBookmark: '_MailOriginal' }} /><table className="MsoNormalTable" border={0} cellSpacing={3} cellPadding={0} width="\&quot;90%\&quot;" style={{ width: '90.0%', msoCellspacing: '1.5pt', background: 'white', msoYftiTbllook: 1184, msoPaddingAlt: '0cm 0cm 0cm 0cm' }}>
                                        <tbody><tr style={{ msoYftiIrow: 0, msoYftiFirstrow: 'yes', msoYftiLastrow: 'yes' }}>
                                            <td style={{ padding: '.75pt .75pt .75pt .75pt' }}>
                                                <p className="MsoNormal" style={{ marginBottom: '12.0pt' }}><span style={{ msoBookmark: '_MailOriginal' }}><span style={{ fontSize: '10.0pt', fontFamily: '\"EYInterstate Light\"', color: 'black' }}>
                                                    <div className='email-template-content' style={{ padding: '5px', fontSize: '11.0pt', fontFamily: `"Calibri",sans-serif` }}>
                                                        <div className='ql-editor'>
                                                            {ReactHtmlParser(content)}
                                                        </div>
                                                    </div>
                                                </span></span><span style={{ msoBookmark: '_MailOriginal' }}><span style={{ fontSize: '10.0pt', fontFamily: "EYInterstate Light" }} /></span></p>
                                            </td>
                                        </tr>
                                        </tbody></table>
                                </div>
                                <p align="center" style={{ textAlign: 'center' }}><span style={{ msoBookmark: '_MailOriginal' }}><span style={{ color: 'black' }}>&nbsp;</span></span></p>
                            </td>
                        </tr>
                        <tr style={{ msoYftiIrow: 2 }}>
                            <td style={{ background: '#2E2E38', padding: '.75pt .75pt .75pt .75pt' }}>
                                <p className="MsoNormal"><span style={{ msoBookmark: '_MailOriginal' }}><span style={{ color: 'white' }}><br /> <br /> <br /> <br /> <br /> <br /> &nbsp;</span></span></p>
                            </td>
                        </tr>
                        <tr style={{ msoYftiIrow: 3, msoYftiLastrow: 'yes' }}>
                            <td style={{ padding: '.75pt .75pt .75pt .75pt' }}>
                                <p className="MsoNormal" align="center" style={{ textAlign: 'center' }}><span style={{ msoBookmark: '_MailOriginal' }}><span style={{ fontSize: '7.5pt', fontFamily: '\"EYInterstate Light\"', color: 'black' }}> <img src={footerLogo} style={{ height: '.833in', width: '.841in' }} alt='' /><br /> <br /> Copyright 2021 © EY. All rights reserved.<br /> <br /> EY refers to the global organization, and may refer to one or more, of the member firms of Ernst &amp; Young Global Limited, each of which is a separate legal entity. Ernst &amp; Young Global Limited, a UK company limited by guarantee, does not provide services to clients.</span></span></p>
                            </td>
                        </tr>
                    </tbody></table>
            </div>
        </div>
    )
}

export default EmailPreview;