import React from "react";
import { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Modules object for setting up the Quill editor
export const modules0 = {
    toolbar: {
        container: "#toolbar0",
        // handlers: {
        //   undo: undoChange,
        //   redo: redoChange,
        // },
    },
    history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
    },
};
export const modules1 = {
    toolbar: {
        container: "#toolbar1",
        // handlers: {
        //   undo: undoChange,
        //   redo: redoChange,
        // },
    },
    history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
    },
};
export const modules2 = {
    toolbar: {
        container: "#toolbar2",
        // handlers: {
        //   undo: undoChange,
        //   redo: redoChange,
        // },
    },
    history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
    },
};

// Formats objects for setting up the Quill editor
export const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "code-block",
];

// Quill Toolbar component
export const QuillToolbar = (props) => (
    <div
        id={"toolbar" + props.index}
        style={{
            justifyContent: "center",
            position: "relative",
            alignItems: "center",
            display: `${props.display ? "flex" : "none"}`,
        }}>
        <span className="ql-formats">
            <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <select className="ql-color" style={{ top: "12.5px" }} />
            <select class="ql-align" style={{ top: "12.5px" }} >
                <option selected />
                <option value="center" />
                <option value="right" />
                <option value="justify" />
            </select>
            <button className="ql-link" />
        </span>
    </div>
);

export default QuillToolbar;
