import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";

import { styled } from "@material-ui/core/styles";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import Box from "@material-ui/core/Box";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import "./BasicReportingTable.scss";

const BasicReportingTable = (props) => {
  const { filterListStatus, filterListRegion } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const isValidDate = (date) => {
    return moment(date).isValid() ? date : "Not Available";
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // console.log(newPage);
  };
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#FAFAFC",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  useEffect(() => {
    //debugger;
    setPage(0);
  }, [props.dataSource]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const TablePaginationActions = (props) => {
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
    // console.log(props);
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page">
          {<FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page">
          {<KeyboardArrowLeft />}
        </IconButton>
        {page + 1 + "/" + Math.ceil(count / rowsPerPage)}
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page">
          {<KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page">
          {<LastPageIcon />}
        </IconButton>
      </Box>
    );
  };

  // console.log(props);
  return props && props.dataSource && props.dataSource.length > 0 ? (
    <div className="basic-report-table">
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        style={{
          padding: "5px 5px 5px 5px",
          backgroundColor: "black",
          color: "white",
        }}
        component="div">
        {props.label + " - Report List"}
      </Typography>
      <TableContainer style={{ maxHeight: 268 }}>
        <Table
          stickyHeader
          /*  style={{ minWidth: 650, maxHeight: 340 }} */
          aria-label="simple table">
          <TableHead>
            <TableRow>
              {
                props.label === "Lead Reviewer Finalization Form Commentary" ? (
                  <>
                    {/* <TableCell align="left">
                      <b>Partner Name</b>
                    </TableCell> */}
                    <TableCell align="left">
                      {" "}
                      <b>Last Name</b>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <b>First Name</b>
                    </TableCell>
                    {/* <TableCell align="left">
                      {" "}
                      <b>GUI</b>
                    </TableCell> */}
                    <TableCell align="left">
                      {" "}
                      <b>Office</b>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <b>Rank</b>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <b>Region</b>
                    </TableCell>
                    {/* <TableCell align="left">
                      {" "}
                      <b>Reviewer Submitted Date</b>
                    </TableCell> */}
                    <TableCell align="left">
                      {" "}
                      <b>Reviewer Submitted Status</b>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <b>Is Recorded In SuccessFactors</b>
                    </TableCell>
                    {/* <TableCell align="left">
                      {" "}
                      <b>PFC Discussion Question 1</b>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <b>PFC Discussion Question 2</b>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <b>PFC Discussion Question 3</b>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <b>PFC Discussion Question 4</b>
                    </TableCell>
                    <TableCell align="left">
                      {" "}
                      <b>Summary of Roles/Areas of Responsibility</b>
                    </TableCell> */}
                    {/* <TableCell align="left">
                      {" "}
                      <b>DRAFT Finalization Form Commentary</b>
                    </TableCell> */}
                    <TableCell align="left">
                      {" "}
                      <b>FINAL Finalization Form Commentary</b>
                    </TableCell>
                  </>
                )
                  :
                  props.label === "PPMD Discussion Guide" ? (
                    <>
                      {/* <TableCell align="left">
                        <b>Partner Name</b>
                      </TableCell> */}
                      <TableCell align="left">
                        {" "}
                        <b>Last Name</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>First Name</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>GUI</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>Office</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>Rank</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>Region</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>Submitted Date</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>Submitted Status</b>
                      </TableCell>
                      {/* <TableCell align="left">
                        {" "}
                        <b>Discussion Question 1</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>Summary of Roles/Areas of Responsibility</b>
                      </TableCell>

                      <TableCell align="left">
                        {" "}
                        <b>Revenue/Margin</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>Sales and Pipeline</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>Quality</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>Exceptional Client Service</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>People & Teaming</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>Draft Finalization Form Comment 1</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>Draft Finalization Form Comment 2</b>
                      </TableCell>
                      <TableCell align="left">
                        {" "}
                        <b>Draft Finalization Form Comment 3</b>
                      </TableCell> */}
                    </>
                  )
                    :
                    (
                      <>
                        <TableCell align="left">
                          <b>PPMD</b>
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          <b>PPMD Email</b>
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          <b>Role</b>
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          <b>
                            {props.label === "Lead Reviewer Approvals"
                              ? "Final Status"
                              : "Status"}
                          </b>
                        </TableCell>
                        {/* {props.label === "Lead Reviewer Approvals" && (
                          <TableCell align="left">
                            {" "}
                            <b>BoB Status</b>
                          </TableCell>
                        )} */}
                        {props.label === "Lead Reviewer Approvals" && (
                          <TableCell align="left">
                            {" "}
                            <b>Assessment Status</b>
                          </TableCell>
                        )}
                        <TableCell align="left">
                          {" "}
                          <b>Region</b>
                        </TableCell>
                        {/* <TableCell align="left">
                {" "}
                <b>City</b>
              </TableCell> */}
                        <TableCell align="left">
                          {" "}
                          <b>Lead Reviewer</b>
                        </TableCell>
                        <TableCell align="left">
                          {" "}
                          <b>Lead Reviewer Email</b>
                        </TableCell>
                      </>
                    )
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? props.dataSource.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              : props.dataSource
            ).map((row) => (
              <StyledTableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                {
                  props.label === "Lead Reviewer Finalization Form Commentary" ? (
                    <>
                      {/* <TableCell component="th" scope="row">
                        {row["Partner Name"]}
                      </TableCell> */}
                      <TableCell component="th" scope="row">
                        {row["Last Name"]}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row["First Name"]}
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                        {row["GUI"]}
                      </TableCell> */}
                      <TableCell component="th" scope="row">
                        {row["Office"]}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row["Rank"]}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row["Region"]}
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                        {isValidDate(moment(row["Reviewer Submitted Date"]).format("DD-MMM-YYYY"))}
                      </TableCell> */}
                      <TableCell component="th" scope="row">
                        {row["Reviewer Submitted Status"]}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row["isRecordedInSuccessFactors"] ? "Yes" : "No"}
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                        {ReactHtmlParser(row["PFC Discussion Question 1"])}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {ReactHtmlParser(row["PFC Discussion Question 2"])}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {ReactHtmlParser(row["PFC Discussion Question 3"])}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {ReactHtmlParser(row["PFC Discussion Question 4"])}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {ReactHtmlParser(row["Summary of Roles/Areas of Responsibility"])}
                      </TableCell> */}
                      {/* <TableCell component="th" scope="row">                     
                        {
                          ReactHtmlParser(`${row["Partner Name"]} exhibited the following distinguished performance behaviors this fiscal year: <br>${row["Distinguish Performance"]}<br> 
                                          ${row["Partner Name"]} has the following development points: <br>${row["Development Points"]}<br>
                                          ${row["Partner Name"]}'s potential and aspirations include: <br>${row["Potential And Aspirations"]}<br>

                                          Overall, ${row["Partner Name"]} is in the ${row["Overall Category"]} category.<br> 
                                          ${row["Partner Name"]}'s quality rating has been considered in their LEAD category determination and the related commentary is contained in MARQ.`)
                        }
                      </TableCell> */}
                      <TableCell component="th" scope="row">
                        {
                          row["Overall Category"] ?
                            row["FINAL Finalization Wording"] === "FINAL" ?
                              /* ReactHtmlParser(`${row["Partner Name"]} exhibited the following distinguished performance behaviors this fiscal year: <br>${row["Distinguish Performance"]}<br> 
                                            ${row["Partner Name"]} has the following development points: <br>${row["Development Points"]}<br>
                                            ${row["Partner Name"]}'s potential and aspirations include: <br>${row["Potential And Aspirations"]}<br>

                                            Overall, ${row["Partner Name"]} is in the ${row["Overall Category"]} category.<br> 
                                            ${row["Partner Name"]}'s quality rating has been considered in their LEAD category determination and the related commentary is contained in MARQ.`) */
                                  ReactHtmlParser(`Overall, ${row["Partner Name"]} is in the ${row["Overall Category"]} category. Year-end distinguished performance<br>
                                            behaviors, development points and potential/aspirations has been discussed with ${row["Partner Name"]} during<br>
                                            the Expectations conversation. ${row["Partner Name"]}'s quality rating has been considered in their LEAD category<br>
                                            determination and the related commentary is contained in MARQ.`)
                              :
                              ReactHtmlParser(row["FINAL Finalization Wording"] ? row["FINAL Finalization Wording"].replaceAll("\n", "<br/>") : "")
                          : null
                        }
                      </TableCell>
                    </>
                  )
                    :
                    props.label === "PPMD Discussion Guide" ? (
                      <>
                        {/* <TableCell component="th" scope="row">
                          {row["Partner Name"]}
                        </TableCell> */}
                        <TableCell component="th" scope="row">
                          {row["Last Name"]}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row["First Name"]}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row["GUI"]}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row["Office"]}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row["Rank"]}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row["Region"]}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {isValidDate(moment(row["Submitted Date"]).format("DD-MMM-YYYY"))}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row["Submitted Status"]}
                        </TableCell>
                        {/* <TableCell component="th" scope="row">
                          {ReactHtmlParser(row["Discussion Question 1"])}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ReactHtmlParser(row["Summary of Roles/Areas of Responsibility"])}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {`Response: ${row["Revenue/Margin"]}`}
                          {`Revenue/Margin Comment: ${ReactHtmlParser(row["Revenue/Margin Comment"])}`}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {`Response: ${row["Sales and Pipeline"]}`}
                          {`Sales and Pipeline Comment: ${ReactHtmlParser(row["Sales and Pipeline Comment"])}`}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ReactHtmlParser(row["Quality"])}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {`Response: ${row["Exceptional Client Service"]}`}
                          {`Exceptional Client Service Comment: ${ReactHtmlParser(row["Exceptional Client Service Comment"])}`}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {`Response: ${row["People & Teaming"]}`}
                          {`Exceptional Client Service Comment: ${ReactHtmlParser(row["People & Teaming Comment"])}`}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ReactHtmlParser(row["Draft Finalization Form Comment 1"])}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ReactHtmlParser(row["Draft Finalization Form Comment 2"])}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ReactHtmlParser(row["Draft Finalization Form Comment 3"])}
                        </TableCell> */}
                      </>
                    )
                      :
                      (
                        <>
                          <TableCell component="th" scope="row">
                            {row["Partner Name"]}
                          </TableCell>
                          <TableCell component="th" scope="row" align="left">
                            {row["Partner Email"]}
                          </TableCell>
                          <TableCell component="th" scope="row" align="left">
                            {row["Role"]}
                          </TableCell>
                          {/* {props.label === "Book of Business" &&  (
                            <TableCell component="th" scope="row" align="left">
                              {row["BoB Status"] === "Completed"
                                ? "Approved"
                                : row["BoB Status"]}
                            </TableCell>
                          )} */}
                          {props.label === "Assessment" && (
                            <TableCell component="th" scope="row" align="left">
                              {row["Assessment Status"] === "Completed"
                                ? "Approved"
                                : row["Assessment Status"]}
                            </TableCell>
                          )}
                          {props.label === "Lead Reviewer Approvals" && (
                            <TableCell component="th" scope="row" align="left">
                              {row["Final Status"] === "Completed"
                                ? "Approved"
                                : row["Final Status"] === "Submitted"
                                  ? "Pending Approval"
                                  : row["Final Status"]}
                            </TableCell>
                          )}
                          {/* {props.label === "Lead Reviewer Approvals" && (
                            <TableCell component="th" scope="row" align="left">
                              {row["BoB Status"] === "Completed"
                                ? "Approved"
                                : row["BoB Status"]}
                            </TableCell>
                          )} */}
                          {props.label === "Lead Reviewer Approvals" && (
                            <TableCell component="th" scope="row" align="left">
                              {row["Assessment Status"] === "Completed"
                                ? "Approved"
                                : row["Assessment Status"]}
                            </TableCell>
                          )}

                          {/*  */}
                          <TableCell align="left">{row["Region"]}</TableCell>
                          {/*  <TableCell align="left">{row["city"]}</TableCell> */}
                          <TableCell align="left">{row["Lead Reviewer Name"]}</TableCell>
                          <TableCell align="left">{row["Lead Reviewer Email"]}</TableCell>
                        </>
                      )
                }
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={props.label === "Lead Reviewer Approvals" ? 9 :
                  props.label === "Discussion Guide" ? 8 : 7}
                count={props.dataSource.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={<div style={{ fontSize: "12px" }}>Show</div>}
                SelectProps={{
                  inputProps: {
                    "aria-label": "Show",
                  },
                  native: true,
                }}
                labelDisplayedRows={() => null}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  ) : !props.isLoading ? (
    <div className="no-data-found">
      {" "}
      No data found. Please apply different filters
    </div>
  ) : null;
};
export default BasicReportingTable;
