import React, { useEffect, useState, useContext } from "react";
import SideMenu from "../../SideMenu/SideMenu";
import { useDispatch, useSelector } from "react-redux";
import * as myConsts from "../../../constants/constants";
import Breadcrumb from "../../Breadcrumbs/Breadcrumbs";
import "./ContentManagement.scss";
import AppContext from "../../../utils/appContext";
import { toast } from "react-toastify";
import { Flip } from "react-toastify";
import WelcomeMessage from "./WelcomeMessage/WelcomeMessage";
import AssessmentIntroduction from "./AssessmentIntro/AssessmentIntro";
import BOBInfo from "./BOBInfo/BOBInfo";
import Loader from "../../Loader/Loader";
import IntroductionCards from "./IntroductionCards/IntroductionCards";

const ContentManagement = (props) => {
  const crumbs = [{ name: "Admin", path: myConsts.routeAdmin }];
  const myContext = useContext(AppContext);
  const dispatch = useDispatch();
  const uploadGeneralContent = useSelector(
    (state) => state.uploadGeneralContent
  );
  const generalContent = useSelector((state) => state.generalContent);
  const [selectedSection, setSelectedSection] = useState(0);
  const [assessmentData, setAssessmentData] = useState({});
  const [bobData, setBobData] = useState({});
  const [currentPageName, setCurrentPageName] = useState(
    sessionStorage.getItem("currentPageName") || "ContentManagement"
  );
  const [isMainPageVisible, setIsMainPageVisible] = useState(
    sessionStorage.getItem("currentPageName")
      ? sessionStorage.getItem("currentPageName") !== "ContentManagement"
        ? false
        : true
      : true
  );
  const [loader, setLoader] = useState(false);

  let sectionContent = [
    "Welcome Message",
    "Introduction Cards",
    "Assessment Introduction",
    "Book of Business Info",
  ];
  useEffect(() => {
    dispatch({
      type: "GET_GENERAL_CONTENT",
      payload: {},
    });
  }, []);

  useEffect(() => {
    if (generalContent.fetching) {
      setLoader(true);
    } else if (generalContent.data && generalContent.data) {
      const cards = [];
      setLoader(false);
      setBobData(
        generalContent.data.bob ? generalContent.data.bob.content : {}
      );
      setAssessmentData(
        generalContent.data.assessment
          ? generalContent.data.assessment.content
          : {}
      );
    }
  }, [generalContent]);

  useEffect(() => {
    if (uploadGeneralContent && uploadGeneralContent.fetching) {
      setLoader(true);
    } else if (
      uploadGeneralContent &&
      !uploadGeneralContent.fetching &&
      uploadGeneralContent.data &&
      uploadGeneralContent.data.message &&
      uploadGeneralContent.data.message === "success"
    ) {
      //assessment or bob
      setLoader(false);
      myContext.setIsLockPopup(false);
      myContext.setIsWarningPopup(false);
      let type = ''
      switch (uploadGeneralContent.data.key) {
        case "welcome_message":
          type = "Welcome Message";
          break;
        case "assessment":
          type = "Assessment Introduction";
          break;
        case "bob":
          type = "Book of Business Info";
          break;
        default:
          type = "Introduction Card";
          break;
      }
      const successMessage = `${type} updated successfully`
      toast.warn(
        <div>
          <i className="icon-check-outline" />
          {successMessage}
        </div>,
        {
          position: "bottom-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      dispatch({
        type: "UPLOAD_GENERAL_CONTENT_RESET",
        payload: {},
      });
      dispatch({
        type: "GET_GENERAL_CONTENT",
        payload: {},
      });
    } else if (
      uploadGeneralContent &&
      uploadGeneralContent.data &&
      uploadGeneralContent.data.fileName
    ) {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [uploadGeneralContent]);

  return (
    <div className="admin-container">
      {loader && <Loader />}
      <div className="admin-info-main-sidemenu">
        <SideMenu
          minimized={true}
          isminimizedMainMenu={true}
          isMainMenu={true}
          sideMenuItemSelect="Admin"
        />
      </div>
      <div className="admin-content admin-content-management-content">
        <div className="second-header admin-crumbs">
          <i
            className="icon-arrow-left"
            onClick={() => {
              if (!isMainPageVisible) {
                setCurrentPageName("ContentManagement");
                setIsMainPageVisible(true);
                sessionStorage.setItem("currentPageName", "ContentManagement");
              } else {
                props.history.push(myConsts.routeAdmin);
              }
            }}
          />
          <div className="admin-crumb-txt">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}>
              <Breadcrumb
                crumbs={crumbs}
                isAdmin={true}
                isInnerAdmin={true}
                {...props}
              />
              {!isMainPageVisible && (
                <div className="icon-chevron-right iconStyling">
                  <a
                    className=" userMngmnt MuiTypography-root MuiLink-root MuiLink-underlineHover admin-crumb MuiTypography-colorInherit"
                    onClick={() => {
                      setIsMainPageVisible(true);
                      setCurrentPageName("ContentManagement");
                      sessionStorage.setItem(
                        "currentPageName",
                        "ContentManagement"
                      );
                    }}>
                    Content Management
                  </a>
                </div>
              )}
            </div>
            <h4>{currentPageName}</h4>
          </div>
        </div>
        <div className="content-management-content">
          <div className="content-management-main-header">
            <div className="content-management-details">
              <div className="content-management-section">
                <div>
                  <div className="header"> Sections </div>
                  <div>
                    <>
                      {sectionContent.map((item, index) => (
                        <>
                          <div
                            // className={"dragged-card"}
                            className={
                              "section-card " +
                              (index === selectedSection
                                ? " selected-card"
                                : " ")
                            }
                            style={{ display: "flex" }}
                            onClick={() => {
                              setSelectedSection(index);
                            }}>
                            {item}
                            <div style={{ marginLeft: "auto" }}>
                              <i className="icon-chevron-right" />
                            </div>
                          </div>
                        </>
                      ))}
                    </>
                  </div>
                </div>
              </div>
              {sectionContent[selectedSection] === "Welcome Message" ? (
                <WelcomeMessage
                  selectedSection={sectionContent[selectedSection]}
                />
              ) : sectionContent[selectedSection] === "Introduction Cards" ? (
                <IntroductionCards />
              ) : sectionContent[selectedSection] ===
                "Assessment Introduction" ? (
                <AssessmentIntroduction
                  selectedSection={sectionContent[selectedSection]}
                  assessmentData={assessmentData}
                  setAssessmentData={(value) => setAssessmentData(value)}
                />
              ) : (
                <BOBInfo
                  selectedSection={sectionContent[selectedSection]}
                  bobData={bobData}
                  setBobData={(value) => setBobData(value)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentManagement;
