import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authProvider,
  APIscope,
} from "../../../../Authentication/authProvider";
import AppContext from "../../../../utils/appContext";
import { validateEmail } from "../../../../utils/utils";
import "./ResetBobAssessment.scss";
import Loader from "../../../Loader/Loader";
import classNames from "classnames";
import * as myConsts from "../../../../constants/constants";
import { toast, Flip } from "react-toastify";
import {
  removeSpecialChars,
  removeEmailSpecialChars,
} from "../../../../utils/utils";

const ResetBobAssessment = (props) => {
  const dispatch = useDispatch();
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  const myContext = useContext(AppContext);
  const searchResults = useSelector((state) => state.searchUser);
  const resetUser = useSelector((state) => state.resetUser);
  const [searchInput, setSeachInput] = useState({
    gpn: "",
    emailId: "",
  });
  const [radioBtnVal, setRadioBtnVal] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [isPhaseOver, setIsPhaseOver] = useState(false);
  const [disableReset, setDisableReset] = useState(false);
  const [isSearchBtnClicked, setIsSearchBtnClicked] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const searchResultHeaders = useMemo(() => {
    return [
      {
        label: "Username",
        key: "userName",
      },
      {
        label: "GPN",
        key: "gpn",
      },
      {
        label: "GUI",
        key: "gui",
      },
      {
        label: "Email ID",
        key: "emailId",
      },
      {
        label: "Region",
        key: "region",
      },
      //..DISABLE BOB :: COMMENTED BELOW CODE IF IT WILL REQUIRE TO ENABLE IN FUTURE
      // {
      //   label: "BOB Status",
      //   key: "bobStatus",
      // },
      {
        label: "Assessment Status",
        key: "assessmentStatus",
      },
    ];
  }, []);

  const onSearchInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      let val;
      if (name === "emailId") {
        val = removeEmailSpecialChars(value);
      } else {
        val = removeSpecialChars(value);
      }
      setSeachInput({
        ...searchInput,
        [name]: val,
      });
    },
    [searchInput]
  );

  const onSearch = useCallback(() => {
    setIsLoading(true);
    setIsSearchBtnClicked(true);
    (async function () {
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "SEARCH_USER",
          payload: {
            token: idToken,
            body: {
              financialYear: myContext.financialYear,
              timePeriod: myContext.activePeriod,
              email: searchInput.emailId,
              gpn: searchInput.gpn.toUpperCase(),
            },
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [searchInput, myContext, dispatch]);

  const handleRadioChange = useCallback((e) => {
    setRadioBtnVal(e.target.value);
  }, []);

  const isSearchBtnEnabled = useCallback(() => {
    return searchInput.gpn.length > 10 || validateEmail(searchInput.emailId);
  }, [searchInput]);

  const checkIsRaidoBtnEnabled = useCallback(
    (status) => {
      if (status === "Both") {
        return (
          searchResults.data.length > 0 &&
          searchResults.data[0].bobStatus.toLowerCase() ===
          myConsts.statusSubmitted &&
          searchResults.data[0].assessmentStatus.toLowerCase() ===
          myConsts.statusSubmitted
        );
      }
      return status && status.toLowerCase() !== myConsts.statusSubmitted;
    },
    [searchResults]
  );

  const checkIsResetBtnEnabled = useCallback(() => {
    return radioBtnVal !== "";
  }, [radioBtnVal]);

  const onCancel = useCallback(() => {
    setSeachInput({
      gpn: "",
      emailId: "",
    });
    setIsSearchBtnClicked(false);
    dispatch({
      type: "SEARCH_USER_RESET",
      payload: {},
    });
    dispatch({
      type: "RESET_USER_DATA",
      payload: {},
    });
  }, [dispatch]);

  const onUserReset = useCallback(() => {
    (async function () {
      try {
        setIsLoading(true);
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "RESET_USER",
          payload: {
            token: idToken,
            body: {
              financialYear: myContext.financialYear,
              timePeriod: myContext.activePeriod,
              emailId: searchResults.data[0].emailId,
              resetFlag: radioBtnVal,
            },
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [radioBtnVal, searchResults, dispatch, myContext]);

  const notify = useCallback((msg) => {
    toast(
      <div className="success-text">
        <span className="icon-verified-outline icon"></span>
        <p>
          <span>Success:</span> <span>{msg}</span>
        </p>
      </div>,
      {
        position: "top-center",
        transition: Flip,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  useEffect(() => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    if (!searchResults.fetching && !resetUser.fetching) {
      setIsLoading(false);
    }
  }, [searchResults, resetUser]);

  useEffect(() => {
    if (resetUser.data && resetUser.data.message === "success") {
      if (radioBtnVal === "BookOfBusiness") {
        notify("Book of Business for user has been reset");
      } else if (radioBtnVal === "Assessment") {
        notify("Assessment for user has been reset");
      } else {
        notify("Book of Business & Assessment for user has been reset");
      }
      onCancel();
      setRadioBtnVal("");
    }
  }, [resetUser, radioBtnVal, onCancel, notify]);

  useEffect(() => {
    if (
      assessmentMetaData &&
      assessmentMetaData.data &&
      assessmentMetaData.data.data
    ) {
      const data = assessmentMetaData.data.data;
      setIsPhaseOver(!data.activePhase);
      setDisableReset(new Date() >= new Date(data.assessmentMetaData.leadReviewer_CompletionDate));
    }
  }, [assessmentMetaData]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="search-container">
        <h4>Search and Reset</h4>
        <div className="search-input-wrapper">
          <div className="input-group">
            <label htmlFor="gpn">GPN</label>
            <input
              type="text"
              name="gpn"
              maxLength="20"
              value={searchInput.gpn}
              onChange={onSearchInputChange}
            />
          </div>
          <div className="input-group">
            <label htmlFor="emailId">Email ID</label>
            <input
              type="email"
              name="emailId"
              maxLength="254"
              value={searchInput.emailId}
              onChange={onSearchInputChange}
            />
          </div>
          <input
            type="button"
            value="Search"
            className={classNames("search-btn", {
              "disable-btn": !isSearchBtnEnabled() || isPhaseOver || disableReset,
            })}
            onClick={onSearch}
            disabled={!isSearchBtnEnabled() || isPhaseOver || disableReset}
          />
        </div>
      </div>
      {searchResults.data.length > 0 ? (
        <div className="search-results">
          <table>
            <thead>
              <tr>
                {searchResultHeaders.map((result, index) => (
                  <th key={index}>{result.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {searchResults.data.length > 0 && (
                <tr>
                  {searchResultHeaders.map((result, index) => (
                    <td key={index}>
                      {searchResults.data[0][result.key]
                        ? searchResults.data[0][result.key]
                        : "--"}
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          </table>
          <div className="reset-container">
            {/* ..DISABLE BOB :: COMMENTED BELOW CODE IF IT WILL REQUIRE TO ENABLE IN FUTURE */}
            {/* <div className="input-group">
              <input
                name="reset"
                type="radio"
                value="BookOfBusiness"
                onChange={handleRadioChange}
                disabled={checkIsRaidoBtnEnabled(
                  searchResults.data[0].bobStatus
                )}
              />
              <span>Book of Business</span>
            </div> */}
            <div className="input-group">
              <input
                name="reset"
                type="radio"
                value="Assessment"
                onChange={handleRadioChange}
                disabled={checkIsRaidoBtnEnabled(
                  searchResults.data[0].assessmentStatus
                )}
              />
              <span>Assessment</span>
            </div>
            {/* ..DISABLE BOB :: COMMENTED BELOW CODE IF IT WILL REQUIRE TO ENABLE IN FUTURE */}
            {/* <div className="input-group">
              <input
                name="reset"
                type="radio"
                value="Both"
                onChange={handleRadioChange}
                disabled={!checkIsRaidoBtnEnabled("Both")}
              />
              <span>Both</span>
            </div> */}
            <div className="btn-container">
              <input
                type="button"
                value="Cancel"
                className="btn cancel-btn"
                onClick={onCancel}
              />
              <input
                type="button"
                value="Reset"
                className={classNames("btn reset-btn", {
                  "disable-btn": !checkIsResetBtnEnabled(),
                })}
                disabled={!checkIsResetBtnEnabled()}
                onClick={() => setIsPopupVisible(true)}
              />
            </div>
          </div>
        </div>
      ) : !isPhaseOver ? (!disableReset ?
        (!isLoading &&
          isSearchBtnClicked && <p className="no-data">-- No data found --</p>)
        : <p className="no-data">{`Current Phase Status : Locked. This option shall be enabled when the next phase is made active.`}</p>
      ) : (
        <p className="no-data">There is no active phase at the moment. This shall be enabled when a phase is made active.</p>
      )}
      {isPopupVisible && (
        <div className="popup-dialog">
          <div className="dialog">
            <div className="header">
              <h5 className="popup-header">Confirmation</h5>
              <span
                className="icon-close-filled icon"
                onClick={() => setIsPopupVisible(false)}
              ></span>
            </div>
            <div className="content">
              <p>Would you like to confirm reset?</p>
              <p>
                Do note that after resetting, user will have to submit again for
                approval.
              </p>
            </div>
            <div className="footer">
              <button
                className="cancel-btn"
                onClick={() => setIsPopupVisible(false)}
              >
                Cancel
              </button>
              <button
                className="confirm-btn"
                onClick={() => {
                  setIsPopupVisible(false);
                  onUserReset();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default ResetBobAssessment;
