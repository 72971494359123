import React, { useState } from "react";
import { Modal } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import AddSectionForm from "./AddSectionForm";
import CopyForm from "./CopyForm";
import AddGoalPopup from "./AddGoalForm";
import ChangeIcon from "./ChangeIcon";
import ChangeOrder from "./ChangeOrder";
import {
  authProvider,
  APIscope,
} from "./../../../../Authentication/authProvider";
import { useDispatch } from "react-redux";
import "./AdminPopup.scss";

const AdminPopup = (props) => {
  const [enableSave, setEnableSave] = useState(false);
  const [values, setValues] = useState({});
  const [updatedList, setUpdatedList] = useState([]);
  const dispatch = useDispatch();
  const closePopup = () => {
    props.cancelPopup();
  };

  const validateValue = (values, errorMessages) => {
    if (props.formType === 1) {
      if (
        values.sectionName === "" ||
        values.sectionDescription === "" ||
        values.color === "" ||
        values.sectionName === "white" ||
        values.multiplierScore === "" ||
        errorMessages.color !== "" ||
        errorMessages.sectionName !== ""
      )
        setEnableSave(false);
      else {
        setEnableSave(true);
        setValues(values);
      }
    } else if (props.formType === 2) {
      if (values.length > 0) {
        setEnableSave(true);
        setValues(values);
      } else setEnableSave(false);
    } else if (props.formType === 0) {
      let flag = false;
      if (values.length > 0) {
        values.forEach((item) => {
          if (
            item.goalName === "" ||
            item.goalDescription === "" ||
            item.goalType === "" ||
            (item.goalType !== "CheckBox" && item.goalOptionNo === "")
          )
            flag = true;

          if (item.goalOptions.length > 0) {
            item.goalOptions.forEach((item2) => {
              if (item2.optionDesc === "") flag = true;
            });
          }
        });
        setEnableSave(!flag);
      }
    } else if (props.formType === 3) {
      if (values !== "") {
        setEnableSave(true);
        setValues(values);
        dispatch({
          type: "OUTSIDE_CLICK_COMMON",
          payload: {
            feature: "AssessmentAdmin",
            changesDone: true,
            OpenModal: "",
            ProceedWithNavigation: "",
          },
        });
      } else setEnableSave(false);
    } else if (props.formType === 4) {
      let Dflag = false;
      props.assessmentData &&
        values &&
        values.length > 0 &&
        props.assessmentData.forEach((item, index) => {
          if (item.pillar !== values[index].pillar) {
            Dflag = true;
          }
        });
      setEnableSave(Dflag);
    }else if (props.formType === 5) {
      let Dflag = false;
      props.sectionData &&
        values &&
        values.length > 0 &&
        props.sectionData.forEach((item, index) => {
          if (item.name !== values[index].name) {
            Dflag = true;
          }
        });
      setEnableSave(Dflag);
    }
  };
  const onSave = async () => {
    if (props.formType === 1) {
      // console.log(values)
      let temp = {};
      temp.score = values.multiplierScore.replace("x", "0");
      temp.color = values.color;
      temp.name = values.sectionName;
      temp.description = values.sectionDescription;
      temp.financialYear = props.fyYear.replace("FY ", "");
      temp.displayOrder=props.uniquelist.sectionName.length+1
      //add section
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "EDIT_ASSESSMENT_SECTION", //add
          payload: {
            token: idToken,
            data: temp,
          },
        });
        // console.log(temp)
      } catch (e) {
        console.error(e);
      }
    } else if (props.formType === 3) {
      props.seticonType(values);
    } else if (props.formType === 4) {
      console.log("updatedList", updatedList);
      let data = {
        financialYear: props.fyYear.replace("FY ", ""),
        displayOrder: [],
      };
      let temp = [];
      updatedList.forEach((item, index) => {
        temp.push({ pillarId: item.pillarId, order: index + 1 });
      });
      data.displayOrder = temp;
      dispatch({
        type: "REORDER_GLOBAL_METRIC",
        payload: data,
      });
    }else if (props.formType === 5) {
      // sectionreorder
      console.log("updatedList", updatedList);
      let data = {
        financialYear: props.fyYear.replace("FY ", ""),
        displayOrder: [],
      };
      let temp = [];
      updatedList.forEach((item, index) => {
        temp.push({ sectionName: item.name, order: index + 1 });
      });
      data.displayOrder = temp;
      // console.log(data)
      dispatch({
        type: "REORDER_ASSESSMENT_SECTION",
        payload: data,
      });
    }
    props.cancelPopup();
  };
  // console.log(props)

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={props.showPopup}
      onClose={(e) => closePopup()}
      hideBackdrop={false}
      disableEscapeKeyDown={true}
      style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
      <div
        className={
          "AdminPopup " +
          (props.formType === 0
            ? " addNewGoal"
            : props.formType === 1
            ? " addNewSection"
            : props.formType === 3
            ? " addNewIcon"
            : props.formType === 4
            ? " orderChange"
            : props.formType === 5
            ? " orderChange"
            : " copyGoal")
        }>
        <div className="AdminHeader">
          <div>{props.title}</div>
          <i
            className="icon-close closeIcon"
            style={{ cursor: "pointer" }}
            onClick={(e) => closePopup()}
          />
        </div>
        {props.formType === 1 && (
          <AddSectionForm
            passValues={(value, errormessages) =>
              validateValue(value, errormessages)
            }
            uniquelist={props.uniquelist}
            isChangeDone={()=>{}}
          />
        )}
        {props.formType === 2 && (
          <CopyForm
            passValues={(value) => validateValue(value)}
            getPrevAssesmentData={props.getPrevAssesmentData}
            fyYear={props.fyYear}
            prevAssessmentData={props.prevAssessmentData}
          />
        )}
        {props.formType === 3 && (
          <ChangeIcon
            passValues={(value) => validateValue(value)}
            iconName={props.iconName}
          />
        )}
        {props.formType === 4 && (
          <ChangeOrder
            tabName={props.tabName}
            passValues={(value) => validateValue(value)}
            iconName={props.iconName}
            sectionData={props.sectionData}
            assessmentData={props.assessmentData}
            passListUpwards={(data) => {
              setUpdatedList(data);
              validateValue(data);
            }}
          />
        )}
        {props.formType === 5 && (
          <ChangeOrder
            tabName={props.tabName}
            passValues={(value) => validateValue(value)}
            iconName={props.iconName}
            sectionData={props.sectionData}
            assessmentData={props.assessmentData}
            passListUpwards={(data) => {
              setUpdatedList(data);
              validateValue(data);
            }}
          />
        )}
        {props.formType === 0 && (
          <AddGoalPopup
            passValues={(value) => validateValue(value)}
            getPrevAssesmentData={props.getPrevAssesmentData}
            fyYear={props.fyYear}
            prevAssessmentData={props.prevAssessmentData}
          />
        )}
        <div className="AdminActions">
          <>
            <div className="cancel" onClick={() => closePopup()}>
              Cancel
            </div>
            {props.formType === 2 ? (
              <div
                className={"save" + (enableSave ? "" : " disable-btn ")}
                onClick={() => {
                  if (enableSave) onSave();
                }}>
                Copy
              </div>
            ) : props.formType === 3 ? (
              <div
                className={"save" + (enableSave ? "" : " disable-btn ")}
                onClick={() => {
                  if (enableSave) onSave();
                }}>
                Select
              </div>
            ) : (
              <div
                className={"save" + (enableSave ? "" : " disable-btn ")}
                onClick={() => {
                  if (enableSave) onSave();
                }}>
                Save
              </div>
            )}
          </>
        </div>
      </div>
    </Modal>
  );
};
export default withRouter(AdminPopup);
