import React, { useState, useCallback, useEffect, useContext } from "react";
import SimpleBar from "simplebar-react";
import readXlsxFile from "read-excel-file";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import { toast, Flip } from "react-toastify";
import Loader from "../../../Loader/Loader";
import AppContext from "../../../../utils/appContext";
import * as myConsts from "../../../../constants/constants";
import {
  authProvider,
  APIscope,
  GraphExplorerscopes,
} from "./../../../../Authentication/authProvider";
import { useDispatch, useSelector } from "react-redux";
import DropdownComponent from "../../../Admin/AdminGeneralParam/DropdownComponent/DropdownComponent";
import { columnNames } from "./columnNames";
import "./AddMultipleUsers.scss";

const AddMultipleUsers = (props) => {
  const dispatch = useDispatch();
  const lockId = "custom-id-lock";
  const filetoastId = "custom-id-file";
  // const [errorList, setErrorList] = useState([]);
  const myContext = useContext(AppContext);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [fileInfo, setFileInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [enableProgress, setEnableProgress] = useState(false);
  const [progress, setProgress] = useState(-1);
  const [popupType, setPopUpType] = useState(-1);
  const [headerErrors, setHeaderError] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const [errorMsgs, setErrorMsg] = useState("");
  const getPhaseValidationStatus = useSelector(
    (state) => state.getPhaseValidationStatus
  );
  const [periodDropdown, setPeriodDropdown] = useState([]);
  // const [yearArray, setYearArray] = useState([]);

  // const [jsonDataIndex, setJsonDataIndex] = useState(0);
  const getLockStatus = useSelector((state) => state.checkLockStatus);
  const addMultipleUsers = useSelector((state) => state.addMultipleUsers);
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  // const [waitingForCompletion, setwaitingForCompletion] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    fyYear: "",
    period: "",
  });
  const [values, setValues] = useState({
    fyYear: "",
    period: "",
  });

  /*const errorListHeaders = [
    "Row No",
    "GPN",
    "GUI",
    "LPN",
    "Email Id",
    "Rank Code",
    "Rank Description",
    "Service Line",
    "Sub Service Line",
    "Country",
    "Region",
    "Location City",
    "Counsellor GUI",
    "Counsellor Name",
    "Tenure Start date",
    "Primary Sector",
    "Secondary Sector",
  ];

  const mapTemplateProps = [
    "row",
    "gpn",
    "gui",
    "lpn",
    "emailId",
    "rankCode",
    "rankDesc",
    "serviceLine",
    "subServiceLine",
    "country",
    "region",
    "locationCity",
    "counselorGUI",
    "counselorName",
    "tenorStartDate",
    "primarySector",
    "secondarySector",
  ]; */

  /* const schema = {
    GPN: {
      prop: "gpn",
      required: true,
      type: (value) => {
        const numbers = value.slice(2, value.length);
        if (
          value.length < 11 ||
          !isAlpha(value.charAt(0)) ||
          !isAlpha(value.charAt(1)) ||
          !isNumeric(numbers)
        ) {
          throw new Error("invalid");
        } else {
          return value;
        }
      },
    },
    GUI: {
      prop: "gui",
      required: true,
      type: (value) => {
        if (value.length < 7 || !isNumeric(value)) {
          throw new Error("invalid");
        } else {
          return value;
        }
      },
    },
    LPN: {
      prop: "lpn",
      required: true,
      type: (value) => {
        if (value.length < 6 || !isNumeric(value)) {
          throw new Error("invalid");
        } else {
          return value;
        }
      },
    },
    "Email Address": {
      prop: "emailId",
      required: true,
      type: (value) => {
        if (!validateEmail(value)) {
          throw new Error("invalid");
        } else {
          return value;
        }
      },
    },
    "Rank Code": {
      prop: "rankCode",
      required: true,
      type: (value) => {
        if (value.toString().length !== 2 || !isNumeric(value)) {
          throw new Error("invalid");
        } else {
          return value;
        }
      },
    },
    "Rank Description": {
      prop: "rankDesc",
      type: String,
      required: true,
    },
    "Service Line (SL Level 1)": {
      prop: "serviceLine",
      type: String,
      required: true,
    },
    "Sub Service Line (SL Level 2)": {
      prop: "subServiceLine",
      type: String,
      required: true,
    },
    "Location Country": {
      prop: "country",
      type: String,
      required: true,
    },
    "Location City": {
      prop: "locationCity",
      type: String,
      required: true,
    },
    "Geo Level 2": {
      prop: "region",
      type: String,
      required: true,
    },
    "Counselor Name": {
      prop: "counselorName",
      type: String,
      required: true,
    },
    "Counselor GUI": {
      prop: "counselorGUI",
      type: String,
      required: true,
    },
    "Partner Admission Date": {
      prop: "tenorStartDate",
    },
    "Last Promotion Date": {
      prop: "lastPromotionDate",
    },
    "Current Employment Start Date": {
      prop: "currentEmploymentStartDate",
    },
    "Primary Sector Specialization Description": {
      prop: "primarySector",
    },
    "Secondary Sector Specialization Description": {
      prop: "secondarySector",
    },
  };*/

  const schema = {
    GPN: {
      prop: "gpn",
    },
    GUI: {
      prop: "gui",
    },
    LPN: {
      prop: "lpn",
    },
    "Email Address": {
      prop: "userId",
    },
    "Rank Code": {
      prop: "rankCode",
    },
    "Rank Description": {
      prop: "rankDesc",
      type: String,
      required: true,
    },
    "Service Line (SL Level 1)": {
      prop: "serviceLine",
    },
    "Sub Service Line (SL Level 2)": {
      prop: "subServiceLine",
    },
    "Location Country": {
      prop: "country",
    },
    "Location City": {
      prop: "locationCity",
    },
    "Geo Level 2": {
      prop: "region",
    },
    "Counselor Name": {
      prop: "counselorName",
    },
    "Counselor GUI": {
      prop: "counselorGUI",
    },
    "Partner Admission Date": {
      prop: "tenorStartDate",
    },
    "Last Promotion Date": {
      prop: "lastPromotionDate",
    },
    "Current Employment Start Date": {
      prop: "currentEmploymentStartDate",
    },
    "Primary Sector Specialization Description": {
      prop: "primarySector",
    },
    "Secondary Sector Specialization Description": {
      prop: "secondarySectorSpecialization",
    },
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: (files) => {
      // if (!waitingForCompletion) {
      setHeaderError(false);
      validateFile(files[0]);
      // }
    },
    maxFiles: 1,
    maxSize: 5119031,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    disabled:
      myContext.disableUserUpdation ||
      (progress > 0 && progress < 100) ||
      progress === 100,
  });
  const dropOnChange = (value, label) => {
    //disable previous year & period
    if (
      "FY " + (myContext.financialYear - 1) !==
      value
      // &&
      // myConsts.TimePeriodsArray[0] !== value
    ) {
      if (label == "fyYear") {
        setValues({ ...values, [label]: value, period: "" });
      } else setValues({ ...values, [label]: value });
    }
  };
  const getLockStatusAPI = async () => {
    //function to call feth API for given year and period.
    try {
      const token = await authProvider.acquireTokenSilent({
        scopes: APIscope,
      });
      const idToken = token.accessToken;
      dispatch({
        type: "CHECK_LOCK_STATUS",
        payload: {
          token: idToken,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const addMultipleUsersAPI = async () => {
    //function to call feth API for given year and period.
    try {
      const idtoken = await authProvider.acquireTokenSilent({
        scopes: GraphExplorerscopes,
      });
      const token = idtoken.accessToken;
      dispatch({
        type: "ADD_MULTIPLE_USER",
        payload: {
          ad_token: token,
          multi_user_upload: fileInfo,
          financialYear: values.fyYear.replace("FY ", ""),
          phase: values.period,
        },
      });
      setIsLoading(true);
      // myContext.setmultipleUserUploadInProgress(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    //add multiple user  API response
    if (
      !addMultipleUsers.fetching &&
      addMultipleUsers.data &&
      addMultipleUsers.data.message &&
      addMultipleUsers.data.success
    ) {
      //Succefullycompleted
      myContext.setIsWarningPopup(false);
      myContext.setIsLockPopup(false);
      setIsLoading(false);
      if (!document.getElementById(filetoastId)) {
        toast.warn(
          <div>
            <i className="icon-verified-outline" />
            {"Success"}
            <b> {": "}</b>
            {"File Uploaded Successfully"}
          </div>,
          {
            position: "top-center",
            transition: Flip,
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            toastId: filetoastId,
          }
        );
      }

      dispatch({
        type: "ADD_MULTIPLE_USER_RESET",
        payload: {},
      });
      // myContext.setmultipleUserUploadInProgress(false);

      setTimeout(function () {
        getLockStatusAPI();
      }, 15000);
      props.onClose();
    } else if (
      !addMultipleUsers.fetching &&
      addMultipleUsers.data &&
      addMultipleUsers.data.message &&
      !addMultipleUsers.data.success
    ) {
      //upload failed
      // myContext.setmultipleUserUploadInProgress(false);
      myContext.setIsWarningPopup(true);
      myContext.setIsLockPopup(false);
      setIsLoading(false);
      toast.warn(
        <div>
          <i className="icon-verified-outline" />
          <b> {": "}</b>
          {"Multiple User Upload has Failed"}
        </div>,
        {
          position: "top-center",
          transition: Flip,
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      myContext.setIsWarningPopup(false);
    }
  }, [addMultipleUsers]);

  useEffect(() => {
    //lock status API response
    if (
      !getLockStatus.fetching &&
      getLockStatus.data &&
      getLockStatus.data.message &&
      getLockStatus.data.proceedAction
    ) {
      //Succefully Completed
      setIsLoading(false);
      myContext.setDisableUserUpdation(false);
    } else if (
      !getLockStatus.fetching &&
      getLockStatus.data &&
      getLockStatus.data.message &&
      !getLockStatus.data.proceedAction
    ) {
      //not completed
      // setTimeout(function () {
      if (firstTime) {
        setFirstTime(false);
        myContext.setIsWarningPopup(false);
        triggerLock();
      }
      // }, 1000);
      setIsLoading(false);
      myContext.setDisableUserUpdation(true);
      dispatch({
        type: "CHECK_LOCK_STATUS_RESET",
        payload: {},
      });
      setTimeout(function () {
        getLockStatusAPI();
      }, 15000);
    }
    // else if (!getLockStatus.fetching && getLockStatus.error) {
    //   getLockStatusAPI();
    // }
  }, [getLockStatus]);

  useEffect(() => {
    if (progress < 100) {
      setTimeout(function () {
        if (progress == 98 && !isLoading && !headerErrors) {
          setProgress(100);
        } else setProgress(progress + 2);
      }, 1);
    }
  }, [progress]);

  useEffect(() => {
    const fileRejectionItems = fileRejections.map(({ errors }) => {
      return errors.map((e) => e.code);
    });
    if (fileRejectionItems.length > 0) {
      if (fileRejectionItems[0].includes("file-too-large"))
        notify(
          "File size is too large. File size should be within 5MB.",
          "error"
        );
      else if (fileRejectionItems[0].includes("file-invalid-type"))
        notify(
          "File type is not valid.Only,excel file with .xlsx extension can be uploaded. ",
          "error"
        );
      else notify(fileRejectionItems[0], "error");
    }
  }, [fileRejections]);

  useEffect(() => {
    // if (!myContext.multipleUserUploadInProgress)
    // if (!getLockStatus.fetching)
    if (props.currentPageName === "Add Multiple Users") {
      getLockStatusAPI();

      setIsLoading(true);
    }
    // else {
    //   myContext.setIsWarningPopup(false);
    //   myContext.setDisableUserUpdation(true);
    // triggerLock();
    // }
  }, [props.currentPageName]);

  const triggerLock = () => {
    myContext.setIsLockPopup(true);
    if (!document.getElementById(lockId))
      toast.warn(
        <div
          style={{
            // textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "EYInterState-Regular",
            fontSize: "12px",
          }}>
          <i className="icon-warning-outline" />
          {/* "Section Updated Successfully" : */}
          {`Previous operation initiated by ${getLockStatus.data.userName} is still in progress. It will take some time. You
          can perform this action after the operation is completed. We
          appreciate your patience!`}
        </div>,
        {
          position: "top-center",
          transition: Flip,
          autoClose: 15000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: lockId,
        }
      );
  };

  const validateFile = useCallback((file) => {
    if (!file) {
      // setErrorList([]);
      return;
    } else {
      setFileInfo(file);
      setIsPopupVisible(true);
      setPopUpType(0);
    }
  }, []);

  const fileHandler = () => {
    // setIsLoading(true);
    // setErrorList([]);
    readXlsxFile(fileInfo, {
      schema,
      transformData(data) {
        validateColumnHeaders(data[3], data[4]);

        return data.filter(
          (row, index) =>
            row.filter((column) => column !== null).length > 0 && index > 2
        );
      },
    }).then(({ rows, errors }) => {
      // console.log("rows", rows);
      setIsLoading(false);
      validateColumns(rows, errors);
    });
  };
  const validateColumnHeaders = (columnList, firstRowData) => {
    // console.log(headerErrors, "start");
    if (columnList === undefined || firstRowData === undefined) {
      setErrorMsg("First row of data is empty");
      setHeaderError(true);
    } else {
      let skip = false;
      columnList.forEach((value, index) => {
        //check if column headers names and count match
        if (value !== columnNames[index] && !headerErrors) {
          if (!skip) {
            setHeaderError(true);
            if (
              columnNames[index] &&
              !columnList.includes(columnNames[index])
            ) {
              setErrorMsg(
                "Complete " + columnNames[index] + " column is missing"
              );
              skip = true;
            } else if (!columnNames.includes(value)) {
              setErrorMsg("An additional column " + value + " is available");
              skip = true;
            } else {
              setErrorMsg(
                "Order mismatch. " +
                  columnNames[index] +
                  " column is in a different position."
              );
              skip = true;
            }
          }

          //value null  columnNames[index] filed missing missing
          //columnname null is an extra value feild added
          //columnNames[index] field not in correct order
        }
      });
      if (
        firstRowData &&
        firstRowData.filter((column) => column !== null).length == 0
      ) {
        //check if 1st row is empty
        setErrorMsg("First row is empty");
        setHeaderError(true);
      }
    }
  };
  const validateColumns = (rows, errors) => {
    rows.forEach((row) => {
      if (!row.tenorStartDate) {
        if (row.lastPromotionDate) {
          row.tenorStartDate = row.lastPromotionDate
            ? formatDate(row.lastPromotionDate)
            : "";
        } else {
          row.tenorStartDate = row.currentEmploymentStartDate
            ? formatDate(row.currentEmploymentStartDate)
            : "";
        }
      } else {
        row.tenorStartDate = row.tenorStartDate
          ? formatDate(row.tenorStartDate)
          : "";
      }
      if (!row.primarySector) {
        row.primarySector = "All";
      }
      if (!row.secondarySector) {
        row.secondarySector = "All";
      }
    });
    // console.log(rows);
    setJsonData([...rows]);
    // console.log("rows", rows);
    /*const updatedErrors = groupBy(errors, "row");
    if (updatedErrors.length > 0) {
      updatedErrors.forEach((data, index) => {
        const temp = { ...rows[data[0].row - 1], row: data[0].row };
        errorRows.push(temp);
      });
    }
    let updatedErrorRows = [];
    errorRows.forEach((row, index) => {
      const obj = {};
      Object.keys(row).forEach(function (key) {
        obj[key] = {
          value: row[key],
          valid: true,
        };
      });
      updatedErrorRows.push(obj);
    });

    updatedErrorRows.forEach((errorRow, index) => {
      const currentRowErrors = updatedErrors[errorRow.row.value];
      currentRowErrors.forEach((currentRowError) => {
        updatedErrorRows[index][schema[currentRowError.column].prop] = {
          value: currentRowError.value || "*Missing",
          valid: false,
        };
      });
    });
    if (updatedErrorRows.length > 0) {
      notify(
        "Validation failed. Please check Error List Summary for more details",
        "error"
      );
    } else {
      notify("Validation Successful.", "success");
    }
    setErrorList(updatedErrorRows);*/
  };

  /* const groupBy = useCallback((objectArray, property) => {
    return objectArray.reduce(function (acc, obj) {
      var key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, []);
  }, []);

  const isAlpha = (ch) => {
    return /^[A-Z]$/i.test(ch);
  };

  const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };*/

  const formatDate = (date) => {
    var date = new Date(Math.round((date - 25569) * 86400 * 1000));
    var convertedDate = date.toISOString().split("T")[0];
    return convertedDate;
  };

  const notify = useCallback((msg, type) => {
    toast(
      <div className="error-text">
        {type === "error" ? (
          <span className="icon-close-filled icon"></span>
        ) : (
          <span className="eyaa-state-36 icon"></span>
        )}
        <p>
          {type === "error" ? (
            <>
              <span>Error:</span> <span>{msg}</span>
            </>
          ) : (
            <span>{msg}</span>
          )}
        </p>
      </div>,
      {
        className: type,
        position: "top-center",
        transition: Flip,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  useEffect(() => {
    dispatch({
      type: "GET_PHASE_VALIDATION_STATUS",
      payload: {},
    });
  }, [dispatch]);
  useEffect(() => {
    if (
      !getPhaseValidationStatus.fetching &&
      getPhaseValidationStatus.data.length
    ) {
      //not completed
      // setValues({
      //   fyYear: "FY" + getPhaseValidationStatus.data[0].year,
      //   period:
      //     getPhaseValidationStatus.data[0].phase.length > 0
      //       ? getPhaseValidationStatus.data[0].phase[0]
      //       : "",
      // });
      // setPeriodDropdown(getPhaseValidationStatus.data[0].phase);
      if (
        assessmentMetaData &&
        assessmentMetaData.data &&
        assessmentMetaData.data.data
      ) {
        const data = assessmentMetaData.data.data;
        if (data.activePhase == null) {
          getPhaseValidationStatus.data.map((item) => {
            if (item.year === data.lastActivePhaseYear) {
              setPeriodDropdown(item.phase);
              setValues({
                fyYear: "FY " + data.lastActivePhaseYear,
                period: item.lastActivePhase,
              });
            }
          });
        } else {
          getPhaseValidationStatus.data.map((item) => {
            if (item.year === data.activePhaseYear) {
              setPeriodDropdown(item.phase);
            }
          });
          setValues({
            fyYear: "FY " + data.activePhaseYear,
            period: data.activePhase,
          });
        }
      }
    }
  }, [getPhaseValidationStatus]);

  useEffect(() => {
    // console.log(financialYear) .find
    const yearDetails = Array.isArray(getPhaseValidationStatus.data)
      ? getPhaseValidationStatus.data.find(
          (elem) => `FY ${elem.year}` === values.fyYear
        )
      : {};
    if (yearDetails && yearDetails.phase) {
      setPeriodDropdown(yearDetails.phase);
    }
  }, [values.fyYear]);

  const yearArray = Array.isArray(getPhaseValidationStatus.data)
    ? getPhaseValidationStatus.data.map((elem) => {
        return `FY ${elem.year}`;
      })
    : [];

  return (
    <>
      {isLoading && <Loader />}
      {/* <SimpleBar
        style={{
          width: "100%",
          maxHeight: "200px",
          overflowX: "hidden",
          paddingRight: "20px",
        }}> */}
      <div className="multiple-user-container">
        <div className="mainheader">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // marginLeft: "10px",
              width: "272px",
              margiTop: "2px",
            }}>
            <DropdownComponent
              label={"Year*"}
              defaultValue={values.fyYear}
              required={false}
              selectOnChange={(dummy, value) => {
                dropOnChange(value, "fyYear");
              }}
              varName="fyYear"
              list={yearArray}
              disableFirstItem={false}
              errorMessage={errorMessages}
              disabled={myContext.disableUserUpdation}
            />
          </div>{" "}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // marginLeft: "10px",
              margiTop: "2px",
              width: "272px",
            }}>
            <DropdownComponent
              label={"Period*"}
              defaultValue={values.period}
              required={false}
              selectOnChange={(dummy, value) => {
                dropOnChange(value, "period");
              }}
              varName="period"
              list={periodDropdown}
              errorMessage={errorMessages}
              // disableFirstItem={true}
              disabled={myContext.disableUserUpdation}
            />
          </div>
          {/* <div className="title">Upload File to Add Multiple User</div> */}
          <h6>Upload File to Add Multiple User</h6>
        </div>

        {/* <p className="download-template">
            <a href={Template} download>
              <span className="eyaa-arrow-tab-down"></span>Download Template
            </a>
          </p> */}
        {/* <p className="info">
            Selected file should be in the same format as the ‘Download
            template’ format.
          </p> */}
        <section
          style={{
            opacity:
              myContext.disableUserUpdation ||
              (progress > 0 && progress < 100) ||
              progress === 100
                ? 0.5
                : 1,
          }}>
          <div className="file-upload-container" {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="helper-text">
              <p>Drag your file here to Upload</p>
              <p>Maximum file size: 5MB</p>
            </div>
            <label
              htmlFor="uploadFile"
              style={{
                cursor:
                  myContext.disableUserUpdation ||
                  (progress > 0 && progress < 100) ||
                  progress === 100
                    ? "not-allowed"
                    : "pointer",
              }}>
              {" "}
              <span className="eyaa-files-104 file-icon"></span>{" "}
              <span>Choose file</span>
            </label>
          </div>
        </section>
        {enableProgress && (
          <div
            className="shadowBox"
            style={{
              borderLeftColor: `${progress === 100 ? "#b1de76" : "#2e2e38"}`,
            }}>
            <div className="title">
              {progress === 100 ? "Validation Completed" : "Validating file"}
              {headerErrors && <div className="errorMsg">{"File Error"}</div>}
            </div>
            {progress !== 100 ? (
              <div className="blackLine">
                <div
                  className="yelloLine"
                  style={{ left: `${(progress / 100) * 330}px` }}>
                  {"&nbsp;"}
                </div>
              </div>
            ) : (
              <div className="greenLine">{"&nbsp;"}</div>
            )}
            <div className="row">
              <div className="fileN"> {fileInfo.name}</div>
              <div className="progress">
                {" "}
                {progress +
                  " % " +
                  (progress === 100 ? "Complete" : "Processing...")}
              </div>
            </div>
          </div>
        )}

        {headerErrors && (
          <div className="error-section">
            <span className="redMsg">
              {" "}
              <i className="icon-warning-filled" />
              File Error:{errorMsgs}
            </span>
            <div className="desc">
              {
                "The file which you have uploaded is not in the correct format. Please check the file and upload again"
              }
            </div>
          </div>
        )}
        {/* {myContext.disableUserUpdation && (
          <div className="error-section">
            <span className="redMsg">
              {" "}
              <i className="icon-warning-filled" />
            </span>
            <div className="desc">
              {
                "Users are being added. It will take some time. You can perform this action after the operation is completed. We appreciate your patience!"
              }
            </div>
          </div>
        )} */}

        {/*errorList.length > 0 && (
            <div className="error-list-header">
              <span>Error List Summary</span>
              <p>
                <ErrorReport errors={errorList} />
                <span className="error-list-close" onClick={() => setErrorList([])}>&times;</span>
              </p>
            </div>
          )*/}
        {/*errorList.length > 0 && (
            <SimpleBar
              style={{
                width: "100%",
                height: "auto",
                marginTop: "-10px",
              }}
            >
              <div className="table-container">
                <table>
                  <thead>
                    <tr>
                      {errorListHeaders.length > 0 &&
                        errorListHeaders.map((header, index) => (
                          <th key={index}>{header}</th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {errorList.length > 0 &&
                      errorList.map((errors, index) => (
                        <tr key={index}>
                          {errorListHeaders.map((header, i) => (
                            <td
                              key={header}
                              className={classNames({
                                "col-error": !errors[mapTemplateProps[i]].valid,
                              })}
                            >
                              {header === "Row No"
                                ? parseInt(
                                    errors[mapTemplateProps[i]].value + 4
                                  )
                                : errors[mapTemplateProps[i]].value}
                            </td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </SimpleBar>
                                )*/}
        <div className="AdminActions">
          <>
            <div
              className="cancel"
              onClick={() => {
                props.onClose();
              }}>
              Cancel
            </div>

            <div
              className={
                "save" +
                (!headerErrors &&
                progress === 100 &&
                !myContext.disableUserUpdation &&
                values.fyYear !== "" &&
                values.period !== ""
                  ? ""
                  : " disable-btn ")
              }
              onClick={() => {
                setIsPopupVisible(true);
                setPopUpType(1);
              }}>
              Add User
            </div>
          </>
        </div>
      </div>
      {/* </SimpleBar> */}
      {isPopupVisible && (
        <div className="popup-dialog">
          <div className="dialog">
            <div className="header">
              <h5>{popupType == 0 ? "File Validation" : "Confirmation"}</h5>
              <span
                style={{ position: "absolute", right: "12px", top: "10px" }}
                className="icon-close-filled icon"
                onClick={() => setIsPopupVisible(false)}></span>
            </div>
            {popupType == 0 ? (
              <div className="content">
                <span>Selected file</span>
                <b> {fileInfo.name}</b>
                <span> should be validated before uploading.</span>
              </div>
            ) : (
              <div className="content">Would you like to add the users? </div>
            )}
            {popupType == 1 && (
              <div className="content" style={{ marginTop: "10px" }}>
                Please note that this will take some time. Once the process is
                completed, we will share the summary report over email.
              </div>
            )}
            {popupType == 0 ? (
              <div className="footer">
                <button
                  className="cancel-btn"
                  onClick={() => {
                    setIsPopupVisible(false);
                    setEnableProgress(false);
                    setFileInfo(null);
                    setHeaderError(false);
                  }}>
                  Cancel
                </button>
                <button
                  className="validate-btn"
                  onClick={() => {
                    setEnableProgress(true);
                    setIsPopupVisible(false);
                    setProgress(0);
                    fileHandler();
                    setHeaderError(false);
                  }}>
                  Validate
                </button>
              </div>
            ) : (
              <div className="footer">
                <button
                  className="cancel-btn"
                  onClick={() => {
                    setIsPopupVisible(false);
                  }}>
                  Cancel
                </button>
                <button
                  className="validate-btn"
                  onClick={() => {
                    setIsPopupVisible(false);
                    setEnableProgress(false);
                    // setIsLoading(true);
                    myContext.setDisableUserUpdation(true);
                    addMultipleUsersAPI();
                    dispatch({
                      type: "CHECK_LOCK_STATUS_RESET",
                      payload: {},
                    });
                  }}>
                  Confirm
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default AddMultipleUsers;
