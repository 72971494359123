import { IconButton, InputBase, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import moment from 'moment';
import { filterList } from '../../../../utils/utils';

const EmailListing = (props) => {
    const { setView, tableHeaders = [], emailList = [], onCancel } = props;
    const [searchSubject, setSearchSubject] = useState('');
    const [list, setList] = useState([]);

    const filterAdhocMails = (list = [], str = '') => {
        const formattedList = list.map(({ sendBy, sendOn, sendTo = [], subject, type, batch_id, ...rest }) => {
            const formattedSendTo = sendTo.join(', ') || '';
            const formattedSendOn = moment(sendOn).format("DD-MM-YYYY HH:mm")
            const adhocMailDetails = { sendTo: formattedSendTo, sendOn: formattedSendOn, sendBy, subject, type, batch_id };
            return adhocMailDetails;
        });
        const ignoreProperties = ['batch_id'];
        const filteredList = filterList(formattedList, str, ignoreProperties);
        return filteredList;
    }

    useEffect(() => {
        setList(filterAdhocMails(emailList, searchSubject));
    }, [emailList, searchSubject]);

    const disableAction = (time, type) => {
        if (type !== 'scheduled') return true;
        const diffInMins = moment(time,'DD-MM-YYYY HH:mm').diff(moment(moment(new Date()).format("DD-MM-YYYY HH:mm"),'DD-MM-YYYY HH:mm'), 'minutes');
        return !(diffInMins >= 15);
    }

    const getHeaderWidth = key => {
        let width = '12.5'
        switch (key) {
            case 'subject':
                width = '45'
                break;
            case 'sendTo':
                width = '15'
                break;
            case 'type':
                width = '10'
                break;
            default:
                break;
        }
        return width;
    }

    return (
        <>
            <div className="input-container">
                <div className="input-search-group">
                    <Paper className="form">
                        <InputBase
                            className="input-search"
                            style={{ width: 260 }}
                            sx={{ ml: 1, flex: 1 }}
                            placeholder="Search"
                            inputProps={{ "aria-label": "search", maxLength: 255 }}
                            value={searchSubject}
                            onChange={(e) => setSearchSubject(e.target.value)}
                        />
                        <IconButton
                            type="button"
                            sx={{ p: "10px" }}
                            aria-label="search"
                        />
                    </Paper>
                </div>
                <div
                    onClick={() => setView('new-adhoc-email')}
                    className={"butn yellow-color refresh-btn"}
                >
                    <i style={{ paddingRight: '5px' }} className="icon-plus" />New Email
                </div>
            </div>
            {emailList.length > 0 ?
                <div className="email-details">
                    <div className="table-container">
                        <TableContainer>
                            <Table sx={{ minWidth: 650 }} stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        {tableHeaders.map((header, index) => (
                                            <TableCell key={index} component="th" scope="row" width={`${getHeaderWidth(header.key)}%`}>{header.value}</TableCell>
                                        ))}
                                        <TableCell key="actions">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </TableContainer>
                        {list.length > 0 ?
                            <SimpleBar
                                style={{
                                    maxHeight: "62vh",
                                }}
                            >
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }}>
                                        <TableBody>
                                            {list.map(email => (
                                                <TableRow>
                                                    {tableHeaders.map((header, index) => (
                                                        <TableCell key={index} width={`${getHeaderWidth(header.key)}%`}>
                                                            {email[header.key]}
                                                        </TableCell>
                                                    ))}
                                                    <TableCell>
                                                        <div style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                                                            <span
                                                                className={classNames("eyaa-state-14", {
                                                                    "disable-icon": disableAction(email.sendOn,email.type),
                                                                })}
                                                                onClick={() => {
                                                                    if (!disableAction(email.sendOn,email.type)) {
                                                                        onCancel({ batch_id: email.batch_id });
                                                                    }
                                                                }}></span>
                                                        </div>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </SimpleBar>
                            : <div className="infoText">
                                {" "}
                                No Ad-hoc emails as per searched text{" "}
                            </div>}
                    </div>
                </div>
                : <div className="infoText">
                    {" "}
                    Click 'New Email' button to draft and send Ad-hoc email{" "}
                </div>}
        </>
    );
}

export default EmailListing;
