export const columnNames = [
    "FiscalYear",
    "AccountingCycleDate",
    "CurrencyTypeCd",
    "CountryCd",
    "EngagementId",
    "Engagement",
    "EngagementType",
    "OpportunityID",
    "OpportunityName",
    "AccountId",
    "Account",
    "ClientId",
    "Client",
    "EngagementArea",
    "EngagementRegion",
    "EngagementCountry",
    "EngagementServiceLine",
    "EngagementSubServiceLine",
    "EngagementCompanyCd",
    "EngagementCurrency",
    "AccountSector",
    "AccountChannel",
    "EngagementPartnerId",
    "EngagementPartner",
    "EngagementManagerId",
    "EngagementManager",
    "GlobalSLPartnerId",
    "GlobalSLPartner",
    "RegionalSLPartnerId",
    "RegionalSLPartner",
    "EngagementServiceCode",
    "EngagementService",
    "Last_ETC",
    "Last_Active_ETC",
    "Last_Passive_ETC",
    "ChargedHours_FYTD",
    "ANSR_FYTD",
    "Margin_FYTD",
    "TER_FYTD",
    "ChargedHours_PFYTD",
    "ANSR_PFYTD",
    "Margin_PFYTD",
    "TER_PFYTD",
    "ChargedHours_PFY",
    "ANSR_PFY",
    "Margin_PFY",
    "TER_PFY",
    "NUI_ETD_PFYTD",
    "NUI_ETD_PFY",
    "NUI_ETD",
    "ChargedHours_Forecast",
    "ANSR_Forecast",
    "TER_Forecast",
    "Margin_Forecast",
    "LastUpdate",
    "FiscalMonth",
    "EngagementCompetency",
    "EngagementStatus",
    "EngagementService_Desc",
    "BilledFeeAmt_FYTD",
    "BilledExpenseAmt_FYTD",
    "BilledFeeAmt_PFYTD",
    "BilledExpenseAmt_PFYTD",
    "BilledFeeAmt_PFY",
    "BilledExpenseAmt_PFY",
    "SECIndicator",
    "FiscalWeek",
    "ClientFiscalYear",
    "TechnologyIndicatorCd",
    "TechnologyIndicatorNm",
    "UpliftCostAmt_FYTD",
    "UpliftCostAmt_PFYTD",
    "UpliftCostAmt_PFY",
    "MarginCost_FYTD",
    "MarginCost_PFYTD",
    "MarginCost_PFY",
    "FytdTechnologyCostAmt",
    "PFytdTechnologyCostAmt",
    "PFYTechnologyCostAmt",
    "EngagementIndicatorCd",
    "EngagementIndicatorNm"
];