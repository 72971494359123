import _ from "lodash";
import * as myConsts from "./../constants/constants";
const timePeriods = myConsts.TimePeriodsArray;

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

export const images = importAll(
  require.context("../assets/images", false, /\.(png|jpe?g|svg|pdf|gif)$/)
);

export const getEYFinancialYear = () => {
  const today = new Date();
  if (today.getMonth() + 1 < 7) {
    return today.getFullYear();
  } else {
    return today.getFullYear() + 1;
  }
};

function b64DecodeUnicode(str) {
  return decodeURIComponent(
    atob(str).replace(/(.)/g, function (m, p) {
      var code = p.charCodeAt(0).toString(16).toUpperCase();
      if (code.length < 2) {
        code = "0" + code;
      }
      return "%" + code;
    })
  );
}

function base64_url_decode(str) {
  var output = str.replace(/-/g, "+").replace(/_/g, "/");
  switch (output.length % 4) {
    case 0:
      break;
    case 2:
      output += "==";
      break;
    case 3:
      output += "=";
      break;
    default:
      throw "Illegal base64url string!";
  }

  try {
    return b64DecodeUnicode(output);
  } catch (err) {
    return atob(output);
  }
}

export function decodeIdToken(token, options) {
  options = options || {};
  var pos = options.header === true ? 0 : 1;
  try {
    return JSON.parse(base64_url_decode(token.split(".")[pos]));
  } catch (e) {
    console.log(e.message);
  }
}
/* export const decodeIdToken = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    Buffer.from(base64, "base64")
      .toString()
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}; */

export const extractFirstLetters = (userData) => {
  let firstName =
    userData && userData.data && userData.data.givenName
      ? userData.data.givenName
      : "";
  let lastName =
    userData && userData.data && userData.data.surname
      ? userData.data.surname
      : "";

  let firstLetters =
    (firstName.length > 0 ? firstName[0] : "") +
    (lastName.length > 0 ? lastName[0] : "");

  return firstLetters;
};

export const removeSpecialChars = (val) => {
  return val.replace(/[^\w\s]/gi, "");
};

export const removeEmailSpecialChars = (email) => {
  return email.replace(/[^a-zA-Z0-9_/-@.]/g, "");
};
export const removeSpecialCharsComments = (val) => {
  return val.replace(/[^a-zA-Z0-9 _/-/!/?%/$/(/)/.,&\n]/g, "");
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateUrl = (url) => {
  const re =
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
  return re.test(String(url));
}

export const filterList = (list = [], str = '', ignoreProperties = []) => {
  const filteredList = list.filter((obj) => {
    return Object.keys(obj).reduce((acc, curr) => {
      return acc || (ignoreProperties.findIndex(ele => (ele === curr)) === -1 && (obj[curr] && obj[curr].toLowerCase().includes(str.toLowerCase())));
    }, false);
  });
  return filteredList;
}

export const errorCheck = (errorMessage = {}) => {
  return Object.keys(errorMessage).reduce((acc, curr) => {
    let isError = false;
    if (_.isEmpty(errorMessage[curr]))
      isError = false;
    else if (Array.isArray(errorMessage[curr]))
      isError = errorMessage[curr].some(ele => (ele !== ''))
    else
      isError = errorMessage[curr] !== ''
    return acc || isError;
  }, false);
}
export const errorMessageText = (property) => {
  let label = property;
  switch (property) {
    case 'uiType':
      label = 'goalType';
      break;
    case 'optionList':
      label = 'option';
      break;
    case 'scoreList':
      label = 'score';
      break;
    case 'metricType':
      label = 'globalMetricType';
      break;
    case 'isCheckBoxCommited':
      label = 'commited';
      break;
    default:
      break;
  }
  const msg = `${_.startCase(label)} is required`;
  return msg;
}

export const getErrorMessages = (obj = {}, properties = []) => {
  return properties.reduce((acc, property) => {
    let error = ''
    if (!obj.hasOwnProperty(property))
      error = errorMessageText(property);
    if (Array.isArray(obj[property]))
      error = obj[property].map(ele => ((ele !== '' && ele !== null && ele !== undefined) ? '' : errorMessageText(property)));
    else
      error = (obj[property] !== '' && obj[property] !== undefined) ? '' : errorMessageText(property);
    return {
      ...acc, [property]: error
    }
  }, {});
}

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const getFinancialYears = (fy = getEYFinancialYear(), keyValue = false) => {
  const years = [-2, -1, 0, 1, 2];
  const financialYear = years.map(year => (fy + year))
    .filter(year => year >= 2022)
    .map(year => {
      const data = keyValue ? ({ key: year, value: (`FY ${year}`) }) : (`FY ${year}`)
      return data;
    });
  return financialYear;
}

export const routeCompare = (path1, path2) => {
  try {
    if (path1?.toLowerCase() === path2?.toLowerCase())
      return true;
    else if (path1.split("/:") && path1.split("/:").length > 1) {
      const tempPath = path2
        ?.split("/")
        .filter((e, i) => i < path2.split("/").length - 1)
        .join("/")
        .toLowerCase();
      const tempRoute = path1
        ?.split("/:")
        .filter((e, i) => i < path1.split("/:").length - 1)
        .join("/")
        .toLowerCase();
      return tempPath === tempRoute;
    } else return false;
  } catch (error) {
    return false;
  }
}

export const getBaseLinePeroid = (currentPeriod = '', data = {}) => {
  let obj = {};
  obj = Object.keys(data).reduce((data, ele) => {
    if (ele !== currentPeriod) data.period = ele;
    return data;
  }, {})
  if (!obj.period)
    obj.period = timePeriods[timePeriods.indexOf(currentPeriod) - 1]
  return obj.period;
}

export const capitalizeFirstLetter = (string) => {
  if (string) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return string;
  }
}