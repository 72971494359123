import { rgb } from "d3";
import React, { useEffect, useMemo } from "react";
import { useCallback } from "react";
import "./progressChart.scss";
import classNames from "classnames";

function ProgressChart(props) {
  const {
    pillarName,
    pillarScore = 0,
    peerGroupAvg = 0,
    radius = 45,
    fillSize = 11,
    isTitleBottomVisible = false,
    isScoreTextVisible = true,
    isScoreVisible = true,
  } = props;
  useEffect(() => {
    const c = document.getElementById(pillarName);
    const ctx = c.getContext("2d");

    const gradient = ctx.createLinearGradient(0, 0, 0, radius * 2 + 30);
    gradient.addColorStop(0, "rgb(255,229,2)");
    gradient.addColorStop(1, "rgb(191, 191, 191)");

    ctx.beginPath();
    ctx.arc(radius + 10, radius + 10, radius, 0.5 * Math.PI, 1.5 * Math.PI);
    ctx.lineWidth = fillSize;
    ctx.strokeStyle = gradient;
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(
      radius + 10,
      radius + 10,
      radius,
      0.5 * Math.PI,
      1.5 * Math.PI,
      true
    );
    ctx.lineWidth = fillSize;
    ctx.strokeStyle = rgb(191, 191, 191);
    ctx.stroke();
    // eslint-disable-next-line
  }, [pillarName]);

  const markerSize = useMemo(() => {
    return fillSize - 3;
  }, [fillSize]);

  const getRotateContainer = useCallback((score, isPeerGroupAvg)=> {
    return (
      <div
          className="rotateContainer"
          style={{
            transform: `rotate(${score * 3.6}deg)`,
            width: `${radius * 2 + 20}px`,
            height: `${radius * 2 + 20}px`,
          }}
        >
          {parseInt(score) !== 0 && (
            <div
              className={classNames('progressMarker', {
                'greenMarker': isPeerGroupAvg
              })}
              style={{
                borderWidth: `0px ${markerSize}px ${markerSize}px ${markerSize}px`,
              }}
            ></div>
          )}
        </div>
    )
  }, [markerSize, radius]);
  return (
    <div className="chartContainer">
      <div className="circleWrapper">
        {!isTitleBottomVisible && <p className="header">{pillarName}</p>}
        {getRotateContainer(pillarScore, false)}
        {parseInt(peerGroupAvg) !== 0 && getRotateContainer(peerGroupAvg, true)}
        <div className="arcContainer" style={{ width: radius * 2 + 20 + "px" }}>
          <canvas
            id={pillarName}
            width={radius * 2 + 20}
            height={radius * 2 + 20}
          ></canvas>
          <div className="scoreContainer">
            {isScoreVisible && <p>{pillarScore}</p>}
            {isScoreTextVisible && <p>score</p>}
          </div>
        </div>
        {isTitleBottomVisible && <p className="header">{pillarName}</p>}
      </div>
    </div>
  );
}

export default ProgressChart;
