import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import "./AdminPopup.scss";
import SimpleBar from "simplebar-react";
import SortableList, { SortableItem } from "react-easy-sort";
import arrayMove from "array-move";
const ChangeOrder = (props) => {
  const [listData, setListData] = useState([]);
  useEffect(() => {
    
    if(props.tabName==="globalMetrics"){
      setListData([...props.assessmentData]);
    }else if(props.tabName==="sections"){
      setListData([...props.sectionData]);
    }
    
  }, []);

  useEffect(() => {
    props.passListUpwards(listData);
    // console.log(listData);
  }, [listData]);

  const onSortEnd = (oldIndex, newIndex) => {
    setListData((array) => arrayMove(array, oldIndex, newIndex));
  };
  return (
    <div className="order-change">
      <div className="helper">Drag and place to change the order</div>
      <div className="column">
        <SimpleBar style={{ maxHeight: "270px" }}>
          <SortableList
            onSortEnd={onSortEnd}
            className="list"
            draggedItemClassName="dragged">
            {listData &&
              listData.map((item) => {
                return (
                  <SortableItem key={item}>
                    <div className={"card"} onClick={() => {}}>
                      <div className="rgtborder">
                        <i
                          className=" icon-drag"
                          style={{ margin: "0px 4px", fontSize: "15px" }}
                        />
                      </div>

                      {props.tabName==="globalMetrics"?item.pillarDisplayName:item.name}
                    </div>
                  </SortableItem>
                );
              })}
          </SortableList>
        </SimpleBar>
      </div>
    </div>
  );
};
export default withRouter(ChangeOrder);
