import React, { useCallback, useState, useEffect, useContext, useMemo } from "react";
import DropdownComponent from "../../../Admin/AdminGeneralParam/DropdownComponent/DropdownComponent";
import AppContext from "../../../../utils/appContext";
import * as myConsts from "../../../../constants/constants";
import "./EntireAssessment.scss";
import { EXPORT_ENTIRE_ASSESSMENT_CONTENT, EXPORT_ENTIRE_ASSESSMENT_CONTENT_RESET } from "../../../../actions/types";
import { useDispatch, useSelector } from "react-redux";
import { Flip, toast } from "react-toastify";
import Loader from "../../../Loader/Loader";
import _ from "lodash";

const EntireAssessment = (props) => {
  const myContext = useContext(AppContext);
  const dispatch = useDispatch()
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const exportEntireAssessment = useSelector((state) => state.exportEntireAssessmentData);
  const [loader, setLoader] = useState(false);
  const getCompletedPhase = useSelector(
    (state) => state.getCompletedPhase
  );

  const [phaseDropdown, setPhaseDropdown] = useState([]);
  const [values, setValues] = useState({
    fyYear: "",
    period: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    fyYear: "",
    period: "",
  });

  const dropOnChange = (value, label) => {
    // setValues({ ...values, [label]: value });
    if (label == "fyYear") {
      setValues({ ...values, [label]: value, period: "" });
    } else setValues({ ...values, [label]: value });
  };

  useEffect(()=>{
    dispatch({
      type: "GET_COMPLETED_PHASE",
      payload: {},
    });
  }, [])

  const exportEntireAssessmentContent = () => {

    dispatch({
      type: EXPORT_ENTIRE_ASSESSMENT_CONTENT,
      payload: {
        fyYear: values.fyYear.replace("FY ", ""),
        period: values.period,
      },
    });
    setIsPopupVisible(false);
  }

  const showToastMessage = type => {
    let message = ''
    switch (type) {
      case 'export':
        message = 'Entire assessment content export initiated'
        break;
      default:
        break;
    }
    toast(
      <div className="success-text">
        <span className="icon-verified-outline icon"></span>
        <p>
          <span>{message}</span>
        </p>
      </div>,
      {
        position: "top-center",
        transition: Flip,
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }

  useEffect(() => {
    if (
      exportEntireAssessment &&
      exportEntireAssessment.data &&
      !exportEntireAssessment.fetching &&
      exportEntireAssessment.data.message &&
      exportEntireAssessment.data.message === "Export success"
    ) {
      showToastMessage('export');
      setLoader(false);
      dispatch({
        type: EXPORT_ENTIRE_ASSESSMENT_CONTENT_RESET,
        payload: {},
      });
    } else if (
      exportEntireAssessment &&
      exportEntireAssessment.data &&
      exportEntireAssessment.fetching
    ) {
      setLoader(true);
    }
  }, [exportEntireAssessment, dispatch]);

  useEffect(() =>{
    if(getCompletedPhase.fetching){
      setLoader(true);
    } else if (!getCompletedPhase.fetching && getCompletedPhase.data && getCompletedPhase.data.length){
      setLoader(false);
      setValues({
        fyYear: ("FY " + getCompletedPhase.data[0].year),
        period: (getCompletedPhase.data[0].phase[0])
      });
      setPhaseDropdown(getCompletedPhase.data[0].phase);
    }
  }, [getCompletedPhase]);

  const yearArr = Array.isArray(getCompletedPhase.data) ? getCompletedPhase.data.map((item) => {
    return `FY ${item.year}`
  }) : [];

  useEffect(() =>{
    const yearDetails = Array.isArray(getCompletedPhase.data) ? getCompletedPhase.data.find((item) => `FY ${item.year}` === values.fyYear) : {}
    if(yearDetails && yearDetails.phase){
      setPhaseDropdown(yearDetails.phase);
    }
  }, [values.fyYear]);

  return (
    <>
      {loader && <Loader />}
      <div className="entire-assessment-data-container">
        <div className="topheader">From</div>
        <div className="mainheader">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
              width: "270px",
              margiTop: "2px",
            }}
          >
            <DropdownComponent
              label={"Year"}
              defaultValue={values.fyYear}
              required={true}
              selectOnChange={(dummy, value) => {
                dropOnChange(value, "fyYear");
              }}
              varName="fyYear"
              // list={[
              //   "FY " + (myContext.financialYear - 1),
              //   "FY " + myContext.financialYear,
              //   "FY " + (myContext.financialYear + 1),
              //   "FY " + (myContext.financialYear + 2),
              // ]}
              list={yearArr}
              errorMessage={errorMessages}
            //   disabled={myContext.disableUserUpdation}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "30px",
              width: "270px",
              margiTop: "2px",
            }}
          >
            <DropdownComponent
              label={"Period"}
              defaultValue={values.period}
              required={true}
              selectOnChange={(dummy, value) => {
                dropOnChange(value, "period");
              }}
              varName="period"
              list = {phaseDropdown}
              errorMessage={errorMessages}
            //   disabled={myContext.disableUserUpdation}
            />
          </div>
          <div className="AdminActions-assessment">
            <>
              <div
                className="cancel"
                onClick={() => {
                  props.onClose();
                }}
              >
                Cancel
              </div>
              <div
               className={
                "save " + (values.fyYear !== "" && values.period !== "" ? "" : "disable-btn")
              }
                onClick={() => {
                  setIsPopupVisible(true);
                  //   setPopUpType(1);
                }}
              >
                Export
              </div>
            </>
          </div>
        </div>
      </div>
      {isPopupVisible && (
        <div className="popup-dialog">
          <div className="dialog">
            <div className="header">
              <h5 className="popup-header">Confirmation</h5>
              <span
                className="icon-close-filled icon"
                onClick={() => setIsPopupVisible(false)}
              ></span>
            </div>
            <div className="content">
              <p>Would you like to export the entire assessment content?</p>
              <p>
                Please note that this operation would take some time. Once completed, we will share the report over an email.
              </p>
            </div>
            <div className="footer">
              <button className="cancel-btn" onClick={() => setIsPopupVisible(false)}>
                Cancel
              </button>
              <button className="confirm-btn" onClick={exportEntireAssessmentContent}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default EntireAssessment;
