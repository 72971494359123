import React from "react";
import * as myConsts from "../../constants/constants";
import "./AcceptPrivacy.scss";
import Overview from "../survey/images/Overview.svg";

const AcceptPrivacy = (props) => {
  return (
    <div className="accept-privacy">
      <div className="wrap-privacy">
        <div className="container-privacy">
          <span>
            Thank you for accessing LEAD 4D. Kindly click login and accept the
            privacy notice to proceed to the LEAD 4D home page.
          </span>
          <img src={Overview} alt="" />
        </div>
        <p>
          <div
            className="btn-login"
            onClick={() => props.history.push(myConsts.routeHome)}
          >
            Login
          </div>
        </p>
      </div>
    </div>
  );
};

export default AcceptPrivacy;
