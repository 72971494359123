import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import moment from "moment";
import AppContext from "../../../utils/appContext";
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const DownloadTemplateSelfDG = (props) => {
    const { data } = props;

    const myContext = useContext(AppContext);
    const workbook = new Excel.Workbook();
    const workSheetName = 'Discussion Guide';

    const exclusionList = [
        "revenueMarginContent",
        "ecsContent",
        "peopleAndTeamingContent",
        "salesAndPipelineContent",
        "developmentPointsContent",
        "potentialAndAspirationsContent",
        "name"
    ];

    const discusssionGuideReport = useMemo(() => {
        return [
            // {
            //   header: "Partner Name",
            //   key: "name",
            //   width: 40,
            // },
            {
                header: "Last Name",
                key: "lastName",
                width: 30,
            },
            {
                header: "First Name",
                key: "firstName",
                width: 30,
            },
            {
                header: "Partner Email",
                key: "userId",
                width: 30,
            },
            {
                header: "GUI",
                key: "gui",
                width: 20,
            },
            {
                header: "Office",
                key: "office",
                width: 30,
            },
            {
                header: "Rank",
                key: "rank",
                width: 30,
            },
            {
                header: "Region",
                key: "region",
                width: 30,
            },
            {
                header: "Primary Sector",
                key: "primarySector",
                width: 30,
            },
            {
                header: "Lead Reviewer Name",
                key: "leadReviewer",
                width: 30,
            },
            {
                header: "Lead Reviewer GUI",
                key: "leadReviewerGUI",
                width: 20,
            },
            {
                header: "Discussion Question 1",
                key: "question1AnsContent",
                width: 70,
            },
            {
                header: "Summary of Roles/Areas of Responsibility",
                key: "question2AnsContent",
                width: 70,
            },
            {
                header: "Revenue/Margin",
                key: "revenueMargin",
                width: 70,
            },
            {
                header: "Sales and Pipeline",
                key: "salesAndPipeline",
                width: 70,
            },
            {
                header: "Quality",
                key: "qualityContent",
                width: 70,
            },
            {
                header: "Exceptional Client Service",
                key: "ecs",
                width: 70,
            },
            {
                header: "People & Teaming",
                key: "peopleAndTeaming",
                width: 70,
            },
            {
                header: "Summary Comments",
                key: "distinguishPerformanceContent",
                width: 70,
            },
            // {
            //   header: "Draft Finalization Form Comment 2",
            //   key: "developmentPointsContent",
            //   width: 250,
            // },
            // {
            //   header: "Draft Finalization Form Comment 3",
            //   key: "potentialAndAspirationsContent",
            //   width: 250,
            // },
            {
                header: "Submitted Date",
                key: "submittedDate",
                width: 20,
            },
            {
                header: "Status",
                key: "status",
                width: 20,
            }
        ];
    }, []);

    //..HANDLERS
    const getPlainText = useCallback((value) => {
        try {
            let arrFormatted = [], plainText = "";
            let isStringOrArray = false;
            if (typeof value === 'string' || Array.isArray(value)) {
                isStringOrArray = true;
            }
            if (!isStringOrArray) {
                return value;
            }
            if (value.includes("<li>")) {
                const av = value.split("<li>");
                av.map((v, i) => {
                    if (!v.includes("<ul>")) {
                        document.getElementById('dvContent').innerHTML = v;
                        let vText = document.getElementById('dvContent').innerText;
                        arrFormatted.push(`• ${vText}\r\n`);
                    }
                });
                plainText = arrFormatted.join("");
            } else {
                document.getElementById('dvContent').innerHTML = value;
                plainText = document.getElementById('dvContent').innerText;
            }
            // document.getElementById('dvContent').innerHTML = content;
            // let plainText = document.getElementById('dvContent').innerText;
            return plainText;
        } catch (e) {
            console.log(`DownloadTemplate_PPMD::ERR:: ${e}`);
            return value;
        }
    }, []);

    const processData = useCallback((value, label) => {
        //debugger;
        // let arrFormatted = [], reviewerAudit = "";
        // if (value.includes("<li>")) {
        //   const av = value.split("<li>");
        //   av.map((v, i) => {
        //     if (!v.includes("<ul>")) {
        //       document.getElementById('dvContent').innerHTML = v;
        //       let vText = document.getElementById('dvContent').innerText;
        //       arrFormatted.push(`• ${vText}\r\n`);
        //     }
        //   });
        //   reviewerAudit = arrFormatted.join("");
        // } else {
        //   document.getElementById('dvContent').innerHTML = value;
        //   reviewerAudit = document.getElementById('dvContent').innerText;
        // }
        let reviewerAudit = getPlainText(value);
        // document.getElementById('dvContent').innerHTML = value;
        // let reviewerAudit = document.getElementById('dvContent').innerText;
        let formattedValue = [];
        //debugger;
        switch (label) {
            case "name":
            case "firstName":
            case "lastName":
            case "userId":
            case "leadReviewer":
            case "leadReviewerGUI":
            case "gui":
            case "office":
            case "rank":
            case "region":
            case "primarySector":
            case "status":
            case "question2AnsContent":
            case "qualityContent":
                formattedValue.push("\n");
                formattedValue.push(`${reviewerAudit}`);
                reviewerAudit = formattedValue.join(`\n`);
                break;
            case "question1AnsContent":
                formattedValue.push("\n");
                formattedValue.push("What additional accomplishments/activities would you like to discuss not readily apparent above or in your feedback?");
                formattedValue.push(`\n`);
                formattedValue.push(`${reviewerAudit}`);
                reviewerAudit = formattedValue.join(`\n`);
                break;
            case "revenueMargin":
                // document.getElementById('dvContent').innerHTML = data.revenueMarginContent;
                // reviewerAudit = document.getElementById('dvContent').innerText;
                formattedValue.push("\n");
                formattedValue.push("Response:");
                //formattedValue.push(`\n`);
                formattedValue.push(`${data.revenueMargin}`);
                formattedValue.push(`\n`);
                formattedValue.push("Comments on Revenue/Margin:");
                //formattedValue.push(`\n`);
                formattedValue.push(`${getPlainText(data.revenueMarginContent)}`);
                reviewerAudit = formattedValue.join(`\n`);
                break;
            case "salesAndPipeline":
                // document.getElementById('dvContent').innerHTML = data.salesAndPipelineContent;
                // reviewerAudit = document.getElementById('dvContent').innerText;
                formattedValue.push("\n");
                formattedValue.push("Response:");
                //formattedValue.push(`\n`);
                formattedValue.push(`${data.salesAndPipeline}`);
                formattedValue.push(`\n`);
                formattedValue.push("Comments on Sales and Pipeline:");
                //formattedValue.push(`\n`);
                formattedValue.push(`${getPlainText(data.salesAndPipelineContent)}`);
                reviewerAudit = formattedValue.join(`\n`);
                break;
            case "ecs":
                // document.getElementById('dvContent').innerHTML = data.ecsContent;
                // reviewerAudit = document.getElementById('dvContent').innerText;
                formattedValue.push("\n");
                formattedValue.push("Response:");
                //formattedValue.push(`\n`);
                formattedValue.push(`${data.ecs}`);
                formattedValue.push(`\n`);
                formattedValue.push("Comments on ECS:");
                //formattedValue.push(`\n`);
                formattedValue.push(`${getPlainText(data.ecsContent)}`);
                reviewerAudit = formattedValue.join(`\n`);
                break;
            case "peopleAndTeaming":
                // document.getElementById('dvContent').innerHTML = data.ecsContent;
                // reviewerAudit = document.getElementById('dvContent').innerText;
                formattedValue.push("\n");
                formattedValue.push("Response:");
                //formattedValue.push(`\n`);
                formattedValue.push(`${data.peopleAndTeaming}`);
                formattedValue.push(`\n`);
                formattedValue.push("Comments on People and Teaming:");
                //formattedValue.push(`\n`);
                formattedValue.push(`${getPlainText(data.peopleAndTeamingContent)}`);
                reviewerAudit = formattedValue.join(`\n`);
                break;
            case "distinguishPerformanceContent":
                formattedValue.push("\n");
                formattedValue.push("Describe the top 2-4 distinguished performance behaviors exhibited during the fiscal year:");
                //formattedValue.push(`\n`);
                formattedValue.push(`${reviewerAudit}`);
                //..developmentPointsContent
                // document.getElementById('dvContent').innerHTML = data.developmentPointsContent;
                // let dp = document.getElementById('dvContent').innerText;
                formattedValue.push("\n");
                formattedValue.push("Describe 2-4 development points:");
                //formattedValue.push(`\n`);
                formattedValue.push(`${getPlainText(data.developmentPointsContent)}`);
                //..potentialAndAspirationsContent
                // document.getElementById('dvContent').innerHTML = data.potentialAndAspirationsContent;
                // let pa = document.getElementById('dvContent').innerText;
                formattedValue.push("\n");
                formattedValue.push("Describe potential and aspirations:");
                //formattedValue.push(`\n`);
                formattedValue.push(`${getPlainText(data.potentialAndAspirationsContent)}`);

                reviewerAudit = formattedValue.join(`\n`);
                break;
            // case "developmentPointsContent":
            //   formattedValue.push("\n");
            //   formattedValue.push("Describe 2-4 development points -");
            //   formattedValue.push(`\n`);
            //   formattedValue.push(`${reviewerAudit}`);
            //   reviewerAudit = formattedValue.join(`\n`);
            //   break;
            // case "potentialAndAspirationsContent":
            //   formattedValue.push("\n");
            //   formattedValue.push("Describe potential and aspirations -");
            //   formattedValue.push(`\n`);
            //   formattedValue.push(`${reviewerAudit}`);
            //   reviewerAudit = formattedValue.join(`\n`);
            //   break;
            case "submittedDate":
                reviewerAudit = moment(value).format("DD-MM-YYYY");
                formattedValue.push("\n");
                formattedValue.push(`${reviewerAudit}`);
                reviewerAudit = formattedValue.join(`\n`);
                break;
            default:
                break;
        }
        return reviewerAudit;
    }, []);

    const handleExport = async () => {
        try {
            const worksheet = workbook.addWorksheet(workSheetName);
            worksheet.columns = discusssionGuideReport;
            let columnHeaderRow = worksheet.getRow(1);
            columnHeaderRow.eachCell(function (cell) {
                cell.fill = { type: "pattern", pattern: 'solid', fgColor: { argb: "FFB9B9C2" } };
                cell.font = { name: "EYInterstate", size: "11" };
                cell.border = { right: { style: "medium", color: { argb: "FF000000" } } };
                cell.alignment = {
                    wrapText: true,
                    horizontal: "center",
                    vertical: "top",
                };
            });
            let rowObj = {};
            discusssionGuideReport.forEach((c) => {
                if (!exclusionList.includes(c.key)) {
                    rowObj[c.key] = data[c.key] ? processData(data[c.key], c.key) : "--";
                }
            });
            let row = worksheet.addRow(rowObj);
            row.eachCell(function (cell) {
                cell.fill = { type: "pattern", pattern: 'solid', fgColor: { argb: "FFF2F2F2" } };
                cell.font = { name: "EYInterstate", size: "9" };
                cell.alignment = {
                    wrapText: true,
                    vertical: "top",
                };
                cell.border = {
                    left: { style: "medium", color: { argb: "FF000000" } },
                    top: { style: "medium", color: { argb: "FF000000" } },
                    right: { style: "medium", color: { argb: "FF000000" } },
                    bottom: { style: "medium", color: { argb: "FF000000" } },
                };
            });
            // write the content using writeBuffer
            const buf = await workbook.xlsx.writeBuffer();

            // download the processed file
            saveAs(new Blob([buf]), `FY${myContext.financialYear} Counselee Year End Discussion Guide.xlsx`);
        } catch (error) {
            console.error('<<<ERRROR>>>', error);
            console.error('Something Went Wrong', error.message);
        } finally {
            // removing worksheet's instance to create new one
            workbook.removeWorksheet(workSheetName);
        }
        // const idtoken = await authProvider.acquireTokenSilent({
        //   scopes: GraphExplorerscopes,
        // });
        // const token = idtoken.accessToken;
        // dispatch({
        //   type: "GET_SELF_DG_EXCEL_DOWNLOAD",
        //   payload: {
        //     ad_token: token,
        //     financialYear: myContext.financialYear
        //   },
        // });
    }

    return (
        <>
            <div onClick={handleExport} className={"btn" + (!true ? " opacityReduced " : "")}
                style={{
                    cursor: `${true ? " pointer" : "auto"}`,
                    opacity: `${true ? 1 : 0.5}`,
                }}>
                <i className="eyaa-share" style={{ marginRight: "5px" }} />
                Export
            </div>
        </>
    )

};

export default DownloadTemplateSelfDG;