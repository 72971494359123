import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, Flip } from "react-toastify";
import {
  Select,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  MenuItem,
  InputLabel,
  TextField
} from "@material-ui/core";
import classNames from "classnames";
import "./ResetDiscussionGuide.scss";
import {
  authProvider,
  APIscope,
} from "../../../../Authentication/authProvider";
import AppContext from "../../../../utils/appContext";
import Loader from "../../../Loader/Loader";
import {
  validateEmail,
  isNumeric,
  removeSpecialChars,
  removeEmailSpecialChars,
} from "../../../../utils/utils";

const ResetDiscussionGuide = (props) => {
  const dispatch = useDispatch();
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  const searchResults = useSelector((state) => state.searchDiscussionGuide);
  const resetUser = useSelector((state) => state.resetDiscussionGuide);
  const myContext = useContext(AppContext);
  //..Local States
  const [radioChoice, setRadioChoice] = React.useState('');
  const [searchInput, setSeachInput] = useState({
    gui: "",
    emailId: "",
  });
  const [isPhaseOver, setIsPhaseOver] = useState(false);
  const [disableReset, setDisableReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchBtnClicked, setIsSearchBtnClicked] = useState(false);
  const [searchResultHeaders, setSearchResultHeaders] = useState({});
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const leadReviewerSearchResultHeaders = useMemo(() => {
    return [
      {
        label: "Username",
        key: "name"
      },
      {
        label: "GUI",
        key: "gui",
      },
      {
        label: "Email ID",
        key: "userId"
      },
      {
        label: "Region",
        key: "region",
      },
      {
        label: "Discussion Guide Status",
        key: "status",
      },
    ];
  }, []);

  const ppmdSearchResultHeaders = useMemo(() => {
    return [
      {
        label: "Username",
        key: "name"
      },
      {
        label: "GUI",
        key: "gui",
      },
      {
        label: "Email ID",
        key: "userId"
      },
      {
        label: "Region",
        key: "region",
      },
      {
        label: "Discussion Guide Status",
        key: "status",
      },
    ];
  }, []);

  //..Handlers
  const handleRadioChange = (e) => {
    setRadioChoice(e.target.value);
    if (e.target.value === "leadReviewer") {
      setSearchResultHeaders(leadReviewerSearchResultHeaders);
    } else if (e.target.value === "ppmd") {
      setSearchResultHeaders(ppmdSearchResultHeaders);
    }
  };

  const isSearchBtnEnabled = useCallback(() => {
    //return searchInput.gui.length > 10 || validateEmail(searchInput.emailId);
    return isNumeric(searchInput.gui) || validateEmail(searchInput.emailId);
  }, [searchInput]);

  const onSearchInputChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      let val;
      if (name === "emailId") {
        val = removeEmailSpecialChars(value);
      } else {
        val = removeSpecialChars(value);
      }
      setSeachInput({
        ...searchInput,
        [name]: val,
      });
    },
    [searchInput]
  );

  const onSearch = useCallback(() => {
    setIsLoading(true);
    setIsSearchBtnClicked(true);
    (async function () {
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "SEARCH_DISCUSSION_GUIDE",
          payload: {
            token: idToken,
            body: {
              financialYear: myContext.financialYear,
              timePeriod: myContext.activePeriod,
              email: searchInput.emailId,
              gui: searchInput.gui.toUpperCase(),
              radioChoice: radioChoice
            },
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [searchInput, myContext, dispatch]);

  const onCancel = useCallback(() => {
    setSeachInput({
      gui: "",
      emailId: "",
    });    
    setIsSearchBtnClicked(false);
    dispatch({
      type: "SEARCH_DISCUSSION_GUIDE_RESET",
      payload: {},
    });
    dispatch({
      type: "RESET_DISCUSSION_GUIDE_DATA",
      payload: {},
    });
  }, [dispatch]);

  const onUserReset = useCallback(() => {
    (async function () {
      try {
        setIsLoading(true);
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "RESET_DISCUSSION_GUIDE",
          payload: {
            token: idToken,
            body: {
              financialYear: myContext.financialYear,
              timePeriod: myContext.activePeriod,
              emailId: radioChoice === "leadReviewer" ?
                searchResults.data[0].userId : searchResults.data[0].userId,
              radioChoice: radioChoice,
            },
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();
  }, [searchResults, dispatch, myContext]);

  const notify = useCallback((msg) => {
    toast(
      <div className="success-text">
        <span className="icon-verified-outline icon"></span>
        <p>
          <span>Success:</span> <span>{msg}</span>
        </p>
      </div>,
      {
        position: "top-center",
        transition: Flip,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  //..On page load events
  useEffect(() => {
    if (
      assessmentMetaData &&
      assessmentMetaData.data &&
      assessmentMetaData.data.data
    ) {
      const data = assessmentMetaData.data.data;
      setIsPhaseOver(!data.activePhase);
      setDisableReset(new Date() >= new Date(data.assessmentMetaData.leadReviewer_DiscussionGuide_CompletionDate));
    }
  }, [assessmentMetaData]);

  useEffect(() => {
    onCancel();
  }, [onCancel]);

  useEffect(() => {
    if (!searchResults.fetching && !resetUser.fetching) {
      setIsLoading(false);
    }
  }, [searchResults, resetUser]);

  useEffect(() => {
    if (resetUser.data && resetUser.data.message === "success") {
      notify("Discussion Guide for user has been reset");
      onCancel();
    }
  }, [resetUser, onCancel, notify]);

  return (
    <>
      {isLoading && <Loader />}
      <div className="mainheader">
        <div>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="manage-data-controlled-radio-buttons-group"
              name="manage-data-controlled-radio-buttons-group"
              value={radioChoice}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="leadReviewer" control={<Radio color="default" />} label="Lead Reviewer Finalization Form Commentary" />
              <FormControlLabel value="ppmd" control={<Radio color="default" />} label="PPMD Self Discussion Guide" />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      {
        radioChoice !== "" && (
          <>
            <div className="search-container">
              <h4 style={{ marginBottom: "2px" }}>Search and Reset</h4>
              <div
                style={{
                  fontSize: "0.90rem",
                  color: "#0000ee",
                  fontFamily: "EYInterState-Regular",
                  fontStyle: "italic",
                  marginBottom: "16px"
                }}>
                {`* Important Note:- search with partner email address for which you would like to reset lead reviewer discussion guide.`}
              </div>
              <div className="search-input-wrapper">
                <div className="input-group">
                  <label htmlFor="gui">GUI</label>
                  <input
                    type="text"
                    name="gui"
                    maxLength="20"
                    value={searchInput.gui}
                    onChange={onSearchInputChange}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="emailId">Email ID</label>
                  <input
                    type="email"
                    name="emailId"
                    maxLength="254"
                    value={searchInput.emailId}
                    onChange={onSearchInputChange}
                  />
                </div>
                <input
                  type="button"
                  value="Search"
                  className={classNames("search-btn", {
                    "disable-btn": !isSearchBtnEnabled() || isPhaseOver || disableReset,
                  })}
                  onClick={onSearch}
                  disabled={!isSearchBtnEnabled() || isPhaseOver || disableReset}
                />
              </div>
            </div>
            {searchResults.data.length > 0 ? (
              <div className="search-results">
                <table>
                  <thead>
                    <tr>
                      {searchResultHeaders.map((result, index) => (
                        <th key={index}>{result.label}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults.data.length > 0 && (
                      <tr>
                        {searchResultHeaders.map((result, index) => (
                          <td key={index}>
                            {searchResults.data[0][result.key]
                              ? searchResults.data[0][result.key]
                              : "--"}
                          </td>
                        ))}
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="reset-container">
                  <div className="btn-container">
                    <input
                      type="button"
                      value="Cancel"
                      className="btn cancel-btn"
                      onClick={onCancel}
                    />
                    <input
                      type="button"
                      value="Reset"
                      className={classNames("btn reset-btn")}
                      onClick={() => setIsPopupVisible(true)}
                    />
                  </div>
                </div>
              </div>
            ) : !isPhaseOver ? (!disableReset ?
              (!isLoading &&
                isSearchBtnClicked && <p className="no-data">-- No data found --</p>)
              : <p className="no-data">{`Current Phase Status : Locked. This option shall be enabled when the next phase is made active.`}</p>
            ) : (
              <p className="no-data">There is no active phase at the moment. This shall be enabled when a phase is made active.</p>
            )}
            {isPopupVisible && (
              <div className="popup-dialog">
                <div className="dialog">
                  <div className="header">
                    <h5 className="popup-header">Confirmation</h5>
                    <span
                      className="icon-close-filled icon"
                      onClick={() => setIsPopupVisible(false)}
                    ></span>
                  </div>
                  <div className="content">
                    <p>Would you like to confirm reset?</p>
                    <p>
                      Do note that after resetting, user will have to submit again for
                      approval.
                    </p>
                  </div>
                  <div className="footer">
                    <button
                      className="cancel-btn"
                      onClick={() => setIsPopupVisible(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="confirm-btn"
                      onClick={() => {
                        setIsPopupVisible(false);
                        onUserReset();
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            )}
          </>
        )
      }
    </>
  );
};
export default ResetDiscussionGuide;