import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  Paper,
  InputBase,
  IconButton,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Loader from "../../../Loader/Loader";
import { validateEmail } from "../../../../utils/utils";
import AppContext from "../../../../utils/appContext";
import "./ViewUserProfiles.scss";
import PPMDCard from "../../../PPMDList/PPMDCard/PPMDCard";

const ViewUserProfiles = (props) => {
  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  const [financialYear, setFinancialYear] = useState("");
  const [timePeriod, setTimePeriod] = useState("Mid-Year");
  const [searchText, setSearchText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchBtnClicked, setIsSearchBtnClicked] = useState(false);
  const [periodOptions, setPeriodOptions] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  const getUserStatusAssessment = useSelector(
    (state) => state.getUserStatusAssessment
  );
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  const userProfile = useSelector((state) => state.userProfile);
  const viewUserData = useSelector((state) => state.setViewUserData);
  const completedPhases = useSelector((state) => state.getCompletedPhase);

  // Mock data for testing dropdowns
  /*const completedPhases = useMemo(() => {
    return {
      fetching: false,
      data: [
        { year: 2021, phase: ["Plan", "Mid-Year", "Year-End"] },
        { year: 2022, phase: ["Plan", "Mid-Year"] },
      ],
    };
  }, []);*/

  const userData = useMemo(() => {
    return _.get(getUserStatusAssessment, "data.data", []);
  }, [getUserStatusAssessment]);

  const currentUserId = useMemo(() => {
    return _.get(userProfile, "data[0].userId", "");
  }, [userProfile]);

  const searchUser = useCallback(() => {
    setErrorMsg("");
    if (currentUserId.toLowerCase() === searchText.toLowerCase()) {
      setErrorMsg("You can't view your own profile.");
      return;
    }
    dispatch({
      type: "SET_VIEW_USER_DATA",
      payload: {
        financialYear,
        timePeriod,
        mailId: searchText,
      },
    });
    setIsSearchBtnClicked(true);
    dispatch({
      type: "GET_USER_STATUS_ASSESSMENT",
      payload: {
        financialYear: financialYear,
        timePeriod: timePeriod,
        userId: searchText.toLowerCase(),
      },
    });
  }, [dispatch, financialYear, timePeriod, searchText, currentUserId]);

  const resetUserData = useCallback(() => {
    setIsSearchBtnClicked(false);
    dispatch({
      type: "GET_USER_STATUS_ASSESSMENT_RESET",
      payload: {},
    });
    setErrorMsg("");
  }, [dispatch]);

  const resetData = useCallback(() => {
    setSearchText("");
    resetUserData();
  }, [resetUserData]);

  const setPeriodDropdown = useCallback(
    (year) => {
      setTimePeriod(0);
      resetUserData();
      if (year !== 0) {
        const currentPhases = completedPhases.data.find(
          (phase) => phase.year === year
        );
        setPeriodOptions(currentPhases.phase);
      } else {
        setPeriodOptions([]);
      }
    },
    [completedPhases, resetUserData]
  );

  useEffect(() => {
    if (getUserStatusAssessment.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (userData.length === 0 && isSearchBtnClicked) {
        setErrorMsg("-- No data to display --");
        setIsSearchBtnClicked(false);
      }
    }
  }, [getUserStatusAssessment, isSearchBtnClicked]);

  useEffect(() => {
    return () => {
      resetData();
    };
  }, [resetData]);

 /* useEffect(() => {
    if (viewUserData.viewData) {
      setFinancialYear(viewUserData.financialYear);
      setPeriodDropdown(viewUserData.financialYear);
      setTimePeriod(viewUserData.timePeriod);
      setSearchText(viewUserData.mailId);
      dispatch({
        type: "GET_USER_STATUS_ASSESSMENT",
        payload: {
          financialYear: viewUserData.financialYear,
          timePeriod: viewUserData.timePeriod,
          userId: viewUserData.mailId.toLowerCase(),
        },
      });
      // dispatch({
      //   type: "SET_VIEW_USER_DATA",
      //   payload: {
      //     viewData: false,
      //   },
      // });
    }
  }, [viewUserData, dispatch]);*/

  useEffect(() => {
    dispatch({
      type: "GET_COMPLETED_PHASE",
      payload: {},
    });
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch({
  //     type: "SET_VIEW_USER_DATA",
  //     payload: {
  //       viewData: false,
  //     },
  //   });
  // }, []);

  useEffect(() => {
    if (completedPhases.fetching || assessmentMetaData.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (completedPhases.data.length > 0) {
        const currentYearData = _.last(completedPhases.data);
        const currentPhase = _.last(currentYearData.phase);
        //if (!viewUserData.viewData) {
          if (
            assessmentMetaData &&
            assessmentMetaData.data &&
            assessmentMetaData.data.data
          ) {
            const data = assessmentMetaData.data.data;
            if (data.activePhase == null) {
              setFinancialYear(data.lastActivePhaseYear);
              completedPhases.data.map((item) => {
                if (item.year === data.lastActivePhaseYear) {
                  setPeriodOptions(item.phase);
                }
              });
              setTimePeriod(data.lastActivePhase);
            } else {
              setFinancialYear(data.activePhaseYear);
              completedPhases.data.map((item) => {
                if (item.year === data.activePhaseYear) {
                  setPeriodOptions(item.phase);
                }
              });
              setTimePeriod(data.activePhase);
            }
          }
          // setFinancialYear(currentYearData.year);
          // setTimePeriod(currentPhase);
       // }
      }
    }
  }, [completedPhases, assessmentMetaData]);

  return (
    <div className="view-user-profiles">
      {isLoading && <Loader />}
      <div className="input-container">
        <div className="select-container">
          <FormControl>
            <InputLabel id="yearLabel">Select Year</InputLabel>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              labelId="yearLabel"
              value={financialYear}
              onChange={(e) => {
                setFinancialYear(e.target.value);
                setPeriodDropdown(e.target.value);
                dispatch({
                  type: "SET_VIEW_USER_DATA",
                  payload: {
                    viewData: false,
                  },
                });
              }}>
              <MenuItem value={0} className="disable-field">
                Select
              </MenuItem>
              {completedPhases.data.length > 0 &&
                completedPhases.data.map((option, index) => (
                  <MenuItem value={option.year} key={index}>
                    {`FY ${option.year}`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel id="periodLabel">Period</InputLabel>
            <Select
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              labelId="periodLabel"
              value={timePeriod}
              onChange={(e) => {
                setTimePeriod(e.target.value);
                resetUserData();
              }}>
              <MenuItem value={0} className="disable-field">
                Select
              </MenuItem>
              {periodOptions.map((val, index) => (
                <MenuItem value={val} key={index}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="input-search-group">
          <Paper className="form">
            <InputBase
              className="input-search"
              style={{ width: 260 }}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search", maxLength: 255 }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (
                  e.key === "Enter" &&
                  validateEmail(searchText) &&
                  financialYear === 0 &&
                  timePeriod === 0
                ) {
                  searchUser();
                }
              }}
            />
            {searchText.length > 0 && (
              <i className="icon-close closeIcon" onClick={resetData} />
            )}
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              disabled={
                !validateEmail(searchText) ||
                financialYear === 0 ||
                timePeriod === 0
              }
              onClick={searchUser}>
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>
      </div>

      <div className="user-details-card">
        {userData.length > 0 && errorMsg === "" ? (
          <PPMDCard
            userData={userData[0]}
            history={props.history}
            isAdmin={true}
            year={financialYear}
            phase={timePeriod}
          />
        ) : errorMsg ? (
          <div className="no-data">{errorMsg}</div>
        ) : (
          <div class="helper-text">Search to view user data</div>
        )}
      </div>
    </div>
  );
};
export default ViewUserProfiles;
