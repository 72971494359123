import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Loader from "../../../Loader/Loader";
import SideMenu from "../../../SideMenu/SideMenu";
import Breadcrumb from "../../../Breadcrumbs/Breadcrumbs";
import * as myConsts from "../../../../constants/constants";
import AddUser from "./AddUser";

const ManageUserPreview = (props) => {
  const dispatch = useDispatch();
  const [crumbs, setBreadcrumbs] = useState([
    { name: "Admin", path: myConsts.routeAdmin },
    { name: "Approvals", path: myConsts.routeAdminApprovals },
  ]);
  const adminApprovalData = useSelector((state) => state.approvalData);

 useEffect(() => {
   const userData = _.get(adminApprovalData, "previewParams", {});
   if(!_.isEmpty(userData)) {
    dispatch({
      type: "GET_HIGHLIGHT_DATA",
      payload: {
        type: "UserManagement",
        financialYear: userData.financialYear,
        timePeriod: userData.timePeriod,
        userId: userData.data.userId
      },
    });
   }
  }, [adminApprovalData]);

  return (
    <div className="admin-container">
      <div className="admin-info-main-sidemenu">
        <SideMenu
          minimized={true}
          isminimizedMainMenu={true}
          isMainMenu={true}
          sideMenuItemSelect="Admin"
        />
      </div>
      <div className="admin-content">
        <div className="second-header admin-crumbs">
          <i
            className="icon-arrow-left"
            onClick={() => props.history.push(myConsts.routeAdminApprovals)}
          />
          <div className="admin-crumb-txt">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Breadcrumb
                crumbs={crumbs}
                isAdmin={true}
                isInnerAdmin={true}
                {...props}
              />
              <h4>
                {_.get(adminApprovalData, "previewParams.displayType", "")}
              </h4>
            </div>
          </div>
        </div>
        <div className="manage-users-content">
          <div className="main-content">
            <div className="manage-user">
              <AddUser
                mode="preview"
                financialYear={_.get(
                  adminApprovalData,
                  "previewParams.financialYear",
                  ""
                )}
                phase={_.get(adminApprovalData, "previewParams.timePeriod", "")}
                changesInChild={() => {}}
                userDetails={_.get(adminApprovalData, "previewParams.data", {})}
                history={props.history}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManageUserPreview;
