import React, {
} from "react";
import Parser from 'html-react-parser';
import "./IntroductionCard.scss";

const IntroductionCard = (props) => {
  return (
    <>
      {props.cardDetails.length > 0 && props.cardDetails.map((card, index) => {
        const src = card.fileDetails ? card.fileDetails.fileUrl : (card.fileUrl instanceof File ? URL.createObjectURL(card.fileUrl) : card.fileUrl);
        // return (
        //   <div className="intro-card" key={index}>
        //     <div className="content">
        //       <span className="header">{card.title}</span>
        //       <img src={src} className="img-preview" alt="" />
        //       {/* <img src={`${src}&modifiedData=${new Date().valueOf()}`} className="img-preview" alt="" /> */}
        //       <div className="description">
        //         {Parser(card.content || "")}
        //       </div>
        //     </div>
        //   </div>
        // )
        return (
          <div className="welcome-card">
            <div className="content" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'  }}>
              {/* <span className="header">{card.title}</span> */}
              <div className="ql-editor" style={{ fontFamily: '"EYInterState-Light",sans-serif', fontSize: '15px', fontWeight: 'lighter', textAlign:'center' }}>
                {Parser(card.content || '')}
              </div>
              {/* <img src={src} className="img-preview-new" alt="" /> */}
            </div>
          </div>
        )
      })}
    </>
  );
};
export default IntroductionCard;
