import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Button,
} from "@material-ui/core";
import { toast, Flip } from "react-toastify";
import readXlsxFile from "read-excel-file";
import _ from "lodash";
import {
  authProvider,
  APIscope,
  GraphExplorerscopes,
} from "./../../../../Authentication/authProvider";
import classNames from "classnames";
import NavigationPrompt from "react-router-navigation-prompt";
import "./AddAssessmentContent.scss";
import Loader from "../../../Loader/Loader";
import AppContext from "../../../../utils/appContext";
import DownloadTemplate from "./DownloadTemplate";
import AssessmentPopup from "../AssessmentPopup/AssessmentPopup";
import DropdownComponent from "../../AdminGeneralParam/DropdownComponent/DropdownComponent";

const AddAssessmentContent = (props) => {
  const myContext = useContext(AppContext);
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [enableProgress, setEnableProgress] = useState(false);
  const [fileInfo, setFileInfo] = useState({});
  const [popupType, setPopUpType] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileUploadStarted, setIsFileUploadStarted] = useState(false);
  const [isFileUploadCompleted, setIsFileUploadCompleted] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const allAssesmentData = useSelector((state) => state.allAssesmentData);
  const approvalPendingYears = useSelector(
    (state) => state.getAssessmentSubmissionStatus.pendingYears
  );
  const activePhaseYear = useSelector(
    (state) => state.getValidationAssesmentMetaData.activeYear
  );
  const validAssessmentYears = useSelector(
    (state) => state.validAssessmentYears
  );
  const [year, setYear] = useState(myContext.financialYear + 1);
  const [templateYears, setTemplateYears] = useState([]);
  const [uploadYears, setUploadYears] = useState([]);

  const uploadAssessmentContent = useSelector(
    (state) => state.uploadAssessmentContent
  );
  const assessmentStatus = useSelector(
    (state) => state.getAssessmentStatus.data
  );
  /*const [assessmentStatus, setAssessmentStatus] = useState({
    proceedAction: false,
  });*/

  const isProceedAction = useMemo(() => {
    return _.isEmpty(assessmentStatus) || assessmentStatus.proceedAction;
  }, [assessmentStatus]);

  const [values, setValues] = useState({
    fyYear: "FY" + (myContext.financialYear + 1),
  });

  const [errorMessages, setErrorMessages] = useState({
    fyYear: "",
  });

  const dropOnChange = (value, label) => {
    setValues({ ...values, [label]: value });
  };
  const isValidYear = useMemo(() => {
    return !approvalPendingYears.includes(year);
  }, [year]);

  const schema = useMemo(() => {
    return {
      pillar: {
        prop: "Global Metric/Pillar",
      },
      goal: {
        prop: "Goal",
      },
      section: {
        prop: "Section",
      },
      impactStatement: {
        prop: "Impact Statement",
      },
    };
  }, []);

  // for download template
  const getYearList = useMemo(() => {
    let list = [];
    list = templateYears.map((el) => "FY" + el);
    return list;
  }, [myContext, templateYears]);

  const financialYearOptions = useMemo(() => {
    let list = [];
    list = uploadYears.map((el) => {
      return {
        key: el,
        value: "FY " + el,
      };
    });
    return list;
  }, [myContext, uploadYears]);

  const menuProps = useMemo(() => {
    return {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "left",
      },
      getContentAnchorEl: null,
    };
  }, []);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: (files) => {
      validateFile(files[0]);
    },
    maxFiles: 1,
    maxSize: 5119031,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    disabled: !isValidYear || !isProceedAction,
  });

  const validateFile = useCallback((file) => {
    if (!file) {
      return;
    } else {
      setPopUpType(1);
      setFileInfo(file);
      setIsPopupVisible(true);
    }
  }, []);

  const getPopupContent = useCallback(() => {
    if (popupType === 1) {
      return (
        <>
          <span>Selected file </span>
          <b>{fileInfo.name}</b>
          <span> should be validated before uploading.</span>
        </>
      );
    } else if (popupType === 2) {
      return (
        <>
          <p>Would you like to add the Assessment content?</p>
          <p>
            Please note that this would replace the existing content structure
            if any in 'Manage Assessment'.
          </p>
          <p>
            {" "}
            This operation will take some time to complete. Once it is
            completed, we will inform you over an email.
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>Please select the year to download the Template</p>
          <DropdownComponent
            label="Select Year"
            defaultValue={values.fyYear}
            required={true}
            selectOnChange={(dummy, value) => {
              dropOnChange(value, "fyYear");
            }}
            varName="fyYear"
            list={getYearList}
            errorMessage={errorMessages}
            disabled={false}
          />
        </>
      );
    }
  }, [popupType, fileInfo, values.fyYear]);

  const validateExcelData = useCallback((rows) => {
    setErrorMsg("");
    const updatedRows = _.cloneDeep(rows);
    const excelHeaders = [
      "Global Metric/Pillar",
      "Goal",
      "Section",
      "Impact Statement",
    ];
    updatedRows.shift();
    const pillars = _.groupBy(updatedRows, 0);
    let isEmpty = false;
    updatedRows.forEach((updatedRow) => {
      updatedRow.forEach((row) => {
        if (row === "" || row === null) {
          isEmpty = true;
        }
      });
    });
    if (isEmpty || updatedRows.length === 0) {
      setErrorMsg("File which you uploaded has blank cells.");
      return;
    } else if (!_.isEqual(excelHeaders, rows[0])) {
      setErrorMsg(
        "The file which you have uploaded is not in the correct format."
      );
      return;
    } else if (Object.keys(pillars).length > 7) {
      setErrorMsg("No of metrics should be less than or equal to 7.");
      return;
    }
    let sectionsList = [];
    Object.keys(pillars).forEach((key) => {
      const currentSections = Object.keys(_.groupBy(pillars[key], 2));
      currentSections.forEach((sect) => {
        sectionsList.push(sect);
      });
    });
    sectionsList = _.uniq(sectionsList);
    if (sectionsList.includes("General")) {
      sectionsList.splice(sectionsList.indexOf("General"), 1);
    }
    Object.keys(pillars).forEach((key) => {
      const currentSections = Object.keys(_.groupBy(pillars[key], 2));
      if (Object.keys(_.groupBy(pillars[key], 1)).length > 7) {
        setErrorMsg("No of goals should be less than or equal to 7.");
      } else if (
        currentSections.length === 6 &&
        currentSections.indexOf("General") !== -1
      ) {
        setErrorMsg("");
      } else if (currentSections.length > 5) {
        setErrorMsg(
          "No of sections should be less than or equal to 5 (excluding general)."
        );
      }
    });
    if (sectionsList.length > 5) {
      setErrorMsg(
        "No of sections should be less than or equal to 5 (excluding general)."
      );
    }
  }, []);

  const fileHandler = useCallback(() => {
    setIsFileUploadStarted(true);
    setIsFileUploadCompleted(false);
    readXlsxFile(fileInfo, { sheet: 2 })
      .then((rows) => {
        validateExcelData(rows);
        setIsFileUploadCompleted(true);
      })
      .catch(() => {
        setErrorMsg(
          "The file which you have uploaded is not in the correct format."
        );
        setIsFileUploadCompleted(true);
      });
  }, [fileInfo, validateExcelData]);

  const uploadAssessment = useCallback(async () => {
    try {
      const idtoken = await authProvider.acquireTokenSilent({
        scopes: GraphExplorerscopes,
      });
      const token = idtoken.accessToken;
      dispatch({
        type: "UPLOAD_ASSESSMENT_CONTENT",
        payload: {
          financialYear: year,
          assessment_upload: fileInfo,
          ad_token: token,
        },
      });
      setIsLoading(true);
    } catch (e) {
      console.error(e);
    }
  }, [year, fileInfo]);

  // click event to Download Template
  const excelExport = () => {
    var clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    const temp1 = document.getElementById("excel-export");
    temp1.dispatchEvent(clickEvent);
  };

  const onPopupConfirm = useCallback(() => {
    setIsPopupVisible(false);
    if (popupType === 1) {
      setProgress(0);
      setEnableProgress(true);
      fileHandler();
    } else if (popupType === 2) {
      uploadAssessment();
    } else {
      excelExport();
    }
  }, [popupType, fileHandler, uploadAssessment, dispatch, values.fyYear]);

  const errorNotify = useCallback((msg, type) => {
    toast(
      <div className="error-text">
        {type === "error" ? (
          <span className="icon-close-filled icon"></span>
        ) : (
          <span className="eyaa-state-36 icon"></span>
        )}
        <p>
          {type === "error" ? (
            <>
              <span>Error:</span> <span>{msg}</span>
            </>
          ) : (
            <span>{msg}</span>
          )}
        </p>
      </div>,
      {
        className: type,
        position: "top-center",
        transition: Flip,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  const successNotify = useCallback(() => {
    toast(
      <div className="success-text">
        <span className="icon-verified-outline icon"></span>
        <p>
          <span></span>
          <span>Assessment content upload initiated!</span>
        </p>
      </div>,
      {
        position: "top-center",
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  useEffect(() => {
    dispatch({
      type: "VALID_ASSESSMENT_YEARS",
      payload: {},
    });
  }, []);

  useEffect(() => {
    const fileRejectionItems = fileRejections.map(({ errors }) => {
      return errors.map((e) => e.code);
    });
    if (fileRejectionItems.length > 0) {
      if (fileRejectionItems[0].includes("file-too-large"))
        errorNotify(
          "File size is too large. File size should be within 5MB.",
          "error"
        );
      else if (fileRejectionItems[0].includes("file-invalid-type"))
        errorNotify(
          "File type is not valid. Only, excel file with .xlsx extension can be uploaded. ",
          "error"
        );
      else errorNotify(fileRejectionItems[0], "error");
    }
  }, [fileRejections]);

  useEffect(() => {
    if (progress < 100) {
      setTimeout(function () {
        if (progress == 98 && !isFileUploadStarted) {
          setProgress(100);
        } else setProgress(progress + 2);
      }, 1);
    }
  }, [progress]);

  useEffect(() => {
    dispatch({
      type: "GET_ALL_ASSESSMENT_DATA",
      payload: {
        activePeriod: "Mid-Year",
        financialYear: values.fyYear.replace("FY", ""),
      },
    });
  }, [dispatch, values.fyYear]);

  useEffect(() => {
    if (allAssesmentData.fetching) {
      setIsLoading(true);
    } else {
      setIsFileUploadStarted(false);
      setEnableProgress(false);
      setErrorMsg("");
      setIsLoading(false);
    }
  }, [allAssesmentData]);

  useEffect(() => {
    if (
      !uploadAssessmentContent.fetching &&
      !_.isEmpty(uploadAssessmentContent.data) &&
      uploadAssessmentContent.data.code === "INITIATED"
    ) {
      dispatch({
        type: "GET_ALL_ASSESSMENT_DATA_RESET",
      });
      dispatch({
        type: "GET_ASSESSMENT_SECTION_LIST_RESET",
      });
      setIsLoading(false);
      dispatch({
        type: "UPLOAD_ASSESSMENT_CONTENT_RESET",
        payload: {},
      });
      successNotify();
      dispatch({
        type: "GET_ASSESSMENT_STATUS",
        payload: {},
      });
      props.onClose();
    }
  }, [uploadAssessmentContent]);

  useEffect(() => {
    props.onChildChange(isFileUploadStarted);
  }, [isFileUploadStarted]);

  useEffect(() => {
    if (validAssessmentYears.fetching) {
      setIsLoading(true);
    } else if (!validAssessmentYears.fetching) {
      setIsLoading(false);
      const validFromYearlist = _.get(
        validAssessmentYears,
        "data.fromYearsList",
        []
      );
      setTemplateYears(validFromYearlist);
      if (validFromYearlist.length) {
        setValues({
          fyYear: "FY" + validFromYearlist[0],
        });
      }
      const validToYearlist = _.get(
        validAssessmentYears,
        "data.toYearsList",
        []
      );
      setUploadYears(validToYearlist);
      if (validToYearlist.length) {
        setYear(validToYearlist[0]);
      }
    }
  }, [validAssessmentYears]);

  // useEffect(() => {
  //   setYear(activePhaseYear + 1);
  //   setValues({
  //     fyYear: "FY" + (activePhaseYear + 1),
  //   });
  // }, [activePhaseYear]);

  return (
    <div className="add-assessment-container">
      <AssessmentPopup />
      {isLoading && <Loader />}
      <NavigationPrompt when={isFileUploadStarted}>
        {({ onConfirm, onCancel }) => (
          <div className="popup-dialog">
            <div className="dialog">
              <div className="header">
                <h5>Confirmation</h5>
                <span
                  className="icon-close-filled icon"
                  onClick={onCancel}></span>
              </div>
              <div className="content">
                Would you like to navigate without completing the operation?
                Please note that on confirming, incomplete actions will be lost
              </div>
              <div className="footer">
                <button className="cancel-btn" onClick={onCancel}>
                  Cancel
                </button>
                <button className="confirm-btn" onClick={onConfirm}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </NavigationPrompt>
      <div className="add-assessment-header">
        <div className="input-group">
          <FormControl variant="filled" className="input-control">
            <InputLabel id="fromYear">Select Year</InputLabel>
            <Select
              MenuProps={menuProps}
              label="year"
              labelId="fromYear"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              disabled={!isProceedAction}
              autoFocus={false}>
              <MenuItem value="Select" style={{ opacity: "0.5" }}>
                Select
              </MenuItem>
              {financialYearOptions.map((option) => (
                <MenuItem value={option.key} key={option.key}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <span>Upload File to Add Assessment Content</span>
      </div>
      <div className="download-template">
        <button
          disabled={!isProceedAction}
          className={!isProceedAction ? "disable-element" : ""}
          onClick={() => {
            setIsPopupVisible(true);
            setPopUpType(3);
          }}>
          <span className="eyaa-arrow-tab-down"></span>
          <span>Download Template</span>
        </button>
        <DownloadTemplate
          assessmentData={allAssesmentData}
          // disableDownloadBtn={!isProceedAction}
        />
      </div>
      <p className="info-text">
        Download the template to make edits and upload the updated file here.
      </p>
      <div
        className={classNames("file-upload-container", {
          "disable-element": !isProceedAction,
        })}
        {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="helper-text">
          <p>Drag your file here to Upload</p>
          <p>Maximum file size: 5MB</p>
        </div>
        <label
          htmlFor="uploadFile"
          className={classNames("", {
            "disable-element": !isValidYear || !isProceedAction,
          })}>
          {" "}
          <span className="eyaa-files-104 file-icon"></span>{" "}
          <span>Choose file</span>
        </label>
      </div>
      {enableProgress && (
        <div
          className="shadowBox"
          style={{
            borderLeftColor: `${progress === 100 ? "#b1de76" : "#2e2e38"}`,
          }}>
          <div className="title">
            {progress === 100 ? "Validation Completed" : "Validating file"}
            {errorMsg && <div className="errorMsg">{"File Error"}</div>}
          </div>
          {progress !== 100 ? (
            <div className="blackLine">
              <div
                className="yelloLine"
                style={{ left: `${(progress / 100) * 330}px` }}>
                {"&nbsp;"}
              </div>
            </div>
          ) : (
            <div className="greenLine">{"&nbsp;"}</div>
          )}
          <div className="row">
            <div className="fileN">{fileInfo.name}</div>
            <div className="progress">
              {" "}
              {progress +
                "% " +
                (progress === 100 ? "Complete" : "Processing...")}
            </div>
          </div>
        </div>
      )}
      {!isValidYear && (
        <div className="error-section">
          {/* <span className="redMsg">
              {" "}
              <i className="icon-warning-filled" />
              File error
            </span> */}
          <div className="desc">
            <p>
              {
                "Assessment content for the selected year has been submitted for review. Only after completing the review you will be able to perform this action for the selected year."
              }
            </p>
          </div>
        </div>
      )}
      {errorMsg && progress === 100 && (
        <div className="error-section">
          <span className="redMsg">
            {" "}
            <i className="icon-warning-filled" />
            File error
          </span>
          <div className="desc">
            <p>{errorMsg}</p>
            <p>Please check the file and upload again.</p>
          </div>
        </div>
      )}
      <div className="btn-container">
        <Button
          variant="outlined"
          className="cancel-btn"
          onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          className={classNames("add-assessment-btn", {
            "disable-btn":
              (errorMsg && isFileUploadCompleted) ||
              !isFileUploadStarted ||
              year == "Select",
          })}
          disabled={
            (errorMsg && isFileUploadCompleted) ||
            !isFileUploadStarted ||
            !isProceedAction ||
            year == "Select"
          }
          onClick={() => {
            setIsPopupVisible(true);
            setPopUpType(2);
          }}>
          Add Assessment
        </Button>
      </div>

      {isPopupVisible && (
        <div className="popup-dialog">
          <div
            className="dialog"
            style={{ height: popupType === 1 ? "160px" : "220px" }}>
            <div className="header">
              <h5>
                {popupType === 1
                  ? "File Validation"
                  : popupType === 2
                  ? "Confirmation"
                  : "Download Template"}
              </h5>
              <span
                className="icon-close-filled icon"
                onClick={() => setIsPopupVisible(false)}></span>
            </div>
            <div className="content">{getPopupContent()}</div>
            <div className="footer">
              <button
                className="cancel-btn"
                onClick={() => setIsPopupVisible(false)}>
                Cancel
              </button>
              <button className="confirm-btn" onClick={onPopupConfirm}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default AddAssessmentContent;
