import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";
import * as constants from "../constants/constants";
// The auth provider should be a singleton. Best practice is to only have it ever instantiated once.
// Avoid creating an instance inside the component it will be recreated on each render.
// If two providers are created on the same page it will cause authentication errors.
export const authProvider = new MsalAuthProvider(
  {
    auth: {
      authority:
        "https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c", ///common
      clientId: constants.clientId,
      postLogoutRedirectUri: null,
      redirectUri: window.location.origin,
      validateAuthority: true,
      // After being redirected to the "redirectUri" page, should user
      // be redirected back to the Url where their login originated from?
      navigateToLoginRequestUrl: true, //set as true if Login type is Redirect
    },
    // Enable logging of MSAL events for easier troubleshooting.
    // This should be disabled in production builds.
    system: {
      logger: new Logger(
        (logLevel, message, containsPii) => {
          // console.log("[MSAL]", message);
        },
        {
          level: LogLevel.Verbose,
          piiLoggingEnabled: false,
        }
      ),
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
    extraQueryParameters: [{ domain_Hint: "ey.net" }],
  },
  {
    scopes: [],
    //scopes: ["3aa7ea64-2214-4f70-b7e4-6ffeb35941bc/.default"],
    //scopes: ["https://graph.windows.net/User.Read", "User.Read"],
  },
  {
    loginType: LoginType.Redirect,
    // loginType: LoginType.Popup,
    // When a token is refreshed it will be done by loading a page in an iframe.
    // Rather than reloading the same page, we can point to an empty html file which will prevent
    // site resources from being loaded twice.
    tokenRefreshUri: window.location.origin,
  }
);

export const APIscope = [constants.clientId + "/.default"]; //Backend API //["https://graph.windows.net/User.Read"];
export const GraphExplorerscopes = ["User.ReadBasic.All"]; //GrapExplorerApi
//export const GraphExplorerscopes = [constants.clientId+"/.default"]; //GrapExplorerApi
