export const columnNames = [
    //"Partner Name",
    "Last Name",
    "First Name",
    "Partner Email",
    "GUI",
    "Office",
    "Rank",
    "Region",
    "Primary Sector",
    "Lead Reviewer Name",
    "Lead Reviewer GUI",
    //"Reviewer Submitted Date",
    //"Reviewer Submitted Status",
    // "DRAFT Finalization Wording",
    //"FINAL Finalization Form Commentary"
    // "PFC Discussion Question 1\n(Did your PPMD achieve their goals?)",
    // "PFC Discussion Question 2\n(How has the PPMD teamed this year across SLs, SSLs and functions? And how will they accelerate it next year?)",
    // "PFC Discussion Question 3\n(What actions has the PPMD taken to contribute to priorities including diversity, equity and inclusiveness?)",
    // "PFC Discussion Question 4\n(What is the PPMD's capability and aspiration to take on the next complex challenge? What is needed to help them get there?)",
    // "Summary of Roles/Areas of Responsibility",
    // "Distinguish Performance",
    // "Development Points",
    // "Potential And Aspirations",
     "Overall Category",
     "FINAL Finalization Form Commentary"     
    // "Reviewer Audit\n(Please note below information will generate automatically based on comments entered against below columns\nDistinguish Performance, Development Points, Potential And Aspirations & Overall Category)"
];