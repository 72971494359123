//Component for Summary view of assessment
//Can be viewed only if assessment update progress is 100%
import React, { useState, useEffect, useContext } from "react";
import { Box, Grid } from "@material-ui/core";
import AnsCard from "../survey/AnsCard";
import SimpleBar from "simplebar-react";
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";
import pdfmakedownload from "../PrintSummary/printSummary";
import focuspdfmakedownload from "../PrintSummary/printFocusSummary";
//import { saveAs } from "file-saver";
import Loader from "../Loader/Loader";
import AppContext from "../../utils/appContext";
import {
  authProvider,
  APIscope,
  GraphExplorerscopes,
} from "./../../Authentication/authProvider";
// import { jsPDF } from "jspdf";
import moment from "moment";
// import SummaryPDF from "../SummaryPrintVersion/SummaryView";
import AlertSubmitPopup from "../Common/AlertSubmitPopup";
import ProgressChart from "../progressChart/progressChart";
import { decodeIdToken, capitalizeFirstLetter } from "../../utils/utils";
// import new_ey_logo from "../Header/images/EYlogo.svg";
// import { pdf } from "@react-pdf/renderer";
// import ReactPDF from "@react-pdf/renderer";
import * as myConsts from "../../constants/constants";
import "./Summary.scss";

const SummaryView = (props) => {
  const dispatch = useDispatch();
  const { pillers, scrollableNodeRef, allOptionList, assessmentSectionList
  } =
    props;
  const [isExpandSummary, setExpansionSummary] = useState(false);
  const [isExpandPiller, setIsExpandPiller] = useState({});
  // const [isalltrueExpandPiller, setIsAllTrueExpandPiller] = useState({});
  // const [prevpillarstate, setPrevpillarstate] = useState({});
  // const [flag, setIsflag] = useState(false);
  const [loader, setLoader] = useState(false);
  const alertHeader = "Submit Assessment";
  const alertMessage = "Would you like to confirm your submission ?";
  const alertSubmitTxt = "Confirm";
  const [base64Img, setBase64Img] = useState("");
  const alertBodyTxt =
    "Do note that once submitted you cannot make anymore changes.";
  const long_hyphen = " \u2014 ";
  const [showAlertSubmitPopup, setShowAlertSubmitPopup] = useState(false);
  const myContext = useContext(AppContext);
  const CHANGE_COLOR = "#87d3f2";
  const BASELINE_COLOR = "#9c82d4";
  let activePPMDuserId =
    myContext.activePPMDuserId || sessionStorage.activePPMDuserId;

  const cancelAlertSubmitPopup = () => {
    setShowAlertSubmitPopup(false);
  };

  const generateFocusPdfDocument = async () => {
    //To generate PDF and download with appropriate name
    // base64Img  contains base64 code of snapshot,of graph section in summary.If this var is empty this functin will be called again
    if (base64Img !== "" || base64Img !== "data:,") {
      setLoader(true);
      let data = {
        title: '',
        userProfileData: userProfile,
        userImg: userDp,
        summaryData: `This ${myContext.currentPeriod?.replace("-", "").toLowerCase()} discussion guide is an optional tool to assist you in facilitating a robust conversation with your LEAD Reviewer. Below are the "focus" statements you selected from the ${myContext.currentPeriod?.replace("-", "").toLowerCase()} self-assessment to highlight with your LEAD Reviewer. As a reminder, all of the "table stakes" statements were greyed out as those are baseline expectations of a PPMD.

        There are also additional conversation starters that can be utilized in the ${myContext.currentPeriod?.replace("-", "").toLowerCase()} section within the LEAD 4D Playbook located on the LEAD 4D Sharepoint site.`,
        // summaryData: `${"View and compare the below consolidated list of impact " +
        //   "statements from the planning activity and the " + myContext.currentPeriod.replace("-", " - ").toLowerCase() + " " +
        //   "self-assessment across LEAD’s " + returnStringNumber(props.summaryData &&
        //     props.summaryData.length ? props.summaryData.length : "") + " global metrics:" +
        //   returnAllGM(props.summaryData ? props.summaryData : [])}`,
        GraphImg: base64Img,
        summaryDataArray: props.summaryData ? props.summaryData : [],
        assessmentSectionList: assessmentSectionList,
        allOptionList: allOptionList,

      }
      data.title = `LEAD 4D - FY${myContext.financialYear
        .toString()
        .substring(2, 4)} ${capitalizeFirstLetter(myContext.currentPeriod?.replace(
          "-",
          ""
        ))} Discussion Guide`;
      focuspdfmakedownload("LEAD 4D_" +
        (userProfile && userProfile.preferredName
          ? userProfile.preferredName
          : "") +
        "_" +
        moment(new Date()).format("YYDDMM_HHmmss") +
        ".pdf", data)
      setTimeout(function () {
        setLoader(false);
      }, 4500);
    } else {
      setLoader(true);
      generateFocusPdfDocument();
    }
  };

  const generatePdfDocument = async () => {
    //To generate PDF and download with appropriate name
    // base64Img  contains base64 code of snapshot,of graph section in summary.If this var is empty this functin will be called again
    if (base64Img !== "" || base64Img !== "data:,") {
      setLoader(true);
      let data = {
        title: '',
        userProfileData: userProfile,
        userImg: userDp,
        summaryData: `${"View and compare the below consolidated list of impact " +
          "statements from the planning activity and the " + myContext.currentPeriod.replace("-", " - ").toLowerCase() + " " +
          "self-assessment across LEAD’s " + returnStringNumber(props.summaryData &&
            props.summaryData.length ? props.summaryData.length : "") + " global metrics:" +
          returnAllGM(props.summaryData ? props.summaryData : [])}`,
        GraphImg: base64Img,
        summaryDataArray: props.summaryData ? props.summaryData : [],
        assessmentSectionList: assessmentSectionList,
        allOptionList: allOptionList,

      }
      data.title = `LEAD 4D - FY${myContext.financialYear
        .toString()
        .substring(2, 4)} ${myContext.currentPeriod.replace(
          "-",
          " - "
        )}  Report`
      // var canvasBlob = getCanvasBlob();
      // var blob = await pdf(
      //   <SummaryPDF
      //     assessmentSectionList={assessmentSectionList}
      //     userProfileData={userProfileData}
      //     userImg={userDp}
      //     myContext={myContext}
      //     userProfile={userProfile}
      //     displayName={displayName}
      //     pillarChartData={pillarChartData}
      //     base64Img={base64Img}
      //     {...props}
      //   />
      // ).toBlob();
      // saveAs(
      //   blob,
      //   "LEAD 4D_" +
      //   (userProfile && userProfile.preferredName
      //     ? userProfile.preferredName
      //     : "") +
      //   "_" +
      //   moment(new Date()).format("YYDDMM_HHmmss") +
      //   ".pdf"
      // );
      pdfmakedownload("LEAD 4D_" +
        (userProfile && userProfile.preferredName
          ? userProfile.preferredName
          : "") +
        "_" +
        moment(new Date()).format("YYDDMM_HHmmss") +
        ".pdf", data)
      setTimeout(function () {
        setLoader(false);
      }, 4500);
    } else {
      setLoader(true);
      generatePdfDocument();
    }
  };
  const submitPopupChanges = async () => {
    // // checkOutsideClick(goToNext);
    const token = await authProvider.acquireTokenSilent({
      scopes: APIscope,
    });
    const idToken = token.accessToken;
    let payloadObj = {
      timePeriod: myContext.activePeriod,
      financialYear: myContext.financialYear,
      documentLink: myConsts.hostUrl + "/Assessment/summary",
    };
    dispatch({
      type: "SUBMIT_USER_ASSESSMENT",
      payload: {
        token: idToken,
        payloadObj,
      },
    });

    cancelAlertSubmitPopup();
  };
  const getCurrentUser = () => {
    // To get PPMD's Id if logged in user is a reviewer else returns current logged in user's Id
    const ppmdID =
      myContext.activePPMDuserId || sessionStorage.activePPMDuserId;
    const userId = myContext.userId || sessionStorage.userId;
    return ppmdID || userId;
  };
  const userProfileData = useSelector((state) => state.userProfile);
  // const userData = useSelector((state) => state.getUserDetails);
  const pillarChartData = useSelector((state) => state.userAssesmentScore);
  const allAssesmentData = useSelector((state) => state.allAssesmentData);
  const [qualitativeFlagData, setQualitativeFlagData] = useState({});
  const [userProfile, setUserProfile] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [userDp, setUserDp] = useState("");

  useEffect(() => {
    if (
      allAssesmentData &&
      allAssesmentData.data &&
      allAssesmentData.data.status === "success"
    ) {
      let json = {};

      allAssesmentData.data.data &&
        allAssesmentData.data.data.assessmentDetails &&
        Array.isArray(allAssesmentData.data.data.assessmentDetails) &&
        allAssesmentData.data.data.assessmentDetails.map((item) => {
          json[item.pillarDisplayName] = item.type === "Qualitative";
        });
      setQualitativeFlagData(json);
    }
  }, [allAssesmentData]);
  useEffect(() => {
    //To process user profileData from API,this data is displayed in PDF
    if (
      userProfileData &&
      userProfileData.data &&
      userProfileData.data.length > 0
    ) {
      const data = userProfileData.data;
      setUserProfile(data[0]);
      setLoader(false);
    }
  }, [userProfileData]);

  const genrateImg64 = (i) => {
    //This is to get base64 code of snapshot in graph section of summary view.
    // if image is empty ,this fun will be called again(max 5 times).If max count exceeded , base64 code of a blank image is set
    if (document.getElementById("chart")) {
      try {
        const divToDisplay = document.getElementById("chart");
        html2canvas(divToDisplay, {
          scrollY: -window.scrollY,
          scale: 3,
          useCORS: true,
        }).then(function (canvas) {
          var imgData = canvas.toDataURL("image/png");
          setBase64Img(imgData);
          if (i <= 9) {
            if (imgData === "" || imgData === "data:,") {
              setLoader(true)
              genrateImg64(i + 1);
            }
          } else {
            setBase64Img(
              "data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA="
            );
          }
        });
      } catch (e) {
        console.error(e, "errrrr");
      }
    }
  };

  useEffect(() => {
    if (pillarChartData.fetching) {
      setLoader(true);
    }

    //to generate base64 encoded image for graph section
    if (document.getElementById("chart")) {
      const divToDisplay = document.getElementById("chart");
      html2canvas(divToDisplay, {
        scrollY: -window.scrollY,
        scale: 3,
        useCORS: true,
      }).then(function (canvas) {
        var imgData = canvas.toDataURL("image/png");
        setBase64Img(imgData);
        // console.log(imgData);
        if (imgData === "" || imgData === "data:,") {
          setLoader(true)
          genrateImg64(1);
        }
      });
    }

    // eslint-disable-next-line
  }, [pillarChartData || myContext.assessmentSubmissionStatus]);

  useEffect(() => {
    if (base64Img === "" || base64Img === "data:,") {
      setLoader(true);
    } else setLoader(false);
  }, [base64Img]);
  // base64Img !== "" && base64Img !== "data:,"
  useEffect(() => {
    //On expanding each pillar,other pillars will be collapsed
    let temp = {};
    let temp2 = {};
    pillers &&
      pillers.length > 0 &&
      pillers.forEach((item) => {
        temp[item.pillar] = false;
        temp2[item.pillar] = true;
      });
    setIsExpandPiller(temp);
    // if (!flag) {
    //   setIsAllTrueExpandPiller(temp2);
    // }
    // eslint-disable-next-line
  }, [pillers]);

  useEffect(() => {
    //On expanding a pillar have a smooth scroll to top of pillar data
    pillers &&
      pillers.length > 0 &&
      pillers.forEach((item) => {
        if (isExpandPiller[item.pillar]) {
          document.getElementById(item.pillar + "scroll");
          if (document.getElementById(item.pillar + "scroll")) {
            let temp = document.getElementById(item.pillar + "scroll");
            temp.scrollIntoView({
              behavior: "smooth",
              block: "nearest",
              inline: "start",
            });
          }
        }
      });

    // eslint-disable-next-line
  }, [isExpandPiller]);

  const returnStringNumber = (n) => {
    switch (n) {
      case 1:
        return ' one ';
      case 2:
        return ' two ';
      case 3:
        return ' three ';
      case 4:
        return ' four ';
      case 5:
        return ' five ';
      case 6:
        return ' six ';
      case 7:
        return ' seven ';
      default:
        return ' ';
    }
  }
  const returnAllGM = (list) => {
    let string = " ";
    if (list && list.length > 0) {
      list.map((item, index) => {
        if (index == list.length - 1) {
          string = string + item.pillarDisplayName + ". "
        } else
          string = string + item.pillarDisplayName + ", "
      })
    }
    return string;
  }
  useEffect(() => {
    //API call to fetch user data,user display picture and assesment scores
    (async function () {
      try {
        if (myContext.navToSummary) {
          myContext.setNavToSummary(false);
        }
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        setDisplayName(
          JSON.parse(sessionStorage.userData).displayName ||
          decodeIdToken(idToken).name
        );
        dispatch({
          type: "GET_USER_ASSESSMENT_SCORE",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.currentPeriod,
          },
        });

        dispatch({
          type: "GET_USER_PROFILE_BY_ID",
          payload: {
            tokens: idToken,
            userId: getCurrentUser(),
            financialYear: myContext.financialYear,
          },
        });
        const gtoken = await authProvider.acquireTokenSilent({
          scopes: GraphExplorerscopes,
        });
        const tokenG = gtoken.accessToken;
        const ppmdId =
          myContext.activePPMDuserId ||
          sessionStorage.activePPMDuserId ||
          sessionStorage.userId;
        const url = `${myConsts.msHostName + "/users/" + ppmdId + myConsts.getUserImg
          }`;
        const options = {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + tokenG,
            userid: myContext.activePPMDuserId,
          },
        };
        await fetch(url, options)
          .then(async (r) => {
            if (r.status === 200 && !r.bodyUsed) return r;
          })
          .then(async (response) => {
            const l = await response.blob();
            if (l.size > 0) {
              // let href = window.URL.createObjectURL(l);
              // setUserDp(href);
              blobToBase64(l).then(res => {
                // do what you wanna do
                setUserDp(res); // res is base64 now
              });
            }
          });
      } catch (e) {
        console.error(e);
      }
    })();

    // eslint-disable-next-line
  }, [myContext]);

  const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  const userImg = useSelector((state) => state.getUserImg);
  const getCount = (length = 0) => {
    let count = '';
    switch (length) {
      case 1:
        count = 'one'
        break;
      case 2:
        count = 'two'
        break;
      case 3:
        count = 'three'
        break;
      case 4:
        count = 'four'
        break;
      case 5:
        count = 'five'
        break;
      case 6:
        count = 'six'
        break;
      case 7:
        count = 'seven'
        break;
      default:
        break;
    }
    return count;
  }
  const globalMetricCount = Array.isArray(props.summaryData) ? getCount(props.summaryData.length) : '';
  const globalMetricName = Array.isArray(props.summaryData) ? props.summaryData.map(metric => (metric.pillar)).join(', ') : '';
  return (
    <>
      {loader && <Loader />}
      {/*  {flag && <div id="welcm-text"></div>} */}
      <div className="preview-download" id="pdf-div">
        {/*  {flag && (
          <>
            <div id="header-wrap">
              <div
                className="header-left"
                style={{ backgroundColor: "#2e2e38" }}>
                <div className="new-logo">
                  <img src={new_ey_logo} alt="logo" />
                </div>
              </div>
              <div>
                <span className="headerText">
                  {`LEAD 4D - FY${myContext.financialYear
                    .toString()
                    .substring(2, 4)} ${myContext.currentPeriod.replace(
                    "-",
                    " - "
                  )} Report`}
                </span>
              </div>
            </div>
            <div className="user-profile-sec padd-margin">
              <div className="lft-sec">
                <div>
                  {userImg && userImg.data && userImg.data.href ? (
                    <img
                      src={
                        userImg && userImg.data && userImg.data.href
                          ? userImg.data.href
                          : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
                      }
                      alt="user-profile-pic"
                      className="profile-img"
                    />
                  ) : (
                    <div className={"dummyDP"}>{extractFirstLetters()} </div>
                  )}
                </div>
                <div className="dtl-box">
                  <div className="dtl-flex">
                    <div>Name: {displayName}</div>
                    <div>Region: {userProfile && userProfile.region}</div>
                    <div>Office: {userProfile && userProfile.locationCity}</div>
                  </div>
                  <div className="dtl-flex">
                    <div>Rank: {userProfile && userProfile.rankDesc}</div>
                    <div>
                      Lead Reviewer: {userProfile && userProfile.counselorName}
                    </div>
                    <div>Country: {userProfile && userProfile.country}</div>
                  </div>
                </div>
              </div>
              <div className="rght-sec"></div>
            </div>
          </>
        )} */}
        <div className="survey-info-content">
          <div className="summary-section">
            <div className="summary">
              <div
                className="txt-content"
                style={{
                  paddingTop: "15px",
                }}>
                <div className="summary-expand-collapse">
                  <div className="txt-header" style={{ fontSize: "14px" }}>
                    Summary
                  </div>
                  {!isExpandSummary ? (
                    <i
                      className="icon-minus"
                      onClick={() => setExpansionSummary(!isExpandSummary)}></i>
                  ) : (
                    <i
                      className="icon-plus"
                      onClick={() => setExpansionSummary(!isExpandSummary)}></i>
                  )}
                </div>
                {!isExpandSummary && (
                  <>
                    <span style={{ fontSize: "12px" }}>
                      <p>
                        View and compare the below consolidated list of impact
                        statements from the planning activity and the {myContext.currentPeriod.replace(
                          "-",
                          " - "
                        ).toLowerCase() + " "}
                        self-assessment across LEAD’s {returnStringNumber(props.summaryData &&
                          props.summaryData.length ? props.summaryData.length : "")}global metrics:
                        {returnAllGM(props.summaryData ? props.summaryData : [])}
                      </p>
                      <p>
                        Click on each pillar to view the list of selected impact
                        actions and changes made to your baseline. Please refer
                        to the dials for a representation of where you currently
                        stand in comparison to your input from the planning
                        activity.
                      </p>
                    </span>
                    {/* PPMD SCORING HIDDEN */}
                    {/* <Grid
                      container
                      style={{
                        marginTop: "12px",
                        justifyContent: "space-around",
                      }}
                      id="chart">
                      {pillarChartData.data &&
                        pillarChartData.data.length > 0 &&
                        pillarChartData.data.map((data, index) => (
                          <>
                            {qualitativeFlagData[data.pillarDisplayName] && (
                              <Grid item lg={1}>
                                <ProgressChart
                                  pillarName={data.pillarDisplayName}
                                  pillarScore={data.pillarScore}
                                  radius={45}
                                />
                              </Grid>
                            )}
                          </>
                        ))}
                    </Grid> */}
                  </>
                )}
              </div>
            </div>

            {props.summaryData &&
              props.summaryData.map((pillerObj, index) => (
                <>
                  <div
                    className="summary-item quality-section"
                    style={{ width: "auto" }}>
                    <div
                      key={pillerObj._id}
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "25px",
                        alignItems: "center",
                        marginBottom: `${!isExpandPiller[pillerObj.pillar] ? "0px" : "8px"
                          }`,
                      }}>
                      {!isExpandPiller[pillerObj.pillar] ? (
                        <i
                          className="icon-plus pillarSymbol"
                          onClick={() => {
                            let temp = isExpandPiller;
                            pillers &&
                              pillers.length > 0 &&
                              pillers.forEach((item) => {
                                temp[item.pillar] = false;
                              });
                            temp[pillerObj.pillar] = !temp[pillerObj.pillar];
                            setIsExpandPiller({ ...temp });
                          }}></i>
                      ) : (
                        <i
                          className="icon-minus pillarSymbol"
                          onClick={() => {
                            let temp = isExpandPiller;
                            temp[pillerObj.pillar] = !temp[pillerObj.pillar];
                            setIsExpandPiller({ ...temp });
                          }}></i>
                      )}
                      <div
                        className={"pillarHeading"}
                        id={pillerObj.pillar + "scroll"}>
                        {pillerObj.pillarDisplayName === "Exceptional client service"
                          ? "ECS"
                          : pillerObj.pillarDisplayName}
                      </div>
                    </div>
                    <SimpleBar
                      style={{
                        maxHeight:
                          "100%" /* "225px"   `${!flag ? "100%" : "100%"}`*/,
                        width: "100%",
                        maxWidth: "100%",
                        overflowX: "hidden",
                      }}>
                      {isExpandPiller[pillerObj.pillar] && (
                        <div
                          className="txt-content"
                          style={{ width: "100%", marginLeft: "10px" }}>
                          {pillerObj.tabList &&
                            pillerObj.tabList.map((goal, index) => {
                              // if (index % 2 == 0)
                              return (
                                <>
                                  {goal.comitted &&
                                    goal.comitted.length > 0 && (
                                      <p className="goalTitle">
                                        {(pillerObj.pillarDisplayName ===
                                          "Exceptional client service"
                                          ? "ECS "
                                          : pillerObj.pillarDisplayName) +
                                          long_hyphen +
                                          goal.goal +
                                          long_hyphen +
                                          " Committed"}
                                      </p>
                                    )}
                                  {goal.comitted &&
                                    goal.comitted.map((impact, idx) => {
                                      // if (idx % 3 == 0)
                                      return (
                                        <Box
                                          paddingBottom="0"
                                          display="flex"
                                          marginTop="10px"
                                          fontSize="11px"
                                          width="98%">
                                          <AnsCard
                                            answer={impact.qstn}
                                            showFullWidth={true}
                                          />
                                        </Box>
                                      );
                                      // else return null;
                                    })}
                                  {goal.remaining &&
                                    goal.remaining.length > 0 && (
                                      <p className="goalTitle">
                                        {(pillerObj.pillarDisplayName ===
                                          "Exceptional client service"
                                          ? "ECS "
                                          : pillerObj.pillarDisplayName) +
                                          long_hyphen +
                                          goal.goal +
                                          long_hyphen +
                                          " Uncommitted"}
                                      </p>
                                    )}
                                  {goal.remaining &&
                                    goal.remaining.map((impact, idx) => {
                                      // if (idx % 3 == 0)
                                      return (
                                        <Box
                                          paddingBottom="0"
                                          display="flex"
                                          marginTop="10px"
                                          fontSize="11px"
                                          width="98%">
                                          <AnsCard
                                            answer={impact.qstn}
                                            showFullWidth={true}
                                          />
                                        </Box>
                                      );
                                      // else return null;
                                    })}
                                  {goal.difference &&
                                    goal.difference.length > 0 && (
                                      <Box
                                        paddingBottom="0"
                                        display="flex"
                                        width="95%"
                                        flexDirection="row"
                                        // alignItems="end"
                                        justifyContent="end"
                                        marginTop="-5px"
                                        marginBottom=" -5px"
                                        alignItems="center">
                                        <p className="goalTitle">
                                          {(pillerObj.pillarDisplayName ===
                                            "Exceptional client service"
                                            ? "ECS"
                                            : pillerObj.pillarDisplayName) +
                                            long_hyphen +
                                            goal.goal +
                                            long_hyphen +
                                            "Changed"}
                                        </p>
                                        {isExpandPiller[pillerObj.pillar] && (
                                          <Box
                                            paddingBottom="0"
                                            display="flex"
                                            width="50%"
                                            flexDirection="row-reverse"
                                            justifyContent="end">
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}>
                                              <div className="baseLineLegend">
                                                &nbsp;
                                              </div>
                                              <div className="legendText">
                                                Baseline
                                              </div>
                                              <div className="currentLegend">
                                                &nbsp;
                                              </div>
                                              <div className="legendText">
                                                Changed Value
                                              </div>
                                            </div>
                                          </Box>
                                        )}
                                      </Box>
                                    )}
                                  {goal.difference &&
                                    goal.difference.map((impact, idx) => {
                                      // if (idx % 3 !== 0)
                                      return (
                                        <>
                                          <Box
                                            paddingBottom="0"
                                            display="flex"
                                            alignItems="center"
                                            marginTop="10px"
                                            fontSize="11px"
                                            width="98%">
                                            <AnsCard
                                              answer={impact.qstn}
                                              showFullWidth={true}
                                              wordBreak={false}
                                            />
                                          </Box>
                                          <div
                                            style={{
                                              display: "flex",
                                            }}>
                                            <Box
                                              width="47.70%"
                                              paddingBottom="0"
                                              display="flex"
                                              fontSize="11px"
                                              alignItems="center"
                                              justifyContent="center"
                                              height="28px"
                                              style={{
                                                background: `${BASELINE_COLOR}`,
                                                color: "white",
                                              }}>
                                              {goal.uiType === "check_box"
                                                ? allOptionList[impact.prev] ===
                                                  "True"
                                                  ? "Selected"
                                                  : "Un-Selected"
                                                : allOptionList[impact.prev]}
                                            </Box>
                                            <Box
                                              width="47.70%"
                                              paddingBottom="0"
                                              height="28px"
                                              fontSize="11px"
                                              justifyContent="center"
                                              display="flex"
                                              alignItems="center"
                                              background={CHANGE_COLOR}
                                              style={{
                                                background: `${CHANGE_COLOR}`,
                                                color: "rgba(46, 46, 56, 0.8)",
                                              }}>
                                              {goal.uiType === "check_box"
                                                ? allOptionList[
                                                  impact.current
                                                ] === "True"
                                                  ? "Selected"
                                                  : "Un-Selected"
                                                : allOptionList[impact.current]}
                                            </Box>
                                          </div>
                                        </>
                                      );
                                      // else return null;
                                    })}
                                </>
                              );
                            })}
                        </div>
                      )}
                    </SimpleBar>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
      <Box
        display="flex"
        justifyContent="space-between"
        margin={
          Object.keys(isExpandPiller).length > 0
            ? /*  Object.values(isExpandPiller).some((item) => item === true)
              ? "15px 0px"
              : */ "15px 0px"
            : "15px 0px 0% 0"
        }>
        {activePPMDuserId && activePPMDuserId !== null ? (
          <div
            style={{ width: "120px" }}
            className="goto-button summary-button"
            onClick={() => {
              props.history.push(myConsts.routeHome + "/" + activePPMDuserId);
            }}>
            Back to PPMD Home
          </div>
        ) : (
          <div
            className="goto-button summary-button"
            onClick={() => {
              scrollableNodeRef.current.scrollTop = 0; //SCROLL_TOP props
            }}>
            Go to top
          </div>
        )}
        {activePPMDuserId && activePPMDuserId !== null ? (
          <div className="right-btn-div" style={{ width: "500px" }}>
            {
              myContext.currentPeriod !== myConsts.TimePeriodYearEnd && (
                <div
                  style={{
                    marginRight: "15px",
                    width: "193px"
                  }}
                  className={"dwnld-button summary-button"}
                  onClick={() => {
                    setLoader(true);
                    generateFocusPdfDocument();
                  }}>
                  {`Download ${capitalizeFirstLetter(myContext.activePeriod === myConsts.TimePeriodYearEnd ? myContext.activePeriod : myContext.activePeriod?.replace("-", ""))} Discussion Guide`}
                </div>
              )
            }
            <div
              style={{
                marginRight: "15px",
                width: "150px"
              }}
              className={"dwnld-button summary-button"}
              onClick={() => {
                setLoader(true);
                generatePdfDocument();
              }}>
              Download Summary Report
            </div>
            <div
              style={{
                height: "31px",
              }}
              className="next-summary  survey-button"
              onClick={() => {
                // checkOutsideClick(goToNext);
                dispatch({
                  type: "OUTSIDE_CLICK_RESET",
                  payload: {},
                });
                // myContext.setIsAutoSave(false);
                props.goToNext();
              }}>
              Next
            </div>
          </div>
        ) : (
          <div className="right-btn-div" style={{ width: "500px" }}>
            {true ? (
              <>
                {
                  myContext.currentPeriod !== myConsts.TimePeriodYearEnd && (
                    <div
                      className={"dwnld-button summary-button"}
                      style={{
                        marginRight: "15px",
                        width: "193px"
                      }}
                      onClick={() => {
                        setLoader(true);
                        generateFocusPdfDocument();
                      }}>
                      {`Download ${capitalizeFirstLetter(myContext.activePeriod?.replace("-", ""))} Discussion Guide`}
                    </div>
                  )
                }
                <div
                  style={{
                    width: "150px"
                  }}
                  className={"dwnld-button summary-button"}
                  onClick={() => {
                    setLoader(true);
                    generatePdfDocument();
                  }}>
                  Download Summary Report
                </div>
              </>
            ) : null}
            <div
              className={
                !(
                  myContext.assessmentSubmissionStatus === "true" ||
                  myContext.assessmentSubmissionStatus === true
                ) &&
                  !myContext.formsInActive &&
                  !myContext.disableUpdateSummary
                  ? "conf-sub summary-button"
                  : "disable-sub summary-button"
              }
              onClick={async () => {
                if (
                  !(
                    myContext.assessmentSubmissionStatus === "true" ||
                    myContext.assessmentSubmissionStatus === true
                  ) &&
                  !myContext.formsInActive &&
                  !myContext.disableUpdateSummary
                )
                  setShowAlertSubmitPopup(true);
              }}>
              Submit
            </div>
            <AlertSubmitPopup
              onlyAlert={false}
              header={alertHeader}
              submitBtnTxt={alertSubmitTxt}
              message={alertMessage}
              bodyText={alertBodyTxt}
              showAlertSubmitPopup={showAlertSubmitPopup}
              cancelAlertSubmitPopup={cancelAlertSubmitPopup}
              submitPopupChanges={submitPopupChanges}
              simpleClosePopup={() => cancelAlertSubmitPopup()}
            />
          </div>
        )}
      </Box>
    </>
  );
};

export default SummaryView;
