import React, { useState, useContext, useEffect } from "react";
import SideMenu from "../SideMenu/SideMenu";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import FinalizationFormSelf from "./FinalizationForm/FinalizationFormSelf";
import "./Admin.scss";
import * as myConsts from "../../constants/constants";
import AppContext from "../../utils/appContext";
import { authProvider, APIscope } from "./../../Authentication/authProvider";
import { useDispatch, useSelector } from "react-redux";
//import _ from "lodash";
import Modal from "react-responsive-modal";
import SimpleBar from "simplebar-react";
import Loader from "../Loader/Loader";
//import DownloadTemplateCounseleeSelf from './FinalizationForm/DownloadTemplateCounseleeSelf';
import DownloadTemplateSelfDG from './FinalizationForm/DownloadTemplateSelfDG';

const FinalizationGuidanceSelf = (props) => {
  const dispatch = useDispatch();
  const [crumbs, setBreadcrumbs] = useState([
    { name: "Discussion Guide", path: myConsts.routeDiscussionGuide },
  ]);
  //..Local States
  const [finalizationFormDetails, setFinalizationFormDetails] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [loader, setLoader] = useState(true);
  const myContext = useContext(AppContext);
  const counseleesFinalizationDetails = useSelector((state) => state.getCounseleeDiscussionGuide);
  //..Finalization form states
  const [fieldValues, setFieldValues] = useState({
    financialYear: myContext.financialYear,
    userId: null,
    //counseleeId: null,
    firstName: null,
    lastName: null,
    name: null,
    gui: null,
    rank: null,
    region: null,
    primarySector: null,
    office: null,
    leadReviewer: null,
    leadReviewerGUI: null,
    leadReviewerEmail: null,
    question1AnsContent: null,
    question2AnsContent: null,
    revenueMargin: null,
    revenueMarginContent: null,
    salesAndPipeline: null,
    salesAndPipelineContent: null,
    qualityContent: null,
    ecs: null,
    ecsContent: null,
    peopleAndTeaming: null,
    peopleAndTeamingContent: null,
    //draftFinalizationFormComment: null,
    distinguishPerformanceContent: null,
    developmentPointsContent: null,
    potentialAndAspirationsContent: null,
    submittedDate: null,
    status: null,
    progress: 0,
    question1AnsPlainText: null,
    question2AnsPlainText: null,
    revenueMarginPlainText: null,
    salesAndPipelinePlainText: null,
    qualityPlainText: null,
    ecsPlainText: null,
    peopleAndTeamingPlainText: null,
    distinguishPerformancePlainText: null,
    developmentPointsPlainText: null,
    potentialAndAspirationsPlainText: null,
  });

  const mandatoryFields = [
    { key: "question1AnsContent", name: "What additional accomplishments/activities would you like to discuss not readily apparent above or in your feedback?" },
    { key: "question2AnsContent", name: "Summary of Roles/Areas of Responsibility" },
    { key: "revenueMargin", name: "Please select Revenue/Margin" },
    { key: "revenueMarginContent", name: "Please comment on any 1-2 leading/transformative behaviors from self-assessment in areas of: Prioritization, Centralizing Service Delivery, Continuous Improvement." },
    { key: "salesAndPipeline", name: "Please select Sales & pipeline" },
    { key: "salesAndPipelineContent", name: "Please comment on any 1-2 leading/transformative behaviors from self-assessment in areas of: Driving pipeline and Sales growth" },
    { key: "qualityContent", name: "Please comment on any 1-2 leading/transformative behaviors from self-assessment in areas of: Simplifying our audit and Pacing and milestones." },
    { key: "ecs", name: "Please select Exceptional Client Service" },
    { key: "ecsContent", name: "Please comment on any 1-2 leading/transformative behaviors from self-assessment in areas of: Distinctive Client Experience (DCE), Expanded Assurance, and Digital & Innovation" },
    { key: "peopleAndTeaming", name: "Please select People & Teaming" },
    { key: "peopleAndTeamingContent", name: "Please comment on any 1-2 leading/transformative behaviors from self-assessment in areas of: High performance teaming (including EY WOW), Diversity, equity and inclusion, Wellbeing (individual and team). Please note specific name(s) for the sponsorship behavior." },
    //{ key: "draftFinalizationFormComment", name: "Draft Finalization Form Comment" }
    { key: "distinguishPerformanceContent", name: "Describe the top 2-4 distinguished performance behaviors exhibited during the fiscal year" },
    { key: "developmentPointsContent", name: "Describe 2-4 development points:" },
    { key: "potentialAndAspirationsContent", name: "Describe potential and aspirations:" },
  ];

  const [errorMessages, setErrorMessages] = useState({
    question1AnsContent: "",
    //question1AnsPlainText: "",    
    question2AnsContent: "",
    //question2AnsPlainText: "",    
    revenueMargin: "",
    revenueMarginContent: "",
    salesAndPipeline: "",
    salesAndPipelineContent: "",
    qualityContent: "",
    ecs: "",
    ecsContent: "",
    peopleAndTeaming: "",
    peopleAndTeamingContent: "",
    //draftFinalizationFormComment: ""
    distinguishPerformanceContent: "",
    developmentPointsContent: "",
    potentialAndAspirationsContent: "",
  });

  const allUserAssesmentData = useSelector(
    (state) => state.allUserAssesmentData //get assessment data of user for all yera and period
  );
  const allAssesmentData = useSelector((state) => state.allAssesmentData);
  const getAllOptions = useSelector((state) => state.getAllOptions);
  const [summaryData, setSummaryData] = useState([]);
  const [anyFocusedStmtSelected, setAnyFocusedStmtSelected] = useState(false);
  const [assessmentObj, setAssessmentObj] = useState({});
  const [allOptionList, setAllOptionList] = useState({});
  const timePeriods = myConsts.TimePeriodsArray;
  const currentPeriod = myContext.currentPeriod;

  //..Events
  //..Focus Summary View
  useEffect(() => {
    //debugger;
    if (
      getAllOptions &&
      !getAllOptions.fetching &&
      getAllOptions.data &&
      getAllOptions.data.length
    ) {
      let temp = {};
      getAllOptions.data.map((item) => {
        temp[item.option_id] = item.options;
      });
      setAllOptionList(temp);
    }
  }, [getAllOptions]);

  useEffect(() => {
    //debugger;
    if (
      allAssesmentData &&
      allAssesmentData.data &&
      allAssesmentData.data.status === "success"
    ) {
      const {
        data: { data },
      } = { ...allAssesmentData };
      const assessmentDetails = data.assessmentDetails;
      setAssessmentObj(assessmentDetails);
    }
    if (
      allUserAssesmentData &&
      allUserAssesmentData.data &&
      allAssesmentData &&
      allAssesmentData.data &&
      allAssesmentData.data.status === "success"
    ) {
      let data = [];
      const assessmentDetails = allAssesmentData.data.data.assessmentDetails;
      if (
        allUserAssesmentData.data.data &&
        allUserAssesmentData.data.data.length > 0
      ) {
        data = allUserAssesmentData.data.data;
      }

      let tempMenuObj = [];
      assessmentDetails.forEach((pillarData) => {
        let menuTabs = [];
        let tabIds = [];
        let uiTypes = [];
        let useroptions = [];
        pillarData.section.forEach((tab) => {
          menuTabs.push(tab.goalName); //goal names array
          tabIds.push(tab.goalId);
          uiTypes.push(tab.uiType ? tab.uiType : "");
          useroptions.push(tab.data); //impact stament with action_id,impactActions  and type
        });
        tempMenuObj.push({
          pillarId: pillarData.pillarId,
          iconUrl: pillarData.iconUrl,
          pillar: pillarData.pillar,
          pillarDisplayName: pillarData.pillarDisplayName,
          tabs: menuTabs,
          tabIds: tabIds,
          uiTypes: uiTypes,
          userOptions: useroptions,
          displayOrder: pillarData.displayOrder,
        });
      });

      const UsrObj = data !== "no data" ? data : [];
      generateSurveyData(UsrObj, tempMenuObj);
    }
    // eslint-disable-next-line
  }, [allAssesmentData, allUserAssesmentData]);

  const generateSurveyData = (
    userAssessmetData,
    menuList,
  ) => {
    /*To generate committed statments and changed statements for all goals.This data is displayed in Summary page 
        comitted Statements are identified based on userinput  to statments :-
        dropdown-if value selected is yes
        checkbox-if checkbox is selected
        readio-if option selected is C or D
        Changed Statements are those for which user selected values are different from last phase values.*/
    //debugger;
    let summaryData = [];
    let ansMapping = {};
    menuList &&
      menuList.forEach((pillar) => {
        if (pillar.pillar !== "Summary") {
          pillar.userOptions &&
            pillar.userOptions.forEach((eachGoal) => {
              eachGoal &&
                eachGoal.forEach((action) => {
                  ansMapping[action.action_id] = action;
                });
            });
        }
      });

    menuList &&
      menuList.forEach((pillar) => {
        let tempPillar = {};
        if (pillar.pillar !== "Summary") {
          tempPillar["pillar"] = pillar.pillar;
          tempPillar["pillarDisplayName"] = pillar.pillarDisplayName;
          tempPillar["tabList"] = [];
          pillar.tabIds.forEach((tab, index) => {
            let goalData = {};
            goalData["goal"] = pillar.tabs[index];
            goalData["uiType"] = pillar.uiTypes[index];
            let tempCurrent = {};
            let tempPrev = {};
            let focusedData = [];
            let prevPeriods = timePeriods.filter(timePeriod => (timePeriod !== currentPeriod));
            userAssessmetData &&
              userAssessmetData.length > 0 &&
              userAssessmetData.forEach((userData) => {
                if (
                  userData.goalId === tab &&
                  userData.timePeriod === currentPeriod &&
                  userData.pillarId === pillar.pillarId
                ) {
                  tempCurrent = userData;
                }
                if (
                  userData.goalId === tab &&
                  prevPeriods.findIndex(prevPeriod => (prevPeriod === userData.timePeriod)) !== -1 &&
                  userData.pillarId === pillar.pillarId
                ) {
                  tempPrev = userData;
                }
              });
            tempCurrent &&
              tempCurrent.userInput &&
              tempPrev.userInput &&
              tempCurrent.userInput.forEach((stmnts, qstnIndex) => {
                let focused = {};
                //..Focused statements
                if (stmnts && stmnts.focused) {
                  focused["qstn"] =
                    ansMapping[tempCurrent.userInput[qstnIndex].action_id];
                  if (getAllOptions && getAllOptions.data
                    && Array.isArray(getAllOptions.data)) {
                    let selOption = getAllOptions.data.find(x => x.option_id === tempCurrent.userInput[qstnIndex].option_id);
                    if (selOption && focused["qstn"]) {
                      focused["qstn"].selectedOption = selOption.options;
                    }
                  }
                }
                if (focused.qstn) focusedData.push(focused);
              });
            goalData["focused"] = focusedData;
            tempPillar["tabList"].push(goalData);
          });

          summaryData.push(tempPillar);
        }
      });
    //..check if any focus statement is selected
    let anyFocusedStmtSelected = false;
    if (summaryData && Array.isArray(summaryData)) {
      summaryData.map((d, i) => {
        if (d.tabList && Array.isArray(d.tabList)) {
          d.tabList.map((g, i) => {
            if (g.focused && Array.isArray(g.focused) && g.focused.length > 0) {
              anyFocusedStmtSelected = true;
            }
          });
        }
      });
    }
    setAnyFocusedStmtSelected(anyFocusedStmtSelected);
    setSummaryData(summaryData);
  };
  useEffect(() => {
    //API call for getting list of counselees on page load
    (async function () {
      //debugger;
      setLoader(true);
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        // dispatch({
        //   type: "GET_COUNSELEE_DISCUSSION_GUIDE",
        //   payload: {
        //     financialYear: myContext.financialYear
        //   },
        // });
        dispatch({
          type: "GET_ALL_USER_ASSESSMENT_DATA",
          payload: {
            token: idToken,

            activePeriod: myContext.activePeriod,
            financialYear: myContext.financialYear,
          },
        });
        dispatch({
          type: "GET_ALL_ASSESSMENT_DATA",
          payload: {
            token: idToken,

            activePeriod: myContext.activePeriod,
            financialYear: myContext.financialYear,
          },
        });
        dispatch({
          type: "GET_ALL_OPTIONS", //get all option id's and description like {option_id: "t1000",options: "I do not have this opportunity in my BOB"}
          payload: {
            activePeriod: myContext.activePeriod,
            financialYear: myContext.financialYear,
            tokens: idToken,
          },
        });
        // initial fetch call ,on page load, focus statements of selected counselee
      } catch (e) {
        console.error(e);
      }
    })();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    //debugger;
    setLoader(true);
    if (counseleesFinalizationDetails &&
      !counseleesFinalizationDetails.fetching &&
      counseleesFinalizationDetails.data) {
      const data = counseleesFinalizationDetails.data;
      if (data.name) {
        setFinalizationFormDetails(data);
        setFieldValues((prevState) => ({
          ...prevState,
          userId: data.userId,
          //counseleeId: data.counseleeId,
          firstName: data.firstName,
          lastName: data.lastName,
          name: data.name,
          gui: data.gui,
          rank: data.rank,
          region: data.region,
          primarySector: data.primarySector,
          office: data.office,
          leadReviewer: data.leadReviewer,
          leadReviewerGUI: data.leadReviewerGUI,
          leadReviewerEmail: data.leadReviewerEmail,
          question1AnsContent: data.question1AnsContent,
          //question1AnsPlainText: null,    
          question2AnsContent: data.question2AnsContent,
          //question2AnsPlainText: null,    
          revenueMargin: data.revenueMargin,
          revenueMarginContent: data.revenueMarginContent,
          salesAndPipeline: data.salesAndPipeline,
          salesAndPipelineContent: data.salesAndPipelineContent,
          qualityContent: data.qualityContent,
          ecs: data.ecs,
          ecsContent: data.ecsContent,
          peopleAndTeaming: data.peopleAndTeaming,
          peopleAndTeamingContent: data.peopleAndTeamingContent,
          //draftFinalizationFormComment: data.draftFinalizationFormComment,
          distinguishPerformanceContent: data.distinguishPerformanceContent,
          developmentPointsContent: data.developmentPointsContent,
          potentialAndAspirationsContent: data.potentialAndAspirationsContent,
          submittedDate: data.submittedDate,
          status: data.status,
          progress: 0,
          question1AnsPlainText: data.question1AnsPlainText,
          question2AnsPlainText: data.question2AnsPlainText,
          revenueMarginPlainText: data.revenueMarginPlainText,
          salesAndPipelinePlainText: data.salesAndPipelinePlainText,
          qualityPlainText: data.qualityPlainText,
          ecsPlainText: data.ecsPlainText,
          peopleAndTeamingPlainText: data.peopleAndTeamingPlainText,
          distinguishPerformancePlainText: data.distinguishPerformancePlainText,
          developmentPointsPlainText: data.developmentPointsPlainText,
          potentialAndAspirationsPlainText: data.potentialAndAspirationsPlainText,
        }));
        setLoader(false);
      }
    }
  }, [counseleesFinalizationDetails]);

  //..Finalization form handlers
  const selectOnChange = (fieldname, value) => {
    //debugger;
    setFieldValues({ ...fieldValues, [fieldname]: value });
    setErrorMessages({ ...errorMessages, [fieldname]: "" });
  };

  const handleTextChange = (e) => {
    //debugger;
    const { name, value, namePlainText, plainText } = e.target;
    setFieldValues({ ...fieldValues, [name]: value, [namePlainText]: plainText });
    setErrorMessages({ ...errorMessages, [name]: "" });
  };

  return (
    <>
      {loader && <Loader />}
      <div className="admin-container general-parameters">
        <div className="admin-info-main-sidemenu">
          <SideMenu
            minimized={true}
            isminimizedMainMenu={true}
            isMainMenu={true}
            sideMenuItemSelect="Home"
          />
        </div>
        <div className="admin-content">
          <div className="second-header admin-crumbs">
            <div className="admin-crumb-txt">
              <Breadcrumb
                crumbs={crumbs}
                isRouteFromDiscussionGuide={true}
                {...props}
              />
            </div>
            <div className="btn-wrapper-top" style={{ right: "17px" }}>
              <div
                className={
                  "btn" +
                  (!true
                    ? " opacityReduced "
                    : "")
                }
                style={{
                  cursor: `${true
                    ? " pointer"
                    : "auto"
                    }`,
                  opacity: `${true ? 1 : 0.5
                    }`,
                }}
                onClick={() => {
                  setOpen(true);
                }}>
                <i
                  className="eyaa-image-25"
                  style={{ marginRight: "5px" }}
                />
                Preview
              </div>
              {/* {
                fieldValues &&
                fieldValues.name && (
                  <DownloadTemplateCounseleeSelf
                    data={fieldValues} />
                )
              } */}
              {
                fieldValues &&
                fieldValues.name && (
                  <DownloadTemplateSelfDG
                    data={fieldValues} />
                )
              }
            </div>
          </div>
          {
            finalizationFormDetails &&
            finalizationFormDetails.name &&
            (
              <FinalizationFormSelf
                fieldValues={fieldValues}
                handleTextChange={handleTextChange}
                selectOnChange={selectOnChange}
                errorMessages={errorMessages}
                setErrorMessages={setErrorMessages}
                mandatoryFields={mandatoryFields}
                summaryData={summaryData}
                assessmentObj={assessmentObj}
                allOptionList={allOptionList}
                anyFocusedStmtSelected={anyFocusedStmtSelected}
                isFormPreview={false}
                history={props.history}
              />
            )
          }
        </div>
      </div>
      <div className='email-template-modal'>
        <Modal
          open={isOpen}
          onClose={() => {
            setOpen(false);
          }}
          styles={{
            modal: {
              maxWidth: "unset",
              padding: "unset",
            },
            overlay: {
              background: "rgba(0, 0, 0, 0.5)",
            }
          }}
          center
        >
          <div className="email-template-wrap">
            <div className="modal-heading" style={{ backgroundColor: "#ffe600" }}>
              {`FY${myContext.financialYear} Counselee Year-End Discussion Guide`}
            </div>
            <SimpleBar
              style={{
                overflowX: "hidden",
                maxHeight: "80vh",
                width: '70vw',
              }}
            >
              <div style={{ minHeight: "80vh", padding: '10px', paddingTop: "0px" }}>
                {/* <FinalizationFormSelfPreview
                  //data={finalizationFormDetails}
                  fieldValues={fieldValues} /> */}
                <FinalizationFormSelf
                  fieldValues={fieldValues}
                  handleTextChange={handleTextChange}
                  selectOnChange={selectOnChange}
                  errorMessages={errorMessages}
                  mandatoryFields={mandatoryFields}
                  summaryData={summaryData}
                  assessmentObj={assessmentObj}
                  allOptionList={allOptionList}
                  anyFocusedStmtSelected={anyFocusedStmtSelected}
                  isFormPreview={true}
                  history={props.history}
                />
              </div>
            </SimpleBar>
          </div>
        </Modal>
      </div>
    </>
  );
};
export default FinalizationGuidanceSelf;
