import * as myConsts from "./constants";
export const nonAdminMenu = [
  {
    label: "Home",
    icon: "icon-home",
    id: "1005",
    route: myConsts.routeHome,
    activeIcon: "app_new.svg",
  },
  // {
  //   label: "Channel",
  //   icon: "icon-global-arrow",
  //   id: "1001",
  //   route: "/Channel",
  //   activeIcon: "app_new.svg",
  // },
  // {
  //   label: "Book of Business",
  //   icon: "icon-file-70",
  //   id: "1004",
  //   route: "/BookOfBusiness",
  //   activeIcon: "app_new.svg",
  // },
  // {
  //   label: "Assessment",
  //   icon: "icon-recent2",
  //    id: "1002",
  //    route: "/Assessment",
  //   activeIcon: "Report.svg",
  // },
  // {
  //   label: "Dashboard",
  //   icon: "icon-views-052",
  //   id: "1003",
  //   route: "/Dashboard",
  //   activeIcon: "Frameworks_Active.svg",
  // },
];

export const adminMenu = {
  label: "Admin",
  icon: "icon-user-09",
  id: "1006",
  route: myConsts.routeAdmin,
  activeIcon: "app_new.svg",
};