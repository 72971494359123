import { combineReducers } from "redux";

import {
  outsideClikcFn,
  setUserAssesmentData,
  getAllAssesmentData,
  getAllPrevAssesmentData,
  getAllUserAssesmentData,
  getUserDetails,
  getUserImg,
  getOptionValues,
  getAssesmentMetaData,
  setAssesmentStart,
  getUserProfileById,
  getAllOptions,
  getUserBobSummaryData,
  submitUserAssessment,
  getUserAssessmentStatusData,
  getUserAssesmentScore,
  getBobData,
  updateBobData,
  getUserGoalScore,
  getResponsibiltyAndSectors,
  setDashboardPillarFilters,
  setDashboardGoalFilters,
  getPillarGroupScore,
  getGoalGroupScore,
  getQuantitativeScore,
  getCounseleesStatus,
  getCounseleesFinalizationDetails,
  addOrEditCounseleesFinalizationDetails,
  getCounseleeDiscussionGuide,  
  addOrUpdateCounseleeDiscussionGuide,
  searchDiscussionGuide,
  resetDiscussionGuide,
  ppmdStatusFilters,
  getReviewerHomePageData,
  acceptOrRejectCounselee,
  commentRead,
  searchUser,
  resetUser,
  searchClient,
  getGeneralParam,
  editGeneralParam,
  setApiErrorCode,
  getReportsData,
  outsideClickAdmin,
  outsideClickBob,
  setUserUUID,
  getExcelReportData,
  getTimePeriodStatus,
  getAssessmentSectionList,
  editAssessmentSectionstate,
  deleteAssessmentSectionstate,
  getAdminSearchUserDetails,
  getAdminSearchManagerDetails,
  getUserCountstate,
  searchUserData,
  searchUserDataMatch,
  rollForwardUsers,
  checkLockStatus,
  addMultipleUsers,
  bulkUploadDiscussionGuide,
  addSingleUser,
  editSingleUser,
  deleteSingleUser,
  getAdminConfig,
  outsideClickCommon,
  addGlobalMetric,
  editGlobalMetric,
  deleteGlobalMetric,
  rollForwardAssessment,
  getAssesmentGoalData,
  reOrderGlobalMetric,
  reOrderAssessmentSection,
  uploadAssessmentContent,
  regionDataUpload,
  generateBookOfBusiness,
  addAssessmentGoal,
  editAssessmentGoal,
  reorderAssessmentGoal,
  deleteAssessmentGoal,
  getAssessmentStatus,
  getValidateUserChangeData,
  getUserDataChangesData,
  updateUserDetails,
  deleteUsers,
  getPhaseValidationStatus,
  getEmailTemplate,
  editEmailTemplate,
  addImpactStatement,
  moveImpactStatement,
  deleteImpactStatement,
  sortImpactStatement,
  getBobRollforwardStatus,
  getAdhocMailList,
  cancelAdhocMailRequest,
  createAdhocMailRequest,
  getGeneralSection,
  editGeneralSection,
  getInvalidAssessmentData,
  submitAdminAssessment,
  getBobRollforwardToNextPhase,
  progressStatusFromBob,
  bobAdminActionStatus,
  mercuryFileUpload,
  validAssessmentYears,
  approvalDataTemplate,
  getUpdateHighlightData,
  getAssessmentSubmissionStatus,
  adminAppState,
  getApprovalCount,
  submitAdminReview,
  getReportFilter,
  getAdditionalReportData,
  exportAdditionalReportData,
  getPowerBIlink,
  updatePowerBILink,
  getUserStatusAssessment,
  setViewUserData,
  exportEntireAssessmentData,
  getValidationAssesmentMetaData,
  getApprovedCompletedYearsList,
  getCompletedPhase,
  maintenanceError,
  uploadGeneralContent,
  getGeneralContent,
} from "./reducers";

const rootReducer = combineReducers({
  isOutsideClick: outsideClikcFn,
  saveUserAssesmentData: setUserAssesmentData,
  allAssesmentData: getAllAssesmentData,
  getAllPrevAssesmentData,
  allUserAssesmentData: getAllUserAssesmentData,
  getUserDetails,
  getUserImg,
  getOptionValues,
  getAssesmentMetaData,
  setAssesmentStart,
  userProfile: getUserProfileById,
  getAllOptions,
  userBobSummary: getUserBobSummaryData,
  submitUserAssessment,
  userAssessmentStatus: getUserAssessmentStatusData,
  userAssesmentScore: getUserAssesmentScore,
  getBobData,
  updateBobData,
  userGoalScore: getUserGoalScore,
  responsibiltyAndSectors: getResponsibiltyAndSectors,
  dashboardPillarFilters: setDashboardPillarFilters,
  dashboardGoalFilters: setDashboardGoalFilters,
  getCounseleesStatus,
  getCounseleesFinalizationDetails,
  addOrEditCounseleesFinalizationDetails,
  getCounseleeDiscussionGuide,  
  addOrUpdateCounseleeDiscussionGuide,
  searchDiscussionGuide,
  resetDiscussionGuide,
  pillarGroupScore: getPillarGroupScore,
  goalGroupScore: getGoalGroupScore,
  ppmdStatusFilters,
  getReviewerHomePageData,
  acceptOrRejectCounselee,
  commentRead,
  quantitativeScore: getQuantitativeScore,
  searchUser,
  resetUser,
  searchClient,
  getGeneralParam,
  editGeneralParam,
  apiErrorCode: setApiErrorCode,
  reportsData: getReportsData,
  outsideClickAdmin,
  outsideClickBob,
  setUserUUID,
  excelReportData: getExcelReportData,
  timePeriodStatus: getTimePeriodStatus,
  getAssessmentSectionList,
  editAssessmentSectionstate,
  deleteAssessmentSectionstate,
  adminSearchUserDetails: getAdminSearchUserDetails,
  adminSearchManagerDetails: getAdminSearchManagerDetails,
  getUserCountstate,
  searchUserData,
  searchUserDataMatch,
  rollForwardUsers,
  checkLockStatus,
  addMultipleUsers,
  bulkUploadDiscussionGuide,
  addSingleUser,
  editSingleUser,
  deleteSingleUser,
  adminConfig: getAdminConfig,
  outsideClickCommon,
  addGlobalMetric,
  editGlobalMetric,
  deleteGlobalMetric,
  reOrderGlobalMetric,
  reOrderAssessmentSection,
  rollForwardAssessment,
  getAssessmentStatus,
  uploadAssessmentContent,
  regionDataUpload,
  getAssesmentGoalData,
  addAssessmentGoal,
  editAssessmentGoal,
  reorderAssessmentGoal,
  deleteAssessmentGoal,
  validateUserChange: getValidateUserChangeData,
  getUserDataChanges: getUserDataChangesData,
  updateUserDetails: updateUserDetails,
  deleteUsers,
  getPhaseValidationStatus,
  getEmailTemplate,
  editEmailTemplate,
  addImpactStatement,
  moveImpactStatement,
  deleteImpactStatement,
  sortImpactStatement,
  getBobRollforwardStatus,
  getAdhocMailList,
  cancelAdhocMailRequest,
  createAdhocMailRequest,
  getGeneralSection,
  editGeneralSection,
  getInvalidAssessmentData,
  submitAdminAssessment,
  getBobRollforwardToNextPhase,
  progressStatusFromBob,
  bobAdminActionStatus,
  mercuryFileUpload,
  generateBookOfBusiness,
  validAssessmentYears,
  approvalData: approvalDataTemplate,
  getUpdateHighlightData,
  getAssessmentSubmissionStatus,
  adminAppState,
  getApprovalCount,
  submitAdminReview,
  getReportFilter,
  getAdditionalReportData,
  exportAdditionalReportData,
  getPowerBIlink,
  updatePowerBILink,
  getUserStatusAssessment,
  setViewUserData,
  exportEntireAssessmentData,
  getValidationAssesmentMetaData,
  getApprovedCompletedYearsList,
  getCompletedPhase,
  maintenanceError,
  uploadGeneralContent,
  generalContent: getGeneralContent,
});

export default rootReducer;
