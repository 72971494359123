import React, { useState, useCallback, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import _ from "lodash";
import "./AddUser.scss";
import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  Grid,
  Checkbox,
  ListItemText,
  FormHelperText,
  IconButton,
  InputAdornment,
  Button,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateRangeIcon from "@material-ui/icons/DateRange";
import SimpleBar from "simplebar-react";
import { toast, Flip } from "react-toastify";
import Loader from "../../../Loader/Loader";
import { validateEmail } from "../../../../utils/utils";
import AppContext from "../../../../utils/appContext";
import * as Config from "./Data/Config";
import { PrepareAddRequest, PrepareEditRequest } from "./Data/PrepareRequest";
import NavigationPrompt from "react-router-navigation-prompt";
import PreviewMode from "./PreviewMode";
import ReviewConfirmation from "../../Approvals/ReviewConfimation/ReviewConfirmation";
import * as myConsts from "../../../../constants/constants";

const AddUser = (props) => {
  const {
    mode,
    financialYear,
    phase,
    onCancel,
    onUserAdd,
    onUserUpdate,
    onUserDelete,
    searchText,
    history
  } = props;
  const myContext = useContext(AppContext);
  const menuProps = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };
  const dispatch = useDispatch();
  const [currentMode, setCurrentMode] = useState(mode);
  const [initialMode, setInitialMode] = useState(mode);
  const [isReviewer, setIsReviewer] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [searchVal, setSearchVal] = useState(searchText);
  const [isLoading, setIsLoading] = useState(false);
  const [formValues, setFormValues] = useState(_.cloneDeep(Config.formData));
  const [initialFormValues, setInitialFormValues] = useState([]);
  const [popupContent, setPopupContent] = useState("");
  const [successText, setSuccessText] = useState("");
  const [isUserExist, setIsUserExist] = useState(false);
  const [isSearchBtnClicked, setIsSearchBtnClicked] = useState(false);
  const [isCancelBtnClicked, setIsCancelBtnClicked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const userDetailsAd = useSelector((state) => state.adminSearchUserDetails);
  const [highlightsData, setHighlightsData] = useState({});
  const [showReviewConfirmation, setShowReviewConfirmation] = useState(false);
  const [madeLRAutoEmail, setMadeLRAutoEmail] = useState(false);
  const managerDetailsAd = useSelector(
    (state) => state.adminSearchManagerDetails
  );
  const userDetailsDb = useSelector((state) => state.searchUserData.data);
  const addSingleUser = useSelector((state) => state.addSingleUser);
  const editSingleUser = useSelector((state) => state.editSingleUser);
  const adminConfig = useSelector((state) => state.adminConfig);
  const currentUserDetails = useSelector((state) => state.getUserDetails.data);
  const getUpdateHighlightData = useSelector(
    (state) => state.getUpdateHighlightData
  );
  const submitAdminReview = useSelector((state) => state.submitAdminReview);
  const adminApprovalData = useSelector((state) => state.approvalData);


  const searchUser = useCallback(() => {
    dispatch({
      type: "SEARCH_USER_DATA",
      payload: {
        financialYear,
        timePeriod: phase,
        searchText: searchVal,
      },
    });
  }, [searchVal, dispatch]);

  const searchUserFromAD = useCallback(() => {
    dispatch({
      type: "GET_ADMIN_SEARCH_USER_DETAILS",
      payload: {
        emailId: searchVal,
      },
    });
    dispatch({
      type: "GET_ADMIN_SEARCH_MANAGER_DETAILS",
      payload: {
        emailId: searchVal,
      },
    });
    setIsSearchBtnClicked(false);
  }, [searchVal, dispatch]);

  const setManagerDetails = useCallback(() => {
    const copyFormValues = { ...formValues };
    copyFormValues.counselorName.value = managerDetailsAd.data.displayName;
    copyFormValues.counselorEmail.value = managerDetailsAd.data.mail;
    /*copyFormValues.tenorStartDate.value = moment(new Date()).format(
      "YYYY/MM/DD"
    );*/
    setFormValues(copyFormValues);
  }, [formValues, managerDetailsAd]);

  const setUserDetails = useCallback(
    (val, name, type) => {
      const numberPattern = new RegExp("^[0-9]*$");
      const copyFormValues = { ...formValues };

      if (type === "number" && !numberPattern.test(val)) {
        return;
      }
      copyFormValues[name].value = val;
      copyFormValues[name].touched = true;

      if (
        copyFormValues[name].value.length === 0 ||
        copyFormValues[name].value === "Select"
      ) {
        copyFormValues[name].error = true;
        copyFormValues[name].errorText = "*Required";
      } else if (
        name === "counselorEmail" &&
        !validateEmail(copyFormValues[name].value)
      ) {
        copyFormValues[name].error = true;
        copyFormValues[name].errorText = "Invalid email format";
      } else {
        copyFormValues[name].error = false;
      }

      if (name === "tenorStartDate") {
        copyFormValues[name].value = moment(val).format("YYYY/MM/DD");
      }
      setFormValues(copyFormValues);
    },
    [formValues]
  );

  const proccessRoles = useCallback(
    (role) => {
      let roles = [];
      if (role === "Admin") {
        return ["Admin"];
      } else if (role === "LeadReviewer-PPMD") {
        roles = ["Lead Reviewer", "PPMD"];
      } else if (role === "LeadReviewer") {
        roles = ["Lead Reviewer"];
      } else {
        roles = [role];
      }
      if (
        (initialMode === "preview" && props.userDetails.isAdmin) ||
        userDetailsDb?.data?.isAdmin
      ) {
        roles.push("Admin");
      }
      /*  if (
          (initialMode === "preview" && props.userDetails.isOperationalAdmin) ||
          userDetailsDb?.data?.isOperationalAdmin
        ) {
          roles.push("Operational Admin");
        } */
      return roles;
    },
    [userDetailsDb, props.userDetails, initialMode]
  );

  const proccessAdditionalResponsibilies = useCallback((data) => {
    if (_.isObject(data)) {
      return data;
    } else if (data) {
      return [data];
    } else {
      return [];
    }
  }, []);

  const setInitialUserDetails = useCallback(
    (data) => {
      let copyFormValues = { ...formValues };
      Object.keys(copyFormValues).forEach((key) => {
        if (key === "role") {
          copyFormValues[key].value = proccessRoles(data[key]) || [];
        } else if (key === "additionalResponsibility") {
          copyFormValues[key].value = proccessAdditionalResponsibilies(
            data[key]
          );
        } else if (key === "tenorStartDate" && data[key]) {
          copyFormValues[key].value = moment(data[key]).format("YYYY/MM/DD");
        } else {
          copyFormValues[key].value = data[key] || "";
        }
      });
      setFormValues(copyFormValues);
      setInitialFormValues(_.cloneDeep(copyFormValues));
    },
    [formValues, proccessRoles]
  );

  const isFormError = useCallback(() => {
    let isDisabled = false;
    Object.keys(formValues).forEach((key) => {
      if (
        (formValues[key].value === "" ||
          formValues[key].value === "Select" ||
          formValues[key].value === null ||
          (key === "role" && formValues[key].value.length === 0) ||
          (key === "counselorEmail" &&
            !validateEmail(formValues[key].value))) &&
        formValues[key].isRequired
      ) {
        isDisabled = true;
      }
    });
    return isDisabled;
  }, [formValues])

  const isBtnDisabled = useCallback(() => {
    if (_.isEqual(formValues, initialFormValues) && currentMode === "Edit") {
      return true;
    }
    return isFormError();
  }, [formValues, initialFormValues, currentMode, isFormError]);

  const addUser = useCallback(() => {
    const request = PrepareAddRequest(
      formValues,
      currentMode !== "Add new user" ? userDetailsDb : userDetailsAd,
      financialYear,
      phase,
      currentMode,
      currentUserDetails
    );
    dispatch({
      type: "ADD_SINGLE_USER",
      payload: request,
    });
  }, [
    formValues,
    userDetailsDb,
    userDetailsAd,
    financialYear,
    phase,
    currentMode,
    dispatch,
    currentUserDetails,
  ]);

  const editUser = useCallback(() => {
    const request = PrepareEditRequest(
      formValues,
      userDetailsDb,
      financialYear,
      phase,
      currentUserDetails,
      isSubmitted,
      madeLRAutoEmail
    );
    dispatch({
      type: "EDIT_SINGLE_USER",
      payload: request,
    });
  }, [
    formValues,
    userDetailsDb,
    dispatch,
    financialYear,
    phase,
    currentUserDetails,
    isSubmitted,
  ]);

  const saveUser = useCallback(() => {
    setIsPopupVisible(false);
    if (isCancelBtnClicked) {
      onCancel();
    } else if (currentMode === "Edit") {
      editUser();
      props.changesInChild(false);
    } else {
      addUser();
      props.changesInChild(false);
    }
  }, [currentMode, addUser, editUser, isCancelBtnClicked, isBtnDisabled]);

  const notify = useCallback(
    (successMsg = "") => {
      toast(
        <div className="success-text">
          <span className="icon-verified-outline icon"></span>
          <p>
            {!successMsg && <span>Success: </span>}
            <span>{successMsg ? successMsg : successText}</span>
          </p>
        </div>,
        {
          position: "top-center",
          transition: Flip,
          autoClose: 8000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    },
    [successText]
  );

  const checkForChanges = useCallback(() => {
    setIsCancelBtnClicked(true);
    if (
      !_.isEqual(formValues, initialFormValues) ||
      (!_.isEmpty(userDetailsAd.data) && !userDetailsAd.error)
    ) {
      setPopupContent(
        "Would you like to navigate without completing the operation? Please note that on confirming, incomplete actions will be lost."
      );
      setIsPopupVisible(true);
    } else {
      onCancel();
    }
  }, [isBtnDisabled, onCancel]);

  const setSubmitDetails = useCallback(() => {
    setPopupContent(
      "Would you like to submit the changes for review? Please note that you will be able to make further edits once the reviewer responds."
    );
    setIsPopupVisible(true);
    setSuccessText("User details submitted for review.");
    setIsSubmitted(true);
  });

  const setEditDetails = useCallback(() => {
    setPopupContent("Would you like to update the user details?");
    setIsPopupVisible(true);
    setSuccessText("User details updated.");
    setIsSubmitted(false);
  }, []);

  const setAddDetails = useCallback(() => {
    setPopupContent(
      "Would you like to Add the user for review? Please note that you will not be able to make any edits once the reviewer responds."
    );
    setIsPopupVisible(true);
    setSuccessText("New user has been submitted for review.");
  }, []);

  const dismissReviewWindow = useCallback(() => {
    dispatch({
      type: "RESPOND_BUTTON_CLICKED_RESET",
      payload: {},
    });
    setShowReviewConfirmation(false);
  }, [dispatch]);

  const submitReviewResponse = useCallback(
    (res) => {
      dismissReviewWindow();
      setIsLoading(true);
      const payload = {
        type: "UserManagement",
        timePeriod: adminApprovalData.previewParams.timePeriod,
        financialYear: adminApprovalData.previewParams.financialYear,
        validatedBy: {
          userId: myContext.userId,
          name: myContext.displayName,
        },
        requestedUserId: adminApprovalData.previewParams.data.userId,
        validationStatus: res,
        action: adminApprovalData.previewParams.action,
      };
      dispatch({
        type: "SEND_ADMIN_REVIEW",
        payload,
      });
    },
    [dismissReviewWindow, myContext]
  );

  const enableSubmitButton = useCallback(() => {
    if (currentMode === "Edit" && userDetailsDb.validationStatus === "InProgress" && !isFormError()) {
      return false;
    } else if (currentMode !== "Edit") {
      return true;
    } else if (currentMode === "Edit" && _.isEqual(formValues, initialFormValues)) {
      return true;
    } else if (isFormError()) {
      return true;
    } else {
      return false;
    }
  }, [currentMode, initialFormValues, formValues, userDetailsDb, isFormError]);

  useEffect(() => {
    if (managerDetailsAd.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      setManagerDetails();
    }
    // eslint-disable-next-line
  }, [managerDetailsAd]);

  useEffect(() => {
    if (userDetailsAd.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (!_.isEmpty(userDetailsAd.data) && !userDetailsAd.error) {
        props.changesInChild(true);
      } else {
        props.changesInChild(false);
      }
    }
    // eslint-disable-next-line
  }, [userDetailsAd]);

  /* useEffect(() => {
    if (currentMode === "Edit") {
      setPopupContent("Would you like to update the user details?");
      setSuccessText("User details updated.");
    } else {
      setPopupContent(" Would you like to Add the user for review? Please note that you will not be able to make any edits once the reviewer responds.");
      setSuccessText("New user has been submitted for review.");
    }
    // eslint-disable-next-line
  }, [currentMode]); */

  useEffect(() => {
    if (
      submitAdminReview &&
      !submitAdminReview.fetching &&
      (submitAdminReview.data.success || submitAdminReview.data.message === "success")
    ) {
      const flag = _.get(submitAdminReview, "reviewState.validationStatus", "");
      if (flag === "Approved") {
        notify("Successfully approved the request");
      } else {
        notify("Successfully rejected the request");
      }
      dispatch({
        type: "SEND_ADMIN_REVIEW_RESET",
        payload: { type: "UserManagement" },
      });
      setIsLoading(false);
      setTimeout(() => { history.push(myConsts.routeAdminApprovals); }, 1000)
    }
  }, [submitAdminReview, myConsts, notify, history]);

  useEffect(() => {
    if (
      _.isObject(userDetailsDb?.data) &&
      userDetailsDb?.searchFlag !== "notInDb" &&
      (initialMode === "Add user" || initialMode === "Add new user")
    ) {
      setCurrentMode("Add user");
      setInitialUserDetails(userDetailsDb.data);

      if (initialMode === "Add new user") {
        setIsUserExist(true);
      }
    } else if (
      userDetailsDb?.data === "noData" &&
      userDetailsDb?.searchFlag === "notInDb" &&
      (initialMode === "Add user" || initialMode === "Add new user")
    ) {
      setIsUserExist(false);
      setCurrentMode("Add new user");
      if (isSearchBtnClicked) {
        searchUserFromAD();
      }
    } else if (initialMode === "Edit" || initialMode === "View") {
      setIsUserExist(false);
      setInitialUserDetails(userDetailsDb.data);
    } else if (initialMode === "preview") {
      setInitialUserDetails(props.userDetails);
    }
  }, [userDetailsDb, searchUserFromAD, initialMode, isSearchBtnClicked]);

  useEffect(() => {
    if (addSingleUser.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (!_.isEmpty(addSingleUser.data)) {
        onUserAdd();
        notify();
      }
    }
  }, [addSingleUser, notify, onUserAdd]);

  useEffect(() => {
    if (editSingleUser.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
      if (!_.isEmpty(editSingleUser.data)) {
        onUserUpdate();
        notify();
      }
    }
  }, [editSingleUser, notify, onUserUpdate]);

  useEffect(() => {
    if (_.isEmpty(adminConfig.data)) {
      dispatch({
        type: "GET_ADMIN_MASTER_CONFIG",
      });
    }
    return () => {
      dispatch({
        type: "GET_ADMIN_SEARCH_USER_DETAILS_RESET",
        payload: {},
      });
      dispatch({
        type: "SEARCH_USER_DATA_RESET",
        payload: {},
      });
      setFormValues(_.cloneDeep(Config.formData));
      setIsCancelBtnClicked(false);
    };
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (adminConfig.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [adminConfig]);

  useEffect(() => {
    if (searchVal === "") {
      dispatch({
        type: "GET_ADMIN_SEARCH_USER_DETAILS_RESET",
        payload: {},
      });
      dispatch({
        type: "GET_ADMIN_SEARCH_MANAGER_DETAILS_RESET",
        payload: {},
      });
    }
  }, [searchVal]);

  useEffect(() => {
    if (_.isEqual(formValues, initialFormValues)) {
      props.changesInChild(false);
    } else {
      props.changesInChild(true);
    }
  }, [initialFormValues, formValues]);

  useEffect(() => {
    setHighlightsData(_.get(getUpdateHighlightData, "data.updatedList", {}));
    if (getUpdateHighlightData.fetching) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [getUpdateHighlightData]);

  useEffect(() => {
    const type = _.get(adminApprovalData, "previewParams.type", "");
    if (type === "UserManagement") {
      setIsReviewer(_.get(adminApprovalData, "previewParams.isReviewer", false))
    }
  }, [adminApprovalData])

  const checkForLRAutoEmail = useCallback(() => {
    setMadeLRAutoEmail(!madeLRAutoEmail);
  });

  return (
    <>
      {currentMode === "View" || currentMode === "Edit" ? (
        <div className="status-container">
          {!_.isEmpty(userDetailsDb) && (
            <span className="status">
              {userDetailsDb.validationStatus === "InProgress"
                ? "Draft"
                : userDetailsDb.validationStatus}
            </span>
          )}
          {/* <Button
            variant="outlined"
            className="delete-btn"
            startIcon={<span className="icon-verified-outline icon" />}
            disabled={
              enableSubmitButton()
            }
            onClick={setSubmitDetails}
          >
            Submit
          </Button> */}
        </div>
      ) : (
        currentMode === "preview" && isReviewer && (
          <div className="status-container">
            {" "}
            <Button
              variant="outlined"
              className="delete-btn"
              startIcon={<span className="icon-verified-outline icon" />}
              onClick={() => setShowReviewConfirmation(true)}
            >
              Respond
            </Button>
          </div>
        )
      )}
      <SimpleBar className="user-details-container">
        {isLoading && <Loader />}
        <NavigationPrompt
          when={
            !_.isEqual(formValues, initialFormValues) ||
            (!_.isEmpty(userDetailsAd.data) && !userDetailsAd.error)
          }
        >
          {({ onConfirm, onCancel }) => (
            <div className="popup-dialog">
              <div className="dialog">
                <div className="header">
                  <h5>Confirmation</h5>
                  <span
                    className="icon-close-filled icon"
                    onClick={onCancel}
                  ></span>
                </div>
                <div className="content">
                  Would you like to navigate without completing the operation?
                  Please note that on confirming, incomplete actions will be
                  lost.
                </div>
                <div className="footer">
                  <button className="cancel-btn" onClick={onCancel}>
                    Cancel
                  </button>
                  <button className="confirm-btn" onClick={onConfirm}>
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
        </NavigationPrompt>
        <div className="header">
          {initialMode === "Add user" && <h4>Add User</h4>}
          {(initialMode === "View" ||
            initialMode === "preview") && <h4>View User Details</h4>}
          {initialMode === "Edit" && <h4>Edit User Details</h4>}
          <span>{`FY ${financialYear}, ${phase}`}</span>
        </div>

        {initialMode === "Add new user" && (
          <div className="input-group">
            <label htmlFor="emailId">Search Email ID to add user</label>
            <p>
              <input
                type="text"
                id="emailId"
                value={searchVal}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && validateEmail(e.target.value)) {
                    setIsSearchBtnClicked(true);
                    searchUser();
                  }
                }}
                onChange={(e) => setSearchVal(e.target.value)}
              />
              <button
                type="button"
                className={classNames("search-btn", {
                  "disable-btn": !validateEmail(searchVal),
                })}
                onClick={() => {
                  setIsSearchBtnClicked(true);
                  searchUser();
                }}
                disabled={!validateEmail(searchVal)}
              >
                Search
              </button>
            </p>
            {!_.isEmpty(userDetailsAd.data) && userDetailsAd.data.error ? (
              <p className="error-text">User not found</p>
            ) : (
              isUserExist && <p className="error-text">User already exists</p>
            )}
          </div>
        )}
        {(((!_.isEmpty(userDetailsAd.data) &&
          !userDetailsAd.data.error &&
          searchVal !== "") ||
          _.isObject(userDetailsDb.data)) &&
          !isUserExist) ||
          (initialMode === "preview" && !_.isEmpty(props.userDetails)) ? (
          <>
            <div className="user-details-table">
              <table>
                <thead>
                  <tr>
                    <th>User name</th>
                    <th>Email ID</th>
                    <th>Rank Description</th>
                  </tr>
                </thead>
                <tbody>
                  {currentMode === "Add new user" ? (
                    <tr>
                      <td>{userDetailsAd.data.displayName}</td>
                      <td>{userDetailsAd.data.mail}</td>
                      <td>{userDetailsAd.data.jobTitle}</td>
                    </tr>
                  ) : currentMode === "preview" ? (
                    <tr>
                      <td>{props.userDetails.preferredName}</td>
                      <td>{props.userDetails.userId}</td>
                      <td>{props.userDetails.rankDesc}</td>
                    </tr>
                  ) : (
                    <tr>
                      <td>{userDetailsDb.data.preferredName}</td>
                      <td>{userDetailsDb.data.userId}</td>
                      <td>{userDetailsDb.data.rankDesc}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            {currentMode === "preview" ? (
              <PreviewMode
                userDetails={props.userDetails}
                highlightsData={highlightsData}
              />
            ) : (
              <div className="demographic-details">
                <h6>Demographic Details</h6>
                <Grid container spacing={2}>
                  <Grid item md={2}>
                    <TextField
                      label="GPN*"
                      variant="filled"
                      className="input-control"
                      value={formValues.gpn.value}
                      onChange={(e) =>
                        setUserDetails(e.target.value, "gpn", "text")
                      }
                      inputProps={{ maxLength: 20 }}
                      error={formValues.gpn.error}
                      helperText={
                        formValues.gpn.error ? formValues.gpn.errorText : ""
                      }
                      disabled={currentMode === "View"}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label="LPN*"
                      variant="filled"
                      className="input-control"
                      value={formValues.lpn.value}
                      onChange={(e) =>
                        setUserDetails(e.target.value, "lpn", "text")
                      }
                      inputProps={{ maxLength: 20 }}
                      error={formValues.lpn.error}
                      helperText={
                        formValues.lpn.error ? formValues.lpn.errorText : ""
                      }
                      disabled={currentMode === "View"}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label="GUI*"
                      variant="filled"
                      className="input-control"
                      value={formValues.gui.value}
                      onChange={(e) =>
                        setUserDetails(e.target.value, "gui", "number")
                      }
                      inputProps={{ maxLength: 20 }}
                      error={formValues.gui.error}
                      helperText={
                        formValues.gui.error ? formValues.gui.errorText : ""
                      }
                      disabled={currentMode !== "Add new user"}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormControl variant="filled" className="input-control">
                      <InputLabel id="rankCode">Rank Code*</InputLabel>
                      <Select
                        MenuProps={menuProps}
                        label="Rank Code*"
                        labelId="rankCode"
                        value={formValues.rankCode.value}
                        onChange={(e) =>
                          setUserDetails(e.target.value, "rankCode", "text")
                        }
                        className={
                          formValues.rankCode.value === "Select"
                            ? "input-disable"
                            : ""
                        }
                        error={formValues.rankCode.error}
                        disabled={currentMode === "View"}
                      >
                        <MenuItem value="Select" style={{ opacity: "0.5" }}>
                          Select
                        </MenuItem>
                        {!_.isEmpty(adminConfig.data) &&
                          adminConfig.data.rankData.length > 0 &&
                          Config.getRankCodes(adminConfig.data.rankData).map(
                            (rank, index) => (
                              <MenuItem value={rank} key={index}>
                                {rank}
                              </MenuItem>
                            )
                          )}
                      </Select>
                      {formValues.rankCode.error && (
                        <FormHelperText className="error-text">
                          {formValues.rankCode.errorText}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={2}>
                    <FormControl variant="filled" className="input-control">
                      <InputLabel id="serviceLine">Service line*</InputLabel>
                      <Select
                        MenuProps={menuProps}
                        labelId="serviceLine"
                        className={
                          formValues.serviceLine.value === "Select"
                            ? "input-disable"
                            : ""
                        }
                        value={formValues.serviceLine.value}
                        onChange={(e) => {
                          setUserDetails(e.target.value, "serviceLine", "text");
                          setUserDetails("Select", "subServiceLine", "text");
                        }}
                        error={formValues.serviceLine.error}
                        disabled={currentMode === "View"}
                      >
                        <MenuItem value="Select" style={{ opacity: "0.5" }}>
                          Select
                        </MenuItem>
                        {!_.isEmpty(adminConfig.data) &&
                          adminConfig.data.serviceLineData.length > 0 &&
                          Config.getServiceLines(
                            adminConfig.data.serviceLineData
                          ).map((service, index) => (
                            <MenuItem value={service} key={index}>
                              {service}
                            </MenuItem>
                          ))}
                      </Select>
                      {formValues.serviceLine.error && (
                        <FormHelperText className="error-text">
                          {formValues.serviceLine.errorText}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={2}>
                    <FormControl variant="filled" className="input-control">
                      <InputLabel id="subServiceLine">
                        Sub service line*
                      </InputLabel>
                      <Select
                        MenuProps={menuProps}
                        labelId="subServiceLine"
                        className={
                          formValues.subServiceLine.value === "Select"
                            ? "input-disable"
                            : ""
                        }
                        value={formValues.subServiceLine.value}
                        onChange={(e) =>
                          setUserDetails(
                            e.target.value,
                            "subServiceLine",
                            "text"
                          )
                        }
                        error={formValues.subServiceLine.error}
                        disabled={currentMode === "View"}
                      >
                        <MenuItem value="Select" style={{ opacity: "0.5" }}>
                          Select
                        </MenuItem>
                        {formValues.serviceLine.value !== "Select" &&
                          formValues.serviceLine.value !== "" &&
                          !_.isEmpty(adminConfig.data) &&
                          adminConfig.data.serviceLineData.length > 0 &&
                          Config.getSubServiceLines(
                            adminConfig.data.serviceLineData,
                            formValues.serviceLine.value
                          ).map((data, index) => (
                            <MenuItem value={data.subServiceLine} key={index}>
                              {data.subServiceLine}
                            </MenuItem>
                          ))}
                      </Select>
                      {formValues.subServiceLine.error && (
                        <FormHelperText className="error-text">
                          {formValues.subServiceLine.errorText}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={2}>
                    <FormControl variant="filled" className="input-control">
                      <InputLabel id="country">Country*</InputLabel>
                      <Select
                        MenuProps={menuProps}
                        labelId="country"
                        label="Select"
                        value={formValues.country.value}
                        onChange={(e) => {
                          setUserDetails(e.target.value, "country", "text");
                          setUserDetails("Select", "region", "text");
                          setUserDetails("Select", "locationCity", "text");
                        }}
                        className={
                          formValues.country.value === "Select"
                            ? "input-disable"
                            : ""
                        }
                        error={formValues.country.error}
                        disabled={currentMode === "View"}
                      >
                        <MenuItem value="Select" style={{ opacity: "0.5" }}>
                          Select
                        </MenuItem>
                        <MenuItem value="United States">United States</MenuItem>
                      </Select>
                      {formValues.country.error && (
                        <FormHelperText className="error-text">
                          {formValues.country.errorText}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={2}>
                    <FormControl variant="filled" className="input-control">
                      <InputLabel id="region">Region*</InputLabel>
                      <Select
                        MenuProps={menuProps}
                        labelId="region"
                        label="Select"
                        value={formValues.region.value}
                        onChange={(e) => {
                          setUserDetails(e.target.value, "region", "text");
                          setUserDetails("Select", "locationCity", "text");
                        }}
                        className={
                          formValues.region.value === "Select"
                            ? "input-disable"
                            : ""
                        }
                        error={formValues.region.error}
                        disabled={currentMode === "View"}
                      >
                        <MenuItem value="Select" style={{ opacity: "0.5" }}>
                          Select
                        </MenuItem>
                        {formValues.country.value !== "Select" &&
                          !_.isEmpty(adminConfig.data) &&
                          adminConfig.data.locationData.length > 0 &&
                          Config.getRegions(adminConfig.data.locationData).map(
                            (region, index) => (
                              <MenuItem value={region} key={index}>
                                {region}
                              </MenuItem>
                            )
                          )}
                      </Select>
                      {formValues.region.error && (
                        <FormHelperText className="error-text">
                          {formValues.region.errorText}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={2}>
                    <FormControl variant="filled" className="input-control">
                      <InputLabel id="locationCity">City*</InputLabel>
                      <Select
                        MenuProps={menuProps}
                        labelId="locationCity"
                        label="Select"
                        value={formValues.locationCity.value}
                        onChange={(e) =>
                          setUserDetails(e.target.value, "locationCity", "text")
                        }
                        className={
                          formValues.locationCity.value === "Select"
                            ? "input-disable"
                            : ""
                        }
                        error={formValues.locationCity.error}
                        disabled={currentMode === "View"}
                      >
                        <MenuItem value="Select" style={{ opacity: "0.5" }}>
                          Select
                        </MenuItem>
                        {formValues.region.value !== "Select" &&
                          formValues.region.value !== "" &&
                          formValues.country.value !== "Select" &&
                          formValues.country.value !== "" &&
                          !_.isEmpty(adminConfig.data) &&
                          adminConfig.data.locationData.length > 0 &&
                          Config.getLocations(
                            adminConfig.data.locationData,
                            formValues.region.value
                          ).map((data, index) => (
                            <MenuItem value={data.location} key={index}>
                              {data.location}
                            </MenuItem>
                          ))}
                      </Select>
                      {formValues.locationCity.error && (
                        <FormHelperText className="error-text">
                          {formValues.locationCity.errorText}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={2}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        label="Tenure Start date*"
                        value={formValues.tenorStartDate.value}
                        onChange={(val) =>
                          setUserDetails(val, "tenorStartDate", "text")
                        }
                        format="yyyy/MM/dd"
                        views={["year", "month", "date"]}
                        className="date-input-control"
                        error={formValues.tenorStartDate.error}
                        helperText={
                          formValues.tenorStartDate.error
                            ? formValues.tenorStartDate.errorText
                            : ""
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              style={{ marginTop: "-12px" }}
                            >
                              <IconButton disabled={currentMode === "View"}>
                                <DateRangeIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        disabled={currentMode === "View"}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label="Counsellor Name*"
                      variant="filled"
                      className="input-control"
                      value={formValues.counselorName.value}
                      onChange={(e) =>
                        setUserDetails(e.target.value, "counselorName", "text")
                      }
                      inputProps={{ maxLength: 100 }}
                      error={formValues.counselorName.error}
                      helperText={
                        formValues.counselorName.error
                          ? formValues.counselorName.errorText
                          : ""
                      }
                      disabled={currentMode === "View"}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label="Counsellor Email*"
                      variant="filled"
                      className="input-control"
                      value={formValues.counselorEmail.value}
                      onChange={(e) =>
                        setUserDetails(e.target.value, "counselorEmail", "text")
                      }
                      inputProps={{ maxLength: 255 }}
                      error={formValues.counselorEmail.error}
                      helperText={
                        formValues.counselorEmail.error
                          ? formValues.counselorEmail.errorText
                          : ""
                      }
                      disabled={currentMode === "View"}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label="Counsellor GUI*"
                      variant="filled"
                      className="input-control"
                      value={formValues.counselorGUI.value}
                      onChange={(e) =>
                        setUserDetails(e.target.value, "counselorGUI", "number")
                      }
                      inputProps={{ maxLength: 20 }}
                      error={formValues.counselorGUI.error}
                      helperText={
                        formValues.counselorGUI.error
                          ? formValues.counselorGUI.errorText
                          : ""
                      }
                      disabled={currentMode === "View"}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <FormControl variant="filled" className="input-control">
                      <InputLabel id="buNumber">BU Number*</InputLabel>
                      <Select
                        MenuProps={menuProps}
                        label="BU Number*"
                        labelId="buNumber"
                        value={formValues.buNumber.value}
                        onChange={(e) =>
                          setUserDetails(e.target.value, "buNumber", "text")
                        }
                        className={
                          formValues.buNumber.value === "Select"
                            ? "input-disable"
                            : ""
                        }
                        error={formValues.buNumber.error}
                        disabled={currentMode === "View"}
                      >
                        <MenuItem value="Select" style={{ opacity: "0.5" }}>
                          Select
                        </MenuItem>
                        {!_.isEmpty(adminConfig.data) &&
                          adminConfig.data.buNumberData.length > 0 &&
                          Config.getBUNumber(adminConfig.data.buNumberData).map(
                            (buNumber, index) => (
                              <MenuItem value={buNumber} key={index}>
                                {buNumber}
                              </MenuItem>
                            )
                          )}
                      </Select>
                      {formValues.buNumber.error && (
                        <FormHelperText className="error-text">
                          {formValues.buNumber.errorText}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item md={2}>
                    <FormControl variant="filled" className="input-control">
                      <InputLabel id="smuCode">SMU Code*</InputLabel>
                      <Select
                        MenuProps={menuProps}
                        label="SMU Code*"
                        labelId="smuCode"
                        value={formValues.smuCode.value}
                        onChange={(e) =>
                          setUserDetails(e.target.value, "smuCode", "text")
                        }
                        className={
                          formValues.smuCode.value === "Select"
                            ? "input-disable"
                            : ""
                        }
                        error={formValues.smuCode.error}
                        disabled={currentMode === "View"}
                      >
                        <MenuItem value="Select" style={{ opacity: "0.5" }}>
                          Select
                        </MenuItem>
                        {!_.isEmpty(adminConfig.data) &&
                          adminConfig.data.smuCodesData.length > 0 &&
                          Config.getSMUCodes(adminConfig.data.smuCodesData).map(
                            (smuCode, index) => (
                              <MenuItem value={smuCode} key={index}>
                                {smuCode}
                              </MenuItem>
                            )
                          )}
                      </Select>
                      {formValues.smuCode.error && (
                        <FormHelperText className="error-text">
                          {formValues.smuCode.errorText}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  {
                    currentMode === "Edit" && (
                      <Grid item md={2}>
                        <FormControl
                          onClick={(e) => checkForLRAutoEmail(e)}>
                          <div className="active-phase-wrapper highlight">
                            <i
                              style={{ cursor: "auto" }}
                              className={
                                madeLRAutoEmail
                                  ? "icon-check-squere-filled"
                                  : "icon-check-unchecked"
                              }
                            />
                            <span
                              style={{
                                cursor: "auto",
                                paddingLeft: "8px",
                                fontSize: "10px",
                              }}>
                              Do you want to notify counselor? (Applicable when counselor email change)
                            </span>
                          </div>
                        </FormControl>
                      </Grid>
                    )
                  }
                </Grid>
                <h6>Select User Role</h6>
                <Grid container spacing={2}>
                  <Grid item md={2}>
                    <FormControl variant="filled" className="input-control">
                      <InputLabel id="role">Role*</InputLabel>
                      <Select
                        MenuProps={menuProps}
                        multiple
                        labelId="role"
                        placeholder="Select"
                        value={formValues.role.value}
                        renderValue={(selected) => selected.join(", ")}
                        onChange={(e) =>
                          setUserDetails(e.target.value, "role", "text")
                        }
                        error={formValues.role.error}
                        disabled={currentMode === "View"}
                      >
                        {Config.roles.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={
                                formValues.role.value &&
                                formValues.role.value.indexOf(name) > -1
                              }
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                      {formValues.role.error && (
                        <FormHelperText className="error-text">
                          {formValues.role.errorText}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <h6>Select Additional Details</h6>
                <Grid container spacing={2}>
                  <Grid item md={2}>
                    <FormControl variant="filled" className="input-control">
                      <InputLabel id="primarySector">Primary Sector</InputLabel>
                      <Select
                        MenuProps={menuProps}
                        labelId="primarySector"
                        label="Select"
                        value={formValues.primarySector.value}
                        onChange={(e) =>
                          setUserDetails(
                            e.target.value,
                            "primarySector",
                            "text"
                          )
                        }
                        className={
                          formValues.primarySector.value === "Select"
                            ? "input-disable"
                            : ""
                        }
                        disabled={currentMode === "View"}
                      >
                        <MenuItem value="Select" style={{ opacity: "0.5" }}>
                          Select
                        </MenuItem>
                        {!_.isEmpty(adminConfig.data) &&
                          adminConfig.data.sectors.length > 0 &&
                          adminConfig.data.sectors.map((sector, index) => (
                            <MenuItem value={sector.sectorName} key={index}>
                              {sector.sectorName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2}>
                    <FormControl variant="filled" className="input-control">
                      <InputLabel id="secondarySector">
                        Secondary Sector
                      </InputLabel>
                      <Select
                        MenuProps={menuProps}
                        labelId="secondarySector"
                        label="Select"
                        value={formValues.secondarySectorSpecialization.value}
                        onChange={(e) =>
                          setUserDetails(
                            e.target.value,
                            "secondarySectorSpecialization",
                            "text"
                          )
                        }
                        className={
                          formValues.secondarySectorSpecialization.value ===
                            "Select"
                            ? "input-disable"
                            : ""
                        }
                        disabled={currentMode === "View"}
                      >
                        <MenuItem value="Select" style={{ opacity: "0.5" }}>
                          Select
                        </MenuItem>
                        {!_.isEmpty(adminConfig.data) &&
                          adminConfig.data.sectors.length > 0 &&
                          adminConfig.data.sectors.map((sector, index) => (
                            <MenuItem value={sector.sectorName} key={index}>
                              {sector.sectorName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2}>
                    <FormControl variant="filled" className="input-control">
                      <InputLabel id="additionalResponsibility">
                        Additional Responsibility
                      </InputLabel>
                      <Select
                        MenuProps={menuProps}
                        multiple
                        labelId="additionalResponsibility"
                        label="Select"
                        value={formValues.additionalResponsibility.value}
                        renderValue={(selected) => selected.join(", ")}
                        onChange={(e) =>
                          setUserDetails(
                            e.target.value,
                            "additionalResponsibility",
                            "text"
                          )
                        }
                        disabled={currentMode === "View"}
                      >
                        {!_.isEmpty(adminConfig.data) &&
                          adminConfig.data.responsibilities.length > 0 &&
                          adminConfig.data.responsibilities.map(
                            (data, index) => (
                              <MenuItem key={index} value={data.responsibility}>
                                <Checkbox
                                  checked={
                                    formValues.additionalResponsibility.value &&
                                    formValues.additionalResponsibility.value.indexOf(
                                      data.responsibility
                                    ) > -1
                                  }
                                />
                                <ListItemText primary={data.responsibility} />
                              </MenuItem>
                            )
                          )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>
            )}

            {initialMode !== "preview" && (
              <div className="adduser-footer">
                {(currentMode === "Edit" || currentMode === "View") && (
                  <div className="last-updated-info">
                    <span>
                      Last updated by: {userDetailsDb.data.updatedBy?.name} |{" "}
                      {moment(userDetailsDb.data.updatedOn).format(
                        "D, MMM YYYY  h:mm A"
                      )}
                    </span>
                  </div>
                )}
                <div className="btn-container">
                  <input
                    type="button"
                    value="Cancel"
                    className="cancel-btn"
                    onClick={() => checkForChanges()}
                  />
                  {currentMode === "View" && !props.hideBtn && (
                    <input
                      type="button"
                      value="Delete"
                      className="delete-btn"
                      onClick={() => onUserDelete("child")}
                    />
                  )}
                  {currentMode === "View" && !props.hideBtn && (
                    <input
                      type="button"
                      value="Edit"
                      className="edit-btn"
                      onClick={() => setCurrentMode("Edit")}
                    />
                  )}
                  {currentMode === "Edit" && (
                    <input
                      type="button"
                      value="Save"
                      className={classNames("save-btn", {
                        "disable-btn": isBtnDisabled(),
                      })}
                      disabled={isBtnDisabled()}
                      onClick={setEditDetails}
                    />
                  )}
                  {currentMode === "Edit" && (
                    <Button
                      variant="outlined"
                      // className="submit-btn"
                      startIcon={<span className="icon-verified-outline icon" />}
                      className={classNames("submit-btn", {
                        "disable-btn": enableSubmitButton(),
                      })}
                      onClick={setSubmitDetails}
                    >
                      Submit
                    </Button>
                  )}
                  {currentMode !== "Edit" && currentMode !== "View" && (
                    <input
                      type="button"
                      value="Add User"
                      className={classNames("add-btn", {
                        "disable-btn": isBtnDisabled(),
                      })}
                      disabled={isBtnDisabled()}
                      onClick={setAddDetails}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        ) : null}
        {isPopupVisible && (
          <div className="popup-dialog">
            <div className="dialog">
              <div className="header">
                <h5>Confirmation</h5>
                <span
                  className="icon-close-filled icon"
                  onClick={() => setIsPopupVisible(false)}
                ></span>
              </div>
              <div className="content">
                <p>{popupContent}</p>
              </div>
              <div className="footer">
                <button
                  className="cancel-btn"
                  onClick={() => setIsPopupVisible(false)}
                >
                  Cancel
                </button>
                <button className="confirm-btn" onClick={saveUser}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </SimpleBar>
      <ReviewConfirmation
        showReviewPopup={showReviewConfirmation}
        dismissPopup={dismissReviewWindow}
        reviewResponse={submitReviewResponse}
      ></ReviewConfirmation>
    </>
  );
};
export default AddUser;
