import React, { useState, useEffect, useContext } from "react";
import { Tooltip } from "@material-ui/core";
import { withRouter, useLocation } from "react-router-dom";
import { Popover } from "@material-ui/core";
import "./Header.scss";
import Loader from "../Loader/Loader";
import DelegatesPopup from "../Common/DelegatesPopup";
import AppContext from "../../utils/appContext";
import NotificationDialog from "./NotificationDialog";
import { useDispatch, useSelector } from "react-redux";
import AlertSubmitPopup from "../Common/AlertSubmitPopup";
import { useIdleTimer } from "react-idle-timer";
import { toast, Flip } from "react-toastify";
import {
  APIscope,
  authProvider,
  GraphExplorerscopes,
} from "./../../Authentication/authProvider";
import { extractFirstLetters } from "../../utils/utils";
import * as myConsts from "../../constants/constants";
import Modal from "react-responsive-modal";
import uuid from "react-uuid";

const Header = (props) => {
  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  //const [loader, setLoader] = useState(false);
  const loader = false;
  const [anchorEl, setAnchorEl] = useState(null);
  const [userDp, setUserDp] = useState("");
  const openPopover = Boolean(anchorEl);
  //const { pathname } = props.location;
  const userData = useSelector((state) => state.getUserDetails);
  const userImg = useSelector((state) => state.getUserImg);
  const userUUID = useSelector((state) => state.setUserUUID);
  const [showDelegatesPopup, setShowDelegatesPopup] = useState(false);
  const id = openPopover ? "simple-popover" : undefined;
  const [showAlertSubmitPopup, setShowAlertSubmitPopup] = useState(false);

  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  // const [remaining, setRemaining] = useState(myConsts.idletimeout);

  const apiErrorCode = useSelector((state) => state.apiErrorCode);
  // const addMultipleUsers = useSelector((state) => state.addMultipleUsers);

  const outsideClickCommonState = useSelector(
    (state) => state.outsideClickCommon.data
  );
  //const [alertHeader, setAlertHeader] = useState("Session Expiration Warning");
  const alertHeader = "Session Expiration Warning";
  /*const [alertMessage, setAlertMessage] = useState(
    "Your session will expire in 2 minutes. If you wish to continue, please click OK"
  );*/
  const alertMessage =
    "Your session will expire in 2 minutes. If you wish to continue, please click OK";
  // const [alertSubmitTxt, setAlertSubmitTxt] = useState("OK");
  const alertSubmitTxt = "OK";
  const location = useLocation();
  const [isOpenApiErr, setOpenApiErr] = useState(false);
  // eslint-disable-next-line
  const [uuidIdentifier, setIdentifier] = useState(uuid());

  useEffect(() => {
    if (apiErrorCode && apiErrorCode.data && apiErrorCode.data !== 0) {
      setOpenApiErr(true);
    } else if (apiErrorCode && apiErrorCode.data && apiErrorCode.data === 0) {
      setOpenApiErr(false);
    }
  }, [apiErrorCode]);

  const cancelAlertSubmitPopup = () => {
    setShowAlertSubmitPopup(false);
    reset();
  };
  const submitPopupChanges = () => {
    setShowAlertSubmitPopup(false);
    reset();
  };
  /*const delegatesPopupShow = () => {
    setShowDelegatesPopup(true);
  };*/
  const cancelDelegatesPopup = () => {
    setShowDelegatesPopup(false);
  };

  const [open, setOpen] = React.useState(false);

  const [validUUIDState, setState] = React.useState({
    data: null,
    error: false,
  });

  /*const handleDialogOpen = () => {
    setOpen(true);
  };*/
  const handleDialogClose = () => {
    setOpen(false);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPopoverr = (event) => {
    setAnchorEl(event.currentTarget);
  };
  /*const getCurrentUser = () => {
    const activePPMDuserId =
      myContext.activePPMDuserId || sessionStorage.activePPMDuserId;
    const userId = myContext.userId || sessionStorage.userId;
    return activePPMDuserId || userId;
  };*/
  // const getLockStatusAPI = async () => {
  //   //function to call feth API for given year and period.
  //   try {
  //     const token = await authProvider.acquireTokenSilent({
  //       scopes: APIscope,
  //     });
  //     const idToken = token.accessToken;
  //     dispatch({
  //       type: "CHECK_LOCK_STATUS",
  //       payload: {
  //         token: idToken,
  //       },
  //     });
  //   } catch (e) {
  //     console.error(e);
  //   }
  // };

  const checkOutsideClick = (fn) => {
    if (props.history.location.pathname === "/BookOfBusiness") {
      if (myContext.bobChangeTriggered) {
        dispatch({
          type: "OUTSIDE_CLICK_BOB",
          payload: {
            flag: false,
            callback: { fn: fn, params: "" },
          },
        });
        myContext.setShowAlertPopupBob(true);
      } else {
        fn && fn();
      }
    } else if (props.history.location.pathname === "/Admin/GeneralParameters") {
      if (myContext.changeTriggered) {
        dispatch({
          type: "OUTSIDE_CLICK_ADMIN",
          payload: {
            flag: false,
            callback: { fn: fn, params: "" },
          },
        });
        myContext.setShowPopupAdmin(true);
      } else {
        fn && fn();
      }
    } else if (props.history.location.pathname === "/Admin/Assessment") {
      if (
        outsideClickCommonState &&
        outsideClickCommonState.feature !== "" &&
        outsideClickCommonState.changesDone
      ) {
        dispatch({
          type: "OUTSIDE_CLICK_COMMON_OPEN_MODEL",
          payload: {
            callback: { fn: fn, params: "" },
          },
        });
      } else {
        fn && fn();
      }
    } else if (
      props.history.location.pathname === "/Admin/EmailConfiguration"
    ) {
      if (
        outsideClickCommonState &&
        outsideClickCommonState.feature !== "" &&
        outsideClickCommonState.changesDone
      ) {
        dispatch({
          type: "OUTSIDE_CLICK_COMMON_OPEN_MODEL",
          payload: {
            callback: { fn: fn, params: "" },
          },
        });
      } else {
        fn && fn();
      }
    } else if (myContext.isAutoSave) {
      dispatch({
        type: "OUTSIDE_CLICK",
        payload: {
          flag: false,
          callback: fn ? { fn: fn, params: "" } : "",
        },
      });
      myContext.setOpenSavePopup(true);
    } else fn && fn();
  };
  useEffect(() => {
    if (userData && userData.data) {
      const data = userData.data;
      if (data.displayName) {
        myContext.setDisplayName(data.displayName);
        sessionStorage.setItem("userData", JSON.stringify(data));
      }
    }
  }, [userData, myContext]);

  useEffect(() => {
    if (userImg && userImg.data && userImg.data.href) {
      setUserDp(userImg.data.href);
    }
  }, [userImg]);
  useEffect(() => {
    if (props.history.location.pathname === myConsts.routeHome)
      dispatch({
        type: "RESET_PPMD_STATUS_FILTERS",
        payload: [],
      });
  }, [props.history.location.pathname, dispatch]);

  const routeToHome = () => {
    props.history.push(myConsts.routeHome);
  };

  useEffect(() => {
    (async function () {
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: GraphExplorerscopes,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "GET_USER_DETAILS",
          payload: { tokens: idToken },
        });
        dispatch({
          type: "GET_USER_IMG",
          payload: { tokens: idToken },
        });
        //Set UUID
        dispatch({
          type: "SET_UUID_FOR_USER",
          payload: { identifier: uuidIdentifier },
        });
      } catch (e) {
        console.error(e);
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      assessmentMetaData &&
      assessmentMetaData.data &&
      assessmentMetaData.data.data
    ) {
      const data = assessmentMetaData.data.data;
      if (sessionStorage.getItem("isValidUser") === null) {
        sessionStorage.setItem("isValidUser", data.validUser);
        myContext.setIsValidUser(data.validUser);
      }
      if (!data.validUser) {
        props.history.push(myConsts.routeNotValidUser);
      }
    }
  }, [assessmentMetaData, props.history]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      //assign interval to a variable to clear it.
      // console.log(userUUID.data.message);
      if (userUUID && userUUID.data && userUUID.data.message === "success") {
        ValidateSession();
      }
    }, 20000); //trigger call in 20 seconds
    return () => clearInterval(intervalId); //This is important
    // eslint-disable-next-line
  }, [userUUID, useState]);

  const ValidateSession = async () => {
    const token = await authProvider.acquireTokenSilent({
      scopes: APIscope,
    });
    const idToken = token.accessToken;
    setState((state) => ({
      data: state.data,
      error: false,
    }));
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        userid: myContext.userId || sessionStorage.userId,
      },
      body: JSON.stringify({ identifier: uuidIdentifier }),
    };
    fetch(myConsts.hostName + myConsts.validateUserUUID, options)
      .then((data) => data.json())
      .then((newData) => setState({ data: newData, error: false }))
      .catch(function (error) {
        //console.log(error);
        setState({ data: null, error: true });
      });
  };
  const tokenExpire = async () => {
    const token = await authProvider.acquireTokenSilent({
      scopes: APIscope,
    });
    const idToken = token.accessToken;
    const options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        userid: sessionStorage.userId,
      },
      body: JSON.stringify({ reqType: "logout" }),
    };
    fetch(`${myConsts.hostName}${myConsts.updateToken}`, options)
      .then((data) => data.json())
      .then((newData) => {
        sessionStorage.setItem("userToken", "logout");
        localStorage.clear();
        sessionStorage.clear();
        props.clearCookies();
        props.logout();
      })
      .catch(function (error) {
        //console.log(error);
      });
  };
  useEffect(() => {
    if (
      validUUIDState &&
      validUUIDState.data &&
      !validUUIDState.error &&
      validUUIDState.data.message === "Conflict"
    ) {
      signOut();
    }
    // eslint-disable-next-line
  }, [validUUIDState]);

  const signOut = () => {
    tokenExpire();
  };
  const handleOnIdle = (event) => { };
  const handleOnActive = (event) => { };
  const handleOnAction = (event) => {
    reset();
    setShowAlertSubmitPopup(false);
  };
  /*const handleReset = () => reset();
  const handlePause = () => pause();
  const handleResume = () => resume();*/

  const { reset, getRemainingTime } = useIdleTimer({
    timeout: myConsts.idletimeout,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    // debounce: 500,
  });
  // getRemainingTime
  useEffect(() => {
    //setRemaining(getRemainingTime());

    setInterval(() => {
      // setRemaining(getRemainingTime());
      if (
        getRemainingTime() <= myConsts.idlepopuptime &&
        getRemainingTime() > 0
      ) {
        setShowAlertSubmitPopup(true);
      } else if (getRemainingTime() <= 0) {
        setShowAlertSubmitPopup(false);
        signOut();
      }
    }, 1000);
    // eslint-disable-next-line
  }, []);

  let errorText = ''
  const errorCode = Number(apiErrorCode.data);
  switch (errorCode) {
    case 500:
      errorText = 'Server Down for Maintenance';
      break;
    case 401:
      errorText = 'Server Error';
      break;
    case 409:
      errorText = 'Your Access token has expired. Please use a new tab to access LEAD4D';
      break;
    case 403:
      errorText = 'App Gateway Error';
      break;

    default:
      errorText = 'Server Error';
      break;
  }
  const errorInfo = (apiErrorCode && apiErrorCode.data && apiErrorCode.data !== 0) ?
    `(${apiErrorCode.data})` : "";

  return (
    <>
      <NotificationDialog open={open} handleDialogClose={handleDialogClose} />
      <DelegatesPopup
        showDelegatesPopup={showDelegatesPopup}
        cancelDelegatesPopup={cancelDelegatesPopup}></DelegatesPopup>
      <section
        id="header-new"
        style={myContext.isFullScreen === true ? { display: "none" } : {}}>
        {loader && <Loader />}
        <div id="header-wrap">
          <div
            className="header-left"
            style={
              location.pathname === myConsts.routeNotValidUser ||
                location.pathname === myConsts.routeAcceptPrivacy
                ? { marginRight: 0 }
                : {}
            }></div>
          <div style={{ display: "flex", textAlign: "center", width: "100%", marginLeft: "20px" }}>
            <span
              className="headerText"
              style={{fontSize: "17px"}}
              onClick={() => {
                checkOutsideClick(routeToHome);
              }}>
              {/* LEAD 4D - YEAR END DISCUSSION GUIDE */}
              FY24 YEAR END FINALIZATION FORM COMMENTARY
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 8,
              }}></div>
          </div>
          <div className="header-center"></div>
          <div className="header-right">
            <div className="header-right-wrap">
              <div className="sub-menu">
                {/* <div className="notify-wrap">
                  {badgecount > 0 && (
                    <div className="notification-badge">6</div>
                  )}
                  <Tooltip
                    title="Notifications"
                    arrow={true}
                    placement="bottom"
                    classes={{ tooltip: "tool-tip", arrow: "customArrow" }}
                  >
                    <span
                      className="icon-bell headerIcon"
                      onClick={() => {
                        checkOutsideClick(handleDialogOpen);
                      }}
                    />
                  </Tooltip>
                </div> */}
                <Tooltip
                  title="Profile"
                  aria-label="Profile"
                  arrow={true}
                  placement="bottom"
                  classes={{ tooltip: "tool-tip", arrow: "customArrow" }}
                  className="profile">
                  <div onClick={(e) => openPopoverr(e)}>
                    {userDp ? (
                      <img
                        src={
                          userDp
                            ? userDp
                            : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
                        }
                        alt="user"
                        className="profileImg menu"
                      />
                    ) : (
                      <div className={"dummyDP"}>
                        {extractFirstLetters(userData)}{" "}
                      </div>
                    )}
                    <div className="icon-caret-down"></div>
                  </div>
                </Tooltip>
                <Popover
                  id={id}
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handlePopoverClose}
                  style={{
                    marginTop: "29px",
                    marginRight: "0px",
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}>
                  <div
                    style={{
                      display: "flex",
                    }}>
                    <div>
                      <div className={"profile-popover"}>
                        <div className={"left-side"}>
                          {userDp ? (
                            <img
                              src={
                                userDp
                                  ? userDp
                                  : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
                              }
                              alt=""
                            />
                          ) : (
                            <div className={"dummyDP"}>
                              {extractFirstLetters(userData)}{" "}
                            </div>
                          )}
                        </div>
                        <div className="right-side">
                          <div style={{ fontFamily: "EYInterstate-Bold" }}>
                            {userData &&
                              userData.data &&
                              userData.data.displayName}
                          </div>
                          <div style={{ fontSize: "10px", paddingTop: "10px" }}>
                            {userData && userData.data && userData.data.mail}
                          </div>
                          <div style={{ fontSize: "10px", paddingTop: "10px" }}>
                            {userData &&
                              userData.data &&
                              userData.data.jobTitle}
                          </div>
                        </div>
                      </div>
                      <div
                        className={"signOutButton"}
                        onClick={(e) => checkOutsideClick(signOut)}>
                        Sign Out
                      </div>
                    </div>
                  </div>
                </Popover>
                <AlertSubmitPopup
                  onlyAlert={false}
                  header={alertHeader}
                  submitBtnTxt={alertSubmitTxt}
                  message={alertMessage}
                  showAlertSubmitPopup={showAlertSubmitPopup}
                  cancelAlertSubmitPopup={cancelAlertSubmitPopup}
                  submitPopupChanges={submitPopupChanges}
                  simpleClosePopup={() => myContext.setOpenSavePopup(false)}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        open={isOpenApiErr}
        onClose={() => true}
        styles={{
          modal: {
            maxWidth: "unset",
            padding: "unset",
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.5)",
          },
          closeButton: {
            display: "none",
          },
        }}
        center>
        <div className="server-under">
          <i className="icon-warning-filled" />
          <div>
            {`${errorText} ${errorInfo}`}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default withRouter(Header);
