//import { dispatch } from "d3";
import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import { toast, Flip } from "react-toastify";
import AppContext from "../../../../utils/appContext";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import "./AssessmentPopup.scss";

const AssessmentPopup = (props) => {
  let clearInterval;
  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  const assessmentStatus = useSelector(
    (state) => state.getAssessmentStatus.data
  );
  const [isToastVisible, setIsToastVisible] = useState(false);

  /*const [assessmentStatus, setAssessmentStatus] = useState({
    proceedAction: false
});*/

  const notify = useCallback(() => {
    myContext.setIsLockPopup(true);
    toast(
      <div className="error-text">
        <span className="eyaa-state-16 icon"></span>
        <span className="content">
          {/* {`Assessment content is being added ${assessmentStatus.initiatedBy ? ` by ${assessmentStatus.initiatedBy}.` : '.'} It will take some time. You can
          perform this action after the operation is completed. We appreciate
          your patience!`} */}
           {`Assessment content is being added by ${assessmentStatus.userName}. It will take some time. You can
          perform this action after the operation is completed. We appreciate
          your patience!`}
        </span>
      </div>,
      {
        className: "assessment-popup",
        position: "top-center",
        transition: Flip,
        autoClose: 8000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  const displayPopup = useCallback(() => {
    if (!_.isEmpty(assessmentStatus)) {
      if (assessmentStatus.proceedAction) {
        setIsToastVisible(false);
        toast.dismiss();
        window.clearInterval(clearInterval);
      } else if (!assessmentStatus.proceedAction && !isToastVisible) {
        notify();
        setIsToastVisible(true);
      }
    }
  }, [assessmentStatus, notify, isToastVisible]);

  useEffect(() => {
    if (clearInterval === undefined) {
      clearInterval = setInterval(() => {
        if (!_.isEmpty(assessmentStatus) && !assessmentStatus.proceedAction) {
          dispatch({
            type: "GET_ASSESSMENT_STATUS",
            payload: {},
          });
        } else {
          window.clearInterval(clearInterval);
        }
      }, 5000);
    }

    return () => {
      window.clearInterval(clearInterval);
    };
  }, [dispatch, assessmentStatus]);

  useEffect(() => {
    displayPopup();
  }, [assessmentStatus]);

  useEffect(() => {
    dispatch({
      type: "GET_ASSESSMENT_STATUS",
      payload: {},
    });

  }, [])


  return <></>;
};
export default AssessmentPopup;
