import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel";
import { useEffect } from "react";
import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #484850",
            borderRadius: "5px 5px 0 0"
        },
    },
    group: {
        width: 'auto',
        height: 'auto',
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'row',
    },
    labelroot: {
        // backgroundColor: "rgb(246 ,246, 250)!important",
        color: "rgba(0, 0, 0, 0.94)",
        // paddingLeft: "5px",
        // paddingTop: "5px",
        fontSize: "11px",
        fontWeight: "500",
        width : '150px'
    },
}));

const RadioButton = (props) => {
    const { defaultValue, label, required, changeCallback, list = [], disabled = false } = props;
    const classes = useStyles();
    const [value, setValue] = useState('');

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue]);

    const handleChange = (event) => {
        setValue(event.target.value);
        changeCallback(event.target.value)
    };
    return (
        <FormControl
            component="fieldset"
            disabled={disabled}
            error={props.errorMessage[props.varName] !== ""}
        >
            <InputLabel
                htmlFor="uncontrolled-native"
                className={"leftSpacing"}
                classes={{
                    root: classes.labelroot,
                }}
                shrink={true}>
                {label + (required ? "*" : "")}
            </InputLabel>
            <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                <RadioGroup row aria-label="anonymous" name="row-radio-buttons-group" value={value}
                    onChange={handleChange}>
                    {list.map(ele => (<FormControlLabel value={ele} control={<Radio color="default" />} label={ele} />))}
                </RadioGroup>
            </div>
            <FormHelperText
                classes={{
                    root: classes.helper,
                }}>
                {props.errorMessage[props.varName]}
            </FormHelperText>
        </FormControl>
    );
};
export default RadioButton;
