import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import AppContext from "./utils/appContext";
import "./App.css";
import Header from "./components/Header/Header";
import {
  authProvider,
  GraphExplorerscopes,
} from "./Authentication/authProvider";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { getEYFinancialYear, decodeIdToken } from "./utils/utils";
import "regenerator-runtime/runtime";
import * as myConsts from "./constants/constants";
import { ToastContainer } from "react-toastify";
import { Beforeunload } from "react-beforeunload";
import { Flip } from "react-toastify";
import AcceptPrivacy from "./components/AcceptPrivacy/AcceptPrivacy";
import moment from "moment";
import InternalErrorBoundary from "./components/ErrorBoundary";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./errorTracking";
import AdminRoutes from "./AdminRoutes";
import NonAdminRoutes from "./NonAdminRoutes";
import Maintenance from "./components/NotValidUser/Maintenance";
import { useSelector } from "react-redux";
import Cookies from 'universal-cookie';

function App() {
  const [financialYear, setFinancialYear] = useState(
    Number(sessionStorage.getItem("financialYear")) || getEYFinancialYear()
  );
  const [appFreshLoad, setAppFreshLoad] = useState(
    Number(sessionStorage.getItem("financialYear")) ? false : true
  );
  const [activeFinancialYear, setActiveFinancialYear] = useState(
    Number(sessionStorage.getItem("activefinancialYear")) ||
    getEYFinancialYear()
  );
  const [lastActiveFinancialYear, setlastActiveFinancialYear] = useState(
    Number(sessionStorage.getItem("lastactivefinancialYear")) || null
  );
  const [assessmentSubmissionStatus, setAssessmentSubmissionStatus] = useState(
    sessionStorage.getItem("assessmentSubmissionStatus") || false
  );
  const [disableUpdateSummary, setDisableUpdateSummary] = useState(
    String(sessionStorage.getItem("disableUpdateSummary")).toLowerCase() ==
    "true" || false
  );
  const [reviewerMessage, updateReviewerMessage] = useState({});
  const [bobStatus, setBobStatus] = useState("");
  const [assessmentStatus, setAssessmentStatus] = useState("");
  const [isAutoSave, setIsAutoSave] = useState(false);
  const [openSavePopup, setOpenSavePopup] = useState(false);
  const [isCompleted, setIsCompleted] = useState(true);
  const [isLastTab, setIsLastTab] = useState(false);
  const [isBobApproved, setIsBobApproved] = useState(false);
  const [enableSummary, setEnableSummary] = useState(false);
  const [goToNextCalled, setGoToNextCalled] = useState(false);
  const [currentGoal, setCurrentGoal] = useState({
    goalId: -1,
    pillarId: -1,
  });
  const [userId, setUserId] = useState(sessionStorage.userId);
  const [displayName, setDisplayName] = useState("");
  const [userData, setUserData] = useState(sessionStorage.userData);
  const [PPMDData, setPPMDData] = useState(
    sessionStorage.PPMDData && JSON.parse(sessionStorage.PPMDData)
  );
  //debugger;
  const [activePPMDuserId, setActivePPMDuserId] = useState(
    sessionStorage.activePPMDuserId
  );
  const [activePPMD_Id, setActivePPMD_Id] = useState(
    sessionStorage.activePPMD_Id
  );
  const [activePPMD_UserId, setActivePPMD_UserId] = useState(
    sessionStorage.activePPMD_UserId
  );  
  const [userRole, setUserRole] = useState(""); //LeadReviewer-PPMD,LeadReviewer,PPMD
  const [activePeriod, setActivePeriod] = useState(
    sessionStorage.getItem("activePeriod")
  ); //Active Time Period
  const [activePhase, setActivePhase] = useState(
    sessionStorage.getItem("activePhase")
  );
  const [currentPeriod, setCurrentPeriod] = useState(
    sessionStorage.getItem("currentPeriod")
  ); //User Selected Time Period
  const [navToSummary, setNavToSummary] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isValidUser, setIsValidUser] = useState(
    sessionStorage.getItem("isValidUser")
  );
  const [isWarningPopup, setIsWarningPopup] = useState(false);
  const [lockPopup, setIsLockPopup] = useState(false);
  const [changeTriggered, setChangeTriggered] = useState(false);
  const [showPopupAdmin, setShowPopupAdmin] = useState(false);
  const [bobChangeTriggered, setBobChangeTriggered] = useState(false);
  const [showAlertPopupBob, setShowAlertPopupBob] = useState(false);
  const [formsInActive, setFormsInActive] = useState(
    String(sessionStorage.getItem("formsInActive")).toLowerCase() == "true" ||
    false
  ); //flag set if activePhase is null or lock date of active period has passed.
  const [disableUserUpdation, setDisableUserUpdation] = useState(false);
  const [isRouteFromAdmin, setIsRouteFromAdmin] = useState(false);
  // const [multipleUserUploadInProgress, setmultipleUserUploadInProgress] =
  //   useState(false);
  const location = useLocation();
  const { data: maintenanceError = false } = useSelector((state) => state.maintenanceError) || {};

  useEffect(() => {
    (async function () {
      try {
        const token = await authProvider.acquireTokenSilent({
          scopes: GraphExplorerscopes,
        });
        const idToken = token.accessToken;

        if (
          sessionStorage.getItem("userId") === null ||
          sessionStorage.getItem("userId") === "undefined"
        ) {
          const unique_name = decodeIdToken(idToken).unique_name;
          if (unique_name) {
            const userId = unique_name.toLowerCase();
            sessionStorage.setItem("userId", userId);
            setUserId(userId);
          }
        }
      } catch (e) {
        console.error(e);
      }
    })();

    moment.locale("en");
  }, []);

  useEffect(() => {
    (async function () {
      if (process.env.REACT_APP_ENV !== "production") {
        setIsAccepted(true);
      } else {
        try {
          if (!isAccepted)
            if (location.pathname !== myConsts.routeAcceptPrivacy) {
              const token = await authProvider.acquireTokenSilent({
                scopes: GraphExplorerscopes,
              });
              const idToken = token.accessToken;
              const { unique_name } = decodeIdToken(idToken);
              const requestOptions = {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: myConsts.PAANSAuthorization,
                },
                body: JSON.stringify({
                  appId: myConsts.PAANSAppId,
                  policyType: myConsts.PAANSPolicyType,
                  region: myConsts.PAANSRegion,
                  language: myConsts.PAANSLanguage,
                  userId: unique_name,
                  includeContent: true,
                  secret: myConsts.PAANSecret,
                  acceptUrl:
                    /* window.location.hostname === "localhost"
                  ? window.location.origin + myConsts.routeHome
                  :  */ myConsts.PAANSAcceptUrl,
                  rejectUrl:
                    /* window.location.hostname === "localhost"
                  ? window.location.origin + myConsts.routeAcceptPrivacy
                  : */ myConsts.PAANSRejectUrl,
                }),
              };

             /* const response = await fetch(
                myConsts.PAANSUrl + "/api/v1/policy/check",
                requestOptions
              );
              const data = await response.json();
              setIsAccepted(data.hasAccepted);
              if (!data.hasAccepted) {
                const formedUrl = `${myConsts.PAANSUrl}${data.redirectUrl}`;
                window.location.replace(formedUrl);
              }*/

              fetch(
                myConsts.PAANSUrl + "/api/v1/policy/check",
                requestOptions
              ).then((response) => {
                response.json().then((res) => {
                  setIsAccepted(res.hasAccepted);
                  if (!res.hasAccepted) {
                    const formedUrl = `${myConsts.PAANSUrl}${res.redirectUrl}`;
                    window.location.replace(formedUrl);
                  }
                });
              });
            }
        } catch (e) {
          console.error(e);
        }
      }
    })();
  }, [location]);

  const userSettings = {
    isAutoSave,
    setIsAutoSave,
    isCompleted,
    setIsCompleted,
    isBobApproved,
    setIsBobApproved,
    isLastTab,
    setIsLastTab,
    financialYear,
    setFinancialYear,
    activePeriod,
    setActivePeriod,
    currentPeriod,
    setCurrentPeriod,
    currentGoal,
    setCurrentGoal,
    openSavePopup,
    setOpenSavePopup,
    setEnableSummary,
    displayName,
    setDisplayName,
    enableSummary,
    assessmentSubmissionStatus,
    setAssessmentSubmissionStatus,
    userRole,
    setUserRole,
    navToSummary,
    setNavToSummary,
    bobStatus,
    setBobStatus,
    assessmentStatus,
    setAssessmentStatus,
    userId,
    setUserId,
    userData,
    setUserData,
    PPMDData,
    setPPMDData,
    activePPMDuserId,
    setActivePPMDuserId,
    activePPMD_Id,
    setActivePPMD_Id,
    activePPMD_UserId,
    setActivePPMD_UserId,
    disableUpdateSummary,
    setDisableUpdateSummary,
    reviewerMessage,
    updateReviewerMessage,
    setIsValidUser,
    isValidUser,
    lockPopup,
    setIsLockPopup,
    isWarningPopup,
    setIsWarningPopup,
    changeTriggered,
    setChangeTriggered,
    showPopupAdmin,
    setShowPopupAdmin,
    formsInActive,
    setFormsInActive,
    bobChangeTriggered,
    setBobChangeTriggered,
    showAlertPopupBob,
    setShowAlertPopupBob,
    setActivePhase,
    activePhase,
    disableUserUpdation,
    setDisableUserUpdation,
    setGoToNextCalled,
    goToNextCalled,
    activeFinancialYear,
    setActiveFinancialYear,
    lastActiveFinancialYear,
    setlastActiveFinancialYear,
    isRouteFromAdmin,
    setIsRouteFromAdmin,
    appFreshLoad,
    setAppFreshLoad,
    // multipleUserUploadInProgress,
    // setmultipleUserUploadInProgress,
  };
  const useUnload = () => {
    /*const cookies = new Cookies();
    const allCookies = cookies.getAll();
    Object.keys(allCookies).forEach(key => {
      cookies.remove(key);
    });*/
  };

  return (
    <>
      <AppInsightsContext.Provider value={reactPlugin}>
        <InternalErrorBoundary type="Main">
          <Beforeunload onBeforeunload={useUnload}>
            {userSettings.isWarningPopup ? (
              <ToastContainer //EY yellow popup
                toastStyle={{
                  backgroundColor: "#ffe600",
                  color: "rgba(0,0,0,.90)",
                  fontFamily: "EYInterState-Regular",
                  fontSize: "13px",
                  textAlign: "center",
                }}
                closeButton={false}
                transition={Flip}
                position="bottom-center"
                autoClose={1500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            ) : (
              <ToastContainer //Green success popup
                toastStyle={{
                  backgroundColor: !userSettings.lockPopup
                    ? "#b1de76"
                    : "#FFDA6A",
                  color: !userSettings.lockPopup ? "white" : "black",
                  fontFamily: "EYInterState-Regular",
                  fontSize: "13px",
                  textAlign: "center",
                }}
                transition={Flip}
                className={!userSettings.lockPopup ? "" : "lockStyle"}
                position={
                  !userSettings.lockPopup ? "bottom-center" : "top-center"
                }
                autoClose={!userSettings.lockPopup ? 1500 : false}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            )}
            <AppContext.Provider value={userSettings}>
              <div className="App">
                {maintenanceError ? <Maintenance /> :
                  <Switch>
                    <AzureAD provider={authProvider} forceLogin={true}>
                      {({
                        login,
                        logout,
                        authenticationState,
                        error,
                        accountInfo,
                      }) => {
                        switch (authenticationState) {
                          case AuthenticationState.Authenticated:
                            const userId =
                              accountInfo.account.userName.toLowerCase();
                            sessionStorage.setItem("userId", userId);
                            setUserId(userId);
                            return (
                              <>
                                <Header
                                  logout={logout}
                                  clearCookies={useUnload}
                                />
                                <InternalErrorBoundary type="Router">
                                  {isAccepted ? (
                                    <>
                                      <InternalErrorBoundary
                                        type={myConsts.routeLandingPage}>
                                        <Route
                                          exact
                                          path="/"
                                          render={() => (
                                            <Redirect
                                              to={myConsts.routeLandingPage}
                                            />
                                          )}
                                        />
                                      </InternalErrorBoundary>
                                      <InternalErrorBoundary type={"Router"}>
                                        <Route
                                          path="/Admin"
                                          render={(props) => (
                                            <AdminRoutes {...props} />
                                          )}
                                        />
                                      </InternalErrorBoundary>
                                      <InternalErrorBoundary type={"Router"}>
                                        <Route
                                          render={(props) => (
                                            <NonAdminRoutes {...props} />
                                          )}
                                        />
                                      </InternalErrorBoundary>
                                    </>
                                  ) : (
                                    <Route
                                      path={myConsts.routeAcceptPrivacy}
                                      component={AcceptPrivacy}
                                    />
                                  )}
                                </InternalErrorBoundary>
                              </>
                            );
                          case AuthenticationState.Unauthenticated:
                            return (
                              <div>
                                {error && (
                                  <p>
                                    <span>
                                      An error occured during authentication,
                                      please try again!
                                    </span>
                                  </p>
                                )}
                                <p>
                                  <span>Hey stranger, you look new!</span>
                                  <button onClick={login}>Login</button>
                                </p>
                              </div>
                            );
                          case AuthenticationState.InProgress:
                            return <p>Authenticating...</p>;
                          default:
                        }
                      }}
                    </AzureAD>
                  </Switch>
                }
              </div>
            </AppContext.Provider>
          </Beforeunload>
        </InternalErrorBoundary>
      </AppInsightsContext.Provider>
    </>
  );
}

export default App;
