import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import EditorToolbar, { modules0, formats } from "./EditToolBar";
import ReactQuill from "react-quill";
import _ from "lodash";
import SimpleBar from "simplebar-react";
import { toast, Flip } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import "./WelcomeMessage.scss";
import { InputLabel, makeStyles, TextField } from "@material-ui/core";
import Loader from "../../../Loader/Loader";
import Modal from "react-responsive-modal";
import Preview from "./Preview";


const useStyles = makeStyles((theme) => ({
  root1: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-underline": {
      width: "250px",
      height: "27px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      //   borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      //   "&:hover:not($disabled):not($focused):not($error):before": {
      //     borderBottom: "none",
      //   },
      " &:hover:before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      },
      " &:hover:after": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  labelroothalf: {
    width: "395px",
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "8px",
  },
  topSpacing: {
    marginTop: "16px",
  },
  topSyli: {
    marginBottom: " -30px",
    marginTop: "16px",
    zIndex: "8",
    width: "115px",
  },
  half: {
    display: "flex",
    flexDirection: "row",
    width: "350px",
    marginLeft: "15px",
  },
  root900: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-underline": {
      //width: "870px",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
      fontFamily: "EYInterState-Regular",
    },
  },
  root: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "400px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
      fontFamily: "EYInterState-Regular",
    },
  },
  rootMulti: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "677px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  labelroot: {
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "8px",
    // fontWeight: "600",
  },
  labelroot1: {
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "10px",
    fontWeight: "600",
  },
  topSpacing: {
    //marginTop: "8px",
  },
  topSyli: {
    marginBottom: " -30px",
    marginTop: "16px",
    zIndex: "8",
    width: "100px",
  },
  half: {
    display: "flex",
    flexDirection: "row",
  },
  arrow: {
    position: "absolute",
    fontSize: 14,
    width: "6em",
    height: "4em",
    //   transform: "rotate(225deg)",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  paper2: {
    backgroundColor: "white",
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 2, 2),
    width: 170,
    overflow: "hidden",
    overflowX: "hidden",
    fontSize: "11px",
  },
  options: {
    borderBottom: "1px solid grey",
    padding: "5px 0px 5px 5px",
    display: "flex",
    alignItems: "center",
    height: "24px",
    cursor: "pointer",
  },
  popper: {
    // zIndex: 1,
    left: "-55px!important",
    '&[x-placement*="top-end"]': {
      top: "-12px!important",
    },
    '&[x-placement*="bottom-end"]': {
      top: "9px!important",
    },
    '&[x-placement*="bottom"] $arrow': {
      top: 2,
      left: "105px!important",
      marginTop: "-0.9em",
      width: "6em",
      height: "2em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: "103px!important",
      marginBottom: "-3.6em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
}));

const IntroductionCards = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const reactQuillRef = useRef([]);
  const [cardDetails, setCardDetails] = useState({
    title: "",
    content: "",
    fileUrl: "",
    fileName: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const generalContent = useSelector((state) => state.generalContent);

  const imagePos = "welcome_message"

  const updateCardDetails = useCallback(
    (field, val) => {
      const copyCardDetails = _.cloneDeep(cardDetails);
      copyCardDetails[field] = val;
      setCardDetails(copyCardDetails);
    },
    [cardDetails]
  );

  const validateFile = useCallback(
    (file) => {
      const fileName = file.name;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
        const copyCardDetails = _.cloneDeep(cardDetails);
        copyCardDetails.fileName = fileName;
        copyCardDetails.fileUrl = file;
        setCardDetails(copyCardDetails);
      } else {
        notfiy("Invalid file format", "error");
      }
    },
    [cardDetails]
  );

  const saveCardDetails = useCallback(
    () => {
      const cardData = cardDetails;
      let formData = new FormData();
      if (cardData.fileUrl instanceof File)
        formData.append("attachment", cardData.fileUrl);
      formData.append("content", cardData.content);
      formData.append("title", cardData.title);
      formData.append("key", imagePos);

      dispatch({
        type: "UPLOAD_GENERAL_CONTENT",
        payload: formData,
      });
    },
    [cardDetails, imagePos, dispatch]
  );

  const notfiy = useCallback((msg, type) => {
    toast(
      <div className="error-text">
        {type === "error" ? (
          <span className="icon-close-filled icon"></span>
        ) : (
          <span className="eyaa-state-36 icon"></span>
        )}
        <p>
          {type === "error" ? (
            <>
              <span>Error:</span> <span>{msg}</span>
            </>
          ) : (
            <span>{msg}</span>
          )}
        </p>
      </div>,
      {
        className: type,
        position: "top-center",
        transition: Flip,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  useEffect(() => {
    dispatch({
      type: "GET_GENERAL_CONTENT",
      payload: {},
    });
  }, [dispatch]);

  useEffect(() => {
    if (generalContent.fetching) {
      setIsLoading(true);
    } else if (
      generalContent.data &&
      generalContent.data.introCards &&
      generalContent.data.introCards.length > 0
    ) {
      const cards = [];
      const contentLen = [];
      setIsLoading(false);
      const welcomeMessageContent = generalContent.data.welcomeMsg || {};
      setCardDetails({
        title: welcomeMessageContent.title,
        content: welcomeMessageContent.content,
        fileUrl: welcomeMessageContent.fileDetails.fileUrl,
        fileName: welcomeMessageContent.fileDetails.actualFileName,
      });
    }
  }, [generalContent]);

  const handleChange = (content) => {
    const limit = 1000;
    const quill = reactQuillRef.current;
    if (quill !== undefined && quill?.getEditor()) {
      const editorLength = quill.getEditor().getLength() - 1;
      if (editorLength > limit) {
        quill.getEditor().deleteText(limit, quill.getEditor().getLength());
      } else {
        updateCardDetails("content", content);
      }
    }
  };

  const descLength = cardDetails.content ? cardDetails.content.replace(/(<([^>]+)>)/ig, '').length : 0;

  return (
    <div className="welcome-message-cards">
      {isLoading && <Loader />}
      <div className="welcome-message-header">
        <div className="sub-header">Welcome Message</div>
        <div className="btn-sec">
          <div className="btn" onClick={() => setOpen(true)}>
            <i className="eyaa-image-25" />
            Preview
          </div>
          <div className="btn" onClick={() => saveCardDetails()}>
            <i className="icon-web-071" />
            Save
          </div>
        </div>
      </div>
      <div className="quil-section">
        <EditorToolbar display={true} index={0} length={1} />
      </div>
      <SimpleBar className="cards-container">
        <div className="cards">
          {cardDetails &&
            <div className="card">
              <div className="input-wrapper">
                <InputLabel
                  htmlFor="uncontrolled-native"
                  className={classes.topSpacing}
                  classes={{
                    root: classes.labelroot,
                  }}
                >
                  {`Welcome Text* (${cardDetails.title.length}/100 Characters)`}
                </InputLabel>
                <TextField
                  fullWidth
                  id="filled-basic"
                  variant="filled"
                  className={classes.root900}
                  required={true}
                  value={cardDetails.title}
                  disabled={false}
                  onChange={(e) => {
                    if (e.target.value.length <= 100) {
                      updateCardDetails("title", e.target.value);
                    }
                  }}
                />
                <SimpleBar className="editor">
                  <span className="editer-heading">{`Description* (${descLength}/1000 Characters)`}</span>
                  <ReactQuill
                  //  index={0}
                    style={{}}
                    ref={(el) => (reactQuillRef.current = el)}
                    theme="snow"
                    onFocus={() => { }}
                    value={cardDetails.content}
                    onChange={(content) => {
                      handleChange(content);
                    }}
                    bounds=".editor"
                    modules={modules0}
                    formats={formats}
                  />
                </SimpleBar>
                <div className="upload-container">
                  <label htmlFor={`uploadImage`}>
                    Infographics/Image(Upload only .jpeg/.png)
                  </label>
                  <input
                    type="file"
                    name="photo"
                    id={`uploadImage`}
                    className="upload-photo"
                    accept="image/png, image/jpeg"
                    onChange={(e) => validateFile(e.target.files[0])}
                  />
                  <span className="file-name">
                    {cardDetails.fileName}
                  </span>
                  <span className="eyaa-files-104 upload-icon"></span>
                </div>
              </div>
            </div>
          }
        </div>
      </SimpleBar>
      <Modal
        open={isOpen}
        onClose={() => {
          setOpen(false);
        }}
        styles={{
          modal: {
            maxWidth: "unset",
            padding: "unset",
          },
          overlay: {
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
        center
      >
        <div className="email-template-wrap">
          <div className="modal-heading">Welcome Message</div>
          <SimpleBar
            style={{
              overflowX: "hidden",
              maxHeight: "80vh",
              width: "70vw",
            }}
            className="preview-modal"
          >
            <Preview cardDetails={cardDetails}/>
          </SimpleBar>
        </div>
      </Modal>
    </div>
  );
};
export default IntroductionCards;
