import React, { useState, useMemo, useCallback, useEffect, useContext } from "react";
import {
    Select,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormLabel,
    MenuItem,
    InputLabel,
    TextField
} from "@material-ui/core";
import "./AddMercuryData.scss";
import { columnNames } from "./columnNames";
import { useDropzone } from "react-dropzone";
import Loader from "../../../Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
    authProvider,
    APIscope,
    GraphExplorerscopes,
} from "./../../../../Authentication/authProvider";
import { toast, Flip } from "react-toastify";
import readXlsxFile from "read-excel-file";
import * as myConsts from "../../../../constants/constants";
import AppContext from "../../../../utils/appContext";
import { getFinancialYears } from "../../../../utils/utils";
//import XLSX from 'xlsx';
import DropdownComponent from "../../../Admin/AdminGeneralParam/DropdownComponent/DropdownComponent";
import DownloadTemplate from './DownloadTemplate';
// import SearchClientTable from "../SearchClientTable/SearchClientTable";

const AddMercuryData = (props) => {
    const dispatch = useDispatch();
    const myContext = useContext(AppContext);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    const [headerErrors, setHeaderError] = useState(false);
    const [progress, setProgress] = useState(-1);
    const [enableProgress, setEnableProgress] = useState(false);
    const [popupType, setPopUpType] = useState(-1);
    const [fileInfo, setFileInfo] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [issueFields, setIssueFields] = useState(null);
    const [radioChoice, setRadioChoice] = React.useState('new');

    const mandatoryFields = [columnNames.indexOf("ClientId"), columnNames.indexOf("Client"), columnNames.indexOf("EngagementRegion")]; // empty check in the uploaded files will be done for these fields only
    const validEngagementRegionValues = ['FSO Americas', 'US-East', 'US-Central', 'US-West'];
    const validAccountSectorValues = ["Advanced Manufacturing & Mobility", "Government & Public Sector",
        "Health Sciences & Wellness", "Technology, Media & Entertainment, Telecommunications",
        "Real Estate, Hospitality & Construction", "To be Determined"];    
    const mercuryFileUpload = useSelector((state) => state.mercuryFileUpload);
    const bobAdminActionStatus = useSelector((state) => state.bobAdminActionStatus);
    //const [loader, setLoader] = useState(false);
    const [clientSearchObj, setClientSearchObj] = useState({
        client: "",
        clientId: "",
    });
    const searchClientList = useSelector((state) => state.searchClient);
    const [searchedList, setSearchedList] = useState([]);
    const [isShowNoRecordsText, setShowNoRecordsText] = useState(false);
    const [financialYear, setFinancialYear] = useState(myContext.financialYear);
    const financialYearOptions = useMemo(() => {
        return getFinancialYears(myContext.financialYear, true);
    }, [myContext]);
    const [timePeriod, setTimePeriod] = useState(
        myContext.activePeriod
            ? myContext.activePeriod
            : myConsts.TimePeriodsArray[0]
    );
    const periodOptions = useMemo(() => ["Plan", "Mid-Year", "Year-End"], []);
    const pfyOptions = useMemo(() => ["PFYTD", "PFY"], []);

    const handleRadioChange = (event) => {
        setRadioChoice(event.target.value);
        setSearchedList([])
    };

    const [values, setValues] = useState({
        fyYear: "FY" + myContext.financialYear,
        period: myContext.activePeriod
            ? myContext.activePeriod
            : myConsts.TimePeriodsArray[0],
        pfyValue: "PFYTD",
    });
    const [errorMessages, setErrorMessages] = useState({
        fyYear: "",
        period: "",
        pfyValue: "",
    });

    const dropOnChange = (value, label) => {
        alert(value);
        setValues({ ...values, [label]: value });
    };

    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop: (files) => {
            // if (!waitingForCompletion) {
            setHeaderError(false);
            validateFile(files[0]);
            // }
        },
        maxFiles: 1,
        maxSize: 15728640,
        accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet ,application/vnd.ms-excel.sheet.binary.macroEnabled.12",
        disabled: myContext.disableUserUpdation,
    });

    const validateFile = useCallback((file) => {
        if (!file) {
            return;
        } else {
            setFileInfo(file);
            setIsPopupVisible(true);
            setPopUpType(0);
        }
    }, []);

    useEffect(() => {
        if (progress < 100) {
            setTimeout(function () {
                if (progress == 98 && !isLoading && !headerErrors) {
                    setProgress(100);
                } else setProgress(progress + 2);
            }, 1);
        }
    }, [progress]);

    useEffect(() => {
        const fileRejectionItems = fileRejections.map(({ errors }) => {
            return errors.map((e) => e.code);
        });
        if (fileRejectionItems.length > 0) {
            if (fileRejectionItems[0].includes("file-too-large"))
                notify(
                    "File size is too large. File size should be within 15MB.",
                    "error"
                );
            else if (fileRejectionItems[0].includes("file-invalid-type"))
                notify(
                    "File type is not valid.Only,excel file with .xlsx extension can be uploaded. ",
                    "error"
                );
            else notify(fileRejectionItems[0], "error");
        }
    }, [fileRejections]);

    const fileHandler = useCallback(() => {
        setIsLoading(true);
        readXlsxFile(fileInfo).then((rows) => {
            validateExcelData(rows);
            console.log(rows);
            setIsLoading(false);
        }).catch(() => { });

        // const filereader = new FileReader();
        // filereader.readAsArrayBuffer(fileInfo);

        // filereader.onload = async (e) => {
        //     const bufferArray = e.target.result;
        //     const wb = XLSX.read(bufferArray, { type: 'buffer' });
        //     const wsname = wb.SheetNames[0];
        //     const ws = wb.Sheets[wsname];
        //     const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
        //     console.log(data);
        // }
    }, [fileInfo, validateExcelData]);

    const validateExcelData = useCallback((rows) => {
        setErrorMsg("");
        setIssueFields(null);
        setHeaderError(false);
        const updatedRows = _.cloneDeep(rows);
        updatedRows.shift();
        //debugger;
        const pillars = _.groupBy(updatedRows, 0);
        const issueCells = [];
        let isEmpty = false, isInValidEngRegion = false, isInValidAccSector = false;
        if (!_.isEqual(columnNames, rows[0])) {
            setErrorMsg(
                "The file which you have uploaded is not in the correct format."
            );
            setHeaderError(true);
            return;
        } else if (updatedRows.length === 0) {
            setErrorMsg("File which you uploaded has blank cells.");
            setHeaderError(true);
            return;
        } else {
            let emptyCellList = [], invalidFieldValues = [];
            updatedRows.forEach((updatedRow, rowIndex) => {
                updatedRow.forEach((row, columnIndex) => {
                    if ((row === "" || row === null)) {
                        isEmpty = true;
                        emptyCellList.push({ column: columnNames[columnIndex], rowNo: rowIndex + 2 });
                    }
                    if (columnNames[columnIndex] === columnNames[2]
                        && (row !== "" || row !== null)
                        && !validEngagementRegionValues.includes(row)) //..EngagementRegion
                    {
                        isInValidEngRegion = true;
                        invalidFieldValues.push({ column: columnNames[columnIndex], rowNo: rowIndex + 2 });
                    }
                    if (columnNames[columnIndex] === columnNames[3]
                        && (row !== "" || row !== null)
                        && !validAccountSectorValues.includes(row)) //..AccountSector
                    {
                        isInValidAccSector = true;
                        invalidFieldValues.push({ column: columnNames[columnIndex], rowNo: rowIndex + 2 });
                    }
                });
            });
            if (isEmpty) {
                setErrorMsg("File contains below number of blank cells, please validate and upload again.");
                setHeaderError(true);
                const groupedResult = _.groupBy(emptyCellList, x => x.column);
                setIssueFields(groupedResult);
                return;
            }
            if (isInValidEngRegion || isInValidAccSector) {
                let errMsg = "File contains below number of cells having invalid values, please validate and upload again.";
                if (isInValidEngRegion) {
                    errMsg = `${errMsg}\nValid Engagement Region values are "FSO Americas", "US-East", "US-Central", "US-West".`;
                }
                if (isInValidAccSector) {
                    errMsg = `${errMsg}\nValid Account Sector values are "Advanced Manufacturing & Mobility", "Government & Public Sector",
                    "Health Sciences & Wellness", "Technology, Media & Entertainment, Telecommunications",
                    "Real Estate, Hospitality & Construction", "To be Determined".`;
                }
                setErrorMsg(errMsg);
                setHeaderError(true);
                const groupedResult = _.groupBy(emptyCellList, x => x.column);
                setIssueFields(groupedResult);
                return;
            }
        }

    }, []);


    const notify = useCallback((msg, type) => {
        toast(
            <div className="error-text">
                {type === "error" ? (
                    <span className="icon-close-filled icon"></span>
                ) : (
                    <span className="eyaa-state-36 icon"></span>
                )}
                <p>
                    {type === "error" ? (
                        <>
                            <span>Error:</span> <span>{msg}</span>
                        </>
                    ) : (
                        <span>{msg}</span>
                    )}
                </p>
            </div>,
            {
                className: type,
                position: "top-center",
                transition: Flip,
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        );
    }, []);



    const mercuryFileToUpload = async () => {
        //function to call fetCh API for MERCURY_FILE_UPLOAD.
        try {
            dispatch({
                type: "MERCURY_FILE_UPLOAD",
                payload: {
                    excel_upload: fileInfo,
                    // financialYear: props.rollForwardData.fromYear,
                    // timePeriod: props.rollForwardData.fromPhase,
                    financialYear: financialYear,
                    timePeriod: timePeriod,
                    bobParameter: values.pfyValue,
                    isFromDataManagement: true
                },
            });
            setIsLoading(true);
            // myContext.setmultipleUserUploadInProgress(true);
        } catch (e) {
            console.error(e);
        }
    };

    const getLockStatusAPI = async () => {
        //function to call bobAdminActionStatus(Lock) Api.
        try {
            const token = await authProvider.acquireTokenSilent({
                scopes: APIscope,
            });
            const idToken = token.accessToken;
            //if (props.rollForwardData.toYear !== "" && props.rollForwardData.toPhase !== "") {
            if (financialYear !== "" && timePeriod !== "") {
                dispatch({
                    type: "BOB_ADMIN_ACTION_STATUS",
                    payload: {
                        // financialYear: props.rollForwardData.toYear,
                        // timePeriod: props.rollForwardData.toPhase,
                        financialYear: financialYear,
                        timePeriod: timePeriod,
                    },
                });
            }
        } catch (e) {
            console.error(e);
        }
    };


    useEffect(() => {
        //mercuryFileUpload  API response
        if (
            !mercuryFileUpload.fetching &&
            mercuryFileUpload.data &&
            mercuryFileUpload.data.status === "In Progress"
            // && mercuryFileUpload.data.proceedAction
        ) {
            //Succefullycompleted
            myContext.setIsWarningPopup(true);
            myContext.setIsLockPopup(false);
            setIsLoading(false);
            toast.warn(
                <div>
                    <i className="icon-verified-outline" />
                    {"Success"}
                    <b> {": "}</b>
                    {"File Uploaded Successfully"}
                </div>,
                {
                    position: "top-center",
                    transition: Flip,
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );
            props.onClose();
            dispatch({
                type: "MERCURY_FILE_UPLOAD_RESET",
                payload: {},
            });

            getLockStatusAPI();
            // myContext.setmultipleUserUploadInProgress(false);

        } else if (
            !mercuryFileUpload.fetching &&
            mercuryFileUpload.data &&
            mercuryFileUpload.data.proceedAction
            // mercuryFileUpload.data.status !== "In Progress" 
        ) {
            //upload failed
            // myContext.setmultipleUserUploadInProgress(false);
            myContext.setIsWarningPopup(true);
            myContext.setIsLockPopup(false);
            setIsLoading(false);
            toast.warn(
                <div>
                    <i className="icon-warning-outline" />
                    <b> {": "}</b>
                    {"Mercury File Upload has Failed"}
                </div>,
                {
                    position: "top-center",
                    transition: Flip,
                    autoClose: 1500,
                    hideProgressBar: true,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            );

            props.onClose();
            dispatch({
                type: "MERCURY_FILE_UPLOAD_RESET",
                payload: {},
            });
            myContext.setIsWarningPopup(false);
        }
    }, [mercuryFileUpload]);


    useEffect(() => {
        //lock status API response
        if (
            !bobAdminActionStatus.fetching &&
            bobAdminActionStatus.data &&
            bobAdminActionStatus.data.bobStatus &&
            bobAdminActionStatus.data.proceedAction
        ) {
            //Succefully Completed
            setIsLoading(false);
            myContext.setDisableUserUpdation(false);
        } else if (
            !bobAdminActionStatus.fetching &&
            bobAdminActionStatus.data &&
            bobAdminActionStatus.data.bobStatus &&
            !bobAdminActionStatus.data.proceedAction
        ) {
            //not completed
            if (firstTime) {
                myContext.setIsWarningPopup(false);
                triggerLock();
                setFirstTime(false);
                setIsLoading(false);
            }
            myContext.setDisableUserUpdation(true);
            // dispatch({
            //     type: "BOB_ADMIN_ACTION_STATUS_RESET",
            //     payload: {},
            // });
            setTimeout(function () {
                getLockStatusAPI();
            }, 15000);
        }
        // else if (!bobAdminActionStatus.fetching && bobAdminActionStatus.error) {
        //   getLockStatusAPI();
        // }
    }, [bobAdminActionStatus]);


    useEffect(() => {
        // setIsLoading(true);
        getLockStatusAPI();
    }, []);


    const triggerLock = () => {
        myContext.setIsLockPopup(true);
        toast.warn(
            <div
                style={{
                    // textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "EYInterState-Regular",
                    fontSize: "12px",
                }}>
                <i className="icon-warning-outline" />
                {/* "Section Updated Successfully" : */}
                {`Previous operation initiated by ${bobAdminActionStatus.data.userName} is still in progress. It will take some time. You can
                perform this action after the operation is completed. We appreciate your
                patience!`}
            </div>,
            {
                position: "top-center",
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        );
    };

    // useEffect for setting the searched cliet list
    useEffect(() => {
        if (
            searchClientList &&
            searchClientList.data &&
            searchClientList.data.payload &&
            searchClientList.data.payload.data
        ) {
            setIsLoading(false);
            const data = searchClientList.data.payload.data;
            setSearchedList(data);
            setShowNoRecordsText(true);
        }
    }, [searchClientList]);

    const handleChangeSearch = (value, name) => {
        if (value.length > 2) {
            setClientSearchObj({ ...clientSearchObj, [name]: value });
        } else setClientSearchObj({ ...clientSearchObj, [name]: "" });
    };
    const searchClient = async () => {
        // console.log('obj', clientSearchObj)

        const { client, clientId } = clientSearchObj;
        try {
            setIsLoading(true);
            const token = await authProvider.acquireTokenSilent({
                scopes: APIscope,
            });
            const idToken = token.accessToken;
            dispatch({
                type: "SEARCH_CLIENT",
                payload: {
                    token: idToken,
                    isFromDataManagement: true,
                    client,
                    clientId,
                    // financialYear: props.rollForwardData.fromYear,
                    // timePeriod: props.rollForwardData.fromPhase,
                    financialYear: financialYear,
                    timePeriod: timePeriod,
                },
            });
        } catch (e) {
            console.error(e);
        }
    };
    return (
        <>
            {isLoading && <Loader />}
            <div className="multiple-mercury-data-management-container">
                <div className="input-container">
                    <div className="select-container">
                        <FormControl>
                            <InputLabel id="yearLabel">Select Year</InputLabel>
                            <Select
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                labelId="yearLabel"
                                value={financialYear}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SEARCH_USER_DATA_RESET",
                                        payload: {},
                                    });
                                    setFinancialYear(e.target.value);
                                }}
                            //disabled={isDisablePage()}
                            >
                                {financialYearOptions.map((option) => (
                                    <MenuItem value={option.key} key={option.key}>
                                        {option.value}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="periodLabel">Period</InputLabel>
                            <Select
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                labelId="periodLabel"
                                value={timePeriod}
                                onChange={(e) => {
                                    dispatch({
                                        type: "SEARCH_USER_DATA_RESET",
                                        payload: {},
                                    });
                                    setTimePeriod(e.target.value);
                                }}
                            //disabled={isDisablePage()}
                            >
                                {periodOptions.map((val, index) => (
                                    <MenuItem
                                        value={val}
                                        key={index}
                                    // disabled={
                                    //   index < periodOptions.indexOf(myContext.activePeriod)
                                    //     ? true
                                    //     : null
                                    // }
                                    >
                                        {val}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl>
                            <InputLabel id="pfyLabel">PFYTD/PFY</InputLabel>
                            <Select
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left",
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left",
                                    },
                                    getContentAnchorEl: null,
                                }}
                                labelId="pfyLabel"
                                value={values.pfyValue}
                                defaultValue={values.pfyValue}
                                required={true}
                                errorMessage={errorMessages}
                                onChange={(e) => {
                                    dropOnChange(e.target.value, "pfyValue");
                                }}
                                disabled={myContext.disableUserUpdation}
                            >
                                {pfyOptions.map((val, index) => (
                                    <MenuItem
                                        value={val}
                                        key={index}
                                    // disabled={
                                    //   index < periodOptions.indexOf(myContext.activePeriod)
                                    //     ? true
                                    //     : null
                                    // }
                                    >
                                        {val}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <h6 style={{
                        color: "#2e2e38",
                        margin: "12px 0",
                        fontSize: "12px",
                        position: "absolute",
                        right: "20px"
                    }}>Upload File to Add / Edit Mercury Data</h6>
                </div>
                {/* <div className="mainheader">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "20px",
                            width: "150px",
                            margiTop: "2px",
                            marginBottom: "20px",
                        }}
                    >
                        FY {props.rollForwardData.fromYear} - {props.rollForwardData.fromPhase}
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginLeft: "15px",
                            width: "250px",
                            margiTop: "2px",
                        }}
                    >
                        <DropdownComponent
                            label={"PFYTD/PFY"}
                            defaultValue={values.pfyValue}
                            required={true}
                            selectOnChange={(dummy, value) => {
                                dropOnChange(value, "pfyValue");
                            }}
                            varName="pfyValue"
                            list={["PFYTD", "PFY"]}
                            errorMessage={errorMessages}
                            disabled={myContext.disableUserUpdation}
                        />
                    </div>
                    <h6>Upload File to Add / Edit Mercury Data</h6>
                </div> */}
                <div className="mainheader">
                    <div>
                        <FormControl>
                            {/* <FormLabel component="legend">Status</FormLabel> */}
                            <RadioGroup
                                row
                                aria-labelledby="manage-data-controlled-radio-buttons-group"
                                name="manage-data-controlled-radio-buttons-group"
                                value={radioChoice}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel value="new" control={<Radio color="default" />} label="Add New Client" />
                                <FormControlLabel value="existing" control={<Radio color="default" />} label="Update Existing Client" />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
                {
                    radioChoice !== "" &&
                    (
                        <div className="input-container">
                            {
                                radioChoice === "new" &&
                                (
                                    <div>
                                        <div className="download-template">
                                            <DownloadTemplate
                                                radioChoice={radioChoice}
                                                rowCount={1}
                                                colCount={19}
                                                disableDownloadBtn={false} />
                                        </div>
                                        <p className="info-text">
                                            Download the template to make edits and upload the updated file here.
                                        </p>
                                    </div>
                                )
                            }
                            {
                                radioChoice === "existing" &&
                                (
                                    <div>
                                        <div className="select-container" style={{ marginLeft: "12px" }}>
                                            <TextField id="standard-search" label="Client ID" type="search"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={(e) => handleChangeSearch(e.target.value, "clientId")}
                                            />
                                            <input
                                                type="button"
                                                value="Search & Download Template"
                                                className="search-btn"
                                                onClick={searchClient}
                                            />
                                        </div>
                                        {
                                            !_.isEmpty(searchedList) &&
                                            (
                                                <div>
                                                    <div className="download-template">
                                                        <DownloadTemplate
                                                            radioChoice={radioChoice}
                                                            rowCount={searchedList.length}
                                                            colCount={19}
                                                            searchedList={searchedList}
                                                            disableDownloadBtn={false} />
                                                    </div>
                                                    <p className="info-text">
                                                        Download the template to make edits and upload the updated file here.
                                                    </p>
                                                </div>
                                            )
                                        }
                                        {/* <p className="info-text">
                                            Download the template to make edits and upload the updated file here.
                                        </p> */}
                                        {/* <div className="user-details">
                                            {!_.isEmpty(searchedList) ? (
                                                <div className="table-container">
                                                    <h6>Client List</h6>
                                                    <SearchClientTable
                                                        isShowNoRecordsText={isShowNoRecordsText}
                                                        rowCount={searchedList.length}
                                                        numSelected={0}
                                                        isDuplicateFound={true}
                                                        //handleClientCheckedAll={handleClientCheckedAll}
                                                        //handleClientChecked={handleClientChecked}
                                                        clientList={searchedList}
                                                        setActions={setIsPopupVisible}
                                                        setIsPopupVisible={setIsPopupVisible}
                                                    />
                                                </div>
                                            ) : (
                                                // !isDisablePage() && (
                                                <div className="helper-text">Search to view client data</div>
                                                // )
                                            )}
                                        </div> */}
                                    </div>
                                )
                            }
                        </div>
                    )
                }

                <section
                    style={{ opacity: myContext.disableUserUpdation || (progress > 0 && progress < 100) || (progress === 100) ? 0.5 : 1 }}>
                    <div className="file-upload-container" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="helper-text">
                            <p>Drag your file here to Upload</p>
                            <p>Maximum file size: 15MB</p>
                        </div>
                        <label htmlFor="uploadFile" style={{ cursor: myContext.disableUserUpdation || (progress > 0 && progress < 100) || (progress === 100) ? "not-allowed" : "pointer" }}>
                            {" "}
                            <span className="eyaa-files-104 file-icon"></span>{" "}
                            <span>Choose file</span>
                        </label>
                    </div>
                </section>
                {/* progressLine */}
                {enableProgress && (
                    <div
                        className="shadowBox"
                        style={{
                            borderLeftColor: `${progress === 100 ? "#b1de76" : "#2e2e38"}`,
                        }}
                    >
                        <div className="title">
                            {progress === 100 ? "Validation Completed" : "Validating file"}
                            {headerErrors && <div className="errorMsg">{"File Error"}</div>}
                        </div>
                        {progress !== 100 ? (
                            <div className="blackLine">
                                <div
                                    className="yelloLine"
                                    style={{ left: `${(progress / 100) * 330}px` }}>
                                    {"&nbsp;"}
                                </div>
                            </div>
                        ) : (
                            <div className="greenLine">{"&nbsp;"}</div>
                        )}
                        <div className="row">
                            <div className="fileN"> {fileInfo.name}</div>
                            <div className="progress">
                                {" "}
                                {progress +
                                    " % " +
                                    (progress === 100 ? "Complete" : "Processing...")}
                            </div>
                        </div>
                    </div>
                )}

                {headerErrors && (
                    <div className="error-section">
                        <span className="redMsg">
                            {" "}
                            <i className="icon-warning-filled" />
                            File Error
                        </span>
                        <div className="desc">
                            {errorMsg}
                        </div>
                        {issueFields ?
                            <div className="blank-counts">
                                {/* <div className="counts-header" >Column Name (Blank cells count)</div> */}
                                {Object.keys(issueFields).map(key => <div className="counts">{`${key} (${issueFields[key].length})`}</div>)}
                            </div> : null}
                    </div>
                )}
                <div className="AdminActions">
                    <>
                        <div
                            className="cancel"
                            onClick={() => {
                                props.onClose();
                            }}
                        >
                            Cancel
                        </div>

                        <div
                            className={
                                "save" +
                                (!headerErrors &&
                                    progress === 100 &&
                                    !myContext.disableUserUpdation
                                    ? ""
                                    : " disable-btn ")
                            }
                            onClick={() => {
                                setIsPopupVisible(true);
                                setPopUpType(1);
                            }}
                        >
                            Add / Edit Mercury Data
                        </div>
                    </>
                </div>
            </div>
            {/* popUpPage */}
            {
                isPopupVisible && (
                    <div className="popup-dialog">
                        <div className="dialog">
                            <div className="header">
                                <h5>{popupType == 0 ? "File Validation" : "Confirmation"}</h5>
                                <span
                                    style={{ position: "absolute", right: "12px", top: "10px" }}
                                    className="icon-close-filled icon"
                                    onClick={() => setIsPopupVisible(false)}
                                ></span>
                            </div>
                            {popupType == 0 ? (
                                <div className="content">
                                    <span>Selected file</span>
                                    <b> {fileInfo.name}</b>
                                    <span> should be validated before uploading.</span>
                                </div>
                            ) : (
                                <div className="content">Would you like to add the mercury data? </div>
                            )}
                            {popupType == 1 && (
                                <div className="content" style={{ marginTop: "10px" }}>
                                    Please note that this will take some time. Once the process is
                                    completed, we will share the summary report over email.
                                </div>
                            )}
                            {popupType == 0 ? (
                                <div className="footer">
                                    <button
                                        className="cancel-btn"
                                        onClick={() => {
                                            setIsPopupVisible(false);
                                            setEnableProgress(false);
                                            setFileInfo(null);
                                            setHeaderError(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="validate-btn"
                                        onClick={() => {
                                            setEnableProgress(true);
                                            setIsPopupVisible(false);
                                            setProgress(0);
                                            fileHandler();
                                            setHeaderError(false);
                                        }}
                                    >
                                        Validate
                                    </button>
                                </div>
                            ) : (
                                <div className="footer">
                                    <button
                                        className="cancel-btn"
                                        onClick={() => {
                                            setIsPopupVisible(false);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="validate-btn"
                                        onClick={() => {
                                            setIsPopupVisible(false);
                                            setEnableProgress(false);
                                            // setIsLoading(true);
                                            myContext.setDisableUserUpdation(true);
                                            mercuryFileToUpload();
                                            dispatch({
                                                type: "BOB_ADMIN_ACTION_STATUS_RESET",
                                                payload: {},
                                            });
                                        }}
                                    >
                                        Confirm
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default AddMercuryData;
