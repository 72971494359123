import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { Button } from "@material-ui/core";
import _ from "lodash";
import readXlsxFile from "read-excel-file";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import { toast, Flip } from "react-toastify";
import Loader from "../../../Loader/Loader";
import AppContext from "../../../../utils/appContext";
import "./DeleteMultipleUsers.scss";
import { useDispatch, useSelector } from "react-redux";
import DropdownComponent from "../../AdminGeneralParam/DropdownComponent/DropdownComponent";
import Template from "../../../../assets/files/Delete Multiple Users_ Template.xlsx";
import NavigationPrompt from "react-router-navigation-prompt";

const DeleteMultipleUsers = (props) => {
  let clearInterval;
  const lockId = "custom-id-lock";
  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  const getPhaseValidationStatus = useSelector(
    (state) => state.getPhaseValidationStatus
  );
  const deleteUsers = useSelector((state) => state.deleteUsers);
  const lockStatus = useSelector((state) => state.checkLockStatus);

  const [firstTime, setFirstTime] = useState(true);
  const [values, setValues] = useState({
    fyYear: "",
    period: "",
  });
  const [periodDropdown, setPeriodDropdown] = useState([]);
  const [errorMessages, setErrorMessages] = useState({
    fyYear: "",
    period: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [enableProgress, setEnableProgress] = useState(false);
  const [progress, setProgress] = useState(-1);
  const [fileInfo, setFileInfo] = useState({});
  const [isFileUploadStarted, setIsFileUploadStarted] = useState(false);
  const [isFileUploadCompleted, setIsFileUploadCompleted] = useState(false);
  const [popupType, setPopUpType] = useState(0);
  const [errorMsg, setErrorMsg] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [userIds, setUserIds] = useState([]);
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);

  const yearArray = useMemo(
    () =>
      Array.isArray(getPhaseValidationStatus.data)
        ? getPhaseValidationStatus.data.map((elem) => {
            return `FY ${elem.year}`;
          })
        : [],
    [getPhaseValidationStatus]
  );

  const isDisablePage = useMemo(() => {
    return myContext.disableUserUpdation;
  }, [myContext]);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: (files) => {
      validateFile(files[0]);
    },
    maxFiles: 1,
    maxSize: 5119031,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    disabled: isDisablePage,
  });

  const validateFile = useCallback((file) => {
    if (!file) {
      return;
    } else {
      setPopUpType(1);
      setFileInfo(file);
      setIsPopupVisible(true);
    }
  }, []);

  const validateExcelData = useCallback((rows) => {
    setErrorMsg("");
    const updatedRows = _.cloneDeep(rows);
    updatedRows.shift();
    const excelHeaders = [null, "Email ID"];
    if (!_.isEqual(excelHeaders, rows[0])) {
      setErrorMsg(
        "The file which you have uploaded is not in the correct format."
      );
      return;
    }
    const ids = updatedRows.map((row) => row[1]);
    if (ids.length === 0) {
      setErrorMsg("The file which you have uploaded have no email id.");
    } else {
      setUserIds(ids);
    }
  }, []);

  const fileHandler = useCallback(() => {
    setIsFileUploadStarted(true);
    setIsFileUploadCompleted(false);
    readXlsxFile(fileInfo)
      .then((rows) => {
        validateExcelData(rows);
        setIsFileUploadCompleted(true);
      })
      .catch(() => {
        setErrorMsg(
          "The file which you have uploaded is not in the correct format."
        );
        setIsFileUploadCompleted(true);
      });
  }, [fileInfo, validateExcelData]);

  const deleteMultipleUsers = useCallback(() => {
    dispatch({
      type: "DELETE_USERS",
      payload: {
        userList: userIds,
        financialYear: parseInt(values.fyYear.substring(2)),
        timePeriod: values.period,
        fileName: fileInfo.name
      },
      deleteMultiUsers: true,
    });
  }, [userIds, values, fileInfo]);

  const onPopupConfirm = useCallback(() => {
    setIsPopupVisible(false);
    if (popupType === 1) {
      setProgress(0);
      setEnableProgress(true);
      fileHandler();
    } else if (popupType === 2) {
      deleteMultipleUsers();
    }
  }, [popupType, dispatch, fileHandler, deleteMultipleUsers]);

  const dropOnChange = useCallback(
    (value, label) => {
      //disable previous year & period
      if (label == "fyYear") {
        if ("FY " + (myContext.financialYear - 1) !== value)
          setValues({ ...values, [label]: value, period: "" });
      } else {
        setValues({ ...values, [label]: value });
      }
    },
    [myContext, values]
  );

  const getPopupContent = useCallback(() => {
    if (popupType === 1) {
      return (
        <>
          <span>Selected file </span>
          <b>{fileInfo.name}</b>
          <span> should be validated before uploading.</span>
        </>
      );
    } else if (popupType === 2) {
      return (
        <>
          <p>Would you like to delete the users?</p>
          <p>
            Please note that the users will be deleted from the selected year
            and phase.
          </p>
          <p>
            This operation will take some time to complete. Once it is
            completed, we will inform you over an email.
          </p>
        </>
      );
    }
  }, [popupType, fileInfo, values.fyYear]);

  const notify = useCallback((msg, type) => {
    toast(
      <div className="error-text">
        {type === "error" ? (
          <span className="icon-close-filled icon"></span>
        ) : (
          <span className="eyaa-state-36 icon"></span>
        )}
        <p>
          {type === "error" ? (
            <>
              <span>Error:</span> <span>{msg}</span>
            </>
          ) : (
            <span>{msg}</span>
          )}
        </p>
      </div>,
      {
        className: type,
        position: "top-center",
        transition: Flip,
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }, []);

  const triggerLock = () => {
    myContext.setIsLockPopup(true);
    if (!document.getElementById(lockId) && lockStatus?.data?.userName)
      toast.warn(
        <div
          style={{
            // textTransform: "capitalize",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontFamily: "EYInterState-Regular",
            fontSize: "12px",
          }}>
          <i className="icon-warning-outline" />
          {/* "Section Updated Successfully" : */}
          {`Previous operation initiated by ${
            lockStatus.data && lockStatus.data.userName
          } is still in progress. It will take some time. You
          can perform this action after the operation is completed. We
          appreciate your patience!`}
        </div>,
        {
          position: "top-center",
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          toastId: lockId,
        }
      );
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch({
      type: "GET_PHASE_VALIDATION_STATUS",
      payload: {},
    });
  }, [dispatch]);

  useEffect(() => {
    if (
      !getPhaseValidationStatus.fetching &&
      getPhaseValidationStatus.data.length
    ) {
      setIsLoading(false);
      //not completed
      // setValues({
      //   fyYear: "FY" + getPhaseValidationStatus.data[0].year,
      //   period: getPhaseValidationStatus.data[0].phase[0],
      // });
      // setPeriodDropdown(getPhaseValidationStatus.data[0].phase);

      if (
        assessmentMetaData &&
        assessmentMetaData.data &&
        assessmentMetaData.data.data
      ) {
        const data = assessmentMetaData.data.data;
        if (data.activePhase == null) {
          getPhaseValidationStatus.data.map((item) => {
            if (item.year === data.lastActivePhaseYear) {
              setPeriodDropdown(item.phase);
              setValues({
                fyYear: "FY " + data.lastActivePhaseYear,
                period: item.lastActivePhase,
              });
            }
          });
        } else {
          getPhaseValidationStatus.data.map((item) => {
            if (item.year === data.activePhaseYear) {
              setPeriodDropdown(item.phase);
            }
          });
          setValues({
            fyYear: "FY " + data.activePhaseYear,
            period: data.activePhase,
          });
        }
      }
    }
  }, [getPhaseValidationStatus]);

  useEffect(() => {
    const yearDetails = Array.isArray(getPhaseValidationStatus.data)
      ? getPhaseValidationStatus.data.find(
          (elem) => `FY ${elem.year}` === values.fyYear
        )
      : {};
    if (yearDetails && yearDetails.phase) {
      setPeriodDropdown(yearDetails.phase);
    }
  }, [values.fyYear]);

  useEffect(() => {
    const fileRejectionItems = fileRejections.map(({ errors }) => {
      return errors.map((e) => e.code);
    });
    if (fileRejectionItems.length > 0) {
      if (fileRejectionItems[0].includes("file-too-large"))
        notify(
          "File size is too large. File size should be within 5MB.",
          "error"
        );
      else if (fileRejectionItems[0].includes("file-invalid-type"))
        notify(
          "File type is not valid.Only,excel file with .xlsx extension can be uploaded. ",
          "error"
        );
      else notify(fileRejectionItems[0], "error");
    }
  }, [fileRejections, notify]);

  useEffect(() => {
    if (progress < 100) {
      setTimeout(function () {
        if (progress == 98 && !isFileUploadStarted) {
          setProgress(100);
        } else setProgress(progress + 2);
      }, 1);
    }
  }, [progress]);

  useEffect(() => {
    if (deleteUsers.fetching) {
      setIsLoading(true);
    } else {
      if (!_.isEmpty(deleteUsers.data) && deleteUsers.data.success) {
        notify("Success: Delete Multiple Users initiated", "success");
        props.onClose();
        props.changesInChild(false);
      }
      setIsLoading(false);
    }
  }, [deleteUsers]);

  useEffect(() => {
    dispatch({
      type: "CHECK_LOCK_STATUS",
      payload: {},
    });
    clearInterval = setInterval(() => {
      dispatch({
        type: "CHECK_LOCK_STATUS",
        payload: {},
      });
    }, 15000);
    return () => {
      dispatch({
        type: "DELETE_USERS_RESET",
        payload: {},
      });
      window.clearInterval(clearInterval);
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      !lockStatus.fetching &&
      lockStatus.data &&
      lockStatus.data.message &&
      lockStatus.data.proceedAction
    ) {
      //Succefully Completed
      myContext.setDisableUserUpdation(false);
    } else if (
      !lockStatus.fetching &&
      lockStatus.data &&
      lockStatus.data.message &&
      !lockStatus.data.proceedAction
    ) {
      //not completed
      if (firstTime) {
        myContext.setIsWarningPopup(false);
        triggerLock();
        setFirstTime(false);
      }
      myContext.setDisableUserUpdation(true);
    }
  }, [lockStatus]);

  useEffect(() => {
    props.changesInChild(isFileUploadStarted);
  }, [isFileUploadStarted]);

  return (
    <div className="multiple-user-container">
      {isLoading && <Loader />}
      <NavigationPrompt when={isFileUploadStarted}>
        {({ onConfirm, onCancel }) => (
          <div className="popup-dialog">
            <div className="dialog">
              <div className="header">
                <h5>Confirmation</h5>
                <span
                  className="icon-close-filled icon"
                  onClick={onCancel}></span>
              </div>
              <div className="content">
                Would you like to navigate without completing the operation?
                Please note that on confirming, incomplete actions will be lost
              </div>
              <div className="footer">
                <button className="cancel-btn" onClick={onCancel}>
                  Cancel
                </button>
                <button className="confirm-btn" onClick={onConfirm}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </NavigationPrompt>
      <div className="mainheader">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "272px",
            margiTop: "2px",
          }}>
          <DropdownComponent
            label={"Year*"}
            defaultValue={values.fyYear}
            required={false}
            selectOnChange={(dummy, value) => {
              setValues({ fyYear: value, period: "" });
            }}
            varName="fyYear"
            list={yearArray}
            disableFirstItem={false}
            errorMessage={errorMessages}
            disabled={isDisablePage}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margiTop: "2px",
            width: "272px",
          }}>
          <DropdownComponent
            label={"Period*"}
            defaultValue={values.period}
            required={false}
            selectOnChange={(dummy, value) => {
              setValues({ ...values, period: value });
            }}
            varName="period"
            list={periodDropdown}
            errorMessage={errorMessages}
            disabled={isDisablePage}
          />
        </div>
        <h6>Upload File to Delete Multiple User</h6>
      </div>
      <div className="download-template">
        <a
          href={Template}
          download
          className={isDisablePage ? "disable-element" : ""}>
          <span className="eyaa-arrow-tab-down"></span>
          <span>Download Template</span>
        </a>
      </div>
      <p className="info-text">
        Download the template to make edits and upload the updated file here.
      </p>

      <section
        style={{
          opacity: myContext.disableUserUpdation,
        }}>
        <div
          className={classNames("file-upload-container", {
            "disable-element": isDisablePage,
          })}
          {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="helper-text">
            <p>Drag your file here to Upload</p>
            <p>Maximum file size: 5MB</p>
          </div>
          <label
            htmlFor="uploadFile"
            style={{
              cursor: myContext.disableUserUpdation,
            }}>
            <span className="eyaa-files-104 file-icon"></span>{" "}
            <span className={isDisablePage ? "disable-element" : ""}>
              Choose file
            </span>
          </label>
        </div>
      </section>
      {enableProgress && (
        <div
          className="shadowBox"
          style={{
            borderLeftColor: `${progress === 100 ? "#b1de76" : "#2e2e38"}`,
          }}>
          <div className="title">
            {progress === 100 ? "Validation Completed" : "Validating file"}
            {errorMsg && <div className="errorMsg">{"File Error"}</div>}
          </div>
          {progress !== 100 ? (
            <div className="blackLine">
              <div
                className="yelloLine"
                style={{ left: `${(progress / 100) * 330}px` }}>
                {"&nbsp;"}
              </div>
            </div>
          ) : (
            <div className="greenLine">{"&nbsp;"}</div>
          )}
          <div className="row">
            <div className="fileN"> {fileInfo.name}</div>
            <div className="progress">
              {" "}
              {progress +
                " % " +
                (progress === 100 ? "Complete" : "Processing...")}
            </div>
          </div>
        </div>
      )}
      {errorMsg && progress === 100 && (
        <div className="error-section">
          <span className="redMsg">
            {" "}
            <i className="icon-warning-filled" />
            File error
          </span>
          <div className="desc">
            <p>{errorMsg}</p>
            <p>Please check the file and upload again.</p>
          </div>
        </div>
      )}
      <div className="btn-container">
        <Button
          variant="outlined"
          className="cancel-btn"
          onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          className={classNames("add-assessment-btn", {
            "disable-btn":
              (errorMsg && isFileUploadCompleted) ||
              !isFileUploadStarted ||
              values.fyYear == "" ||
              values.period == "",
          })}
          disabled={
            (errorMsg && isFileUploadCompleted) ||
            !isFileUploadStarted ||
            values.fyYear == "" ||
            values.period == ""
          }
          onClick={() => {
            setIsPopupVisible(true);
            setPopUpType(2);
          }}>
          Delete Users
        </Button>
      </div>
      {isPopupVisible && (
        <div className="popup-dialog">
          <div
            className="dialog"
            style={{ height: popupType === 1 ? "160px" : "220px" }}>
            <div className="header">
              <h5>
                {popupType === 1
                  ? "File Validation"
                  : popupType === 2
                  ? "Confirmation"
                  : "Download Template"}
              </h5>
              <span
                className="icon-close-filled icon"
                onClick={() => setIsPopupVisible(false)}></span>
            </div>
            <div className="content">{getPopupContent()}</div>
            <div className="footer">
              <button
                className="cancel-btn"
                onClick={() => setIsPopupVisible(false)}>
                Cancel
              </button>
              <button className="confirm-btn" onClick={onPopupConfirm}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default DeleteMultipleUsers;
