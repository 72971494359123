import * as myConsts from "../../constants/constants";
import "./ErrorFallBack.scss";

const ErrorFallBack = (props) => {
    const { resetErrorBoundary, history, page = '' } = props;

    const getRoute = type => {
        let route = '';
        switch (type) {
            case 'Main':
            case 'Router':
            case '':
                route = myConsts.routeHome;
                break;
            default:
                const { location: { pathname = '/' } = {} } = history || {};
                route = pathname;
        }
        return route;
    }

    const getButtonText = type => {
        let route = '';
        switch (type) {
            case 'Main':
            case 'Router':
                route = 'Go to Home';
                break;
            default:
                route = type !== '' ? `Go to ${type}` : 'Refresh';
        }
        return route;
    }

    const onReset = type => {
        resetErrorBoundary();
        const route = type === 'home' ? myConsts.routeHome : getRoute(page)
        history.push(route);
    }

    const buttonText = getButtonText(page);

    return (
        <div className="error-fallback">
            <div
                className="error-image"
            ></div>
            <div className="msg">Sorry, something went wrong.</div>
            <div className="btn-wrapper">
                <div
                    className='btn'
                    onClick={onReset}>
                    {buttonText}
                </div>
                {buttonText !== 'Go to Home' ? <>
                    <div className="msg">or</div>
                    <div
                        className='btn'
                        onClick={() => onReset('home')}>
                        {'Go to Home'}
                    </div>
                </> : null}
            </div>
        </div>
    );
}

export default ErrorFallBack;