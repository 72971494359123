import { makeStyles, withStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SimpleBar from "simplebar-react";
import "./SearchClientTable.scss";
import AppContext from "../../utils/appContext";
import { useContext } from "react";

const StyledTableCell = withStyles((theme) => ({
  head: {
    //   backgroundColor: "#B9B9C2",
    color: "#0F0F10",
    fontFamily: "EYInterState-Regular",
    fontSize: 10,
    //   borderRight: "1px solid #F9F9FB",
    padding: "5px 7px",
    textAlign: "center",
  },
  body: {
    fontFamily: "EYInterState-Regular",
    fontSize: window.matchMedia('(max-width: 1280px)').matches ? 9 : 10,
    //   backgroundColor: "#F9F9FB",
    //   borderRight: "1px solid #B9B9C2",
    color: "#707070",
    padding: "5px 5px",
    textAlign: "center",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
  rowStyle: {
    border: "1px solid #ccc",
  },
  scroll: {
    maxHeight: "35vh",
    overflowX: "hidden",
    // overflowY: 'scroll'
  },
  rootCont: {
    minHeight: "23vh",
  },
  noDataText: {
    textAlign: "center",
    padding: 10,
    fontFamily: "EYInterState-Regular",
    fontSize: 13,
    color: "#2e2e38",
  },
});

const SearchClientTable = ({
  clientList,
  rowCount,
  numSelected,
  isDuplicateFound,
  handleClientChecked,
  handleClientCheckedAll,
  isShowNoRecordsText,
}) => {
  const classes = useStyles();
  const myContext = useContext(AppContext);
  const fy = myContext.financialYear ? `${myContext.financialYear}`.substring(2, 4) : '22';
  return (
    <div className={classes.rootCont}>
      {clientList && clientList.length > 0 && (
        <TableContainer>
          <SimpleBar className={classes.scroll}>
            <Table
              classes={{ root: classes.rowStyle }}
              className={classes.table}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    {rowCount > 0 && numSelected === rowCount ? (
                      <i
                        className="icon-check-checked"
                        onClick={() => handleClientCheckedAll(false)}
                        style={
                          isDuplicateFound
                            ? {
                              pointerEvents: "none",
                              color: "lightgrey",
                              fontSize: 14,
                            }
                            : {
                              color: "#747480",
                              fontSize: 14,
                              cursor: "pointer",
                            }
                        }
                      />
                    ) : (
                      <i
                        className="icon-check-unchecked"
                        onClick={() => handleClientCheckedAll(true)}
                        style={
                          isDuplicateFound
                            ? {
                              pointerEvents: "none",
                              color: "lightgrey",
                              fontSize: 14,
                            }
                            : {
                              color: "#747480",
                              fontSize: 14,
                              cursor: "pointer",
                            }
                        }
                      />
                    )}
                  </StyledTableCell>
                  <StyledTableCell>Client ID</StyledTableCell>
                  <StyledTableCell>Client Name</StyledTableCell>
                  <StyledTableCell>Account Sector</StyledTableCell>
                  <StyledTableCell>Region</StyledTableCell>
                  <StyledTableCell>SEC Indicator</StyledTableCell>
                  <StyledTableCell>{`FY${fy} TER (YTD)`}</StyledTableCell>
                  <StyledTableCell>{`FY${fy - 1} TER (YTD)`}</StyledTableCell>
                  <StyledTableCell>{`FY${fy} Revenue Plan (TER)`}</StyledTableCell>
                  <StyledTableCell>{`FY${fy} Margin $ YTD`}</StyledTableCell>
                  <StyledTableCell>{`FY${fy} Margin % (YTD)`}</StyledTableCell>
                  <StyledTableCell>{`FY${fy - 1} Margin % (YTD)`}</StyledTableCell>
                  <StyledTableCell>{`FY${fy} Client Target Margin %`}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientList &&
                  clientList.map((row, i) => (
                    <StyledTableRow key={row.bobObjId}>
                      <StyledTableCell padding="checkbox">
                        {row.isNewClient ? (
                          <i
                            className="icon-check-checked"
                            onClick={() => handleClientChecked(i, false)}
                            style={
                              row.isDuplicate
                                ? {
                                  pointerEvents: "none",
                                  color: "lightgrey",
                                  fontSize: 14,
                                }
                                : {
                                  color: "#747480",
                                  fontSize: 14,
                                  cursor: "pointer",
                                }
                            }
                          />
                        ) : (
                          <i
                            className="icon-check-unchecked"
                            onClick={() => handleClientChecked(i, true)}
                            style={
                              row.isDuplicate
                                ? {
                                  pointerEvents: "none",
                                  color: "lightgrey",
                                  fontSize: 14,
                                }
                                : {
                                  color: "#747480",
                                  fontSize: 14,
                                  cursor: "pointer",
                                }
                            }
                          />
                        )}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.clientId}
                      </StyledTableCell>
                      <StyledTableCell>{row.client}</StyledTableCell>
                      <StyledTableCell>{row.accountSector}</StyledTableCell>
                      <StyledTableCell>{row.engagementRegion}</StyledTableCell>
                      <StyledTableCell>{row.secInd}</StyledTableCell>
                      <StyledTableCell>
                        {row.currentTER && row.currentTER < 0
                          ? "$ (" +
                          Math.abs(row.currentTER).toLocaleString() +
                          ")"
                          : row.currentTER === null
                            ? "N/A"
                            : "$ " + row.currentTER.toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.priorTER && row.priorTER < 0
                          ? "$ (" +
                          Math.abs(row.priorTER).toLocaleString() +
                          ")"
                          : row.priorTER === null
                            ? "N/A"
                            : "$ " + row.priorTER.toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.revenueGoalIn && row.revenueGoalIn < 0
                          ? "$ (" +
                          Math.abs(row.revenueGoalIn).toLocaleString() +
                          ")"
                          : row.revenueGoalIn === null
                            ? "N/A"
                            : "$ " + row.revenueGoalIn.toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.marginIn && row.marginIn < 0
                          ? "$ (" +
                          Math.abs(row.marginIn).toLocaleString() +
                          ")"
                          : row.marginIn === null
                            ? "N/A"
                            : "$ " + row.marginIn.toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color:
                            row.regionMarginYTD > 0
                              ? row.curentMarginYTD > row.regionMarginYTD
                                ? "#2DB757"
                                : "#E21E1E"
                              : "#707070",
                        }}
                      >
                        {row.curentMarginYTD !== null
                          ? row.curentMarginYTD + "%"
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.priorMarginYTD !== null
                          ? row.priorMarginYTD + "%"
                          : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.regionMarginYTD !== null
                          ? row.regionMarginYTD + "%"
                          : "N/A"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </SimpleBar>
        </TableContainer>
      )}
      {!clientList.length && isShowNoRecordsText && (
        <div className={classes.noDataText}>No Records Found</div>
      )}
    </div>
  );
};

export default SearchClientTable;
