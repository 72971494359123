//import { color } from "d3-color";
import React from "react";
import { Quill } from "react-quill";
import { useDispatch, useSelector } from "react-redux";

// Custom Undo button icon component for Quill editor. You can import it directly
// from 'quill/assets/icons/undo.svg' but I found that a number of loaders do not
// handle them correctly

const CustomAddNote = (props) => {
  const dispatch = useDispatch();
  return (
    <div
      style={{
        border: "1px solid #C4C4CD",
        backgroundColor: "#F6F6FA",
        padding: "2px",
        display: "flex",
        alignItems: "center",
        cursor: `${props.length && props.length < 3 ? "pointer" : "auto"}`,
        opacity: `${props.length && props.length < 3 ? 1 : 0.5}`,
      }}
      onClick={() => {
        if (props.length && props.length < 3) {
          props.addNote();
          dispatch({
            type: "OUTSIDE_CLICK_COMMON",
            payload: {
              feature: "AssessmentAdmin",
              changesDone: true,
              OpenModal: "",
              ProceedWithNavigation: "",
            },
          });
        }
      }}>
      <i
        className="eyaa-file-06"
        style={{ marginRight: "5x", color: "grey", fontSize: "15px" }}
      />
    </div>
  );
};

// Undo and redo functions for Custom Toolbar
function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

// Add sizes to whitelist and register them
const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

// Modules object for setting up the Quill editor
export const modules0 = {
  toolbar: {
    container: "#toolbar0",
    // handlers: {
    //   undo: undoChange,
    //   redo: redoChange,
    // },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};
export const modules1 = {
  toolbar: {
    container: "#toolbar1",
    // handlers: {
    //   undo: undoChange,
    //   redo: redoChange,
    // },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};
export const modules2 = {
  toolbar: {
    container: "#toolbar2",
    // handlers: {
    //   undo: undoChange,
    //   redo: redoChange,
    // },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

// Formats objects for setting up the Quill editor
export const formats = [
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];

// Quill Toolbar component
export const QuillToolbar = (props) => (
  <div
    id={"toolbar" + props.index}
    style={{
      // display: "flex",
      justifyContent: "center",
      position: "relative",
      alignItems: "center",
      // opacity: " 0.8",
      display: `${props.display ? "flex" : "none"}`,
    }}>
    {!props.hideLabel && <span
      className="ql-formats"
      style={{ position: "absolute", left: "10px", fontSize: "11px" }}>
      Notes
    </span>}
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      {/* </span>

    
    <span className="ql-formats"> */}
      <select className="ql-color" style={{ top: "12.5px" }} />
      {/* </span>
    <span className="ql-formats"> */}
      <button className="ql-link" />
    </span>

    <span className="ql-formats" style={{ position: "absolute", right: "0px" }}>
      <button className="ql-undo" disabled={props.length && props.length >= 3}>
        <CustomAddNote length={props.length} addNote={props.addNote} />
      </button>
    </span>
  </div>
);

export default QuillToolbar;
