import React, { Fragment, useContext, useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import QuestionComponent from "./QuestionComponent";
import Goal from "./Goal";
import Overview from "./images/Overview.svg";
import { useDispatch, useSelector } from "react-redux";
import AppContext from "../../utils/appContext";
import { authProvider, APIscope } from "./../../Authentication/authProvider";
import Loader from "../Loader/Loader";
import ReactHtmlParser from "react-html-parser";

const QualitySection = React.memo((props) => {
  const {
    changeSelectedData,
    currentObj,
    goToNext,
    goToPrev,
    activeTab,
    currentUserObj,
    setActiveTab,
    checkOutsideClick,
    activePeriod,
    currentPeriod,
    selectedSection,
    scrollableNodeRef,
    checkIfLastTab,
    totalProgress,
    readOnly,
    isPlanDataNull,
    goalObj,
    handleImpactStmtFocusChecked,
    impactStmtFocusChecked
  } = props;

  const appContext = useContext(AppContext);
  const dispatch = useDispatch();
  const myContext = useContext(AppContext);
  const [tabObj, setTabObj] = useState([]);
  const [usrObj, setUsrObj] = useState([]);
  const [tabIndex, setTabIndex] = useState(1);
  const [activeTabId, setActiveTabId] = useState("");
  const financialYear = appContext.financialYear;
  const [activeUIType, setActiveUIType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getAssessmentOptionsAPI = useSelector((state) => state.getOptionValues);
  const [assesmentOptions, setAssessmentOptions] = useState([]);
  const [assesmentOptionsLabel, setAssessmentOptionsLabel] = useState([]);
  const [hidePrevNavBtn, setHidePrevNavBtn] = useState(false);
  let activePPMDuserId =
    myContext.activePPMDuserId || sessionStorage.activePPMDuserId;

  useEffect(() => {
    if (
      getAssessmentOptionsAPI &&
      !getAssessmentOptionsAPI.fetching &&
      getAssessmentOptionsAPI.data &&
      getAssessmentOptionsAPI.data.options &&
      getAssessmentOptionsAPI.data.options.length > 0
    ) {
      setAssessmentOptions(getAssessmentOptionsAPI.data.options);
      setAssessmentOptionsLabel(getAssessmentOptionsAPI.data.checkBoxLabel);
      setIsLoading(false);
    }
  }, [getAssessmentOptionsAPI]);
  useEffect(() => {
    if (props.preview) {
      if (
        (activeTab && currentObj && currentObj.section.length > 0) ||
        (currentObj && currentObj.section.length === 0 && activeTab === 0) ||
        (currentObj && currentObj.section.length === 0 && activeTab === "")
      ) {
        const temp = JSON.parse(JSON.stringify(currentObj));
        const tempInd = temp.section.findIndex((x) => x.goalId === activeTab);
        setActiveTabId(tempInd != -1 ? temp.section[tempInd].goalId : "");
        if (tempInd == -1) {
          setIsLoading(false);
        }
        if (
          (props.assessmentObj.length > 0 &&
            props.assessmentObj[0] &&
            props.assessmentObj[0].section.length == 0 &&
            temp.pillarId === props.assessmentObj[0].pillarId) || //empty goalList
          (props.assessmentObj.length > 0 &&
            props.assessmentObj[0] &&
            props.assessmentObj[0].section.length > 0 &&
            tempInd !== -1 &&
            props.assessmentObj[0].section[0].goalName ==
            temp.section[tempInd].goalName &&
            temp.pillarId === props.assessmentObj[0].pillarId) ||
          (props.singleView && temp.section && temp.section.length == 0) ||
          (props.singleView &&
            temp.section &&
            temp.section.length > 0 &&
            temp.section[tempInd].goalName == goalObj.goalName)
        )
          setHidePrevNavBtn(true);
        else setHidePrevNavBtn(false);
        setActiveUIType(tempInd != -1 ? temp.section[tempInd].uiType : "");
        const tempTabObj = temp.section.find((x) => x.goalId === activeTab);
        setTabObj(tempTabObj || []);
        if (currentUserObj) {
          const tempUsrObj = currentUserObj.filter(
            (x) => x.goalId === activeTab
          );
          setUsrObj(tempUsrObj);
        }
        setTabIndex(tempInd + 1);
      }
    } else {
      if (activeTab && currentObj) {
        const temp = JSON.parse(JSON.stringify(currentObj));
        const tempInd = temp.section.findIndex((x) => x.goalId === activeTab);
        setActiveTabId(temp.section[tempInd].goalId);
        if (
          temp.section[tempInd].goalName === "Risk identification" &&
          !hidePrevNavBtn
        ) {
          if (activePPMDuserId && activePPMDuserId !== null)
            setHidePrevNavBtn(false);
          else setHidePrevNavBtn(true);
        } else {
          setHidePrevNavBtn(false);
        }
        setActiveUIType(temp.section[tempInd].uiType);
        const tempTabObj = temp.section.find((x) => x.goalId === activeTab);
        setTabObj(tempTabObj || []);
        if (currentUserObj) {
          const tempUsrObj = currentUserObj.filter(
            (x) => x.goalId === activeTab
          );
          setUsrObj(tempUsrObj);
        }
        setTabIndex(tempInd + 1);
      }
    }
    // eslint-disable-next-line
  }, [activeTab, currentObj, currentUserObj]);

  useEffect(() => {
    (async function () {
      try {
        if (
          activePeriod &&
          activePeriod !== "" &&
          currentPeriod &&
          currentPeriod !== "" &&
          activeTabId &&
          activeTabId !== "" &&
          activeUIType &&
          activeUIType !== "" &&
          financialYear &&
          financialYear !== ""
        ) {
          setIsLoading(true);
          const token = await authProvider.acquireTokenSilent({
            scopes: APIscope,
          });
          const idToken = token.accessToken;
          fetchassessmentOptions(idToken);
        }

        // setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line
  }, [activeTabId, props.location]);

  const fetchassessmentOptions = (idToken) => {
    if (
      activePeriod &&
      activePeriod !== "" &&
      currentPeriod &&
      currentPeriod !== "" &&
      activeTabId &&
      activeTabId !== "" &&
      activeUIType &&
      activeUIType !== "" &&
      financialYear &&
      financialYear !== ""
    )
      dispatch({
        type: "GET_OPTION_VALUES",
        payload: {
          activePeriod: currentPeriod,
          goalId: activeTabId,
          uiType: activeUIType,
          financialYear: props.preview ? props.fyYear : financialYear,
          tokens: idToken,
        },
      });
  };

  const [isExpand, setExpansionOverview] = useState(false);

  return (
    <>
      {isLoading && <Loader />}
      <Box className="quality-section">
        {readOnly ? (
          <>
            {props.preview && (
              <>
                <div
                  className="start-section"
                  style={!isExpand ? {} : { paddingBottom: 0 }}>
                  <div
                    className="title-expand-collapse"
                    style={isExpand ? { padding: "20px 40px" } : {}}>
                    <div className="txt-header">Goals</div>
                    {!isExpand ? (
                      <i
                        className="icon-minus"
                        onClick={() => setExpansionOverview(!isExpand)}></i>
                    ) : (
                      <i
                        className="icon-plus"
                        onClick={() => setExpansionOverview(!isExpand)}></i>
                    )}
                  </div>
                  {!isExpand && (
                    <>
                      <div className="overview-text">
                        {currentObj && currentObj.pillarOverview}
                      </div>
                      <div className="sec-btm">
                        <div className="txt-content">
                          <span style={{ fontSize: "13px" }}>
                            {currentObj &&
                              currentObj.section.map((eachTab, key) => (
                                <p key={key.toString() + "pco"}>
                                  <b
                                    className="tab-name"
                                    onClick={() => {
                                      let current = myContext.currentGoal;
                                      current.goalId = eachTab.goalId;
                                      myContext.setCurrentGoal({ ...current });
                                      checkOutsideClick(
                                        setActiveTab,
                                        eachTab.goalId
                                      );
                                    }}>
                                    {eachTab.goalName}
                                  </b>
                                  {eachTab.goalDescription}
                                </p>
                              ))}
                          </span>
                          {currentObj &&
                            currentObj.pillarNotes &&
                            currentObj.pillarNotes.map((note, i) => (
                              <div className="notes" key={i + "notes"}>
                                {ReactHtmlParser(note)}
                              </div>
                            ))}
                        </div>
                        <img src={Overview} alt="" />
                      </div>
                    </>
                  )}
                </div>
                {currentObj &&
                  currentObj.section &&
                  currentObj.section.length > 0 && (
                    <Goal
                      tabObj={tabObj}
                      totalTabs={currentObj.section.length}
                      selectedTab={activeTab}
                      tabIndex={tabIndex}
                      selectedSection={selectedSection}
                    />
                  )}
              </>
            )}

            <QuestionComponent
              history={props.history}
              tabObj={tabObj}
              usrObj={usrObj}
              assesmentOptions={assesmentOptions}
              assesmentOptionsLabel={assesmentOptionsLabel}
              uiType={activeUIType}
              goToNext={goToNext}
              goToPrev={goToPrev}
              hidePrevNavBtn={hidePrevNavBtn}
              currentObj={currentObj}
              selectedTab={activeTab}
              activePeriod={activePeriod}
              currentPeriod={currentPeriod}
              selectedSection={selectedSection}
              checkOutsideClick={checkOutsideClick}
              changeSelectedData={changeSelectedData}
              scrollableNodeRef={scrollableNodeRef}
              checkIfLastTab={checkIfLastTab}
              totalProgress={totalProgress}
              readOnly={props.readOnly}
              preview={props.preview}
              isPlanDataNull={isPlanDataNull}
              handleImpactStmtFocusChecked={handleImpactStmtFocusChecked}
              impactStmtFocusChecked={impactStmtFocusChecked}
            />
          </>
        ) : (
          <>
            <div
              className="start-section"
              style={!isExpand ? {} : { paddingBottom: 0 }}>
              <div
                className="title-expand-collapse"
                style={isExpand ? { padding: "20px 40px" } : {}}>
                <div className="txt-header">Goals</div>
                {!isExpand ? (
                  <i
                    className="icon-minus"
                    onClick={() => setExpansionOverview(!isExpand)}></i>
                ) : (
                  <i
                    className="icon-plus"
                    onClick={() => setExpansionOverview(!isExpand)}></i>
                )}
              </div>
              {!isExpand && (
                <>
                  <div className="overview-text">
                    {currentObj && currentObj.pillarOverview}
                  </div>
                  <div className="sec-btm">
                    <div className="txt-content">
                      <span style={{ fontSize: "13px" }}>
                        {currentObj &&
                          currentObj.section.map((eachTab, key) => (
                            <p key={key.toString() + "pco"}>
                              <b
                                className="tab-name"
                                onClick={() => {
                                  let current = myContext.currentGoal;
                                  current.goalId = eachTab.goalId;
                                  myContext.setCurrentGoal({ ...current });
                                  checkOutsideClick(
                                    setActiveTab,
                                    eachTab.goalId
                                  );
                                }}>
                                {eachTab.goalName}
                              </b>
                              {eachTab.goalDescription}
                            </p>
                          ))}
                      </span>
                      {currentObj &&
                        currentObj.pillarNotes &&
                        currentObj.pillarNotes.map((note, i) => (
                          <div className="notes" key={i + "notes"}>
                            {ReactHtmlParser(note)}
                          </div>
                        ))}
                    </div>
                    <img src={Overview} alt="" />
                  </div>
                </>
              )}
            </div>
            {currentObj && (
              <Goal
                tabObj={tabObj}
                totalTabs={currentObj.section.length}
                selectedTab={activeTab}
                tabIndex={tabIndex}
                selectedSection={selectedSection}
              />
            )}
            <QuestionComponent
              history={props.history}
              tabObj={tabObj}
              usrObj={usrObj}
              assesmentOptions={assesmentOptions}
              assesmentOptionsLabel={assesmentOptionsLabel}
              uiType={activeUIType}
              goToNext={goToNext}
              goToPrev={goToPrev}
              hidePrevNavBtn={hidePrevNavBtn}
              currentObj={currentObj}
              selectedTab={activeTab}
              activePeriod={activePeriod}
              currentPeriod={currentPeriod}
              selectedSection={selectedSection}
              checkOutsideClick={checkOutsideClick}
              changeSelectedData={changeSelectedData}
              scrollableNodeRef={scrollableNodeRef}
              checkIfLastTab={checkIfLastTab}
              totalProgress={totalProgress}
              readOnly={false}
              preview={false}
              isPlanDataNull={isPlanDataNull}
              handleImpactStmtFocusChecked={handleImpactStmtFocusChecked}
              impactStmtFocusChecked={impactStmtFocusChecked}
            />
          </>
        )}
      </Box>
    </>
  );
});

export default QualitySection;
