import React, { Fragment, useState, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill'
import getPlaceholderModule from 'quill-placeholder-module'
import SimpleBar from "simplebar-react";
import "./Quill.scss";
import { modules } from './constant';
import QuillToolbar from './QuillToolbar';

const Block = Quill.import('blots/block');
Block.tagName = 'div';
Quill.register(Block);

Quill.register('modules/placeholder', getPlaceholderModule(Quill));

const QuillEditor = (props) => {
  const { text, placeholders = {}, saveClicked, onSave, onChange, maxHeight = '', readOnly = false } = props;
  const [value, setValue] = useState('')

  const isQuillEmpty = (quill) => {
    if ((quill.getContents()['ops'] || []).length !== 1) { return false }
    return quill.getText().trim().length === 0
  }

  const handleChange = (quillValue, delta, source, editor) => {
    const isEmpty = isQuillEmpty(editor) || false;
      let plainText=quillValue.replaceAll('&lt;', '{{').replaceAll('&gt;', '}}').replace(/({{([^>]+)}})/ig,'R').replace(/(<([^>]+)>)/ig,'');
    // console.log(plainText,plainText.length)
      if (plainText.length<= 5000) {
      if (!quillValue.includes("<img")) {
        let formattedText = quillValue;
        setValue(formattedText);
        onChange(formattedText, isEmpty);
      } else {
        setValue(value);
        onChange(value, isEmpty);
      }
    } else {
      setValue(value);
      onChange("", isEmpty);
      onChange(value, isEmpty);
    }
  }

  useEffect(() => {
    const formattedText = text.replaceAll('{{', '&lt;').replaceAll('}}', '&gt;');
    setValue(formattedText);
  }, [text]);

  useEffect(() => {
    if (saveClicked !== 0) {
      const formattedText = value.replaceAll('&lt;', '{{').replaceAll('&gt;', '}}');
      onSave(formattedText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveClicked]);

  return (
    <Fragment>
      <QuillToolbar dynamicPlaceholders={placeholders} readOnly={readOnly} />
      <div className='quill-container' style={{ height: readOnly ? '95%' : '86%', border: '1px solid #ccc' }}>
        <SimpleBar style={{ maxHeight: `${maxHeight ? maxHeight : (readOnly ? '300px' : "250px")}` }}>
          <ReactQuill
            readOnly={readOnly}
            value={value}
            modules={modules}
            onChange={handleChange}
            placeholder="Add email template here."
            bounds='.quill-container'
          />
        </SimpleBar>
      </div>
    </Fragment>
  );
};

export default QuillEditor;
