import React from "react";
import classNames from "classnames";
import "./BarChart.scss";

/* This is simple bar chart component used in the dashboard page. Props details explained below.
rank - Percentage to be filled.
fillStart - can fill the bar from either top or bottom
width - bar width
height - bar heigth
*/

function BarChart(props) {
  const { rank, fillStart, width, height } = props;
  return (
    <div className="bar-chart" style={{ width: width+'px', height: height+'px' }}>
      <div
        className={classNames("bar-chart-fill", {
          "bar-chart-top-fill": fillStart === "top",
          "bar-chart-bottom-fill": fillStart === "bottom",
        })}
        style={{ height: rank + "%"}}
      ></div>
      <div className="marker">
          <span>0</span>
      </div>
    </div>
  );
}

export default BarChart;
