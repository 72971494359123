import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import SimpleBar from "simplebar-react";
import EmailListing from './EmailListing';
import EmailTemplate from './EmailTemplate';
import EmailPreview from '../EmailEvents/EmailPreview';
import Loader from '../../../Loader/Loader';
import { tableHeaders } from '../constants';
import { CANCEL_MAIL_REQUEST, CANCEL_MAIL_REQUEST_RESET, GET_LIST_MAILS, SEND_ADHOC_MAIL, SEND_ADHOC_MAIL_RESET } from '../../../../actions/types';
import { Flip, toast } from 'react-toastify';


const AdhocEmails = (props) => {
    const { setIsChangesInChild, onCancelCheck } = props;
    const dispatch = useDispatch();
    const getAdhocMailList = useSelector((state) => (state.getAdhocMailList)) || {};
    const cancelAdhocMailRequest = useSelector((state) => (state.cancelAdhocMailRequest)) || {};
    const createAdhocMailRequest = useSelector((state) => (state.createAdhocMailRequest)) || {};
    const { data: adhocMailList = {} } = getAdhocMailList;
    const { data: emailList = [] } = adhocMailList;
    const [view, setView] = useState('adhoc-email-list');
    const [previewContent, setPreviewContent] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [popupType, setPopupType] = useState(0);
    const [data, setData] = useState({});

    const showToastMessage = type => {
        let message = ''
        switch (type) {
            case 'cancel':
                message = 'Ad-hoc email cancelled successfully'
                break;
            case 'sent':
                message = 'Ad-hoc email sent successfully'
                break;
            case 'schedule':
                message = 'Ad-hoc email scheduled successfully'
                break;
            default:
                break;
        }
        toast(
            <div className="success-text">
                <span className="icon-verified-outline icon"></span>
                <p>
                    <span>{message}</span>
                </p>
            </div>,
            {
                position: "top-center",
                transition: Flip,
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        );
    }

    useEffect(() => {
        if (view === 'adhoc-email-list')
            dispatch({
                type: GET_LIST_MAILS,
                payload: {
                    start: 0,
                    limit: 1000
                },
            });
    }, []);

    useEffect(() => {
        console.log(view)
    }, [view]);

    useEffect(() => {
        if (
            getAdhocMailList &&
            getAdhocMailList.data &&
            !getAdhocMailList.fetching
        ) {
            setLoader(false);
        } else if (
            getAdhocMailList &&
            getAdhocMailList.data &&
            getAdhocMailList.fetching
        ) {
            setLoader(true);
        }
    }, [getAdhocMailList]);

    useEffect(() => {
        if (
            cancelAdhocMailRequest &&
            cancelAdhocMailRequest.data &&
            !cancelAdhocMailRequest.fetching &&
            cancelAdhocMailRequest.data.status &&
            cancelAdhocMailRequest.data.status === "success"
        ) {
            showToastMessage('cancel');
            setLoader(false);
            dispatch({
                type: CANCEL_MAIL_REQUEST_RESET,
                payload: {},
            });
        } else if (
            cancelAdhocMailRequest &&
            cancelAdhocMailRequest.data &&
            cancelAdhocMailRequest.fetching
        ) {
            setLoader(true);
        }
    }, [cancelAdhocMailRequest, dispatch]);

    useEffect(() => {
        if (
            createAdhocMailRequest &&
            createAdhocMailRequest.data &&
            !createAdhocMailRequest.fetching &&
            createAdhocMailRequest.data.status &&
            createAdhocMailRequest.data.status === "success"
        ) {
            if (popupType === 3)
                showToastMessage('schedule');
            else if (popupType === 4)
                showToastMessage('sent');
            setLoader(false);
            dispatch({
                type: SEND_ADHOC_MAIL_RESET,
                payload: {},
            });
        } else if (
            createAdhocMailRequest &&
            createAdhocMailRequest.data &&
            createAdhocMailRequest.fetching
        ) {
            setLoader(true);
        }
    }, [createAdhocMailRequest, dispatch]);

    const onPopupConfirm = () => {
        setIsPopupVisible(false);
        setPreviewContent('');
        setView('adhoc-email-list');
        if (popupType === 2) {
            dispatch({
                type: CANCEL_MAIL_REQUEST,
                payload: data,
            });
        } else if (popupType === 3 || popupType === 4) {
            const { sendList, subject, content, sendBy = '', dateTime } = data;
            const sendAt = popupType === 4 ? 'now' : dateTime;
            const payload = { sendList, subject, content, sendBy, sendAt }
            dispatch({
                type: SEND_ADHOC_MAIL,
                payload
            });
        }
    };

    return (
        <>
            {loader && <Loader />}
            <div className='adhoc-emails'>
                {view === 'adhoc-email-list' ?
                    <EmailListing
                        setView={setView}
                        tableHeaders={tableHeaders}
                        emailList={emailList}
                        onCancel={(data) => {
                            setPopupType(2);
                            setIsPopupVisible(true);
                            setData(data);
                        }}
                    /> : <EmailTemplate
                        setPreviewContent={setPreviewContent}
                        setOpen={setIsOpen}
                        setView={setView}
                        onSend={(data, type) => {
                            setPopupType(type);
                            setIsPopupVisible(true);
                            setData(data);
                        }}
                        setIsChangesInChild={setIsChangesInChild}
                        onCancelCheck={onCancelCheck}
                    />}
            </div>
            <div className='email-template-modal'>
                <Modal
                    open={isOpen}
                    onClose={() => {
                        setIsOpen(false);
                    }}
                    styles={{
                        modal: {
                            maxWidth: "unset",
                            padding: "unset",
                        },
                        overlay: {
                            background: "rgba(0, 0, 0, 0.5)",
                        }
                    }}
                    center
                >
                    <div className="email-template-wrap">
                        <div className="modal-heading" >
                            Email Preview
                        </div>
                        <SimpleBar
                            style={{
                                overflowX: "hidden",
                                maxHeight: "80vh",
                                width: '70vw',
                            }}
                        >
                            <div style={{ minHeight: "80vh", padding: '10px' }}>
                                <EmailPreview content={previewContent} />
                            </div>
                        </SimpleBar>
                    </div>
                </Modal>
                {isPopupVisible && (
                    <div className="popup-dialog">
                        <div className="dialog" style={{ height: "180px" }}>
                            <div className="header">
                                {popupType === 1 && <h5>Discard Changes</h5>}
                                {(popupType === 2 || popupType === 3 || popupType === 4) && <h5>Confirmation</h5>}
                                <span
                                    className="icon-close-filled icon"
                                    onClick={() => setIsPopupVisible(false)}
                                ></span>
                            </div>
                            {popupType === 1 && (
                                <div className="content">
                                    <p>Would you like to discard changes?</p>
                                    <p>Please note that on confirming, unsaved changes will be lost.</p>
                                </div>
                            )}
                            {popupType === 2 && (
                                <div className="content">
                                    <p>Would you like to recall the scheduled Email ?</p>
                                    <p>Please note that this will cancel the Email.</p>
                                </div>
                            )}
                            {popupType === 3 && (
                                <div className="content">
                                    <p>Would you like to send the Email on the scheduled time ?</p>
                                    <p>Please note that you will be able to cancel the Email before atleast 15 minutes from the scheduled time.</p>
                                </div>
                            )}
                            {popupType === 4 && (
                                <div className="content">
                                    <p>Would you like to send the Email now?</p>
                                    <p> Please note that you will not be able to cancel the Email once sent.</p>
                                </div>
                            )}
                            <div className="footer">
                                <button
                                    className="cancel-btn"
                                    onClick={() => setIsPopupVisible(false)}
                                >
                                    Cancel
                                </button>
                                <button className="confirm-btn" onClick={onPopupConfirm}>
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default AdhocEmails
