import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import _ from "lodash";
import DropdownComponent from "../../AdminGeneralParam/DropdownComponent/DropdownComponent";
import TextField from "@material-ui/core/TextField";
import { Box } from "@material-ui/core";
import Popper from "@material-ui/core/Popper";
import FormHelperText from "@material-ui/core/FormHelperText";

// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import "./AdminPopup.scss";
import { getErrorMessages, isNumeric } from "../../../../utils/utils";
const useStyles = makeStyles((theme) => ({
  helper: {
    color: "red",
  },
  root: {
    "& .MuiFilledInput-underline.Mui-error:after": {
      borderBottom: "1px solid #f44336",
    },
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "556px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  labelroot: {
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "8px",
  },
  topSpacing: {
    marginTop: "16px",
  },
  topSyli: {
    marginBottom: " -30px",
    marginTop: "16px",
    zIndex: "8",
    width: "120px",
  },
  half: {
    display: "flex",
    flexDirection: "row",
  },
  popper: {
    zIndex: "3000",
    '&[x-placement*="bottom-start"]': {
      top: "9px!important",
      left: "-248px!important",
    },
  },
}));
const AdminPopup = (props) => {
  const classes = useStyles();
  const [values, setValues] = useState({
    _id: '',
    sectionName: "General",
    radioButtonMultiplierScore: "",
    checkBoxMultiplierScore: "",
    dropDownMultiplierScore: "",
  });;

  const [errorMessages, setErrorMessages] = useState({
    sectionName: "",
    radioButtonMultiplierScore: "",
    checkBoxMultiplierScore: "",
    dropDownMultiplierScore: "",
  });

  const textOnChange = (value, label) => {
    props.isChangeDone(true)
    setValues({ ...values, [label]: value });
  };
  useEffect(() => {
    props.passValues(values, errorMessages);

    // eslint-disable-next-line
  }, [values, errorMessages]);
  useEffect(() => {
    props.isChangeDone(false);
    if (props.data) {
      setValues({
        _id: props.data._id,
        radioButtonMultiplierScore: props.data && isNumeric(props.data.radio_button) ? "x" + Number.parseInt(props.data.radio_button) : '',
        checkBoxMultiplierScore: props.data && isNumeric(props.data.check_box) ? "x" + Number.parseInt(props.data.check_box) : '',
        dropDownMultiplierScore: props.data && isNumeric(props.data.drop_down) ? "x" + Number.parseInt(props.data.drop_down) : ''
      });
    }
    // eslint-disable-next-line
  }, [props.data]);

  useEffect(() => {
    const { radioButtonMultiplierScore, checkBoxMultiplierScore, dropDownMultiplierScore, _id } = values;
    if (_id !== '') {
      const obj = { radioButtonMultiplierScore, checkBoxMultiplierScore, dropDownMultiplierScore, }
      let localErrorMessage = getErrorMessages(obj, ['radioButtonMultiplierScore', 'checkBoxMultiplierScore', 'dropDownMultiplierScore']);
      setErrorMessages((prevState) => ({
        ...prevState,
        ...localErrorMessage
      }));
    }
  }, [values]);
  return (
    <div className="add-new-section">
      <InputLabel
        htmlFor="uncontrolled-native"
        className={"leftSpacing"}
        classes={{
          root: classes.labelroot,
        }}>
        {"Section Name*"}
      </InputLabel>
      <TextField
        id="filled-basic"
        variant="filled"
        className={classes.root}
        required={true}
        disabled
        value={values.sectionName}
        onChange={(e) => textOnChange(e.target.value, "sectionName")}
        error={errorMessages && errorMessages.sectionName !== ""}
        helperText={
          errorMessages && errorMessages.sectionName !== ""
            ? errorMessages.sectionName
            : ""
        }
      />
      <div>
        <div className={classes.half}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputLabel
              htmlFor="uncontrolled-native"
              className={classes.topSyli}
              classes={{
                root: classes.labelroot,
              }}>
              {"Multipler Score - Radio Button*"}
            </InputLabel>
            <DropdownComponent
              label=""
              defaultValue={values.radioButtonMultiplierScore}
              required={false}
              selectOnChange={
                (dummy, value) => {
                  textOnChange(value, "radioButtonMultiplierScore");
                } /* selectOnChange */
              }
              varName="radioButtonMultiplierScore"
              list={["", "x1", "x2", "x3", "x4", "x5", "x6", "x7", "x8", "x9", "x10"]}
              errorMessage={errorMessages}
              disabled={false}
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputLabel
              htmlFor="uncontrolled-native"
              className={classes.topSyli}
              classes={{
                root: classes.labelroot,
              }}>
              {"Multipler Score - Check Box*"}
            </InputLabel>
            <DropdownComponent
              label=""
              defaultValue={values.checkBoxMultiplierScore}
              required={false}
              selectOnChange={
                (dummy, value) => {
                  textOnChange(value, "checkBoxMultiplierScore");
                } /* selectOnChange */
              }
              varName="checkBoxMultiplierScore"
              list={["", "x1", "x2", "x3", "x4", "x5", "x6", "x7", "x8", "x9", "x10"]}
              errorMessage={errorMessages}
              disabled={false}
            />
          </div>
        </div>
      </div>
      <div>
        <div className={classes.half}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <InputLabel
              htmlFor="uncontrolled-native"
              className={classes.topSyli}
              classes={{
                root: classes.labelroot,
              }}>
              {"Multipler Score - Drop Down*"}
            </InputLabel>
            <DropdownComponent
              label=""
              defaultValue={values.dropDownMultiplierScore}
              required={false}
              selectOnChange={
                (dummy, value) => {
                  textOnChange(value, "dropDownMultiplierScore");
                } /* selectOnChange */
              }
              varName="dropDownMultiplierScore"
              list={["", "x1", "x2", "x3", "x4", "x5", "x6", "x7", "x8", "x9", "x10"]}
              errorMessage={errorMessages}
              disabled={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(AdminPopup);
