import _ from "lodash";

export const roles = ["Admin", "PPMD", "Lead Reviewer"];

export const formData = {
  gpn: {
    value: "",
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  lpn: {
    value: "",
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  gui: {
    value: "",
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  rankCode: {
    value: "Select",
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  buNumber: {
    value: "Select",
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  smuCode: {
    value: "Select",
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  serviceLine: {
    value: "Select",
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  subServiceLine: {
    value: "Select",
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  country: {
    value: "Select",
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  region: {
    value: "Select",
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  locationCity: {
    value: "Select",
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  tenorStartDate: {
    value: null,
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  counselorName: {
    value: "",
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  counselorEmail: {
    value: "",
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  counselorGUI: {
    value: "",
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  role: {
    value: [],
    touched: false,
    error: false,
    errorText: "",
    isRequired: true,
  },
  primarySector: {
    value: "Select",
    touched: false,
    error: false,
    errorText: "",
    isRequired: false,
  },
  secondarySectorSpecialization: {
    value: "Select",
    touched: false,
    error: false,
    errorText: "",
    isRequired: false,
  },
  additionalResponsibility: {
    value: [],
    touched: false,
    error: false,
    errorText: "",
    isRequired: false,
  },
};

export const getRegions = (data) => {
  const groupRegions = _.groupBy(data, "region");
  let regions = [];
  Object.keys(groupRegions).forEach((key) => {
    regions.push(key);
  });
  return regions;
};

export const getLocations = (data, region) => {
  const locations = _.groupBy(data, "region");
  if (locations[region] && locations[region].length > 1) {
    return locations[region];
  } else {
    return data.filter((data) => data.location !== "");
  }
};

export const getServiceLines = (data) => {
  const groupServiceLines = _.groupBy(data, "serviceLine");
  let serviceLines = [];
  Object.keys(groupServiceLines).forEach((key) => {
    serviceLines.push(key);
  });
  return serviceLines;
};

export const getSubServiceLines = (data, serviceLine) => {
  const serviceLines = _.groupBy(data, "serviceLine");
  if (serviceLines[serviceLine] && serviceLines[serviceLine].length > 1) {
    return serviceLines[serviceLine];
  } else {
    return data.filter((data) => data.subServiceLine !== "");
  }
};

export const getRankCodes = (rankData) => {
  return _.chain(rankData)
    .filter((rank) => rank.rankCode !== "-")
    .map((rank) => _.toNumber(rank.rankCode))
    .uniqBy()
    .sortBy()
    .value();
};

export const getBUNumber = (data) => {
  const groupBUNumbers = _.groupBy(data, "buNumber");
  let buNumbers = [];
  Object.keys(groupBUNumbers).forEach((key) => {
    buNumbers.push(key);
  });
  return buNumbers;
};

export const getSMUCodes = (data) => {
  const groupSMUCodes = _.groupBy(data, "smuCode");
  let smuCodes = [];
  Object.keys(groupSMUCodes).forEach((key) => {
    smuCodes.push(key);
  });
  return smuCodes;
};