import React, { useState } from "react";
import { Modal, TextField, Checkbox } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import "./DelegatesPopup.scss";
import Autocompletee from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import AddIcon from "@material-ui/icons/Add";

const CssTextField = withStyles({
  root: {
    margin: "5px 0",
    borderRadius: "0",
    fontFamily: "EYInterState-regular",
    boxShadow: "none",
    width: "100%",
    "& label.MuiFormLabel-root": {},
    "& label.Mui-focused": {
      color: "#2E2E38",
      // background: "#FFF",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -5px) scale(0.75)",
      // fontSize: "12px",
    },
    "& .MuiInput-underline:after": {
      border: "0",
      borderRadius: "0",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "0.14rem solid rgba(0, 0, 0, 0.23)",
        borderRadius: "0",
      },
      "&:hover fieldset": {
        borderColor: "#747480",
        boxShadow: "none",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#747480",
      },
    },
    "& p.MuiFormHelperText-root.Mui-error": {
      position: "relative",
      bottom: "12px",
      left: "2px",
      margin: "0px",
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    boxShadow: "none",
    outline: "none",
    border: "0",
    fontFamily: "EYInterState-regular",
  },
  listbox: {
    width: "100%",
    margin: 0,
    padding: 0,
    zIndex: 9,
    position: "absolute",
    listStyle: "none",
    fontFamily: "EYInterState-regular",
    border: "0.14rem solid rgba(0, 0, 0, 0.23)",
    borderTop: "0px",
    height: "20vh",
    overflow: "auto",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    background: "#FFF",
    "& ::-webkit-scrollbar": {
      display: "none",
    },
    "& li": {
      padding: "8px 15px",
    },
    '& li[data-focus="true"]': {
      backgroundColor: "#F6F6FA",
      color: "#2E2E38",
      cursor: "pointer",
      fontWeight: 600,
    },
    "& li:active": {
      backgroundColor: "#F6F6FA",
      color: "#2E2E38",
      fontWeight: 600,
    },
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    padding: "4px 0",
  },
}));

const DelegatesPopup = (props) => {
  const classes = useStyles();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const userList = [
    {
      id: "abarna@realize.com",
      firstName: "Abarna",
      lastName: "",
      fullName: "Abarna ",
      photo:
        "https://realizeqastorage.blob.core.windows.net/blobcontainer/Default_Avatar0.7112249318657621.png?sig=VTP%2F%2FmDD850oP5DdsoHXcH69Wxcr35VqvrQPFJldyEU%3D&se=2021-09-01T08%3A31%3A48Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Manager",
    },
    {
      id: "abby@realize.com",
      firstName: "Abby",
      lastName: "",
      fullName: "Abby ",
      photo:
        "https://realizeqastorage.blob.core.windows.net/blobcontainer/Default_Avatar0.7112249318657621.png?sig=VTP%2F%2FmDD850oP5DdsoHXcH69Wxcr35VqvrQPFJldyEU%3D&se=2021-09-01T08%3A31%3A48Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Senior Manager",
    },
    {
      id: "brianna@realize.com",
      firstName: "Brianna",
      lastName: "",
      fullName: "Brianna ",
      photo:
        "https://realizeqastorage.blob.core.windows.net/blobcontainer/Default_Avatar0.7112249318657621.png?sig=VTP%2F%2FmDD850oP5DdsoHXcH69Wxcr35VqvrQPFJldyEU%3D&se=2021-09-01T08%3A31%3A48Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Staff/Assistant",
    },
    {
      id: "bridget@realize.com",
      firstName: "Bridget",
      lastName: "",
      fullName: "Bridget ",
      photo:
        "https://realizeqastorage.blob.core.windows.net/blobcontainer/Default_Avatar0.7112249318657621.png?sig=VTP%2F%2FmDD850oP5DdsoHXcH69Wxcr35VqvrQPFJldyEU%3D&se=2021-09-01T08%3A31%3A48Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Staff/Assistant",
    },
    {
      id: "chris@realize.com",
      firstName: "Chris",
      lastName: "",
      fullName: "Chris ",
      photo:
        "https://realizeqastorage.blob.core.windows.net/blobcontainer/Default_Avatar0.7112249318657621.png?sig=VTP%2F%2FmDD850oP5DdsoHXcH69Wxcr35VqvrQPFJldyEU%3D&se=2021-09-01T08%3A31%3A48Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Director",
    },
    {
      id: "daniel@realize.com",
      firstName: "Daniel",
      lastName: "",
      fullName: "Daniel ",
      photo:
        "https://realizeqastorage.blob.core.windows.net/blobcontainer/Default_Avatar0.7112249318657621.png?sig=VTP%2F%2FmDD850oP5DdsoHXcH69Wxcr35VqvrQPFJldyEU%3D&se=2021-09-01T08%3A31%3A48Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Staff/Assistant",
    },
    {
      id: "debra@realize.com",
      firstName: "Debra",
      lastName: "",
      fullName: "Debra ",
      photo:
        "https://realizeqastorage.blob.core.windows.net/blobcontainer/Default_Avatar0.7112249318657621.png?sig=VTP%2F%2FmDD850oP5DdsoHXcH69Wxcr35VqvrQPFJldyEU%3D&se=2021-09-01T08%3A31%3A48Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Senior Associate",
    },
    {
      id: "elena@realize.com",
      firstName: "Elena",
      lastName: "",
      fullName: "Elena ",
      photo:
        "https://realizeqastorage.blob.core.windows.net/blobcontainer/Default_Avatar0.7112249318657621.png?sig=VTP%2F%2FmDD850oP5DdsoHXcH69Wxcr35VqvrQPFJldyEU%3D&se=2021-09-01T08%3A31%3A48Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Senior Manager",
    },
    {
      id: "elliott@realize.com",
      firstName: "Elliott",
      lastName: "",
      fullName: "Elliott ",
      photo:
        "https://realizeqastorage.blob.core.windows.net/blobcontainer/Default_Avatar0.7112249318657621.png?sig=VTP%2F%2FmDD850oP5DdsoHXcH69Wxcr35VqvrQPFJldyEU%3D&se=2021-09-01T08%3A31%3A48Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Senior",
    },
    {
      id: "franklin@realize.com",
      firstName: "Franklin",
      lastName: "",
      fullName: "Franklin ",
      photo:
        "https://realizeqastorage.blob.core.windows.net/blobcontainer/Default_Avatar0.7112249318657621.png?sig=VTP%2F%2FmDD850oP5DdsoHXcH69Wxcr35VqvrQPFJldyEU%3D&se=2021-09-01T08%3A31%3A48Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Manager",
    },
    {
      id: "geeteshwar@realize.com",
      firstName: "Geeteshwar",
      lastName: "",
      fullName: "Geeteshwar ",
      photo:
        "https://realizeqastorage.blob.core.windows.net/blobcontainer/Default_Avatar0.7112249318657621.png?sig=VTP%2F%2FmDD850oP5DdsoHXcH69Wxcr35VqvrQPFJldyEU%3D&se=2021-09-01T08%3A31%3A48Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Senior Associate",
    },
    {
      id: "george@realize.com",
      firstName: "George",
      lastName: "W",
      fullName: "George W",
      photo:
        "https://realizeqastorage.blob.core.windows.net/blobcontainer/Default_Avatar0.7112249318657621.png?sig=VTP%2F%2FmDD850oP5DdsoHXcH69Wxcr35VqvrQPFJldyEU%3D&se=2021-09-01T08%3A31%3A48Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Administrative Contractor",
    },
    {
      id: "athiraa@realize.com",
      firstName: "athiraa",
      lastName: "",
      fullName: "athiraa ",
      photo:
        "https://realizuatstorage.blob.core.windows.net/blobcontainer/Default_Avatar.jpg?sig=1c3%2FPqZ0P1r4x3vCcdy7%2BJTc1oBAEkR2Qpbw9v5QlQM%3D&se=2020-11-09T06%3A56%3A56Z&sv=2018-11-09&sp=r&sr=b",
      designation: "reviewer",
    },
    {
      id: "amit.pamecha@realize.com",
      firstName: "Amit",
      lastName: "Pamecha",
      fullName: "Amit Pamecha",
      photo:
        "https://realizuatstorage.blob.core.windows.net/blobcontainer/Default_Avatar.jpg?sig=1c3%2FPqZ0P1r4x3vCcdy7%2BJTc1oBAEkR2Qpbw9v5QlQM%3D&se=2020-11-09T06%3A56%3A56Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Manager",
    },
    {
      id: "mahesh@realize.com",
      firstName: "Mahesh",
      lastName: "",
      fullName: "Mahesh ",
      photo:
        "https://realizeuatstorage.blob.core.windows.net/blobcontainer/Default_Avatar.jpg?sig=1c3%2FPqZ0P1r4x3vCcdy7%2BJTc1oBAEkR2Qpbw9v5QlQM%3D&se=2020-11-09T06%3A56%3A56Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Staff",
    },
    {
      id: "sohan@realize.com",
      firstName: "Sohan",
      lastName: "",
      fullName: "Sohan ",
      photo:
        "https://realizeuatstorage.blob.core.windows.net/blobcontainer/Default_Avatar.jpg?sig=1c3%2FPqZ0P1r4x3vCcdy7%2BJTc1oBAEkR2Qpbw9v5QlQM%3D&se=2020-11-09T06%3A56%3A56Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Senior",
    },
    {
      id: "johnd@realize.com",
      firstName: "John",
      lastName: "Doe",
      fullName: "John Doe",
      photo:
        "https://realizeuatstorage.blob.core.windows.net/blobcontainer/Default_Avatar.jpg?sig=1c3%2FPqZ0P1r4x3vCcdy7%2BJTc1oBAEkR2Qpbw9v5QlQM%3D&se=2020-11-09T06%3A56%3A56Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Senior",
    },
    {
      id: "janed@realize.com",
      firstName: "Jane",
      lastName: "Doe",
      fullName: "Jane Doe",
      photo:
        "https://realizeuatstorage.blob.core.windows.net/blobcontainer/Default_Avatar.jpg?sig=1c3%2FPqZ0P1r4x3vCcdy7%2BJTc1oBAEkR2Qpbw9v5QlQM%3D&se=2020-11-09T06%3A56%3A56Z&sv=2018-11-09&sp=r&sr=b",
      designation: "Manager",
    },
  ];
  // const [approver, setApprover] = useState([]);
  const [spoc, setSPOC] = useState([]);
  // const [quality, setQuality] = useState([]);
  // const [margin, setMargin] = useState([]);
  // const [ecs, setECS] = useState([]);
  // const [peopleAndTraining, setPeopleAndTraining] = useState([]);
  // const [ter, setTER] = useState([]);
  // const [salesAndReports, setSalesAndReports] = useState([]);
  const [delegateNow, setDelegateNow] = useState(false);
  // const [delegatedUsers, setDelegatedUsers] = useState({
  //   approver : [],
  //   spoc: '',
  //   quality: '',
  //   margin: '',
  //   ecs: '',
  //   peopleAndTraining: '',
  //   ter: '',
  //   salesAndReports: ""
  // });

  const saveDelegates = () => {
    //console.log("save delegates API Call");
    closePopup();
  };
  const closePopup = () => {
    // setApprover([]);
    setSPOC([]);
    // setQuality([]);
    // setMargin([]);
    // setECS([]);
    // setPeopleAndTraining([]);
    // setTER([]);
    // setSalesAndReports([]);
    props.cancelDelegatesPopup();
  };
  return (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={props.showDelegatesPopup}
        onClose={(e) => closePopup()}
        hideBackdrop={false}
        disableEscapeKeyDown={true}
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className={"delegatePopup"}>
          <div className="delegateHeader">
            <div>Delegate Responsibility</div>
            <i
              className="icon-close-filled closeIcon"
              onClick={(e) => closePopup()}
            />
          </div>
          <div className="delegateContent">
            <div className="inputFields" style={{ marginTop: 10 }}>
              {/* <div style={{ position: "relative" }}>
                <Autocompletee
                  multiple
                  id="checkboxes-add-users"
                  options={userList}
                  value={approver}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.fullName}
                  disableClearable
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <div
                        className="autoCompleteList"
                        onClick={() => {
                          if (
                            approver.filter(
                              (obj) => obj.fullName === option.fullName
                            ).length !== 0
                          ) {
                            setApprover([
                              ...approver.filter(
                                (obj) => obj.fullName !== option.fullName
                              ),
                            ]);
                          } else {
                            setApprover([
                              ...approver.filter(
                                (obj) => obj.fullName !== option.fullName
                              ),
                              option,
                            ]);
                          }
                        }}
                      >
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 0, padding: 5 }}
                          checked={
                            approver.filter(
                              (obj) => obj.fullName === option.fullName
                            ).length !== 0
                          }
                          onClick={() => {
                            if (
                              approver.filter(
                                (obj) => obj.fullName === option.fullName
                              ).length !== 0
                            ) {
                              setApprover([
                                ...approver.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                              ]);
                            } else {
                              setApprover([
                                ...approver.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                                option,
                              ]);
                            }
                          }}
                        />
                        {option.fullName}
                      </div>
                    </React.Fragment>
                  )}
                  classes={{
                    popper: "Popper-cls-account-group",
                    paper: "Paper-cls-talent",
                    option: "Option-cls-account-group",
                    listbox: "Listbox-cls-account-group",
                  }}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      id="facilitatorItem"
                      className={classes.input}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      label="Approver"
                      focused={
                        (approver && approver.length) > 0 ? true : undefined
                      }
                    />
                  )}
                />
              </div> */}

              {delegateNow === true ? (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div style={{marginRight: 15, width: '30%', fontSize : 12}}>SPOC</div> */}
                  <Autocompletee
                    multiple
                    style={{ width: "100%" }}
                    id="checkboxes-add-users"
                    options={userList}
                    value={spoc}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.fullName}
                    disableClearable
                    popupIcon={<AddIcon fontSize="large" />}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <div
                          className="autoCompleteList"
                          onClick={() => {
                            if (
                              spoc.filter(
                                (obj) => obj.fullName === option.fullName
                              ).length !== 0
                            ) {
                              setSPOC([
                                ...spoc.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                              ]);
                            } else {
                              setSPOC([
                                ...spoc.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                                option,
                              ]);
                            }
                          }}
                        >
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 0, padding: 5 }}
                            checked={
                              spoc.filter(
                                (obj) => obj.fullName === option.fullName
                              ).length !== 0
                            }
                            onClick={() => {
                              if (
                                spoc.filter(
                                  (obj) => obj.fullName === option.fullName
                                ).length !== 0
                              ) {
                                setSPOC([
                                  ...spoc.filter(
                                    (obj) => obj.fullName !== option.fullName
                                  ),
                                ]);
                              } else {
                                setSPOC([
                                  ...spoc.filter(
                                    (obj) => obj.fullName !== option.fullName
                                  ),
                                  option,
                                ]);
                              }
                            }}
                          />
                          {option.fullName}
                        </div>
                      </React.Fragment>
                    )}
                    classes={{
                      popper: "Popper-cls-account-group",
                      paper: "Paper-cls-talent",
                      option: "Option-cls-account-group",
                      listbox: "Listbox-cls-account-group",
                    }}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        id="facilitatorItem"
                        className={classes.input}
                        variant="outlined"
                        size="small"
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        label="SPOC"
                        focused={(spoc && spoc.length) > 0 ? true : undefined}
                      />
                    )}
                  />
                </div>
              ) : (
                <div style={{ fontSize: 11, opacity: 0.8 }}>
                  <p>
                    You may assign a SPOC or delegate each section to Managers
                    and above as category leads. Alternatively you may complete
                    the survey by yourself.
                  </p>
                  <p> Do you wish to delegate now?</p>
                </div>
              )}

              {/* <div className="orLabel">OR</div>
              <div className="categoryLabel">Assign Category Lead</div>
              <div style={{ position: "relative" }}>
                <Autocompletee
                  multiple
                  id="checkboxes-add-users"
                  options={userList}
                  value={quality}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.fullName}
                  disableClearable
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <div
                        className="autoCompleteList"
                        onClick={() => {
                          if (
                            quality.filter(
                              (obj) => obj.fullName === option.fullName
                            ).length !== 0
                          ) {
                            setQuality([
                              ...quality.filter(
                                (obj) => obj.fullName !== option.fullName
                              ),
                            ]);
                          } else {
                            setQuality([
                              ...quality.filter(
                                (obj) => obj.fullName !== option.fullName
                              ),
                              option,
                            ]);
                          }
                        }}
                      >
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 0, padding: 5 }}
                          checked={
                            quality.filter(
                              (obj) => obj.fullName === option.fullName
                            ).length !== 0
                          }
                          onClick={() => {
                            if (
                              quality.filter(
                                (obj) => obj.fullName === option.fullName
                              ).length !== 0
                            ) {
                              setQuality([
                                ...quality.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                              ]);
                            } else {
                              setQuality([
                                ...quality.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                                option,
                              ]);
                            }
                          }}
                        />
                        {option.fullName}
                      </div>
                    </React.Fragment>
                  )}
                  classes={{
                    popper: "Popper-cls-account-group",
                    paper: "Paper-cls-talent",
                    option: "Option-cls-account-group",
                    listbox: "Listbox-cls-account-group",
                  }}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      id="facilitatorItem"
                      className={classes.input}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      label="Quality"
                      focused={
                        (quality && quality.length) > 0 ? true : undefined
                      }
                    />
                  )}
                />
              </div>
              
              <div style={{ position: "relative" }}>
                <Autocompletee
                  multiple
                  id="checkboxes-add-users"
                  options={userList}
                  value={margin}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.fullName}
                  disableClearable
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <div
                        className="autoCompleteList"
                        onClick={() => {
                          if (
                            margin.filter(
                              (obj) => obj.fullName === option.fullName
                            ).length !== 0
                          ) {
                            setMargin([
                              ...margin.filter(
                                (obj) => obj.fullName !== option.fullName
                              ),
                            ]);
                          } else {
                            setMargin([
                              ...margin.filter(
                                (obj) => obj.fullName !== option.fullName
                              ),
                              option,
                            ]);
                          }
                        }}
                      >
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 0, padding: 5 }}
                          checked={
                            margin.filter(
                              (obj) => obj.fullName === option.fullName
                            ).length !== 0
                          }
                          onClick={() => {
                            if (
                              margin.filter(
                                (obj) => obj.fullName === option.fullName
                              ).length !== 0
                            ) {
                              setMargin([
                                ...margin.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                              ]);
                            } else {
                              setMargin([
                                ...margin.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                                option,
                              ]);
                            }
                          }}
                        />
                        {option.fullName}
                      </div>
                    </React.Fragment>
                  )}
                  classes={{
                    popper: "Popper-cls-account-group",
                    paper: "Paper-cls-talent",
                    option: "Option-cls-account-group",
                    listbox: "Listbox-cls-account-group",
                  }}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      id="facilitatorItem"
                      className={classes.input}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      label="Margin"
                      focused={(margin && margin.length) > 0 ? true : undefined}
                    />
                  )}
                />
              </div>
              
              <div style={{ position: "relative" }}>
                <Autocompletee
                  multiple
                  id="checkboxes-add-users"
                  options={userList}
                  value={ecs}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.fullName}
                  disableClearable
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <div
                        className="autoCompleteList"
                        onClick={() => {
                          if (
                            ecs.filter(
                              (obj) => obj.fullName === option.fullName
                            ).length !== 0
                          ) {
                            setECS([
                              ...ecs.filter(
                                (obj) => obj.fullName !== option.fullName
                              ),
                            ]);
                          } else {
                            setECS([
                              ...ecs.filter(
                                (obj) => obj.fullName !== option.fullName
                              ),
                              option,
                            ]);
                          }
                        }}
                      >
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 10, padding: 5 }}
                          checked={
                            ecs.filter(
                              (obj) => obj.fullName === option.fullName
                            ).length !== 0
                          }
                          onClick={() => {
                            if (
                              ecs.filter(
                                (obj) => obj.fullName === option.fullName
                              ).length !== 0
                            ) {
                              setECS([
                                ...ecs.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                              ]);
                            } else {
                              setECS([
                                ...ecs.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                                option,
                              ]);
                            }
                          }}
                        />
                        {option.fullName}
                      </div>
                    </React.Fragment>
                  )}
                  classes={{
                    popper: "Popper-cls-account-group",
                    paper: "Paper-cls-talent",
                    option: "Option-cls-account-group",
                    listbox: "Listbox-cls-account-group",
                  }}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      id="facilitatorItem"
                      className={classes.input}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      label="ECS"
                      focused={(ecs && ecs.length) > 0 ? true : undefined}
                    />
                  )}
                />
              </div>
              
              <div style={{ position: "relative" }}>
                <Autocompletee
                  multiple
                  id="checkboxes-add-users"
                  options={userList}
                  value={peopleAndTraining}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.fullName}
                  disableClearable
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <div
                        className="autoCompleteList"
                        onClick={() => {
                          if (
                            peopleAndTraining.filter(
                              (obj) => obj.fullName === option.fullName
                            ).length !== 0
                          ) {
                            setPeopleAndTraining([
                              ...peopleAndTraining.filter(
                                (obj) => obj.fullName !== option.fullName
                              ),
                            ]);
                          } else {
                            setPeopleAndTraining([
                              ...peopleAndTraining.filter(
                                (obj) => obj.fullName !== option.fullName
                              ),
                              option,
                            ]);
                          }
                        }}
                      >
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 0, padding: 5 }}
                          checked={
                            peopleAndTraining.filter(
                              (obj) => obj.fullName === option.fullName
                            ).length !== 0
                          }
                          onClick={() => {
                            if (
                              peopleAndTraining.filter(
                                (obj) => obj.fullName === option.fullName
                              ).length !== 0
                            ) {
                              setPeopleAndTraining([
                                ...peopleAndTraining.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                              ]);
                            } else {
                              setPeopleAndTraining([
                                ...peopleAndTraining.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                                option,
                              ]);
                            }
                          }}
                        />
                        {option.fullName}
                      </div>
                    </React.Fragment>
                  )}
                  classes={{
                    popper: "Popper-cls-account-group",
                    paper: "Paper-cls-talent",
                    option: "Option-cls-account-group",
                    listbox: "Listbox-cls-account-group",
                  }}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      id="facilitatorItem"
                      className={classes.input}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      label="People & Training"
                      focused={
                        (peopleAndTraining && peopleAndTraining.length) > 0
                          ? true
                          : undefined
                      }
                    />
                  )}
                />
              </div>
             
              <div style={{ position: "relative" }}>
                <Autocompletee
                  multiple
                  id="checkboxes-add-users"
                  options={userList}
                  value={ter}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.fullName}
                  disableClearable
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <div
                        className="autoCompleteList"
                        onClick={() => {
                          if (
                            ter.filter(
                              (obj) => obj.fullName === option.fullName
                            ).length !== 0
                          ) {
                            setTER([
                              ...ter.filter(
                                (obj) => obj.fullName !== option.fullName
                              ),
                            ]);
                          } else {
                            setTER([
                              ...ter.filter(
                                (obj) => obj.fullName !== option.fullName
                              ),
                              option,
                            ]);
                          }
                        }}
                      >
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 0, padding: 5 }}
                          checked={
                            ter.filter(
                              (obj) => obj.fullName === option.fullName
                            ).length !== 0
                          }
                          onClick={() => {
                            if (
                              ter.filter(
                                (obj) => obj.fullName === option.fullName
                              ).length !== 0
                            ) {
                              setTER([
                                ...ter.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                              ]);
                            } else {
                              setTER([
                                ...ter.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                                option,
                              ]);
                            }
                          }}
                        />
                        {option.fullName}
                      </div>
                    </React.Fragment>
                  )}
                  classes={{
                    popper: "Popper-cls-account-group",
                    paper: "Paper-cls-talent",
                    option: "Option-cls-account-group",
                    listbox: "Listbox-cls-account-group",
                  }}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      id="facilitatorItem"
                      className={classes.input}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      label="TER"
                      focused={(ter && ter.length) > 0 ? true : undefined}
                    />
                  )}
                />
              </div>
              
              <div style={{ position: "relative" }}>
                <Autocompletee
                  multiple
                  id="checkboxes-add-users"
                  options={userList}
                  value={salesAndReports}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.fullName}
                  disableClearable
                  renderOption={(option, { selected }) => (
                    <React.Fragment>
                      <div
                        className="autoCompleteList"
                        onClick={() => {
                          if (
                            salesAndReports.filter(
                              (obj) => obj.fullName === option.fullName
                            ).length !== 0
                          ) {
                            setSalesAndReports([
                              ...salesAndReports.filter(
                                (obj) => obj.fullName !== option.fullName
                              ),
                            ]);
                          } else {
                            setSalesAndReports([
                              ...salesAndReports.filter(
                                (obj) => obj.fullName !== option.fullName
                              ),
                              option,
                            ]);
                          }
                        }}
                      >
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 0, padding: 5 }}
                          checked={
                            salesAndReports.filter(
                              (obj) => obj.fullName === option.fullName
                            ).length !== 0
                          }
                          onClick={() => {
                            if (
                              salesAndReports.filter(
                                (obj) => obj.fullName === option.fullName
                              ).length !== 0
                            ) {
                              setSalesAndReports([
                                ...salesAndReports.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                              ]);
                            } else {
                              setSalesAndReports([
                                ...salesAndReports.filter(
                                  (obj) => obj.fullName !== option.fullName
                                ),
                                option,
                              ]);
                            }
                          }}
                        />
                        {option.fullName}
                      </div>
                    </React.Fragment>
                  )}
                  classes={{
                    popper: "Popper-cls-account-group",
                    paper: "Paper-cls-talent",
                    option: "Option-cls-account-group",
                    listbox: "Listbox-cls-account-group",
                  }}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      id="facilitatorItem"
                      className={classes.input}
                      variant="outlined"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      label="Sales & Reports"
                      focused={
                        (salesAndReports && salesAndReports.length) > 0
                          ? true
                          : undefined
                      }
                    />
                  )}
                />
              </div> */}
            </div>
            <div className="delegateActions">
              {delegateNow === true ? (
                <>
                  <div className="cancel" onClick={() => closePopup()}>
                    Later
                  </div>
                  <div className="save" onClick={() => saveDelegates()}>
                    Save
                  </div>
                </>
              ) : (
                <>
                  <div className="cancel" onClick={() => closePopup()}>
                    Later
                  </div>
                  <div
                    className="save"
                    onClick={() => {
                      setDelegateNow(true);
                    }}
                  >
                    Yes
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="delegateFooter">
            {delegateNow === true ? (
              <p>
                SPOC will be able to mark response in all the sections on behalf
                of the PPMD. Added user will receive mail communications with
                instructions to complete the survey.
              </p>
            ) : (
              <p>
                In case if you wish to delegate or assign leads later, please
                click the highlighted button on the top menu near your profile
                image.
              </p>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default withRouter(DelegatesPopup);
