export const columnNames = [
  "Last Name",
  "Second Last Name",
  "First Name",
  "Middle Name",
  "Preferred Name",
  "Preferred First Name",
  "Former Name",
  "Formal Name",
  "Name AC",
  "Last Name AC",
  "First Name AC",
  "Middle Name AC",
  "GUI",
  "Employee ID",
  "LPN",
  "GPN",
  "Taleo Candidate ID",
  "Rank Code Name",
  "Rank Code",
  "Rank Description",
  "Business Title",
  "Rank Group 1",
  "Rank Group 2",
  "Rank Group 3",
  "Rank Group 4",
  "Non Employee Rank",
  "GFIS Rank",
  "Experience Level",
  "Global Grade",
  "Activity Type Code",
  "Activity Type Description",
  "Company Code",
  "Company Description",
  "Company AC",
  "Managerial Country",
  "WBS Element",
  "Cost Center Code",
  "Cost Center Description",
  "Financial Company Code",
  "Financial Company Description",
  "Profit Center Code",
  "Profit Center Description",
  "Code Block",
  "BU Code",
  "OU Code",
  "MU Code",
  "SMU Code",
  "BU Description",
  "OU Description",
  "MU Description",
  "SMU Description",
  "Service Line (SL Level 1)",
  "Sub Service Line (SL Level 2)",
  "SL Level 3",
  "SL Level 4",
  "SL Level 5",
  "Alternate Service Line",
  "Management Area (BU Level 1)",
  "Management Region (BU Level 2)",
  "BU Level 3",
  "BU Level 4",
  "BU Level 5",
  "BU Affiliate",
  "Practice BU vs National BU",
  "Finance Area",
  "Finance Region",
  "Finance SL",
  "Finance SSL",
  "Location Code",
  "Location City",
  "Location State Prov",
  "Location Country",
  "Geo Level 1",
  "Geo Level 2",
  "Geo Level 3",
  "Geo Level 4",
  "Geo Level 5",
  "Employee Status",
  "Active Status",
  "Leave Effective Date",
  "Expected Return Date",
  "Full Part Time",
  "FWA Code",
  "FWA Description",
  "Tele Work",
  "Standard Hours Frequency",
  "Standard Hours",
  "Contract Hours",
  "Paid Hours",
  "Contractual FTE",
  "Paid FTE (Finance)",
  "Overtime Eligibility",
  "Payroll Unit",
  "Employee Class Code",
  "Employee Class Description",
  "Regular Temporary",
  "Employee Type",
  "Person Organization Relationship Code",
  "Person Organization Relationship Description",
  "Person Of Interest (POI) Type Code",
  "Person Of Interest (POI) Type Description",
  "Paygroup Code",
  "Employee Category Code",
  "Employee Category Description",
  "Employee Subcategory Code",
  "Non Employee Work Type Code",
  "Non Employee Work Type Description",
  "Establishment Code",
  "Global Assignment Program Code",
  "Global Assignment Program Description",
  "Global Assignment Start Date",
  "Global Assignment End Date",
  "In Pat Ex Pat Status",
  "Home Country",
  "Host Country",
  "EY Start Date",
  "Last Rehire Date",
  "Seniority Pay Calculation Date",
  "Company Seniority Date",
  "Current Employment Start Date",
  "Current Length of Service",
  "Total EY Length of Service",
  "Partner Admission Date",
  "Hire Code Description",
  "Hire Reason",
  "Action Code",
  "Action Reason Code",
  "Expected End Date",
  "Promotion",
  "Last Promotion Date",
  "Last Progression Date",
  "Former Intern",
  "Effective Date of Termination",
  "Work Phone",
  "gT&E Supervisor GUI",
  "gT&E Supervisor Name",
  "Counselor GUI",
  "Counselor Name",
  "Team Leader GUI",
  "Team Leader Name",
  "Counseling Family Leader GUI",
  "Counseling Family Leader Name",
  "Admin Assistant GUI",
  "Admin Assistant Name",
  "Job Code",
  "Job Code Description",
  "Primary Sector Specialization Code",
  "Primary Sector Specialization Description",
  "Secondary Sector Specialization Code",
  "Secondary Sector Specialization Description",
  "Industry Sector",
  "Timesheet Indicator",
  "Department Code",
  "Department Description",
  "Employee Record",
  "Source",
  "Language Preference",
  "Active Directory ID",
  "Headcount",
  "Workforce",
  "Email Address",
];
