/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, memo, useRef } from "react";
import { withRouter } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import SimpleBar from "simplebar-react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast, Flip } from "react-toastify";
import AppContext from "../../../../../utils/appContext";
import "../../AssessmentAdmin.scss";
import DropdownComponent from "../../../AdminGeneralParam/DropdownComponent/DropdownComponent";
import RadioButton from "../../../../RadioButton";
import { ADD_ASSESSMENT_GOAL, EDIT_ASSESSMENT_GOAL, GET_ASSESSMENT_GOAL, OUTSIDE_CLICK_COMMON, RESET_ADD_ASSESSMENT_GOAL, RESET_ASSESSMENT_GOAL, RESET_DELETE_ASSESSMENT_GOAL, RESET_EDIT_ASSESSMENT_GOAL, RESET_REORDER_ASSESSMENT_GOAL } from "../../../../../actions/types";
import { errorCheck, getErrorMessages } from "../../../../../utils/utils";
import { Checkbox, FormHelperText, RadioGroup, FormControlLabel, Radio, FormControl } from "@material-ui/core";
import { ifNonEmptyInput } from '../../../../../helpers/textValidator';

const useStyles = makeStyles((theme) => ({
  root1: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "250px",
      height: "27px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      //   borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      //   "&:hover:not($disabled):not($focused):not($error):before": {
      //     borderBottom: "none",
      //   },
      " &:hover:before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      },
      " &:hover:after": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.16)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  focuslabelroot: {
    // backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    // paddingLeft: "5px",
    // paddingTop: "5px",
    fontSize: "11px",
    fontWeight: "500",
    width: '150px'
  },
  // labelroot: {
  //   backgroundColor: "rgb(246 ,246, 250)!important",
  //   color: "rgba(0, 0, 0, 0.94)",
  //   paddingLeft: "5px",
  //   paddingTop: "5px",
  //   fontSize: "8px",
  // },
  // topSpacing: {
  //   marginTop: "16px",
  // },
  // topSyli: {
  //   marginBottom: " -30px",
  //   marginTop: "16px",
  //   zIndex: "8",
  //   width: "115px",
  // },
  // half: {
  //   display: "flex",
  //   flexDirection: "row",
  //   width: "350px",
  //   marginLeft: "15px",
  // },
  root: {
    "& .MuiFilledInput-underline.Mui-error:after": {
      borderBottom: "1px solid #f44336",
    },
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
      fontFamily: "EYInterState-Regular",
    },
  },
  rootMulti: {
    "& .MuiFilledInput-underline.Mui-error:after": {
      borderBottom: "1px solid #f44336",
    },
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  labelroot: {
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "8px",
    // fontWeight: "600",
  },
  labelroot1: {
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "10px",
    fontWeight: "600",
  },
  topSpacing: {
    marginTop: "8px",
  },
  topSyli: {
    marginBottom: " -30px",
    marginTop: "16px",
    zIndex: "8",
    width: "100px",
  },
  half: {
    display: "flex",
    flexDirection: "row",
  },
  arrow: {
    position: "absolute",
    fontSize: 14,
    width: "6em",
    height: "4em",
    //   transform: "rotate(225deg)",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  paper2: {
    backgroundColor: "white",
    // border: '2px solid #000',
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 2, 2),
    width: 170,
    overflow: "hidden",
    overflowX: "hidden",
    fontSize: "11px",
  },
  options: {
    borderBottom: "1px solid grey",
    padding: "5px 0px 5px 5px",
    display: "flex",
    alignItems: "center",
    height: "24px",
    cursor: "pointer",
  },
  popper: {
    // zIndex: 1,
    left: "-55px!important",
    '&[x-placement*="top-end"]': {
      top: "-12px!important",
    },
    '&[x-placement*="bottom-end"]': {
      top: "9px!important",
    },
    '&[x-placement*="bottom"] $arrow': {
      top: 2,
      left: "105px!important",
      marginTop: "-0.9em",
      width: "6em",
      height: "2em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: "103px!important",
      marginBottom: "-3.6em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.9em",
      width: "6em",
      height: "4em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  input: {
    background: "rgb(255, 255, 255) !important"
  },
  helper: {
    marginLeft: "10px",
    color: '#f44336'
  },
}));

const GoalDetails = (props) => {
  const myContext = useContext(AppContext);
  const { goalDetails, goalMetadata, updateGoalName, saveGoal, setDisableSaveGoal, setLoader, disableFields = false, resetSaveGoal } = props;
  const { selectedPillarIndex, selectedGoalIndex, pillarId, goalId = '', financialYear } = goalMetadata;
  const assessmentGoalData = useSelector((state) => (state.getAssesmentGoalData));
  const { data: goalData } = assessmentGoalData || {}
  const addAssessmentGoalData = useSelector((state) => state.addAssessmentGoal);
  const editAssessmentGoalData = useSelector((state) => state.editAssessmentGoal);
  const reorderAssessmentGoalData = useSelector((state) => state.reorderAssessmentGoal);
  const deleteAssessmentGoalData = useSelector((state) => state.deleteAssessmentGoal);
  const classes = useStyles();
  const dispatch = useDispatch();
  const inputValuesforType = {
    radio_button: "Radio Button",
    check_box: "Check Box",
    drop_down: "Drop Down",
  };

  const usePreviousEffect = (fn, inputs = []) => {
    const previousInputsRef = useRef([...inputs])

    useEffect(() => {
      fn(previousInputsRef.current)
      previousInputsRef.current = [...inputs]
    }, [fn, inputs])
  }


  const goalOptionsConstant = ["1", "2", "3", "4", "5"];
  const goalScoreConstant = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "NA"];

  const [goalName, setGoalName] = useState('');
  const [goalDescription, setGoalDescription] = useState('');
  const [goalRemark, setGoalRemark] = useState('');
  const [uiType, setUiType] = useState('');
  const [noOfGoalOptions, setNoOfGoalOptions] = useState(0);
  const [considerForScoring, setConsiderForScoring] = useState('');
  const [considerForFocused, setConsiderForFocused] = useState('');
  const [goalOptions, setGoalOptions] = useState([]);
  const [checkBoxLabel, setCheckBoxLabel] = useState('');
  const [enableSave, setEnableSave] = useState(false);
  const [isCheckBoxCommited, setIsCheckBoxCommited] = useState(false);
  const [newGoal, setNewGoal] = useState(true);

  // eslint-disable-next-line no-unused-vars
  // For any errors of the input fields
  const initialErrorState = {
    goalName: "",
    goalDescription: "",
    uiType: "",
    noOfGoalOptions: "",
    considerForScoring: "",
    considerForFocused: "",
    checkBoxLabel: "",
    optionList: [],
    scoreList: [],
    isCheckBoxCommited: ''
  }
  const [errorMessages, setErrorMessages] = useState(initialErrorState);

  usePreviousEffect(
    ([prevGoalMetadata]) => {
      if (_.isEmpty(goalMetadata) || !_.isEqual(prevGoalMetadata, goalMetadata)) {
        setErrorMessages(initialErrorState);
        setEnableSave(false);
      }
    },
    [goalMetadata]
  )

  // eslint-disable-next-line no-unused-vars
  // To disable all the input fields
  const [editable, setEditable] = useState(true);

  useEffect(() => {
    if (!_.isEmpty(goalDetails)) {
      const {
        goalName,
        goalDescription,
        goalRemark
      } = goalDetails;
      if (financialYear !== '' &&
        pillarId !== '' &&
        goalId !== '') {
        if (!assessmentGoalData.fetching)
          dispatch({
            type: GET_ASSESSMENT_GOAL,
            payload: {
              financialYear,
              pillarId,
              goalId
            },
          });
        if (!newGoal && goalId !== '') {
          const localErrorMessage = getErrorMessages(goalDetails, ['goalName', 'goalDescription']);
          setErrorMessages((prevState) => ({
            ...prevState,
            ...localErrorMessage
          }));
        }
      } else {
        dispatch({
          type: RESET_ASSESSMENT_GOAL
        });
      }
      setGoalName(goalName || '');
      setGoalDescription(goalDescription || '');
      setGoalRemark(goalRemark || '');
      setEditable(!disableFields);
    }
  }, [goalDetails]);

  useEffect(() => {
    const tempGoalData = goalId === '' ? {} : goalData;
    setEnableSave(false);
    if (goalId === '')
      setNewGoal(true);
    else
      setNewGoal(false);
    if (goalId === '' || !_.isEmpty(tempGoalData)) {
      setUiType(inputValuesforType[tempGoalData.uiType || ''] || '');
      setConsiderForScoring(tempGoalData.considerForScoring || '');
      setConsiderForFocused(tempGoalData.considerForFocused || '');
      const optionScore = tempGoalData.optionScore || {}
      const goalOptions = _.isArray(tempGoalData.options) ? tempGoalData.options.map(e => ({ ...e, score: _.isUndefined(optionScore[e.option_id]) ? '' : optionScore[e.option_id] })) : [];
      setGoalOptions(goalOptions || []);
      const noOfGoalOptions = inputValuesforType[tempGoalData.uiType || ''] !== "Check Box" && goalOptions.length > 0 ? `${goalOptions.length}` : '';
      setNoOfGoalOptions(noOfGoalOptions);
      setCheckBoxLabel(tempGoalData.checkBoxLabel || '');
      if (!newGoal && goalId !== '') {
        const isCheckBoxCommited = _.isArray(tempGoalData.options) ? (tempGoalData.options.some(el => (el.committed)) ? 'value' : '') : '';
        const optionList = tempGoalData.options ? tempGoalData.options.map(({ option }) => (option)) : [];
        const optionScoreList = Object.values(optionScore);
        const obj = { ...tempGoalData, isCheckBoxCommited, noOfGoalOptions: tempGoalData.uiType !== "Check Box" ? (noOfGoalOptions === 0 ? '' : noOfGoalOptions) : -1, scoreList: optionScoreList, optionList }
        const localErrorMessage = getErrorMessages(obj, ['uiType', 'considerForScoring', 'considerForFocused', 'checkBoxLabel', 'noOfGoalOptions', 'optionList', 'scoreList', 'isCheckBoxCommited']);
        setErrorMessages((prevState) => ({
          ...prevState,
          ...localErrorMessage
        }));
      }
    }
  }, [goalData]);

  useEffect(() => {
    setEditable(!disableFields)
  }, [disableFields]);

  const getGoalDetails = () => {
    const data =
    {
      goalName,
      goalDescription,
      goalRemark,
      uiType: Object.keys(inputValuesforType).find(key => inputValuesforType[key] === uiType) || '',
      considerForScoring,
      considerForFocused,
      goalOptions: goalOptions.map((option) => {
        const tempOption = { ...option };
        if (considerForScoring === 'No')
          delete tempOption.score;
        return tempOption;
      }),
      checkBoxLabel,
      pillarId,
      goalId,
      financialYear
    }
    return data;
  }

  useEffect(() => {
    if (saveGoal !== 0) {
      setEnableSave(false);
      const goalDetails = getGoalDetails();
      const type = goalDetails && goalDetails.goalId ? EDIT_ASSESSMENT_GOAL : ADD_ASSESSMENT_GOAL
      const payloadObj = goalDetails;
      if (!(goalDetails && goalDetails.goalId)) {
        delete payloadObj.goalId;
      }
      try {
        dispatch({
          type,
          payload: { payloadObj }
        });
      } catch (e) {
        console.error(e);
      }
      resetSaveGoal(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveGoal]);

  useEffect(() => {
    if (!newGoal && goalId !== '') {
      const optionList = goalOptions ? goalOptions.map(({ option }) => (option)) : [];
      const scoreList = goalOptions ? goalOptions.map(({ score }) => (considerForScoring === 'Yes' ? score : -1)) : [];
      const isCheckBoxCommitedCheck = _.isArray(goalOptions) ? (goalOptions.some(el => (el.committed)) ? 'value' : '') : '';
      const obj = { isCheckBoxCommited: isCheckBoxCommitedCheck, goalName, goalDescription, uiType, considerForScoring, considerForFocused, checkBoxLabel: uiType === "Check Box" ? checkBoxLabel : '-', noOfGoalOptions: uiType !== "Check Box" ? (noOfGoalOptions === 0 ? '' : noOfGoalOptions) : -1, optionList, scoreList }
      const localErrorMessage = getErrorMessages(obj, ['goalName', 'goalDescription', 'uiType', 'considerForScoring', 'considerForFocused', 'checkBoxLabel', 'noOfGoalOptions', 'optionList', 'scoreList', 'isCheckBoxCommited']);
      setErrorMessages((prevState) => ({
        ...prevState,
        ...localErrorMessage
      }));
    }
    let disableSave = !(goalName !== '' && goalDescription !== '' && uiType !== '' && considerForScoring !== '' && considerForFocused !== '' && goalOptions.length !== 0 && isCheckBoxCommited);
    if (uiType === "Check Box") {
      disableSave = !(!disableSave && checkBoxLabel !== '')
    } else {
      goalOptions.forEach(element => {
        disableSave = !(!disableSave && element.option !== '' && (considerForScoring === 'No' || considerForFocused === 'No' || element.score !== '') && isCheckBoxCommited)
      });
    }
    setDisableSaveGoal(errorCheck(errorMessages) || !enableSave || disableSave);
  }, [goalName,
    goalDescription,
    uiType,
    considerForScoring,
    considerForFocused,
    goalOptions,
    checkBoxLabel,
    goalRemark,
    isCheckBoxCommited]);

  useEffect(() => {
    setDisableSaveGoal(errorCheck(errorMessages) || !enableSave);
  }, [errorMessages]);


  useEffect(() => {
    let disableSave = !(goalName !== '' && goalDescription !== '' && uiType !== '' && considerForScoring !== '' && considerForFocused !== '' && goalOptions.length !== 0 && isCheckBoxCommited);
    if (uiType === "Check Box") {
      disableSave = !(!disableSave && checkBoxLabel !== '')
    }
    goalOptions.forEach(element => {
      disableSave = !(!disableSave && element.option !== '' && isCheckBoxCommited)
    });
    setDisableSaveGoal(!enableSave || disableSave);
  }, []);

  //Loaders and success messages
  useEffect(() => {
    if (
      assessmentGoalData &&
      assessmentGoalData.data &&
      !assessmentGoalData.fetching
      // assessmentGoalData.data.status &&
      // assessmentGoalData.data.status === "success"
    ) {
      setLoader(false);
    } else if (
      assessmentGoalData &&
      assessmentGoalData.data &&
      assessmentGoalData.fetching
    ) {
      setLoader(true);
    }
    // eslint-disable-next-line
  }, [assessmentGoalData]);

  useEffect(() => {
    if (
      addAssessmentGoalData &&
      addAssessmentGoalData.data &&
      !addAssessmentGoalData.fetching &&
      addAssessmentGoalData.data.status &&
      addAssessmentGoalData.data.status === "success"
    ) {
      showToastMessage('add');
      dispatch({
        type: RESET_ADD_ASSESSMENT_GOAL,
        payload: {},
      });
    } else if (
      addAssessmentGoalData &&
      addAssessmentGoalData.data &&
      addAssessmentGoalData.fetching
    ) {
      setLoader(true);
    }
    // eslint-disable-next-line
  }, [addAssessmentGoalData]);

  useEffect(() => {
    if (
      editAssessmentGoalData &&
      editAssessmentGoalData.data &&
      !editAssessmentGoalData.fetching &&
      editAssessmentGoalData.data.message === "success"
    ) {
      showToastMessage('edit');
      dispatch({
        type: RESET_EDIT_ASSESSMENT_GOAL,
        payload: {},
      });
    } else if (
      editAssessmentGoalData &&
      editAssessmentGoalData.data &&
      editAssessmentGoalData.fetching
    ) {
      setLoader(true);
    }
    // eslint-disable-next-line
  }, [editAssessmentGoalData]);

  useEffect(() => {
    if (
      deleteAssessmentGoalData &&
      deleteAssessmentGoalData.data &&
      !deleteAssessmentGoalData.fetching &&
      deleteAssessmentGoalData.data.message === "success"
    ) {
      showToastMessage('delete');
      dispatch({
        type: RESET_DELETE_ASSESSMENT_GOAL,
        payload: {},
      });
    } else if (
      deleteAssessmentGoalData &&
      deleteAssessmentGoalData.data &&
      deleteAssessmentGoalData.fetching
    ) {
      setLoader(true);
    }
    // eslint-disable-next-line
  }, [deleteAssessmentGoalData]);

  const showToastMessage = type => {
    let message = ''
    switch (type) {
      case 'add':
        message = 'Goal added successfully'
        break;
      case 'edit':
        message = 'Goal edited successfully'
        break;
      case 'reorder':
        message = 'Goal reordered successfully'
        break;
      case 'delete':
        message = 'Goal deleted successfully'
        break;
      default:
        break;
    }
    toast(
      <div className="success-text">
        <span className="icon-verified-outline icon"></span>
        <p>
          <span>{message}</span>
        </p>
      </div>,
      {
        position: "top-center",
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  }

  const validateInput = (label, index = 0) => {
    switch (label) {
      case 'goalName':
        ifNonEmptyInput(goalName) ? setGoalName(goalName.trim()) : setGoalName("");
        break;
      case 'goalDescription':
        ifNonEmptyInput(goalDescription) ? setGoalDescription(goalDescription.trim()) : setGoalDescription("");
        break;
      case 'checkBoxLabel':
        ifNonEmptyInput(checkBoxLabel) ? setCheckBoxLabel(checkBoxLabel.trim()) : setCheckBoxLabel("");
        break;
      case 'goalRemark':
        ifNonEmptyInput(goalRemark) ? setGoalRemark(goalRemark.trim()) : setGoalRemark("");
        break;
      case 'optionDesc':
        const goalOptionsArray = [...goalOptions];
        const text = goalOptionsArray[index].option;
        goalOptionsArray[index].option = ifNonEmptyInput(text) ? text.trim() : "";
        setGoalOptions(goalOptionsArray);
        break;
      default:
        break;
    }
  }

  const goalDataOnChange = (value, label, index) => {
    setEnableSave(true);
    dispatch({
      type: OUTSIDE_CLICK_COMMON,
      payload: {
        feature: "AssessmentAdmin",
        changesDone: true,
        OpenModal: "",
        ProceedWithNavigation: "",
      },
    });
    const goalOptionsArray1 = [...goalOptions]
    const goalOptionsArray = [...goalOptions]
    switch (label) {
      case 'goalName':
        if (value.length < 30) {
          setGoalName(value);
          // updateGoalName(value, selectedPillarIndex, selectedGoalIndex)
        }
        break;
      case 'goalDescription':
        setGoalDescription(value);
        break;
      case 'goalRemark':
        setGoalRemark(value);
        break;
      case 'uiType':
        let goalOptionList = []
        if (value === "Check Box") {
          goalOptionList = [{
            option: 'True',
            score: ''
          }, {
            option: 'False',
            score: ''
          }]
        }
        setGoalOptions(goalOptionList);
        setUiType(value);
        setNoOfGoalOptions('');
        break;
      case 'noOfGoalOptions':
        let tempGoalOptions = [];
        const goalOption = {
          option: "",
          score: "",
        };
        if (noOfGoalOptions > value) {
          tempGoalOptions = goalOptions.filter((el, index) => (index < value))
        } else {
          tempGoalOptions = [...goalOptions]
          for (let i = noOfGoalOptions; i < value; i++) {
            tempGoalOptions.push({ ...goalOption });
          }
        }
        setNoOfGoalOptions(value);
        setGoalOptions(tempGoalOptions);
        break;
      case 'optionDesc':
      case 'optionScore':
        if (label === 'optionDesc')
          goalOptionsArray[index].option = value;
        else
          goalOptionsArray[index].score = value;
        setGoalOptions(goalOptionsArray);
        break;
      case 'checkCommitted':
        if (goalOptionsArray1[index] && goalOptionsArray1[index].committed) {
          goalOptionsArray1[index].committed = !goalOptionsArray1[index].committed;
        }
        else {
          goalOptionsArray1[index].committed = true
        }
        setGoalOptions(goalOptionsArray1);
        break;
      case 'checkBoxLabel':
        if (value.length < 30) {
          setCheckBoxLabel(value);
        }
        break;
      case 'checkboxSelectedScore':
      case 'checkboxUnSelectedScore':
        let scoreIndex = 0;
        if (label === 'checkboxUnSelectedScore')
          scoreIndex = 1;
        goalOptionsArray[scoreIndex].score = value
        setGoalOptions(goalOptionsArray);
        break;
      case 'consideForScoring':
        setConsiderForScoring(value);
        break;
      case 'considerForFocused':
        setConsiderForFocused(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let tempGoalOptions = [...goalOptions];
    if (tempGoalOptions.some(item => item.committed === true)) {
      setIsCheckBoxCommited(true);
    } else {
      setIsCheckBoxCommited(false)
    }
  }, [goalOptions])

  const handleFocusChange = (event) => {
    goalDataOnChange(event.target.value, "considerForFocused");
  };

  return (
    // <SimpleBar style={{ maxHeight: "500px" }}>
    <div className="form">
      <div style={{ width: "100%" }}>
        <InputLabel
          error={errorMessages.goalName !== ''}
          htmlFor="uncontrolled-native"
          className={"leftSpacing"}
          disabled={!editable}
          classes={{
            root: classes.labelroot,
          }}>
          {"Goal Name*"}
        </InputLabel>
        <TextField
          id="filled-basic"
          variant="filled"
          fullWidth
          disableUnderline={!editable}
          disabled={!editable}
          className={classes.root}
          required={true}
          value={goalName}
          error={errorMessages.goalName !== ''}
          helperText={errorMessages.goalName}
          onChange={(e) =>
            goalDataOnChange(e.target.value, "goalName")
          }
          onBlur={() => validateInput("goalName")}
        />
      </div>
      <div className={classes.topSpacing}>
        <div style={{ width: "100%" }}>
          <InputLabel
            htmlFor="uncontrolled-native"
            // className={classes.topSpacing}
            error={errorMessages.goalDescription !== ''}
            disabled={!editable}
            classes={{
              root: classes.labelroot,
            }}>
            {"Goal Description*"}
          </InputLabel>
          <TextField
            id="filled-basic"
            variant="filled"
            error={errorMessages.goalDescription !== ''}
            helperText={errorMessages.goalDescription}
            fullWidth
            disableUnderline={!editable}
            disabled={!editable}
            multiline
            maxRows={2}
            className={classes.rootMulti}
            required={true}
            value={goalDescription}
            onChange={(e) =>
              goalDataOnChange(
                e.target.value,
                "goalDescription"
              )
            }
            onBlur={() => validateInput("goalDescription")}
          />
        </div>
      </div>
      <div className={classes.topSpacing} style={{
        width: '100%'
      }}>
        <div style={{
          border: "1px solid #C4C4CD",
          padding: "5px",
          display: "flex"
        }}>
          <span
            className="ql-formats"
            style={{ left: "10px", fontSize: "11px" }}>
            Goal Note
          </span>
          <span className="ql-formats" style={{
            display: 'block',
            marginLeft: 'auto'
          }}>
          </span>
        </div>
        <div style={{
          border: "1px solid #C4C4CD",
          padding: "5px"
        }}>
          <div style={{
            border: "1px solid #C4C4CD",
            padding: "5px",
            display: "flex"
          }}> <TextField
              fullWidth
              value={goalRemark}
              multiline
              id="filled-basic"
              variant="filled"
              disabled={!editable}
              maxRows={2}
              className={classes.rootMulti}
              required={true}
              onChange={(e) =>
                goalDataOnChange(e.target.value, "goalRemark")
              }
              onBlur={() => validateInput("goalRemark")}
              InputProps={{ disableUnderline: true, className: classes.input }}
            /><div className="customReactQ">
            </div>
          </div>
        </div>
      </div >
      <Grid container spacing={.5}>
        <Grid item xs={3}>
          <DropdownComponent
            label="Goal Type"
            defaultValue={uiType}
            required={true}
            selectOnChange={(dummy, value) => {
              goalDataOnChange(value, "uiType");
            }}
            varName="uiType"
            list={Object.values(inputValuesforType).map(input => (input || ''))}
            errorMessage={errorMessages}
            disableUnderline={!editable}
            disabled={!editable}
            customClass={"narrowtextField"}
          />
        </Grid>
        <Grid item xs={3}>
          <div
            style={{
              width: '100%',
              opacity: (uiType === "Check Box" || uiType === '') ? .5 : 1
            }}>
            <DropdownComponent
              label={"No.of Goal Options"}
              defaultValue={noOfGoalOptions}
              required={uiType === "Radio Button" || uiType === 'Drop Down'}
              selectOnChange={(dummy, value) => {
                goalDataOnChange(value, "noOfGoalOptions");
              }}
              varName="noOfGoalOptions"
              list={goalOptionsConstant}
              disableFirstItem
              errorMessage={{ ...errorMessages, noOfGoalOptions: (uiType === "Radio Button" || uiType === 'Drop Down') ? errorMessages.noOfGoalOptions : '' }}
              disableUnderline={!editable}
              disabled={!editable || uiType === "Check Box" || uiType === ''}
              customClass={"narrowtextField"}
            />
          </div>
        </Grid>
        <Grid item xs={6}>
          <div style={{
            height: '100%',
            marginTop: '5px',
            padding: 0
          }}>
            <div style={{ display: "flex", height: "100%" }}>
              <Grid
                container
                spacing={0}
                align="left"
                display="flex"
                justify="space-around"
                direction="row"
              >
                <Grid item style={{ marginTop: '9px' }}>
                  <RadioButton
                    label={"Consider for scoring"}
                    defaultValue={considerForScoring}
                    required={true}
                    varName="considerForScoring"
                    changeCallback={(value) => {
                      goalDataOnChange(value, "consideForScoring");
                    }}
                    list={['Yes', 'No']}
                    errorMessage={errorMessages}
                    disableUnderline={!editable}
                    disabled={!editable}
                    component={"scorefieldset"}
                    key={"scorefieldset"}
                  />
                </Grid>
                <Grid item style={{ marginTop: '9px' }}>
                  <FormControl
                    component="focusfieldset"
                    disabled={!editable}
                    error={errorMessages["considerForFocused"] !== ""}>
                    <InputLabel
                      htmlFor="focus-uncontrolled-native"
                      className={"leftSpacing"}
                      classes={{
                        root: classes.focuslabelroot,
                      }}
                      shrink={true}>
                      {"Consider for focused*"}
                    </InputLabel>
                    <div style={{ marginTop: '5px', marginBottom: '5px' }}>
                      <RadioGroup row aria-label="anonymous" name="considerForFocused" value={considerForFocused}
                        onChange={handleFocusChange}
                      >
                        <FormControlLabel value={"Yes"} control={<Radio color="default" />} label={'Yes'} />
                        <FormControlLabel value={'No'} control={<Radio color="default" />} label={'No'} />
                      </RadioGroup>
                    </div>
                    <FormHelperText
                      classes={{
                        root: classes.helper,
                      }}>
                      {errorMessages["considerForFocused"]}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item>
                  {goalOptions.length > 0 && uiType !== 'Check Box' &&
                    <>
                      <div style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "0px",
                        fontFamily: "EYInterstate-Regular",
                        color: errorMessages.isCheckBoxCommited !== '' ? '#f44336' : 'rgba(0, 0, 0, 0.80)',
                        fontSize: "1rem",
                        marginTop: "10px"
                      }}>
                        Consider as committed* <br />
                        <span
                          style={{
                            color: "#747480",
                            fontFamily: "EYInterstate-Regular",
                            fontSize: "0.8rem",
                            marginLeft: "6px"
                          }}
                        >Need to select at least one</span>
                      </div>
                      <FormHelperText
                        classes={{
                          root: classes.helper,
                        }}>
                        {errorMessages.isCheckBoxCommited}
                      </FormHelperText>
                    </>
                  }
                </Grid>
              </Grid>

            </div>
          </div>
        </Grid>
      </Grid>
      <div>
        {uiType !== "Check Box" && goalOptions.length > 0 && goalOptions.map(
          (value, ind) => {
            return (
              <div className={classes.half}>
                <div
                  style={{
                    width: "50%",
                    marginBottom: "5px",
                    marginTop: "10px",
                  }}>
                  <InputLabel
                    htmlFor="uncontrolled-native"
                    className={"leftSpacing"}
                    error={errorMessages.optionList[ind] && errorMessages.optionList[ind] !== ''}
                    disabled={!editable}
                    classes={{
                      root: classes.labelroot,
                    }}>
                    {uiType +
                      " Option " +
                      (ind + 1) +
                      "*"}
                  </InputLabel>
                  <TextField
                    fullWidth
                    id={"filled-basic" + ind}
                    variant="filled"
                    // multiline
                    className={classes.root}
                    disableUnderline={!editable}
                    disabled={!editable}
                    required={true}
                    error={errorMessages.optionList[ind] && errorMessages.optionList[ind] !== ''}
                    helperText={errorMessages.optionList[ind]}
                    value={goalOptions[ind].option}
                    onChange={(e) =>
                      goalDataOnChange(
                        e.target.value,
                        "optionDesc",
                        ind
                      )
                    }
                    onBlur={() => validateInput("optionDesc", ind)}
                  />
                </div>

                {
                  <div
                    style={{
                      width: "50%",
                      marginBottom: "5px",
                      marginTop: "-5px",
                      marginLeft: "15px",
                      display: "flex"
                    }}>
                    <DropdownComponent
                      label={uiType +
                        " Option " +
                        (ind + 1) +
                        " Score"
                      }
                      defaultValue={goalOptions[ind].score}
                      required={considerForScoring === 'Yes'}
                      selectOnChange={(dummy, value) => {
                        goalDataOnChange(
                          value,
                          "optionScore",
                          ind
                        );
                      }}
                      varName="score"
                      list={goalScoreConstant}
                      errorMessage={{ score: considerForScoring === 'Yes' ? (errorMessages.scoreList[ind] || '') : '' }}
                      disableUnderline={!editable}
                      disabled={!editable || !(considerForScoring === 'Yes')}
                      customClass={"narrowtextField"}
                    />
                    <div
                      style={{
                        width: "50%",
                        marginBottom: "5px",
                        marginTop: "0px",
                        marginLeft: "15px",
                        display: "flex",
                        justifyContent: "center"
                      }}>
                      <Checkbox
                        color="default"
                        defaultValue={false}
                        disabled={!editable}
                        style={{ color: errorMessages.isCheckBoxCommited === '' ? '' : '#f44336' }}
                        checked={goalOptions[ind] && goalOptions[ind].committed}
                        onChange={(e) => {
                          goalDataOnChange(
                            e.target.value,
                            "checkCommitted",
                            ind
                          );
                        }
                        }
                      >
                      </Checkbox>
                    </div>
                  </div>
                }
                {/* {
                  <div
                    style={{
                      width: "10%",
                      marginBottom: "5px",
                      marginTop: "0px",
                      marginLeft: "15px",
                    }}>
                    <Checkbox
                      color="default"
                      defaultValue={false}
                      checked={goalOptions[ind]&&goalOptions[ind].committed}
                      onChange={(e) =>{
                        goalDataOnChange(
                          e.target.value,
                          "checkCommitted",
                          ind
                        );
                      }
                      }
                      >
                    </Checkbox>
                  </div>
                } */}
              </div>
            );
          }
        )}
      </div>
      <Grid container spacing={.5}>
        {uiType === "Check Box" && (
          <>
            <Grid item xs={6} style={{ paddingRight: "15px" }}>
              <div
                style={{
                  width: "100%",
                  marginBottom: "5px",
                  marginTop: "10px",
                }}>
                <InputLabel
                  htmlFor="uncontrolled-native"
                  error={errorMessages.checkBoxLabel !== ''}
                  className={"leftSpacing"}
                  disabled={!editable}
                  classes={{
                    root: classes.labelroot,
                  }}>
                  {"Checkbox Label*"}
                </InputLabel>
                <TextField
                  fullWidth
                  error={errorMessages.checkBoxLabel !== ''}
                  helperText={errorMessages.checkBoxLabel}
                  id={"filled-basic"}
                  variant="filled"
                  className={classes.root}
                  disableUnderline={!editable}
                  disabled={!editable}
                  required={true}
                  value={checkBoxLabel}
                  onChange={(e) =>
                    goalDataOnChange(
                      e.target.value,
                      "checkBoxLabel"
                    )
                  }
                  onBlur={() => validateInput("checkBoxLabel")}
                />


              </div>

            </Grid>
            <Grid item>
              {goalOptions.length > 0 && uiType === 'Check Box' &&
                <>
                  <div style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: "10px",
                    fontFamily: "EYInterstate-Regular",
                    fontSize: "1rem",
                    marginTop: "10px",
                    color: errorMessages.isCheckBoxCommited !== '' ? '#f44336' : 'rgba(0, 0, 0, 0.80)'
                  }}>
                    Consider as committed*<br />
                    <span
                      style={{
                        color: "#747480",
                        fontFamily: "EYInterstate-Regular",
                        fontSize: "0.8rem",
                        marginLeft: "6px"
                      }}
                    >Need to select at least one</span>
                  </div>
                  <FormHelperText
                    classes={{
                      root: classes.helper,
                    }}>
                    {errorMessages.isCheckBoxCommited}
                  </FormHelperText>
                </>
              }
            </Grid>

            {goalOptions.length > 0 && goalOptions.map(
              (value, ind) => {
                const label = ind === 0 ? 'Checkbox if selected - Score' : 'Checkbox if unselected - Score';
                const chanageLabel = ind === 0 ? 'checkboxSelectedScore' : 'checkboxUnSelectedScore';
                return (
                  < Grid item xs={12} >
                    <div
                      style={{
                        width: '100%',
                        marginTop: "-5px",
                        display: "flex",
                        flexDirection: "row"
                      }}>
                      <DropdownComponent
                        label={label}
                        defaultValue={goalOptions[ind].score}
                        required={considerForScoring === 'Yes'}
                        selectOnChange={(dummy, value) => {
                          goalDataOnChange(value, chanageLabel);
                        }}
                        varName="score"
                        list={goalScoreConstant}
                        errorMessage={{ score: considerForScoring === 'Yes' ? (errorMessages.scoreList[ind] || '') : '' }}
                        disableUnderline={!editable}
                        disabled={!editable || !(considerForScoring === 'Yes')}
                        customClass={"widertextField"}
                      />
                      <div
                        style={{
                          width: "10%",
                          marginBottom: "5px",
                          marginTop: "13px",
                          marginLeft: "30px",
                        }}>
                        <Checkbox
                          color='default'
                          defaultValue={false}
                          disabled={!editable}
                          style={{ color: errorMessages.isCheckBoxCommited === '' ? '' : '#f44336' }}
                          checked={goalOptions[ind] && goalOptions[ind].committed}
                          onChange={(e) => {
                            goalDataOnChange(
                              e.target.value,
                              "checkCommitted",
                              ind
                            );
                          }
                          }
                        >
                        </Checkbox>
                      </div>
                    </div>
                  </Grid>
                )
              })
            }
          </>
        )}
      </Grid>
    </div>

    // </SimpleBar>
  );
};
export default withRouter(memo(GoalDetails));
