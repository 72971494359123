import React, { useCallback, useState, useEffect, useContext } from "react";

const PowerBiDashboard = () => {

    return(
        <>
        <div>
        PowerBiDashboard
        </div>
        </>
    );

};
export default PowerBiDashboard;