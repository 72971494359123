import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import * as myConsts from "../../constants/constants";
import AppContext from "../../utils/appContext";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
    letterSpacing: 0,
    fontSize: "14px",
    fontFamily: "EYInterState-regular",
    color: "#747480",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  separator: {
    color: "#747480",
  },
  li: {
    fontSize: "14px",
    color: "#0F0F10",
  },
}));

const Breadcrumb = (props) => {
  const outsideClickCommonState = useSelector(
    (state) => state.outsideClickCommon.data
  );
  const { isAdmin, isSmallFont, crumbs, isInnerAdmin, isRouteFromDiscussionGuide } = props;
  const classes = useStyles();
  const myContext = useContext(AppContext);
  const dispatch = useDispatch();
  const activePPMDuserId =
    myContext.activePPMDuserId || sessionStorage.activePPMDuserId;
  const PPMDData =
    myContext.PPMDData ||
    (sessionStorage.PPMDData && JSON.parse(sessionStorage.PPMDData));

  // Don't render a single breadcrumb.
  /*  if (crumbs && crumbs.length < 1) {
    return null;
  } */

  const navTo = (e, path) => {
    e.preventDefault();
    props.history.push(path);
  };
  const navToForOutsideClick = (path) => {
    // e.preventDefault();
    props.history.push(path);
  };

  const checkOutsideClick = (fn, params1) => {
    if (props.history.location.pathname === "/BookOfBusiness") {
      if (myContext.bobChangeTriggered) {
        dispatch({
          type: "OUTSIDE_CLICK_BOB",
          payload: {
            flag: false,
            callback: { fn: fn, params: params1 },
          },
        });
        myContext.setShowAlertPopupBob(true);
      } else {
        fn && fn(params1);
      }
    } else if (props.history.location.pathname === "/Admin/GeneralParameters") {
      if (myContext.changeTriggered) {
        dispatch({
          type: "OUTSIDE_CLICK_ADMIN",
          payload: {
            flag: false,
            callback: { fn: fn, params: params1 },
          },
        });
        myContext.setShowPopupAdmin(true);
      } else {
        fn && fn(params1);
      }
    } else if (props.history.location.pathname === "/Admin/Assessment") {
      if (
        outsideClickCommonState &&
        outsideClickCommonState.feature !== "" &&
        outsideClickCommonState.changesDone
      ) {
        dispatch({
          type: "OUTSIDE_CLICK_COMMON_OPEN_MODEL",
          payload: {
            callback: { fn: fn, params: params1 },
          },
        });
      } else {
        fn && fn(params1);
      }
    } else if (
      props.history.location.pathname === "/Admin/EmailConfiguration"
    ) {
      if (
        outsideClickCommonState &&
        outsideClickCommonState.feature !== "" &&
        outsideClickCommonState.changesDone
      ) {
        dispatch({
          type: "OUTSIDE_CLICK_COMMON_OPEN_MODEL",
          payload: {
            callback: { fn: fn, params: params1 },
          },
        });
      } else {
        fn && fn(params1);
      }
    } else {
      if (myContext.isAutoSave) {
        dispatch({
          type: "OUTSIDE_CLICK",
          payload: {
            flag: false,
            callback: { fn: fn, params: params1 },
          },
        });
        myContext.setOpenSavePopup(true);
      } else {
        fn && fn(params1);
      }
    }
  };

  const setAdminRoute = (e, route, setUserData) => {
    myContext.setActivePPMDuserId("");
    sessionStorage.setItem("activePPMDuserId", "");
    myContext.setIsRouteFromAdmin(false);
    sessionStorage.removeItem("profilePhase");
    dispatch({
      type: "GET_USER_PROFILE_BY_ID",
      payload: {
        userId: myContext.userId,
      },
    });
    if (setUserData) {
      dispatch({
        type: "SET_VIEW_USER_DATA",
        payload: {
          viewData: true,
        },
      });
    } else {
      sessionStorage.setItem("viewUserProfile", false);
    }
    navTo(e, route);
  };

  return (
    <div>
      <Breadcrumbs
        separator={
          <NavigateNextIcon fontSize="inherit" className={classes.separator} />
        }
        aria-label="breadcrumb"
        classes={classes}>
        <Link
          color="inherit"
          className={classes.li}
          style={
            isSmallFont
              ? { fontSize: 10, cursor: "pointer" }
              : { cursor: "pointer" }
          }
          href="/"
          onClick={(e) => {
            sessionStorage.removeItem("currentPageName");
            if (
              props.history.location.pathname === "/BookOfBusiness" ||
              props.history.location.pathname === "/Assessment" ||
              props.history.location.pathname === "/Admin/GeneralParameters" ||
              props.history.location.pathname === "/Admin/Assessment" ||
              props.history.location.pathname === "/Admin/EmailConfiguration"
            ) {
              e.preventDefault();
              checkOutsideClick(navToForOutsideClick, myConsts.routeHome);
            } else navTo(e, myConsts.routeHome);
          }}>
          {isAdmin || myContext.isRouteFromAdmin ? (
            <span className="admin-crumb">Home</span>
          ) : (
            <i
              className="icon-home"
              style={{
                fontSize: 18,
              }}
            />
          )}
        </Link>
        {activePPMDuserId && activePPMDuserId !== null && (
          <>
            {myContext.isRouteFromAdmin ? (
              <>
                <Link
                  color="inherit"
                  className={classes.li}
                  href="/"
                  key={6}
                  onClick={(e) => setAdminRoute(e, myConsts.routeAdmin, false)}
                  style={{ cursor: "pointer" }}>
                  Admin
                </Link>
              </>
            ) : isRouteFromDiscussionGuide ? (
              <Link
                color="inherit"
                className={classes.li}
                href="/"
                key={6}
                onClick={(e) => {
                  navTo(e, myConsts.routeDiscussionGuide);
                }}
                style={{ cursor: "pointer" }}>
                Finalization Guidance
              </Link>
            )
              :
              (
                <Link
                  color="inherit"
                  className={classes.li}
                  href="/"
                  key={6}
                  onClick={(e) => {
                    if (props.history.location.pathname === "/Assessment") {
                      e.preventDefault();
                      checkOutsideClick(
                        navToForOutsideClick,
                        myConsts.routePpmdProfiles
                      );
                    } else navTo(e, myConsts.routePpmdProfiles);
                  }}
                  style={{ cursor: "pointer" }}>
                  PPMD Profiles
                </Link>
              )}
          </>
        )}
        {myContext.isRouteFromAdmin && activePPMDuserId && activePPMDuserId !== null && (
          <Link
            color="inherit"
            className={classes.li}
            href="/"
            key={6}
            onClick={(e) =>
              setAdminRoute(e, myConsts.routeAdminUserManagement, false)
            }
            style={{ cursor: "pointer" }}>
            User Management
          </Link>
        )}
        {myContext.isRouteFromAdmin && activePPMDuserId && activePPMDuserId !== null && (
          <Link
            color="inherit"
            className={classes.li}
            href="/"
            key={6}
            onClick={(e) =>
              setAdminRoute(e, myConsts.routeAdminUserManagement, true)
            }
            style={{ cursor: "pointer" }}>
            View User Profiles
          </Link>
        )}
        {activePPMDuserId &&
          activePPMDuserId !== null &&
          (crumbs.length < 1 ? (
            <Typography className={classes.li}>
              {PPMDData.preferredName}
            </Typography>
          ) : (
            <Link
              color="inherit"
              className={classes.li}
              href="/"
              key={7}
              onClick={(e) => {
                if (props.history.location.pathname === "/Assessment") {
                  e.preventDefault();
                  checkOutsideClick(
                    navToForOutsideClick,
                    myConsts.routeHome + "/" + activePPMDuserId
                  );
                } else navTo(e, myConsts.routeHome + "/" + activePPMDuserId);
              }}
              style={{ cursor: "pointer" }}>
              {PPMDData.preferredName}
            </Link>
          ))}
        {crumbs &&
          crumbs.map(({ name, path }, key) =>
            !isInnerAdmin && key + 1 === crumbs.length ? (
              <Typography className={classes.li} key={key}>
                {name}
              </Typography>
            ) : (
              <Link
                className={isInnerAdmin && "admin-crumb"}
                key={key}
                onClick={(e) => {
                  sessionStorage.removeItem("currentPageName");
                  if (
                    props.history.location.pathname === "/BookOfBusiness" ||
                    props.history.location.pathname === "/Assessment" ||
                    props.history.location.pathname ===
                    "/Admin/GeneralParameters" ||
                    props.history.location.pathname === "/Admin/Assessment" ||
                    props.history.location.pathname ===
                    "/Admin/EmailConfiguration"
                  ) {
                    e.preventDefault();
                    checkOutsideClick(navToForOutsideClick, path);
                  } else navTo(e, path);
                }}
                color="inherit"
                style={{ cursor: "pointer" }}>
                {name}
              </Link>
            )
          )}
      </Breadcrumbs>
    </div>
  );
};
export default Breadcrumb;
