import React, { useState, useContext, useEffect } from "react";
import SideMenu from "../SideMenu/SideMenu";
import "simplebar/dist/simplebar.min.css";
import "./Home.scss";
import AppContext from "../../utils/appContext";
import DropdownComponent from "../Admin/AdminGeneralParam/DropdownComponent/DropdownComponent";
import Alert from "../Alert/AlertComponent";
import { useAlert } from "../../helpers/useAlert";
import { useDispatch, useSelector } from "react-redux";
import Popper from "@material-ui/core/Popper";
import { toast } from "react-toastify";
import { Flip } from "react-toastify";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  authProvider,
  APIscope,
  GraphExplorerscopes,
} from "./../../Authentication/authProvider";
import Loader from "../Loader/Loader";
import { Autocomplete } from "../../helpers/Autocomplete";
import SimpleBar from "simplebar-react";
// import lead4d from "../../assets/images/Lead4D_image.png";
// import Image1 from "../../assets/images/technicalUserGuide.jpeg";
// import Image2 from "../../assets/images/midYearPlaybook.jpeg";
// import Image3 from "../../assets/images/midYearFAQ.jpeg";
// import Image4 from "../../assets/images/QuickstartGuide.jpeg";
import play from "../../assets/images/play.png";
import Modal from "react-responsive-modal";
import {
  extractFirstLetters,
  decodeIdToken,
  getEYFinancialYear,
} from "../../utils/utils";
import * as myConsts from "../../constants/constants";
import ApprovalPopup from "../Common/ApprovalPopup";
import TimePeriod from "../TimePeriod/TimePeriod";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumb from "../Breadcrumbs/Breadcrumbs";
import { Tooltip } from "@material-ui/core";
import _ from "lodash";
import IntroductionCard from "../IntroductionCard/IntroductionCard"
import Parser from 'html-react-parser';
import Preview from "../Admin/ContentManagement/WelcomeMessage/Preview";
import { imagePos } from "../Admin/ContentManagement/IntroductionCards/IntroductionCards";

const lead4d = "https://sites.ey.com/:i:/r/sites/LEAD4D/Shared%20Documents/General/Phase%204/Lead4D-Images/Lead4D_image.png?csf=1&web=1&e=1xeaoa";
const Image1 = "https://sites.ey.com/:i:/r/sites/LEAD4D/Shared%20Documents/General/Phase%204/Lead4D-Images/technicalUserGuide.jpeg?csf=1&web=1&e=3ByzNH";
const Image2 = "https://sites.ey.com/:i:/r/sites/LEAD4D/Shared%20Documents/General/Phase%204/Lead4D-Images/midYearPlaybook.jpeg?csf=1&web=1&e=JGwMLR";
const Image3 = "https://sites.ey.com/:i:/r/sites/LEAD4D/Shared%20Documents/General/Phase%204/Lead4D-Images/midYearFAQ.jpeg?csf=1&web=1&e=xOtikC";
const Image4 = "https://sites.ey.com/:i:/r/sites/LEAD4D/Shared%20Documents/General/Phase%204/Lead4D-Images/QuickstartGuide.jpeg?csf=1&web=1&e=dxbJnA";

const Home = (props) => {
  const {
    match: { params },
  } = props;
  const dispatch = useDispatch();
  const assessmentMetaData = useSelector((state) => state.getAssesmentMetaData);
  const commentRead = useSelector((state) => state.commentRead);
  const userAssessmentStatus = useSelector(
    (state) => state.userAssessmentStatus
  );
  const userProfileData = useSelector((state) => state.userProfile);
  const getApprovedCompletedYearsList = useSelector(
    (state) => state.getApprovedCompletedYearsList
  );
  const timePeriodStatus = useSelector((state) => state.timePeriodStatus);

  const acceptOrRejectCounselee = useSelector(
    (state) => state.acceptOrRejectCounselee
  );
  const generalContent = useSelector(
    (state) => state.generalContent
  );
  const introCards = _.get(generalContent, "data.introCards", []);
  const introCardContent = introCards.sort((a, b) => imagePos.indexOf(a.key) - imagePos.indexOf(b.key));
  const welcomeMessageContent = _.get(generalContent, "data.welcomeMsg", {});
  const myContext = useContext(AppContext);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loader, setLoader] = useState(false);
  const [sideMenuItemSelected, setSideMenuItemSelected] = useState();
  const [isAssessmentStarted, setIsAssessmentStarted] = useState(false);
  const userData = useSelector((state) => state.getUserDetails);
  const userImg = useSelector((state) => state.getUserImg);
  const [fyYear, setFyYearList] = useState(["FY 2022"]);
  const [fy, setFyYear] = useState("FY " + getEYFinancialYear());
  const [userProfile, setUserProfile] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [assessmentMetaDetails, setAssessmentMetaDetails] = useState(null);
  const [linkDirectToSummary, setLinkDirectToSummary] = useState(false);
  const [showApprovalPopup, setShowApprovalPopup] = useState(false);
  const [userPhaseFlag, setUserPhaseFlag] = useState({});

  const counseleesFinalizationDetails = useSelector((state) => state.getCounseleeDiscussionGuide);

  const crumbs = [];
  const errorMessages = {
    fyYear: "",
  };
  const open = Boolean(anchorEl);
  //const id = open ? "simple-popper" : undefined;
  const useStyles = makeStyles((theme) => ({
    paper: {
      border: "1px solid #42425ad6",
      fontSize: "11px",
      padding: "15px",
      maxWidth: "750px",
      backgroundColor: theme.palette.background.paper,
    },
    tooltip: {
      fontSize: 10,
    },
  }));

  const classes = useStyles();
  const approvalPopupShow = () => {
    setShowApprovalPopup(true);
  };
  const clickAwayHandler = () => {
    if (anchorEl) callAPIToIndicateAsRead();
  };
  const callAPIToIndicateAsRead = async () => {
    // eslint-disable-next-line
    {
      setAnchorEl(null);
      if (!myContext.reviewerMessage.read) {
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        dispatch({
          type: "COMMENT_READ",
          payload: {
            token: idToken,
            financialYear: myContext.financialYear,
            timePeriod: myContext.activePeriod,
          },
        });
      }
    }
  };
  const cancelApprovalPopup = () => {
    setShowApprovalPopup(false);
  };

  const [userDp, setUserDp] = useState("");
  useEffect(() => {
    (async function () {
      try {
        setLoader(true);
        const token = await authProvider.acquireTokenSilent({
          scopes: APIscope,
        });
        const idToken = token.accessToken;
        if (!getCurrentUser()) {
          if (userProfileData.data[0].role === "Admin") {
            props.history.push(myConsts.routeAdmin);
          } else {
            props.history.push(myConsts.routeHome);
          }
          return;
        }

        dispatch({
          type: "GET_USER_PROFILE_BY_ID",
          payload: {
            tokens: idToken,
            userId: getCurrentUser(),
            financialYear: myContext.financialYear,
          },
        });
      } catch (e) {
        console.error(e);
      }
    })();
    // eslint-disable-next-line
    sessionStorage.removeItem("currentPageName");
  }, [props.location.pathname, myContext.financialYear]);

  useEffect(async () => {
    const idtoken = await authProvider.acquireTokenSilent({
      scopes: GraphExplorerscopes,
    });
    const token = idtoken.accessToken;
    dispatch({
      type: "GET_COUNSELEE_DISCUSSION_GUIDE",
      payload: {
        ad_token: token,
        financialYear: myContext.financialYear
      },
    });
    dispatch({
      type: "GET_GENERAL_CONTENT",
      payload: {},
    });
    if (
      assessmentMetaData &&
      assessmentMetaData.data &&
      assessmentMetaData.data.data &&
      !assessmentMetaData.data.data.isAssessmentStarted
    ) {
      dispatch({
        type: "GET_ASSESSMENT_META_DATA",
        payload: {
          financialYear: myContext.financialYear,
          userId: getCurrentUser(),
        },
      });
    }
    if (activePPMDuserId === undefined)
      dispatch({
        type: "GET_YEARS_LIST",
        payload: {},
      });
  }, []);

  const getCurrentUser = () => {
    const ppmdID =
      myContext.activePPMDuserId || sessionStorage.activePPMDuserId;
    const userId = myContext.userId || sessionStorage.getItem("userId");
    return props.location.pathname != "/Assessment/summary" &&
      props.location.pathname.split("/").length > 2
      ? ppmdID
      : userId;
  };
  useEffect(() => {
    (async function () {
      try {
        const idtoken = await authProvider.acquireTokenSilent({
          scopes: GraphExplorerscopes,
        });
        const token = idtoken.accessToken;
        if (props.location.pathname.split("/").length === 2) {
          setUserDp("");
          myContext.setActivePPMDuserId("");
          myContext.setPPMDData("");
          sessionStorage.removeItem("activePPMDuserId");
          sessionStorage.removeItem("PPMDData");
          setDisplayName(decodeIdToken(token).name);
        } else {
          const name =
            myContext.PPMDData.preferredName ||
            JSON.parse(sessionStorage.PPMDData).preferredName;
          setDisplayName(name);
          //API forPPMD image
          const ppmdId =
            myContext.activePPMDuserId || sessionStorage.activePPMDuserId;
          if (!ppmdId) {
            return;
          }
          const url = `${myConsts.msHostName + "/users/" + ppmdId + myConsts.getUserImg
            }`;
          const options = {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              userid: myContext.activePPMDuserId,
            },
          };
          await fetch(url, options)
            .then(async (r) => {
              if (r.status === 200 && !r.bodyUsed) return r;
            })
            .then(async (response) => {
              const l = await response.blob();
              if (l.size > 0) {
                let href = window.URL.createObjectURL(l);
                setUserDp(href);
              }
            });
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [params.ppmdID, props.location, myContext]);

  useEffect(() => {
    if (
      assessmentMetaData &&
      assessmentMetaData.data &&
      assessmentMetaData.data.data
    ) {
      const data = assessmentMetaData.data.data;
      const activePhase =
        data.activePhaseYear == myContext.financialYear
          ? data.activePhase
          : null;

      myContext.setActiveFinancialYear(data.activePhaseYear);
      sessionStorage.setItem("activefinancialYear", data.activePhaseYear);
      if (data.activePhaseYear == null) {
        myContext.setlastActiveFinancialYear(data.lastActivePhaseYear);
        sessionStorage.setItem(
          "lastactivefinancialYear",
          data.lastActivePhaseYear
        );
      }
      if (sessionStorage.getItem("isValidUser") === null) {
        sessionStorage.setItem("isValidUser", data.validUser);
        myContext.setIsValidUser(data.validUser);
        if (!data.validUser) {
          props.history.push(myConsts.routeNotValidUser);
        }
      }
      if (
        activePhase === null ||
        (data.assessmentMetaData &&
          data.assessmentMetaData.leadReviewer_CompletionDate &&
          new Date() >
          new Date(data.assessmentMetaData.leadReviewer_CompletionDate)) ||
        (myContext.currentPeriod !== null &&
          activePhase !== null &&
          activePhase !== myContext.currentPeriod)
      ) {
        sessionStorage.setItem("activePhase", activePhase);
        myContext.setActivePhase(activePhase);
        sessionStorage.setItem("formsInActive", true);
        myContext.setFormsInActive(true);
        if (
          activePhase === null ||
          (data.assessmentMetaData &&
            data.assessmentMetaData.leadReviewer_CompletionDate &&
            new Date() >
            new Date(data.assessmentMetaData.leadReviewer_CompletionDate))
        ) {
          dispatch({
            type: "GET_TIME_PERIOD_STATUS",
            payload: { financialYear: myContext.financialYear },
          });
        }
      } else {
        if (sessionStorage.getItem("activePeriod") === null) {
          sessionStorage.setItem("activePeriod", activePhase);
        }

        sessionStorage.setItem("activePhase", activePhase);
        myContext.setActivePhase(activePhase);
        if (
          sessionStorage.getItem("currentPeriod") === null ||
          !myContext.currentPeriod
        ) {
          sessionStorage.setItem("currentPeriod", activePhase);
          myContext.setCurrentPeriod(activePhase);
          setTab(activePhase);
        }

        sessionStorage.setItem("formsInActive", false);
        myContext.setFormsInActive(false);
        //setLoader(false);
        myContext.setActivePeriod(activePhase);
      }
      myContext.setUserRole(data.userRole); //data.userRole,LeadReviewer-PPMD
      setIsAssessmentStarted(data.isAssessmentStarted);
      setAssessmentMetaDetails(data.assessmentMetaData);
      if (myContext.isRouteFromAdmin) {
        sessionStorage.setItem(
          "currentPeriod",
          sessionStorage.getItem("profilePhase")
        );
        myContext.setCurrentPeriod(sessionStorage.getItem("profilePhase"));
        setTab(sessionStorage.getItem("profilePhase"));
      }
    }
  }, [assessmentMetaData]); //, myContext, props.history]);

  useEffect(() => {
    if (
      userProfileData &&
      userProfileData.data &&
      userProfileData.data.length > 0
    ) {
      const data = userProfileData.data;
      const flag = userProfileData.data.find(
        (item) => item.financialYear === myContext.financialYear
      );
      if (flag) {
        setUserPhaseFlag(flag.phaseFlag);
      } else {
        setUserPhaseFlag({
          Plan: false,
          "Mid-Year": false,
          "Year-End": false,
        });
      }
      setUserProfile(data[0]);
      setLoader(false);
    }
  }, [userProfileData]);

  useEffect(() => {
    if (
      getApprovedCompletedYearsList &&
      getApprovedCompletedYearsList.data &&
      getApprovedCompletedYearsList.data.status &&
      getApprovedCompletedYearsList.data.status == "success"
    ) {
      const data = [];
      getApprovedCompletedYearsList.data.data.forEach((item) =>
        data.push("FY " + item)
      );
      // data.push("FY 2024");
      let fyCurrentYr = "FY " + myContext.financialYear;
      setFyYearList([fyCurrentYr]);
      if (activePPMDuserId === undefined) {
        if (
          myContext.activeFinancialYear &&
          myContext.activeFinancialYear !== myContext.financialYear
        ) {
          // if (myContext.appFreshLoad) {
          myContext.setFinancialYear(myContext.activeFinancialYear);
          sessionStorage.setItem(
            "financialYear",
            myContext.activeFinancialYear
          );
          sessionStorage.removeItem("activePeriod");
          myContext.setActivePeriod(null);
          sessionStorage.removeItem("currentPeriod");
          myContext.setCurrentPeriod(null);
          setTab(null);
          dispatch({
            type: "GET_ASSESSMENT_META_DATA_RESET",
            payload: {},
          });
          dispatch({
            type: "GET_TIME_PERIOD_STATUS_RESET",
            payload: {},
          });
          // }
        } else if (
          myContext.activeFinancialYear == null &&
          myContext.activeFinancialYear !== myContext.lastActiveFinancialYear
        ) {
          // if (myContext.appFreshLoad) {
          myContext.setFinancialYear(myContext.lastActiveFinancialYear);
          sessionStorage.setItem(
            "financialYear",
            myContext.lastActiveFinancialYear
          );
          sessionStorage.removeItem("activePeriod");
          myContext.setActivePeriod(null);
          sessionStorage.removeItem("currentPeriod");
          myContext.setCurrentPeriod(null);
          setTab(null);
          dispatch({
            type: "GET_ASSESSMENT_META_DATA_RESET",
            payload: {},
          });
          dispatch({
            type: "GET_TIME_PERIOD_STATUS_RESET",
            payload: {},
          });
          // }
        }
      }
    }
  }, [getApprovedCompletedYearsList]);

  useEffect(() => {
    if (
      timePeriodStatus &&
      timePeriodStatus.data &&
      Object.keys(timePeriodStatus.data).length > 0 &&
      timePeriodStatus.year &&
      timePeriodStatus.year === myContext.financialYear
    ) {
      let activePhase;
      let arrayToBeSorted = Object.keys(timePeriodStatus.data);
      const properties = myConsts.TimePeriodsArray.filter((v) =>
        arrayToBeSorted.includes(v)
      ).reverse();

      for (var i = 0; i < properties.length; i++) {
        if (timePeriodStatus.data[properties[i]]) {
          activePhase = properties[i];
          break;
        }
      }
      if (sessionStorage.getItem("activePeriod") === null) {
        sessionStorage.setItem("activePeriod", activePhase);
      }
      if (
        sessionStorage.getItem("currentPeriod") === null ||
        !myContext.currentPeriod
      ) {
        sessionStorage.setItem("currentPeriod", activePhase);
        myContext.setCurrentPeriod(activePhase);
        setTab(activePhase);
      }
      myContext.setActivePeriod(activePhase);
    }
  }, [timePeriodStatus]);

  useEffect(() => {
    if (
      acceptOrRejectCounselee &&
      acceptOrRejectCounselee.data &&
      acceptOrRejectCounselee.data.statuscode &&
      acceptOrRejectCounselee.data.statuscode === "200"
    ) {
      if (props.history.location.pathname !== myConsts.routeHome)
        toast(
          <div>
            <i className="icon-verified-outline" /> Thank you for your Response.
          </div>,
          {
            position: "bottom-center",
            transition: Flip,
            autoClose: 1500,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
    }
  }, [acceptOrRejectCounselee, props.history]);

  useEffect(() => {
    (async function () {
      try {
        if (
          acceptOrRejectCounselee &&
          acceptOrRejectCounselee.data &&
          acceptOrRejectCounselee.data.statuscode &&
          acceptOrRejectCounselee.data.statuscode === "200"
        ) {
          setLoader(false);

          const idtoken = await authProvider.acquireTokenSilent({
            scopes: APIscope,
          });
          const token = idtoken.accessToken;
          dispatch({
            type: "GET_USER_BOB_SUMMARY",
            payload: {
              token: token,
              financialYear: myContext.financialYear,
              timePeriod: myContext.currentPeriod,
              userId: getCurrentUser(),
            },
          });
          dispatch({
            type: "GET_USER_ASSESSMENT_STATUS",
            payload: {
              token: token,
              financialYear: myContext.financialYear,
              timePeriod: myContext.currentPeriod,
              userId: getCurrentUser(),
            },
          });
          dispatch({
            type: "APPROVE_OR_REJECT_COUNSELEE_RESET",
            payload: {},
          });
        }
      } catch (e) {
        console.error(e);
      }
    })();
    // eslint-disable-next-line
  }, [acceptOrRejectCounselee]);

  useEffect(() => {
    try {
      if (commentRead && commentRead.fetching) {
        setLoader(true);
      } else if (
        commentRead &&
        !commentRead.fetching &&
        commentRead.data &&
        commentRead.data.payload &&
        commentRead.data.payload.statuscode &&
        commentRead.data.payload.statuscode === "200"
      ) {
        myContext.updateReviewerMessage({
          ...myContext.reviewerMessage,
          read: true,
        });
        setLoader(false);
        dispatch({
          type: "COMMENT_READ_RESET",
          payload: {},
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, [commentRead, myContext]);

  useEffect(() => {
    if (props.location) {
      setSideMenuItemSelected("Home");
    }
    dispatch({
      type: "OUTSIDE_CLICK_RESET",
      payload: {},
    });
    if (myContext && myContext.lockPopup) toast.dismiss();
  }, [props.location, dispatch]);

  useEffect(() => {
    myContext.setIsWarningPopup(false);
    dispatch({ type: "RESET_DASHBOARD_PILLAR_FILTERS", payload: {} });
    dispatch({ type: "RESET_DASHBOARD_GOAL_FILTERS", payload: {} });
    if (Array.isArray(document.getElementsByClassName("not-found-wrap")))
      document.getElementsByClassName("not-found-wrap")[0].style.display =
        "block";
  }, [dispatch, myContext]);

  useEffect(() => {
    if (params.isSummary && params.isSummary === "summary") {
      setLinkDirectToSummary(true);
    }
    // eslint-disable-next-line
  }, [params.isSummary]);

  useEffect(() => {
    setActiveIndexTime(
      myConsts.TimePeriodsArray.indexOf(myContext.activePeriod)
    );
    // eslint-disable-next-line
  }, [myContext.activePeriod]);

  useEffect(() => {
    if (generalContent.fetching) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [generalContent]);

  const navigateToAssessment = async () => {
    const token = await authProvider.acquireTokenSilent({
      scopes: APIscope,
    });
    if (
      !isAssessmentStarted &&
      myContext.activeFinancialYear &&
      myContext.activePhase &&
      myContext.activeFinancialYear === myContext.financialYear &&
      myContext.activePhase === tabValue
    ) {
      dispatch({
        type: "MARK_ASSESSMENT_START",
        payload: {
          token: token.accessToken,
          financialYear: myContext.financialYear,
          activePeriod: myContext.activePeriod,
        },
      });
    }
    props.history.push(myConsts.routeAssessment);
  };

  const [isOpen, setOpen] = useState(false);
  const [tabValue, setTab] = useState(
    myContext.currentPeriod || myContext.activePeriod
  );

  const navToNewTab = (type) => {
    let newWindow;
    switch (type) {
      // case "introducing":
      //   setOpen(true);
      //   break;
      case "playbook":
        newWindow = window.open(
          "https://eyus.sharepoint.com/sites/AmericasAssuranceNewsHub/Site%20Assets/files/LEAD%204D/YE-LEAD-4D-Playbook.pdf",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      case "faq":
        newWindow = window.open(
          "https://eyus.sharepoint.com/sites/AmericasAssuranceNewsHub/Site%20Assets/files/LEAD%204D/LEAD-4D-FAQ-YE.pdf",
          "_blank",
          "noopener,noreferrer"
        );
      case "technical_enablement":
        newWindow = window.open(
          "https://eyus.sharepoint.com/sites/AmericasAssuranceNewsHub/Site%20Assets/files/LEAD%204D/LEAD-4D-UserGuide.pdf",
          "_blank",
          "noopener,noreferrer"
        );
      case "reviewer":
        newWindow = window.open(
          "https://eyus.sharepoint.com/sites/AmericasAssuranceNewsHub/Site%20Assets/files/LEAD%204D/LEAD-4D-QuickStartGuide-YE.pdf",
          "_blank",
          "noopener,noreferrer"
        );
        break;
      default:
        break;
    }
    if (newWindow) newWindow.opener = null;
  };

  const [assessmentStatus, setAssessmentStatus] = useState(
    myConsts.statusNotStarted
  );
  const [userAssessmentStatusData, setUserAssessmentStatusData] =
    useState(null);
  const [userAssessmentProgress, setUserAssessmentProgress] = useState(0);

  useEffect(() => {
    if (userAssessmentStatus && userAssessmentStatus.data) {
      const assessmentData = userAssessmentStatus.data;
      if (
        assessmentData.data &&
        Object.keys(assessmentData.data).length > 0 &&
        assessmentData.data !== "no data"
      ) {
        setAssessmentStatus(assessmentData.data.status.toLowerCase());
        myContext.setAssessmentStatus(assessmentData.data.status.toLowerCase());
        setUserAssessmentStatusData(assessmentData.data);
        let progress = assessmentData.data.progress || 0;
        if (
          assessmentData.data.status.toLowerCase() === myConsts.statusSubmitted
        ) {
          myContext.setAssessmentSubmissionStatus(true);
          sessionStorage.setItem("assessmentSubmissionStatus", true);
          myContext.setDisableUpdateSummary(true);
          sessionStorage.setItem("disableUpdateSummary", true);
          myContext.updateReviewerMessage({
            msg: "",
            from: "",
            read: true,
          });
        } else if (
          (assessmentData.data.status.toLowerCase() ===
            myConsts.statusRequestedChange ||
            assessmentData.data.status.toLowerCase() ===
            myConsts.statusCompleted) &&
          activePPMDuserId &&
          activePPMDuserId !== null
        ) {
          myContext.setDisableUpdateSummary(true);
          sessionStorage.setItem("disableUpdateSummary", true);
          myContext.updateReviewerMessage({
            msg: "",
            from: "",
            read: true,
          });
          myContext.setAssessmentSubmissionStatus(
            assessmentData.data.status.toLowerCase() ===
            myConsts.statusCompleted
          );
        } else if (
          assessmentData.data.status.toLowerCase() ===
          myConsts.statusRequestedChange ||
          assessmentData.data.status.toLowerCase() === myConsts.statusCompleted
        ) {
          myContext.updateReviewerMessage({
            msg:
              assessmentData.data.comment && assessmentData.data.comment.msg
                ? assessmentData.data.comment.msg
                : "",
            from:
              assessmentData.data.comment && assessmentData.data.comment.from
                ? assessmentData.data.comment.from
                : "",
            read:
              assessmentData.data.comment && assessmentData.data.comment.read,
          });
          myContext.setDisableUpdateSummary(
            assessmentData.data.status.toLowerCase() ===
            myConsts.statusCompleted
          );
          sessionStorage.setItem(
            "disableUpdateSummary",
            assessmentData.data.status.toLowerCase() ===
            myConsts.statusCompleted
          );
          myContext.setAssessmentSubmissionStatus(
            assessmentData.data.status.toLowerCase() ===
            myConsts.statusCompleted
          );
          sessionStorage.setItem(
            "assessmentSubmissionStatus",
            assessmentData.data.status.toLowerCase() ===
            myConsts.statusCompleted
          );
        } else {
          myContext.setDisableUpdateSummary(false);
          sessionStorage.setItem("disableUpdateSummary", false);
          myContext.setAssessmentSubmissionStatus(false);
          sessionStorage.setItem("assessmentSubmissionStatus", false);
          myContext.updateReviewerMessage({
            msg: "",
            from: "",
            read: true,
          });
        }
        setUserAssessmentProgress(progress);
        if (linkDirectToSummary) {
          myContext.setNavToSummary(true);
          props.history.push(myConsts.routeAssessment);
        }
      } else {
        setUserAssessmentStatusData(null);
      }
    }
    // eslint-disable-next-line
  }, [userAssessmentStatus, props.location]);

  const getRouteTextOnStatus = () => {
    if (
      myContext.bobStatus === myConsts.statusNotStarted ||
      myContext.bobStatus === myConsts.statusInProgress ||
      myContext.bobStatus === myConsts.statusRequestedChange
    ) {
      if (myContext.formsInActive)
        return {
          txt: "View Book of Business",
          route: myConsts.routeBookOfBusiness,
        };
      else
        return {
          txt: "Validate Book of Business",
          route: myConsts.routeBookOfBusiness,
        };
    } else if (
      myContext.bobStatus === myConsts.statusSubmitted ||
      myContext.bobStatus === myConsts.statusCompleted
    ) {
      if (
        assessmentStatus === myConsts.statusNotStarted ||
        assessmentStatus === myConsts.statusInProgress
      ) {
        if (myContext.formsInActive)
          return {
            txt: "View Assessment",
            route: myConsts.routeAssessment,
          };
        else
          return {
            txt: "Complete Assessment",
            route: myConsts.routeAssessment,
          };
      } else if (assessmentStatus === myConsts.statusSubmitted) {
        return { txt: "View Dashboard", route: myConsts.routeDashBoard };
      } else if (assessmentStatus === myConsts.statusRequestedChange) {
        if (myContext.formsInActive)
          return {
            txt: "View Assessment",
            route: myConsts.routeAssessment,
          };
        else return { txt: "Edit Assessment", route: myConsts.routeAssessment };
      } else if (assessmentStatus === myConsts.statusCompleted) {
        return { txt: "Approved", route: "" };
      }
    } else {
      return { txt: "", route: "" };
    }
  };
  const fnCallOnCurrentPeriodChange = (currentPeriod) => {
    if (activePPMDuserId && activePPMDuserId !== null) {
      return;
    }
    const data = assessmentMetaData.data.data;
    if (
      myContext.activePhase === null ||
      (data.assessmentMetaData &&
        data.assessmentMetaData.leadReviewer_CompletionDate &&
        new Date() >
        new Date(data.assessmentMetaData.leadReviewer_CompletionDate)) ||
      (myContext.activePhase !== null &&
        myContext.activePhase !== currentPeriod)
    ) {
      sessionStorage.setItem("formsInActive", true);
      myContext.setFormsInActive(true);
    } else {
      sessionStorage.setItem("formsInActive", false);
      myContext.setFormsInActive(false);
    }
    sessionStorage.setItem("currentPeriod", currentPeriod);
    myContext.setCurrentPeriod(currentPeriod);
    setTab(currentPeriod);
  };

  const activePPMDuserId =
    myContext.activePPMDuserId || sessionStorage.activePPMDuserId;
  const [activeIndexTime, setActiveIndexTime] = useState(
    myConsts.TimePeriodsArray.indexOf(myContext.activePeriod)
  );

  const getTenureRange = (tenure) => {
    if (tenure < 6) {
      return "0-5 years";
    } else if (tenure < 11) {
      return "6-10 years";
    } else {
      return "11+ years";
    }
  };
  return (
    <>
      {loader && <Loader />}
      <div className="channel-info-main-container">
        <div className="channel-info-main-sidemenu">
          <SideMenu
            minimized={true}
            sideMenuItemSelect={sideMenuItemSelected}
            isminimizedMainMenu={true}
            isMainMenu={true}
          />{" "}
        </div>{" "}
        <div className="channel-info-container">
          <div className="second-header">
            <div className="btnsection">
              {activePPMDuserId && activePPMDuserId !== null ? (
                <Breadcrumb crumbs={crumbs} {...props} />
              ) : (
                <div
                  className="header-name"
                  style={{ width: "76%", marginRight: "-5px" }}>
                  Home
                </div>
              )}
            </div>
          </div>
          {/* Home section */}
          <SimpleBar
            style={{
              width: "100%",
              height: "calc(100vh - 105px)",
            }}>
            <div className="user-profile-sec padd-margin">
              <div className="lft-sec">
                <div>
                  {(userImg && userImg.data && userImg.data.href) || userDp ? (
                    <img
                      src={
                        userDp
                          ? userDp
                          : userImg && userImg.data && userImg.data.href
                            ? userImg.data.href
                            : "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2000&q=80"
                      }
                      alt="user-profile-pic"
                      className="profile-img"
                    />
                  ) : (
                    <div className={"dummyDP"}>
                      {extractFirstLetters(userData)}{" "}
                    </div>
                  )}
                </div>
                <div className="dtl-box">
                  <div className="dtl-flex">
                    <div>Name: {displayName}</div>
                    <div>Region: {userProfile && userProfile.region}</div>
                  </div>
                  <div className="dtl-flex">
                    <div>Office: {userProfile && userProfile.locationCity}</div>
                    <div>Rank: {userProfile && userProfile.rankDesc}</div>
                  </div>
                  <div className="dtl-flex">
                    <div>
                      LEAD Reviewer: {userProfile && userProfile.counselorName}
                    </div>
                    <div>Country: {userProfile && userProfile.country}</div>
                  </div>
                  <div className="dtl-flex">
                    <div>
                      Tenure: {userProfile && getTenureRange(userProfile.tenor)}
                    </div>
                  </div>
                </div>
              </div>
              {!myContext.formsInActive &&
                userPhaseFlag[myContext.currentPeriod] &&
                !myContext.isRouteFromAdmin && (
                  <div className="right-sec-home">
                    {activePPMDuserId && activePPMDuserId !== null ? (
                      <div
                        onClick={
                          assessmentStatus !== myConsts.statusCompleted &&
                            assessmentStatus !== myConsts.statusRequestedChange ?
                            approvalPopupShow
                            :
                            (e) => e.preventDefault()
                        }
                        className={
                          assessmentStatus === myConsts.statusCompleted ||
                            assessmentStatus === myConsts.statusRequestedChange
                            ? "butn grey-color disable-color"
                            : "butn yellow-color"
                        }>
                        Respond
                      </div>
                    ) : myContext.userRole !== myConsts.rolesLeadReviewer ? (
                      <>
                        {myContext.reviewerMessage &&
                          myContext.reviewerMessage.msg !== "" && (
                            <div className={"boxx notify-wrap-pop"}>
                              {myContext.reviewerMessage &&
                                myContext.reviewerMessage.msg !== "" &&
                                !myContext.reviewerMessage.read && (
                                  <div className="notification-badge">
                                    &nbsp;
                                  </div>
                                )}
                              <i
                                className="icon-email-25"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  setAnchorEl(
                                    anchorEl ? null : e.currentTarget
                                  );
                                }}
                              />

                              <Popper
                                id={"poper"}
                                open={open}
                                anchorEl={anchorEl}
                                placement={"bottom-end"}>
                                <ClickAwayListener
                                  onClickAway={clickAwayHandler}>
                                  <div className={classes.paper}>
                                    <div className="title-popper">
                                      {
                                        "You have a message from your LEAD Reviewer"
                                      }{" "}
                                    </div>
                                    <div className="blue-bg">
                                      <SimpleBar
                                        style={{
                                          maxHeight: "30vh",
                                          overflowX: "hidden",
                                          padding: "9px",
                                        }}>
                                        {(myContext.reviewerMessage.from
                                          ? myContext.reviewerMessage.from +
                                          " : "
                                          : "") + myContext.reviewerMessage.msg}
                                      </SimpleBar>
                                    </div>
                                    <div className="bottom-div">
                                      <div
                                        className={"butn-pop yellow-color"}
                                        onClick={async (e) => {
                                          setAnchorEl(null);
                                          if (!myContext.reviewerMessage.read) {
                                            const token =
                                              await authProvider.acquireTokenSilent(
                                                {
                                                  scopes: APIscope,
                                                }
                                              );
                                            const idToken = token.accessToken;
                                            dispatch({
                                              type: "COMMENT_READ",
                                              payload: {
                                                token: idToken,
                                                financialYear:
                                                  myContext.financialYear,
                                                timePeriod:
                                                  myContext.activePeriod,
                                              },
                                            });
                                          }
                                        }}>
                                        {"Ok"}
                                      </div>
                                    </div>
                                  </div>
                                </ClickAwayListener>
                              </Popper>
                            </div>
                          )}
                        {userAssessmentStatusData !== null &&
                          userAssessmentStatusData.status.toLowerCase() !==
                          "no data" && assessmentStatus !== myConsts.statusSubmitted && (
                            <>
                              {/* <div
                                className={
                                  getRouteTextOnStatus().txt.toLowerCase() ===
                                    myConsts.statusCompleted
                                    ? "butn grey-color disable-color"
                                    : "butn yellow-color"
                                }
                                onClick={(e) => {
                                  getRouteTextOnStatus().txt.toLowerCase() !==
                                    myConsts.statusCompleted &&
                                    getRouteTextOnStatus().route !== "" &&
                                    props.history.push(
                                      getRouteTextOnStatus().route
                                    );
                                }}>
                                {getRouteTextOnStatus().txt}
                              </div> */}
                            </>
                          )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                )}
            </div>
            <div className="padd-margin" style={{ padding: 0 }}>
              <DropdownComponent
                label="Select Year"
                defaultValue={"FY " + myContext.financialYear}
                required={true}
                // value={"FY " + myContext.financialYear}
                selectOnChange={
                  (label, value) => {
                    setFyYear(value);
                    myContext.setFinancialYear(
                      Number.parseInt(value.replace("FY ", ""))
                    );
                    sessionStorage.setItem(
                      "financialYear",
                      Number.parseInt(value.replace("FY ", ""))
                    );
                    sessionStorage.removeItem("activePeriod");
                    myContext.setActivePeriod(null);
                    sessionStorage.removeItem("currentPeriod");
                    myContext.setCurrentPeriod(null);
                    setTab(null);
                    dispatch({
                      type: "GET_ASSESSMENT_META_DATA_RESET",
                      payload: {},
                    });
                    dispatch({
                      type: "GET_TIME_PERIOD_STATUS_RESET",
                      payload: {},
                    });
                  } /* selectOnChange */
                }
                varName="fyYear"
                list={fyYear}
                errorMessage={errorMessages}
                disabled={activePPMDuserId && activePPMDuserId !== null}
              />
            </div>
            <div className="padd-margin tabs">
              <div className="tab">
                {activePPMDuserId && activePPMDuserId !== null ? (
                  <>
                    {myConsts.TimePeriodsArray.map((time, key) =>
                      myContext.currentPeriod === time ? (
                        <Tooltip
                          classes={{ tooltip: classes.tooltip }}
                          title="View Only"
                          placement="bottom-center">
                          <div
                            key={key}
                            className={
                              tabValue && tabValue === time
                                ? "selected-tab"
                                : ""
                            }
                            onClick={() => {
                              fnCallOnCurrentPeriodChange(time);
                            }}>
                            {time}
                          </div>
                        </Tooltip>
                      ) : (
                        <Tooltip
                          classes={{ tooltip: classes.tooltip }}
                          title=""
                          style={{ cursor: "not-allowed" }}
                          placement="bottom-center">
                          <div
                            key={key}
                            className={
                              tabValue && tabValue === time
                                ? "selected-tab"
                                : ""
                            }
                            onClick={() => {
                              fnCallOnCurrentPeriodChange(time);
                            }}>
                            {time}
                          </div>
                        </Tooltip>
                      )
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    {myConsts.TimePeriodsArray.map((time, key) =>
                      activeIndexTime < key ? (
                        <Tooltip
                          classes={{ tooltip: classes.tooltip }}
                          title="Not active. Will be enabled in future phase"
                          placement="bottom-center">
                          <div
                            key={key}
                            style={{ cursor: "not-allowed", color: "#747480" }}>
                            {time}
                          </div>
                        </Tooltip>
                      ) : activeIndexTime === key ? (
                        myContext.formsInActive ? (
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title="View Only. Reference to the previous LEAD Phase"
                            placement="bottom-center">
                            <div
                              key={key}
                              className={
                                tabValue && tabValue === time
                                  ? "selected-tab"
                                  : ""
                              }
                              onClick={() => {
                                fnCallOnCurrentPeriodChange(time);
                              }}>
                              {time}
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            classes={{ tooltip: classes.tooltip }}
                            title="Active Phase for the current cycle"
                            placement="bottom-center">
                            <div
                              key={key}
                              className={
                                (tabValue && tabValue === time
                                  ? "selected-tab"
                                  : "") + " active-phase"
                              }
                              style={{
                                fontWeight: 600,
                              }}
                              onClick={() => {
                                fnCallOnCurrentPeriodChange(time);
                              }}>
                              {time}
                            </div>
                          </Tooltip>
                        )
                      ) : (
                        <Tooltip
                          classes={{ tooltip: classes.tooltip }}
                          title="View Only. Reference to the previous LEAD Phase"
                          placement="bottom-center">
                          <div
                            key={key}
                            className={
                              tabValue && tabValue === time
                                ? "selected-tab"
                                : ""
                            }
                            onClick={() => {
                              fnCallOnCurrentPeriodChange(time);
                            }}>
                            {time}
                          </div>
                        </Tooltip>
                      )
                    )}
                  </>
                )}
              </div>
            </div>
            {tabValue && myContext.activePeriod && myContext.currentPeriod && (
              <TimePeriod
                {...props}
                navigateToAssessment={navigateToAssessment}
                userAssessmentStatusData={userAssessmentStatusData}
                userAssessmentProgress={userAssessmentProgress}
                assessmentMetaDetails={assessmentMetaDetails}
                getCurrentUser={getCurrentUser}
                activePPMDuserId={activePPMDuserId}
                setLoader={setLoader}
                userDiscussionGuideData={counseleesFinalizationDetails && counseleesFinalizationDetails.data}
                isImpersonateUser={getCurrentUser() === sessionStorage.userId ? false : true}
              />
            )}
            {!(activePPMDuserId && activePPMDuserId !== null) && ( // hide for ppmd profile by lead reviewer
              <div className="bottom-container">
                <Preview cardDetails={welcomeMessageContent} />
                <div className="wrapper">
                  {/* <div className="col">
                    <div className="content">
                      <span className="header">
                        Resources for LEAD 4D Mid-Year
                      </span>
                      <img src={Image1} className="img-preview" alt="" />
                      <img
                        src={play}
                        alt=""
                        className="play-btn"
                        onClick={() => navToNewTab("introducing")}
                      />
                      <p>
                        <b
                          className="link"
                          onClick={() => navToNewTab("introducing")}
                        >
                          Watch an instructional video
                        </b>{" "}
                        on how to complete your mid-year self-assessment and how
                        to interpret your LEAD 4D dashboard.
                      </p>
                    </div>
                  </div> */}
                  {/*<div className="col">
                    <div className="content">
                      <span className="header">
                        Download the Year-End Playbook
                      </span>
                      <img src={Image2} className="img-preview" alt="" />
                      <p>
                        <b
                          className="link"
                          onClick={() => navToNewTab("playbook")}>
                          Read the LEAD 4D Year-End & Dashboard Playbook
                        </b>{" "}
                        for an overview of the year-end process and to review conversation guides to have more effective year-end LEAD feedback conversations by leveraging results of your dashboard.
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="content">
                      <span className="header">
                        Learn more about LEAD 4D Year-End
                      </span>
                      <img src={Image3} className="img-preview" alt="" />
                      <p>
                        <b className="link" onClick={() => navToNewTab("faq")}>
                          Review this FAQ document
                        </b>{" "}
                        for detailed instructions on how to fill out the year-end self-assessment and review your dashboard, as well as guidance on how dashboard KPIs will be calculated for each of the LEAD's six global metrics.
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="content">
                      <span className="header">
                        Read LEAD reviewer-specific guidance
                      </span>
                      <img src={Image4} className="img-preview" alt="" />
                      <p>
                        <b
                          className="link"
                          onClick={() => navToNewTab("reviewer")}>
                          Read the LEAD 4D year-end LEAD reviewer quick start
                          guide
                        </b>{" "}
                        for an overview of your responsibilities at year-end and conversation guidance on how to leverage your PPMD's self-assessment and dashboard for a more meaningful feedback conversation.
                      </p>
                    </div>
                  </div>
                  <div className="col">
                    <div className="content">
                      <span className="header">
                        Access technical enablement
                      </span>
                      <img src={Image1} className="img-preview" alt="" />
                      <p>
                        <b
                          className="link"
                          onClick={() => navToNewTab("technical_enablement")}>
                          Access the LEAD 4D year-end technical user guide
                        </b>{" "}
                        for a screen-by-screen look at how the newly launched
                        LEAD 4D tool works for both PPMDs and LEAD reviewers.
                      </p>
                    </div>
                </div> */}
                  <IntroductionCard cardDetails={introCardContent} />
                </div>
              </div>
            )}
            {/* Home page section end */}
          </SimpleBar>
        </div>{" "}
        <ApprovalPopup
          partnerImg={userDp}
          partnerName={displayName}
          setLoader={setLoader}
          userAssessmentData={userAssessmentStatusData}
          partnerNameLetters={extractFirstLetters(userData)}
          showApprovalPopup={showApprovalPopup}
          cancelApprovalPopup={cancelApprovalPopup}></ApprovalPopup>{" "}
      </div>
      {/* <ModalVideo open={isOpen} onClose={() => setOpen(false)} /> */}
    </>
  );
};

// const ModalVideo = (props) => {
//   const { open, onClose } = props;
//   const azureUrl =
//     "https://web.microsoftstream.com/embed/video/af4226b0-aa8c-458a-8f91-b2b8ea1817e1?autoplay=false&showinfo=true";

//   return (
//     <Modal
//       open={open}
//       onClose={onClose}
//       styles={{
//         modal: {
//           maxWidth: "unset",
//           padding: "unset",
//         },
//         overlay: {
//           background: "rgba(0, 0, 0, 0.5)",
//         },
//         closeButton: {
//           background: "yellow",
//         },
//       }}
//       center>
//       <iframe
//         title="home"
//         width="640"
//         height="360"
//         src={azureUrl}
//         allowFullscreen
//         style={{ border: "none" }}></iframe>
//     </Modal>
//   );
// };
export default Home;
