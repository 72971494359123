import React, { useState } from "react";
import { Box } from "@material-ui/core";
import SimpleBar from "simplebar-react";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import AlertSubmitPopup from "../../../Common/AlertSubmitPopup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/core/styles";
import AdminEditPopup from "../AdminPopup/AdminEditPopup";
import "./LongCardListing.scss";
import moment from "moment";

const LongCard = React.memo((props) => {
  // const { answer } = props;
  const { generalSectionList } = props;
  const [generalSection] = generalSectionList || [];
  const length = props.assessmentData ? props.assessmentData.length : 0;
  const [anchor, setanchor] = React.useState(null);
  const [arrowRef, setArrowRef] = React.useState(null);
  const [editIndex, setEditIndex] = React.useState(-1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const openpopUp = Boolean(anchor);

  // let options = {
  //   year: "numeric",
  //   month: "short",
  //   day: "numeric",
  // };
  const useStyles = makeStyles((theme) => ({
    arrow: {
      position: "absolute",
      fontSize: 14,
      width: "6em",
      height: "4em",
      //   transform: "rotate(225deg)",
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
      },
    },
    paper2: {
      backgroundColor: "white",
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      // padding: theme.spacing(2, 2, 2),
      width: 80,
      overflow: "hidden",
      overflowX: "hidden",
      fontSize: "11px",
    },
    popper: {
      // zIndex: 1,
      left: "-24px!important",
      '&[x-placement*="top-end"]': {
        top: "-12px!important",
      },
      '&[x-placement*="bottom-end"]': {
        top: "9px!important",
      },
      '&[x-placement*="bottom"] $arrow': {
        top: 2,
        left: "22px!important",
        marginTop: "-0.9em",
        width: "6em",
        height: "2em",
        "&::before": {
          borderWidth: "0 1em 1em 1em",
          borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
        },
      },
      '&[x-placement*="top"] $arrow': {
        bottom: "-42px",
        left: "21px",
        marginBottom: "-0.9em",
        width: "6em",
        height: "4em",
        "&::before": {
          borderWidth: "1em 1em 0 1em",
          borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
        },
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
        marginLeft: "-0.9em",
        width: "6em",
        height: "4em",
        "&::before": {
          borderWidth: "1em 1em 1em 0",
          borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
        },
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        marginRight: "-0.9em",
        width: "6em",
        height: "4em",
        "&::before": {
          borderWidth: "1em 0 1em 1em",
          borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
        },
      },
    },
    options: {
      padding: "0px",
      cursor: "pointer",
      alignItems: "center",
      height: "25px",
      display: "flex",
      width: "90px",
      marginLeft: "6px",
      borderBottom: "1px solid #d3d3d352",
    },
  }));
  const classes = useStyles();

  const clickAwayHandler = () => {
    setanchor(null);
  };

  return (
    <div className="grid-display">
      {props.tabName === "sections" ? (
        <div className="centerText"> Maximum of 5 sections (excluding general) can be added </div>
      ) : (
        <div className="centerText">
          {" "}
          Maximum of 7 Global Metrics can be added{" "}
        </div>
      )}
      <SimpleBar
        style={{
          width: "100%",
          maxHeight: "49vh",
          height: "49vh",
        }}>
        {props.assessmentData &&
          props.assessmentData.map((item, index) => (
            <>
              <Box
                maxWidth={"46%"}
                minWidth="46%"
                className="long-card"
                style={{
                  borderLeftColor:
                    props.tabName === "sections"
                      ? item.color
                      : "rgba(46, 46, 56, 0.7)",
                }}>
                {props.tabName === "sections" ? (
                  <div className="card-Contents">
                    <div className="col-view">
                      <div className="title">{item.name}</div>
                      <div className="date" style={{ marginTop: "2px" }}>
                        {"Multiplier score "} <b>{item.score}</b>
                      </div>
                      <div
                        className="date one-line-elipses"
                        style={{ marginTop: "1px" }}>
                        {item.description}
                      </div>
                    </div>
                    <div className="Icon">
                      <div>
                        {index.toString().length < 2
                          ? "0" + (index + 1)
                          : index + 1}
                      </div>
                      {!props.disableFields && (

                        <i
                          className="icon-more-v"
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditIndex(index);
                            setanchor(e.currentTarget);
                          }}
                        />

                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className="card-Contents"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      props.setActiveGoal(index);
                    }}>
                    <div className="IconImg">
                      <i className={item.iconUrl} />
                    </div>
                    <div className="col-view">
                      <div className="title">{item.pillar}</div>
                      <div className="date">
                        {"Created On " +
                          `${moment(new Date()).format("Do MMMM YYYY")}`}
                      </div>
                    </div>
                    {
                      <div className="Icon">
                        <div>
                          {index.toString().length < 2
                            ? "0" + (index + 1)
                            : index + 1}
                        </div>
                        {!props.disableFields && (
                          <i
                            className="icon-more-v"
                            onClick={(e) => {
                              e.stopPropagation();
                              setanchor(e.currentTarget);
                              setEditIndex(index);
                            }}
                          />
                        )}
                      </div>
                    }
                  </div>
                )}
              </Box>
            </>
          ))}
        {props.tabName === "sections" && generalSection &&
          <Box
            maxWidth={"46%"}
            minWidth="46%"
            className="long-card"
            style={{
              borderLeftColor: "#66666D",
            }}
          >
            <div className="card-Contents">
              <div className="col-view">
                <div className="title">{'General'}</div>
                <div className="date" style={{ marginTop: "2px" }}>
                  {"Multiplier score -"}
                  {"  Radio Button"} <b>{generalSection.radio_button}</b>
                  {",  Check Box"} <b>{generalSection.check_box}</b>
                  {",  Drop Down"} <b>{generalSection.drop_down}</b>
                </div>
              </div>
              <div className="Icon">
                <div>
                  {length.toString().length < 2
                    ? "0" + (length + 1)
                    : length + 1}
                </div>
                {!props.disableFields && (
                  <i
                    className="icon-more-v"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditIndex(-2);
                      setanchor(e.currentTarget);
                    }}
                  />

                )}
              </div>
            </div>
          </Box>}
      </SimpleBar>
      <AlertSubmitPopup
        header={"Confirmation"}
        onlyAlert={false}
        delete={true}
        cancelBtnTxt={"Cancel"}
        submitBtnTxt={"Confirm"}
        simpleClosePopup={() => setShowDeleteModal(false)}
        description={
          props.tabName !== "sections"
            ? "Please note that this will also delete all the goals and impact statements within the selected metric."
            : ""
        }
        message={
          "Do you wish to delete this" +
          (props.tabName === "sections" ? " section?" : " global metric?")
        }
        showAlertSubmitPopup={showDeleteModal}
        cancelAlertSubmitPopup={() => setShowDeleteModal(false)}
        submitPopupChanges={() => {
          props.delete(
            props.tabName === "sections" ? "section" : "global metric",
            props.tabName === "sections"
              ? props.assessmentData[editIndex].name
              : props.assessmentData[editIndex].pillarId,
            props.assessmentData[editIndex].financialYear
          );
          setShowDeleteModal(false);
        }}></AlertSubmitPopup>

      <Popper
        placement="bottom"
        id="customPopupRoot"
        className={classes.popper}
        open={openpopUp}
        anchorEl={anchor}
        modifiers={{
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}>
        {<span className={classes.arrow} ref={setArrowRef} />}
        <ClickAwayListener onClickAway={clickAwayHandler}>
          <Paper className={classes.paper2}>
            <div
              className={classes.options}
              onClick={() => {
                if (props.tabName === "sections") {
                  setShowPopup(true);
                } else {
                  props.setActiveGoal(editIndex);
                  props.setActiveEditIndex(editIndex);
                  // props.setPreview(true);
                }
              }}>
              Edit
            </div>
            {props.tabName && (
              <div
                className={classes.options}
                style={{ opacity: editIndex === -2 ? .5 : 1, cursor: editIndex === -2 ? 'default' : 'pointer' }}
                onClick={() => {
                  if (editIndex !== -2)
                    props.ChangeOrder();
                }}>
                Display Order
              </div>
            )}
            <div
              className={classes.options}
              style={{ opacity: editIndex === -2 ? .5 : 1, cursor: editIndex === -2 ? 'default' : 'pointer' }}
              onClick={() => {
                if (editIndex !== -2)
                  setShowDeleteModal(true);
              }}>
              Delete
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
      <AdminEditPopup
        title={"Edit Section"}
        showPopup={showPopup}
        formType={editIndex === -2 ? 3 : 1}
        cancelPopup={() => setShowPopup(false)}
        fyYear={props.fyYear}
        data={editIndex === -2 ? generalSection : { ...props.assessmentData[editIndex] }}
        generalSection={editIndex === -2}
        uniquelist={props.uniquelist}
      />
    </div>
  );
});

export default LongCard;
