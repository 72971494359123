import React, { useContext } from "react";
import "./ProgressChartCard.scss";
import "./DoubleBarChartCard.scss";
import classNames from "classnames";
import _ from 'lodash'
import DoubleBarChart from "../../DoubleBarChart/DoubleBarChart";
import AppContext from "../../../utils/appContext";

/* This component is for displaying the double bar chart in the both dashboard and dashboard goal page. 
Here first bar represents the revenue and second one represets the goal. if revenue is less than goal, 
then first bar will be filled with gray color and second bar will be filled with green color. if revenue 
is greater than goal, then first bar will be filled with gray color and second bar will be filled with 
yellow color. */

const DoubleBarChartCard = (props) => {
  const { header, onClick, revenue, revenueGoal } = props;
  const myContext = useContext(AppContext);
  const fy = myContext.financialYear ? `${myContext.financialYear}`.substring(2, 4) : '22';
  const { level, position } = props.rank;
  return (
    <div
      className={classNames("dashboardChartsWrapper", {
        disableCursor: !revenue || !revenueGoal,
      })}
      onClick={() => onClick && onClick()}
    >
      <p className="dashboardChartHeader">{header}</p>
      {!_.isUndefined(revenue) && !_.isUndefined(revenueGoal) ? (
        <div className="doubleBarChartWrapper">
          <div className="doubleBarChartCover">
            <DoubleBarChart
              value1={revenueGoal}
              value2={revenue}
              fillStart={level}
              width="250"
              height="25"
            />
          </div>
          <div className="bottom-section">
            <div className="legend-info">
              <div className="legend">
                <div className="legend-icon"></div>
                <div className="legend-text">{`FY${fy} Revenue Plan (TER)`}</div>
              </div>
              <div className="legend">
                <div
                  className={classNames("legend-icon", {
                    "legend-icon-color": revenueGoal >= revenue,
                  })}
                ></div>
                <div className="legend-text">Current Year TER (YTD) ($)</div>
              </div>
            </div>
            {level ? (
              <div className="barChartData">
                <div className="barInfo">
                  <span>{level}</span>
                  <span
                    className={classNames("position", {
                      topColor: level === "top",
                      bottomColor: level === "bottom",
                    })}
                  >
                    {position}
                  </span>
                  <span>Percentile</span>
                </div>
                <div
                  className={classNames("indicator", {
                    upDownArrow: level === "mid",
                    topArrow: level === "top",
                    bottomArrow: level === "bottom",
                  })}
                ></div>
              </div>
            ) : (
              <span className="notApplicable">NA</span>
            )}
          </div>
        </div>
      ) : (
        <div className="barChartNoData">
          <span>No data to display</span>
        </div>
      )}
    </div>
  );
};

export default DoubleBarChartCard;
