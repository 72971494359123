import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import InputLabel from "@material-ui/core/InputLabel";
import DropdownComponent from "../../AdminGeneralParam/DropdownComponent/DropdownComponent";
import TextField from "@material-ui/core/TextField";
import SimpleBar from "simplebar-react";
import {ifNonEmptyInput} from '../../../../helpers/textValidator';
import "./AdminPopup.scss";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "400px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
      fontFamily: "EYInterState-Regular",
    },
  },
  rootMulti: {
    "& .MuiFilledInput-root": {
      background: "rgb(246,246,250)",
      borderRadius: "0px",
    },
    "& .MuiFilledInput-underline": {
      width: "900px",
      //   borderBottom: "0px solid rgba(0, 0, 0, 0.42)",
      "&:after": {
        // The MUI source seems to use this but it doesn't work
        borderBottom: "none",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      },
    },

    "& .MuiFilledInput-input": {
      padding: "8px 10px 8px 6px",
      fontSize: "12px",
      lineHeight: "15px",
      fontFamily: "EYInterState-Regular",
    },
    "& .MuiFilledInput-multiline": {
      padding: "10px  10px 6px  0px",
    },
  },
  labelroot: {
    backgroundColor: "rgb(246 ,246, 250)!important",
    color: "rgba(0, 0, 0, 0.94)",
    paddingLeft: "5px",
    paddingTop: "5px",
    fontSize: "8px",
  },
  topSpacing: {
    marginTop: "16px",
  },
  topSyli: {
    marginBottom: " -30px",
    marginTop: "16px",
    zIndex: "8",
    width: "100px",
  },
  half: {
    display: "flex",
    flexDirection: "row",
  },
  popper: {
    zIndex: "3000",
    '&[x-placement*="bottom-start"]': {
      top: "9px!important",
      left: "-146px!important",
    },
  },
}));
const AddGoalPopup = (props) => {
  const classes = useStyles();

  //

  const [selectedIndex, setSelectedIndex] = useState(0);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const goal = {
    goalName: "",
    goalDescription: "",
    noOfOptions: "",
    goalType: "",
    goalOptions: [],
    goalOptionNo: "",
  };
  const [values, setValues] = useState([
    {
      goalName: "",
      goalDescription: "",
      noOfOptions: "",
      goalType: "",
      goalOptions: [],
      goalOptionNo: "",
    },
  ]);
  // const open = Boolean(anchorEl);
  let errorMessages = {
    goalName: "",
    goalDescription: "",
    noOfOptions: "",
    goalType: "",
    goalOptions: "",
    goalOptionNo: "",
  };


 const validateInput = (label) => {
  let temp = values;
  const text = temp[selectedIndex][label];
    if(!ifNonEmptyInput(text)) {
      temp[selectedIndex][label] = "";
      setValues([...temp]);
    } else {
      temp[selectedIndex][label] = text.trim();
      setValues([...temp]);
    }
 }

  const textOnChange = (value, label, index) => {
    if (label === "goalName") {
      if (value.length < 30) {
        let temp = values;
        temp[selectedIndex].goalName = value;
        setValues([...temp]);
      }
    } else if (label === "goalDescription") {
      if (value.length < 190) {
        let temp = values;
        temp[selectedIndex].goalDescription = value;
        setValues([...temp]);
      }
    } else if (label === "goalType") {
      let temp = values;
      temp[selectedIndex].goalType = value;
      temp[selectedIndex].goalOptionNo = "";
      temp[selectedIndex].goalOptions = [];
      if (value === "CheckBox") temp[selectedIndex].goalOptions = [];
      setValues([...temp]);
    } else if (label === "goalOptionNo") {
      let temp = values;
      temp[selectedIndex].goalOptionNo = value;
      let temp2 = [];
      let option = {
        optionDesc: "",
        optionScore: "",
      };
      for (let i = 0; i < value; i++) {
        temp2.push({ ...option });
      }
      temp[selectedIndex].goalOptions = [...temp2];
      setValues([...temp]);
    } else if (label === "optionDesc") {
      let temp = values;
      temp[selectedIndex].goalOptions[index].optionDesc = value;
      setValues([...temp]);
    } else if (label === "optionScore") {
      let temp = values;
      temp[selectedIndex].goalOptions[index].optionScore = value;
      setValues([...temp]);
    }
  };
  useEffect(() => {
    props.passValues(values);
    // eslint-disable-next-line
  }, [values]);
  useEffect(() => {}, []);

  return (
    <div className="add-new-goal">
      <div className="tabHeadingrow">
        {values.map((item, index) => (
          <div
            className={
              "tabHeading" + (selectedIndex === index ? " selected-tab" : "")
            }
            onClick={() => setSelectedIndex(index)}>
            {"Goal " + (index + 1)}
          </div>
        ))}
        <div
          className={"btn" + (values.length === 8 ? " disable-btn" : "")}
          onClick={() => {
            if (values.length < 8)
              //+existing goal count
              setValues([...values, goal]);
          }}>
          <i className="icon-plus" style={{ marginRight: "5px" }} />
          Add New Goal
        </div>
      </div>
      <div className="form">
        <SimpleBar style={{ maxHeight: "365px" }}>
          <div style={{ width: "400px" }}>
            <InputLabel
              htmlFor="uncontrolled-native"
              className={"leftSpacing"}
              classes={{
                root: classes.labelroot,
              }}>
              {"Goal Name*"}
            </InputLabel>
            <TextField
              id="filled-basic"
              variant="filled"
              className={classes.root}
              required={true}
              value={values[selectedIndex].goalName}
              onChange={(e) => textOnChange(e.target.value, "goalName")}
              onBlur={() => validateInput("goalName")}
            />
          </div>
          <div style={{ width: "900px" }}>
            <InputLabel
              htmlFor="uncontrolled-native"
              className={classes.topSpacing}
              classes={{
                root: classes.labelroot,
              }}>
              {"Goal Description*"}
            </InputLabel>
            <TextField
              id="filled-basic"
              variant="filled"
              multiline
              maxRows={2}
              className={classes.rootMulti}
              required={true}
              value={values[selectedIndex].goalDescription}
              onChange={(e) => textOnChange(e.target.value, "goalDescription")}
              onBlur={() => validateInput("goalDescription")}
            />
          </div>
          <div className={classes.half}>
            <div
              style={{
                width: "400px",
                marginBottom: "5px",
                marginTop: "5px",
                marginRight: "15px",
              }}>
              <DropdownComponent
                label="Goal Type*"
                defaultValue={values[selectedIndex].goalType}
                required={false}
                selectOnChange={(dummy, value) => {
                  textOnChange(value, "goalType");
                }}
                varName="goalType"
                list={["", "Radio Button", "CheckBox", "Drop Down"]}
                errorMessage={errorMessages}
                disabled={false}
                wider={true}
              />
            </div>
            {values[selectedIndex].goalType != "CheckBox" && (
              <div
                style={{
                  width: "400px",
                  marginBottom: "5px",
                  marginTop: "5px",
                }}>
                <DropdownComponent
                  label={"No.of Goal Options*"}
                  defaultValue={values[selectedIndex].goalOptionNo}
                  required={false}
                  selectOnChange={(dummy, value) => {
                    textOnChange(value, "goalOptionNo");
                  }}
                  varName="goalType"
                  list={["", "1", "2", "3", "4"]}
                  errorMessage={errorMessages}
                  disabled={false}
                />
              </div>
            )}
          </div>
          <div>
            {values[selectedIndex].goalOptions &&
              values[selectedIndex].goalOptions.length > 0 &&
              values[selectedIndex].goalOptions.map((value, ind) => {
                return (
                  <div className={classes.half}>
                    <div
                      style={{
                        width: "400px",
                        marginBottom: "5px",
                        marginTop: "10px",
                      }}>
                      <InputLabel
                        htmlFor="uncontrolled-native"
                        className={"leftSpacing"}
                        classes={{
                          root: classes.labelroot,
                        }}>
                        {values[selectedIndex].goalType +
                          " Option " +
                          (ind + 1) +
                          "*"}
                      </InputLabel>
                      <TextField
                        id={"filled-basic" + ind}
                        variant="filled"
                        className={classes.root}
                        required={true}
                        value={
                          values[selectedIndex].goalOptions[ind].optionDesc
                        }
                        onChange={(e) =>
                          textOnChange(e.target.value, "optionDesc", ind)
                        }
                      />
                    </div>

                    {
                      <div
                        style={{
                          width: "400px",
                          marginBottom: "5px",
                          marginTop: "-5px",
                          marginLeft: "15px",
                        }}>
                        <DropdownComponent
                          label={
                            values[selectedIndex].goalType +
                            " Option " +
                            (ind + 1) +
                            " Score"
                          }
                          defaultValue={
                            values[selectedIndex].goalOptions[ind].optionScore
                          }
                          required={false}
                          selectOnChange={(dummy, value) => {
                            textOnChange(value, "optionScore", ind);
                          }}
                          varName="goalType"
                          list={["", "1", "2", "3", "4"]}
                          errorMessage={errorMessages}
                          disabled={false}
                        />
                      </div>
                    }
                  </div>
                );
              })}
          </div>
        </SimpleBar>
      </div>
    </div>
  );
};
export default withRouter(AddGoalPopup);
