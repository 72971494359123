import React, { useMemo } from "react";
import "./DoubleBarChart.scss";
import NumberFormat from "react-number-format";

/* This is simple horizantal double bar chart component used in the dashboard page.
 Props details explained below.
value1 - first bar width
value2 - second bar width
height - bar heigth
*/

function DoubleBarChart(props) {
  const { height, value1, value2 } = props;
  const firstBarStyle = useMemo(() => {
    if (value1 < value2) {
      return {
        backgroundColor: "#BFBFBF",
        width: (value1 / value2) * 100 + "%",
        height: height + "px",
      };
    } else {
      return {
        backgroundColor: "#BFBFBF",
        width: "100%",
        height: height + "px",
      };
    }
  }, [value1, value2, height]);
  const secondBarStyle = useMemo(() => {
    if (value1 < value2) {
      return {
        backgroundColor: "#B1DE76",
        width: "100%",
        height: height + "px",
      };
    } else {
      return {
        backgroundColor: "#FFEFC1",
        width: "100%",
        height: height + "px",
      };
    }
  }, [value1, value2, height]);
  return (
    <div className="double-bar-chart">
      <div className="bar" style={firstBarStyle}>
        <NumberFormat
          value={value1}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          renderText={(formattedValue) => <>{formattedValue}</>}
        />
      </div>
      <div className="bar" style={secondBarStyle}>
        {value1 < value2 ? (
          <NumberFormat
            value={value2}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            renderText={(formattedValue) => <>{formattedValue}</>}
          />
        ) : (
          <div style={{width: (value2 / value1) * 100 + "%", backgroundColor: '#FFE600'}}>
            <NumberFormat
              value={value2}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              renderText={(formattedValue) => <>{formattedValue}</>}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DoubleBarChart;
